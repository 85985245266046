import React from 'react';
import _ from 'lodash';
import { Rate, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { globalVariable } from '../../../../utils/config/config';
import { MdBusinessCenter } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import formatUrlPart from '../../../../utils/common/format-url-part';

const SpeicalResultCard = function (props) {
  // Extracting dir_single directly from props
  const dir_single = _.get(props, 'value.profile', {});

  // Extract necessary fields from dir_single
  const linkToProvider = _.get(dir_single, 'user_handle', '');
  const dir_Photo = _.get(dir_single, 'photo', '');
  const businessname = _.get(dir_single, 'businessname', '');
  const dir_firstname = _.get(dir_single, 'firstname', '');
  const dir_lastname = _.get(dir_single, 'lastname', '');
  const dir_displayName = businessname || `${dir_firstname} ${dir_lastname}`;
  const average_rating = _.get(dir_single, 'average_rating', 0);
  const gid = _.get(dir_single, 'group_id', '');
  const rpdirlist = _.get(dir_single, 'is_directory_listing', '');
  const rpspolist = _.get(dir_single, 'is_sponsored', '');
  const dir_type = _.get(dir_single, 'type', '');

  // Determine the displayName and type for the link
  let displayName = (businessname || `${dir_firstname}`).toLowerCase().replace(/\s+/g, '-');
  displayName = !_.isEmpty(displayName) ? displayName : '-';

  const link_to = `/profile/${linkToProvider}`;

  return (
    <div className="nurtem-search-card-design" key={props.index}>
      <Link to={link_to} target={"_blank"}>
        <div className="search-width">
          <img
            className="profile-image-avatar"
            src={dir_Photo || globalVariable.DEFAULT_PROFILE_PIC}
            alt="img"
          />
        </div>
        <div className="search-width2">
          <div className="card-divider">
            <div className="profile-name text-truncate">
              {dir_displayName}
            </div>
          </div>
          <div className="badge-group mt-2">
            <span className="badge badge-green mb-0 mr-2">{dir_type}</span>
          </div>
          <div className="card-divider">
            <div className="card-divider2 mt-2">
              <Rate style={{ marginRight: 10 }} disabled value={average_rating} />
              <strong style={{ fontSize: 13 }}>{average_rating.toFixed(1)}</strong>
            </div>
          </div>
        </div>
        <div className="class-links" />
        {(
          <div className="search-width3">
            <div className="card-divider4">
              {businessname ? <MdBusinessCenter size={25} style={{ marginRight: 5 }} /> : <FaUserTie size={15} style={{ marginRight: 5 }} />}
              <div className="profile-name2 text-truncate">
                {dir_displayName}
              </div>
            </div>
            <div className="badge-group" />
          </div>
        )}
      </Link>
    </div>
  );
};

export default withTranslation()(SpeicalResultCard);
