import React, { useEffect, useState } from 'react'
import {
  AiOutlineCalendar,
  AiOutlineExclamationCircle,
  AiOutlineLaptop,
} from 'react-icons/ai'
import _ from 'lodash'
import queryString from 'query-string'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import OtherDay from '../../../components/common/other-day'
import ReschduledDays from '../../../components/common/reschduled-days'
import LabelConvertion from '../../../components/re-usable/label-convertion'
import SessionsLeft from '../../../components/re-usable/SessionsLeft'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import timeZone from '../../../components/common/timezone'
import axiostoServer from '../../../utils/axios'
import variant from '../../../utils/data/variant'
import { ListAntd } from '../../../components/antd-components/index.tsx'
import { List, Tooltip } from 'antd'
function UserSessionInfo(props) {
  const { Item } = List // Destructure Item from List

  const { session_details } = props
  const [sessionsPurchased, setSessionsPurchased] = useState('Fully-Paid')
  const billingType = _.get(session_details, 'billing_type')
  const session_type =
    session_details.billing_type !== 2
      ? LabelConvertion(session_details.billing_type, 'billing-type')
      : `${LabelConvertion(
        session_details.billing_type,
        'billing-type'
      )} - ${LabelConvertion(
        session_details.payment_cycle_type,
        'payment_cycle_type'
      )}`

  const sessionDates = _.get(session_details, 'session_dates', [])
  const url = window.location.search
  const urlparams = queryString.parse(url)
  const paid_for = _.get(urlparams, 'userid')
  const clsID = _.get(session_details, 'id')
  const sessionscancelled = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sesCancell')}</span>
    </span>
  )
  const sessionsadded = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sesCancell1')}</span>
    </span>
  )
  const sessionsreschduled = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sesCancell2')}</span>
    </span>
  )
  useEffect(() => {
    if (billingType !== 3 && paid_for && clsID) {
      axiostoServer({
        method: 'post',
        url: '/number-sessons-paid',
        withCredentials: true,
        data: {
          class_id: clsID,
          paidfor: paid_for,
          billingType,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .then((resp) => {
          if (resp.status === 200 || resp.status === 304) {
            setSessionsPurchased(_.get(resp, 'data.data.sessionPaid', 0))
          }
        })
        .catch((error) => {
          console.error('Error fetching session purchase:', error)
        })
    }
  }, [billingType, clsID, paid_for])

  const SessionPaid = () => {
    if (sessionsPurchased && billingType === 2) {
      const uniqueMonths = Array.from(
        new Set(sessionDates.map((date) => dayjs(date).format('MMM')))
      )
      const dataValue = uniqueMonths.slice(0, sessionsPurchased)
      return dataValue
    }
    return sessionsPurchased
  }
  const sessionAttended = _.get(props, 'session_attended', 0)
  const sessionDue =
    sessionAttended > 0 && sessionAttended > sessionsPurchased
      ? _.subtract(sessionAttended, sessionsPurchased)
      : '---'
  function getLabelByValue(arrObj, value) {
    const matchingObject = arrObj.find((item) => item.value === value)
    return matchingObject ? matchingObject.label : '--'
  }
  const sessionDetails = _.get(session_details, 'time_slot')

  const timeSlotArray = sessionDetails
    ? Object.keys(sessionDetails).map((day) => ({
      day,
      start_time: new Date(sessionDetails[day].start_time).toLocaleTimeString(
        'en-US',
        { hour: '2-digit', minute: '2-digit' }
      ),
      end_time: new Date(sessionDetails[day].end_time).toLocaleTimeString(
        'en-US',
        { hour: '2-digit', minute: '2-digit' }
      ),
    }))
    : []

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      <div className="row py-3" style={{ width: '100%' }}>
        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35, display: 'none' }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#F7FECC' }}
            >
              <AiOutlineCalendar style={{ fontSize: 20 }} />
            </div>
            <div>
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                {props.t('totalSes')}
              </div>
              <div className="session-text-bold" style={{ marginLeft: 10 }}>
                {session_details.session_dates &&
                  session_details.session_dates.length > 0
                  ? session_details.session_dates.length
                  : '---'}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35, display: 'none' }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#dfdfee' }}
            >
              <AiOutlineCalendar style={{ fontSize: 20 }} />
            </div>
            <div>
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                {props.t('sessLeft')}
              </div>
              <div className="session-text-bold" style={{ marginLeft: 10 }}>
                {SessionsLeft(session_details.session_dates)}
              </div>
            </div>
          </div>
        </div>
        {billingType !== 3 && props.type === 'classes' && (
          <div
            className="col-12 col-md-6 py-3 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#DAF5F6' }}
              >
                <AiOutlineLaptop style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('due')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {sessionDue}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className='col-12 col-md-6 py-3 pr-md-0' style={{ width: '50%', marginBottom: 35 }}>
                    <div className='session-block' style={{ display: 'flex' }}>
                        <div
                            className='session-icon mr-2 sessionInfoStyle'
                            style={{ background: '#DAF5F6' }}
                        >
                            <AiOutlineLaptop style={{ fontSize: 20 }} />
                        </div>
                        <div>
                            <div className='session-text-regular' style={{ width: 150, paddingTop: 0, paddingRight: 0 }}>Session Attended</div>
                            <div className='session-text-bold' style={{ marginLeft: 10 }}>{sessionAttended}</div>
                            {console.log("studentPrsss=========", session_details)}
                        </div>
                    </div>
                </div> */}
        {billingType !== 3 && props.type === 'classes' && (
          <div
            className="col-12 col-md-6 py-3 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#DAF5F6' }}
              >
                <AiOutlineLaptop style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sessPur')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {sessionsPurchased && <SessionPaid />}
                </div>
              </div>
            </div>
          </div>
        )}
        {props.type === 'classes' && sessionAttended > sessionsPurchased && (
          <div
            className="col-12 col-md-6 py-3 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#DAF5F6' }}
              >
                <AiOutlineLaptop style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sessDue')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {sessionAttended}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#ffeded' }}
            >
              <AiOutlineCalendar style={{ fontSize: 20 }} />
            </div>
            <div>
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                {props.type === 'classes' ? props.t('clsDate') : props.t('campDate')}
              </div>
              <div
                className="session-text-bold"
                style={{ marginLeft: 10, width: '100%' }}
              >
                {`${timeDateConverterSession(
                  session_details.start_date,
                  'date',
                  'MMM DD,YYYY'
                )} - ${timeDateConverterSession(
                  session_details.end_date,
                  'date',
                  'MMM DD,YYYY'
                )}`}
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-12 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#ffeded' }}
            >
              <AiOutlineCalendar style={{ fontSize: 20 }} />
            </div>
            <div className="full-width ">
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                {props.t('batchTimings')}
              </div>
              {console.log('types=====', props.type)}
              {console.log('types1=====', timeSlotArray)}
              {console.log('types12=====', session_details)}
              <div
                className="session-text-bold"
                style={{ marginLeft: 10, width: '100%' }}
              >
                {props.type === 'classes' || props.type === 'camps' ? (
                  <ListAntd
                    className={'ml-2 mt-1'}
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={timeSlotArray}
                    renderItem={(item) => (
                      <Item>
                        <Item.Meta
                          title={item.day}
                          description={`${item.start_time} - ${item.end_time}`}
                        />
                      </Item>
                    )}
                  />
                ) : (
                  <>
                    {`${timeDateConverterSession(
                      session_details.start_time,
                      'time',
                      'hh:mm A'
                    )} - ${timeDateConverterSession(
                      session_details.end_time,
                      'time',
                      'hh:mm A'
                    )}`}{' '}
                    {timeZone()}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {session_details.type === 'classes' && (
          <div
            className="col-12 col-md-6 py-3 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#F7FECC' }}
              >
                <AiOutlineLaptop style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('batType')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {session_type}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#f5bde9' }}
            >
              <i className="ntm-calendar" style={{ fontWeight: '600' }} />
            </div>
            <div>
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                <Tooltip placement="topLeft" title={sessionscancelled}>
                  {props.t('sessionsCancelled')}
                </Tooltip>
              </div>

              <div style={{ marginLeft: 10 }}>
                <OtherDay days={_.get(session_details, 'cancelled_days')} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-12 col-md-6  py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#ff8181' }}
            >
              <i className="ntm-calendar" style={{ fontWeight: '600' }} />
            </div>
            <div className="sess-left">
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                <Tooltip placement="topLeft" title={sessionsadded}>
                  {props.t('sessAdd')}
                </Tooltip>
              </div>

              <div style={{ marginLeft: 10 }}>
                <OtherDay days={_.get(session_details, 'extra_days')} />
              </div>
            </div>
          </div>
        </div>

        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#dfdfdf' }}
            >
              <i className="ntm-calendar" style={{ fontWeight: '600' }} />
            </div>
            <div className="sess-left">
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                <Tooltip placement="topLeft" title={sessionsreschduled}>
                  {props.t('sessRes')}
                </Tooltip>
              </div>
              <div style={{ marginLeft: 10 }}>
                <ReschduledDays
                  days={_.get(session_details, 'reschduled_days')}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-6 py-3 pr-md-0"
          style={{ width: '50%', marginBottom: 35 }}
        >
          <div className="session-block" style={{ display: 'flex' }}>
            <div
              className="session-icon mr-2 sessionInfoStyle"
              style={{ background: '#dfdfee' }}
            >
              <i className="ntm-calendar" style={{ fontWeight: '600' }} />
            </div>
            <div>
              <div
                className="session-text-regular"
                style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
              >
                {props.t('frequency')}
              </div>
              <div
                className="session-text-bold"
                style={{ marginLeft: 10, marginTop: 2, width: 'unset' }}
              >
                {session_details.variant === 'o'
                  ? _.map(session_details.selected_days, (day, index) => {
                    const formattedDay = _.upperFirst(
                      day.split(' ').join(', ')
                    )
                    const comma =
                      index !== session_details.selected_days.length - 1
                        ? ', '
                        : ''
                    return formattedDay + comma
                  })
                  : getLabelByValue(variant, session_details.variant)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(UserSessionInfo)
