import React from 'react';
import { Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as routeName from '../../../components/route-names';
import ClassDetailsView from './detail-view';
import history from '../../../utils/history';

class Camp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 3,
    };
  }

  componentDidMount() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);

    // if no camp id in url then redirect to camp list
    if (urlparams && !urlparams.gid) {
      history.push(`${routeName.tutorClasses}`);
    }
  }

  async componentDidUpdate(prevPros) {
    if (prevPros.deleteClassInfo !== this.props.deleteClassInfo && this.props.deleteClassInfo && this.props.deleteClassInfo.data && this.props.deleteClassInfo.status === 200) {
      Modal.destroyAll();
    } else if (prevPros.deleteClassInfo !== this.props.deleteClassInfo && this.props.deleteClassInfo.data) {
      Modal.destroyAll();
      notification.error({
        message: 'Error',
        description:
                    this.props.deleteClassInfo.data.message,
      });
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return '';
    return value.charAt(0).toUpperCase() + value.slice(1);
  };

  onDeleteConfirm = async (classId) => {
    this.props.onClearCampDeleteDetail();
    await this.setState({
      classId,
    });
    try {
      const currentThis = this;
      const requestParams = {};
      requestParams.id = classId;
      currentThis.props.onDeleteClass(requestParams);
    } catch (error) {
      console.log('Oops errors!', error);
    }
  };

  pageRedirect = (value) => {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    if (urlparams.tutor) {
      history.push(`${routeName.TutorUpdateClass}?id=${value}&type=update&tutor=${urlparams.tutor}`);
    } else {
      history.push(`${routeName.TutorUpdateClass}?id=${value}&type=update`);
    }
  };

  render() {
    return (
      <div className="full-width tutor-my-camp-details-page pb-4 mb-3">

        <ClassDetailsView
          {...this.props}
          onDeleteClass={(id) => this.onDeleteConfirm(id)}
          onEditClass={(id) => this.pageRedirect(id)}
          classDetails={this.props.singleClassView}
          state={this.state}
        />

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  deleteClassInfo: state.classesDetails.deleteClassInfo,
  loading: state.classesDetails.loading,
  singleClassView: state.classesDetails.tutorCampsDetails ? state.campsDetails.tutorCampsDetails.details : {},
});

const mapDispatchToProps = (dispatch) => ({
  onClearCampDeleteDetail: () => dispatch(classActions.onClearClassDeleteDetail()),
  onDeleteClass: (requestParams) => dispatch(classActions.onDeleteClass(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Camp));
