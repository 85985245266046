import React, { useState, useEffect } from 'react';
import { Button, Input } from 'antd';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { withTranslation } from 'react-i18next';
import * as routeName from '../../route-names';
import history from '../../../utils/history';
import * as actions from '../../../store/actions/authentication/authentication';

dayjs.extend(utc);
dayjs.extend(timezone);

const EmailCodeVeri = (props) => {
  const EmailId = props.loginSignupAdminApproval.email ? props.loginSignupAdminApproval.email : props.signInHandleVerification.email;
  const [codeData, setCodeData] = useState(null);
  const CodeSend = (e) => {
    const requestParams = {};
    requestParams.type = props.type;
    requestParams.timezone = dayjs.tz.guess();
    requestParams.time = new Date();
    requestParams.code = codeData;
    requestParams.email = EmailId;
    props.SignupCodeVerification(requestParams);
  };
  const CodeSendResend = (e) => {
    const requestParams = {};
    requestParams.type = props.type;
    requestParams.timezone = dayjs.tz.guess();
    requestParams.time = new Date();
    requestParams.code = codeData;
    requestParams.email = EmailId;
    props.SignupCodeVerificationResend(requestParams);
  };

  useEffect(() => {
    if (props.onSignupCodeVerificationState.status === 200) {
      const urlElements = window.location.href.split('/');
      if (urlElements[3] === 'sign-in') {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        history.push(routeName.signIn);
      }
    }
  }, [props.onSignupCodeVerificationState]);
  const render = () => (
    <div className="success-message-text">
      <div className="full-width">{props.t('signupVerifi')}</div>
      <div className="full-width mb-2">{props.t('signupVerifi1')}</div>
      <Input
        size="medium"
                    // prefix={<LockFilled />}
                    // type='password'
        placeholder="Enter code from email"
        onChange={(e) => (setCodeData(e.target.value))}
      />
      <div className="button-container-verification">
        <Button
          disabled={!codeData}
          onClick={() => CodeSend()}
          size="small"
          type="primary"
        >
          {props.t('verify')}
        </Button>
        <Button
          onClick={() => CodeSendResend()}
          size="small"
          type="link"
        >
          {props.t('resend')}
        </Button>
      </div>
    </div>

  );
  return render();
};

const mapStateToProps = (state) =>
// console.log('stateinemail========', state.authentication)
  ({
    onSignupCodeVerificationState: state.authentication.onSignupCodeVerificationState,
    signInHandleVerification: state.authentication.signInHandleVerification,
    loginSignupAdminApproval: state.authentication.loginSignupAdminApproval,
    signUpSuccess: state.authentication.signUpSuccess,
    signInSuccess: state.authentication.signInSuccess,
  });
const mapDispatchToProps = (dispatch) => ({
  SignupCodeVerification: (requestParams) => dispatch(actions.onSignupCodeVerification(requestParams)),
  SignupCodeVerificationResend: (requestParams) => dispatch(actions.onSignupCodeVerificationResend(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EmailCodeVeri));
