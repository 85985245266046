import React from 'react';

export const loader = (
  <div className="ph-item">
    <div className="ph-col-4">
      <div className="ph-picture" style={{ height: `${190}px` }} />
    </div>
    <div className="ph-col-8">
      <div className="ph-row">
        <div className="ph-col-8 big" />
        <div className="ph-col-2 empty" />
        <div className="ph-col-2 big" />
      </div>
      <div className="ph-row">
        <div className="ph-col-8" />
        <div className="ph-col-4 empty" />
      </div>
      <div className="ph-row mt-3">
        <div className="ph-col-12" />
        <div className="ph-col-12" />
        <div className="ph-col-12" />
      </div>

      <div className="ph-row mt-3">
        <div className="ph-col-4 big" />
        <div className="ph-col-4 empty" />
        <div className="ph-col-4 big" />
      </div>
    </div>
  </div>
);
