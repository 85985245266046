import React from 'react';
import { Rate } from 'antd';
import { CLASSPNG } from '../../utils/config/assets';
import _ from 'lodash';

export default function campInfo(props) {
  const value = props.data;
  return (
    <div className="class-details-card">
      <div className="results-cards__image-container mb-2 ">
        <img src={(value.photo) ? (value.photo) : CLASSPNG} className="profile-class-image mr-2" alt="img" />
      </div>
      <div className="nurtem-profile-class-card-header">
        <div className="profile-card-name-container">
          <div className="profile-class-name text-truncate " style={{ textAlign: 'left', paddingLeft: 10 }}>
            {' '}
            {_.startCase(value.name)}
          </div>
        </div>
      </div>

      <div className="nurtem-profile-class-card-headerr">
        <div className="camp-detail-card-menu-star pl-2 pb-2">
          <div className="star-rating">
            <Rate disabled="true" value={_.parseInt(value.rating_count, 0)} />
          </div>
        </div>
      </div>
    </div>
  );
}
