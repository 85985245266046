import _ from 'lodash';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';

export const sessions = (data) => ({
  type: actionTypes.sessions,
  payload: data,
});

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const getSessionList = (requestParams, type) => {
  const url = `/${type}/list-sessions`;
  return (dispatch) => {
    dispatch(enableLoading());
    axios
      .get(url, {
        params: { ...requestParams },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          const data = _.get(response, ['data', 'data'], []);
          if (!_.isEmpty(data)) {
            dispatch(sessions({ group_id: requestParams.group_id, data }));
          }
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        dispatch(disableLoading());
      });
  };
};

export const loading = (status = false) => ({
  type: actionTypes.loading,
  loading: status,
});
