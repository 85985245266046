import React from 'react'
import _ from 'lodash'
import { Table } from 'antd'
import { withTranslation } from 'react-i18next'
import FileUpload from '../../../components/re-usable/file-upload'
import downloadXls from '../../../utils/common/download-xls'
import { OBUSERXLSX } from '../../../utils/config/assets'

function StudentOnboard(props) {
  const { filterDetails } = props
  console.log('onboardParams>>>', props)
  // Assuming you have data for the table rows
  const dataSource = []

  // Assuming you have columns for the table
  const columns = [
    {
      title: props.t('type'),
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: props.t('templates'),
      dataIndex: 'batchName',
      key: 'batchName',
    },
    {
      title: props.t('uploads'),
      dataIndex: 'selectOne',
      key: 'selectOne',
    },
  ]

  // Assuming you have rows for the additional row with "Onboard Students"
  const additionalRow = [
    {
      key: 'onboard-students',
      type: 'Onboard Students',
      batchName: (
        <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          <div
            onClick={() => downloadXls(OBUSERXLSX)}
            style={{ textDecoration: 'underline' }}
          >
            Download xls template
          </div>
        </div>
      ),
      selectOne: (
        <FileUpload
          url="/files/onboard-users"
          step="3"
          clssdet={filterDetails}
        />
      ),
    },
  ]
  return (
    <div>
      <Table
        dataSource={[...dataSource, ...additionalRow]}
        columns={columns}
        pagination={false}
      />
    </div>
  )
}

export default withTranslation()(StudentOnboard)
