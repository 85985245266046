import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import * as action from '../../../store/actions/tutor/camps';
import './user-new-my-class.css';
import StudentList from '../../../components/common/student-list';
import Milestones from '../../../components/common/milestone';
import Ratings from '../../../components/rating/rating-list';
import EnrollStudent from './components/enroll-a-student';
import Faq from '../../../components/common/faq';
import NotesAssignment from '../../../components/common/notes-assignment';
import history from '../../../utils/history';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';
import SessionDetailsRight from '../../../components/tutor/full-details/session-details';
import * as actionTypes from '../../../store/actions/common/actionTypes';
import ParticipantUploads from '../../../components/common/upload-list';
import SessionInfo from '../tutor-classes/components/sessionInfo';
import * as routeName from '../../../components/route-names';
import MeetingLink from '../tutor-classes/components/meeting-link';
import { FcCheckmark, FcConferenceCall, FcFaq, FcInfo, FcLink, FcMoneyTransfer, FcRating, FcSurvey, FcTodoList, FcUpload, FcViewDetails } from "react-icons/fc";
import { TiUserAdd } from "react-icons/ti";
import { LuMilestone } from "react-icons/lu";
import WaitingList from '../tutor-classes/components/waiting-list';
import { PiUserListBold } from 'react-icons/pi';

const SessionDetailedView = (props) => {
  const urlparams = queryString.parse(window.location.search);
  const tutorProfile = _.get(props, 'tutorBaseProfile', {});

  const profile_type = _.get(tutorProfile, 'profile_type', '');
  const works_for = _.get(tutorProfile, 'works_for', '');
  const isTutorUnderBusiness = profile_type === 1 && works_for !== null;

  const [campDetails, setcampDetails] = useState({});

  useEffect(() => {
    if (urlparams && urlparams.gid && urlparams.sid) {
      // get the details of the individual session
      props.onsessionDetail({ id: urlparams.sid, expand: 'services,provider', type: 'camps' });
    } else {
      history.goBack();
    }
  }, [urlparams.sid]);

  useEffect(() => {
    if (props.sessionDetail) {
      setcampDetails(props.sessionDetail);
    }
  }, [props.sessionDetail]);

  const viewAttendance = (key, session) => {
    const provider_id = _.get(session, 'provider.id', _.get(session, 'provider', ''));

    if (key === '2') {
      history.push(`${routeName.TutorSessionAttendance.split(':')[0]}?classId=${session.id}?type=${session.type}&provId=${provider_id}`);
    } else if (key === '10') {
      history.push(`${routeName.attendanceReportFull.split(':')[0]}?classId=${session.id}&type=${'camps'}&provId=${provider_id}`);
    } else if (key === '11') {
      history.push(`${routeName.payments}?provId=${provider_id}`);
    } else if (key === '12') {
      history.push(`${routeName.paymentReportFull.split(':')[0]}?classId=${session.id}&type=${'camps'}&provId=${provider_id}`);
    }
  };
  const tabTitle = props.t('students');
  const tabTitle1 = props.t('att');
  const tabTitle2 = props.t('notes&asg');
  const tabTitle3 = props.t('stuUp');
  const tabTitle4 = props.t('enrollStu');
  const tabTitle5 = props.t('faqs');
  const tabTitle6 = props.t('milestones');
  const tabTitle7 = props.t('r&r');
  const tabTitle8 = props.t('batchInfo');
  const tabTitle9 = props.t('tutorSidebarAttendanceReport');
  const tabTitle10 = props.t('tutorSidebarOfflinePayments');
  const tabTitle11 = props.t('tutorSidebarPaymentReport');
  const tabTitle12 = props.t('waitlist2');

  const render = () => (
    <div className="nurtem-my-class-card tutor-my-camp-details-page pb-4 mb-3 sessionbox">
      <ScrollToTop />
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 border-right">
          <Tabs
            defaultActiveKey="9"
            tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
            onChange={(key) => viewAttendance(key, campDetails)}
          >
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcInfo style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle8}</span>} key="9">
              <SessionInfo
                session={campDetails}
                id={urlparams.sid}
                type="camps"
              />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcConferenceCall style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle}</span>} key="1">
              <StudentList session={campDetails} type="camps" />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><PiUserListBold  style={{ marginRight: '5px', fontSize: '20px', color: '#daa69a' }} /> {tabTitle12}</span>} key="14">
              <WaitingList session={campDetails} type="camps" />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcCheckmark style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle1}</span>} key="2">
              {/* <Button onClick={() => viewAttendance(classDetails)} className="btn-green-filled">Attendance</Button> */}
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcViewDetails style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle2}</span>} key="3">
              <NotesAssignment session={campDetails} type="camps" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcUpload style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle3}</span>} key="4">

              <ParticipantUploads
                session={urlparams.sid}
                cat="camps"
                subcat={actionTypes.campUploads}
                user="host"
                user_type="student"
              />

            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><TiUserAdd style={{ color: '#7d3d8b', marginRight: '5px', fontSize: '20px' }} /> {tabTitle4}</span>} key="5">
              <EnrollStudent session={campDetails} type="camps" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcFaq style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle5}</span>} key="6">
              <Faq session={campDetails} type="camps" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><LuMilestone style={{ color: 'darkblue', marginRight: '5px', fontSize: '20px' }} /> {tabTitle6}</span>} key="7">
              <Milestones session={campDetails} type="camps" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcRating style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle7}</span>} key="8">
              <Ratings session={campDetails} type="camps" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcTodoList style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle9}</span>} key="10" />
            {!isTutorUnderBusiness && <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcMoneyTransfer style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle10}</span>} key="11" />}
            {!isTutorUnderBusiness && <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcSurvey style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle11}</span>} key="12" />}

            {campDetails.link && (
              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcLink style={{ marginRight: '5px', fontSize: '20px' }} /> Meeting Link</span>} key="13">
                <MeetingLink session={campDetails} type="classes" />
              </items>
            )}
          </Tabs>
        </div>
        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pl-0'>
            {urlparams && urlparams.gid && urlparams.sid &&
              <SessionDetailsRight
                id={urlparams.sid}
                type={'camps'}
              />
            }
          </div> */}
      </div>
    </div>
  );
  return render();
};

const mapStateToProps = (state) => ({
  sessionDetail: state.campsDetails.sessionDetail.data,
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),

});

const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) => dispatch(action.onGetsessionDetail(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SessionDetailedView));
