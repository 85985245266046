import React from 'react'
import { Layout, Image, Tabs, notification, Rate } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as userAction from '../../../store/actions/users'
import queryString from 'query-string'
import * as servicesActions from '../../../store/actions/public/Services'
import './user-my-camp.css'
import * as action from '../../../store/actions/public/Classes'
import parser from 'html-react-parser'
import Rating from '../../../components/common-user/ratings'
import Flyers from '../../../components/re-usable/gallery-view/flyers-preview'
import { Link } from 'react-router-dom'
import Notes from '../../../components/common-user/notes'
import Milestones from '../../../components/common-user/milestones'
import Faq from '../../../components/common-user/faq'
import Ratings from '../../../components/rating/rating-list'
import '../../tutor/tutor-camps/user-new-my-class.css'
import * as tutorActions from '../../../store/actions/public/tutor-public-profile'
import OtherClassesCamps from '../../../components/common-user/full-details/other-class-camp'
import _ from 'lodash'
import { FullDetailsLoader } from '../../../components/loader/common/full-details-loader'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import UploadToS3 from '../../../components/re-usable/file-to-s3'
import ParticipantUploads from '../../../components/common/upload-list'
import * as actionTypes from '../../../store/actions/common/actionTypes'
import UserSessionInfo from './user-sessionInfo'
import { CLASSPNG, DEFAULTUSER } from '../../../utils/config/assets'
import UserMeetingLink from './user-meeting-link'
import { MdLocationOn } from 'react-icons/md'
import { BiLogoZoom } from 'react-icons/bi'
import { NurtemUtils } from '../../../utils'
import { toggleLeftMenu } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import {
  FcFaq,
  FcInfo,
  FcLink,
  FcList,
  FcRating,
  FcTodoList,
  FcUpload,
  FcViewDetails,
} from 'react-icons/fc'
import { LuMilestone } from 'react-icons/lu'
import { FaBook, FaLinkedinIn } from 'react-icons/fa'
import Linkify from 'linkify-react'
import { BsTwitterX } from "react-icons/bs";

const { Footer, Content } = Layout

class DetailView extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 20,
      singleClassView: {},
      provider_id:
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.details &&
        this.props.tutorBaseProfile.details.id,
      classRow_id: '',
      upWork: this.props.t('upWork'),
      notes: this.props.t('notes&asg'),
      milestones: this.props.t('milestones'),
      fqs: this.props.t('faqs'),
      rr: this.props.t('r&r'),
      batchInfo: this.props.t('batchInfo'),
      tutorSidebarAttendanceReport: this.props.t(
        'tutorSidebarAttendanceReport'
      ),
    }
  }

  onChangePage = (page, pageSize, type) => {
    this.setState({
      [type]: page,
    })
    console.log('page', this.props)
    let requestParams = {
      provider: this.props.classDetailsView.provider.id,
      page: page,
    }
    requestParams.limit = 8
    requestParams.count = 8
    type === 'campsListPage'
      ? this.props.onGetCampsList(requestParams)
      : this.props.onGetClassesList(requestParams)
  }

  commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
      top: 100,
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    this._isMounted = true
    this.props.setToggleMenu(true)
    //this.props.getDependentUsers()
    let url = this.props.location.search
    let urlparams = queryString.parse(url)
    if (urlparams && urlparams.campId) {
      this.props.onClassDetail({
        id: urlparams.campId,
        expand: 'class_details,user,dependent_user,provider',
      })
    }
  }

  async componentDidUpdate(prevPros) {
    let url = this.props.location.search
    let urlparams = queryString.parse(url)
    if (prevPros.location.search !== this.props.location.search) {
      if (urlparams && urlparams.campId) {
        this.props.onClassDetail({
          id: urlparams.campId,
          expand: 'class_details,user,dependent_user,provider',
        })
      }
    }

    if (
      this.props.userClassInfo &&
      prevPros.userClassInfo !== this.props.userClassInfo
    ) {
      try {
        let requestParams = {
          provider: _.get(
            this.props,
            'userClassInfo.details.provider.id',
            null
          ),
        }
        requestParams.limit = 8
        requestParams.count = 8
        this.props.onGetClassesList(requestParams)
      } catch (error) {
        console.log(
          'error at detail-view.js in  componentDidUpdate >>userClassInfo',
          error
        )
      }
    }
  }
  viewAttendance = (key, session) => {
    let url = this.props.location.search
    let urlparams = queryString.parse(url)
    if (urlparams) {
      let userId = _.get(urlparams, 'userid', '')
      let userType = _.get(urlparams, 'usertype', '')
      let username = _.get(urlparams, 'username', '')
      if (key === '8' && userId && userType !== 'dependent') {
        history.push(
          `${routeName.attendanceReportUser.split(':')[0]}?classId=${session.id}&batchType=${'classes'}&userId=${userId}`
        )
      } else if (key === '8' && userId && userType === 'dependent') {
        history.push(
          `${routeName.attendanceReportUser.split(':')[0]}?classId=${session.id}&batchType=${'classes'}&dependent_user=${userId}&username=${username}`
        )
      }
    }
  }
  render() {
    let st = _.chain(this.props.userClassInfo.details)
    let session_details = st.get('class_details').value()
    this.session_date = session_details
    let providerInfo = st.get('provider').value()
    let userId =
      st.get('dependent_user').value() !== null
        ? st.get('dependent_user.id').value()
        : st.get('user').value() !== null
          ? st.get('user.id').value()
          : ''
    let tit = st.get('class_details.session_name').value()
    let ht = '270px'
    let sessionAttended = st.get('session_attended').value()
    let desc = st.get('class_details.description', '').value()
    console.log('')
    let provider = st.get('provider').value()
    let group_id = st.get('class_details.group_id').value()
    let session_id = st.get('class_details.id').value()
    let flyers = st.get('flyers').value()
    const providerDetail = st.get('provider').value()
    const fn = st.get('provider.firstname').startCase().value()
    const ln = st.get('provider.lastname').startCase().value()
    const bn = st.get('provider.businessname').isEmpty().value()
    const ty = st.get('provider.type').value()
    const providerId = st.get('provider.id').value()
    const providerName =
      ty === 'Individual' ? fn + ' ' + ln : !_.isEmpty(bn) ? bn : fn + ' ' + ln
    const fname = st.get('provider.firstname').startCase().value()
    const lname = st.get('provider.lastname').startCase().value()
    const bname = st.get('provider.businessname').isEmpty().value()
      ? fname
      : st.get('provider.businessname').startCase().value()

    let tutorName =
      st.get('provider.type').value() === 'Individual'
        ? `${fname} ${lname}`
        : `${bname}`
    if (tutorName) {
      tutorName = tutorName.toLowerCase().trim()
    }
    let aCampName = st.get('classes.name').value()
    if (aCampName) {
      aCampName = aCampName.toLowerCase().trim()
    }
    let viewCampUrl = ''
    if (tutorName && aCampName) {
      let gid = st.get('classes.id')
      viewCampUrl = `${tutorName.replace(/\s+/g, '-')}/${aCampName.replace(/\s+/g, '-')}/?gid=${gid}`
    }

    let url = this.props.location.search
    let urlparams = queryString.parse(url)

    return (
      <div className="tabs-container nurtem-my-class-card tutor-my-camp-details-page mb-4">
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
          onChange={(key) => this.viewAttendance(key, session_details)}
        >
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcList style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                {this.props.t('batchDetail')}
              </span>
            }
            key="1"
          >
            {!session_details ? (
              <FullDetailsLoader />
            ) : (
              <Layout>
                <Content>
                  <div className="row">
                    <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                      <div className="head-classdetails-page-title">
                        {tit}
                      </div>
                    </div>
                    <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                      <div className="ratings user-details">
                        <Rating
                          title={tit}
                          type={'classes'}
                          campUrl={viewCampUrl}
                          provider={provider}
                          class_details={group_id}
                          userId={userId}
                          session_details={session_id}
                        />
                      </div>
                    </div>
                    <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                      <Image
                        src={
                          st.get('classes.photo').value()
                            ? st.get('classes.photo').value()
                            : CLASSPNG
                        }
                        className="camp-img-bg"
                        alt="img"
                        width="100%"
                        height={ht}
                      />
                    </div>
                  </div>
                </Content>
                <div className="flyer mt-3 mb-3">
                  <div className="sub-title">{this.props.t('gallery')}</div>
                </div>
                {
                  <Content>
                    <Layout
                      style={{
                        fontSize: window.innerWidth < 980 ? '12px' : '14px',
                      }}
                    >
                      <Layout className="flyer">
                        {flyers && flyers.length > 0 ? (
                          <Flyers ht={'300px'} wt={'30%'} images={flyers} />
                        ) : (
                          <>
                            {' '}
                            <EmptyAnime AnimeType={'EmptyUserSide'} />
                            <p className="empty-animations">
                              {this.props.t('noImageFound')}
                            </p>
                          </>
                        )}
                      </Layout>
                    </Layout>
                  </Content>
                }
                <div className="sub-title ">{this.props.t('descp')}</div>
                <div style={{ background: '#fff' }} className='parafont'>
                  <p>
                  {typeof desc === 'string'
                    ? parser(desc.replace(/\n/g, '<br>').replace(/<\/p>\s*<br>/g, '</p>'))
                    : ''}
                  </p>
                </div>
                <div className="sub-title  ">{this.props.t('aboutTutor')}</div>
                {providerInfo && (
                  <>
                    {console.log('providerinfo=======', providerInfo)}
                    <div className="row ml-3 mr-3">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                        <div className="pro-header">
                          <img
                            src={
                              providerInfo.photo
                                ? providerInfo.photo
                                : DEFAULTUSER
                            }
                            className="tutor-page-img-bg"
                            alt="img"
                          />
                        </div>
                        <div className="row">
                          {_.get(providerInfo, 'email', '') && (
                            <div
                              className="col-12  contact-list-profile mb-1"
                              style={{ display: 'flex' }}
                            >
                              <span
                                className="icon link"
                                style={{ marginRight: 3 }}
                              >
                                <i className="ntm-envelope"></i>
                              </span>{' '}
                              <span>{_.get(providerInfo, 'email', '')}</span>
                            </div>
                          )}
                          {_.get(providerInfo, 'address', '') && (
                            <div
                              className="col-12  contact-list-profile mb-1"
                              style={{ display: 'flex' }}
                            >
                              <span
                                className="icon link"
                                style={{ marginRight: 6 }}
                              >
                                <MdLocationOn style={{ fontSize: 18 }} />
                              </span>{' '}
                              <span>{_.get(providerInfo, 'address', '')}</span>
                            </div>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              fontSize: 20,
                              marginTop: 5,
                            }}
                          >
                            {_.get(providerInfo, 'skype_id', '') && (
                              <div className="col-3  contact-list-profile mb-1">
                                <span className="icon link">
                                  <a href={_.get(providerInfo, 'skype_id', '')}>
                                    <BsTwitterX />
                                  </a>
                                </span>
                              </div>
                            )}
                            {_.get(providerInfo, 'zoom_id', '') && (
                              <div className="col-3 contact-list-profile mb-1">
                                <span className="icon link">
                                  <a
                                    style={{ wordBreak: 'break-all' }}
                                    className="a"
                                    href={_.get(providerInfo, 'zoom_id', '')}
                                    rel="noreferrer"
                                    target="_blank"
                                  >
                                    <FaLinkedinIn
                                      style={{
                                        color: '#0058ffc7',
                                        fontSize: 24,
                                      }}
                                    />
                                  </a>
                                </span>
                              </div>
                            )}
                            {_.get(providerInfo, 'facebook_id', '') && (
                              <div className="col-3  contact-list-profile mb-1">
                                {' '}
                                <span>
                                  <a
                                    className="a"
                                    href={_.get(
                                      providerInfo,
                                      'facebook_id',
                                      ''
                                    )}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <span className="icon link">
                                      <i
                                        className="ntm-facebook-logo"
                                        style={{ color: '#0058ffc7', fontSize: 24 }}
                                      ></i>
                                    </span>{' '}
                                  </a>
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                        <div className="camp-profile-details mt-1 mb-2">
                          <div className="profile-name-header">
                            {/* <div className='hideTutor' style={{ fontSize: 15, color: '#5a5a5a' }}><b>{this.props.t("tutorLabel")}</b></div> */}
                            <Link
                              className="view-profile"
                              to={{
                                pathname: `/profile/${_.get(providerInfo, 'user_handle', null)}`,
                                state: { data: 'Hello from FirstPage' },
                              }}
                              target="_blank"
                            >
                              {_.get(providerInfo, 'type') === 'Individual'
                                ? !_.isEmpty(
                                  _.get(providerInfo, 'firstname', null)
                                )
                                  ? `${_.startCase(_.get(providerInfo, 'firstname'))} ${_.get(providerInfo, 'lastname', '') !== '' ? `${_.startCase(_.get(providerInfo, 'lastname', ''))}` : ''}`
                                  : ''
                                : `${_.startCase(_.get(providerInfo, 'businessname'))}`}
                            </Link>
                          </div>

                          <h5
                            className="claim-business"
                            style={{ display: 'flex', width: '100%' }}
                          >
                            <span
                              className="star-rating"
                              style={{ display: 'flex' }}
                            >
                              <Rate
                                disabled="true"
                                value={_.parseInt(
                                  _.get(providerInfo, 'average_rating', 0)
                                )}
                              />
                              {parseInt(
                                providerInfo && providerInfo.average_rating
                              ) !== 0 && (
                                  <b className="ml-3" style={{ fontSize: 14 }}>
                                    {`${_.get(
                                      providerInfo,
                                      'average_rating',
                                      0
                                    ).toFixed(1)}`}
                                    /5
                                  </b>
                                )}
                            </span>
                          </h5>
                          <b className="rating-count">
                            ({parseInt(providerInfo.rating_count)}{' '}
                            {this.props.t('review')}'s)
                          </b>
                        </div>

                        <div className="camp-profile-details mb-2">
                          <div className="camp-profile-info  tutor-desc">
                            {_.get(providerInfo, 'bio', '') !== ''
                              ? parser(
                                NurtemUtils.urlify(
                                  _.get(providerInfo, 'bio', '')
                                )
                              )
                              : ''}
                            {_.get(providerInfo, 'services', []).length > 0 && (
                              <>
                                <div className="about-title mt-3">
                                  {' '}
                                  <h6>{this.props.t('onboardingTutor14')} </h6>
                                </div>
                                <div className="camps-class-tags-container">
                                  {providerInfo &&
                                    providerInfo.services &&
                                    providerInfo.services.length > 0 &&
                                    providerInfo.services.map(
                                      (service, index) => {
                                        return (
                                          <Link
                                            key={index}
                                            to={`/search/${service.name}`}
                                          >
                                            <span className="camps-class-tags-course-banner">
                                              <ul style={{ listStyle: 'none' }}>
                                                <li>{service.name}</li>
                                              </ul>{' '}
                                            </span>
                                          </Link>
                                        )
                                      }
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div
                  ref={this.myCampspickerRef}
                  className="tutor-public-profile-container about-title mt-5 mb-2"
                >
                  <div className="sub-title  ml-2">
                    {this.props.t('availableClasses')}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 otherclsbox">
                  <div className="row">
                    {this.props.classesList && (
                      <>
                        <OtherClassesCamps
                          type={'class'}
                          otherList={this.props.classesList}
                          provider={providerDetail}
                          providerName={providerName}
                          providerType={ty}
                          thisDetailId={group_id}
                        />
                      </>
                    )}
                  </div>
                </div>
              </Layout>
            )}
          </items>

          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcViewDetails
                  style={{ marginRight: '5px', fontSize: '20px' }}
                />{' '}
                {this.props.t('notes&asg')}
              </span>
            }
            key="2"
          >
            <Notes session_details={session_details} type="classes" />
          </items>
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <LuMilestone
                  style={{
                    color: 'brown',
                    marginRight: '5px',
                    fontSize: '20px',
                  }}
                />{' '}
                {this.props.t('milestones')}
              </span>
            }
            key="3"
          >
            <Milestones session_details={session_details} type="classes" />
          </items>
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcUpload
                  style={{
                    color: 'darkslateblue',
                    marginRight: '5px',
                    fontSize: '20px',
                  }}
                />{' '}
                {this.state.upWork}
              </span>
            }
            key="4"
          >
            <div className="col-12 mb-3">
              <div className="row mb-3">
                <div className="col-6 tab-content-title">
                  {this.state.upWork}
                </div>
                <div className="col-6 d-flex justify-content-end container-upload">
                  <UploadToS3
                    cat={'classes'}
                    subcat={actionTypes.classUploads}
                    cat_id={session_id}
                    user_id={userId}
                    usertype={urlparams.usertype}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <ParticipantUploads
                    session={session_id}
                    cat={'classes'}
                    subcat={actionTypes.classUploads}
                    user_id={urlparams.userid}
                    user_type={'user'}
                    user="participant"
                  />
                </div>
              </div>
            </div>
          </items>
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FaBook
                  style={{
                    color: 'darkslateblue',
                    marginRight: '5px',
                    fontSize: '20px',
                  }}
                />{' '}
                {this.props.t('upWork1')}
              </span>
            }
            key="5"
          >
            <div className="col-12 mb-3">
              <div className="row mb-3">
                <div className="col-12">
                  <ParticipantUploads
                    session={session_id}
                    cat={'classes'}
                    subcat={actionTypes.tutorUploads}
                    user_id={providerId}
                    user_type={'provider'}
                    delete={true}
                  />
                </div>
              </div>
            </div>
          </items>

          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcFaq style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                {this.state.fqs}
              </span>
            }
            key="6"
          >
            <Faq session_details={session_details} type="classes" />
          </items>
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcRating style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                {this.state.rr}
              </span>
            }
            key="7"
          >
            <Ratings session={session_details} type="classes" />
          </items>

          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcTodoList style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                {this.state.tutorSidebarAttendanceReport}
              </span>
            }
            key="8"
          />
          <items
            tab={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <FcInfo style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                {this.state.batchInfo}
              </span>
            }
            key="9"
          >
            <UserSessionInfo
              session_details={session_details}
              session_attended={sessionAttended}
              type="classes"
            />
          </items>
          {session_details && session_details.link && (
            <items
              tab={
                <span style={{ display: 'flex', alignItems: 'center' }}>
                  <FcLink style={{ marginRight: '5px', fontSize: '20px' }} />{' '}
                  Meeting Link
                </span>
              }
              key="10"
            >
              <UserMeetingLink session_details={session_details} />
            </items>
          )}
        </Tabs>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    toggleMenu: state.search.toggleLeftMenu,

    loading: state.campsDetails.loading,
    loggedInUserDetails: state.authentication,
    DetailView: state.users.DetailView,
    userClassInfo: state.users.userClassInfo,
    singlePackViewLoading: state.campsDetails.singleCampsViewLoading,
    dependentUsers: state.userProfile.dependentUsers,
    userBaseProfile: state.userProfile.userBaseProfile,
    classesList: state.tutorPublicProfile.tutorClasses,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClassDetail: (requestParams) =>
      dispatch(userAction.getUserClassInfo(requestParams)),
    onGetCurrentLocation: (callback) =>
      dispatch(servicesActions.onGetCurrentLocation(callback)),
    onGetClassesList: (providerId) =>
      dispatch(tutorActions.getTutorClasses(providerId)),
    getDependentUsers: () => dispatch(userAction.getDependentUsers()),
    onItemAdd: () => dispatch(action.cartItemAdd()),
    setToggleMenu: (requestParams) =>
      dispatch(searchAction.common(toggleLeftMenu, requestParams)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DetailView))
