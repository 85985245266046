import React, { Component } from 'react';
import { Form } from 'antd';
import { TextInput, Button } from '../../../components/antd-components/index.tsx';
import './reset-password.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/authentication/authentication';
import * as servicesActions from '../../../store/actions/public/Services';
import axios from '../../../utils/axios';
import { withErrorHandler } from '../../../utils/handler';
import history from '../../../utils/history';
import css from '../../../utils/CSSUtility';
import { MdEmail } from "react-icons/md";

const FormItem = Form.Item;
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.loginContentPage = this.loginContentPage.bind(this);
  }

  state = {
    loginType: '',
  };

  async componentDidMount() {
    if (this.props.loginSignupTabValue) {
      this.setState({
        loginType: await this.props.loginSignupTabValue,
      });
    }
  }

  loginContentPage = async (loginType) => {
    if (loginType) {
      this.setState({
        loginType,
      });
    }
  };

  closeResetPassword = (value) => {
    if (value === 'closeIcon') {
      history.push({
        pathname: '/sign-in',
      });
    }
  };

  onFinish = (credentials, signInType) => {
    const login = { ...credentials };
    this.props.onResetPassword(login);
  };

  componentDidUpdate(preProps) {
    if (preProps.resetPasswordResponse !== this.props.resetPasswordResponse) {
      if (
        this.props.resetPasswordResponse
        && this.props.resetPasswordResponse.status === 200
      ) {
        history.push({
          pathname: '/sign-in',
        });
      }
    }
  }

  render() {
    console.log('this.state.loginType>>', this.state.loginType);

    return (
      <div className="login-page-container student-login-open">
        <div className="row login-height align-items-center justify-content-around">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div className="student-login">
              <div className="login-image-scetion">
                <img className="img-mt" src={require('../../../assets/images/forgot-img.png')} alt="img" />
                <span className="shadow" />
              </div>
              <div className="login-text">
                {this.props.t('resetPassword')}
                {' '}
                <i className="ntm-right-arrow login-icon" />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" />
          {/* Student Login modal */}

          <div className="student-login-modal ">
            <div className="row student-login-modal-inner align-items-center justify-content-around">
              <button disabled={this.props.loading} className="btn login-close" onClick={() => this.closeResetPassword('closeIcon')}><i className="ntm-cancel" /></button>
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                <div className="row">
                  <div className="col-12 mb-4">
                    <div style={css.heading1}>{this.props.t('forgotPassword')}</div>
                  </div>
                  <div className="col-12">
                    <Form onFinish={(e) => this.onFinish(e, 'user')} className="login-form">
                      <FormItem
                        name="email"
                        rules={[{
                          required: true, message: this.props.t('authenticationEmailValidationMessage'),
                        }, {
                          type: 'email',
                          message: this.props.t('authenticationEmailValidationMessage'),
                        }]}
                      >
                        <TextInput
                          addonBefore={<MdEmail />}
                          size="middle"
                          placeholder={this.props.t('yourValidEmailID')}
                        />

                      </FormItem>
                      <div className="full-width mb-3 mt-2">
                        <div className="row login-input">
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn btn-sm btn-login"
                              loading={this.props.loading}
                            >
                              {' '}
                              {this.props.t('resetNow')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Tutor Login modal */}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  resetPasswordResponse: state.authentication.resetPasswordResponse,
  loginSignupTabValue: state.authentication.loginSignupTab,
});

const mapDispatchToProps = (dispatch) => ({
  onResetPassword: (data) => dispatch(actions.resetPassword(data)),
  loginSignupTab: (credentials) => dispatch(actions.loginSignupTab(credentials)),
  onSignIn: (credentials) => dispatch(actions.signIn(credentials)),
  disableLoading: () => dispatch(actions.disableLoading()),
  onTutorSocialSignup: (credentials) => dispatch(actions.socialSignupProvider(credentials)),
  onUserSocialSignup: (credentials) => dispatch(actions.userSocialSignupProvider(credentials)),
  onSocialUserSignup: (credentials) => dispatch(actions.userSocialSignupProvider(credentials)),
  onsiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),
  onHeaderSearch: (requestParams) => dispatch(servicesActions.onHeaderSearch(requestParams)),
  searchClassCampsRouteRedirect: (requestParams) => dispatch(servicesActions.searchClassCampsRouteRedirect(requestParams)),
  onTutorSiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),
  onTutorSiderbar: (requestParams) => dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(withTranslation()(ResetPassword), axios));
