/**
 * @author ramsteyn17@gmail.com
 * @dated 31/01/2023
 * This is to display the added sessions in a class/camp for both tutor & user And to delete the added session from the tutor.
 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { notification, Tooltip } from 'antd';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { FiCheck } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import axiostoServer from '../../utils/axios';

function AddedDays(props) {
  const detail = props.sessionDetail;
  const fortype = props.type;
  const [addedDay, setAddedDay] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [confirmIndex, setConfirmIndex] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter((item) => item);
  const id = _.get(detail, 'id');

  useEffect(() => {
    const addedDay = _.get(detail, 'extra_days');
    if (addedDay !== undefined) {
      setAddedDay(addedDay);
    }
  }, [detail]);

  const delCancellDays = (value, type, id, index) => {
    if (showConfirmation && confirmIndex === index) {
      if (value !== null && type === 'del_add') {
        const del_days = [];
        if (type === 'del_add') {
          del_days.push({
            date: value.date,
            type,
            id,
            for: fortype,
          });
        }

        axiostoServer({
          method: 'post',
          url: '/del-modified-sessions',
          withCredentials: true,
          data: {
            del_days,
          },
          config: { headers: { 'Content-Type': 'application/json' } },
        })
          .then((resp) => {
            if (resp.status === 200 || resp.status === 304) {
              notification.success({
                message: resp.data.message,
              });
              setAddedDay((prevState) => prevState.filter((_, i) => i !== index));
            } else if (resp.status === 201) {
              notification.error({
                message: resp.data.message,
              });
            }
          });
      }
    } else {
      setConfirmIndex(index);
      setShowConfirmation(true);
    }
  };
  const handleCancel = () => {
    setShowConfirmation(false);
    setConfirmIndex(null);
  };

  const type = 'del_add';

  if (addedDay !== null
        && _.size(addedDay) > 0 && _.isArray(addedDay)) {
    return addedDay.map((week_name, index) => (
      <div key={week_name + index} style={{ display: 'flex', flexDirection: 'row' }}>
        <span className="session-text-bold mt-2" style={{ width: '100%', float: 'left', marginLeft: 10 }}>
          {(week_name.date)}
        </span>

        {(pathnames.includes('tutor') && !showConfirmation) ? (
          <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Delete">
            <AiOutlineDelete
              color="#ff5900"
              onClick={() => {
                setSelectedIndex(index);
                delCancellDays(week_name, type, id, index);
              }}
              className="faq-buttons-delete ml-2"
            />
          </Tooltip>
        ) : null}

        {showConfirmation && selectedIndex === index && (
        <>
          <FiCheck
            color="#52c41a"
            className="faq-buttons-delete"
            onClick={() => delCancellDays(week_name, type, id, index)}
          />
          <RxCross2
            color="#eb2f96"
            className="faq-buttons-edit"
            onClick={handleCancel}
            style={{ zIndex: 5, paddingTop: 5 }}
          />
        </>
        )}

      </div>
    ));
  }
  return (
    <div className="col-12 mb-2">
      <div className="time-schedule">
        <div className="week-day">--</div>
      </div>
    </div>
  );
}

export default AddedDays;
