import React from 'react';
import { Router, Switch } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Header from '../layout/header';
import Footer from '../layout/footer';
import LayoutContent from './content';
import PublicLayoutContent from '../public/content';
import history from '../../utils/history';
import '../../assets/fonts/nurtem/style.css';
import * as servicesActions from '../../store/actions/public/Services';
import * as routeName from '../../components/route-names';
import _ from 'lodash';

class Account extends React.Component {
  // constructor(props) {
  //   super(props);

  // }
  componentDidMount() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    if (!_.isEmpty(urlparams)) {
      if (urlparams && urlparams.type === 'guest') {
        history.push('/mycart');
      }
    } else {
      const path = _.get(this.props, 'location.pathname', _.get(window, 'location.pathname', ''));

      if ((path.indexOf(routeName.TutorPublicProfile.split(':')[0]) > -1) === false
        && (path.indexOf(routeName.search.split(':')[0]) > -1) === false
        && (path.indexOf(routeName.viewClassDetails.split(':')[0]) > -1) === false
        && (path.indexOf(routeName.viewCampDetails.split(':')[0]) > -1) === false

        && (path.indexOf(routeName.viewCompetitionDetails.split(':')[0]) > -1) === false
        && (path.indexOf(routeName.viewEventDetails.split(':')[0]) > -1) === false

        && (path.indexOf(routeName.myCart) > -1) === false
        && (path.indexOf(routeName.signIn) > -1) === false
        && (path.indexOf(routeName.guestUpdate) > -1) === false
        && (path.indexOf(routeName.userSignUp) > -1) === false
        && (path.indexOf(routeName.maintenancePage) > -1) === false
        && (_.get(this, 'props.loggedInUserDetails.isLoggedIn') === false)) {
        history.push(routeName.Landing);
      }
    }

    // if any local storage variable exits by mistakenly regarding this app. We have to remove those variables
  }

  componentDidUpdate(prevProps) {
    if (document.referrer !== window.location.href) {

    }
  }

  render() {
    return (
      <Router history={history}>
        <div className="page-container">
          <div className="header-container">
            <div className="container-fluid">
              <Header location={history.location} />
            </div>
          </div>
          {!this.props.onTutorPublicsiderbar && !this.props.onGuestsiderbar && (
            <div className="page-body-container" style={{ minHeight: 'calc(100vh - 180px)', overflowX: 'hidden' }}>
              <div className="container-fluid">
                <Switch>
                  <LayoutContent />
                </Switch>
                <PublicLayoutContent />
              </div>
            </div>
          )}
          {(this.props.onTutorPublicsiderbar || this.props.onGuestsiderbar) && (
            <div className="page-body-container MyCartSideBar" style={{ minHeight: 'calc(100vh - 180px)' }}>
              <div className="container-fluid">
                <div className="">
                  {(window.location.href.indexOf('profile') > -1) ? (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="page-sidebar">
                        {/* {this.props.onGuestsiderbar ? <GuestDashboardPageSidebar /> : ''} */}
                      </div>
                    </div>
                  ) : ''}
                  <Switch>
                    <LayoutContent />
                  </Switch>
                  <PublicLayoutContent />
                </div>
              </div>
            </div>
          )}
          <div className="footer-container">
            <div className="container-fluid">
              <Footer />
            </div>
          </div>
        </div>
      </Router>
    );
  }
}
const mapStateToProps = (state) => ({
  onTutorPublicsiderbar: state.services.onTutorPublicsiderbar,
  onGuestsiderbar: state.services.onGuestsiderbar,
  loggedInUserDetails: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  onTutorSiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),

  onTutorSiderbar: (requestParams) => dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Account);
