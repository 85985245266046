import React, { useState } from 'react';
import { Spin } from 'antd';

function ImageWithLoading({ imageUrl, defaultImageUrl }) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div className="class-image">
      {isLoading && (
      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
        <Spin spinning size="default" />
      </div>
      )}
      <div
        className="class-img-bg"
        style={{ backgroundImage: imageUrl ? `url(${imageUrl})` : `url(${defaultImageUrl})` }}
      >
        {imageUrl && (
        <img src={imageUrl} alt="Class Image" onLoad={handleImageLoad} style={{ display: 'none' }} />
        )}
        {!imageUrl && (
        <img src={defaultImageUrl} alt="Default Image" onLoad={handleImageLoad} style={{ display: 'none' }} />
        )}
      </div>
    </div>
  );
}

export default ImageWithLoading;
