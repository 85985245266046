import React from 'react';
import { Modal, Table, Space } from 'antd';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import * as actions from '../../../store/actions/tutor/camps';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as competitionActions from '../../../store/actions/tutor/competitions';
import * as commonActions from '../../../store/actions/tutor/common';
import LoadingCard from '../loading-card-request';
import _ from 'lodash';

class TutorRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      class_page: 1,
      camp_page: 1,
      competition_page: 1,
      event_page: 1,
      limit: 10,
      tutorClassRequestList: [],
      tutorCampsRequestList: [],
      tutorCompetitonsRequestList: [],
      tutorEventsRequestList: [],
      selectedRowKeys: [],
      activeTab: 'classes',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const url = window.location.search;
    const params = queryString.parse(url);
    if (params && params.class_page) {
      this.setState({
        class_page: params.class_page,
      });
    } else if (params && params.camp_page) {
      this.setState({
        camp_page: params.camp_page,
      });
    } else if (params && params.competition_page) {
      this.setState({
        competition_page: params.competition_page,
      });
    } else if (params && params.event_page) {
      this.setState({
        event_page: params.event_page,
      });
    }

    this.props.getClassesRequests({ params, sort: 'created_at.desc' });
    this.props.getCampsRequests({ ...params, sort: 'created_at.desc' });
    this.props.getCompetitionRequests({ ...params, sort: 'created_at.desc' });
    this.props.getEventRequests({ ...params, sort: 'created_at.desc' });
    this.props.getRequestsCounts();
  }

  handleRequestAction = (record, action) => {
    const content = {
      id: record.key,
      user: record.user_id,
      status: action === 'accept' ? 1 : 2,
    };

    if (record.dependent_user) {
      content.dependent_user = record.dependent_user;
    }

    const actionMap = {
      classes: this.props.ClassesRequestAction,
      camps: this.props.CampsRequestAction,
      competitions: this.props.CompetitionRequestAction,
      events: this.props.EventRequestAction,
    };

    const actionFunction = actionMap[record.type.toLowerCase()];

    Modal.confirm({
      title: this.props.t('areSure'),
      content: action === 'accept' ? this.props.t('acceptNotice') : this.props.t('acceptNotice2'),
      okText: this.props.t('confirm'),
      onOk: () => actionFunction(content, this.handleResponse),
      cancelText: this.props.t('cancel'),
    });
  };

  handleResponse = (response) => {
    if (response.status === 200) {
      this.refreshAllRequests();
      this.props.getRequestsCounts();
    } else {
      Modal.error({
        title: this.props.t('Error'),
        content: _.get(response, 'data.message', this.props.t('An error occurred')),
      });
    }
  };

  refreshAllRequests = () => {
    const params = {
      page: this.getCurrentPage(),
      limit: this.state.limit,
      sort: 'created_at.desc',
    };

    this.props.getClassesRequests(params);
    this.props.getCampsRequests(params);
    this.props.getCompetitionRequests(params);
    this.props.getEventRequests(params);
  }

  getDataSource = (items, itemKey, type) => {
    console.log("items>>>>>", items)
    console.log("items>>>>>", itemKey)
    const typeMap = {
      class: 'class',
      Camps: 'camp',
      Competitions: 'competition'
    };

    const typekey = typeMap[itemKey] || 'event';
    return (items || []).map(item => ({
      ...item,
      key: item.id,
      user: _.isEmpty(item.dependent_user)
        ? item.user && `${item.user.firstname} ${_.get(item, 'user.lastname', '')}`
        : _.startCase(item.dependent_user.firstname),
      requestedItem: _.get(item, `${typekey}_details.session_name`),
      type: _.startCase(type),
      user_id: item.user && item.user.id,
      dependent_user: item.dependent_user && item.dependent_user.id,
    }));
  }

  isLoading = () => {
    return this.props.enableRequestLoading ||
      this.props.enableCampsRequestLoading ||
      this.props.enableCompetitionRequestLoading ||
      this.props.enableEventRequestLoading;
  }

  getTotalCount = () => {
    return _.sum([
      _.get(this.props.tutorClassRequests, 'meta.total_count', 0),
      _.get(this.props.tutorCampsRequests, 'meta.total_count', 0),
      _.get(this.props.tutorCompetitionRequests, 'meta.total_count', 0),
      _.get(this.props.tutorEventRequests, 'meta.total_count', 0),
    ]);
  }

  getCurrentPage = () => {
    return Math.max(
      parseInt(this.state.class_page),
      parseInt(this.state.camp_page),
      parseInt(this.state.competition_page),
      parseInt(this.state.event_page)
    );
  }

  pageChange = (page, pageSize) => {
    window.scrollTo(0, 0);
    this.setState({
      class_page: page,
      camp_page: page,
      competition_page: page,
      event_page: page,
    });

    const params = {
      page,
      limit: this.state.limit,
      sort: 'created_at.desc',
    };

    this.props.getClassesRequests(params);
    this.props.getCampsRequests(params);
    this.props.getCompetitionRequests(params);
    this.props.getEventRequests(params);
  }

  render() {
    const unifiedColumns = [
      {
        title: this.props.t('students'),
        dataIndex: 'user',
        key: 'user',
        width: '25%',
      },
      {
        title: this.props.t('batchLabel2'),
        dataIndex: 'requestedItem',
        key: 'requestedItem',
        width: '25%',
      },
      {
        title: this.props.t('serviceType'),
        dataIndex: 'type',
        key: 'type',
        width: '20%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '30%',
        render: (text, record) => (
          <Space size="middle">
            <button
              onClick={() => this.handleRequestAction(record, 'accept')}
              className="btn btn-accept mr-1"
            >
              {this.props.t('accept2')}
            </button>
            <button
              onClick={() => this.handleRequestAction(record, 'reject')}
              className="btn btn-reject"
            >
              {this.props.t('reject2')}
            </button>
          </Space>
        ),
      },
    ];

    const unifiedDataSource = [
      ...this.getDataSource(this.props.tutorClassRequests.items, 'class', 'classes'),
      ...this.getDataSource(this.props.tutorCampsRequests.items, 'Camps', 'camps'),
      ...this.getDataSource(this.props.tutorCompetitionRequests.items, 'Competitions', 'competitions'),
      ...this.getDataSource(this.props.tutorEventRequests.items, 'Events', 'events'),
    ];

    return (
      <div className="row">
        <div className="col-12 requestList">
          <div className="nurtem-table" style={{ marginBottom: 20 }}>
            {this.isLoading() ? (
              <LoadingCard mystyle="card-tab-section" />
            ) : (
              <>
                <Table
                  loading={this.isLoading()}
                  dataSource={unifiedDataSource}
                  columns={unifiedColumns}
                  pagination={{
                    pageSize: this.state.limit,
                    onChange: this.pageChange,
                    total: this.getTotalCount(),
                    hideOnSinglePage: true,
                    showSizeChanger: false,
                    current: this.getCurrentPage(),
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorCompetitionRequests: state.competitionsDetails.tutorCompetitionRequests,
  tutorCompetitionRequestAction: state.competitionsDetails.tutorCompetitionRequestAction,
  tutorEventRequests: state.eventsDetails.tutorEventRequests,
  tutorEventRequestAction: state.eventsDetails.tutorEventRequestAction,
  tutorClassRequests: state.classesDetails.tutorClassRequests,
  tutorClassRequestAction: state.classesDetails.tutorClassRequestAction,
  tutorCampsRequests: state.campsDetails.tutorCampsRequests,
  tutorCampsRequestAction: state.campsDetails.tutorCampsRequestAction,
  enableRequestLoading: state.classesDetails.enableRequestLoading,
  enableCampsRequestLoading: state.campsDetails.enableCampsRequestLoading,
  milestoneclassLoading: state.classesDetails.milestoneLoading,
  milestonecampsLoading: state.campsDetails.milestoneLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getClassesRequests: (requestParams) => dispatch(classActions.getClassesRequests({ ...requestParams, limit: 10 })),
  ClassesRequestAction: (data, callback) => dispatch(classActions.ClassesRequestAction(data, callback)),
  getCampsRequests: (params) => dispatch(actions.getCampsRequests({ ...params, limit: 10 })),
  CampsRequestAction: (data, callback) => dispatch(actions.CampsRequestAction(data, callback)),
  getCompetitionRequests: (requestParams) => dispatch(competitionActions.getCompetitionRequests({ ...requestParams, limit: 10 })),
  CompetitionRequestAction: (data, callback) => dispatch(competitionActions.CompetitionRequestAction(data, callback)),
  getEventRequests: (requestParams) => dispatch(competitionActions.getEventRequests({ ...requestParams, limit: 10 })),
  EventRequestAction: (data, callback) => dispatch(competitionActions.EventRequestAction(data, callback)),
  getRequestsCounts: (data, callback) => dispatch(commonActions.onGetRequestCount(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TutorRequest));