import * as actionTypes from '../../actions/public/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: {
    contentLoading: false,
    filterLoading: false,
  },
  campsData: {},
  campsServices: {},
  onOtherViewCamps: {},
};
const campsServices = (state, action) => ({ ...state, campsServices: action.success });
const campsFoundSuccess = (state, action) => ({ ...state, campsData: action.success });

const onOtherViewCamps = (state, action) => ({ ...state, onOtherViewCamps: action.success });

const enableContentLoading = (state, action) => updateObject(state, action.loading);
const disableContentLoading = (state, action) => updateObject(state, action.loading);
const enableFilterLoading = (state, action) => updateObject(state, action.loading);
const disableFilterLoading = (state, action) => updateObject(state, action.loading);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.campsFoundSuccess:
      return campsFoundSuccess(state, action);
    case actionTypes.enableContentLoading:
      return enableContentLoading(state, action);
    case actionTypes.disableContentLoading:
      return disableContentLoading(state, action);
    case actionTypes.enableFilterLoading:
      return enableFilterLoading(state, action);
    case actionTypes.disableFilterLoading:
      return disableFilterLoading(state, action);
    case actionTypes.onOtherViewCamps:
      return onOtherViewCamps(state, action);
    case actionTypes.campsServices:
      return campsServices(state, action);
    default:
      return state;
  }
};

export default reducer;
