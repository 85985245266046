import tutorAction from './tutor';
import servicesAction from './services';
import campsAction from './camps';
import discountsAction from './discounts';
import classesAction from './classes';
import userActions from './users';
import competitionActions from './competitions';
import eventActions from './events';

export const generalAction = {
  tutor: tutorAction,
  services: servicesAction,
  camps: campsAction,
  discounts: discountsAction,
  classes: classesAction,
  user: userActions,
  events: eventActions,
  competitions: competitionActions,
};
export default generalAction;
