import React from "react";
// import PropTypes from 'prop-types';

function TruncatedText({ val }) {
  const maxLines = 3;
  const lineHeight = 2;
  const maxHeight = `${maxLines * lineHeight}em`;
  const style = {
    maxHeight,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    wordWrap: "break-word",
    WebkitBoxOrient: "vertical",
    maxWidth: "400px",
    WebkitLineClamp: maxLines,
  };

  return <div style={style}>{val}</div>;
}

// TruncatedText.propTypes = {
//   val: PropTypes.string.isRequired,
// };

export default TruncatedText;
