import React from 'react';
import { Modal, Button } from 'antd';
import _ from 'lodash';
import './style.css';

export function CanSwitchTabModal(WrappedComponent) {
  class mainComponent extends React.Component {
    state = {
      visible: false,
      componentParams: {},
      callback: () => { },
    };

    showModal = (componentParams, callback = () => { }) => {
      this.setState((previousState) => ({
        ...previousState,
        visible: true,
        componentParams,
        callback,
      }));
    };

    handleOk = (e) => {
      this.state.callback(true);
      this.setState({
        visible: false,
      });
    };

    handleCancel = (e) => {
      this.state.callback(false);
      this.setState({
        visible: false,
      });
    };

    closeModal = () => {
      this.setState({
        visible: false,
      });
    };

    modalUI = () => (
      <Modal
        className="student-view-notes-modal"
        title={_.get(this, 'state.componentParams.title', 'title')}
        maskClosable={false}
        centered
        open={this.state.visible}
        closable={false}
        footer={[
          <Button className="btn-cancel" key="back" onClick={this.handleCancel}>Cancel</Button>,
          <Button
            className="btn-submit"
            key="submit"
            type="primary"
            onClick={this.handleOk}
          >
            Yes
          </Button>,
        ]}
      >
        <div className="nurtem-modal-container p-4">
          {_.get(this, 'state.componentParams.content', 'modal content will goes here')}
        </div>
      </Modal>
    );

    render() {
      return (
        <>
          {this.modalUI()}
          <WrappedComponent {...this.props} {...{ canSwitchTabModal: { showModal: this.showModal, closeModal: this.closeModal } }} />
        </>
      );
    }
  }

  return mainComponent;
}
