import React from 'react';
import _ from 'lodash';

export default class SessionDetailsLoader extends React.Component {
  render() {
    const NoOfTimes = this.props.cards ? _.range(this.props.cards) : _.range(6);
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className={css}>
        <div className="row">
          {Array.isArray(NoOfTimes) && NoOfTimes.map((_, i) => (
            <div key={i} className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mb-4">
              <div className="nurtem-dashboard-card">
                <div className="ph-col-12">
                  <div className="ph-row">
                    <div className="ph-col-12 big" />
                  </div>
                  <div className="ph-row mt-3">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                  <div className="ph-row mt-3">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                  <div className="ph-row mt-2">
                    <div className="ph-col-4 empty small" />
                    <div className="ph-col-2 small" />
                    <div className="ph-col-4 empty small" />
                    <div className="ph-col-2 small" />
                  </div>
                  <div className="ph-row mt-2">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                  <div className="ph-row mt-2">
                    <div className="ph-col-2 empty" />
                    <div className="ph-col-8 big" />
                    <div className="ph-col-2 empty" />
                  </div>
                  <div className="ph-row mt-2">
                    <div className="ph-col-12 small" />
                  </div>
                  <div className="ph-row">
                    <div className="ph-col-4 big" />
                    <div className="ph-col-4 empty" />
                    <div className="ph-col-4 big" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
