import React, { FC } from 'react';
import { Table } from 'antd';
import { TableProps, ColumnType } from 'antd/lib/table';

interface DataType {
  _id: string;
  [key: string]: any;
}

interface CommonAntdTableProps
  extends Omit<TableProps<DataType>, 'columns' | 'dataSource'> {
  dataSource: DataType[];
  columns: ColumnType<DataType>[];
}

export const CommonAntdTable: FC<CommonAntdTableProps> = ({
  dataSource,
  columns,
  pagination,
  loading,
  onChange,
}) => {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      onChange={onChange}
      pagination={pagination}
      loading={loading}
      rowKey={(record) => record._id}
      className="shadow-md"
      // bordered
    />
  );
};
