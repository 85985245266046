const S3_URL = 'https://nurtem-s3.s3.us-west-2.amazonaws.com';

// export const XLSX = S3_URL + '/Assets/Enroll_multiple_user_template.xlsx';
export const OBXLSX = `${S3_URL}/Assets/onboard_tutor.xlsx`;
export const OBCLASSXLSX = `${S3_URL}/Assets/onboard_class.xlsx`;
export const OBUSERXLSX = `${S3_URL}/Assets/onboard_user.xlsx`;
export const UserPng = `${S3_URL}/Assets/student-login-image.png`;
export const TUTORPNG = `${S3_URL}/Assets/tutor-login-image.png`;
export const DEFAULTUSER = `${S3_URL}/Assets/default-user.png`;
export const LOGO = `${S3_URL}/Assets/logo.png`;
export const CLASSPNG = `${S3_URL}/Assets/nurtemnobg.png`;
export const BOOKINGVIDEO = `${S3_URL}/Assets/booking.mp4`;
export const algebra = 'https://nurtem-s3.s3.us-west-2.amazonaws.com/algebra.jpg';
export const chess = 'https://nurtem-s3.s3.us-west-2.amazonaws.com/chess.jpg';
export const maths = 'https://nurtem-s3.s3.us-west-2.amazonaws.com/maths.jpg';
export const speaking = 'https://nurtem-s3.s3.us-west-2.amazonaws.com/speaking.jpg';
export const swim = 'https://nurtem-s3.s3.us-west-2.amazonaws.com/swim.jpg';
export const python ='https://nurtem-s3.s3.us-west-2.amazonaws.com/python.jpg';