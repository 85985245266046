import React, { useEffect, useState } from 'react';
import {
  Form, Button, notification, Input,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import generalInfoFormMeta from './form-meta';
import * as commomAction from '../../../../../store/actions/common';

function SocialLink(props) {
  const [formMeta] = useState(generalInfoFormMeta);
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const formProperties = _.pick(props.tutorBaseProfile, [
      'id',
      'zoom_id',
      'skype_id',
      'facebook_id',
      'whatsapp_number',
    ]);

    form.setFieldsValue(formProperties);
    checkIfAnyFieldFilled(formProperties);
  }, [props.tutorBaseProfile]);

  const checkIfAnyFieldFilled = (values) => {
    const requiredFields = ['zoom_id', 'skype_id', 'facebook_id', 'whatsapp_number'];
    const anyFieldFilled = requiredFields.some(field => !!values[field]);
    setIsButtonDisabled(!anyFieldFilled);
  };

  const onValuesChange = (_, allValues) => {
    checkIfAnyFieldFilled(allValues);
  };

  const onFocus = () => {
    setIsButtonDisabled(false);
  };

  const onFinishSocialList = () => {
    console.log('onSaveChanges>>>>', form.getFieldValue());

    const profileDetails = form.getFieldValue();
    profileDetails.update_progress = 1;

    commomAction.updateProvidersPartialProfile(profileDetails, (res) => {
      if (res.status === 200) {
        notification.success({
          description: 'Provider social details has been updated.',
          message: 'Success',
        });
      } else {
        notification.warning({
          description: 'Provider social details has not been updated.',
          message: 'Failed to update',
        });
      }
    });
  };

  return (
    <div className="row" style={{ margin: 0 }}>
      <label className="filter-group-title mt-3 ml-3">{props.t('socialLink')}</label>
      <div className="col-12 mb-3">
        <Form
          form={form}
          onFinish={onFinishSocialList}
          onValuesChange={onValuesChange}
          scrollToFirstError
        >
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <label className="filter-group-title mb-3">{props.t('baseProfile12')}</label>
              <div className="full-width">
                <Form.Item {..._.get(formMeta, 'service_provider_zoom.form_item', {})}>
                  <Input {..._.get(formMeta, 'service_provider_zoom.input', {})} onFocus={onFocus} />
                </Form.Item>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <label className="filter-group-title mb-3">
                {props.t('baseProfile13')}
                {' '}
              </label>
              <div className="full-width">
                <Form.Item {..._.get(formMeta, 'service_provider_skype.form_item', {})}>
                  <Input {..._.get(formMeta, 'service_provider_skype.input', {})} onFocus={onFocus} />
                </Form.Item>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <label className="filter-group-title mb-3">
                {props.t('baseProfile14')}
                {' '}
              </label>
              <div className="full-width">
                <Form.Item {..._.get(formMeta, 'service_provider_facebook.form_item', {})}>
                  <Input {..._.get(formMeta, 'service_provider_facebook.input', {})} onFocus={onFocus} />
                </Form.Item>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <label className="filter-group-title mb-3">{props.t('baseProfile15')}</label>
              <div className="full-width">
                <Form.Item {..._.get(formMeta, 'service_provider_whatsapp.form_item', {})}>
                  <Input {..._.get(formMeta, 'service_provider_whatsapp.input', {})} onFocus={onFocus} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="full-width button-group-section mt-3">
            <Form.Item>
              <Button type="primary" htmlType="submit" className="btn-filter" disabled={isButtonDisabled}>Save Changes</Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SocialLink));
