import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { notification } from 'antd';
import rootReducer from './reducers/rootReducer';
import route from '../components/route-names/declaringRouteNames.json';
import { isPathFound } from '../components/Other/routeRestriction';

let lock = false;
const customMiddleWare = (store) => (next) => (action) => {
  if (typeof action === 'object') {
    if (
      typeof Object.values(action)[1] === 'undefined'
      || (Object.values(action)[1] !== null
        && Object.values(action)[1].session_expired)
    ) {
      lock = true;
      if (Object.values(action)[1] && Object.values(action)[1].message) {
        const message_args = {
          duration: 5,
          message: `${Object.values(action)[1].message}`,
          onClose() {
            window.location.reload();
          },
        };
        notification.error(message_args);
      }

      return;
    }
  }
  if (!lock || isPathFound(route.PUBLIC)) {
    next(action);
  }
};
  
export default function configureStore(initialState = {}) {

  console.log("process.env.NODE_ENV>>>", process.env.NODE_ENV)
  
  const composeEnhancers = process.env.NODE_ENV === 'development'
  ? composeWithDevTools
  : compose;


  const middlewares = [customMiddleWare, thunk];
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
}
