import route from './declaringRouteNames.json'

export const {
  competitions,
  events,
  updateCompetition,
  updateEvent,
  competitionsView,
  eventsView,
  SessionViewCompetition,
  SessionViewEvent,
} = route.COMPETITION

// App Route
export const {
  signIn,
  resetPassword,
  changePassword,
  logout,
  userSignUp,
  tutorSignUp,
} = route.ACCOUNT

// Tutor Routes
export const {
  tutorDashboard,
  TutorBaseProfileStep1,
  TutorBaseProfileStep2,
  TutorBaseProfileStep3,
  AttendancesView,
  tutorClassView,
  tutorCamps,
  tutorCampView,
  SessionView,
  SessionViewClass,
  StudentView,
  TutorDetailsView,
  viewAllRequest,
  viewAllMessages,
  tutorClasses,
  tutorSidebar,
  ServiceView,
  CouponsManagementList,
  CreateCoupons,
  EditCoupons,
  TutorUpdateClass,
  TutorUpdateCamp,
  UpdateEvent,
  TutorAttendance,
  TutorSessionAttendance,
  TutorCompetitionAttendance,
  tutorEditProfile,
  tutorStudentList,
  tutorOnboardingWizard,
  tutorEditProfileOthers,
  Tutorcalendar,
  myCoupons,
  Tutorsetting,
  TutorAccount,
  TutorChangePassword,
  TutorList,
  updateProfile,
  tutorMycart,
  attendanceReport,
  attendanceReportFull,
  paymentReport,
  paymentReportFull,
  payments,
  businessmenu
} = route.TUTOR

// User Routes
export const {
  userDashboard,
  message,
  classView,
  tutorViewDetail,
  myCart,
  myWishList,
  myWaitList,
  viewClassDetails,
  viewCampDetails,
  viewCompetitionDetails,
  viewEventDetails,
  userClassView,
  userCompetitionView,
  userEventView,
  userClasses,
  userCampView,
  userCamps,
  userCompetitions,
  userEvents,
  userProfile,
  userCalendar,
  usersettings,
  paymentReportUser,
  attendanceReportUser,
  userInvoice,
  guestUpdate,
  userpayment,
  attendanceReportUserFull,
  userViewBatch,
} = route.USER

// Public Route
export const {
  home,
  search,
  searchClasses,
  searchCamps,
  services,
  searchServices,
  classesSearch,
  TutorPublicProfile,
  termsAndConditions,
  aboutus,
  FaQ,
  UserVerify,
  UsersEmailVerify,
  PrivacyPolicy,
  CookiePolicy,
  ContactUs,
  pageNotFound,
  maintenancePage,
  Landing,
  batchDetails,
} = route.PUBLIC
