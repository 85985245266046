import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Linkwrap from '../../tutor/component/linkwrap';

function UserMeetingLink(props) {
  const { session_details } = props;
  console.log('sessiondetails', session_details);
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      <div className="row py-3" style={{ width: '100%', gap: '20%' }}>
        <div style={{ fontSize: 14, marginLeft: 25 }}>
          Meeting Link :
          {' '}
          <Linkwrap link={session_details.link} />
        </div>
        <div style={{ fontSize: 14, marginLeft: 25 }}>
          Meeting Code:
          {' '}
          {session_details.code}
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(UserMeetingLink);
