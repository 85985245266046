export const enableValidateAddClassStep1 = 'enableValidateAddClassStep1';
export const disableValidateAddClassStep1 = 'disableValidateAddClassStep1';
export const enableValidateAddClassStep2 = 'enableValidateAddClassStep2';
export const disableValidateAddClassStep2 = 'disableValidateAddClassStep2';
export const saveToLocalStorage = 'saveToLocalStorage';
export const classCreatedSuccessfully = 'saveToLocalStorage';
export const nextPage = 'nextPage';
export const previousPage = 'previousPage';
export const resetPage = 'resetPage';
export const addClassEnableLoading = 'addClassEnableLoading';
export const faqLoading = 'faqLoading';
export const addClassDisableLoading = 'addClassDisableLoading';
export const addSessionDisableLoading = 'addSessionDisableLoading';
export const beforeFetchingServices = 'beforeFetchingServices';
export const afterFetchingServices = 'afterFetchingServices';
export const beforeFetchingServicesValues = 'beforeFetchingServicesValues';
export const afterFetchingServicesValues = 'afterFetchingServicesValues';
export const beforeFetchingLocations = 'beforeFetchingLocations';
export const afterFetchingLocations = 'afterFetchingLocations';
export const setClassData = 'setClassData';
export const setClassPhotosData = 'setClassPhotosData';
export const tutorClassDetailsFoundSuccess = 'tutorClassDetailsFoundSuccess';
export const classSessionModifySuccess = 'classSessionModifySuccess';
