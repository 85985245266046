import axios from '../../../utils/axios';

export const commonCall = (payload, type) => ({
  type,
  payload,
});

export const getApiCall = (type, url, requestParams = {}) => (dispatch) => {
  axios
    .get(url, {
      params: requestParams,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(commonCall(response.data, type));
      }
    })
    .catch(() => {
    });
};
