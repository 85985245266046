import React from 'react';
import { Modal, Table, Space, Badge } from 'antd';
import './tutor-request.css';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import * as actions from '../../../store/actions/tutor/camps';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as competitionActions from '../../../store/actions/tutor/competitions';
import * as commonActions from '../../../store/actions/tutor/common';
import AcceptAllBtn from './component/acceptAllBtn';
import LoadingCard from '../loading-card-request';
import _ from 'lodash';

class TutorRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      class_page: 1,
      camp_page: 1,
      competition_page: 1,
      event_page: 1,
      limit: 10,
      tutorClassRequestList: [],
      tutorCampsRequestList: [],
      tutorCompetitonsRequestList: [],
      tutorEventsRequestList: [],
      selectedRowKeys: [],
      activeTab: 'classes',
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const url = window.location.search;
    const params = queryString.parse(url);
    if (params && params.class_page) {
      this.setState({
        class_page: params.class_page,
      });
    } else if (params && params.camp_page) {
      this.setState({
        camp_page: params.camp_page,
      });
    } else if (params && params.competition_page) {
      this.setState({
        competition_page: params.competition_page,
      });
    } else if (params && params.event_page) {
      this.setState({
        event_page: params.event_page,
      });
    }

    this.props.getClassesRequests({ params, sort: 'created_at.desc' });
    this.props.getCampsRequests({ ...params, sort: 'created_at.desc' });
    this.props.getCompetitionRequests({ ...params, sort: 'created_at.desc' });
    this.props.getEventRequests({ ...params, sort: 'created_at.desc' });
    this.props.getRequestsCounts();
  }

  async componentDidUpdate(prevprops) {
    const campSort = {
      sort: 'created_at.desc',
    };
    if (
      prevprops.tutorCampsRequestAction !== this.props.tutorCampsRequestAction
    ) {
      if (
        this.props.tutorCampsRequestAction
        && this.props.tutorCampsRequestAction.status === 200
        && this.props.tutorCampsRequestAction.data
        && this.props.tutorCampsRequestAction.data.message
      ) {
        this.props.getCampsRequests(campSort);
      }
    }

    if (
      prevprops.tutorClassRequestAction !== this.props.tutorClassRequestAction
    ) {
      if (
        this.props.tutorClassRequestAction
        && this.props.tutorClassRequestAction.status === 200
      ) {
        this.props.getClassesRequests(campSort);
      }
    }

    if (
      prevprops.tutorCompetitionRequestAction
      !== this.props.tutorCompetitionRequestAction
    ) {
      if (
        this.props.tutorCompetitionRequestAction
        && this.props.tutorCompetitionRequestAction.status === 200
      ) {
        this.props.getCompetitionRequests(campSort);
      }
    }

    if (
      prevprops.tutorEventRequestAction
      !== this.props.tutorEventRequestAction
    ) {
      if (
        this.props.tutorEventRequestAction
        && this.props.tutorEventRequestAction.status === 200
      ) {
        this.props.getEventRequests(campSort);
      }
    }

    if (
      prevprops.tutorClassRequests !== this.props.tutorClassRequests
      && this.props.tutorClassRequests.items
      && this.props.tutorClassRequests.items.length > 0
      && _.size(_.get(this, 'props.tutorClassRequests.items')) > 0
    ) {
      this.setState((prevState) => ({
        tutorClassRequestList: [
          ...prevState.tutorClassRequestList,
          ...this.props.tutorClassRequests.items,
        ],
      }));
    }

    if (
      prevprops.tutorCampsRequests !== this.props.tutorCampsRequests
      && this.props.tutorCampsRequests.items
      && this.props.tutorCampsRequests.items.length > 0
      && _.size(_.get(this, 'props.tutorCampsRequests.items')) > 0
    ) {
      this.setState((prevState) => ({
        tutorCampsRequestList: [
          ...prevState.tutorCampsRequestList,
          ...this.props.tutorCampsRequests.items,
        ],
      }));
    }

    if (
      prevprops.tutorCompetitionRequests
      !== this.props.tutorCompetitionRequests
      && this.props.tutorCompetitionRequests.items
      && this.props.tutorCompetitionRequests.items.length > 0
      && _.size(_.get(this, 'props.tutorCompetitionRequests.items')) > 0
    ) {
      this.setState((prevState) => ({
        tutorCompetitonsRequestList: [
          ...this.props.tutorCompetitionRequests.items,
        ],
      }));
    }

    if (
      prevprops.tutorEventRequests
      !== this.props.tutorEventRequests
      && this.props.tutorEventRequests.items
      && this.props.tutorEventRequests.items.length > 0
      && _.size(_.get(this, 'props.tutorEventRequests.items')) > 0
    ) {
      this.setState((prevState) => ({
        tutorEventsRequestList: [
          ...this.props.tutorEventRequests.items,
        ],
      }));
    }
  }

  onAcceptUser = (value, type, forType) => {
    const thisdata = this;

    const selectedCount = this.state.selectedRowKeys.length;
    const selectedkeys = this.state.selectedRowKeys;
    if (value && value.type === 'classes') {
      const content = {
        id: value.key,
        user: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }
      if (_.get(value, 'class_details.previous_provider')) {
        content.work_for_provider = _.get(value, 'class_details.provider');
      }

      Modal.confirm({
        title: this.props.t('areSure'),
        content:
          type === 'userAccept'
            ? this.props.t('acceptNotice')
            : this.props.t('acceptNotice2'),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.ClassesRequestAction(content, (response) => {
            if (_.get(response, 'status') === 200) {
              if (selectedCount) {
                thisdata.setState({
                  selectedRowKeys: _.filter(
                    selectedkeys,
                    (key) => key !== _.get(value, 'key'),
                  ),
                });
              }
              const arrayFilter = [];
              _.filter(
                _.get(thisdata, 'state.tutorClassRequestList'),
                (o) => {
                  console.log('ooooooo', o);
                  if (_.get(o, 'id') !== _.get(value, 'key')) {
                    arrayFilter.push(o);
                  }
                },
              );
              thisdata.setState({
                tutorClassRequestList: arrayFilter,
              });

              // if (_.get(response, 'data.message')) {
              //     notification.success({
              //         message: 'Success',
              //         description: _.get(response, 'data.message')
              //     })
              // }
              // let seats_full_days =
              //   _.size(_.get(value, "class_details.seats_full_days", [])) > 0
              //     ? [
              //         ...value.class_details.seats_full_days,
              //         value.booking_dates[0],
              //       ]
              //     : [value.booking_dates[0]];
              // thisdata.props.ClassesSlotFullAction({
              //   id: _.get(value, "class_details.id"),
              //   seats_full_days: _.uniq(seats_full_days),
              // });

              // thisdata.props.history.push("/tutor/view-all-request");
            }
          });
        },
        cancelButtonProps: {
          disabled: false,
        },
        confirmLoading: true,
        cancelText: this.props.t('cancel'),
      });
    } else if (value && value.type === 'competitions') {
      const content = {
        id: value.key,
        user: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }

      Modal.confirm({
        title: this.props.t('are you sure?'),
        content:
          type === 'userAccept'
            ? this.props.t('acceptNotice')
            : this.props.t('acceptNotice2'),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.CompetitionRequestAction(content, (response) => {
            if (_.get(response, 'status') === 200) {
              const arrayFilter = [];
              _.filter(
                _.get(thisdata, 'state.tutorCompetitonsRequestList'),
                (o) => {
                  console.log('ooooooo', o);
                  if (_.get(o, 'id') !== _.get(value, 'key')) {
                    arrayFilter.push(o);
                  }
                },
              );
              thisdata.setState({
                tutorCompetitonsRequestList: arrayFilter,
              });
              if (selectedCount) {
                thisdata.setState({
                  selectedRowKeys: _.filter(
                    selectedkeys,
                    (key) => key !== _.get(value, 'key'),
                  ),
                });
              }

              // if (_.get(response, 'data.message')) {
              //     notification.success({
              //         message: 'Success',
              //         description: _.get(response, 'data.message')
              //     })
              // }

              thisdata.props.history.push('/tutor/view-all-request');
            }
          });
        },
        cancelButtonProps: {
          disabled: false,
        },
        confirmLoading: true,
        cancelText: this.props.t('cancel'),
      });
    } else if (value && value.type === 'events') {
      const content = {
        id: value.key,
        user: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }

      Modal.confirm({
        title: this.props.t('are you sure?'),
        content:
          type === 'userAccept'
            ? this.props.t('acceptNotice')
            : this.props.t('acceptNotice2'),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.EventRequestAction(content, (response) => {
            if (_.get(response, 'status') === 200) {
              const arrayFilter = [];
              _.filter(
                _.get(thisdata, 'state.tutorEventsRequestList'),
                (o) => {
                  console.log('ooooooo', o);
                  if (_.get(o, 'id') !== _.get(value, 'key')) {
                    arrayFilter.push(o);
                  }
                },
              );
              thisdata.setState({
                tutorEventsRequestList: arrayFilter,
              });
              if (selectedCount) {
                thisdata.setState({
                  selectedRowKeys: _.filter(
                    selectedkeys,
                    (key) => key !== _.get(value, 'key'),
                  ),
                });
              }

              // if (_.get(response, 'data.message')) {
              //     notification.success({
              //         message: 'Success',
              //         description: _.get(response, 'data.message')
              //     })
              // }

              thisdata.props.history.push('/tutor/view-all-request');
            }
          });
        },
        cancelButtonProps: {
          disabled: false,
        },
        confirmLoading: true,
        cancelText: this.props.t('cancel'),
      });
    } else {
      const content = {
        id: value.key,
        user: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }
      Modal.confirm({
        title: this.props.t('are you sure?'),
        content:
          type === 'userAccept'
            ? this.props.t('acceptNotice')
            : this.props.t('acceptNotice2'),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.CampsRequestAction(content, (response) => {
            /// /console.log('response', response);
            if (_.get(response, 'status') === 200) {
              const arrayFilter = [];
              _.filter(
                _.get(thisdata, 'state.tutorCampsRequestList'),
                (o) => {
                  /// ///console.log('ooooooo', o);
                  if (_.get(o, 'id') !== _.get(value, 'key')) {
                    arrayFilter.push(o);
                  }
                },
              );
              thisdata.setState({
                tutorCampsRequestList: arrayFilter,
              });
              if (selectedCount) {
                thisdata.setState({
                  selectedRowKeys: _.filter(
                    selectedkeys,
                    (key) => key !== _.get(value, 'key'),
                  ),
                });
              }
            }
            // if (_.get(response, 'status') === 200) {
            //     if (_.get(response, 'data.message')) {
            //         notification.success({
            //             message: 'Success',
            //             description: _.get(response, 'data.message')
            //         })
            //     }
            // }
          });
        },
        confirmLoading: thisdata.props.milestonecampsLoading,
        cancelButtonProps: {
          disabled: false,
        },
        cancelText: this.props.t('cancel'),
      });
    }
  };

  tutorRoutes = () => {
    history.push({
      pathname: routeName.tutorClassView,
    });
  };

  pageChange = async (page, pageSize, type) => {
    /// /console.log('pageChangepageChange', page);
    window.scrollTo(0, 0);
    this.setState({
      camp_page: page,
    });
    await this.setState({
      [type]: page,
    });
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const requestParams = { ...params, sort: 'created_at.desc' };
    requestParams[type] = page;
    const queryUrl = queryString.stringify({
      ...requestParams,
    });
    history.push({
      pathname: `${routeName.viewAllRequest}`,
      search: `?${queryUrl}`,
    });
    if (type === 'class_page') {
      requestParams.page = requestParams.class_page;
      delete requestParams.class_page;
      this.props.getClassesRequests(requestParams);
    } else {
      requestParams.page = page;
      delete requestParams.camp_page;
      this.props.getCampsRequests(requestParams);
    }
  };

  acceptAll = (props) => {
    const content = {
      id: this.state.selectedRowKeys,
      status: 1,
    };
    this.props.CampsRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  rejectAll = (props) => {
    this.setState({ loading: true });
    const content = {
      id: this.state.selectedRowKeys,
      status: 2,
    };
    this.props.CampsRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  acceptAllCompetition = (props) => {
    const content = {
      id: this.state.selectedRowKeys,
      status: 1,
    };
    this.props.CompetitionRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };
  acceptAllEvent = (props) => {
    const content = {
      id: this.state.selectedRowKeys,
      status: 1,
    };
    this.props.EventRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  rejectAllCompetition = (props) => {
    this.setState({ loading: true });
    const content = {
      id: this.state.selectedRowKeys,
      status: 2,
    };
    this.props.CompetitionRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  rejectAllEvent = (props) => {
    this.setState({ loading: true });
    const content = {
      id: this.state.selectedRowKeys,
      status: 2,
    };
    this.props.EventRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  acceptAllClass = (props) => {
    const content = {
      id: this.state.selectedRowKeys,
      status: 1,
    };
    this.props.ClassesRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  rejectAllClass = (props) => {
    this.setState({ loading: true });
    const content = {
      id: this.state.selectedRowKeys,
      status: 2,
    };
    this.props.ClassesRequestAction(content, (response) => {
      // console.log('response======', response);
      if (response.status === 200) {
        this.setState({ loading: false, selectedRowKeys: [] });
      }
    });
  };

  onSelectChange = (newSelectedRowKeys) => {
    this.setState({ selectedRowKeys: newSelectedRowKeys });
  };

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  countClass = _.size(_.get(this, 'props.tutorClassRequests.items'))
  countCamp = _.size(_.get(this, 'props.tutorCampsRequests.items'))
  countComp = _.size(_.get(this, 'props.tutorCompetitionRequests.items'))
  countEvent = _.size(_.get(this, 'props.tutorEventRequests.items'))

  render() {
    const { class_page, camp_page, competition_page, event_page } = this.state;
    const { selectedRowKeys, loading } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    console.log('selectedRowKeys', selectedRowKeys);
    const action1 = {
      title: this.props.t('Action'),
      key: 'action',
      width: '40%',
      render: (text, record) => {
        const session_id = _.get(record, 'key', 'user_id');
        return (
          console.log('recorddd', record),
          (
            <Space size="middle">
              <div>
                <button
                  onClick={() => {
                    const element = document.getElementById(session_id);
                    element.style.display = 'none';
                    setTimeout(() => {
                      element.style.display = 'block';
                    }, 1000);
                    this.onAcceptUser(record, 'userAccept', 'classes');
                  }}
                  id={session_id}
                  className="btn btn-accept mr-1"
                >
                  {this.props.t('accept2')}
                </button>
                {' '}
                <button
                  id={session_id}
                  onClick={() => {
                    const element = document.getElementById(session_id);
                    element.style.display = 'none';
                    setTimeout(() => {
                      element.style.display = 'block';
                    }, 1000);
                    this.onAcceptUser(record, 'userReject', 'classes');
                  }}
                  className="btn btn-reject"
                >
                  {this.props.t('reject2')}
                </button>
              </div>
            </Space>
          )
        );
      },
    };

    const user1 = {
      title: this.props.t('userLabel'),
      dataIndex: 'user',
      key: 'user',
      width: '30%',
    };

    const Requested_class = {
      title: this.props.t('req'),
      dataIndex: 'class',
      key: 'class',
      width: '30%',
    };

    const Requested_camp = {
      title: this.props.t('req1'),
      dataIndex: 'Camps',
      key: 'Camps',
      width: '30%',
    };

    const Requested_competition = {
      title: this.props.t('req2'),
      dataIndex: 'Competitions',
      key: 'Competitions',
      width: '30%',
    };

    const Requested_event = {
      title: this.props.t('req3'),
      dataIndex: 'Events',
      key: 'Events',
      width: '30%',
    };

    const columns = [user1, Requested_class, action1];
    const camps_columns = [user1, Requested_camp, action1];
    const competition_columns = [user1, Requested_competition, action1];
    const event_columns = [user1, Requested_event, action1];

    const user_class_request = [];
    if (
      (this.props.tutorClassRequests
        && this.props.tutorClassRequests.items
        && this.props.tutorClassRequests.items.length > 0)
      || _.size(_.get(this, 'state.tutorClassRequestList')) > 0
    ) {
      this.props.tutorClassRequests.items.map((class_user, index) => {
        // console.log('class_user', class_user);
        // let filteredClassDataSource = _.uniqBy(user_class_request, (n) => {return _.get(n, 'id')})
        user_class_request.push({
          type: 'classes',
          key: class_user.id,
          user: _.isEmpty(class_user.dependent_user)
            ? class_user.user
            && `${class_user.user.firstname} ${_.get(class_user, 'user.lastname', '') !== ''
              ? `${_.startCase(_.get(class_user, 'user.lastname', ''))}`
              : ''
            }`
            : _.startCase(class_user.dependent_user.firstname),
          class:
            class_user.class_details && class_user.class_details.session_name,
          user_id: class_user.user && class_user.user.id,
          dependent_user:
            class_user.dependent_user && class_user.dependent_user.id,
          class_details: _.get(class_user, 'class_details'),
          booking_dates: _.get(class_user, 'booking_dates', []),
        });
        return null;
      });
    }

    const user_camps_request = [];
    if (
      this.props.tutorCampsRequests
      && this.props.tutorCampsRequests.items
      && this.props.tutorCampsRequests.items.length > 0
    ) {
      this.props.tutorCampsRequests.items.map((camp_user, index) => {
        // console.log('camp_user', camp_user);
        user_camps_request.push({
          key: camp_user.id,
          user: _.isEmpty(camp_user.dependent_user)
            ? camp_user.user
            && `${camp_user.user.firstname} ${_.get(camp_user, 'user.lastname', '') !== ''
              ? `${_.startCase(_.get(camp_user, 'user.lastname', ''))}`
              : ''
            }`
            : _.startCase(camp_user.dependent_user.firstname),
          Camps: _.get(camp_user, 'camp_details.session_name'), // This line is updated by iyyanar@studioq.co.in for requested camp name
          user_id: camp_user.user && camp_user.user.id,
          type: 'camps',
          dependent_user:
            camp_user.dependent_user && camp_user.dependent_user.id,
        });
        return null;
      });
    }

    const user_competition_request = [];
    if (
      this.props.tutorCompetitionRequests
      && this.props.tutorCompetitionRequests.items
      && this.props.tutorCompetitionRequests.items.length > 0
    ) {
      this.props.tutorCompetitionRequests.items.map((camp_user, index) => {
        // console.log('camp_user', camp_user);
        user_competition_request.push({
          key: camp_user.id,
          user: _.isEmpty(camp_user.dependent_user)
            ? camp_user.user
            && `${camp_user.user.firstname} ${_.get(camp_user, 'user.lastname', '') !== ''
              ? `${_.startCase(_.get(camp_user, 'user.lastname', ''))}`
              : ''
            }`
            : _.startCase(camp_user.dependent_user.firstname),
          Competitions: _.get(camp_user, 'competition_details.session_name'), // This line is updated by iyyanar@studioq.co.in for requested camp name
          user_id: camp_user.user && camp_user.user.id,
          type: 'competitions',
          dependent_user:
            camp_user.dependent_user && camp_user.dependent_user.id,
        });
        return null;
      });
    }

    const user_event_request = [];
    if (
      this.props.tutorEventRequests
      && this.props.tutorEventRequests.items
      && this.props.tutorEventRequests.items.length > 0
    ) {
      this.props.tutorEventRequests.items.map((camp_user, index) => {
        // console.log('camp_user', camp_user);
        user_event_request.push({
          key: camp_user.id,
          user: _.isEmpty(camp_user.dependent_user)
            ? camp_user.user
            && `${camp_user.user.firstname} ${_.get(camp_user, 'user.lastname', '') !== ''
              ? `${_.startCase(_.get(camp_user, 'user.lastname', ''))}`
              : ''
            }`
            : _.startCase(camp_user.dependent_user.firstname),
          Events: _.get(camp_user, 'event_details.session_name'), // This line is updated by iyyanar@studioq.co.in for requested camp name
          user_id: camp_user.user && camp_user.user.id,
          type: 'events',
          dependent_user:
            camp_user.dependent_user && camp_user.dependent_user.id,
        });
        return null;
      });
    }

    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div
            className="nurtem-card-design tutorRequestCard nurtem-table"
            style={{ marginBottom: 20 }}
          >
            <div
              className="tutor-base-profile-tab tutorRequest"
              style={{ display: 'flex', height: '100%' }}
            >
              <ul className="nav-tabs-request" style={{ marginBottom: 0 }}>
                {['classes', 'camps', 'competitions', 'events'].map((tab) => (
                  <li
                    key={tab}
                    className={`nav-item ${this.state.activeTab === tab ? 'actives' : ''
                      }`}
                    onClick={() => this.setActiveTab(tab)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                      <span className="nav-link">{_.startCase(tab)}</span>
                      {(this.countCamp || this.countClass || this.countComp || this.countEvent) > 0 &&
                        <span><Badge count={tab === 'classes' ? this.countClass
                          : tab === 'camps' ? this.countCamp
                            : tab === 'competitions' ? this.countComp
                              : this.countEvent} />
                        </span>}
                    </div>
                  </li>
                ))}
              </ul>

              <div
                className="tab-content"
                style={{ width: '100%', margin: 10, marginLeft: 15 }}
              >
                {this.state.activeTab === 'classes' && (
                  <div className="full-width">
                    {_.size(user_class_request) === 0
                      && this.props.enableRequestLoading ? (
                      <div className="full-width">
                        {' '}
                        <div className="col-12 ">
                          <LoadingCard mystyle="card-tab-section" />
                        </div>
                      </div>
                    ) : (
                      <>

                        <Table
                          dataSource={user_class_request}
                          loading={this.props.enableRequestLoading}
                          columns={columns}
                          rowSelection={rowSelection}
                          // pagination = {false}
                          pagination={{
                            pageSize: this.state.limit,
                            onChange: (page, pageSize) => this.pageChange(page, pageSize, 'class_page'),
                            total:
                              this.props.tutorClassRequests
                              && this.props.tutorClassRequests.meta
                              && this.props.tutorClassRequests.meta.total_count,
                            hideOnSinglePage:
                              !this.props.enableRequestLoading
                              || this.props.enableRequestLoading,
                            showSizeChanger: false,
                            current: parseInt(class_page),
                          }}
                        />
                        <AcceptAllBtn
                          key="class"
                          accept={this.acceptAllClass}
                          reject={this.rejectAllClass}
                          hasSelected={hasSelected}
                          loading={loading}
                          selectedRowCount={selectedRowKeys.length}
                          totalCount={_.get(
                            this,
                            'props.tutorClassRequests.meta.total_count',
                            0,
                          )}
                        />
                      </>
                    )}
                  </div>
                )}

                {this.state.activeTab === 'camps' && (
                  <div className="full-width">
                    {_.size(user_camps_request) === 0
                      && this.props.enableCampsRequestLoading ? (
                      <div className="full-width">
                        {' '}
                        <div className="col-12 ">
                          <LoadingCard mystyle="card-tab-section" />
                        </div>
                      </div>
                    ) : (
                      <>

                        <Table
                          loading={this.props.enableCampsRequestLoading}
                          dataSource={user_camps_request}
                          columns={camps_columns}
                          rowSelection={rowSelection}
                          pagination={{
                            pageSize: this.state.limit,
                            onChange: (page, pageSize) => this.pageChange(page, pageSize, 'camp_page'),
                            total:
                              this.props.tutorCampsRequests
                              && this.props.tutorCampsRequests.meta
                              && this.props.tutorCampsRequests.meta.total_count,
                            hideOnSinglePage: true,
                            showSizeChanger: false,
                            current: parseInt(camp_page),
                          }}
                        />
                        <AcceptAllBtn
                          key="camp"
                          accept={this.acceptAll}
                          reject={this.rejectAll}
                          hasSelected={hasSelected}
                          loading={loading}
                          selectedRowCount={selectedRowKeys.length}
                          totalCount={_.get(
                            this,
                            'props.tutorCampsRequests.meta.total_count',
                            0,
                          )}
                        />
                      </>
                    )}
                  </div>
                )}

                {this.state.activeTab === 'competitions' && (
                  <div className="full-width">
                    {_.size(user_competition_request) === 0
                      && this.props.enableCampsRequestLoading ? (
                      <div className="full-width">
                        <div className="col-12 ">
                          <LoadingCard mystyle="card-tab-section" />
                        </div>
                      </div>
                    ) : (
                      <>

                        <Table
                          loading={this.props.enableCampsRequestLoading}
                          dataSource={user_competition_request}
                          columns={competition_columns}
                          rowSelection={rowSelection}
                          pagination={{
                            pageSize: this.state.limit,
                            onChange: (page, pageSize) => this.pageChange(
                              page,
                              pageSize,
                              'competition_page',
                            ),
                            total:
                              this.props.tutorCompetitionRequests
                              && this.props.tutorCompetitionRequests.meta
                              && this.props.tutorCompetitionRequests.meta
                                .total_count,
                            hideOnSinglePage: true,
                            showSizeChanger: false,
                            current: parseInt(competition_page),
                          }}
                        />
                        <AcceptAllBtn
                          key="competition"
                          accept={this.acceptAllCompetition}
                          reject={this.rejectAllCompetition}
                          hasSelected={hasSelected}
                          loading={loading}
                          selectedRowCount={selectedRowKeys.length}
                          totalCount={_.get(
                            this,
                            'props.tutorCompetitionRequests.meta.total_count',
                            0,
                          )}
                        />
                      </>
                    )}
                  </div>
                )}

                {this.state.activeTab === 'events' && (
                  <div className="full-width">
                    {_.size(user_event_request) === 0
                      && this.props.enableEventRequestLoading ? (
                      <div className="full-width">
                        <div className="col-12 ">
                          <LoadingCard mystyle="card-tab-section" />
                        </div>
                      </div>
                    ) : (
                      <>

                        <Table
                          loading={this.props.enableCampsRequestLoading}
                          dataSource={user_event_request}
                          columns={event_columns}
                          rowSelection={rowSelection}
                          pagination={{
                            pageSize: this.state.limit,
                            onChange: (page, pageSize) => this.pageChange(
                              page,
                              pageSize,
                              'event_page',
                            ),
                            total:
                              this.props.tutorEventRequests
                              && this.props.tutorEventRequests.meta
                              && this.props.tutorEventRequests.meta
                                .total_count,
                            hideOnSinglePage: true,
                            showSizeChanger: false,
                            current: parseInt(event_page),
                          }}
                        />
                        <AcceptAllBtn
                          key="event"
                          accept={this.acceptAllEvent}
                          reject={this.rejectAllEvent}
                          hasSelected={hasSelected}
                          loading={loading}
                          selectedRowCount={selectedRowKeys.length}
                          totalCount={_.get(
                            this,
                            'props.tutorEventRequests.meta.total_count',
                            0,
                          )}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) =>
// console.log("state.competitionsDetails.tutorCompetitionRequests", state.competitionsDetails.tutorCompetitionRequests)
({
  tutorCompetitionRequests:
    state.competitionsDetails.tutorCompetitionRequests,
  tutorCompetitionRequestAction:
    state.competitionsDetails.tutorCompetitionRequestAction,
  tutorEventRequests:
    state.eventsDetails.tutorEventRequests,
  tutorEventRequestAction:
    state.eventsDetails.tutorEventRequestAction,
  tutorClassRequests: state.classesDetails.tutorClassRequests,
  tutorClassRequestAction: state.classesDetails.tutorClassRequestAction,
  tutorCampsRequests: state.campsDetails.tutorCampsRequests,
  tutorCampsRequestAction: state.campsDetails.tutorCampsRequestAction,
  enableRequestLoading: state.classesDetails.enableRequestLoading,
  enableCampsRequestLoading: state.campsDetails.enableCampsRequestLoading,
  milestoneclassLoading: state.classesDetails.milestoneLoading,
  milestonecampsLoading: state.campsDetails.milestoneLoading,
});
const mapDispatchToProps = (dispatch) => ({
  getClassesRequests: (requestParams) => dispatch(
    classActions.getClassesRequests({ ...requestParams, limit: 10 }),
  ),
  ClassesRequestAction: (data, callback) => dispatch(classActions.ClassesRequestAction(data, callback)),
  ClassesSlotFullAction: (data) => dispatch(classActions.ClassesSlotFullAction(data)),
  getCampsRequests: (params) => dispatch(actions.getCampsRequests({ ...params, limit: 10 })),
  CampsRequestAction: (data, callback) => dispatch(actions.CampsRequestAction(data, callback)),
  getCompetitionRequests: (requestParams) => dispatch(
    competitionActions.getCompetitionRequests({
      ...requestParams,
      limit: 10,
    }),
  ),
  CompetitionRequestAction: (data, callback) => dispatch(competitionActions.CompetitionRequestAction(data, callback)),
  getEventRequests: (requestParams) => dispatch(
    competitionActions.getEventRequests({
      ...requestParams,
      limit: 10,
    }),
  ),
  EventRequestAction: (data, callback) => dispatch(competitionActions.EventRequestAction(data, callback)),
  getRequestsCounts: (data, callback) => dispatch(commonActions.onGetRequestCount(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TutorRequest));
