import { notification } from 'antd';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';

// import _ from 'lodash';
export const globalObj = {
  processid: 0,
  page: 1,
};
export const onGetRecentMessages = (data) => ({
  type: actionTypes.recentchatlistuser,
  success: data,
});
export const onAddingUser = (data) => ({
  type: actionTypes.addinguser,
  success: data,
});
export const disableMessageLoading = () => ({
  type: actionTypes.disableMessageLoading,
  loading: { messageLoading: false },
});
export const disableAddUserLoading = () => ({
  type: actionTypes.disableAddUserLoading,
  adduserLoading: { adduserLoading: false },
});
export const enableAddUserLoading = () => ({
  type: actionTypes.enableAddUserLoading,
  adduserLoading: { adduserLoading: true },
});
export const updateMessage = (data) => ({
  type: actionTypes.updateMessage,
  success: data,
});
export const createMessage = (data) => ({
  type: actionTypes.createMessage,
  success: data,
});
export const onGetIndividualMessages = (data) => ({
  type: actionTypes.individualMessages,
  success: data,
});
export const onGetProviderList = (data) => ({
  type: actionTypes.providerListForUser,
  success: data,
});

export const recentMessages = (requestParams) => (dispatch) => {
  if (globalObj.page !== requestParams.page) {
    globalObj.page = requestParams.page;
    clearInterval(globalObj.processid);
  }

  // start get message initially and then we go for set interval call
  getMessagesrecent(dispatch, requestParams);
  // create new poll
  globalObj.processid = setInterval(getMessagesrecent, 5000, dispatch, requestParams);
};

export const getMessagesrecent = (dispatch, requestParams) => {
  if (window.location.href.indexOf('message') === -1) {
    // console.log("processid to kill",globalObj.processid)
    return clearInterval(globalObj.processid);
  }

  axios
    .get('/message/recent', {
      params: {...requestParams, sec:Math.random()},
      withCredentials: true,
      config: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      },
    })
    .then((response) => {
      dispatch(onGetRecentMessages(response.data));
      dispatch(disableMessageLoading());
    })
    .catch((error) => {
      dispatch(onGetRecentMessages(error));
    });
};

export const getMessagesrecentCount = (requestParams) => (dispatch) => {
  axios
    .get('/message/recent', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      dispatch(onGetRecentMessages(response.data));
      dispatch(disableMessageLoading());
    })
    .catch((error) => {
      dispatch(onGetRecentMessages(error));
    });
};

export const onCreateUserMessages = (requestParams) => (dispatch) => {
  axios({
    method: 'post',
    url: '/message/create',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      dispatch(createMessage(response));
    })
    .catch((error) => {
      dispatch(createMessage(error));
    });
};

export const AddingUser = (requestParams) => (dispatch) => {
  dispatch(enableAddUserLoading());
  axios({
    method: 'post',
    url: '/message/add-user',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      dispatch(onAddingUser(response));
      dispatch(disableAddUserLoading());
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
      } else if (response.status === 201) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.warning(message_args);
      }
    })
    .catch((error) => {
      dispatch(onAddingUser(error));
      dispatch(disableAddUserLoading());
    });
};

export const onUpdateUserMessages = (requestParams) => (dispatch) => {
  axios({
    method: 'post',
    url: '/message/update',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      dispatch(updateMessage(response));
    })
    .catch((error) => {
      dispatch(updateMessage(error));
    });
};
export const onUpdateUserData = (requestParams) => (dispatch) => {
  axios({
    method: 'post',
    url: '/message/update-count',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      dispatch(updateMessage(response));
    })
    .catch((error) => {
      dispatch(updateMessage(error));
    });
};

export const indvidualMessages = (requestParams) => (dispatch) => {
  // clear the old interval
  clearInterval(globalObj.processid);
  // start get message initially and then we go for set interval call
  getIndividualMessages(dispatch, requestParams);
  // create new poll
  globalObj.processid = setInterval(getIndividualMessages, 3000, dispatch, requestParams);
};

export const getIndividualMessages = (dispatch, requestParams) => {
  if (window.location.href.indexOf('message') === -1) {
    // console.log("processid to kill",globalObj.processid)
    return clearInterval(globalObj.processid);
  }
  axios
    .get('/message/list', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      dispatch(onGetIndividualMessages(response.data));
      dispatch(disableMessageLoading());
    })
    .catch((error) => {
      dispatch(updateMessage(error));
    });
};

export const getProviderList = (requestParams) => (dispatch) => {
  axios
    .get('/message/provider-list', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      dispatch(onGetProviderList(response.data));
      dispatch(disableMessageLoading());
    })
    .catch((error) => {
      dispatch(updateMessage(error));
    });
};
