import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import css from '../../utils/CSSUtility'

const Popup = ({ component: Component, params, label, width, footer }) => {
  const [visible, setVisible] = useState(false)
  const showModal = () => {
    setVisible(true)
  }

  const handleOk = () => {
    console.log('handle ok')
    setVisible(false)
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <div>
      <Button type="primary" onClick={showModal}>
        {label}
      </Button>
      <Modal
        title={label}
        centered
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer ?? null}
        width={width ? width : 600}
      >
        {visible ? <Component {...params} handleOk={handleOk} /> : ''}
      </Modal>
    </div>
  )
}

export default Popup
