/**
 * Tutor Dashboard Sidebar
 *
 * @var profile_type
 * @description for user 						>> null
 * 				for tutor >> business			>> 0
 * 				for tutor >> under a business	>> 1
 */

import React from 'react'
import { Menu, Modal, Tour } from 'antd'
import { Badge, Button } from '../../../../components/antd-components/index.tsx'
import './tutor-dashboard-sidebar.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { Link, withRouter } from 'react-router-dom'
import _ from 'lodash'
import {
  FcBusinessman,
  FcCollaboration,
  FcConferenceCall,
  FcEngineering,
  FcMoneyTransfer,
  FcSurvey,
  FcBusinessContact,
  FcTodoList,
} from 'react-icons/fc'
import { TbTimelineEventPlus, TbLayoutDashboard } from "react-icons/tb";
import { IoCalendarNumberOutline } from "react-icons/io5";
import * as servicesActions from '../../../../store/actions/public/Services'
import { GiChampions } from 'react-icons/gi'
import { FaCampground } from 'react-icons/fa6'
import { BiSolidCoupon } from 'react-icons/bi'
import { TiUserAdd } from 'react-icons/ti'
import { SiGoogleclassroom } from 'react-icons/si'
import * as routeName from '../../../../components/route-names'
import * as classActions from '../../../../store/actions/tutor/common'
import * as msgAction from '../../../../store/actions/public/message'
import history from '../../../../utils/history'
import * as tourGuideActions from '../../../../store/actions/common/tour-guide'
import { MdGroupAdd } from 'react-icons/md'

class TutorDashboardPageSidebar extends React.Component {
  state = {
    defaultKey: routeName.tutorDashboard,
  }

  constructor(props) {
    super(props)
    this.ref1 = React.createRef()
    this.ref2 = React.createRef()
    this.ref3 = React.createRef()
    this.ref4 = React.createRef()
    this.ref5 = React.createRef()
    this.ref6 = React.createRef()
    this.ref7 = React.createRef()
    this.ref8 = React.createRef()
    this.ref9 = React.createRef()
    this.ref10 = React.createRef()
    this.state = {
      open: false,
      defaultKey: ['1'],
    }
  }

  steps = [
    {
      title: (
        <div className="centered-title">
          <span role="img" aria-label="Celebration">
            🎉
          </span>
          <span role="img" aria-label="Graduation Hat">
            🎓
          </span>{' '}
          {this.props.t('tutorWebTour1')}{' '}
          <span role="img" aria-label="Graduation Hat">
            🎓
          </span>
          <span role="img" aria-label="Celebration">
            🎉
          </span>
        </div>
      ),
      description: (
        <>
          {this.props.t('tutorWebTour2')}
          <br />
          <br />
          {this.props.t('tutorWebTour3')}
        </>
      ),
      placement: 'center',
    },
    {
      title: this.props.t('tutorSidebarMyMessage'),
      description: <>{this.props.t('tutorWebTour4')}</>,
      placement: 'right',
      target: () => this.ref5.current,
    },
    {
      title: this.props.t('tutorSidebarMyCalendar'),
      description: (
        <>
          {this.props.t('tutorWebTour10')}
          ''
        </>
      ),
      placement: 'right',
      target: () => this.ref4.current,
    },
    {
      title: this.props.t('tutorSidebarMyClasses'),
      description: this.props.t('tutorWebTour5'),
      placement: 'right',
      target: () => this.ref1.current,
    },
    {
      title: this.props.t('tutorSidebarMyCamps'),
      description: <>{this.props.t('tutorWebTour6')}</>,
      placement: 'right',
      target: () => this.ref2.current,
    },
    {
      title: this.props.t('tutorSidebarMyCompetitions'),
      description: <>{this.props.t('tutorWebTour7')}</>,
      placement: 'right',
      target: () => this.ref3.current,
    },
    {
      title: this.props.t('tutorSidebarMyEvents'),
      description: <>{this.props.t('tutorWebTour8')}</>,
      placement: 'right',
      target: () => this.ref10.current,
    },
    {
      title: this.props.t('onboardWizard'),
      description: <>{this.props.t('tutorWebTour7b')}</>,
      placement: 'right',
      target: () => this.ref7.current,
    },
    {
      title: this.props.t('tutorSidebarStudentRequests'),
      description: <>{this.props.t('tutorWebTour7a')}</>,
      placement: 'right',
      target: () => this.ref6.current,
    },
    {
      title: this.props.t('tutorSidebarMyPayments'),
      description: this.props.t('tutorWebTour11'),
      placement: 'right',
      target: () => this.ref8.current,
    },
    {
      title: this.props.t('tutorSidebarSettings'),
      description: <>{this.props.t('tutorWebTour12')}</>,
      placement: 'right',
      target: () => this.ref9.current,
    },
  ]

  handleSkipTour = () => {
    this.setState({ open: false });

    // Update the backend to mark the tour as completed
    // Here after no tour will be appear 
    const id = _.get(this.props, 'tutorProfile.id', '');
    if (id) {
      const userFormData = {
        guide: 1,
        id: id,
        type: 'provider'
      };
      this.props.updateProfile(userFormData);
    }
  }

  async componentDidMount() {
    const url = this.props.location.search
    const params = queryString.parse(url)
    params.limit = 100 // getting latest 100 messages and checking for not replied(new message)
    params.receiver_type = params.sender_type = 'provider'

    if (
      this.props.location &&
      this.props.location.pathname !== this.state.defaultKey
    ) {
      this.setState({
        defaultKey: this.props.location.pathname,
      })
    }
    if (this.props.tutorProfile) {
      if (
        (this.props.tutorProfile.firstname ||
          this.props.tutorProfile.businessname) &&
        this.props.tutorProfile.email
      ) {
        if (this.props.tutorProfile.tour_guide === 0) {
          this.setState({
            open: true,
          })
        }
      }
    }
    await this.props.getRequestsCounts()
    if (this.props.tutorProfile) {
      const requestParams = {
        provider: this.props.tutorProfile.id,
        sort: 'updated_at.desc',
      }
      this.props.onGetRecentMessages(requestParams)
    }
  }

  handleAfterClose = (current) => {
    // Make API call to update flag in backend
    const id = _.get(this.props, 'tutorProfile.id', '')

    const stepLength = this.steps && this.steps.length
    const userFormData = {}
    userFormData.guide = 1
    userFormData.id = id
    userFormData.type = 'provider'
    if (id && current === stepLength) {
      this.props.updateProfile(userFormData)
    }
  }

  handleClick = async (e) => {
    this.setState({
      defaultKey: e.key,
    })
    if (window.innerWidth <= 650) {
      const element = document.querySelector('.ant-layout-sider')
      element.classList.toggle('ant-layout-sider-collapsed')
    }
  }

  onchangeTutorCartPage = (route) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })

    if (
      _.size(_.get(this.props, 'itemsExist', [])) > 0 ||
      _.size(_.get(this.props, 'campsItemsExist', [])) > 0
    ) {
      Modal.confirm({
        title: this.props.t('Are you sure leave this screen?'),
        content: 'once changed, you will not be able to recover the data',
        okText: this.props.t('confirm'),
        onOk() {
          history.push(route)
        },
        cancelButtonProps: {
          disabled: false,
        },
        confirmLoading: {
          disabled: false,
        },
        cancelText: this.props.t('cancel'),
      })
    } else {
      history.push(route)
    }
  }

  onchangeRoute = (route) => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    history.push(route)
  }

  activeMenu = (path) =>
    this.props.location && this.props.location.pathname === path
      ? ' select-menu'
      : ''

  render() {
    const { loginDetails } = this.props
    const { defaultKey } = this.state
    const prviousPath =
      _.get(this, 'props.location.pathname') === routeName.tutorMycart
    const notificationCount = _.get(this, 'props.requestCount', 0)
    let user_message_unread = 0
    if (
      this.props.recentchatlistuser &&
      this.props.recentchatlistuser.items &&
      this.props.recentchatlistuser.items.length > 0
    ) {
      this.props.recentchatlistuser.items.forEach((user_message, index) => {
        const message_list =
          user_message.user && user_message.user.messages_list
        const msgUnreadCount =
          message_list &&
          message_list.filter((item) => item.type === 'user').length
        const message_readcount =
          user_message.user && user_message.user.read_count
        if (msgUnreadCount) {
          user_message_unread += msgUnreadCount - message_readcount
        }
      })
    }
    let user_message_count = 0
    if (
      this.props.tutorMessages &&
      this.props.tutorMessages.items &&
      this.props.tutorMessages.items.length > 0
    ) {
      this.props.tutorMessages.items.forEach((user_message, index) => {
        user_message_count = _.isEmpty(user_message.replies)
          ? user_message_count + 1
          : user_message_count
      })
    }

    const login_type = _.get(loginDetails, 'login_type', '')

    const isUser = login_type === 'user'
    const isProvider = login_type === 'provider'

    const tutorProfile = _.get(this.props, 'tutorProfile', {})

    let tutorProfileType = _.get(tutorProfile, 'type', '')
    tutorProfileType = _.toLower(tutorProfileType)

    const profile_type = _.get(tutorProfile, 'profile_type', '')
    const isBusiness = isProvider && tutorProfileType === 'business'
    const isBusinessHeadProfile = isBusiness && profile_type === 0
    const isTutorUnderBusiness = isProvider && profile_type === 1

    return (
      <div className="tutor-dashboard-sidebar-container">
        <Menu
          onClick={(e) => this.handleClick(e)}
          style={{ width: `${100}%` }}
          // defaultSelectedKeys={defaultKey}
          selectedKeys={defaultKey}
          mode="inline"
        >
          <Menu.Item
            key={routeName.tutorDashboard}
            className={this.activeMenu(routeName.tutorDashboard)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.userDashboard)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorDashboard)
                  : this.onchangeRoute(routeName.tutorDashboard)
            }
          >
            <Link to={routeName.tutorDashboard}>
              <div className="tutor-collapsed">
                <TbLayoutDashboard style={{ fontSize: '25px', color: '#006d77' }} />{' '}
                <Button className="side-bar-tutor">
                  {this.props.t('tutorSidebarDashboard')}{' '}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.viewAllMessages}
            className={this.activeMenu(routeName.viewAllMessages)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.viewAllMessages)
                  : this.onchangeRoute(routeName.viewAllMessages)
            }
          >
            <Link to={routeName.viewAllMessages}>
              <div className="tutor-collapsed" ref={this.ref5}>
                {user_message_unread > 0 ? (
                  <Badge
                    className="ml-2 notification-badge"
                    count={user_message_unread}
                    overflowCount={user_message_unread}>
                    <FcCollaboration style={{ fontSize: '25px' }} />
                  </Badge>
                ) : (
                  <FcCollaboration style={{ fontSize: '25px' }} />
                )}
                <Button className="side-bar-tutor notification-btn">
                  {' '}
                  {this.props.t('tutorSidebarMyMessage')}{' '}
                </Button>{' '}
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.Tutorcalendar}
            className={this.activeMenu(routeName.Tutorcalendar)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.Tutorcalendar)
                  : this.onchangeRoute(routeName.Tutorcalendar)
            }
          >
            <Link to={routeName.Tutorcalendar}>
              <div className="tutor-collapsed" ref={this.ref4}>
                <IoCalendarNumberOutline style={{ fontSize: '25px', color: '#4f1156' }} />
                <Button className="side-bar-tutor">
                  {' '}
                  {this.props.t('tutorSidebarMyCalendar')}{' '}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.tutorClasses}
            className={this.activeMenu(routeName.tutorClasses)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorClasses)
                  : this.onchangeRoute(routeName.tutorClasses)
            }
          >
            <Link to={routeName.tutorClasses}>
              <div className="tutor-collapsed" ref={this.ref1}>
                <SiGoogleclassroom
                  style={{ fontSize: '25px', color: 'darkorange' }}
                />
                <Button className="side-bar-tutor">
                  {this.props.t('tutorSidebarMyClasses')}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.tutorCamps}
            className={this.activeMenu(routeName.tutorCamps)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorCamps)
                  : this.onchangeRoute(routeName.tutorCamps)
            }
          >
            <Link to={routeName.tutorCamps}>
              <div className="tutor-collapsed" ref={this.ref2}>
                <FaCampground style={{ color: 'green', fontSize: '25px' }} />
                <Button className="side-bar-tutor">
                  {this.props.t('tutorSidebarMyCamps')}
                </Button>
              </div>
            </Link>
          </Menu.Item>

          <Menu.Item
            key={routeName.competitions}
            className={this.activeMenu(routeName.competitions)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.competitions)
                  : this.onchangeRoute(routeName.competitions)
            }
          >
            <Link to={routeName.competitions}>
              <div className="tutor-collapsed" ref={this.ref3}>
                <GiChampions style={{ color: 'gold', fontSize: '25px' }} />
                <Button className="side-bar-tutor">
                  {this.props.t('tutorSidebarMyCompetitions')}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.events}
            className={this.activeMenu(routeName.events)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.events)
                  : this.onchangeRoute(routeName.events)
            }
          >
            <Link to={routeName.events} ref={this.ref10}>
              <div className="tutor-collapsed" >
                <TbTimelineEventPlus style={{ color: '#0099ff', fontSize: '25px' }} />
                <Button className="side-bar-tutor">
                  {this.props.t('tutorSidebarMyEvents')}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.tutorOnboardingWizard}
            className={this.activeMenu(routeName.tutorOnboardingWizard)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.userProfile)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorOnboardingWizard)
                  : this.onchangeRoute(routeName.tutorOnboardingWizard)
            }
          >
            <Link to={routeName.tutorOnboardingWizard} ref={this.ref7}>
              <div className="tutor-collapsed">
                <MdGroupAdd style={{ fontSize: '25px' }} />
                <Button className="side-bar-tutor"> Onboarding Wizard </Button>
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key={routeName.viewAllRequest}
            className={this.activeMenu(routeName.viewAllRequest)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.home)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.viewAllRequest)
                  : this.onchangeRoute(routeName.viewAllRequest)
            }
          >
            <Link to={routeName.viewAllRequest}>
              <div className="tutor-collapsed" ref={this.ref6}>
                {notificationCount > 0 ? (
                  <Badge
                    className="ml-2 notification-badge"
                    count={notificationCount}
                    overflowCount={notificationCount}
                  >
                    <TiUserAdd style={{ color: 'darkblue', fontSize: '25px' }} />
                  </Badge>
                ) : (
                  <TiUserAdd style={{ color: 'darkblue', fontSize: '25px' }} />
                )}
                <Button className="side-bar-tutor notification-btn">
                  {' '}
                  {this.props.t('tutorSidebarStudentRequests')}
                </Button>
              </div>
            </Link>
          </Menu.Item>
          {isBusinessHeadProfile && (
            <Menu.Item
              key={routeName.TutorList}
              className={this.activeMenu(routeName.TutorList)}
              onClick={() =>
                isUser
                  ? this.onchangeRoute(routeName.home)
                  : prviousPath
                    ? this.onchangeTutorCartPage(routeName.TutorList)
                    : this.onchangeRoute(routeName.TutorList)
              }
            >
              <Link to={routeName.TutorList}>
                <div className="tutor-collapsed">
                  <FcBusinessman style={{ fontSize: '25px' }} />
                  <Button className="side-bar-tutor">
                    {this.props.t('tutorSidebarMyTutors')}
                  </Button>
                </div>
              </Link>
            </Menu.Item>
          )}
          {isProvider && !isTutorUnderBusiness && (
            <>
              <Menu.Item
                key={routeName.myCoupons}
                className={this.activeMenu(routeName.myCoupons)}
                onClick={() =>
                  _.isEqual(_.get(loginDetails, 'login_type', null), 'user')
                    ? this.onchangeRoute(routeName.home)
                    : prviousPath
                      ? this.onchangeTutorCartPage(routeName.myCoupons)
                      : this.onchangeRoute(routeName.myCoupons)
                }
              >
                <Link to={routeName.myCoupons}>
                  <div className="tutor-collapsed" >
                    <BiSolidCoupon
                      style={{ fontSize: '25px', color: 'darkred' }}
                    />
                    <Button className="side-bar-tutor">
                      {this.props.t('tutorSidebarMyCoupons')}
                    </Button>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={routeName.attendanceReport}
                className={this.activeMenu(routeName.attendanceReport)}
                onClick={() =>
                  isUser
                    ? this.onchangeRoute(routeName.home)
                    : prviousPath
                      ? this.onchangeTutorCartPage(routeName.attendanceReport)
                      : this.onchangeRoute(routeName.attendanceReport)
                }
              >
                <Link to={routeName.attendanceReport}>
                  <div className="tutor-collapsed">
                    <FcTodoList style={{ fontSize: '25px' }} />
                    <Button className="side-bar-tutor">
                      {this.props.t('tutorSidebarAttendanceReport')}
                    </Button>
                  </div>
                </Link>
              </Menu.Item>
              <Menu.Item
                key={routeName.paymentReport}
                className={this.activeMenu(routeName.paymentReport)}
                onClick={() =>
                  isUser
                    ? this.onchangeRoute(routeName.home)
                    : prviousPath
                      ? this.onchangeTutorCartPage(routeName.paymentReport)
                      : this.onchangeRoute(routeName.paymentReport)
                }
              >
                <Link to={routeName.paymentReport}>
                  <div className="tutor-collapsed" ref={this.ref8}>
                    <FcSurvey style={{ fontSize: '25px' }} />
                    <Button className="side-bar-tutor">
                      {this.props.t('tutorSidebarPaymentReport')}
                    </Button>
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item
                key={routeName.payments}
                className={this.activeMenu(routeName.payments)}
                onClick={() =>
                  isUser
                    ? this.onchangeRoute(routeName.home)
                    : prviousPath
                      ? this.onchangeTutorCartPage(routeName.payments)
                      : this.onchangeRoute(routeName.payments)
                }
              >
                <Link to={routeName.payments}>
                  <div className="tutor-collapsed">
                    <FcMoneyTransfer style={{ fontSize: '25px' }} />
                    <Button className="side-bar-tutor">
                      {this.props.t('tutorSidebarOfflinePayments')}
                    </Button>
                  </div>
                </Link>
              </Menu.Item>
            </>
          )}
          <Menu.Item
            key={routeName.tutorStudentList}
            className={this.activeMenu(routeName.tutorStudentList)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.userProfile)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorStudentList)
                  : this.onchangeRoute(routeName.tutorStudentList)
            }
          >
            <Link to={routeName.tutorStudentList}>
              <div className="tutor-collapsed">
                <FcConferenceCall style={{ fontSize: '25px' }} />
                <Button className="side-bar-tutor">
                  {' '}
                  {this.props.t('tutorSidebarStudentLists')}{' '}
                </Button>
              </div>
            </Link>
          </Menu.Item>

          {isBusinessHeadProfile && (
            <Menu.Item
              key={routeName.businessmenu}
              className={this.activeMenu(routeName.businessmenu)}
            >
              <Link to={routeName.businessmenu}>
                <div className="tutor-collapsed">
                  <FcBusinessContact style={{ fontSize: '25px' }} />
                  <Button className="side-bar-tutor">{'Business'}</Button>
                </div>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item
            key={routeName.tutorEditProfile}
            className={this.activeMenu(routeName.tutorEditProfile)}
            onClick={() =>
              isUser
                ? this.onchangeRoute(routeName.userProfile)
                : prviousPath
                  ? this.onchangeTutorCartPage(routeName.tutorEditProfile)
                  : this.onchangeRoute(routeName.tutorEditProfile)
            }
          >
            <Link to={routeName.tutorEditProfile}>
              <div className="tutor-collapsed" ref={this.ref9}>
                <FcEngineering style={{ fontSize: '25px' }} />
                <Button className="side-bar-tutor">
                  {' '}
                  {this.props.t('tutorSidebarSettings')}{' '}
                </Button>
              </div>
            </Link>
          </Menu.Item>
        </Menu>
        <Tour
          open={this.state.open}
          onClose={this.handleSkipTour}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} /{total}
            </span>
          )}
          onChange={(current) => this.handleAfterClose(current + 1)}
          mask={{
            style: {
              boxShadow: 'inset 0 0 15px #333',
            },
          }}
          type="primary"
          steps={
            _.get(this, 'props.tutorProfile.type') !== 'Individual'
              ? this.steps
              : this.steps
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loginDetails: state.authentication,
  tutorProfile: _.get(state, 'tutor.tutorBaseProfile.details'),
  tutorMessages: state.users.messages,
  campsItemsExist: state.camps.campsAddedCartItem,
  itemsExist: state.classes.addedCartItems,
  recentchatlistuser: state.message.recentchatlistuser,
  requestCount: state.common.requestCount,
})

const mapDispatchToProps = (dispatch) => ({
  // onsiderbarLogo: requestParams =>
  // 	dispatch(servicesActions.sidebarLogo(requestParams)),
  // ontutorSearchBox: requestParams =>
  // 	dispatch(servicesActions.ontutorSearchBox(requestParams)),
  onGetRecentMessages: (requestParams) =>
    dispatch(msgAction.getMessagesrecentCount(requestParams)),
  updateProfile: (data, callback) =>
    dispatch(tourGuideActions.onTourGuide(data, callback)),
  getRequestsCounts: (data, callback) =>
    dispatch(classActions.onGetRequestCount(data, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(TutorDashboardPageSidebar)))
