import React, { useState, useEffect } from 'react'
import {Input, Button, Table } from 'antd'
import _, { random } from 'lodash'
import './student-list.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { DEFAULTUSER } from '../../utils/config/assets'
import S3Downloader from '../re-usable/file-to-s3/downloader'
import * as commonActions from '../../store/actions/common/uploads'
import EmptyAnime from '../re-usable/lottie/EmptyAnime'
import Popup from './popup'
import EvaluationForm from './evaluationForm'

const UploadList = (props) => {
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [page, setPage] = useState(1)

  const pageChange = async (page = 1, pageSize = 5) => {
    try {
      await setPage(page)
      const cat_id = _.get(props, 'session', '')
      if (!_.isEmpty(cat_id)) {
        const notesParams = {
          cat: props.cat,
          subcat: props.subcat,
          user: props.user_id ? props.user_id : null,
          user_type: props.user_type ? props.user_type : null,
          cat_id,
          limit: pageSize,
          status: 1,
          s: random(10000),
          page,
          sort: 'created_at.desc',
          expand: 'user_profile,provider_profile',
        }

        console.log('notesParams>>>', notesParams)

        await props.getUploadedList(notesParams)
      }
    } catch (error) {
      console.log('error at upload list, pageChange ', error)
    }
  }

  const customLocale = {
    emptyText: (
      <div>
        <EmptyAnime AnimeType="Empty" loop animeheight={150} />

        <p className="empty-animations">{props.t('noResult')}</p>
      </div>
    ),
  }

  useEffect(() => {
    pageChange()
  }, [])

  const onEvaluateUploads = ({ id }) => {
    const data = {
      id,
    }
  }

  const onDeleteUploads = ({ id }) => {
    try {
      setDeleteLoading(true)
      const data = {
        id,
      }
      props.onDeleteUploads(data, (resp) => {
        if (resp.status === 200) {
          pageChange()
        }
      })
    } catch (error) {
      console.error('Delete error:', error)
    } finally {
      setDeleteLoading(false)
    }
  }

  const participantObj = {
    title: props.t('participants'),
    dataIndex: 'participant',
    key: 'participant',
    width: '12%',
  }

  const filenameObj = {
    title: props.t('fileName'),
    dataIndex: 'filename',
    key: 'filename',
    width: '12%',
  }

  const evaluationStatus = {
    title: 'Evaluation Status',
    dataIndex: 'provider_evaluation_status',
    key: 'provider_evaluation_status',
    width: '15%',
    align: 'center',
  }
  const evaluationRemarks = {
    title: 'Remarks',
    dataIndex: 'provider_remarks',
    key: 'provider_remarks',
    width: '15%',
    align: 'center',
  }
  const downloadBtn = {
    title: props.t('download'),
    dataIndex: 'url',
    key: 'url',
    width: '15%',
    align: 'center',
  }

  const deleteBtn = {
    title: props.t('delete'),
    dataIndex: 'delete',
    key: 'delete',
    width: '10%',
    align: 'center',
  }

  const actionBtn = {
    title: 'Evaluate',
    dataIndex: 'evaluate',
    key: 'Evaluate',
    width: '10%',
    align: 'center',
  }

  const columns = [participantObj, filenameObj, downloadBtn, deleteBtn]
  // Modify the columns array based on the user_type
  let modifiedColumns = [...columns]
  if (props.user_type === 'provider') {
    // If user_type is 'provider', hide the participants and delete columns
    modifiedColumns = modifiedColumns.filter(
      (column) => column.key !== 'participant'
    )
  } else if (props.user_type === 'user') {

    modifiedColumns = [
      filenameObj,
      downloadBtn,
      evaluationRemarks,
      evaluationStatus,
      deleteBtn,
    ]
    
  } else if (props.user_type === 'student') {
    // If user_type is 'user', hide the delete column
    modifiedColumns = [
      participantObj,
      filenameObj,
      downloadBtn,
      evaluationStatus,
      actionBtn,
    ]
  }
  const render = () => {
    let tableDatatest = []
    // dynamically getting upload list, using props.subcat
    const uploaded_list = _.get(
      props,
      ['uploadedList', props.subcat, 'items'],
      []
    )
    const totalCount = _.get(
      props,
      ['uploadedList', props.subcat, 'meta', 'total_count'],
      0
    )
    if (Array.isArray(uploaded_list) && uploaded_list.length > 0) {
      tableDatatest = uploaded_list.map((value, index) => {
        const id = _.get(value, 'id', '')
        const name_title_value =
          value && !_.isEmpty(value.user_profile)
            ? `${_.startCase(_.get(value, 'user_profile.firstname', ''))} ${_.get(value, 'user_profile.lastname', '')}`
            : !_.isEmpty(value.provider_profile)
              ? _.get(value, 'provider_profile.type') === 'Business'
                ? _.get(value, 'provider_profile.businessname', '')
                : `${_.startCase(_.get(value, 'provider_profile.firstname', ''))} ${_.get(value, 'provider_profile.lastname', '')}`
              : ''
        const photo =
          value && !_.isEmpty(value.user_profile)
            ? _.get(value, 'user_profile.photo')
            : _.get(value, 'provider_profile.photo')
        const imageSource = photo || DEFAULTUSER
        if (id) {
          return {
            provider_evaluation_score:value.provider_evaluation_score,
            provider_remarks: value.provider_remarks,
            provider_evaluation_status: value.provider_evaluation_status,
            key: id,
            participant: (
              <div className="table-profile">
                <div>
                  <img src={imageSource} className="profile-image" alt="img" />
                </div>
                <div
                  className="text-truncate"
                  title={name_title_value}
                  style={{ width: '80px' }}
                >
                  {name_title_value}
                </div>
              </div>
            ),
            filename: (
              <div
                title={_.get(value, 'filename')}
                className="text-truncate"
                style={{ width: '100px' }}
              >
                {_.get(value, 'filename')}{' '}
              </div>
            ),
            delete: (
              <Button
                type="primary"
                style={{ width: '80px' }}
                disabled={_.get(props, 'delete', false)}
                loading={deleteLoading}
                danger
                onClick={() => (id ? onDeleteUploads({ id }) : '')}
              >
                {props.t('delete')}
              </Button>
            ),

            evaluate: (
              <Popup
                key={'Evaluate'}
                label="Evaluate"
                width={800}
                component={EvaluationForm}
                params={{
                  id:id
                }}
              />
            ),

            url: <S3Downloader url={value.url} />,
          }
        }
      })
    }

    return (
      <div className="container uploadbox">
        {/* items Header */}

        {props.user === 'host' && (
          <div className="row mb-3">
            <div className=" col-6 tab-content-title">{(props.cat==='events' || props.cat==='competitions' ? props.t('partiUp'):props.t('stuUp'))}</div>
          </div>
        )}
        <div className="row" style={{ margin: 0 }}>
          <Table
            loading={false}
            dataSource={tableDatatest}
            columns={modifiedColumns}
            style={{ width: '100%', overflowX: 'auto' }}
            locale={customLocale}
            pagination={{
              pageSize: 5,
              onChange: (page, pageSize) => pageChange(page, pageSize),
              total: parseInt(totalCount),
              hideOnSinglePage: !props.loading || props.loading,
              showSizeChanger: false,
              current: page,
            }}
          />
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) => ({
  uploadedList: state.uploads,
  loggedInUserDetails: state.authentication,
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteUploads: (requestParams, callback) =>
    dispatch(commonActions.onDeleteUploads(requestParams, callback)),
  getUploadedList: (requestParams) =>
    dispatch(commonActions.getUploadedList(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UploadList))
