import { useEffect, useState } from 'react';
import { Select } from 'antd';
import i18n from 'i18next';

const { Option } = Select;

const languages = [
  { name: 'English', code: 'en' },
  { name: 'French', code: 'fr' },
  { name: 'Spanish', code: 'es' },
  { name: 'Dutch', code: 'de' },
  // { name: 'Tamil', code: 'ta' }
];

function LanguageSelect() {
  const [selectedValue, setSelectedValue] = useState(
    localStorage.getItem('selectedLanguage') || 'en',
  );

  const handleSelect = (value) => {
    i18n.changeLanguage(value);
    console.log(`Selected language code: ${value}`);
    setSelectedValue(value);
    localStorage.setItem('selectedLanguage', value);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedValue);
  }, []);

  return (
    <Select
      style={{ marginRight: '5px' }}
      placeholder="Select a language"
      onSelect={handleSelect}
      defaultValue={selectedValue}
    >
      {languages.map((language) => (
        <Option key={language.code} value={language.code}>
          {language.name}
        </Option>
      ))}
    </Select>
  );
}

export default LanguageSelect;
