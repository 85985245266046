import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';

import translationEN from './locales/en.json';
import translationSP from './locales/es.json';
import translationFr from './locales/fr.json';
import translationDe from './locales/de.json';
import translationTa from './locales/ta.json';

const getUserLanguage = window.navigator.userLanguage || window.navigator.language;

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationSP,
  },
  de: {
    translation: translationDe,
  },
  fr: {
    translation: translationFr,
  },
  ta: {
    translation: translationTa,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getUserLanguage,
    fallbackLng: 'en',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
