import React from 'react'
import { PiStudentBold, PiUsersThreeFill } from 'react-icons/pi'
import { GiCampingTent, GiChampions } from 'react-icons/gi'
import { IoBookSharp } from 'react-icons/io5'
import { SiGoogleclassroom, SiTestcafe } from 'react-icons/si'
import { MdOutlineEvent } from 'react-icons/md'
import _ from 'lodash'
import * as routeName from '../../../../components/route-names'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { FaCampground } from 'react-icons/fa6'
import { TbTimelineEventPlus } from 'react-icons/tb'

const DashboardHeader = (props) => {
  // console.log('props============', props)
  return (
    <div
      style={{
        width: '100%',
        marginLeft: 10,
        justifyContent: 'space-evenly',
        display: 'flex',
      }}
    >
      <div style={{ display: 'flex' }}>
        <Link to={`${routeName.userClasses}`}>
          <div className="card shadow h-100 py-2">
            <div className="card-body">
              <div className="col no-gutters align-items-center text-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1">
                    {props.t('classes')}
                  </div>
                  {/* <div className="h5 mb-0 font-weight-bold text-gray-800">{_.get(props, 'class.total_count', 0)}</div> */}
                </div>
                <div className="col-auto">
                  {/* <PiStudentBold className="user-dash-icon" /> */}
                  <SiGoogleclassroom
                    style={{ fontSize: '35px', color: 'darkorange' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex' }}>
        <Link to={`${routeName.userCamps}`}>
          <div className="card shadow h-100 py-2">
            <div className="card-body">
              <div className="col no-gutters align-items-center text-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1">
                    {props.t('camps')}
                  </div>
                  {/* <div className="h5 mb-0 font-weight-bold text-gray-800">{_.get(props, 'camp.total_count', 0)}</div> */}
                </div>
                <div className="col-auto">
                  {/* <GiCampingTent className="user-dash-icon" /> */}
                  <FaCampground style={{ color: 'green', fontSize: '35px' }} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex' }}>
        <Link to={`${routeName.userEvents}`}>
          <div className="card shadow h-100 py-2">
            <div className="card-body">
              <div className="col no-gutters align-items-center text-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1">
                    {props.t('tutorSidebarMyEvents')}
                  </div>
                </div>
                <div className="col-auto">
                  {/* <MdOutlineEvent className="user-dash-icon" /> */}
                  <TbTimelineEventPlus
                    style={{ color: '#0099ff', fontSize: '35px' }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex' }}>
        <Link to={`${routeName.userCompetitions}`}>
          <div className="card  shadow h-100 py-2">
            <div className="card-body">
              <div
                className="col no-gutters align-items-center text-center "
                style={{ textWrap: 'nowrap' }}
              >
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1">
                    {props.t('tutorSidebarMyCompetitions')}
                  </div>
                  {/* <div className="h5 mb-0 font-weight-bold text-gray-800">{_.get(props, 'competition.total_count', 0)}</div> */}
                </div>
                <div className="col-auto">
                  {/* <GiChampions className="user-dash-icon" /> */}
                  <GiChampions style={{ color: 'gold', fontSize: '35px' }} />
                </div>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div style={{ display: 'flex' }}>
        <a href="https://test.nurtem.com/" target="_blank">
          <div className="card  shadow h-100 py-2">
            <div className="card-body">
              <div className="col no-gutters align-items-center text-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold  text-uppercase mb-1">
                    {'TestPrep'}{' '}
                  </div>
                </div>
                <div className="col-auto">
                  <SiTestcafe className="user-dash-icon" />
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div style={{ display: 'flex' }}>
        <a href="https://course.nurtem.com/" target="_blank">
          <div className="card shadow h-100 py-2">
            <div className="card-body">
              <div className="col no-gutters align-items-center text-center">
                <div className="col mr-2">
                  <div className="text-xs font-weight-bold text-uppercase mb-1">
                    {'Course'}{' '}
                  </div>
                </div>
                <div className="col-auto">
                  <IoBookSharp style={{ color: '#009b92', fontSize: '35px' }} />
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default withTranslation()(DashboardHeader)
