import React from 'react';
import './loader.css';

export class DefaultLoader extends React.Component {
  render() {
    return (
      <div className="loader-item">
        <div className="loader loader-2" />
      </div>
    );
  }
}
