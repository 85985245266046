import * as SecureLS from 'secure-ls';
import { notification, message } from 'antd';
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import { fetchLocations } from './add-class';
import { switchPropertyLoading, reducersName, notifyAPIResponse } from '../../utility';

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});
export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const tutorBusinessinfoSuccess = (data) => ({
  type: actionTypes.tutorBusinessinfoSuccess,
  success: data,
});

export const onStepTwoSubmit = (data) => (dispatch) => {
  const ls = new SecureLS();
  ls.set('contactinfo', { data });
  dispatch(tutorBusinessinfoSuccess(data));
};

export const tutorContactinfoSuccess = (data) => ({
  type: actionTypes.tutorContactinfoSuccess,
  success: data,
});

export const onStepOneSubmit = (data) => (dispatch) => {
  const ls = new SecureLS();
  ls.set('businessinfo', { data });
  dispatch(tutorContactinfoSuccess(data));
};
// Profile INfo Form Set
export const onStepProfileInfoSubmit = (data) => (dispatch) => {
  const ls = new SecureLS();
  ls.set('profileinfo', { data });
};

export const tutorProfileSuccess = (data) => ({
  type: actionTypes.tutorBaseProfile,
  success: data,
});
export const tutorEmptyProfileSuccess = () => ({
  type: actionTypes.tutorBaseProfile,
  success: {},
});

export const tutorProfile = (data) => (dispatch) => {
  axios({
    method: 'get',
    url: '/providers/profile',
    withCredentials: true,
    params: { expand: 'classes,services' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(tutorProfileSuccess(response.data));
        const ls = new SecureLS();
        if (!ls.get('providerinfo')) {
          if (response.data.details.update_progress === 0) {
            history.push(routeName.TutorBaseProfileStep1);
          } else {
            // routeRestriction(data)
          }
        }

        ls.set('providerinfo', { data: response.data });
      }
    })
    .catch((error) => { });
};

export const tutorExtrainfoSuccess = (data) => ({
  type: actionTypes.tutorExtrainfoSuccess,
  success: data,
});

export const onStepThirdSubmit = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/providers/update',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(disableLoading());
        // this following two action will reset baseProfileData
        dispatch(tutorProfileSuccess({}));
        dispatch(tutorProfile({}));
        dispatch(tutorExtrainfoSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onProfileUpdateSuccess = (data) => ({
  type: actionTypes.ProfilesUpdateSuccess,
  success: data,
});

export const onProfileUpdate = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/providers/update',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(disableLoading());
        dispatch(onProfileUpdateSuccess(res.data));
        dispatch(tutorProfileSuccess(res.data));
        dispatch(fetchLocations());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onBusinessProfileUpdateSuccess = (data) => ({
  type: actionTypes.BusinessProfileUpdateSuccess,
  success: data,
});

export const onBusinessProfileUpdate = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/providers/businessprofile',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(disableLoading());
        dispatch(onBusinessProfileUpdateSuccess(res.data));
        dispatch(tutorProfileSuccess(res.data));
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onImageupload = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/providers/update-photo',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((res) => {
      if (res.status === 200) {
        message.success('Profile updated successfully');
        dispatch(tutorProfileSuccess(res.data));
        dispatch(onProfileUpdateSuccess({ ...res.data, isDpUpload: true }));
      }
    })
    .catch((error) => {
      message.error('Profile image failed to upload');
      dispatch(disableLoading());
    });
};

export const onImageRemove = () => (dispatch) => {
  axios({
    method: 'post',
    url: '/providers/photo-remove',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((res) => {
      if (res.status === 200) {
        message.success('Profile image removed successfully');
        dispatch(tutorProfileSuccess(res.data));
        dispatch(onProfileUpdateSuccess({ ...res.data, isDpUpload: true }));
      }
    })
    .catch((error) => {
      message.error('Profile image failed to delete');
      dispatch(disableLoading());
    });
};

export const userHandle = (data) => ({
  type: actionTypes.userHandle,
  success: data,
});
export const onUserHandleSubmit = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: 'providers/handle',
    withCredentials: true,
    data: { user_handle: data },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const responseData = res.data;
        responseData.status = res.status;
        dispatch(userHandle(responseData));
      }
    })
    .catch((error) => {
      if (error.response) {
        const responseData = error.response.data;
        responseData.status = error.response.status;
        dispatch(userHandle(responseData));
      }
    });
};

export const onGalleryupdate = (data) => (dispatch) => {
  dispatch(enableLoading());
  const formData = new FormData();
  for (let x = 0; x < data.files.length; x++) {
    formData.append('photo', data.files[x]);
  }
  if (data.is_removed) {
    for (let y = 0; y < data.is_removed.length; y++) {
      formData.append('is_removed', data.is_removed[y]);
    }
  }
  axios
    .post('/createGallery', formData, {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onProfileUpdateSuccess(response.data));
      }
      // window.location.reload();
    })
    .catch((error) => dispatch(disableLoading()));
};

export const sendNotesToStudent = (requestBody, callback = () => { }, notificationOn = false) => (dispatch) => {
  switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToStudent, true, { [actionTypes.sendNotesToStudent]: {} });
  axios
    .post(
      '/sendNotesToUser',
      {
        ...requestBody,
      },
      { withCredentials: true },
    )
    .then((response) => {
      dispatch({
        type: actionTypes.updateTutorState,
        data: { [actionTypes.sendNotesToStudent]: _.get(response, 'data', {}) },
      });
      notifyAPIResponse('success', 'Notes have been sent to the user successfully!', 'Notes Send', notificationOn);
      callback(_.get(response, 'data', {}));
    })
    .catch((error) => {
      // notifyAPIResponse('error', 'Something went wrong while sending notes to student!. Please try again', 'Notes Failed to Send', notificationOn);
    })
    .finally(() => {
      // always executed
      switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToStudent, false);
    });
};

export const getProvidersProfile = (data) => (dispatch) => {
  axios({
    method: 'get',
    url: '/providers/profile',
    withCredentials: true,
    params: { expand: 'classes,services' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(tutorProfileSuccess(response.data));
        const ls = new SecureLS();
        if (!ls.get('providerinfo')) {
          if (response.data.details.update_progress === 0) {
            history.push(routeName.TutorBaseProfileStep1);
          } else {
            // routeRestriction(data)
          }
        }

        ls.set('providerinfo', { data: response.data });
      }
    })
    .catch((error) => { });
};

/** Send notes for class api */
export const classNotes = (data) => ({
  type: actionTypes.sendNotesToClass,
  success: data,
});
export const onClassNotesSubmit = (data, callback = () => { }) => (dispatch) => {
  switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, true, { [actionTypes.sendNotesToClass]: {} });
  axios({
    method: 'post',
    url: '/notes/create-notes',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(classNotes(res.data));
      callback(_.get(res, 'data', {}));
      if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(classNotes(error.response.data));
      }
    })
    .finally(() => {
      // always executed
      switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, false);
    });
};
// notes delete
export const onClassNotesDelete = (data, callback = () => { }) => (dispatch) => {
  switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, true, { [actionTypes.sendNotesToClass]: {} });
  axios({
    method: 'post',
    url: '/notes/delete',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(classNotes(res.data));
      callback(_.get(res, 'data', {}));
      if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(classNotes(error.response.data));
      }
    })
    .finally(() => {
      // always executed
      switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, false);
    });
};
// notes update
export const onClassNotesUpdate = (data, callback = () => { }) => (dispatch) => {
  switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, true, { [actionTypes.sendNotesToClass]: {} });
  axios({
    method: 'post',
    url: '/notes/update',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(classNotes(res.data));
      callback(_.get(res, 'data', {}));
      if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      if (error.response) {
        dispatch(classNotes(error.response.data));
      }
    })
    .finally(() => {
      // always executed
      switchPropertyLoading(dispatch, reducersName.tutor, actionTypes.sendNotesToClass, false);
    });
};
