import React from 'react';
import { Form } from 'antd';
import { PasswordInput, Button } from '../../../components/antd-components/index.tsx';
import { AiFillLock, AiOutlineEyeInvisible, AiOutlineEye } from 'react-icons/ai';
import './reset-password.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/authentication/authentication';
import axios from '../../../utils/axios';
import { withErrorHandler } from '../../../utils/handler';
import history from '../../../utils/history';
import css from '../../../utils/CSSUtility';

const FormItem = Form.Item;
class Reset_Password extends React.Component {
  state = {
    confirmDirty: false,
  };

  passwordValidationRules = [
    { required: true, message: this.props.t('enterPass') },
    // { min: 8, message: 'Password must be at least 8 characters long' },
    { max: 16, message: this.props.t('enterPass2') },
    {
      pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]+/,
      message: this.props.t('enterPass3'),
    },
  ];

  onFinish = (credentials, signInType) => {
    const query = new URLSearchParams(this.props.location.search);
    const urlParams = new URLSearchParams(this.props.match.params);
    const token = urlParams.get('token');
    const email = query.get('email');
    if (credentials.password === credentials.confirm) {
      const requestParams = {};
      requestParams.email = email;
      requestParams.token = token;
      requestParams.password = credentials.password;
      this.props.changePassword(requestParams);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.changePasswordResponse !== this.props.changePasswordResponse
    ) {
      if (
        this.props.changePasswordResponse
        && this.props.changePasswordResponse.status === 200
      ) {
        history.push({
          pathname: '/sign-in',
        });
      }
    }
  }

  render() {
    return (
      <div className="login-page-container student-login-open">
        <div className="row login-height align-items-center justify-content-around mb-2">
          <div className="new-password student-login-modal ">
            <div className="row student-login-modal-inner align-items-center">
              <div className="passphoto">
                <img className="resetImag" src={require('../../../assets/images/reset-password.jpg')} alt="img" />
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-4" style={{ boxShadow: '0px 0px 0px', height: 300, borderRadius: 10 }}>
                <div className="row">
                  <div className="col-12 mb-4">
                    <div
                      className="text-center"
                      style={css.heading1}
                    >
                      {this.props.t('newPassword')}
                    </div>
                  </div>
                  <div className="col-12 passwordInput">
                    <Form onFinish={(e) => this.onFinish(e, 'user')} className="login-form reset-password-form">
                      <FormItem name="password" rules={this.passwordValidationRules} hasFeedback>
                        <PasswordInput
                          size="middle"
                          prefix={<AiFillLock style={{ fontSize: 20 }} />}
                          type="password"
                          placeholder={this.props.t('newPassword')}
                          iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                        />

                      </FormItem>
                      <FormItem
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[{
                          required: true, message: this.props.t('authenticationRequiredPasswordValidationMessage'),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value || getFieldValue('password') === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(this.props.t('authenticationRequiredPasswordValidationMessage2'));
                          },
                        }),
                        ]}
                      >
                        <PasswordInput
                          size="middle"
                          prefix={<AiFillLock style={{ fontSize: 20 }} />}
                          type="password"
                          placeholder={this.props.t('confirmPassword')}
                          iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                        />

                      </FormItem>

                      <div className="full-width mb-3 mt-2">
                        <div className="row login-input" style={{ justifyContent: 'center' }}>
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style={{ padding: 0 }}>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="btn btn-sm btn-login"
                              loading={this.props.loading}
                            >
                              {this.props.t('submit')}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  changePasswordResponse: state.authentication.changePasswordResponse,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (data) => dispatch(actions.changePassword(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(withTranslation()(Reset_Password), axios));
