import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import _ from 'lodash'

const baseProfileFormMeta = {
  service_provider_zoom: {
    form_item: {
      name: 'zoom_id',
      rules: [
        {
          required: false,
        },
        () => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve()
            }
            const linkRegex =
              /^(http[s]?:\/\/)?(www\.)?((linkedin\.com\/in\/[\w-]+)|(linkedin\.com\/company\/[\w-]+)|[\w.-]+\.[a-zA-Z]{2,}(\/[\w\.-]*)*)\/?$/
            if (!linkRegex.test(value)) {
              return Promise.reject('Please enter a valid Linkedin profile link.')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { placeholder: 'Enter your linkedin profile link' },
  },
  service_provider_skype: {
    form_item: {
      name: 'skype_id',
      rules: [
        {
          required: false,
        },
        () => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve()
            }
            const linkRegex = /^http[s]?:\/\/(www\.)?x\.com\/[a-zA-Z0-9_]+$/;
            if (!linkRegex.test(value)) {
              return Promise.reject('Please enter a valid X profile link.')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { placeholder: 'Enter your X Profile link' },
  },
  service_provider_facebook: {
    form_item: {
      name: 'facebook_id',
      rules: [
        {
          required: false,
        },
        () => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve()
            }

            const linkRegex = /^(http[s]?:\/\/)?(www\.)?facebook\.com\/[\w.-]+\/?$/;

            if (!linkRegex.test(value)) {
              return Promise.reject('Please enter a valid Facebook profile link.')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 30, placeholder: 'Enter your Facebook profile link' },
  },
  service_provider_whatsapp: {
    form_item: {
      name: 'whatsapp_number',
      rules: [
        {
          required: false,
        },
        () => ({
          validator(rule, value) {
            if (!value) {
              return Promise.resolve()
            }

            const whatsappNumberRegex = /^\+?[1-9]\d{1,14}$/;

            if (!whatsappNumberRegex.test(value)) {
              return Promise.reject('Please enter a valid WhatsApp number.')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 15, placeholder: 'Enter your WhatsApp number' },
  },
}
export default baseProfileFormMeta
