import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import ViewClassDetails from '../public/view-class-details';
import ViewCampsDetails from '../public/view-camp-details';
import ViewCompetitionDetails from '../public/view-competition-details';
import viewEventDetails from '../public/view-event-details';

// import UserDashboard from "./dashboard/old";
import UserNewDashboard from './dashboard/new/index';
import UserMyCart from './user-cart/index';
import WishList from './wishlist';
import WaitList from './waitlist';

import SearchComponent from '../public/search-class-camps/search';
import Message from './message/index';
import UserCamps from './user-camps/camps';
import UserCompetitions from './user-competitions/competitions';
import UserEvents from './user-events/events';
import CampDetailView from './user-camps/detail-view';
import CompetitionDetailView from './user-competitions/detail-view';
import EventsDetailView from './user-events/detail-view';
import UserClasses from './user-classes/classes';
import ClassDetailView from './user-classes/detail-view';
import '../../assets/fonts/nurtem/style.css';
import * as routeName from '../../components/route-names';
import TutorPublicProfile from '../public/tutor-profile/index';
import paymentReport from './payment-report/index';
import attendanceReport from './attendance-report/index';
import UserNewProfile from './profile/new/index';
import UserResetPassword from './setting/setting';
import calendar from './calendar/index';
import * as actions from '../../store/actions/users';
import { connect } from 'react-redux';
import GuestFlowScreen from './guest-flow/guest-flow';
import TermsAndConditions from '../public/terms-and-conditions/index';
import PrivacyPolicy from '../public/privacy-policy/index';
import CookiePolicy from '../public/cookie-policy/index';
import SecureLS from 'secure-ls';
import Invoice from './payment-report/invoice-generator';
import BatchView from '../public/view-batch/batch-view';
// import AttendanceReportFull from './attendance-report/report';
import _ from 'lodash';
import payments from './payments/index';

const ls = new SecureLS();

class LayoutContent extends React.Component {
  componentDidMount() {
    this.props.onUserProfile();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (this.props.location.pathname.indexOf(routeName.guestUpdate)) {

      } else {
        ls.set('guestUsersRoute', false);
      }
    }
  }

  render() {
    const account_type = _.get(this, 'props.userBaseProfile.details.user.account_type');

    return (
      <div style={{ width: '100%', float: 'left' }}>
        <Switch>
          <Route path={routeName.viewClassDetails} component={ViewClassDetails} />
          <Route path={routeName.viewCampDetails} component={ViewCampsDetails} />
          <Route path={routeName.viewCompetitionDetails} component={ViewCompetitionDetails} />
          <Route path={routeName.viewEventDetails} component={viewEventDetails} />

          <Redirect from="/" exact to={routeName.userDashboard} />
          <Route path={routeName.userDashboard} component={UserNewDashboard} />
          {account_type !== 'dependent' && <Route path={routeName.guestUpdate} component={GuestFlowScreen} />}
          <Route path={routeName.search} component={SearchComponent} />
          <Route path={routeName.TutorPublicProfile} component={TutorPublicProfile} />
          {account_type !== 'dependent' && <Route path={routeName.myCart} component={UserMyCart} />}
          {account_type !== 'dependent' && <Route path={routeName.myWishList} component={WishList} />}

          {account_type !== 'dependent' && <Route path={routeName.myWaitList} component={WaitList} />}

          <Route path={routeName.userCamps} component={UserCamps} />
          <Route path={routeName.userCompetitions} component={UserCompetitions} />
          <Route path={routeName.userEvents} component={UserEvents} />
          <Route path={routeName.userClasses} component={UserClasses} />
          <Route path={routeName.userCampView} component={CampDetailView} />
          <Route path={routeName.userViewBatch} component={BatchView} />
          <Route path={routeName.userCompetitionView} component={CompetitionDetailView} />
          <Route path={routeName.userEventView} component={EventsDetailView} />
          <Route path={routeName.userClassView} component={ClassDetailView} />
          <Route path={routeName.message} component={Message} />
          <Route path={routeName.paymentReportUser} component={paymentReport} />
          <Route path={routeName.attendanceReportUser} component={attendanceReport} />
          {/* <Route path={routeName.attendanceReportUserFull} component={AttendanceReportFull} /> */}
          <Route path={routeName.userInvoice} component={Invoice} />
          {account_type !== 'dependent' && <Route path={routeName.userProfile} component={UserNewProfile} />}
          <Route path={routeName.userCalendar} component={calendar} />
          <Route path={routeName.usersettings} component={UserResetPassword} />
          <Route path={routeName.termsAndConditions} component={TermsAndConditions} />
          <Route path={routeName.PrivacyPolicy} component={PrivacyPolicy} />
          <Route path={routeName.CookiePolicy} component={CookiePolicy} />

          <Route path={routeName.userpayment} component={payments} />

        </Switch>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  userBaseProfile: state.userProfile.userBaseProfile,
});

const mapDispatchToProps = (dispatch) => ({
  onUserProfile: (data) => dispatch(actions.userProfile()),
  guestUserRouteActive: (request) => dispatch(actions.guestUserDepententSelect(request)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutContent);
