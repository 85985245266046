import { AiOutlineUpload } from 'react-icons/ai';
import {
  Button, Upload, Form, notification,
} from 'antd';
import React from 'react';
import _ from 'lodash';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import axios from '../../../utils/axios';

dayjs.extend(utc);
dayjs.extend(timezone);
function FileUpload(params) {
  const clssdet = _.get(params, 'clssdet', {});
  const step = _.get(params, 'step', 1);
  const disabled = _.get(params, 'disabled', false);
  const callback = _.get(params, 'callback', () => { });
  const callbackMore = _.get(params, 'callbackMore', () => { });
  const url = _.get(params, 'url', '/files/onboard-users');
  const timezone = (dayjs.tz.guess());
  const time = new Date();

  const emptyObj = _.isEmpty(clssdet);

  const props = {
    data: clssdet,
    onChange: (stat) => {
      // console.log("upload file > state ", stat)
    },
    status: 'success',
    name: 'uploadedfile',
    customRequest(options) {
      const { onSuccess, file, onProgress } = options;

      const fmData = new FormData();
      fmData.append('uploadedfile', file);
      fmData.append('timezone', timezone);
      fmData.append('time', time);
      if (!emptyObj) {
        fmData.append('type', _.get(clssdet, ['type'], ''));
        fmData.append('classID', _.get(clssdet, ['details'], ''));
        fmData.append('provider', _.get(clssdet, ['provider'], ''));
      }

      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        withCredentials: true,
        onUploadProgress: (event) => {
          onProgress({ percent: (event.loaded / event.total) * 100 }, file);
          onSuccess();
        },

      };

      axios
        .post(url, fmData, config)
        .then((res) => {
          if (res.status === 200) {
            callback(res.data.details);
            notification.success({
              message: res.data.message,
            });
          }

          // get current students
          // history.push(_.get(clssdet, ['redirecturl']))
          if (!emptyObj) {
            const requestParams = {
              limit: 1000,
              expand: 'user,dependent_user',
            };

            const ctype = _.get(clssdet, 'type', '');

            if (ctype === 'classes') {
              requestParams.class_details = _.get(clssdet, 'details', '');
            } else if (ctype === 'camps') {
              requestParams.camp_details = _.get(clssdet, 'details', '');
            } else {
              requestParams.competition_details = _.get(clssdet, 'details', '');
            }
            requestParams.s = Math.random();

            callbackMore(requestParams);
          }
        })
        .catch((err) => {
          console.log('res.status catch bloc >>>>>> ', err);
          // notification.error({
          //   message: "Failed to import students.",
          // });
        });
    },

  };

  return (
    <Form.Item>
      <Upload {...props} listType="picture">
        <Button disabled={(Object.keys(clssdet).length === 0 && step === '3') || disabled} icon={<AiOutlineUpload style={{ fontSize: 20 }} />}>{params.t('clicktoUp')}</Button>
      </Upload>
    </Form.Item>
  );
}

export default withTranslation()(FileUpload);
