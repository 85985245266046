import * as actionTypes from '../../actions/public/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: false,
  sessions: {},
  loading: {
    loading: false,
  },
};

const loading = (state, action) => ({ ...state, loading: action.payload });

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const sessions = (state, action) => {
  if (state.sessions.length > 10) {
    for (const i in state.sessions) {
      delete state.sessions[i];
    }
    // delete state.sessions[Object.keys(state.sessions)[0]];
  }
  return { ...state, sessions: { ...state.sessions, [action.payload.group_id]: action.payload.data } };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.loading:
      return loading(state, action);
    case actionTypes.sessions:
      return sessions(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
