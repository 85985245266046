import React from 'react';
import {
  Button, Table, Checkbox, Popconfirm,
} from 'antd';
import { AiOutlineCalendar, AiFillLeftCircle, AiFillRightCircle } from 'react-icons/ai';
import './tutor-dashboard-attendance.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import _ from 'lodash';
import async from 'async';
import * as attendances from '../../../store/actions/tutor/attendances';
import * as action from '../../../store/actions/tutor/camps';
import * as competitionActions from '../../../store/actions/tutor/competitions';
import * as classActions from '../../../store/actions/tutor/add-class';
import * as userAction from '../../../store/actions/users';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';

let class_id;

class TutorCompetitionAttendance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthDate: dayjs().format('MMM YYYY'),
      page: 1,
      dataSource: [],
      columns: [],
      showTextBox: false,
      showTextBoxes: false,
      date: {},
      selectedDate: null,
      toDate: null,
      isLoading: false,
      selectedButton: null,
      dataArray: [],
      transformedData: {
        session_details: '',
        date_marked: [],
        date_unmarked: [],
      },
    };
  }

  async componentDidMount() {
    /** Window scroll to top */
    if (window.location.hash) window.scroll({ top: 0, behavior: 'smooth' });
    setTimeout(() => { window.scroll({ top: 0, behavior: 'smooth' }); }, 1);

    class_id = this.props.match.params.classId;
    if (class_id) {
      try {
        await Promise.all([
          this.props.onsessionDetail({s: Math.random(), id: class_id, expand: 'services,provider', type: 'competitions' }),
          this.props.onGetAttendenceList({s: Math.random(), session_details: class_id, user_type: 'provider', limit: 100 }),
          this.props.getCompetitionStudents({
            s: Math.random(),
            competition_details: class_id,
            limit: 100,
            status: 1,
            // page: page,
            sort: 'created_at.desc',
            expand: 'competition_details,user,dependent_user',
          }),
        ]);
        this.setState({ class_id });
      } catch (error) {
        console.log('err in competition att', error);
      }
    }
  }

  apiCall = async () => {
    const class_id = this.props.match.params.classId;
    if (class_id) {
      try {
        await Promise.all([
          this.props.onsessionDetail({ s: Math.random(),id: class_id, expand: 'services,provider', type: 'competitions' }),
          this.props.onGetAttendenceList({s: Math.random(), session_details: class_id, user_type: 'provider', limit: 100 }),
          this.props.getCompetitionStudents({
            s: Math.random(),
            competition_details: class_id,
            limit: 100,
            status: 1,
            // page: page,
            sort: 'created_at.desc',
            expand: 'competition_details,user,dependent_user',
          }),
        ]);
        this.setState({ class_id });
        this.tablePreviewcall();
      } catch (error) {
        console.log(error);
      }
    }
  };

  async componentDidUpdate(prevprops, prevState) {
    if (this.props.sessionDetail && typeof this.props.attendances === 'object' && !Array.isArray(this.props.attendances) && class_id === this.props.sessionDetail.id) {
      if (prevprops.createdAttendance !== this.props.createdAttendance
                || prevprops.cancelledAttendance !== this.props.cancelledAttendance) {
        if (this.props.createdAttendance.status) {
          this.setState({ isLoading: false });
        }
        this.apiCall();
      }

      if ((prevState.dataSource !== this.state.dataSource
                && _.size(_.get(prevState, 'dataSource')) !== _.size(_.get(this, 'state.dataSource')))
                || (prevState.columns !== this.state.columns
                    && _.size(_.get(prevState, 'columns')) !== _.size(_.get(this, 'state.columns')))
                || prevState.monthDate !== this.state.monthDate
      ) {
        this.tablePreviewcall();
      }

      if ((prevprops.attendances !== this.props.attendances)
                || (prevprops.competitionStudents !== this.props.competitionStudents
                    && _.size(_.get(prevprops, 'competitionStudents')) !== _.size(_.get(this, 'props.competitionStudents')))) {
        this.tablePreviewcall();
      }
    }
  }

  onCancelClass = async (cancel_date, column_key, reason) => {
    const session_details = this.props.sessionDetail;
    const cancelled_days = session_details.cancelled_days ? session_details.cancelled_days : [];
    cancel_date = dayjs(cancel_date);
    const credit_booking_dates = dayjs(cancel_date).format('MMM DD,YYYY');
    const addedon = dayjs();
    const type = 'cancelled_class';
    if (_.findIndex(cancelled_days, (o) => _.isMatch(o.date, cancel_date)) === -1) {
      cancelled_days.push({
        date: dayjs(cancel_date).format('MMM DD,YYYY'),
        type,
        addedon,
        reason: reason || 'N/A',
        for: 'camps',
      });
      const data = {
        params: { id: session_details.id },
        data: { cancelled_days },
      };
      const request = {
        previous_credits: _.size(cancelled_days),
        class_id: _.get(session_details, 'id'),
        booking_date: credit_booking_dates,
      };
      await this.props.cancelClass(data);
      await this.props.credits(request);
    }
  };

  onSubmitAtt = () => {
    if (!this.state.transformedData.date_marked.length && !this.state.transformedData.date_unmarked.length) {
      return;
    }
    const data = this.state.transformedData;
    this.setState({ isLoading: true });
    this.props.createAttendance({ ...data, provider: this.props.tutorBaseProfile.details.id });

    this.setState({
      transformedData: {
        session_details: '',
        date_marked: [],
        date_unmarked: [],
      },
    });
  };

  onCollectData = async (checked, session_details, user, dependent_user, mark_date, id) => {
    const dataArray = [...this.state.dataArray];
    const transformedData = { ...this.state.transformedData };
    transformedData.session_details = session_details.competition_details.id;
    transformedData.class_details = session_details.competition_details.group_id;
    transformedData.type = 'camps';
    const data = {
      user,
      dependent_user,
      mark_date: dayjs(mark_date).format('MMM DD,YYYY'),
      session_details: session_details.competition_details.id,
      class_information_id: session_details.id,
    };

    if (!checked) {
      const index = dataArray.findIndex((item) => item.class_information_id === session_details.id && item.mark_date === mark_date);
      if (index > -1) {
        dataArray.splice(index, 1);
      }
      transformedData.date_unmarked.push({ session_details: session_details.competition_details.id, class_information_id: session_details.id, marked_date: dayjs(mark_date).format('MMM DD,YYYY') });
    } else {
      dataArray.push(data);

      const dateMarkedIndex = transformedData.date_marked.findIndex((item) => item.marked_date === data.mark_date);
      if (dateMarkedIndex === -1) {
        transformedData.date_marked.push({
          marked_date: data.mark_date,
          attendance: [
            {
              user: data.user,
              dependent_user: data.dependent_user,
              class_information_id: data.class_information_id,
            },
          ],
        });
      } else {
        transformedData.date_marked[dateMarkedIndex].attendance.push({
          user: data.user,
          dependent_user: data.dependent_user,
          class_information_id: data.class_information_id,
        });
      }

      transformedData.date_unmarked = transformedData.date_unmarked.filter((item) => !(item.class_information_id === session_details.id && item.marked_date === dayjs(mark_date).format('MMM DD,YYYY')));
    }

    transformedData.date_marked = dataArray.reduce((result, item) => {
      const marked_date = item.mark_date;

      if (!result[marked_date]) {
        result[marked_date] = {};
      }

      if (!result[marked_date].attendance) {
        result[marked_date].attendance = [];
      }

      result[marked_date].attendance.push({
        user: item.user,
        dependent_user: item.dependent_user,
        class_information_id: item.class_information_id,
      });

      return result;
    }, {});

    transformedData.date_marked = Object.entries(transformedData.date_marked).map(([marked_date, attendance]) => ({
      marked_date,
      attendance: attendance.attendance,
    }));
    this.setState({ dataArray, transformedData });
  };

  tablePreviewcall = () => {
    const { columns } = this.state;
    if (this.props.sessionDetail && this.props.attendances) {
      this.buildColumns(this.props.sessionDetail, this.props.attendances, (column_data) => {
        if (_.size(column_data) !== _.size(_.get(this, 'state.columns'))) {
          this.setState({
            columns: column_data,
          });
        }
      });
    }
    if (this.props.attendances && this.props.competitionStudents && this.props.competitionStudents.items) {
      try {
        this.buildDataSource(columns, this.props.competitionStudents.items, this.props.attendances, (source_data) => {
          this.setState({
            dataSource: source_data,
          });
        });
      } catch (error) {
        console.log('error at competition-attendance in  buildDataSource function', error);
      }
    }
  };

  buildColumns = (details, attendeesDetails, callback) => {
    let column_data = [{
      title: this.props.t('stuName'),
      dataIndex: 'name',
      key: 'name',
      children: [
        {
          title: <div />,
          dataIndex: 'name',
          key: 'name',
          width: '20%',
        },
      ],
    }];
    const weekDaysCount = details.session_dates;
    const currentFilterDate = dayjs(this.state.monthDate, 'MM YYYY').format('MMM DD,YYYY');
    const startOfMonth = dayjs(currentFilterDate).startOf('month').format('MMM DD,YYYY');
    const endOfMonth = dayjs(currentFilterDate).endOf('month').format('MMM DD,YYYY');

    const finalData = _.map(weekDaysCount, (value, index) => ((dayjs(startOfMonth).isSame(value, 'day') || dayjs(startOfMonth).isBefore(value, 'day'))
                && (dayjs(endOfMonth).isSame(value, 'day') || dayjs(endOfMonth).isAfter(value, 'day')) ? value : null));

    _.compact(finalData).map((start, index) => {
      const selected_date = dayjs(start).format('MMM DD,YYYY');

      if ((dayjs(startOfMonth).isSame(selected_date, 'day') || dayjs(startOfMonth).isBefore(selected_date, 'day'))
                && (dayjs(endOfMonth).isSame(selected_date, 'day') || dayjs(endOfMonth).isAfter(selected_date, 'day'))) {
        const column_index = index;
        const hideCancelClass = _.get(this, 'props.classDetails.provider.type') === 'Individual' || 'Business' ? 'ant-btn-sm btn-cancel' : 'ant-btn-sm btn-cancel';

        const temp = {
          title: dayjs(selected_date).format('MMM DD,YYYY'),
          attendance_date: selected_date,
          is_cancelled: false,
          width: '20%',
          children: [{
            title: <div>
              <Popconfirm
                title={this.props.t('dateresh2')}
                onConfirm={() => this.onCancelClass(selected_date, column_index)}
                okText="Yes"
                cancelText="No"
              >
                <Button id={`cancel_btn_column_${column_index}`} className={hideCancelClass}>Cancel Class</Button>
              </Popconfirm>
                   </div>,
            dataIndex: `column_${column_index}`,
          }],
        };

        Object.keys(attendeesDetails).length > 0 && _.map(attendeesDetails.date_marked, (att, i) => {
          const date_marked = att.marked_date;

          if (_.isEqual(date_marked, selected_date)) {
            temp.children = [{
              title: <div>
                <Button id={`cancel_btn_column_${column_index}`} className="ant-btn-sm btn-cancel-disable" disabled>
                  Cancel Class
                </Button>
              </div>,
              dataIndex: `column_${column_index}`,
            }];
          }
        });
        temp[`column_${column_index}`] = selected_date;
        column_data.push(temp);
        return null;
      }
      return null;
    });

    if (_.size(column_data) > 1 && _.size(column_data) < 3) {
      column_data = [...column_data];
    }
    callback(column_data);
  };

  buildDataSource = (columns, competitionStudents, attendances, callback) => {
    const params = new URLSearchParams(window.location.search);
    const source_data = [];
    const checkboxAction = this.onCollectData;
    const studentslistfilters = _.uniqBy(competitionStudents, (n) => (!_.isEmpty(n.dependent_user) && n.dependent_user.id) || (n.user && n.user.id));

    async.forEachOf(studentslistfilters, (value, key, student_callback) => {
      if ((_.get(value, 'booking_dates')
                && (_.size(_.get(value, 'booking_dates')) > 0
                    && !_.isDate(new Date(_.get(value, 'booking_dates')))))
                || _.size(_.get(value, 'additional_details', [])) > 0) {
        const bookdates = !_.isEmpty(_.get(value, 'booking_dates')) && !_.isDate(new Date(_.get(value, 'booking_dates'))) ? _.get(value, 'booking_dates', []) : _.get(value, 'additional_details', []);

        _.map(bookdates, (booking, index) => {
          const temp = {};

          if (!params.has('day')) {
            temp.name = _.get(value, 'dependent_user.id') ? `${_.startCase(_.get(value, 'dependent_user.firstname'))} ${
              _.get(value, 'dependent_user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'dependent_user.lastname', ''))[0]}.` : ''}` : `${value.user.firstname} ${
              _.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}`;
            temp.key = `row_${key}`;
            const user = value.user.id;
            const dependent_user = value.dependent_user ? value.dependent_user.id : null;
            let arr = columns;
            arr = _.tail(arr);
            async.forEachOf(arr, (column_value, column_key, column_callback) => {
              let checked = !!0;
              const current_date = dayjs().format('MMM DD,YYYY');

              const column_date = column_value.attendance_date;
              temp.date = column_date;
              if (attendances && _.size(attendances.date_marked) === 0) {
                temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
                  ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
                  : _.isEmpty(column_date)
                    ? <div />
                    : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
              }
              let id = null;
              async.forEachOf(attendances.date_marked, (sub_value, sub_key) => {
                const mark_date = sub_value.marked_date;
                const att_name = sub_value.attendance;
                async.forEachOf(att_name, (sub_value, sub_key, attendance_callback) => {
                  const attendance_user = !_.isEmpty(_.get(sub_value, 'dependent_user')) ? _.get(sub_value, 'dependent_user') : _.get(sub_value, 'user');

                  if (mark_date === column_date && _.isEmpty(_.get(value, 'dependent_user')) && user === attendance_user) {
                    checked = !!1;
                    id = sub_value.id;
                    temp[`column_${column_key}`] = <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
                  } else {
                    temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
                      ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
                      : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
                  }
                  if (mark_date === column_date && dependent_user === attendance_user) {
                    checked = !!1;
                    id = sub_value.id;
                    temp[`column_${column_key}`] = <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
                  } else {
                    temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
                      ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
                      : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
                  }
                  attendance_callback();
                });
              });

              column_callback();
            });
            source_data.push(temp);
            student_callback();
          }
        });
      } else {
        const temp = {};
        temp.name = _.get(value, 'dependent_user.id') ? `${_.startCase(_.get(value, 'dependent_user.firstname'))} ${
          _.get(value, 'dependent_user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'dependent_user.lastname', ''))[0]}.` : ''}` : `${value.user.firstname} ${
          _.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}`;
        temp.key = `row_${key}`;
        const user = value.user.id;
        const dependent_user = value.dependent_user ? value.dependent_user.id : null;
        let arr = columns;
        arr = _.tail(arr);
        async.forEachOf(arr, (column_value, column_key, column_callback) => {
          let checked = !!0;
          const current_date = dayjs().format('MMM DD,YYYY');

          const column_date = column_value.attendance_date;
          temp.date = column_date;
          if (attendances && _.size(attendances.date_marked) === 0) {
            temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
              ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
              : _.isEmpty(column_date)
                ? <div />
                : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
          }
          let id = null;
          async.forEachOf(attendances.date_marked, (sub_value, sub_key) => {
            const mark_date = sub_value.marked_date;
            const att_name = sub_value.attendance;
            async.forEachOf(att_name, (sub_value, sub_key, attendance_callback) => {
              const attendance_user = !_.isEmpty(_.get(sub_value, 'dependent_user')) ? _.get(sub_value, 'dependent_user') : _.get(sub_value, 'user');

              if (mark_date === column_date && _.isEmpty(_.get(value, 'dependent_user')) && user === attendance_user) {
                checked = !!1;
                id = sub_value.id;
                temp[`column_${column_key}`] = <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
              } else {
                temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
                  ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
                  : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
              }
              if (mark_date === column_date && dependent_user === attendance_user) {
                checked = !!1;
                id = sub_value.id;
                temp[`column_${column_key}`] = <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
              } else {
                temp[`column_${column_key}`] = (dayjs(column_date).isAfter(current_date) || column_value.is_cancelled === true)
                  ? <Checkbox disabled className={`checkbox_column_${column_key}`} defaultChecked={checked} />
                  : <Checkbox disabled={_.get(column_value, 'is_cancelled', false)} defaultChecked={checked} onChange={(e) => checkboxAction(e.target.checked, value, user, dependent_user, column_date, id)} />;
              }
              attendance_callback();
            });
          });

          column_callback();
        });
        source_data.push(temp);
        student_callback();
      }
    });
    callback(source_data);
  };

  onChangeMonth = (type, value) => {
    this.setState({
      dataSource: [],
      columns: [],
    });
    if (type === 'next') {
      this.setState({
        monthDate: dayjs(value, 'MM YYYY').add(1, 'M').format('MM YYYY'),
      });
    } else {
      this.setState({
        monthDate: dayjs(value, 'MM YYYY').subtract(1, 'months').format('MM YYYY'),
      });
    }
    this.apiCall();
  };

  render() {
    const { monthDate, dataSource, columns } = this.state;
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 nurtem-profile-card p-4 ">
        <div className="row">
          <ScrollToTop />
          <div className="col-12 mb-3">
            <div className="nurtem-profile-card-title">{this.props.t('stuAtt')}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="nurtem-profile-card-title">
              {this.props.t('stuName')}
              {' '}
              :
              {' '}
              {' '}
              <span style={{ fontWeight: '400' }}>{_.get(this, 'props.sessionDetail.session_name')}</span>
            </div>
          </div>

          <div className="col-12 mb-3">
            <Button onClick={() => this.onSubmitAtt()} loading={this.state.isLoading} className="submit-att-button">{this.props.t('stuAtt')}</Button>
          </div>

          <div className="col-12">
            <div className="nurtem-profile-card p-4 border">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  <Button onClick={() => this.onChangeMonth('prev', monthDate)} className="btn-control">
                    <AiFillLeftCircle />
                    {' '}
                    {this.props.t('prev')}
                  </Button>
                </div>
                <div className="col d-flex align-items-center justify-content-center" style={{ fontWeight: '600' }}>
                  <AiOutlineCalendar className="mr-3" />
                  {' '}
                  {monthDate}
                </div>
                <div className="col-auto">
                  <Button onClick={() => this.onChangeMonth('next', monthDate)} className="btn-control">
                    {this.props.t('next')}
                    {' '}
                    <AiFillRightCircle />
                  </Button>
                </div>
              </div>

              <div className="row tutor-attendance-page tutor-tab nurtem-table mt-4">
                <div className="col-12 p-0 table-responsive">
                  <Table
                    sticky={false}
                    style={{ maxWidth: '1200px' }}
                    scroll={{ x: 1200 }}
                    className="table"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  attendances: state.attendances.attendancesList.data.items,
  competitionStudents: state.competitionsDetails.competitionStudents,
  sessionDetail: state.campsDetails.sessionDetail.data,
  createdAttendance: state.attendances.AttendenceCreation,
  loading: state.attendances.disableLoading,
  deletedAttendance: state.attendances.deleteAttendenceInfo.data,
  cancelledAttendance: state.classesDetails.tutorClassDetails.details,
});

const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) => dispatch(action.onGetsessionDetail(requestParams)),
  getCompetitionStudents: (requestParams) => dispatch(competitionActions.getCompetitionStudents(requestParams)),
  createAttendance: (data) => dispatch(attendances.CreateAttendence(data)),
  onGetAttendenceList: (requestParams) => dispatch(attendances.getAttendenceList(requestParams)),
  deleteAttendance: (data) => dispatch(attendances.onDeleteAttendence(data)),
  cancelClass: (data) => dispatch(classActions.cancelledClass(data)),
  credits: (data) => dispatch(userAction.bookingCredits(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TutorCompetitionAttendance));
