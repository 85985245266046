import * as actionTypes from './actionTypes';
import axios from '../../../../utils/axios';
import * as campActionTypes from '../../tutor/actionTypes';

export const onSuccess = (data) => ({
  type: actionTypes.onSuccess,
  success: data,
});
export const tutorPublicDetails = (data) => ({
  type: actionTypes.tutorPublicDetails,
  success:
    data,
});
export const onTutorClasses = (data) => ({
  type: actionTypes.onTutorClasses,
  success: data,
});
export const classesListLoading = (data) => ({
  type: actionTypes.classesListLoading,
  success: data,
});
export const requestCompleted = () => ({
  type: actionTypes.requestCompleted,
  requestCompleted: { requestCompleted: true },
});
export const turnOnTutorPublicDetailsLoader = () => ({
  type: actionTypes.tutorPublicDetailsLoadingInProgress,
  value: true,
});
export const errorOccurred = (error) => ({
  type: actionTypes.errorOccurred,
  errorOccurred: { errorOccurred: { status: true, details: error } },
});



export const tutorPublicProfile2 = async (userHandle) => {
  
  try {
    if (userHandle) {
      const response = await axios({
        method: 'get',
        url: 'providers/public/profile',
        withCredentials: true,
        params: { userHandle },
        headers: { 'Content-Type': 'application/json' },
      });

      return response.data.details; // Return the response data
    } else {
      return false

    }
  } catch (error) {
    return false
  }
};

export const getTutorPublicProfile = (userHandle) => (dispatch) => {
  

  dispatch({
    type: actionTypes.tutorPublicDetailsLoadingInProgress,
    value: true,
  });
  axios({
    method: 'get',
    url: 'providers/public/profile',
    withCredentials: true,
    params: { userHandle },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(tutorPublicDetails(res.data.details));
      dispatch(requestCompleted());
    })
    .catch((error) => {
      if (error.response) {
        dispatch(errorOccurred({ code: error.response.status, details: error.response.data }));
      }
      dispatch(requestCompleted());
    })
    .finally(() => {
      dispatch({
        type: actionTypes.tutorPublicDetailsLoadingInProgress,
        value: false,
      });
    });
};

export const getTutorClasses = (data) => (dispatch) => {
  dispatch(classesListLoading(true));
  axios({
    method: 'get',
    url: 'classes/list',
    withCredentials: true,
    params: data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(onTutorClasses(res.data));
      dispatch(requestCompleted());
      dispatch(classesListLoading(false));
    })
    .catch((error) => {
      dispatch(requestCompleted());
      dispatch(classesListLoading(false));
    });
};
export const enableLoading = () => ({
  type: campActionTypes.enableLoading,
  loading: { loading: true },
});

export const enableCampListloading = (data) => ({
  type: campActionTypes.enableCampListloading,
  loading: data,
});

export const disableLoading = () => ({
  type: campActionTypes.disableLoading,
  loading: { loading: false },
});
export const onCampsList = (data) => ({
  type: campActionTypes.campsListFound,
  success: data,
});
export const onSearchCampsList = (data) => ({
  type: campActionTypes.onSearchCampsList,
  success: data,
});
export const getCampsList = (data) => (dispatch) => {
  dispatch(enableLoading());
  dispatch(enableCampListloading(true));
  axios({
    method: 'get',
    url: '/camps/list',
    withCredentials: true,
    params: data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCampsList(response.data));
        dispatch(onSearchCampsList(response.data));
        dispatch(disableLoading());
        dispatch(enableCampListloading(false));
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
      dispatch(enableCampListloading(false));
    });
};

export const getSearchCampsList = (providerId) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/camps/list',
    withCredentials: true,
    params: { ...providerId, expand: 'services', sort: 'created_at.desc' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCampsList(response.data));
        dispatch(onSearchCampsList(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};
