import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';
import { Modal, Button } from 'antd';
import _ from 'lodash';
import './style.css';
import { withTranslation } from 'react-i18next';
import GenList from '../../../components/re-usable/list-with-row-selection';
import * as actions from '../../../store/actions/tutor/payments';
import * as action from '../../../store/actions/public/Classes';
import * as userAction from '../../../store/actions/users';
import CheckoutForm from './CheckoutForm';
import { globalVariable } from '../../../utils/config/config';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import Myspin from '../../../components/loader/spin';

const Payments = (props) => {
  const [selectedPayment] = useState('ach');
  const [intent_clientSecret, set_intent_clientSecret] = useState('');
  const [isACHModalOpen, set_isACHModalOpen] = useState(false);
  const [payment_data, set_payment_data] = useState([]);

  const [selectedRows, set_selectedRows] = useState([]);
  const [billAmount, set_billAmount] = useState(0);

  const getSelectedData = () => ({
    amount: _.round(billAmount * 100, 0),
    cart_items: selectedRows,
  });

  const tablecolumns = [

    {
      title: props.t('nameLabel'),
      dataIndex: 'className',
      width: '45%',
    },

    {
      title: props.t('userLabel'),
      dataIndex: 'user_name',
      width: '15%',
    },
    {
      title: props.t('depLabel'),
      dataIndex: 'dependent_user_name',
      width: '15%',
    },
    {
      title: props.t('billAmnt'),
      dataIndex: 'amountToBePaidForThisClass',
      width: '15%',
    },
  ];

  useEffect(() => {
    const requestParams = {};
    requestParams.limit = 100;
    requestParams.expand = 'provider,dependent_user,class_details';
    requestParams.sort = 'updated_at.desc';

    props.user_payment_info(requestParams, set_payment_data);
  }, []);

  useEffect(() => {
    set_billAmount(_.get(payment_data, ['moredata', 'billAmount']));
    set_selectedRows(_.get(payment_data, ['data']));
  }, [payment_data]);

  // used in check box row selection
  const rowSelection = {
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      console.log('selectedRows', selectedRows);
      set_selectedRows(selectedRows);
      const result = _.round(_.sumBy(selectedRows, 'amountToBePaidForThisClass'), 2);
      set_billAmount(result);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selected) {
        const result = _.round(_.sumBy(selectedRows, 'amountToBePaidForThisClass'), 2);
        set_billAmount(result);
        set_selectedRows(selectedRows);
      } else {
        set_billAmount(0);
        set_selectedRows([]);
      }
    },
  };

  const createBookingCallback = (resp) => {
    history.push(routeName.userDashboard);
  };

  const showACHModal = (params = true) => {
    set_isACHModalOpen(params);
  };

  const handleAch = () => {
    showACHModal(true);

    const paymentIntent_data = {
      payment_data: {
        payment_method: selectedPayment,
        cart_items: selectedRows,
      },
      amount: _.round(billAmount * 100, 0),
    };

    props.stripePaymentIntent(paymentIntent_data, (data) => {
      try {
        if (data.clientSecret) {
          set_intent_clientSecret(data.clientSecret);
        } else {
          console.log('error> at /stripe/create-payment-intent cart.js', data.error);
        }
      } catch (error) {
        console.log('error> at /stripe/create-payment-intent cart.js');
      }
    });
  };

  const handleCancel = () => {
    showACHModal(false);
  };
  const render = () => {
    const ch = _.chain(payment_data);

    const cartItems = ch.get('data').value();
    const isPaymentInfoReady = ch.get('data').isEmpty().value();

    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="nurtem-card-design nurtem-table payments-page py-4 mb-3">
            <div className="message-title user-payment mb-3 d-flex justify-content-between">
              <span>{props.t('paymentsLabel')}</span>
            </div>
            <div
              className="col-12"
              style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '10px',
              }}
            />
            <GenList rowSelection={rowSelection} key="paymentstatus" selectionType="checkbox" title="Payment status" col={tablecolumns} data={cartItems} />

            {!isPaymentInfoReady && (
            <div className="col-12" style={{ display: 'flex', justifyContent: 'end' }}>
              <table className="table" style={{ width: '350px' }}>
                <tbody>
                  <tr className="table-info-offline-payment">
                    <td>{props.t('totalAmount')}</td>
                    <td>{billAmount}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ textAlign: 'right' }}>
                      <Button type="primary" onClick={() => handleAch()}>{props.t('payNow')}</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            )}
          </div>
        </div>

        <Modal
          title="Payment"
          open={isACHModalOpen}
          onCancel={handleCancel}
          footer={null}
        >
          {intent_clientSecret ? (
            <Elements
              stripe={loadStripe(globalVariable.STRIPE_PUBLISHABLE_KEY)}
            >
              <CheckoutForm
                selectedData={getSelectedData}
                payment_method="ach"
                amount={_.round(billAmount * 100, 0)}
                cart_items={cartItems}
                showACHModal={showACHModal}
                onCreateBookings={props.onCreateBookings}
                createBookingCallback={createBookingCallback}
                clientSecret={intent_clientSecret}
              />
            </Elements>
          ) : <Myspin />}

        </Modal>
      </div>
    );
  };

  return render();
};

const mapStateToProps = (state) => {
  console.log('usersnow=========', state.users);
  return {
    userBaseProfile: state.userProfile.userBaseProfile,
    dependentUsers: state.userProfile.dependentUsers,

    userClasses: state.users.userClasses,
    userCamps: state.users.userCamps,
    userCompetitions: state.users.userCompetitions,

    offline_payment_object: state.users.offline_payment_object,
    balance_amount: state.users.balance_amount,
    is_studentListLoaded: state.payments.student_list_loaded,
    student_list_by_provider: state.payments.student_list_by_provider,
    student_payment_requestdata: state.users.student_payment_information.requestdata,
    student_payment_information: state.users.student_payment_information.responsedata,
  };
};

const mapDispatchToProps = (dispatch) => ({
  user_payment_info: (requestParams, callback) => dispatch(userAction.user_payment_info(requestParams, callback)),
  stripePaymentIntent: (data, callback) => dispatch(action.stripe_payment_intent(data, callback)),

  onCreateBookings: (data, url, bookingCallback) => dispatch(userAction.onCreateBookings(data, url, bookingCallback)),

  get_student_payment_info: (data) => dispatch(actions.get_student_payment_info(data)),
  clear_student_payment_info: () => dispatch(actions.on_receiving_student_payment_information({})),
  payOffline: (data) => dispatch(actions.payOffline(data)),
  student_list_loaded: (data) => dispatch(actions.student_list_loaded(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Payments));
