import React, { useState, useEffect } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import './ach.css'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

function CheckoutForm(props) {
  const stripe = useStripe()
  const elements = useElements()
  const [delayTime, setDelayTime] = useState(5000)
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const { clientSecret } = props

    if (!clientSecret) {
      return
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        console.log('paymentIntent.status>>>1', paymentIntent.status)
        //requires_source

        if (paymentIntent) {
          switch (paymentIntent.status) {
            case 'succeeded':
              setMessage(props.t('paySuccess'))
              break
            case 'processing':
              setMessage(props.t('payLoading'))
              break
            case 'requires_payment_method':
              setMessage(props.t('payFail'))
              break
            case 'payment_failed':
              setMessage(props.t('payFail'))
              break
            default:
              setMessage('')
              break
          }
        }
      })
  }, [stripe, props.clientSecret])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      setIsLoading(true)
      stripe
        .confirmPayment({
          elements,
          // confirmParams: {
          //   // Make sure to change this to your payment completion page
          //   return_url: "http://localhost:3000",
          // },
          redirect: 'if_required',
        })
        .then(({ paymentIntent, error }) => {
          if (error) {
            setMessage(error.message)
            setTimeout(() => {
              setDelayTime(delayTime * 2)
              setIsLoading(false)
            }, delayTime)
          } else {
            console.log('confirmPayment >>paymentIntent', paymentIntent)
            console.log('paymentIntent.status>>>2', paymentIntent.status)

            const { payment_data, amount } = props.paymentIntent_data

            let status = _.get(paymentIntent, 'status')

            //for ach and entering bank details manually
            if (
              status === 'requires_source_action' &&
              paymentIntent.payment_method &&
              paymentIntent.next_action.type === 'verify_with_microdeposits'
            ) {
              status = 'processing'
            }

            switch (status) {
              case 'succeeded':
                setMessage(props.t('paySuccess'))
                props.onCreateBookings(
                  {
                    token: paymentIntent,
                    account_id: '',
                    bookingDetails: payment_data,
                    amoutTo: amount,
                  },
                  '/usersbooking',
                  (resp) => {
                    const message = `${_.get(resp, 'data.message', '')} - Payment Succeeded`

                    setTimeout(() => {
                      setIsLoading(false)
                      props.showACHModal(false)
                      props.createBookingCallback({
                        amount,
                        resp,
                        message,
                      })
                    }, 1500)
                  }
                )

                // if it is not redirected then
                setTimeout(() => {
                  setMessage(
                    `${status} Some issue with booking; will be resolved soon`
                  )
                  setIsLoading(false)
                  props.showACHModal(false)
                }, 1500)
                break
              case 'processing':
                setMessage(props.t('payLoading'))
                props.onCreateBookings(
                  {
                    token: paymentIntent,
                    account_id: '',
                    bookingDetails: payment_data,
                    amoutTo: amount,
                  },
                  '/usersbooking',
                  (resp) => {
                    const message = `${_.get(resp, 'data.message', '')} - Your Payment Is Under Processing`

                    setMessage(message)
                    setTimeout(() => {
                      setIsLoading(false)
                      props.showACHModal(false)
                      props.createBookingCallback({
                        amount,
                        message,
                      })
                    }, 1500)
                  }
                )

                // if it is not redirected then
                setTimeout(() => {
                  setMessage(
                    `${status} Some issue with booking; will be resolved soon`
                  )
                  setIsLoading(false)
                  props.showACHModal(false)
                }, 1500)

                break
              case 'requires_payment_method':
                setMessage(props.t('payFail'))
                setTimeout(() => {
                  setDelayTime(delayTime * 2)
                  setIsLoading(false)
                  // setMessage("");
                }, delayTime)
                break
              default:
                setMessage(props.t('payFail'))
                setTimeout(() => {
                  setDelayTime(delayTime * 2)
                  setIsLoading(false)
                  // setMessage("");
                }, delayTime)
                break
            }
          }
        })
    } catch (error) {
      console.log('error at CheckoutForm.js', error)
      setTimeout(() => {
        setIsLoading(false)
        setMessage(error.message)
      }, delayTime)
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
    clientSecret: props.clientSecret,
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {/* <LinkAuthenticationElement clientSecret={props.clientSecret}
                id="link-authentication-element"
                onChange={(e) => setEmail("gunabalans@gmail.com")}
            /> */}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner" />
          ) : (
            props.t('payNow')
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

export default withTranslation()(CheckoutForm)
