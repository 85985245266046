import * as actionTypes from '../../actions/common/actionTypes';

const initialState = {
  competitionUploads: {},
  classUploads: {},
  campUploads: {},
  tutorUploads: {},
  eventUploads: {},
};

const setUploadedListState = (state, action) => {
  console.log('setUploadedListState>>', action);

  return { ...state, [action.type]: action.payload };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.classUploads:
    case actionTypes.campUploads:
    case actionTypes.competitionUploads:
    case actionTypes.eventUploads:
    case actionTypes.tutorUploads:
      return setUploadedListState(state, action);

    default:
      return state;
  }
};

export default reducer;
