import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import '../style.css'
import { Checkbox, InputNumber, Tooltip, Button } from 'antd'
import { MdShoppingCart } from 'react-icons/md'
import { PiCurrencyCircleDollarFill, PiHeartBold } from 'react-icons/pi'
import { connect } from 'react-redux'
import * as action from '../../../../store/actions/public/Classes'
import {
  updateCampAddonProperty,
  getGrandTotal,
  campsAddToCart,
} from './component-functionalities'
import history from '../../../../utils/history'
import * as userAction from '../../../../store/actions/users'
import { seatsAvailableCount } from '../../../../utils/common'
import timeDateConverterSession from '../../../../components/timezone/sessionTimeDate'
import { cartItems } from '../../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../../store/actions/public/search'

function AddToCartContainer(props) {
  const [campAddons, setCampAddons] = useState([])

  const ch = _.chain(props.loggedInUserDetails)
  const isLoggedIn = ch.get('isLoggedIn', false).value()
  const isProvider = ch.get('login_type', false).eq('provider').value()
  console.log('detailssss=========', isProvider)
  useEffect(() => {
    const addons = _.get(props, 'campDetail.addons', []) //const addons = _.get(props, 'campDetail.data.addons', [])
    if (!_.isEmpty(addons)) {
      buildCampAddonDetails(addons)
    }
  }, [props.campDetail, props.selectedSession]) // props.campDetail.data

  const buildCampAddonDetails = async (data) => {
    const mandatory_addons = _.get(
      props,
      'selectedSession.mandatory_addons',
      []
    )
    const addons = await data.map((addon, index) => {
      const name = _.get(addon, 'name', '')
      const mandatory_addons_new = mandatory_addons.map((item) =>
        item.slice(0, -2)
      )
      const status = mandatory_addons_new.includes(name)
      addon.name = name
      addon.cost = _.toNumber(_.get(addon, 'cost', 0))
      addon.mandatory = status
      addon.isMandatory = status
      addon.checked = status
      addon.count = 1
      return addon
    })
    setCampAddons(addons)
  }

  const noSessionLeftInfo = props.t('noSesLeftSelectOther')

  const campBasePriceBlock = () => {
    const price = _.get(props, 'selectedSession.price', false)
    const type = _.get(props, 'type', 'classes')

    return (
      <div className="view-camp-cart-card-set pt-1">
        <div className="d-flex align-items-center ">
          <div className="b-text " style={{ color: '#000' }}>
            {props.selectedSession.billing_type === 2 ? (
              <b>
                {props.selectedSession.payment_cycle_type === 2
                  ? 'Cost per Session in Month'
                  : 'Cost per Month'}
              </b>
            ) : (
              <b>
                {type === 'competitions'
                  ? 'Competition cost'
                  : type === 'events'
                    ? 'Ticket cost'
                    : 'Cost per Session'}
              </b>
            )}
          </div>
        </div>
        <div className="align-items-center mr-2">
          {price && <div className="full-width b-bold">${price}</div>}
        </div>
      </div>
    )
  }

  const campAddonsBlock = (campAddons) => {
    console.log('addonhere1====', campAddons);
    if (Array.isArray(campAddons) && campAddons.length > 0) {
      return (
        <>
          <div className="view-camp-cart-card-set pt-2">
            <div className="full-width b-text align-items-center">
              <Tooltip placement="top" title={props.t('addOnLabel')}>
                <span className="addonText"> Select Addon</span>
              </Tooltip>
            </div>
          </div>
          {campAddons.map((campAddon, key) => {
            const data = {
              checkBoxDisabled: _.get(campAddon, 'isMandatory', true),
              checkBoxDefaultChecked: _.get(campAddon, 'checked', true),
              addonName: _.get(campAddon, 'name', ''),
              addonDesp: _.get(campAddon, 'description', ''),
              inputNumberDisabled: !_.get(campAddon, 'checked', false),
              inputNumberMin:
                _.get(campAddon, 'mandatory', 0) > 0
                  ? _.get(campAddon, 'mandatory', 0)
                  : 1,
              inputNumberDefaultValue:
                _.get(campAddon, 'mandatory', 0) > 0
                  ? _.get(campAddon, 'mandatory', 0)
                  : 1,
              currentAddonCount: _.get(campAddon, 'count', 1),
            };
            console.log('desp======', _.get(data, 'addonDesp'));

            const onAddonCheckChange = (e) => {
              const checked = _.get(e, 'target.checked', false);
              updateCampAddonProperty(
                campAddons,
                campAddon,
                { checked, count: checked ? _.get(campAddon, 'count', 1) : 1 },
                (updated, data) => {
                  if (updated) {
                    setCampAddons(data);
                  }
                }
              );
            };

            const onAddonCountChange = (value) => {
              if (!_.isEqual(_.get(data, 'currentAddonCount'), value)) {
                updateCampAddonProperty(
                  campAddons,
                  campAddon,
                  { count: value },
                  (updated, data) => {
                    if (updated) {
                      setCampAddons(data);
                    }
                  }
                );
              }
            };

            const totalAddonCost = _.get(campAddon, 'checked', false)
              ? (
                <span>
                  ${_.multiply(_.get(campAddon, 'count', 1), _.get(campAddon, 'cost', 0))}
                </span>
              )
              : '';
            const checkedclass = _.get(data, 'checkBoxDefaultChecked')
              ? ' full-width d-flex align-items-start ant-checkbox-checked'
              : 'full-width d-flex align-items-start';

            const valCount = _.get(data, 'currentAddonCount', 1);

            return (
              <div className="view-camp-cart-card-set" key={key}>
                <div className="full-width form-row d-flex align-items-center mb-2 ">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 AddToCartCheckbox ">
                    {_.get(props, 'selectedSession.id') ? (
                      <Checkbox
                        disabled={_.get(data, 'checkBoxDisabled')}
                        defaultChecked={_.get(data, 'checkBoxDefaultChecked')}
                        className={checkedclass}
                        onChange={onAddonCheckChange}
                      >
                        <Tooltip placement="top" title={_.get(data, 'addonDesp')}>
                          {_.get(data, 'addonName')}
                        </Tooltip>
                      </Checkbox>
                    ) : (
                      <Tooltip
                        placement="topLeft"
                        title="Atleast choose one Session"
                      >
                        <Checkbox
                          disabled
                          className="full-width d-flex align-items-start"
                        >
                          <Tooltip
                            placement="top"
                            title={_.get(data, 'addonDesp')}
                          >
                            <b>{_.get(data, 'addonName')}</b>
                          </Tooltip>
                        </Checkbox>
                      </Tooltip>
                    )}
                  </div>

                  <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    {_.get(campAddon, 'checked', false) && (
                      <InputNumber
                        className="input-bg"
                        disabled={_.get(data, 'inputNumberDisabled')}
                        min={1}
                        max={99}
                        defaultValue={valCount}
                        onChange={onAddonCountChange}
                        parser={(value) => {
                          value = String(value).replace(/[^0-9]/g, '');
                          if (
                            _.isEmpty(value) ||
                            _.toNumber(value) < _.get(data, 'inputNumberMin')
                          ) {
                            return _.get(data, 'currentAddonCount');
                          }
                          return value;
                        }}
                      />
                    )}
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                    <div className="align-items-center">
                      <div
                        className="full-width b-bold"
                        style={{ padding: '5px 0px' }}
                      >
                        {totalAddonCost}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };


  const grandTotalBlock = () => {
    const grandTotal = getGrandTotal(
      _.get(props, 'selectedSession.id')
        ? _.get(props, 'selectedSession.price')
        : 0,
      campAddons
    )
    return (
      <div className="view-camp-cart-card-set border-top d-block">
        <div className="form-row align-items-center">
          <div className="col-12 col-lg-9">
            <div className="b-text text-right pr-3">
              <b>Total</b>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <div className="align-items-form-center">
              <div className="full-width b-bold">
                ${_.round(grandTotal.grandTotal, 2)}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const packageClick = () => {
    props.onScrollView()
  }

  const addToCartBtnBlock = () => {
    const onAddToCart = async (status, redirectToCart = false) => {
      // return grand total and addon total as object
      const priceData = getGrandTotal(
        _.get(props, 'selectedSession.id')
          ? _.get(props, 'selectedSession.price')
          : 0,
        campAddons
      )

      const userObj = {
        isLoggedIn,
        userID: _.get(props, 'userBaseProfile.id', ''),
      }

      campsAddToCart(
        props.t,
        props.selectedSession,
        campAddons,
        priceData,
        userObj,
        status,
        props.type,
        props.setCartItemsRedux
      )

      // if (isLoggedIn) {
      //   setTimeout(async () => {
      //     await props.onUserProfile()
      //   }, 3000)
      // }

      if (redirectToCart) {
        history.push('/mycart')
      }
    }

    const onBooknow = () => {
      onAddToCart(1, true)
    }

    const currentDate = dayjs().startOf('day')
    const sessionDates = props.selectedSession.session_dates
    const st = _.chain(props.selectedSession)
    const batchType = _.get(props, 'selectedSession.type', 'classes')
    const endDate = timeDateConverterSession(
      st.get('end_date').value(),
      'date',
      'MM-DD-YYYY'
    )
    const hasUpcomingSessions =
      batchType !== 'competitions' && batchType !== 'events'
        ? Array.isArray(sessionDates) &&
        sessionDates.some((date) => new Date(date) > currentDate)
        : dayjs(endDate).startOf('day') >= currentDate
    const maxCount =
      batchType !== 'competitions' && batchType !== 'events'
        ? _.get(props, 'selectedSession.no_of_max_students', 0)
        : _.get(props, 'selectedSession.max_no_of_participants', 0)
    const stuAccpt = _.get(props, 'selectedSession.additional_details', {})
    const total_seats_left = seatsAvailableCount(maxCount, stuAccpt)

    return (
      <>
        {!_.get(props, 'selectedSession.id') ? (
          <Tooltip placement="topLeft" title={props.t('batchValid')}>
            <Button
              onClick={() => packageClick()}
              style={{ cursor: 'not-allowed' }}
              className="btn btn-add-to-cart"
            >
              <MdShoppingCart className="cart-icon" /> {props.t('addCart')}
            </Button>
          </Tooltip>
        ) : total_seats_left < 1 ? (
          <div
            className="view-camp-cart-card-set buttonstyle"
            style={{ justifyContent: 'flex-end' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                rowGap: '15px',
                flexDirection: 'column',
              }}
            >
              {isLoggedIn && (
                <Button
                  style={{ cursor: 'pointer' }}
                  onClick={() => onAddToCart(2)}
                  className="btn btn-add-to-cart mr-2"
                >
                  <MdShoppingCart className="cart-icon mr-1" />
                  {props.t('waitlistButton')}
                </Button>
              )}

              <span style={{ color: 'red', fontWeight: 600 }}>
                {noSessionLeftInfo}
              </span>
            </div>
          </div>
        ) : batchType !== 'competitions' &&
          batchType !== 'events' &&
          props.selectedSession.session_dates?.length === 0 ? (
          <span style={{ color: 'red', fontWeight: 600 }}>
            props.t('noSesLeftSelectOther')
          </span>
        ) : !hasUpcomingSessions ? (
          props.t('noSesLeft')
        ) : isLoggedIn && isProvider ? (
          <Tooltip placement="topLeft" title={props.t('pruchaseLogin')}>
            <Button
              style={{ cursor: 'not-allowed' }}
              className="btn btn-add-to-cart"
            >
              <MdShoppingCart className="cart-icon" /> {props.t('addCart')}
            </Button>
          </Tooltip>
        ) : props.selectedSession.no_of_max_students ===
          props.selectedSession.student_count ? (
          <Tooltip placement="topLeft" title={props.t('seatsF')}>
            <Button
              style={{ cursor: 'not-allowed' }}
              className="btn btn-add-to-cart"
            >
              <MdShoppingCart className="cart-icon" /> {props.t('addCart')}
            </Button>
          </Tooltip>
        ) : (
          <div
            className="view-camp-cart-card-set buttonstyle"
            style={{ justifyContent: 'flex-end' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Button
                style={{ cursor: 'pointer' }}
                onClick={() => onAddToCart(1)}
                className="btn btn-add-to-cart mr-2"
              >
                <MdShoppingCart className="cart-icon mr-1" />{' '}
                {props.t('addCart')}
              </Button>
              {_.get(props, 'loggedInUserDetails.isLoggedIn') === true && (
                <Button
                  style={{ cursor: 'pointer' }}
                  onClick={() => onAddToCart(0)}
                  className="btn btn-add-to-cart mr-2"
                >
                  <PiHeartBold className="cart-icon mr-1" />{' '}
                  {props.t('wishlistLabel')}
                </Button>
              )}

              <Button
                style={{ cursor: 'pointer', background: '#187f29e3' }}
                onClick={(events) => {
                  events.preventDefault()
                  onBooknow()
                }}
                className="btn btn-add-to-cart"
              >
                <PiCurrencyCircleDollarFill className="cart-icon mr-1" />
                {props.t('register')}
              </Button>
            </div>
          </div>
        )}
      </>
    )
  }

  const mainComponent = () => (
    <div className=" view-camp-cart-card mb-3 px-3 ">
      {campAddonsBlock(campAddons)}
      {campBasePriceBlock()}
      {/* Declaring camp grand total block */}
      {grandTotalBlock()}
      {/* Declare Add to cart btn block */}
      {!isProvider && addToCartBtnBlock()}
    </div>
  )

  const mainComponentNoAddon = () => (
    <div className="view-camp-cart-card mb-3 p-2 ">
      {campBasePriceBlock()}
      {/* Declaring camp grand total block */}
      {grandTotalBlock()}
      {/* Declare Add to cart btn block */}
      {!isProvider && addToCartBtnBlock()}
    </div>
  )

  return (
    <>{!_.isEmpty(campAddons) ? mainComponent() : mainComponentNoAddon()}</>
  )
}

const mapStateToProps = (state) => ({
  loggedInUserDetails: state.authentication,
  userBaseProfile: state.userProfile.userBaseProfile.details,
  classesStudents: state.classesDetails.classesStudents,
  campsStudents: state.campsDetails.campsStudents,
  competitionStudents: state.competitionsDetails.competitionStudents,
})

const mapDispatchToProps = (dispatch) => ({
  setCartItemsRedux: (requestParams) =>
    dispatch(searchAction.common(cartItems, requestParams)),

  onItemAdd: () => dispatch(action.cartItemAdd()),
  onUserProfile: () => dispatch(userAction.userProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AddToCartContainer))
