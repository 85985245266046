import history from './history';
import * as routeName from '../components/route-names';

const redirectTo = () => {
  const url = window.location.href;
  const parsedUrl = new URL(url);
  const remainingPath = parsedUrl.pathname + parsedUrl.search;
  history.push(`${routeName.signIn}?redirectTo=${remainingPath}`);
};

export default redirectTo;
