import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateServicesState;
// tutor actions

export default {
  // get nurtem services list
  list: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/services/list',
      },
      actionType: actionType.services.list,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  servicesCount: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/classes/list-service-count',
      },
      actionType: actionType.services.servicesCount,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
};
