import * as actionTypes from '../../../actions/public/search/actionTypes';

const initialState = {

  [actionTypes.headerSearch]: true,

  [actionTypes.searchType]: 'class',
  [actionTypes.serviceID]: '',
  [actionTypes.searchZipCode]: '',
  [actionTypes.searchLocation]: {},

  [actionTypes.showMoreSearchUI]: false,
  [actionTypes.teachingMod]: '',
  [actionTypes.typeOfClass]: '',
  [actionTypes.ageGroup]: [],
  [actionTypes.milesFromHome]: 0,
  [actionTypes.preferredDays]: [],
  [actionTypes.preferredDate]: [],
  [actionTypes.preferredTime]: [],
  [actionTypes.preferredPriceRange]: [],

  [actionTypes.location_search_data]: [],
  [actionTypes.location_search_data_fetching]: false,

  [actionTypes.searchMounted]: false,
  [actionTypes.tutorClassesCampsSearchData]: [],
  [actionTypes.sponoredList]: [],
  [actionTypes.generalList]: [],
  [actionTypes.dirList]: [],

  [actionTypes.classList]: [],
  [actionTypes.campList]: [],
  [actionTypes.compeList]: [],
  [actionTypes.eventList]: [],

  [actionTypes.commonSort]: '',


  [actionTypes.toggleLeftMenu]: false,
  [actionTypes.cartItems]: {},

};

const reducer = (state = initialState, action) =>
// var newState = { ...state }
// newState[action.type] = action.payload
// return newState;

  ({ ...state, [action.type]: action.payload });
export default reducer;
