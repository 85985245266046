/**
 * @author ramsteyn17@gmail.com
 * @dated 21/03/2023
 * This is to display the timing from unix epoch time to users local timezones.
 */
import React from 'react';
import _ from 'lodash';
import occurrenceType from '../../utils/data/occurrence-type';
import { convertLocalTime } from '../../utils/formatter';

const classesStart = (classdetails, week) => `${occurrenceType[classdetails.occurrence_type - 1]} ${week}`;
const classTimingsOnly = (value) => {
  const dayOfWeek = _.get(value, ['day_of_week'], []);
  if (dayOfWeek && dayOfWeek.length > 0) {
    if (_.size(_.get(value, 'day_of_week', [])) > 0) {
      if (_.get(value, 'start_time_UTC') && _.get(value, 'end_time_UTC')) {
        return value.day_of_week.map((week, index) => {
          const utcTimestampStart = value.start_time_UTC[index];
          const utcTimestampEnd = value.end_time_UTC[index];
          const localDateStart = new Date(utcTimestampStart * 1000);
          const options = { hour: 'numeric', minute: 'numeric' };
          const localDateStringStart = localDateStart.toLocaleString([], options);
          const localDateEnd = new Date(utcTimestampEnd * 1000);
          const localDateStringEnd = localDateEnd.toLocaleString([], options);
          return (
            <div key={index} className="text">
              {' '}
              {classesStart(value, week)}
              {' '}
              {localDateStringStart}
              {' '}
              -
              {' '}
              {localDateStringEnd}
            </div>
          );
        });
      }
      return value.day_of_week.map((week, index) => (
        <div key={index} className="text">
          {' '}
          {classesStart(value, week)}
          {' '}
          {convertLocalTime(week.start_time)}
          {' '}
          to
          {' '}
          {convertLocalTime(week.end_time)}
        </div>
      ));
    }
  }
};
export default classTimingsOnly;
