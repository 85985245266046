import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Spin } from 'antd';
import { connect } from 'react-redux';

import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import componentFunctionalities from './component-functionalities';
import CouponBlockForm from './form';
import buildFormMeta from './form/form-meta';
import generalActionTypes from '../../../../store/general/actionTypes';
import generalAction from '../../../../store/general/action';

const CouponBlock = (WrappedComponent) => {
  const MainComponent = (props) => {
    const { t } = useTranslation();

    // Declare a new state variable, which we'll call "pagination"
    const [componentDetails, setComponentDetails] = useState(_.get(componentFunctionalities, 'config.details', {}));
    // Creating form instance, which we'll call "form"
    const [couponBlockFormInstance] = Form.useForm();
    // Creating a instance for "formFields"
    const formDetails = buildFormMeta();
    // Declare a new state variable, which we'll call "formLoader"
    const [formLoader, setFormLoader] = useState(false);
    // Declare a new state variable, which we'll call "couponBlockListInstance"
    const [couponBlockListInstance, setCouponBlockListInstance] = useState({});
    // Creating a instance for componentFunctionality "LogicalBlock"
    const LogicalBlock = componentFunctionalities.LogicalBlock({
      self: {
        useEffect,
        componentDetails,
        setComponentDetails,
        couponBlockFormInstance,
        formDetails,
        setFormLoader,
        couponBlockListInstance,
        config: _.get(componentFunctionalities, 'config', {}),
      },
      externalProps: props,
      t,
    });

    const couponModeDisplayBlock = (mode) => (
      <h3>
        {_.get(LogicalBlock.generalLogic.getCurrentModeDetails(), 'label', _.capitalize(`${mode} Coupon`))}
      </h3>
    );
    const componentUI = (mode) => (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="nurtem-card-design nurtem-table tutor-my-coupons-page py-4 mt-2 mb-3">
          {couponModeDisplayBlock(mode)}
          <Spin spinning={formLoader}>
            <CouponBlockForm {...{
              couponBlockFormInstance, LogicalBlock, componentDetails, config: _.get(componentFunctionalities, 'config', {}),
            }}
            />
          </Spin>
        </div>
      </div>
    );
    const renderOutput = () => {
      const url = props.location.search;
      const urlparams = queryString.parse(url);

      return (
        <div className="row">
          {urlparams && urlparams.mode ? componentUI(urlparams.mode) : <WrappedComponent {...props} {...{ couponBlock: { API: { ...LogicalBlock.componentAPI, updateCouponDetails: _.get(componentDetails, 'updateCouponDetails', {}), formLoader }, setCouponBlockListInstance } }} />}
        </div>
      );
    };
    return renderOutput();
  };
  const mapStateToProps = (state) => ({ discounts: _.get(state, `general.${generalActionTypes.discounts.name}`, {}) });

  const mapDispatchToProps = (dispatch) => ({
    createDiscount: (details) => dispatch(generalAction.discounts.create(details)),
    updateDiscount: (details) => dispatch(generalAction.discounts.update(details)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(MainComponent);
};

export default CouponBlock;
