import React from 'react'
import _ from 'lodash'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BsCalendar2Week, BsPerson } from 'react-icons/bs'
import { Carousel } from 'antd'
import { MdOutlineLocationOn, MdCalendarMonth } from 'react-icons/md'
import { connect } from 'react-redux'
import { FaRegCalendarPlus } from 'react-icons/fa6'
import { FaRegCalendarCheck } from 'react-icons/fa'
import { TfiCup } from 'react-icons/tfi'
// import * as classaction from '../../store/actions/tutor/Classes';
// import * as campaction from '../../store/actions/tutor/camps';
// import * as competitionActions from "../../store/actions/tutor/competitions";
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'
import { seatsAvailableCount } from '../../utils/common'
import { NurtemUtils } from '../../utils'
import LabelConvertion from './label-convertion'
import timeZone from '../common/timezone'
import timeDateConverterSession from '../timezone/sessionTimeDate'
import TimingsDisplay from '../common/timingsDisplay'
import Linkify from 'linkify-react'

const SessionInfo = (props) => {
  const st = _.chain(props.record)
  console.log('deatilsss============', st)
  const descp = props.record.description
  console.log('deatilss2222222222222============', descp)
  const SDarray = props.record.selected_days
  // const [page,] = useState(1)
  // const id = st.get('id').value()
  // const provider = st.get('provider').value()
  const DayExpander = () => {
    const abbreviatedDays = SDarray
    const dayMapping = {
      th: 'Thu',
      sa: 'Sat',
      tu: 'Tue',
      su: 'Sun',
      mo: 'Mon',
      we: 'Wed',
      fr: 'Fri',
    }
    const sortedWeekdays = abbreviatedDays.sort((a, b) => {
      const dayOrder = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
      return dayOrder.indexOf(a) - dayOrder.indexOf(b)
    })
    const expandedDays = sortedWeekdays.map((abbrev) => dayMapping[abbrev])
    return expandedDays.join(', ')
  }
  // useEffect(() => {
  //     if (id) {
  //         const notesParams = {
  //             limit: 100,
  //             status: 1,
  //             page: page,
  //             sort: 'created_at.desc',
  //             provider: provider
  //         }

  //         if (props.type === 'classes') {
  //             notesParams.class_details = id;
  //             props.getClassesStudents(notesParams);
  //         } else if (props.type === 'camps') {
  //             notesParams.camp_details = id;
  //             props.getCampsStudents(notesParams)
  //         } else if (props.type === 'competitions') {
  //             notesParams.competition_details = id;
  //             props.getCompetitionStudents(notesParams)
  //         }
  //     }
  // }, []);

  const sLeftUI = (sl) => (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <BsPerson style={{ fontSize: 20, marginRight: 10 }} />
      {props.t('seatLeft')}
      <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
        {/* <BsPerson style={{ fontSize: 20, marginRight: 10 }} /> */}
        {sl} Seats
      </h6>
    </div>
  )

  const render = () => {
    const maxCount =
      props.type !== 'competitions' && props.type !== 'events'
        ? st.get('no_of_max_students').value()
        : st.get('max_no_of_participants').value()
    const stuAccpt = st.get('additional_details').value()
    const total_seats_left = seatsAvailableCount(maxCount, stuAccpt)
    const timings = st.get('time_slot', {}).value() || {}
    const items = <TimingsDisplay timings={timings} />

    return (
      <div
        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
        style={{ maxHeight: '300px', overflowY: 'scroll' }}
      >
        <div
          style={{
            marginTop: 10,
            display: 'grid',
            gridGap: '15px',
            gridTemplateColumns: 'auto auto',
            gridTemplateRows: '150px 60px',
          }}
        >
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <AiOutlineClockCircle style={{ fontSize: 20 }} className="mr-2" />
            Time Duration
            <div style={{ width: 220, marginLeft: 10 }}>{items}</div>
          </div>

          {(props.type === 'competitions' || props.type === 'events') &&
          st.get('teaching_type').value() ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              {/* {st.get("teaching_type").value() === 'g' &&
                                <div>
                                    <i className="ntm-communication" style={{ fontSize: '20px', marginRight: 9, fontWeight: '600' }} />
                                    Total Groups
                                    <h6 style={{ marginTop: "10px", marginLeft: "15px" }}>
                                        {st.get('max_no_of_participants_in_team').value()} - {LabelConvertion(st.get('teaching_type').value(), 'teaching_type')}
                                    </h6>
                                </div>
                            } */}
              {sLeftUI(total_seats_left)}
            </div>
          ) : (
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl- "
              style={{ marginTop: '5px' }}
            >
              {sLeftUI(total_seats_left)}
            </div>
          )}
          {st.get('class_mode').value() && st.get('camp_mode').value() && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <h6 style={{ display: 'flex', alignItems: 'end' }}>
                {' '}
                <BsCalendar2Week
                  style={{ fontSize: 20 }}
                  className="mr-2 Popupdesign"
                />
                {st.get('variant').value() !== 'o'
                  ? LabelConvertion(st.get('variant').value(), 'variant')
                  : DayExpander()}
              </h6>
            </div>
          )}
          {props.type === 'competitions' || props.type === 'events' ? (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <FaRegCalendarPlus
                style={{ fontSize: 20 }}
                className="mr-2 Popupdesign"
              />
              <b>{props.t('bookingsOpen')} </b>
              <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                {`${timeDateConverterSession(st.get('start_date').value(), 'date', 'MMM DD,YYYY')} to ${timeDateConverterSession(st.get('end_date').value(), 'date', 'MMM DD,YYYY')}`}
              </h6>
            </div>
          ) : (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <MdCalendarMonth
                style={{ fontSize: 20 }}
                className="mr-2 Popupdesign"
              />
              {props.type === 'classes' ? 'Class Duration' : 'Camp Duration'}
              <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                {`${timeDateConverterSession(st.get('start_date').value(), 'date', 'MMM DD,YYYY')} to ${timeDateConverterSession(st.get('end_date').value(), 'date', 'MMM DD,YYYY')}`}
              </h6>
            </div>
          )}
          {(props.type === 'competitions' || props.type === 'events') &&
            (st.get('duration_competition').value() === '1' ? (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <TfiCup style={{ fontSize: 20 }} className="mr-2 Popupdesign" />
                <b>{props.t('compOn')}</b>
                <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                  {timeDateConverterSession(
                    st.get('competition_start_date').value(),
                    'date',
                    'MMM DD,YYYY'
                  )}
                </h6>
              </div>
            ) : (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <TfiCup style={{ fontSize: 20 }} className="mr-2 Popupdesign" />
                <b>{props.t('compOn')}</b>
                <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                  {`${timeDateConverterSession(st.get('competition_start_date').value(), 'date', 'MMM DD,YYYY')} - ${timeDateConverterSession(
                    st.get('competition_end_date').value(),
                    'date',
                    'MMM DD,YYYY'
                  )}`}
                </h6>
              </div>
            ))}

          {(props.type === 'competitions' || props.type === 'events') && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
              <FaRegCalendarCheck
                style={{ fontSize: 20 }}
                className="mr-2 Popupdesign"
              />
              <b>{props.t('resultOn')} </b>
              <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                {timeDateConverterSession(
                  st.get('competition_result_date').value(),
                  'date',
                  'MMM DD,YYYY'
                )}
              </h6>
            </div>
          )}

          {st.get('teaching_mode').value() && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <i
                className="ntm-promotion"
                style={{ fontSize: '20px', marginRight: 9, fontWeight: '600' }}
              />
              Teaching Mode
              <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                {LabelConvertion(
                  st.get('teaching_mode').value(),
                  'teaching-mode'
                )}
              </h6>
            </div>
          )}
          {st.get('location_taught').value() &&
            st.get('teaching_mode').value() === 'i' && (
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <MdOutlineLocationOn
                  style={{ fontSize: '21px', marginRight: 7, color: '#454448' }}
                />
                Location
                <h6 style={{ marginTop: '10px', marginLeft: '15px' }}>
                  {/* <i className="ntm-earth-globe mr-2" style={{ fontSize: '25px', fontWeight: '600' }} /> */}
                  {st.get('location_taught').value()}
                </h6>
              </div>
            )}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <span>Description</span>
          <span style={{ color: 'black', fontWeight: 'normal' }}>
            <Linkify as="p">
              {descp && parser(NurtemUtils.urlify(descp))}
            </Linkify>
          </span>
        </div>
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) =>
  // console.log("studentstate", state);
  ({
    // classesStudents: state.classesDetails.classesStudents,
    // campsStudents: state.campsDetails.campsStudents,
    // competitionStudents: state.competitionsDetails.competitionStudents,
  })
const mapDispatchToProps = (dispatch) => ({
  // getClassesStudents: requestParams =>
  //     dispatch(classaction.getClassesStudents(requestParams)),
  // getCampsStudents: requestParams =>
  //     dispatch(campaction.getCampsStudents(requestParams)),
  // getCompetitionStudents: requestParams =>
  //     dispatch(competitionActions.getCompetitionStudents(requestParams))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SessionInfo))
