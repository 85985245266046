import React from 'react';

export default function loadingCard(params) {
  const cls = params.mystyle;
  return (
    <div className={cls}>
      <div className="ph-item">
        <div>
          <div className="ph-row">
            <div className="ph-col-2 big" />
            <div className="ph-col-2 empty" />
            <div className="ph-col-2 big" />
          </div>
        </div>
        <div className="ph-col-12">
          <div className="ph-row">
            <div className="ph-col-12" />
            <div className="ph-col-12 mb-4" />
            <div className="ph-col-12" />
            <div className="ph-col-12  mb-4" />
            <div className="ph-col-12" />
            <div className="ph-col-12 mb-4" />
            <div className="ph-col-12" />
            <div className="ph-col-12  mb-4" />
            <div className="ph-col-12" />
          </div>
        </div>
      </div>
    </div>
  );
}
