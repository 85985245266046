/**
 * @author ramsteyn17@gmail.com
 * @dated 24/03/2023
 * This module is for User Payment Report. Which has the code for the Generating payment reports with or without the
 * filter options.
 */

import React, { useEffect, useState } from 'react'
import { Tabs, Table, Button, Tooltip } from 'antd'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { connect } from 'react-redux'
import _ from 'lodash'
import './style.css'
import { withTranslation } from 'react-i18next'
import * as paymentReportUser from '../../../store/actions/tutor/common'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
import { PaymentReportLoader } from '../../../components/loader/common/payment-report-loader'

const FiltersReport = (props) => {
  // console.log('propsreport========', props);
  let users
  let filterData = []
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    users = _.get(props, 'userBaseProfile.details')
    filterData = { ..._.get(props, 'filterDetails') }
    if (users !== undefined && filterData.user) {
      const details = {
        sort: 'created_at.desc',
        user: users.id,
        type: 'user',
        date: new Date(),
        ..._.get(props, 'filterDetails'),
      }
      props.getPaymentReport(details)
    } else {
      const details = {
        type: 'user',
        sort: 'created_at.desc',
        date: new Date(),
        ..._.get(props, 'filterDetails'),
      }
      props.getPaymentReport(details)
    }
  }, [_.get(props, 'filterDetails'), _.get(props, 'userBaseProfile')])

  const pageChange = (page) => {
    setCurrentPage(page)
    if (users !== undefined) {
      const details = {
        sort: 'created_at.desc',
        date: new Date(),
        user: users.id,
        ..._.get(props, 'filterDetails'),
        type: 'user',
        page,
        limit: 10,
      }
      props.getPaymentReport(details)
    } else {
      const details = {
        type: 'user',
        date: new Date(),
        sort: 'created_at.desc',
        page,
        ..._.get(props, 'filterDetails'),
        limit: 10,
      }
      props.getPaymentReport(details)
    }
  }

  const column_data = [
    // {
    //   title: props.t('userLabel'),
    //   dataIndex: 'user_name',
    //   key: 'user_name',
    // },
    {
      title: 'Type',
      dataIndex: 'class_type_label',
      key: 'class_type_label',
    },
    {
      title: props.t('paidDate'),
      dataIndex: 'paid_date',
      key: 'paid_date',
    },
    {
      title: props.t('batName'),
      dataIndex: 'className',
      key: 'className',
    },
    {
      title: props.t('tutorLabel'),
      dataIndex: 'providerName',
      key: 'providerName',
    },
    {
      title: props.t('payStatus'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '8%',
      render: (text) => {
        // Capitalize the first letter of payment_status
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
  ]

  const getGroupedData = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.className]) {
        acc[item.className] = []
      }
      acc[item.className].push(item)
      return acc
    }, {})
  }

  const PaymentTable = ({ paymentReportLoading, paymentReports }) => {
    const data = _.get(paymentReports, 'details.data', [])
    // Filter out items with paid_amount equal to zero
    const filteredData = data.filter(item => item.paid_amount !== 0);
    const dataSource = _.get(paymentReports, 'details', [])
    const groupedData = getGroupedData(filteredData)

    const expandedRowRender = (record) => {
      const nestedColumns = [
        {
          title: props.t('userLabel'),
          dataIndex: 'user_name',
          key: 'user_name',
        },
        {
          title: props.t('depLabel'),
          dataIndex: 'dependent_user_name',
        },
        {
          title: props.t('payMethod'),
          dataIndex: 'payment_method',
          key: 'payment_method',
        },
        {
          title: props.t('discountCode'),
          dataIndex: 'discountCode',
        },
        {
          title: props.t('sessions'),
          dataIndex: 'number_session_paid',
        },
        {
          title: props.t('billingType'),
          dataIndex: 'billing_type_str',
        },
        {
          title: props.t('amountPaid'),
          dataIndex: 'paid_amount',
          key: 'paid_amount',
        },
        {
          title: props.t('transId'),
          dataIndex: 'transaction_id',
        },
        {
          title: props.t('invoice1'),
          dataIndex: '',
          key: 'invoice',
          render: (record) => (
            <Button
              className="report-btn"
              onClick={() => history.push(`${routeName.userInvoice}`, record)}
            >
              Invoice
            </Button>
          ),
        },
      ]

      return (
        <Table
          columns={nestedColumns}
          dataSource={groupedData[record.className]}
          pagination={false}
        />
      )
    }
    return (
      <div className="mt-2 mb-3">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="schedule-content">
              <Tabs defaultActiveKey="1">
                <items
                  tab={
                    <span>
                      {props.t('paymentsLabel')}
                      <Tooltip
                        placement="topRight"
                        title="Only current month's reports are displayed by default. Use the date filter to view reports from previous months."
                      >
                        <AiOutlineInfoCircle
                          style={{
                            marginLeft: '5px',
                            fontSize: '20px',
                            color: 'red',
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                  key={3}
                >
                  <div className="full-width">
                    <div className="hidden-scroll-x">
                      {_.get(props, 'paymentReportLoading') ? (
                        <>
                          <PaymentReportLoader />
                          <div
                            className="col-12"
                            style={{ display: 'flex', justifyContent: 'end' }}
                          >
                            <table className="table" style={{ width: '410px' }}>
                              <tbody>
                                <tr className="table-info">
                                  <td>{props.t('totalAmount')}</td>
                                  <td>{props.t('loading1')}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : _.size(data) > 0 ? (
                        <>
                          <Table
                            loading={_.get(
                              props,
                              'paymentReportLoading',
                              false
                            )}
                            scroll={{ x: '100%' }}
                            pagination={{
                              current: currentPage, // Pass the current page value
                              pageSize: 10,
                              onChange: pageChange,
                              total:
                                props.paymentreports &&
                                props.paymentreports.meta &&
                                parseInt(props.paymentreports.meta.total_count),
                              hideOnSinglePage: true,
                              showSizeChanger: false,
                            }}
                            columns={column_data}
                            dataSource={Object.values(groupedData).map(
                              (items) => items[0]
                            )}
                            expandable={{
                              expandedRowRender: expandedRowRender,
                              rowExpandable: (record) => true,
                            }}
                          />
                          <div
                            className="col-12"
                            style={{ display: 'flex', justifyContent: 'end' }}
                          >
                            <table className="table" style={{ width: '410px' }}>
                              <tbody>
                                <tr className="table-info">
                                  <td>Total Amount</td>
                                  <td>
                                    {_.get(dataSource, [
                                      'moredata',
                                      'billAmount',
                                    ])}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : (
                        props.t('noData')
                      )}
                    </div>
                  </div>
                </items>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <PaymentTable
      paymentReportLoading={_.get(props, 'paymentReportLoading', false)}
      paymentReports={_.get(props, 'paymentreports', {})}
    />
  )
}

const mapStateToProps = (state) => ({
  paymentreports: state.common.paymentReport,
  userBaseProfile: state.userProfile.userBaseProfile,
  paymentReportLoading: state.common.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getPaymentReport: (requestParams) =>
    dispatch(paymentReportUser.paymentReport(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(FiltersReport))
