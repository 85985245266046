import React from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  notification,
  Button,
  Spin,
  Pagination,
  Form,
  Input,
  FloatButton,
  Empty,
} from 'antd'
import _ from 'lodash'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import queryString from 'query-string'
import redirectTo from '../../../utils/redirectTo'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as tutorAction from '../../../store/actions/public/tutor-public-profile/index'
import './style.css'
import generalAction from '../../../store/general/action'
import CampDetailsCard from './camp-details-card'
// import SessionInfo from '../../../components/re-usable/session-info'
// import AddToCartContainer from '../view-class-details/add-to-cart-container'
// import componentFunctionalities from './component-functionalities'
import SessionList from '../../../components/common/session-list-card'
// import AboutTutorData from '../../../components/re-usable/about-tutor';
import ClassCampCard from '../../../components/re-usable/class-camp-card'
import Ratings from '../../../components/rating/rating-list'
import * as faqActions from '../../../store/actions/tutor/add-class'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import viewClassCamps from '../../../utils/common/get-view-url'
import { withTranslation } from 'react-i18next'
import Linkify from 'linkify-react'

dayjs.extend(utc)
dayjs.extend(timezone)
const { TextArea } = Input

class ViewCampsDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.myClassesRef = React.createRef()
    this.myClassespickerRef = React.createRef()
    this.mySessionsRef = React.createRef()
  }

  state = {
    campDetail: { data: {}, fetching: false },
    selectPackagesData: { id: null, details: {} },
    selectedSession: {},
    askQuestion: false,
    detailsClicked: false,
  }

  modelcheck = () => {
    this.setState({ detailsClicked: false })
  }

  toSessionOverview = (record) => {
    this.setState({ selectedSession: record })
    this.scrollDetails()
    this.setState({ detailsClicked: true })
  }

  scrollDetailsSesssion = () => {
    this.mySessionsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  async componentDidMount() {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', false)
    if (gid) {
      this.enableSidebarAndUserMenu()
      this.getCampDetails(gid)
    }
    setTimeout(() => {
      const requestParams = {
        provider: _.get(this.state.campDetail.data.provider, 'id', null),
      }
      requestParams.limit = 8
      requestParams.count = 8
      requestParams.excludedId = gid
      this.props.onGetCampsList(requestParams)
      this.listFAQView(gid)
    }, 2000)
  }

  enableSidebarAndUserMenu = () => {
    this.props.turnOnTutorPublicDetailsLoader()
  }

  getCampDetails = (campId) => {
    const urlparams = queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    this.setState({ campDetail: { data: {}, fetching: true } })
    this.props.getCampDetails({
      params: { camp_id: campId, expand: 'provider,user' },
      callback: (success, data) => {
        if (success) {
          const campDetails = _.get(data, 'details', {})
          this.setState({ campDetail: { data: campDetails, fetching: false } })
          this.props.onGetTutorPublicProfile(
            _.get(campDetails, 'provider.id', null)
          )
        }
      },
    })
    this.listFAQView(gid)
    {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  scrollDetails = () => {
    this.myClassesRef.current.scrollIntoView({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  onChangePage = async (page, pageSize, type) => {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    this.setState({
      [type]: page,
    })
    console.log('page', this.props)
    const requestParams = {
      provider: this.props.campDetailsView.provider.id,
      page,
    }
    requestParams.limit = 8
    requestParams.count = 8
    requestParams.excludedId = gid
    type === 'campsListPage'
      ? this.props.onGetCampsList(requestParams)
      : this.props.onGetClassesList(requestParams)
  }

  commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
      top: 100,
    })
  }

  onfaqFinish = async (value, type) => {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    const data = { ...value }
    data.created_by = 'user'
    data.type = 'camps'
    data.timezone = dayjs.tz.guess()
    data.time = new Date()
    data.type_details = gid
    data.session_details = this.state.selectedSession.id
    this.faqActions(data)
  }

  faqActions = (values, type) => {
    this.setState({
      faq_loading: true,
    })
    this.props.createFaq(values, (response, type) => {
      this.setState({
        faq_loading: false,
        askQuestion: false,
      })

      if (type === 'success') {
        this.faqRef.current.resetFields()
        this.listFAQView(_.get(response, 'details.class_details'))

        this.commonSuccessErrorMessage('success', _.get(response, 'message'))
      } else if (_.get(response, 'response.data.errors')) {
        this.commonSuccessErrorMessage(
          'error',
          _.get(response, 'response.data.errors.rules[0].message')
        )
      } else if (_.get(response, 'response.data.message')) {
        this.commonSuccessErrorMessage(
          'error',
          _.get(response, 'response.data.message')
        )
      } else if (_.get(response, 'data.message')) {
        this.commonSuccessErrorMessage('error', _.get(response, 'data.message'))
      }
    })
  }

  listFAQView = (id) => {
    this.setState({
      faq_loading: true,
    })
    this.props.listFAQ(
      { type_details: id, sort: 'created_at.desc', limit: 1000 },
      (response, type) => {
        this.setState({
          faq_loading: false,
        })
        if (type === 'success') {
          this.setState({
            faqList: _.get(response, 'items', []),
          })
        } else if (_.get(response, 'response.data.errors')) {
          this.commonSuccessErrorMessage(
            'error',
            _.get(response, 'response.data.errors.rules[0].message')
          )
        } else if (_.get(response, 'response.data.message')) {
          this.commonSuccessErrorMessage(
            'error',
            _.get(response, 'response.data.message')
          )
        }
      }
    )
  }
  campDescriptionBlock = (data) => {

    return (
      <div
        className="description mt-1 mr-2"
        style={{
          padding: '10px 20px 10px 5px',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: 17,
          lineHeight: '27px',
          backdropFilter: 'none',
        }}
      >
        {!_.isEmpty(data) ? (
          <span className="parafont">
            <Linkify as="p">{parser(data)}</Linkify>
          </span>
        ) : (
          <Empty />
        )}
      </div>
    )
  }

  getFaq = (id) => {
    if (_.size(_.get(this, 'state.faqList')) > 0) {
      if (id) {
        const faqList = _.get(this, 'state.faqList')
        const filtered = faqList.filter((value) => value.session_details === id)
        if (_.size(filtered)) {
          return filtered.map((value, index) => (
            <div key={index} className="faq-set">
              <div className="faq-set-question">{_.get(value, 'question')}</div>
              <div
                className="faq-set-answer"
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {_.get(value, 'answer')}
              </div>
            </div>
          ))
        }
        return (
          <div>
            <EmptyAnime AnimeType="EmptyUserSide" />
            <p className="empty-animations">{this.props.t('noFAQF')}</p>
          </div>
        )
      }
      const faqList = _.get(this, 'state.faqList')
      return faqList.map((value, index) => (
        <div key={index} className="faq-set">
          <div className="faq-set-question">{_.get(value, 'question')}</div>
          <div className="faq-set-answer" style={{ whiteSpace: 'pre-wrap' }}>
            {_.get(value, 'answer')}
          </div>
        </div>
      ))
    }
    if (_.get(this, 'state.faq_loading') === true) {
      return (
        <Spin
          style={{
            marginTop: '122px',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            position: 'absolute',
          }}
          size="large"
          tip="Loding..."
        />
      )
    }
    return (
      <div>
        <EmptyAnime AnimeType="EmptyUserSide" />
        <p className="empty-animations">{this.props.t('noFAQF')}</p>
      </div>
    )
  }

  render() {
    const { askQuestion, detailsClicked } = this.state
    const urlparams = queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    const bid = _.get(urlparams, 'bid', null)
    const providerDetail = _.get(this, 'state.campDetail.data.provider', {})
    const fn = providerDetail.firstname
    const ln = providerDetail.lastname
    const bn = providerDetail.businessname
    const ty = providerDetail.type
    const providerName =
      ty === 'Individual' ? `${fn} ${ln}` : !_.isEmpty(bn) ? bn : `${fn} ${ln}`

    const provider = {
      providerName,
      providerType: ty,
    }
    const campDetails = _.get(this, 'state.campDetail.data', {})
    console.log("campDEtails>>>>>>>>>", campDetails);
    const description = _.get(campDetails, 'description', '')
    console.log("description>>>>>>>>>", description);

    const session_name = _.get(this.state.selectedSession, 'session_name', '')
    const isNotEmptySelectedSession = !_.isEmpty(this.state.selectedSession)

    return (
      <>
        <div
          className="nurtem-card-design mt-2 mb-3"
          style={{ padding: '20px 45px' }}
        >
          <CampDetailsCard
            onScrollView={this.scrollDetails}
            {...this.props}
            componentInput={{
              ..._.get(this, 'state.campDetail', {}),
            }}
          />

          <div
            id="sessionblock"
            ref={this.myClassesRef}
            className="full-width mt-4"
          >
            <div className="sub-title ml-2">
              {this.props.t('batchAvailable')}
            </div>
            <SessionList
              groupID={gid}
              type="camps"
              callback={this.toSessionOverview}
              status={0}
              live
              bid={bid}
            />
          </div>
          {/* <Modal
            className="sessionPopupCard"
            // style={{width:'70%'}}
            title={_.startCase(session_name)}
            open={isNotEmptySelectedSession && detailsClicked}
            onCancel={this.modelcheck}
            footer={null}
          >
            <div ref={this.mySessionsRef} className="full-width">
              <div className="row">
                <div className="position-static">
                  <div className="" style={{ height: '94%' }}>
                    <div className="row">
                      {isNotEmptySelectedSession && (
                        <>
                          <div className="overview-description-header col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <SessionInfo
                              record={this.state.selectedSession}
                              type="camps"
                            />
                          </div>

                          <div className=" col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            {!componentFunctionalities.isCampFull(
                              _.get(this, 'state.campDetail', {})
                            ) ? (
                              <AddToCartContainer
                                onScrollView={this.scrollDetails}
                                selectedSession={this.state.selectedSession}
                                campDetail={_.get(this, 'state.campDetail', {})}
                                callback={this.modelcheck}
                                type="camps"
                              />
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}

          {/* {isNotEmptySelectedSession &&
            !_.isEmpty(this.state.selectedSession.description) && (
              <div className="sub-title mt-4  ml-2">Description</div>
            )}
          {isNotEmptySelectedSession &&
            !_.isEmpty(this.state.selectedSession.description) && (
              <div className="row">
                <div className="col-12">
                  <div className="container mt-3 mb-2 parafont">
                    <p>
                      <span className=" pr-4">
                        <b>{this.props.t('students')}</b>
                        :&nbsp;
                        {this.state.selectedSession.no_of_max_students}{' '}
                      </span>{' '}
                      <b> {this.props.t('tutor')}: </b>
                      {providerName}{' '}
                    </p>
                    {parser(this.state.selectedSession.description)}
                  </div>
                </div>
              </div>
            )} */}

          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            style={{ paddingLeft: 0, float: 'right' }}
          >
            <span className="sub-title mt-4  ml-2">{this.props.t('descp')}</span>
            {this.campDescriptionBlock(description)}
          </div>
          {!_.isEmpty(this.state.selectedSession.Ratings) && (
            <div className="sub-title mt-4  ml-2">{this.props.t('r&r')}</div>
          )}
          <div className="full-width mb-4 ">
            <div className=" mb-4 mt-4 ml-2">
              <div className="sub-title mt-4  ml-2">
                {' '}
                {this.props.t('faqs')}{' '}
              </div>
              {_.get(this.props, 'loggedInUserDetails.isLoggedIn') === true ? (
                <span
                  onClick={() => this.setState({ askQuestion: true })}
                  className="faq-add-link"
                >
                  {this.props.t('askQuestion')}
                </span>
              ) : (
                <span onClick={() => redirectTo()} className="faq-add-link">
                  {this.props.t('login')} to {this.props.t('askQuestion')}{' '}
                </span>
              )}
            </div>
            <div className="faq-set-container ml-3">
              {isNotEmptySelectedSession &&
                this.getFaq(this.state.selectedSession.id)}
            </div>
          </div>

          {/* <div className="tutor-public-profile-container about-title mt-5 mb-2">
                        <div className="sub-title  ml-2">{this.props.t("aboutTutor")}</div>
                    </div>
                    <AboutTutorData type={'classes'} provider={_.get(this, 'state.campDetail.data.provider', {})} /> */}
          <div
            ref={this.myCampspickerRef}
            className="tutor-public-profile-container about-title mt-5 mb-2"
          >
            <div className="sub-title  ml-2">
              {this.props.t('availableCamps')}
            </div>
          </div>
          <div
            className="more-classes other-camps"
            style={{ backgroundColor: '#fff' }}
          >
            <div className="row">
              {this.props.campsList &&
                this.props.campsList.meta &&
                this.props.campsList.meta.count > 0 ? (
                this.props.campsList.items.map((value, key) => {
                  if (value.id !== gid) {
                    return (
                      <div
                        key={`class_list${key}`}
                        className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-4"
                      >
                        <div className="nurtem-profile-class-card">
                          <Link
                            onClick={() => this.getCampDetails(value.id)}
                            to={viewClassCamps(providerDetail, value, 'camp')}
                          >
                            <>
                              {' '}
                              <ClassCampCard
                                data={value}
                                providerdata={provider}
                              />
                            </>
                          </Link>
                        </div>
                      </div>
                    )
                  }
                  return null
                })
              ) : (
                <div className="profile-profile-name explorer">
                  <div>
                    <EmptyAnime AnimeType="EmptyUserSide" />
                    <p className="empty-animations">
                      {this.props.t('noCampsF')}
                    </p>
                  </div>
                </div>
              )}

              <div className="full-width mb-4">
                {this.props.campsList &&
                  this.props.campsList.items &&
                  this.props.campsList.items.length > 0 &&
                  this.props.campsList &&
                  this.props.campsList.meta &&
                  this.props.campsList.meta.total_count > 8 ? (
                  <Pagination
                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                    defaultCurrent={1}
                    current={this.state.campsListPage}
                    showSizeChanger={false}
                    onChange={(page, pageSize) => {
                      this.onChangePage(page, pageSize, 'campsListPage')
                    }}
                    defaultPageSize={_.get(
                      this.props.campsList,
                      'meta.limit',
                      0
                    )}
                    total={_.get(this.props.campsList, 'meta.total_count', 0)}
                    className="ant-table-pagination"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="faq-modal"
          open={askQuestion}
          centered
          title="Ask a Questions"
          footer={null}
          onCancel={() => this.setState({ askQuestion: false })}
        >
          <Form
            scrollToFirstError
            onFinish={(e) => this.onfaqFinish(e)}
            ref={this.faqRef}
          >
            <div className="row">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.props.t('Queisreq'),
                  },
                ]}
                name="question"
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <div className="full-width">
                  <div className="full-width position-relative">
                    <TextArea rows={3} className="border-radius-10" />
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="col-12">
                <div className="full-width text-right">
                  <Button
                    loading={this.state.faq_loading}
                    htmlType="submit"
                    className="btn-submit mr-3"
                  >
                    Submit
                  </Button>
                  <Button
                    disabled={this.state.faq_loading}
                    onClick={() => this.setState({ askQuestion: false })}
                    className="btn-cancel"
                  >
                    {this.props.t('cancel')}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
        <FloatButton.BackTop className="back-to-top" />
      </>
    )
  }
}

const mapStateToProps = (state) =>
// console.log("services", state);
({
  sessions: state.sessions.sessions,
  campDetailsView: _.get(state, 'general.camps.view.details', {}),
  userBaseProfile: state.userProfile.userBaseProfile,
  campsList: state.campsDetails.campsList,
  tutorDetails: state.tutor.tutorBaseProfile,
  loggedInUserDetails: state.authentication,
})
const mapDispatchToProps = (dispatch) => ({
  turnOnTutorPublicDetailsLoader: () =>
    dispatch(tutorAction.turnOnTutorPublicDetailsLoader()),
  onGetTutorPublicProfile: (providerId) =>
    dispatch(tutorAction.getTutorPublicProfile(providerId)),
  getCampDetails: (details) => dispatch(generalAction.camps.view(details)),
  onGetCampsList: (providerId) =>
    dispatch(tutorAction.getCampsList(providerId)),
  listFAQ: (data, callback) => dispatch(faqActions.listFAQ(data, callback)),
  createFaq: (data, callback) => dispatch(faqActions.createFAQ(data, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewCampsDetails))
