import React, { FC, ReactNode, CSSProperties, MouseEventHandler } from 'react';
import { Button } from 'antd';
import { ButtonType, ButtonShape } from 'antd/lib/button';

interface CustomButtonProps {
  type?: ButtonType;
  className?: string;
  htmlType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  shape?: ButtonShape;
  style?: CSSProperties;
  children: ReactNode;
  icon?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  loading?: boolean;
  danger?: boolean;
  disabled?: boolean;
}

export const CustomButton: FC<CustomButtonProps> = ({
  type = 'primary',
  className,
  onClick,
  htmlType,
  icon,
  loading,
  style,
  shape,
  danger,
  disabled,
  children,
}) => {
  return (
    <Button
      type={type}
      className={className}
      onClick={onClick}
      htmlType={htmlType}
      icon={icon}
      loading={loading}
      style={style}
      shape={shape}
      danger={danger}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
