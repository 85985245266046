import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import history from '../../../utils/history'
import * as action from '../../../store/actions/tutor/camps'
import * as actionclass from '../../../store/actions/tutor/Classes'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import componentFunctionalities from '../view-class-details/component-functionalities'
import AddToCartContainer from '../view-class-details/add-to-cart-container'
import LabelConvertion from '../../../components/re-usable/label-convertion'
import { BsCalendar3, BsPersonPlusFill } from 'react-icons/bs'
import TimingsDisplay from '../../../components/common/timingsDisplay'
import { seatsAvailableCount } from '../../../utils/common'
import css from '../../../utils/CSSUtility'
import parser from 'html-react-parser'
import { NurtemUtils } from '../../../utils'
import Linkify from 'linkify-react'
import { PiChalkboardTeacherFill } from 'react-icons/pi'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import { CLASSPNG } from '../../../utils/config/assets'
import { IoLocationSharp } from 'react-icons/io5'
import { MdOutlineAccessTime } from 'react-icons/md'
import { FaRegCalendarCheck, FaRegCalendarPlus } from 'react-icons/fa6'
import { TfiCup } from 'react-icons/tfi'
import * as eventActions from '../../../store/actions/tutor/events'

const BatchView = (props) => {
  // console.log("props>>>>>>>", props)
  const urlparams = queryString.parse(window.location.search)
  // console.log("urlparams>>>>>>>", urlparams)
  const groupID = urlparams.gid
  const sessionID = urlparams.bid
  const batchtype = urlparams.type

  useEffect(() => {
    if (groupID && sessionID) {
      props.onsessionDetail({
        id: sessionID,
        expand: 'services,provider',
        type: batchtype,
      })
      batchtype === 'classes' &&
        props.onGetClassDetails({
          id: groupID,
          expand: 'provider',
        })
      batchtype === 'camps' &&
        props.onGetCampsDetails({
          camp_id: groupID,
          expand: 'provider',
        })
      batchtype === 'competitions' &&
        props.onCompetitionDetails({
          id: groupID,
          expand: 'provider',
        })
      batchtype === 'events' &&
        props.onEventDetail({
          id: groupID,
          expand: 'provider',
        })
    } else {
      history.goBack()
    }
  }, [])

  const capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  const SessionDetailView = _.get(props, 'sessionDetail', {})
  const isNotEmptySessionDetailView = !_.isEmpty(SessionDetailView)
  const classDetailView = _.get(props, 'classDetails', {})
  const campDetailView = _.get(props, 'CampsDetails', {})
  const competitionDetailView = _.get(props, 'CompetitionsDetails.details', {})
  const eventDetailView = _.get(props, 'onEventDetails.details', {})
  // console.log("evntDetailView>>>>>>>", eventDetailView);
  // console.log("SessionDetailView>>>>>>>", SessionDetailView);

  const className = _.get(classDetailView, 'name', '')
  const classPhoto = _.get(classDetailView, 'photo', '')
  const campName = _.get(campDetailView, 'name', '')
  const campPhoto = _.get(campDetailView, 'photo', '')
  const competitionName = _.get(competitionDetailView, 'name', '')
  const competitionPhoto = _.get(competitionDetailView, 'photo', '')
  const eventName = _.get(eventDetailView, 'name', '')
  const eventPhoto = _.get(eventDetailView, 'photo', '')

  const batchName = _.get(SessionDetailView, 'session_name', '')
  const batchVariant = _.get(SessionDetailView, 'variant', '')
  const batchTimeSlot = _.get(SessionDetailView, 'time_slot', {})
  const items = <TimingsDisplay timings={batchTimeSlot} />
  const location = _.get(SessionDetailView, 'location_taught', '')
  const teachMode = _.get(SessionDetailView, 'teaching_mode', '')
  const descp = _.get(SessionDetailView, 'description', '')
  const startDate = _.get(SessionDetailView, 'start_date', '')
  const endDate = _.get(SessionDetailView, 'end_date', '')
  const maxStud = _.get(SessionDetailView, 'no_of_max_students', '')
  const maxCompeStud = _.get(SessionDetailView, 'max_no_of_participants', '')
  const additonalDetails = _.get(SessionDetailView, 'additional_details', {})
  const total_seats_left = seatsAvailableCount(
    (batchtype === 'competitions' || batchtype === 'events') ? maxCompeStud : maxStud,
    additonalDetails
  )
  const bookStartDate = _.get(SessionDetailView, 'start_date', '')
  const bookEndDate = _.get(SessionDetailView, 'end_date', '')
  const compeResultDate = _.get(
    SessionDetailView,
    'competition_result_date',
    ''
  )
  const durationDetail = _.get(SessionDetailView, 'duration_competition', '')
  const compeStartDate = _.get(SessionDetailView, 'competition_start_date', '')
  const compeEndDate = _.get(SessionDetailView, 'competition_end_date', '')
  const compeRules = _.get(SessionDetailView, 'rules', '')
  const compTerms = _.get(SessionDetailView, 'terms_conditions', '')
  const detailsofAddon = batchtype === 'classes' ? props.classDetails : batchtype === 'camps' ? props.CampsDetails
    : batchtype === 'competitions' ? competitionDetailView : eventDetailView
  // console.log("campDetail>>>>>>>", detailsofAddon);

  return (
    <div
      style={{ backgroundColor: '#fff', height: 'auto', padding: '10px 50px' }}
    >
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className="head-classdetails-page-title  ml-3">
            {className && capitalizeWord(className)}
            {campName && capitalizeWord(campName)}
            {competitionName && capitalizeWord(competitionName)}
            {eventName && capitalizeWord(eventName)}
          </div>
          <div className="head-batchname-page-title ml-3">
            {batchName && capitalizeWord(batchName)}
          </div>
        </div>
      </div>
      <div
        className="col-12 col-sm-12 col-md-6 col-lg-12 col-xl-12 "
        style={{ display: 'flex' }}
      >
        <div
          className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 PopupMenuImage"
          style={{ padding: '10px 10px' }}
        >
          <div
            className="class-img-detail"
            style={{
              boxShadow: '0 4px 8px rgb(65 120 255 / 33%)',
              height: 350,
            }}
          >
            <img
              style={{ height: '100%' }}
              src={
                classPhoto || campPhoto || competitionPhoto || eventPhoto
                  ? classPhoto || campPhoto || competitionPhoto || eventPhoto
                  : CLASSPNG
              }
              className="profile-camp-image mr-2"
              alt="img"
            />
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
          {!componentFunctionalities.isCampFull(props.classDetails) ? (
            <AddToCartContainer
              selectedSession={SessionDetailView}
              type={batchtype}
              campDetail={detailsofAddon}
            />
          ) : null}
        </div>
      </div>

      <div style={{ display: 'flex', margin: '10px 20px' }}>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
          <div>
            <span style={css.heading1}>
              <MdOutlineAccessTime
                style={{ fontSize: 25, color: '#008ad1' }}
                className="mr-2"
              />
              Time Duration
            </span>
            <div style={{ width: 300, marginLeft: 35, marginTop: 5 }}>
              {items}
            </div>
            <div style={{ marginLeft: 35, marginTop: 5 }}>
              <span style={css.header}>
                {batchVariant === 'o'
                  ? `( ${SessionDetailView.selected_days.length} ${SessionDetailView.selected_days.length === 1 ? 'Day' : 'Days'} / week)`
                  : batchVariant === 'wd'
                    ? '(5 Days / Week)'
                    : batchVariant === 'wend'
                      ? '(2 Days / Week)'
                      : ''}
                {batchVariant === 'fw' && '(7 Days / Week)'}
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
          {teachMode && (
            <div>
              <span style={css.heading1}>
                <PiChalkboardTeacherFill
                  style={{ fontSize: '30px', marginRight: 5, color: '#008ad1' }}
                />
                {batchtype === 'events' ? props.t('eMode') : props.t('tMode')}
              </span>
              <h6 style={{ marginTop: '10px', marginLeft: '43px' }}>
                {LabelConvertion(teachMode, 'teaching-mode')}
              </h6>
            </div>
          )}
          <div style={{ marginTop: 30 }}>
            <span style={css.heading1}>
              <BsPersonPlusFill
                style={{ fontSize: 25, marginRight: 10, color: '#008ad1' }}
              />
              {props.t('seatLeft')}
            </span>
            <h6 style={{ marginTop: '10px', marginLeft: '37px' }}>
              {total_seats_left} Seats
            </h6>
          </div>
          {(batchtype === 'competitions') && (
            <div style={{ marginTop: 30 }}>
              <span style={css.heading1}>
                <FaRegCalendarCheck
                  style={{ fontSize: 25, marginRight: 10, color: '#008ad1' }}
                  className="mr-2 Popupdesign"
                />
                Results On
              </span>
              <h6 style={{ marginTop: '10px', marginLeft: '35px' }}>
                {timeDateConverterSession(
                  compeResultDate,
                  'date',
                  'MMM DD,YYYY'
                )}
              </h6>
            </div>
          )}
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
          {batchtype === 'competitions' || batchtype === 'events' ? (
            <div>
              <span style={css.heading1}>
                <FaRegCalendarPlus
                  style={{ fontSize: 23, color: '#008ad1' }}
                  className="mr-2 Popupdesign"
                />{' '}
                Registration Opens
              </span>
              <h6 style={{ marginTop: '10px', marginLeft: '35px' }}>
                {`${timeDateConverterSession(bookStartDate, 'date', 'MMM DD,YYYY')} to ${timeDateConverterSession(bookEndDate, 'date', 'MMM DD,YYYY')}`}
              </h6>
            </div>
          ) : (
            <div>
              <span style={css.heading1}>
                <BsCalendar3
                  style={{ fontSize: 23, color: '#008ad1' }}
                  className="mr-2 Popupdesign"
                />
                {batchtype === 'classes' ? 'Class Duration' : 'Camp Duration'}
              </span>
              <h6 style={{ marginTop: '10px', marginLeft: '35px' }}>
                {`${timeDateConverterSession(startDate, 'date', 'MMM DD,YYYY')} to ${timeDateConverterSession(endDate, 'date', 'MMM DD,YYYY')}`}
              </h6>
            </div>
          )}
          {(batchtype === 'competitions' || batchtype === 'events') &&
            (durationDetail === '1' ? (
              <div style={{ marginTop: 30 }}>
                <span style={css.heading1}>
                  <TfiCup
                    style={{ fontSize: 25, marginRight: 10, color: '#008ad1' }}
                    className="mr-2 Popupdesign"
                  />
                  {batchtype === 'competitions' ? 'Competition On' : 'Event on'}
                </span>
                <h6 style={{ marginTop: '10px', marginLeft: '35px' }}>
                  {timeDateConverterSession(
                    compeStartDate,
                    'date',
                    'MMM DD,YYYY'
                  )}
                </h6>
              </div>
            ) : (
              <div style={{ marginTop: 30 }}>
                <span style={css.heading1}>
                  <TfiCup
                    style={{ fontSize: 25, marginRight: 10, color: '#008ad1' }}
                    className="mr-2 Popupdesign"
                  />
                  {batchtype === 'competitions' ? 'Competition On' : 'Event on'}
                </span>
                <h6 style={{ marginTop: '10px', marginLeft: '35px' }}>
                  {`${timeDateConverterSession(compeStartDate, 'date', 'MMM DD,YYYY')} - ${timeDateConverterSession(
                    compeEndDate,
                    'date',
                    'MMM DD,YYYY'
                  )}`}
                </h6>
              </div>
            ))}
          <div>
            {location && (teachMode === 'i' || teachMode === 'o') && (
              <div style={{ marginTop: 30 }}>
                <span>
                  <span style={css.heading1}>
                    {' '}
                    <IoLocationSharp
                      style={{
                        fontSize: '30px',
                        marginRight: 7,
                        color: '#008ad1',
                      }}
                    />
                    Location
                  </span>
                  <h6
                    style={{
                      marginLeft: '39px',
                      fontSize: '16px',
                      fontWeight: '400',
                      color: '#000',
                      marginTop: '10px',
                    }}
                  >
                    {location}
                  </h6>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
        <span className="ml-3" style={css.heading1}>
          {props.t('descp')}
        </span>
        <div
          style={{
            color: 'black',
            fontWeight: 'normal',
            marginLeft: 15,
            textAlign: 'justify',
            marginTop: '10px',
          }}
        >
          {descp &&
            descp.split('\n').map((paragraph, index) => (
              <p key={index} style={{ fontSize: 17 }}>
                <Linkify>{parser(NurtemUtils.urlify(paragraph))}</Linkify>
              </p>
            ))}
        </div>
      </div>
      {(batchtype === 'competitions' || batchtype === 'events') &&
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <span className="ml-3" style={css.heading1}>
            {batchtype === 'competitions' ? props.t('batchDetails39') : props.t('eventRules')}
          </span>
          <div
            style={{
              color: 'black',
              fontWeight: 'normal',
              marginLeft: 15,
              textAlign: 'justify',
              marginTop: '10px',
            }}
            className='parafont'
          >
            {compeRules &&
              compeRules.split('\n').map((paragraph, index) => (
                <p key={index} style={{ fontSize: 17 }}>
                  <Linkify>{parser(NurtemUtils.urlify(paragraph))}</Linkify>
                </p>
              ))}
          </div>
        </div>}
      {(batchtype === 'competitions' || batchtype === 'events') &&
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <span className="ml-3" style={css.heading1}>
            {props.t('terms&conditions')}
          </span>
          <div
            style={{
              color: 'black',
              fontWeight: 'normal',
              marginLeft: 15,
              textAlign: 'justify',
              marginTop: '10px',
            }}
            className='parafont'
          >
            {compTerms &&
              compTerms.split('\n').map((paragraph, index) => (
                <p key={index} style={{ fontSize: 17 }}>
                  <Linkify>{parser(NurtemUtils.urlify(paragraph))}</Linkify>
                </p>
              ))}
          </div>
        </div>}
    </div>
  )
}
const mapStateToProps = (state) => ({
  sessionDetail: state.campsDetails.sessionDetail.data,
  classDetails: state.classesDetails.tutorClassDetails.details,
  CampsDetails: state.campsDetails.tutorCampsDetails.details,
  CompetitionsDetails: state.competitionsDetails.CompetitionDetails,
  onEventDetails: state.eventsDetails.EventDetails,
})

const mapDispatchToProps = (dispatch) => ({
  onGetClassDetails: (requestParams) =>
    dispatch(actionclass.onGetClassDetails(requestParams)),
  onGetCampsDetails: (requestParams) =>
    dispatch(action.onGetCampsDetails(requestParams)),
  onCompetitionDetails: (requestParams) =>
    dispatch(competitionActions.onGetCompetitionDetails(requestParams)),
  onsessionDetail: (requestParams) =>
    dispatch(action.onGetsessionDetail(requestParams)),
  onEventDetail: (requestParams) =>
    dispatch(eventActions.onGetCompetitionDetails(requestParams)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BatchView))
