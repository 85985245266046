import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import * as formatter from '../../../utils/formatter';
import { DEFAULTUSER } from '../../../utils/config/assets';

class StudentDetailsComponent extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  profile = (user_details, user_class_info) => {
    console.log('user_details', user_class_info);
    const filtered_user_details = {
      photo: !_.isEmpty(_.trim(_.get(user_details, 'photo', null))) ? _.get(user_details, 'photo') : DEFAULTUSER,
      firstname: _.isEmpty(user_class_info.dependent_user) ? !_.isEmpty(_.trim(_.get(user_details, 'firstname', null))) ? _.get(user_details, 'firstname') : '(Student Name Is Not Exist)' : _.startCase(user_class_info.dependent_user.firstname),
      mobile_number: !_.isEmpty(_.trim(_.toString(_.get(user_details, 'mobile_number', null))))
        ? formatter.formatToUSPhoneNumber(_.get(user_details, 'mobile_number'), true)
        : '(Student Name Is Not Exist)',
      email: !_.isEmpty(_.trim(_.get(user_details, 'email', null)))
        ? _.get(user_details, 'email')
        : '(Student Email Is Not Exist)',
    };

    return (
      <div className="row align-items-center">
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <img className="student-profile" src={_.get(filtered_user_details, 'photo')} alt="tutor-dashboard-view-student" />
        </div>
        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
          <div className="student-semi-bold">{_.get(filtered_user_details, 'firstname')}</div>
          <div className="student-regular">{_.get(user_class_info, 'dependent_user') ? _.get(user_class_info, 'dependent_user.email') : _.get(filtered_user_details, 'mobile_number')}</div>
          <div className="student-regular">{_.get(user_class_info, 'dependent_user') ? null : _.get(filtered_user_details, 'email')}</div>
        </div>
      </div>
    );
  };

  milestones = (user_class_info) => (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div className="row">
        <div className="col-auto pr-0">
          <i className="ntm-milestone student-icon" />
        </div>
        <div className="col">
          <div className="student-semi-bold">
            {formatter.addZeroToSingleDigit(_.size(_.get(user_class_info, 'milestones', [])))}
            /
            {formatter.addZeroToSingleDigit(_.size(_.get(user_class_info, 'class_details.milestones', [])))}
          </div>
          <div className="student-regular">{this.props.t('milestones')}</div>
        </div>
      </div>
    </div>
  );

  completion = (user_class_info) => (
    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <div className="row">
        <div className="col-auto pr-0">
          <i className="ntm-percentage student-icon" />
        </div>
        <div className="col">
          <div className="student-semi-bold">
            {_.get(user_class_info, 'completion_status', 0)}
            %
          </div>
          <div className="student-regular">{this.props.t('completion')}</div>
        </div>
      </div>
    </div>
  );

  mainComponent = (user_class_info) => {
    const user_details = _.get(user_class_info, 'user', {});
    return (
      <div className="tutor-dashboard-view-header mb-3 pt-1">
        <div className="row align-items-center">
          <div className="col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7">
            {/* Profile Block */}
            {!_.isEmpty(user_details) ? this.profile(user_details, user_class_info) : null}
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
            <div className="row">
              {/* Milestones Block */}
              {this.milestones(user_class_info)}

              {/* Completion Block */}
              {this.completion(user_class_info)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const user_class_info = _.get(this, 'props.userClassInfo', {});

    return (
      <>
        {!_.isEmpty(user_class_info) ? this.mainComponent(user_class_info) : null}
      </>
    );
  }
}

export default withTranslation()(StudentDetailsComponent);
