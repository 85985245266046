import axios from 'axios';
import baseurl from './baseurl';

const getUserLanguage = window.navigator.userLanguage || window.navigator.language;

const result = getUserLanguage.includes('en') ? 'en' : getUserLanguage;

const instance = axios.create({
  baseURL: baseurl,
  params: {
    lang: result || 'en',
  },
});

export default instance;
