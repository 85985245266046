const LabelConversion = (value, type) => {
  const conversionMap = {
    'teaching-mode': {
      o: 'Online',
      i: 'In-Person',
      d: 'Drive to Student',
      h: 'Hybrid'
    },
    variant: {
      o: 'Others',
      wd: 'Mo,Tu,We,Th,Fr',
      wdm: 'Weekdays Mornings',
      wde: 'Weekdays Evening',
      wend: 'Sat,Sun',
      wendm: 'Weekend Morning',
      wende: 'Weekend Evenings',
      fw: 'Su,Mo,Tu,We,Th,Fr,Sa',
      fwm: 'Full week Morning',
      fwe: 'Full week evening',
    },
    'teaching-levels': {
      b: 'Beginner',
      i: 'Intermediate',
      e: 'Expert',
    },
    'billing-type': {
      0: 'Package',
      1: 'Session',
      2: 'Monthly',
      3: 'Season',
    },
    payment_cycle_type: {
      1: 'Fixed',
      2: 'Variable',
    },
    teaching_type: {
      g: 'Groups',
      i: 'Individual',
    },
  };

  const conversion = conversionMap[type] || {};
  return conversion[value] || '';
};

export default LabelConversion;
