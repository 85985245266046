import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import './student-list.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DEFAULTUSER } from '../../utils/config/assets';
import * as classaction from '../../store/actions/tutor/Classes';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';

const StudentList = (props) => {
  const [page, setPage] = useState(1);
  const columns = [
    {
      title: props.t('students'),
      dataIndex: 'students',
      key: 'students',
      align: 'center',
    },
    {
      title: props.t('sessPurchased'),
      dataIndex: 'purchased',
      key: 'purchased',
      align: 'center',
    },
    {
      title: props.t('due'),
      dataIndex: 'message',
      key: 'message',
      align: 'center',
    },
  ];
  useEffect(() => {
    async function fetchStudents() {
      if (props.session.id && props.session.id !== '') { // Only send the API request if camp_details is present or changed
        const notesParams = {
          limit: 100,
          status: 1,
          provider: props.session.provider,
          page,
          sort: 'created_at.desc',
          expand: 'user,dependent_user,class_details,payment_details',
        };

        if (props.type === 'classes') {
          notesParams.class_details = props.session.id;
          await props.getClassesStudents(notesParams);
        }
      }
    }

    fetchStudents();
  }, [props.session.id]);

  const pageChange = async (page, pageSize) => {
    await setPage(page);
    const notesParams = {
      camp_details: props.session.id,
      limit: 100,
      status: 1,
      provider: props.session.provider,
      page,
      sort: 'created_at.desc',
      expand: 'camp_details,user,dependent_user',
    };
    if (props.type === 'classes') {
      await props.getClassesStudents(notesParams);
    }
  };

  const customLocale = {
    emptyText: (<div>
      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

      <p className="empty-animations">{props.t('noStu')}</p>
                </div>),
  };

  const render = () => {
    let tableDatatest = [];
    if (
      props.classesStudents
      && props.classesStudents.items
      && props.classesStudents.items.length > 0
    ) {
      const studentslistfilters = _.uniqBy(_.get(props, 'classesStudents.items'), (n) => (!_.isEmpty(n.dependent_user) ? n.dependent_user.id : n.user && n.user.id));
      tableDatatest = _.size(studentslistfilters) > 0 && studentslistfilters.map((value) => {
        const name_title_value = value && _.isEmpty(value.dependent_user) ? value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}` : _.startCase(value.dependent_user.firstname);
        const userPhoto = value && _.get(value, 'user.photo');
        return {
          key: value.id,
          students: <div className="table-profile">
            <div>
              <img src={userPhoto || DEFAULTUSER} className="profile-image" alt="img" />
            </div>
            <div className="text-truncate" title={name_title_value}>
              {value && _.isEmpty(value.dependent_user)
                ? (value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))}` : ''}`)
                : _.startCase(value.dependent_user.firstname)}
              {_.get(value, 'status', 0) === 0 ? '( Awaiting for response )' : ''}
            </div>
                    </div>,
          paid: _.get(value, 'payment_details.payment_source') === 'offline'
            ? _.startCase(_.get(value, 'payment_details.payment_object.payment_mehtod'))
            : _.startCase(_.get(value, 'payment_details.payment_object.payment_method_details.type')),
          // attendance: <div>{_.get(value, 'session_attended', '')}</div>,
          purchased: <div>{_.get(value, 'sessionsPaid.sessionPaid', '')}</div>,
          // contact: <div>{_.get(value, 'user.mobile_number', '')}</div>,
          message:
  <div>
    {/* <Button className="btn-view-outline">View</Button>
              <Button className="btn-view-outline" style={{ padding: '0', width: '50px' }}><Link
                to={`/tutor/student-view/${_.get(value, 'user.id')}?sid=${_.get(value, 'id')}`}
              >View</Link></Button> */}
    {_.get(value, 'session_attended', '') > _.get(value, 'sessionsPaid.sessionPaid', '')
      ? _.subtract(_.get(value, 'session_attended', 0), _.get(value, 'sessionsPaid.sessionPaid', 0)) : '---'}
  </div>,
        };
      });
    }

    return (
      <div className="container">
        {/* items Header */}
        <div className="row mb-3">
          <div className=" col-6 tab-content-title">{props.t('stuList')}</div>
        </div>
        <div className="row" style={{margin:0}}>
          <Table
            className="tablestyle"
            loading={_.get(props, 'ClassesStudentListLoading', false)}
            dataSource={tableDatatest}
            columns={columns}
            style={{ width: '100%' }}
            locale={customLocale}
            pagination={{
              pageSize: 100,
              onChange: (page, pageSize) => pageChange(page, pageSize),
              total:
                props.classesStudents
                && props.classesStudents.meta
                && parseInt(props.classesStudents.meta.total_count),
              hideOnSinglePage: !props.loading || props.loading,
              showSizeChanger: false,
              current: page,
            }}
          />
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  businessClassesStudents: state.classesDetails.businessClassesStudents,
  classesStudents: state.classesDetails.classesStudents,
  businessClassesStudentListLoading: state.classesDetails.businessClassesStudentListLoading,
  ClassesStudentListLoading: state.classesDetails.ClassesStudentListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getClassesStudents: (requestParams) => dispatch(classaction.getClassesStudents(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentList));
