import React from 'react';
import { Link } from 'react-router-dom';
import * as routeName from '../../../components/route-names'

export default function header(props) {
  return (
    <div className="tutor-add-class-header px-2">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 d-flex">
          <span className="add-class-icon mr-4"><i className="ntm-open-book" /></span>
          <span className="add-class-title mr-4" >
            {props.actiontype === 'update'
              ? `Update ${props.title}`
              : `Add ${props.title}`}
          </span>
        </div>
      </div>
    </div>
  );
}
