import React from 'react';
import { Route } from 'react-router-dom';
import WebLayout from './index';

class LayoutContent extends React.Component {
  render() {
    return (

      <Route path="/" component={WebLayout} />

    );
  }
}

export default LayoutContent;
