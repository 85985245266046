import React, { useEffect, useState } from 'react'
import {
  Button,
  Tabs,
  Pagination,
  Table,
  notification,
  Card,
  Row,
  Col,
  Collapse,
} from 'antd'
import {
  AiOutlineCalendar,
  AiOutlineTeam,
  AiOutlineClockCircle,
} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import './tutor-dashboard.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import dayjs from 'dayjs'
import generalAction from '../../../store/general/action'
import * as actions from '../../../store/actions/tutor/camps'
import * as commonAction from '../../../store/actions/tutor/common'
import * as compActions from '../../../store/actions/tutor/competitions'
import history from '../../../utils/history'
import './business-dashboard.css'
import * as routeName from '../../../components/route-names'
import { TodaysClassLoader } from '../../../components/loader/common/class-list-loader'
import { ClassCatLoader } from '../../../components/loader/common/class-cat-loader'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import SessionsLeft from '../../../components/re-usable/SessionsLeft'
import LabelConversion from '../../../components/re-usable/label-convertion'
import { DEFAULTUSER, CLASSPNG } from '../../../utils/config/assets'
import campImage from '../../../assets/images/camp3.png'
import classImage from '../../../assets/images/class.png'
import EventsImage from '../../../assets/images/Events.png'
import TestPrep from '../../../assets/images/TestPrep.png'
import Course from '../../../assets/images/Course.png'
import competitionImage from '../../../assets/images/competition.png'
import css from '../../../utils/CSSUtility'
import student from '../../../assets/images/student.jpg'
import timeZone from '../../../components/common/timezone'
import formatUrlPart from '../../../utils/common/format-url-part'
import TutorRequest from '../tutor-request/tutor-request-dash'
import OnboardingWizardDash from '../onboarding-wizard/onboarding-wizard-dash'

const { Panel } = Collapse

const NewDashboard = (props) => {
  const [todayClassList, setTodayClassList] = useState([])
  const [todayClassListMeta, setTodayClassListMeta] = useState({})
  const [todayClassListLoading, setTodayClassListLoading] = useState(false)
  const [, setTodaySessionList] = useState([])
  const [, setTodaySessionListMeta] = useState({})
  const [combinedList, setCombinedList] = useState([])

  const [providerIds, setProviderIds] = useState([])
  const [, setServicesListCount] = useState([])
  const [servicesBaseClassList, setServicesBaseClassList] = useState([])
  const [servicesClassListLoading, setServicesClassListLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 9
  const columns = [
    {
      title: props.t('tutDashboard'),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '250px',
    },
    {
      title: props.t('tutDashboard2'),
      dataIndex: 'classname',
      key: 'classname',
      align: 'left',
    },
    {
      title: props.t('Action'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
    },
  ]
  // Initial Call
  useEffect(() => {
    if (
      _.get(props, 'tutorBaseProfile') &&
      _.get(props, 'tutorBaseProfile.details')
    ) {
      if (
        !_.get(props, 'tutorBaseProfile.details.email') ||
        (!_.get(props, 'tutorBaseProfile.details.firstname') &&
          !_.get(props, 'tutorBaseProfile.details.businessname'))
      ) {
        history.push(routeName.TutorBaseProfileStep1)
        notification.warning({
          message: 'Warning',
          description: 'Please update your profile.',
        })
      }
    }
    getTutorList()
    getCampslistsCount()
    getCompslistsCount()
  }, [])

  // Tutor List
  const getTutorList = () => {
    if (_.get(props, 'tutorBaseProfile.details.id')) {
      const requestParams = {}
      requestParams.limit = 1000
      requestParams.works_for = _.get(props, 'tutorBaseProfile.details.id')
      requestParams.sort = 'created_at.desc'
      requestParams.expand = 'classes'
      setTodayClassListLoading(true)
      const campSort = {
        sort: 'created_at.desc',
        limit: 100,
      }
      props.getCampsRequests(campSort)
      props.getCampsCount()
      props.tutorList({
        params: requestParams,
        callback: (res, data) => {
          if (res) {
            if (_.size(_.get(data, 'items', [])) > 0) {
              const provider_ids = _.toString(
                _.uniq([
                  ..._.map(_.get(data, 'items', []), 'id'),
                  _.get(props, 'tutorBaseProfile.details.id'),
                ])
              )

              setProviderIds(provider_ids)
              onGetTodayClassList(provider_ids, {})
              onGetServicesCount(provider_ids, {})
            } else {
              const provider_id = _.get(props, 'tutorBaseProfile.details.id')

              setProviderIds(provider_id)
              onGetTodayClassList(provider_id, {})
              onGetServicesCount(provider_id, {})
            }
          }
        },
      })
    }
  }

  // Get Camps List
  const getCampslistsCount = () => {
    const requestParams = {}
    requestParams.provider = _.get(props, 'tutorBaseProfile.details.id')
    requestParams.limit = 1000
    requestParams.sort = 'created_at.desc'
    requestParams.expand = 'provider'
    props.getCampsList(requestParams)
  }

  // Get Camps List
  const getCompslistsCount = () => {
    const requestParams = {}
    requestParams.provider = _.get(props, 'tutorBaseProfile.details.id')
    requestParams.limit = 1000
    requestParams.sort = 'created_at.desc'
    requestParams.expand = 'provider'
    props.getCompsList(requestParams)
  }

  // Service Count
  const onGetServicesCount = (provider_ids, params) => {
    try {
      setServicesClassListLoading(true)
      const sample_data = []

      props.servicesCount({
        params: { provider_ids, limit: 1000 },
        callback: (res, datas) => {
          setServicesClassListLoading(false)
          if (_.size(_.get(datas, 'items', [])) > 0) {
            const service_count = _.filter(
              _.get(datas, 'items', []),
              (o) => o.classes_count !== 0
            )

            setServicesListCount(service_count)
            service_count.forEach((value, index) => {
              props.getClassesList({
                params: {
                  provider_ids,
                  expand: 'provider,batches',
                  limit: 10,
                  services: _.get(value, '_id'),
                  sort: 'created_at.desc',
                },
                callback: async (res, data) => {
                  if (res === true && _.size(_.get(data, 'items', [])) >= 0) {
                    sample_data.push({
                      classes_count: _.get(value, 'classes_count'),
                      name: _.startCase(_.get(value, 'name')),
                      table_data: _.get(data, 'items'),
                      table_meta: _.get(data, 'meta', {}),
                      id: _.get(value, '_id'),
                    })
                    setServicesBaseClassList((previous) =>
                      _.unionBy(
                        [
                          ...previous,
                          {
                            classes_count: _.get(value, 'classes_count'),
                            name: _.startCase(_.get(value, 'name')),
                            table_data: _.get(data, 'items'),
                            table_meta: _.get(data, 'meta', {}),
                            id: _.get(value, '_id'),
                          },
                        ],
                        'id'
                      )
                    )
                  }
                },
              })
            })
            setTimeout(() => {
              setServicesClassListLoading(false)
            }, 400)
          }
        },
      })
    } catch (error) {
      setServicesClassListLoading(false)
      console.log(
        'error at business-dashboard.js in  onGetServicesCount function',
        error
      )
    }
  }

  // Today class list
  const onGetTodayClassList = (provider_ids, params) => {
    setTodayClassListLoading(true)
    props.getclasseslistSessions({
      provider: _.get(props, 'tutorBaseProfile.details.id'),
      active: 1,
      type: 'classes',
      expand: 'provider',
      ...params,
    })
    props.onGetsessionList({
      provider: _.get(props, 'tutorBaseProfile.details.id'),
      active: 1,
      type: 'camps',
      expand: 'provider',
      ...params,
    })
  }

  // Set todays class
  useEffect(() => {
    if (
      _.size(_.get(props, 'sessionListClass.items')) > 0 ||
      _.size(_.get(props, 'sessionList.items')) > 0
    ) {
      // Filter today's classes
      const filteredClassesWithToday = _.get(
        props,
        'sessionListClass.items',
        []
      ).filter((session) =>
        session.session_dates.some((date) =>
          dayjs(date).isSame(dayjs(), 'date')
        )
      )

      // Filter today's sessions
      const filteredSessionsWithToday = _.get(
        props,
        'sessionList.items',
        []
      ).filter((session) =>
        session.session_dates.some((date) =>
          dayjs(date).isSame(dayjs(), 'date')
        )
      )
      if (
        _.isArray(filteredClassesWithToday) &&
        _.isArray(filteredSessionsWithToday)
      ) {
        // Set the filtered today's class list
        setTodayClassList(filteredClassesWithToday)
        setTodayClassListMeta({ total_count: filteredClassesWithToday.length })

        // Set the filtered today's session list
        setTodaySessionList(filteredSessionsWithToday)
        setTodaySessionListMeta({
          total_count: filteredSessionsWithToday.length,
        })

        // Combine both lists
        const combinedList = [
          ...filteredClassesWithToday,
          ...filteredSessionsWithToday,
        ]
        setCombinedList(combinedList)
      }
    }

    setTodayClassListLoading(false)
  }, [_.get(props, 'sessionListClass'), _.get(props, 'sessionList')])

  // Common Tutor name function
  const tutorName = (details) => {
    if (_.get(details, 'id')) {
      return _.get(details, 'type') === 'Individual'
        ? `${_.startCase(_.get(details, 'firstname'))} ${_.get(details, 'lastname', '') !== ''
          ? `${_.startCase(_.get(details, 'lastname', ''))}`
          : ''
        }`
        : `${_.startCase(_.get(details, 'businessname'))}`
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const viewAttendance = (session) => {
    let attRoute = ''
    if (session.type === 'camps') {
      attRoute = routeName.TutorSessionAttendance
    } else if (session.type === 'classes') {
      attRoute = routeName.TutorAttendance
    }

    if (attRoute) {
      history.push(
        `${attRoute.split(':')[0]}?classId=${session.id}&type=${session.type
        }&provId=${_.get(session, 'provider.id')}`
      )
    }
  }

  // Render today class list Tags
  const renderTodayClass = () => {
    // Calculate the start and end index of the items to display on the current page
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage

    // Slice the todayClassList based on the calculated indices
    const displayedItems = combinedList.slice(startIndex, endIndex)
    if (_.size(combinedList) > 0) {
      return _.map(displayedItems, (value, index) => {
        const session_name = _.get(value, 'session_name')
        const photoProvider = _.get(value, 'provider.photo')
        const timings = _.get(value, 'time_slot', {})

        const routingUrl =
          _.get(value, 'type') === 'classes'
            ? routeName.SessionViewClass
            : _.get(value, 'type') === 'camps'
              ? routeName.SessionView
              : _.get(value, 'type') === 'competitions'
                ? routeName.SessionViewCompetition
                : routeName.SessionViewEvent

        const viewSession = `${routingUrl}/${encodeURIComponent(session_name)}/?gid=${_.get(value, 'group_id')}&sid=${_.get(
          value,
          'id'
        )}`

        // const viewSession = `${routingUrl}/${formatUrlPart(session_name)}/?gid=${_.get(value, 'group_id')}&sid=${_.get(
        //   value,
        //   'id'
        // )}`

        const currentDay = new Date().toLocaleDateString('en-US', {
          weekday: 'long',
        })
        const currentTimeSlot = timings?.[currentDay]

        if (currentTimeSlot) {
          return (
            <div
              key={index}
              className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4 mb-4"
            >
              <div className="nurtem-dashboard-card">
                <div className="row">
                  <div className="col-12">
                    <Link to={viewSession}>
                      <div className="nurtem-dashboard-card-header mb-3 text-truncate">
                        <div className="nurtem-dashboard-card-class-detail">
                          <div>
                            <div
                              className="dashboard-class-name"
                              style={{ textTransform: 'capitalize' }}
                            >
                              {session_name}
                            </div>
                            <div className="dashboard-profile-name mt-1">
                              <img
                                src={photoProvider || DEFAULTUSER}
                                className="dashboard-profile-image mr-1"
                                alt="img"
                              />
                              {tutorName(_.get(value, 'provider', {}))}{' '}
                              <span className="class-badge">
                                {LabelConversion(
                                  _.get(value, 'teaching_mode'),
                                  'teaching-mode'
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nurtem-dashboard-class-overview  mb-2 mt-3">
                        <div className="nurtem-dashboard-class-width text-truncate">
                          <AiOutlineTeam className="todays-schedule-icon" />{' '}
                          {SessionsLeft(_.get(value, 'session_dates'))}{' '}
                          {' - Days left'}
                        </div>
                        <div className="nurtem-dashboard-class-width ">
                          <AiOutlineCalendar className="todays-schedule-icon" />{' '}
                          {LabelConversion(_.get(value, 'variant'), 'variant')}
                        </div>
                      </div>
                      <div
                        className="dashboard-profile-name mt-1 mb-3 mt-2"
                      // style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <AiOutlineClockCircle className="todays-schedule-icon" />{' '}
                        <div>
                          {currentTimeSlot ? (
                            <div className="dashboard-profile-name">
                              {currentDay} -{' '}
                              {timeDateConverterSession(
                                currentTimeSlot.start_time,
                                'time',
                                'hh:mm A'
                              )}{' '}
                              to{' '}
                              {timeDateConverterSession(
                                currentTimeSlot.end_time,
                                'time',
                                'hh:mm A'
                              )}
                            </div>
                          ) : (
                            <span>No time slot available for today.</span>
                          )}
                        </div>
                      </div>
                    </Link>
                    <div className="BtnMrg">
                      <Button
                        onClick={() => viewAttendance(value)}
                        // className="BtnMark"
                        type="primary"
                      >
                        {props.t('tutDashboard3')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
      })
    }
  }

  let studentclassreq = _.get(props, 'tutorClassRequests.items.length')
  let studentcampreq = _.get(props, 'tutorCampsRequests.items.length')
  studentclassreq = studentclassreq || 0
  studentcampreq = studentcampreq || 0
  const totalStudentCnt = studentclassreq + studentcampreq

  const renderNoTodayClass = () => (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="row" style={{ justifyContent: 'center' }}>
        <div className="smallCard">
          <Link to={routeName.tutorClasses}>
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div className="icon icon-shape">
                      {/* <GiTeacher className='icons-style' /> */}
                      <img
                        src={classImage}
                        style={{ width: 100, height: 80 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col-12"
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>
                      {props.t('tutorSidebarMyClasses')}
                    </div>
                    {/* <span className="h5 font-weight-bold mb-0">
                        {_.get(
                          props,
                          "tutorBaseProfile.details.classes.length"
                        ) !== 0
                          ? _.get(
                            props,
                            "tutorBaseProfile.details.classes.length"
                          )
                          : 0}
                      </span> */}
                  </div>
                </Row>
              </div>
            </Card>
          </Link>
        </div>
        <div className="smallCard">
          <Link to={routeName.tutorCamps}>
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div className="icon icon-shape">
                      {/* <GiCampingTent className='icons-style' /> */}
                      <img
                        src={campImage}
                        style={{ width: 100, height: 80 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col"
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>
                      {props.t('tutorSidebarMyCamps')}
                    </div>
                    {/* <span className="h5 font-weight-bold mb-0">
                        {_.get(
                          props,
                          "providerCampsDetails.meta.total_count",
                          0
                        )}
                      </span> */}
                  </div>
                </Row>
                {/* <p className="mt-3 mb-0 text-sm">
                                    <span className="text-danger ">
                                        <Link to={routeName.tutorCampView} className="view-style radius-50 btn-animated">View Camps <i className="ntm-right-arrow"></i></Link>
                                    </span>{" "}
                                    <span className="text-nowrap">
                                        <Link to={`${routeName.TutorUpdateCamp}?type=add`}  > <button className="btn-style">Add <i className="ntm-plus"></i></button></Link>
                                    </span>
                                </p> */}
              </div>
            </Card>
          </Link>
        </div>
        <div className="smallCard">
          <Link to={routeName.competitions}>
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div
                      className="icon icon-shape"
                      style={{ paddingLeft: 10 }}
                    >
                      {/* <GiTargetPrize className='icons-style' /> */}
                      <img
                        src={competitionImage}
                        style={{ width: 100, height: 90 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col"
                    style={{
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>
                      {props.t('tutorSidebarMyCompetitions')}
                    </div>
                    {/* <span className="h5 font-weight-bold mb-0">
                        {_.get(props, "CompetitionsList.meta.total_count", 0)}
                      </span> */}
                  </div>
                </Row>
              </div>
            </Card>
          </Link>
        </div>

        <div className="smallCard">
          <Link to={routeName.events}>
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div className="icon icon-shape ">
                      <img
                        src={EventsImage}
                        style={{ width: 120, height: 56, marginTop: 19 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col"
                    style={{
                      padding: '10px 5px',
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>
                      {props.t('tutorSidebarMyEvents')}
                    </div>
                  </div>
                </Row>
              </div>
            </Card>
          </Link>
        </div>
        <div className="smallCard">
          <a href="https://test.nurtem.com/" target="_blank">
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div className="icon icon-shape " style={{ paddingTop: 5 }}>
                      <img
                        src={TestPrep}
                        style={{ width: 110, height: 80 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col"
                    style={{
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>TestPrep</div>
                    {/* <span className="h5 font-weight-bold mb-0">
                        {totalStudentCnt}
                      </span> */}
                  </div>
                </Row>
              </div>
            </Card>
          </a>
        </div>
        <div className="smallCard">
          <a href="https://course.nurtem.com/" target="_blank">
            <Card className="card-stats mb-4 ">
              <div>
                <Row
                  style={{ flexDirection: 'column', alignContent: 'center' }}
                >
                  <Col className="col-auto">
                    <div className="icon icon-shape " style={{ paddingTop: 5 }}>
                      <img
                        src={Course}
                        style={{ width: 100, height: 80 }}
                        alt="class"
                      />
                    </div>
                  </Col>
                  <div
                    className="col"
                    style={{
                      display: 'flex',
                      width: 'unset',
                      alignSelf: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <div style={css.heading2}>Course</div>
                    {/* <span className="h5 font-weight-bold mb-0">
                        {totalStudentCnt}
                      </span> */}
                  </div>
                </Row>
              </div>
            </Card>
          </a>
        </div>
      </div>
    </div>
  )

  // TableView
  const tableSource = (value, i) => {
    if (_.size(_.get(value, 'table_data', [])) > 0) {
      return _.map(_.get(value, 'table_data', []), (v, index) => ({
        key: _.get(v, 'id'),
        name: (
          <div
            className="tutor-profile-name mt-1"
            style={{ justifyContent: 'left' }}
          >
            {/* <img
              src={
                _.get(v, 'provider.photo')
                  ? _.get(v, 'provider.photo')
                  : DEFAULTUSER
              }
              className="tutor-profile-image mr-1"
              alt="img"
            />{' '} */}
            {tutorName(_.get(v, 'provider'))}
          </div>
        ),
        classname: (
          <div
            className="tutor-profile-name mt-1"
            style={{ justifyContent: 'left' }}
          >
            {/* <img
              src={_.get(v, 'photo') ? _.get(v, 'photo') : CLASSPNG}
              className="tutor-profile-image mr-1"
              alt="img"
            />{' '} */}
            {_.get(v, 'name').charAt(0).toUpperCase() +
              _.get(v, 'name').slice(1)}
          </div>
        ),
        action: (
          <div>
            <Collapse accordion>
              <Panel header={props.t('ViewBatches')} key="1">
                {renderNestedTable(_.get(v, 'batches', []))}
              </Panel>
            </Collapse>
          </div>
        ),
      }))
    }
    return []
  }

  const renderNestedTable = (batches) => {
    const nestedColumns = [
      {
        title: props.t('batName'),
        dataIndex: 'batchName',
        key: 'batchName',
      },
      {
        title: props.t('batType'),
        dataIndex: 'batchType',
        key: 'batchType',
      },
      {
        title: props.t('sDate'),
        dataIndex: 'startDate',
        key: 'startDate',
      },
      {
        title: props.t('eDate'),
        dataIndex: 'endDate',
        key: 'endDate',
      },
      {
        title: props.t('Action'),
        dataIndex: 'action',
        key: 'action',
        align: 'center',
      },
    ]

    const nestedData = batches.map((batch) => ({
      key: batch.id,
      batchName: batch.session_name,
      startDate: timeDateConverterSession(batch.start_date, 'date'),
      endDate: timeDateConverterSession(batch.end_date, 'date'),
      batchType: LabelConversion(
        _.get(batch, 'billing_type', 0),
        'billing-type'
      ),
      action: (
        <div>
          <Link
            to={`${routeName.SessionViewClass}/${_.get(
              batch,
              'session_name'
            ).replace(
              /[^a-zA-Z0-9]/g,
              '-'
            )}/?gid=${_.get(batch, 'group_id')}&sid=${_.get(batch, 'id')}`}
          >
            <Button type='primary'>{props.t('view')}</Button>
          </Link>
        </div>
      ),
      // Add more data properties as needed...
    }))

    return (
      <Table
        columns={nestedColumns}
        dataSource={nestedData}
        pagination={false}
      />
    )
  }

  // Render Tab header tags
  const renderServiceClassList = () => {
    // console.log("servicesBaseClassList", servicesBaseClassList);
    if (_.size(servicesBaseClassList) > 0) {
      const cl = _.orderBy(servicesBaseClassList, ['name'], ['asc'])

      return _.map(cl, (value, i) => {
        // let class_count = _.get(value, 'classes_count', 0) > 1 ? `${_.get(value, 'classes_count', 0)} Classes` : `${_.get(value, 'classes_count', 0)} Class`
        const j = i > 5 ? i % 5 : i
        const style_class = `tab-icon${j + 1}`
        return (
          <items
            tab={
              <div className="tab-list">
                <div className={`${style_class} mr-2`}>
                  {_.get(value, 'name')[0]}
                </div>
                <div>
                  <div className="class-name">{_.get(value, 'name')}</div>
                  {/* <div className="class-text">{class_count}</div> */}
                </div>
              </div>
            }
            key={i + 1}
          >
            <Table
              // scroll={{ y: 340 }}
              className="classCategoriesTable"
              dataSource={tableSource(value)}
              // expandable={{ expandedRowRender, defaultExpandedRowKeys: ['0'] }}
              columns={columns}
            />
          </items>
        )
      })
    }
  }

  // Render
  const render = () => (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="row dashboard-tutor">
        {renderNoTodayClass()}

        {
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
            <div className="row dashboardtoday">
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <div style={css.heading1}>
                  {"Student Requests"}
                </div>
                <TutorRequest />
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                <div style={css.heading1}>
                  {"Onboard Wizard"}
                </div>
                <OnboardingWizardDash />
              </div>
            </div>
          </div>
        }

        {_.size(combinedList) > 0 && (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
            <div className="row dashboardtoday">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                {todayClassListLoading === true ? (
                  <div style={css.heading1}>
                    {props.t('todSch')} {dayjs().format('dddd Do MMMM')}
                  </div>
                ) : (
                  _.size(combinedList) > 0 && (
                    <div style={css.heading1}>
                      {props.t('todSch')} {dayjs().format('dddd Do MMMM')}
                    </div>
                  )
                )}
                {todayClassListLoading === true ? (
                  <TodaysClassLoader cards={12} />
                ) : (
                  <div className="row">{renderTodayClass()}</div>
                )}
              </div>
              {/* Card */}
              <Pagination
                current={currentPage}
                total={combinedList.length}
                pageSize={itemsPerPage}
                onChange={handlePageChange}
              />
            </div>
          </div>
        )}

        {_.size(servicesBaseClassList) > 0 ? (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <div
                  className="nurtem-dashboard-card-title classCate m-0"
                  style={css.heading1}
                >
                  {props.t('tutDashboard5')}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 catclass">
                <div className="nurtem-dashboard-card p-0 pb-2">
                  {servicesClassListLoading === true ? (
                    <ClassCatLoader />
                  ) : (
                    <Tabs defaultActiveKey="1">{renderServiceClassList()}</Tabs>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  )
  // console.log('todaysclassesstatelist', _.get(props, 'sessionListClass'))
  return render()
}

const mapStateToProps = (state) =>
// console.log("tutorProfile>>", state.competitionsDetails);
({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  deleteClassInfo: state.classesDetails.deleteClassInfo,
  loading: state.classesDetails.loading,
  loggedInUserDetails: state.authentication,
  tutorsList: _.get(state, 'general.tutor.list'),
  tutorClasses: state.classesDetails.classesList,
  classesStudents: state.classesDetails.classesStudents,
  milestonInfo: state.classesDetails.milestonInfo,
  tutorListLoading: _.get(state, 'general.tutor.listLoadingInProgress'),
  createLoadingInProgress: _.get(
    state,
    'general.tutor.createLoadingInProgress'
  ),
  onClassesLoading: state.classesDetails.onSearchClassesLoading,
  ClassesStudentListLoading: state.classesDetails.ClassesStudentListLoading,
  classDetailsView: state.classesDetails.singleClassView,
  singleClassViewLoading: state.classesDetails.singleClassViewLoading,
  setClassPhotosloading: state.addClass.setClassPhotosloading,
  tutorCampsRequests: state.campsDetails.tutorCampsRequests,
  tutorClassRequests: state.classesDetails.tutorClassRequests,
  tutorCampsCount: state.campsDetails.campsCount,
  tutorTotalCampsCount: state.campsDetails.providerCampsDetails,
  sessionListClass: state.common.sessionListClass,
  sessionList: state.common.sessionList,
  providerCampsDetails: state.campsDetails.providerCampsDetails,
  CompetitionsList: state.competitionsDetails.providerCompetitions,
})
const mapDispatchToProps = (dispatch) => ({
  getClassesList: (requestParams) =>
    dispatch(generalAction.classes.list(requestParams)),
  getclasseslistSessions: (requestParams, callback) =>
    dispatch(commonAction.onGetsessionListClass(requestParams, callback)),
  onGetsessionList: (requestParams) =>
    dispatch(commonAction.onGetsessionList(requestParams)),
  tutorList: (data) => dispatch(generalAction.tutor.tutorList(data)),
  servicesCount: (requestParams) =>
    dispatch(generalAction.services.servicesCount(requestParams)),
  servicesList: (requestParams) =>
    dispatch(generalAction.services.list(requestParams)),
  getCampsRequests: (params) =>
    dispatch(actions.getCampsRequests({ ...params })),
  getCampsCount: () => dispatch(actions.getCampsCount()),
  getCampsList: (requestParams) =>
    dispatch(actions.getProviderCamps(requestParams)),
  getCompsList: (requestParams) =>
    dispatch(compActions.getProviderCompetitions(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NewDashboard))
