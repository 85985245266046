import React from 'react';
import './privacy-policy.css';

class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="privacy-policy-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 className="title">{this.props.t('privacyPolicy')}</h2>
            <p>
              {this.props.t('privacyPolicy1')}
            </p>
            <h4>{this.props.t('privacyPolicy2')}</h4>
            <p>
              {this.props.t('privacyPolicy3')}
            </p>
            <p>
              {this.props.t('privacyPolicy4')}
            </p>
            <p>
              {this.props.t('privacyPolicy5')}

            </p>
            <h4>
              {this.props.t('privacyPolicy6')}

            </h4>
            <p>
              {this.props.t('privacyPolicy7')}

            </p>
            <h4>
              {this.props.t('privacyPolicy8')}

            </h4>
            <p>
              {this.props.t('privacyPolicy9')}

            </p>
            <h4>
              {this.props.t('privacyPolicy10')}

            </h4>
            <p>
              {this.props.t('privacyPolicy11')}

            </p>
            <p>
              {this.props.t('privacyPolicy12')}

            </p>
            <h4>
              {this.props.t('privacyPolicy13')}

            </h4>
            <p>
              {this.props.t('privacyPolicy14')}

            </p>
            <h4>
              {this.props.t('privacyPolicy15')}
            </h4>
            <p>
              {this.props.t('privacyPolicy16')}
            </p>
            <h4>
              {this.props.t('privacyPolicy17')}
            </h4>
            <p>
              {this.props.t('privacyPolicy18')}
            </p>
            <p>
              {this.props.t('privacyPolicy19')}
            </p>
            <h4>
              {this.props.t('privacyPolicy20')}
            </h4>
            <p>
              {this.props.t('privacyPolicy21')}
            </p>
            <p>
              {this.props.t('privacyPolicy22')}
            </p>
            <p>
              {this.props.t('privacyPolicy23')}
            </p>
            <h4>
              {this.props.t('privacyPolicy24')}
            </h4>
            <p>
              {this.props.t('privacyPolicy25')}
            </p>
            <p>
              {this.props.t('privacyPolicy26')}
            </p>
            <h4>
              {this.props.t('privacyPolicy27')}
            </h4>
            <p>
              {this.props.t('privacyPolicy28')}
            </p>
            <h4>
              {this.props.t('privacyPolicy29')}
            </h4>
            <p>
              {this.props.t('privacyPolicy30')}
            </p>
            <h4>{this.props.t('privacyPolicy29')}</h4>

            <h4>
              {this.props.t('privacyPolicy31')}
            </h4>
            <p>
              {this.props.t('privacyPolicy32')}
            </p>
            <h4>
              {this.props.t('privacyPolicy33')}
            </h4>
            <p>
              {this.props.t('privacyPolicy34')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
