import dayjs from 'dayjs';
import _ from 'lodash';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const seatsAvailableCount = (no_of_max_students, additional_details) => {
  const no_of_students_booked = _.get(additional_details, 'no_of_students_booked', 0);
  const no_of_students_accepted = _.get(additional_details, 'no_of_students_accepted', 0);

  // Calculate total booked seats
  const totalBooked = _.add(no_of_students_booked, no_of_students_accepted);

  // Ensure the result is at least 0
  return Math.max(0, _.subtract(no_of_max_students, totalBooked));
};

const seatsLeft = (st, props) => {
  let total_seats_left = 0;
  let max_students = st.get('no_of_max_students').value();
  let total_seats_filled = _.get(props.classesStudents, 'meta.total_count', 0);

  if (props.type === 'camps') {
    total_seats_filled = _.get(props.campsStudents, 'meta.total_count', 0);
  } else if (props.type === 'competitions') {
    total_seats_filled = _.get(props.competitionStudents, 'meta.total_count', 0);

    // if (st.get("teaching_type").value() !== 'i') {
    max_students = st.get('max_no_of_participants').value();
    // }
  } else if (props.type === 'events') {
    total_seats_filled = _.get(props.competitionStudents, 'meta.total_count', 10);

    // if (st.get("teaching_type").value() !== 'i') {
    max_students = st.get('max_no_of_participants').value();
    // }
  }
  total_seats_left = max_students - total_seats_filled;
  return total_seats_left;
};

const requestParamsMore = (val) => {
  const propertiesToExtract = [
    'location',
    'ageGroup',
    'variant',
    'preferredDays',
    'preferredDate',
    'preferredTime',
    'preferredPriceRange',
    'typeOfClass',
    'milesFromHome',
    'teachingMod',
  ];

  // Result object
  const obj = {};

  const sort = _.get(val, 'commonSort', false);
  if (sort) {
    obj.sort = sort;
  }

  // Loop through property names and set values if they exist
  propertiesToExtract.forEach((propertyName) => {
    const value = _.get(val, propertyName, false);
    if (value) {
      obj[propertyName] = value;
    }
  });

  return obj;
};

const reqFromHeader = (val) => {
  const obj = {
    page: 1,
    rand: Math.random(),
  };

  const sort = _.get(val, 'commonSort', false);

  if (sort) {
    obj.sort = sort;
  }

  return obj;
};

const getReqParamsForSearchFilter = (headerSearch, reqparm, reqparmMore) => {
  let reqParams = {};

  if (headerSearch) {
    reqParams = {
      ...reqparm,
    };
  } else {
    reqParams = {
      ...reqparm,
      ...reqparmMore,
    };
  }
  return reqParams;
};

const pad = (str, max) => {
  str = str.toString();
  return str.length < max ? pad(`0${str}`, max) : str;
};

const createTimeSlots = (fromTime, toTime) => {
  const startTime = dayjs(fromTime, 'hh:mm A');
  const endTime = dayjs(toTime, 'hh:mm A');
  if (endTime.isBefore(startTime)) {
    endTime.add(1, 'day');
  }
  const arr = [];
  while (startTime <= endTime) {
    arr.push(new dayjs(startTime).format('hh:mm A'));
    startTime.add(30, 'minutes');
  }
  return arr;
};

const capitalizeWord = (value) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

const convertHours = (mins) => {
  const hour = Math.floor(mins / 60);
  mins %= 60;
  return `${pad(hour, 2)}:${pad(mins, 2)}`;
};

const getDaysBetweenDates = (start, end, dayName, startTime, endTime, type) => {
  let result = [];
  const days = {
    sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6,
  };
  const week = dayName;
  week.forEach((value, index) => {
    const day = days[value.toLowerCase().substr(0, 3)];
    // Copy start date
    const current = new Date(start);
    const end1 = new Date(end);
    // Shift to next of required days
    current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
    // While less than end date, add dates to result array
    while (current <= end1) {
      const dateFromat = dayjs(current).format('MM/DD/YYYY');
      const endFormat = dayjs(end1).format('MM/DD/YYYY');
      const startDate = dayjs(
        `${dateFromat} ${startTime}`,
        'MM/DD/YYYY HH:mm A',
      ).format();
      const endDate = dayjs(
        `${endFormat} ${endTime}`,
        'MM/DD/YYYY HH:mm A',
      ).format();

      result.push({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });

      current.setDate(current.getDate() + 7);
    }
  });

  if (type === 2) {
    const res = result.filter((v, i) =>
      // check the index is odd
      i % 2 === 0);
    result = res;
  }

  if ([3, 4, 5, 6].indexOf(type) > -1) {
    if (type === 3) {
      const monthOnly = _.map(result, (value, i) => dayjs(_.get(value, 'startDate')).format('MM'));
      const data_final = [];
      _.map(_.uniq(monthOnly), (value, i) => {
        const data_final1 = [];

        _.map(result, (v, i1) => {
          if (dayjs(_.get(v, 'startDate')).format('MM') === value) {
            data_final1.push(i1 + 1);
          }
        });
        const final_size = _.size(data_final1) > 1 ? 1 : _.size(data_final1);
        data_final.push(final_size);
      });

      result = _.times(_.size(data_final), String);
    } else if (type === 4) {
      const monthOnly = _.map(result, (value, i) => dayjs(_.get(value, 'startDate')).format('MM'));
      const data_final = [];
      _.map(_.uniq(monthOnly), (value, i) => {
        const data_final1 = [];

        _.map(result, (v, i1) => {
          if (dayjs(_.get(v, 'startDate')).format('MM') === value) {
            data_final1.push(i1 + 1);
          }
        });
        const final_size = _.size(data_final1) > 2 ? 2 : _.size(data_final1);
        data_final.push(final_size);
      });

      result = _.times(_.sum(data_final), String);
    } else if (type === 5) {
      const monthOnly = _.map(result, (value, i) => dayjs(_.get(value, 'startDate')).format('MM'));
      const data_final = [];
      _.map(_.uniq(monthOnly), (value, i) => {
        const data_final1 = [];

        _.map(result, (v, i1) => {
          if (dayjs(_.get(v, 'startDate')).format('MM') === value) {
            data_final1.push(i1 + 1);
          }
        });
        const final_size = _.size(data_final1) > 3 ? 3 : _.size(data_final1);
        data_final.push(final_size);
      });

      result = _.times(_.sum(data_final), String);
    } else if (type === 6) {
      const monthOnly = _.map(result, (value, i) => dayjs(_.get(value, 'startDate')).format('MM'));
      const data_final = [];
      _.map(_.uniq(monthOnly), (value, i) => {
        const data_final1 = [];

        _.map(result, (v, i1) => {
          if (dayjs(_.get(v, 'startDate')).format('MM') === value) {
            data_final1.push(i1 + 1);
          }
        });
        const final_size = _.size(data_final1) > 4 ? 4 : _.size(data_final1);
        data_final.push(final_size);
      });

      result = _.times(_.sum(data_final), String);
    }
  }
  return result;
};

const getDatesbetweenclass = (startDate, endDate, weekDayNames) => {
  try {
    const dates = [];

    const currDate = dayjs(startDate).startOf('day').subtract(1, 'days');
    const lastDate = dayjs(endDate).startOf('day').add(1, 'days');

    while (currDate.add(1, 'days').diff(lastDate) < 0) {
      const currentWeekDayName = currDate.clone().format('dddd').toLowerCase();

      if (Array.isArray(weekDayNames) && weekDayNames.map((weekDayName) => weekDayName.toLowerCase()).includes(currentWeekDayName)) {
        dates.push(currDate.clone().toDate());
      }
    }

    return dates;
  } catch (error) {
    console.log('error at common.js in getDatesbetweenclass');
    return [];
  }
};
const getDatesbetweenclassforalter = (startDate, endDate, weekDayNames, startTime, type) => {
  const dates = [];
  const currDate = dayjs(startDate).startOf('day').subtract(1, 'days');
  const lastDate = dayjs(endDate).startOf('day').add(1, 'days');
  const betweenMonths = [];

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    const currentWeekDayName = currDate.clone().format('dddd').toLowerCase();

    if (Array.isArray(weekDayNames) && weekDayNames.map((weekDayName) => weekDayName.toLowerCase()).includes(currentWeekDayName)) {
      dates.push(currDate.clone().toDate());
    }
  }
  if (type === 1 || type === 2) {
    return dates;
  }
  /* calculating for 1,2,3 & 4 times a month */
  const s = dates;
  const f = s[0];
  const l = s[s.length - 1];
  if (f < l) {
    const date = dayjs(f).startOf('month');
    while (date < dayjs(l).endOf('month')) {
      betweenMonths.push(date.format('YYYY-MM'));
      date.add(1, 'month');
    }
  }
  const datesLength = dates.length;
  let monthcalc;
  if (type === 6) {
    monthcalc = betweenMonths.length * 4;
  } else if (type === 5) {
    monthcalc = betweenMonths.length * 3;
  } else if (type === 4) {
    monthcalc = betweenMonths.length * 2;
  } else if (type === 3) {
    monthcalc = betweenMonths.length * 1;
  }
  if (datesLength > monthcalc) {
    return monthcalc;
  }
  return datesLength;
};

const getTotalSessionCount = (classDetailsView, method2 = false) => {
  if (classDetailsView.occurrence_validity) {
    const start_date = dayjs(classDetailsView.occurrence_start);
    const end_date = dayjs(classDetailsView.occurrence_validity);
    // classDetailsView.day_of_week = classDetailsView.day_of_week
    if (start_date <= end_date) {
      if (end_date.diff(start_date, 'days') > 0 && classDetailsView.occurrence_type) {
        if ([1, 2].indexOf(classDetailsView.occurrence_type) > -1) {
          const totalsessionCount = getDatesbetweenclass(
            start_date,
            classDetailsView.occurrence_validity,
            classDetailsView.day_of_week,
            classDetailsView.start_time,
            classDetailsView.occurrence_type,
          );
          if (classDetailsView.occurrence_type === 2) {
            return _.round(totalsessionCount.length / 2);
          }
          return Math.floor(totalsessionCount.length);
        }

        const totalsessionCount = getDatesbetweenclassforalter(
          start_date,
          classDetailsView.occurrence_validity,
          classDetailsView.day_of_week,
          classDetailsView.start_time,
          classDetailsView.occurrence_type,
        );

        if (totalsessionCount >= 0) {
          return (totalsessionCount);
          // if (method2) {
          //     /**
          //      * totalsessionCount calculation has 2 method used in different places
          //      * this need clarification
          //      * based on classDetailsView.occurrence_type > 3
          //      * below code added in containers\tutor\tutor-classes\tutor-my-class.js
          //      */

          //     if (classDetailsView.occurrence_type === 3) {
          //         return (totalsessionCount.length > 1 ? 1 : totalsessionCount.length) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 4) {
          //         return (totalsessionCount.length > 2 ? 2 : totalsessionCount.length) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 5) {
          //         return (totalsessionCount.length > 3 ? 3 : totalsessionCount.length) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 6) {
          //         return (totalsessionCount.length > 4 ? 4 : totalsessionCount.length) * classDetailsView.day_of_week.length
          //     }
          //     // return classDetailsView.day_of_week.length * (months + 1);
          // }
        }
      }
    }
  }
  return 0;
};
const getCurrentSessionCount = (classDetailsView, method2 = false) => {
  let cancelledDays;
  if (classDetailsView.cancelled_days !== null) {
    cancelledDays = classDetailsView.cancelled_days.length;
  } else {
    cancelledDays = 0;
  }
  if (classDetailsView.occurrence_validity) {
    const start_date = dayjs(classDetailsView.occurrence_start).isAfter(dayjs()) ? dayjs(classDetailsView.occurrence_start) : dayjs(new Date());
    const end_date = dayjs(classDetailsView.occurrence_validity);
    if ((dayjs(end_date)).isAfter(dayjs(start_date))) {
      if (end_date.diff(start_date, 'days') > 0 && classDetailsView.occurrence_type) {
        if ([1, 2].indexOf(classDetailsView.occurrence_type) > -1) {
          const totalsessionCount = getDatesbetweenclass(
            start_date,
            classDetailsView.occurrence_validity,
            classDetailsView.day_of_week,
          );
          if (classDetailsView.occurrence_type === 2) {
            return _.round((totalsessionCount.length / 2) - cancelledDays);
          }
          return Math.floor((totalsessionCount.length) - cancelledDays);
        }

        const totalsessionCount = getDatesbetweenclassforalter(
          start_date,
          classDetailsView.occurrence_validity,
          classDetailsView.day_of_week,
          classDetailsView.start_time,
          classDetailsView.occurrence_type,
        );
        if (totalsessionCount >= 0) {
          return (totalsessionCount - cancelledDays);
          // if (method2) {
          //     /**
          //      * totalsessionCount calculation has 2 method used in different places
          //      * this need clarification
          //      * based on classDetailsView.occurrence_type > 3
          //      * below code added in containers\tutor\tutor-classes\tutor-my-class.js
          //      */

          //     if (classDetailsView.occurrence_type === 3) {
          //         return (totalsessionCount.length > 1 ? 1 : (totalsessionCount.length - cancelledDays)) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 4) {
          //         return (totalsessionCount.length > 2 ? 2 : (totalsessionCount.length - cancelledDays)) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 5) {
          //         return (totalsessionCount.length > 3 ? 3 : (totalsessionCount.length - cancelledDays)) * classDetailsView.day_of_week.length
          //     } else if (classDetailsView.occurrence_type === 6) {
          //         return (totalsessionCount.length > 4 ? 4 : (totalsessionCount.length - cancelledDays)) * classDetailsView.day_of_week.length
          //     }
          //     // return classDetailsView.day_of_week.length * (months + 1);
          // }
        }
      }
    }
  }
  return 0;
};
const EXPLORE_URL = 'https://nurtem.com/classes-and-camps/';
const CONTACT_URL = 'https://nurtem.com/contact-nurtem/';

export {
  CONTACT_URL, EXPLORE_URL, seatsAvailableCount, seatsLeft, requestParamsMore, getReqParamsForSearchFilter, reqFromHeader, daysOfWeek, createTimeSlots, getDaysBetweenDates, getDatesbetweenclass, getDatesbetweenclassforalter, getTotalSessionCount, getCurrentSessionCount, convertHours, capitalizeWord,
};
