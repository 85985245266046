import React from 'react';
import { Input, Form, Button } from 'antd';
import { AiFillLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
// import "./change-password.css";
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../store/actions/authentication/authentication';
import axios from '../../../utils/axios';
import { withErrorHandler } from '../../../utils/handler';

class ChangePassword extends React.Component {
  state = {
    loginType: '',
  };

  async componentDidMount() {
    if (this.props.loginSignupTabValue) {
      await this.setState({
        loginType: this.props.loginSignupTabValue,
      });
    }
  }

  onFinish = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let errorMessage = '';
    if (confirmPassword.length === 0 || oldPassword.length === 0) {
      errorMessage = (this.props.t('settings1'));
    } else if (newPassword !== confirmPassword) {
      errorMessage = (this.props.t('settings2'));
    } else if (confirmPassword === oldPassword) {
      errorMessage = (this.props.t('settings3'));
    } else if (!regex.test(newPassword)) {
      errorMessage = this.props.t('settings4');
    } else if (!regex.test(confirmPassword)) {
      errorMessage = this.props.t('settings5');
    }

    if (errorMessage) {
      this.setState({ errorMessage });
      return;
    }
    this.onPassChange(this.props.tutorBaseProfile.details, oldPassword, confirmPassword);
  };

  onPassChange = (userInfo, old, pass) => {
    const request = {
      username: userInfo.email,
      id: userInfo.user,
      oldPassword: old,
      password: pass,
    };
    this.props.onPasswordChange(request);
    this.setState({ isLoading: true });
  };

  componentDidUpdate(preProps) {

  }

  render() {
    return (
      <div className="login-page-container student-login-open">
        <div className="row align-items-center justify-content-around">
          <div className="new-password settingChangePassword student-login-modal " style={{ boxShadow: 'none', padding: 0 }}>
            <div className="row student-login-modal-inner justify-content-around">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                <Form onFinish={(e) => this.onFinish(e, 'user')} className="login-form">
                  <Form.Item
                    label={<span style={{ fontWeight: 600 }}>{this.props.t('currPass')}</span>}
                    rules={[{
                      required: true, message: this.props.t('settings6'),
                    },
                    {
                      max: 100,
                      message: this.props.t('currPass'),
                    }]}
                    name="curr_pass"
                    className="full-width"
                  >
                    <Input.Password

                      className="ant-input-affix-wrapper-lg mt-2 mb-2"
                      prefix={<AiFillLock style={{ fontSize: 20 }} />}
                      type="password"
                      placeholder={this.props.t('currPass')}
                      iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                      onChange={(e) => this.setState({ oldPassword: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span style={{ fontWeight: 600 }}>{this.props.t('newPass')}</span>}
                    rules={[{
                      required: true, message: this.props.t('newPass1'),
                    },
                    {
                      max: 100,
                      message: this.props.t('newPass2'),
                    }]}
                    name="new_pass"
                    className="full-width"
                  >
                    <Input.Password
                      className="ant-input-affix-wrapper-lg mt-2 mb-2"
                      prefix={<AiFillLock style={{ fontSize: 20 }} />}
                      type="password"
                      placeholder={this.props.t('newPass')}
                      iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                      onChange={(e) => this.setState({ newPassword: e.target.value })}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<span style={{ fontWeight: 600 }}>{this.props.t('confirmPass')}</span>}
                    rules={[{
                      required: true, message: this.props.t('confirmPass1'),
                    },
                    {
                      max: 100,
                      message: this.props.t('confirmPass2'),
                    }]}
                    name="curr_new_pass"
                    className="full-width"
                  >
                    <Input.Password
                      className="ant-input-affix-wrapper-lg mt-2 mb-2"
                      prefix={<AiFillLock style={{ fontSize: 20 }} />}
                      type="password"
                      placeholder={this.props.t('confirmPass')}
                      iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                      onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                    />
                  </Form.Item>
                  {this.state.errorMessage && <div style={{ color: 'red' }}>{this.state.errorMessage}</div>}
                  <div className="full-width mb-3 mt-2">
                    <div className="row login-input">
                      <div className="col-12 col-sm-12 col-md-4 col-lg-6 col-xl-6">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn btn-sm btn-login"
                          loading={this.props.loading}
                          style={{ width: '115px !important' }}
                        >
                          {this.props.t('createPass')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  changePasswordResponse: state.authentication.changePasswordResponse,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
});

const mapDispatchToProps = (dispatch) => ({
  disableLoading: () => dispatch(actions.disableLoading()),
  onLogout: () => dispatch(actions.onLogout()),
  onPasswordChange: (data) => dispatch(actions.PasswordChange(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(withTranslation()(ChangePassword), axios));
