import React, { useEffect, useState } from 'react';
import {
  Modal, Form, Input, Button, Select,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { withTranslation } from 'react-i18next';
import * as msgAction from '../../../store/actions/public/message';
import * as commonAction from '../../../store/actions/tutor/common';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as action from '../../../store/actions/tutor/camps';

dayjs.extend(timezone);
const { TextArea } = Input;

const MessageCreateForm = (props) => {
  const [form] = Form.useForm();
  const [stuentListopt, setstuentListopt] = useState('');
  const [studentIDs, setstudentIDs] = useState([]);
  const [parentIDs, setparentIDs] = useState([]);
  const [campList, setCampList] = useState('');
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedCamp, setSelectedCamp] = useState(null);
  const [selectedType, setselectedType] = useState(true);

  const handleCancel = () => {
    form.resetFields(['user', 'camp_details', 'class_details']);
    props.showMessageForm(false);
    setSelectedCamp(null);
    setSelectedClass(null);
  };

  useEffect(() => {
    const requestParamsClass = {};
    requestParamsClass.limit = 1000;
    requestParamsClass.sort = 'created_at.desc';
    requestParamsClass.type = 'classes';
    requestParamsClass.provider = props.tutorBaseProfile.details.id;
    props.getclasseslistSessions(requestParamsClass);
    const requestParams = {};
    requestParams.limit = 1000;
    requestParams.sort = 'created_at.desc';
    requestParams.type = 'camps';
    requestParams.provider = props.tutorBaseProfile.details.id;
    props.onGetsessionList(requestParams);
  }, []);

  useEffect(() => {
    const students = _.get(props, 'students.items', []);
    setstuentListopt(null)
    const student_ids = [];
    const parent_ids = [];
    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = '';
        let name = '';
        let type;
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id');
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'dependent_user';
        } else {
          id = _.get(each, 'user.id');
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'user';
        }
        student_ids.push(id);
        parent_ids.push(_.get(each, 'user.id'));
        return <Select.Option value={JSON.stringify({ id, type })} key={`${type}_${id}`}>{name}</Select.Option>;
      });

      studentList.unshift(<Select.Option value={JSON.stringify({ id: '', type: 'allparents' })} key="allparents">All Parents</Select.Option>);
      studentList.unshift(<Select.Option value={JSON.stringify({ id: '', type: 'user' })} key="allstudents">Everyone</Select.Option>);
      setstuentListopt(studentList);
      setstudentIDs(_.uniq(student_ids));
      setparentIDs(_.uniq(parent_ids));
    }
  }, [_.get(props, 'students.items')]);

  useEffect(() => {
    const students = _.get(props, 'campsStudents.items', []);
    const student_ids = [];
    const parent_ids = [];
    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = '';
        let name = '';
        let type;
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id');
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'dependent_user';
        } else {
          id = _.get(each, 'user.id');
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'user';
        }
        student_ids.push(id);
        parent_ids.push(_.get(each, 'user.id'));
        return <Select.Option value={JSON.stringify({ id, type })} key={`${type}_${id}`}>{name}</Select.Option>;
      });

      studentList.unshift(<Select.Option value={JSON.stringify({ id: '', type: 'allparents' })} key="allparents">All Parents</Select.Option>);
      studentList.unshift(<Select.Option value={JSON.stringify({ id: '', type: 'user' })} key="allstudents">Everyone</Select.Option>);
      setstuentListopt(studentList);
      setstudentIDs(_.uniq(student_ids));
      setparentIDs(_.uniq(parent_ids));
    }
  }, [_.get(props, 'campsStudents.items')]);

  const onFinish = async (val) => {
    const { id, type } = JSON.parse(val.user);
    if (val.texts.trim() !== '') {
      let nameChat = '';
      if (_.isEmpty(id)) {
        if (selectedClass) {
          nameChat = selectedClass;
        } else if (selectedCamp) {
          nameChat = selectedCamp;
        }
      }
      const values = {
        provider: props.tutorBaseProfile.details.id,
        name: props.tutorBaseProfile.details.type === 'Business' ? props.tutorBaseProfile.details.businessname : props.tutorBaseProfile.details.firstname,
        user: id,
        receiver: _.isEmpty(id) ? (type === 'allparents' ? parentIDs : studentIDs) : [id],
        sender_type: 'tutor',
        text: val.texts.trim(),
        chat_name: nameChat,
      };
      props.onCreateMessages(values);
      form.resetFields(['user', 'camp_details', 'class_details', 'Students', 'texts']);
      props.showMessageForm(false);
      setSelectedCamp(null);
      setSelectedClass(null);
      setstuentListopt(null);
      setselectedType(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onclassSelect = (classId, option) => {
    setSelectedCamp(null);
    setstuentListopt(null);
    setSelectedClass(option.children);
    setselectedType(false);
    form.resetFields(['user', 'camp_details']);
    const params = {
      class_details: classId,
      expand: 'user,class_details,provider,dependent_user',
      s: Math.random(),
    };
    props.getstudentslist(params);
  };

  const oncampSelect = (classId, option) => {
    setSelectedClass(null);
    setselectedType(false);
    setstuentListopt(null);
    setSelectedCamp(option.children);
    form.resetFields(['user', 'class_details']);
    const notesParams = {
      camp_details: classId,
      provider: props.tutorBaseProfile.details.id,
      limit: 1000,
      status: 1,
      // page: page,
      sort: 'created_at.desc',
      expand: 'camp_details,user,provider,dependent_user',
    };
    props.getCampsStudents(notesParams);
  };

  useEffect(() => {
    setCampList(_.get(props, 'sessionList.items'));
  }, [_.get(props, 'sessionList.items')]);

  const render = () => {
    const clsList = _.get(props, 'sessionListClass.items');
    return (

      <Modal
        className="student-view-notes-modal"
        title="Send Message"
        maskClosable={false}
        centered
        closable={false}
        open={props.visible}
        onCancel={() => handleCancel()}
        footer={null}
      >
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="full-width"
        >
          <Form.Item
            label="Class"
            name="class_details"
            rules={[{ required: false, message: props.t('classValid') }]}
            className="nurtem-modal-container px-4"
          >
            <Select
              showSearch
              placeholder="Select / Search a class name"
              optionFilterProp="children"
              onSelect={onclassSelect}
            >
              {_.size(clsList) > 0 && _.map(clsList, (value, key) => <Select.Option value={_.get(value, 'id')} key={_.get(value, 'id')}>{_.get(value, 'session_name')}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            label="Camp"
            name="camp_details"
            rules={[{ required: false, message: props.t('campValid') }]}
            className="nurtem-modal-container px-4"
          >
            <Select
              showSearch
              placeholder="Select / Search a Camp's session name"
              optionFilterProp="children"
              onSelect={oncampSelect}
            >
              {_.size(campList) > 0 && _.map(campList, (value, key) => <Select.Option value={_.get(value, 'id')} key={_.get(value, 'id')}>{_.get(value, 'session_name')}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            label="Students"
            name="user"
            rules={[{ required: true, message: props.t('stuValid') }]}
            className="nurtem-modal-container px-4"
          >
            <Select
              showSearch
              placeholder="Select / Search a user name"
              optionFilterProp="children"
              disabled={selectedType}
            >
              {stuentListopt}
            </Select>
          </Form.Item>

          <Form.Item
            rules={[{
              required: true, message: 'Question is required.',
            }]}
            name="texts"
            className="nurtem-modal-container px-4"
          >
            <TextArea placeholder="Send Message" />
          </Form.Item>
          <Form.Item className="nurtem-modal-container px-4">
            <div className="full-width text-right">
              <Button className="btn-cancel mr-2" key="back" onClick={handleCancel}>{props.t('cancel')}</Button>
              <Button
                className="btn-submit"
                key="submit"
                htmlType="submit"
              >
                {props.t('submit')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  createMessage: state.message.createMessage,
  visible: state.common.show_element,
  students: state.classesDetails.classesStudents,
  campsStudents: state.campsDetails.campsStudents,
  sessionList: state.common.sessionList,
  sessionListClass: state.common.sessionListClass,
});

const mapDispatchToProps = (dispatch) => ({

  showMessageForm: (data) => dispatch(commonAction.show_element(data)),
  getclasseslistSessions: (requestParams) => dispatch(commonAction.onGetsessionListClass(requestParams)),
  onGetsessionList: (requestParams) => dispatch(commonAction.onGetsessionList(requestParams)),
  getCampsStudents: (requestParams) => dispatch(action.getCampsStudents(requestParams)),
  getstudentslist: (requestParams) => dispatch(classActions.getClassesStudents(requestParams)),
  onCreateMessages: (requestParams) => dispatch(msgAction.onCreateUserMessages(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MessageCreateForm));
