import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Pagination, Rate } from 'antd';
import _ from 'lodash';
import { CLASSPNG, DEFAULTUSER } from '../../../utils/config/assets'

const TutorCompetitions = ({ tutorDetails, pageSize, t, viewClassCamps, scrollToTop, type, title }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Access the data based on the type parameter
  const data = tutorDetails[type] || [];

  if (data.length === 0) {
    return null; // or some placeholder component
  }

  // Determine the items to display based on the current page and page size
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const itemsToDisplay = data.slice(startIndex, endIndex);

  return (
    <>
      <div className="tutor-public-profile-container about-title mt-2 mb-2">
        {t(title)}
      </div>
      <div className="more-classes" style={{ backgroundColor: '#fff' }}>
        <div className="row">
          {itemsToDisplay.map((value, key) => (
            <div
              key={`competition_list${key}`}
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-4"
            >
              <div className="nurtem-profile-class-card">
                <Link
                  onClick={scrollToTop}
                  to={viewClassCamps(tutorDetails, value, type)}
                >
                  <div className="class-details-card">
                    <div className="results-cards__image-container">
                      <img
                        src={value.photo ? value.photo : CLASSPNG}
                        className="profile-class-image mr-2"
                        alt="img"
                      />
                    </div>
                    <div className="nurtem-profile-class-card-header">
                      <div style={{ display: 'inline-block' }} className="nurtem-profile-class-card-class-detail">
                        <div className="profile-card-name-container" style={{ width: '100%', float: 'none' }}>
                          <div
                            style={{
                              width: '100%',
                              textAlign: 'left',
                              paddingLeft: 10,
                            }}
                            className="profile-class-name text-truncate"
                          >
                            {_.startCase(value.name)}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nurtem-profile-class-card-header">
                      <div style={{ display: 'inline-block' }} className="nurtem-profile-class-card-class-detail">
                        <div className="profile-card-name-container" style={{ width: '100%', float: 'none' }}>
                          <div
                            style={{
                              width: '100%',
                              textAlign: 'left',
                              paddingLeft: 10,
                              paddingTop: 5,
                              fontSize: 13,
                              textTransform: 'uppercase',
                              color: 'gray',
                            }}
                            className="profile-class-name text-truncate"
                          >
                            {_.startCase(_.get(value, 'services[0].name', ''))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="nurtem-profile-class-card-header mt-1">
                      <div className="camp-detail-card-menu-star pl-2 pb-2">
                        <div className="star-rating">
                          <Rate disabled value={_.parseInt(value.rating_count, 0)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))}
          <div className="full-width mb-2">
            {data.length > pageSize && (
              <Pagination
                style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                current={currentPage}
                pageSize={pageSize}
                onChange={(page) => setCurrentPage(page)}
                total={data.length}
                showSizeChanger={false}
                className="ant-table-pagination"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

// Define prop types
TutorCompetitions.propTypes = {
  tutorDetails: PropTypes.shape({
    competitions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        photo: PropTypes.string,
        rating_count: PropTypes.number.isRequired,
        services: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string
          })
        )
      })
    ),
    // Define other types if needed, for example: awards, workshops, etc.
  }).isRequired,
  pageSize: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired, // Assuming `t` is a translation function
  viewClassCamps: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired, // 'competitions', 'awards', etc.
};

export default TutorCompetitions;
