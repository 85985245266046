import React, { useEffect } from 'react'
import { Table } from 'antd'
import * as attendances from '../../../store/actions/tutor/attendances'
import * as userAction from '../../../store/actions/users'
import * as action from '../../../store/actions/tutor/camps'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import { connect } from 'react-redux'
import _ from 'lodash'
import queryString from 'query-string'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'

const UsersAttendance = (props) => {
  // console.log('propsnow==========', props)
  const { type } = props
  let sessionId = _.get(props, 'session_details', '')
  let selected_user = _.get(props, 'selected_user.id', '')
  const urlparams = queryString.parse(window.location.search)
  const classId = _.get(urlparams, 'classId', '')
  const batchType = _.get(urlparams, 'batchType', '')
  const userId = _.get(urlparams, 'userId', '')
  const dependent_user = _.get(urlparams, 'dependent_user', '')
  const username = _.get(urlparams, 'username')
  let UserName = username
    ? username
    : _.get(
        props,
        'filterDetails.user_details',
        _.get(props, 'userBaseProfile.details.firstname')
      )

  useEffect(() => {
    if (classId && batchType && dependent_user) {
      props.getAttendenceReport({
        session_details: classId,
        type: batchType,
        dependent_user: dependent_user,
      })
      props.onsessionDetail({
        id: classId,
        expand: 'services,provider',
        type: batchType,
      })
    } else if (classId && batchType) {
      props.getAttendenceReport({
        session_details: classId,
        type: batchType,
      })
      props.onsessionDetail({
        id: classId,
        expand: 'services,provider',
        type: batchType,
      })
    } else if (sessionId) {
      props.getAttendenceReport({
        session_details: sessionId,
        type: type,
      })
      props.onsessionDetail({
        id: sessionId,
        expand: 'services,provider',
        type: type,
      })
    }
  }, [sessionId, type])

  const AttendanceTable = ({ data, targetUser }) => {
    const userSessions = new Set()
    data.items.forEach((item) => {
      item.date_marked.forEach((dateMarkedItem) => {
        const matchingAttendance = dateMarkedItem.attendance.find(
          (attendanceInfo) =>
            (attendanceInfo.user === targetUser &&
              attendanceInfo.dependent_user === null) ||
            attendanceInfo.dependent_user === targetUser
        )
        if (matchingAttendance) {
          userSessions.add(
            dayjs(dateMarkedItem.marked_date).format('YYYY-MM-DD')
          )
        }
      })
    })

    const dataSource = data.items.map((item) => {
      const userAttendance = {}
      item.date_marked.forEach((dateMarkedItem) => {
        const markedDate = dayjs(dateMarkedItem.marked_date).format(
          'YYYY-MM-DD'
        )
        userAttendance[markedDate] = userSessions.has(markedDate) ? '✔' : ''
      })

      return {
        key: item.id,
        user: targetUser,
        UserName: UserName,
        ...userAttendance,
        totalSessions: userSessions.size,
      }
    })
    // console.log('propsfiltedere123=========', dataSource)

    const columns = [
      {
        title: props.t('userLabel'),
        dataIndex: 'UserName',
        key: 'user',
        fixed: 'left',
        width: 100,
      },
      ..._.get(props, 'sessionDetail.session_dates', []).map((date) => ({
        title: dayjs(date).format('MMM DD'),
        dataIndex: date,
        key: date,
        render: (value) => (value ? '✔' : ''),
      })),
      {
        title: props.t('totalSessionAttended'),
        dataIndex: 'totalSessions',
        key: 'totalSessions',
        fixed: 'right',
        width: 100,
      },
    ]

    return (
      <div className="tutor-attendance-page nurtem-table mt-2 mb-3">
        <div className="container-xl row">
          <div className="col-12">
            <div className="full-width ">
              <Table
                dataSource={dataSource}
                scroll={{ x: '100%' }}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  const attendanceData = _.get(props, 'attendancesreports', [])
  const targetUser = selected_user
    ? selected_user
    : userId
      ? userId
      : _.get(props, 'userBaseProfile.details.id')
  const SessionDetails = _.get(props, 'sessionDetail', {})
  const render = () => {
    if (attendanceData.items && Object.keys(SessionDetails).length !== 0) {
      return (
        <div className="tutor-attendance-page nurtem-table mt-2 mb-3">
          <div className="container-xl row">
            <div className="col-12">
              <div className="attendance-title mb-3">{props.t('att')}</div>
              {(sessionId || classId) && (
                <AttendanceTable
                  data={attendanceData}
                  targetUser={targetUser}
                />
              )}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="tutor-attendance-page nurtem-table mt-2 mb-3">
          <div className="container-xl row">
            <div className="col-12">
              <EmptyAnime AnimeType={'Empty'} loop={true} animeheight={150} />
            </div>
          </div>
        </div>
      )
    }
  }
  return render()
}

const mapStateToProps = (state) => {
  // console.log('stateattendnacehere=============', state.campsDetails.sessionDetail.data)
  return {
    attendancesreports: state.attendances.attendanceReport,
    userBaseProfile: state.userProfile.userBaseProfile,
    sessionDetail: state.campsDetails.sessionDetail.data,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAttendenceReport: (requestParams) =>
      dispatch(attendances.attendanceReport(requestParams)),
    onUserProfile: () => dispatch(userAction.userProfile()),
    onsessionDetail: (requestParams) =>
      dispatch(action.onGetsessionDetail(requestParams)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UsersAttendance))
