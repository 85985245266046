import React, { useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { connect } from 'react-redux';
import _ from 'lodash';
import './style.css';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import * as paymentReportTutor from '../../../store/actions/tutor/common';
import generalAction from '../../../store/general/action';
import { PaymentReportLoader } from '../../../components/loader/common/payment-report-loader';
import css from '../../../utils/CSSUtility';

const FiltersReport = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const urlparams = queryString.parse(window.location.search);
    const classId = _.get(urlparams, 'classId', '');
    const type = _.get(urlparams, 'type', '');
    const userId = _.get(urlparams, 'user', '');
    const userType = _.get(urlparams, 'usertype', '');
    const tutors = [
      _.get(props, 'businessTutorBaseProfile.details'),
      ..._.get(props, 'tutorslist.items', []),
    ];
    const check_provider_id = _.get(props, 'provider');

    const get_tutor = _.find(tutors, { id: check_provider_id });
    console.log('valuesfilter=====', props.filterDetails);
    console.log('valuesfilter123=====', props.tutorIdForFilter);
    if (get_tutor !== undefined) {
      const details = {
        provider: _.get(props, 'provider'),
        date: new Date(),
        ..._.get(props, 'filterDetails'),
        provider_type: 'works_for',
        sort: 'created_at.desc',
        provider_id: _.get(this, 'props.tutorBaseProfile.details.id'),
        month: true,
      };
      props.getPaymentReport(details);
    } else if (
      classId
      && type
      && Object.keys(_.get(props, 'filterDetails', {})).length === 0
    ) {
      const filterDetails = {};
      if (type === 'classes') {
        filterDetails.class_details = classId;
      } else if (type === 'camps') {
        filterDetails.camp_details = classId;
      } else if (type === 'competitions') {
        filterDetails.competition_details = classId;
      }
      if (userId && userType === 'user') {
        filterDetails.user = userId;
      } else if (userId && userType === 'dependent') {
        filterDetails.user = userId;
        filterDetails.dependent_user = userId;
      }
      filterDetails.provider = _.get(props, 'tutorBaseProfile.details.id');
      const details = {
        date: new Date(),
        ...filterDetails,
        sort: 'created_at.desc',
        month: false,
      };
      props.getPaymentReport(details);
    } else {
      const details = {
        date: new Date(),
        ..._.get(props, 'filterDetails'),
        sort: 'created_at.desc',
        month: true,
      };
      props.getPaymentReport(details);
    }
    if (_.get(props, 'businessTutorBaseProfile.details.id')) {
      const requestParams = {};
      requestParams.limit = 100;
      requestParams.works_for = _.get(
        props,
        'businessTutorBaseProfile.details.id',
      );
      requestParams.sort = 'created_at.desc';
      requestParams.expand = 'classes';
      props.gettutorlist({ params: requestParams });
    }
  }, [_.get(props, 'filterDetails')]);

  const pageChange = (page) => {
    setCurrentPage(page);
    const tutors = [
      _.get(props, 'businessTutorBaseProfile.details'),
      ..._.get(props, 'tutorslist.items', []),
    ];
    const check_provider_id = _.get(props, 'provider');
    const get_tutor = _.find(tutors, { id: check_provider_id });

    if (get_tutor !== undefined) {
      const details = {
        provider_type: 'works_for',
        date: new Date(),
        ..._.get(props, 'filterDetails', {}),
        page,
        limit: 10,
      };
      props.getPaymentReport(details);
    } else {
      const details = {
        ..._.get(props, 'filterDetails', {}),
        date: new Date(),
        page,
        limit: 10,
      };
      props.getPaymentReport(details);
    }
  };

  const column_data = [
    {
      title: props.t('paidDate'),
      dataIndex: 'paid_date',
      key: 'paid_date',
      width: '8%',
    },
    {
      title: props.t('type'),
      dataIndex: 'class_type_label',
      key: 'class_type_label',
      width: '7%',
    },
    {
      title: props.t('batchLabel'),
      dataIndex: 'className',
      key: 'className',
      width: '15%',
    },
    {
      title: props.t('tutorLabel'),
      dataIndex: 'providerName',
      key: 'providerName',
      width: '15%',
    },
    {
      title: props.t('userLabel'),
      dataIndex: 'user_name',
      key: 'user_name',
      width: '15%',
    },
    {
      title: props.t('depLabel'),
      dataIndex: 'dependent_user_name',
      width: '15%',
    },

    {
      title: props.t('payMethod'),
      dataIndex: 'payment_method',
      key: 'payment_method',
      width: '7%',
    },
    {
      title: props.t('discountCode'),
      dataIndex: 'discountCode',
    },
    {
      title: props.t('sessions'),
      dataIndex: 'number_session_paid',
      width: '8%',
    },
    {
      title: props.t('billingType'),
      dataIndex: 'billing_type_str',
      width: '8%',
    },
    {
      title: props.t('payStatus'),
      dataIndex: 'payment_status',
      key: 'payment_status',
      width: '8%',
      render: (text) => {
        // Capitalize the first letter of payment_status
        return text.charAt(0).toUpperCase() + text.slice(1);
      }
    },
     {
      title: props.t('amountPaid'),
      dataIndex: 'paid_amount',
      key: 'paid_amount',
      width: '8%',
    },
  ];

  const render = () => {
    const dataSource = _.get(props, 'paymentreports.details', []);
    const data = _.get(dataSource, 'data', []);
     // Filter the data to include only items where paid_amount is not equal to zero
    const filteredData = data.filter(item => item.paid_amount !== 0);

    return (
      <div className="tutor-attendance-page paymentData nurtem-table mt-2 mb-3 reportbox">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div style={css.heading2} >
              <span>
                {props.t('paymentsLabel')}
                <Tooltip placement="topRight" title={props.t('filterTool')}>
                  <AiOutlineInfoCircle
                    style={{
                      marginLeft: '5px',
                      fontSize: '20px',
                      color: 'red',
                    }}
                  />
                </Tooltip>
              </span>

              <div className="full-width">
                <div className="hidden-scroll-x" style={{ marginTop: 10 }}>
                  {_.get(props, 'paymentReportLoading') ? (
                    <>
                      <PaymentReportLoader />
                      <div
                        className="col-12"
                        style={{ display: 'flex', justifyContent: 'end' }}
                      >
                        <table className="table" style={{ width: '410px' }}>
                          <tbody>
                            <tr className="table-info">
                              <td>{props.t('totalAmount')}</td>
                              <td>{props.t('loading1')}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : _.size(data) > 0 ? (
                    <>
                      <Table
                        loading={_.get(props, 'paymentReportLoading', false)}
                        pagination={{
                          current: currentPage, // Pass the current page value
                          pageSize: 10,
                          onChange: pageChange,
                          total:
                            props.paymentreports
                            && props.paymentreports.meta
                            && parseInt(props.paymentreports.meta.total_count),
                          hideOnSinglePage: true,
                          showSizeChanger: false,
                        }}
                        columns={column_data}
                        dataSource={filteredData}
                      />
                      <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <table className="table" style={{ width: '410px' }}>
                          <tbody>
                            <tr className="table-info">
                              <td>{props.t('totalAmount')}</td>
                              <td className="float-right">
                                ${_.get(dataSource, [
                                  'moredata',
                                  'billAmount',
                                ]).toFixed(2)}
                              </td>
                            </tr>
                            <tr className="table-info">
                              <td>{props.t('totalAmountMonth')}</td>
                              <td className="float-right">
                                ${_.get(dataSource, [
                                  'moredata',
                                  'billAmountThisMonth',
                                ]).toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : (
                    props.t('noData')
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return render();
};

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  paymentreports: state.common.paymentReport,
  students: state.classesDetails.classesStudents,
  paymentReportLoading: state.common.loading,
  tutorslist: _.get(state, 'general.tutor.list'),
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentReport: (requestParams) => dispatch(paymentReportTutor.paymentReport(requestParams)),
  gettutorlist: (data) => dispatch(generalAction.tutor.tutorList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(FiltersReport));
