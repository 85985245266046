/**
 * The redux_cart_Items variable is only used in header/index 
 * to display the count for cart, wishlist, and waitlist.
 */
import { Menu, Space, Dropdown, Select, notification } from 'antd'
import {
  SearchInput,
  Button,
  Spinner,
  Tag,
  Badge,
} from '../../../components/antd-components/index.tsx'
import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { AiOutlineDown, AiOutlineClockCircle } from 'react-icons/ai'
import { PiHeartBold } from 'react-icons/pi'
import { campsAddToCart } from '../../public/view-class-details/add-to-cart-container/component-functionalities'

import * as tutorPublicActions from '../../../store/actions/public/tutor-public-profile/index'
import * as tutorClassActions from '../../../store/actions/tutor/Classes'
import * as userActions from '../../../store/actions/users'
import * as Accountactions from '../../../store/actions/authentication/authentication'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
import * as servicesActions from '../../../store/actions/public/Services'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import './header.css'
import { CONTACT_URL, EXPLORE_URL } from '../../../utils/common.js'
import * as searchActionType from '../../../store/actions/public/search/actionTypes'
import { cartItems } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import { DEFAULTUSER, LOGO } from '../../../utils/config/assets'
import Lang from './language'
import * as SecureLS from 'secure-ls'
import _ from 'lodash'
import axiostoServer from '../../../utils/axios'

// import Notification from './component/noti-bell';

const { Option } = Select

class PageHeader extends React.Component {
  _isMounted = false

  ls = new SecureLS()

  constructor(props) {
    super(props)
    this.tutorClassesCampsSearch = debounce(this.tutorClassesCampsSearch, 400)
    this.search_flag = false
    this.cart_items_updated_flag = false
  }

  state = {
    selectOutBox: true,
  }

  //in header we get cart item with out status filter so use for all cart/wishlist/wait list counts
  getCartItems = async (isLoggedIn, id) => {
    try {
      if (isLoggedIn) {
        const resp = await axiostoServer({
          method: 'get',
          url: '/users/get-cartitems',
          withCredentials: true,
          params: {
            id,
          },
          headers: { 'Content-Type': 'application/json' },
        })

        if (resp.status === 200) {
          return resp.data.details
        }
        notification.error({
          description: this.props.t('errGetCart'),
          message: this.props.t('error'),
        })
      }
    } catch (error) {
      notification.error({
        description: this.props.t('errGetCart'),
        message: this.props.t('error'),
      })
      console.error('Error while getting the cart!', error)
    }
  }

  tutorClassesCampsSearch = async (value) => {
    const requestParams = {}

    if (value) {
      requestParams.search = value
      requestParams.page = 1

      requestParams.provider =
        this.props.tutorBaseProfile && this.props.tutorBaseProfile.id
      await this.props.onSearchClassesList(requestParams)
      await this.props.onSearchcampsList(requestParams)
      await this.props.onSearchCompetitionsList(requestParams)
    }
  }

  onLogout = () => {
    this.props.onLogout()
    this.props.ontutorSearchBox(false)
  }

  async componentDidMount() {
    const isLoggedIn = _.get(
      this.props,
      'loggedInUserDetails.isLoggedIn',
      false
    )
    const loginType = _.get(this.props, 'loggedInUserDetails.login_type', '')
    const IsLoggedInUser = isLoggedIn && loginType === 'user'
    const isLoggedInProvider = isLoggedIn && loginType === 'provider'
    if (IsLoggedInUser) {
      this.props.userProfile()
    }
    if (!this._isMounted && !isLoggedInProvider) {
      setTimeout(async () => {
        let CartApi = {}
        if (IsLoggedInUser) {
          CartApi = await this.getCartItems(
            _.get(this, 'props.loggedInUserDetails.isLoggedIn', false),
            _.get(this.props, 'userBaseProfile.id', {})
          )
        } else {
          CartApi = await this.ls.get('cartItems')
        }
        this.props.setCartItemsRedux(CartApi)
        this._isMounted = true
      }, 1000)
    }
  }

  async componentDidUpdate(prevProps) {
    const ch = _.chain(this.props.loggedInUserDetails)
    const isLoggedIn = ch.get('isLoggedIn', false).value()
    const loginType = _.get(this.props, 'loggedInUserDetails.login_type', '')

    const isProvider = ch.get('login_type', '').eq('provider').value()
    const isUser = ch.get('login_type', '').eq('user').value()
    const userID = _.get(this, 'props.userBaseProfile.id', false)

    if (isLoggedIn && isUser && userID && !this.cart_items_updated_flag) {
      const CartSecureLs = await this.ls.get('cartItems')
      this.cart_items_updated_flag = true

      /**
       * if u find cartItems then store to db
       */
      if (userID && CartSecureLs && Object.keys(CartSecureLs).length > 0) {
        /**
         * update to cart item to db
         */

        const userObj = {
          isLoggedIn,
          userID,
        }

        /**
         * if (item.status === 0) {
         *       history.push("/mywishlist");
         *   } else if (item.status === 1) {
         *       history.push("/cart");
         *   } else if (item.status === 2) {
         *       history.push("/mywaitlist");
         *   }
         */
        const status = 1 // cartItems key used in ls so only cart data will be listed

        for (const key in CartSecureLs) {
          /**
           * 65c8f81a05d8cb4bd422aed4--64083b79bc970b199481666e
           * key contain '--' seperator used in key (sessionid--userProfileid)
           * !key.includes("--") means, it is only guest check out
           * refer : view-class-details\add-to-cart-container\component-functionalities.js
           */
          if (!key.includes('--')) {
            if (isLoggedIn) {
              const element = CartSecureLs[key]
              const selectedSession = _.get(element, 'session', {})
              const addon = _.get(element, 'addon', [])
              const priceData = _.get(element, 'price', {})
              const type = _.get(element, 'type', '')
              campsAddToCart(
                this.props.t,
                selectedSession,
                addon,
                priceData,
                userObj,
                status,
                type,
                this.props.setCartItemsRedux
              )
            }
          }
        }
      }

      this.ls.remove('cartItems')
    }

    /// ////////////////////////////////////////////////
    if (this.props.updateUserProfileInfo !== prevProps.updateUserProfileInfo) {
      this.props.userProfile()
    }

    if (isLoggedIn && isProvider) {
      if (
        prevProps.tutorClassesCount !== this.props.tutorClassesCount &&
        this.props.tutorClassesCount &&
        this.props.tutorClassesCount.items &&
        this.props.tutorClassesCount.items.length > 0
      ) {
        const tutorClassesCountItemsWithType =
          this.props.tutorClassesCount.items.map((item) => ({
            ...item,
            type: 'class',
          }))
        this.props.setTutorClassesCampsSearchData(
          tutorClassesCountItemsWithType
        )
      }

      if (
        prevProps.tutorCampsCount !== this.props.tutorCampsCount &&
        this.props.tutorCampsCount &&
        this.props.tutorCampsCount.items &&
        this.props.tutorCampsCount.items.length > 0
      ) {
        const tutorCampsCountItemsWithType =
          this.props.tutorCampsCount.items.map((item) => ({
            ...item,
            type: 'camp',
          }))
        this.props.setTutorClassesCampsSearchData([
          ...this.props.tutorClassesCampsSearchData,
          ...tutorCampsCountItemsWithType,
        ])
      }

      if (
        prevProps.tutorCompetitions !== this.props.tutorCompetitions &&
        this.props.tutorCompetitions &&
        this.props.tutorCompetitions.items &&
        this.props.tutorCompetitions.items.length > 0
      ) {
        const tutorCompetitionsItemsWithType =
          this.props.tutorCompetitions.items.map((item) => ({
            ...item,
            type: 'competition',
          }))
        this.props.setTutorClassesCampsSearchData([
          ...this.props.tutorClassesCampsSearchData,
          ...tutorCompetitionsItemsWithType,
        ])
      }
    }
  }

  redirect = (url) => {
    history.push(url)
  }

  ousidePart = (type, e) => {
    this.search_flag = true
    this.setState({
      selectOutBox: type === 'IN',
    })
  }

  refreshPage = (route) => {
    if (route === this.props.location.pathname) {
      window.location.reload()
    }
  }

  menuToggle = (event) => {
    document.body.classList.toggle('menu-open')
  }

  dashboardLink = (loggedIN, ltype) => {
    if (loggedIN) {
      return ltype === 'provider' ? (
        this.props.onTutorDashboardsiderbar === false && (
          <li className="nav-item">
            <Link className="nav-link" to={routeName.tutorDashboard}>
              {this.props.t('dashboard')}
            </Link>
          </li>
        )
      ) : (
        <li className="nav-item">
          <Link className="nav-link" to={routeName.userDashboard}>
            {this.props.t('dashboard')}
          </Link>
        </li>
      )
    }
  }

  linkToLogo = (loggedIN, ltype) =>
    loggedIN === true
      ? loggedIN === true && ltype === 'provider'
        ? this.props.tutorBaseProfile &&
          this.props.tutorBaseProfile.update_progress === 0
          ? routeName.TutorBaseProfileStep1
          : routeName.tutorDashboard
        : routeName.userDashboard
      : routeName.Landing

  triggerSearch = (value) => {
    if (value) {
      const path = {
        pathname: `/search/${value}`,
      }
      history.push(path)
    }
  }

  makeLink = (label, url) => (
    <Menu.Item className="logoutbox">
      <Link to={url}>{this.props.t(label)}</Link>
    </Menu.Item>
  )

  countByStatus(data, status) {
    if (!data) return 0;

    return Object.values(data).filter(item => item.status === status).length;
  }

  render() {
    console.log('this.props.redux_cart_Items>>>>', this.props.redux_cart_Items)

    const ch = _.chain(this.props.loggedInUserDetails)
    const isLoggedIn = ch.get('isLoggedIn', false).value()
    const isProvider = ch.get('login_type', '').eq('provider').value()

    const isUser = ch.get('login_type', '').eq('user').value()
    const loginType = ch.get('login_type', '').value()
    const CartSecureLs = this.ls.get('cartItems')

    const url = this.props.location.search
    const params = queryString.parse(url)
    const account_type = _.get(this, 'props.userBaseProfile.user.account_type')

    const menu = (
      <Menu>
        {account_type !== 'dependent' &&
          this.makeLink(
            'myProfile',
            isLoggedIn && isUser
              ? routeName.userProfile
              : routeName.tutorEditProfile
          )}

        {window.innerWidth <= 650 && (
          <>
            {' '}
            {this.makeLink('explore', EXPLORE_URL)}
            {this.makeLink('contact', CONTACT_URL)}
          </>
        )}
        <Menu.Item className="logoutbox">
          <Button onClick={() => this.onLogout()}>
            {this.props.t('logout')}
          </Button>
        </Menu.Item>
      </Menu>
    )
    const menulog = (
      <Menu>
        {this.makeLink('explore', EXPLORE_URL)}
        {this.makeLink('contact', CONTACT_URL)}

        <Menu.Item className="logoutbox">
          {isLoggedIn === false && (
            <Link
              to={
                params && (params.classId || params.campId)
                  ? params.campId
                    ? `${routeName.userSignUp}?campId=${params.campId}`
                    : `${routeName.userSignUp}?provider=${params.provider}&classId=${params.classId}`
                  : routeName.userSignUp
              }
            >
              {this.props.t('signup')}
            </Link>
          )}
        </Menu.Item>

        <Menu.Item className="logoutbox">
          {isLoggedIn === false && (
            <Link
              to={
                params && (params.classId || params.campId)
                  ? params.campId
                    ? `${routeName.signIn}?campId=${params.campId}`
                    : `${routeName.signIn}?provider=${params.provider}&classId=${params.classId}`
                  : routeName.signIn
              }
            >
              {this.props.t('login')}
            </Link>
          )}
        </Menu.Item>
      </Menu>
    )

    const { selectOutBox } = this.state
    function showmmenu() {
      const element = document.querySelector('.ant-layout-sider')
      element && element.classList.toggle('ant-layout-sider-collapsed')
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="row w-100">
          <div className="col-12  d-flex align-items-center navbarnew">
            {isLoggedIn && (
              <>
                <button
                  type="button"
                  className="ant-btn ant-btn ant-btn-default  ant-btn-icon-top "
                  onClick={showmmenu}
                >
                  <span
                    role="img"
                    aria-label="left"
                    tabIndex="-1"
                    className="anticon anticon-top"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
                      <rect x="2" y="2" width="5" height="0.5" />
                      <rect x="2" y="3.5" width="5" height="0.5" />
                      <rect x="2" y="5" width="5" height="0.5" />
                    </svg>
                  </span>
                </button>
                &nbsp;&nbsp;
              </>
            )}

            {window.innerWidth >= 767 && (
              <Link
                to={() => this.linkToLogo(isLoggedIn, loginType)}
                className={
                  this.props.sidebarLogo === true
                    ? 'nurtem-logo  float-left'
                    : 'nurtem-logo-common bg-transparent'
                }
              >
                <img className="logo-img" src={LOGO} alt="img" />
              </Link>
            )}
            {window.innerWidth <= 767 && !isLoggedIn && (
              <Link
                to={() => this.linkToLogo(isLoggedIn, loginType)}
                className={
                  this.props.sidebarLogo === true
                    ? 'nurtem-logo  float-left'
                    : 'nurtem-logo-common bg-transparent'
                }
              >
                <img className="logo-img" src={LOGO} alt="img" />
              </Link>
            )}
            <Dropdown overlay={isLoggedIn ? menu : menulog}>
              <button
                className="navbar-toggler bg-light"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                {isLoggedIn ? (
                  <span className="ntm-user" />
                ) : (
                  <span className="menu-icon">
                    <span />
                    <span />
                    <span />
                  </span>
                )}
              </button>
            </Dropdown>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav m-auto">
                <div className="header-search">
                  {(this.props.onTutorDashboardsiderbar ||
                    this.props.onTutorPublicsiderbar) &&
                    this.props.ontutorSearchBox &&
                    this.props.loggedInUserDetails &&
                    isLoggedIn &&
                    isProvider ? (
                    <Select
                      showSearch
                      defaultActiveFirstOption={false}
                      defaultOpen={false}
                      style={{ width: `${100}%` }}
                      className="global-search"
                      placeholder={this.props.t('searchLabel')}
                      allowClear
                      filterOption={false}
                      onSearch={this.tutorClassesCampsSearch}
                      open={selectOutBox}
                      onChange={() =>
                        setTimeout(() => {
                          this.search_flag = true
                          this.ousidePart('OUT', 'onChange')
                        }, 5)
                      }
                      onClick={(e) => this.ousidePart('IN', 'onFocus')}
                      autoClearSearchValue
                      notFoundContent={
                        this.props.onBusinessSearchClassesLoading ? (
                          <Spinner size="small" />
                        ) : null
                      }
                      autoComplete="off"
                      onBlur={(e) => this.ousidePart('OUT')}
                    >
                      {this.search_flag &&
                        this.props.tutorClassesCampsSearchData &&
                        this.props.tutorClassesCampsSearchData.length > 0 &&
                        _.uniqBy(
                          this.props.tutorClassesCampsSearchData,
                          'id'
                        ).map((d) => (
                          <Option className="header-search-option" key={d.id}>
                            <Link
                              className="search-for-tutor-class"
                              rel="noopener noreferrer"
                              to={`${routeName.tutorCampView}/?q=${d.name}`}
                            >
                              <span>{d.name}</span>
                              <Tag
                                className={
                                  d.type === 'camp'
                                    ? 'search-camp-tag'
                                    : d.type === 'class'
                                      ? 'search-class-tag'
                                      : d.type === 'competition'
                                        ? 'search-competition-tag'
                                        : ''
                                }
                              >
                                {d.type}
                              </Tag>
                            </Link>
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Space.Compact>
                      <SearchInput
                        className={isLoggedIn ? 'user2search' : 'userSearch'}
                        placeholder={this.props.t('searchLabel')}
                        onSearch={this.triggerSearch}
                      />
                    </Space.Compact>
                  )}
                </div>
              </ul>

              <ul style={{ marginRight: 10 }} className="navbar-nav">
                {this.dashboardLink(isLoggedIn, loginType)}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={EXPLORE_URL}
                  >
                    {this.props.t('explore')}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={CONTACT_URL}
                  >
                    {this.props.t('contact')}
                  </a>
                </li>
              </ul>

              <ul
                className="navbar-nav wishcart mr-4"
                style={{ alignItems: 'center' }}
              >
                {isLoggedIn && isUser && (
                  <>
                    <Badge
                      count={this.countByStatus(this.props.redux_cart_Items, 2)}
                      overflowCount={10}
                    >
                      <span
                        className="wishList ml-2"
                        onClick={() => this.redirect('/mywaitlist')}
                      >
                        <AiOutlineClockCircle className="mb-1" />
                      </span>
                    </Badge>

                    <Badge
                      count={this.countByStatus(this.props.redux_cart_Items, 0)}

                      overflowCount={10}
                    >
                      <span
                        className="wishList ml-2"
                        onClick={() => this.redirect('/mywishlist')}
                      >
                        <PiHeartBold className="mb-1" />
                      </span>
                    </Badge>
                  </>
                )}
                {!isProvider && (
                  <Badge
                    count={this.countByStatus(this.props.redux_cart_Items, 1)}
                    overflowCount={10}
                  >
                    <button
                      onClick={() => this.redirect('/mycart')}
                      type="button"
                      className="btn btn-sm btn-cart ml-2 "
                    >
                      <i className="ntm-shopping-cart" />
                    </button>
                  </Badge>
                )}
                {/* <Notification/> */}
                {window.innerWidth >= 767 &&
                  this.props.loggedInUserDetails &&
                  this.props.loggedInUserDetails.isLoggedIn === false && (
                    <Link
                      to={
                        params && (params.classId || params.campId)
                          ? params.campId
                            ? `${routeName.signIn}?campId=${params.campId}`
                            : `${routeName.signIn}?provider=${params.provider}&classId=${params.classId}`
                          : routeName.signIn
                      }
                      className="text-primary utils__link--underlined"
                      style={{ margin: '0 5px' }}
                    >
                      <button
                        onClick={() => this.refreshPage('/sign-in')}
                        type="button"
                        className={`btn btn-sm btn-login ml-2 ${_.get(this, 'props.location.pathname') === '/sign-in' ? 'btn-login-active' : ''}`}
                      >
                        {this.props.t('login')}
                      </button>
                    </Link>
                  )}
                {window.innerWidth >= 767 &&
                  this.props.loggedInUserDetails &&
                  this.props.loggedInUserDetails.isLoggedIn === false && (
                    <Link
                      to={
                        params && (params.classId || params.campId)
                          ? params.campId
                            ? `${routeName.userSignUp}?campId=${params.campId}`
                            : `${routeName.userSignUp}?provider=${params.provider}&classId=${params.classId}`
                          : routeName.userSignUp
                      }
                      className="text-primary utils__link--underlined"
                      style={{ margin: '0 5px' }}
                    >
                      {' '}
                      <button
                        type="button"
                        onClick={() => this.refreshPage('/sign-up')}
                        className={`btn btn-sm btn-signup ml-2 ${_.get(this, 'props.location.pathname') === '/sign-up' ? 'btn-signup-active' : ''}`}
                      >
                        {this.props.t('signup')}
                      </button>
                    </Link>
                  )}
              </ul>

              <Lang />
              {/* After Login */}
              {this.props.loggedInUserDetails &&
                this.props.loggedInUserDetails.isLoggedIn === true &&
                this.props.loggedInUserDetails.login_type === 'provider' && (
                  <ul className="navbar-nav">
                    <Dropdown overlay={menu}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <img
                          src={
                            this.props.tutorBaseProfile &&
                              this.props.tutorBaseProfile.update_progress &&
                              this.props.tutorBaseProfile.photo &&
                              this.props.tutorBaseProfile.photo !== ''
                              ? this.props.tutorBaseProfile.photo
                              : DEFAULTUSER
                          }
                          className="profile-image mr-2"
                          alt="img"
                        />

                        <span>
                          {_.get(this.props.tutorBaseProfile, 'type') ===
                            'Business'
                            ? _.get(
                              this.props.tutorBaseProfile,
                              'businessname',
                              ''
                            )
                            : `${_.get(this.props.tutorBaseProfile, 'firstname', '')} ${_.get(this.props.tutorBaseProfile, 'lastname', '')}`}
                        </span>

                        <AiOutlineDown />
                      </div>
                    </Dropdown>
                  </ul>
                )}
              {this.props.loggedInUserDetails &&
                this.props.loggedInUserDetails.isLoggedIn === true &&
                this.props.loggedInUserDetails.login_type === 'user' && (
                  <ul className="navbar-nav">
                    <Dropdown overlay={menu}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <img
                          src={
                            this.props.userBaseProfile &&
                              this.props.userBaseProfile.photo
                              ? this.props.userBaseProfile.photo
                              : DEFAULTUSER
                          }
                          className="profile-image mr-2"
                          alt="img"
                        />

                        <span>
                          {_.get(this.props.userBaseProfile, 'firstname', '')}
                        </span>
                        <span>&nbsp;</span>
                        <span>
                          {_.get(this.props.userBaseProfile, 'lastname', '')}
                        </span>
                        <span>&nbsp;</span>
                        <AiOutlineDown />
                      </div>
                    </Dropdown>
                  </ul>
                )}
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

const mapStateToProps = (state) => ({
  redux_cart_Items: state.search.cartItems,

  cartItemAdded: state.classes.addedCartItems,
  tutorClassesCampsSearchData: state.search.tutorClassesCampsSearchData,
  loggedInUserDetails: state.authentication,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile.details,
  userBaseProfile: state.userProfile.userBaseProfile.details,
  updateUserProfileInfo: state.userProfile.updateUserProfileInfo,
  sidebarLogo: state.services.sidebarLogo,
  ontutorSearchBox: state.services.ontutorSearchBox,
  tutorClassesCount: state.classesDetails.onSearchClassesList,
  onBusinessSearchClassesLoading:
    state.classesDetails.onBusinessSearchClassesLoading,
  tutorCampsCount: state.campsDetails.onSearchCampsList,
  tutorCompetitions: state.competitionsDetails.providerCompetitions,
  onTutorPublicsiderbar: state.services.onTutorPublicsiderbar,
  onTutorDashboardsiderbar: state.services.onTutorDashboardsiderbar,
})

const mapDispatchToProps = (dispatch) => ({
  setCartItemsRedux: (requestParams) =>
    dispatch(searchAction.common(cartItems, requestParams)),

  setTutorClassesCampsSearchData: (requestParams) =>
    dispatch(
      searchAction.common(
        searchActionType.tutorClassesCampsSearchData,
        requestParams
      )
    ),
  userProfile: () => dispatch(userActions.userProfile()),
  onLogout: () => dispatch(Accountactions.onLogout()),
  onOpenUserProfileModal: (e) => dispatch(userActions.openUserProfileModal(e)),
  openProfileModal: () => dispatch(userActions.openProfileModal()),
  onSearchcampsList: (data) =>
    dispatch(tutorPublicActions.getSearchCampsList(data)),
  onSearchCompetitionsList: (data) =>
    dispatch(competitionActions.getProviderCompetitions(data)),
  onSearchClassesList: (data) =>
    dispatch(tutorClassActions.getClassesList(data)),
  ontutorSearchBox: (requestParams) =>
    dispatch(servicesActions.ontutorSearchBox(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PageHeader))
