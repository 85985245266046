import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Space, Tooltip, message } from 'antd';
import queryString from 'query-string';
import { AiOutlineExclamationCircle, AiFillInfoCircle } from 'react-icons/ai';
import parentComponentFunctionalities from '../component-functionalities';
import history from '../../../../utils/history';
import * as routeName from '../../../../components/route-names';
import { IoCopyOutline } from "react-icons/io5";
import { CopyToClipboard } from '../../../../components/re-usable/copy-clipboard/index';

const config = {
  ..._.get(parentComponentFunctionalities, 'config', {}),
};

const success = () => {
  message.success({ content: "Code Copied", duration: 3 })
}
const table = {
  dataSource: [],
  columns: (assets) => {
    const disableAction = (record) => _.chain(assets).get('couponBlock.API.updateCouponDetails.id', null).isEqual(_.get(record, 'id')).value();
    const formInProgress = () => _.get(assets, 'couponBlock.API.formLoader', false);

    const t = _.get(assets, 't', '');

    return [
      {
        title: t('couponCode'),
        dataIndex: 'code',
        width: '20%',
        render: (code) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{code}</span>
            <CopyToClipboard text={code}>
              <button className="btn btn-copy" onClick={success}>
                <IoCopyOutline />
              </button>
            </CopyToClipboard>
          </div>
        ),
      },
      {
        title: t('couponType'),
        dataIndex: 'type',
        width: '30%',
        render: (type) => _.chain(config.type).find({ value: _.toString(type) }).get('name', '-').value(),
      },
      {
        title: t('value'),
        dataIndex: 'amount_type',
        width: '10%',
        render: (amount_type, record) => _.chain(config.amount_type).find({ value: _.toString(amount_type) }).wrap((amount_type) => _.get(amount_type, 'render')(_.get(record, 'amount'))).value()(),
      },
      {
        title: t('expiryDate'),
        dataIndex: 'end_date',
        width: '20%',
        render: (end_date) => dayjs(end_date).format('MMM DD, YYYY'),
      },
      {
        title: t('Action'),
        key: 'action',
        width: '10%',
        render: (text, record) => {
          const canDisableAction = disableAction(record);
          return (
            <Space size="middle">
              <div className={canDisableAction ? 'highlight-selected' : ''}>
                <button className="btn btn-edit" onClick={() => assets.onClickUpdateCoupon(record)} disabled={canDisableAction || formInProgress()}>
                  <i className="ntm-edit" />
                </button>
                <button className="btn btn-delete" onClick={() => assets.onClickDeleteCoupon(record)} disabled={canDisableAction || formInProgress()}>
                  <i className="ntm-bin" />
                </button>
              </div>
            </Space>
          );
        },
      },
    ];
  },
  pagination: {
    pageSizeOptions: ['3', '5', '7', '10'],
    showSizeChanger: false,
    showQuickJumper: false,
    showTotal: (total) => `Total ${total} items`,
  },
  loading: false,
};
const LogicalBlock = (componentAssests) => {
  const getTutorDiscountsList = (params) => {
    if (_.chain(componentAssests).get('externalProps.getDiscountsList').isFunction().value()) {
      componentAssests.setLoading(true);
      componentAssests.externalProps.getDiscountsList({
        params: { ...params, provider: _.chain(componentAssests).get('externalProps.tutorBaseProfile.details.id').value() },
        callback: (success, data) => {
          if (success) {
            componentAssests.setTutorDiscountsList(data);
          }
          componentAssests.setLoading(false);
        },
      });
    }
  };

  const deleteSpecificTutorDiscount = (body) => {
    if (_.chain(componentAssests).get('externalProps.deleteSpecificDiscount').isFunction().value()) {
      componentAssests.setLoading(true);
      componentAssests.externalProps.deleteSpecificDiscount({
        body,
        callback: (success, data) => {
          if (success) {
            if (_.chain(componentAssests).get('dataSource', []).size().isEqual(1)
              .value() && _.chain(componentAssests).get('tutorDiscountsListApi.page', 0).toNumber().gt(1)
                .value()) {
              pushHistory({
                page: _.chain(componentAssests).get('tutorDiscountsListApi.page', 0).toNumber().subtract(1)
                  .value(),
              });
            } else {
              applyFilter();
            }
          }
          componentAssests.setLoading(false);
        },
        shortNotification: { enable: true, message: { success: 'Coupon deleted successfully', failure: 'Coupon failed to get delete!' } },
      });
    }
  };

  const onClickDeleteCoupon = (record) => {
    if (_.chain(componentAssests).get('externalProps.canSwitchTabModal.showModal').isFunction().value()) {
      componentAssests.externalProps.canSwitchTabModal.showModal({
        title: 'Please confirm',
        content: (
          <Tooltip
            placement="right"
            title={(
              <span>
                {' '}
                <span className="tooltip-icon"><AiOutlineExclamationCircle /></span>
                {' '}
                <span className="tooltip-text">ie: Please note the operation is irrevesible. You will n't get this coupon back!</span>
              </span>
            )}
          >
            <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
              Do you really want to Delete this coupon?
              <AiFillInfoCircle style={{ fontSize: '20 px' }} />
            </label>
          </Tooltip>
        ),
      }, (success) => {
        if (success) {
          deleteSpecificTutorDiscount(_.pick(record, ['id']));
        }
      });
    }
  };

  const buildFilterParams = async (additionalParams = {}, omitParams = []) => {
    try {
      const defaultParams = await _.pickBy(_.get(componentAssests, 'tutorDiscountsListApi', {}), (value) => _.identity(value) || _.isInteger(value));
      const queryParams = await _.pickBy(queryString.parse(_.get(componentAssests, 'externalProps.location.search')), (value, key) => ((_.has(_.get(componentAssests, 'tutorDiscountsListApi', {}), key)) && _.identity(value)) || _.isInteger(value));
      additionalParams = await _.pickBy(additionalParams, (value) => _.identity(value) || _.isInteger(value));
      let finalParams = { ...defaultParams, ...queryParams, ...additionalParams };
      finalParams = _.omit(finalParams, omitParams);
      await Object.keys(finalParams).forEach((k) => {
        if (Number.isInteger(Number(finalParams[k]))) {
          finalParams[k] = parseInt(finalParams[k]);
        }
      });
      return finalParams;
    } catch (error) {
      console.log('error at component-functionalities.js in  onGetServicesCount function', error);
      return {};
    }
  };

  const pushHistory = async (additionalParams = {}, omitParams = [], callback = () => { }) => {
    // adding expand param in query param is not necessary
    // omitParams = _.concat(omitParams, ['expand', 'provider']);
    const finalParams = await buildFilterParams(additionalParams, omitParams);
    getTutorDiscountsList(finalParams);
    callback();
  };

  const applyFilter = async (params) => {
    const finalParams = await buildFilterParams();
    componentAssests.setTutorDiscountsListApi({ ..._.get(componentAssests, 'tutorDiscountsListApi', {}), ...finalParams });
    getTutorDiscountsList({ ...finalParams, ...params });
  };

  const processApplyFilter = (params) => {
    const pastQueryParams = _.get(componentAssests, 'pastTutorDiscountsListApi', {});
    const currentQueryParams = queryString.parse(_.get(componentAssests, 'externalProps.location.search', ''));
    if (!_.isEqual(pastQueryParams, currentQueryParams)) {
      componentAssests.setPastTutorDiscountsListApi({ ...currentQueryParams, ...params });
    }
  };

  const onPaginationPropsChange = (page, pageSize, data) => {
    const params = { ...data };
    // pagination
    params.page = page;
    params.limit = pageSize;
    pushHistory(params);
  };

  const onClickAddCoupon = () => {
    history.push(`${routeName.myCoupons}/?mode=add`);
    if (_.chain(componentAssests).get('externalProps.couponBlock.API.setUpAddCoupon').isFunction().value()) {
      componentAssests.externalProps.couponBlock.API.setUpAddCoupon();
    }
  };

  const onClickUpdateCoupon = (updateCouponDetails) => {
    history.push(`${routeName.myCoupons}/?mode=update`);
    if (_.chain(componentAssests).get('externalProps.couponBlock.API.setUpUpdateCoupon').isFunction().value()) {
      componentAssests.externalProps.couponBlock.API.setUpUpdateCoupon(updateCouponDetails);
    }
  };
  const goToLastPage = () => {
    const totalCouponCount = _.get(componentAssests, 'tutorDiscountsList.meta.total_count', 0);
    const couponListLimit = _.get(componentAssests, 'tutorDiscountsListApi.limit');
    const couponListCurrentPage = _.get(componentAssests, 'tutorDiscountsListApi.page');
    const couponListLastPage = _.chain(totalCouponCount).divide(couponListLimit).ceil().value();
    if (!_.isEqual(couponListLastPage, couponListCurrentPage)) {
      pushHistory({ page: couponListLastPage });
    } else {
      applyFilter();
    }
  };
  const refreshCouponList = () => {
    applyFilter();
  };
  return {
    pushHistory,
    applyFilter,
    processApplyFilter,
    onPaginationPropsChange,
    onClickDeleteCoupon,
    onClickAddCoupon,
    onClickUpdateCoupon,
    goToLastPage,
    refreshCouponList,
  };
};
export default {
  table,
  LogicalBlock,
};
