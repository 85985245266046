// React imports
import React, { useState } from 'react';

// Ant Design imports
import { Button, Image, Modal, Upload, Spin } from 'antd';
import { AiOutlineDelete, AiOutlineExclamationCircle, AiOutlinePlus } from 'react-icons/ai';
import { BsFilePdf } from "react-icons/bs";

// Redux imports
import { connect } from 'react-redux';
import * as commonActions from '../../store/actions/common';
import * as action from '../../store/actions/tutor/camps';

// Other imports
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import getMimeTypeFromExtension from '../../utils/common/getMimeTypeFromExtension';

// Component imports
import EmptyAnime from '../re-usable/lottie/EmptyAnime';

function FlyerPreview(props) {
  const { flyers, gid, cat } = props;
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [flyerToDelete, setFlyerToDelete] = useState(null);
  const [errorFlyer, setErrorFlyer] = useState(null);
  const [listType, setListType] = useState('picture-card');

  const styles = {
    gridContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center',gap: '15px',flexWrap: 'wrap' },
    gridItem: {},
  };

  const handleDelete = (flyer) => {
    setFlyerToDelete(flyer);
    setModalVisible(true);
  };

  const handleModalOk = () => {
    setLoading(true);
    props.onDeleteImage({ id: flyerToDelete.id }, (error, response) => {
      if (error) {
        setLoading(false);
        setModalVisible(false);
      } else {
        props.onGetCampsDetails({ camp_id: gid, expand: 'provider,services' });
        setLoading(false);
        setModalVisible(false);
      }
    });
  };

  const handleModalCancel = () => {
    setFlyerToDelete(null);
    setModalVisible(false);
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const onChangeFlyer = debounce((info) => {
    const status = _.get(info, ['file', 'status']);
    const size = _.get(info, ['file', 'size']);
    const maxSizeInBytes = 1024 * 1024 * 11; // 10MB
    const type = _.get(info, ['file', 'type']);

    const singleFile = _.get(info, 'file', '');
    const isImage = type.startsWith('image/');
    const isSizeValid = size / 1024 / 1024 < 11;

    if (!isSizeValid) {
      setErrorFlyer(props.t('upImageValid'));
      setLoading(false);
      return false;
    }
    if (size > 0 && size < maxSizeInBytes) {
      setErrorFlyer(null);
      props.uploadImages({
        cat,
        subcat: 'flyer',
        catid: gid,
        images: [singleFile],
      });
    }
  }, 1000);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Upload
          key="flyer"
          multiple
          name="picture_flyer"
          listType={listType}
          maxCount="4"
          className="avatar-uploader"
          showUploadList
          onChange={onChangeFlyer}
          customRequest={dummyRequest}
        >
          <div>
            {loading ? <Spin /> : <AiOutlinePlus style={{ fontSize: 20 }} />}
            <div style={{ marginTop: 8 }}>{props.t('upload')}</div>
          </div>
        </Upload>
      </div>
      {errorFlyer && (
        <p style={{ color: 'red', fontWeight: 'bold', padding: '10px', backgroundColor: '#ffdddd', border: '1px solid #f44336', borderRadius: '4px', marginTop: '10px' }}>
          {errorFlyer}
        </p>
      )}
      <div className="container mt-2 p-0">
        <div style={styles.gridContainer}>
          {Array.isArray(flyers) && flyers.length > 0 ? (

            flyers.map((flyer, index) => (
              <div
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '5px',
                  width: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  height: '120px',
                  position: 'relative',
                }}
                key={flyer.id}
              >
                {getMimeTypeFromExtension(flyer.url).isImage ? (
                  <Image
                    style={{ width: '100%', height: 'auto' }}
                    src={flyer.url}
                  />
                ) : (
                  <a href={flyer.url} target="_blank" rel="noopener noreferrer" style={{ width: '100%' }}>
                    <BsFilePdf style={{ width: '100%', height: 'auto',color:'#cc0000' }} />
                  </a>
                )}
                <Button
                  style={{ position: 'absolute', top: '7px', right: '5px' }}
                  className="delete ant-image-mask-info"
                  icon={<AiOutlineDelete />}
                  onClick={() => handleDelete(flyer)}
                />
              </div>
            ))

          ) : (
            <div>
              <EmptyAnime AnimeType="Empty" loop animeheight={150} />
              <p className="empty-animations">{props.t('noImageFound')}</p>
            </div>
          )}
        </div>
      </div>
      {modalVisible && flyerToDelete && (
        <Modal
          title="Are you sure you want to delete this flyer?"
          icon={<AiOutlineExclamationCircle />}
          okText="Yes"
          okType="danger"
          cancelText="No"
          open={modalVisible}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          confirmLoading={loading}
        />
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onDeleteImage: (requestParams, callback) => dispatch(commonActions.onDeleteImage(requestParams, callback)),
  onGetCampsDetails: (requestParams) => dispatch(action.onGetCampsDetails(requestParams)),
  uploadImages: (requestParams) => dispatch(commonActions.uploadImages(requestParams)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(FlyerPreview));
