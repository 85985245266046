import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Button, Table } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import css from '../../../../utils/CSSUtility';
import { withTranslation } from 'react-i18next';
import generalActionTypes from '../../../../store/general/actionTypes';
import generalAction from '../../../../store/general/action';
import componentFunctionalities from './component-functionalities';

const DiscountCouponsList = (props) => {
  console.log('props>>>>>>me new>>>', props.t);

  // Declare a new state variable, which we'll call "dataSource"
  const [dataSource, setDataSource] = useState(_.get(componentFunctionalities, 'table.dataSource', []));
  // Declare a new state variable, which we'll call "pagination"
  const [pagination] = useState(_.get(componentFunctionalities, 'table.pagination', {}));
  // Declare a new state variable, which we'll call "loading"
  const [loading, setLoading] = useState(_.get(componentFunctionalities, 'table.loading', false));
  // Declare a new state variable, which we'll call "tutorDiscountsListApi"
  const [tutorDiscountsListApi, setTutorDiscountsListApi] = useState({
    limit: 5,
    page: 1,
    // provider: 'FETCH_FROM_SESSION'
  });
    // Declare a new state variable, which we'll call "pastTutorDiscountsListApi"
  const [pastTutorDiscountsListApi, setPastTutorDiscountsListApi] = useState({});
  // Declare a new state variable, which we'll call "tutorDiscountsList"
  const [tutorDiscountsList, setTutorDiscountsList] = useState({});

  // Creating a instance for componentFunctionality "LogicalBlock"
  const LogicalBlock = componentFunctionalities.LogicalBlock({
    useEffect,
    tutorDiscountsList,
    setTutorDiscountsList,
    tutorDiscountsListApi,
    setTutorDiscountsListApi,
    pastTutorDiscountsListApi,
    setPastTutorDiscountsListApi,
    setLoading,
    dataSource,
    externalProps: props,
  });

  const columns = componentFunctionalities.table.columns({
    ..._.pick(LogicalBlock, ['onClickUpdateCoupon', 'onClickDeleteCoupon']),
    ..._.pick(props, ['couponBlock', 't']),
  });

  // The below function will emit only once.
  useEffect(() => {
    console.log('Page het props', _.get(props, 'location'));
    LogicalBlock.pushHistory();
  }, []);

  // The below function will emit each time the state value updates.
  useEffect(() => {
    LogicalBlock.processApplyFilter();
  });

  // The below function will emit each time the pastTutorDiscountsListApi value updates.
  useEffect(() => {
    if (!_.isEmpty(pastTutorDiscountsListApi)) {
      LogicalBlock.applyFilter();
    }
  }, [pastTutorDiscountsListApi]);

  // The below function will emit each time the tutorDiscountsList value updates.
  useEffect(() => {
    const listItems = _.get(tutorDiscountsList, 'items', []);
    setDataSource(listItems);
    props.setLogicBlockInstance(LogicalBlock);
  }, [tutorDiscountsList]);

  const addCouponBlock = () => {
    const validationProps = props;
    if (validationProps) {
      const disableAddCoupon = () => _.get(props, 'couponBlock.API.formLoader', false);
      return (
        <div className="d-flex justify-content-between  mb-3">
          <div className="message-title">
            <span style={css.heading1}>{props.t('listCoupon')}</span>
          </div>
          <Button style={{ marginRight: '2%' }} icon={<AiOutlinePlus style={{ fontSize: 20 }} />} type='primary' className="class-btn" onClick={LogicalBlock.onClickAddCoupon} disabled={disableAddCoupon()}>{props.t('couponLabel4')}</Button>
        </div>
      );
    }
  };

  const couponList = () => (
    <Table
      dataSource={dataSource}
      columns={columns}
      rowKey="id"
      loading={loading}
      pagination={{
        ...pagination,
        // current: _.get(tutorDiscountsListApi, 'page'),
        pageSize: _.get(tutorDiscountsListApi, 'limit'),
        total: _.get(tutorDiscountsList, 'meta.total_count', 0),
        onChange: LogicalBlock.onPaginationPropsChange,
        onShowSizeChange: LogicalBlock.onPaginationPropsChange,
      }}
    />
  );
  const renderOutput = () => (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="nurtem-card-design nurtem-table tutor-my-coupons-page py-4 mb-3">
        {addCouponBlock()}
        <div className="schedule-content mt-2 coupon-list-table">
          {couponList()}
        </div>
      </div>
    </div>
  );

  return renderOutput();
};

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  discounts: _.get(state, `general.${generalActionTypes.discounts.name}`, {}),
});

const mapDispatchToProps = (dispatch) => ({
  getDiscountsList: (details) => dispatch(generalAction.discounts.list(details)),
  deleteSpecificDiscount: (details) => dispatch(generalAction.discounts.delete(details)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DiscountCouponsList));
