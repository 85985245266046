import React from 'react';
import _ from 'lodash';

export class SearchLoader extends React.Component {
  render() {
    const NoOfTimes = this.props.cards ? _.range(this.props.cards) : _.range(6);
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className={css}>
        <div className="row">
          <div className="ph-item" style={{ width: '100%', marginTop: 10 }}>
            {Array.isArray(NoOfTimes) && NoOfTimes.map((_, i) => (
              <div key={i} className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
                <div className="nurtem-dashboard-card">
                  <div className="col-12">
                    <div className="row">
                      <div className="ph-col-3">
                        <div className="ph-picture" style={{ height: '120px', width: '120px', borderRadius: '20%' }} />
                      </div>
                      <div className="ph-col-3 empty">
                        <div style={{ width: 80 }} />
                      </div>
                      <div className="ph-col-2 mr-2 ml-2">
                        <div className="ph-row mt-3">
                          <div className="ph-col-12" />
                          <div className="ph-col-12" />
                          <div className="ph-col-4" />
                          <div className="ph-col-2 empty" />
                          <div className="ph-col-6 big" />
                        </div>
                      </div>
                      <div className="ph-col-3 empty">
                        <div style={{ width: 100 }} />
                      </div>
                      <div className="ph-col-6">
                        <div className="ph-row mt-3">
                          <div className="ph-col-12" />
                          <div className="ph-col-12" />
                          <div className="ph-col-12" />
                          <div className="ph-col-2 big" />
                          <div className="ph-col-3 ml-4 empty" />
                          <div className="ph-col-2 big" />
                          <div className="ph-col-3 ml-4 empty" />
                          <div className="ph-col-2 big" />
                        </div>
                      </div>
                      <div className="ph-col-2">
                        <div className="ph-row mt-3">
                          <div className="ph-col-6 empty" />
                          <div className="ph-col-2" />
                          <div className="ph-col-8 big" />
                        </div>
                      </div>
                      <div className="ph-col-10">
                        <div className="ph-row mt-3">
                          <div className="ph-col-2 mr-4" />
                          <div className="ph-col-4 mr-4" />
                          <div className="ph-col-4 mr-4" />
                          <div className="ph-col-2 mr-4" />
                          <div className="ph-col-4 mr-4" />
                          <div className="ph-col-4 mr-4" />
                        </div>
                        <div className="ph-row">
                          <div className="ph-col-4" />
                          <div className="ph-col-2 empty" />
                          <div className="ph-col-4" />
                          <div className="ph-col-2 empty" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
