import React, { PureComponent } from 'react'
import { Tabs, Tooltip, Calendar } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../../utils/axios'
import { withTranslation } from 'react-i18next'
import { withLoaderHandler } from '../../../utils/handler/withLoaderHandler'
import { CalendarLoader } from '../../../components/loader/common'
import * as userAction from "../../../store/actions/users";
import * as routeName from '../../../components/route-names'
import * as actions from '../../../store/actions/tutor/index'
import * as _ from 'lodash'
import "./calendar.css"
import dayjs from 'dayjs'


class Calendars extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      events: [],
      visible: false,
      holidayText: '',
      holidayDate: dayjs(),
      campsevents: [],
      competitionsevents: [],
      eventsevents: []
    }
  };

  cleanUrl = (item) => {
    return String(_.get(item, 'competition_details.session_name')).toLowerCase().replace(/(\s+|%20)/g, '-')
  }

  cleanUrlEvent = (item) => {
    return String(_.get(item, 'event_details.session_name')).toLowerCase().replace(/(\s+|%20)/g, '-')
  }

  componentDidMount() {
    window.scrollTo(0, 0); // scroll to top
    let requestParams = {};
    requestParams.expand = "provider,user,dependent_user,class_details";
    requestParams.limit = 1000
    requestParams.sort = 'created_at.desc'
    this.props.onGetUserClasses(requestParams);
    let params = {};
    params.expand = "camp_details,user,dependent_user";
    let paramsComp = {};
    paramsComp.limit = 1000
    paramsComp.sort = 'created_at.desc'
    paramsComp.expand = "competition_details,user,dependent_user";
    let paramsEvent = {};
    paramsEvent.limit = 1000
    paramsEvent.sort = 'created_at.desc'
    paramsEvent.expand = "event_details,user,dependent_user";
    this.props.onGetUserCamps(params);
    this.props.onGetUserCompetitions(paramsComp);
    this.props.onGetUserEvents(paramsEvent);
    this.props.tutorEmptyProfileSuccess();
    if (this.props.userClasses && this.props.userClasses.items) {
      this.onhandleCalendar();
    }
    if (this.props.userCamps && this.props.userCamps.items) {
      this.onCampCalendar(this.props.userCamps.items);
    }
    if (this.props.userCompetitions && this.props.userCompetitions.items) {
      this.onCompetitionCalendar(this.props.userCompetitions.items);
    }
    if (this.props.userEvents && this.props.userEvents) {
      this.onEventsCalendar(this.props.userEvents.items);
    }
  };

  componentDidUpdate(prevsProps) {
    if (prevsProps.userClasses !== this.props.userClasses) {

      if (this.props.userClasses && this.props.userClasses.items) {
        this.onhandleCalendar();
      }
    }
    if (prevsProps.userCamps !== this.props.userCamps) {
      if (this.props.userCamps && this.props.userCamps.items) {
        this.onCampCalendar(this.props.userCamps.items);
      }
    }
    if (prevsProps.userCompetitions !== this.props.userCompetitions) {
      if (this.props.userCompetitions && this.props.userCompetitions.items) {
        this.onCompetitionCalendar(this.props.userCompetitions.items);
      }
    }
    if (prevsProps.userEvents !== this.props.userEvents) {
      if (this.props.userEvents && this.props.userEvents.items) {
        this.onEventsCalendar(this.props.userEvents.items);
      }
    }
  };

  callback = key => {
    this.setState({
      tabskey: key
    });
  };

  clickUserClass = data => {
    this.child.onGetUserClasses(data)
  };

  onbetweenDates = (startDate, endDate) => {
    const dates = [];
    let currentDate = dayjs(startDate);
    const lastDate = dayjs(endDate);

    while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate, 'day')) {
      dates.push(currentDate.format('MMM DD,YYYY'));
      currentDate = currentDate.add(1, 'day');
    }

    return dates;
  };

  onCompetitionCalendar = async (datas = []) => {
    try {
      var eventLists = [];
      var eventListsData = [];

      for (const item of datas) {
        if (_.get(item, 'competition_details.duration_competition', '') === '2') {
          const sessionDates = await this.onbetweenDates(
            _.get(item, 'competition_details.start_date', ''),
            _.get(item, 'competition_details.end_date', '')
          );

          sessionDates.forEach((date) => {
            var object = {
              title: (
                <Tooltip title={_.get(item, 'competition_details.session_name').length > 20 ? _.get(item, 'competition_details.session_name') : ''}>
                  <Link to={`${routeName.tutorCampView}/${String(_.get(item, 'competition_details.session_name')).toLowerCase().replace(/(\s+|%20)/g, '-')}/?gid=${_.get(item, 'type_details')}`}>
                    {_.get(item, 'competition_details.session_name')}
                  </Link>
                </Tooltip>
              ),
              start: dayjs(date),
              end: dayjs(date),
            };
            eventLists.push(object);
          });
        } else {
          const individualDates = dayjs(_.get(item, 'competition_details.end_date')).format('MMM DD,YYYY');
          eventListsData.push(individualDates);

          eventListsData.forEach((date) => {
            var object = {
              title: (
                <Link to={`${routeName.competitionsView}/${this.cleanUrl(item)}/?gid=${_.get(item, 'type_details')}`}>
                  {_.get(item, 'competition_details.session_name')}
                </Link>
              ),
              start: dayjs(date),
              end: dayjs(date),
            };
            eventLists.push(object);
          });
        }
      }

      await this.setState({
        competitionsevents: eventLists,
      });
    } catch (error) {
      console.log('Error at calendar.js in onCompetitionCalendar function', error);
    }
  };

  onEventsCalendar = async (datas = []) => {
    try {
      var eventLists = [];
      var eventListsData = [];

      for (const item of datas) {
        if (_.get(item, 'event_details.duration_competition', '') === '2') {
          const sessionDates = await this.onbetweenDates(
            _.get(item, 'event_details.start_date', ''),
            _.get(item, 'event_details.end_date', '')
          );
          sessionDates.forEach((date) => {
            var object = {
              title: (
                <Tooltip title={_.get(item, 'event_details.session_name').length > 20 ? _.get(item, 'event_details.session_name') : ''}>
                  <Link to={`${routeName.tutorCampView}/${String(_.get(item, 'event_details.session_name')).toLowerCase().replace(/(\s+|%20)/g, '-')}/?gid=${_.get(item, 'type_details')}`}>
                    {_.get(item, 'event_details.session_name')}
                  </Link>
                </Tooltip>
              ),
              start: dayjs(date),
              end: dayjs(date),
            };
            eventLists.push(object);
          });
        } else {
          const individualDates = dayjs(_.get(item, 'event_details.end_date')).format('MMM DD,YYYY');
          eventListsData.push(individualDates);
          eventListsData.forEach((date) => {
            var object = {
              title: (
                <Link to={`${routeName.userEventView}/${this.cleanUrlEvent(item)}/?gid=${_.get(item, 'type_details')}`}>
                  {_.get(item, 'event_details.session_name')}
                </Link>
              ),
              start: dayjs(date),
              end: dayjs(date),
            };
            eventLists.push(object);
          });
        }
      }

      await this.setState({
        eventsevents: eventLists,
      });
    } catch (error) {
      console.log('Error at calendar.js in onEventsCalendar function', error);
    }
  };

  onCampCalendar = async (datas = []) => {
    var eventLists = [];

    datas.forEach((item) => {
      let camps_data = _.get(item, 'camp_details');
      let sessionDates = _.get(camps_data, 'session_dates', []);

      sessionDates.forEach((date) => {
        let userID =
          _.get(item, 'booked_for_type') === 'user'
            ? _.get(item, 'user')
            : _.get(item, 'booked_for_type') === 'dependent'
              ? _.get(item, 'dependent_user')
              : '';
        let for_whom =
          _.get(item, 'booked_for_type') === 'user'
            ? _.get(item, 'user.firstname') + ' ' + _.get(item, 'user.lastname')
            : _.get(item, 'booked_for_type') === 'dependent'
              ? _.get(item, 'dependent_user.firstname') +
              ' ' +
              _.get(item, 'dependent_user.lastname')
              : '';

        var object = {
          title: (
            <Link
              to={`${routeName.userClassView}/${_.get(
                camps_data,
                'session_name',
                ''
              )}/?campId=${_.get(item, 'id')}&userid=${userID}&usertype=${_.get(
                item,
                'booked_for_type'
              )}`}
            >
              {_.get(camps_data, 'session_name')} For - {for_whom}
            </Link>
          ),
          start: dayjs(date), // Use the session date as the start date
          end: dayjs(date), // Use the session date as the end date
        };
        eventLists.push(object);
      });
    });

    await this.setState({
      campsevents: eventLists,
    });
  };

  onhandleCalendar = async () => {
    let eventLists = [];
    let classHolidays = [];

    if (this.props.userClasses && this.props.userClasses.items) {
      this.props.userClasses.items.forEach((item) => {
        if (_.get(item, 'class_details') !== null) {
          let camps_data = _.get(item, 'class_details');
          let sessionDates = _.get(camps_data, 'session_dates', []);

          sessionDates.forEach((date) => {
            let userID =
              _.get(item, 'booked_for_type') === 'user'
                ? _.get(item, 'user')
                : _.get(item, 'booked_for_type') === 'dependent'
                  ? _.get(item, 'dependent_user')
                  : '';
            let for_whom =
              _.get(item, 'booked_for_type') === 'user'
                ? _.get(item, 'user.firstname') + ' ' + _.get(item, 'user.lastname')
                : _.get(item, 'booked_for_type') === 'dependent'
                  ? _.get(item, 'dependent_user.firstname') +
                  ' ' +
                  _.get(item, 'dependent_user.lastname')
                  : '';

            var object = {
              title: (
                <Link
                  to={`${routeName.userClassView}/${_.get(
                    camps_data,
                    'session_name',
                    ''
                  )}/?campId=${_.get(item, 'id')}&userid=${userID}&usertype=${_.get(
                    item,
                    'booked_for_type'
                  )}`}
                >
                  {_.get(camps_data, 'session_name')} For - {for_whom}
                </Link>
              ),
              start: dayjs(date), // Use the session date as the start date
              end: dayjs(date), // Use the session date as the end date
            };
            eventLists.push(object);
          });
        }
      });

      if (
        this.props.userClasses &&
        this.props.userClasses.items &&
        this.props.userClasses.items.some(
          (item) =>
            item &&
            item.provider &&
            item.provider.public_holidays &&
            _.isArray(item.provider.public_holidays) &&
            item.provider.public_holidays.length > 0
        )
      ) {
        let multipleHolidays = [];
        this.props.userClasses.items.forEach((item) => {
          if (
            item &&
            item.provider &&
            item.provider.public_holidays &&
            _.isArray(item.provider.public_holidays) &&
            item.provider.public_holidays.length > 0
          ) {
            let holiday = item.provider.public_holidays;
            holiday.forEach((value) => {
              multipleHolidays.push(value);
            });
            classHolidays = multipleHolidays.reduce((unique, o) => {
              if (
                !unique.some(
                  (obj) =>
                    obj.date === o.date && obj.description === o.description
                )
              ) {
                unique.push(o);
              }
              return unique;
            }, []);
          }
        });
      }

      if (classHolidays && classHolidays.length > 0) {
        classHolidays.forEach((item) => {
          if (item && _.isArray(classHolidays)) {
            let start_date = new Date(item.date).setHours(0, 0, 0, 0);
            let end_date = new Date(item.date).setHours(23, 59, 59, 0);
            var object = {
              title: (
                <span className="btn-event btn-holiday">
                  {item.description}
                </span>
              ),
              start: new Date(start_date),
              end: new Date(end_date),
            };
            eventLists.push(object);
          }
        });
      }

      await this.setState({
        events: eventLists,
      });
    }
  };

  dateCellRender = (value) => {
    const listData = this.state.events.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    );

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    );
  };

  dateCellRender1 = (value) => {
    const listData = this.state.campsevents.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    );

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    );
  };

  dateCellRender2 = (value) => {
    const listData = this.state.competitionsevents.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    );

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    );
  };

  dateCellRender3 = (value) => {
    const listData = this.state.eventsevents.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    );

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <div
        className='Tutor_Main_Dashboard_Event_COntainer'
        style={{ position: 'relative', marginBottom: 20 }}
      >
        {this.props.userBaseProfile &&
          Object.keys(this.props.userBaseProfile).length === 0 ? (
          <div className='BigCalendarplaceholder'>
            <i className='icmn-calendar' />
          </div>
        ) : (
          <Tabs defaultActiveKey="1" onChange={this.callback}>
            <items tab={this.props.t('yourClasses')} key="1">
              <Calendar cellRender={this.dateCellRender} />;
            </items>
            <items tab={this.props.t('yourCamp')} key="2">
              <Calendar cellRender={this.dateCellRender1} />;

            </items>
            <items tab={this.props.t('yourCompetition')} key="3">
              <Calendar cellRender={this.dateCellRender2} />;
            </items>
            <items tab={this.props.t('yourEvent')} key="4">
              <Calendar cellRender={this.dateCellRender3} />;
            </items>
          </Tabs>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {

  return {
    userBaseProfile: state.userProfile.userBaseProfile,
    tutorCampsCount: state.campsDetails.campsCount,
    userCamps: state.users.userCamps,
    providerClass: state.classesDetails.tutorClasses,
    userClasses: state.users.userClasses,
    userCompetitions: state.users.userCompetitions,
    userEvents: state.users.userEvents,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    tutorEmptyProfileSuccess: () => dispatch(actions.tutorEmptyProfileSuccess()),
    onGetUserCamps: params =>
      dispatch(userAction.getUserCamps(params)),
    onGetUserClasses: requestParams =>
      dispatch(userAction.getUserClasses(requestParams)),
    onGetUserCompetitions: requestParams =>
      dispatch(userAction.getUserCompetitions(requestParams)),
    onGetUserEvents: requestParams =>
      dispatch(userAction.getUserEvent(requestParams))

  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLoaderHandler(withTranslation()(Calendars), axios, CalendarLoader))
