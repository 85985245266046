/**
 * @author ramsteyn17@gmail.com
 * @dated 31/01/2023
 * This is to display the rescheduled sessions in a class/camp for both tutor & user. And to delete the reschduled sessions fromt the tutor.
 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { notification, Tooltip } from 'antd';
import { AiOutlineDelete, AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { FiCheck } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import axiostoServer from '../../utils/axios';

function ReschduleDays(props) {
  const detail = props.sessionDetail;
  const fortype = props.type;
  const [reschduledDay, setRescheduledDay] = useState(_.get(detail, 'reschduled_days'));
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [confirmIndex, setConfirmIndex] = useState(null);
  const location = useLocation();
  const { pathname } = location;
  const pathnames = pathname.split('/').filter((item) => item);
  const id = _.get(detail, 'id');

  useEffect(() => {
    const reschduledDay = _.get(detail, 'reschduled_days');
    if (reschduledDay !== undefined) {
      setRescheduledDay(reschduledDay);
    }
  }, [detail]);

  const delCancellDays = (oldDate, newDate, type, id, index) => {
    if (showConfirmation && confirmIndex === index) {
      if (oldDate !== null && newDate !== null && type === 'del_res') {
        const del_days = [];
        if (type === 'del_res') {
          del_days.push({
            oldDate,
            newDate,
            type,
            id,
            for: fortype,
          });
        }

        axiostoServer({
          method: 'post',
          url: '/del-modified-sessions',
          withCredentials: true,
          data: {
            del_days,
          },
          config: { headers: { 'Content-Type': 'application/json' } },
        })
          .then((resp) => {
            if (resp.status === 200 || resp.status === 304) {
              notification.success({
                message: resp.data.message,
              });
              setRescheduledDay((prevState) => prevState.filter((_, i) => i !== index));
            } else if (resp.status === 201) {
              notification.error({
                message: resp.data.message,
              });
            }
          });
      }
    } else {
      setConfirmIndex(index);
      setShowConfirmation(true);
    }
  };
  const handleCancel = () => {
    setShowConfirmation(false);
    setConfirmIndex(null);
  };
  const type = 'del_res';

  if (reschduledDay !== null
        && _.size(reschduledDay) > 0 && _.isArray(reschduledDay)) {
    return reschduledDay.map((week_name, index) => (
      <div key={week_name + index} style={{ display: 'flex', flexDirection: 'row' }}>
        <span className="session-text-bold mt-2" style={{ width: '100%', float: 'left', marginLeft: 10 }}>
          {`${week_name.oldDate} to ${week_name.newDate}`}
        </span>

        {(pathnames.includes('tutor') && !showConfirmation) ? (
          <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Delete">
            <AiOutlineDelete
              color="#ff5900"
              onClick={() => {
                setSelectedIndex(index);
                delCancellDays(week_name.oldDate, week_name.newDate, type, id, index);
              }}
              className="faq-buttons-delete ml-2"
            />
          </Tooltip>
        ) : null}

        {showConfirmation && selectedIndex === index && (
        <>
          <FiCheck
            color="#52c41a"
            className="faq-buttons-delete"
            onClick={() => delCancellDays(week_name.oldDate, week_name.newDate, type, id, index)}
          />
          <RxCross2
            color="#eb2f96"
            className="faq-buttons-edit"
            onClick={handleCancel}
            style={{ zIndex: 5, paddingTop: 5 }}
          />
        </>
        )}

      </div>
    ));
  }
  return (
    <div className="col-12 mb-2">
      <div className="time-schedule">
        <div className="week-day">--</div>
      </div>
    </div>
  );
}

export default ReschduleDays;
