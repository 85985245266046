import React, { FC } from 'react';
import { Spin } from 'antd';

interface CustomSpinnerProps {
  size?: 'small' | 'default' | 'large';
}

export const CustomSpinner: FC<CustomSpinnerProps> = ({ size }) => {
  return <Spin size={size} />;
};
