import dayjs from 'dayjs';

export const formatToUSPhoneNumber = (phoneNumberString, returnNumberStringIfFail = false) => {
  const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return returnNumberStringIfFail ? phoneNumberString : null;
};

export const addZeroToSingleDigit = (digit) => (`0${digit}`).slice(-2);

export const convertLocalTime = (time) => {
  if (time) {
    const getDateTime = dayjs(`${dayjs().format('MM-DD-YYYY')} ${time}`, 'MM-DD-YYYY hh:mm a');
    const getUtcTime = dayjs.utc(getDateTime).toDate();
    return getUtcTime;
  }
};
