import React, { Component } from 'react';
import { notification } from 'antd';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

export const withErrorHandler = (
  WrappedComponent,
  axios,
  wantToShowSuccessMessage = true,
) => {
  class Handler extends Component {
    _isMounted = false;

    componentDidMount() {
      this._isMounted = true;
    }

    UNSAFE_componentWillMount() {
      this.reqInterceptor = axios.interceptors.request.use((req) => req);
      this.resInterceptor = axios.interceptors.response.use(
        (res) =>

        // if (this._isMounted) {
        //   if (res.data.message) {
        //     if (wantToShowSuccessMessage) {
        //       var str = res.data.message;

          //       console.log("str >>> ", str)
          //       if (!str.includes("Discounts applied")) {
          //         this.showAlert(res.data.message, "success");
          //       }
          //     }
          //   }
          // }
          res,
        (error) => {
          // console.log('error.response', error.response);

          if (this._isMounted) {
            if (!error.response) {
              // this.showAlert(this.props.t("network error"), "error");
            } else if (_.get(error, 'response.data.errors.rules[0].message')) {
              this.showAlert(
                error.response.data.errors.rules[0].message,
                'error',
              );
            } else if (_.get(error, 'response.data.errors[0].rules[0].message')) {
              this.showAlert(
                _.get(error, 'response.data.errors[0].rules[0].message'),
                'error',
              );
            } else if (error.response.data.message !== 'Awaiting admin approval.') {
              if (_.get(error, 'response.data.message') === 'Already enrolled for some of the items.') {
                if (_.get(error, 'response.data.enrolled_items.class_ids')) {
                  this.showAlert('The class is already booked by the students.', 'error');
                } else {
                  this.showAlert('The camp is already booked by the students.', 'error');
                }
              } else {
                this.showAlert(error.response.data.message, 'error');
              }
            }
          }
          return Promise.reject(error.response);
        },
      );
    }

    showAlert = (message, type) => {
      console.log('successorerrormessage', message);
      const message_args = {
        duration: 2,
        message: '',
      };
      const errorMessage = 'Invalid username or password combination.';
      message_args.message = message;

      if (_.isObject(message) === false && type === 'success') {
        notification[errorMessage === message ? 'error' : 'success']({
          message: errorMessage === message ? 'Error' : 'Success',
          description: errorMessage === message ? message : _.capitalize(message),
        });
      } else if (_.isObject(message) === false && type === 'warning') {
        notification.warning({
          message: 'Warning',
          description: message,
        });
      } else if (_.isObject(message) === false) {
        notification.error({
          message: 'Error',
          description: errorMessage === message ? message : _.capitalize(message),
        });
      }
    };

    componentWillUnmount() {
      this._isMounted = false;
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    render() {
      return (
        <WrappedComponent {...this.props} />
      );
    }
  }
  return withTranslation()(Handler);
};
