export default {
  service_provider_classes_policy: {
    form_item: {
      name: 'classes_policy',
      rules: [
        {
          required: false,
          message: 'Please Mention your policies.',
        },
      ],
    },
    editor: {
      wrapperClassName: 'demo-wrapper',
      editorClassName: 'demo-editor',
    },
    label_text: 'Policy',
  },
};
