export const enableValidateAddCamp = 'enableValidateAddCamp';
export const disableValidateAddCamp = 'disableValidateAddCamp';
export const saveToLocalStorage = 'saveToLocalStorage';
export const campCreatedSuccessfully = 'saveToLocalStorage';
export const nextPage = 'nextPage';
export const previousPage = 'previousPage';
export const resetPage = 'resetPage';
export const addCampEnableLoading = 'addCampEnableLoading';
export const addCampDisableLoading = 'addCampDisableLoading';
export const beforeFetchingServices = 'beforeFetchingServices';
export const afterFetchingServices = 'afterFetchingServices';
export const beforeFetchingServicesValues = 'beforeFetchingServicesValues';
export const afterFetchingServicesValues = 'afterFetchingServicesValues';
export const beforeFetchingLocations = 'beforeFetchingLocations';
export const afterFetchingLocations = 'afterFetchingLocations';
export const setCampData = 'setCampData';
export const tutorCampDetailsFoundSuccess = 'tutorCampDetailsFoundSuccess';
export const enableLoading = 'enableLoading';
export const disableLoading = 'disableLoading';
export const addCampImageDisableLoading = 'addCampImageDisableLoading';
export const campImageCreatedSuccessfully = 'saveToLocalStorage';
