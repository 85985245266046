import React, { useState } from 'react'
import { Form, Select, Divider, Tooltip, Spin } from 'antd'
import { AiOutlineSearch } from 'react-icons/ai'
import _ from 'lodash'
import { connect } from 'react-redux'
import debounce from 'lodash/debounce'
import { withTranslation } from 'react-i18next'
import * as tutorCommon from '../../../store/actions/tutor/common'
import generalAction from '../../../store/general/action'

function ServiceSelectionSection(props) {
  const { opt } = props
  const { name } = props
  const { onServiceSelect } = props
  const headerName = _.get(props, 'whichForm', 'Class')
  // let onServiceSelect2 = props.onServiceSelect2

  const [serviceList, setServiceList] = useState([])
  const [serviceSearchData, setServiceSearchData] = useState([])
  const [selectedServiceName, setSelectedServiceName] = useState('Heloo')
  const [fetching, setFetching] = useState(false)

  const filterOption = (input, option) =>
    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0

  const handleServiceSelect = (e, opt) => {
    const selectedValue = _.get(opt, 'label', null)
    onServiceSelect(selectedValue)
  }

  const handleAddService = (e, name) => {
    const selectedServices = serviceList.find((d) => e === _.get(d, 'id', null))
    const selectedValue = _.get(name, 'label', null)
    setSelectedServiceName(selectedValue)
    onServiceSelect(selectedValue)
    // onServiceSelect2(name);
    const newServiceList = serviceList.filter(
      (val) => _.get(val, 'id', null) !== _.get(selectedServices, 'id', null)
    )
    setServiceList(newServiceList)
    function isIdInArray(array, obj) {
      const idSet = new Set(array.map((item) => item.id))
      return idSet.has(obj._id)
    }
    const isIdPresent = isIdInArray(opt, selectedServices)

    if (Object.keys(selectedServices).length > 0 && !isIdPresent) {
      serviceSearchData.push(selectedServices)
      opt.push(selectedServices)
      setServiceSearchData(_.uniqBy(serviceSearchData, 'id'))
      // console.log('service_id======', selectedServices)
      if (selectedServices.id) {
        props.providers_update_service({
          service_ids: Array.isArray(selectedServices.id)
            ? selectedServices.id
            : [selectedServices.id],
        })
      }
    }
  }

  const handleSearchDebounced = debounce((e) => {
    if (e) {
      setFetching(true)
      props.getServicesList({
        params: {
          search: _.startCase(e),
        },
        callback: (success, data) => {
          if (success) {
            const serviceList = _.get(data, 'items', [])
            const missingElements = _.differenceWith(
              serviceList,
              serviceSearchData,
              (obj1, obj2) => obj1.id === obj2.id
            )
            setServiceList(missingElements)
          }
          setFetching(false)
        },
      })
    }
  }, 800)

  return (
    <div className=" mb-3">
      <Tooltip placement="right">
        <label
          className="filter-group-title mb-2"
          style={{ width: 'auto', display: 'flex' }}
        >
          <span style={{ fontSize: 20, marginRight: 5 }} className="label-star">
            *
          </span>
          {headerName} {props.t('service')}
        </label>
      </Tooltip>
      <div className="full-width position-relative row">
        <Form.Item
          name={name}
          className="col-12"
          rules={[
            {
              required: true,
              message: `${props.t('serviceReq')}`,
            },
          ]}
        >
          <Select
            style={{ width: '100%' }}
            suffixIcon={<AiOutlineSearch />}
            showSearch
            placeholder={props.t('searchService')}
            options={opt.map((item) => ({
              label: item.name && item.id ? item.name : '',
              value: item.id || undefined,
            }))}
            value={selectedServiceName}
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '10px 0' }} />
                <h6>{props.t('serviceNew')}</h6>
                <Divider style={{ margin: '10px 0 0 0' }} />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '10px 0',
                  }}
                >
                  <Select
                    showSearch
                    suffixIcon={<AiOutlineSearch />}
                    placeholder={props.t('searchService')}
                    options={serviceList.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    onSearch={handleSearchDebounced}
                    optionFilterProp="label"
                    onSelect={handleAddService}
                    notFoundContent={fetching ?  'No Records Found': null}
                  />{/* <Spin size="small" /> */}
                </div>
              </div>
            )}
            getPopupContainer={(trigger) => trigger.parentNode}
            onSelect={handleServiceSelect}
            filterOption={filterOption}
            optionFilterProp="children"
            notFoundContent={'No Records Found'}
          />{/* <Spin size="small" /> */}
        </Form.Item>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  service: state.services,
})

const mapDispatchToProps = (dispatch) => ({
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  providers_update_service: (requestParams, callback) =>
    dispatch(tutorCommon.providers_update_service(requestParams, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ServiceSelectionSection))
