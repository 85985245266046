import React, { Component } from 'react'
import { Form } from 'antd'
import { TextInput, PasswordInput, Button, Notification } from '../../../components/antd-components/index.tsx';
import {
  AiFillApple,
  AiFillLock,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai'
import { BrowserRouter as Router, Switch, Link } from 'react-router-dom'
import './login.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { GoogleLogin } from '@react-oauth/google'
import queryString from 'query-string'
import { isUndefined } from 'lodash'
import * as actions from '../../../store/actions/authentication/authentication'
import { globalVariable } from '../../../utils/config/config'
import AppleLogin from './apple'
import * as routeName from '../../../components/route-names'
import * as servicesActions from '../../../store/actions/public/Services'
import axios from '../../../utils/axios'
import { withErrorHandler } from '../../../utils/handler'
import EmailCodeVeri from '../../../components/re-usable/signup/email-verification'
import { UserPng, TUTORPNG } from '../../../utils/config/assets'
import css from '../../../utils/CSSUtility'
import { MdEmail } from "react-icons/md";

const FormItem = Form.Item
const urlElements = window.location.href.split('/')
class OnboardingLogin extends Component {
  constructor(props) {
    super(props)
    this.loginContentPage = this.loginContentPage.bind(this)
  }

  state = {
    loginType: '',
  }

  async componentDidMount() {
    if (this.props.loginSignupTabValue) {
      await this.setState({
        loginType: this.props.loginSignupTabValue,
      })
    }

    if (isUndefined(window.AppleID)) {
      <Notification type={'error'} successMessage={this.props.t('checkInternetConnection')} />
    }
  }

  tabChage = async (tabType) => {
    await this.props.loginSignupTab(tabType)
  }

  loginContentPage = async (loginType) => {
    if (loginType === 'student') {
      await this.setState({
        loginType: 'student',
      })
    } else if (loginType === 'tutor') {
      await this.setState({
        loginType: 'tutor',
      })
    } else if (loginType === 'StClose') {
      await this.setState({
        loginType: 'StClose',
      })
    } else {
      await this.setState({
        loginType: 'TuClose',
      })
    }
  }

  getFacebookToken = (response, signInType) => {
    const url = this.props.location.search
    const params = queryString.parse(url)
    if (signInType === 'user') {
      if (response.accessToken) {
        const requestParams = {
          type: 'facebook',
          token: response.accessToken,
        }
        this.props.onUserSocialSignup(requestParams)
        this.props.searchClassCampsRouteRedirect(params)
      }
    } else if (response.accessToken) {
      const requestParams = {
        type: 'facebook',
        token: response.accessToken,
      }
      this.props.onTutorSocialSignup(requestParams)
    }
  }

  getGoogleToken = (response, signInType) => {
    const url = this.props.location.search
    const params = queryString.parse(url)
    console.log('login======', response)
    if (signInType === 'user') {
      if (response.credential || response.Zi) {
        const requestParams = {
          type: 'google',
          token: response.credential || response.Zi,
        }
        this.props.onUserSocialSignup(requestParams)
        this.props.searchClassCampsRouteRedirect(params)
      }
    } else if (response.credential || response.Zi) {
      const requestParams = {
        type: 'google',
        token: response.credential || response.Zi,
      }
      this.props.onTutorSocialSignup(requestParams)
    }
  }

  getAppleToken = (response, signInType) => {
    const url = this.props.location.search
    const params = queryString.parse(url)
    let requestParams
    if (signInType === 'user') {
      if (response.authorization) {
        requestParams = {
          type: 'apple',
          url: 'sign-in',
          domain: urlElements[2],
          token: response,
        }

        this.props.onSocialUserSignup(requestParams)
        this.props.searchClassCampsRouteRedirect(params)
      }
    } else if (response.authorization) {
      const requestParams = {
        type: 'apple',
        url: 'sign-in',
        domain: urlElements[2],
        token: response,
      }

      this.props.onTutorSocialSignup(requestParams)
    }
  }

  onFinish = (credentials, signInType) => {
    const url = this.props.location.search
    const params = queryString.parse(url)
    const login = { ...credentials }
    if (signInType === 'user') {
      login.login_type = 'user'
      this.props.onSignIn(login)
      this.props.searchClassCampsRouteRedirect(params)
    } else {
      login.login_type = 'provider'
      this.props.onSignIn(login)
    }
  }

  emailverificationHandle = () => (
    <div className="row student-login-modal-inner align-items-center justify-content-around">
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <div
          className="swal2-icon swal2-success swal2-animate-success-icon"
          style={{ display: 'flex' }}
        >
          <div
            className="swal2-success-circular-line-left"
            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
          />
          <span className="swal2-success-line-tip" />
          <span className="swal2-success-line-long" />
          <div className="swal2-success-ring" />
          <div
            className="swal2-success-fix"
            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
          />
          <div
            className="swal2-success-circular-line-right"
            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
          />
        </div>
        <EmailCodeVeri type="provider" />
      </div>
    </div>
  )

  render() {
    const url = this.props.location.search
    const params = queryString.parse(url)
    console.log(
      'this.props.loginSignupTabValue >> login',
      this.props.loginSignupTabValue
    )

    return (
      <div
        className={
          this.state.loginType === 'student'
            ? 'login-page-container student-login-open modalFunction'
            : this.state.loginType === 'tutor'
              ? 'login-page-container tutor-login-open modalFunction'
              : this.state.loginType === 'StClose'
                ? 'login-page-container StudentClose modalFunction'
                : this.state.loginType === 'TuClose'
                  ? 'login-page-container TutorClose modalFunction'
                  : 'login-page-container'
        }
      >
        <div className="row login-height align-items-center justify-content-around">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
            <div className="student-login">
              <div
                className="login-image-scetion"
                onClick={() => this.loginContentPage('student')}
              >
                <img className="img-mt" src={UserPng} alt="img" />
                <span className="shadow" />
              </div>
              <div
                className="login-text"
                onClick={() => this.loginContentPage('student')}
              >
                {this.props.t('studentLogin')}
                <i className="ntm-right-arrow login-icon" />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 logtop">
            <div className="tutor-login">
              <div
                className="login-image-scetion"
                onClick={() => this.loginContentPage('tutor')}
              >
                <img className="img-mt-0" src={TUTORPNG} alt="img" />
                <span className="shadow" />
              </div>
              <div
                className="login-text"
                onClick={() => this.loginContentPage('tutor')}
              >
                {this.props.t('tutorLogin')}{' '}
                <i className="ntm-right-arrow login-icon" />
              </div>
            </div>
          </div>
          {/* Student Login modal */}

          <div
            className={
              this.state.loginType === 'student'
                ? 'student-login-modal open'
                : 'student-login-modal'
            }
          >
            {(!this.props.signInHandleVerification ||
              this.props.signInHandleVerification.status === 401) && (
                <div className="row student-login-modal-inner align-items-center justify-content-around">
                  <button
                    disabled={this.props.loading}
                    className="btn login-close"
                    onClick={() => this.loginContentPage('TuClose')}
                  >
                    <i className="ntm-cancel" />
                  </button>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div style={css.heading1}>
                          {this.props.t('loginWithEmail')}
                        </div>
                      </div>
                      <div className="col-12">
                        <Form
                          scrollToFirstError
                          onFinish={(e) => this.onFinish(e, 'user')}
                          className="login-form"
                        >
                          <FormItem
                            name="username"
                            validateTrigger="onBlur"
                            rules={[
                              {
                                required: true,
                                message: this.props.t('emailValidationMessage'),
                              },
                              {
                                type: 'email',
                                message: this.props.t('inputValidEmail'),
                              },
                            ]}
                          >
                            <TextInput
                              addonBefore={<MdEmail />}
                              size="middle"
                              placeholder={this.props.t('yourValidEmailID')}
                              maxLength={70}
                            />
                          </FormItem>
                          <FormItem
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: this.props.t('pwValidationMessage'),
                              },
                            ]}
                          >
                            <PasswordInput
                              size="middle"
                              addonBefore={<AiFillLock />}
                              type="password"
                              iconRender={(visible) =>
                                visible ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )
                              }
                              placeholder={this.props.t('password')}
                            />
                          </FormItem>
                          <div className="full-width mb-3 mt-2">
                            <div className="row login-input">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 buttonlogin">
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="btn btn-sm btn-login"
                                  loading={this.props.loading}
                                >
                                  {this.props.t('login')}
                                </Button>
                              </div>
                              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="new-account">
                                  {this.props.t('dontHaveAccount')}{' '}
                                  <Link
                                    to={
                                      params && (params.classId || params.campId)
                                        ? params.campId
                                          ? `${routeName.userSignUp}?campId=${params.campId}`
                                          : `${routeName.userSignUp}?classId=${params.classId}`
                                        : routeName.userSignUp
                                    }
                                    onClick={() => this.tabChage('student')}
                                    className="text-primary utils__link--underlined"
                                    style={{ margin: '0 5px' }}
                                  >
                                    {this.props.t('signup')}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                      <div className="col-12 mb-3 mt-3">
                        <div className="login-with-text">
                          <span>{this.props.t('loginWith')}</span>
                        </div>
                        <div className="login-with-line" />
                      </div>
                      <div className="col-12">
                        <div className="row login-input">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <GoogleLogin
                              onSuccess={(e) => this.getGoogleToken(e, 'user')}
                              onError={(e) => this.getGoogleToken(e, 'user')}
                            />
                          </div>
                          {/* {Apple('student')} */}
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <AppleLogin
                              id="apple_user"
                              clientId={globalVariable.APPLE_ID}
                              redirectURI={globalVariable.REDIRECT_URL_SIGNIN}
                              usePopup
                              callback={(e) => this.getAppleToken(e, 'user')} // Catch the response
                              scope="email name"
                              responseMode="query"
                              render={(renderProps) => (
                                <div
                                  className="btn btn-google"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                >
                                  <AiFillApple className="btn-apple" />
                                  <div className="btn-text-apple">
                                    {this.props.t('loginWithApple')}
                                  </div>
                                </div>
                              )}
                              cookiePolicy="single_host_origin"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3 ">
                        <div className="forgot-password-account">
                          <Link
                            to={routeName.resetPassword}
                            className="text-primary utils__link--underlined"
                            style={{ margin: '0 5px' }}
                          >
                            {this.props.t('forgotPassword')}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            {this.props.signInHandleVerification.status === 402 &&
              this.emailverificationHandle()}
          </div>

          {/* Tutor Login modal */}
          <div
            className={
              this.state.loginType === 'tutor'
                ? 'tutor-login-modal open'
                : 'tutor-login-modal'
            }
          >
            {(!this.props.signInHandleVerification ||
              this.props.signInHandleVerification.status === 401 ||
              this.props.signInHandleVerification.status === 500) && (
                <div className="row student-login-modal-inner align-items-center justify-content-around">
                  <button
                    disabled={this.props.loading}
                    className="btn login-close"
                    onClick={() => this.loginContentPage('StClose')}
                  >
                    <i className="ntm-cancel" />
                  </button>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div style={css.heading1}>
                          {this.props.t('loginWithEmail')}
                        </div>
                      </div>
                      <div className="col-12">
                        <Form
                          scrollToFirstError
                          onFinish={(e) => this.onFinish(e, 'provider')}
                          className="login-form"
                        >
                          <FormItem
                            name="username"
                            validateTrigger="onBlur"
                            rules={[
                              {
                                required: true,
                                message: this.props.t('emailValidationMessage'),
                              },
                              {
                                type: 'email',
                                message: this.props.t('inputValidEmail'),
                              },
                            ]}
                          >
                            <TextInput
                              addonBefore={<MdEmail />}
                              size="middle"
                              placeholder={this.props.t('yourValidEmailID')}
                              maxLength={70}
                            />
                          </FormItem>
                          <FormItem
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: this.props.t('pwValidationMessage'),
                              },
                            ]}
                          >
                            <PasswordInput
                              size="middle"
                              addonBefore={<AiFillLock />}
                              type="password"
                              placeholder={this.props.t('Password')}
                              iconRender={(visible) =>
                                visible ? (
                                  <AiOutlineEye />
                                ) : (
                                  <AiOutlineEyeInvisible />
                                )
                              }
                            />
                          </FormItem>
                          <div className="full-width mb-3 mt-2">
                            <div className="row login-input">
                              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 buttonlogin">
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  className="btn btn-sm btn-login"
                                  loading={this.props.loading}
                                >
                                  {this.props.t('login')}
                                </Button>
                              </div>
                              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                                <div className="new-account">
                                  {this.props.t('dontHaveAccount')}{' '}
                                  <Link
                                    to={routeName.userSignUp}
                                    onClick={() => this.tabChage('tutor')}
                                    className="text-primary utils__link--underlined"
                                    style={{ margin: '0 5px' }}
                                  >
                                    {this.props.t('signup')}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                      <div className="col-12 mb-3 mt-3">
                        <div className="login-with-text">
                          <span>{this.props.t('loginWith')}</span>
                        </div>
                        <div className="login-with-line" />
                      </div>
                      <div className="col-12 mb-3 mt-3">
                        <div className="row login-input">
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <GoogleLogin
                              onSuccess={(e) =>
                                this.getGoogleToken(e, 'provider')
                              }
                              onError={(e) => this.getGoogleToken(e, 'provider')}
                            />
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                            <AppleLogin
                              id="apple_user"
                              clientId={globalVariable.APPLE_ID}
                              redirectURI={globalVariable.REDIRECT_URL_SIGNIN}
                              usePopup
                              callback={(e) => this.getAppleToken(e, 'provider')} // Catch the response
                              scope="email name"
                              responseMode="query"
                              render={(renderProps) => (
                                <div
                                  className="btn btn-google"
                                  onClick={renderProps.onClick}
                                  disabled={renderProps.disabled}
                                >
                                  <AiFillApple className="btn-apple" />
                                  <div className="btn-text-apple">
                                    {this.props.t('loginWithApple')}
                                  </div>
                                </div>
                              )}
                              cookiePolicy="single_host_origin"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mt-3 ">
                        <div className="forgot-password-account">
                          <Link
                            to={routeName.resetPassword}
                            className="text-primary utils__link--underlined"
                            style={{ margin: '0 5px' }}
                          >
                            {this.props.t('forgotPassword')}{' '}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {this.props.signInHandleVerification.status === 402 &&
              this.emailverificationHandle()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  loginSignupTabValue: state.authentication.loginSignupTab,
  signInHandleVerification: state.authentication.signInHandleVerification,
})

const mapDispatchToProps = (dispatch) => ({
  loginSignupTab: (credentials) =>
    dispatch(actions.loginSignupTab(credentials)),
  onSignIn: (credentials) => dispatch(actions.signIn(credentials)),
  onTutorSocialSignup: (credentials) =>
    dispatch(actions.socialSignupProvider(credentials)),
  onUserSocialSignup: (credentials) =>
    dispatch(actions.userSocialSignupProvider(credentials)),
  onSocialUserSignup: (credentials) =>
    dispatch(actions.userSocialSignupProvider(credentials)),
  searchClassCampsRouteRedirect: (requestParams) =>
    dispatch(servicesActions.searchClassCampsRouteRedirect(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(withTranslation()(OnboardingLogin), axios))
