const sanitizeUrl = (url) => {
    try {
        return url.replace(/[^a-zA-Z0-9-_]/g, '-') // Keep only alphanumeric characters, dashes, and underscores
            .replace(/([_-])\1+/g, '$1') // Replace multiple consecutive - or _ with a single -
            .replace(/^[_-]\s*/, '')
            .replace(/[_-]\s*$/, '') // Remove trailing underscores, dashes, and spaces
            .toLowerCase();
    } catch (error) {
        return url
    }

}


export default sanitizeUrl;
