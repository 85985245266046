import React from 'react';
import { Form, Input, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';

function NameForm(props) {
  const { onChange, nameofthecamps, type } = props;
  const nameRegex = /[a-zA-Z]/;
  return (
    <>
      <Tooltip placement="right" title={nameofthecamps}>
        <label className="filter-group-title mb-2" style={{ width: 'auto', display: 'flex' }}>
          {' '}
          <span style={{ fontSize: 20, marginRight: 5 }} className="label-star">*</span>
          {`${type} Name`}
          {' '}

        </label>
      </Tooltip>
      <div className="full-width position-relative row">
        <Form.Item
          name="name"
          className="col-12"
          rules={
            [
              {
                required: true,
                message: `${type} ${props.t('nameReq')}`,
              },
              {
                max: 100,
                message: `${type} ${props.t('nameReq1')}`,
              },
              {
                pattern: nameRegex,
                message: `${type} ${props.t('nameReq2')}`,
              },
            ]
          }
        >
          <Input
            onChange={onChange}
            autoComplete="off"
            placeholder={`Example: Surface areas and volumes ${type} 9`}
            maxLength={101}
          />
        </Form.Item>
      </div>
    </>
  );
}

export default withTranslation()(NameForm);
