import React from 'react';
import _ from 'lodash';
import { Rate } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MdLocationOn } from 'react-icons/md';
import { DEFAULTUSER } from '../../utils/config/assets';

const aboutTutorData = (props) => {
  const render = () => {

    const sessionDetails = _.get(props, 'sessions', []);
    const tutorDetails = _.get(props, 'provider', {});
    const review = _.get(tutorDetails, 'rating_count');
    const isLoggedIn = _.get(props, 'isLoggedIn', {});

    let length;
    Object.keys(sessionDetails).forEach((key) => {
      const sessionDetailsArray = sessionDetails[key];
      if (Array.isArray(sessionDetailsArray)) {
        const arrayLength = sessionDetailsArray.length;
        length = arrayLength;
      }
    });


    return (
      <div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ textAlign: 'center' }}>
          <div>
            <img
              src={(tutorDetails && tutorDetails.photo) ? (tutorDetails.photo) : DEFAULTUSER}
              className="tutor-img-bg"
              alt="img"
              height={'200px'}
              width={'200px'}
            />
          </div>
          <div className="camp-profile-details mt-1 mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="profile-name-header">
              <Link
                className="view-profile"
                to={{
                  pathname: `/profile/${_.get(tutorDetails, 'user_handle', null)}`,
                  state: { data: 'Hello from FirstPage' },
                }}
                target="_blank"
              >
                {_.get(tutorDetails, 'type') === 'Individual' ? (!_.isEmpty(_.get(tutorDetails, 'firstname', null)) ? `${_.startCase(_.get(tutorDetails, 'firstname'))} ${_.get(tutorDetails, 'lastname', '') !== '' ? `${_.startCase(_.get(tutorDetails, 'lastname', ''))}` : ''}` : '') : `${_.startCase(_.get(tutorDetails, 'businessname'))}`}
              </Link>
            </div>
          </div>
          <div>
            {isLoggedIn && (_.get(tutorDetails, 'email', '')) && (
              <div className="col-12  contact-list-profile mb-1" style={{ display: 'flex', justifyContent: 'center' }}>
                <span className="icon link" style={{ marginRight: 3 }}>
                  <i
                    className="ntm-envelope"
                  />
                </span>
                {' '}
                <span>{_.get(tutorDetails, 'email', '')}</span>
              </div>
            )}
            {(_.get(tutorDetails, 'address', '')) && (
              <div className="col-12  contact-list-profile mb-1" style={{ display: 'flex', justifyContent: 'center' }}>
                <span className="icon link" >
                  <MdLocationOn style={{ fontSize: 18 }} />
                </span>
                {' '}
                <span>{_.get(tutorDetails, 'address', '')}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ justifyContent: 'center' }}>

          <div className="camp-profile-details mt-1 mb-2" style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="profile-name-header">
              <span className="star-rating" style={{ display: 'flex' }}>
                <Rate disabled="true" value={_.parseInt(_.get(tutorDetails, 'average_rating', 0))} />
                {parseInt(tutorDetails && tutorDetails.average_rating)
                  !== 0 && (
                    <b className="ml-3" style={{ fontSize: 18 }}>
                      {Number.isInteger(_.get(tutorDetails, 'average_rating', 0))
                        ? _.get(tutorDetails, 'average_rating', 0)
                        : _.get(tutorDetails, 'average_rating', 0).toFixed(1)}/5
                    </b>
                  )}
              </span>
            </div>
          </div>

          <div
            className="camp-profile-details mb-2"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '50px',
              justifyContent: 'center',
              fontFamily: 'lato',
              fontWeight: 600,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ textAlign: 'center' }}>
                Batches
              </div>
              <div style={{ textAlign: 'center' }}>
                {length ? length : 0}
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ textAlign: 'center' }}>
                Reviews
              </div>
              <div style={{ textAlign: 'center' }}>
                {review}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return render();
};
export default withTranslation()(aboutTutorData);
