import * as actionTypes from '../../actions/tutor/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  activeKey: '1',
  loading: false,
  loadingSession: { loadingSession: false },
  loadingCampList: { loadingCampList: false },
  loadingCampDetails: { loadingCampDetails: false },
  campListloading: false,
  milestoneLoading: false,
  tutorCampsDetails: {},
  deleteCampsInfo: {},
  milestoneCreate: {},
  milestoneList: {},
  tutorCamps: {},
  tutorCampsRequests: {},
  tutorCampsRequestAction: {},
  campsStudents: {},
  updatedMilestoneInfo: {},
  campsList: {},
  sessionDetail: {},
  campsSponsoredList: {},
  campsCount: {},
  providerCampsDetails: {},
  otherProviderCamps: {},
  providerDirectoryList: {},
  onSearchCampsList: {},
};

const activeKey = (state, action) => ({ ...state, activeKey: action.success });

const campsCount = (state, action) => ({ ...state, campsCount: action.success });

const campsList = (state, action) => ({ ...state, campsList: action.success });

const sessionDetail = (state, action) => ({ ...state, sessionDetail: action.success });
const onSearchCampsList = (state, action) => ({ ...state, onSearchCampsList: action.success });
const onCampsSponsoredList = (state, action) => ({ ...state, campsSponsoredList: action.success });
const onProviderDirectoryList = (state, action) => ({ ...state, providerDirectoryList: action.success });
const tutorCampsDetails = (state, action) => ({ ...state, tutorCampsDetails: action.success });
const providerCampsDetails = (state, action) => ({ ...state, providerCampsDetails: action.success });
const otherProviderCampsDetails = (state, action) => ({ ...state, otherProviderCamps: action.success });
const tutorCampsDetele = (state, action) => ({ ...state, deleteCampsInfo: action.success });
const campsCreateMilestoneSuccess = (state, action) => ({ ...state, milestoneCreate: action.success });
const campsListMilestoneSuccess = (state, action) => ({ ...state, milestoneList: action.success });

const tutorCamps = (state, action) => ({ ...state, tutorCamps: action.success });

const tutorCampsRequests = (state, action) => ({ ...state, tutorCampsRequests: action.success });
const tutorCampsRequestAction = (state, action) => ({ ...state, tutorCampsRequestAction: action.success });

const campsStudents = (state, action) => ({ ...state, campsStudents: action.success });

const enableLoading = (state, action) => updateObject(state, action.loading);
const enableSessionLoading = (state, action) => updateObject(state, action.loadingSession);
const enableCampDetailsLoading = (state, action) => updateObject(state, action.loadingCampDetails);
const disableCampListloading = (state, action) => ({ ...state, campListloading: action.loading });
const enableCampListLoading = (state, action) => updateObject(state, action.loadingCampList);
const disableCampListLoading = (state, action) => updateObject(state, action.loadingCampList);
const enableCampListloading = (state, action) => ({ ...state, campListloading: action.loading });
const disableLoading = (state, action) => updateObject(state, action.loading);
const disableSessionLoading = (state, action) => updateObject(state, action.loadingSession);
const disableCampDetailsLoading = (state, action) => updateObject(state, action.loadingCampDetails);

const enableMilestoneLoading = (state, action) => updateObject(state, action.milestoneLoading);
const disableMilestoneLoading = (state, action) => updateObject(state, action.milestoneLoading);
const onUpdateMilestone = (state, action) => ({ ...state, updatedMilestoneInfo: action.success });

const resetTutorCampsDetails = (state, action) => updateObject(state, { tutorCampsDetails: {} });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.active_key:
      return activeKey(state, action);

    case actionTypes.tutorCampsDetailsFoundSuccess:
      return tutorCampsDetails(state, action);
    case actionTypes.providerCamps:
      return providerCampsDetails(state, action);
    case actionTypes.otherProviderCamps:
      return otherProviderCampsDetails(state, action);
    case actionTypes.tutorCampsDeleteFoundSuccess:
      return tutorCampsDetele(state, action);
    case actionTypes.milestoneCreate:
      return campsCreateMilestoneSuccess(state, action);
    case actionTypes.milestoneList:
      return campsListMilestoneSuccess(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.enableSessionLoading:
      return enableSessionLoading(state, action);
    case actionTypes.disableCampListLoading:
      return disableCampListLoading(state, action);
    case actionTypes.enableCampListLoading:
      return enableCampListLoading(state, action);
    case actionTypes.enableCampDetailsLoading:
      return enableCampDetailsLoading(state, action);
    case actionTypes.disableCampListloading:
      return disableCampListloading(state, action);
    case actionTypes.enableCampListloading:
      return enableCampListloading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.disableSessionLoading:
      return disableSessionLoading(state, action);
    case actionTypes.disableCampDetailsLoading:
      return disableCampDetailsLoading(state, action);
    case actionTypes.enableMilestoneLoading:
      return enableMilestoneLoading(state, action);
    case actionTypes.disableMilestoneLoading:
      return disableMilestoneLoading(state, action);
    // case actionTypes.providerCamps:
    //   return tutorCamps(state, action);
    case actionTypes.tutorCampsRequests:
      return tutorCampsRequests(state, action);
    case actionTypes.campsRequestActionSuccess:
      return tutorCampsRequestAction(state, action);
    case actionTypes.campsStudents:
      return campsStudents(state, action);
    case actionTypes.updateMilestone:
      return onUpdateMilestone(state, action);
    case actionTypes.campsListFound:
      return campsList(state, action);
    case actionTypes.sessionDetail:
      return sessionDetail(state, action);
    case actionTypes.onSearchCampsList:
      return onSearchCampsList(state, action);
    case actionTypes.onCampsSponsoredList:
      return onCampsSponsoredList(state, action);
    case actionTypes.onProviderDirectoryList:
      return onProviderDirectoryList(state, action);
    case actionTypes.campsCount:
      return campsCount(state, action);
    case actionTypes.resetTutorCampsDetails:
      return resetTutorCampsDetails(state, action);
    default:
      return state;
  }
};

export default reducer;
