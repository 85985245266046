function getMimeTypeFromExtension(extension) {
  const extensionToMime = {
    pdf: 'application/pdf',
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif',
    mp3: 'audio/mpeg', // Add MIME type for MP3
    mp4: 'video/mp4', // Add MIME type for MP4
    // Add more mappings as needed
  };

  const imageExtensions = ['pdf', 'mp4']; // List of supported extensions

  const normalizedExtension = extension.toLowerCase().split('.').pop(); // Extract last part after the last dot
  const mimeType = extensionToMime[normalizedExtension] || null;

  // Check if the extension corresponds to an image type
  const isImage = !imageExtensions.includes(normalizedExtension);

  return { mimeType, isImage };
}

export default getMimeTypeFromExtension;
