import React from 'react';
import { Form } from 'antd';
import { TextInput, PasswordInput, Button } from '../../../components/antd-components/index.tsx';
import {
  AiFillApple, AiFillLock, AiOutlineEye, AiOutlineEyeInvisible,
} from 'react-icons/ai';
import { BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import './signup.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { GoogleLogin } from '@react-oauth/google'
import queryString from 'query-string';
import * as actions from '../../../store/actions/authentication/authentication';
import { globalVariable } from '../../../utils/config/config';
import AppleLogin from '../login/apple';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import * as routeName from '../../../components/route-names';
import * as servicesActions from '../../../store/actions/public/Services';
import axios from '../../../utils/axios';
import { withErrorHandler } from '../../../utils/handler';
// import * as SecureLS from "secure-ls";
import EmailCodeVeri from '../../../components/re-usable/signup/email-verification';
import { UserPng, TUTORPNG } from '../../../utils/config/assets';
import css from '../../../utils/CSSUtility';
import { MdEmail } from "react-icons/md";

const FormItem = Form.Item;
const urlElements = window.location.href.split('/');

class OnboardingSignup extends React.Component {
  constructor(props) {
    super(props);
    this.loginContentPage = this.loginContentPage.bind(this);
  }

  state = {
    loginType: '',
  };

  async componentDidMount() {
    if (this.props.loginSignupTabValue) {
      await this.setState({
        loginType: this.props.loginSignupTabValue,
      });
    }
  }

  loginContentPage = async (loginType) => {
    if (loginType === 'student') {
      await this.setState({
        loginType: 'student',
      });
    } else if (loginType === 'tutor') {
      await this.setState({
        loginType: 'tutor',
      });
    } else if (loginType === 'StClose') {
      await this.setState({
        loginType: 'StClose',
      });
    } else {
      await this.setState({
        loginType: 'TuClose',
      });
    }
    await this.props.loginSignupTab('');
    await this.props.loginAdminSignupApproval('');
  };

  tabChage = async (tabType) => {
    await this.props.loginSignupTab(tabType);
  };

  passwordValidation = (rule, value, callback, message) => {
    const passw = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?]).{8,}/;
    if (value && !value.match(passw)) {
      callback(message);
    } else {
      callback();
    }
  };

  emailvalidation = (rule, value, callback) => {
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      callback(this.props.t('inputValidEmail'));
    } else {
      callback();
    }
  };

  getFacebookToken = (response, signInType) => {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    if (signInType === 'student') {
      if (response.accessToken) {
        const requestParams = {
          type: 'facebook',
          token: response.accessToken,
        };
        this.props.onSocialUserSignup(requestParams);
        this.props.searchClassCampsRouteRedirect(params);
      }
    } else if (response.accessToken) {
      const requestParams = {
        type: 'facebook',
        token: response.accessToken,
      };
      this.props.onSocialTutorSignup(requestParams);
    }
  };

  getGoogleToken = (response, signInType) => {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    if (signInType === 'student') {
      if (response.credential || response.Zi) {
        const requestParams = {
          type: 'google',
          token: response.credential || response.Zi,
        };
        this.props.onSocialUserSignup(requestParams);
        this.props.searchClassCampsRouteRedirect(params);
      }
    } else if (response.credential || response.Zi) {
      const requestParams = {
        type: 'google',
        token: response.credential || response.Zi,
      };
      this.props.onSocialTutorSignup(requestParams);
    }
  };

  getAppleToken = (response, signInType) => {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    if (signInType === 'student') {
      if (response.authorization) {
        const requestParams = {
          type: 'apple',
          url: 'sign-up',
          domain: urlElements[2],
          token: response,
        };
        this.props.onSocialUserSignup(requestParams);
        this.props.searchClassCampsRouteRedirect(params);
      }
    } else if (response.authorization) {
      const requestParams = {
        type: 'apple',
        url: 'sign-up',
        domain: urlElements[2],
        token: response,
      };
      this.props.onSocialTutorSignup(requestParams);
    }
  };

  onFinish = (credentials, signInType) => {
    const url = this.props.location.search;
    const params = queryString.parse(url);

    if (signInType === 'student') {
      const Usersdetails = { ...credentials };
      if (params && (params.classId || params.campId)) {
        Usersdetails.redirection_url = params.campId ? `campId=${params.campId}` : `classId=${params.classId}`;
      }
      this.props.onUserSignUp(Usersdetails);
    } else {
      this.props.onTutorSignUp(credentials);
    }
  };

  componentDidUpdate(preProps) {
  }

  render() {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    console.log('this.props.loginSignupTabValue >> sign up', this.props.loginSignupTabValue);

    return (
      <div className={this.state.loginType === 'student' ? 'login-page-container student-login-open modalFunction' : this.state.loginType === 'tutor' ? 'login-page-container tutor-login-open modalFunction' : this.state.loginType === 'StClose' ? 'login-page-container StudentClose modalFunction' : this.state.loginType === 'TuClose' ? 'login-page-container TutorClose modalFunction' : 'login-page-container'}>
        <div className="row login-height align-items-center justify-content-around">
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-3">
            <div className="student-login">
              <div className="login-image-scetion" onClick={() => this.loginContentPage('student')}>
                <img className="img-mt" src={UserPng} alt="img" />
                <span className="shadow" />
              </div>
              <div className="login-text" onClick={() => this.loginContentPage('student')}>
                {this.props.t('studentSignup')}
                {' '}
                <i className="ntm-right-arrow login-icon" />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 logtop">
            <div className="tutor-login">
              <div className="login-image-scetion" onClick={() => this.loginContentPage('tutor')}>
                <img className="img-mt-0" src={TUTORPNG} alt="img" />
                <span className="shadow" />
              </div>
              <div className="login-text" onClick={() => this.loginContentPage('tutor')}>
                {this.props.t('tutorSignup')}
                {' '}
                <i className="ntm-right-arrow login-icon" />
              </div>
            </div>
          </div>
          {/* Student Login modal */}
          <div className={this.state.loginType === 'student' ? 'student-login-modal open' : 'student-login-modal'}>
            <div className="row student-login-modal-inner align-items-center justify-content-around">
              <button disabled={this.props.loading} className="btn login-close" onClick={() => this.loginContentPage('TuClose')}><i className="ntm-cancel" /></button>
              {!this.props.loginSignupAdminApproval && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div style={css.heading1}>{this.props.t('signupWithEmail')}</div>
                    </div>

                    <div className="col-12">
                      <Form scrollToFirstError onFinish={(e) => this.onFinish(e, 'student')} className="login-form">
                        <FormItem
                          name="username"
                          validateTrigger="onBlur"
                          rules={[{
                            required: true,
                            message: this.props.t(
                              'authenticationEmailValidationMessage',
                            ),
                          }, {
                            type: 'email',
                            message: this.props.t(
                              'authenticationEmailValidationMessage',
                            ),
                          }]}
                        >
                          <TextInput
                            addonBefore={< MdEmail />}
                            size="middle"
                            placeholder={this.props.t('yourValidEmailID')}
                            maxLength={70}
                          />

                        </FormItem>
                        <FormItem
                          name="password"
                          rules={[{
                            required: true,
                            message: this.props.t(
                              'authenticationRequiredPasswordValidationMessage',
                            ),
                          }, {
                            validator: (rule, value, callback) => this.passwordValidation(
                              rule,
                              value,
                              callback,
                              this.props.t(
                                'enterPass3',
                              ),
                            ),
                          }]}
                        >
                          <PasswordInput
                            size="middle"
                            addonBefore={<AiFillLock />}
                            type="password"
                            placeholder={this.props.t('password')}
                            iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                          />

                        </FormItem>
                        <div className="full-width mb-3 mt-2">
                          <div className="row login-input">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 buttonlogin">
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="btn btn-sm btn-login"
                                loading={this.props.loading}
                              >
                                {this.props.t('submit')}
                              </Button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <div className="new-account">
                                {this.props.t('alreadySignedUp')}
                                {' '}
                                <Link
                                  to={params && (params.classId || params.campId) ? params.campId ? `${routeName.signIn}?campId=${params.campId}` : `${routeName.signIn}?classId=${params.classId}` : routeName.signIn}
                                  onClick={() => this.tabChage('student')}
                                  className="text-primary utils__link--underlined"
                                  style={{ margin: '0 5px' }}
                                >
                                  {this.props.t('loginHere')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="col-12 mb-3 mt-3">
                      <div className="login-with-text">
                        <span>{this.props.t('signupWith')}</span>
                      </div>
                      <div className="login-with-line" />
                    </div>
                    <div className="col-12">
                      <div className="row login-input">
                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-0">
												<FacebookLogin
													appId={globalVariable.FACEBOOK_ID}
													autoLoad={false}
													fields='name,email,picture'
													callback={(e) => this.getFacebookToken(e, 'student')}
													redirectUri={window.location.href}
													render={renderProps => (
														<div
															className='btn btn-facebook'
															onClick={renderProps.onClick}
														>
															<i className="ntm-facebook-logo"></i>
															Login with Facebook

														</div>
													)}
												/>
											</div> */}

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                          <GoogleLogin
                            onSuccess={(e) =>
                              this.getGoogleToken(e, 'student')
                            }
                            onError={this.getGoogleToken}
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">

                          <AppleLogin
                            id="apple_user"
                            clientId={globalVariable.APPLE_ID}
                            redirectURI={globalVariable.REDIRECT_URL_SIGNUP}
                            usePopup
                            callback={(e) => this.getAppleToken(e, 'student')} // Catch the response
                            scope="email name"
                            responseMode="query"
                            render={(renderProps) => (
                              <div
                                className="btn btn-google"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                              >
                                <AiFillApple className="btn-apple" />
                                <div className="btn-text-apple">
                                  {this.props.t('loginWithApple')}
                                </div>
                              </div>
                            )}
                            cookiePolicy="single_host_origin"
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-12 mb-3 mt-2">
                      <div className="login-input">
                        <div className="tc-text">
                          {this.props.t('signUPTems')}
                          {' '}
                          <a href="https://nurtem.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">{this.props.t('termsOfService')}</a>
                          {' '}
                          {this.props.t('viewOur')}
                          {' '}
                          <a href="https://nurtem.com/privacy-policy/" target="_blank" rel="noopener noreferrer">{this.props.t('privacyPolicy')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Student Success Message */}
              {this.props.loginSignupAdminApproval.status === 402 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 ">
                  <div
                    className="swal2-icon swal2-success swal2-animate-success-icon"
                    style={{ display: 'flex' }}
                  >
                    <div
                      className="swal2-success-circular-line-left"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                    <span className="swal2-success-line-tip" />
                    <span className="swal2-success-line-long" />
                    <div className="swal2-success-ring" />
                    <div
                      className="swal2-success-fix"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                    <div
                      className="swal2-success-circular-line-right"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                  </div>
                  <EmailCodeVeri
                    type="user"
                  />
                </div>
              )}
            </div>
          </div>
          {/* Tutor Login modal */}
          <div className={this.state.loginType === 'tutor' ? 'tutor-login-modal open' : 'tutor-login-modal'}>
            <div className="row student-login-modal-inner align-items-center justify-content-around">
              <button disabled={this.props.loading} className="btn login-close" onClick={() => this.loginContentPage('StClose')}><i className="ntm-cancel" /></button>
              {!this.props.loginSignupAdminApproval && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div style={css.heading1}>{this.props.t('signupWithEmail')}</div>
                    </div>

                    <div className="col-12">
                      <Form scrollToFirstError onFinish={(e) => this.onFinish(e, 'tutor')} className="login-form">
                        <FormItem
                          name="username"
                          validateTrigger="onBlur"
                          rules={[{
                            required: true,
                            message: this.props.t(
                              'authenticationEmailValidationMessage',
                            ),
                          }, {
                            type: 'email',
                            message: this.props.t(
                              'authenticationEmailValidationMessage',
                            ),
                          }]}
                        >
                          <TextInput
                            addonBefore={<MdEmail />}
                            size="middle"
                            placeholder={this.props.t('yourValidEmailID')}
                            maxLength={70}
                          />

                        </FormItem>
                        <FormItem
                          name="password"
                          rules={[{
                            required: true,
                            message: this.props.t(
                              'authenticationRequiredPasswordValidationMessage',
                            ),
                          }, {
                            validator: (rule, value, callback) => this.passwordValidation(
                              rule,
                              value,
                              callback,
                              this.props.t(
                                'enterPass3',
                              ),
                            ),
                          }]}
                        >
                          <PasswordInput
                            size="middle"
                            addonBefore={<AiFillLock />}
                            type="password"
                            placeholder={this.props.t('password')}
                            iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
                          />

                        </FormItem>
                        <div className="full-width mb-3 mt-2">
                          <div className="row login-input">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 buttonlogin">
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="btn btn-sm btn-login"
                                loading={this.props.loading}
                              >
                                {this.props.t('submit')}
                              </Button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                              <div className="new-account">
                                {this.props.t('alreadySignedUp')}
                                {' '}
                                <Link
                                  to={routeName.signIn}
                                  onClick={() => this.tabChage('tutor')}
                                  className="text-primary utils__link--underlined"
                                  style={{ margin: '0 5px' }}
                                >
                                  {this.props.t('loginHere')}
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="col-12 mb-3 mt-3">
                      <div className="login-with-text">
                        <span>{this.props.t('signupWith')}</span>
                      </div>
                      <div className="login-with-line" />
                    </div>
                    <div className="col-12 mt-3">
                      <div className="row login-input">
                        {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pr-0">
												<FacebookLogin
													appId={globalVariable.FACEBOOK_ID}
													autoLoad={false}
													fields='name,email,picture'
													callback={(e) => this.getFacebookToken(e, 'tutor')}
													redirectUri={window.location.href}
													render={renderProps => (
														<div
															className='btn btn-facebook'
															onClick={renderProps.onClick}
														>
															<i className="ntm-facebook-logo"></i>
															Login with Facebook

														</div>
													)}
												/>
											</div> */}

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                          <GoogleLogin
                            onSuccess={(e) =>
                              this.getGoogleToken(e, 'tutor')
                            }
                            onError={this.getGoogleToken}
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">

                          <AppleLogin
                            id="apple_user"
                            clientId={globalVariable.APPLE_ID}
                            redirectURI={globalVariable.REDIRECT_URL_SIGNUP}
                            usePopup
                            callback={(e) => this.getAppleToken(e, 'tutor')} // Catch the response
                            scope="email name"
                            responseMode="query"
                            render={(renderProps) => (
                              <div
                                className="btn btn-google"
                                onClick={renderProps.onClick}
                                disabled={renderProps.disabled}
                              >
                                <AiFillApple className="btn-apple" />
                                <div className="btn-text-apple">
                                  {this.props.t('loginWithApple')}
                                </div>
                              </div>
                            )}
                            cookiePolicy="single_host_origin"
                          />
                        </div>

                      </div>
                    </div>
                    <div className="col-12 mt-2">
                      <div className="login-input">
                        <div className="tc-text">
                          {this.props.t('signUPTems')}
                          {' '}
                          <a href="https://nurtem.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">{this.props.t('termsOfService')}</a>
                          {' '}
                          {this.props.t('viewOur')}
                          {' '}
                          <a href="https://nurtem.com/privacy-policy/" target="_blank" rel="noopener noreferrer">{this.props.t('privacyPolicy')}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Tutor Success Message */}
              {this.props.loginSignupAdminApproval.status === 402 && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                  <div
                    className="swal2-icon swal2-success swal2-animate-success-icon"
                    style={{ display: 'flex' }}
                  >
                    <div
                      className="swal2-success-circular-line-left"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                    <span className="swal2-success-line-tip" />
                    <span className="swal2-success-line-long" />
                    <div className="swal2-success-ring" />
                    <div
                      className="swal2-success-fix"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                    <div
                      className="swal2-success-circular-line-right"
                      style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                    />
                  </div>
                  <EmailCodeVeri
                    type="provider"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  loginSignupTabValue: state.authentication.loginSignupTab,
  loginSignupAdminApproval: state.authentication.loginSignupAdminApproval,
});

const mapDispatchToProps = (dispatch) => ({
  loginSignupTab: (credentials) => dispatch(actions.loginSignupTab(credentials)),
  loginAdminSignupApproval: (credentials) => dispatch(actions.loginSignupAdminApproval(credentials)),
  onTutorSignUp: (credentials) => dispatch(actions.signUpProvider(credentials)),
  // disableLoading: () => dispatch(actions.disableLoading()),
  onSocialTutorSignup: (credentials) => dispatch(actions.socialSignupProvider(credentials)),
  onUserSignUp: (credentials) => dispatch(actions.signUpUser(credentials)),
  onSocialUserSignup: (credentials) => dispatch(actions.userSocialSignupProvider(credentials)),
  // onsiderbarLogo: requestParams =>
  // 	dispatch(servicesActions.sidebarLogo(requestParams)),
  // onTutorSiderbarLogo: requestParams =>
  // 	dispatch(servicesActions.sidebarLogo(requestParams)),
  // onTutorSiderbar: requestParams =>
  // 	dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
  searchClassCampsRouteRedirect: (requestParams) => dispatch(servicesActions.searchClassCampsRouteRedirect(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withErrorHandler(withTranslation()(OnboardingSignup), axios));
