// AiDescriptionGenerator.js
import React, { Component } from 'react'
import { Button, Tooltip, notification, Form } from 'antd'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as commonActions from '../../store/actions/common'

class AiDescriptionGenerator extends Component {
  state = {
    aiLoading: false,
    descriptionEditorStateValue: EditorState.createEmpty(),
  }

  componentDidMount() {
    if (this.props.descpValue) {
      this.setState({ descriptionEditorStateValue: this.props.descpValue })
    }
  }

  componentDidUpdate(prevprops) {
    if (
      this.props.name !== prevprops.name ||
      this.props.servicetext !== prevprops.servicetext
    ) {
      // const data = {
      //     name: this.props.name,
      //     service: this.props.servicetext,
      //     type: this.props.type,
      // };
      if (this.props.descpValue) {
        this.setState({ descriptionEditorStateValue: this.props.descpValue })
      }
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({ descriptionEditorStateValue: editorState })
    this.props.updateDescription(editorState)
  }

  contentToHtmlEditor = (content) => {
    const blocksFromHtml = htmlToDraft(content)
    // console.log("content>>>1", blocksFromHtml)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    return EditorState.createWithContent(contentState)
  }

  handleAiGenerate = () => {
    this.setState({ aiLoading: true })
    if (this.props.name || this.props.servicetext) {
      const data = {
        name: this.props.name,
        service: this.props.servicetext,
        type: this.props.type,
      }
      this.props.onAiGenerateText(data, (resp) => {
        if (resp.status === 200) {
          // console.log("content===========1", resp)
          this.setState({ aiLoading: false })
          const content = _.get(resp.data, ['message', 'content'], '')

          // let blocksFromHtml = htmlToDraft(content);
          // // console.log("content>>>1", blocksFromHtml)
          // const { contentBlocks, entityMap } = blocksFromHtml;
          // const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
          const description_html = this.contentToHtmlEditor(content)

          this.props.updateDescription(description_html)
          this.setState({ descriptionEditorStateValue: description_html })
        } else {
          // console.log("content===========", resp)
          this.setState({ aiLoading: false })
          notification.warning({ message: this.props.t('error1') })
        }
      })
    } else {
      this.setState({ aiLoading: false })
      notification.warning({ message: this.props.t('genAi1') })
    }
  }

  render() {
    return (
      <div className="col-12 mt-3 p-0">
        <div className="row justify-content-between">
          <label
            className="col-6 filter-group-title mt-3"
            style={{ width: 'auto', display: 'flex', marginBottom: '0px' }}
          >
            <span
              style={{ fontSize: 20, marginRight: 5 }}
              className="label-star"
            >
              *
            </span>{' '}
            {this.props.t('descp')}
          </label>

          <label className="col-6 filter-group-title mb-3 text-right">
            <Tooltip
              placement="top"
              title="Your AI assistant to generate description about the class"
            >
              <Button
                type="primary"
                loading={this.state.aiLoading}
                onClick={this.handleAiGenerate}
              >
                {this.props.t('genAi')}
              </Button>
            </Tooltip>
          </label>
        </div>
        <div className="full-width drift-editor">
          <Form.Item
            name={this.props.itemname ? this.props.itemname : 'description'}
            rules={[
              {
                required: false,
                message: 'Description is required in Class.',
              },
              {
                validator: async (rule, value) => {
                  const descPvalue = draftToHtml(
                    convertToRaw(
                      this.state.descriptionEditorStateValue.getCurrentContent()
                    )
                  )
                  // Remove HTML tags and whitespace to check if it's effectively empty
                  const strippedContent = descPvalue
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .trim()
                  if (!strippedContent) {
                    throw new Error('Description is required!')
                  }
                },
              },
            ]}
          >
            <Editor
              editorState={this.state.descriptionEditorStateValue}
              onEditorStateChange={this.onEditorStateChange}
            />
          </Form.Item>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
// Define any state properties that your component needs from the Redux store
({
  // For example:
  // name: state.name,
  // servicetext: state.servicetext,
})

const mapDispatchToProps = (dispatch) => ({
  onAiGenerateText: (requestParams, callback) =>
    dispatch(commonActions.onAiGenerateText(requestParams, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AiDescriptionGenerator))
