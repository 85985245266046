import React, { useState } from 'react';
import { Button, Space, Table } from 'antd';
import { connect } from 'react-redux';
import generalAction from '../../../store/general/action';
import '../style.css';
import { AiOutlineEdit } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate';
import * as routeName from '../../../components/route-names';
import _ from 'lodash';
import css from '../../../utils/CSSUtility';
import { FaPlus } from 'react-icons/fa6';

const SessionList = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const urlparams = queryString.parse(window.location.search);
  const gid = _.get(urlparams, 'gid');
  const from = _.get(urlparams, 'tutor', null);
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const sessions = props.sessions.hasOwnProperty(props.groupID) ? props.sessions[props.groupID] : [];
  const formattedSessions = sessions.map((session) => {
    const formattedDate = session.duration_competition === '2'
      ? `${timeDateConverterSession(session.start_date, 'date')} to ${timeDateConverterSession(session.end_date, 'date')}`
      : timeDateConverterSession(session.end_date, 'date');
    const compDate = session.duration_competition === '2'
      ? `${timeDateConverterSession(session.competition_start_date, 'date')} to ${timeDateConverterSession(session.competition_end_date, 'date')}`
      : timeDateConverterSession(session.competition_start_date, 'date');
    // const formattedLink = session.link || '---';
    // const formattedCode = session.code || '---';
    return {
      ...session,
      session_name: (<a style={{ color: '#1890ff' }} onClick={() => { props.callback(session); }}>{session.session_name}</a>),
      formatted_date: formattedDate,
      comp_date: compDate,
      // formatted_link: _.truncate(formattedLink, { 'length': 25, 'omission': '...' }),
      // formatted_code: formattedCode,
      key: session.id, // Assigning a unique key for each session
    };
  });
  // Sort the formattedSessions array by created_at in descending order
  const sortedSessions = formattedSessions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const ListingClasscolumns = [{
    title: props.t('batName'),
    dataIndex: 'session_name',
    key: 'session_name',
  },
  {
    title: props.t('compBookDate'),
    dataIndex: 'formatted_date',
    key: 'formatted_date',
  },
  {
    title: props.t('date'),
    dataIndex: 'comp_date',
    key: 'comp_date',
  },
  {
    title: props.t('Action'),
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <div><button onClick={() => { record.session_name = record.session_name.props.children; props.callback(record); }} className="btn btn-accept  mr-1">Details</button></div>

        <div>
          {
            from ? (
              <Link to={`${routeName.updateEvent}/${props.compName}?id=${record.group_id}&sid=${record.id}&type=update&scroll=true&tutor=${from}`}>
                <AiOutlineEdit className="faq-buttons-edit" style={{ paddingTop: 5 }} />
              </Link>
            )
              : (
                <Link to={`${routeName.updateEvent}/${props.compName}?id=${record.group_id}&sid=${record.id}&type=update&scroll=true`}>
                  <AiOutlineEdit className="faq-buttons-edit" style={{ paddingTop: 5 }} />
                </Link>
              )
          }
        </div>
      </Space>
    ),
  },
  ];

  const render = () => (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-2">
        <Link to={`${routeName.updateEvent}/${props.compName}?id=${gid}&type=update&sid=new`}>
          <Button icon={<FaPlus style={{ fontSize: 18 }} />} style={css.button}>Add Batch</Button>
        </Link>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="nurtem-table" style={{ overflowX: 'auto' }}>
          {<Table
            columns={ListingClasscolumns}
            dataSource={sortedSessions}
            pagination={{
              current: currentPage,
              pageSize,
              total: props.sessions.hasOwnProperty(props.groupID) ? props.sessions[props.groupID].length : 0,
              // showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />}
        </div>
      </div>
    </>
  );
  return render();
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['sessions', 'loading'], false),
  sessions: _.get(state, ['sessions', 'sessions'], {}),
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  tutorTransfer: (data) => dispatch(generalAction.tutor.providerTransfer(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SessionList));
