import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateClassesState;
// tutor actions

export default {
  // get nurtem services list
  list: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: 'business/classes/list',
      },
      actionType: actionType.classes.list,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },

  // get nurtem services list
  viewClass: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/classes/view',
      },
      actionType: actionType.classes.view,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
};
