import React from 'react';

import '../privacy-policy/privacy-policy.css';

class CookiePolicy extends React.Component {
  render() {
    return (
      <div className="privacy-policy-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 className="title">{this.props.t('title')}</h2>
            <p>{this.props.t('websiteOperation')}</p>
            <h4>{this.props.t('whatAreCookies')}</h4>
            <p>{this.props.t('cookiesDefinition')}</p>
            <p>{this.props.t('purposes')}</p>
            <ul>
              <li>{this.props.t('enableFunctions')}</li>
              <li>{this.props.t('provideAnalytics')}</li>
              <li>{this.props.t('storePreferences')}</li>
              <li>{this.props.t('enableAdDelivery')}</li>
            </ul>
            <p>{this.props.t('sessionAndPersistentCookies')}</p>
            <p>{this.props.t('sessionCookieDescription')}</p>
            <p>{this.props.t('persistentCookieDescription')}</p>
            <h4>{this.props.t('thirdPartiesCookies')}</h4>
            <p>{this.props.t('thirdPartiesDescription')}</p>
            <h4>{this.props.t('cookiesOptions')}</h4>
            <p>{this.props.t('deleteCookies')}</p>
            <p>{this.props.t('note')}</p>
            <h4>{this.props.t('learnMoreAboutCookies')}</h4>
            <p>{this.props.t('learnMoreDescription')}</p>
            <ul>
              <li>{this.props.t('aboutBrowserGuide')}</li>
              <li>{this.props.t('allAboutCookies')}</li>
              <li>{this.props.t('networkAdvertisingInitiative')}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default CookiePolicy;
