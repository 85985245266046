import componentAPI from './component-api';
import generalLogic from './general-logic';

export default (componentAssests) => {
  console.log('componentAssests>>>>>>>me1>>>', componentAssests);
  return {
    componentAPI: componentAPI(componentAssests),
    generalLogic: generalLogic(componentAssests),
  };
};
