import _ from 'lodash';
import * as actionTypes from '../../actions/tutor/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: false,
  tutorBaseProfile: {},
  tutorBusinessinfo: {},
  tutorProfileinfo: {},
  userHandle: [],
  ProfilesUpdateSuccess: {},
  providerPaymentList: {},
  [actionTypes.sendNotesToStudent]: {},
};

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const tutorBaseProfile = (state, action) => ({ ...state, tutorBaseProfile: action.success });

const tutorBusinessinfoSuccess = (state, action) => ({ ...state, tutorBusinessinfo: action.success });

const tutorExtrainfoSuccess = (state, action) => ({ ...state, tutorProfileinfo: action.success });
const userHandleResponse = (state, action) => ({ ...state, userHandle: action.success });
const ProfilesUpdateSuccess = (state, action) => ({ ...state, ProfilesUpdateSuccess: action.success });
const onGetProviderPaymentList = (state, action) => ({ ...state, providerPaymentList: action.success });
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.tutorBaseProfile:
      return tutorBaseProfile(state, action);
    case actionTypes.tutorBusinessinfoSuccess:
      return tutorBusinessinfoSuccess(state, action);
    case actionTypes.tutorExtrainfoSuccess:
      return tutorExtrainfoSuccess(state, action);
    case actionTypes.userHandle:
      return userHandleResponse(state, action);
    case actionTypes.ProfilesUpdateSuccess:
      return ProfilesUpdateSuccess(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.providerPaymentList:
      return onGetProviderPaymentList(state, action);
    case actionTypes.tutorSwitchPropertyLoading:
      return updateObject(state, _.get(action, 'data', {}));
    case actionTypes.updateTutorState:
      return updateObject(state, _.get(action, 'data', {}));
    default:
      return state;
  }
};

export default reducer;
