import { notification } from 'antd'
import _ from 'lodash'
import * as routeName from '../components/route-names'
import history from './history'

const profileFilledCheck = (userBaseProfile) => {
  console.log('userBaseProfile>>>', userBaseProfile)

  const account_type = _.get(userBaseProfile, 'details.user.account_type', '')
  if (!_.isEmpty(userBaseProfile) && account_type !== 'dependent') {
    const firstname = _.get(userBaseProfile, 'details.firstname', '')

    if (_.isEmpty(firstname)) {
      history.push(routeName.userProfile)
      notification.warning({
        message: 'Warning',
        description: 'Please update your profile.',
      })
    }
  }
}

export default profileFilledCheck
