import { notification } from 'antd';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const attendenceFoundSuccess = (data) => ({
  type: actionTypes.tutorAttendenceDetailsFoundSuccess,
  success: data,
});

export const onGetAttendenceDetails = (requestParams) => {
  const modifiedParams = { ...requestParams };
  delete modifiedParams.filterStatus;
  return (dispatch) => {
    dispatch(enableLoading());
    axios
      .get('/attendances/list', {
        params: {
          ...modifiedParams,
          expand: 'user,class_details,dependent_user,mark_date',
        },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(attendenceFoundSuccess(response.data));
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        dispatch(disableLoading());
      });
  };
};

export const onAttendenceList = (data) => ({
  type: actionTypes.attendenceListFound,
  success: { data, status: true },
});

export const getAttendenceList = (requestParams = {}) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/attendances/list',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onAttendenceList(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const newAttendenceCreate = (data) => ({
  type: actionTypes.AttendenceCreation,
  success: data,
});

export const CreateAttendence = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/attendances/create',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        // dispatch(
        //   getAttendenceList({
        //     class_details: requestParams.class_details,
        //     user_type: "provider"
        //   })
        // );
        const message_args = {
          duration: 2,
          message: response.data.message,
        };
        notification.success(message_args);
        dispatch(newAttendenceCreate(response));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const attendenceDeleteFoundSuccess = (data) => ({
  type: actionTypes.attendenceDeleteFoundSuccess,
  success: data,
});

export const onDeleteAttendence = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/attendances/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(
          getAttendenceList({
            class_details: requestParams.class_details,
            user_type: 'provider',
          }),
        );
        dispatch(attendenceDeleteFoundSuccess(deleteResponse));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      dispatch(attendenceDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onClearAttendenceDetail = () => (dispatch) => {
  dispatch(attendenceFoundSuccess({}));
};

export const onClearAttendenceDeleteDetail = () => (dispatch) => {
  dispatch(attendenceDeleteFoundSuccess({}));
};

export const ProviderAttendenceSuccess = (data) => ({
  type: actionTypes.providerAttendence,
  success: data,
});

export const getProviderAttendence = () => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/providers/attendances',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(ProviderAttendenceSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

/** Attendance Report fetching Api ...added by indhumathi */

const afterFetchingreport = (data) => ({
  type: actionTypes.attendanceReport,
  success: data,
});

export const attendanceReport = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  dispatch(afterFetchingreport({}));

  axios({
    method: 'get',
    url: `/attendances/report?s=${Math.random()}`,
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(afterFetchingreport(res.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};
