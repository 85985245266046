import React from 'react'
import {
  Input,
  Button,
  Select,
  Checkbox,
  Radio,
  Tag,
  Upload,
  Tooltip,
  Form,
  TimePicker,
  InputNumber,
  Spin,
} from 'antd'
import {
  AiOutlineSearch,
  AiFillCheckCircle,
  AiTwotoneDelete,
  AiTwotoneEdit,
  AiFillInfoCircle,
  AiOutlinePlus,
  AiOutlineExclamationCircle,
} from 'react-icons/ai'
import { Scrollbars } from 'react-custom-scrollbars'
import './tutor-onboarding.css'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import debounce from 'lodash/debounce'
import Geocode from 'react-geocode'
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import countryList from 'react-select-country-list'
import dayjs from 'dayjs'
import axios1 from 'axios'
import * as actions from '../../../store/actions/tutor/index'
import { globalVariable } from '../../../utils/config/config'
import { country_call_code } from './country_export'
import * as routeNames from '../../../components/route-names'
import * as servicesActions from '../../../store/actions/public/Services'
import history from '../../../utils/history'
import axios from '../../../utils/axios'
import _ from 'lodash'
const { TextArea } = Input

const { Option } = Select
const CheckboxGroup = Checkbox.Group
const ima = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      Please select which type of service provider you are.
    </span>
  </span>
)
const modeofteaching = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      You can multi select the teaching mode.
    </span>
  </span>
)
const businesshours = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      Enter your business hours. Example "9:00 AM - 5:00 PM".
    </span>
  </span>
)
const yearsofteaching = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">Your overall teaching experience.</span>
  </span>
)
const studentstaught = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">No. of students you taught</span>
  </span>
)
const typesofclasses = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      You can search for class and camp categories which you are offering.
      Multiple class and camps available
    </span>
  </span>
)
const listofcities = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      List of locations that you conduct the class. Multiple location available
    </span>
  </span>
)
const yourpublic = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      {' '}
      will be a link that you can share with anyone to view your profile.
    </span>
  </span>
)
const tagline = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      {' '}
      Share about yourself in 130 char or less
    </span>
  </span>
)
const business = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text"> Enter your business name.</span>
  </span>
)
const country = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      {' '}
      You can select the country from the list populated below.
    </span>
  </span>
)
const address = (
  <span>
    {' '}
    <span className="tooltip-icon">
      <AiOutlineExclamationCircle />
    </span>{' '}
    <span className="tooltip-text">
      {' '}
      You can select the address from the list populated below.
    </span>
  </span>
)
const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

const dummyRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

class TutorOnboarding extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.lastFetchId = 0
    //this.serviceSearch = debounce(this.serviceSearch, 800)
    //this.fetchLocationTaught = debounce(this.fetchLocationTaught, 800)
    //this.userHandle = debounce(this.userHandle, 800)
    //this.fetchAddress = debounce(this.fetchAddress, 800)
    this.myLastRefSlider = React.createRef()
    this.mypublicRefSlider = React.createRef()
    this.myuploadRefSlider = React.createRef()
    this.mycityRefSlider = React.createRef()
    this.phoneNUmberClick = this.phoneNUmberClick.bind(this)
    Geocode.setApiKey(globalVariable.GOOGLE_API_KEY)
  }

  state = {
    disabled: false,
    size: 'small',
    service_search_data: [],
    location_search_data: [],
    select_location_city_data: [],
    city_data: [],
    fetching: false,
    addressfetching: false,
    address_search_data: [],
    countryfetching: false,
    country_search_data: [],
    data: [],
    value: [],
    current: 0,
    isSubmitedSuccess: false,
    businessinfo: {},
    serviceSearchData: [],
    teachingModes: false,
    validateStatus: '',
    validationStatus: '',
    profileNameValid: false,
    profileNameValue: '',
    onGetcountry_code: '',
    country_code: '+1',
    photo: [],
    location: {},
    scroll_profile: 1,
    countryList: countryList().getData(),
    address_Error: '',
    classFormData: {},
    length: 0,
    location_search_error: '',
    businessHours: [],
    selectedDay: '',
    selectedStartTime: null,
    uploadError: null,
    selectedEndTime: null,
    description_value: null,
    profile_photo_s3_url: false,
    set_profile_photo_s3_url_processing: false,
    fileIsValid: true,
    EditBusiness: false,
    setProviderProfileImage: {
      photo: [],
      deleted: false,
      canUpload: false,
      loading: false,
    },
  }

  serviceSearch = async (value) => {
    const requestParams = {}
    requestParams.search = _.startCase(value)
    requestParams.loadMoreBtnStatus = false
    if (value) {
      await this.props.onGetServices(requestParams)
    }
  }

  getcountry = async (value) => {
    
    console.log("value>>", value)

    const datas = country_call_code[0]
    const get_callCode = datas[value]
    // this.formRef.current.setFieldsValue({
    //   country_code: get_callCode && get_callCode.code,
    // })
    this.setState({
      country_shot_code: value,
      country_code: get_callCode && get_callCode.code,
    })
  }

  async componentDidMount() {
    await this.props.onsiderbarLogo(false)
  }

  async componentDidUpdate(prevProps, prevState) {
    let tempServices = []
    if (
      this.props.service.serviceSearchData &&
      _.get(this, 'props.service.serviceSearchData.items')
    ) {
      tempServices = this.props.service.serviceSearchData.items.map((item) => ({
        text: item.name,
        value: item.id,
      }))
    }
    if (
      this.props.service.serviceSearchData !==
      prevProps.service.serviceSearchData
    ) {
      await this.setState((prevState, props) => ({
        ...prevState,
        serviceSearchData: [...tempServices],
      }))
    }
    if (prevProps.userHandle !== this.props.userHandle) {
      if (this.props.userHandle) {
        this.setState({
          validateStatus: this.userHandleResponse(),
        })
      }
    }

    if (
      prevProps.tutorExtrainfoSuccess !== this.props.tutorExtrainfoSuccess &&
      this.props.tutorExtrainfoSuccess.details &&
      this.props.tutorExtrainfoSuccess.details.update_progress !== 0
    ) {
      history.push({ pathname: routeName.tutorDashboard })
    }
  }

  userHandleResponse = () => {
    if (this.props.userHandle) {
      let validationStatus
      if (this.props.userHandle.status === 200) {
        this.setState({
          validationStatus: 'success',
        })
        validationStatus = 'success'
      } else {
        this.formRef.current.setFieldsValue({
          user_handle: '',
        })
        validationStatus = 'error'
        this.setState({
          errorMsg: true,
          validationStatus: 'error',
        })
      }
      return validationStatus
    }
    return null
  }

  // Location search
  fetchLocationTaught = (value) => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({
      location_search_data: [],
      location_search_error: '',
      fetching: true,
    })
    geocodeByAddress(value)
      .then((body) => {
        if (fetchId !== this.lastFetchId) {
          // for fetch callback order
          return
        }

        const location_search_data = body.map((location) => ({
          text: location.formatted_address,
          value: `${location.geometry.location.lat()},${location.geometry.location.lng()}`,
        }))
        this.setState({
          location_search_data,
          fetching: false,
          location_search_error: '',
          city_data: [
            ...location_search_data,
            ...this.state.select_location_city_data,
          ],
        })
      })
      .catch((error) => {
        this.setState({
          fetching: false,
          location_search_error: error,
        })
      })
  }

  selectedLocationTaught = async (value) => {
    const city_data = [...value, ...this.state.select_location_city_data]
    const remove_duplicate = _.uniqBy(city_data, 'key')
    await this.formRef.current.setFieldsValue({
      location_taught: [],
    })
    await this.setState({
      select_location_city_data: [...remove_duplicate],
    })
    await this.setState({
      location_search_data: [],
      fetching: false,
    })
  }

  selectedCityLocationTaught = async (value) => {
    await this.formRef.current.setFieldsValue({
      location_taught: [],
    })
    await this.setState({
      location_search_data: [],
      fetching: false,
    })
  }

  onChangeLocations = (value) => {
    this.setState({
      value,
      data: [],
      fetching: false,
    })
  }

  onSelectedService = async (service) => {
    await this.formRef.current.setFieldsValue({
      service_ids: service,
    })
    this.setState({ serviceSearchData: [] })
  }

  handleDisabledChange = (disabled) => {
    this.setState({ disabled })
  }

  handleSizeChange = (e) => {
    this.setState({ size: e.target.value })
  }

  businesstype = (e) => {
    console.log('hey---', e)
    const businesstype = e.target.value === 'Business'
    if (businesstype) {
      this.setState({
        businesstypes: false,
        value: e.target.value,
      })
    } else {
      this.setState({
        businesstypes: true,
        value: e.target.value,
      })
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    })
  }

  teachingMode = (value) => {
    if (value) {
      const teachingMode = value.some((a) => a === 'studio')
      if (teachingMode) {
        this.setState({
          teachingModes: false,
        })
      } else {
        this.setState({
          teachingModes: true,
        })
      }
    }
    this.formRef.current.setFieldsValue({
      teaching_modes: value,
    })
  }

  genderMode = (value) => {
    this.formRef.current.setFieldsValue({
      gender: value,
    })
  }

  userHandle = async (event) => {
    console.log('dbcheck=====', event)
    if (this.state.profileNameValid) {
      await this.setState({
        validateStatus: 'validating',
      })
      await this.props.onUserHandleSubmit(event)
    }
  }

  profileNameValidator = (rule, value, callback) => {
    this.setState({ profileNameValid: false })
    if (typeof value === 'undefined') {
      this.setState({ validateStatus: 'error', validationStatus: '' })
      callback(this.props.t('onboardingTutor'))
      this.setState({ profileNameValue: null })
    } else if (value === '') {
      this.setState({ validateStatus: 'error', validationStatus: '' })
      callback(this.props.t('onboardingTutor'))
      this.setState({ profileNameValue: null })
    } else if (!value.match('^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$')) {
      this.setState({ validateStatus: 'error', validationStatus: '' })
      callback(this.props.t('onboardingTutor1'))
      this.setState({ profileNameValue: null })
    } else {
      this.setState({ profileNameValid: true })
      this.setState({ profileNameValue: value })
      callback()
    }
  }

  // Address
  fetchAddress = async (value) => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({
      address_search_data: [],
      address_Error: '',
      addressfetching: true,
    })

    console.log("value>>>", value)
    geocodeByAddress(value)
      .then((body) => {
        console.log("value>>>body", body)

        if (fetchId !== this.lastFetchId) {
          return
        }
        const address_search_data =
          body &&
          body.length > 0 &&
          body.map((location) => ({
            formatted_address: location.formatted_address,
            postal_code: this.fetchPostalCode(location.address_components),
          }))
        this.setState({
          address_search_data,
          address_Error: '',
          addressfetching: false,
        })
      })
      .catch((error) => {
        this.setState({ addressfetching: false, address_Error: error })
      })
  }

  // Address
  findzipcodeLocationLocality = (addressComponentsArray, levelArray) => {
    for (let i = 0; i < addressComponentsArray.length; ++i) {
      for (let j = 0; j < levelArray.length; j++) {
        if (addressComponentsArray[i].types.indexOf(levelArray[j]) > -1) {
          return {
            locality_level: addressComponentsArray[j],
            country_name:
              addressComponentsArray[j] && addressComponentsArray[j].long_name,
            short_name:
              addressComponentsArray[j] && addressComponentsArray[j].short_name,
          }
        }
      }
    }
  }

  photoUpdate = async (fileList, text) => {
    const formData = new FormData()
    formData.append('photo', fileList.file.originFileObj, fileList.file.name)
    this.setState({
      [`${text}`]: fileList.fileList,
    })
  }

  onRemovePhoto = (fileList, text) => { }

  phoneNUmberClick = (value) => {
    if (value === 'public') {
      this.mypublicRefSlider.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } else if (value === 'upload') {
      this.myuploadRefSlider.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } else if (value === 'phone') {
      this.myLastRefSlider.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    }
  }

  searchCountry = async (value) => {
    try {
      const country_search_data = []
      this.setState({ country_search_data: [], countryfetching: true })
      this.state.countryList.forEach((country) => {
        if (
          value &&
          country &&
          country.label.toLowerCase().indexOf(value.toLowerCase()) > -1
        ) {
          country_search_data.push(country)
        }
        this.setState({
          country_search_data,
          countryfetching: false,
        })
      })
    } catch (error) {
      this.setState({
        countryfetching: false,
      })
      console.log(
        'error at tutor-onboarding.js in  searchCountry function',
        error
      )
    }
  }

  fetchCountryAddress = async (value) => {
    this.lastFetchId += 1
    const fetchId = this.lastFetchId
    this.setState({ country_search_data: [], countryfetching: true })
    geocodeByAddress(value)
      .then((body) => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        const locationText = this.findzipcodeLocationLocality(
          body[0].address_components,
          ['country']
        ).country_name
        const locationShortText = this.findzipcodeLocationLocality(
          body[0].address_components,
          ['country']
        ).short_name
        const country_search_data =
          body &&
          body.length > 0 &&
          body.map((location) => ({
            formatted_address: locationText,
            postal_code: this.fetchPostalCode(location.address_components),
            short_name: locationShortText,
          }))
        this.setState({ country_search_data, countryfetching: false })
      })
      .catch((error) => {
        this.setState({ countryfetching: false })
      })
  }

  selectedAddress = async (value) => {
    if (
      this.state.address_search_data &&
      this.state.address_search_data.length > 0
    ) {
      await this.setState({
        isLocation: false,
        address: this.state.address_search_data[0].formatted_address,
        location_search_data: [],
        zip_code: this.state.address_search_data[0].postal_code,
        fetching: false,
      })
    }
  }

  selectedCountry = async (value) => {
    await this.setState({
      isLocation: false,
      address: value.key,
      location_search_data: [],
      country_code: value.item.props.postal_code,
      fetching: false,
    })
    await this.formRef.current.setFieldsValue({
      zip_code: value.item.props.postal_code,
    })
  }

  fetchPostalCode = (address_components) => {
    try {
      let postal_code
      address_components.forEach((value) => {
        value.types.forEach((type) => {
          if (type === 'postal_code') {
            postal_code = value.long_name
          }
        })
      })
      return postal_code
    } catch (error) {
      console.log(
        'error at tutor-onboarding.js in  fetchPostalCode function',
        error
      )
      return ''
    }
  }

  getLocation(address = this.state.address) {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {

        console.log("address>>>>", address)

        this.setState({
          location: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
        })
      })
      .catch((error) => console.error('Error', error))
  }

  onFinish = (credentials) => {
    try {
      const tutorProfileUpdate = { ...credentials }

      if (
        this.state.select_location_city_data &&
        this.state.select_location_city_data.length > 0
      ) {
        tutorProfileUpdate.location_taught = [
          ...this.state.select_location_city_data,
        ]
      }
      const location_taught = []
      if (tutorProfileUpdate.location_taught) {
        tutorProfileUpdate.location_taught.map((element, index) => {
          const geo_points = element.key.split(',')
          location_taught.push({
            text: element.label,
            geopoints: { lat: geo_points[0], lng: geo_points[1] },
          })
          return element
        })
      }
      let service_ids = []
      if (tutorProfileUpdate.service_ids) {
        service_ids = tutorProfileUpdate.service_ids.map(
          (service) => service.key
        )
      }
      // this.props.onImageupload(data)
      const profiledata = {
        firstname: tutorProfileUpdate.firstname,
        lastname: tutorProfileUpdate.lastname,
        businessname: tutorProfileUpdate.businessname,
        businesshours: this.state.businessHours,
        mobile_number: tutorProfileUpdate.mobile_number,
        address: tutorProfileUpdate.address,
        zip_code: tutorProfileUpdate.zip_code,
        create_profile_as: tutorProfileUpdate.create_profile_as,
        years_of_experience: tutorProfileUpdate.years_of_experience,
        teaching_modes: tutorProfileUpdate.teaching_modes,
        service_ids,
        location: this.state.location,
        location_text: tutorProfileUpdate.address,
        location_taught,
        bio: tutorProfileUpdate.description,
        country: tutorProfileUpdate.country,
        //country_code: this.state.country_code,
        country_code:this.state.country_shot_code,
        gender: tutorProfileUpdate.gender,
        no_of_students_taught: tutorProfileUpdate.no_of_students_taught,
      }
      if (!_.isEmpty(this.state.profile_photo_s3_url)) {
        this.setState({ set_profile_photo_s3_url_processing: false })
        profiledata.photo = this.state.profile_photo_s3_url
      }
      this.props.onStepThirdSubmit(profiledata)
    } catch (error) {
      console.log('error at tutor-onboarding.js in  onFinish function', error)
    }
  }

  onReset = () => {
    this.formRef.current.resetFields()
    this.setState({
      validationStatus: '',
      validateStatus: '',
      profileNameValue: '',
      photo: [],
      businessHours: [],
      select_location_city_data: [],
    })
  }

  closeCityData = (removedTag) => {
    const select_location_city_data =
      this.state.select_location_city_data.filter(
        (tag) => tag.value !== removedTag
      )
    this.setState({ select_location_city_data })
  }

  scroll_profile = 1

  handleScroll = (data) => {
    if (data && data.scrollTop === 0) {
      this.setState({
        scroll_profile: 1,
      })
    } else if (data && data.scrollTop > 118 && data && data.scrollTop < 328) {
      this.setState({
        scroll_profile: 2,
      })
    } else if (data && data.scrollTop > 548 && data && data.scrollTop < 1004) {
      this.setState({
        scroll_profile: 3,
      })
    }
    this.scroll_profile++
  }

  handleDayChange = (value) => {
    this.setState({ selectedDay: value })
  }

  handleStartTimeChange = (time) => {
    this.setState({ selectedStartTime: time })
  }

  handleEndTimeChange = (time) => {
    this.setState({ selectedEndTime: time })
  }

  handleAddHours = () => {
    const { selectedDay, selectedStartTime, selectedEndTime, businessHours } =
      this.state
    const startTime = dayjs(selectedStartTime).format('HH:mm')
    const endTime = dayjs(selectedEndTime).format('HH:mm')
    if (startTime >= endTime) {
      alert(this.props.t('timeValidation1'))
      return
    }

    // Check if the selected day already exists in businessHours
    if (businessHours && businessHours.length > 0) {
      const existingHourIndex = businessHours.findIndex(
        (hour) => hour.day === selectedDay
      )

      if (existingHourIndex !== -1) {
        // If the day already exists, update the existing entry
        const updatedHours = [...businessHours]
        updatedHours[existingHourIndex] = {
          day: selectedDay,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
        }
        updatedHours.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
        this.setState({
          businessHours: updatedHours,
          selectedDay: '',
          selectedStartTime: null,
          selectedEndTime: null,
          EditBusiness: false,
        })
      } else {
        // If the day doesn't exist, add a new entry
        const newHour = {
          day: selectedDay,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
        }
        const newHourArray = [...businessHours, newHour]
        newHourArray.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
        this.setState({
          businessHours: [...businessHours, newHour],
          selectedDay: '',
          selectedStartTime: null,
          selectedEndTime: null,
          EditBusiness: false,
        })
      }
    } else {
      // If the day doesn't exist, add a new entry
      const newHour = {
        day: selectedDay,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
      }
      const newHourArray = [newHour]
      newHourArray.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
      this.setState({
        businessHours: [newHour],
        selectedDay: '',
        selectedStartTime: null,
        selectedEndTime: null,
      })
    }
  }

  handleEditHours = (index) => {
    const { businessHours } = this.state
    const selectedHour = businessHours[index]
    this.setState({
      selectedDay: selectedHour.day,
      selectedStartTime: selectedHour.startTime,
      selectedEndTime: selectedHour.endTime,
      EditBusiness: true,
    })
  }

  handleDeleteHours = (index) => {
    const { businessHours } = this.state
    const updatedHours = [...businessHours]
    updatedHours.splice(index, 1)
    this.setState({ businessHours: updatedHours })
  }

  handlePhoneNumberChange = (e) => {
    const { value } = e.target
    const numericValue = value.replace(/\D/g, '') // Remove non-numeric characters

    // Update the input value
    e.target.value = numericValue
  }

  setProviderProfileImage = (data) => {
    this.setState({
      setProviderProfileImage: data,
    })
  }

  beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    if (!isJpgOrPng) {
      this.setState({
        uploadError: 'You can only upload JPG/PNG file!',
        fileIsValid: false,
      })
      this.setProviderProfileImage({
        photo: [],
        deleted: false,
        loading: false,
      })
      return false
    }
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M) {
      this.setState({
        uploadError: 'Image must be smaller than 10MB!',
        fileIsValid: false,
      })
      this.setProviderProfileImage({
        photo: [],
        deleted: false,
        loading: false,
      })
      return false
    }
    this.setState({ uploadError: null, fileIsValid: true })
    return isJpgOrPng && isLt10M
  }

  render() {

    console.log("this.state.country_code>>>", this.state.country_code)

    if (
      this.state.isSubmitedSuccess &&
      this.props.tutorExtrainfoSuccess.details
    ) {
      return <Redirect to={routeNames.tutorDashboard} push />
    }
    if (
      this.props.tutorExtrainfoSuccess &&
      this.props.tutorExtrainfoSuccess.details &&
      this.props.tutorExtrainfoSuccess.details.update_progress !== 0
    ) {
      return <Redirect to={routeNames.tutorDashboard} push />
    }
    // const { disabled } = this.state;
    const {
      fetching,
      location_search_data,
      setProviderProfileImage,
      set_profile_photo_s3_url_processing,
      scroll_profile,
      address_search_data,
      select_location_city_data,
      addressfetching,
      countryfetching,
      country_search_data,
    } = this.state
    // const { size } = this.state;
    const teaching_mode_options = [
      { label: this.props.t('In-person'), value: 'studio' },
      { label: this.props.t('online'), value: 'online' },
      { label: this.props.t('driveToStu'), value: 'drive' },
    ]

    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    }
    const { value } = this.state

    const uploadButton = (
      <div>
        {_.get(setProviderProfileImage, 'loading', false) ? (
          <Spin />
        ) : (
          <AiOutlinePlus />
        )}
        <div className="ant-upload-text">{this.props.t('upImage')}</div>
      </div>
    )

    const {
      address_Error,
      location_search_error,
      businessHours,
      selectedDay,
      selectedStartTime,
      selectedEndTime,
    } = this.state

    return (
      <div className="tutor-onboarding-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <div className="tutor-onboarding-left">
              <div className="tutor-header-group">
                <div className="title">{this.props.t('onboardingTutor2')}</div>
                <div className="text" />
              </div>
              <div className="tutor-wizard">
                <ul>
                  <li className={scroll_profile === 1 ? 'active' : ''}>
                    <span className="link">
                      <span className="step-text">
                        {this.props.t('onboardingTutor3')}
                      </span>
                      <span className="step">1</span>
                    </span>
                  </li>
                  <li className={scroll_profile === 2 ? 'active' : ''}>
                    <span className="link">
                      <span className="step-text">
                        {this.props.t('onboardingTutor4')}
                      </span>
                      <span className="step">2</span>
                    </span>
                  </li>
                  <li className={scroll_profile === 3 ? 'active' : ''}>
                    <span className="link">
                      <span className="step-text">
                        {this.props.t('onboardingTutor5')}
                      </span>
                      <span className="step">3</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div className="tutor-onboarding">
                <img
                  className="img-fluid"
                  src={require('../../../assets/images/tutor-onboarding-bg.png')}
                  alt="img"
                />
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <div className="tutor-onboarding-right">
              <Scrollbars
                onScrollFrame={this.handleScroll}
                className="tutor-onboarding-scroll-height hidden-scroll-x"
                renderTrackHorizontal={(props) => (
                  <div
                    {...props}
                    className="track-horizontal"
                    style={{ display: 'none' }}
                  />
                )}
                renderThumbHorizontal={(props) => (
                  <div
                    {...props}
                    className="thumb-horizontal"
                    style={{ display: 'none' }}
                  />
                )}
              >
                <Form
                  scrollToFirstError
                  ref={this.formRef}
                  onFinish={(e) => this.onFinish(e)}
                >
                  {/* Business Info Start */}
                  <div className="business-information ">
                    <Form.Item
                      name="create_profile_as"
                      rules={[
                        {
                          required: true,
                          message: this.props.t('onboardingTutor6II'),
                        },
                      ]}
                    >
                      <div className="full-width mb-2">
                        <Tooltip placement="right" title={ima}>
                          <label
                            className="filter-group-title m-0"
                            style={{ width: 'auto' }}
                          >
                            I’m an
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Radio.Group
                              className="class-type-radio-group"
                              onChange={this.businesstype}
                              value={value}
                            >
                              <Radio style={radioStyle} value="Individual">
                                { }
                                Individual
                              </Radio>
                              <Radio style={radioStyle} value="Business">
                                Business
                              </Radio>
                            </Radio.Group>
                          </div>
                        </Tooltip>
                      </div>
                    </Form.Item>

                    <div className="full-width ">
                      <Tooltip placement="right" title={modeofteaching}>
                        <label
                          className="filter-group-title m-0"
                          style={{ width: 'auto' }}
                        >
                          {this.props.t('onboardingTutor7')}
                          <span className="label-star">*</span>
                        </label>
                      </Tooltip>
                      <div className="full-width" ref={this.mypublicRefSlider}>
                        <Form.Item
                          name="teaching_modes"
                          rules={[
                            {
                              required: true,
                              message: this.props.t('onboardingTutor8'),
                            },
                          ]}
                        >
                          <CheckboxGroup>
                            <CheckboxGroup
                              className="teaching_modes_checkbox full-width mb-1"
                              options={teaching_mode_options}
                              onChange={(checkedValue) =>
                                this.teachingMode(checkedValue)
                              }
                            />
                          </CheckboxGroup>
                        </Form.Item>
                      </div>
                    </div>

                    {this.state.businesstypes === true ? (
                      <div className="full-width ">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label className="filter-group-title m-0">
                              {this.props.t('firstname')}
                              <span className="label-star">*</span>
                            </label>
                            <div className="full-width">
                              <Form.Item
                                name="firstname"
                                rules={[
                                  {
                                    required: true,
                                    message: this.props.t('firstname1'),
                                  },
                                  {
                                    validator: (rule, value, callback) => {
                                      if (
                                        value &&
                                        !value.match(/^[a-zA-Z0-9 ]*$/)
                                      ) {
                                        callback(this.props.t('firstname2'))
                                      } else if (value && value.length > 20) {
                                        callback(this.props.t('firstname3'))
                                      } else {
                                        callback()
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input maxLength={21} placeholder="" />
                              </Form.Item>
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label className="filter-group-title m-0">
                              {this.props.t('lastname')}
                              <span className="label-star">*</span>
                            </label>
                            <div className="full-width">
                              <Form.Item
                                name="lastname"
                                rules={[
                                  {
                                    required: true,
                                    message: this.props.t('lastname1'),
                                  },
                                  {
                                    validator: (rule, value, callback) => {
                                      if (
                                        value &&
                                        !value.match(/^[a-zA-Z0-9 ]*$/)
                                      ) {
                                        callback(this.props.t('lastname2'))
                                      } else if (value && value.length > 20) {
                                        callback(this.props.t('lastname3'))
                                      } else {
                                        callback()
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input maxLength={21} placeholder="" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="full-width mb-2">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <Tooltip placement="top" title={business}>
                              <label className="filter-group-title m-0">
                                {this.props.t('businame')}
                                <span className="label-star">*</span>
                              </label>
                            </Tooltip>
                            <div className="full-width">
                              <Form.Item
                                name="businessname"
                                rules={[
                                  {
                                    required: true,
                                    message: this.props.t('businame1'),
                                  },
                                  {
                                    validator: (rule, value, callback) => {
                                      if (
                                        value &&
                                        !value.match(/^[a-zA-Z0-9 ]*$/)
                                      ) {
                                        callback(this.props.t('businame2'))
                                      } else if (value && value.length > 80) {
                                        callback(this.props.t('businame3'))
                                      } else {
                                        callback()
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input maxLength={81} placeholder="" />
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="full-width mb-2">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <Tooltip placement="right" title={country}>
                            <label
                              className="filter-group-title m-0"
                              style={{ width: 'auto' }}
                            >
                              {this.props.t('country')}{' '}
                              <span className="label-star">*</span>
                            </label>
                          </Tooltip>
                          <div className="full-width">
                            <Form.Item
                              name="country"
                              onClick={() => this.phoneNUmberClick('public')}
                              rules={[
                                {
                                  required: true,
                                  message: this.props.t('country1'),
                                },
                              ]}
                            >
                              <Select
                                showSearch
                                notFoundContent={
                                  countryfetching ? <Spin size="small" /> : null
                                }
                                onInputKeyDown={this.onInputChange}
                                filterOption={false}
                                onSearch={this.searchCountry}
                                style={{ width: '100%' }}
                                suffixIcon={<AiOutlineSearch />}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(value) => {
                                  this.getcountry(value)
                                }}
                              >
                                {country_search_data.map((d) => (
                                  <Option key={d.label} value={d.value}>
                                    {d.label}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                          <label className="filter-group-title m-0">
                            {this.props.t('zipcode')}
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Form.Item
                              name="zip_code"
                              rules={[
                                {
                                  required: true,
                                  message: this.props.t('zipcode1'),
                                },
                                {
                                  validator: (rule, value, callback) => {
                                    if (value && !value.match('^[0-9]*$')) {
                                      callback(this.props.t('zipcode2'))
                                    } else if (value && value.length < 3) {
                                      callback(this.props.t('zipcode3'))
                                    } else {
                                      callback()
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input maxLength={6} placeholder="" />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="full-width mb-2" style={{ width: '48%' }}>
                      <div
                        className="filter-group-title m-0"
                        ref={this.myLastRefSlider}
                      >
                        <Tooltip placement="right" title={address}>
                          <label
                            className="filter-group-title m-0"
                            style={{ width: 'auto' }}
                          >
                            {this.props.t('address')}
                            <span className="label-star">*</span>
                            {address_Error && !addressfetching && (
                              <span className="profile-name unavailable ">
                                {this.props.t('onboardingTutor9')}
                              </span>
                            )}
                          </label>
                        </Tooltip>
                      </div>
                      <div className="full-width ">
                        <Form.Item
                          style={{ marginRight: '-1px' }}
                          name="address"
                          rules={[
                            {
                              required: true,
                              message: this.props.t('onboardingTutor10'),
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            notFoundContent={
                              addressfetching ? <Spin size="small" /> : null
                            }
                            defaultActiveFirstOption={false}
                            onInputKeyDown={this.onInputChange}
                            filterOption={false}
                            onSearch={(e) => (e ? this.fetchAddress(e) : '')}
                            onChange={(value) => {
                              this.getLocation(value)
                            }}
                            onSelect={this.selectedAddress}
                            style={{ width: '100%' }}
                            suffixIcon={<AiOutlineSearch />}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {address_search_data.map((d) => (
                              <Option
                                onChange={this.selectedAddress}
                                postal_code={d.postal_code}
                                key={d.formatted_address}
                              >
                                {d.formatted_address}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div className="full-width mb-3">
                      <div className="row">
                        {/* <div className="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3">
                          <label className="filter-group-title m-0 text-nowrap">
                            {this.props.t('countryCode')}{' '}
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Form.Item
                              name="country_code"
                              rules={[
                                {
                                  required: true,
                                  message: this.props.t('countryCode1'),
                                },
                              ]}
                            >
                              <Input readOnly maxLength={6} placeholder="" />
                            </Form.Item>
                          </div>
                        </div> */}

                        <div className="col-7 col-sm-7 col-md-7 col-lg-6 col-xl-6">
                          <label className="filter-group-title m-0">
                            {this.props.t('phNo')}
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Form.Item
                              name="mobile_number"
                              onClick={() => this.phoneNUmberClick('phone')}
                              rules={[
                                {
                                  pattern: /^[\d()+\- ]+$/, // Allow digits, parentheses,  hyphen,plus sign, and space
                                  message: this.props.t('phNo1'),
                                },
                                {
                                  min: 8,
                                  message: this.props.t('phNo2'),
                                },
                                {
                                  max: 15,
                                  message: this.props.t('phNo3'),
                                },
                                {
                                  required: true,
                                  message: this.props.t('phNo4'),
                                },
                              ]}
                            >
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0">
                                <div className="full-width">
                                  <Input
                                    addonBefore={this.state.country_code}
                                    placeholder=""
                                    maxLength={14}
                                    onChange={this.handlePhoneNumberChange}
                                  />
                                </div>
                              </div>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    {this.state.businesstypes === true ? (
                      <div className="full-width mb-3">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="full-width">
                              <label className="filter-group-title m-0">
                                {this.props.t('gender')}
                                <span className="label-star">*</span>
                              </label>
                              <div className="full-width">
                                <Form.Item
                                  name="gender"
                                  rules={[
                                    {
                                      required: true,
                                      message: this.props.t('gender1'),
                                    },
                                  ]}
                                >
                                  <Radio.Group className="class-type-radio-group">
                                    <Radio style={radioStyle} value="0">
                                      Male
                                    </Radio>
                                    <Radio style={radioStyle} value="1">
                                      Female
                                    </Radio>
                                    <Radio style={radioStyle} value="2">
                                      Prefer not to answer
                                    </Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {/* Contant Info End */}

                    {/* Profile Info Start */}
                    <div
                      className="full-width col-7 mb-2 p-0"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Tooltip placement="right" title={typesofclasses}>
                        <div
                          className="filter-group-title m-0"
                          style={{ width: 'auto' }}
                        >
                          {this.props.t('baseProfile')}
                          <span className="label-star">*</span>
                        </div>
                      </Tooltip>
                      <div className="title-exp">
                        {this.props.t('baseProfile1')}
                      </div>
                      <div className="full-width position-relative">
                        {/* <span className="search-icon" >
													<AiOutlineSearch />
												</span> */}
                        <Form.Item
                          name="service_ids"
                          rules={[
                            {
                              required: true,
                              message: this.props.t('onboardingTutor11'),
                            },
                          ]}
                        >
                          <Select
                            labelInValue
                            mode="multiple"
                            style={{ width: '100%' }}
                            className="global-search"
                            placeholder={this.props.t(
                              'Chess, Java, Carnatic vocal'
                            )}
                            suffixIcon={<AiOutlineSearch />}
                            filterOption={false}
                            onSearch={this.serviceSearch}
                            onChange={this.onSelectedService}
                            notFoundContent={
                              this.props.service.searchLoading ? (
                                <Spin size="small" />
                              ) : null
                            }
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {this.state.serviceSearchData.map((d) => (
                              <Option key={d.value}>{d.text}</Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    {this.state.teachingModes === false ? (
                      <div className="full-width mb-2">
                        <Tooltip placement="right" title={listofcities}>
                          <div
                            className="filter-group-title m-0"
                            style={{ width: 'auto' }}
                          >
                            {this.props.t('baseProfile3')}
                            <span className="label-star">*</span>
                            {location_search_error && !fetching && (
                              <span className="profile-name unavailable ">
                                {this.props.t('onboardingTutor9')}
                              </span>
                            )}
                          </div>
                        </Tooltip>
                        <div className="title-exp">
                          {this.props.t('baseProfile4')}
                        </div>
                        <div className="full-width position-relative">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 pr-0">
                              <div className="full-width position-relative">
                                {/* <span className="search-icon" >
																<AiOutlineSearch />
															</span> */}
                                <Form.Item
                                  name="location_taught"
                                  rules={[
                                    {
                                      required:
                                        select_location_city_data.length > 0
                                          ? false
                                          : !this.state.teachingModes,
                                      message: this.props.t('location1'),
                                    },
                                  ]}
                                >
                                  <Select
                                    className="w-100"
                                    disabled={this.state.teachingModes}
                                    mode="multiple"
                                    labelInValue
                                    suffixIcon={<AiOutlineSearch />}
                                    placeholder="Search a Locations"
                                    notFoundContent={
                                      fetching ? <Spin size="small" /> : null
                                    }
                                    filterOption={false}
                                    onSearch={(e) =>
                                      e ? this.fetchLocationTaught(e) : ''
                                    }
                                    onChange={(e) =>
                                      this.selectedLocationTaught(e)
                                    }
                                    style={{ width: '100%' }}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    onSelect={this.selectedCityLocationTaught}
                                  >
                                    {location_search_data.map((d) => (
                                      <Option key={d.value}>{d.text}</Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 mt-2 location-tag">
                              {select_location_city_data.map((d) => (
                                <Tag
                                  closable
                                  key={d.value}
                                  onClose={() => this.closeCityData(d.value)}
                                >
                                  {d.label}
                                </Tag>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}

                    <div className="full-width ">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <Tooltip placement="right" title={businesshours}>
                            <label
                              className="filter-group-title m-0"
                              style={{ width: 'auto' }}
                            >
                              {this.props.t('baseProfile10')}
                            </label>
                          </Tooltip>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                          <Form.Item
                            name="businesshours"
                            onClick={() => this.phoneNUmberClick('city')}
                            rules={[
                              {
                                required: false,
                                message: this.props.t('baseProfile11'),
                              },
                            ]}
                          >
                            <div className="row mb-3">
                              <div className="col-4">
                                <Select
                                  value={selectedDay}
                                  onChange={this.handleDayChange}
                                  placeholder="Select Day"
                                >
                                  <Option value="Monday">Mon</Option>
                                  <Option value="Tuesday">Tue</Option>
                                  <Option value="Wednesday">Wed</Option>
                                  <Option value="Thursday">Thur</Option>
                                  <Option value="Friday">Fri</Option>
                                  <Option value="Saturday">Sat</Option>
                                  <Option value="Sunday">Sun</Option>
                                </Select>
                              </div>
                              <div className="col-4">
                                <TimePicker
                                  value={selectedStartTime}
                                  onSelect={this.handleStartTimeChange}
                                  onChange={(time, timeString) => {
                                    if (time === null) {
                                      this.handleStartTimeChange(null)
                                    } else {
                                      this.handleStartTimeChange(time)
                                    }
                                  }}
                                  format="h:mm A"
                                  minuteStep={15}
                                  allowClear
                                  use12Hours
                                  // popupClassName="noFooterTimePick"
                                  placeholder="Start Time"
                                />
                              </div>
                              <div className="col-4">
                                <TimePicker
                                  value={selectedEndTime}
                                  use12Hours
                                  allowClear
                                  // popupClassName="noFooterTimePick"
                                  onSelect={this.handleEndTimeChange}
                                  onChange={(time, timeString) => {
                                    if (time === null) {
                                      this.handleEndTimeChange(null)
                                    } else {
                                      this.handleEndTimeChange(time)
                                    }
                                  }}
                                  format="h:mm A"
                                  minuteStep={15}
                                  placeholder="End Time"
                                />
                              </div>
                            </div>
                            <Button
                              type="primary"
                              onClick={this.handleAddHours}
                              disabled={
                                !selectedDay ||
                                !selectedStartTime ||
                                !selectedEndTime
                              }
                            >
                              {this.state.EditBusiness
                                ? this.props.t('uphr')
                                : this.props.t('addhr')}
                            </Button>
                            <div className="mt-3">
                              {businessHours.map((hour, index) => (
                                <div key={index} className="row">
                                  <div className="col-3">{hour.day}</div>
                                  <div className="col-3">
                                    {dayjs(hour.startTime).format('h:mm A')}
                                  </div>
                                  <div className="col-3">
                                    {dayjs(hour.endTime).format('h:mm A')}
                                  </div>
                                  <div className="col-3 pb-1">
                                    <AiTwotoneDelete
                                      className="faq-buttons-delete"
                                      color="#ff5900"
                                      onClick={() =>
                                        this.handleDeleteHours(index)
                                      }
                                    >
                                      {this.props.t('delete')}
                                    </AiTwotoneDelete>
                                    <AiTwotoneEdit
                                      className="faq-buttons-edit"
                                      color="#52c41a"
                                      onClick={() =>
                                        this.handleEditHours(index)
                                      }
                                    >
                                      {this.props.t('edit')}
                                    </AiTwotoneEdit>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="full-width mb-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mt-3">
                          <Tooltip placement="right" title={studentstaught}>
                            <label
                              className="filter-group-title m-0"
                              style={{ width: 'auto' }}
                            >
                              {this.props.t('baseProfile17')}
                              {/* <span className="label-star">*</span> */}
                            </label>
                          </Tooltip>
                          <div className="full-width">
                            <Form.Item
                              name="no_of_students_taught"
                              rules={[
                                {
                                  required: false,
                                  /* message: this.props.t(
                                  "baseProfile18"
                                ) */
                                },
                                {
                                  validator: (rule, value, callback) => {
                                    if (value < 0) {
                                      callback(this.props.t('baseProfile19'))
                                    } else if (value >= 10000) {
                                      callback(this.props.t('baseProfile20'))
                                    } else {
                                      callback()
                                    }
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                className="text-center"
                                style={{ width: `${80}px` }}
                                parser={(value) => value.replace(/[^0-9]/g, '')}
                              />
                              {/* <Input className="text-center" style={{ width: 80 + 'px' }} /> */}
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 mt-3">
                          <Tooltip placement="right" title={yearsofteaching}>
                            <label
                              className="filter-group-title m-0"
                              style={{ width: 'auto' }}
                            >
                              {this.props.t('baseProfile16')}
                              {/* <span className="label-star">*</span> */}
                            </label>
                          </Tooltip>
                          <div className="full-width">
                            <Form.Item
                              name="years_of_experience"
                              rules={[
                                {
                                  validator: (rule, value, callback) => {
                                    if (value < 0) {
                                      callback(this.props.t('baseProfile22'))
                                    } else if (value >= 100) {
                                      callback(this.props.t('baseProfile23'))
                                    } else {
                                      callback()
                                    }
                                  },
                                },
                              ]}
                            >
                              <InputNumber
                                className="text-center"
                                style={{ width: `${80}px` }}
                                parser={(value) => value.replace(/[^0-9]/g, '')}
                              />
                              {/* <Input className="text-center" style={{ width: 80 + 'px' }} /> */}
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contact-information  ">
                    <div className="full-width mb-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <div className="filter-group-title m-0">
                            <Tooltip placement="right" title={yourpublic}>
                              <label>
                                {this.props.t('baseProfile5')}{' '}
                                <span className="label-star">*</span>
                              </label>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          {this.state.validationStatus === 'success' && (
                            <span
                              className="profile-name "
                              style={{ alignItems: 'center' }}
                            >
                              <AiFillCheckCircle
                                style={{ fontSize: 15, marginRight: 5 }}
                                className="profile-icon"
                              />
                              {this.props.t('baseProfile6')}
                            </span>
                          )}
                          {this.state.validationStatus === 'error' && (
                            <span
                              className="profile-name unavailable "
                              style={{ alignItems: 'center' }}
                            >
                              <AiFillInfoCircle
                                style={{ fontSize: 15, marginRight: 5 }}
                              />
                              {this.props.t('baseProfile7')}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="full-width">
                        <div className="profile-link">
                          <Form.Item
                            name="user_handle"
                            hasFeedback
                            rules={[
                              {
                                validator: this.profileNameValidator,
                              },
                            ]}
                            validateStatus={this.state.validateStatus}
                          >
                            <Input
                              placeholder="nurtem-inc"
                              addonBefore="https://class.nurtem.com/profile/"
                              onChange={({ target: { value } }) =>
                                this.userHandle(value)
                              }
                            />
                          </Form.Item>
                          {this.state.profileNameValue && (
                            <span
                              className="profile-name-value mt-2"
                              style={{ alignItems: 'center' }}
                            >
                              <AiFillInfoCircle
                                style={{ fontSize: 15, marginRight: 5 }}
                              />{' '}
                              Your Website link is -&gt;
                              https://class.nurtem.com/profile/
                              {this.state.profileNameValue}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="profile-information"
                    ref={this.myuploadRefSlider}
                  >
                    <div className="full-width mb-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <label className="filter-group-title m-0">
                            {this.props.t('upPhoto')}
                          </label>
                          <div className="full-width">
                            <Form.Item
                              onClick={() => this.phoneNUmberClick('upload')}
                              name="photo"
                              rules={[
                                {
                                  required: false,
                                  message: this.props.t(
                                    'Please select the photo'
                                  ),
                                },
                              ]}
                              validateStatus={
                                this.state.uploadError ? 'error' : null
                              }
                              help={
                                this.state.uploadError
                                  ? this.state.uploadError
                                  : null
                              }
                            >
                              <Upload
                                disabled={_.get(
                                  setProviderProfileImage,
                                  'loading',
                                  false
                                )}
                                name="avatar"
                                listType="picture-card"
                                className="avatar-uploader"
                                accept="image/*"
                                onPreview={(file) => { }}
                                customRequest={dummyRequest}
                                beforeUpload={this.beforeUpload}
                                fileList={_.get(
                                  setProviderProfileImage,
                                  'photo',
                                  []
                                )}
                                onRemove={(file) => {
                                  this.setProviderProfileImage({
                                    photo: [],
                                    deleted: true,
                                  })
                                  // this.props.onDeleteUploads({ url: _.get(setProviderProfileImage, 'photo[0].url', []) })
                                }}
                                maxCount={1}
                                onChange={(fileList) => {
                                  const flist = _.get(fileList, 'fileList', [])

                                  if (
                                    !_.isEmpty(flist) &&
                                    flist.length > 0 &&
                                    this.state.fileIsValid
                                  ) {
                                    this.setState({
                                      set_profile_photo_s3_url_processing: true,
                                    })
                                    const file = _.get(
                                      flist,
                                      '[0].originFileObj',
                                      {}
                                    )
                                    let file_name = _.get(
                                      file,
                                      ['name'],
                                      'tutor_profile.png'
                                    )
                                    const params = {
                                      cat: 'profile',
                                      cat_id: '1',
                                      subcat: 'tutor',
                                      access: 'public',
                                    }

                                    this.setProviderProfileImage({
                                      photo: [],
                                      deleted: false,
                                      loading: true,
                                    })

                                    file_name = file_name.replace(
                                      /[^a-zA-Z0-9\-\.]/g,
                                      '-'
                                    )

                                    axios({
                                      method: 'post',
                                      url: '/s3/secure-url',
                                      withCredentials: true,
                                      data: { ...params, file_name },
                                      headers: {
                                        'Content-Type': 'application/json',
                                      },
                                    })
                                      .then((resp) => {
                                        if (resp.status === 200) {
                                          let url = resp.data
                                          url = url.trim()

                                          // file upload directly to s3
                                          axios1({
                                            method: 'put',
                                            url,
                                            data: file,
                                            headers: {
                                              'Content-Type': file.type,
                                            },
                                          })
                                            .then((resp) => {
                                              if (resp.status === 200) {
                                                const url = _.chain(resp)
                                                  .get('config.url')
                                                  .split('?')
                                                  .first()
                                                  .value()
                                                this.setState({
                                                  profile_photo_s3_url: url,
                                                })
                                                this.setState({
                                                  set_profile_photo_s3_url_processing: false,
                                                })
                                                this.setProviderProfileImage({
                                                  photo: [
                                                    {
                                                      uid: '1',
                                                      name: 'profile',
                                                      status: 'done',
                                                      url,
                                                    },
                                                  ],
                                                  deleted: false,
                                                  loading: false,
                                                })
                                              }
                                            })
                                            .catch((error) => {
                                              console.error(
                                                'Error >> uploading to S3:',
                                                error
                                              )
                                              this.setProviderProfileImage({
                                                photo: [],
                                                deleted: false,
                                                loading: false,
                                              })
                                            })
                                        }
                                      })
                                      .catch((error) => {
                                        console.error(
                                          'Error >>1 uploading to S3:',
                                          error
                                        )
                                        this.setProviderProfileImage({
                                          photo: [],
                                          deleted: false,
                                          loading: false,
                                        })
                                      })
                                  }
                                }}
                              >
                                {setProviderProfileImage.length > 0
                                  ? null
                                  : uploadButton}
                              </Upload>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="full-width mb-3">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <Tooltip placement="right" title={tagline}>
                            <label
                              className="filter-group-title m-0"
                              style={{ width: 'auto' }}
                            >
                              {this.props.t('onboardingTutor12')}{' '}
                              <span className="label-star">*</span>
                            </label>
                          </Tooltip>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <Form.Item
                            name="description"
                            rules={[
                              {
                                required: true,
                                message: this.props.t('onboardingTutor13'),
                              },
                            ]}
                          >
                            <TextArea
                              onChange={(e) => {
                                console.log(
                                  'char',
                                  this.setState({
                                    description_value: e.target.value.length,
                                  })
                                )
                              }}
                              rows={4}
                              maxLength={130}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div
                            style={{
                              fontSize: '12px',
                              color: '#000',
                              opacity: 0.6,
                              marginTop: '3px',
                            }}
                          >
                            {this.state.description_value}
                            /130
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="full-width button-group-section mb-3">
                      <div className="row justify-content-between">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                          <Form.Item>
                            <Button
                              disabled={_.get(
                                setProviderProfileImage,
                                'loading',
                                false
                              )}
                              loading={this.props.loading}
                              type="primary"
                              htmlType="submit"
                              className="btn-filter"
                            >
                              {this.props.t('submit')}
                            </Button>
                          </Form.Item>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                          <Form.Item>
                            <Button
                              htmlType="button"
                              disabled={
                                this.props.loading ||
                                set_profile_photo_s3_url_processing
                              }
                              onClick={this.onReset}
                              className="btn-reset"
                            >
                              {this.props.t('reset')}
                            </Button>
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
                {/* Profile Info End */}
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  service: state.services,
  userHandle: state.tutorProfile.userHandle,
  tutorExtrainfoSuccess: state.tutorProfile.tutorProfileinfo,
})

const mapDispatchToProps = (dispatch) => ({
  onStepThirdSubmit: (data) => dispatch(actions.onStepThirdSubmit(data)),
  onGetServices: (requestParams) =>
    dispatch(servicesActions.onServices(requestParams)),
  onUserHandleSubmit: (data) => dispatch(actions.onUserHandleSubmit(data)),
  onImageupload: (data) => dispatch(actions.onImageupload(data)),
  onsiderbarLogo: (requestParams) =>
    dispatch(servicesActions.sidebarLogo(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorOnboarding))
