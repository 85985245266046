import React from 'react'
import _ from 'lodash'

export const mainComponentLoader = () => (
  <div className="mt-2 mb-3 pt-3">
    <div className="ph-item">
      <div className="ph-col-4">
        <div className="ph-picture" style={{ height: `${190}px` }} />
      </div>
      <div className="ph-col-8">
        <div className="ph-row">
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty" />
          <div className="ph-col-2 big" />
        </div>
        <div className="ph-row">
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
        </div>
        <div className="ph-row mt-3">
          <div className="ph-col-12" />
          <div className="ph-col-12" />
          <div className="ph-col-12" />
        </div>

        <div className="ph-row mt-3">
          <div className="ph-col-4 big" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-4 big" />
        </div>
      </div>
    </div>
  </div>
)
export const replaceIfEmpty = (value, replaceValue) => {
  value = _.trim(value)
  return !_.isEmpty(value) ? value : replaceValue
}
export const getGrade = (ageGroup) => {
  const ageGroupsData = [
    { id: [0, 1], name: 'Infant < 1 year' },
    { id: [1, 2], name: 'Toddler ( 1 - 2 years)' },
    { id: [2, 5], name: 'Preschooler (Ages 2–5 years)' },
    { id: [5, 11], name: 'Elementary (Grade K-5)' },
    { id: [11, 14], name: 'Middle School (Grade 6 -8)' },
    { id: [14, 18], name: 'High School (Grade 9-12)' },
    { id: [18, 99], name: 'Adult' },
  ]
  const matchedResult = _.find(ageGroupsData, { id: ageGroup })

  if (!_.isEmpty(matchedResult)) {
    return _.get(matchedResult, 'name')
  }
  return `(grade ${_.join(ageGroup, '-')})`
}
