import React from 'react'
import _ from 'lodash'
import { notification } from 'antd'
import * as SecureLS from 'secure-ls'
import axiostoServer from '../../../../utils/axios'
import history from '../../../../utils/history'
import * as routeName from '../../../../components/route-names'

const mainComponentLoader = () => (
  <div className="nurtem-card-design mt-2 mb-3 pt-3">
    <div className="ph-item">
      <div className="ph-col-8">
        <div className="ph-row">
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty" />
          <div className="ph-col-2 big" />
        </div>

        <div className="ph-row">
          <div className="ph-col-8" />
          <div className="ph-col-4 empty" />
        </div>
        <div className="ph-row mt-3">
          <div className="ph-col-12" />
          <div className="ph-col-12" />
          <div className="ph-col-12" />
        </div>
        <div className="ph-row mt-3">
          <div className="ph-col-4 big" />
          <div className="ph-col-4 empty" />
          <div className="ph-col-4 big" />
        </div>
        <div className="ph-row mt-3">
          <div className="ph-col-12" />
          <div className="ph-col-12" />
          <div className="ph-col-8 big" />
          <div className="ph-col-2 empty" />
          <div className="ph-col-2 big" />
          <div className="ph-col-12" />
          <div className="ph-col-12" />
        </div>
      </div>
      <div className="ph-col-4">
        <div className="ph-picture" style={{ height: `${320}px` }} />
      </div>
    </div>
  </div>
)

const updateCampAddonProperty = (
  campAddonsArray,
  specificAddonObject,
  replaceObjectProperties,
  callback = () => { }
) => {
  const modifyCampAddons = [...campAddonsArray]
  const selectorProperty = 'name'
  const campAddonIndex = _.findIndex(
    campAddonsArray,
    _.pick(specificAddonObject, [selectorProperty])
  )
  if (campAddonIndex > -1) {
    modifyCampAddons[campAddonIndex] = {
      ...modifyCampAddons[campAddonIndex],
      ...replaceObjectProperties,
    }
  }
  if (_.isFunction(callback)) {
    callback(campAddonIndex > -1, modifyCampAddons)
  } else {
    return { updated: campAddonIndex > -1, data: modifyCampAddons }
  }
}

const getAddonTotal = (campAddonsArray) => {
  let addonTotal = 0
  for (const val of campAddonsArray) {
    if (_.get(val, 'checked', false)) {
      const count = _.toNumber(_.get(val, 'count', 0))
      const cost = _.toNumber(_.get(val, 'cost', 0))
      const addonsIndividualTotal = _.multiply(count, cost)
      addonTotal = _.add(addonTotal, addonsIndividualTotal)
    }
  }

  return addonTotal
}

/**
 * returns price object
 * @param {*} basePrice
 * @param {*} campAddonsArray
 * @returns  {
        sessionPrice:bp,
        addonTotal: addP,
        grandTotal: _.sum(addP, bp)
    }
 */
const getGrandTotal = (basePrice, campAddonsArray) => {
  const bp = _.isNaN(basePrice) ? 0 : _.toNumber(basePrice)

  const addP = getAddonTotal(campAddonsArray)

  return {
    sessionPrice: bp,
    addonTotal: addP,
    grandTotal: _.add(addP, bp),
  }
}

const SessionPaid = (paid_for, clsID, billingType, isLoggedIn, reorder) => {
  if (!isLoggedIn || billingType === 3 || !reorder) {
    return 0
  }
  return new Promise((resolve, reject) => {
    if (paid_for && clsID) {
      axiostoServer({
        method: 'post',
        url: '/number-sessons-paid',
        withCredentials: true,
        data: {
          billingType,
          class_id: clsID,
          paidfor: paid_for,
        },
        headers: { 'Content-Type': 'application/json' },
      })
        .then((resp) => {
          if (resp.status === 200 || resp.status === 304) {
            resolve(resp.data.data)
          } else {
            reject(new Error('Failed to fetch session purchase'))
          }
        })
        .catch((error) => {
          reject(error)
        })
    } else {
      reject(new Error('Invalid paid_for or clsID'))
    }
  })
}

const getCartItems = async (isLoggedIn, id, t) => {
  try {
    if (isLoggedIn) {
      const resp = await axiostoServer({
        method: 'get',
        url: '/users/get-cartitems',
        withCredentials: true,
        params: { id },
        headers: { 'Content-Type': 'application/json' },
      })

      if (resp.status === 200) {
        return resp.data.details
      }
      notification.error({
        description: t('errGetCart'),
        message: 'Error',
      })
    }
  } catch (error) {
    notification.error({
      description: t('errGetCart'),
      message: 'Error',
    })
    console.error('Error fetching session purchase:', error)
    throw error
  }
}
// camps add to cart
const campsAddToCart = async (
  t,
  campDetails,
  campAddonsArray,
  pricedata,
  userData,
  status,
  type,
  callback
) => {
  try {
    const isLoggedIn = _.get(userData, 'isLoggedIn', false)
    const ls = new SecureLS()
    let campsItemsExist = {}
    const userID = _.get(userData, 'to_whom_id', _.get(userData, 'userID', ''))

    let cartObjKey = campDetails.id
    const reorder = _.get(userData, 'reorder', false)
    const billingType = _.get(campDetails, 'billing_type', 1)
    if (isLoggedIn && userID) {
      cartObjKey = `${campDetails.id}--${userID}`
    }

    if (isLoggedIn) {
      campsItemsExist = await getCartItems(
        isLoggedIn,
        _.get(userData, 'userID'),
        t
      )
    }
    const campsItemsExist1 = await ls.get('cartItems')

    campsItemsExist = { ...campsItemsExist, ...campsItemsExist1 }

    if (
      !_.isEmpty(campsItemsExist) &&
      campsItemsExist.hasOwnProperty(cartObjKey)
    ) {
      notification.warning({
        description: t('err1GetCart'),
        message: t('warning'),
      })

      return false
    }

    const SessionsPaidApi = await SessionPaid(
      userID,
      campDetails.id,
      billingType,
      isLoggedIn,
      reorder
    )

    const cartDataObj = {
      [cartObjKey]: {
        session: campDetails,
        type,
        addon: campAddonsArray,
        price: pricedata,
        SessionsPaid: SessionsPaidApi.sessionPaid,
        SessionsPaidOn: SessionsPaidApi.paidOn,
        status,
        reorder,
        user: _.get(userData, 'userID', ''),
      },
    }

    let cmd
    let description
    if (status === 0) {
      cmd = t('wishlist')
      description = t('wishlist1')
    }
    if (status === 1) {
      cmd = t('cart ')
      description = t('cart')
    } else if (status === 2) {
      cmd = t('waitlist ')
      description = t('waitlist1 ')
    }

    const errDesc = `${t('Erradd')}${cmd}!`
    const errMsg = t('error')
    let getCItems = {}

    if (isLoggedIn) {
      ls.set('guestCheckout', false)
      new Promise((resolve, reject) => {
        if (cartDataObj) {
          axiostoServer({
            method: 'post',
            url: '/users/create-cart',
            withCredentials: true,
            data: { ...cartDataObj },
            headers: { 'Content-Type': 'application/json' },
          })
            .then((resp) => {
              if (resp.status === 200) {
                notification.success({
                  description: `${description} ${_.get(resp, 'data.message', '')}`,
                  message: t('success'),
                })
                resolve(resp.data.details)
              } else {
                notification.error({
                  description: errDesc,
                  message: errMsg,
                })
                reject(new Error('Failed to fetch session purchase'))
              }
            })
            .catch((error) => {
              notification.error({
                description: errDesc,
                message: errMsg,
              })
              reject(error)
            })
        } else {
          reject(new Error('Invalid paid_for or clsID'))
        }
      })

      //get cart item
      getCItems = await getCartItems(
        isLoggedIn, userID, t
      )

    } else {
      ls.set('guestCheckout', true)
      notification.success({
        description: t('cart1'),
        message: t('success'),
      })
      //no wish list or waitlist in guest flow
      if (status === 1) {
        getCItems = ls.get('cartItems')
        ls.set('cartItems', { ...getCItems, ...cartDataObj })
      }
    }
    // Call the callback function if it's provided
    if (typeof callback === 'function') {
      callback({ ...getCItems, ...cartDataObj })
    }


  } catch (error) {
    console.error('Error adding to cart:', error)
    notification.error({
      description: t('Erradd')` Please try again later.`,
      message: t('error'),
    })
  }
}

export {
  mainComponentLoader,
  updateCampAddonProperty,
  getGrandTotal,
  campsAddToCart,
}
