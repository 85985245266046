import React from 'react'
import { Table, Space } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import generalAction from '../../../store/general/action'
import * as campAction from '../../../store/actions/tutor/camps'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import * as routeName from '../../../components/route-names'
import history from '../../../utils/history'
import '../tutor-camps/tutor-list.css'
import { DEFAULTUSER } from '../../../utils/config/assets'
import _ from 'lodash'
import AssignTutorModalComponent from '../transfer-account';
import { Tag } from '../../../components/antd-components/index.tsx'

class TutorList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tutorList: [],
      page_provider_id: '',
      page: 1,
      class_page: 1,
      limit: 4,
      tutorTableData: [],
      classesTableData: [],
      provider_id:
        this.props.businessTutorBaseProfile &&
        this.props.businessTutorBaseProfile.details &&
        this.props.businessTutorBaseProfile.details.id,
      classRow_id: '',
      tutorRow_id: '',
      assignTutorModal: false,
      assignTutorId: '',
      group_id: '',
      formReset: '',
    }
  }

  componentDidMount() {
    if (
      this.props.businessTutorBaseProfile &&
      this.props.businessTutorBaseProfile.details &&
      this.props.businessTutorBaseProfile.details.id
    ) {
      const requestParams = {}
      requestParams.limit = 4
      requestParams.works_for = this.props.businessTutorBaseProfile.details.id
      requestParams.sort = 'created_at.desc'
      requestParams.expand = 'classes'
      this.props.tutorList({ params: requestParams })
    }
  }

  headerTitle = (details) =>
    _.get(details, 'firstname')
      ? `${_.startCase(_.get(details, 'firstname', ''))} ${_.startCase(_.get(details, 'lastname', ''))}`
      : _.get(details, 'email')

  async componentDidUpdate(prevPros) {
    if (
      this.props.searchTutorList !== prevPros.searchTutorList &&
      this.props.searchTutorList
    ) {
      await this.setState({
        tutorList: this.props.searchTutorList
          .map((item) => {
            if (_.get(item, 'id') !== this.state.tutorRow_id) {
              return {
                text: this.headerTitle(item),
                value: item.id,
                key: item.id,
              }
            }
            return null
          })
          .filter((item) => item !== null),
      })
    }

    if (
      prevPros.tutorsList !== this.props.tutorsList &&
      this.props.tutorsList.items &&
      this.props.tutorsList.items.length > 0
    ) {
      this.setState({
        tutorTableData: this.props.tutorsList.items,
      })
    }
    if (
      prevPros.providerCompetitions !== this.props.providerCompetitions &&
      this.props.providerCompetitions.items &&
      this.props.providerCompetitions.items.length > 0
    ) {
      this.setState({
        classesTableData: this.props.providerCompetitions.items,
      })
    }
  }

  viewCompetitionList = (id) => {
    this.setState({
      tutorRow_id: id,
      singleClassView: {},
      individual_provider: id,
      student_page: 1,
      class_page: 1,
    })
    const requestParams = {}
    requestParams.limit = 4
    requestParams.page = this.state.classesTableData.length > 0 ? (this.state.class_page > 1 && this.state.class_page - 1) : this.state.class_page
    requestParams.provider = id
    requestParams.expand = 'provider'
    requestParams.sort = 'created_at.desc'
    this.props.getComppetitionsList(requestParams)
  }

  pageClassListChange = async (page, pageSize) => {
    await this.setState({
      class_page: page,
    })
    const data = {}
    data.provider = this.state.tutorRow_id
    data.page = page
    data.limit = this.state.class_limit
    data.expand = 'provider'
    data.sort = 'created_at.desc'
    this.props.getComppetitionsList(data)
  }

  pageChange = async (page, pageSize) => {
    await this.setState({
      page,
    })

    const data = {}
    // data.provider = this.state.provider_id
    data.page = page
    data.limit = this.state.limit
    data.works_for = _.get(this, 'props.businessTutorBaseProfile.details.id')
    data.expand = 'provider'
    data.sort = 'created_at.desc'
    this.props.tutorList({ params: data })
  }

  titleRender = (details) => (
    <div className="row">
      <div>
        <img
          style={{ width: '50px', height: '50px', borderRadius: '30px' }}
          src={_.get(details, 'photo') ? _.get(details, 'photo') : DEFAULTUSER}
          alt="img"
        />
      </div>
      <div className="tutorlist">
        {_.get(details, 'type', '') === 'Individual'
          ? `${_.startCase(_.get(details, 'firstname', '---'))} ${_.startCase(_.get(details, 'lastname', ''))}`
          : _.startCase(_.get(details, 'businessname', '---'))}
      </div>
    </div>
  )

  viewDetails = (camp_name, groupid, provId) => {
    history.push(
      `${routeName.competitionsView}/${encodeURIComponent(camp_name)}/?gid=${groupid}&tutor=business&provId=${provId}`
    )
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  tutorAssignModalFunction = (value, key, record) => {
    this.setState({
      assignTutorModal: value,
      group_id: key,
      provider_id: record?.classDetails?.provider?.id,
      formReset: _.uniqueId('_123_123_'),
    })
    this.tigerTutorList('expand')
  }

  tigerTutorList = (e) => {
    const requestParams = {};
    requestParams.limit = 1000;
    requestParams.works_for = _.get(this.props, 'tutorBaseProfile.details.id');
    requestParams.sort = 'created_at.desc';
    if (e && e === 'expand') {
      requestParams.expand = 'update_progress';
    }
    this.props.tutorList({
      params: requestParams,
      callback: (res, data) => {
        if (res) {
          if (_.size(_.get(data, 'items', [])) > 0) {
          } else {
          }
        }
      },
    });
  };

  render() {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    const { tutorRow_id, page, class_page, classRow_id, group_id, assignTutorModal, formReset, } = this.state

    const ListingClasscolumns = [
      {
        title: this.props.t('compName'),
        dataIndex: 'class',
        key: 'class',
      },
      {
        title: this.props.t('type'),
        dataIndex: 'type',
        render: (type) =>
          type === 1 ? (
            <Tag active="1">Competition</Tag>
          ) : type === 2 && (
            <Tag active="2">Event</Tag>
          ),
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        render: (text, record) => (
          <div size="middle">
            <div>
              <button
                onClick={() =>
                  this.viewDetails(
                    record.classDetails.name,
                    record.classDetails.id,
                    record.classDetails.provider.id
                  )
                }
                className="btn btn-accept  mr-1"
              >
                {this.props.t('viewDetails')}
              </button>
              {_.get(this, 'props.tutorBaseProfile.details.type')
                === 'Business' && (
                  <button
                    onClick={() => this.tutorAssignModalFunction(true, _.get(record, 'key'), record)}
                    className={
                      group_id === _.get(record, 'key')
                        ? 'btn btn-accept-tutor-active  mr-1'
                        : 'btn btn-accept-tutor  mr-1'
                    }
                  >
                    {this.props.t('reasTutor')}
                  </button>
                )}
            </div>
          </div>
        ),
      },
    ]
    let classData = []
    if (
      this.props.providerCompetitions &&
      this.props.providerCompetitions.items &&
      this.props.providerCompetitions.items.length > 0
    ) {
      classData = this.state.classesTableData.map((value, index) => {
        const location_data = _.isEmpty(_.get(value, 'location_text'))
          ? 'Drive to Student'
          : value.location_text
        return {
          class: value.name ? this.capitalizeWord(value.name) : '',
          location: value.teaching_mode === 'o' ? 'Online' : location_data,
          key: value.id,
          type: value.type,
          classDetails: value,
        }
      })
    }
    const ListingTutorscolumns = [
      {
        title: this.props.t('tutName'),
        dataIndex: 'tutor',
        key: 'tutor',
        width: '35%',
      },
      {
        title: this.props.t('email'),
        dataIndex: 'email',
        key: 'email',
        width: '30%',
      },
      {
        title: this.props.t('phNo'),
        dataIndex: 'phone',
        key: 'phone',
        width: '15%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '20%',
        render: (text, record) => (
          <Space size="middle">
            <div>
              <button
                onClick={() => this.viewCompetitionList(record.tutorDetails.id)}
                className="btn btn-accept  mr-1"
              >
                {this.props.t('viewComps')}
              </button>
            </div>
          </Space>
        ),
      },
    ]
    let tutorData = []

    if (
      this.props.tutorsList &&
      this.props.tutorsList.items &&
      this.props.tutorsList.items.length > 0
    ) {
      tutorData = this.state.tutorTableData.map((value, index) => ({
        tutor: this.titleRender(value),
        email: _.get(value, 'email'),
        phone: _.get(value, 'mobile_number')
          ? _.get(value, 'mobile_number')
          : '---',
        key: value.id,
        tutorDetails: value,
      }))
    }

    return (
      <div className="full-width tutor-my-class-page pb-4 mb-3">
        <div className="row">
          {urlparams && !urlparams.classId && (
            <div className="col-12">
              <div className="nurtem-table">
                <div className="schedule-content mt-2 class-details-table">
                  {this.props.tutorListLoading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      rowClassName={(record, index) =>
                        record.key === tutorRow_id ? 'active' : ''
                      }
                      columns={ListingTutorscolumns}
                      dataSource={tutorData}
                      // scroll={{ y: 200 }}
                      pagination={{
                        pageSize: this.state.limit,
                        onChange: (page, pageSize) =>
                          this.pageChange(page, pageSize),
                        total:
                          this.props.tutorsList &&
                          this.props.tutorsList.meta &&
                          parseInt(this.props.tutorsList.meta.total_count),
                        hideOnSinglePage:
                          !this.props.businessLoading ||
                          this.props.businessLoading,
                        showSizeChanger: false,
                        current: page,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
          {_.get(this, 'state.tutorRow_id') !== '' && (
            <div className="col-12">
              <div className="nurtem-table py-4 mt-2 mb-3">
                <div className="message-title mb-3">
                  <span>{this.props.t('listComp')}</span>
                </div>
                <div className="schedule-content mt-2 class-details-table">
                  <div className="hidden-scroll-x">
                    <Table
                      loading={_.get(
                        this.props,
                        'businessOnClassesLoading',
                        false
                      )}
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={ListingClasscolumns}
                      dataSource={classData}
                      // scroll={{ y: 200 }}
                      pagination={{
                        pageSize: this.state.class_limit,
                        onChange: (page, pageSize) =>
                          this.pageClassListChange(page, pageSize),
                        total:
                          this.props.providerCompetitions &&
                          this.props.providerCompetitions.meta &&
                          parseInt(
                            this.props.providerCompetitions.meta.total_count
                          ),
                        hideOnSinglePage:
                          !this.props.businessLoading ||
                          this.props.businessLoading,
                        showSizeChanger: false,
                        current: class_page,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <AssignTutorModalComponent
          callback={(e) => this.viewCompetitionList(_.get(this, 'state.provider_id', _.get(this, 'props.tutorBaseProfile.details.id')))}
          tutorsList={_.get(this, 'props.tutorsList')}
          type="competitions"
          formReset={formReset}
          tutorAssignModalFunction={(e, key) =>
            this.tutorAssignModalFunction(e, key)
          }
          assignTutorModal={assignTutorModal}
          classTutorDetails={{
            class_id: this.state.group_id,
            provider: _.get(this, 'state.provider_id', _.get(this, 'props.tutorBaseProfile.details.id')),
          }}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  active_key: state.campsDetails.activeKey,
  businessTutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  businessLoading: state.classesDetails.loading,
  tutorsList: _.get(state, 'general.tutor.list'),
  searchTutorList: _.get(state, 'general.tutor.searchTutorList.items', []),
  tutorListLoading: _.get(state, 'general.tutor.listLoadingInProgress'),
  providerCompetitions: state.competitionsDetails.otherProviderCompetitions,
})

const mapDispatchToProps = (dispatch) => ({
  tutorList: (data) => dispatch(generalAction.tutor.tutorList(data)),
  awitchTab: (data) => dispatch(campAction.activeKey(data)),
  getComppetitionsList: (requestParams) =>
    dispatch(competitionActions.getOtherProviderCompetitions(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorList))
