import React from 'react';
import { Modal, notification } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import * as competitionActions from '../../../store/actions/tutor/competitions';
import * as routeName from '../../../components/route-names';
import ClassDetailsView from './detail-view';
import history from '../../../utils/history';
import _ from 'lodash';

class Competition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      limit: 3,
    };
  }

  componentDidMount() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);

    if (urlparams && urlparams.campId) {
      const requestParams = {};
      requestParams.expand = 'services,provider,';
      requestParams.page = this.state.page;
      requestParams.limit = 100;
      requestParams.classId = urlparams.campId;
      this.props.onCompetitionDetails({ class_id: urlparams.classId, type: 'classView', expand: 'provider,services' });
    }
  }

  async componentDidUpdate(prevPros) {
    if (prevPros.deleteClassInfo !== this.props.deleteClassInfo && this.props.deleteClassInfo && this.props.deleteClassInfo.data && this.props.deleteClassInfo.status === 200) {
      Modal.destroyAll();
    } else if (prevPros.deleteClassInfo !== this.props.deleteClassInfo && this.props.deleteClassInfo.data) {
      Modal.destroyAll();
      notification.error({
        message: 'Error',
        description:
                    this.props.deleteClassInfo.data.message,
      });
    }
  }

  commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
    });
  };

  onDeleteConfirm = async (classId) => {
    this.props.onClearCompetitionDeleteDetail();
    await this.setState({
      classId,
    });
    try {
      const currentThis = this;
      const requestParams = {};
      requestParams.id = classId;
      currentThis.props.onDeleteCompetition(requestParams);
    } catch (error) {
      console.log('Oops errors!', error);
    }
    // Modal.confirm({
    //     keyboard: false,
    //     maskClosable: false,
    //     title: 'Are you sure to delete this Competition?',
    //     okText: 'Yes',
    //     okType: 'danger',
    //     cancelText: 'No',
    //     cancelButtonProps: {
    //     },
    //     onOk() {
    //         return new Promise((resolve, reject) => {
    //             let requestParams = {}
    //             requestParams.id = classId
    //             currentThis.props.onDeleteCompetition(requestParams)
    //         }).catch(() => console.log('Oops errors!'))
    //     }
    // })
  };

  pageRedirect = (value) => {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    if (urlparams.tutor) {
      history.push(`${routeName.updateCompetition}?id=${value}&type=update&tutor=${urlparams.tutor}`);
    } else {
      history.push(`${routeName.updateCompetition}?id=${value}&type=update`);
    }
  };

  render() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);

    // if no camp id in url then redirect to camp list
    if (urlparams && !urlparams.gid) {
      history.push(`${routeName.tutorClasses}`);
    }

    return (
      <div className="full-width tutor-my-camp-details-page pb-4 mb-3">

        <ClassDetailsView
          {...this.props}
          onDeleteClass={(id) => this.onDeleteConfirm(id)}
          onEditClass={(id) => this.pageRedirect(id)}
          classDetails={this.props.singleClassView}
          state={this.state}
          studentPageChange={(page) => this.studentPageChange(page)}
        />

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  tutorCompetitions: state.competitionsDetails.CompetitionDetails,
  deleteClassInfo: state.competitionsDetails.deleteCompetitionsInfo,
  singleClassView: state.competitionsDetails.CompetitionDetails,
});

const mapDispatchToProps = (dispatch) => ({
  onCompetitionDetails: (requestParams) => dispatch(competitionActions.onGetCompetitionDetails(requestParams)),
  onClearCompetitionDeleteDetail: () => dispatch(competitionActions.onClearCompetitionDeleteDetail()),
  onDeleteCompetition: (requestParams) => dispatch(competitionActions.onDeleteCompetition(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Competition));
