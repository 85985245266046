import React from 'react'
import { Tabs, Rate, Pagination, Collapse, FloatButton } from 'antd'
import {
  AiOutlineWifi,
  AiFillSafetyCertificate,
  AiFillDollarCircle,
} from 'react-icons/ai'
import { Link } from 'react-router-dom'
import embed from 'embed-video'
import './tutor-profile.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import renderHTML from 'react-html-parser'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import { BiLogoZoom } from 'react-icons/bi'
import { MdLocationOn } from 'react-icons/md'
import * as tutorAction from '../../../store/actions/public/tutor-public-profile/index'
import axiostoServer from '../../../utils/axios'
import history from '../../../utils/history'
import * as servicesActions from '../../../store/actions/public/Services'
import Ratings from '../../../components/rating/rating-list'
import { NurtemUtils } from '../../../utils'
import Claimed from '../../../components/tutor/claim-business/claimed'
import Linkify from 'linkify-react'
import * as searchActionType from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import * as common from '../../../utils/common'
import { globalVariable } from '../../../utils/config/config'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import viewClassCamps from '../../../utils/common/get-view-url'
import { CLASSPNG, DEFAULTUSER } from '../../../utils/config/assets'
import _ from 'lodash'

import ClassList from './classList'
const { Panel } = Collapse
function callback(key) { }

class TutorPublicProfile extends React.Component {
  constructor(props) {

    super(props)
    this.myClassesRef = React.createRef()
    this.myClassespickerRef = React.createRef()
    this.myAboutRef = React.createRef()
    this.myAwardRef = React.createRef()
    this.myEventRef = React.createRef()
    this.myRatingRef = React.createRef()
    this.myMediaRef = React.createRef()
    this.myCampspickerRef = React.createRef()
    this.myCompspickerRef = React.createRef()

    this.state = {
      isMounted: false,
      tutorProfile: {},
      classListPage: 1,
      campsListPage: 1,
      competitionsListPage: 1,
      likes: 0,
      zipCode: '',
      isTop: true,
      scrollValue: false,
      ratingList: [],
      ratingList_meta: {},
      progress: {
        '1_star': 0,
        '2_star': 0,
        '3_star': 0,
        '4_star': 0,
        '5_star': 0,
      },
      occurrence_type: [
        '',
        'Every',
        'Every Alternate',
        '(Once In A Month)',
        '(Twice In A Month)',
        '(Thrice In A Month)',
        '(Four Times A Month)',
      ],
      showModal: false,
      caption: '',
      modalSrc: '',
      ioImageListing: [
        {
          id: 'f06f2cdf-1bb8-4388-a655-4e916a2703ce',
          visitRequirementList: [
            {
              imageName: 'dining pic 1',
              imagePath: CLASSPNG,
            },
            {
              imageName: 'dining pic 2',
              imagePath: 'https://source.unsplash.com/1280x720/?food',
            },
            {
              imageName: 'bedroom pic 1',
              imagePath: 'https://source.unsplash.com/1280x720/?travel',
            },
            {
              imageName: 'bedroom pic 1',
              imagePath: 'https://source.unsplash.com/1280x720/?people',
            },
          ],
        },
      ],
      sectionIndex: null,
      imageIndex: null,
      name: '',
      email: '',
      phone: '',
      message: '',
      loading: false,
      isModalVisible: false,
      currentSectionLength: null,
    }
  }

  plusSlides = (n) => {
    const { ioImageListing, imageIndex, sectionIndex, currentSectionLength } =
      this.state

    const nextIndex = imageIndex + n
    const numberOfSections = ioImageListing.length

    if (nextIndex < 0 && sectionIndex === 0) {
      alert('NO MORE LEFT IMAGE')
      return
    }
    if (
      sectionIndex === numberOfSections - 1 &&
      nextIndex >
      ioImageListing[numberOfSections - 1].visitRequirementList.length - 1
    ) {
      alert('NO MORE RIGHT IMAGE')
      return
    }
    let nextImage
    let nextImageIndex
    let nextSectionIndex
    let nextSectionLength
    if (nextIndex >= 0 && nextIndex < currentSectionLength) {
      nextImage = ioImageListing[sectionIndex].visitRequirementList[nextIndex]
      nextImageIndex = nextIndex
      nextSectionIndex = sectionIndex
      nextSectionLength = currentSectionLength
    } else if (nextIndex < 0) {
      nextImage =
        ioImageListing[sectionIndex - 1].visitRequirementList[
        ioImageListing[sectionIndex - 1].visitRequirementList.length - 1
        ]
      nextImageIndex =
        ioImageListing[sectionIndex - 1].visitRequirementList.length - 1
      nextSectionIndex = sectionIndex - 1
      nextSectionLength =
        ioImageListing[sectionIndex - 1].visitRequirementList.length
    } else {
      nextImage = ioImageListing[sectionIndex + 1].visitRequirementList[0]
      nextImageIndex = 0
      nextSectionIndex = sectionIndex + 1
      nextSectionLength =
        ioImageListing[sectionIndex + 1].visitRequirementList.length
    }
    this.setState({
      caption: nextImage.imageName,
      modalSrc: nextImage.imagePath,
      imageIndex: nextImageIndex,
      sectionIndex: nextSectionIndex,
      currentSectionLength: nextSectionLength,
    })
  }

  async componentDidMount() {
    if (!this.state.isMounted) {
      const pathArr = window.location.pathname.split('/')
      const serviceName = _.last(pathArr)

      const profile = await tutorAction.tutorPublicProfile2(serviceName)
      console.log("profile", profile)
      // Only set state if the profile is not already set
      if (profile && (!this.state.tutorProfile || this.state.tutorProfile !== profile)) {
        this.setState({ tutorProfile: profile, isMounted: true })
      }
    }
  }

  removeHTMLTags = (value) => {
    const regex = /(<([^>]+)>)/gi
    return String(value).replace(regex, '')
  }

  viewCount = (des) => {
    const count = String(des).replace(/(<([^>]+)>)/gi, '')
    return count.length
  }

  tutorsClassCamps = () => {
    this.myClassesRef.current.scrollIntoView({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  async componentDidUpdate(prevprops) {


    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 800
      const valu = document.querySelectorAll('.ant-tabs-nav-list')[0]?.children
      const tabs = valu && Array.prototype.slice.call(valu)
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
        const scrollValue = window.scrollY > 800
        if (scrollValue !== this.state.scrollValue) {
          this.setState({ scrollValue })
        }
      }
      function clearTabs(tabs) {
        tabs.map((val) => val.classList.remove('ant-tabs-tab-active'))
        tabs[8].classList.remove(
          'awardLine',
          'campLine',
          'classLine',
          'competetionLine',
          'eventLine',
          'reviewLine',
          'mediaLine'
        )
      }
      if (window.scrollY > 800 && window.scrollY < 950) {
        tabs && clearTabs(tabs)
        tabs && tabs[1].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('awardLine')
      } else if (window.scrollY > 950 && window.scrollY < 1350) {
        tabs && clearTabs(tabs)
        tabs && tabs[3].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('classLine')
      }

      if (window.scrollY > 1350 && window.scrollY < 1750) {
        tabs && clearTabs(tabs)
        tabs && tabs[4].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('campLine')
      }

      if (window.scrollY > 1750 && window.scrollY < 2100) {
        tabs && clearTabs(tabs)
        tabs && tabs[5].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('competetionLine')
      }

      if (window.scrollY > 2100 && window.scrollY < 2400) {
        tabs && clearTabs(tabs)
        tabs && tabs[2].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('eventLine')
      }

      if (window.scrollY > 2400 && window.scrollY < 2800) {
        tabs && clearTabs(tabs)
        tabs && tabs[6].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('reviewLine')
      }

      if (window.scrollY > 2800) {
        tabs && clearTabs(tabs)
        tabs && tabs[7].classList.add('ant-tabs-tab-active')
        tabs && tabs[8].classList.add('mediaLine')
      }
    })
  }

  // handleScroll = (data, type) => {
  //   if (!_.isEmpty(this.state.tutorProfile && type === 'class' &&
  //     data &&
  //     data.top === 1)) {
  //     const tutorDetails = this.state.tutorProfile

  //     if (
  //       this.props.classesList &&
  //       this.props.classesList.meta &&
  //       this.props.classesList.meta.total_count >
  //       this.props.classesList.meta.count
  //     ) {
  //       const requestParams = { provider: tutorDetails.id }
  //       requestParams.limit = this.props.classesList.meta.count + 8
  //       requestParams.count = this.props.classesList.meta.count + 8
  //       this.props.onGetClassesList(requestParams)
  //     }
  //   }
  // }

  onChangePage = (page, pageSize, type) => {
    this.setState({
      [type]: page,
    })
    // const requestParams = { provider: tutorDetails.id, page }
    // requestParams.limit = 8
    // requestParams.count = 8
    // requestParams.expand = 'services'
    // requestParams.sort = 'created_at.desc'
    // if (type === 'campsListPage') {
    //   this.props.onGetCampsList(requestParams)
    // } else if (type === 'classListPage') {
    //   this.props.onGetClassesList(requestParams)
    // } else {
    //   this.props.onGetCompetitionList(requestParams)
    // }
  }

  scrollAbout = () => {
    try {
      this.myAboutRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollAward = () => {
    try {
      this.myAwardRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollEvent = () => {
    try {
      this.myEventRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        block: 'center',
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollViewPicker = () => {
    try {
      this.myClassespickerRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollViewPickerCamp = () => {
    try {
      this.myCampspickerRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollViewPickerComp = () => {
    try {
      this.myCompspickerRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollRating = () => {
    try {
      this.myRatingRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  scrollMedia = () => {
    try {
      this.myMediaRef.current.scrollIntoView({
        behavior: 'smooth',
        top: 0,
        left: 0,
      })
    } catch (error) {
      console.log('error in scroll to ref')
    }
  }

  handleOpenModal = () => {
    this.setState({ isModalVisible: true })
  }

  handleCloseModal = () => {
    this.setState({ isModalVisible: false })
  }

  handleInputChange = (event) => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleSubmit = () => {
    const { name, email, phone, message } = this.state
    if (!name || !email || !phone || !message) {
      alert(this.props.t('fillAll'))
      return
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const phoneRegex = /^\d{1,13}$/
    if (!emailRegex.test(email)) {
      alert(this.props.t('inputValidEmail'))
      return
    }
    if (!phoneRegex.test(phone)) {
      alert(this.props.t('inputValidPhone'))
      return
    }
    const claim = {
      name,
      email,
      phone,
      message,
    }
    this.onClaim(claim, this.state.tutorProfile)
  }

  onClaim = (data, provider) => {
    data.id = provider.id
    this.setState({ loading: true })
    axiostoServer({
      method: 'post',
      url: '/claim-business',
      withCredentials: false,
      data,
      config: { headers: { 'Content-Type': 'application/json' } },
    }).then((resp) => {
      if (
        resp.status === 200 ||
        resp.status === 201 ||
        resp.status === 400 ||
        resp.status === 500
      ) {
        this.setState({
          loading: false,
        })
        this.setState({ isModalVisible: false })
      }
    })
  }

  type = ''

  serviceName = _.last(window.location.pathname.split('/'))

  getSmallestPrice = (classes) =>
    Math.min.apply(
      null,
      classes &&
      classes[0] &&
      classes.map((item) => item.price && Math.round(item.price))
    )

  tutorList = () => {
    const current_id = this.state.tutorProfile.id
    const profile_list = this.props.generalList.items
    let filteredData
    if (profile_list) {
      filteredData = profile_list.filter((item) => item._id !== current_id)

      if (filteredData.length > 0) {
        return (
          <>
            <div
              ref={this.othertutorsRef}
              className="tutor-public-profile-container about-title mt-2 mb-2"
            >
              {this.props.t('youConsider')}
            </div>
            {filteredData &&
              filteredData.map((value, key) => {
                const teachingMode =
                  value.provider_profile &&
                  value.provider_profile.teaching_modes &&
                  value.provider_profile.teaching_modes[0]
                return (
                  <div className="nurtem-card-design mt-2 mb-3">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 card-shadow">
                        <div className="row mb-2">
                          <div className="col-2 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <div className="profile-image2">
                              <img
                                src={
                                  _.get(value, 'provider_profile.photo')
                                    ? _.get(value, 'provider_profile.photo')
                                    : globalVariable.DEFAULT_PROFILE_PIC
                                }
                                className="avatar"
                                alt="img"
                              />
                            </div>
                          </div>
                          <div className="col-10 col-sm-12 col-md-8 col-lg-10 col-xl-10 ">
                            <div className="row ml-2">
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="profile-name text-truncate">
                                  {_.get(value, 'provider_profile.type') ===
                                    'Individual'
                                    ? !_.isEmpty(
                                      _.get(
                                        value,
                                        'provider_profile.firstname',
                                        null
                                      )
                                    )
                                      ? `${_.startCase(_.get(value, 'provider_profile.firstname'))} ${_.get(value, 'provider_profile.lastname', '') !== '' ? `${_.startCase(_.get(value, 'provider_profile.lastname', '')[0])}.` : ''}`
                                      : ''
                                    : `${_.startCase(_.get(value, 'provider_profile.businessname'))}`}
                                </div>
                                <div className="star-rating">
                                  <Rate
                                    disabled
                                    value={_.get(
                                      value,
                                      'provider_profile.average_rating'
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                {value.classes ? (
                                  <div className="row">
                                    <div className="price-icon mr-2">
                                      <AiFillDollarCircle />
                                    </div>
                                    <div className="price-tag">
                                      <span className="d-block">
                                        {this.props.t('startsFrom')}
                                        <b className="bold">
                                          ${this.getSmallestPrice(value.classes)}
                                          {this.props.t('hr')}
                                        </b>
                                      </span>
                                    </div>
                                    <div className="col-7">
                                      <Link
                                        className="view-profile"
                                        to={{
                                          pathname: `/profile/${value.provider_profile && value.provider_profile.user_handle}/${this.serviceName}`,
                                          state: { data: 'Hello from FirstPage' },
                                        }}
                                        target="_blank"
                                      >
                                        <button
                                          style={{ marginRight: '5px' }}
                                          className="btn btn-view-profile"
                                        >
                                          {this.props.t('viewProfile')}
                                        </button>{' '}
                                      </Link>
                                      {value.classes && value.classes[0] && (
                                        <Link
                                          to={viewClassCamps(
                                            value.provider_profile,
                                            value.classes[0],
                                            {}
                                          )}
                                        >
                                          <button className="btn btn-view-profile">
                                            {this.props.t('view')} {this.type}
                                          </button>{' '}
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <Claimed tutorDetails={value.provider_profile} />
                                )}
                              </div>
                              <div className="col-12">
                                <div className="badge-group mt-2">
                                  {teachingMode &&
                                    value.provider_profile.teaching_modes.includes(
                                      'online'
                                    ) && (
                                      <span className="badge badge-green mr-2">
                                        {this.props.t('online')}
                                      </span>
                                    )}
                                  {teachingMode &&
                                    value.provider_profile.teaching_modes.includes(
                                      'drive'
                                    ) && (
                                      <span className="badge badge-purple mr-2">
                                        {this.props.t('driveToStu')}
                                      </span>
                                    )}
                                  {teachingMode &&
                                    value.provider_profile.teaching_modes.includes(
                                      'studio'
                                    ) && (
                                      <span className="badge badge-red mr-2">
                                        {this.props.t('inPerson')}
                                      </span>
                                    )}
                                </div>
                              </div>
                            </div>
                            <div className="row ml-2">
                              <div className="col-12">
                                <div className="description module line-clamp">
                                  {value.provider_profile &&
                                    value.provider_profile.bio
                                    ? value.provider_profile &&
                                    parser(
                                      NurtemUtils.urlify(
                                        _.truncate(
                                          _.get(
                                            value,
                                            'provider_profile.bio',
                                            ''
                                          ),
                                          {
                                            length: 250,
                                            omission: ' ...',
                                          }
                                        )
                                      )
                                    )
                                    : ''}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="footer mb-4 ml-2">
                          <div className="col-5">
                            {value.nurtem_verified ? (
                              <span className="btn btn-verified">
                                <span className="icon-verified mr-2">
                                  <AiFillSafetyCertificate />
                                </span>
                                {this.props.t('verified')}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </>
        )
      }
    }
  }

  render() {

    if (!_.isEmpty(this.state.tutorProfile)) {
      const tutorDetails = this.state.tutorProfile

      let businesshours = _.get(tutorDetails, 'businesshours', [])
      businesshours = Array.isArray(businesshours) ? businesshours : []

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth', // for smoothly scrolling
        })
      }

      const callbackTabClicked = (key, event) => {
        if (key === '1') {
          this.scrollAbout()
        } else if (key === '2') {
          this.scrollAward()
        } else if (key === '3') {
          this.scrollEvent()
        } else if (key === '4') {
          this.scrollViewPicker()
        } else if (key === '5') {
          this.scrollViewPickerCamp()
        } else if (key === '6') {
          this.scrollViewPickerComp()
        } else if (key === '7') {
          this.scrollRating()
        } else if (key === '8') {
          this.scrollMedia()
        }
      }

      return (<div className="full-width mt-3">
        <div
          className={
            this.state.isTop
              ? 'profilee-conatiner-page'
              : 'view-profile-group-page sticky'
          }
        >
          {!_.isEmpty(tutorDetails) && (
            <div className="view-profile-group-sticky-header mb-2">
              <div className="container" style={{ padding: '0px 150px' }}>
                <div className="row">
                  <div className="col-12 col-lg-5 col-xl-5">
                    <div className="row">
                      <div className="col-3 col-lg-2 col-xl-2">
                        <div className="user-review-set-header">
                          <div className="class-profile-image">
                            <img
                              src={
                                tutorDetails &&  tutorDetails.photo
                                  ? tutorDetails.photo
                                  : DEFAULTUSER
                              }
                              className="avatar"
                              alt="img"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-9 col-lg-10 col-xl-10">
                        <div className="about-title2 mb-2">
                          {_.get(tutorDetails, 'type') === 'Individual'
                            ? !_.isEmpty(_.get(tutorDetails, 'firstname', null))
                              ? `${_.startCase(_.get(tutorDetails, 'firstname'))} ${_.get(tutorDetails, 'lastname', '') !== '' ? `${_.startCase(_.get(tutorDetails, 'lastname', ''))}` : ''}`
                              : ''
                            : `${_.startCase(_.get(tutorDetails, 'businessname'))}`}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-5 col-xl-5">
                    {tutorDetails.services.length > 10 ? (
                      <div className="profile-service-details">
                        <Collapse onChange={callback}>
                          <Panel header="Classes/Camps conducted for :">
                            {tutorDetails &&
                              tutorDetails.services &&
                              tutorDetails.services.length > 0 &&
                              tutorDetails.services.map((service, index) => (
                                <Link
                                  key={index}
                                  to={`/search/${service.name}`}
                                >
                                  <span className="camps-class-tags-course-sticky-header">
                                    {service.name}
                                  </span>
                                </Link>
                              ))}
                          </Panel>
                        </Collapse>
                      </div>
                    ) : (
                      <div className="profile-service-details">
                        <div className="about-title">
                          {this.props.t('tutorPage')}
                        </div>
                        <div className="camps-class-tags-container">
                          {tutorDetails &&
                            tutorDetails.services &&
                            tutorDetails.services.length > 0 &&
                            tutorDetails.services.map((service, index) => (
                              <Link key={index} to={`/search/${service.name}`}>
                                <span className="camps-class-tags-course">
                                  {service.name}
                                </span>
                              </Link>
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-2 col-xl-2">
                    <div className="rating-group-card-header">
                      <div className="star-rating mt-2">
                        <span>
                          <Rate
                            disabled="true"
                            defaultValue={parseInt(
                              tutorDetails && tutorDetails.average_rating
                            )}
                          />
                          {parseInt(
                            tutorDetails && tutorDetails.average_rating
                          ) !== 0 && (
                              <b className="ml-3">
                                (
                                {`${_.get(
                                  tutorDetails,
                                  'average_rating',
                                  0
                                ).toFixed(1)}`}
                                /5)
                              </b>
                            )}
                        </span>
                      </div>
                      <div className="rating-count">
                        ({parseInt(tutorDetails.rating_count)}{' '}
                        {this.props.t('rating')})
                      </div>
                    </div>
                  </div>

                  <div className="about-tab">
                    <Tabs
                      defaultActiveKey="1"
                      className="tabsclass"
                      onTabClick={callbackTabClicked}
                    >
                      <items tab={this.props.t('about')} key="1" />
                      <items tab={this.props.t('awards')} key="2" />
                      <items tab={this.props.t('events')} key="3" />
                      <items tab={this.props.t('classesTutor')} key="4" />
                      <items tab={this.props.t('campsTutor')} key="5" />
                      <items tab={this.props.t('competitionsTutor')} key="6" />
                      <items tab={this.props.t('review')} key="7" />
                      <items tab={this.props.t('media')} key="8" />
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="nurtem">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
                <div className="pro-header">
                  <img
                    src={tutorDetails && tutorDetails.photo ? tutorDetails.photo : DEFAULTUSER}
                    className="tutor-page-img-bg"
                    alt="img"
                  />
                </div>
                <div className="row">
                  {/* {(_.get(tutorDetails, 'email', '')) && (
                      <div className="col-12  contact-list-profile mb-1" style={{ display: 'flex' }}>
                        <span className="icon link" style={{ marginRight: 3 }}>
                          <i
                            className="ntm-envelope"
                          />
                        </span>
                        {' '}
                        <span>{_.get(tutorDetails, 'email', '')}</span>
                      </div>
                    )} */}
                  {_.get(tutorDetails, 'address', '') && (
                    <div
                      className="col-12  contact-list-profile mb-1"
                      style={{ display: 'flex', justifyContent: 'center' }}
                    >
                      <span className="icon link" style={{ marginRight: 6 }}>
                        <MdLocationOn style={{ fontSize: 18 }} />
                      </span>{' '}
                      <span>{_.get(tutorDetails, 'address', '')}</span>
                    </div>
                  )}
                  {/* <div style={{ display: 'flex', fontSize: 20, marginTop: 5 }}>
                      {(_.get(tutorDetails, 'skype_id', '')) && (
                        <div className="col-3  contact-list-profile mb-1">
                          <span className="icon link">
                            <a href={_.get(tutorDetails, 'skype_id', '')}><i className="ntm-skype" style={{ color: '#0058ffc7' }} /></a>
                          </span>
                        </div>
                      )}
                      {(_.get(tutorDetails, 'zoom_id', '')) && (
                        <div className="col-3 contact-list-profile mb-1">
                          <span className="icon link">
                            <a style={{ wordBreak: 'break-all' }} className="a" href={_.get(tutorDetails, 'zoom_id', '')} rel="noreferrer" target="_blank"><BiLogoZoom style={{ color: '#0058ffc7', fontSize: 24 }} /></a>
                          </span>
                        </div>
                      )}
                      {(_.get(tutorDetails, 'facebook_id', '')) && (
                        <div className="col-3  contact-list-profile mb-1">
                          {' '}
                          <span>
                            <a className="a" href={_.get(tutorDetails, 'facebook_id', '')} target="_blank" rel="noopener noreferrer">
                              <span className="icon link">
                                <i
                                  className="ntm-facebook-logo"
                                  style={{ color: '#0058ffc7' }}
                                />
                              </span>
                              {' '}
  
                            </a>
                          </span>
                        </div>
                      )}
                    </div> */}
                </div>
                <div
                  className="camp-profile-details mb-2"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '75px',
                    fontFamily: 'lato',
                    fontWeight: 600,
                    justifyContent: 'center',
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ textAlign: 'center' }}>Experience</div>
                    <div style={{ textAlign: 'center' }}>
                      {_.get(tutorDetails, 'years_of_experience', '')}
                    </div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ textAlign: 'center' }}>Reviews</div>
                    <div style={{ textAlign: 'center' }}>
                      {parseInt(tutorDetails.rating_count)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div className="camp-profile-details mt-1 mb-2">
                  <div className="profile-name-header">
                    {/* <div className='hideTutor' style={{ fontSize: 15, color: '#5a5a5a' }}><b>{this.props.t("tutorLabel")}</b></div> */}
                    <Link
                      className="view-profile"
                      to={{
                        pathname: `/profile/${_.get(tutorDetails, 'user_handle', null)}`,
                        state: { data: 'Hello from FirstPage' },
                      }}
                      target="_blank"
                    >
                      {_.get(tutorDetails, 'type') === 'Individual'
                        ? !_.isEmpty(_.get(tutorDetails, 'firstname', null))
                          ? `${_.startCase(_.get(tutorDetails, 'firstname'))} ${_.get(tutorDetails, 'lastname', '') !== '' ? `${_.startCase(_.get(tutorDetails, 'lastname', ''))}` : ''}`
                          : ''
                        : `${_.startCase(_.get(tutorDetails, 'businessname'))}`}
                    </Link>
                  </div>

                  <h5
                    className="claim-business"
                    style={{ display: 'flex', width: '100%' }}
                  >
                    <Claimed tutorDetails={tutorDetails} />

                    <span className="star-rating" style={{ display: 'flex' }}>
                      <Rate
                        disabled="true"
                        value={_.parseInt(
                          _.get(tutorDetails, 'average_rating', 0)
                        )}
                      />
                      {parseInt(tutorDetails && tutorDetails.average_rating) !==
                        0 && (
                          <b className="ml-3" style={{ fontSize: 14 }}>
                            {`${_.get(tutorDetails, 'average_rating', 0).toFixed(
                              1
                            )}`}
                            /5
                          </b>
                        )}
                    </span>
                  </h5>
                  {/* <b className="rating-count">
                      ({parseInt(tutorDetails.rating_count)} {this.props.t("review")}'s)
                    </b> */}
                </div>

                <div className="camp-profile-details mb-2">
                  <div className="camp-profile-info  tutor-desc">
                    {_.get(tutorDetails, 'bio', '') !== ''
                      ? parser(
                        NurtemUtils.urlify(_.get(tutorDetails, 'bio', ''))
                      )
                      : ''}
                    {_.get(tutorDetails, 'services', []).length > 0 && (
                      <>
                        <div className="about-title mt-3">
                          {' '}
                          <h6>{this.props.t('onboardingTutor14')} </h6>
                        </div>
                        <div className="camps-class-tags-container">
                          {tutorDetails &&
                            tutorDetails.services &&
                            tutorDetails.services.length > 0 &&
                            tutorDetails.services.map((service, index) => (
                              <Link key={index} to={`/search/${service.name}`}>
                                <span className="camps-class-tags-course-banner">
                                  <ul style={{ listStyle: 'none' }}>
                                    <li>{service.name}</li>
                                  </ul>{' '}
                                </span>
                              </Link>
                            ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="profilee-conatiner">
              <div className="tutor-public-profile-container classes-corousal">
                <div className="row">
                  <div
                    ref={this.myAwardRef}
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  >
                    {tutorDetails &&
                      tutorDetails.tagline &&
                      tutorDetails.tagline.length > 0 ? (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="about-title mt-2 mb-2">
                            {this.props.t('aboutTutor')}
                            {/* tutorDetails && _.startCase(tutorDetails.firstname) */}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="about-text">
                            {/* <Truncate
                              lines={
                                this.viewCount(tutorDetails.tagline) >
                                  1000
                                  ? 25
                                  : 25
                              }
                              dangerouslySetInnerHTML={{
                                __html: tutorDetails.tagline
                              }}
                            /> */}
                            <Linkify as="p">
                              {renderHTML(
                                _.truncate(tutorDetails.tagline, {
                                  length: 2500,
                                  omission: ' ...',
                                })
                              )}
                            </Linkify>
                          </div>
                        </div>
                        {!_.isEmpty(businesshours) ? (
                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                            <h6
                              className="Business-hours-coverpic about-title"
                              style={{ textAlign: 'left', marginBottom: 18 }}
                            >
                              Business Hours
                            </h6>
                            <div className="businuess-hours-content">
                              {businesshours.map((hour, index) => (
                                <div
                                  className="camp-profile-details"
                                  key={`m${index}`}
                                >
                                  <div>
                                    <div
                                      key={`m2${index}`}
                                      className="camp-profile-info row"
                                      style={{ fontSize: 16 }}
                                    >
                                      <div
                                        className="col-1"
                                        style={{
                                          fontWeight: 600,
                                          marginRight: 10,
                                        }}
                                      >
                                        {hour.day.substring(0, 3)}
                                      </div>
                                      <div
                                        className="col-7 time"
                                        style={{ textAlign: 'left' }}
                                      >
                                        {dayjs(hour.startTime).format('h:mm A')}{' '}
                                        -{dayjs(hour.endTime).format('h:mm A')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    {tutorDetails &&
                      tutorDetails.credentials &&
                      tutorDetails.credentials.length > 0 && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div
                              ref={this.myClassesRef}
                              className="about-title mt-2 mb-2"
                            >
                              {this.props.t('awards')}
                            </div>
                          </div>
                          <div
                            className="full-width"
                            style={{ paddingTop: 18 }}
                          >
                            {tutorDetails.credentials.map(
                              (value, key) => (
                                <div
                                  key={`credentials${key}`}
                                  className="award-cer"
                                >
                                  <ul
                                    style={{
                                      listStyle: 'disc',
                                      marginBottom: 5,
                                    }}
                                  >
                                    {' '}
                                    <li>{value}</li>
                                  </ul>
                                  {/* <Badge className="badge mb-2 mr-2">{value}</Badge> */}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">


                    <ClassList
                      tutorDetails={tutorDetails}
                      pageSize={"4"}
                      t={this.props.t}
                      viewClassCamps={viewClassCamps}
                      scrollToTop={scrollToTop}
                      type="classes"
                      title={"classesTutor"}
                    />

                    <ClassList
                      tutorDetails={tutorDetails}
                      pageSize={"4"}
                      t={this.props.t}
                      viewClassCamps={viewClassCamps}
                      scrollToTop={scrollToTop}
                      type="camps"
                      title={"campsTutor"}
                    />

                    <ClassList
                      tutorDetails={tutorDetails}
                      pageSize={"4"}
                      t={this.props.t}
                      viewClassCamps={viewClassCamps}
                      scrollToTop={scrollToTop}
                      type="events"
                      title={"competitionsTutor"}
                    />

                  </div>

                  <div
                    ref={this.myEventRef}
                    className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    style={{ marginBottom: 15 }}
                  >
                    {tutorDetails &&
                      tutorDetails.whats_new &&
                      tutorDetails.whats_new.length > 0 && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="about-title mt-2 mb-2">
                              {this.props.t('events')}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="announcements">
                              <div className="announcements-text">
                                {this.removeHTMLTags(
                                  _.get(this, 'props.provider.whats_new', '')
                                ).trim() !== '' ? (
                                  renderHTML(tutorDetails.whats_new)
                                ) : !_.isEmpty(tutorDetails) ? (
                                  'If you need classes at different day and time, reach support@nurtem.com or call 408-365-4708 We will be able to open new slots based on request and availability.'
                                ) : (
                                  <div>
                                    {' '}
                                    <EmptyAnime AnimeType="EmptyUserSide" />{' '}
                                    <p className="empty-animations">
                                      No Announcement/Events Found
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>

                  {tutorDetails.rating_count ? <div
                    className="row"
                    style={{ paddingLeft: 15, width: '100%', marginBottom: 15 }}
                    ref={this.myRatingRef}
                  >
                    <Ratings session={tutorDetails} type="provider" />
                  </div> : ''}

                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      {tutorDetails && tutorDetails.amenities && tutorDetails.amenities.length > 0 && (
                        <div className="row" style={{ marginTop: 15 }}>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="about-title mt-4 mb-3">{this.props.t('facilites')}</div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="announcements">
                              <div className="announcements-text ml-2">
                                <div className="row">
                                  {tutorDetails.amenities.map((value, key) => (
                                    <div key={value + key} className="amenities col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                      {_.get(value, value) === 'Free Wi-Fi' ? <AiOutlineWifi className="mr-4" style={{ color: 'rgb(150 0 0)', fontSize: '20px' }} /> : <span alt="" aria-hidden="true" role="img" className="icon--24-checkmark-v2 css-106vfgv"><svg width="24" height="24" className="icon_svg mr-3"><path d="M9.46 17.52a1 1 0 01-.71-.29l-4-4a1.004 1.004 0 111.42-1.42l3.25 3.26 8.33-8.34a1.004 1.004 0 011.42 1.42l-9 9a1 1 0 01-.71.37z" /></svg></span>}
                                      {value}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div> */}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    {this.tutorList()}
                  </div>
                </div>
                <div ref={this.myMediaRef}>
                  {tutorDetails &&
                    tutorDetails.media_links &&
                    tutorDetails.media_links.length > 0 && (
                      <div className="row">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ marginTop: 10 }}
                        >
                          <div className="about-title mb-3 mt-4">
                            {this.props.t('media')}
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="media-youtube">
                            <div className="row">
                              {tutorDetails.media_links.map(
                                (value, key) => (
                                  <div
                                    key={`media_links_${key}`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4"
                                  >
                                    {value && !_.isEmpty(value) && (
                                      <div className="media-image-set mb-1">
                                        <div className="embed-responsive embed-responsive-16by9 mb-2 mb-lg-0">
                                          {renderHTML(
                                            embed(value) ? embed(value) : value
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                {/* <>
                    {tutorDetails && tutorDetails.soundcloud_url && tutorDetails.soundcloud_url.length > 0 &&
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="about-title mb-3 mt-4">Sound Cloud</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="media-youtube">
                            <div className="row">
                              {tutorDetails.soundcloud_url.map((value, key) => (
                                <div key={'media_links_' + key} className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 mb-4">
                                  {value && !(_.isEmpty(value)) &&
                                    <div className="media-image-set mb-1">
                                      <ReactPlayer
                                        width={"350px"}
                                        height={"170px"}
                                        url={value}
                                      />
                                    </div>
                                  }
                                </div>
                              ))}
                            </div>
  
                          </div>
                        </div>
                      </div>
                    }
                  </> */}
              </div>
            </div>
          </div>
        </div>
        <FloatButton.BackTop className="back-to-top" />
      </div>)
    } else {
      return (<div>
        <EmptyAnime AnimeType="EmptyUserSide" />
      </div>)
    }

  }
}

const mapStateToProps = (state) => {
  console.log('state', state)
  return {
    classesList: state.tutorPublicProfile.tutorClasses,
    campsList: state.campsDetails.campsList,
    CompetitionsList: state.competitionsDetails.providerCompetitions,
    campListloading: state.campsDetails.campListloading,
    provider: state.tutorPublicProfile.tutorPublicDetails,
    errorOccurred: state.tutorPublicProfile.errorOccurred,
    loggedInUserDetails: state.authentication,
    classListloading: state.tutorPublicProfile.classesListLoading,
    tutorSingleClassesDetails: state.classesDetails.tutorClassDetails,
    viewAllClassTabs: state.services.viewAllClassTabs,
    tutorDetails: state.tutorPublicProfile.tutorPublicDetails,
    ClaimBusinessResponse: state.authentication.ClaimBusinessResponse,
    requestParams: common.reqFromHeader(state.search),
    generalList: state.search.generalList,
  }
}

const mapDispatchToProps = (dispatch) => ({

  onGetClassesList: (providerId) =>
    dispatch(tutorAction.getTutorClasses(providerId)),
  onGetCampsList: (providerId) =>
    dispatch(tutorAction.getCampsList(providerId)),
  getCompetitionsList: (requestParams) =>
    dispatch(competitionActions.getProviderCompetitions(requestParams)),

  onGetClasseCampList: (rpGeneral) =>
    dispatch(searchAction.onGetClasseCampList(rpGeneral)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorPublicProfile))
