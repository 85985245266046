/**
 * @author ramsteyn17@gmail.com
 * @dated 11/02/2023
 * This is to change the current password,forget password,delete account for User
 */

import React, { Component } from 'react'
import { Button, Table, message, Modal, Input, Tabs, Steps } from 'antd'
import {
  AiOutlineCopy,
  AiFillLock,
  AiFillEdit,
  AiOutlineEye,
  AiOutlineEyeInvisible,
} from 'react-icons/ai'
import { Switch } from '../../../components/antd-components/index.tsx'
import { Scrollbars } from 'react-custom-scrollbars'
import './settings.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as actions from '../../../store/actions/authentication/authentication'
import * as userAction from '../../../store/actions/users'
import axios from '../../../utils/axios'
import { withErrorHandler } from '../../../utils/handler'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import { CopyToClipboard } from '../../../components/re-usable/copy-clipboard/index'
import { TbPasswordUser, TbCashBanknote } from 'react-icons/tb'
import { RxCrossCircled } from 'react-icons/rx'
import { FcList } from 'react-icons/fc'
import css from '../../../utils/CSSUtility'
import { HiOutlineSun } from 'react-icons/hi'
import { FaMoon } from 'react-icons/fa'

const deletionDate = dayjs().add(30, 'days').format('MM/DD/YYYY')

class UserResetPassword extends Component {
  columns = [
    {
      title: this.props.t('nameLabel'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: this.props.t('enrollLabel8'),
      dataIndex: 'student_id',
      key: 'student_id',
    },
    {
      title: this.props.t('Action'),
      dataIndex: 'action',
      key: 'action',
    },
  ]

  constructor(props) {
    super(props)
    this.state = {
      loginType: '',
      copied: false,
      visible: false,
      passvisible: false,
      forgotvisible: false,
      autpayvisible: false,
      selectedReason: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      errorMessage: '',
      current: 0,
      reasonFilled: false,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    if (
      _.get(this, 'props.userBaseProfile.details.user.account_type') !==
      'dependent'
    ) {
      this.props.getDependentUsers({ limit: 100 })
    }
    if (_.get(this, 'props.userBaseProfile.details.isAutopayEnabled', false)) {
      this.setState({ autpayvisible: true })
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.resetPasswordResponse !== this.props.resetPasswordResponse) {
      if (
        this.props.resetPasswordResponse &&
        this.props.resetPasswordResponse.status === 200
      ) {
        this.setState({ isLoading: false })
        this.setState({ visible: false })
        this.setState({ passvisible: false })
        this.setState({ forgotvisible: false })
        this.onLogout()
      } else if (
        (this.props.resetPasswordResponse &&
          this.props.resetPasswordResponse.status === 201) ||
        this.props.resetPasswordResponse.status === 400
      ) {
        this.setState({ isLoading: false })
        this.setState({ visible: false })
        this.setState({ passvisible: false })
        this.setState({ forgotvisible: false })
      }
    }
  }

  showModalForgot = () => {
    this.setState({ forgotvisible: true })
  }

  showModalAutopay = () => {
    this.setState({ autpayvisible: true })
  }

  showModal = () => {
    this.setState({ visible: true })
  }

  showModalPass = () => {
    this.setState({ passvisible: true })
  }

  handleForgot = () => {
    this.sendMail(_.get(this.props.userBaseProfile, 'details.email', ''))
    this.setState({ isLoading: true })
  }

  handleDisableAutopay = () => {
    this.sendMail(_.get(this.props.userBaseProfile, 'details.email', ''))
    this.setState({ isLoading: true })
  }

  handleOk = () => {
    this.onDelete(
      _.get(this.props.userBaseProfile, 'details', ''),
      this.state.selectedReason
    )
  }

  PasswordCheck = () => {
    if (this.state.passwordRecieved) {
      this.props.onPasswordCheck({ password: this.state.passwordRecieved })
      this.setState({ errorMessage: null })
    } else {
      this.setState({ errorMessage: 'Please Enter Password' })
    }
  }

  handlePassOk = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state
    const regex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
    let errorMessage = ''
    if (
      confirmPassword.length === 0 &&
      newPassword.length === 0 &&
      oldPassword.length === 0
    ) {
      errorMessage = this.props.t('settings1')
    } else if (confirmPassword.length === 0 || oldPassword.length === 0) {
      errorMessage = this.props.t('settings1')
    } else if (newPassword !== confirmPassword) {
      errorMessage = this.props.t('settings2')
    } else if (confirmPassword === oldPassword) {
      errorMessage = this.props.t('settings3')
    } else if (!regex.test(newPassword)) {
      errorMessage = this.props.t('settings4')
    } else if (!regex.test(confirmPassword)) {
      errorMessage = this.props.t('settings5')
    }

    if (errorMessage) {
      this.setState({ errorMessage })
      return
    }
    this.onPassChange(
      _.get(this.props.userBaseProfile, 'details', ''),
      oldPassword,
      confirmPassword
    )
  }

  handleCancel = () => {
    this.setState({ visible: false })
    this.setState({ passvisible: false })
    this.setState({ forgotvisible: false })
    this.setState({ autpayvisible: false })
  }

  onDelete = (userInfo, reason) => {
    const request = {
      username: userInfo.email,
      id: userInfo.user.id,
      type: ['user'],
    }
    if (reason) {
      request.reason = reason
    } else {
      request.reason = null
    }
    this.props.onDeleteAccount(request)
    //after a dely logout
    setTimeout(() => {
      this.onLogout()
    }, 1000)
    this.setState({ isLoading: true })
  }

  onPassChange = (userInfo, old, pass) => {
    const request = {
      username: userInfo.email,
      id: userInfo.user.id,
      oldPassword: old,
      password: pass,
    }
    this.props.onPasswordChange(request)
    this.setState({ isLoading: true })
  }

  onChange = (event) => {
    const target = event
    if (target) {
      this.setState({
        selectedReason: target,
      })
    }
  }

  sendMail = (value) => {
    const request = {
      email: value,
    }
    this.props.onResetPassword(request)
  }

  onLogout = () => {
    this.props.onLogout()
  }

  success = () => {
    message.success({ content: this.props.t('copySuccess'), duration: 3 })
  }

  next = () => {
    if (this.state.current === 0 && !this.state.reasonFilled) {
      // If current step is 0 (reason_form) and text area is empty, don't proceed
      return
    }
    this.setState((prevState) => ({
      current: prevState.current + 1,
    }))
  }

  handleReasonChange = (event) => {
    const { value } = event.target
    this.setState({
      selectedReason: value,
      reasonFilled: value.trim() !== '', // Check if text area is filled
    })
  }

  prev = () => {
    this.setState((prevState) => ({
      current: prevState.current - 1,
    }))
  }

  handleAutoPay = (e) => {
    this.setState({ autpayvisible: e })
    const userFormData = _.get(this.props.userBaseProfile, 'details', '')
    userFormData.isAutopayEnabled = e
    this.props.updateProfile(userFormData)
  }

  render() {
    const user_data = _.get(this.props.userBaseProfile, 'details', '')
    console.log("user_data>>>>>",user_data)
    const userIdDatset = []
    const dependent_users = this.props.dependentUsers
    console.log("user_data>>>>>dependent_users",dependent_users)

    if (
      dependent_users &&
      dependent_users.items &&
      dependent_users.items.length > 0
    ) {
      dependent_users.items.forEach((each, index) => {
        const temp = {
          key: _.get(each, 'student_id'),
          name: _.get(each, 'firstname'),
          student_id: <div id="myInput">{_.get(each, 'student_id')}</div>,
          action: (
            <div>
              {' '}
              <CopyToClipboard
                text={_.get(each, 'student_id')}
                onCopy={() => this.setState({ copied: true })}
              >
                <button onClick={this.success} className="btn btn-accept  mr-1">
                  {' '}
                  <AiOutlineCopy
                    style={{ fontSize: 20 }}
                    className="mr-1"
                  />{' '}
                  Copy
                </button>
              </CopyToClipboard>{' '}
            </div>
          ),
        }
        userIdDatset.push(temp)
      })
    }

    const dataSource = [
      {
        key: _.get(user_data, 'student_id'),
        name: _.get(user_data, 'firstname'),
        student_id: <div id="myInput">{_.get(user_data, 'student_id')}</div>,
        action: (
          <div>
            {' '}
            <CopyToClipboard
              text={_.get(user_data, 'student_id')}
              onCopy={() => this.setState({ copied: true })}
            >
              <button onClick={this.success} className="btn btn-accept  mr-1">
                {' '}
                <AiOutlineCopy style={{ fontSize: 20 }} className="mr-1" /> Copy
              </button>
            </CopyToClipboard>{' '}
          </div>
        ),
      },
      ...userIdDatset,
    ]

    const { current } = this.state

    const reason_form = {
      key: 'reason_form',
      title: 'Enter the Reason',
      content: (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <p className="setting-page-text">{this.props.t('settings9')}</p>
          <p className="setting-page-text">{this.props.t('settings10')}</p>
          <textarea
            value={this.state.selectedReason}
            onChange={this.handleReasonChange}
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '10px',
            }}
            className="ant-input-affix-wrapper mt-2 mb-2"
            rows={5}
            placeholder="Write your reason  here (max. 500 characters)"
          />
          {!this.state.reasonFilled && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              Reason is required
            </div>
          )}
        </div>
      ),
    }

    const password_form = {
      key: 'password_form',
      title: 'Confirm Deletion',
      content: (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <div className="mb-3">
            <span className="setting-page-text">
              <span style={{ color: 'red' }}>{this.props.t('warning')}:</span>
              {this.props.t('settings14a')}
              {this.props.t('settings15a')}
              {this.props.t('settings16a')}({deletionDate}
              ).
              {this.props.t('settings17a')}
            </span>
          </div>
          <Input.Password
            size="large"
            prefix={<AiFillLock style={{ fontSize: 20 }} />}
            type="password"
            placeholder="Confirm Password"
            onChange={(e) =>
              this.setState({ passwordRecieved: e.target.value })
            }
            iconRender={(visible) =>
              visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
            }
          />
          {this.state.errorMessage && (
            <div style={{ color: 'red' }}>{this.state.errorMessage}</div>
          )}
          <div className="mt-2">
            {!this.props.passwordCheckResponse ? (
              <Button type="primary" onClick={this.PasswordCheck}>
                {this.props.t('settings18')}
              </Button>
            ) : (
              <Button type="primary" danger onClick={this.handleOk}>
                {this.props.t('settings19')}
              </Button>
            )}
          </div>
        </div>
      ),
    }

    const itemsList = [reason_form, password_form]

    const contentStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#999',
      marginTop: 16,
    }

    return (
      <div
        className="nurtem-card-design nurtem-table setting-table mb-3"
        style={{ padding: 0 }}
      >
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
        >
          <items
            tab={
              <span>
                <FcList
                  style={{
                    fontSize: '20px',
                    marginRight: '5px',
                  }}
                />
                {this.props.t('nurtemId')}
              </span>
            }
            key="1"
          >
            <div className="col-12">
              <div className="schedule-content mt-2">
                <Scrollbars
                  className=" hidden-scroll-x"
                  style={{ height: 250 }}
                  renderTrackHorizontal={(props) => (
                    <div
                      {...props}
                      className="track-horizontal"
                      style={{ display: 'none' }}
                    />
                  )}
                  renderThumbHorizontal={(props) => (
                    <div
                      {...props}
                      className="thumb-horizontal"
                      style={{ display: 'none' }}
                    />
                  )}
                >
                  {this.props.onClassesLoading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      dataSource={dataSource}
                      columns={this.columns}
                      pagination={false}
                    />
                  )}
                </Scrollbars>
              </div>
            </div>
          </items>
          <items
            tab={
              <span>
                <TbPasswordUser
                  style={{
                    color: 'darkolivegreen',
                    fontSize: '20px',
                    marginRight: '5px',
                  }}
                />
                {this.props.t('settings20')}
              </span>
            }
            key="3"
          >
            <div className="student-login-modal-inner">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <div className="row">
                  <div style={css.heading1}>{this.props.t('resetPass')}</div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mb-2">
                    <span style={css.heading3}>
                      {this.props.t('resetPass1')}{' '}
                      <span
                        style={{
                          cursor: 'pointer',
                          color: 'blue',
                          textDecoration: 'underline',
                        }}
                        onClick={this.showModalForgot}
                      >
                        {this.props.t('resetPass2')}
                      </span>
                    </span>

                    <Modal
                      title="Reset password "
                      open={this.state.forgotvisible}
                      onOk={this.handleForgot}
                      okText="Send Email"
                      confirmLoading={this.state.isLoading}
                      onCancel={this.handleCancel}
                    >
                      <p>{this.props.t('resetPass3')}</p>
                    </Modal>
                  </div>
                  <div style={css.heading2}>{this.props.t('password')}:</div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-2 mb-2 pr-md-0">
                    <Input
                      prefix={<AiFillLock style={{ fontSize: 20 }} />}
                      type="password"
                      placeholder="   ********"
                      disabled
                    />
                  </div>
                  <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4 mt-2 pl-md-0">
                    <Button
                      className="setting-button"
                      style={{ marginLeft: 10 }}
                      onClick={this.showModalPass}
                    >
                      <AiFillEdit style={{ fontSize: 20 }} />
                    </Button>
                    <Modal
                      title="Change password"
                      open={this.state.passvisible}
                      onOk={this.handlePassOk}
                      okText="Change password"
                      confirmLoading={this.state.isLoading}
                      onCancel={this.handleCancel}
                    >
                      <div className="confirm-title" style={{ fontSize: 16 }}>
                        {this.props.t('currPass')}
                      </div>
                      <Input.Password
                        className="ant-input-affix-wrapper mt-2 mb-2"
                        prefix={<AiFillLock style={{ fontSize: 20 }} />}
                        type="password"
                        placeholder="Old password"
                        iconRender={(visible) =>
                          visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                        }
                        onChange={(e) =>
                          this.setState({ oldPassword: e.target.value })
                        }
                      />
                      <div className="confirm-title" style={{ fontSize: 16 }}>
                        {this.props.t('newPass')}
                      </div>
                      <Input.Password
                        className="ant-input-affix-wrapper mt-2 mb-2"
                        prefix={<AiFillLock style={{ fontSize: 20 }} />}
                        type="password"
                        placeholder="New password"
                        iconRender={(visible) =>
                          visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                        }
                        onChange={(e) =>
                          this.setState({ newPassword: e.target.value })
                        }
                      />
                      <div className="confirm-title" style={{ fontSize: 16 }}>
                        {this.props.t('confirmPass')}
                      </div>
                      <Input.Password
                        className="ant-input-affix-wrapper mt-2 mb-2	"
                        prefix={<AiFillLock style={{ fontSize: 20 }} />}
                        type="password"
                        placeholder="New password"
                        iconRender={(visible) =>
                          visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />
                        }
                        onChange={(e) =>
                          this.setState({ confirmPassword: e.target.value })
                        }
                      />
                      {this.state.errorMessage && (
                        <div style={{ color: 'red' }}>
                          {this.state.errorMessage}
                        </div>
                      )}
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </items>

          {/* <items
            tab={
              <span>
                <TbCashBanknote
                  style={{
                    color: 'darkolivegreen',
                    fontSize: '20px',
                    marginRight: '5px',
                  }}
                />
                {this.props.t('autoPay')}
              </span>
            }
            key="4"
          >
            <div className="student-login-modal-inner">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <div className="row">
                  <div style={css.heading1}>
                    {this.props.t('manageAutoPay')}
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-0 mb-2">
                    <span style={css.heading3}>
                      {this.props.t('manageAutoPayDesc')}{' '}
                    </span>

                    <Switch
                      className=""
                      checkedChildren={<HiOutlineSun />}
                      unCheckedChildren={<FaMoon />}
                      checked={_.get(this.state, 'autpayvisible', false)}
                      onClick={(e) => this.handleAutoPay(e)}
                    />
                  </div>
                  <div style={css.heading2}></div>
                </div>
              </div>
            </div>
          </items> */}
          <items
            tab={
              <span>
                <RxCrossCircled
                  style={{
                    color: 'red',
                    fontSize: '20px',
                    marginRight: '5px',
                  }}
                />
                {this.props.t('closeAccount')}
              </span>
            }
            key="2"
          >
            <div className="container-fluid">
              <div className="container">
                <Steps current={current}>
                  {itemsList.map((item) => (
                    <Steps.Step key={item.key} title={item.title} />
                  ))}
                </Steps>
              </div>
              <div style={contentStyle}>{itemsList[current].content}</div>
              <div style={{ marginTop: 10, marginLeft: 5 }}>
                {current < itemsList.length - 1 && (
                  <Button
                    type="primary"
                    style={{ margin: '0 8px' }}
                    onClick={() => this.next()}
                  >
                    Next
                  </Button>
                )}
                {current > 0 && (
                  <Button
                    style={{ margin: '0 8px' }}
                    onClick={() => this.prev()}
                  >
                    Prev
                  </Button>
                )}
              </div>
            </div>
          </items>
        </Tabs>
        {console.log('state', this.props)}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userBaseProfile: state.userProfile.userBaseProfile,
  resetPasswordResponse: state.authentication.resetPasswordResponse,
  passwordCheckResponse: state.authentication.passwordCheckResponse,
  passwordCheckResponse: state.authentication.passwordCheckResponse,
  updateUserProfileInfo: state.userProfile.updateUserProfileInfo,
  dependentUsers: state.userProfile.dependentUsers,
})

const mapDispatchToProps = (dispatch) => ({
  userProfile: () => dispatch(userAction.userProfile()),
  onLogout: () => dispatch(actions.onLogout()),
  onResetPassword: (data) => dispatch(actions.resetPassword(data)),
  onDeleteAccount: (data) => dispatch(actions.deleteAccount(data)),
  onPasswordCheck: (data) => dispatch(actions.passwordCheck(data)),
  getDependentUsers: (data) => dispatch(userAction.getDependentUsers(data)),
  onPasswordChange: (data) => dispatch(actions.PasswordChange(data)),
  updateProfile: (data) => dispatch(userAction.updateUserProfileInfo(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(withTranslation()(UserResetPassword), axios))
