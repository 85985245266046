import React, { useState, useEffect } from 'react';
import {
  Rate, Spin, Card, Modal, Tooltip,
} from 'antd';
import { AiOutlineEye, AiOutlineShareAlt } from 'react-icons/ai';
import _ from 'lodash';
import { connect } from 'react-redux';
import { RWebShare } from 'react-web-share';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import * as userAction from '../../store/actions/users';
import * as ratings from '../../store/actions/public/rating';

const desc = ['Terrible', 'Bad', 'Normal', 'Good', 'Wonderful'];
function Rating(props) {
  const urlparams = queryString.parse(window.location.search);

  const [, setRefreshKey] = useState(0);
  const [, setProviderRefreshKey] = useState(0);
  const [tutRating, setTutRating] = useState(0);
  const [tutReview, setTutReview] = useState(null);
  const [clsRating, setClsRating] = useState(0);
  const [clsReview, setClsReview] = useState(null);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [isReviewModalVisible2, setIsReviewModalVisible2] = useState(false);
  const [reviewText, setReviewText] = useState('');

  const handleReviewOk = () => {
    // Submit API request with reviewText
    // console.log('createrate3==========', _.get(props, 'getRatingList'));
    const provider_id = _.get(props, 'provider.id') ? _.get(props, 'provider.id') : _.get(props, 'provider');
    const rating_id = _.get(props, 'getRatingList.details.id') ? _.get(props, 'getRatingList.details.id') : (_.get(props, 'getRatingList.items[0].id')) ? (_.get(props, 'getRatingList.items[0].id')) : (_.get(props, 'onCreateRating.details.id'));
    const body = {};
    if (props.type === 'camps') {
      body.id = rating_id;
      body.type = props.type;
      body.camp_details = props.session_details;
      body.type_details = props.camp_details;
      body.text = reviewText;
      body.provider = provider_id;
    } else if (props.type === 'classes') {
      body.id = rating_id;
      body.type = props.type;
      body.class_details = props.session_details;
      body.type_details = props.class_details;
      body.text = reviewText;
      body.provider = provider_id;
    } else if (props.type === 'competition') {
      body.id = rating_id;
      body.type = props.type;
      body.competition_details = props.session_details;
      body.type_details = props.competition_details;
      body.text = reviewText;
      body.provider = provider_id;
    } else if (props.type === 'event') {
      body.id = rating_id;
      body.type = props.type;
      body.event_details = props.session_details;
      body.type_details = props.event_details;
      body.text = reviewText;
      body.provider = provider_id;
    }
    // body.user = urlparams.userid
    props.initiateProviderReview(body);
    setTimeout(() => setRefreshKey((oldKey) => oldKey + 1), 2000);
    setIsReviewModalVisible(false);
    setReviewText('');
    return (
      <Spin delay={2000} />
    );
  };

  const handleReviewOk2 = () => {
    // Submit API request with reviewText
    const provider_id = _.get(props, 'provider.id') ? _.get(props, 'provider.id') : _.get(props, 'provider');
    const body = {};
    body.id = _.get(props, 'getTutorRatingList.details.id') ? _.get(props, 'getTutorRatingList.details.id') : (_.get(props, 'getTutorRatingList.items[0].id')) ? (_.get(props, 'getTutorRatingList.items[0].id')) : (_.get(props, 'onCreateRating.details.id'));
    body.type = 'provider';
    // body.session_details = props.session_details
    body.text = reviewText;
    body.provider = provider_id;
    // body.user = urlparams.userid
    props.initiateProviderReview(body);
    setTimeout(() => setRefreshKey((oldKey) => oldKey + 1), 2000);
    setIsReviewModalVisible2(false);
    setReviewText('');
    return (
      <Spin delay={2000} />
    );
  };

  const handleReviewCancel = () => {
    setIsReviewModalVisible(false);
    setIsReviewModalVisible2(false);
  };

  const handleReviewTextChange = (e) => {
    setReviewText(e.target.value.slice(0, 500));
  };

  useEffect(() => {
    if (urlparams && urlparams.userid && props.session_details) {
      const keyWord = props.type === 'camps' ? 'camp_details' : props.type === 'classes' ? 'class_details' : props.type === 'competition' ? 'competition_details' : props.type === 'event' ? 'event_details' : null;
      props.tutorListOfRatings({
        type: 'provider',
        provider: _.get(props, 'provider.id'),
        session_details: props.session_details,
        sort: 'created_at.desc',
        expand: 'session_details',
        user: urlparams.userid,
      });

      props.listOfRatings({
        type: props.type,
        [keyWord]: props.session_details,
        sort: 'created_at.desc',
        expand: keyWord,
        user: urlparams.userid,
      });
      setClsRating(_.get(props, 'getRatingList.items[0].no_of_stars', 0));
    }
  }, [props.session_details]);

  useEffect(() => {
    setClsRating(_.get(props, 'getRatingList.items[0].no_of_stars', 0));
    setClsReview(_.get(props, 'getRatingList.items[0].text'));
  }, [props.getRatingList]);

  useEffect(() => {
    setTutRating(_.get(props, 'getTutorRatingList.items[0].no_of_stars', 0));
    setTutReview(_.get(props, 'getTutorRatingList.items[0].text'));
  }, [props.getTutorRatingList]);

  useEffect(() => {
    setClsReview(_.get(props, 'getRatingList.items[0].text'));
  }, [props.onCreateReviews]);

  useEffect(() => {
    setTutReview(_.get(props, 'getTutorRatingList.items[0].text'));
  }, [props.onCreateReviews]);

  const createYourRatings = (value) => {
    setClsRating(value);
    const body = {};
    if (props.type === 'camps') {
      body.type = props.type;
      body.camp_details = props.session_details;
      body.type_details = props.camp_details;
      body.no_of_stars = value;
      body.provider = _.get(props, 'provider');
      body.user = urlparams.userid;
    } else if (props.type === 'classes') {
      body.type = props.type;
      body.class_details = props.session_details;
      body.type_details = props.class_details;
      body.no_of_stars = value;
      body.provider = _.get(props, 'provider.id');
      body.user = urlparams.userid;
    } else if (props.type === 'competition') {
      body.type = props.type;
      body.competition_details = props.session_details;
      body.type_details = props.competition_details;
      body.no_of_stars = value;
      body.provider = _.get(props, 'provider.id');
      body.user = urlparams.userid;
    } else if (props.type === 'event') {
      body.type = props.type;
      body.event_details = props.session_details;
      body.type_details = props.event_details;
      body.no_of_stars = value;
      body.provider = _.get(props, 'provider.id');
      body.user = urlparams.userid;
    }
    props.initiateRating(body, (res) => {
      if (res.status === 200 && props.type === 'classes') {
        props.onClassDetail({ id: urlparams.campId, expand: 'class_details,user,dependent_user,provider' });
      }
    });
    setTimeout(() => setRefreshKey((oldKey) => oldKey + 1), 2000);
    return (
      <Spin delay={2000} />
    );
  };

  const createYourRatingsForTutor = (value) => {
    const provider_id = _.get(props, 'provider.id') ? _.get(props, 'provider.id') : _.get(props, 'provider');
    setTutRating(value);
    const body = {};
    body.type = 'provider';
    body.session_details = props.session_details;
    body.no_of_stars = value;
    body.provider = provider_id;
    body.user = urlparams.userid;
    props.initiateRatingForTutor(body);
    setTimeout(() => setProviderRefreshKey((oldKey) => oldKey + 1), 2000);
  };

  const useMatchMedia = (mediaQuery, initialValue) => {
    const [isMatching, setIsMatching] = useState(initialValue);
    useEffect(() => {
      const watcher = window.matchMedia(mediaQuery);
      setIsMatching(watcher.matches);
      const listener = (matches) => {
        setIsMatching(matches.matches);
      };
      if (watcher.addEventListener) {
        watcher.addEventListener('change', listener);
      } else {
        watcher.addEventListener(listener);
      }
      return () => {
        if (watcher.removeEventListener) {
          return watcher.removeEventListener('change', listener);
        }
        return watcher.removeEventListener(listener);
      };
    }, [mediaQuery]);
    return isMatching;
  };

  // const isMobileResolution = useMatchMedia('(max-width:991px)', true)
  const isWebResolution = useMatchMedia('(min-width:992px)', true);
  const handleOnClick = () => {
    if (navigator.share) {
      navigator
        .share({
          title: '`${postTitle} | ${siteTitle}`,',
          text: 'Check out',
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
        })
        .catch((error) => {
          console.error('Something went wrong sharing the blog', error);
        });
    }
  };

  return (
    <Card style={{ height: '100%', maxHeight: '130px' }}>

      <Card.Grid style={{ width: 'unset', padding: '12px' }}>
        <div className="rate-your-text">Rate your Tutor</div>
        <div className="star-rating">
          <Rate
            tooltips={desc}
            onChange={(value) => createYourRatingsForTutor(value)}
            value={tutRating}
            disabled={tutRating > 0}
          />
        </div>
        {tutRating > 0 && (
          <div
            className="write-review"
            onClick={() => !tutReview && setIsReviewModalVisible2(true)}
            style={{
              color: 'blue',
              cursor: 'pointer',
              marginTop: '8px',
              textDecoration: 'underline',
              pointerEvents: tutReview ? 'none' : 'auto',
              opacity: tutReview ? 0.5 : 1,
            }}
          >
            {props.t('writeReview')}
          </div>
        )}
      </Card.Grid>

      <Modal
        title="Write a review"
        open={isReviewModalVisible2}
        onOk={handleReviewOk2}
        onCancel={handleReviewCancel}
        okButtonProps={{ disabled: reviewText.length === 0 }}
      >
        <textarea
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
          rows={5}
          placeholder="Write your review here (max. 500 characters)"
          value={reviewText}
          onChange={handleReviewTextChange}
        />
        <div>
          {500 - reviewText.length}
          {' '}
          {props.t('writeReview1')}
        </div>
      </Modal>

      <Card.Grid style={{ width: 'unset', padding: '12px' }}>
        <div className="rate-your-text">{props.type === 'camps' ? 'Rate your camp' : props.type === 'classes' ? 'Rate your classes' : props.type === 'competition' ? 'Rate Your Competition' : props.type === 'event' ? 'Rate Your Event' : ''}</div>
        <div className="star-rating">
          <Rate
            tooltips={desc}
            onChange={(value) => createYourRatings(value)}
            value={clsRating}
            disabled={clsRating > 0}
          />
        </div>
        {clsRating > 0 && (
          <div
            className="write-review"
            onClick={() => !clsReview && setIsReviewModalVisible(true)}
            style={{
              color: 'blue',
              cursor: 'pointer',
              marginTop: '8px',
              textDecoration: 'underline',
              pointerEvents: clsReview ? 'none' : 'auto',
              opacity: clsReview ? 0.5 : 1,
            }}
          >
            {props.t('writeReview')}
          </div>
        )}
      </Card.Grid>

      <Modal
        title="Write a review"
        open={isReviewModalVisible}
        onOk={handleReviewOk}
        onCancel={handleReviewCancel}
        okButtonProps={{ disabled: reviewText.length === 0 }}
      >
        <textarea
          style={{
            width: '100%',
            borderRadius: '10px',
          }}
          rows={5}
          placeholder="Write your review here (max. 500 characters)"
          value={reviewText}
          onChange={handleReviewTextChange}
        />
        <div>
          {500 - reviewText.length}
          {' '}
          {props.t('writeReview1')}
        </div>
      </Modal>

      <Card.Grid style={{ width: 'unset', padding: '12px' }}>
        <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="View">
          {props.type === 'classes' ? (
            <a href={`/view-class/${props.campUrl}`} rel="noopener noreferrer" target="_blank">
              <AiOutlineEye style={{ fontSize: 20, marginBottom: 10 }} className="mt-3 mr-2" />
            </a>
          )
            : props.type === 'camps' ? (
              <a href={`/view-camp/${props.campUrl}`} rel="noopener noreferrer" target="_blank">
                <AiOutlineEye style={{ fontSize: 20, marginBottom: 10 }} className="mt-3 mr-2" />
              </a>
            )
              : props.type === 'competition' ? (
                <a href={`/view-competition/${props.campUrl}`} rel="noopener noreferrer" target="_blank">
                  <AiOutlineEye style={{ fontSize: 20, marginBottom: 10 }} className="mt-3 mr-2" />
                </a>
              )
                : props.type === 'event' ? (
                  <a href={`/view-event/${props.campUrl}`} rel="noopener noreferrer" target="_blank">
                    <AiOutlineEye style={{ fontSize: 20, marginBottom: 10 }} className="mt-3 mr-2" />
                  </a>
                )
                  : ''}
        </Tooltip>

        {isWebResolution ? (
          <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="Share">
            <RWebShare
              data={{
                text: 'Web Share - GfG',
                url: props.type === 'classes' ? `/view-class/${props.campUrl}`
                  : props.type === 'camps' ? `/view-camp/${props.campUrl}`
                    : props.type === 'competition' ? `/view-competition/${props.campUrl}`
                      : props.type === 'event' ? `/view-event/${props.campUrl}` : '',
                title: 'GfG',
              }}
              onClick={() => console.log('shared successfully!')}
            >
              <AiOutlineShareAlt
                style={{ fontSize: 20 }}
                className="eye mt-1"
                onClick={handleOnClick}
              />
            </RWebShare>
            {' '}

          </Tooltip>
        ) : (
          <AiOutlineShareAlt
            style={{ fontSize: 20 }}
            className="eye mt-1"
            onClick={handleOnClick}
          />
        )}
        {/* {isMobileResolution &&
                <>
                    <a href={`/view-camp/${props.campUrl}`} target="_blank">
                        <EyeOutlined className="mt-3 mr-2" />
                    </a>
                    <ShareAltOutlined
                        className='eye mt-1'
                        onClick={handleOnClick} />
                </>
            } */}
      </Card.Grid>

    </Card>
  );
}

const mapStateToProps = (state) =>
// console.log('createrate2', state);
({
  userClasses: state.users.userClasses,
  classDetailsView: state.classesDetails.singleClassView,
  rateClassesDetails: _.get(state, 'classesDetails.tutorClassDetails.details.average_rating'),
  providerRateClassesDetails: _.get(state, 'classesDetails.tutorClassDetails.details.provider.average_rating'),
  getRatingList: _.get(state, 'rating.onRatingList'),
  getTutorRatingList: _.get(state, 'rating.onTutorRatingList'),
  onCreateRating: _.get(state, 'rating.onCreateRating'),
  onCreateReviews: _.get(state, 'rating.onCreateReviews'),
  particularClassViewId: _.get(state, 'classesDetails.tutorClassDetails.details'),
  getUsersId: _.get(state, 'users.userBaseProfile.details.id'),
});
const mapDispatchToProps = (dispatch) => ({
  initiateRating: (data, callback) => dispatch(ratings.createRatings(data, callback)),
  initiateRatingForTutor: (data) => dispatch(ratings.createRatings(data)),
  initiateProviderReview: (data) => dispatch(ratings.createReview(data)),
  listOfRatings: (requestParams) => dispatch(ratings.listRatings(requestParams)),
  tutorListOfRatings: (requestParams) => dispatch(ratings.tutorListRatings(requestParams)),
  onClassDetail: (requestParams) => dispatch(userAction.getUserClassInfo(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Rating));
