import React, { useEffect, useState } from 'react'
import * as classaction from '../../../store/actions/tutor/common'
import { DEFAULTUSER } from '../../../utils/config/assets'
import { connect } from 'react-redux'
import _ from 'lodash'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import { Tabs, Table, Space } from 'antd'
import css from '../../../utils/CSSUtility'

const TutorStudentList = (props) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [type, setType] = useState('classes')
  useEffect(() => {
    let requestParams = {}
    requestParams.limit = pageSize
    requestParams.sort = 'created_at.desc'
    requestParams.expand = true
    requestParams.unique = false
    requestParams.page = currentPage
    requestParams.type = type
    if (type === 'classes') {
      props.getTotalStudents(requestParams)
    } else if (type === 'camps') {
      props.getTotalStudentsCamp(requestParams)
    } else {
      props.getTotalStudentsComp(requestParams)
    }
  }, [currentPage, type])

  const handlePageChange = (pagination) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }
  const handleTabChange = (key) => {
console.log("key===================",key)

    key === '2'
      ? setType('camps')
      : key === '3'
        ? setType('competitions')
        : setType('classes')
  }
console.log("type===================",type)
  const ListingClasscolumns = [
    {
      title: props.t('userLabel'),
      dataIndex: 'username',
      key: 'username',
      width: '20%',
      render: (text, record) => (
        <Space>
          <img
            src={record.userphoto}
            alt="User"
            style={{ width: 30, height: 30, borderRadius: '15px' }}
          />
          <span>{text}</span>
        </Space>
      ),
    },
    {
      title: type === 'camps' ? "Camp Name" : type === 'competitions' ? "Competition Name" : "Class Name",
      dataIndex: 'typename',
      key: 'typename',
      width: '20%',
    },
    {
      title: props.t('batName'),
      dataIndex: 'batchname',
      key: 'batchname',
      width: '20%',
    },
    // {
    //   title: props.t('batType'),
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: '10%',
    // },
    {
      title: props.t('proName'),
      dataIndex: 'provider',
      key: 'provider',
      width: '10%',
    },
    {
      title: props.t('joinedOn'),
      dataIndex: 'joinedon',
      key: 'joinedon',
      width: '10%',
    },
    // {
    //     title: props.t("Action"),
    //     key: 'action',
    //     width: '10%',
    //     render: (text, record) => (
    //         <Space size="middle">
    //             <div><button onClick={() => {
    //                 history.push(`${routeName.paymentReportFull.split(':')[0]}?classId=${record.typeid}&type=${record.type}&user=${record.userid}&usertype=${record.usertype}`);
    //             }} className="btn btn-accept  mr-1" >{props.t("tutorSidebarPaymentReport")}</button></div>
    //         </Space>
    //     ),
    // },
  ]
  const renderTable = (dataSource, totalKey) => {
    return (
      <div className="nurtem-table" style={{ overflowX: 'auto' }}>
        <Table
          columns={ListingClasscolumns}
          dataSource={dataSource.map((session) => ({
            ...session,
            batchname: session.batchname,
            username: session.username,
            userphoto: session.userphoto ? session.userphoto : DEFAULTUSER,
            type: session.type,
            provider: session.provider,
            typename: session.typename,
            joinedon: dayjs(session.joinedon).format('MMM DD, YY'),
            key: session.key,
          }))}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: _.get(props, totalKey, 10),
            showSizeChanger: false,
          }}
           onChange={(page) => handlePageChange(page, type)} 
        />
      </div>
    )
  }
  const renderClassTab = () => {
    return renderTable(
      _.get(props, 'TotalStudentList.items', []),
      'TotalStudentList.meta.total_count'
    )
  }
  const renderCampsTab = () => {
    return renderTable(
      _.get(props, 'TotalStudentListCamp.items', []),
      'TotalStudentListCamp.meta.total_count'
    )
  }
  const renderCompsTab = () => {
    return renderTable(
      _.get(props, 'TotalStudentListComp.items', []),
      'TotalStudentListComp.meta.total_count'
    )
  }
  const items = [
    {
      key: '1',
      label: props.t('totalStuInClass'),
      children: renderClassTab(),
    },
    {
      key: '2',
      label: props.t('totalStuInCamp'),
      children: renderCampsTab(),
    },
    {
      key: '3',
      label: props.t('totalStuInCompetition'),
      children: renderCompsTab(),
    },
  ]
  const render = () => {
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="nurtem-card-design mb-4">
          <div className="student-title"><span style={css.heading1}>{props.t("tutorSidebarStudentLists")}</span></div>
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
        </div>
      </div>
    )
  }
  return render()
}
const mapStateToProps = (state) => {
  return {
    businessTutorBaseProfile: state.tutorProfile.tutorBaseProfile,
    TotalStudentList: state.common.TotalStudentList,
    TotalStudentListCamp: state.common.TotalStudentListCamp,
    TotalStudentListComp: state.common.TotalStudentListComp,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getTotalStudents: (requestParams) =>
      dispatch(classaction.onTotalStudentList(requestParams)),
    getTotalStudentsCamp: (requestParams) =>
      dispatch(classaction.onTotalStudentListCamp(requestParams)),
    getTotalStudentsComp: (requestParams) =>
      dispatch(classaction.onTotalStudentListComp(requestParams)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorStudentList))
