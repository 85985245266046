import React, { useState, useEffect } from 'react'
import { Button, Flex, message, Row, Steps, theme, notification } from 'antd'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import queryString from 'query-string'
import MasterClassForm from './master-class-form'
import Header from '../../component/update-form-header'
import Batch from './batch'
import history from '../../../../utils/history'
import { Link } from 'react-router-dom';
import debounce from 'lodash/debounce'
import css from '../../../../utils/CSSUtility'
import * as routeName from '../../../../components/route-names';

function MultiStage(props) {
  const params = queryString.parse(window.location.search)
  const { type, label } = props
  const { token } = theme.useToken()
  const [current, setCurrent] = useState(0)

  // const Linkto = (type === 'classes' && routeName.tutorClasses || type === 'camps' && routeName.tutorCamps
  //   || type === 'competitions' && routeName.competitions || type === 'events' && routeName.events)

  const msg = () =>
    notification.info({
      message: 'Please create ' + label + ' then create Batch.',
      style: { zIndex: 600 },
    })
  const next = debounce(() => {
    const params = queryString.parse(window.location.search)
    if (params.id) {
      setCurrent(current + 1)
    } else {
      msg()
      setCurrent(0)
    }
  }, 500)

  const thisOne = debounce((val) => {
    if (params.id) {
      setCurrent(val)
    } else {
      msg()
      setCurrent(0)
    }
  }, 500)

  const [actionType, setActionType] = useState('add')

  useEffect(() => {
    const params = queryString.parse(window.location.search)
    setActionType(params.type)
    if (params.sid) {
      setCurrent(1)
    }
  }, [window.location.search])

  const class_form = {
    key: 'class_form',
    title: <Header actiontype={actionType} title={label} />,
    content: <MasterClassForm nextPage={next} title={label} type={type} />,
  }

  const session_form = {
    key: 'session_form',
    title: 'Batches',
    content: <Batch type={type} title={label} />,
  }

  const itemsList = [class_form, session_form]

  const contentStyle = {
    display: Flex,
    flexDirection: Row,
    alignItem: 'center',
    justifyContent: 'center',
    color: token.colorTextTertiary,
    marginTop: 16,
    paddingTop: 15,
  }

  const stepsLength = itemsList.length

  return (
    <div className="container-fluid">
      <div className="container">
        <Steps onChange={thisOne} current={current} items={itemsList} />
      </div>
      <div style={contentStyle}>{itemsList[current].content}</div>
      <div
        style={{
          marginTop: 24,
          marginLeft: 35,
        }}
      >
        {/* <Link to={Linkto}> */}
        <Button
          style={css.button}
          onClick={() => history.goBack()}
        >
          Back
        </Button>
        {/* </Link> */}
      </div>
    </div>
  )
}
const mapStateToProps = () => ({})

export default connect(mapStateToProps)(withTranslation()(MultiStage))
