import * as actionTypes from '../../actions/common/actionTypes';

const initialState = {
  ipapi: {},
};

const setApiState = (state, action) => ({
  ...state,
  [action.type]: action.payload,
});

const reducer = (state = initialState, action = null) => {
  switch (action.type) {
    case actionTypes.ipapi:
      return setApiState(state, action);

    default:
      return state;
  }
};

export default reducer;
