import React from 'react';
import { Button } from 'antd';
import { withTranslation } from 'react-i18next';

const acceptAllBtn = (params) => {
  console.log('acceptbtn======', params);
  return (
    <div className="multi-button">
      <Button
        style={{ marginRight: 10 }}
        type="primary"
        onClick={params.reject}
        disabled={
          !params.hasSelected
          // params.selectedRowCount === 1 ||
          || params.totalCount === 0
        }
        loading={params.loading}
        danger
      >
        {params.t('reject')}
      </Button>
      <Button
        type="primary"
        onClick={params.accept}
        disabled={
          !params.hasSelected
          //  params.selectedRowCount === 1 ||
          || params.totalCount === 0
        }
        loading={params.loading}
      >
        {params.t('accept')}
      </Button>
      <span style={{ marginLeft: 10 }}>
        {params.selectedRowCount > 1
          ? `Selected ${params.selectedRowCount} items`
          : ''}
      </span>
    </div>
  );
};
export default withTranslation()(acceptAllBtn);
