import React from 'react';
import { Tabs } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import './settings.css';
import { FiPhoneCall } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import ChangePassword from './change-password';
import ResetPassword from './setting';

const index = (props) => (
  <div className="login-page-container student-login-open">
    <div className="login-height settings-page setting-change-password" style={{ padding: 0, marginBottom: 20 }}>
      <Tabs
        defaultActiveKey="1"
        style={{ height: '100%' }}
        tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
      >
        <items
          tab={(
            <span style={{ padding: 10 }}>
              {props.t('settings8')}
            </span>
            )}
          key="1"
        >
          <div className="container">
            <div className="row center">
              <div className="col-1">&nbsp;</div>
              <div className="col-8 center-links m-4 " style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="m-2">
                  <div style={{ width: 150, height: 150 }}>
                    <img
                      className="turorprofileimg"
                      src={props.tutorBaseProfile.details.photo}
                      style={{
                        backgroundSize: 'cover', width: '100%', height: '100%', borderRadius: '100%', boxShadow: '1px 1px 5px',
                      }}
                      alt="img"
                    />
                  </div>
                </div>
                <div className="row m-2">
                  <span>
                    <h4>
                      {' '}
                      {props.tutorBaseProfile.details.firstname}
                      {' '}
                      {props.tutorBaseProfile.details.lastname}
                      {' '}
                    </h4>
                    {' '}
                  </span>
                </div>
                <div className="row m-2">
                  <span>
                    {' '}
                    <FiPhoneCall style={{ marginRight: 20, fontSize: 18, color: 'green' }} />
                    {props.tutorBaseProfile.details.mobile_number}
                    {' '}

                  </span>
                </div>
                <div className="row m-2">
                  <span>
                    <MdEmail style={{ marginRight: 10, fontSize: 18 }} />
                    {props.tutorBaseProfile.details.email}
                    {' '}

                  </span>
                </div>
              </div>
              <div className="col-1">&nbsp;</div>
            </div>
          </div>
        </items>

        <items
          tab={(
            <span style={{ padding: 10 }}>
              {props.t('changePassword')}
            </span>
            )}
          key="2"
        >
          {' '}
          <div className="col-12">
            <ChangePassword />
          </div>
        </items>

        <items
          tab={(
            <span style={{ padding: 10 }}>
              {props.t('closeAccount')}
            </span>
            )}
          key="3"
        >
          <div className="col-12 ">
            <ResetPassword />
          </div>
        </items>
      </Tabs>
    </div>
  </div>
);
const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
});

export default connect(
  mapStateToProps,
)(withTranslation()(index));
