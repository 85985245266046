import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// Get the browser's language
// let browserLanguage = navigator.language || navigator.userLanguage;

// browserLanguage = browserLanguage === 'en-US' ? 'en' : browserLanguage;

// // Dynamically import the corresponding dayjs locale based on the browser language
// import(`dayjs/locale/${browserLanguage}`).then((localeModule) => {
//   dayjs.locale(localeModule.default);
// });

export default dayjs;
