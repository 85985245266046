import _ from 'lodash';

export const NurtemUtils = {

  // return postcode if exits in a give address components
  fetchPostalCode: (address_components) => {
    let postal_code = null;
    address_components.forEach((value) => {
      value.types.forEach((type) => {
        if (type === 'postal_code') {
          postal_code = value.long_name;
        }
      });
    });
    return postal_code;
  },

  // checks the given url is valid youtubeUrl
  checkTheUrlIsValidYoutubeOrVimeoUrl: (url) => {
    const p = /^(http:\/\/|https:\/\/)(vimeo\.com|youtu\.be|www\.youtube\.com)\/([\w\/]+)([\?].*)?$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  },

  // check the input value is valid iframe
  isValidIframe: (iframeInput) => {
    if (iframeInput.match('(?<=<iframe ).*?(?=></iframe>)')) {
      return iframeInput;
    }
    return false;
  },

  // checks the given url is valid SoundcloudUrl
  checkTheUrlIsValidSoundcloudUrl: (url) => {
    const isSoundcloudUrl = new URL(url).hostname === 'soundcloud.com' || new URL(url).hostname === 'snd.sc';
    if (isSoundcloudUrl) {
      return 'https://://';
    }
    return false;
  },

  urlify: (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (text && String(text).match(urlRegex)) {
      return String(text).replace(urlRegex, (url) => {
        const convert_url = _.head(_.split(url, '</', 1));
        return `<a target="_blank" href="${convert_url}">${convert_url}</a>`;
      });
    }
    return text;
  },

  // Get Application baseURL
  getApplicationBaseURL: _.toString(_.get(window, 'location.origin', null)),
};
