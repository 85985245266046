import _ from 'lodash';
import * as actionTypes from '../../actions/users/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  countOfSessionsAttended: 0,
  updateUserProfile: false,
  loading: {
    loading: false,
  },
  classLoading: false,
  campsLoading: false,
  dependentUserLoading: false,
  serviceListLoading: false,
  profileLoading: false,
  noteLoading: false,
  isOpenedProfileModal: false,
  profileModalvalue: false,
  userInfo: {},
  notes: {},
  sendNotesInfo: {},
  userClassInfo: {},
  userClassIndividualSuccess: {},
  userCampsIndividualSuccess: {},
  userCompsIndividualSuccess: {},
  userEventsIndividualSuccess: {},
  userBaseProfile: {},
  userClasses: {},
  todayClasses: {},
  dependentUsers: {},
  userPaymentList: {},
  updateUserProfileInfo: {},
  guestUserFlowStep: 1,
  guestUserDepententSelect: 'Myself',
  createDependentUser: {},
  serviceList: [],
  updateDependentUser: {},
  deleteDependentUser: {},
  bookingsResponse: {},
  onBookingsCreatedLoading: false,
  campBookingsResponse: {},
  couponsApplyResponse: {},
  trailBookingResponce: {},
  otherBookingClass: {},
  userCamps: {},
  userCompetitions: {},
  userEvents: {},
  userCompetitionsInfo: {},
  userEventsInfo: {},
  userCampsInfo: {},
  student_payment_information: [],
  [actionTypes.providerNotesForUser]: {},
  couponsApplyCodeLoading: false,
  offline_payment_object: [],
  balance_amount: 0,
  monthlypayment: {},
};
const userClassNoOfsessionsAttended = (state, action) => ({ ...state, countOfSessionsAttended: action.success });

const updateUserProfile = (state, action) => ({ ...state, updateUserProfile: action.loading });

const onCreateDependentUser = (state, action) => ({ ...state, createDependentUser: action.success });

const onserviceList = (state, action) => ({ ...state, serviceList: action.success });

const onUpdateDependentUser = (state, action) => ({ ...state, updateDependentUser: action.success });

const onDeleteDependentUser = (state, action) => ({ ...state, deleteDependentUser: action.success });
const couponsApplyCodeLoading = (state, action) => ({ ...state, couponsApplyCodeLoading: action.success });

const onUpdateUserProfileInfo = (state, action) => ({ ...state, updateUserProfileInfo: action.success });

const guestUserFlowStep = (state, action) => ({ ...state, guestUserFlowStep: action.success });
const guestUserDepententSelect = (state, action) => ({ ...state, guestUserDepententSelect: action.success });

const onGetUserUserPaymentList = (state, action) => ({ ...state, userPaymentList: action.success });

const onBookingsResponse = (state, action) => ({ ...state, bookingsResponse: action.success });
const onBookingsCreatedLoading = (state, action) => ({ ...state, onBookingsCreatedLoading: action.success });
const campBookingsResponse = (state, action) => ({ ...state, campBookingsResponse: action.success });
const onCouponsApplyResponse = (state, action) => ({ ...state, couponsApplyResponse: action.success });
const onTrailBookingResponse = (state, action) => ({ ...state, trailBookingResponce: action.success });

const onGetUserProfile = (state, action) => ({ ...state, userBaseProfile: action.success });

const onGetUserClasses = (state, action) => ({ ...state, userClasses: action.success });

const userClassIndividualSuccess = (state, action) => ({ ...state, userClassIndividualSuccess: action.success });

const userCampsIndividualSuccess = (state, action) => ({ ...state, userCampsIndividualSuccess: action.success });

const userCompsIndividualSuccess = (state, action) => ({ ...state, userCompsIndividualSuccess: action.success });

const userEventsIndividualSuccess = (state, action) => ({ ...state, userEventsIndividualSuccess: action.success });

const onGetTodayClasses = (state, action) => ({ ...state, todayClasses: action.success });

const onGetDependetUser = (state, action) => ({ ...state, dependentUsers: action.success });

const onUserClassInfo = (state, action) => ({ ...state, userClassInfo: action.success });

const onUserInfo = (state, action) => ({ ...state, userInfo: action.success });

const onOtherBookingClass = (state, action) => ({ ...state, otherBookingClass: action.success });

const onGetNotes = (state, action) => ({ ...state, notes: action.success });

const onSendNotes = (state, action) => ({ ...state, sendNotesInfo: action.success });

const enableClassLoading = (state, action) => updateObject(state, action.loading);

const disableClassLoading = (state, action) => updateObject(state, action.loading);
const enableCampsLoading = (state, action) => updateObject(state, action.loading);
const enableCompetitionsLoading = (state, action) => updateObject(state, action.loading);

const disableCampsLoading = (state, action) => updateObject(state, action.loading);
const disableCompetitionsLoading = (state, action) => updateObject(state, action.loading);

const enableProfileLoading = (state, action) => updateObject(state, action.loading);

const disableProfileLoading = (state, action) => updateObject(state, action.loading);

const enableDependentUserLoading = (state, action) => updateObject(state, action.loading);

const disableDependentUserLoading = (state, action) => updateObject(state, action.loading);

const enableServiceListLoading = (state, action) => updateObject(state, action.loading);

const disableServiceListLoading = (state, action) => updateObject(state, action.loading);

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const enableNotesLoading = (state, action) => updateObject(state, action.loading);

const disableNotesLoading = (state, action) => updateObject(state, action.loading);

const openUserProfileModal = (state, action) => updateObject(state, action.loading);

const closeUserProfileModal = (state, action) => updateObject(state, action.loading);

const openProfileModal = (state, action) => updateObject(state, action.loading);
const closeProfileModal = (state, action) => updateObject(state, action.loading);
const disableprofileModal = (state, action) => updateObject(state, action.loading);
const enableprofileModal = (state, action) => updateObject(state, action.loading);
const userCamps = (state, action) => ({ ...state, userCamps: action.success });
const userCompetitions = (state, action) => ({ ...state, userCompetitions: action.success });
const userEvents = (state, action) => ({ ...state, userEvents: action.success });
const userCompetitionsInfo = (state, action) => ({ ...state, userCompetitionsInfo: action.success });
const userEventsInfo = (state, action) => ({ ...state, userEventsInfo: action.success });
const userCampsInfo = (state, action) => ({ ...state, userCampsInfo: action.success });
const monthlybookingInfo = (state, action) => ({ ...state, monthlypayment: action.success });
const on_receiving_student_payment_information = (state, action) => ({ ...state, student_payment_information: action.payload });
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.userInfoFound:
      return onUserInfo(state, action);
    case actionTypes.notesFound:
      return onGetNotes(state, action);
    case actionTypes.sendNotes:
      return onSendNotes(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.enableDependentUserLoading:
      return enableDependentUserLoading(state, action);
    case actionTypes.disableDependentUserLoading:
      return disableDependentUserLoading(state, action);
    case actionTypes.enableServiceListLoading:
      return enableServiceListLoading(state, action);
    case actionTypes.disableServiceListLoading:
      return disableServiceListLoading(state, action);
    case actionTypes.couponsApplyCodeLoading:
      return couponsApplyCodeLoading(state, action);
    case actionTypes.enableProfileLoading:
      return enableProfileLoading(state, action);
    case actionTypes.disableProfileLoading:
      return disableProfileLoading(state, action);
    case actionTypes.enableClassLoading:
      return enableClassLoading(state, action);
    case actionTypes.disableClassLoading:
      return disableClassLoading(state, action);
    case actionTypes.enableCampsLoading:
      return enableCampsLoading(state, action);
    case actionTypes.enableCompetitionsLoading:
      return enableCompetitionsLoading(state, action);
    case actionTypes.disableCampsLoading:
      return disableCampsLoading(state, action);
    case actionTypes.disableCompetitionsLoading:
      return disableCompetitionsLoading(state, action);
    case actionTypes.userClassInfo:
      return onUserClassInfo(state, action);
    case actionTypes.userBaseProfile:
      return onGetUserProfile(state, action);
    case actionTypes.userClasses:
      return onGetUserClasses(state, action);
    case actionTypes.userClassIndividualSuccess:
      return userClassIndividualSuccess(state, action);
    case actionTypes.userCampsIndividualSuccess:
      return userCampsIndividualSuccess(state, action);
    case actionTypes.userCompsIndividualSuccess:
      return userCompsIndividualSuccess(state, action);
    case actionTypes.userEventsIndividualSuccess:
      return userEventsIndividualSuccess(state, action);
    case actionTypes.todayClasses:
      return onGetTodayClasses(state, action);
    case actionTypes.dependentUser:
      return onGetDependetUser(state, action);
    case actionTypes.userPaymentList:
      return onGetUserUserPaymentList(state, action);
    case actionTypes.updateUserProfileInfo:
      return onUpdateUserProfileInfo(state, action);
    case actionTypes.guestUserFlowStep:
      return guestUserFlowStep(state, action);
    case actionTypes.guestUserDepententSelect:
      return guestUserDepententSelect(state, action);
    case actionTypes.deleteDependentUser:
      return onDeleteDependentUser(state, action);
    case actionTypes.updateUserProfile:
      return updateUserProfile(state, action);
    case actionTypes.createDependentUser:
      return onCreateDependentUser(state, action);
    case actionTypes.serviceList:
      return onserviceList(state, action);
    case actionTypes.updateDependentUser:
      return onUpdateDependentUser(state, action);
    case actionTypes.bookingsResponse:
      return onBookingsResponse(state, action);
    case actionTypes.onBookingsCreatedLoading:
      return onBookingsCreatedLoading(state, action);
    case actionTypes.campBookingsResponse:
      return campBookingsResponse(state, action);
    case actionTypes.couponsApplyResponse:
      return onCouponsApplyResponse(state, action);
    case actionTypes.trailBookingResponse:
      return onTrailBookingResponse(state, action);
    case actionTypes.enableNotesLoading:
      return enableNotesLoading(state, action);
    case actionTypes.disableNotesLoading:
      return disableNotesLoading(state, action);
    case actionTypes.openUserProfileModal:
      return openUserProfileModal(state, action);
    case actionTypes.openProfileModal:
      return openProfileModal(state, action);
    case actionTypes.closeProfileModal:
      return closeProfileModal(state, action);
    case actionTypes.closeUserProfileModal:
      return closeUserProfileModal(state, action);
    case actionTypes.disableprofileModal:
      return disableprofileModal(state, action);
    case actionTypes.enableprofileModal:
      return enableprofileModal(state, action);
    case actionTypes.otherBookingClass:
      return onOtherBookingClass(state, action);
    case actionTypes.userCamps:
      return userCamps(state, action);
    case actionTypes.userCompetitions:
      return userCompetitions(state, action);
    case actionTypes.userEvents:
      return userEvents(state, action);
    case actionTypes.userCompetitionsInfo:
      return userCompetitionsInfo(state, action);
    case actionTypes.userEventsInfo:
      return userEventsInfo(state, action);
    case actionTypes.userCampsInfo:
      return userCampsInfo(state, action);
    case actionTypes.monthlyCompute:
      return monthlybookingInfo(state, action);
    case actionTypes.usersSwitchPropertyLoading:
      return updateObject(state, _.get(action, 'data', {}));
    case actionTypes.user_class_sessions_attended:
      return userClassNoOfsessionsAttended(state, action);
    case actionTypes.updateUserState:
      return updateObject(state, _.get(action, 'data', {}));
    case actionTypes.on_receiving_student_payment_information:
      return on_receiving_student_payment_information(state, action);
    case actionTypes.offline_payment_object:
      return { ...state, offline_payment_object: action.payload };
    case actionTypes.balance_amount:
      return { ...state, balance_amount: action.payload };
    default:
      return state;
  }
};

export default reducer;
