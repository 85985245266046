import React, { Component } from 'react';
import { Empty } from 'antd';
import { AiOutlineFilter } from 'react-icons/ai';
import PageSidebar from '../../layout/Sidebar/sidebar';
import './search.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';
import SearchPageHeader from './component/header';
import SearchComponent from './component/searchComponent';
import * as searchActionType from '../../../store/actions/public/search/actionTypes';
import * as searchAction from '../../../store/actions/public/search';
import { SearchLoader } from '../../../components/loader/common/search-loader';
import _ from 'lodash';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true,
      isLoading: true,
    };
  }

  ShowSideBar() {
    this.setState({
      isActive: !this.state.isActive,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevProps.generalList !== this.props.generalList && this.props.generalList.items) {
        this.setState({ isLoading: false });
      }
    } catch (error) {
      console.log('error at search.js in componentDidUpdate');
    }
  }

  render() {
    const generalListCount = _.get(this.props.generalList, ['items', 'length'], 0);
    const sponoredListCount = _.get(this.props.sponoredList, ['items', 'length'], 0);
    const dirListListCount = _.get(this.props.dirList, ['items', 'length'], 0);
    const sum = _.sum([generalListCount, sponoredListCount, dirListListCount]);

    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
          <div className="page-sidebar" style={{position:'sticky', top: '80px',height: '100vh',overflowY:'auto'}}>
            <div className="mobile-filter" onClick={() => this.ShowSideBar()}>
              <span>{this.props.t('FilterResults')}</span>
              <div className="mobile-filter-icon">
                <AiOutlineFilter />
              </div>
            </div>
            <div className={`SideBarBlock ${this.state.isActive ? 'show' : ''}`}>
              <PageSidebar />
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
          <ScrollToTop />
          <SearchPageHeader />
          {this.state.isLoading ? (
            <SearchLoader cards={5} />
          ) : (
            <>

              {sponoredListCount > 0 && <SearchComponent compID={searchActionType.sponoredList} key={searchActionType.sponoredList} title="Sponsored Listings" list={this.props.sponoredList} />}

              {generalListCount > 0 && <SearchComponent compID={searchActionType.generalList} key={searchActionType.generalList} title="All Listings" list={this.props.generalList} />}

              {dirListListCount > 0 && <SearchComponent compID={searchActionType.dirList} key={searchActionType.dirList} title="Directory Listings" list={this.props.dirList} />}

              {sum < 1 && <Empty description={<span>{this.props.t('searchEmpty')}</span>} />}

            </>
          )}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // requestParams: common.reqFromHeader(state.search),
  // searchMounted: state.search.searchMounted,
  sponoredList: state.search.sponoredList,
  generalList: state.search.generalList,
  dirList: state.search.dirList,
});

const mapDispatchToProps = (dispatch) => ({
  // onSearchType: (requestParams) => dispatch(searchAction.common(searchActionType.searchType, requestParams)),
  // setSearchMounted: (rp) => dispatch(searchAction.common(searchActionType.searchMounted, rp)),
  // clearServiceID: () => dispatch(searchAction.common(searchActionType.serviceID, '')),
  // setServiceID: async (requestParams) => dispatch(searchAction.common(searchActionType.serviceID, requestParams)),
  onGetClasseCampList: (rpGeneral) => dispatch(searchAction.onGetClasseCampList(rpGeneral)),
  onGetClasseCampList_sponsored: (rpSponsored) => dispatch(searchAction.onGetClasseCampList(rpSponsored, searchActionType.sponoredList)),
  onGetClasseCampList_dirListing: (rpDirlist) => dispatch(searchAction.onGetClasseCampList(rpDirlist, searchActionType.dirList)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Search));
