import React, { FC, ChangeEventHandler } from 'react';
import { Input } from 'antd';
const { Search } = Input;

interface CustomSearchInputProps {
  addonAfter?: React.ReactNode;
  addonBefore?: React.ReactNode;
  allowClear?: boolean;
  classNames?: string;
  defaultValue?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSearch?: (
    value: string,
    event?:
      | React.KeyboardEvent<HTMLInputElement>
      | React.MouseEvent<HTMLElement, MouseEvent>
      | undefined,
  ) => void;
  placeholder?: string;
  showCount?: boolean;
  size?: 'small' | 'middle' | 'large';
  status?: 'warning' | 'error';
  suffix?: React.ReactNode;
  type?: string;
  value?: string;
  autoComplete?: string;
  style?:string;
}

export const CustomSearchInput: FC<CustomSearchInputProps> = ({
  addonAfter,
  addonBefore,
  allowClear = true,
  classNames = '',
  defaultValue = '',
  disabled = false,
  id,
  maxLength = 50,
  onChange,
  onSearch,
  placeholder = '',
  showCount = false,
  size = 'large',
  status,
  suffix,
  type,
  value,
  autoComplete,
  style='',
}) => {
  return (
    <Search
      style={style}
      addonBefore={addonBefore}
      addonAfter={addonAfter}
      defaultValue={defaultValue}
      allowClear={allowClear}
      className={classNames}
      disabled={disabled}
      maxLength={maxLength}
      onChange={onChange}
      onSearch={onSearch}
      placeholder={placeholder}
      showCount={showCount}
      size={size}
      id={id}
      status={status}
      suffix={suffix}
      type={type}
      value={value}
      autoComplete={autoComplete}
    />
  );
};
