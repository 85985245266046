import React from 'react';

export class ClassLoader extends React.Component {
  render() {
    const loaderHtml = [];
    for (let i = 0; i < 4; i++) {
      loaderHtml.push(
        <div key={i} className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div className="SearchResultCard SearchResultCardPlaceholder">
            <div className="SearchResultCardHeader">
              <div className="SearchResultCardHeaderProfile">
                <div className="SearchResultCardHeaderProfileImg" />
                <div className="SearchResultCardHeaderProfileInfo" />
              </div>
            </div>
            <div className="SearchResultCardBody">
              <div className="SearchResultCardBodySet" />
              <div className="SearchResultCardBodySetBtn" />
            </div>
          </div>
        </div>,
      );
    }

    return <>{loaderHtml}</>;
  }
}
