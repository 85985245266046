import React, { useEffect } from 'react';
import { Table } from 'antd';
import { GiCheckMark } from 'react-icons/gi';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import async from 'async';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime';
import * as campaction from '../../../store/actions/tutor/camps';
import * as classaction from '../../../store/actions/tutor/Classes';
import * as attendances from '../../../store/actions/tutor/attendances';

const FiltersReport = (props) => {
  useEffect(() => {
    const filterDetails = _.get(props, 'filterDetails');
    const urlparams = queryString.parse(window.location.search);
    const classId = _.get(urlparams, 'classId', '');
    const type = _.get(urlparams, 'type', '');
    const provId = _.get(urlparams, 'provId', '');
    if (filterDetails && Object.keys(filterDetails).length > 0) {
      filterDetails.session_details = _.get(filterDetails, 'details');
      props.getAttendenceReport(filterDetails);
    }
    if (classId && type && provId) {
      props.getAttendenceReport({
        session_details: classId,
        details: classId,
        type,
        provider: provId || props.tutorBaseProfile.id,
      });

      const notesParams = {
        limit: 100,
        status: 1,
        // provider: props.session.provider,
        page: 1,
        sort: 'created_at.desc',
        expand: 'user,dependent_user,class_details,camp_details,payment_details',
      };

      if (type === 'classes' && _.isEmpty(_.get(props, 'students.meta', null))) {
        notesParams.class_details = classId;
        props.getClassesStudents(notesParams);
      } else if (type === 'camps' && _.isEmpty(_.get(props, 'campsStudents.meta', null))) {
        notesParams.camp_details = classId;
        props.getCampsStudents(notesParams);
      }
    }
  }, [_.get(props, 'filterDetails')]);

  /** Source Data rendering */
  const setSourceData = (columns, students, reports, callback) => {
    try {
      const source_data = [];

      if (reports.length > 0) {
        const studentslistfilters = _.uniqBy(students, (n) => (!_.isEmpty(n.dependent_user) && n.dependent_user.id) || (n.user && n.user.id));

        async.forEachOf(studentslistfilters, (value, key, student_callback) => {
          // this row sstudent
          const temp = {};
          const bookedFor = _.get(value, 'booked_for', '');
          const bookedForType = _.get(value, 'booked_for_type', '');
          const classCount = {};
          if (bookedForType === 'dependent') {
            temp.name = _.startCase(`${_.get(value, 'dependent_user.firstname')} ${_.get(value, 'dependent_user.lastname', '')}`);
          } else {
            temp.name = _.startCase(`${_.get(value, 'user.firstname')} ${_.get(value, 'user.lastname', '')}`);
          }
          temp.key = `row_${key}`;

          let arr = columns;
          arr = _.tail(arr);

          async.forEachOf(arr, (column_value, column_key, column_callback) => {
            let column_date = column_value[`column_${column_key}`];
            let checked = 0;
            let id = null;
            const DatemarkedAtt = _.get(reports[0], 'date_marked');
            async.forEachOf(DatemarkedAtt, (sub_value, sub_key) => {
              const mark_date = _.get(sub_value, 'marked_date', '');
              const att_name = _.get(sub_value, 'attendance', []);

              // console.log('comd ata1======', mark_date)
              async.forEachOf(att_name, (sub_value1, sub_key1, attendance_callback) => {
                // console.log('comd ata12======', sub_value1)

                let attendance_user = _.get(sub_value1, 'dependent_user', '');
                let attendance_user_type = 'dependent';
                if (_.isEmpty(attendance_user)) {
                  attendance_user = _.get(sub_value1, 'user', '');
                  attendance_user_type = 'user';
                }

                column_date = dayjs(column_date).format('MM-DD-YY');
                if ((mark_date) === (column_date)
                  && bookedForType === attendance_user_type
                  && bookedFor === attendance_user) {
                  checked = 1;
                  id = sub_value1.id;
                  temp[`column_${column_key}`] = <GiCheckMark color="green" />;
                  classCount[`count_${column_date}`] = column_date;
                }
                attendance_callback();
              });
            });

            column_callback();
          });
          source_data.push(temp);
          // let col_data = source_data;
          // if (col_data) {
          //   col_data.forEach((value, index) => {
          //     temp['no_of_class'] = _.size(classCount);
          //   })
          // }
          temp.no_of_class = _.size(classCount);
          student_callback();
        });
        callback(source_data);
      }
    } catch (error) {

    }
  };

  /** Table column dynamic rendering */
  const setCoulnmDate = async (reports, setColNameCallback) => {
    const column_data = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: 100,
      },
    ];

    const weekDaysCount = _.get(reports, 'class_details.session_dates') ? _.get(reports, 'class_details.session_dates') : _.get(reports, 'camp_details.session_dates');
    const classArr = [];
    if (weekDaysCount) {
      weekDaysCount.map((data) => classArr.push(dayjs(data)));
    }

    classArr.forEach((start, index) => {
      const selected_date = dayjs(start).format('MMM DD YY');
      const column_index = index;
      const temp = {
        title: selected_date,
        dataIndex: `column_${column_index}`,
      };
      temp[`column_${column_index}`] = selected_date;
      column_data.push(temp);
    });
    column_data.push(
      {
        title: 'Number of classes attended',
        dataIndex: 'no_of_class',
        key: 'no_of_class',
        fixed: 'right',
        width: 100,
      },
    );

    setColNameCallback(column_data);
  };

  const render = () => {
    const report = _.get(props, ['attendancesreports', 'items'], []);

    if (report.length > 0) {
      let columns = [];
      let dataSource = [];
      let students = [];
      let type = _.get(props, 'filterDetails.type');
      if (!type) {
        const urlparams = queryString.parse(window.location.search);
        type = _.get(urlparams, 'type', '');
      }
      try {
        if (type === 'classes') {
          if (!_.isEmpty(_.get(props, 'user'))) {
            _.find(_.get(props, 'students.items', []), (o) => {
              if (_.get(o, 'dependent_user.id') === _.get(props, 'user')) {
                return students.push(o);
              } if (_.isEmpty(_.get(o, 'dependent_user')) && _.get(o, 'user.id') === _.get(props, 'user')) {
                return students.push(o);
              }
            });
          } else {
            students = _.get(props, 'students.items');
          }

          if (_.get(props, 'students.meta.total_count') > 0) {
            setCoulnmDate(_.get(props, ['students', 'items', '0']), (column_data) => {
              columns = column_data;
            });
          }
        } else if (type === 'camps') {
          if (!_.isEmpty(_.get(props, 'user'))) {
            _.find(_.get(props, 'campsStudents.items', []), (o) => {
              if (_.get(o, 'dependent_user.id') === _.get(props, 'user')) {
                return students.push(o);
              } if (_.isEmpty(_.get(o, 'dependent_user')) && _.get(o, 'user.id') === _.get(props, 'user')) {
                return students.push(o);
              }
            });
          } else {
            students = _.get(props, 'campsStudents.items', []);
          }

          if (_.get(props, 'campsStudents.meta.total_count') > 0) {
            setCoulnmDate(_.get(props, ['campsStudents', 'items', '0']), (column_data) => {
              columns = column_data;
            });
          }
        }
        setSourceData(columns, students, report, (source_date) => {
          dataSource = source_date;
        });
      } catch (error) {
        console.log('error at report in  render function', error);
      }

      if (!_.isEmpty(dataSource)) {
        return (
          <div className="tutor-attendance-page nurtem-table mt-2 mb-3">
            <div className="container-xl row">
              <div className="col-12">
                <div className="attendance-title mb-3">{props.t('att')}</div>
                <div className="full-width ">
                  <Table
                    scroll={{ x: '100%' }}
                    pagination={false}
                    columns={columns}
                    dataSource={dataSource}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="p-0 mb-4">
          <EmptyAnime AnimeType="Empty" loop animeheight={150} />

          <p className="empty-animations">{props.t('att2')}</p>
        </div>
      );
    }
    return (
      <div className="p-0 mb-4">
        <EmptyAnime AnimeType="Empty" loop animeheight={150} />

        <p className="empty-animations">
          {props.t('att2')}
          {' '}
        </p>
      </div>
    );
  };

  return render();
};

const mapStateToProps = (state) => ({
  attendancesreports: state.attendances.attendanceReport,
  students: state.classesDetails.classesStudents,
  campsStudents: state.campsDetails.campsStudents,
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getAttendenceReport: (requestParams) => dispatch(attendances.attendanceReport(requestParams)),
  getClassesStudents: (requestParams) => dispatch(classaction.getClassesStudents(requestParams)),
  getCampsStudents: (requestParams) => dispatch(campaction.getCampsStudents(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FiltersReport));
