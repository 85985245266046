import { notification } from 'antd';
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import * as routeName from '../../../components/route-names';
import { resetPage } from './add-class';
import history from '../../../utils/history';

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const enableClassDetailsLoading = () => ({
  type: actionTypes.enableClassDetailsLoading,
  loadingClassDetails: { loadingClassDetails: true },
});
export const disableClassDetailsLoading = () => ({
  type: actionTypes.disableClassDetailsLoading,
  loadingClassDetails: { loadingClassDetails: false },
});

export const enableRequestLoading = (data) => ({
  type: actionTypes.enableRequestLoading,
  loading: data,
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});
export const enableMilestoneLoading = () => ({
  type: actionTypes.enableMilestoneLoading,
  milestoneLoading: { milestoneLoading: true },
});

export const disableMilestoneLoading = () => ({
  type: actionTypes.disableMilestoneLoading,
  milestoneLoading: { milestoneLoading: false },
});

export const setCouponsData = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.setCouponsData,
    success: data,
  });
};

export const classFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorClassDetailsFoundSuccess,
    success: {
      ...data,
      randomKey: Math.random()
        .toString(36)
        .substring(5)
        .toUpperCase(),
    },
  });
};

export const classDeleteFoundSuccess = (data) => ({
  type: actionTypes.tutorClassDeleteFoundSuccess,
  success: data,
});
export const singleClassView = (data) => ({
  type: actionTypes.singleClassView,
  success: data,
});
export const singleClassViewLoading = (data) => ({
  type: actionTypes.singleClassViewLoading,
  success: data,
});

export const classCreateNoteSuccess = (data) => ({
  type: actionTypes.tutorClassCreateNoteSuccess,
  success: data,
});
export const onUpdateNotesForTutor = (data) => ({
  type: actionTypes.onUpdateNotesForTutor,
  success: data,
});
export const classDeleteNoteSuccess = (data) => ({
  type: actionTypes.tutorClassDeleteNoteSuccess,
  success: data,
});

export const classFoundSuccessData = (data) => ({
  type: actionTypes.tutorClassDetailsFoundSuccess,
  success: {},
});
export const resetTutorClassDetails = (data) => ({
  type: actionTypes.resetTutorClassDetails,
  data: {},
});
export const onGetClassDetails = (requestParams) => (dispatch) => {
  dispatch(enableClassDetailsLoading());
  axios
    .get('/classes/view', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(classFoundSuccess(response.data));
        dispatch(disableClassDetailsLoading());
      }
    })
    .catch((error) => {
      dispatch(disableClassDetailsLoading());
    });
};

export const createdCoupons = (value) => ({
  type: actionTypes.createdCoupons,
  createdCoupons: { createdCoupons: value },
});

export const onDeleteClass = (requestParams, callback = () => { }) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/classes/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);

        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        deleteResponse.success = true;
        deleteResponse.idClass = Math.random()
          .toString(36)
          .substring(5)
          .toUpperCase();
        dispatch(classDeleteFoundSuccess(deleteResponse));
        dispatch(disableLoading());
        history.push(`${routeName.tutorClasses}`);
        callback();
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;
      deleteResponse.success = false;
      dispatch(classDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onCreateNotesForClass = (requestParams, callback = () => { }) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/notes/create-notes',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(classCreateNoteSuccess(deleteResponse));
        dispatch(disableLoading());
      }
      callback(response.status);
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 400;
      deleteResponse.data = {};

      dispatch(classCreateNoteSuccess(deleteResponse));
      dispatch(disableLoading());
      callback(400);
    });
};

export const onUpdateNotesForClass = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/notes/update',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(onUpdateNotesForTutor(deleteResponse));
        dispatch(disableLoading());
        const message_args = {
          duration: 2,
          message: response.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      dispatch(onUpdateNotesForTutor(deleteResponse));
      dispatch(disableLoading());
    });
};
export const onDeleteNotesForClass = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/notes/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(classDeleteNoteSuccess(deleteResponse));
        dispatch(disableLoading());
        const message_args = {
          duration: 2,
          message: response.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      dispatch(classDeleteNoteSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onClearClassDetail = () => (dispatch) => {
  dispatch(classFoundSuccess({}));
};

export const onClearClassDeleteDetail = () => (dispatch) => {
  dispatch(classDeleteFoundSuccess({}));
};

export const ProviderClassesSuccess = (data) => ({
  type: actionTypes.providerClasses,
  success: data,
});

export const OtherProviderClassesSuccess = (data) => ({
  type: actionTypes.otherProviderClasses,
  success: data,
});

export const getProviderClasses = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/providers/classes',
    params: requestParams,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(ProviderClassesSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const getOtherProviderClasses = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/providers/classes',
    params: requestParams,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(OtherProviderClassesSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onClassesRequestsSuccess = (data) => ({
  type: actionTypes.tutorClassesRequests,
  success: data,
});

export const getClassesRequests = (data) => {
  console.log('data log', data);
  return (dispatch) => {
    dispatch(enableLoading());
    dispatch(enableRequestLoading(true));
    axios({
      method: 'get',
      url: '/classes/requests',
      params: {
        ...data, expand: 'user,provider,class_details,dependent_user', sort: 'created_at.desc', type: 'business_provider',
      },
      withCredentials: true,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onClassesRequestsSuccess(response.data));
          dispatch(enableRequestLoading(false));
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        const responseData = {};
        responseData.status = 'error';
        dispatch(enableRequestLoading(false));
        dispatch(onClassesRequestsSuccess(responseData));
        dispatch(disableLoading());
      });
  };
};

export const onClassesRequestActionSuccess = (data) => ({
  type: actionTypes.classesRequestActionSuccess,
  success: data,
});
export const ClassesStudentListLoading = (data) => ({
  type: actionTypes.ClassesStudentListLoading,
  success: data,
});
export const businessClassesStudentListLoading = (data) => ({
  type: actionTypes.businessClassesStudentListLoading,
  success: data,
});

export const ClassesRequestAction = (data, callback) => (dispatch) => {
  dispatch(enableMilestoneLoading());
  axios({
    method: 'post',
    url: '/classes/requestaction',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const responseData = {};
        responseData.status = response.status;
        responseData.data = response.data;
        const message_args = {
          duration: 2,
          message: 'Success',
          description: response.data.message,
        };
        notification.success(message_args);
        dispatch(onClassesRequestActionSuccess(responseData));
        dispatch(disableMilestoneLoading());
        callback(responseData);
      }
    })
    .catch((error) => {
      const responseData = {};

      if (error && error.response && error.response.data && error.response.data.errors) {
        const message_args = {
          duration: 2,
          message: error.response.data.errors && error.response.data.errors.rules && error.response.data.errors.rules[0] && error.response.data.errors.rules[0].message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onClassesRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === false) {
        const message_args = {
          duration: 2,
          message: error && error.response && error.response.data && error.response.data.message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onClassesRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === true) {
        const message_args = {
          duration: 2,
          message: _.get(error, 'response.data.message.message', 'Something went wrong please try again'),
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onClassesRequestActionSuccess(responseData));
      }
      dispatch(disableMilestoneLoading());
      callback('error');
    });
};

export const ClassesSlotFullAction = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/classes/seats-full-days',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {

      }
    })
    .catch((error) => {
      const responseData = {};
      responseData.status = 'error';
    });
};

export const onClassesStudents = (data) => ({
  type: actionTypes.classesStudents,
  success: data,
});

export const onBusinessClassesStudents = (data) => ({
  type: actionTypes.businessClassesStudents,
  success: data,
});

export const getClassesStudents = (data) => {
  console.log('data', data);
  return (dispatch) => {
    if (_.get(data, 'requestType') === 'business') {
      dispatch(businessClassesStudentListLoading(true));
    } else {
      dispatch(ClassesStudentListLoading(true));
    }

    axios({
      method: 'get',
      url: '/classes/students',
      params: data,
      withCredentials: true,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          if (_.get(data, 'requestType') === 'business') {
            dispatch(onBusinessClassesStudents(response.data));
            dispatch(businessClassesStudentListLoading(false));
          } else {
            dispatch(ClassesStudentListLoading(false));
            dispatch(onClassesStudents(response.data));
          }
        }
      })
      .catch((error) => {
        dispatch(ClassesStudentListLoading(false));
        dispatch(businessClassesStudentListLoading(false));
      });
  };
};

export const onUpdateMilestone = (data) => ({
  type: actionTypes.updateMilestone,
  success: data,
});
export const createMilestone = (data) => ({
  type: actionTypes.createMilestone,
  success: data,
});

export const updateClassMilestone = (data) => (dispatch) => {
  dispatch(enableMilestoneLoading());
  axios({
    method: 'post',
    url: '/updateClassMilestone',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUpdateMilestone(response.data));
        const message_args = {
          duration: 2,
          message: response.data.message,
        };
        notification.success(message_args);

        dispatch(disableMilestoneLoading());
      }
    })
    .catch((error) => {
      console.log('error', _.get(error, 'response'));
      dispatch(onUpdateMilestone(_.get(error, 'response.data')));
      dispatch(disableMilestoneLoading());
    });
};

export const onTutorSpecificClasses = (data) => ({
  type: actionTypes.tutorSpecificClassesFound,
  success: data,
});

export const getTutorSpecificClasses = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/classes/listgroup',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onTutorSpecificClasses(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onClassesList = (data) => ({
  type: actionTypes.classesListFound,
  success: data,
});

export const onBusinessClassesList = (data) => ({
  type: actionTypes.businessClassesListFound,
  success: data,
});
export const onSearchClassesList = (data) => ({
  type: actionTypes.onSearchClassesList,
  success: data,
});
export const onSearchClassesLoading = (data) => ({
  type: actionTypes.onSearchClassesLoading,
  success: data,
});
export const onBusinessSearchClassesLoading = (data) => ({
  type: actionTypes.onBusinessSearchClassesLoading,
  success: data,
});
export const onEmptyClassesList = () => ({
  type: actionTypes.classesListFound,
  success: {},
});
export const onCouponList = (data) => ({
  type: actionTypes.couponList,
  success: data,
});
export const onGetCouponData = (data) => ({
  type: actionTypes.couponData,
  success: data,
});

export const getClassesList = (requestParams = {}) => (dispatch) => {
  if (_.get(requestParams, 'requestType') === 'business') {
    dispatch(onBusinessSearchClassesLoading(true));
  } else {
    dispatch(enableLoading());
    dispatch(onSearchClassesLoading(true));
  }

  axios({
    method: 'get',
    url: '/classes/list',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        if (_.get(requestParams, 'requestType') === 'business') {
          dispatch(onBusinessClassesList(response.data));
        } else if (requestParams.search) {
          dispatch(onSearchClassesList(response.data));
        } else {
          dispatch(onClassesList(response.data));
        }
        dispatch(onSearchClassesLoading(false));
        dispatch(onBusinessSearchClassesLoading(false));

        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(onSearchClassesLoading(false));
      dispatch(onBusinessSearchClassesLoading(false));
      dispatch(disableLoading());
    });
};

// Coupon List
export const getCouponList = (requestParams = {}) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/discounts/list',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCouponList(response.data));
        dispatch(createdCoupons(false));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

// Discount Create
export const onCreateCoupons = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/discounts/create',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(setCouponsData(response.data));
        dispatch(disableLoading());
        history.push(routeName.CouponsManagementList);
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      dispatch(setCouponsData(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onDeleteCoupon = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/discounts/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(classDeleteFoundSuccess(deleteResponse));
        dispatch(createdCoupons(true));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      dispatch(classDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

// Coupon List
export const onGetCoupons = (requestParams = {}) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/discounts/view',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onGetCouponData(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

// Discount Create
export const onUpdateCoupons = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/discounts/update',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(disableLoading());
        history.push(routeName.CouponsManagementList);
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      dispatch(disableLoading());
    });
};

export const onClassesCount = (data) => ({
  type: actionTypes.classesCount,
  success: data,
});

export const onTutorClassesList = (data) => ({
  type: actionTypes.onTutorClassesList,
  success: data,
});
export const getClassesCount = (type) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/getClassesCount',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        if (type === 'getClasses') {
          dispatch(onTutorClassesList(response.data));
        } else {
          dispatch(onClassesCount(response.data));
        }

        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const userVerify = (req) => {
  const requestParams = { token: req };

  return (dispatch) => {
    dispatch(enableLoading());
    axios({
      method: 'post',
      url: '/users/verify',
      withCredentials: true,
      data: requestParams,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          const deleteResponse = {};
          deleteResponse.status = response.status;
          deleteResponse.data = response.data;
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        const deleteResponse = {};
        deleteResponse.status = 'error';
        dispatch(disableLoading());
      });
  };
};

export const providerPaymentListSuccess = (data) => ({
  type: actionTypes.providerPaymentList,
  success: data,
});

export const getProviderPaymentList = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/getProviderPaymentList',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(providerPaymentListSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => dispatch(disableLoading()));
};
