import _ from 'lodash';
import { NurtemUtils } from '../../../../../utils';

const generalInfoFormMeta = {
  service_provider_announcements: {
    form_item: {
      name: 'whats_new',
      rules: [
        {
          required: false,
          message: 'Please Enter the new announcement.',
        },
      ],
    },
    editor: {
      wrapperClassName: 'demo-wrapper',
      editorClassName: 'demo-editor',
    },
    label_text: 'Announcement',
  },
  service_provider_media_links: {
    form_item: {
      name: 'media_links',
      rules: [
        () => ({
          validator(rule, value) {
            value = _.trim(value);
            if (!_.isEmpty(value) && _.isEmpty(NurtemUtils.checkTheUrlIsValidYoutubeOrVimeoUrl(value))) {
              return Promise.reject('Have to be a valid youtube or Vimeo url.');
            }
            return Promise.resolve();
          },
        }),
      ],
    },
    input: {
      placeholder: 'eg: https://www.youtube.com/watch?v=2y4iYTmI9Yo',
      allowClear: true,
    },
    label_text: 'YouTube or Vimeo Links',
  },
  // service_provider_soundcloud_url: {
  //     form_item: {
  //         name: "soundcloud_url",
  //         rules: [
  //             () => ({
  //                 validator(rule, value) {
  //                     value = _.trim(value);
  //                     if (!_.isEmpty(value) && _.isEmpty(NurtemUtils.checkTheUrlIsValidSoundcloudUrl(value))) {
  //                         return Promise.reject("Have to be a valid soundcloud Url.");
  //                     } else {
  //                         return Promise.resolve();
  //                     }
  //                 },
  //             })
  //         ]
  //     },
  //     input: {
  //         placeholder: "eg: https://soundcloud.com/glennmorrison/beethoven-moonlight-sonata",
  //         allowClear: true,
  //     },
  //     label_text: "SoundCloud links"
  // },
};

export default generalInfoFormMeta;
