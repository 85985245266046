import React, { useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import "./login.css"
import { withTranslation } from 'react-i18next';
import formatUrlPart from '../../../utils/common/format-url-part';
import ClsDftImg from '../../../assets/images/dft-img-new.jpg';
/* Landing page Card UI */

const LandingCard = function (props) {
    const propsClasses = _.get(props, ['value', 'batches'], {});
    const ch = _.chain(propsClasses);
    const classInfo = ch.first().get('class_info', {}).value();
    const provider = ch.first().get('provider_info', {}).value();
    const session_name = ch.first().get('session_name', '').value();
    const businessname = _.get(provider, 'businessname', '');
    const classphoto = _.get(classInfo, 'photo', '');
    const classesname = _.get(classInfo, 'name', '');
    const firstname = _.get(provider, 'fname', '');
    const teacherName = _.get(provider, 'class_teacher', '');
    const price = ch.first().get('price', '').value();
    const gid = ch.first().get('group_id', '').value();
    const Ctype = ch.first().get('type', '').value();

    let displayName = (businessname || `${firstname}`).toLowerCase().replace(/\s+/g, '-');
    displayName = !_.isEmpty(displayName) ? displayName : '-';
    let type = 'class';
    if (Ctype === 'camps') {
        type = 'camp';
    } else if (Ctype === 'competitions') {
        type = 'competition';
    } else if (Ctype === 'events') {
        type = 'event';
    }


    const link_to = `/view-${type}/${displayName}/${formatUrlPart(session_name)}/?gid=${gid}`;

    return (
        <Link to={link_to}>
            <div className="search-container">
                <div style={{ height: '190px', width: '249px', position: 'relative' }}>
                    <img
                        src={classphoto ? classphoto : ClsDftImg}
                        className="background-image"
                        alt='class-background'
                    />
                </div>
                <div className='p-3'>
                    <div className="ClassName text-truncate">
                        {classesname}
                    </div>
                    <div className="classInfo">
                        <div>
                            <span>by</span><span className='tutorName'>{"  "}{teacherName ? teacherName : businessname}</span>
                        </div>
                        <div>
                            <span>$</span> <span className='tutorName'>{price.toFixed(2)}</span>
                        </div>
                    </div>
                </div>
                <div>

                </div>
            </div>
        </Link>
    );
};

export default (withTranslation()(LandingCard));
