import React from 'react'
import queryString from 'query-string'
import { notification, Button, Spin, Form } from 'antd'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import dayjs from '../../../../utils/day'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as classActions from '../../../../store/actions/tutor/Classes'
import * as addClassAction from '../../../../store/actions/tutor/add-class'
import * as commonActions from '../../../../store/actions/common'
import * as campActions from '../../../../store/actions/tutor/add-camp'
import * as campDetailActions from '../../../../store/actions/tutor/camps'
import * as competitionActions from '../../../../store/actions/tutor/competitions'
import * as eventActions from '../../../../store/actions/tutor/events'
import history from '../../../../utils/history'
import * as routeName from '../../../../components/route-names'
import AiDescriptionGenerator from '../../../../components/common/AiDescriptionGenerator'
import NameForm from '../../../../components/common/AddUpdateForms/NameForm'
import { RadioGroup, Tooltip } from '../../../../components/antd-components/index.tsx'
import ServiceSelectionSection from '../../../../components/common/AddUpdateForms/ServiceNameForm'
import Photouploads from '../../../../components/re-usable/file-to-s3/photoupload'

class TutorUpdateClass extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      selectedService: {},
      selectedService_name: '',
      selectedService_id: '',
      addservicevalue: '',
      serviceList: [],
      urlParams: {},
      mainform_button_loading: false,
      disabled: false,
      loading: false,
      action_type: 'create',
      formModified: false,
      submitting: true,
      value: 1,
      type: 1,
      campFormData: {},
      serviceSearchData: [],
      serviceSearchInitiated: false,
      descriptionHtml: EditorState.createEmpty(),
      descp_html: EditorState.createEmpty(),
      errors: {},
      update_id: '',
      ipapi: null,
      servicetext: '',
      name: '',
      formValues: '',
      buttonDisable: false,
      aiLoading: false,
      photo: null,
      photoValidation: null,
      photo_display: null,
      photo_display_ai: null,
    }
  }

  async componentDidMount() {
    const { tutorBaseProfile } = this.props
    try {
      const params = queryString.parse(window.location.search)
      this.setState({
        urlParams: params,
      })

      if (tutorBaseProfile && tutorBaseProfile.services) {
        this.setState({ serviceSearchData: tutorBaseProfile.services })
      }

      if (params.type === 'add') {
        this.setState({
          action_type: 'create',
          update_id: '',
          classFormData: {},
        })
      }

      if (params.type && params.type === 'update' && params.id) {
        this.setState({
          action_type: 'update',
          update_id: params.id,
          classFormData: {},
        })
        const requestParams = { id: params.id, expand: 'services,provider' }
        if (this.props.type === 'classes') {
          this.props.onClassDetail(requestParams)
        } else if (this.props.type === 'camps') {
          this.props.onCampDetail({ camp_id: params.id })
        } else if (this.props.type === 'competitions') {
          this.props.onCompetitionDetail(requestParams)
        } else if (this.props.type === 'events') {
          this.props.onEventDetail(requestParams)
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error at update class >componentDidMount', error)
    }
  }

  async componentDidUpdate(prevprops) {
    try {
      if (
        this.props.tutorBaseProfile !== prevprops.tutorBaseProfile &&
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.services
      ) {
        this.setState({
          serviceSearchData: this.props.tutorBaseProfile.services,
        })
      }

      let didUpdate
      let campFormData
      if (this.props.type === 'classes') {
        didUpdate =
          prevprops.onClassDetails !== this.props.onClassDetails &&
          this.props.onClassDetails.details
        campFormData = _.cloneDeep(this.props.onClassDetails.details)
      } else if (this.props.type === 'camps') {
        didUpdate =
          prevprops.campDetails !== this.props.campDetails &&
          this.props.campDetails.details
        campFormData = _.cloneDeep(this.props.campDetails.details)
      } else if (this.props.type === 'competitions') {
        didUpdate =
          prevprops.onCompetitionDetails !== this.props.onCompetitionDetails &&
          this.props.onCompetitionDetails.details
        campFormData = _.cloneDeep(this.props.onCompetitionDetails.details)
      } else if (this.props.type === 'events') {
        didUpdate =
          prevprops.onEventDetails !== this.props.onEventDetails &&
          this.props.onEventDetails.details
        campFormData = _.cloneDeep(this.props.onEventDetails.details)
      }

      if (didUpdate) {
        if (campFormData.description && this.state.action_type === 'update') {
          const blocksFromHtml = htmlToDraft(campFormData.description)
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          )
          const descriptionHtml = EditorState.createWithContent(contentState)
          this.setState({ descriptionHtml })
          this.setState({ name: campFormData.name })
        }
        if (campFormData.type && this.state.action_type === 'update') {
          this.setState({ type: campFormData.type })
        }

        if (campFormData.photo) {
          this.setState({ photo_display: campFormData.photo })
        }

        const campDisplayData = _.cloneDeep(campFormData)

        if (campFormData.services && campFormData.services[0]) {
          campDisplayData.service_ids = [campFormData.services[0].id]
          campFormData.service_ids = [campFormData.services[0].id]
        }
        delete campFormData.services

        this.setState({
          campFormData,
          action_type: 'update',
        })

        await this.formRef.current.setFieldsValue(campDisplayData)
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error at update class,componentDidUpdate', error)
    }
  }

  onFinishCallback = (type, details, actionType) => {
    try {
      const campid = _.get(details, 'id', false)
      const className = _.get(details, 'name', '-')
      if (type === 'success' && campid) {
        notification.success({
          message: `${this.props.title} ${actionType}d successfully.`,
        })
        if (this.props.type === 'classes') {
          history.push(
            `${routeName.TutorUpdateClass}/${className}?id=${campid}&type=update`
          )
        } else if (this.props.type === 'camps') {
          history.push(
            `${routeName.TutorUpdateCamp}/${className}?id=${campid}&type=update`
          )
        } else if (this.props.type === 'competitions') {
          history.push(
            `${routeName.updateCompetition}/${className}?id=${campid}&type=update`
          )
        } else if (this.props.type === 'events') {
          history.push(
            `${routeName.updateEvent}/${className}?id=${campid}&type=update`
          )
        }

        setTimeout(() => {
          this.setState({
            mainform_button_loading: false,
          })
          this.props.nextPage()
          window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 1000)
      }
    } catch (error) {
      // console.log('error at update class,onFinishCallback', error);
    } finally {
      setTimeout(() => {
        if (this.state.mainform_button_loading) {
          this.setState({
            mainform_button_loading: false,
          })
        }
      }, 2000)
    }
  }

  onFinish = async (e) => {
    try {
      this.setState({ mainform_button_loading: true, buttonDisable: true })
      setTimeout(() => {
        this.setState({
          buttonDisable: false,
        })
      }, 1000)

      const name = _.get(e, 'name', '')
      const serviceIds = _.get(e, 'service_ids')

      if (!name) {
        notification.error({
          message: this.props.t('Name required!'),
        })
        return false
      }

      const campData = {
        name,
        service_ids: _.isArray(serviceIds) ? serviceIds : [serviceIds],
      }
      // update description from editor
      if (!_.isEmpty(this.state.descriptionHtml)) {
        campData.description = draftToHtml(
          convertToRaw(this.state.descriptionHtml.getCurrentContent())
        )
      }

      if (this.state.type && this.props.type === 'competitions') {
        campData.type = this.state.type
      }
      // this.state.photo_display_ai generated form AI
      if (!_.isEmpty(this.state.photo_display_ai)) {
        campData.photo_ai_generated_url = this.state.photo_display_ai
      }
      if (!_.isEmpty(this.state.photo_display)) {
        campData.photo = this.state.photo_display
      } else {
        campData.photo = null
      }
      const timezones = this.props.ipapi
        ? this.props.ipapi.timezone
        : dayjs.tz.guess()
      campData.timezone = timezones
      if (this.state.urlParams.tutor) {
        if (this.props.type === 'classes') {
          campData.provider = _.get(
            this,
            'props.onClassDetails.details.provider.id',
            ''
          )
        } else if (this.props.type === 'camps') {
          campData.provider = _.get(
            this,
            'props.campDetails.details.provider.id',
            ''
          )
        } else if (this.props.type === 'competitions') {
          campData.provider = _.get(
            this,
            'props.onCompetitionDetails.details.provider.id',
            ''
          )
        } else if (this.props.type === 'events') {
          campData.provider = _.get(
            this,
            'props.EventDetails.details.provider.id',
            ''
          )
        }
      }
      if (this.state.action_type === 'update') {
        const params = this.state.urlParams

        const data = {
          params: { id: params.id },
          data: campData,
        }
        if (this.props.type === 'classes') {
          this.props.updateClass(data, (type, details) =>
            this.onFinishCallback(type, details, 'update')
          )
        } else if (this.props.type === 'camps') {
          this.props.updateCamp(data, (type, details) =>
            this.onFinishCallback(type, details, 'update')
          )
        } else if (this.props.type === 'competitions') {
          this.props.updateClass(
            data,
            (type, details) => this.onFinishCallback(type, details, 'update'),
            'competitions/update'
          )
        } else if (this.props.type === 'events') {
          this.props.updateClass(
            data,
            (type, details) => this.onFinishCallback(type, details, 'update'),
            'events/update'
          )
        }
      } else {
        if (this.props.type === 'classes') {
          this.props.createClass(campData, (type, details) =>
            this.onFinishCallback(type, details, 'create')
          )
        } else if (this.props.type === 'camps') {
          this.props.createCamp(campData, (type, details) =>
            this.onFinishCallback(type, details, 'create')
          )
        } else if (this.props.type === 'competitions') {
          this.props.createClass(
            campData,
            (type, details) => this.onFinishCallback(type, details, 'create'),
            'competitions/create'
          )
        } else if (this.props.type === 'events') {
          this.props.createClass(
            campData,
            (type, details) => this.onFinishCallback(type, details, 'create'),
            '/events/create'
          )
        }
      }
    } catch (error) {
      // console.log('error at update class, onFinish', error);
      setTimeout(() => {
        this.setState({
          mainform_button_loading: false,
        })
        history.push(routeName.tutorClasses)
      }, 1800)
    }
    return false
  }

  onFinishFailed = (e) => {
    console.log('failed to submit form', e)
  }

  handleAiImageGenerate = () => {
    this.setState({ aiLoading: true })

    if (this.state.name || this.state.servicetext) {
      const data = {
        name: this.state.name,
        service: this.state.servicetext,
        type: this.state.type,
      }

      this.props.onAiGenerateImage(data, (resp) => {
        this.setState({ aiLoading: false });

        let respChain = _.chain(resp);

        if (resp instanceof Error || (resp && resp.error)) {
          // If there is an error, set photo_display_ai and photo_display to null
          console.log('respChain.get(urls)====', respChain.get('urls'))
          this.setState({ photo_display_ai: null, photo_display: null });
        } else {
          console.log('respChain.get(urls)', respChain.get('urls'))
          const url = respChain.get('urls').value();
          this.setState({ photo_display_ai: url, photo_display: null });
        }
      });
    } else {
      this.setState({ aiLoading: false })
      notification.warning({ message: this.props.t('onboardingTutor11') })
    }
  }

  handleRadioChange = (e) => {
    const statusValue = e.target.value;
    console.log('datahere===1', statusValue)
    this.setState({ type: statusValue })
  };

  render() {
    const { serviceSearchData } = this.state
    const opt =
      Array.isArray(serviceSearchData) && serviceSearchData.length > 0
        ? serviceSearchData
        : [
          {
            name: 'Pl. add service',
            id: 'noservice',
          },
        ]

    return (
      <div >
        {this.props.type === 'classes'
          ? this.props.detailLoading &&
          this.state.action_type === 'update' && (
            <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
              <Spin
                tip={`Update  ${this.props.title} Loading`}
                spinning
                size="large"
              />
            </div>
          )
          : this.props.type === 'camps'
            ? this.props.detailLoadingCamp &&
            this.state.action_type === 'update' && (
              <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                <Spin
                  tip={`Update  ${this.props.title} Loading`}
                  spinning
                  size="large"
                />
              </div>
            )
            : this.props.detailLoadingComp &&
            this.state.action_type === 'update' && (
              <div style={{ position: 'absolute', top: '20%', left: '50%' }}>
                <Spin
                  tip={`Update  ${this.props.title} Loading`}
                  spinning
                  size="large"
                />
              </div>
            )}
        <Form
          key="mastercampform"
          scrollToFirstError
          onFinishFailed={(e) => this.onFinishFailed(e)}
          onFinish={(e) => this.onFinish(e)}
          ref={this.formRef}
          style={
            this.props.type === 'classes'
              ? this.props.detailLoading &&
              this.state.action_type === 'update' && {
                opacity: 0.5,
                pointerEvents: 'none',
              }
              : this.props.type === 'camps'
                ? this.props.detailLoadingCamp &&
                this.state.action_type === 'update' && {
                  opacity: 0.5,
                  pointerEvents: 'none',
                }
                : this.props.detailLoadingComp &&
                this.state.action_type === 'update' && {
                  opacity: 0.5,
                  pointerEvents: 'none',
                }
          }
        >
          <div className="row">
            <div className="col-md-6">
              <NameForm
                onChange={(e) => {
                  this.setState({ name: e.target.value })
                }}
                nameofthecamps={
                  <span>
                    {' '}
                    <span className="tooltip-icon">
                      <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
                    </span>{' '}
                    <span className="tooltip-text">
                      `This name will be displayed while a student search for
                      ${this.props.title}.`
                    </span>
                  </span>
                }
                type={this.props.title}
              />

              <ServiceSelectionSection
                name="service_ids"
                opt={opt}
                onServiceSelect={(e) => this.setState({ servicetext: e })}
                whichForm={this.props.title}
              />
            </div>
            <div className="col-md-6">
              <label
                htmlFor="photo"
                className="filter-group-title mb-1"
                style={{ width: 'auto' }}
              >
                {' '}
                {this.props.t('upImage')}
              </label>
              <div className="label-short-desc mb-1">
                {this.props.t('upImageValid')}
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <Form.Item
                  name="photo"
                  rules={[
                    {
                      required: false,
                      message: this.props.t('classPhotoReq'),
                    },
                  ]}
                  validateStatus={this.state.photoValidation ? 'error' : null}
                  help={
                    this.state.photoValidation
                      ? this.state.photoValidation
                      : null
                  }
                >
                  <Photouploads
                    type={this.props.type}
                    photoUrl={
                      this.state.photo_display_ai
                        ? this.state.photo_display_ai
                        : this.state.photo_display
                    }
                    updatePhoto={(photo) =>
                      this.setState({
                        photo_display_ai: null,
                        photo_display: photo,
                      })
                    }
                    photoLoading={(photoUploading) =>
                      this.setState({ photoUploading })
                    }
                    aiPhoto={!!this.state.photo_display_ai}
                    photoValidation={(valid) =>
                      this.setState({ photoValidation: valid })
                    }
                  />
                </Form.Item>
                <div className="px-2">
                  <Button
                    name="aiimagegen"
                    type="primary"
                    loading={this.state.aiLoading}
                    onClick={this.handleAiImageGenerate}
                  >
                    {this.props.t('addForm7')}
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-md-12">

              <AiDescriptionGenerator
                name={this.state.name}
                servicetext={this.state.servicetext}
                type={this.props.title}
                descpValue={this.state.descriptionHtml}
                onAiGenerateText={this.props.onAiGenerateText}
                updateDescription={(descriptionHtml) =>
                  this.setState({ descriptionHtml })
                }
                loading={(e) => this.setState({ aiLoading: e })}
              />
            </div>
          </div>

          <div className="row mt-3" style={{ flexFlow: "row-reverse", gap: 10, marginRight: 10 }}>
            <Button
              className="mt-2"
              disabled={
                this.state.mainform_button_loading || this.state.aiLoading
              }
              loading={
                this.state.mainform_button_loading || this.state.aiLoading
              }
              htmlType="submit"
              type="primary"
            >
              {this.props.t('submit')}
            </Button>
            {/* <Button
              className="mt-2"
              type="primary"
              danger
              style={{ marginLeft: 10 }}
              onClick={() => history.goBack()}
            >
              {this.props.t('cancel')}
            </Button> */}
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ipapi: state.apiCall.ipapi,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile.details,
  service: state.services,
  CommissionConfigurations: state.configurations.CommissionConfigurations,
  onClassDetails: state.classesDetails.tutorClassDetails,
  campDetails: state.campsDetails.tutorCampsDetails,
  detailLoading: state.classesDetails.loadingClassDetails,
  detailLoadingCamp: state.campsDetails.loading,
  onCompetitionDetails: state.competitionsDetails.CompetitionDetails,
  onEventDetails: state.eventsDetails.EventDetails,
  detailLoadingComp: state.competitionsDetails.loading,
  createdData: state.addCamp.campData,
  loading: state.addCamp.loading,
})

const mapDispatchToProps = (dispatch) => ({
  //Common Actions
  onAiGenerateImage: (requestParams, callback) =>
    dispatch(commonActions.onAiGenerateImage(requestParams, callback)),
  onAiGenerateText: (requestParams, callback) =>
    dispatch(commonActions.onAiGenerateText(requestParams, callback)),

  //Class Actions
  createClass: (data, callback, url) =>
    dispatch(addClassAction.createClass(data, callback, url)),
  updateClass: (data, callback, url) =>
    dispatch(addClassAction.updateClass(data, callback, url)),
  onClassDetail: (requestParams) =>
    dispatch(classActions.onGetClassDetails(requestParams)),

  //Camp Actions
  createCamp: (data, callback) =>
    dispatch(campActions.createCamp(data, callback)),
  updateCamp: (data, callback) =>
    dispatch(campActions.updateCamps(data, callback)),
  onCampDetail: (requestParams) =>
    dispatch(campDetailActions.onGetCampsDetails(requestParams)),

  //Competition Actions
  onCompetitionDetail: (requestParams) =>
    dispatch(competitionActions.onGetCompetitionDetails(requestParams)),

  //eventActions
  onEventDetail: (requestParams) =>
    dispatch(eventActions.onGetCompetitionDetails(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorUpdateClass))
