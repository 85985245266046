import React from 'react';
import { Menu, Tour } from 'antd';
import { Badge } from '../../../../components/antd-components/index.tsx';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as servicesActions from '../../../../store/actions/public/Services';
import * as msgAction from '../../../../store/actions/public/message';
import * as routeName from '../../../../components/route-names';
import './user-dashboard-sidebar.css';
import * as tourGuideActions from '../../../../store/actions/common/tour-guide';
import './tour-guide.css';
import _ from 'lodash';
import { FcCalendar, FcCollaboration, FcEngineering, FcSelfServiceKiosk, FcSurvey, FcTodoList } from 'react-icons/fc';
import { SiGoogleclassroom } from 'react-icons/si';
import { FaCampground } from 'react-icons/fa6';
import { FaUser } from "react-icons/fa";
import { GiChampions } from 'react-icons/gi';
import { TbTimelineEventPlus, TbLayoutDashboard } from "react-icons/tb";
import { IoCalendarNumberOutline } from "react-icons/io5";

class UserDashboardPageSidebar extends React.Component {
  constructor(props) {
    super(props);
    this.ref1 = React.createRef();
    this.ref2 = React.createRef();
    this.ref3 = React.createRef();
    this.ref4 = React.createRef();
    this.ref5 = React.createRef();
    this.ref7 = React.createRef();
    this.ref8 = React.createRef();
    this.ref9 = React.createRef();
    this.state = {
      open: false,
      defaultKey: ['1'],
    };
  }

  handleAfterClose = (current) => {
    // Make API call to update flag in backend
    const id = _.get(this.props, ['userBaseProfile', 'id'], '');

    const stepLength = this.steps && this.steps.length;
    const userFormData = {};
    userFormData.guide = 'user dashboard';
    userFormData.id = id;
    userFormData.type = 'user';
    if (id && current === stepLength) {
      this.props.updateProfile(userFormData);
    }
  };
  handleSkipTour = () => {
    this.setState({ open: false });

    // Update the backend to mark the tour as completed
    const id = _.get(this.props, ['userBaseProfile', 'id'], '');
    if (id) {
      const userFormData = {
        guide: 'user dashboard',
        id: id,
        type: 'user'
      };
      this.props.updateProfile(userFormData);
    }
  }
  steps = [
    {
      title: (
        <div className="centered-title">
          <span role="img" aria-label="Celebration">
            🎉
          </span>
          <span role="img" aria-label="Graduation Hat">
            🎓
          </span>
          {' '}
          {this.props.t('tutorWebTour1')}
          {' '}
          <span role="img" aria-label="Graduation Hat">
            🎓
          </span>
          <span role="img" aria-label="Celebration">
            🎉
          </span>
        </div>
      ),
      description: (
        <>
          {this.props.t('tutorWebTour2')}
          <br />
          <br />
          {this.props.t('tutorWebTour3')}
        </>
      ),
      placement: 'center',
    },
    {
      title: this.props.t('tutorSidebarMyMessage'),
      description: (
        <>
          {this.props.t('tutorWebTour4')}
        </>
      ),
      placement: 'right',
      target: () => this.ref7.current,
    },
    {
      title: this.props.t('tutorSidebarMyClasses'),
      description: this.props.t('tutorWebTour5'),
      placement: 'right',
      /* cover: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="tour-title" style={{ marginBottom: 10 }}>My Classes</div>
          💻 You can view your purchased classes here 💻
        </div>
      ), */
      target: () => this.ref1.current,
    },
    {
      title: this.props.t('tutorSidebarMyCamps'),
      description: (
        <>
          {this.props.t('tutorWebTour6')}
        </>
      ),
      placement: 'right',
      target: () => this.ref2.current,
    },
    {
      title: this.props.t('tutorSidebarMyCompetitions'),
      description: (
        <>
          {this.props.t('tutorWebTour7')}
        </>
      ),
      placement: 'right',
      target: () => this.ref5.current,
    },
    {
      title: this.props.t('tutorSidebarMyEvents'),
      description: (
        <>
          {this.props.t('tutorWebTour8')}
        </>
      ),
      placement: 'right',
      target: () => this.ref9.current,
    },
    /* {
      description: 'Watch this video to learn how to book a class/camp:',
      placement: 'center',
      cover: (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div className="tour-title" style={{ marginBottom: 10 }}>Book a class/camp</div>
          <video width="100%" height="auto" controls>
            <source
              src={BOOKINGVIDEO}
              type="video/mp4" />
          </video>
        </div>
      ),
    }, */
    {
      title: this.props.t('tutorSidebarMyCalendar'),
      description: (
        <>
          {this.props.t('tutorWebTour10')}
        </>
      ),
      placement: 'right',
      target: () => this.ref3.current,
    },
    // ...(_.get(this, 'props.userBaseProfile.user.account_type') === "dependent"
    // 	? []
    // 	: [
    {
      title: this.props.t('tutorSidebarAttendanceReport'),
      description: this.props.t('tutorWebTour11a'),
      placement: 'right',
      target: () => this.ref4.current,
    },
    // ]),
    {
      title: this.props.t('tutorSidebarSettings'),
      description: (
        <>
          {this.props.t('tutorWebTour12')}
        </>
      ),
      placement: 'right',
      target: () => this.ref8.current,
    },

  ];

  handleClick = async (e) => {
    await this.setState({
      defaultKey: [e.key],
    });
    if (window.innerWidth <= 650) {
      const element = document.querySelector('.ant-layout-sider');
      element.classList.toggle('ant-layout-sider-collapsed');
    }
  };

  async componentDidMount() {
    if (this.props.location && this.props.location.pathname !== this.state.defaultKey) {
      this.setState({
        defaultKey: this.props.location.pathname,
      });
      if (this.props.userBaseProfile) {
        // console.log('tourherereached========', this.props.userBaseProfile)
        if (this.props.userBaseProfile.firstname || this.props.userBaseProfile.mobile_number || this.props.userBaseProfile.zip_code !== 0 || this.props.userBaseProfile.address) {
          if (this.props.userBaseProfile.tour_guide !== 'user dashboard') {
            this.setState({
              open: true,
            });
          }
        }
      }
    }
  }

  render() {
    const { loginDetails } = this.props;
    const { defaultKey } = this.state;
    let user_message_unread = 0;
    // console.log("unreadmessage", this.props.recentchatlistuser);
    if (this.props.recentchatlistuser && this.props.recentchatlistuser.items
      && this.props.recentchatlistuser.items.length > 0) {
      this.props.recentchatlistuser.items.forEach((user_message, index) => {
        const message_list = user_message.provider && user_message.provider.messages_list;
        // console.log("unreadmessage--===", message_list);
        const msgUnreadCount = message_list && message_list.filter((item) => item.type === 'tutor').length;
        if (user_message && user_message.provider) {
          const message_readcount = user_message.provider.read_count_std;
          // console.log("unreadmessage--", msgUnreadCount);
          // console.log("unreadmessage--===", message_readcount);
          if (msgUnreadCount) {
            if (message_readcount) {
              user_message_unread += parseInt(msgUnreadCount) - parseInt(message_readcount);
            } else {
              user_message_unread += parseInt(msgUnreadCount);
            }
            // console.log("unreadmessage=rrrr", user_message_unread);
          }
        }
      });
    }
    let user_message_count = 0;
    if (this.props.tutorMessages && this.props.tutorMessages.items
      && this.props.tutorMessages.items.length > 0) {
      this.props.tutorMessages.items.forEach((user_message, index) => {
        user_message_count = _.isEmpty(user_message.replies) ? user_message_count + 1 : user_message_count;
      });
    }
    const currentPathName = _.get(this, 'props.history.location.pathname');

    return (
      <div>
        <div className="user-dashboard-sidebar-container">
          <Menu
            onClick={(e) => this.handleClick(e)}
            className="mt-0"
            style={{ width: `${100}%` }}
            // defaultSelectedKeys={defaultKey}
            selectedKeys={defaultKey}
            mode="inline"
          >
            <Menu.Item className={currentPathName === '/user/dashboard' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userDashboard}>
              <Link to={routeName.userDashboard}>
                <div className="user-collapsed">
                  <TbLayoutDashboard style={{ fontSize: '25px', color: '#006d77' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarDashboard')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === '/user/message' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.message}>
              <Link to={routeName.message}>
                <div className="user-collapsed" ref={this.ref7}>
                  <FcCollaboration style={{ fontSize: '25px' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyMessage')}
                    {' '}
                    {user_message_unread > 0 ? <Badge className="ml-2 notification-badge" count={user_message_unread} overflowCount={user_message_unread} /> : ''}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === routeName.userClasses ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userClasses}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userClasses}>
                <div className="user-collapsed" ref={this.ref1}>
                  <SiGoogleclassroom style={{ fontSize: '25px', color: 'darkorange' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyClasses')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === routeName.userCamps ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userCamps}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userCamps}>
                <div className="user-collapsed" ref={this.ref2}>
                  <FaCampground style={{ color: 'green', fontSize: '25px' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyCamps')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === routeName.userCompetitions ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userCompetitions}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userCompetitions}>
                <div className="user-collapsed" ref={this.ref5}>
                  <GiChampions style={{ color: 'gold', fontSize: '25px' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyCompetitions')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === routeName.userEvents ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userEvents}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userEvents}>
                <div className="user-collapsed" ref={this.ref9}>
                  <TbTimelineEventPlus style={{ color: '#0099ff', fontSize: '25px' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyEvents')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item className={currentPathName === '/user/calendar' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.userCalendar} onClick={() => this.onchangeMenusItems}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userCalendar}>
                <div className="user-collapsed" ref={this.ref3}>
                  <IoCalendarNumberOutline style={{ fontSize: '25px', color: '#4f1156' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarMyCalendar')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
            {_.get(this, 'props.userBaseProfile.user.account_type') !== 'dependent' && (
              <Menu.Item className={currentPathName === '/user/payment-report' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.paymentReportUser} onClick={() => this.onchangeMenusItems}>
                <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.paymentReportUser}>
                  <div className="user-collapsed" ref={this.ref4}>
                    <FcSurvey style={{ fontSize: '25px' }} />
                    <span className="side-bar-user">
                      {this.props.t('tutorSidebarPaymentReport')}
                      {' '}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            )}
            {_.get(this, 'props.userBaseProfile.user.account_type') !== 'dependent' && (
              <Menu.Item className={currentPathName === '/user/attendance-report' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.attendanceReportUser} onClick={() => this.onchangeMenusItems}>
                <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.attendanceReportUser}>
                  <div className="user-collapsed" ref={this.ref4}>
                    <FcTodoList style={{ fontSize: '25px' }} />
                    <span className="side-bar-user">
                      {this.props.t('tutorSidebarAttendanceReport')}
                      {' '}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            )}

            {/* {_.get(this, 'props.userBaseProfile.user.account_type') !== "dependent" && <Menu.Item className={currentPathName === "/user/invoice" ? "ant-menu-item-selected select-menu" : ""} key={routeName.userpayment} >
							<Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.userpayment}>
								<div className="user-collapsed" >
									<i className="ntm-wallet"></i><span className='side-bar-user'> Pay now </span>
								</div>
							</Link>
						</Menu.Item>} */}

            {_.get(this, 'props.userBaseProfile.user.account_type') !== 'dependent' && (
              <Menu.Item className={currentPathName === '/user/profile' ? 'select-menu' : ''} key={routeName.userProfile}>
                <Link to={routeName.userProfile}>
                  <div className="user-collapsed">
                    <FaUser style={{ color: 'cornflowerblue', fontSize: '20px', marginRight: '5px' }} />
                    <span className="side-bar-user">
                      {' '}
                      {this.props.t('myProfile')}
                      {' '}
                    </span>
                  </div>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item className={currentPathName === '/user/settings' ? 'ant-menu-item-selected select-menu' : ''} key={routeName.usersettings}>
              <Link to={loginDetails && loginDetails.login_type === 'provider' ? '/' : routeName.usersettings}>
                <div className="user-collapsed" ref={this.ref8}>
                  <FcEngineering style={{ fontSize: '25px' }} />
                  <span className="side-bar-user">
                    {' '}
                    {this.props.t('tutorSidebarSettings')}
                    {' '}
                  </span>
                </div>
              </Link>
            </Menu.Item>
          </Menu>
        </div>
        <Tour
          open={this.state.open}
          onClose={this.handleSkipTour}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1}
              {' '}
              /
              {total}
            </span>
          )}
          onChange={(current) => this.handleAfterClose(current + 1)}
          mask={{
            style: {
              boxShadow: 'inset 0 0 15px #333',
            },
          }}
          type="primary"
          // steps={this.steps}
          steps={_.get(this, 'props.userBaseProfile.user.account_type') !== 'dependent' ? this.steps : this.steps.filter((item, index) => index !== 6)}
        />

      </div>
    );
  }
}

const mapStateToProps = (state) =>
// console.log('stateToProps', state);
({
  loginDetails: state.authentication,
  userBaseProfile: state.userProfile.userBaseProfile.details,
  recentchatlistuser: state.message.recentchatlistuser,
  updatedData: state.userProfile.updateUserProfileInfo,
  updateTourGuide: state.guide.updateTourGuide,
});

const mapDispatchToProps = (dispatch) => ({
  onsiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),
  ontutorSearchBox: (requestParams) => dispatch(servicesActions.ontutorSearchBox(requestParams)),
  onGetRecentMessages: (requestParams) => dispatch(msgAction.recentMessages(requestParams)),
  updateProfile: (data, callback) => dispatch(tourGuideActions.onTourGuide(data, callback)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(withRouter(UserDashboardPageSidebar)));
