import React, { useState } from 'react';
import {
  Input, Button, Radio, Form, Modal,
} from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import FileUpload from '../../../../components/re-usable/file-upload';
import * as action from '../../../../store/actions/tutor/camps';
import * as common from '../../../../store/actions/tutor/common';
import notifications from '../../../../components/re-usable/response-notification';
import timeDateConverterSession from '../../../../components/timezone/sessionTimeDate';
import downloadXls from '../../../../utils/common/download-xls';
import { OBUSERXLSX } from '../../../../utils/config/assets';

const EnrollStudent = (props) => {
  console.log('session>>', props.session);
  const [loading] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [getClassSlot] = useState('');
  const [, setXlsUploadedErrorData] = useState([]);
  const [form] = Form.useForm();
  const [radioOption, setRadioOption] = useState(1);
  const options = [
    { label: props.t('enrollUser'), value: 1 },
    { label: props.t('enrollChildUser'), value: 2 },
    { label: props.t('enrollMultipleUser'), value: 3 },
  ];

  const callback = (respData) => {
    const requestParams = {};
    requestParams.limit = 1000;
    requestParams.camp_details = props.session.id;
    requestParams.expand = 'user,dependent_user';
    requestParams.type = props.type;
    props.getCampsStudents(requestParams);

    notifications(respData);
  };

  const onChangeForm = (e) => {
    const bookingData = {
      ...e,
      classID: props.session.id,
      type: props.type,
      group_id: props.session.group_id,
      booking_dates: new Date().toISOString(),
    };
    props.enrollStudents(bookingData, callback);
  };

  const onChangeRadio = (e) => {
    form.resetFields();
    setRadioOption(e.target.value);
  };

  const endDateCheck = (props) => {
    const todayDate = new Date();
    const endDate = dayjs(props.end_date);
    const actual_end_date = timeDateConverterSession(endDate, 'date', 'MM-DD-YYYY');

    if (dayjs(actual_end_date).isBefore(dayjs(todayDate))) {
      return true;
    }
    return false;
  };

  const render = () => (
    <div className="container">

      <Form
        scrollToFirstError
        form={form}
        onFinish={(e) => {
          onChangeForm(e, radioOption);
        }}
        className="row"
      >
        <div className="col-12 mb-4">
          <Radio.Group
            options={options}
            onChange={(e) => onChangeRadio(e)}
            value={radioOption}
            defaultValue={1}
          />
        </div>
        {/* Enroll a User */}
        {userEnrollForm(radioOption, setModal2Visible, props.session.id, getClassSlot, setXlsUploadedErrorData, props.session.group_id, props.getCampsStudents)}

        {(radioOption !== 3) ? (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mt-3">
            <Button className="btn-cancel mt-1 mr-3" onClick={() => form.resetFields()}>{props.t('cancel')}</Button>
            <Button loading={loading} htmlType="submit" disabled={endDateCheck(props.session)} className="btn-submit mt-1">{props.t('submit')}</Button>
          </div>
        )
          : ''}
      </Form>

      <Modal
        className="meeting-information"
        title="Where to find the ID?"
        centered
        open={modal2Visible}
        footer={null}
        onOk={() => setModal2Visible(false)}
        onCancel={() => setModal2Visible(false)}
      >
        <div className="text-center">
          <img src={require('../../../../assets/images/sample-data.jpg')} className="img-fluid" alt="img" />
        </div>
      </Modal>
    </div>
  );
  return render();
};

const mapStateToProps = null;

const mapDispatchToProps = (dispatch) => ({
  getCampsStudents: (requestParams) => dispatch(action.getCampsStudents(requestParams)),
  enrollStudents: (requestParams, cb) => dispatch(common.enroll(requestParams, cb)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(EnrollStudent));

function userEnrollForm(radioOption, modal2Visible, clsID, classSlot, xlsUploadedErrorData, grpId, callback = () => { }) {
  if (radioOption === 1) {
    return enrollUser(modal2Visible, 1);
  } if (radioOption === 2) {
    return enrollUser(modal2Visible, 2);
  } if (radioOption === 3) {
    return enrollMultpleUser(clsID, classSlot, xlsUploadedErrorData, grpId, callback);
  }
}

function enrollUser(setModal2Visible, type) {
  return (
    <div className="container">
      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        {type === 1 ? userID() : dependentID()}
      </div>

      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setModal2Visible(true)}>Where to find the ID?</div>
      </div>

    </div>
  );
}

function dependentID() {
  return (
    <Form.Item
      rules={[{
        required: true, message: 'Please enter dependent id',
      }, ({ getFieldValue }) => ({
        validator(_, value) {
          if (value && getFieldValue('user_id') === value) {
            return Promise.reject(new Error('The two fields id same, Please enter proper id'));
          }
          return Promise.resolve();
        },
      })]}
      name="dependent_user"
    >
      <Input placeholder="Child user's Nurtem ID" />
    </Form.Item>
  );
}

function userID() {
  return (
    <Form.Item
      rules={[{
        required: true, message: 'Please enter user id',
      }]}
      name="user_id"
    >
      <Input placeholder="User's Nurtem ID" />
    </Form.Item>
  );
}

function enrollMultpleUser(classID, classSlot, xlsUploadedErrorData, grpId, callback) {
  const clssdet = {
    type: 'camps', details: classID, classSlot, grpId, redirecturl: window.location.href,
  };

  return (
    <div
      className="container"
      style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', columnGap: '25px',
      }}
    >
      <FileUpload clssdet={clssdet} callback={xlsUploadedErrorData} callbackMore={callback} />
      <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
        <div onClick={() => downloadXls(OBUSERXLSX)} style={{ textDecoration: 'underline' }}>Download xls template</div>
      </div>
    </div>
  );
}
