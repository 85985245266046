import React from 'react';

export class ProfileCompletionLoader extends React.Component {
  render() {
    return (
      <div className="Profile_Completion_Container DashboardProfileCompletionContainer flex flex-column flexJustifyBetween">
        <div className="request-card-placeholder profile-completion-placeholder">
          <div className="Tutor_Request_Set_Info_Container">
            <div className="w-100">
              <div className="line_1" />
            </div>
            <div className="SectionTableActionSet flexJustifyBetween">
              <div className="line_2" />
              <div className="btn_plh" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
