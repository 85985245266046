import React, { useEffect, useState } from 'react';
import {
  notification, Modal, Button, Select, Form,
} from 'antd';
import { AiOutlineSearch } from 'react-icons/ai';
import { connect } from 'react-redux';
import generalAction from '../../../store/general/action';
import './style.css';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

const { Option } = Select;

const AssignTutorModalComponent = (props) => {
  const form = React.createRef();
  const [tutorList, setTutorList] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (_.size(_.get(props, 'tutorsList', [])) > 0) {
      const onLoadList = [];

      _.map(_.get(props, 'tutorList', []), (value, i) => {
        if (_.get(props, 'tutorBaseProfile.id') !== _.get(value, 'value') && _.get(props, 'classTutorDetails.provider') !== _.get(value, 'value')) {
          onLoadList.push(value);
        }
      });
      setTutorList(onLoadList);
    }
  }, []);
  useEffect(() => {
    if (_.size(_.get(props, 'tutorsList', [])) > 0) {
      const onLoadList = [];
      _.map(_.get(props, 'tutorsList', []), (value, i) => {
        if (_.get(props, 'tutorBaseProfile.id') !== _.get(value, 'value') && _.get(props, 'classTutorDetails.provider') !== _.get(value, 'value')) {
          onLoadList.push(value);
        }
      });
      setTutorList(onLoadList);
    }
  }, [_.get(props, 'tutorsList')]);

  useEffect(() => {
    if (_.size(_.get(props, 'tutorsList', [])) > 0) {
      const onLoadList = [];
      _.map(_.get(props, 'tutorsList', []), (value, i) => {
        if (_.get(props, 'tutorBaseProfile.id') !== _.get(value, 'value') && _.get(props, 'classTutorDetails.provider') !== _.get(value, 'value')) {
          onLoadList.push(value);
        }
      });
      setTutorList(onLoadList);
    }
  }, [_.get(props, 'classTutorDetails')]);
  useEffect(() => {
    if (form && form.current) {
      form.current.resetFields();
      setLoading(false);
    }
  }, [_.get(props, 'formReset')]);
  const onChangeForm = (e) => {
    const transferType = _.get(props, 'type') === 'classes' ? ['classes'] : _.get(props, 'type') === 'camps'
      ? ['camps'] : _.get(props, 'type') === 'events'
        ? ['events'] : ['competitions'];
    _.map(transferType, (v, i) => {
      const body = {
        new_provider: e.provider,
        transfer_type: v,
        ..._.get(props, 'classTutorDetails', {}),
      };
      if (_.get(props, 'classTutorDetails.provider') !== _.get(props, 'tutorBaseProfile.id')) {
        body.update_type = 'works_for';
      }
      setLoading(true);
      props.tutorTransfer({
        body,
        callback: (res, data) => {
          if (res === true && i === _.size(transferType) - 1) {
            notification.success({
              message: 'Success',
              description: _.get(data, 'message'),
            });
            props.callback();
            props.tutorAssignModalFunction(false, '');
          }
          setLoading(false);
        },
      });
    });
  };

  const render = () => (
    <Modal

      className="faq-modal assign-tutor-modal"
      open={_.get(props, 'assignTutorModal', false)}
      centered
      maskClosable={!loading}
      closable={!loading}
      title="Select a tutor to reassign "
      onCancel={() => props.tutorAssignModalFunction(false, '')}
      footer={null}
    >
      <Form scrollToFirstError onFinish={(e) => onChangeForm(e, {}, 'createFaq')} ref={form}>
        <div className="row">

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="full-width ">
              <div className="full-width position-relative row">
                <Form.Item
                  rules={[{
                    required: true, message: props.t('tutorValid'),
                  }]}
                  name="provider"
                  className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                  prefix={<span className="search-icon"><AiOutlineSearch /></span>}
                >

                  <Select
                    disabled={!!loading}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    showSearch
                    style={{ width: `${100}%` }}
                    className="global-search"
                    optionFilterProp="children"
                    placeholder="Search tutor..."
                    notFoundContent={null}
                  >
                    {tutorList && tutorList.length > 0 && tutorList[0] && tutorList[0].length > 0 && tutorList[0].map((d, index) => (
                      <Option value={d.id} key={d.id}>{d.type === 'Individual' ? `${d.firstname} ${d.lastname}` : d.businessname}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </div>

          <Form.Item className="col-12">
            <div className="full-width text-right">
              <Button disabled={loading} onClick={() => props.tutorAssignModalFunction(false, '')} className="mr-3" type="primary" danger>{props.t('cancel')}</Button>
              <Button loading={loading} htmlType="submit" type="primary">{props.t('submit')}</Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
  return render();
};

const mapStateToProps = (state) => ({
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  tutorTransfer: (data) => dispatch(generalAction.tutor.providerTransfer(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(AssignTutorModalComponent));
