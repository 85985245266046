// Function to clean up the specific unwanted HTML patterns
const cleanHtmlContent = (htmlContent) => {
// Remove '<p></p>\n' and '<p></p><br>' patterns
let cleanedHtml = htmlContent
.replace(/<p><\/p>\n/g, '') // Remove <p></p>\n pattern
.replace(/<p><\/p><br>/g, '') // Remove <p></p><br> pattern
.replace(/<p><\/p>/g, '')
return cleanedHtml;
}

export  {cleanHtmlContent}