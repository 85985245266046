import React from 'react';

export default class CartLoader extends React.Component {
  render() {
    return (
      <>
        <div
          className="CollapseHeaderContainer Placeholder"
          style={{ marginBottom: '15px' }}
        >
          <div className="CollapseHeaderProfSet">
            <span className="CollapseHeaderProfSetProfileImg" />
            <div className="CollapseHeaderProfSetProfileInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
          <div className="CollapseHeaderProfSet">
            <i className="lnr lnr-map-marker LocationIcon" />
            <div className="CollapseHeaderInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
        <div
          className="CollapseHeaderContainer Placeholder"
          style={{ marginBottom: '15px' }}
        >
          <div className="CollapseHeaderProfSet">
            <span className="CollapseHeaderProfSetProfileImg" />
            <div className="CollapseHeaderProfSetProfileInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
          <div className="CollapseHeaderProfSet">
            <i className="lnr lnr-map-marker LocationIcon" />
            <div className="CollapseHeaderInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
        <div
          className="CollapseHeaderContainer Placeholder"
          style={{ marginBottom: '15px' }}
        >
          <div className="CollapseHeaderProfSet">
            <span className="CollapseHeaderProfSetProfileImg" />
            <div className="CollapseHeaderProfSetProfileInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
          <div className="CollapseHeaderProfSet">
            <i className="lnr lnr-map-marker LocationIcon" />
            <div className="CollapseHeaderInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
        <div
          className="CollapseHeaderContainer Placeholder"
          style={{ marginBottom: '15px' }}
        >
          <div className="CollapseHeaderProfSet">
            <span className="CollapseHeaderProfSetProfileImg" />
            <div className="CollapseHeaderProfSetProfileInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
          <div className="CollapseHeaderProfSet">
            <i className="lnr lnr-map-marker LocationIcon" />
            <div className="CollapseHeaderInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
        <div
          className="CollapseHeaderContainer Placeholder"
          style={{ marginBottom: '15px' }}
        >
          <div className="CollapseHeaderProfSet">
            <span className="CollapseHeaderProfSetProfileImg" />
            <div className="CollapseHeaderProfSetProfileInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
          <div className="CollapseHeaderProfSet">
            <i className="lnr lnr-map-marker LocationIcon" />
            <div className="CollapseHeaderInfo">
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
      </>
    );
  }
}
