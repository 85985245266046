import React, { useEffect, useState } from 'react';
import {
  Input, Table, Modal, Button, Form, Pagination, Tooltip,
} from 'antd';
import { AiOutlineDelete, AiOutlineEdit, AiOutlineCheckCircle } from 'react-icons/ai';
import _ from 'lodash';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { withTranslation } from 'react-i18next';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import * as action from '../../store/actions/tutor/camps';

dayjs.extend(utc);
dayjs.extend(timezone);

const Milestones = (props) => {
  const [modal2Visible, setModal2Visible] = useState(false);
  const [milestoneType, setMilestoneType] = useState('');
  const [milestoneValue, setMilestoneValue] = useState('');
  const [list, setMileStonesList] = useState(_.get(props, 'mileStonesList', []));
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  const columns = [
    {
      title: props.t('milestones'),
      dataIndex: 'milestones',
      key: 'milestones',
    },
    {
      title: props.t('Action'),
      dataIndex: 'action',
      key: 'action',
    },
  ];
  const updateMileStones = (value, id, type = milestoneType) => {
    try {
      const requestParams = {};
      const trimmedValues = Object.fromEntries(
        Object.entries(value).map(([key, value]) => [key, value.trim()]),
      );
      const hasNonEmptyFields = Object.values(trimmedValues).some(Boolean);

      if (!hasNonEmptyFields && type !== 'delete' && type !== 'completed') {
        return;
      }
      if (type === 'edit') {
        const last = _.pull(list, milestoneValue);
        requestParams.milestones = [...last, _.get(value, 'title')];
        requestParams.type = props.type;
        requestParams.mode = 'update';
        requestParams.milestone_id = id;
        requestParams.session_details = props.session.id;
        requestParams.type_details = props.session.group_id;
        requestParams.timezone = dayjs.tz.guess();
        requestParams.time = new Date();
      } else if (type === 'delete') {
        console.log('camehere=========');
        // requestParams.milestones = [...list, _.get(value, 'title')];
        requestParams.milestone_id = id;
        requestParams.type = props.type;
        requestParams.mode = 'delete';
        requestParams.session_details = props.session.id;
        requestParams.type_details = props.session.group_id;
        requestParams.timezone = dayjs.tz.guess();
        requestParams.time = new Date();
      } else if (type === 'add') {
        if (_.get(value, 'title') && !list.includes(_.get(value, 'title'))) {
          requestParams.milestones = [...list, _.get(value, 'title')];
          requestParams.type = props.type;
          requestParams.mode = 'create';
          requestParams.session_details = props.session.id;
          requestParams.type_details = props.session.group_id;
          requestParams.timezone = dayjs.tz.guess();
          requestParams.time = new Date();
        }
      } else if (type === 'completed') {
        requestParams.type = props.type;
        requestParams.mode = 'completed';
        requestParams.session_details = props.session.id;
        requestParams.milestone_id = id;
        requestParams.completed = 1;
        requestParams.timezone = dayjs.tz.guess();
        requestParams.time = new Date();
      } else {
        requestParams.milestones = _.without(list, value);
        requestParams.classDetails = _.get(props, 'classDetails');
        setMileStonesList(requestParams.milestones);
      }

      props.onCreateMileStone(requestParams);

      setTimeout(async () => {
        setModal2Visible(false);
        await form.resetFields();
      }, 100);
    } catch (error) {
      console.log('error at components/common/milestone.js', error);
    } finally {
      setTimeout(() => {
        setModal2Visible(false);
      }, 100);
    }
  };

  const deleteMileStone = (val) => {
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Are you sure to delete this milestone?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      cancelButtonProps: {
        disabled: modal2Visible,
      },
      okButtonProps: {
        loading: modal2Visible,
      },

      onOk: () => {
        updateMileStones({}, val, 'delete');

        setTimeout(() => {
          Modal.destroyAll();
        }, 200);
      },
    });
  };

  const completeMileStone = (val) => {
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Congrats u have achieved the milestone!',
      okText: 'Yes',
      okType: 'primary',
      cancelText: 'No',
      cancelButtonProps: {
        disabled: modal2Visible,
      },
      okButtonProps: {
        loading: modal2Visible,
      },

      onOk: () => {
        updateMileStones({}, val, 'completed');

        setTimeout(() => {
          Modal.destroyAll();
        }, 200);
      },
    });
  };

  useEffect(() => {
    if (_.get(props, 'onCreateMileStone.status') === 200) {
      setMileStonesList([..._.get(props, 'onCreateMileStone.details.milestones', [])]);
      form.resetFields();
    }
  }, [_.get(props, 'onCreateMileStone')]);

  useEffect(() => {
    props.listMilestone({ id: props.session.id, type: props.type });
  }, [props.session.id, props.milestoneCreate]);

  const customLocale = {
    emptyText: (<div>
      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

      <p className="empty-animations">{props.t('noMile')}</p>
    </div>),
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    const newStartIndex = (page - 1) * pageSize;
    const newEndIndex = newStartIndex + pageSize;
    startIndex = newStartIndex;
    endIndex = newEndIndex;
  };

  const render = () => {
    let mileStones = [];
    try {
      if (props.milestoneList && props.milestoneList.items && props.milestoneList.items.length > 0) {
        mileStones = props.milestoneList.items.slice(startIndex, endIndex).map((value, index) => ({
          milestones:
            <p style={{ wordWrap: 'break-word' }}>{_.get(value, 'name')}</p>,
          key: index,
          action:
            <div>
              {value.completed === 1 ? (
                <span>Completed</span>
              ) : (
                <>
                  <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Edit">
                    <AiOutlineEdit
                      className="faq-buttons-edit"
                      onClick={() => {
                        setMilestoneType('edit');
                        setMilestoneValue(value.id);
                        form.setFieldsValue({ title: value.name });
                        setModal2Visible(true);
                      }}
                      style={{ fontSize: 29, marginBottom: 5 }}
                      color="#52c41a"
                    />
                  </Tooltip>
                  <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Delete">
                    <AiOutlineDelete
                      className="faq-buttons-delete"
                      onClick={() => {
                        setMilestoneType('delete');
                        setMilestoneValue(value.id);
                        deleteMileStone(value.id);
                      }}
                      color="#ff5900"
                    />
                  </Tooltip>
                </>
              )}
              <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Completed">
                <button disabled={value.completed === 1} type="button" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <AiOutlineCheckCircle
                    className="faq-buttons-tick"
                    color="#d1a30e"
                    style={{ fontSize: 29, marginBottom: 10 }}
                    onClick={() => {
                      setMilestoneType('complete');
                      setMilestoneValue(value.id);
                      completeMileStone(value.id);
                    }}
                  />
                </button>
              </Tooltip>
            </div>,
        }));
      }
    } catch (error) {
      console.log('error at milestone.js in render function');
    }
    return (
      <div className="container">
        {/* items Header */}
        <div className="row mb-3">
          <div className=" col-6 tab-content-title">{props.t('milestones')}</div>
          <div className="col-6" style={{ textAlign: 'end' }}>
            <Button
              type="primary"
              disabled={_.get(props, 'classDetails.no_of_students_paid') > 0}
              className="btn-green-filled"
              onClick={() => {
                setModal2Visible(true);
                setMilestoneType('add');
              }}
            >
              {props.t('addMile')}
            </Button>
          </div>
        </div>
        {/* items Header */}
        <div className="row" style={{ margin: 0 }}>
          {mileStones.length > 0 && (
            <Table
              dataSource={mileStones}
              columns={columns}
              pagination={false}
              locale={customLocale}
              style={{ width: '100%', marginBottom: '20px' }}
            />
          )}
          {mileStones.length > 0 && props.milestoneList && props.milestoneList.items && props.milestoneList.items.length > itemsPerPage && (
            <Pagination
              className="mt-3"
              current={currentPage}
              pageSize={itemsPerPage}
              total={props.milestoneList.items.length}
              onChange={(page, pageSize) => handlePageChange(page, pageSize)}
            />
          )}
        </div>
        <Modal
          className="faq-modal"
          title={milestoneType === 'add' ? 'Add Milestone' : 'Update Milestone'}
          centered
          open={modal2Visible}
          onOk={() => setModal2Visible(false)}
          onCancel={async () => { await form.resetFields(); setModal2Visible(false); }}
          footer={null}
        >
          <Form form={form} scrollToFirstError onFinish={(e) => updateMileStones(e, milestoneValue)} className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Form.Item
                label="Milestone Details"
                name="title"
                rules={[
                  {
                    required: true,
                    message: props.t('addTTitleMile'),
                  },
                  {
                    max: 60,
                    message: props.t('maxTitleLength'),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-12" style={{ textAlign: 'end' }}>
              <Button type="primary" danger key="back" onClick={async () => { await form.resetFields(); setModal2Visible(false); }}>{props.t('cancel')}</Button>
              <Button loading={_.get(props, 'loading', false)} className="ml-2" type="primary" htmlType="submit" key="submit">
                {milestoneType === 'add' ? 'Add' : 'Update'}
                {' '}
                {props.t('milestones')}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  milestoneCreate: state.campsDetails.milestoneCreate,
  milestoneList: state.campsDetails.milestoneList.data,
});

const mapDispatchToProps = (dispatch) => ({
  onCreateMileStone: (requestParams) => dispatch(action.onCreateMilestoneForAll(requestParams)),
  listMilestone: (data, callback) => dispatch(action.onListMilestone(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Milestones));
