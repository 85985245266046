import React from 'react'
import MultiStage from '../update-class/component/multi-stage'

function TutorUpdateClass() {
  return (
    <div className="nurtem-card-design tutor-add-camps-page py-4 mb-3">
      <MultiStage type="events" label="Event" />
    </div>
  )
}

export default TutorUpdateClass
