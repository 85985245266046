import React, { useState, useEffect } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import './ach.css'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

function CheckoutForm(props) {
  const [delayTime, setDelayTime] = useState(5000)
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {

    try {

      e.preventDefault()
      setIsLoading(true)

      props.onCreateTransaction({
        clientSecret:"",
        status: 'succeeded',
        paymentIntent_data: props.paymentIntent_data,
      })

      setTimeout(() => {
        setIsLoading(false)
        props.showACHModal(false)
      }, 1500)


    } catch (error) {
      
    }
    
  }

  
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <button  id="submit">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner" />
          ) : "Proceed with the Plan"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

export default withTranslation()(CheckoutForm)
