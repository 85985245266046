export const globalVariable = Object.freeze({
  production: true,

  /// ////////////////////////////////////////////////////////////////////////////////////////////
  /// ////////////////////////////   PRODUCTION   SETTINGS  BEGIN   //////////////////////////////
  /// ////////////////////////////////////////////////////////////////////////////////////////////
  CURRENCY_SYMBOL: '$',
  // NURTEM SETTINGS //
  BASE_API_URL: 'http://api.nurtem.com/nurtem/',
  RESOURCE_URL: 'http://static.nurtem.com/nurtem/assets/',
  MIDDLEWARE_URL: 'http://middleware.nurtem.com/',
  REDIRECT_URL_SIGNUP:'https://class.nurtem.com/sign-up',
  REDIRECT_URL_SIGNIN:'https://class.nurtem.com/sign-in',
  DEFAULT_PROFILE_PIC: 'https://nurtem-s3.s3.us-west-2.amazonaws.com/gallery/default-user.jpeg',

  //url links
  EXPLORE_URL: 'https://nurtem.com/classes-and-camps/',
  CONTACT_URL: 'https://nurtem.com/contact-nurtem/',


  // INTEGRATIONS SETTINGS //
  APPLE_ID:'com.nurtem.class.sid',
  FACEBOOK_ID: '2053307541422015',
  GOOGLE_ID:
    '994331906268-migmv6eumu3abdtu6r49g7kqojako6j0.apps.googleusercontent.com',
  GOOGLE_CLIENT_ID:
    '1085603581700-48hms3t2ul6504jkim1h0u8fvv7dagq2.apps.googleusercontent.com',
  GOOGLE_CLIENT_SECREATE: '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',
  GOOGLE_API_KEY: 'AIzaSyBwAvzAQWYxbruCgj2Pr1rK6OX_mx3mWEM',
  GOOGLE_MAP_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  IPAPI_URL: "https://ipapi.co/json?key=LnUQkXKdgGZLXpf8DHvOZdIcr8QvNGnsX4rI7iUmnFVCO3DETy",
  // PAYMENT - STRIPE AND PLAID SETTINGS //
  STRIPE_PUBLISHABLE_KEY: 'pk_live_6oQbHUgj13JH8lNsZgxTFUti',
  PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665'

  /// ////////////////////////////////////////////////////////////////////////////////////////////
  /// ////////////////////////////   PRODUCTION   SETTINGS  END   ////////////////////////////////
  /// ////////////////////////////////////////////////////////////////////////////////////////////

  /*
  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////   NURTEM EVENT SETTINGS  BEGIN   //////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////
  // NURTEM SETTINGS //

  BASE_API_URL: 'https://nurtemeventapi.nurtem.com/nurtem/',
  RESOURCE_URL: 'http://static.nurtem.com/assets/',
  FACEBOOK_ID: '2053307541422015',
  GOOGLE_ID:
    '994331906268-0t9juq457bma4ofr3j22d4odmig4lqah.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyBwAvzAQWYxbruCgj2Pr1rK6OX_mx3mWEM',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_RVT3Gz5tuyAWZ1kQnMLYwdfB',
  PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665',
  DEFAULT_PROFILE_PIC: 'https://nurtem-s3.s3.us-west-2.amazonaws.com/gallery/default-user.jpeg',
  IPAPI_URL: "https://ipapi.co/json?key=LnUQkXKdgGZLXpf8DHvOZdIcr8QvNGnsX4rI7iUmnFVCO3DETy",
  APPLE_ID: 'com.nurtem.class.sid',
  REDIRECT_URL_SIGNUP:'https://nurtemevent.nurtem.com/sign-up',
  REDIRECT_URL_SIGNIN:'https://nurtemevent.nurtem.com/sign-in'

  ///////////////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////   NURTEM EVENT SETTINGS  END   /////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////

*/

  // NURTEMDEV
  // BASE_API_URL: 'https://nurtemdevapi.nurtem.com/nurtem/',
  // RESOURCE_URL: 'http://static.nurtem.com/assets/',
  // FACEBOOK_ID: '2053307541422015',
  // GOOGLE_ID: '994331906268-migmv6eumu3abdtu6r49g7kqojako6j0.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_live_6oQbHUgj13JH8lNsZgxTFUti',
  // MIDDLEWARE_URL: 'http://middleware.nurtem.com/',
  // MIDDLEWARE_URL: `${baseUrl}:${window._env_.KUBESAFE_MIDDLEWARE_PORT}/api`
  // GOOGLE_ID:
  //  '994331906268-0t9juq457bma4ofr3j22d4odmig4lqah.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyBwAvzAQWYxbruCgj2Pr1rK6OX_mx3mWEM',
  // GOOGLE_MAP_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_test_RVT3Gz5tuyAWZ1kQnMLYwdfB',
  // PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665',
  // DEFAULT_PROFILE_PIC: 'https://nurtem-s3.s3.us-west-2.amazonaws.com/gallery/default-user.jpeg',
  // IPAPI_URL: "https://ipapi.co/json?key=LnUQkXKdgGZLXpf8DHvOZdIcr8QvNGnsX4rI7iUmnFVCO3DETy",
  // APPLE_ID:'com.nurtem.class.sid',
  // REDIRECT_URL_SIGNUP:'https://class.nurtem.com/sign-up',
  // REDIRECT_URL_SIGNIN:'https://class.nurtem.com/sign-in'

  // TESTPROD
  // BASE_API_URL: 'https://testprodapi.nurtem.com/nurtem/',
  // RESOURCE_URL: 'http://static.nurtem.com/assets/',
  // FACEBOOK_ID: '2053307541422015',
  // GOOGLE_ID:
  //  '994331906268-0t9juq457bma4ofr3j22d4odmig4lqah.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyBwAvzAQWYxbruCgj2Pr1rK6OX_mx3mWEM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_test_RVT3Gz5tuyAWZ1kQnMLYwdfB',
  // PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665'
  // APPLE_ID: 'com.nurtem.testprod.sid',

  // STAGING
  // APPLE_ID: 'com.nurtem.class.sid',
  // REDIRECT_URL_SIGNUP:'https://nurtemdev.nurtem.com/sign-up',
  // REDIRECT_URL_SIGNIN:'https://nurtemdev.nurtem.com/sign-in'

  // STAGING
  // BASE_API_URL: 'http://localhost:3000/nurtem/',
  // RESOURCE_URL: 'http://localhost:3000/nurtem/assets/',
  // FACEBOOK_ID: '761606294172063',
  // GOOGLE_ID:
  //  '616610737698-5svjh9p2su7jkvhqte208sp4ohsvd64m.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_test_RVT3Gz5tuyAWZ1kQnMLYwdfB',
  // MIDDLEWARE_URL: `${baseUrl}:${window._env_.KUBESAFE_MIDDLEWARE_PORT}/api`
  // PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665'

  // DEVELOPMENT
  // BASE_API_URL: 'http://localhost:3000/nurtem/',
  // RESOURCE_URL: 'http://localhost:3000/nurtem/assets/',
  // FACEBOOK_ID: '761606294172063',
  // GOOGLE_ID:
  //  '616610737698-5svjh9p2su7jkvhqte208sp4ohsvd64m.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_test_RVT3Gz5tuyAWZ1kQnMLYwdfB',
  // MIDDLEWARE_URL: `${baseUrl}:${window._env_.KUBESAFE_MIDDLEWARE_PORT}/api`
  // PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665'

  // PRODUCTION
  // BASE_API_URL: 'http://localhost:3000/nurtem/',
  // RESOURCE_URL: 'http://localhost:3000/nurtem/assets/',
  // FACEBOOK_ID: '2053307541422015',
  // GOOGLE_ID:
  // '994331906268-migmv6eumu3abdtu6r49g7kqojako6j0.apps.googleusercontent.com',
  // GOOGLE_API_KEY: 'AIzaSyCcNGzm7mNoF7U5SJt4MPqELsbLvdS-ZBM',
  // STRIPE_PUBLISHABLE_KEY: 'pk_live_6oQbHUgj13JH8lNsZgxTFUti',
  // MIDDLEWARE_URL: `${baseUrl}:${window._env_.KUBESAFE_MIDDLEWARE_PORT}/api`,
  // PLAID_PUBLIC_KEY: '43b86b2b0f9b1e60dc56fe52105665',
  // REDIRECT_URL_SIGNUP:'https://localhost:3000/sign-up',
  // REDIRECT_URL_SIGNIN:'https://localhost:3000/sign-in'
})
