import React, { FC, ReactNode } from 'react'
import { List } from 'antd'
export type ColumnCount = number
export type ColumnType =
  | 'gutter'
  | 'column'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
export interface ListGridType {
  column?: ColumnCount
  xs?: ColumnCount
  sm?: ColumnCount
  md?: ColumnCount
  lg?: ColumnCount
  xl?: ColumnCount
  xxl?: ColumnCount
}
interface CommonAntdListProps {
  className?: string
  itemLayout?: 'horizontal' | 'vertical'
  loading?: boolean
  loadMore?: ReactNode
  grid?: ListGridType
  renderItem: (item: any) => ReactNode
  dataSource: any[]
}

export const CommonAntdList: FC<CommonAntdListProps> = ({
  className = 'demo-loadmore-list full-width',
  itemLayout = 'horizontal',
  loading = false,
  grid,
  loadMore,
  dataSource,
  renderItem,
}) => {
  return (
    <List
      className={className}
      loading={loading}
      grid={grid}
      itemLayout={itemLayout}
      loadMore={loadMore}
      dataSource={dataSource}
      renderItem={renderItem}
    />
  )
}
