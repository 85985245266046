import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { DefaultLoader } from '../../components/loader/default-loader';

export const withLoaderHandler = (
  WrappedComponent,
  axios,
  LoaderComponent = DefaultLoader,
) => {
  class Handler extends Component {
    _isMounted = false;

    state = {
      loading: false,
      handler: { isNotHandled: true },
    };

    numberOfAjaxCAllPending = 0;

    componentDidMount() {
      this._isMounted = true;
    }

    UNSAFE_componentWillMount() {
      this.reqInterceptor = axios.interceptors.request.use((req) => {
        if (this._isMounted) {
          this.numberOfAjaxCAllPending++;

          if (!this.state.handler.isNotHandled) {
            this.setState({ loading: true });
          }
        }
        return req;
      });
      this.resInterceptor = axios.interceptors.response.use(
        (res) => {
          if (this._isMounted) {
            if (
              !this.state.handler.isNotHandled
              && this.numberOfAjaxCAllPending === 1
            ) {
              this.setState({ loading: false });
            }
            this.numberOfAjaxCAllPending--;
          }
          return res;
        },
        (error) => {
          if (this._isMounted) {
            if (
              !this.state.handler.isNotHandled
              && this.numberOfAjaxCAllPending === 1
            ) {
              this.setState({ loading: false });
            }
            this.numberOfAjaxCAllPending--;
          }
          return error;
        },
      );
    }

    componentWillUnmount() {
      this._isMounted = false;
      axios.interceptors.request.eject(this.reqInterceptor);
      axios.interceptors.response.eject(this.resInterceptor);
    }

    render() {
      return (
        <>
          {this.state.loading ? (
            <div className="defaultLoader">
              <LoaderComponent />
            </div>
          ) : (
            <WrappedComponent
              handler={{
                ...this.state.handler,
                handleThis: () => {
                  this.setState({ handler: { isNotHandled: false } });
                },
              }}
              {...this.props}
            />
          )}
        </>
      );
    }
  }
  return withTranslation()(Handler);
};

// note please use following line to your component if you call any redux async func in a lifeCycle hook that invoke once after the component mount

/*
ie:
        if(this.props.handler.isNotHandled)
          {
            this.props.handler.handleThis();
            your redux axios func call
          }

*/
