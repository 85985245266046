import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Router } from 'react-router-dom';
import history from '../utils/history';
import LayoutContent from './content';

class WebLayout extends React.Component {
  componentDidMount() {

  }

  render() {
    return (
      <Router history={history}>

        <LayoutContent />

      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  loggedInUserDetails: state.authentication,
  onGoBack: state.authentication.goBack,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(WebLayout));
