import React, { useEffect, useState } from 'react';
import {
  Form, Spin, Input, Button, message, Upload, notification, Tooltip,
} from 'antd';
import { AiOutlineEdit, AiOutlineDelete, AiOutlinePlus } from 'react-icons/ai';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _ from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import * as actions from '../../../../../store/actions/tutor';
import generalInfoFormMeta from './form-meta';
import generalAction from '../../../../../store/general/action';
import generalActionTypes from '../../../../../store/general/actionTypes';

// const { TextArea } = Input;
function MediaAndAnnouncements(props) {
  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(generalInfoFormMeta);
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({});
  // Declare a new state variable, which we'll call "didMount"
  const [didMount, setDidMount] = useState(false);
  // Declare a new state variable, which we'll call "tutorAnnouncements"
  const [tutorAnnouncements, setTutorAnnouncements] = useState(EditorState.createEmpty());
  // Declare a new state variable, which we'll call "mediaLinks"
  const [mediaLinks, setMediaLinks] = useState([]);
  // Declare a new state variable, which we'll call "soundCloudUrl"
  const [soundCloudUrl, setSoundCloudUrl] = useState([]);
  // Declare a new state variable, which we'll call "forceRender"
  const [localState, setLocalState] = useState({
    save_changes_loading: false,
    is_fetching_tutor_profile: false,
  });
  const [Coverpicture, setCoverpicture] = useState({
    coverpic: [], deleted: false, canUpload: false, loading: false,
  });
    // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false);
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false);
  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm();

  useEffect(() => {
    componentDidMount(() => {
      // fetching tutor profile from api
      setLocalState({ ...localState, is_fetching_tutor_profile: true });
      const url = props.location.search;
      const urlparams = queryString.parse(url);
      if (urlparams && urlparams.id) {
        props.viewProvider({
          params: { expand: 'classes,services', id: urlparams.id },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      } else {
        props.getTutorProfile({
          params: { expand: 'classes,services' },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      }
    });
    manageLoadSpin();
  });

  useEffect(() => {
    setCurrentTabModified();
  }, [canUpdateForm]);

  // Is mediaLinks being updated
  useEffect(() => {
    // calling validateCanUpdateForm func
    if (!_.isEmpty(tutorProfile)) validateCanUpdateForm(form.getFieldsValue(), { [_.get(formMeta, 'service_provider_media_links.form_item.name')]: mediaLinks });
  }, [mediaLinks]);

  // Is soundCloudUrl being updated
  useEffect(() => {
    // calling validateCanUpdateForm func
    if (!_.isEmpty(tutorProfile)) validateCanUpdateForm(form.getFieldsValue(), { [_.get(formMeta, 'service_provider_soundcloud_url.form_item.name')]: soundCloudUrl });
  }, [soundCloudUrl]);

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => { }))) {
      props.setCurrentTabModified(canUpdateForm);
    }
  };

  const setGeneralInfoFormFields = (tutor_profile_details) => {
    setMediaLinks(_.uniq(_.get(tutor_profile_details, _.get(formMeta, 'service_provider_media_links.form_item.name', null), [])));
    setSoundCloudUrl(_.uniq(_.get(tutor_profile_details, _.get(formMeta, 'service_provider_soundcloud_url.form_item.name', null), [])));

    getConvertedText(_.get(tutor_profile_details, _.get(formMeta, 'service_provider_announcements.form_item.name', null), ''), (announcements) => {
      setTutorAnnouncements(announcements);
    });

    if (!_.isEmpty(_.get(tutor_profile_details, 'coverpic', ''))) {
      setCoverpicture({
        coverpic: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: _.get(tutor_profile_details, 'coverpic'),
          },
        ],
      });
    }
  };

  const onSaveChanges = (form_data) => {
    setLocalState({ ...localState, save_changes_loading: true });

    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          const url = props.location.search;
          const urlparams = queryString.parse(url);
          if (urlparams && urlparams.id) {
            data.details.id = urlparams.id;
          }
          processTutorProfileImageUpload(form_data);
          form_data && form_data.coverpic && delete form_data.coverpic;
          updateProfile(form_data, _.get(data, 'details', {}), (success, updatedProfileData) => {
            // after completion of two api call we are doing the below process
            if (success) {
              setTutorProfile(updatedProfileData);
              setCanUpdateForm(false);
            }
            setLocalState({ ...localState, save_changes_loading: false });
          });
        } else {
          message.error(props.t('error1'));
          setLocalState({ ...localState, save_changes_loading: false });
        }
      },
    });
  };

  const updateProfile = (form_data, profileDetails, callback = () => { }) => {
    let buildingProfileDetails = _.pick(form_data, ['facebook_id', 'skype_id', 'whatsapp_number', 'zoom_id']);
    profileDetails.location = { lat: _.get(profileDetails, 'location.coordinates[1]', null), lng: _.get(profileDetails, 'location.coordinates[0]', null) };
    buildingProfileDetails = { ...profileDetails, ...processUpdateProfileData(), ...buildingProfileDetails };
    // updating tutor profile to api
    props.updateProfile({
      body: buildingProfileDetails,
      callback: (success, data) => {
        if (success) {
          const url = props.location.search;
          const urlparams = queryString.parse(url);
          if (urlparams && urlparams.id) {
            data.details.id = urlparams.id;
          }
          updateBusinessProfile(form_data, _.get(data, 'details', {}), callback);
        } else {
          message.error(props.t('error1'));
          setLocalState({ ...localState, save_changes_loading: false });
        }
      },
      notification: { enable: false },
    });
  };

  const updateBusinessProfile = (form_data, profileDetails, callback = () => { }) => {
    profileDetails = { ...profileDetails, ...processUpdateBusinessProfileData() };
    // updating tutor business profile to api
    props.updateBusinessProfile({
      body: profileDetails,
      callback: (success, data) => {
        callback(success, _.get(data, 'details', {}));
      },
      // notification: { enable: true, message: { success: " Updated Successfully." } }
    });
    notification.success({
      description: 'Updated Successfully',
      message: 'Success',
    });
  };

  const processUpdateProfileData = () => {
    const profileDetails = {};
    profileDetails[_.get(formMeta, 'service_provider_announcements.form_item.name')] = draftToHtml(convertToRaw(tutorAnnouncements.getCurrentContent()));
    profileDetails[_.get(formMeta, 'service_provider_media_links.form_item.name')] = mediaLinks;
    return profileDetails;
  };

  const processUpdateBusinessProfileData = () => {
    const profileDetails = {};
    profileDetails[_.get(formMeta, 'service_provider_soundcloud_url.form_item.name')] = soundCloudUrl;
    return profileDetails;
  };

  const isFieldRequired = (form_meta_name) => {
    const form_rules = _.isFunction(_.get(formMeta, `${form_meta_name}.form_item`, {})) ? formMeta[form_meta_name].form_item().rules : _.get(formMeta, `${form_meta_name}.form_item.rules`, []);
    return !_.isEmpty(_.find(form_rules, 'required')) ? (<span className="label-star">*</span>) : null;
  };

  const getConvertedText = (announcements, callback) => {
    const blocksFromHtml = htmlToDraft(announcements);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    announcements = EditorState.createWithContent(contentState);
    callback(announcements);
  };

  function TutorImageUploadBlock() {
    const uploadButton = (
      <div>
        {_.get(Coverpicture, 'loading', false) ? <Spin /> : <AiOutlinePlus style={{ fontSize: 20 }} />}
        <div className="ant-upload-text">{props.t('upload')}</div>
      </div>
    );

    return (
      <div className="full-width mb-4 mt-2">
        <div className="row">
          {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label className="filter-group-title mb-3">{props.t('genProfile7')}</label>
            <div className="full-width">
              <Form.Item name="coverpic">
                <Upload
                  disabled={_.get(Coverpicture, 'loading', false)}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  accept="image/*"
                  onPreview={(file) => { }}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok');
                    }, 0);
                  }}
                  fileList={_.get(Coverpicture, 'coverpic', [])}
                                    // onPreview={this.handlePreview}
                  onRemove={(file) => {
                    setCoverpicture({ coverpic: [], deleted: true, canUpload: false });
                  }}
                  onChange={(fileList) => {
                    if (!_.isEmpty(_.get(fileList, 'fileList', []))) {
                      setCoverpicture({ coverpic: _.get(fileList, 'fileList', []), deleted: false, canUpload: true });
                    }
                  }}
                >
                  {_.size(_.get(Coverpicture, 'coverpic', [])) > 0 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <div style={{
                fontSize: '12px', color: '#000', opacity: 0.6, marginTop: '5px',
              }}
              >
                We accept size of 10MB and it must be jpg, jpeg, png
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  const processTutorProfileImageUpload = (form_data) => {
    if (!_.isEmpty(_.get(Coverpicture, 'coverpic[0].originFileObj', [])) && _.get(Coverpicture, 'canUpload', false)) {
      const formData = new FormData();
      formData.append('coverpic', _.get(Coverpicture, 'coverpic[0].originFileObj', []), _.get(Coverpicture, 'coverpic[0].name'));
      setCoverpicture({ ...Coverpicture, loading: true });
      props.updateCoverImage({
        body: formData,
        callback: (success, data) => {
          props.onTutorProfile(props.loggedInUserDetails);
          if (success) {
            setTutorProfile(_.get(data, 'details', {}));
            setCoverpicture({ ...Coverpicture, canUpload: false, loading: false });
            message.success(props.t('genProfile'));
            setCoverpicture({
              coverpic: [
                {
                  url: _.get(data, 'details.coverpic'),
                },
              ],
            });
          } else {
            setCoverpicture({ ...Coverpicture, loading: false });
            message.error(props.t('genProfile8'));
          }
        },
      });
    } else if (_.get(Coverpicture, 'deleted', false)) {
      setCoverpicture({ ...Coverpicture, loading: true });
      props.removeCoverImage({
        callback: (success, data) => {
          if (success) {
            setTutorProfile(_.get(data, 'details', {}));
            setCoverpicture({ ...Coverpicture, deleted: false, loading: false });
            message.success(props.t('genProfile9'));
          } else {
            setCoverpicture({ ...Coverpicture, loading: false });
            message.error(props.t('genProfile10'));
          }
        },
      });
    }
  };

  const validateCanUpdateForm = (form_data, additional_data = {}) => {
    let current_form_data = _.omit(_.clone(form_data), 'coverpic');
    current_form_data = { ...processUpdateProfileData(), ...processUpdateBusinessProfileData(), ...additional_data };

    const current_tutor_profile_details = _.pick(_.clone(tutorProfile), [
      _.get(formMeta, 'service_provider_announcements.form_item.name', ''),
      _.get(formMeta, 'service_provider_media_links.form_item.name'),
      _.get(formMeta, 'service_provider_soundcloud_url.form_item.name'),
    ]);
    const result = !_.isEqual(current_form_data, current_tutor_profile_details) || (_.get(Coverpicture, 'deleted', false) || _.get(Coverpicture, 'canUpload', false));
    if (!_.isEqual(result, canUpdateForm)) {
      setCanUpdateForm(result);
    } else if ((_.has(props, 'isCurrentTabIsModified') && !_.get(props, 'isCurrentTabIsModified'))) {
      setCurrentTabModified();
    }
  };

  const manageLoadSpin = () => {
    const load_spin = (_.get(localState, 'is_fetching_tutor_profile', false) || _.get(localState, 'save_changes_loading', false) || _.get(Coverpicture, 'loading', false));
    if (!_.isEqual(loadSpin, load_spin)) {
      setLoadSpin(load_spin);
    }
  };

  const componentDidMount = (callback) => {
    if (!didMount) {
      callback();
      setDidMount(true);
    }
  };

  const mainComponent = (tutorDetails) => (
    <div className="row" style={{ margin: 0 }}>
      <div className="col-12 mb-3">
        <Form
          form={form}
          onFinish={onSaveChanges}
          scrollToFirstError
          onValuesChange={(changedValues, allValues) => {
            validateCanUpdateForm(allValues);
          }}
        >

          {/* defining form property media link */}
          <div className="full-width mb-4">
            <label className="filter-group-title  mt-3 mb-3" style={{ width: 'auto' }}>{_.get(formMeta, 'service_provider_media_links.label_text', {})}</label>

            <div className="full-width position-relative">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                  <Form.Item {..._.get(formMeta, 'service_provider_media_links.form_item', {})}>
                    <Input {..._.get(formMeta, 'service_provider_media_links.input', {})} />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                  <Button
                    className="youtube-add-url-link"
                    onClick={() => {
                      const media_link = form.getFieldValue(_.get(formMeta, 'service_provider_media_links.form_item.name', null));
                      const media_link_error = form.getFieldError(_.get(formMeta, 'service_provider_media_links.form_item.name', null));
                      if (!_.isEmpty(media_link) && _.isEmpty(media_link_error)) {
                        if (!_.includes(mediaLinks, media_link)) {
                          // add media link
                          form.resetFields([_.get(formMeta, 'service_provider_media_links.form_item.name', null)]);
                          setMediaLinks(_.uniq(_.concat(mediaLinks, media_link)));
                        }
                      }
                    }}
                  >
                    <AiOutlinePlus style={{ fontSize: 20 }} />
                  </Button>
                </div>
              </div>
            </div>

            <div className="url-link-set-container">
              {_.map(mediaLinks, (d, key) => (
                <div className="full-width position-relative pt-3" key={key}>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                      <div className="youtube-link-tags">{d}</div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                      <div className="youtube-add-url-action-btn" style={{ display: 'flex' }}>
                        <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="Delete">
                          <AiOutlineDelete
                            className="faq-buttons-delete"
                            color="red"
                            onClick={() => {
                              // remove media link
                              setMediaLinks(_.uniq(_.filter(mediaLinks, (media_link) => !_.isEqual(media_link, d))));
                            }}
                          />
                        </Tooltip>
                        <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="Edit">
                          <AiOutlineEdit
                            style={{ fontSize: 25, paddingTop: 5, color: 'blue' }}
                            className="faq-buttons-edit"
                            onClick={() => {
                              // edit media link
                              form.setFieldsValue({ [_.get(formMeta, 'service_provider_media_links.form_item.name', null)]: d });
                              setMediaLinks(_.uniq(_.filter(mediaLinks, (media_link) => !_.isEqual(media_link, d))));
                            }}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* defining form property soundcloud url */}
          {/* <div className="full-width mb-4">
                        <label className="filter-group-title mb-3" style={{ width: 'auto' }}>{_.get(formMeta, 'service_provider_soundcloud_url.label_text', {})}</label>

                        <div className="full-width position-relative">
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                    <Form.Item {..._.get(formMeta, 'service_provider_soundcloud_url.form_item', {})}>
                                        <Input {..._.get(formMeta, 'service_provider_soundcloud_url.input', {})} />
                                    </Form.Item>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                    <Button className="youtube-add-url-link" onClick={() => {
                                        const sound_cloud_url = form.getFieldValue(_.get(formMeta, 'service_provider_soundcloud_url.form_item.name', null));
                                        const sound_cloud_url_error = form.getFieldError(_.get(formMeta, 'service_provider_soundcloud_url.form_item.name', null));
                                        if (!_.isEmpty(sound_cloud_url) && _.isEmpty(sound_cloud_url_error)) {
                                            if (!_.includes(soundCloudUrl, sound_cloud_url)) {
                                                // add sound cloud url
                                                form.resetFields([_.get(formMeta, 'service_provider_soundcloud_url.form_item.name', null)]);
                                                const finalSoundCloudUrl = (_.uniq(_.concat(soundCloudUrl, sound_cloud_url)));
                                                setSoundCloudUrl(finalSoundCloudUrl);
                                            }
                                        }
                                    }}>
                                        <AiOutlinePlus style={{ fontSize: 20 }} />
                                    </Button>
                                </div>
                            </div>
                        </div>

                        <div className="url-link-set-container">
                            {_.map(soundCloudUrl, (d, key) => (
                                <div className="full-width position-relative pt-3" key={key}>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                            <div className="youtube-link-tags">{d}</div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2">
                                            <div className="youtube-add-url-action-btn" style={{ display: 'flex' }}>
                                                <Tooltip placement="top" arrow={false} overlayClassName={'tooltipForIcon'} title='Delete'>
                                                    <AiOutlineDelete
                                                        className="faq-buttons-delete"
                                                        color='#52c41a'
                                                        onClick={() => {
                                                            // remove sound cloud url
                                                            const finalSoundCloudUrl = _.filter(soundCloudUrl, sound_cloud_url => !_.isEqual(sound_cloud_url, d));
                                                            setSoundCloudUrl(finalSoundCloudUrl);
                                                        }}></AiOutlineDelete></Tooltip>
                                                <Tooltip placement="top" arrow={false} overlayClassName={'tooltipForIcon'} title='Edit'>
                                                    <AiOutlineEdit
                                                        style={{ fontSize: 25, paddingTop: 5, color: 'blue' }}
                                                        className="faq-buttons-delete" onClick={() => {

                                                            // edit sound cloud url
                                                            form.setFieldsValue({ [_.get(formMeta, 'service_provider_soundcloud_url.form_item.name', null)]: d })
                                                            setSoundCloudUrl(_.filter(soundCloudUrl, sound_cloud_url => !_.isEqual(sound_cloud_url, d)));
                                                        }}></AiOutlineEdit></Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> */}

          {/* defining form property announcements */}
          <div className="full-width pr-2">
            <div className="row">
              <div className="col-12 col-lg-12 mt-3">
                <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
                  {_.get(formMeta, 'service_provider_announcements.label_text', '')}
                  {isFieldRequired('service_provider_announcements')}
                </label>
                <div className="full-width drift-editor announcement-editor">
                  <Form.Item {..._.get(formMeta, 'service_provider_announcements.form_item', {})}>
                    <Editor
                      {..._.get(formMeta, 'service_provider_announcements.editor', {})}
                      editorState={tutorAnnouncements}
                      onEditorStateChange={(editorState) => {
                        setTutorAnnouncements(editorState);
                        validateCanUpdateForm(form.getFieldsValue(), {
                          [_.get(formMeta, 'service_provider_announcements.form_item.name', '')]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                        });
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          {/* defining form property Gallery */}

          {TutorImageUploadBlock()}
          {/* Defining form submit button */}
          <div className="full-width button-group-section mt-3">
            <Form.Item>
              <Button disabled={!canUpdateForm || _.get(localState, 'save_changes_loading', false)} loading={_.get(localState, 'save_changes_loading', false)} type="primary" htmlType="submit" className="btn-filter">Save Changes</Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    </div>
  );
  return (
    <Scrollbars
      className="tutor-onboarding-scroll-height hidden-scroll-x"
      renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
      renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
    >
      <Spin spinning={loadSpin}>
        {mainComponent()}
      </Spin>
    </Scrollbars>
  );
}

const mapStateToProps = (state) => ({ tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}), loggedInUserDetails: state.authentication });

const mapDispatchToProps = (dispatch) => ({
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) => dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) => dispatch(generalAction.tutor.updateProfile(details)),
  updateBusinessProfile: (details) => dispatch(generalAction.tutor.updateBusinessProfile(details)),
  getTutorProfile: (details) => dispatch(generalAction.tutor.getProfile(details)),
  updateCoverImage: (details) => dispatch(generalAction.tutor.updateCoverImage(details)),
  removeCoverImage: (details) => dispatch(generalAction.tutor.removeCoverImage(details)),
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MediaAndAnnouncements));
