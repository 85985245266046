import React, { useState, useEffect } from 'react';
import { notification, Tooltip, Button } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { MdLocationOn, MdCalendarMonth } from 'react-icons/md';
import { SiTruenas } from 'react-icons/si';
import { withTranslation } from 'react-i18next';
import axiostoServer from '../../../utils/axios';
import LabelConvertion from '../../../components/re-usable/label-convertion';
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate';
import * as userAction from '../../../store/actions/users';
import WishListLoader from '../../../components/loader/common/wishlist-loader';
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime';
import history from '../../../utils/history';
import * as searchAction from '../../../store/actions/public/search'
import { cartItems } from '../../../store/actions/public/search/actionTypes'


const WishList = (props) => {
  const [wishListItems, setWishListItems] = useState({});
  const [wishListItemsLoading, setWishListItemsLoading] = useState(false);
  const getWishListItems = async (status = -1) => {
    try {

      let params = {
        id: _.get(props, 'userBaseProfile.id', {}),
      }

      if (status > -1) {
        params = {
          id: _.get(props, 'userBaseProfile.id', {}),
          status: status,
        }
      }


      setWishListItemsLoading(true);
      const resp = await axiostoServer({
        method: 'get',
        url: '/users/get-cartitems',
        withCredentials: true,
        params: params,
        headers: { 'Content-Type': 'application/json' },
      });

      if (resp.status === 200) {
        if (status > -1) {
          setWishListItems(resp.data.details);
        } else {
          props.setCartItemsRedux(resp.data.details)
        }


        setWishListItemsLoading(false);
        return (resp.data.details);
      }
      setWishListItemsLoading(false);
      notification.error({
        description: props.t('errInWishlist5'),
        message: 'Error',
      });
    } catch (error) {
      notification.error({
        description: props.t('errInWishlist5'),
        message: 'Error',
      });
      console.error('Error fetching session purchase:', error);
    }
  };
  useEffect(() => {
    getWishListItems(0);
    props.onUserProfile();
  }, []);
  function batchDate(date) {
    const nextDate = date && date.find((date) => {
      const todayDate = new Date();
      const checkDate = new Date(date);
      if (checkDate.getDate() > todayDate.getDate() && checkDate.getMonth() >= todayDate.getMonth() && checkDate.getFullYear() >= todayDate.getFullYear()) {
        return checkDate;
      }
    });
    return nextDate;
  }

  const addToCart = async (id) => {
    try {
      const resp = await axiostoServer({
        method: 'post',
        url: '/users/update-cart',
        withCredentials: true,
        data: {
          _id: id,
          status: 1,
        },
        headers: { 'Content-Type': 'application/json' },
      });

      if (resp.status === 200) {
        getWishListItems(0);
        getWishListItems();

        props.onUserProfile();
        return (resp.data.details);
      }
      notification.error({
        description: props.t('errInWishlist6'),
        message: 'Error',
      });
    } catch (error) {
      notification.error({
        description: props.t('errInWishlist6'),
        message: 'Error',
      });
      console.error('Error fetching session purchase:', error);
    }
  };

  const deleteFrmWhishList = async (id) => {
    try {
      const resp = await axiostoServer({
        method: 'post',
        url: '/users/delete-cart',
        withCredentials: true,
        data: {
          _id: id,
        },
        headers: { 'Content-Type': 'application/json' },
      });

      if (resp.status === 200) {
        getWishListItems(0);
        getWishListItems();
        props.onUserProfile();
      } else {
        notification.error({
          description: props.t('errInWishlist7'),
          message: 'Error',
        });
      }
    } catch (error) {
      notification.error({
        description: props.t('errInWishlist7'),
        message: 'Error',
      });
      console.error('Error deleting session purchase:', error);
    }
  };

  const moveToCart = (id) => {
    addToCart(id);
    if (Object.keys(wishListItems).length === 1) {
      setTimeout(() => {
        history.push('/mycart');
      }, 1000);
    }
  };

  const render = () => (
    <div className="nurtem-card-design" style={{ minHeight: '400px' }}>
      {wishListItemsLoading ? <WishListLoader cards={12} />
        : Object.keys(wishListItems).length === 0 ? (
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
          }}
          >
            <EmptyAnime AnimeType="EmptyUserSide" />
            <p className="empty-animations">{props.t('wishlistlabel')}</p>
          </div>
        )

          : (
            <div style={{
              display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', justifyContent: 'center', gap: '20px',
            }}
            >

              {Object.values(wishListItems).map((value, index) => (
                <div key={`class_list${index}`} className="nurtem-profile-class-card" style={{ maxWidth: 270 }}>
                  <div className="class-details-card">
                    <div div className="row session-box" style={{ width: '100%', margin: 0 }}>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ padding: 0 }}>
                        <div className="nurtem-profile-class-card-header">
                          <div style={{ display: 'flex', justifyContent: 'center' }} className="nurtem-profile-class-card-class-detail">
                            <div className="profile-card-name-container" style={{ padding: 5, width: '100%', borderRadius: 10 }}>
                              <Tooltip title={(value.session.session_name)} placement="top">
                                <div style={{ width: '100%' }} className="profile-class-name text-truncate">
                                  {(value.session.session_name)}
                                </div>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ height: 30 }}>
                        <div className="addon-button price" style={{}}>
                          <h6>{new Date(batchDate(value.session.session_dates) || value.session.start_date).toLocaleDateString('en-US', { weekday: 'long' })}</h6>
                        </div>

                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="nurtem-profile-camp-packname-header" style={{ justifyContent: 'center', padding: 5 }}>
                          <div className="camp-detail-card-menu p-1" style={{ fontSize: '1.4rem' }}>
                            {/* <AiOutlineCalendar className="mr-1" style={{ color: '#666', fontSize: '20px' }} /> */}
                            {timeDateConverterSession(batchDate(value.session.session_dates) || value.session.start_date, 'date', 'MM DD,YYYY')}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'center' }}>
                        <div className="nurtem-profile-camp-packname-header" style={{ padding: 5 }}>
                          <div className="camp-detail-card-menu p-1">
                            {(timeDateConverterSession(value.session.start_time, 'time', 'hh:mm A'))}
                            {' '}
                            {' '}
                            &nbsp;to&nbsp;
                            {' '}
                            {' '}
                            {' '}
                            {(timeDateConverterSession(value.session.end_time, 'time', 'hh:mm A'))}
                            {' '}
                          </div>
                        </div>
                      </div>

                      {value.teaching_type && (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className="nurtem-profile-camp-packname-header" style={{ padding: '10px 0px' }}>
                            <div className="camp-detail-card-menu">
                              {LabelConvertion(value.session.teaching_type, 'teaching_type')}
                              {' '}
                              {' '}
                              {(value.session.teaching_type === 'g' ? `(${value.session.max_no_of_participants_in_team} members )` : '')}
                            </div>
                          </div>
                          <h6 style={{ fontWeight: 600, margin: 0, color: '#ba3946' }}>
                            $
                            {(value.price)}
                          </h6>
                        </div>
                      )}

                      {value.session.teaching_mode && (
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <div className="nurtem-profile-camp-packname-header" style={{ paddingRight: 0 }}>
                            <div className="camp-detail-card-menu p-1">
                              <MdCalendarMonth />
                              {LabelConvertion(value.session.variant, 'variant')}
                            </div>
                          </div>
                          <div className="nurtem-profile-camp-packname-header">
                            <div className="camp-detail-card-menu p-1" style={{ marginRight: 15 }}>
                              <SiTruenas />
                              {(value.session.no_of_max_students)}
                              {' '}
                              seats
                            </div>
                          </div>
                          <h6 style={{ fontWeight: 600, margin: 0, color: '#ba3946' }}>
                            $
                            {(value.session.price)}
                          </h6>
                        </div>
                      )}

                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12  mb-2">
                        <div className="addon-button " style={{ paddingBottom: 10 }}>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 wishtocart" style={{ display: 'flex', justifyContent: 'space-around' }}>
                              <Button
                                className="detailButton"
                                style={{ padding: '0px 40px', backgroundColor: '#205298' }}
                                onClick={() => moveToCart(value.id)}
                                type="primary"
                              >
                                {props.t('wishlistlabel3')}
                              </Button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'space-around' }}>
                              <Button
                                style={{ padding: '0px 40px', marginTop: '10px' }}
                                onClick={() => deleteFrmWhishList(value.id)}
                                type="primary"
                                danger
                              >
                                {props.t('wishlistlabel2')}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="nurtem-profile-camp-packname-header" style={{ padding: '5px 0px', borderTop: '1px solid #000' }}>
                          <div className="camp-detail-card-menu p-1">
                            <MdLocationOn className="mr-1" style={{ color: '#666', fontSize: '20px' }} />
                            {value.session.location_taught ? value.session.location_taught : LabelConvertion(value.session.teaching_mode, 'teaching-mode')}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
    </div>
  );
  return render();
};

const mapStateToProps = (state) => ({
  uploadedList: state.uploads,
  loggedInUserDetails: state.authentication,
  userBaseProfile: state.userProfile.userBaseProfile,

});

const mapDispatchToProps = (dispatch) => ({
  setCartItemsRedux: (requestParams) =>
    dispatch(searchAction.common(cartItems, requestParams)),
  onUserProfile: () => dispatch(userAction.userProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WishList));
