import React from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import _ from 'lodash'

const baseProfileFormMeta = {
  service_provider_type: {
    form_item: {
      name: 'create_profile_as',
      rules: [
        {
          required: true,
          message: 'Please select which type of service provider you are.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            Please select which type of service provider you are.
          </span>
        </span>
      ),
    },
  },
  service_provider_type_plans: {
    form_item: {
      name: 'plans',
      rules: [
        {
          required: true,
          message: 'Please select which type of plan you want.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            Please select which type of plan you want.
          </span>
        </span>
      ),
    },
  },
  service_provider_teaching_modes: {
    form_item: {
      name: 'teaching_modes',
      rules: [
        {
          required: true,
          message: 'Please check the mode of teaching.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            You can multi select the teaching mode.
          </span>
        </span>
      ),
    },
    data: [
      {
        label: 'In-Person (business location/your place)',
        value: 'studio',
      },
      {
        label: 'Online',
        value: 'online',
      },
      {
        label: 'Drive to student home',
        value: 'drive',
      },
    ],
  },
  service_provider_businesshours: {
    form_item: {
      name: 'businesshours',
      rules: [
        {
          required: false,
          message: 'Please input your Business Hours.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            Enter your business hours. Example "9:00 AM - 5:00 PM"
          </span>
        </span>
      ),
    },
    day_select: {
      placeholder: 'Select Day',
      options: [
        { value: 'Monday', label: 'Mon' },
        { value: 'Tuesday', label: 'Tue' },
        { value: 'Wednesday', label: 'Wed' },
        { value: 'Thursday', label: 'Thur' },
        { value: 'Friday', label: 'Fri' },
        { value: 'Saturday', label: 'Sat' },
        { value: 'Sunday', label: 'Sun' },
      ],
    },
    start_time_picker: {
      format: 'HH:mm',
      placeholder: 'Start Time',
    },
    end_time_picker: {
      format: 'HH:mm',
      placeholder: 'End Time',
    },
  },

  service_provider_teaching_experience: {
    form_item: {
      name: 'years_of_experience',
      rules: [
        {
          required: false,
          message: 'Please enter your work experience.',
        },
        () => ({
          validator(rule, value) {
            if (value < 0) {
              return Promise.reject(
                'Experience should not be in negative value'
              )
            }
            if (value >= 100) {
              return Promise.reject('Experience should be lesser than 100')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            Your overall teaching experience.
          </span>
        </span>
      ),
    },
    input_number: {},
  },
  tutor_bio: {
    form_item: {
      name: 'bio',
      rules: [
        {
          required: true,
          message: 'Please give a tag line about you.',
        },
      ],
    },
    text_area: { maxLength: 130, rows: 2 },
  },
  service_provider_no_of_students_taught: {
    form_item: {
      name: 'no_of_students_taught',
      rules: [
        {
          required: false,
          message: 'Number of Students Taught so for',
        },
        () => ({
          validator: (rule, value) => {
            if (value < 0) {
              return Promise.reject(
                'Number of Students should not be in negative value'
              )
            }
            if (value >= 10000) {
              return Promise.reject(
                'Number of Students should be lesser than 10000'
              )
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span>
            {' '}
            <span className="tooltip-icon">
              <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
            </span>{' '}
            <span className="tooltip-text">No. of students you taught</span>
          </span>
        </span>
      ),
    },
    input_number: { min: 0 },
  },
  nurtem_service_ids: {
    form_item: {
      name: 'service_ids',
      rules: [
        {
          required: true,
          message: 'Please choose classes offered.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            You can search for class and camp categories which you are offring.
          </span>
        </span>
      ),
    },
    select: {
      labelInValue: true,
      mode: 'multiple',
      style: { width: '100%' },
      className: 'global-search',
      placeholder: 'Start typing',
      filterOption: false,
      getPopupContainer: (trigger) => trigger.parentNode,
    },
  },
  service_provider_location_taught: {
    form_item: (properties = {}) => ({
      name: 'location_taught',
      rules: [
        {
          required: _.get(properties, 'field_required', false),
          message: 'Please select the location.',
        },
      ],
    }),
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            List of locations that you conduct the class.
          </span>
        </span>
      ),
    },
    select: {
      className: 'w-100',
      mode: 'multiple',
      placeholder: 'Search a Locations',
      filterOption: false,
      style: { width: '100%' },
      getPopupContainer: (trigger) => trigger.parentNode,
    },
  },
  service_provider_handle: {
    form_item: {
      name: 'user_handle',
      hasFeedback: true,
    },
    tooltip: {
      placement: 'right',
      title: (
        <span>
          {' '}
          <span className="tooltip-icon">
            <AiOutlineExclamationCircle style={{ fontSize: 18 }} />
          </span>{' '}
          <span className="tooltip-text">
            {' '}
            will be a link that you can share with anyone to view your profile.
          </span>
        </span>
      ),
    },
    input: {
      maxLength: 25,
      addonBefore: 'https://class.nurtem.com/profile/',
    },
  },
  service_provider_firstname: {
    form_item: {
      name: 'firstname',
      rules: [
        {
          required: true,
          message: 'Please enter your first name.',
        },
        () => ({
          validator(rule, value) {
            if (!_.isEmpty(value) && !value.match('^[a-zA-Z0-9 ]*$')) {
              return Promise.reject('Please enter valid name.')
            }
            if (value && value.length > 20) {
              return Promise.reject("Name can't be more than 20 Char")
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 21, placeholder: 'Enter your First Name' },
  },
  service_provider_lastname: {
    form_item: {
      name: 'lastname',
      rules: [
        {
          required: false,
          message: 'Please enter your last name.',
        },
        () => ({
          validator(rule, value) {
            if (!_.isEmpty(value) && !value.match('^[a-zA-Z0-9 ]*$')) {
              return Promise.reject('Please enter valid name.')
            }
            if (!_.isEmpty(value) && value.length > 20) {
              return Promise.reject("Business Name can't be more than 20 Char")
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 21, placeholder: 'Enter your Last Name' },
  },
  service_provider_businessname: {
    form_item: {
      name: 'businessname',
      rules: [
        {
          required: true,
          message: 'Please enter your business name.',
        },
        () => ({
          validator(rule, value) {
            if (!_.isEmpty(value) && !value.match('^[a-zA-Z0-9 ]*$')) {
              return Promise.reject('Please enter valid name.')
            }
            if (value && value.length > 80) {
              return Promise.reject("Name can't be more than 80 Char")
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 81, placeholder: 'Enter your Business Name' },
  },
  service_provider_zipcode: {
    form_item: {
      name: 'zip_code',
      rules: [
        {
          required: false,
          message: 'Please enter your zipcode.',
        },
        () => ({
          validator(rule, value) {
            if (!_.isEmpty(value) && !value.match('^[0-9]*$')) {
              return Promise.reject('Please enter valid Zipcode.')
            }
            if (!_.isEmpty(value) && _.size(value) < 3) {
              return Promise.reject("Zip code can't less than 3 numbers.")
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 6, placeholder: 'Enter your Zipcode' },
  },
  service_provider_address: {
    form_item: {
      name: 'location',
      style: { marginRight: '-1px' },
      rules: [
        {
          required: true,
          message: 'Please enter your address.',
        },
      ],
    },
    select: {
      labelInValue: true,
      showSearch: true,
      filterOption: false,
      style: { width: '100%' },
      getPopupContainer: (trigger) => trigger.parentNode,
    },
  },
  service_provider_mobile_number: {
    form_item: {
      name: 'mobile_number',
      rules: [
        {
          required: true,
          message: 'Please enter your phone number.',
        },
        () => ({
          validator(rule, value) {
            if (
              !_.isEmpty(value) &&
              (_.size(value) < 8 || _.size(value) > 13)
            ) {
              return Promise.reject(
                'Phone number must be in the range of 8 - 13 digits.'
              )
            }
            if (!_.isEmpty(value) && !value.match('^[0-9]*$')) {
              return Promise.reject('Please enter numbers.')
            }
            return Promise.resolve()
          },
        }),
      ],
    },
    input: { maxLength: 14, placeholder: 'Enter your Phone Number' },
  },
  service_provider_gender: {
    form_item: {
      name: 'gender',
      rules: [
        {
          required: false,
          message: 'Please select the gender.',
        },
      ],
    },
  },
  service_provider_bio: {
    form_item: {
      name: 'bio',
      rules: [
        {
          required: true,
          message: 'Please Enter your Tag Lines.',
        },
      ],
    },
    text_area: { maxLength: 380, rows: 3 },
    // editor: {
    //     wrapperClassName: "demo-wrapper",
    //     editorClassName: "demo-editor"
    // },
    // label_text: "Tutor Bio"
  },
}
export default baseProfileFormMeta
