import React from 'react';
import DiscountCouponsList from './list';
import CouponBlock from './coupon-block';
import { CanSwitchTabModal } from '../edit-profile/my-profile-tab/can-switch-tab-modal';

import './style.css';

class ManageCoupons extends React.Component {
  setLogicBlockInstance = (instanceBlock) => {
    this.props.couponBlock.setCouponBlockListInstance(instanceBlock);
  };

  renderOutput = () => (<DiscountCouponsList {...this.props} setLogicBlockInstance={this.setLogicBlockInstance} />);

  render() {
    return this.renderOutput();
  }
}

export default CanSwitchTabModal(CouponBlock(ManageCoupons));
