import * as actionTypes from '../../actions/public/actionTypes';
// import { updateObject } from "../../utility";

const initialState = {
  loading: {
    rateListLoading: false,
    tutorRateListLoading: false,
  },
  onRatingList: {},
  onRatingListFull: {},
  onTutorRatingList: {},
  onCreateRating: {},
  onCreateReviews: '',
};
const ratingListService = (state, action) => ({ ...state, onRatingList: action.success });
const ratingListServiceFull = (state, action) => ({ ...state, onRatingListFull: action.success });
const tutorRatingListService = (state, action) => ({ ...state, onTutorRatingList: action.success });
const onCreateRating = (state, action) => ({ ...state, onCreateRating: action.success });
const onCreateReviews = (state, action) => ({ ...state, onCreateReviews: action.success });
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.onRatingListData:
      return ratingListService(state, action);
    case actionTypes.onRatingListFull:
      return ratingListServiceFull(state, action);
    case actionTypes.onRatingListDataForTutor:
      return tutorRatingListService(state, action);
    case actionTypes.onCreateRating:
      return onCreateRating(state, action);
    case actionTypes.onCreateReview:
      return onCreateReviews(state, action);
    default:
      return state;
  }
};

export default reducer;
