import axios from './axios';

const axiosServer = (url, data, callback, errorCallback = () => {}, method = 'post') => {
  try {
    axios({
      method,
      url,
      withCredentials: true,
      data,
      config: { headers: { 'Content-Type': 'application/json' } },
    }).then((resp) => {
      if (resp.status === 200 || resp.status === 304) {
        callback(resp);
      }
    }).catch((error) => {
      errorCallback(error);
    });
  } catch (error) {
    console.log('Axios error > ', error);
  }
};

export default axiosServer;
