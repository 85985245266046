// AiDescriptionGenerator.js
import React, { Component } from 'react'
import { Button, Tooltip, notification, Form } from 'antd'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as commonActions from '../../store/actions/common'

/**
 * AiDescriptionGenerator Component
 *
 * A component to facilitate the generation of descriptions using artificial intelligence (AI).
 *
 * Props:
 *  - name: (string) The name of the description field.
 *  - servicetext: (string) The input service text to be processed.
 *  - type: (string) The type of the text. Possible values include "class", "camp", etc.
 *  - descriptionText: (string) The current description text being displayed.
 *  - getEditorValue: (function) A function to update the description text.
 */

class AiDescriptionGenerator extends Component {
  state = {
    aiLoading: false,
    descriptionEditorStateValue: EditorState.createEmpty(),
    servicetext: '',
  }

  componentDidMount() {
    // if (!_.isEmpty(this.props.descriptionText)) {
    //   const description_html = this.contentToHtmlEditor(this.props.descriptionText)
    //   this.setState({ descriptionEditorStateValue: description_html })
    // }
  }

  componentDidUpdate(prevprops) {
    // console.log('this.props.descriptionText>>>', this.props.descriptionText)
    // console.log('this.props.descriptionText>>>2pre', prevprops.servicetext)
    // console.log('this.props.descriptionText>>>2c', this.props.servicetext)
    // console.log(
    //   'this.props.descriptionText>>>con',
    //   this.props.servicetext !== prevprops.servicetext
    // )

    if (
      !_.isEmpty(this.props.servicetext) &&
      this.props.servicetext !== this.state.servicetext &&
      !_.isEmpty(this.props.descriptionText)
    ) {
      const description_html = this.contentToHtmlEditor(
        this.props.descriptionText
      )
      this.setState({
        servicetext: this.props.servicetext,
        descriptionEditorStateValue: description_html,
      })
    }
  }

  onEditorStateChange = (editorState) => {
    const editorStateToHtml = this.convertEditorToHtml(editorState)
    this.props.getEditorValue(editorStateToHtml)
    this.setState({ descriptionEditorStateValue: editorState })
  }

  convertEditorToHtml = (content) => {
    return draftToHtml(convertToRaw(content.getCurrentContent()))
  }
  contentToHtmlEditor = (content) => {
    const blocksFromHtml = htmlToDraft(content)
    // console.log("content>>>1", blocksFromHtml)
    const { contentBlocks, entityMap } = blocksFromHtml
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    )
    return EditorState.createWithContent(contentState)
  }

  handleAiGenerate = () => {
    this.setState({ aiLoading: true })
    this.props.loading(true)

    if (this.props.name || this.props.servicetext) {
      const data = {
        name: this.props.name,
        service: this.props.servicetext,
        type: this.props.type,
      }
      this.props.onAiGenerateText(data, (resp) => {
        if (resp.status === 200) {
          // console.log("content===========1", resp)
          this.setState({ aiLoading: false })
          this.props.loading(false)
          const content = _.get(
            resp.data,
            ['message', 'content'],
            'this is test from gbstest'
          )

          const description_html = this.contentToHtmlEditor(content)

          this.props.getEditorValue(content)
          this.setState({ descriptionEditorStateValue: description_html })
        } else {
          // console.log("content===========", resp)
          this.setState({ aiLoading: false })
          this.props.loading(false)
          notification.warning({ message: this.props.t('error1') })
        }
      })
    } else {
      this.setState({ aiLoading: false })
      this.props.loading(false)
      notification.warning({ message: this.props.t('genAi1') })
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 m-0 p-0">
          <div className="row justify-content-between">
            <label
              className="col-6 filter-group-title mt-3"
              style={{ width: 'auto', display: 'flex', marginBottom: '0px' }}
            >
             {this.props.isRequired==true && (<span
                style={{ fontSize: 20, marginRight: 5 }}
                className="label-star"
              >
                *
              </span>)}{' '}
              {this.props.t('descp')}
            </label>

            <label className="col-6 filter-group-title mb-3 text-right">
              <Tooltip
                placement="top"
                title="Your AI assistant to generate description about the class"
              >
                <Button
                  type="primary"
                  loading={this.state.aiLoading}
                  onClick={this.handleAiGenerate}
                >
                  {this.props.t('genAi')}
                </Button>
              </Tooltip>
            </label>
          </div>
          <div className="full-width drift-editor">
            <Form.Item
            name={this.props.itemname ? this.props.itemname : 'description'}
            rules={[
              {
                required: this.props.isRequired === true, // Conditionally set required
                message: 'Description is required',
              },
              {
                validator: async (rule, value) => {
                  if (this.props.isRequired) { // Check if the description should be required
                    const descPvalue = draftToHtml(
                      convertToRaw(
                        this.state.descriptionEditorStateValue.getCurrentContent()
                      )
                    )
                    // Remove HTML tags and whitespace to check if it's effectively empty
                    const strippedContent = descPvalue
                      .replace(/<\/?[^>]+(>|$)/g, '')
                      .trim()
                    if (!strippedContent) {
                      throw new Error('Description is required')
                    }
                  }
                },
              },
            ]}
          >
            <Editor
              editorState={this.state.descriptionEditorStateValue}
              onEditorStateChange={this.onEditorStateChange}
            />
          </Form.Item>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) =>
  // Define any state properties that your component needs from the Redux store
  ({
    // For example:
    // name: state.name,
    // servicetext: state.servicetext,
  })

const mapDispatchToProps = (dispatch) => ({
  onAiGenerateText: (requestParams, callback) =>
    dispatch(commonActions.onAiGenerateText(requestParams, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AiDescriptionGenerator))
