import dayjs from 'dayjs';

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');

dayjs.extend(isSameOrAfter);
const SessionsLeft = (props) => {
  const currentDate = dayjs().startOf('day');
  const filteredDates = props?.filter((date) => dayjs(date).isSameOrAfter(currentDate)) ?? [];
  return filteredDates.length;
};
export default SessionsLeft;
