export const signInSuccess = 'signInSuccess';
export const signUpSuccess = 'signUpSuccess';
export const logoutSuccess = 'logoutSuccess';
export const loginSignupTab = 'loginSignupTab';
export const loginSignupAdminApproval = 'loginSignupAdminApproval';
export const onSignupCodeVerificationState = 'onSignupCodeVerificationState';
export const SignupCodeVerificationResend = 'SignupCodeVerificationResend';
export const resetPasswordResponse = 'resetPasswordResponse';
export const passwordCheckResponse = 'passwordCheckResponse';
export const ClaimBusinessResponse = 'ClaimBusinessResponse';
export const changePasswordResponse = 'changePasswordResponse';
export const enableLoading = 'enableLoading';
export const enableProviderLoginLoading = 'enableProviderLoginLoading';
export const enableStudentLoginLoading = 'enableStudentLoginLoading';
export const disableLoading = 'disableLoading';
export const onGoBack = 'onGoBack';
export const isUserVerified = 'isUserVerified';
export const signInHandleVerification = 'signInHandleVerification';
