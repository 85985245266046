import React from 'react';

export default class SessionDetailsLoader extends React.Component {
  render() {
    return (
      <div className="ph-item">
        <div className="ph-col-12">
          <div className="ph-row">
            {/* <div className="ph-col-12 empty"></div> */}
            <div className="ph-col-12 empty" />
            <div className="ph-col-6 big" />
            <div className="ph-col-12 empty" />
            {/* <div className="ph-col-4 big"></div> */}
            <div className="ph-col-4 big" />
            <div className="ph-col-12 empty" />
          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-4" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '20px' }} />
            </div>

            <div
              className="ph-picture"
              style={{
                height: '40px', marginLeft: '9px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-4" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '20px' }} />
            </div>

          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-4" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '20px' }} />
            </div>

            <div
              className="ph-picture"
              style={{
                height: '40px', marginLeft: '9px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-4" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '20px' }} />
            </div>

          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-4" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '50px' }} />
            </div>
          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-6" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '30px' }} />
            </div>
          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-6" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '30px' }} />
            </div>
          </div>
          <div className="ph-row">
            <div
              className="ph-picture"
              style={{
                height: '40px', marginBottom: '10px', width: '40px', borderRadius: '100px',
              }}
            />
            <div className="ph-col-6" style={{ marginLeft: '5px', height: '15px' }}>
              <div className="ph-col-2 empty" />
              <div className="ph-col-1 big" style={{ width: '30px' }} />
            </div>
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
          </div>
        </div>
      </div>
    );
  }
}
