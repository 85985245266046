import _ from 'lodash';
import { notification } from 'antd';
import generalLogic from './general-logic';
import history from '../../../../../utils/history';
import * as routeName from '../../../../../components/route-names';

// Here we can write all the functions which is needed to communicate with middleware.
export default (componentAssests) => {
  const t = _.get(componentAssests, 't', (params) => { console.log('translation object is missiing in general-logic'); });

  const refreshCouponList = _.get(componentAssests, 'self.couponBlockListInstance.refreshCouponList', () => { });
  const createCoupon = (details) => {
    const createDiscount = _.get(componentAssests, 'externalProps.createDiscount', () => { });
    const setFormLoader = _.get(componentAssests, 'self.setFormLoader', () => { });
    const generalLogicInstance = generalLogic(componentAssests);
    setFormLoader(true);
    createDiscount({
      body: details,
      callback: (success, data) => {
        if (success) {
          refreshCouponList();
          generalLogicInstance.ExitCouponBlock({ showConfirmModal: false });
          history.push(routeName.myCoupons);
        }
        setFormLoader(false);
      },
      // notification: { enable: true, message: { success: "Coupon created successfully" } }
    });
    notification.success({
      description: t('couponMsg1'),
      message: 'Success',
    });
  };

  const updateCoupon = (details) => {
    const updateDiscount = _.get(componentAssests, 'externalProps.updateDiscount', () => { });
    const setFormLoader = _.get(componentAssests, 'self.setFormLoader', () => { });
    const couponId = _.get(componentAssests, 'self.componentDetails.updateCouponDetails.id', null);
    const generalLogicInstance = generalLogic(componentAssests);
    console.log('details', details);
    setFormLoader(true);
    updateDiscount({
      params: { id: couponId },
      body: details,
      callback: (success, data) => {
        if (success) {
          refreshCouponList();
          generalLogicInstance.ExitCouponBlock({ showConfirmModal: false });
          history.push(routeName.myCoupons);
        }
        setFormLoader(false);
      },
      // notification: { enable: true, message: { success: "Coupon updated successfully" } }
    });
    notification.success({
      description: t('couponMsg2'),
      message: 'Success',
    });
  };

  return {
    createCoupon,
    updateCoupon,
  };
};
