import _ from 'lodash';
import actionTypes from './actionTypes';

const initialState = {
  // tutor state
  [actionTypes.tutor.name]: {
    [actionTypes.tutor.profile]: {},
    [actionTypes.tutor.handle]: {},
    [actionTypes.tutor.profileUpdate]: {},
    [actionTypes.tutor.businessProfileUpdate]: {},
    [actionTypes.tutor.profileImageUpdate]: {},
    [actionTypes.tutor.profileImageRemove]: {},
  },

  // services state
  [actionTypes.services.name]: {
    [actionTypes.services.list]: {},
  },

  // camps state
  [actionTypes.camps.name]: {
    [actionTypes.camps.view]: {},
  },

  // classes state
  [actionTypes.classes.name]: {
    [actionTypes.classes.view]: {},
    [actionTypes.classes.list]: {},

  },

  // competitions state
  [actionTypes.competitions.name]: {
    [actionTypes.competitions.view]: {},
  },

  // discounts state
  [actionTypes.discounts.name]: {
    [actionTypes.discounts.list]: {},
    [actionTypes.discounts.delete]: {},
    [actionTypes.discounts.create]: {},
    [actionTypes.discounts.update]: {},
  },
};

const generalUpdateTutorState = (state, action) => ({
  ...state,
  [actionTypes.tutor.name]:
  {
    ...state[actionTypes.tutor.name],
    ..._.get(action, 'data', {}),
  },
});

const generalUpdateServicesState = (state, action) => ({
  ...state,
  [actionTypes.services.name]:
  {
    ...state[actionTypes.services.name],
    ..._.get(action, 'data', {}),
  },
});

const generalUpdateCampsState = (state, action) => ({
  ...state,
  [actionTypes.camps.name]:
  {
    ...state[actionTypes.camps.name],
    ..._.get(action, 'data', {}),
  },
});

const generalUpdateClassesState = (state, action) => ({
  ...state,
  [actionTypes.classes.name]:
  {
    ...state[actionTypes.classes.name],
    ..._.get(action, 'data', {}),
  },
});

const generalUpdateEventState = (state, action) => ({
  ...state,
  [actionTypes.events.name]:
  {
    ...state[actionTypes.events.name],
    ..._.get(action, 'data', {}),
  },
});
const generalUpdateCompetitionState = (state, action) => ({
  ...state,
  [actionTypes.competitions.name]:
  {
    ...state[actionTypes.competitions.name],
    ..._.get(action, 'data', {}),
  },
});

const generalUpdateDiscountsState = (state, action) => ({
  ...state,
  [actionTypes.discounts.name]:
  {
    ...state[actionTypes.discounts.name],
    ..._.get(action, 'data', {}),
  },
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.generalUpdateTutorState:
      return generalUpdateTutorState(state, action);
    case actionTypes.generalUpdateServicesState:
      return generalUpdateServicesState(state, action);
    case actionTypes.generalUpdateCampsState:
      return generalUpdateCampsState(state, action);

    case actionTypes.generalUpdateClassesState:
      return generalUpdateClassesState(state, action);

    case actionTypes.generalUpdateCompetitionState:
      return generalUpdateCompetitionState(state, action);
    case actionTypes.generalUpdateEventState:
      return generalUpdateEventState(state, action);

    case actionTypes.generalUpdateDiscountsState:
      return generalUpdateDiscountsState(state, action);
    default:
      return state;
  }
};

export default reducer;
