import React, { useState, useEffect } from 'react'
import { Button, Tabs, Tooltip, Modal } from 'antd'
import {
  AiOutlineForm,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineShareAlt,
} from 'react-icons/ai'
import { connect } from 'react-redux'
import { RWebShare } from 'react-web-share'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import parser from 'html-react-parser'
import * as sessionActions from '../../../store/actions/public/sessions'
import '../tutor-camps/user-new-my-class.css'
import SessionList from './session-list'
import { CLASSPNG } from '../../../utils/config/assets'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import * as routeName from '../../../components/route-names'
import history from '../../../utils/history'
import FlyerPreview from '../../../components/common/gallery'
import ImageWithLoading from '../../../components/tutor/imageOf'
import { FullDetailsLoader } from '../../../components/loader/common/full-details-loader'
import viewUrl from '../../../utils/common/get-view-url'
import { toggleLeftMenu } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'

const CompDetailedView = (props) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const urlparams = queryString.parse(window.location.search)
  const gid = _.get(urlparams, 'gid')
  const comp_name = props.location.pathname.split('/')[3]
  const provId = _.get(urlparams, 'provId', '')

  function toTutorSessionDetails(record) {
    if (provId) {
      history.push(
        `${routeName.SessionViewCompetition}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}&provId=${provId}`
      )
    } else {
      history.push(
        `${routeName.SessionViewCompetition}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}`
      )
    }
  }
  const getSessionList = (id) => {
    if (id) {
      const requestParams = {}
      requestParams.group_id = id
      requestParams.sort = 'created_at.desc'
      props.getSessionList(requestParams, 'competitions')
    }
  }

  const { onDeleteClass, onEditClass, tutorCompetitions } = props

  useEffect(() => {
    window.scrollTo(0, 0)
    props.onCompetitionDetails({
      id: gid,
      expand: 'provider',
    })
    getSessionList(gid)
    props.setToggleMenu(true)
  }, [])

  const render = () => {
    const cd = _.chain(tutorCompetitions.details)
    const provider = cd.get('provider', '').value()

    const viewCampUrl = viewUrl(provider, cd.value(), 'competition')

    const flyers = cd.get('flyers').value()

    const image_back = cd.get('photo').value()

    const UseMatchMedia = (mediaQuery, initialValue) => {
      const [isMatching, setIsMatching] = useState(initialValue)
      useEffect(() => {
        const watcher = window.matchMedia(mediaQuery)
        setIsMatching(watcher.matches)
        const listener = (matches) => {
          setIsMatching(matches.matches)
        }
        if (watcher.addEventListener) {
          watcher.addEventListener('change', listener)
        } else {
          watcher.addEventListener(listener)
        }
        return () => {
          if (watcher.removeEventListener) {
            return watcher.removeEventListener('change', listener)
          }
          return watcher.removeEventListener(listener)
        }
      }, [mediaQuery])
      return isMatching
    }

    const isWebResolution = UseMatchMedia('(min-width:992px)', true)
    const handleOnClick = () => {
      if (navigator.share) {
        navigator
          .share({
            title: '`${postTitle} | ${siteTitle}`,',
            text: 'Check out',
            url: document.location.href,
          })
          .then(() => {
            console.log('Successfully shared')
          })
          .catch((error) => {
            console.error('Something went wrong sharing the blog', error)
          })
      }
    }
    const showModal = () => {
      setOpen(true)
    }
    const handleOk = () => {
      setConfirmLoading(true)
      onDeleteClass(cd.get('id', '').value())
        .then(() => {
          setOpen(false)
          setConfirmLoading(false)
        })
        .catch((error) => {
          console.error('Error deleting class:', error)
          setConfirmLoading(false)
        })
    }

    const handleCancel = () => {
      console.log('Clicked cancel button')
      setOpen(false)
    }

    return (
      <div className="nurtem-my-class-card m-2">
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
        >
          <items tab="Batch details" key="1">
            <SessionList
              groupID={gid}
              compName={comp_name}
              type="competitions"
              callback={toTutorSessionDetails}
            />
          </items>

          <items tab="Competition Details" key="2">
            {props.loading ? (
              <FullDetailsLoader />
            ) : (
              <>
                <div className="full-width">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pr-md-0">
                      <div className="class-name" style={{ paddingLeft: 25 }}>
                        {cd.get('name', '').value()}
                      </div>
                      <ImageWithLoading
                        imageUrl={image_back}
                        defaultImageUrl={CLASSPNG}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 pl-md-0 pb-3">
                      <div className="class-header">
                        <div className="row align-items-center pl-3">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 " />
                          <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
                            <div className="class-action">
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="View"
                              >
                                <Button
                                  href={viewCampUrl}
                                  target="_blank"
                                  className="delete"
                                  icon={<AiOutlineEye />}
                                />
                              </Tooltip>
                              {isWebResolution ? (
                                <RWebShare
                                  data={{
                                    text: 'Competition details page',
                                    url: window.location.origin + viewCampUrl,
                                    title: 'Share competition details page',
                                  }}
                                  onClick={() =>
                                    console.log('shared successfully!')
                                  }
                                >
                                  <Tooltip
                                    placement="bottom"
                                    arrow={false}
                                    overlayClassName="tooltipForIcon"
                                    title="Share"
                                  >
                                    <Button
                                      className="delete"
                                      icon={<AiOutlineShareAlt />}
                                    />
                                  </Tooltip>
                                </RWebShare>
                              ) : (
                                <Button
                                  className="delete"
                                  onClick={handleOnClick}
                                  icon={<AiOutlineShareAlt />}
                                />
                              )}
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Delete"
                              >
                                <Button
                                  // onClick={() => onDeleteClass(cd.get('id'))}
                                  onClick={showModal}
                                  className="delete"
                                  icon={<AiOutlineDelete />}
                                />
                              </Tooltip>
                              <Modal
                                title="Are you sure to delete this Competition?"
                                open={open}
                                onOk={handleOk}
                                okType="danger"
                                okText="Yes"
                                confirmLoading={confirmLoading}
                                onCancel={handleCancel}
                                cancelText="No"
                              />
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Edit"
                              >
                                <Button
                                  onClick={() =>
                                    onEditClass(cd.get('id', '').value())
                                  }
                                  className="edit"
                                  icon={<AiOutlineForm />}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nurtem-my-class-card mb-4">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ paddingLeft: 35, marginTop: 20 }}
                        >
                          <div>
                            {typeof cd.get('description', '').value() ===
                              'string'
                              ? parser(cd.get('description', '').value())
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </items>

          <items tab="Gallery" key="3">
            <FlyerPreview flyers={flyers} gid={gid} cat="competitions" />
          </items>
        </Tabs>
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  loading: state.competitionsDetails.loading,
  sessions: state.sessions.sessions,
})

const mapDispatchToProps = (dispatch) => ({
  onCompetitionDetails: (requestParams) =>
    dispatch(competitionActions.onGetCompetitionDetails(requestParams)),
  getSessionList: (requestParams, type) =>
    dispatch(sessionActions.getSessionList(requestParams, type)),
  setToggleMenu: (requestParams) =>
    dispatch(searchAction.common(toggleLeftMenu, requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CompDetailedView))
