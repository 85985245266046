import React from 'react';
import {
  Button, Tabs, Table, Space, Checkbox, Divider, Modal, Spin, notification,
} from 'antd';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import * as action from '../../../store/actions/tutor/Classes';
import './style.css';
import Notes from './notes';
import StudentDetailsComponent from './student-details-component';
import * as userAction from '../../../store/actions/users';
import * as actionTypes from '../../../store/actions/users/actionTypes';
import { isPropertyLoadingInProgress } from '../../../store/utility';
import history from '../../../utils/history';
import { tutorSendNotesToStudentModal } from './send-notes';

class TutorDashboardViewStudent extends React.Component {
  state = {
    tabs: {
      tab_panes: {
        messages: { name: 'Messages', key: '1' },
        notes: { name: 'Notes', key: '2' },
      },
      activeKey: '-',
    },
    loadSpin: false,
    pastQueryParams: {},
    modal1Visible: false,
    modal2Visible: false,
    mileStones: '',
  };

  setModal2Visible(modal2Visible) {
    this.setState({ modal2Visible, mileStones: '' });
  }

  onGetUserClassDetails = () => {
    console.log('props', this.props);
    const params = queryString.parse(_.get(this, 'props.location.search', null));
    const requestParams = {};
    if (!_.isEmpty(_.get(params, 'dependent'))) {
      requestParams.user = _.get(this, 'props.match.params.userId', null);
      requestParams.class_id = _.get(params, 'classId', null);
      requestParams.dependent_user = _.get(params, 'dependent', null);
      requestParams.expand = 'class_details,user,dependent_user,provider';
    } else {
      requestParams.user = _.get(this, 'props.match.params.userId', null);
      requestParams.class_id = _.get(params, 'classId', null);
      requestParams.expand = 'class_details,user,dependent_user,provider';
    }

    this.props.onUserClassDetail(requestParams);
  };

  componentDidMount() {
    this.setTabActiveKey(_.get(queryString.parse(_.get(this, 'props.location.search')), 'tab'));
    this.onGetUserClassDetails();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updatedMilestoneInfo !== prevProps.updatedMilestoneInfo) {
      console.log('prevProps.updatedMilestoneInfo', this.props.updatedMilestoneInfo);
      if (_.get(this, 'props.updatedMilestoneInfo.errors.rules[0].message')) {
        notification.error({
          message: 'Error',
          description: _.get(this, 'props.updatedMilestoneInfo.errors.rules[0].message'),
        });
      } else if (this.props.updatedMilestoneInfo.message) {
        this.setModal2Visible(false);
      }
    }
    if (this.props.updatedMilestoneInfo !== prevProps.updatedMilestoneInfo) {
      this.onGetUserClassDetails();
    }
    this.changeTabPane();
    this.manageLoadSpin();
  }

  setTabActiveKey = (value) => {
    const tab_keys = _.map(_.values(_.get(this, 'state.tabs.tab_panes')), 'key');
    if (!_.isEmpty(value) && _.indexOf(tab_keys, value) > -1) {
      this.setState((previousState) => ({ tabs: _.merge(previousState.tabs, { activeKey: value }) }));
      history.push({
        pathname: _.get(this, 'props.location.pathname'),
        search: queryString.stringify(
          _.merge(
            queryString.parse(_.get(this, 'props.location.search')),
            { tab: value },
          ),
        ),
      });
    } else {
      this.setState((previousState) => ({ tabs: _.merge(previousState.tabs, { activeKey: _.get(this, 'state.tabs.tab_panes.notes.key') }) }));
    }
  };

  changeTabPane = () => {
    const pastQueryParams = _.pick(_.get(this, 'state.pastQueryParams'), ['tab']);
    const currentQueryParams = _.pick(queryString.parse(_.get(this, 'props.location.search')), ['tab']);
    if (!_.isEqual(pastQueryParams, currentQueryParams)) {
      this.setState({ pastQueryParams: currentQueryParams });
      this.setTabActiveKey(_.get(currentQueryParams, 'tab'));
    }
  };

  actionBlock = () => (
    <div className="tutor-dashboard-view-header">
      <div className="row">

        <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" />
        <div className="col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 pl-0">
          <div className="student-btn-group">
            {/* <Button className="student-btn mr-2" onClick={() => this.props.sendNotesModal.showModal()}>Send Notes</Button> */}
            {_.get(this, 'props.userClassDetails.details.provider.type') === 'Business' || 'Individual' ? <Button className="student-btn" onClick={() => this.setModal2Visible(true)}>{this.props.t('updateMilestone')}</Button> : ''}

          </div>
        </div>
        {_.get(this, 'props.userClassDetails.details.provider.type') === 'Business' ? <Divider /> : ''}

      </div>
    </div>
  );

  contentTab = () => {
    const tabPanes = _.get(this, 'state.tabs.tab_panes');
    return (
      <div className="full-width tutor-tab">
        <Tabs
          onTabClick={
            (key) => {
              this.setTabActiveKey(key);
            }
}
          activeKey={_.get(this, 'state.tabs.activeKey')}
        >
          <items tab={_.get(tabPanes, 'notes.name')} key={_.get(tabPanes, 'notes.key')}>
            {/* Defining notes component */}
            <Notes {...this.props} />
          </items>
        </Tabs>
      </div>
    );
  };

  manageLoadSpin = () => {
    const loadSpin = (isPropertyLoadingInProgress(_.get(this, 'props.users'), actionTypes.userClassInfo));
    if (!_.isEqual(_.get(this, 'state.loadSpin'), loadSpin)) {
      this.setState({ loadSpin });
    }
  };

  onChange(text, record) {
    this.setState({
      mileStones: record.milestones,
    });
  }

  updateMilestones = () => {
    const { userId } = this.props.match.params;
    const url = this.props.location.search;
    const params = queryString.parse(url);
    const requestParams = {};
    requestParams.class_details = params.classId;
    requestParams.user = userId;
    requestParams.milestone = this.state.mileStones;
    requestParams.comment = `${this.state.mileStones} milestone created`;
    if (!_.isEmpty(_.get(params, 'dependent'))) { requestParams.dependent_user = params.dependent; }

    if (this.state.mileStones) {
      this.props.onUpdateMilestone(requestParams);
      this.setState({
        mileStones: '',
      });
    }
  };

  render() {
    console.log('this.props.userClassDetails', this.props.userClassDetails);
    let MilestonedataSource = [];
    let milestoneArray = [];
    milestoneArray = _.get(this.props.userClassDetails, 'details.class_details.milestones', []);
    let completedMilestones = [];
    completedMilestones = _.get(this.props.userClassDetails, 'details.milestones', []);
    if (milestoneArray.length > 0) {
      MilestonedataSource = milestoneArray.map((value, index) => ({
        milestones: value,
        disable: _.includes(completedMilestones, value),
      }));
    }

    const Milestonecolumns = [{
      title: this.props.t('milestones'),
      dataIndex: 'milestones',
      key: 'milestones',
    },
    {
      title: this.props.t('Action'),
      key: 'action',
      render: (text, record) => {
        console.log('recordrecordrecord', this.state.mileStones === _.get(record, 'milestones'));
        return (
          <Space size="middle">
            <div>
              <Checkbox disabled={_.get(record, 'disable')} defaultChecked={_.get(record, 'disable') === true} onChange={(e) => this.onChange(text, record)} />
            </div>
          </Space>
        );
      },
    },
    ];
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="nurtem-card-design py-2 mt-4 mb-3">
            <div className="tutor-dashboard-view-student">

              <Spin spinning={_.get(this, 'state.loadSpin')}>
                {/* Defining student details component */}
                <StudentDetailsComponent {...this.props} userClassInfo={_.get(this, 'props.users.userClassInfo.details', {})} />

                {/* Declaring action Block */}
                {this.actionBlock()}

                {/* Declaring Content tab */}
                {this.contentTab()}
              </Spin>
            </div>
          </div>
        </div>

        {/* Update Milestone Modal */}
        <Modal
          className="student-view-notes-modal"
          title="Update Milestone"
          centered
          open={this.state.modal2Visible}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          footer={[
            <Button className="btn-cancel" key="back" disabled={this.props.milestoneLoading} onClick={() => this.setModal2Visible(false)}>Cancel</Button>,
            <Button loading={this.props.milestoneLoading} disabled={this.props.milestoneLoading} className="btn-submit" key="submit" type="primary" onClick={() => this.updateMilestones()}>Update</Button>,
          ]}
        >
          <div className="nurtem-modal-container nurtem-table">
            <div className="milestones-table">
              <Scrollbars className=" hidden-scroll-x" style={{ height: 280 }}>
                <Table dataSource={MilestonedataSource} columns={Milestonecolumns} pagination={false} />
              </Scrollbars>
            </div>
          </div>
        </Modal>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  userClassDetails: state.users.userClassInfo,
  milestoneLoading: state.classesDetails.milestoneLoading,
  updatedMilestoneInfo: state.classesDetails.updatedMilestoneInfo,
});

const mapDispatchToProps = (dispatch) => ({
  onUserClassDetail: (requestParams) => dispatch(userAction.getUserClassInfo(requestParams)),
  onUpdateMilestone: (requestParams) => dispatch(action.updateClassMilestone(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(tutorSendNotesToStudentModal(withTranslation()(TutorDashboardViewStudent))));
