import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  Modal,
  Radio,
  Tooltip,
  Form,
  Select,
  Spin,
  Tag,
  Button,
  Tabs,
  Table,
  Card
} from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import _ from 'lodash'
import { EditorState } from 'draft-js'
import { withTranslation } from 'react-i18next'
import baseProfileFormMeta from './form-meta'
import * as commonActions from '../../../../../store/actions/common'
import generalAction from '../../../../../store/general/action'
import * as action from '../../../../../store/actions/public/Classes'
import * as action2 from '../../../../../store/actions/tutor/common'
import generalActionTypes from '../../../../../store/general/actionTypes'
import * as actions from '../../../../../store/actions/tutor'
import { globalVariable } from '../../../../../utils/config/config'
import PlanSubscriptionForm from '../../../../user/user-cart/PlanSubscriptionForm'
import NoCostPlanSubscriptionForm from '../../../../user/user-cart/NoCostPlanSubscriptionForm'
import css from "../../../../../utils/CSSUtility"
import { FaCheck } from "react-icons/fa";

const { TabPane } = Tabs
const { Meta } = Card;
function BaseProfileTab(props) {
  console.log("plan props>>>>", props)


  const [service_text, set_service_text] = useState('')
  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(baseProfileFormMeta)
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({})
  // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false)
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false)

  const [openSubscriptionPlanModal, setOpenSubscriptionPlanModal] = useState(false)

  const [businessHours, setBusinessHours] = useState([])
  const [enableModal, setEnableModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const [localState, setLocalState] = useState({
    location_taught: {
      location_search_data: [],
      select_location_city_data: [],
      fetching: false,
    },
    address: { search_result: [], fetching: false },
    user_handle: {
      profileNameValid: false,
      validateStatus: '',
      value: '',
    },
    gender: { Male: '0', Female: '1', 'Prefer not to answer': '2' },
    save_changes_loading: false,
    is_fetching_tutor_profile: false,
    service_provider_type: false,
  })
  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm()

  const [tutorPlans, setTutorPlans] = useState([])

  const [openPaymentModel, setToOpenPaymentModel] = useState(false)

  const [openCashlessModel, setToOpenCashlessModel] = useState(false)

  const [paymentData, setPaymentData] = useState(0)

  const [intent_clientSecret, setIntentClientSecret] = useState(false)

  const transaction_list = _.get(props, 'subscribTransaction.items', [])
  console.log("plan transaction list>>>>", transaction_list)
  const tutorDetails = _.get(props, 'tutorProfileDetails', {})
  console.log("plantutor>>>>", tutorDetails)
  const subscriptionDetails = _.get(tutorDetails, 'subscription_plan', {})
  console.log("plansubscription>>>>", subscriptionDetails)
  const planDetails = _.get(subscriptionDetails, 'planDetails', '')

  const handleCancel = () => {
    // handle cancel payment
    setToOpenPaymentModel(false)
  }

  const handleOpenSubscriptionPlanModal = () => {
    setOpenSubscriptionPlanModal(true)
  }

  const handleCancelSubscriptionPlanModal = () => {
    setOpenSubscriptionPlanModal(false)
  }

  const handleCancel2 = () => {
    // handle cancel payment
    setToOpenCashlessModel(false)
  }
  const handleSubscribeModal = (value) => {
    setEnableModal(true)
    setModalData(value)

  }
  const handleCancelSubscribeModal = () => {
    setEnableModal(false)
  }

  useEffect(() => {
    const abortController = new AbortController() // Create an instance of AbortController

    //commonActions.makRequest('/providers/types', setTutorTypes)
    commonActions.makRequest('/sku/plans', setTutorPlans)

    // fetching tutor profile from api
    setLocalState({ ...localState, is_fetching_tutor_profile: true })
    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          setBaseProfileFormFields(_.get(data, 'details', {}))
        }
      },
    })

    props.subscribTransactionList()

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    setCurrentTabModified()

    // No need for cleanup here, but adding for consistency
    return () => { }
  }, [canUpdateForm])

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => { }))) {
      props.setCurrentTabModified(canUpdateForm)
    }
  }

  const refreshTutorProfile = () => {
    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          setBaseProfileFormFields(_.get(data, 'details', {}));
        }
      },
    });
  };

  const handleChangeTab = () => {
    setActiveKey(3);
  };
  const setBaseProfileFormFields = (tutor_profile_details) => {
    const formProperties = _.pick(tutor_profile_details, [
      'teaching_modes',

      'years_of_experience',
      'no_of_students_taught',
      'user_handle',
      'businesshours',
      'firstname',
      'lastname',
      'businessname',
      'zip_code',
      'mobile_number',
      'gender',
      'bio',
    ])

    setBusinessHours(_.get(formProperties, 'businesshours', []))
    formProperties.create_profile_as = _.get(tutor_profile_details, 'type', '')

    const plans = _.get(
      tutor_profile_details,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    if (!_.isEmpty(plans)) {
      formProperties.plans = plans
    }

    const service_labels = []

    form.setFieldsValue(formProperties)
    setTutorProfile(tutor_profile_details)

    // for ai generation
    const name = _.get(tutor_profile_details, 'name', '')
    const years_of_experience = _.get(formProperties, 'years_of_experience', 1);

    const question = `tutor name '${name}' providing servies in the following area ${service_labels.toString()} with ${years_of_experience} years of experiance`
    set_service_text(question)
  }

  const [textAreaCount, ChangeTextAreaCount] = React.useState(0)
  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length)
  }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const mainComponent = () => {
    //console.log('profile>>>', tutorProfile)

    const selectedPlan = _.get(
      tutorProfile,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    const alreadyNotInAPlan = _.isEmpty(selectedPlan)

    const profile_type = _.get(tutorProfile, 'profile_type', '')
    const plStr = alreadyNotInAPlan
      ? 'Please select a plan'
      : `You are curently on "${selectedPlan}", you can change the plan.`

    const plan_rule = alreadyNotInAPlan
      ? {
        name: 'plans',
        rules: [
          {
            required: true,
            message: 'Please select which type of plan you want.',
          },
        ],
      }
      : {
        name: 'plans',
      }
    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      const day = String(date.getDate()).padStart(2, '0');
      const month = date.toLocaleString('default', { month: 'short' });
      const year = date.getFullYear();
      return ` ${month} ${day}, ${year}`;
    };

    const columns = [
      {
        title: 'Plan Name',
        dataIndex: 'planDetails',
        key: 'planDetails',
        render: (obj) => obj && obj.label,
      },
      {
        title: 'Plan Price',
        dataIndex: 'planDetails',
        key: 'planDetails',
        render: (obj) => obj && obj.price,
      },
      {
        title: 'Start Date',
        dataIndex: 'startDate',
        key: 'startDate',
        render: (date) => formatDate(date),
      },
      {
        title: 'End Date',
        dataIndex: 'endDate',
        key: 'endDate',
        render: (date) => formatDate(date),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status) => {
          let color;
          switch (status) {
            case 'active':
              color = 'green';
              break;
            default:
              color = 'geekblue';
          }
          return <Tag color={color}>{status && status.toUpperCase()}</Tag>;
        },
      },
    ];
    const Listcolumns = [
      {
        title: 'Payment Date',
        dataIndex: 'paymentDate',
        key: 'paymentDate',
        render: (date) => formatDate(date),
      },
      {
        title: 'Plan Name',
        dataIndex: 'planName',
        key: 'planName',
      },
      {
        title: 'Cost',
        dataIndex: 'amount',
        key: 'amount',
        render: (text) => parseFloat(text) / 100,
      },
      {
        title: 'Plan Duration (in months)',
        dataIndex: 'durationMonths',
        key: 'durationMonths',
      },
      {
        title: 'Payment Status',
        dataIndex: 'payment_status',
        key: 'payment_status',
        render: (payment_status) => {
          let color;
          switch (payment_status) {
            case 'active':
              color = 'green';
              break;
            default:
              color = 'geekblue';
          }
          return <Tag color={color}>{payment_status.toUpperCase()}</Tag>;
        },
      },
    ];
    const handlePlanChange = (event, item, price) => {
      if (price > 0) {
        setIntentClientSecret(false);
        setToOpenPaymentModel(true);
        setToOpenCashlessModel(false);
        setEnableModal(false)
        let paymentIntent_data = {
          provider_type: profile_type,
          payment_data: {
            login_type: 'provider',
            subscription_date: new Date(),
            collection_method: 'subscription',
            payment_method: 'ach',
            cart_items: item,
          },
          amount: _.round(price * 100, 0),
        };

        setPaymentData(paymentIntent_data);

        props.stripePaymentIntent(paymentIntent_data, (data) => {
          try {
            if (data.clientSecret) {
              setIntentClientSecret(data.clientSecret);
            } else {
              console.log('error> at /stripe/create-payment-intent cart.js', data.error);
            }
          } catch (error) {
            console.log('error> at /stripe/create-payment-intent cart.js');
          }
        });
      } else {
        setToOpenCashlessModel(true);
        setToOpenPaymentModel(false);
        let paymentIntent_data = {
          provider_type: profile_type,
          payment_data: {
            login_type: 'provider',
            subscription_date: new Date(),
            collection_method: 'subscription',
            payment_method: 'ach',
            cart_items: item,
          },
          amount: 0,
        };

        setPaymentData(paymentIntent_data);
      }
      setLocalState({ ...localState });
    };
    const currenttransUnit = planDetails.unit === 'monthly' ? 'Month' : planDetails.unit === 'yearly' ? 'Year' : ''
    return (

      <>
        <Tabs defaultActiveKey="1" >
          <TabPane tab='Subscription' key='1'>
            <div className="my-profile-tabPane-container">
              <div className="row" style={{ width: '100%', margin: 0 }}>
                <div className="col-12 mb-3">
                  <div className="business-information">
                    {/* <div className='d-flex flex-row-reverse mb-2 ml-2 '>
                      <Button type='primary' onClick={handleOpenSubscriptionPlanModal}>Change the Plan</Button>
                    </div> */}
                    <div className='currentPlan'>
                      <div className='currentLabel ml-3' >
                        Current Plan
                      </div>
                      <div className='currentName ml-3' >
                        <span>{planDetails.label}</span>

                        <span style={{ color: 'black', fontSize: 22 }}>
                          <span>
                            <sup style={{ color: 'black', fontWeight: 600, fontSize: 17 }}>$</sup>
                          </span>{planDetails.price}
                          <span style={{ color: 'gray', fontSize: '14px' }}>
                            {" / "}{currenttransUnit}
                          </span>
                        </span>
                      </div>
                      <div className='ml-3 mt-4'>
                        Free Trial period{" "}<span style={{ color: '#000', fontWeight: 600 }}>{planDetails.trialPeriod}{" "}{planDetails.trialPeriodUnit}</span>
                      </div>
                      <div className='ml-3'>
                        Start from{" "}<span style={{ color: '#000', fontWeight: 600 }}>{formatDate(subscriptionDetails.startDate)}{" "}</span>Upto{" "}
                        <span style={{ color: '#000', fontWeight: 600 }}>{formatDate(subscriptionDetails.endDate)}{" "}</span>
                      </div>
                    </div>
                    <div style={css.heading1} className='mt-4'>
                      Change Plan
                    </div>
                    <div className="card-container">
                      {_.map(tutorPlans, (item, key) => {
                        console.log("mapitems>>>>", item)
                        const price = _.get(item, 'price', 0);
                        const unit = _.get(item, 'unit', '')
                        const transUnit = unit === 'monthly' ? 'Month' : unit === 'yearly' ? 'Year' : ''
                        const isSelected = _.get(tutorProfile, ['subscription_plan', 'planDetails', 'value'], '') === _.get(item, 'value', '');

                        return (
                          <div
                            key={key}
                            className={`plan-card ${isSelected ? 'selected' : ''}`}
                            style={{ width: 300, margin: '10px', border: '1px solid #f0f0f0', borderRadius: '10px', padding: '16px' }}
                          >
                            {isSelected && <div style={{ color: '#1687a0', fontWeight: 600 }}>
                              Current Plan
                            </div>}
                            {isSelected && (
                              <FaCheck
                                className='iconCorrected'
                              />
                            )}
                            <div style={{ marginBottom: '16px', display: 'flex', flexDirection: 'column', gap: 5 }}>
                              <span style={css.heading1} className='m-0'>{_.get(item, 'label', '')}</span>
                              <div style={{ fontSize: 18 }}>
                                <span>
                                  <sup style={{ color: 'black', fontWeight: 600, fontSize: 17 }}>$</sup>
                                </span>
                                <span style={{ color: 'black', fontWeight: 600, fontSize: 25 }}>{`${price}`}</span>
                                <span style={{ color: 'grey', fontWeight: 600, fontSize: 12 }}>{"/ "}{transUnit}</span>
                              </div>
                            </div>
                            {!isSelected && <button
                              onClick={() => handleSubscribeModal(item)}
                              className='subscribeButton'
                            >
                              Subscribe
                            </button>}
                          </div>
                        );
                      })}
                    </div>
                    {/* <Table columns={columns} dataSource={data} pagination={false} /> */}
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab='Transaction List' key='2'>
            <div className="my-profile-tabPane-container">
              <div className="row" style={{ width: '100%', margin: 0 }}>
                <div className="col-12 mb-3">
                  <div className="business-information">
                    <Table columns={Listcolumns} dataSource={transaction_list} pagination={false} />
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
        <Modal
          width={"75%"}
          title={<span style={css.heading1}>
            Change Plan
          </span>}
          open={enableModal}
          onCancel={handleCancelSubscribeModal}
          footer={null}
        >
          {modalData && (
            <div className="modalDiv">
              <span className="planLabel">
                Current Plan{" "}
                <span className="planDetail">{planDetails.label}</span>
              </span>
              <span className="planPrice">
                Current Plan Price{" "}
                <span className="priceDetail">${planDetails.price}</span>
                <span className="priceUnit">{" / "}{currenttransUnit}</span>
              </span>
              <span className="planLabel">
                Requested Plan{" "}
                <span className="planDetail">{modalData.label}</span>
              </span>
              <span className="planPrice">
                Requested Plan Price{" "}
                <span className="priceDetail">${modalData.price}</span>
                <span className="priceUnit">{" / "}{modalData.unit === "monthly" ? "Month" : modalData.unit === "yearly" && "Year"}</span>
              </span>
              {planDetails.level < modalData.level ? (
                <span className="upgradeMessage">
                  By upgrading to the <span className="boldText">{modalData.label}</span> plan, your new rate will be{" "}
                  <span className="boldText">${modalData.price}{" "}</span>
                  {modalData.unit === "monthly" ? (
                    <span className="boldText">Month</span>
                  ) : (
                    modalData.unit === "yearly" && <span className="boldText">Year</span>
                  )}{" "}plus tax. Once you confirm the upgrade, your new plan will take effect immediately.
                </span>
              ) : (
                <span className="downgradeMessage">
                  By downgrading to the <span className="boldText">{modalData.label}</span> plan, your new rate will be{" "}
                  <span className="boldText">${modalData.price}{" "}</span>
                  {modalData.unit === "monthly" ? (
                    <span className="boldText">Month</span>
                  ) : (
                    modalData.unit === "yearly" && <span className="boldText">Year</span>
                  )}{" "}plus tax. Your current <span className="boldText">{planDetails.label}{" "}</span>
                  plan will remain active until the end of the current billing cycle, after which the downgrade to the
                  <span className="boldText">{modalData.label}</span> plan will take effect. No refunds or credits will be issued for the remaining period of the current billing cycle.
                </span>
              )}
            </div>
          )}
          {modalData && (
            <div className="updradeButtondiv">
              <button
                className="updradeButton"
                onClick={(event) => handlePlanChange(event, modalData, modalData.price)}
              >
                {planDetails.level > modalData.level ? "Downgrade Plan" : "Upgrade Plan"}
              </button>
            </div>
          )}

        </Modal>
      </>)
  }

  return (
    <>
      <Scrollbars
        className="tutor-onboarding-scroll-height hidden-scroll-x"
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            className="track-horizontal"
            style={{ display: 'none' }}
          />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            className="thumb-horizontal"
            style={{ display: 'none' }}
          />
        )}
      >
        <Spin spinning={loadSpin}>{mainComponent()}</Spin>
      </Scrollbars>
      <Modal
        title="Payment Powered by Stripe"
        open={openPaymentModel}
        onCancel={handleCancel}
        footer={null}
      >
        {intent_clientSecret ? (
          <Elements
            options={{
              clientSecret: intent_clientSecret,
              theme: 'stripe',
            }}
            stripe={loadStripe(globalVariable.STRIPE_PUBLISHABLE_KEY)}
          >
            {paymentData && (
              <PlanSubscriptionForm
                paymentIntent_data={paymentData}
                showACHModal={setToOpenPaymentModel}
                clientSecret={intent_clientSecret}
                onCreateTransaction={(details) => {
                  props.onCreateTransaction(details);
                  refreshTutorProfile();  // Refresh profile after transaction
                }}
              />
            )}
          </Elements>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </Modal>

      <Modal
        title="Cashless Plan"
        open={openCashlessModel}
        onCancel={handleCancel2}
        footer={null}
      >
        <NoCostPlanSubscriptionForm
          paymentIntent_data={paymentData}
          showACHModal={setToOpenCashlessModel}
          clientSecret={''}
          onCreateTransaction={(details) => {
            props.onCreateTransaction(details);
            refreshTutorProfile();  // Refresh profile after transaction
          }}
        />
      </Modal>

    </>
  )
}

const mapStateToProps = (state) => ({
  tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}),
  services: _.get(state, `general.${generalActionTypes.services.name}`, {}),
  loggedInUserDetails: state.authentication,
  tutorProfileDetails: _.get(
    state,
    'tutorProfile.tutorBaseProfile.details',
    {}
  ),
  subscribTransaction: state.common.transactionList
})

const mapDispatchToProps = (dispatch) => ({
  onCreateTransaction: (details) =>
    dispatch(commonActions.onCreateTransaction(details)),

  // onAiGenerateText: (requestParams, callback) =>
  //   dispatch(commonActions.onAiGenerateText(requestParams, callback)),
  stripePaymentIntent: (data, callback) =>
    dispatch(action.stripe_payment_intent(data, callback)),
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) =>
    dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) =>
    dispatch(generalAction.tutor.updateProfile(details)),
  updateProfileImage: (details) =>
    dispatch(generalAction.tutor.updateProfileImage(details)),
  removeProfileImage: (details) =>
    dispatch(generalAction.tutor.removeProfileImage(details)),
  getTutorProfile: (details) =>
    dispatch(generalAction.tutor.getProfile(details)),
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
  subscribTransactionList: () => dispatch(action2.onSubscriptionTransactionList())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BaseProfileTab))
