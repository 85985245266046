// competitions

export const tutorCompetitionsDeleteFoundSuccess = 'tutorCompetitionsDeleteFoundSuccess';
export const tutorCompetitionRequests = 'tutorCompetitionRequests';
export const tutorCompetitionRequestAction = 'tutorCompetitionRequestAction';
export const tutorCompetitionDetailsFoundSuccess = 'tutorCompetitionDetailsFoundSuccess';
export const CompetitionsStudentListLoading = 'CompetitionsStudentListLoading';
export const competitionStudents = 'competitionStudents';
export const providerCompetitions = 'providerCompetitions';
export const otherProviderCompetitions = 'otherProviderCompetitions';
// events
export const providerEvents = 'providerEvents';
export const enableEventsLoading = 'enableEventsLoading';
export const disableEventsLoading = 'disableEventsLoading';
export const tutorEventsDetailsFoundSuccess = 'tutorEventsDetailsFoundSuccess';
export const tutorEventsDeleteFoundSuccess = 'tutorEventsDeleteFoundSuccess';
export const tutorEventRequests = 'tutorEventRequests';
export const tutorEventRequestAction = 'tutorEventRequestAction';
export const sessionListEvent = 'sessionListEvent';
export const eventStudents = 'eventStudents';

/* ----*ended*----*/
export const show_element = 'show_element';
export const show_group_info = 'show_group_info';
export const selected_provider = 'selected_provider';
export const sessionList = 'sessionList';
export const requestCount = 'requestCount';
export const sessionListComp = 'sessionListComp';
export const sessionListClass = 'sessionListClass';
export const enableClassSessionsLoading = 'enableClassSessionsLoading';
export const disableClassSessionsLoading = 'disableClassSessionsLoading';
export const enableTotalStudentListLoading = 'enableTotalStudentListLoading';
export const disableTotalStudentListLoading = 'disableTotalStudentListLoading';
export const enableTotalStudentListLoadingCamp = 'enableTotalStudentListLoadingCamp';
export const disableTotalStudentListLoadingCamp = 'disableTotalStudentListLoadingCamp';
export const enableTotalStudentListLoadingComp = 'enableTotalStudentListLoadingComp';
export const disableTotalStudentListLoadingComp = 'disableTotalStudentListLoadingComp';
export const active_key = 'active_key';
export const TotalStudentList = 'TotalStudentList';
export const TotalStudentListCamp = 'TotalStudentListCamp';
export const TotalStudentListComp = 'TotalStudentListComp';
export const TotalSubsTransList = 'TotalSubsTransList';
export const RequestAssignment = 'RequestAssignment';

// payments actions
export const offline_payment_object = 'offline_payment_object';
export const balance_amount = 'balance_amount';
export const reload = 'reload';
export const on_receiving_student_payment_information = 'on_receiving_student_payment_information';
export const providerstudents = 'providerstudents';
export const get_student_payment_info = 'get_student_payment_info';
export const student_list_loaded = 'student_list_loaded';

export const tutorBaseProfile = 'tutorBaseProfile';
export const tutorBusinessinfoSuccess = 'tutorBusinessinfoSuccess';
export const tutorContactinfoSuccess = 'tutorContactinfoSuccess';
export const tutorExtrainfoSuccess = 'tutorExtrainfoSuccess';
export const enableLoading = 'enableLoading';
export const disablecompListLoading = 'disablecompListLoading';
export const enablecompListLoading = 'enablecompListLoading';
export const enableClassDetailsLoading = 'enableClassDetailsLoading';
export const disableClassDetailsLoading = 'disableClassDetailsLoading';
export const enableCampDetailsLoading = 'enableCampDetailsLoading';
export const enableSessionLoading = 'enableSessionLoading';
export const enableCampListloading = 'enableCampListloading';
export const campListloading = 'campListloading';
export const disableCampListloading = 'disableCampListloading';
export const disableLoading = 'disableLoading';
export const disableCampListLoading = 'disableCampListLoading';
export const enableCampListLoading = 'enableCampListLoading';
export const disableCampDetailsLoading = 'disableCampDetailsLoading';
export const disableSessionLoading = 'disableSessionLoading';
export const ProfilesUpdateSuccess = 'ProfilesUpdateSuccess';
export const BusinessProfileUpdateSuccess = 'BusinessProfileUpdateSuccess';
export const tutorClassDetailsFoundSuccess = 'tutorClassDetailsFoundSuccess';
export const classSessionModifySuccess = 'classSessionModifySuccess';
export const tutorClassDeleteFoundSuccess = 'tutorClassDeleteFoundSuccess';
export const singleClassViewLoading = 'singleClassViewLoading';
export const enableRequestLoading = 'enableRequestLoading';
export const enableCampsRequestLoading = 'enableCampsRequestLoading';
export const singleClassView = 'singleClassView';
export const tutorClassCreateNoteSuccess = 'tutorClassCreateNoteSuccess';
export const onUpdateNotesForTutor = 'onUpdateNotesForTutor';
export const tutorClassDeleteNoteSuccess = 'tutorClassDeleteNoteSuccess';
// export const imageUploadSuccess = "imageUploadSuccess";
export const userHandle = 'userHandle';
export const providerClasses = 'providerClasses';
export const otherProviderClasses = 'otherProviderClasses';
export const tutorClassesRequests = 'tutorClassesRequests';

export const classesRequestActionSuccess = 'classesRequestActionSuccess';
export const ClassesStudentListLoading = 'ClassesStudentListLoading';
export const classesStudents = 'classesStudents';
export const businessClassesStudents = 'businessClassesStudents';
export const createMilestone = 'createMilestone';
export const updateMilestone = 'updateMilestone';
export const enableMilestoneLoading = 'enableMilestoneLoading';
export const disableMilestoneLoading = 'disableMilestoneLoading';
export const tutorSpecificClassesFound = 'tutorSpecificClassesFound';
export const classesListFound = 'classesListFound';
export const businessClassesListFound = 'businessClassesListFound';
export const onSearchClassesList = 'onSearchClassesList';
export const onSearchClassesLoading = 'onSearchClassesLoading';
export const businessClassesStudentListLoading = 'businessClassesStudentListLoading';
export const onBusinessSearchClassesLoading = 'onBusinessSearchClassesLoading';
export const couponList = 'couponList';
export const couponData = 'couponData';
export const galleryResponce = 'galleryResponce';
export const classesCount = 'classesCount';
export const onTutorClassesList = 'onTutorClassesList';
export const sendNotesToStudent = 'sendNotesToStudent';
export const updateTutorState = 'updateTutorState';
export const tutorSwitchPropertyLoading = 'tutorSwitchPropertyLoading';
/* Attendence Details */
export const AttendenceCreation = 'AttendenceCreation';
export const tutorAttendenceDetailsFoundSuccess = 'tutorAttendenceDetailsFoundSuccess';
export const attendenceDeleteFoundSuccess = 'attendenceDeleteFoundSuccess';
export const attendenceListFound = 'attendenceListFound';
export const providerAttendence = 'providerAttendence';
// Commission percentage
export const CommissionPercentages = 'CommissionPercentages';
export const resetTutorClassDetails = 'resetTutorClassDetails';
export const setCouponsData = 'setCouponsData';
export const createdCoupons = 'createdCoupons';
export const providerPaymentList = 'providerPaymentList';
/* camps  Details */
export const providerCamps = 'providerCamps';
export const otherProviderCamps = 'otherProviderCamps';
export const tutorCampsRequests = 'tutorCampsRequests';
export const campsRequestActionSuccess = 'campsRequestActionSuccess';
export const campsStudents = 'campsStudents';
export const sessionDetail = 'sessionDetail';
export const tutorSpecificCampsFound = 'tutorSpecificCampsFound';
export const campsListFound = 'campsListFound';
export const onSearchCampsList = 'onSearchCampsList';
export const onCampsSponsoredList = 'onCampsSponsoredList';
export const onProviderDirectoryList = 'onProviderDirectoryList';
export const campsCount = 'campsCount';
export const tutorCampsDetailsFoundSuccess = 'tutorCampsDetailsFoundSuccess';
export const tutorCampsDeleteFoundSuccess = 'tutorCampsDeleteFoundSuccess';
export const resetTutorCampsDetails = 'resetTutorCampsDetails';
export const tutorCampsCreateMilestoneSuccess = 'tutorCampsCreateMilestoneSuccess';
export const milestoneCreate = 'milestoneCreate';
export const milestoneList = 'milestoneList';
export const attendanceReport = 'attendanceReport';
export const paymentReport = 'paymentReport';
export const EnablepaymentReportLoading = 'EnablepaymentReportLoading';
export const DisablepaymentReportLoading = 'DisablepaymentReportLoading';
// Sent Notes to class
export const sendNotesToClass = 'sendNotesToClass';
