import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import _ from 'lodash';
import { Spin } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { withTranslation } from 'react-i18next';
import * as userAction from '../../../store/actions/users';
import * as actionTypes from '../../../store/actions/users/actionTypes';
import * as tutorActionTypes from '../../../store/actions/tutor/actionTypes';
import { isPropertyLoadingInProgress } from '../../../store/utility';

// this component defines The notes that tutor send to the student for the specific job
class Notes extends React.Component {
  state = {
    query_params: {
      page: 1,
      limit: 10,
      sort: 'updated_at.desc',
    },
    provider_notes_list: [],
    provider_notes_meta: {},
    loadSpin: false,
    allowLoadMore: false,
  };

  lockScroll = false;

  getProviderNotesForUser = () => {
    const params = queryString.parse(_.get(this, 'props.location.search', null));
    let requestParams = {};
    requestParams = {
      // user: _.get(this, 'props.match.params.userId', null),
      class_details: _.get(params, 'classId', null),
      ..._.get(this, 'state.query_params'),
    };
    this.props.onGetProviderNotesForUser(requestParams, (data) => {
      this.setState((previousParams) => ({
        ...previousParams,
        provider_notes_list: _.concat(_.get(previousParams, 'provider_notes_list'), _.get(data, 'items', [])),
        provider_notes_meta: _.get(data, 'meta', {}),
        query_params: { ..._.get(previousParams, 'query_params'), page: _.add(_.get(previousParams, 'query_params.page'), _.isEqual(_.get(data, 'meta.count', 0), _.get(previousParams, 'query_params.limit')) ? 1 : 0) },
        allowLoadMore: false,
      }));
      this.lockScroll = false;
    });
  };

  componentDidMount() {
    this.getProviderNotesForUser();
  }

  componentDidUpdate() {
    this.manageLoadSpin();
    if (_.get(this, 'props.tutor.triggerProviderNotesToStudentComponent', false)) {
      this.setState({ allowLoadMore: true });
      this.props.triggerProviderNotesToStudentComponent(false);
    }
  }

  manageLoadSpin = () => {
    const loadSpin = (isPropertyLoadingInProgress(_.get(this, 'props.users'), actionTypes.providerNotesForUser) && _.size(_.get(this, 'state.provider_notes_list')) > 0);
    if (!_.isEqual(_.get(this, 'state.loadSpin'), loadSpin)) {
      this.setState({ loadSpin });
    }
  };

  providerNotesList = (items) => {
    console.log('items list', items);
    return (
      <>
        {
                    _.map(_.uniq(items, 'id'), (item, key) => (
                      <div className="tutor-message-info pl-0" key={key}>
                        <div className="font-weight-bold">
                          {_.get(item, 'title', 'empty title')}
                          {' '}
                          <span className="tutor-message-name-set-time w-100 text-left ml-2">
                            <i className="ntm-clock mr-1" />
                            {(new Date(_.get(item, 'updated_at', null)).format('MMM DD,YYYY'))}
                          </span>
                        </div>

                        <div className="tutor-message-content" style={{ whiteSpace: 'pre-wrap' }}>
                          {_.get(item, 'text', 'empty notes')}

                        </div>
                      </div>
                    ))
                }

      </>
    );
  };

  onScrollProviderNotesList = (e) => {
    if (((_.isEqual(_.get(e, 'top', 0), 1) && !_.get(this, 'state.loadSpin') && (_.size(_.get(this, 'state.provider_notes_list')) < _.get(this, 'state.provider_notes_meta.total_count', 0))) || (_.isEqual(_.get(e, 'top', 0), 1) && _.get(this, 'state.allowLoadMore'))) && !this.lockScroll) {
      this.lockScroll = true;
      this.getProviderNotesForUser();
    }
  };

  enableLoadMoreIfLast = () => {
    if (_.size(_.get(this, 'state.provider_notes_list')) >= _.get(this, 'state.provider_notes_meta.total_count', 0)) {

    }
  };

  render() {
    return (
      <div className="tutor-message-container pt-0">
        <Scrollbars onScrollFrame={this.onScrollProviderNotesList} style={{ height: 350 }}>
          <Spin spinning={_.get(this, 'state.loadSpin')}>

            {this.providerNotesList(_.get(this, 'state.provider_notes_list', []))}
            {_.get(this, 'state.loadSpin') ? (<p className="text-center">{this.props.t('loading1')}</p>) : null}

          </Spin>
        </Scrollbars>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.users,
  tutor: state.tutor,
});

const mapDispatchToProps = (dispatch) => ({
  onGetProviderNotesForUser: (requestParams, callback = () => { }) => dispatch(userAction.getProviderNotesForUser(requestParams, callback)),
  triggerProviderNotesToStudentComponent: (value) => dispatch({ type: tutorActionTypes.updateTutorState, data: { triggerProviderNotesToStudentComponent: value } }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Notes));
