import * as SecureLS from 'secure-ls';
import { Layout, Button } from 'antd';
import { connect } from 'react-redux';
import React from 'react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { Router } from 'react-router-dom';
import Header from '../layout/header';
import BreadCrumb from '../layout/BreadCrumb';
// import BreadCrumb from '../layout/BreadCrumb/breadcrumb-new';
import Footer from '../layout/footer';
import LayoutContent from './content';
import PublicLayoutContent from '../public/content';
import TutorDashboardPageSidebar from '../layout/Sidebar/tutor-dashboard-sidebar/tutor-dashboard-sidebar';
import history from '../../utils/history';
import { routeRestriction } from '../../components/Other/routeRestriction';
import '../../assets/fonts/nurtem/style.css';
import * as routeName from '../../components/route-names';
import { DefaultLoader } from '../../components/loader/default-loader';
import * as actions from '../../store/actions/tutor';
import { toggleLeftMenu } from '../../store/actions/public/search/actionTypes'
import * as searchAction from '../../store/actions/public/search'

import './style.css';

const ls = new SecureLS();
const { Sider } = Layout;
class TutorAccount extends React.Component {
  state = {
    sidebar: true,
  };

  
  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.pathname === routeName.TutorBaseProfileStep1) {
      if (this.state.sidebar) {
        this.setState({ sidebar: false });
      }
    } else if (!this.state.sidebar) {
      this.setState({ sidebar: true });
    }
  }

  componentDidMount() {
    const providerInfo = ls.get('providerinfo');

    this.props.onTutorProfile(this.props.loggedInUserDetails);
    if (providerInfo && providerInfo.data.details.update_progress === 0) {
      history.push(routeName.TutorBaseProfileStep1);
    } else if (!this.state.sidebar) {
      this.setState({ sidebar: true });
    }

    routeRestriction(this.props.loggedInUserDetails);
  }

  onCollapse = (cond) => {
    this.props.setToggleMenu(cond)
  };

  render() {
    return (
      <Router history={history}>
        <div className="page-container">
          <div className="header-container">
            <div className="container-fluid">
              <Header location={history.location} />
            </div>
          </div>
          {Object.getOwnPropertyNames(this.props.tutorBaseProfile).length
            > 0 ? (
              <Layout className="site-layout">
                <div style={{
                  position: 'relative', minHeight: 'calc(100vh - 190px)', width: '100%', display: 'flex', flexDirection: 'row',
                }}
                >

                  {this.state.sidebar && (
                  <Sider
                    breakpoint="lg"
                    trigger={null}
                    collapsible
                    collapsed={this.props.toggleMenu}
                    className="AdminSideBar"
                    width="16.6%"
                    transition=""
                    onBreakpoint={(broken) => {
                      if (!broken) {
                        // Screen size increased, set collapsed to false
                        this.props.setToggleMenu(false)
                      } else {
                        // Screen size decreased, set collapsed to true
                        this.props.setToggleMenu(true)
                      }
                    }}
                    style={{
                      position: 'fixed', overflow: 'auto', top: 60, bottom: -20,
                    }}
                  >
                    <TutorDashboardPageSidebar />
                  </Sider>
                  )}
                  {this.state.sidebar && <Button size="small" className={this.props.toggleMenu ? 'menuresize menuresizeleftbtn' : 'menuresize menuresizerightbtn'} shape="circle" icon={this.props.toggleMenu ? <AiOutlineRight style={{ fontSize: 20 }} onClick={() => this.onCollapse(false)} /> : <AiOutlineLeft style={{ fontSize: 20 }} onClick={() => this.onCollapse(true)} />} />}

                  <div className={this.state.sidebar ? this.props.toggleMenu ? ' containerLeft' : ' containerRight' : 'onboardContainer'}>

                    <div
                      className="container-fluid"
                      style={{
                        display: 'flex', flexDirection: 'column', rowGap: '10px', marginTop: '10px', marginLeft: '2px', paddingLeft: '2px',
                      }}
                    >

                      <div className="container-fluid" style={{ marginLeft: '2px', paddingLeft: '2px' }}>
                        {' '}
                        <BreadCrumb />
                      </div>

                      <div className="container-fluid" style={{ marginLeft: '2px', paddingLeft: '2px' }}>
                        <LayoutContent />
                        <PublicLayoutContent />
                      </div>
                    </div>
                  </div>
                </div>
              </Layout>
            )
            : <DefaultLoader />}
          {Object.getOwnPropertyNames(this.props.tutorBaseProfile).length
            > 0 ? (
              <div className={this.state.sidebar ? this.props.toggleMenu ? 'footerLeft' : 'footerRight' : 'onboardFooter'}>
                <div className="footer-container">
                  <div className="container-fluid">
                    <Footer />
                  </div>
                </div>
              </div>
            ) : <> </>}
        </div>
      </Router>
    );
  }
}
const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  loggedInUserDetails: state.authentication,
});
const mapDispatchToProps = (dispatch) => ({
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  setToggleMenu: (requestParams) =>
  dispatch(searchAction.common(toggleLeftMenu, requestParams)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TutorAccount);
