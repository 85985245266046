import React from 'react';
import './user-verify.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { DefaultLoader } from '../../../components/loader/default-loader';
import * as actions from '../../../store/actions/authentication/authentication';
import * as routeName from '../../../components/route-names';

class UserVerify extends React.Component {
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const token = query.get('token');
    if (token !== '') {
      this.props.userVerify(token);
    }
  }

  render() {
    const url = this.props.location.search;
    const params = queryString.parse(url);
    if (!this.props.loading) {
      return (
        <div className="container">
          <div className="email-verify-container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-12">
                <div className="card border-0 p-3">
                  {(() => {
                    if (this.props.isUserVerified) {
                      return (
                        <div
                          className="swal2-icon swal2-success swal2-animate-success-icon"
                          style={{ display: 'flex' }}
                        >
                          <div
                            className="swal2-success-circular-line-left"
                            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                          />
                          <span className="swal2-success-line-tip" />
                          <span className="swal2-success-line-long" />
                          <div className="swal2-success-ring" />
                          <div
                            className="swal2-success-fix"
                            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                          />
                          <div
                            className="swal2-success-circular-line-right"
                            style={{ backgroundColor: 'rgb(255, 255, 255)' }}
                          />
                        </div>
                      );
                    }
                    return (
                      <div className="swal2-icon swal2-error swal2-animate-error-icon" style={{ display: 'flex' }}>
                        <span className="swal2-x-mark">
                          <span className="swal2-x-mark-line-left" />
                          <span className="swal2-x-mark-line-right" />
                        </span>
                      </div>
                    );
                  })()}
                  <h2 className="title-color mb-2">
                    {this.props.isUserVerified ? 'Thanks for joining Nurtem !' : `${this.props.errorMessage}`}
                  </h2>
                  {(() => {
                    if (this.props.isUserVerified) {
                      return (
                        <h5>
                          {this.props.t('please')}
                          {' '}
                          <Link to={params && (params.classId || params.campId) ? params.campId ? `${routeName.signIn}campId=${params.campId}` : `${routeName.signIn}?provider=${params.provider}&classId=${params.classId}` : routeName.signIn} className="title-color">{this.props.t('login')}</Link>
                          {' '}
                          {this.props.t('toCon')}
                        </h5>
                      );
                    }
                  })()}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (<DefaultLoader />);
  }
}
const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  isUserVerified: state.authentication.isUserVerified ? state.authentication.isUserVerified.result : null,
  errorMessage: state.authentication.isUserVerified ? state.authentication.isUserVerified.message : null,
});

const mapDispatchToProps = (dispatch) => ({
  userVerify: (requestParams) => dispatch(actions.userVerify(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserVerify));
