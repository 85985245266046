import * as SecureLS from 'secure-ls';
import _, { constant } from 'lodash';
import { notification } from 'antd';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import { switchPropertyLoading, reducersName } from '../../utility';

export const globalObj = {
  processid: 0,
  page: 1,
};

export const on_receiving_student_payment_information = (data) => ({
  type: actionTypes.on_receiving_student_payment_information,
  payload: data,
});

export const on_receiving_payment_object = (data) => ({
  type: actionTypes.offline_payment_object,
  payload: data,
});

export const balanceAmount = (data) => ({
  type: actionTypes.balance_amount,
  payload: data,
});
export const user_payment_info = (data, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/users/payment-info',
    data,
    withCredentials: true,
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data.details);
      }
    })
    .catch((error) => {
      callback([]);
    });
};

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const user_class_sessions_attended = (data) => ({
  type: actionTypes.user_class_sessions_attended,
  success: data,
});

export const updateUserProfile = (data) => ({
  type: actionTypes.updateUserProfile,
  loading: data,
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const enableProfileLoading = () => ({
  type: actionTypes.enableProfileLoading,
  loading: { profileLoading: true },
});

export const disableProfileLoading = () => ({
  type: actionTypes.disableProfileLoading,
  loading: { profileLoading: false },
});

export const disableprofileModal = () => ({
  type: actionTypes.disableprofileModal,
  loading: { profileModal: false },
});
export const enableprofileModal = () => ({
  type: actionTypes.enableprofileModal,
  loading: { profileModal: false },
});

export const enableClassLoading = () => ({
  type: actionTypes.enableClassLoading,
  loading: { loading: true },
});

export const disableClassLoading = () => ({
  type: actionTypes.disableClassLoading,
  loading: { loading: false },
});

export const enableDependentUserLoading = () => ({
  type: actionTypes.enableDependentUserLoading,
  loading: { dependentUserLoading: true },
});

export const disableDependentUserLoading = () => ({
  type: actionTypes.disableDependentUserLoading,
  loading: { dependentUserLoading: false },
});

export const enableServiceListLoading = () => ({
  type: actionTypes.enableServiceListLoading,
  loading: { serviceListLoading: true },
});

export const disableServiceListLoading = () => ({
  type: actionTypes.disableServiceListLoading,
  loading: { serviceListLoading: false },
});

export const onUserInfoFound = (data) => ({
  type: actionTypes.userInfoFound,
  success: data,
});

export const onSendNotesFound = (data) => ({
  type: actionTypes.sendNotes,
  success: data,
});

export const onNotesFound = (data) => ({
  type: actionTypes.notesFound,
  success: data,
});

export const getUserInfo = (requestParams) => {
  const modifiedParams = { ...requestParams };
  delete modifiedParams.filterStatus;
  return (dispatch) => {
    dispatch(enableLoading());
    axios
      .get('/getUserDetails', {
        params: { ...modifiedParams },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onUserInfoFound(response.data));
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        dispatch(disableLoading());
      });
  };
};

export const clearUserInfo = () => (dispatch) => {
  dispatch(onUserInfoFound({}));
};

export const getProviderNotesForUser = (requestParams, callback = () => { }) => (dispatch) => {
  switchPropertyLoading(dispatch, reducersName.users, actionTypes.providerNotesForUser, true, { [actionTypes.providerNotesForUser]: {} });
  axios
    .get('/notes/list-for-provider', {
      params: requestParams,
      withCredentials: true,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
    .then((response) => {
      dispatch({
        type: actionTypes.updateUserState,
        data: { [actionTypes.providerNotesForUser]: _.get(response, 'data', {}) },
      });
      callback(_.get(response, 'data', {}));
    })
    .catch((error) => {
    })
    .finally(() => {
      // always executed
      switchPropertyLoading(dispatch, reducersName.users, actionTypes.providerNotesForUser, false);
    });
};

export const sendNotes = (requestParams) => {
  const modifiedParams = { ...requestParams };
  delete modifiedParams.filterStatus;
  return (dispatch) => {
    dispatch(enableLoading());
    axios
      .post(
        '/sendNotesToUser',
        {
          ...modifiedParams,
        },
        { withCredentials: true },
      )
      .then((response) => {
        dispatch(onSendNotesFound(response.data));
        dispatch(disableLoading());
      })
      .catch((error) => {
        dispatch(disableLoading());
      });
  };
};

export const clearSendNotesInfo = () => (dispatch) => {
  dispatch(onSendNotesFound({}));
};

export const onUserClassInfo = (data) => ({
  type: actionTypes.userClassInfo,
  success: data,
});

export const getUserClassInfo = (data) => (dispatch) => {
  dispatch(enableClassLoading());
  axios({
    method: 'get',
    url: '/getUserClassInfo',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUserClassInfo(response.data));
        dispatch(disableClassLoading());
      }
    })
    .catch((error) => {
      dispatch(disableClassLoading());
    })
    .finally(() => {
      // always executed
    });
};

export const userProfileSuccess = (data) => ({
  type: actionTypes.userBaseProfile,
  success: data,
});

export const userProfile = () => (dispatch) => {
  dispatch(enableProfileLoading());
  axios({
    method: 'get',
    url: '/getUserProfile',
    withCredentials: true,
    params: { expand: 'enrolled_count,sessions_left,user,services,cart' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      const res = { ...response.data, success: true };
      dispatch(userProfileSuccess(res));
      dispatch(disableProfileLoading());
      const ls = new SecureLS();
      ls.set('userInfo', { data: response.data });
    })
    .catch((error) => {
      dispatch(disableProfileLoading());
    });
};

export const userClassSuccess = (data) => ({
  type: actionTypes.userClasses,
  success: data,
});
export const userClassIndividualSuccess = (data) => ({
  type: actionTypes.userClassIndividualSuccess,
  success: data,
});

export const userCampsIndividualSuccess = (data) => ({
  type: actionTypes.userCampsIndividualSuccess,
  success: data,
});

export const userCompsIndividualSuccess = (data) => ({
  type: actionTypes.userCompsIndividualSuccess,
  success: data,
});

export const userEventsIndividualSuccess = (data) => ({
  type: actionTypes.userEventsIndividualSuccess,
  success: data,
});

export const todayClassSuccess = (data) => ({
  type: actionTypes.todayClasses,
  success: data,
});

export const getUserClassesIndividual = (requestParams) => (dispatch) => {
  dispatch(enableClassLoading());

  axios({
    method: 'get',
    url: '/getUserClassesIndividual',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response && response.status && response.status === 200) {
        dispatch(userClassIndividualSuccess(response.data));
        dispatch(disableClassLoading());
      }
    })
    .catch((error) => dispatch(disableClassLoading()));
};

export const getUserCampsIndividual = (requestParams) => (dispatch) => {
  dispatch(enableClassLoading());

  axios({
    method: 'get',
    url: '/getUserClassesIndividual',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response && response.status && response.status === 200) {
        dispatch(userCampsIndividualSuccess(response.data));
        dispatch(disableClassLoading());
      }
    })
    .catch((error) => dispatch(disableClassLoading()));
};

export const getUserCompetitionsIndividual = (requestParams) => (dispatch) => {
  dispatch(enableClassLoading());

  axios({
    method: 'get',
    url: '/getUserClassesIndividual',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response && response.status && response.status === 200) {
        dispatch(userCompsIndividualSuccess(response.data));
        dispatch(disableClassLoading());
      }
    })
    .catch((error) => dispatch(disableClassLoading()));
};

export const getUserEventsIndividual = (requestParams) => (dispatch) => {
  dispatch(enableClassLoading());

  axios({
    method: 'get',
    url: '/getUserClassesIndividual',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response && response.status && response.status === 200) {
        dispatch(userEventsIndividualSuccess(response.data));
        dispatch(disableClassLoading());
      }
    })
    .catch((error) => dispatch(disableClassLoading()));
};

export const getUserClasses = (requestParams) => {
  requestParams.s = Math.random();

  return (dispatch) => {
    dispatch(enableClassLoading());

    axios({
      method: 'get',
      url: '/getUserClasses',
      withCredentials: true,
      params: requestParams,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response && response.status && response.status === 200) {
          if (requestParams.today === 1) {
            dispatch(todayClassSuccess(response.data));
          } else {
            dispatch(userClassSuccess(response.data));
          }
          dispatch(disableClassLoading());
        }
      })
      .catch((error) => dispatch(disableClassLoading()));
  };
};

export const dependentUserSuccess = (data) => ({
  type: actionTypes.dependentUser,
  success: data,
});

export const getDependentUsers = (requestParams) => (dispatch) => {
  dispatch(enableDependentUserLoading());
  axios({
    method: 'get',
    url: '/getDependentUsers',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(dependentUserSuccess(response.data));
        dispatch(disableDependentUserLoading());
      }
    })
    .catch((error) => {
      dispatch(disableDependentUserLoading());
    });
};

export const userPaymentListSuccess = (data) => ({
  type: actionTypes.userPaymentList,
  success: data,
});

export const updateUserProfileInfoSuccess = (data) => ({
  type: actionTypes.updateUserProfileInfo,
  success: data,
});
export const guestUserFlowStep = (data) => ({
  type: actionTypes.guestUserFlowStep,
  success: data,
});
export const guestUserDepententSelect = (data) => ({
  type: actionTypes.guestUserDepententSelect,
  success: data,
});
export const guestUserRouteActive = (data) => ({
  type: actionTypes.guestUserRouteActive,
  success: data,
});

export const updateUserProfileInfo = (requestParams) => (dispatch) => {
  dispatch(updateUserProfile(true));

  axios({
    method: 'post',
    url: '/updateUserProfileInfo',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      dispatch(updateUserProfile(false));
      if (response.status === 200) {
        dispatch(disableLoading());
        const userDetails = { ...response.data, success: true };
        dispatch(updateUserProfileInfoSuccess(userDetails));
      }
    })
    .catch((error) => {
      notification.error({ message: 'User Profile was not updated.' });
      // dispatch(updateUserProfileInfoSuccess(error));
      dispatch(disableLoading());
      dispatch(updateUserProfile(false));
    });
};

export const createDependentUserSuccess = (data) => ({
  type: actionTypes.createDependentUser,
  success: data,
});
export const serviceListSuccess = (data) => ({
  type: actionTypes.serviceList,
  success: data,
});

export const serviceList = (requestParams) => (dispatch) => {
  dispatch(enableServiceListLoading());
  axios({
    method: 'get',
    url: '/services/list',
    withCredentials: true,
    params: requestParams,
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(serviceListSuccess(response.data));
        dispatch(disableServiceListLoading());
      }
    })
    .catch((error) => {
      dispatch(serviceListSuccess(error));
      dispatch(disableServiceListLoading());
    });
};

export const createDependentUser = (requestParams) => (dispatch) => {
  dispatch(enableDependentUserLoading());
  axios({
    method: 'post',
    url: '/createDependentUser',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(createDependentUserSuccess(response.data));
        dispatch(disableDependentUserLoading());
      }
    })
    .catch((error) => {
      dispatch(createDependentUserSuccess(error));
      dispatch(disableDependentUserLoading());
    });
};

export const deleteDependentUserSuccess = (data) => ({
  type: actionTypes.deleteDependentUser,
  success: data,
});

export const deleteDependentUser = (requestParams) => (dispatch) => {
  dispatch(enableDependentUserLoading());
  axios({
    method: 'post',
    url: '/deleteDependentUser',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(deleteDependentUserSuccess(response.data));
        dispatch(disableDependentUserLoading());
      }
    })
    .catch((error) => {
      dispatch(deleteDependentUserSuccess(error));
      dispatch(disableDependentUserLoading());
    });
};

export const updateDependentUserSuccess = (data) => ({
  type: actionTypes.updateDependentUser,
  success: data,
});

export const updateDependentUser = (requestParams) => (dispatch) => {
  dispatch(enableDependentUserLoading());
  axios({
    method: 'post',
    url: '/updateDependentUser',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      dispatch(updateDependentUserSuccess(response.data));
      dispatch(disableDependentUserLoading());
    })
    .catch((error) => {
      dispatch(updateDependentUserSuccess(error));
      dispatch(disableDependentUserLoading());
    });
};

export const onBookingsCreated = (data) => ({
  type: actionTypes.bookingsResponse,
  success: data,

});
export const onBookingsCreatedLoading = (data) => ({
  type: actionTypes.onBookingsCreatedLoading,
  success: data,

});

export const onApplyCoupons = (data) => ({
  type: actionTypes.couponsApplyResponse,
  success: data,

});

export const onCreateBookings = (data, url, callback) => (dispatch) => {
  dispatch(onBookingsCreatedLoading(true));

  axios({
    method: 'post',
    url,
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res && _.get(res, 'status', '') === 200) {
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.success(message_args);
        setTimeout(() => {
          dispatch(onBookingsCreatedLoading(false));
        }, 900);
        callback(res);
      }
    })
    .catch((error) => {
      dispatch(onBookingsCreatedLoading(false));
    });
};

// export const onCampCreateBookings = data => {
//   return dispatch => {
//     axios({
//       method: 'post',
//       url: '/createBookings',
//       withCredentials: true,
//       data: data,
//       config: { headers: { 'Content-Type': 'application/json' } }
//     })
//       .then(res => {
//         let responseData = { ...res.data }
//         responseData.type = 'camps'
//         dispatch(onBookingsCreated(responseData))
//       })
//       .catch(error => {
//         if (
//           error &&
//           error.response &&
//           error.response.data &&
//           error.response.status
//         ) {
//           let responseData = error && error.response && error.response.data;
//           responseData.status =
//             error && error.response && error.response.status;
//           dispatch(onBookingsCreated(responseData));
//         }
//       });
//   };
// };

export const onTrailResponce = (data) => ({
  type: actionTypes.trailBookingResponse,
  success: data,
});

export const onBookTrail = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/createTrail',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      const responseData = res.data;
      responseData.status = res.status;
      dispatch(onTrailResponce(responseData));
    })
    .catch((err) => {
      const responseData = err && err.response && err.response.data;
      responseData.status = err && err.response && err.response.status;
      dispatch(onTrailResponce(responseData));
    });
};
export const enableNotesLoading = () => ({
  type: actionTypes.enableNotesLoading,
  loading: { noteLoading: true },
});

export const disableNotesLoading = () => ({
  type: actionTypes.disableNotesLoading,
  loading: { noteLoading: false },
});

export const getUserNotes = (requestParams) => (dispatch) => {
  dispatch(enableNotesLoading());
  axios
    .get('/notes/list-for-user', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onNotesFound(response.data));
        dispatch(disableNotesLoading());
      }
    })
    .catch((error) => {
      dispatch(disableNotesLoading());
    });
};

export const openUserProfileModal = (event) => ({
  type: actionTypes.openUserProfileModal,
  loading: { isOpenedProfileModal: true },
});

export const closeUserProfileModal = () => ({
  type: actionTypes.closeUserProfileModal,
  loading: { isOpenedProfileModal: false },
});

export const openProfileModal = (event) => ({
  type: actionTypes.openProfileModal,
  loading: { profileModalvalue: false },
});

export const closeProfileModal = () => ({
  type: actionTypes.closeProfileModal,
  loading: { profileModalvalue: true },
});

export const onOtherBookingClass = (data) => ({
  type: actionTypes.otherBookingClass,
  success: data,

});

export const couponsApplyCodeLoading = (data) => ({
  type: actionTypes.couponsApplyCodeLoading,
  loading: data,
});
// Apply Coupons
export const couponsApplyCode = (data, callback) => (dispatch) => {
  dispatch(couponsApplyCodeLoading(true));
  axios({
    method: 'post',
    url: '/discounts/apply',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      const responseData = { ...res.data };
      dispatch(onApplyCoupons(responseData));
      callback(responseData);
      dispatch(couponsApplyCodeLoading(false));
      // const message_args = {
      //   duration: 3,
      //   message: res.data.message
      // }
      // notification.success(message_args)
    })
    .catch((error) => {
      const deleteResponse = _.get(error, 'response.data', {});
      deleteResponse.status = 'error';
      dispatch(onApplyCoupons(deleteResponse));
      dispatch(couponsApplyCodeLoading(false));
      callback();
    });
};
/* Camps Details */

export const enableCampsLoading = () => ({
  type: actionTypes.enableCampsLoading,
  loading: { loading: true },
});
export const enableCompetitionsLoading = () => ({
  type: actionTypes.enableCompetitionsLoading,
  loading: { loading: true },
});

export const disableCampsLoading = () => ({
  type: actionTypes.disableCampsLoading,
  loading: { loading: false },
});
export const disableCompetitionsLoading = () => ({
  type: actionTypes.disableCompetitionsLoading,
  loading: { loading: false },
});
export const userCampsSuccess = (data) => ({
  type: actionTypes.userCamps,
  success: data,
});
export const userCompetitionsSuccess = (data) => ({
  type: actionTypes.userCompetitions,
  success: data,
});

export const userEventsSuccess = (data) => ({
  type: actionTypes.userEvents,
  success: data,
});

export const getUserCamps = (requestParams) => (dispatch) => {
  dispatch(enableCampsLoading());
  axios({
    method: 'get',
    url: '/getUserCamps',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(userCampsSuccess(response.data));
        dispatch(disableCampsLoading());
      }
    })
    .catch((error) => dispatch(disableCampsLoading()));
};

export const getUserCompetitions = (requestParams) => (dispatch) => {
  dispatch(enableCompetitionsLoading());

  axios({
    method: 'get',
    url: '/getUserCompetitions',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(userCompetitionsSuccess(response.data));
      }
      dispatch(disableCompetitionsLoading());
    })
    .catch((error) => dispatch(disableCompetitionsLoading()));
};

export const getUserEvent = (requestParams) => (dispatch) => {
  dispatch(enableCompetitionsLoading());

  axios({
    method: 'get',
    url: '/getUserEvents',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(userEventsSuccess(response.data));
      }
      dispatch(disableCompetitionsLoading());
    })
    .catch((error) => dispatch(disableCompetitionsLoading()));
};

export const onUserCampsInfo = (data) => ({
  type: actionTypes.userCampsInfo,
  success: data,
});
export const onUserCompetitionsInfo = (data) => ({
  type: actionTypes.userCompetitionsInfo,
  success: data,
});

export const onUserEventsInfo = (data) => ({
  type: actionTypes.userEventsInfo,
  success: data,
});

export const getUserCampsInfo = (data) => (dispatch) => {
  dispatch(enableCampsLoading());
  axios({
    method: 'get',
    url: '/getUserCampsInfo',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUserCampsInfo(response.data));
        dispatch(disableCampsLoading());
      }
    })
    .catch((error) => {
      dispatch(disableCampsLoading());
    });
};
export const getUserCompetitionsInfo = (data) => (dispatch) => {
  dispatch(enableCampsLoading());
  axios({
    method: 'get',
    url: '/getUserCompetitionsInfo',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUserCompetitionsInfo(response.data));
        dispatch(disableCampsLoading());
      }
    })
    .catch((error) => {
      dispatch(disableCampsLoading());
    });
};

export const getUserEventsInfo = (data) => (dispatch) => {
  dispatch(enableCampsLoading());
  axios({
    method: 'get',
    url: '/getUserEventsInfo',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUserEventsInfo(response.data));
        dispatch(disableCampsLoading());
      }
    })
    .catch((error) => {
      dispatch(disableCampsLoading());
    });
};

/** Monthly booking compute api */

export const onMonthlyBookingInfo = (data) => ({
  type: actionTypes.monthlyCompute,
  success: data,
});

export const monthlybooking = (requestParams, callback) => (dispatch) => {
  // dispatch(enableDependentUserLoading())
  axios({
    method: 'post',
    url: '/monthlybooking',
    withCredentials: false,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        callback(response.data);
        dispatch(onMonthlyBookingInfo(response.data));

        // dispatch(disableDependentUserLoading())
      }
    })
    .catch((error) => {
      dispatch(onMonthlyBookingInfo(error));
      // dispatch(disableDependentUserLoading())
    });
};

export const sessionsLeftInfo = (data) => ({
  type: actionTypes.sessionLeft,
  success: data,
});

export const sessionsLeft = (requestParams, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/number-sessons-left',
    withCredentials: false,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        callback(response.data);
        dispatch(sessionsLeftInfo(response.data));
      }
    })
    .catch((error) => {
      dispatch(sessionsLeftInfo(error));
    });
};

/** Booking credits  */

export const onBookingCreditsInfo = (data) => ({
  type: actionTypes.bookingCredits,
  success: data,
});

export const bookingCredits = (requestParams) => (dispatch) => {
  axios({
    method: 'post',
    url: '/bookingCredits',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'multipart/form-data' } },
  })
    .then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(onBookingCreditsInfo(response.data));
      }
    })
    .catch((error) => {
      dispatch(onBookingCreditsInfo(error));
    });
};
