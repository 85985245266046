import _ from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const CouponForm = (props = {}) => {
  const { t } = useTranslation();
  return {
    fields: {
      coupon_code: {
        form_item: {
          name: 'code',
          rules: [
            () => ({
              validator(rule, value) {
                if (!_.isEmpty(value) && _.chain(value).size().lte(4).value()) {
                  return Promise.reject('Coupon Character should be greater than 4.');
                } if (!_.isEmpty(value) && _.chain(value).size().gte(50).value()) {
                  return Promise.reject('Coupon Character should not be greater than 8.');
                } if (!_.isEmpty(value) && (!(/^[a-zA-Z0-9]*$/.test(value)))) {
                  return Promise.reject('Coupon should not contain any special character.');
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        element_properties: {
          placeholder: t('couponCodePlace'),
        },
        render_on: {},
        required_on: {},
        label: t('couponCode'),
      },

      coupon_type: {
        form_item: {
          name: 'type',
        },
        element_properties: {
          style: { width: '100%' },
        },
        render_on: {},
        disable_on: { coupon_mode: [_.get(props, 'config.modes.updateCoupon.alias', null)] },
        required_on: {},
        label: 'Coupon Type',
      },

      coupon_valid_from: {
        form_item: {
          name: 'start_date',
          rules: [
            ({ getFieldValue }) => ({
              validator(rule, value) {
                const start_date = value;
                const end_date = getFieldValue('end_date');
                if (!_.isEmpty(start_date) && !_.isEmpty(end_date) && !_.lt(start_date.diff(end_date, 'days'), 1)) {
                  return Promise.reject('Start date should not be greater than the expiry date.');
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        element_properties: {
          disabledDate: (current) => current && _.lt(current.diff(dayjs(), 'days'), 0),
          format: 'MM/DD/YY',
          onChange: () => {
            const validateFields = _.get(props, 'couponBlockFormInstance.validateFields', () => { });
            const getFieldValue = _.get(props, 'couponBlockFormInstance.getFieldValue', () => { });
            if (!_.isEmpty(getFieldValue('end_date'))) {
              validateFields(['end_date']);
            }
          },
        },
        render_on: {},
        required_on: {},
        label: 'Start Date',
      },

      coupon_no_of_dependent: {
        form_item: {
          name: 'no_of_dependents',
        },
        element_properties: {
          className: 'text-center',
          placeholder: 'eg: 1',
          style: { width: '100px' },
          min: 1,
          parser: (value) => {
            value = value.replace(/[^0-9]/g, '');
            if (_.isEmpty(value) || _.toNumber(value) < 1) {
              return 1;
            }
            return value;
          },

        },
        render_on: { coupon_type: [_.get(props, 'config.type.item_based.alias', null)] },
        required_on: {},
        label: 'No.of Dependent(s)',
      },

      coupon_value: {
        form_item: {
          name: 'amount',
        },
        element_properties: {
          className: 'text-center',
          placeholder: 'eg: 15',
          style: { width: '100px' },
          min: 1,
          parser: (value) => {
            value = value.replace(/[^0-9]/g, '');
            if (_.isEmpty(value) || _.toNumber(value) < 1) {
              return 1;
            }
            return value;
          },

        },
        render_on: {},
        disable_on: { coupon_mode: [_.get(props, 'config.modes.updateCoupon.alias', null)] },
        required_on: {},
        label: `Offer in ${_.get(_.get(props, 'LogicalBlock.generalLogic.getCurrentTypeDetails', () => { })(), 'amount_type.symbol', '')}`,
      },

      coupon_value_type: {
        form_item: {
          name: 'amount_type',
        },
      },

      coupon_expiry_date: {
        form_item: {
          name: 'end_date',
          rules: [
            ({ getFieldValue, validateFields }) => ({
              validator(rule, value) {
                const start_date = getFieldValue('start_date');
                const end_date = value;
                if (!_.isEmpty(start_date) && !_.isEmpty(end_date) && _.lt(end_date.diff(start_date, 'days'), 0)) {
                  validateFields(['start_date']);
                  return Promise.reject('Expiry date should not be lesser than the start date.');
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        element_properties: {
          disabledDate: (current) => current && _.lt(current.diff(dayjs(), 'days'), 0),
          format: 'MM/DD/YY',
          onChange: () => {
            const validateFields = _.get(props, 'couponBlockFormInstance.validateFields', () => { });
            const getFieldValue = _.get(props, 'couponBlockFormInstance.getFieldValue', () => { });
            if (!_.isEmpty(getFieldValue('start_date'))) {
              validateFields(['start_date']);
            }
          },
        },
        render_on: {},
        required_on: {},
        label: 'Expiry Date',
      },

      coupon_min_cart_value: {
        form_item: {
          name: 'min_amount',
          rules: [
            ({ getFieldValue }) => ({
              validator(value) {
                const type = getFieldValue('type');
                const rate = getFieldValue('amount');
                const min = getFieldValue('min_amount');
                if (type === 'flate_rate' && min < rate) {
                  return Promise.reject('Minimum amount should not be less than the offered amount.');
                }
                return Promise.resolve();
              },
            }),
          ],
        },
        element_properties: {
          className: 'text-center',
          placeholder: 'eg: 1',
          style: { width: '100px' },
          min: 1,
          parser: (value) => {
            value = value.replace(/[^0-9]/g, '');
            if (_.isEmpty(value) || _.toNumber(value) < 1) {
              return 1;
            }
            return value;
          },

        },
        render_on: {},
        required_on: {},
        label: 'Min cart value',
      },

      coupon_max_cart_value: {
        form_item: {
          name: 'max_amount',
        },
        element_properties: {
          className: 'text-center',
          placeholder: 'eg: 0',
          style: { width: '100px' },
          min: 0,
          parser: (value) => {
            value = value.replace(/[^0-9]/g, '');
            if (_.isEmpty(value) || _.toNumber(value) < 0) {
              return 0;
            }
            return value;
          },

        },
        render_on: { coupon_type: [_.get(props, 'config.type.general_discount.alias', null), _.get(props, 'config.type.item_based.alias', null)] },
        required_on: {},
        label: 'Max discount',
      },

      coupon_max_no_of_avails: {
        form_item: {
          name: 'max_no_avails',
        },
        element_properties: {
          className: 'text-center',
          placeholder: 'eg: 1',
          style: { width: '100px' },
          min: 1,
          parser: (value) => {
            value = value.replace(/[^0-9]/g, '');
            if (_.isEmpty(value) || _.toNumber(value) < 1) {
              return 1;
            }
            return value;
          },

        },
        render_on: {},
        required_on: {},
        label: 'No.of avails per user',
      },

      coupon_description: {
        form_item: {
          name: 'description',
        },
        element_properties: {
          rows: 4,

        },
        render_on: {},
        label: 'Coupon Description',
      },
    },
  };
};
export default CouponForm;
