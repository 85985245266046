import React, { useEffect, useState } from 'react';
import { Upload, Spin, notification } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import _ from 'lodash';
import axios1 from 'axios';

import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../utils/axios';
import * as commonActions from '../../../store/actions/common/uploads';

function Photouploads(props) {
  const [providerProfileImage, setProviderProfileImage] = useState({
    photo: [], deleted: false, canUpload: false, loading: false,
  });
  const [profile_photo_s3_url_processing, set_profile_photo_s3_url_processing] = useState(false);
  const uploadButton = (
    <div>
      {profile_photo_s3_url_processing ? <Spin /> : <AiOutlinePlus style={{ fontSize: 20 }} />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  useEffect(() => {
    if (props.photoUrl) {
      setProviderProfileImage({
        photo: [{
          uid: '1',
          name: 'class',
          status: 'done',
          url: _.get(props, 'photoUrl', ''),
        }],
        deleted: false,
        canUpload: false,
      });
    }
  }, [props.photoUrl, props]);

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    const isSizeValid = file.size / 1024 / 1024 < 10;

    if (!isImage) {
      props.photoValidation(props.t('imgValid'));
      return false;
    }

    if (!isSizeValid) {
      props.photoValidation(props.t('upImageValid'));
      return false;
    }
    props.photoValidation('');
    return true;
  };

  return (
    <div>

      <Upload
        disabled={profile_photo_s3_url_processing}
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        accept="image/*"
        onPreview={(file) => { }}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess('ok');
          }, 0);
        }}
        fileList={_.get(providerProfileImage, 'photo', [])}
        beforeUpload={beforeUpload}
        onRemove={(file) => {
          setProviderProfileImage({ photo: [], deleted: true });
          props.updatePhoto(null);
          if (!props.aiPhoto) {
            props.onDeleteUploads({ url: _.get(providerProfileImage, 'photo[0].url', []) });
          }
        }}
        onChange={(fileList) => {
          const flist = _.get(fileList, 'fileList', []);

          if (!_.isEmpty(flist) && flist.length > 0) {
            set_profile_photo_s3_url_processing(true);
            props.photoLoading(true);
            const file = _.get(flist, '[0].originFileObj', {});
            let file_name = _.get(file, ['name'], 'tutor_profile.png');

            const file_size = _.get(file, 'size', 0);
            const maxSizeInBytes = 1024 * 1024 * 11; // 10MB

            if (file_size > maxSizeInBytes) {
              set_profile_photo_s3_url_processing(false);
              const msg = props.t('fileSizeMsg');
              notification.warning({ message: msg });
              return false;
            }

            const params = {
              cat: `${props.type}`,
              cat_id: 'sessions',
              subcat: props.subCat ? `${props.subCat}` : 'tutor',
              access: 'public',
            };
            setProviderProfileImage({ photo: [], deleted: false });

            file_name = file_name.replace(/[^a-zA-Z0-9\-\.]/g, '-');

            console.log('file_name>>>', file_name);

            axios({
              method: 'post',
              url: '/s3/secure-url',
              withCredentials: true,
              data: { ...params, file_name },
              headers: { 'Content-Type': 'application/json' },
            })
              .then((resp) => {
                if (resp.status === 200) {
                  let url = resp.data;
                  url = url.trim();

                  // file upload directly to s3
                  axios1({
                    method: 'put',
                    url,
                    data: file,
                    headers: {
                      'Content-Type': file.type,
                    },
                  })
                    .then((resp) => {
                      if (resp.status === 200) {
                        const url = _.chain(resp).get('config.url').split('?').first()
                          .value();
                        set_profile_photo_s3_url_processing(false);

                        setProviderProfileImage({
                          photo: [{
                            uid: '1',
                            name: 'classes',
                            status: 'done',
                            url,
                          }],
                          deleted: false,
                          canUpload: false,
                        });
                        props.updatePhoto(url);
                        props.photoLoading(false);
                      }
                    })
                    .catch((error) => {
                      set_profile_photo_s3_url_processing(false);
                      props.photoLoading(false);
                      console.error('Error >> uploading to S3:', error);
                    });
                }
              })
              .catch((error) => {
                set_profile_photo_s3_url_processing(false);
                props.photoLoading(false);
                console.error('Error >> uploading to S3:', error);
              });
          }
        }}
      >
        {_.size(_.get(providerProfileImage, 'photo', [])) > 0 ? null : uploadButton}
      </Upload>
    </div>
  );
}

const mapStateToProps = (state) => ({
  uploadedList: state.uploads,
  loggedInUserDetails: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
  onDeleteUploads: (requestParams) => dispatch(commonActions.onDeleteUploads(requestParams)),
  getUploadedList: (requestParams) => dispatch(commonActions.getUploadedList(requestParams)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Photouploads));
