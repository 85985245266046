import React from 'react'
import queryString from 'query-string'
import parser from 'html-react-parser'
import AiDescriptionGenerator from '../../../../components/common/AiDescriptionGeneratorUpgraded'
import {
  notification,
  Modal,
  Input,
  InputNumber,
  List,
  Button,
  Select,
  DatePicker,
  Form,
  Spin,
} from 'antd'
import { AiOutlineSearch, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { FaCopy } from 'react-icons/fa'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'
import baseProfileFormMeta from '../../edit-profile/my-profile-tab/base-profile-tab/form-meta'
import dayjs from '../../../../utils/day'
import '../../update-camp/update-camp.css'
import { NurtemUtils } from '../../../../utils'
import * as priceactions from '../../../../store/actions/tutor/configurations'
import * as sessionActions from '../../../../store/actions/public/sessions'
import timeDateConverterSession from '../../../../components/timezone/sessionTimeDate'
import axiostoServer from '../../../../utils/axioserver'
import teachingType from '../../../../utils/data/teaching_type'
import learningLevel from '../../../../utils/data/learning_level'
import teachingMode from '../../../../utils/data/teaching_mode'
import eventMode from '../../../../utils/data/event_mode'
import variant from '../../../../utils/data/variant'
import ageGroupsValues from '../../../../utils/data/age_groups_values'
import weekdays from '../../../../utils/data/weekdays'
import paymentCycle from '../../../../utils/data/payment_cycle'
import billingtype from '../../../../utils/data/billingtype'
import TruncatedText from '../../../../components/common/TruncatedText'
import {
  disabledStartDate,
  disabledCompetitionStartDate,
  disabledEndDate,
  disabledCompetitionEndDate,
  disabledCompetitionResultDate,
} from '../../../../utils/common/disable-date'
import {cleanHtmlContent} from '../../../../utils/common/filter_empty_tag'
import ScrollAbout from '../../../../utils/common/scroll-about'
import css from '../../../../utils/CSSUtility'
import Popup from '../../../../components/common/popup'
import {
  ListAntd,
  Tooltip,
  Table,
  FloatButton,
} from '../../../../components/antd-components/index.tsx'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import TimeShedule from './timeShedule'
import Addon from './addon'
import history from '../../../../utils/history';
import * as routeName from '../../../../components/route-names';
const { Option } = Select
const { Item } = List
const fullPath = window.location.pathname;
const pathParts = fullPath.split('/');
const url_name = pathParts[3];


const getRouteName = (type) => {
  return type === 'events'
    ? routeName.eventsView
    : type === 'competitions'
      ? routeName.competitionsView
      : type === 'classes'
        ? routeName.tutorClassView
        : type === 'camps'
          ? routeName.tutorCampView
          : null; // Default case if type doesn't match any known type
};

class TutorUpdateBatch extends React.Component {
  multiCampsRef = React.createRef()
  constructor(props) {
    super(props)
    this.type = props.type //type refers class/camp/competition
    this.initialValues = {
      start_date: null,
      end_date: null,
      // start_time: dayjs
      //   .utc(record.start_time)
      //   .tz(dayjs.tz.guess())
      //   .locale('en'),
      // end_time: dayjs
      //   .utc(record.end_time)
      //   .tz(dayjs.tz.guess())
      //   .locale('en'),
      price: null,
      //session_name: record.session_name,
      //location_taught: record.location_taught,
      teaching_type: null,
      //variant_occurance: variant[0].value,
      selected_days: null,
      billing_type: null,
      payment_cycle_type: null,
      teaching_mode: null,
      age_group: null,
      variant: null,
      no_of_max_students: null,
      tax_percentage: null,
      //mandatory_addons: record.mandatory_addons,
      levels: null,
      description: '',
      //link: record.link,
      ///code: record.code,
    }

    this.state = {
      urlParams: {},
      show_add_btn: true,
      addons: [],
      billing_type: null,
      teaching_type: null,
      action_type: 'create',
      formModified: false,
      draftButton: false,
      draftButton1: false,
      draftButton2: false,
      submitting: true,
      value: 1,
      campFormData: {},
      locationData: [],
      descp_html: '',
      commission: 0,
      update_id: '',
      variant: '',
      variantSelectedDays: [],
      timeSlot: {},
      sessionData: [],
      package_editable: { view: true },
      selected_teching_mode: [],
      name: '',
      formValues: '',
      buttonDisable: false,
      aiLoading: false,
      batchcreated: '',
      servicetext: '',
      rules_html: EditorState.createEmpty(),
      terms_conditions: EditorState.createEmpty(),
      paymemt_cycle: '',
      formMeta: baseProfileFormMeta,
      address: { search_result: [], fetching: false }

      // Function to fetch address

    }
    // Bind the fetchAddress method
    this.fetchAddress = this.fetchAddress.bind(this);
    this.addressDelayedQuery = _.debounce(this.fetchAddress, 1200);
  }
  fetchAddress = (value) => {
    this.setState((prevState) => ({
      address: {
        ...prevState.address,
        search_result: [],
        fetching: true,
      }

    }));

    geocodeByAddress(value)
      .then((body) => {
        const search_result = _.map(body, (location) => ({
          text: location.formatted_address,
        }))
        this.setState((prevState) => ({
          address: {
            ...prevState.address,
            search_result,
            fetching: false,
          }
        }));
      })
      .catch((error) => {
        this.setState((prevState) => ({
          address: {
            ...prevState.address,
            search_result: [],
            fetching: false,
          }
        }));
        console.error(
          'Error while fetching address in event location',
          error
        );
      });
  }
  generateSelectiveJSON = () => {
    const {
      teaching_type,
      billing_type
    } = this.state;
    const teach_type = teaching_type === 'i' ? 'Individual' : 'Group';
    const bill_type = billing_type === 1 ? 'Session' : billing_type === 2 ? 'Monthly' : 'Season'

    const selectiveJSON = {
      bill_type,
      teach_type
    };

    return selectiveJSON;
  };
  async componentDidMount() {
    try {
      const params = queryString.parse(window.location.search)
      this.setState({
        urlParams: params,
      })

      if (
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.location_taught
      ) {
        this.setState({
          locationData: this.props.tutorBaseProfile.location_taught,
        })
      }

      if (params.type === 'add') {
        this.setState({ action_type: 'create', update_id: '' })
      }

      if (params.type && params.type === 'update' && params.id) {
        this.setState({ action_type: 'update', update_id: params.id })
        this.props.getSessionList(
          { group_id: params.id, sort: 'created_at.desc' },
          this.props.type
        )
      }
      this.props.getCommissionPrice()

      if (params.type === 'add') {
        this.setState({ batchcreated: true })
      } else {
        this.setState({ batchcreated: false })
      }

      if (params.type && params.type === 'update' && params.scroll === 'true') {
        ScrollAbout('session_table_list')
      }
      this.setState({ formValues: this.multiCampsRef.current.getFieldsValue() })
      if (this.props.type === 'competitions' || this.props.type === 'events') {
        this.setState({ variant: 'o' })
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error at update class >componentDidMount', error)
    }
  }

  async componentDidUpdate(prevprops) {
    try {
      if (
        this.props.CommissionConfigurations !==
        prevprops.CommissionConfigurations &&
        _.get(this, 'props.CommissionConfigurations.details')
      ) {
        const commission = await this.props.CommissionConfigurations
        if (commission.details && parseFloat(commission.details.value) > 0) {
          this.setState({ commission: parseFloat(commission.details.value) })
        }
      }

      if (
        this.props.sessions &&
        this.props.sessions !== prevprops.sessions &&
        Object.prototype.hasOwnProperty.call(
          this.props.sessions,
          this.state.update_id
        )
      ) {
        this.setState({
          sessionData: this.props.sessions[this.state.update_id],
        })
      }
      let didUpdate
      let campFormData
      if (this.props.type === 'classes') {
        didUpdate =
          prevprops.onClassDetails !== this.props.onClassDetails &&
          this.props.onClassDetails.details
        campFormData = _.cloneDeep(this.props.onClassDetails.details)
      } else if (this.props.type === 'camps') {
        didUpdate =
          prevprops.campDetails !== this.props.campDetails &&
          this.props.campDetails.details
        campFormData = _.cloneDeep(this.props.campDetails.details)
      } else if (this.props.type === 'competitions') {
        didUpdate =
          prevprops.onCompetitionDetails !== this.props.onCompetitionDetails &&
          this.props.onCompetitionDetails.details
        campFormData = _.cloneDeep(this.props.onCompetitionDetails.details)
      } else if (this.props.type === 'events') {
        didUpdate =
          prevprops.onEventDetails !== this.props.onEventDetails &&
          this.props.onEventDetails.details
        campFormData = _.cloneDeep(this.props.onEventDetails.details)
      }
      if (didUpdate) {
        if (campFormData.addons && campFormData.addons.length > 0) {
          this.setState({ addons: campFormData.addons })
        }

        if (
          campFormData.location &&
          campFormData.location.coordinates &&
          campFormData.location.coordinates.length > 0
        ) {
          campFormData.location = {
            lat: campFormData.location.coordinates[1],
            lng: campFormData.location.coordinates[0],
          }
        }

        if (this.props.CommissionConfigurations) {
          const commission = await this.props.CommissionConfigurations
          if (commission.details && parseFloat(commission.details.value) > 0) {
            this.setState({ commission: parseFloat(commission.details.value) })
          }
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error at update class,componentDidUpdate', error)
    }
  }

  handleFormValuesChange = (changedValues, allValues) => {
    delete this.state.formValues.payment_cycle_type
    delete this.state.formValues.selected_days
    delete this.state.formValues.mandatory_addons
    // eslint-disable-next-line no-param-reassign
    delete allValues.mandatory_addons
    delete this.state.formValues.variant_occurance
    let isFormModified
    if (this.state.submitting) {
      isFormModified = !_.isEqual(allValues, this.state.formValues)
    }
    this.setState({
      formModified: isFormModified,
    })
  }
  //timeSlot

  setTimeSlot = (timeslot) => {
    this.setState({
      timeSlot: timeslot,
    })
  }

  getTimeSlot = () => this.state.timeSlot

  onFinishMultiCampsTime = async (value) => {
    try {
      if (Object.keys(this.state.timeSlot).length < 1) {
        ScrollAbout()
        return notification.warning({
          message: 'Sessions time slot is required',
        })
      }
      const { type } = this.state.urlParams
      const data = [...this.state.sessionData]
      const details = { ...value }


      const { start_date, end_date, start_time, end_time, price } = details

      details.time_slot = this.state.timeSlot
      details.variant = this.state.variant
      details.start_date = dayjs(start_date ?? '')
        .startOf('day')
        .toISOString()
      details.end_date = dayjs(end_date ?? '')
        .startOf('day')
        .toISOString()

      // details.start_time = dayjs(start_time ?? '').toISOString()
      // details.end_time = dayjs(end_time ?? '').toISOString()
      const comVal = parseFloat(this.state.commission) ?? 0
      details.price = price + (price * comVal) / 100

      const timezones = this.props.ipapi
        ? this.props.ipapi.timezone
        : dayjs.tz.guess()
      details.timezone = timezones

      //details.description = this.state.descp_html
      details.description = cleanHtmlContent(this.state.descp_html)
      if (this.props.type === 'competitions' || this.props.type === 'events') {
        if (!_.isEmpty(this.state.rules_html)) {
          const rules_data = draftToHtml(
            convertToRaw(this.state.rules_html.getCurrentContent())
          )
          details.rules = cleanHtmlContent(rules_data)
        }
        if (!_.isEmpty(this.state.terms_conditions)) {
          const terms_conditions_data = draftToHtml(
            convertToRaw(this.state.terms_conditions.getCurrentContent())
          )
          details.terms_conditions = cleanHtmlContent(terms_conditions_data)
        }
        details.competition_start_date = dayjs(value.competition_start_date)
          .startOf('day')
          .toISOString()
        if (details.competition_end_date) {
          details.competition_end_date = dayjs(value.competition_end_date)
            .startOf('day')
            .toISOString()
        }
        details.competition_result_date = dayjs(value.competition_result_date)
          .startOf('day')
          .toISOString()
      }
      if (this.props.type === 'events' && details.teaching_mode != 'o') {
        details.location_taught = details.location_taught.value
      }
      const callbackAdd = (resp) => {
        if (resp.status === 200) {
          data.push(resp.data.data)
          this.setState({
            sessionData: data,
          })
          notification.success({
            message: `${details.session_name} added successfully`,
          })
          const params = this.state.urlParams
          const route = getRouteName(this.props.type);
          history.push(`${route}/${encodeURIComponent(url_name)}/?gid=${params.id}`)
        }
      }

      if (type === 'add') {
        data.push(details)
        this.setState(
          {
            formModified: false,
            batchcreated: false,
            sessionData: data,
          },
          () => {
            notification.success({ message: `${details.session_name} added !` })
          }
        )
      } else {
        // update >> add

        let id = _.get(value, 'id', '')
        const sessionAdd = _.get(value, 'session_add', false)

        if (sessionAdd) {
          id = ''
        }

        const providerId =
          this.props.type === 'classes'
            ? _.get(this.props, 'onClassDetails.details.provider.id', '')
            : this.props.type === 'camps'
              ? _.get(this.props, 'campDetails.details.provider.id', '')
              : this.props.type === 'events'
                ? _.get(this.props, 'onEventDetails.details.provider.id', '')
                : _.get(
                  this.props,
                  'onCompetitionDetails.details.provider.id',
                  ''
                )
        if (_.isEmpty(id) || this.state.show_add_btn) {
          const params = this.state.urlParams
          details.group_id = params.id
          delete details.id
          axiostoServer(
            '/add/session',
            {
              type: this.props.type,
              details,
              provider: providerId,
            },
            callbackAdd,
            () =>
              notification.warning({
                message: `${details.session_name} 2 not updated!`,
              })
          )
        } else {
          const callback1 = (resp) => {
            if (resp.status === 200) {
              // remove element other than the given id ans assign to evens
              const evens = _.remove(data, (rec) => rec.id !== id)
              evens.push(details)
              this.setState({
                formModified: false,
                sessionData: evens,
              })
              notification.success({
                message: `${details.session_name} updated successfully`,
              })
              const params = this.state.urlParams
              const route = getRouteName(this.props.type);
              history.push(`${route}/${encodeURIComponent(url_name)}/?gid=${params.id}`)
            }
          }
          axiostoServer(
            '/update/session',
            {
              id,
              type: this.props.type,
              details,
              provider: providerId,
            },
            callback1,
            () =>
              notification.warning({
                message: `${details.session_name} 1 not updated!`,
              })
          )
        }
      }
      this.setState({
        buttonDisable: true,
        submitting: false,
        formModified: false,
      })

      setTimeout(() => {
        this.setState({
          buttonDisable: false,
        })
      }, 1000)
    } catch (error) {
      console.log('error at update class,onFinishMultiCampsTime', error)
    }
  }

  editPackageDetails = async (packageRecord, recordName, type, type2) => {
    try {
      const record = packageRecord
      if (record && !_.isEmpty(record)) {
        if (type === 'edit') {
          this.setState({
            billing_type: record.billing_type,
            selected_teching_mode: record.teaching_mode,
            descp_html: record.description,
            teaching_type: record.teaching_type,
            variant: record.variant,
            variantSelectedDays: record.selected_days,
            servicetext: recordName,
            timeSlot: record.time_slot ? record.time_slot : {},
          })
          if (
            this.props.type === 'competitions' ||
            this.props.type === 'events'
          ) {
            this.setState({ duration_competition: record.duration_competition })
            if (record.rules) {
              const blocksFromHtml = htmlToDraft(record.rules)
              const { contentBlocks, entityMap } = blocksFromHtml
              const contentState2 = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              )
              const rules = EditorState.createWithContent(contentState2)
              this.setState({ rules_html: rules })
            }
            if (record.terms_conditions) {
              const blocksFromHtml = htmlToDraft(record.terms_conditions)
              const { contentBlocks, entityMap } = blocksFromHtml
              const contentState2 = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              )
              const terms_conditions =
                EditorState.createWithContent(contentState2)
              this.setState({ terms_conditions: terms_conditions })
            }
          }

          if (record.selected_days === null) {
            delete record.selected_days
          }

          if (record.description) {
            //details.description = this.state.descp_html
            //
            // const blocksFromHtml = htmlToDraft(record.description)
            // const { contentBlocks, entityMap } = blocksFromHtml
            // const contentState2 = ContentState.createFromBlockArray(
            //   contentBlocks,
            //   entityMap
            // )
            // const descp_html = EditorState.createWithContent(contentState2)
            //this.setState({ descp_html :record.description})
          }

          let fieldValues = {
            start_date: dayjs
              .utc(record.start_date)
              .tz(dayjs.tz.guess())
              .locale('en'),
            end_date: dayjs
              .utc(record.end_date)
              .tz(dayjs.tz.guess())
              .locale('en'),
            // start_time: dayjs
            //   .utc(record.start_time)
            //   .tz(dayjs.tz.guess())
            //   .locale('en'),
            // end_time: dayjs
            //   .utc(record.end_time)
            //   .tz(dayjs.tz.guess())
            //   .locale('en'),
            price: parseFloat(record.price / (1 + this.state.commission / 100)),
            id: record.id,
            session_name: record.session_name,
            location_taught: record.location_taught,
            teaching_type: record.teaching_type,
            variant_occurance: record.variant_occurance,
            selected_days: record.selected_days,
            billing_type: record.billing_type,
            payment_cycle_type: record.payment_cycle_type,
            teaching_mode: record.teaching_mode,
            age_group: record.age_group,
            variant: record.variant,
            no_of_max_students: record.no_of_max_students,
            tax_percentage: record.tax_percentage,
            mandatory_addons: record.mandatory_addons,
            levels: record.levels,
            description: record.description,
            link: record.link,
            code: record.code,
          }
          if (
            this.props.type === 'competitions' ||
            this.props.type === 'events'
          ) {
            if (record.competition_start_date) {
              fieldValues.competition_start_date = dayjs
                .utc(record.competition_start_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.competition_end_date) {
              fieldValues.competition_end_date = dayjs
                .utc(record.competition_end_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.competition_result_date) {
              fieldValues.competition_result_date = dayjs
                .utc(record.competition_result_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.max_no_of_participants) {
              fieldValues.max_no_of_participants = record.max_no_of_participants
            }
            if (record.max_no_of_participants_in_team) {
              fieldValues.max_no_of_participants_in_team =
                record.max_no_of_participants_in_team
            }
            if (record.duration_competition) {
              fieldValues.duration_competition = record.duration_competition
            }
          }
          const recordId = _.get(record, 'id', '')
          if (!_.isEmpty(recordId) && !type2 === 'create') {
            fieldValues.id = recordId
          }
          this.multiCampsRef.current.setFieldsValue(fieldValues)
          this.setState({ formValues: fieldValues })

          if (type2 === 'create') {
            this.onTotggleSwitch(true)
          } else {
            this.onTotggleSwitch(false)
          }
        } else if (type === 'delete') {
          const callbackDelete = (resp) => {
            if (resp.status === 200) {
              const removeArray = [...this.state.sessionData]
              const evens = _.remove(
                removeArray,
                (rec) => rec.id !== _.get(record, 'id', '')
              )
              this.setState({
                sessionData: evens,
              })
              notification.success({
                message: `${recordName} deleted successfully`,
              })
            }
          }

          const handleOk = () => {
            const { typeA } = this.state.urlParams
            if (typeA === 'add') {
              // here we delete local copy in sessionData state
              const res = { status: 200 }
              callbackDelete(res)
            } else {
              axiostoServer(
                '/delete/session',
                {
                  id: record.id,
                  type: this.props.type,
                  provider: _.get(
                    this,
                    'props.onClassDetails.details.provider.id',
                    ''
                  ),
                },
                callbackDelete,
                () =>
                  notification.warning({
                    message: `${recordName} not deleted!`,
                  })
              )
            }
            Modal.destroyAll()
          }

          Modal.confirm({
            keyboard: false,
            maskClosable: false,
            title: 'Are you sure to delete this batch?',
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
              return new Promise(() => {
                handleOk()
              }).catch(() => console.log('Oops errors!'))
            },
          })
        }
      } else if (type === 'cancel') {
        this.multiCampsRef.current.resetFields()
        this.setState({
          descp_html: '',
          formModified: false,
          timeSlot: {},
        })
        this.onTotggleSwitch(true)
      }
    } catch (error) {
      console.log('error at update class,editPackageDetails', error)
    }
  }
  copyPackageDetails = async (packageRecord, recordName, type, type2) => {
    try {
      const record = packageRecord
      if (record && !_.isEmpty(record)) {
        if (type === 'edit') {
          this.setState({
            billing_type: record.billing_type,
            selected_teching_mode: record.teaching_mode,
            descp_html: record.description,
            teaching_type: record.teaching_type,
            variant: record.variant,
            variantSelectedDays: record.selected_days,
            servicetext: recordName,
            timeSlot: record.time_slot ? record.time_slot : {},
          })
          if (
            this.props.type === 'competitions' ||
            this.props.type === 'events'
          ) {
            this.setState({ duration_competition: record.duration_competition })
            if (record.rules) {
              const blocksFromHtml = htmlToDraft(record.rules)
              const { contentBlocks, entityMap } = blocksFromHtml
              const contentState2 = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              )
              const rules = EditorState.createWithContent(contentState2)
              this.setState({ rules_html: rules })
            }
            if (record.terms_conditions) {
              const blocksFromHtml = htmlToDraft(record.terms_conditions)
              const { contentBlocks, entityMap } = blocksFromHtml
              const contentState2 = ContentState.createFromBlockArray(
                contentBlocks,
                entityMap
              )
              const terms_conditions =
                EditorState.createWithContent(contentState2)
              this.setState({ terms_conditions: terms_conditions })
            }
          }

          if (record.selected_days === null) {
            delete record.selected_days
          }

          if (record.description) {
            //details.description = this.state.descp_html
            //
            // const blocksFromHtml = htmlToDraft(record.description)
            // const { contentBlocks, entityMap } = blocksFromHtml
            // const contentState2 = ContentState.createFromBlockArray(
            //   contentBlocks,
            //   entityMap
            // )
            // const descp_html = EditorState.createWithContent(contentState2)
            //this.setState({ descp_html :record.description})
          }

          let fieldValues = {
            start_date: dayjs
              .utc(record.start_date)
              .tz(dayjs.tz.guess())
              .locale('en'),
            end_date: dayjs
              .utc(record.end_date)
              .tz(dayjs.tz.guess())
              .locale('en'),
            // start_time: dayjs
            //   .utc(record.start_time)
            //   .tz(dayjs.tz.guess())
            //   .locale('en'),
            // end_time: dayjs
            //   .utc(record.end_time)
            //   .tz(dayjs.tz.guess())
            //   .locale('en'),
            price: parseFloat(record.price / (1 + this.state.commission / 100)),
            id: record.id,
            session_name: record.session_name + ' ' + 'copy',
            location_taught: record.location_taught,
            teaching_type: record.teaching_type,
            variant_occurance: record.variant_occurance,
            selected_days: record.selected_days,
            billing_type: record.billing_type,
            payment_cycle_type: record.payment_cycle_type,
            teaching_mode: record.teaching_mode,
            age_group: record.age_group,
            variant: record.variant,
            no_of_max_students: record.no_of_max_students,
            tax_percentage: record.tax_percentage,
            mandatory_addons: record.mandatory_addons,
            levels: record.levels,
            description: record.description,
            link: record.link,
            code: record.code,
          }
          if (
            this.props.type === 'competitions' ||
            this.props.type === 'events'
          ) {
            if (record.competition_start_date) {
              fieldValues.competition_start_date = dayjs
                .utc(record.competition_start_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.competition_end_date) {
              fieldValues.competition_end_date = dayjs
                .utc(record.competition_end_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.competition_result_date) {
              fieldValues.competition_result_date = dayjs
                .utc(record.competition_result_date)
                .tz(dayjs.tz.guess())
                .locale('en')
            }
            if (record.max_no_of_participants) {
              fieldValues.max_no_of_participants = record.max_no_of_participants
            }
            if (record.max_no_of_participants_in_team) {
              fieldValues.max_no_of_participants_in_team =
                record.max_no_of_participants_in_team
            }
            if (record.duration_competition) {
              fieldValues.duration_competition = record.duration_competition
            }
          }
          const recordId = _.get(record, 'id', '')
          if (!_.isEmpty(recordId) && !type2 === 'create') {
            fieldValues.id = recordId
          }
          this.multiCampsRef.current.setFieldsValue(fieldValues)
          this.setState({ formValues: fieldValues })

          if (type2 === 'create') {
            this.onTotggleSwitch(true)
          } else {
            this.onTotggleSwitch(false)
          }
        }
      }
    } catch (error) {
      console.log('error at Duplicate class,copyPackageDetails', error)
    }
  }

  packageColumns = [
    {
      title: this.props.t('nameLabel'),
      dataIndex: 'session_name',
      key: 'session_name',
    },
    {
      title: this.props.t('price'),
      dataIndex: 'price',
      key: 'price',
      width: 30,
      render: (price) => _.round(parseFloat(price, 0), 2),
    },
    {
      title: this.props.t('sDate'),
      dataIndex: 'start_date',
      key: 'start_date',
      render: (val) => timeDateConverterSession(val, 'date', 'MMM DD, YYYY'),
    },
    {
      title: this.props.t('eDate'),
      dataIndex: 'end_date',
      key: 'end_date',
      render: (val) => timeDateConverterSession(val, 'date', 'MMM DD, YYYY'),
    },
    // {
    //   title: this.props.t('startTime'),
    //   dataIndex: 'start_time',
    //   key: 'start_time',
    //   render: (val) => timeDateConverterSession(val, 'time', 'hh:mm A'),
    // },
    // {
    //   title: this.props.t('endTime'),
    //   dataIndex: 'end_time',
    //   key: 'end_time',
    //   render: (val) => timeDateConverterSession(val, 'time', 'hh:mm A'),
    // },
    {
      title: this.props.t('descp'),
      dataIndex: 'description',
      key: 'description',
      render: (val) =>
        typeof val === 'string' ? <TruncatedText val={parser(val)} /> : '',
    },
    {
      title: this.props.t('action'),
      dataIndex: '',
      key: 'command_1',
      render: (desc, record) => {
        // eslint-disable-next-line camelcase
        const session_name = _.get(record, 'session_name')
        const sessionId = _.get(record, 'id', 'session_name')
        const studentsAccepted = _.get(
          record.additional_details,
          'no_of_students_accepted'
        )
        const isDisabled =
          this.state.package_editable && !this.state.package_editable.view
        const btnDisable =
          (this.state.package_editable && !this.state.package_editable.view) ||
          studentsAccepted > 0
        return (
          <div
            key={sessionId}
            className="action-icons pt-2"
            style={{ display: 'flex', justifyContent: 'space-evenly' }}
          >
            <Tooltip
              placement="top"
              arrow={false}
              overlayClassName="tooltipForIcon"
              title="Delete"
            >
              <Button
                id={sessionId}
                disabled={btnDisable}
                onClick={() => {
                  const element = document.getElementById(sessionId)
                  element.style.display = 'none'
                  setTimeout(() => {
                    element.style.display = 'block'
                  }, 5000)
                  this.editPackageDetails(record, session_name, 'delete')
                }}
                style={{ border: 0 }}
                icon={
                  <AiOutlineDelete
                    className="faq-buttons-delete"
                    color="#ff5900"
                  />
                }
              />
            </Tooltip>
            {(() => {
              const params = this.state.urlParams
              if (params && params.type === 'update') {
                return (
                  <>
                    <Tooltip
                      placement="top"
                      arrow={false}
                      overlayClassName="tooltipForIcon"
                      title="Edit"
                    >
                      <Button
                        disabled={isDisabled}
                        onClick={() => {
                          this.editPackageDetails(record, session_name, 'edit')
                          ScrollAbout()
                        }}
                        style={{ border: 0 }}
                        icon={
                          <AiOutlineEdit
                            style={{ fontSize: 25, paddingTop: 5 }}
                            className="faq-buttons-edit"
                            color="#52c41a"
                          />
                        }
                      />
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      arrow={true}
                      title={this.props.t('dupBatch')}
                    >
                      <Button
                        disabled={isDisabled}
                        onClick={() => {
                          this.copyPackageDetails(
                            record,
                            session_name,
                            'edit',
                            'create'
                          )
                          ScrollAbout()
                        }}
                        style={{ border: 0 }}
                        icon={
                          <FaCopy
                            style={{ fontSize: 25, paddingTop: 5 }}
                            className="faq-buttons-edit"
                            color="#52c41a"
                          />
                        }
                      />
                    </Tooltip>
                  </>
                )
              }
            })()}
          </div>
        )
      },
    },
  ]

  onTotggleSwitch = (checked = true) => {
    this.setState({ show_add_btn: checked })
  }

  getWeekdayShedule = () => {
    return {
      variant: this.state.variant,
      variantSelectedDays: this.state.variantSelectedDays,
    }
  }

  onEditorStateChange3 = (rules_html) => {
    this.setState({ rules_html })
  }

  onEditorStateChange2 = (terms_conditions) => {
    this.setState({ terms_conditions })
  }

  changeVariant = (variant) => {
    // Mapping between abbreviated and full day names
    const dayMap = {
      su: 'Sunday',
      mo: 'Monday',
      tu: 'Tuesday',
      we: 'Wednesday',
      th: 'Thursday',
      fr: 'Friday',
      sa: 'Saturday',
    }

    // Filter out keys from timeSlot not present in variant
    const newTimeSlot = {}
    variant.forEach((dayAbbrev) => {
      const dayName = dayMap[dayAbbrev]
      if (dayName && this.state.timeSlot.hasOwnProperty(dayName)) {
        newTimeSlot[dayName] = this.state.timeSlot[dayName]
      }
    })

    // Update state with filtered timeSlot
    this.setState({ variantSelectedDays: variant, timeSlot: newTimeSlot })
  }

  saveAsDraft = () => {
    this.setState({ draftButton: true })
    const currentValues = this.multiCampsRef.current.getFieldsValue()
    console.log('currentValues====', currentValues)
    console.log('currentValues1====', this.state.descp_html)
    if (currentValues.price) {
      const comVal = parseFloat(this.state.commission) ?? 0
      currentValues.price =
        currentValues.price + (currentValues.price * comVal) / 100
    }
    if (this.state.descp_html) {
      currentValues.description = this.state.descp_html
    }
    if (this.state.timeSlot) {
      currentValues.time_slot = this.state.timeSlot
    }
    if (this.state.variant) {
      currentValues.variant = this.state.variant
    }
    console.log('currentValues12====', currentValues)

    // Check if draft form data already exists in the local storage
    const draftData = localStorage.getItem('draftFormData')

    if (draftData) {
      this.setState({ draftButton: false })
      // Update existing draft form data
      localStorage.setItem('draftFormData', JSON.stringify(currentValues))
      notification.success({
        message: 'Saved Draft',
      })
    } else {
      this.setState({ draftButton: false })
      // Save new draft form data
      localStorage.setItem('draftFormData', JSON.stringify(currentValues))
      notification.success({
        message: 'Saved Draft',
      })
    }
  }

  useDraft = () => {
    this.setState({ draftButton1: true })
    const record = JSON.parse(localStorage.getItem('draftFormData'))
    console.log('currentValues123====', record)
    console.log('currentValues1234====', localStorage.getItem('draftFormData'))

    if (record) {
      // Set the form fields with the draft data
      this.setState({
        billing_type: record.billing_type,
        selected_teching_mode: record.teaching_mode,
        descp_html: record.description,
        teaching_type: record.teaching_type,
        variant: record.variant,
        variantSelectedDays: record.selected_days,
        // servicetext: recordName,
        timeSlot: record.time_slot ? record.time_slot : {},
      })
      if (this.props.type === 'competitions' || this.props.type === 'events') {
        this.setState({ duration_competition: record.duration_competition })
        if (record.rules) {
          const blocksFromHtml = htmlToDraft(record.rules)
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState2 = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          )
          const rules = EditorState.createWithContent(contentState2)
          this.setState({ rules_html: rules })
        }
        if (record.terms_conditions) {
          const blocksFromHtml = htmlToDraft(record.terms_conditions)
          const { contentBlocks, entityMap } = blocksFromHtml
          const contentState2 = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          )
          const terms_conditions = EditorState.createWithContent(contentState2)
          this.setState({ terms_conditions: terms_conditions })
        }
      }

      if (record.selected_days === null) {
        delete record.selected_days
      }

      // if (record.description) {
      //   record.description = this.state.descp_html

      //   const blocksFromHtml = htmlToDraft(record.description)
      //   const { contentBlocks, entityMap } = blocksFromHtml
      //   const contentState2 = ContentState.createFromBlockArray(
      //     contentBlocks,
      //     entityMap
      //   )
      //   const descp_html = EditorState.createWithContent(contentState2)
      //   this.setState({ descp_html: record.description })
      // }

      let fieldValues = {
        price: parseFloat(record.price / (1 + this.state.commission / 100)),
        id: record.id,
        session_name: record.session_name,
        location_taught: record.location_taught,
        teaching_type: record.teaching_type,
        variant_occurance: record.variant_occurance,
        selected_days: record.selected_days,
        billing_type: record.billing_type,
        payment_cycle_type: record.payment_cycle_type,
        teaching_mode: record.teaching_mode,
        age_group: record.age_group,
        variant: record.variant,
        no_of_max_students: record.no_of_max_students,
        tax_percentage: record.tax_percentage,
        mandatory_addons: record.mandatory_addons,
        levels: record.levels,
        description: record.description,
        link: record.link,
        code: record.code,
      }
      if (record.start_date) {
        fieldValues.start_date = dayjs
          .utc(record.start_date)
          .tz(dayjs.tz.guess())
          .locale('en')
      }
      if (record.end_date) {
        fieldValues.end_date = dayjs
          .utc(record.end_date)
          .tz(dayjs.tz.guess())
          .locale('en')
      }
      if (record.session_name) {
        this.setState({
          servicetext: record.session_name,
        })
      }

      if (this.props.type === 'competitions' || this.props.type === 'events') {
        if (record.competition_start_date) {
          fieldValues.competition_start_date = dayjs
            .utc(record.competition_start_date)
            .tz(dayjs.tz.guess())
            .locale('en')
        }
        if (record.competition_end_date) {
          fieldValues.competition_end_date = dayjs
            .utc(record.competition_end_date)
            .tz(dayjs.tz.guess())
            .locale('en')
        }
        if (record.competition_result_date) {
          fieldValues.competition_result_date = dayjs
            .utc(record.competition_result_date)
            .tz(dayjs.tz.guess())
            .locale('en')
        }
        if (record.max_no_of_participants) {
          fieldValues.max_no_of_participants = record.max_no_of_participants
        }
        if (record.max_no_of_participants_in_team) {
          fieldValues.max_no_of_participants_in_team =
            record.max_no_of_participants_in_team
        }
        if (record.duration_competition) {
          fieldValues.duration_competition = record.duration_competition
        }
      }
      const recordId = _.get(record, 'id', '')
      if (!_.isEmpty(recordId) && !type2 === 'create') {
        fieldValues.id = recordId
      }
      this.multiCampsRef.current.setFieldsValue(fieldValues)
      this.setState({ formValues: fieldValues })
      this.setState({ draftButton1: false })
    }
  }

  clearDraft = () => {
    this.setState({ draftButton2: true })
    // Clear the draft form data from local storage
    localStorage.removeItem('draftFormData')
    this.setState({ draftButton2: false })
  }

  render() {
    const { package_editable } = this.state
    const filterOption = (input, option) => {
      const opt2 = option || {}
      return (opt2.label || '').toLowerCase().includes(input.toLowerCase())
    }

    const nameRegex = /[a-zA-Z]/
    const timeSlotArray = Object.keys(this.state.timeSlot).map((day) => ({
      day,
      start_time: new Date(
        this.state.timeSlot[day].start_time
      ).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
      end_time: new Date(this.state.timeSlot[day].end_time).toLocaleTimeString(
        'en-US',
        { hour: '2-digit', minute: '2-digit' }
      ),
    }))

    // Sort the this.state.sessionData array by created_at in descending order
    const sortedSessions = this.state.sessionData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

    const draftData = localStorage.getItem('draftFormData')
    return (
      <div className="container-fluid">
        <FloatButton.BackTop />
        <div
          id="session_detail"
          className="tutor-add-class-form-container tutor-onboarding-right"
          style={{ height: 'fit-content' }}
        >
          <Form
            key="campsessionform"
            className="full-width"
            scrollToFirstError
            onFinish={(e) => this.onFinishMultiCampsTime(e)}
            onValuesChange={this.handleFormValuesChange}
            ref={this.multiCampsRef}
            initialValues={this.initialValues}
          >

            <div className="row">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div>
                  <Form.Item hidden name="id">
                    <Input type="hidden" />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-center">
                  <span style={css.header}> {this.props.t('batchDetail')}</span>
                </div>
              </div>
            </div>
            <div className="row justify-content-xl-between mb-3">
              <div className=" mt-3 ml-3">
                <div>
                  <Form.Item
                    onChange={(e) =>
                      this.setState({ servicetext: e.target.value })
                    }
                    label={
                      <span style={css.label}>{this.props.t('batName')}</span>
                    }
                    rules={[
                      {
                        required: true,
                        message: this.props.t('batchDetail1'),
                      },
                      {
                        max: 100,
                        message: this.props.t('nameReq1'),
                      },
                      {
                        pattern: nameRegex,
                        message: this.props.t('nameReq2'),
                      },
                    ]}
                    name="session_name"
                    className="full-width"
                  >
                    <Input
                      placeholder={this.props.t('egPlace1')}
                      maxLength={100}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="mt-3">
                <Button
                  onClick={() => this.saveAsDraft()}
                  type="primary"
                  style={{ marginRight: '5px' }}
                  loading={this.state.draftButton}
                >
                  Save as Draft
                </Button>
                {draftData && (
                  <Button
                    onClick={this.useDraft}
                    type="primary"
                    style={{ marginRight: '5px' }}
                    loading={this.state.draftButton1}
                  >
                    Use Draft
                  </Button>
                )}
                {draftData && (
                  <Button
                    onClick={this.clearDraft}
                    type="primary"
                    danger
                    style={{ marginRight: '5px' }}
                    loading={this.state.draftButton2}
                  >
                    Clear Draft
                  </Button>
                )}
              </div>
            </div>
            {/** Schedule Details Starts */}
            <div className="row">
              <div className="col-md-6 mt-3">
                <div style={css.subtitle}>
                  <span>Schedule</span>
                </div>
              </div>
            </div>
            <div className="row">
              {this.props.type !== 'competitions' &&
                this.props.type !== 'events' && (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                    <div>
                      <Form.Item
                        label={
                          <span style={css.label}>
                            {this.props.t('frequency')}
                          </span>
                        }
                        tooltip={this.props.t('frequency1')}
                        name="variant"
                        rules={[
                          {
                            required: true,
                            message: this.props.t('frequency2'),
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          style={{ width: '100%  !important' }}
                          placeholder={this.props.t('selectFreqClass')}
                          onChange={(e) => {
                            this.multiCampsRef.current.setFieldsValue({
                              selected_days: null,
                            })
                            this.setState({ variant: e, timeSlot: {}, variantSelectedDays: [] })
                          }}
                          options={variant}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}

              {(this.props.type === 'competitions' ||
                this.props.type === 'events') && (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                    <Form.Item
                      label={
                        <span style={{ fontWeight: 600 }}>
                          {this.props.type === 'competitions'
                            ? this.props.t('batchDetails8')
                            : this.props.t('batchDetail8')}
                        </span>
                      }
                      tooltip={this.props.t('batchDetails7')}
                      name="duration_competition"
                      rules={[
                        {
                          required: true,
                          message: this.props.t('batchDetails9'),
                        },
                      ]}
                    >
                      <Select
                        showSearch="false"
                        placeholder={this.props.t('selectCompDuration')}
                        onChange={(e) => {
                          this.setState({ duration_competition: e }),
                            this.setState({ variant: 'o7' })
                          this.multiCampsRef.current.setFieldsValue({
                            selected_days: null,
                          })
                        }}
                      >
                        <Option key={1}>{this.props.t('onedayComp')}</Option>
                        <Option key={2}>{this.props.t('multidayComp')}</Option>
                      </Select>
                    </Form.Item>
                  </div>
                )}
              {this.state.variant.startsWith("o") && (
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                  <div>
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('addForm1')}
                        </span>
                      }
                      tooltip={this.props.t('addForm17')}
                      name="selected_days"
                      rules={[
                        {
                          required: this.state.variant.startsWith("o"),
                          message: this.props.t('addForm3'),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        disabled={!this.state.variant.startsWith("o")}
                        placeholder={this.state.duration_competition === '1' ? this.props.t('selectDay') : this.props.t('selectDays')}
                        mode={
                          (
                            this.state.variant.startsWith("o") ||
                            this.props.type === 'competitions' ||
                            this.props.type === 'events') &&
                            this.state.duration_competition === '1'
                            ? undefined
                            : 'multiple'
                        }
                        className="ageGroupOption"
                        style={{ width: `${100}%` }}
                        optionFilterProp="name"
                        filterOption={filterOption}
                        options={weekdays}
                        maxCount={this.state.variant.startsWith("o") ? this.state.variant.slice(-1) : 10}
                        onChange={(e) => {
                          ; (this.props.type === 'competitions' ||
                            this.props.type === 'events') &&
                            this.state.duration_competition === '1'
                            ? this.setState({ variantSelectedDays: [e] })
                            : this.changeVariant(e)
                        }}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                <div>
                  <Form.Item
                    label={
                      <span style={css.label}>
                        {this.props.type === 'competitions' ||
                          this.props.type === 'events'
                          ? this.props.t('bookStart')
                          : this.props.t('sDate')}
                      </span>
                    }
                    tooltip={this.props.t('sDate1')}
                    name="start_date"
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        message: this.props.t('sDate3'),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: `${100}%` }}
                      allowClear
                      placeholder={this.props.t('selectDate')}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabledDate={(current) =>
                        disabledStartDate(
                          current,
                          _.get(this, 'multiCampsRef.current') &&
                          this.multiCampsRef.current.getFieldValue(
                            'start_date'
                          ),
                          _.get(this, 'multiCampsRef.current') &&
                          this.multiCampsRef.current.getFieldValue('end_date')
                        )
                      }
                      format="MM-DD-YYYY"
                      showToday={false}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault() // Prevent form submission
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                <div>
                  <Form.Item
                    label={
                      <span style={css.label}>
                        {this.props.type === 'competitions' ||
                          this.props.type === 'events'
                          ? this.props.t('bookEnd')
                          : this.props.t('eDate')}
                      </span>
                    }
                    tooltip={this.props.t('eDate1')}
                    name="end_date"
                    rules={[
                      {
                        type: 'object',
                        required: true,
                        message: this.props.t('eDate2'),
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: `${100}%` }}
                      allowClear
                      placeholder={this.props.t('selectDate')}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      disabledDate={(current) =>
                        disabledEndDate(
                          current,
                          _.get(this, 'multiCampsRef.current') &&
                          this.multiCampsRef.current.getFieldValue(
                            'start_date'
                          ),
                          this.props.type
                          /*  _.get(this, 'multiCampsRef.current') &&
                           this.multiCampsRef.current.getFieldValue('end_date') */
                        )
                      }
                      format="MM-DD-YYYY"
                      showToday={false}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault() // Prevent form submission
                        }
                      }}
                    />
                  </Form.Item>
                </div>
              </div>

              {(this.props.type === 'competitions' ||
                this.props.type === 'events') && (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                    <Form.Item
                      label={
                        <span style={{ fontWeight: 600 }}>
                          {this.state.duration_competition === '2'
                            ? this.props.type === 'events'
                              ? this.props.t('batchDetail18')
                              : this.props.t('batchDetails18')
                            : this.props.type === 'competitions'
                              ? this.props.t('batchDetails19')
                              : this.props.t('batchDetail19')}
                        </span>
                      }
                      tooltip={this.props.t('batchDetails16')}
                      name="competition_start_date"
                      rules={[
                        {
                          type: 'object',
                          required: true,
                          message: this.props.t('batchDetails13'),
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear
                        placeholder={this.props.t('selectDate')}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabledDate={(current) =>
                          disabledCompetitionStartDate(
                            current,
                            _.get(this, 'multiCampsRef.current') &&
                            this.multiCampsRef.current.getFieldValue(
                              'start_date'
                            ),
                            _.get(this, 'multiCampsRef.current') &&
                            this.multiCampsRef.current.getFieldValue(
                              'end_date'
                            ),
                            (this.props.type === 'events' ? this.props.type : this.state.duration_competition)
                          )
                        }
                        format="MM-DD-YYYY"
                        showToday={false}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault() // Prevent form submission
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                )}

              {this.state.duration_competition === '2' &&
                (this.props.type === 'competitions' ||
                  this.props.type === 'events') && (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                    <Form.Item
                      label={
                        <span style={{ fontWeight: 600 }}>
                          {this.props.type === 'competitions'
                            ? this.props.t('batchDetails17')
                            : this.props.t('batchDetail17')}
                        </span>
                      }
                      tooltip={this.props.t('batchDetails20')}
                      name="competition_end_date"
                      rules={[
                        {
                          type: 'object',
                          required: this.state.duration_competition === '2',
                          message: this.props.t('batchDetails14'),
                        },
                      ]}
                    >
                      <DatePicker
                        allowClear
                        placeholder={this.props.t('selectDate')}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabledDate={(current) =>
                          disabledCompetitionEndDate(
                            current,
                            _.get(this, 'multiCampsRef.current') &&
                            this.multiCampsRef.current.getFieldValue(
                              'competition_start_date'
                            )
                          )
                        }
                        format="MM-DD-YYYY"
                        showToday={false}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault() // Prevent form submission
                          }
                        }}
                      />
                    </Form.Item>
                  </div>
                )}

              {(this.props.type === 'competitions' ||
                this.props.type === 'events') && (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mt-3">
                    {console.log(
                      'start_date===',
                      _.get(this, 'multiCampsRef.current') &&
                      this.multiCampsRef.current.getFieldValue(
                        'competition_start_date'
                      )
                    )}
                    {console.log(
                      'competition_end_date  ===',
                      _.get(this, 'multiCampsRef.current') &&
                      this.multiCampsRef.current.getFieldValue(
                        'competition_end_date'
                      )
                    )}
                    {this.props.type === 'competitions' && (
                      <Form.Item
                        label={
                          <span style={{ fontWeight: 600 }}>
                            {this.props.type === 'competitions'
                              ? this.props.t('batchDetails25')
                              : this.props.t('batchDetail25')}
                          </span>
                        }
                        tooltip={this.props.t('batchDetails26')}
                        name="competition_result_date"
                        rules={[
                          {
                            type: 'object',
                            required: true,
                            message: this.props.t('batchDetails27'),
                          },
                        ]}
                      >
                        <DatePicker
                          allowClear
                          placeholder={this.props.t('selectDate')}
                          getPopupContainer={(trigger) => trigger.parentNode}
                          disabledDate={(current) =>
                            disabledCompetitionResultDate(
                              current.startOf('day'),
                              this.state.duration_competition === '2'
                                ? _.get(this, 'multiCampsRef.current') &&
                                this.multiCampsRef.current.getFieldValue(
                                  'competition_end_date'
                                )
                                : _.get(this, 'multiCampsRef.current') &&
                                this.multiCampsRef.current.getFieldValue(
                                  'competition_start_date'
                                )
                            )
                          }
                          format="MM-DD-YYYY"
                          showToday={false}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault() // Prevent form submission
                            }
                          }}
                        />
                      </Form.Item>
                    )}
                  </div>
                )}
            </div>

            <div className="row">
              <div className="col-md-12 mb-2" id="time_slot">
                <div style={css.subtitle}>
                  <span>Time Slots</span>
                </div>
                {((this.state.variant.startsWith("o") &&
                  this.state.variantSelectedDays &&
                  this.state.variantSelectedDays.length > 0) ||
                  !this.state.variant.startsWith("o")) && (
                    <Popup
                      key={'addTimeSlot'}
                      label="Add/Edit Time Slot"
                      width={800}
                      component={TimeShedule}
                      params={{
                        getTimeSlot: this.getTimeSlot.bind(this),
                        setTimeSlot: this.setTimeSlot.bind(this),
                        getWeekdayShedule: this.getWeekdayShedule.bind(this),
                      }}
                    />
                  )}
              </div>
            </div>
            <div className="col-12">
              <ListAntd
                itemLayout="horizontal"
                grid={{ gutter: 16, xs: 1, sm: 2, md: 2, lg: 2, xl: 4, xxl: 4 }}
                dataSource={timeSlotArray}
                renderItem={(item) => (
                  <Item>
                    <Item.Meta
                      title={item.day}
                      description={`${item.start_time} - ${item.end_time}`}
                    />
                  </Item>
                )}
              />
            </div>
            {/** Schedule Details Ends */}

            {/** Teaching Details Starts */}

            <div className="row">
              <div className="col-md-6 mb-2">
                <div style={css.subtitle}>
                  <span>Teaching Details</span>
                </div>
              </div>
            </div>

            <div className="row">
              {this.props.type !== 'camps' && (
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div>
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.type === 'competitions' ||
                            this.props.type === 'events'
                            ? this.props.t('addForm8a')
                            : this.props.t('addForm8')}
                        </span>
                      }
                      tooltip={this.props.t('addForm8')}
                      name="teaching_type"
                      rules={[
                        {
                          required:
                            this.props.type === 'classes' ? true : false,
                          message: this.props.t('addForm10'),
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder={this.props.t('selectTeachingType')}
                        onChange={(e) => {
                          this.setState({ teaching_type: e })
                        }}
                        options={teachingType}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
              {this.props.type === 'classes' && (
                <>
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <div>
                      <Form.Item
                        label={
                          <span style={css.label}>
                            {this.props.t('addForm11')}
                          </span>
                        }
                        tooltip={this.props.t('addForm12')}
                        name="billing_type"
                        rules={[
                          {
                            required:
                              this.props.type === 'classes' ? true : false,
                            message: this.props.t('addForm13'),
                          },
                        ]}
                      >
                        <Select
                          showSearch="true"
                          placeholder={this.props.t('selectClassType')}
                          onChange={(e) => {
                            this.setState({ billing_type: e })
                          }}
                          options={billingtype}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </div>
                  </div>

                  {parseInt(this.state.billing_type) === 2 && (
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <Form.Item
                        label={
                          <span style={css.label}>
                            {this.props.t('addForm14')}
                          </span>
                        }
                        tooltip={this.props.t('addForm15')}
                        name="payment_cycle_type"
                        rules={[
                          {
                            required:
                              this.props.type === 'classes' ? true : false,
                            message: this.props.t('addForm16'),
                          },
                        ]}
                      >
                        <Select
                          style={{ width: `${100}%` }}
                          showSearch
                          placeholder={this.props.t('selectPayCycle')}
                          fieldNames={{ value: 'value', label: 'label' }}
                          options={paymentCycle}
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={(e) => {
                            this.setState({ paymemt_cycle: e })
                          }}
                        />
                      </Form.Item>
                    </div>
                  )}
                </>
              )}
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <Form.Item
                  label={<span style={css.label}>{(this.props.type === 'events' ? this.props.t('eMode') : this.props.t('tMode'))}</span>}
                  tooltip={(this.props.type === 'events' ? this.props.t('eModeToolTip') : this.props.t('tMode3'))}
                  name="teaching_mode"
                  rules={[
                    {
                      required: true,
                      message: (this.props.type === 'events' ? this.props.t('eModeReq') : this.props.t('tMode1')),
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={(this.props.type === 'events' ? this.props.t('selectEventMode') : this.props.t('selectTeachingMode'))}
                    onChange={(e) => {
                      this.setState({ selected_teching_mode: e })
                    }}
                    options={(this.props.type === 'events' ? eventMode : teachingMode)}
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </div>
            </div>

            <div className="row mb-3">
              {(this.props.type !== 'competitions' && this.props.type !== 'events') && <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div>
                  <Form.Item
                    label={
                      <span style={css.label}>{this.props.t('addForm4')}</span>
                    }
                    tooltip={this.props.t('addForm18')}
                    name="levels"
                    rules={[
                      {
                        required: true,
                        message: this.props.t('addForm6'),
                      },
                    ]}
                  >
                    <Select
                      showSearch="false"
                      placeholder={this.props.t('selectLearningLevel')}
                      onChange={() => { }}
                      options={learningLevel}
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .includes(input.toLowerCase()) ||
                        option.value.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>
              </div>}

              {(this.props.type !== 'events') && (
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div>
                    <Form.Item
                      label={
                        <span style={css.label}>{this.props.t('aGroup')}</span>
                      }
                      tooltip={this.props.t('aGroup1')}
                      name="age_group"
                      rules={[
                        {
                          required: true,
                          message: this.props.t('aGroup2'),
                        },
                      ]}
                    >
                      <Select
                        mode="multiple"
                        showSearch="true"
                        className="ageGroupOption"
                        placeholder={this.props.t('selectAgeGrp')}
                        onChange={() => { }}
                        options={ageGroupsValues}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .includes(input.toLowerCase()) ||
                          option.value.toLowerCase().includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </div>
                </div>)}


              <>
                {this.props.type !== 'competitions' &&
                  this.props.type !== 'events' ? (
                  <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('batchDetails31')}
                        </span>
                      }
                      tooltip={this.props.t('batchDetails45')}
                      name="no_of_max_students"
                      rules={[
                        {
                          required: true,
                          message: this.props.t('batchDetails46'),
                        },
                        {
                          validator: async (rule, value) => {
                            if (
                              this.state.teaching_type !== 'i' &&
                              value > 250
                            ) {
                              throw new Error(this.props.t('batchDetails47'))
                            } else if (
                              this.state.teaching_type === 'i' &&
                              value > 1
                            ) {
                              throw new Error(this.props.t('batchDetails48'))
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        // defaultValue={10}
                        style={{ width: `${100}%` }}
                        min={1}
                        max={this.state.teaching_type === 'i' ? 1 : 251}
                        step={1}
                      />
                    </Form.Item>
                  </div>
                ) : (
                  <>
                    {this.state.teaching_type === 'g' && this.props.type !== 'events' && (
                      <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <Form.Item
                          label={
                            <span style={{ fontWeight: 600 }}>
                              {this.props.t('batchDetails28')}
                            </span>
                          }
                          tooltip={this.props.t('batchDetails29')}
                          name="max_no_of_participants_in_team"
                          rules={[
                            {
                              required: () => this.state.teaching_type === 'g',
                            },
                            {
                              validator: async (rule, value) => {
                                if (
                                  this.state.teaching_type !== 'i' &&
                                  value > 100
                                ) {
                                  throw new Error(
                                    'Seats should be lesser than 100'
                                  )
                                } else if (
                                  this.state.teaching_type === 'i' &&
                                  value > 1
                                ) {
                                  throw new Error('Seats should be 1')
                                }
                              },
                            },
                          ]}
                        >
                          <InputNumber
                            min={1}
                            max={this.state.teaching_type === 'i' ? 1 : ''}
                          />
                        </Form.Item>
                      </div>
                    )}
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <Form.Item
                        label={
                          <span style={{ fontWeight: 600 }}>
                            {this.state.teaching_type === 'g'
                              ? this.props.type === 'events'
                                ? this.props.t('batchDetails31a')
                                : this.props.t('batchDetails30')
                              : this.props.t('batchDetails31a')}
                          </span>
                        }
                        tooltip={this.props.t('batchDetails32')}
                        name="max_no_of_participants"
                        rules={[
                          {
                            required: true,
                            message: this.props.t('batchDetails33'),
                          },
                          {
                            validator: async (rule, value) => {
                              const reg = /^[0-9]+$/
                              if (!reg.test(value)) {
                                throw new Error(
                                  'Only integer values are allowed.'
                                )
                              } else if (value > 250) {
                                throw new Error(
                                  'Seats should be lesser than 250'
                                )
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          min={1}
                          max={250}
                          placeholder={this.props.t('selectNoofSeat')}
                          step={1}
                        />
                      </Form.Item>
                    </div>
                  </>
                )}
              </>

              <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div style={{ width: '73%' }}>
                  <Form.Item
                    label={
                      <span style={css.label}>
                        {this.props.type === 'events'
                          ? this.props.t('batchDetails49a')
                          : this.props.t('batchDetails49')}
                      </span>
                    }
                    name="location_taught"
                    className="full-width"
                    rules={[
                      {
                        required: (this.state.selected_teching_mode === 'i'),
                        message: (this.props.type === 'events' ? this.props.t('event_loc') : this.props.t('batchDetails50')),
                      },
                    ]}
                  >
                    {this.props.type === 'events' ? (
                      <>
                        <Select
                          getPopupContainer={(trigger) => trigger.parentNode}
                          {..._.get(this.state.formMeta.service_provider_address, 'select', {})}
                          onSearch={(search) => this.addressDelayedQuery(search)}
                          notFoundContent={
                            _.get(this.state.address, 'fetching', false) ? (
                              <Spin size="small" />
                            ) : _.get(this.state.address, 'search_result', []).length === 0 ? (
                              <div>No Record Found</div>
                            ) : null
                          }
                          suffixIcon={<AiOutlineSearch />}
                        >
                          {_.map(_.get(this.state.address, 'search_result', []), (d) => (
                            <Option key={_.get(d, 'text', null)}>
                              {_.get(d, 'text', null)}
                            </Option>
                          ))}
                        </Select>
                      </>
                    ) : (
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        style={{ width: '100%' }}
                        showSearch
                        optionFilterProp="children"
                        placeholder={
                          this.state.campFormData.location_text
                            ? this.state.campFormData.location_text
                            : this.props.t('selectLocation')
                        }
                        allowClear
                      >
                        {Array.isArray(this.state.locationData) &&
                          this.state.locationData.map((item) => (
                            <Option key={item.text}>{item.text}</Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div style={css.subtitle}>
                  <span>Additional Details</span>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div>
                  {this.state.selected_teching_mode === 'o' && (
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('meetLink')}
                        </span>
                      }
                      rules={[
                        {
                          required: false,
                        },
                      ]}
                      name="link"
                      className="full-width"
                    >
                      <Input placeholder="Eg: https://zoom.us/j/****" />
                    </Form.Item>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div>
                  {this.state.selected_teching_mode === 'o' && (
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('meetCode')}
                        </span>
                      }
                      rules={[
                        {
                          required: false,
                          message: 'Password for the link.',
                        },
                        {
                          validator: async (rule, value) => {
                            if (value && value.length > 200) {
                              throw new Error(this.props.t('meetCodeValid'))
                            }
                          },
                        },
                      ]}
                      name="code"
                      className="full-width"
                    >
                      <Input style={{ width: `${100}%` }} placeholder="*****" />
                    </Form.Item>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <div style={css.subtitle}>
                  <span>Addon Details</span>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <div>
                  <Form.Item
                    label={
                      <span style={css.label}>{this.props.t('manAddon')}</span>
                    }
                    tooltip="It is a required Additional Things/Material to a task, course"
                    name="mandatory_addons"
                    rules={[
                      {
                        required: false,
                        message: 'Addon is optional.',
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      className="ageGroupOption"
                      placeholder={this.props.t('selectAddon')}
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      disabled={
                        Array.isArray(this.state.addons) &&
                        this.state.addons.length === 0
                      }
                      options={
                        Array.isArray(this.state.addons) &&
                        this.state.addons.length > 0 &&
                        this.state.addons.map((item, key) => ({
                          value: `${item.name}_${key}`,
                          label: `${item.name} - $${item.cost}`,
                        }))
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="col-md-3 d-flex align-items-center justify-content-center">
                <Popup
                  key={'createAddon'}
                  label="Create/Edit Addon"
                  width={1000}
                  //modalContent={() => (<Addon existingAddons={_.get(this.props.onClassDetails, 'details.addons', [])} type={this.type} setState={this.setState.bind(this)}/>)}
                  component={Addon}
                  params={{
                    existingAddons:
                      this.props.type === 'classes'
                        ? _.get(this.props.onClassDetails, 'details.addons', [])
                        : this.props.type === 'camps'
                          ? _.get(this.props.campDetails, 'details.addons', [])
                          : this.props.type === 'events'
                            ? _.get(
                              this.props.onEventDetails,
                              'details.addons',
                              []
                            )
                            : _.get(
                              this.props.onCompetitionDetails,
                              'details.addons',
                              []
                            ),
                    type: this.type,
                    setState: this.setState.bind(this),
                  }}
                />
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 p-3 BoxNew">
              <div className="row">
                <div className="col-md-6 mb-2">
                  <div style={css.subtitle}>
                    <span>Price</span>
                  </div>
                </div>
              </div>
              {console.log(
                'this.payment_cycle_type>>>>>',
                this.state.paymemt_cycle
              )}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div>
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('price')}
                          {this.state.paymemt_cycle === 2 &&
                            ' ( per session in a month ) '}
                        </span>
                      }
                      tooltip="Class price"
                      name="price"
                      rules={[
                        {
                          required: true,
                          message: this.props.t('price2'),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: `${100}%` }}
                        min={0}
                        precision={2}
                        step={0.5}
                        className="text-center"
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div>
                    <Form.Item
                      label={
                        <span style={css.label}>
                          {this.props.t('batchDetails35')}
                        </span>
                      }
                      name="tax_percentage"
                      tooltip={this.props.t('batchDetails36')}
                      rules={[
                        {
                          required: false,
                          message: this.props.t('batchDetails37'),
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: `${100}%` }}
                        min={0}
                        max={100}
                        maxLength={4}
                        formatter={(value) => (value ? `${value}%` : '')}
                        parser={(value) => value.replace('%', '')}
                        className="text-center"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {(this.props.type === 'competitions' ||
                this.props.type === 'events') && (
                  <>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                      <div className="full-width mt-3">
                        <div className="full-width drift-editor">
                          <Form.Item
                            name="rules"
                            label={
                              <span style={{ fontWeight: 600 }}>
                                {this.props.type === 'competitions'
                                  ? this.props.t('batchDetails39')
                                  : this.props.t('eventRules')}
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: this.props.t('compRulesReq'),
                              },
                              {
                                validator: async (rule, value) => {
                                  const descPvalue = draftToHtml(
                                    convertToRaw(
                                      this.state.rules_html.getCurrentContent()
                                    )
                                  )
                                  // Remove HTML tags and whitespace to check if it's effectively empty
                                  const strippedContent = descPvalue
                                    .replace(/<\/?[^>]+(>|$)/g, '')
                                    .trim()
                                  // if (!strippedContent) {
                                  //   throw new Error("Rules Should Not be Empty")
                                  // }
                                },
                              },
                            ]}
                          >
                            <Editor
                              key="rules"
                              editorState={this.state.rules_html}
                              onEditorStateChange={this.onEditorStateChange3}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                      <div className="full-width mt-3">
                        <div className="full-width drift-editor">
                          <Form.Item
                            name="rules"
                            label={
                              <span style={{ fontWeight: 600 }}>
                                {this.props.t('term&cond')}
                              </span>
                            }
                            rules={[
                              {
                                required: false,
                                message: this.props.t('compTermCond'),
                              },
                              {
                                validator: async (rule, value) => {
                                  const descPvalue = draftToHtml(
                                    convertToRaw(
                                      this.state.terms_conditions.getCurrentContent()
                                    )
                                  )
                                  // Remove HTML tags and whitespace to check if it's effectively empty
                                  const strippedContent = descPvalue
                                    .replace(/<\/?[^>]+(>|$)/g, '')
                                    .trim()
                                  // if (!strippedContent) {
                                  //   throw new Error(this.props.t('compTermCond'))
                                  // }
                                },
                              },
                            ]}
                          >
                            <Editor
                              key="terms_conditions"
                              editorState={this.state.terms_conditions}
                              onEditorStateChange={this.onEditorStateChange2}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              <div className="col-12 p-4">
                <div>
                  <AiDescriptionGenerator
                    name={this.generateSelectiveJSON()}
                    servicetext={this.state.servicetext}
                    type={this.props.title}
                    descriptionText={this.state.descp_html}
                    getEditorValue={(descp_html) => {
                      if (this.state.descp_html !== descp_html) {
                        this.setState({ descp_html })
                      }
                    }}
                    loading={(e) => this.setState({ aiLoading: e })}
                    isRequired={false}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-8" />
              <div className=" row col-4 p-0 justify-content-end">
                <div style={{ width: 'max-content', paddingRight: '10px' }}>
                  <Button
                    id="submit-button-session"
                    htmlType="submit"
                    style={css.button}
                    disabled={this.state.buttonDisable || this.state.aiLoading}
                    loading={this.state.aiLoading}
                  >
                    {this.state.urlParams.type === 'add' ||
                      this.state.show_add_btn
                      ? this.props.t('batchDetails42')
                      : this.props.t('batchDetails43')}
                  </Button>
                </div>
                <div style={{ width: 'max-content' }}>
                  {package_editable.view === true && (
                    <Button
                      onClick={() =>
                        this.editPackageDetails(
                          package_editable.data,
                          '',
                          'cancel'
                        )
                      }
                      style={css.cancelButton}
                    >
                      {this.props.t('reset')}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="full-width  nurtem-table mt-3"
              id="session_table_list"
            >
              <Table
                key="sessionlist"
                columns={this.packageColumns}
                dataSource={sortedSessions}
                loading={this.props.sessionsloading}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ipapi: state.apiCall.ipapi,
  searchLocation: state.search.searchLocation,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile.details,
  service: state.services,
  CommissionConfigurations: state.configurations.CommissionConfigurations,
  onClassDetails: state.classesDetails.tutorClassDetails,
  campDetails: state.campsDetails.tutorCampsDetails,
  onCompetitionDetails: state.competitionsDetails.CompetitionDetails,
  onEventDetails: state.eventsDetails.EventDetails,
  detailLoading: state.classesDetails.loading,
  createdData: state.addCamp.campData,
  loading: state.addCamp.loading,
  tutorsList: _.get(state, 'general.tutor.searchTutorList.items', []),
  sessions: state.sessions.sessions,
  sessionsloading: state.sessions.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getCommissionPrice: () => dispatch(priceactions.getCommissionPrice()),
  getSessionList: (requestParams, type) =>
    dispatch(sessionActions.getSessionList(requestParams, type)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorUpdateBatch))
