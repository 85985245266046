import React from 'react';
import MultiStage from './component/multi-stage';

function TutorUpdateClass() {
  return (
    <div className="nurtem-card-design tutor-add-camps-page py-4 mb-3">
      <MultiStage type="classes" label="Class"/>
    </div>
  );
}

export default TutorUpdateClass;
