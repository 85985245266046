import React, { useState, useEffect } from 'react'
import { Button, Tabs, Tooltip, Modal } from 'antd'
import {
  AiOutlineForm,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineShareAlt,
} from 'react-icons/ai'
//import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import { RWebShare } from 'react-web-share'
import queryString from 'query-string'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'
import * as sessionActions from '../../../store/actions/public/sessions'
import './user-new-my-class.css'
import SessionList from './session-list'
import { CLASSPNG } from '../../../utils/config/assets'
import * as action from '../../../store/actions/tutor/camps'
import * as routeName from '../../../components/route-names'
import history from '../../../utils/history'
import FlyerPreview from '../../../components/common/gallery'
import { FullDetailsLoader } from '../../../components/loader/common/full-details-loader'
import ImageWithLoading from '../../../components/tutor/imageOf'
import viewUrl from '../../../utils/common/get-view-url'
import { toggleLeftMenu } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'

const CampDetailedView = (props) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const urlparams = queryString.parse(window.location.search)
  const gid = _.get(urlparams, 'gid')
  const camp_name = props.location.pathname.split('/')[3]
  const provId = _.get(urlparams, 'provId', '')

  function toTutorSessionDetails(record) {
    if (provId) {
      history.push(
        `${routeName.SessionView}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}&provId=${provId}`
      )
    } else {
      history.push(
        `${routeName.SessionView}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}`
      )
    }
  }
  const getSessionList = (id) => {
    if (id) {
      const requestParams = {}
      requestParams.group_id = id
      requestParams.sort = 'created_at.desc'
      props.getSessionList(requestParams, 'camps')
    }
  }

  const { onDeleteClass, onEditClass, tutorCampsDetails } = props

  useEffect(() => {
    props.onGetCampsDetails({
      camp_id: gid,
      expand: 'provider',
    })
    getSessionList(gid)
    props.setToggleMenu(true)
  }, [])

  const render = () => {
    const cd = _.chain(tutorCampsDetails)
    const flyers = cd.get('flyers').value()
    const provider = cd.get('provider').value()
    const viewCampUrl = viewUrl(provider, cd.value(), 'camp')
    const image_back = cd.get('photo').value()

    const UseMatchMedia = (mediaQuery, initialValue) => {
      const [isMatching, setIsMatching] = useState(initialValue)
      useEffect(() => {
        const watcher = window.matchMedia(mediaQuery)
        setIsMatching(watcher.matches)
        const listener = (matches) => {
          setIsMatching(matches.matches)
        }
        if (watcher.addEventListener) {
          watcher.addEventListener('change', listener)
        } else {
          watcher.addEventListener(listener)
        }
        return () => {
          if (watcher.removeEventListener) {
            return watcher.removeEventListener('change', listener)
          }
          return watcher.removeEventListener(listener)
        }
      }, [mediaQuery])
      return isMatching
    }
    // const isMobileResolution = UseMatchMedia('(max-width:991px)', true)
    const isWebResolution = UseMatchMedia('(min-width:992px)', true)
    const handleOnClick = () => {
      if (navigator.share) {
        navigator
          .share({
            title: 'Check out',
            text: 'Check out',
            url: document.location.href,
          })
          .then(() => {
            console.log('Successfully shared')
          })
          .catch((error) => {
            console.error('Something went wrong sharing the blog', error)
          })
      }
    }
    const showModal = () => {
      setOpen(true)
    }
    const handleOk = () => {
      setConfirmLoading(true)
      onDeleteClass(_.get(tutorCampsDetails, 'id'))
        .then(() => {
          setOpen(false)
          setConfirmLoading(false)
        })
        .catch((error) => {
          console.error('Error deleting class:', error)
          setConfirmLoading(false)
        })
    }

    const handleCancel = () => {
      console.log('Clicked cancel button')
      setOpen(false)
    }
    // let profilelink = _.get(tutorCampsDetails, "provider.user_handle");
    return (
      <div className="nurtem-my-class-card m-2">
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
        >
          <items tab={props.t('batchDetail')} key="1">
            <SessionList
              groupID={gid}
              campName={camp_name}
              callback={toTutorSessionDetails}
            />
          </items>
          <items tab={props.t('campDetails')} key="2">
            {props.loadingCampDetails ? (
              <FullDetailsLoader />
            ) : (
              <>
                <div className="full-width">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pr-md-0">
                      <div className="class-name" style={{ paddingLeft: 25 }}>
                        {cd.get('name','').value()}
                      </div>
                      <ImageWithLoading
                        imageUrl={image_back}
                        defaultImageUrl={CLASSPNG}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 pl-md-0 pb-3">
                      <div className="class-header">
                        <div className="row align-items-center pl-3">
                          <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                            {/* <div className='row class-age-group mt-1' style={{ marginLeft: '2px' }}>
                        {_.startCase(cd.get('teaching_mode'))} | <Carousel style={{ marginLeft: '5px' }} speed={100} autoplay>
                          {_.isArray(cd.get('ageGroup_name')) ===
                            true &&
                            cd.get("ageGroup_name").map((age, index) => {
                              return (
                                <div
                                  key={index}
                                  className='h-subtitle'
                                  style={{ fontSize: '13px' }}
                                >
                                  {age}
                                </div>
                              )
                            })}
                        </Carousel>
                      </div> */}
                          </div>
                          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <div className="class-action">
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="View"
                              >
                                <Button
                                  href={viewCampUrl}
                                  target="_blank"
                                  className="delete "
                                  icon={<AiOutlineEye />}
                                />
                              </Tooltip>
                              {isWebResolution ? (
                                <RWebShare
                                  data={{
                                    text: 'Camp details page',
                                    url: window.location.origin + viewCampUrl,
                                    title: 'Share camp details page',
                                  }}
                                  onClick={() =>
                                    console.log('shared successfully!')
                                  }
                                >
                                  <Tooltip
                                    placement="bottom"
                                    arrow={false}
                                    overlayClassName="tooltipForIcon"
                                    title="Share"
                                  >
                                    <Button
                                      className="delete mt-1"
                                      icon={<AiOutlineShareAlt />}
                                    />
                                  </Tooltip>
                                </RWebShare>
                              ) : (
                                <Button
                                  className="delete mt-1"
                                  onClick={handleOnClick}
                                  icon={<AiOutlineShareAlt />}
                                />
                              )}
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Delete"
                              >
                                <Button
                                  // onClick={() => onDeleteClass(_.get(tutorCampsDetails, 'id'))}
                                  onClick={showModal}
                                  className="delete"
                                  icon={<AiOutlineDelete />}
                                />
                              </Tooltip>
                              <Modal
                                title="Are you sure to delete this Camp?"
                                open={open}
                                onOk={handleOk}
                                okType="danger"
                                okText="Yes"
                                confirmLoading={confirmLoading}
                                onCancel={handleCancel}
                                cancelText="No"
                              />
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Edit"
                              >
                                <Button
                                  onClick={() =>
                                    onEditClass(_.get(tutorCampsDetails, 'id'))
                                  }
                                  className="edit"
                                  icon={<AiOutlineForm />}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="class-body"
                        style={{ paddingLeft: 35, marginTop: 10 }}
                      >
                        {/* <Scrollbars
                            className='camp-scroll-height'
                            renderTrackHorizontal={props => (
                              <div
                                {...props}
                                className='track-horizontal'
                                style={{ display: 'none' }}
                              />
                            )}
                            renderThumbHorizontal={props => (
                              <div
                                {...props}
                                className='thumb-horizontal'
                                style={{ display: 'none' }}
                              />
                            )}
                          >
                            {`${_.get(tutorCampsDetails, 'description')}`.replace(regex, '').replace(secondRegEx, '')}
                          </Scrollbars> */}
                        <div>
                          {typeof _.get(tutorCampsDetails, 'description') ===
                          'string'
                            ? parser(_.get(tutorCampsDetails, 'description'))
                            : ''}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='nurtem-my-class-card mt-3 mb-3'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4'>
                      <div className='class-name'>
                        Description
                      </div>
                      <>
                        <div dangerouslySetInnerHTML={{ __html: `${cd.get('description')}` }} />
                      </>
                    </div>
                  </div> */}
              </>
            )}
          </items>
          <items tab={props.t('gallery')} key="3">
            <FlyerPreview flyers={flyers} gid={gid} cat="camps" />
          </items>

          {/* <items tab='Faq' key='3'>
            <Faq session={props} />
          </items> */}

          {/* <items tab='Pay Tution' key='5'>
                  <Payment {...props} />
                </items> */}

          {/* <items tab='Course Material' key='6'>
                  <CourseMaterial {...props} />
                </items> */}

          {/* <items tab='Test / Exam' key='7'>
                  <Exam {...props} />
                 <TestExam />
              </items> */}

          {/* <items tab='Milestone' key='8'>
                  <Milestones {...props} mileStonesList={mileStonesList}/>
                </items>  */}

          {/* <items tab='Prerequisite' key='10'>
                  <Requists {...props} />
                </items>
                */}
        </Tabs>
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  sessions: state.sessions.sessions,
  tutorCampsDetails: state.campsDetails.tutorCampsDetails.details,
  loadingCampDetails: state.campsDetails.loadingCampDetails,
})

const mapDispatchToProps = (dispatch) => ({
  onGetCampsDetails: (requestParams) =>
    dispatch(action.onGetCampsDetails(requestParams)),
  getSessionList: (requestParams, type) =>
    dispatch(sessionActions.getSessionList(requestParams, type)),
  setToggleMenu: (requestParams) =>
    dispatch(searchAction.common(toggleLeftMenu, requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CampDetailedView))
