import React, { useEffect } from 'react';
import { Select } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import * as searchActionType from '../../../../store/actions/public/search/actionTypes';
import * as searchAction from '../../../../store/actions/public/search';
import * as common from '../../../../utils/common';

const { Option } = Select;
const Header = function (props) {
  const pathArr = String(_.get(window.location, 'pathname', '')).split('/');
  const pathname = Array.isArray(pathArr) && _.last(pathArr).replace(/%20/g, ' ');

  useEffect(() => {
    const reqp = {
      ...props.requestParams,
      serviceName: pathname,
      page: 1,
    };

    props.onGetClasseCampList(reqp);
    props.onGetClasseCampList_sponsored(reqp);
    props.onGetClasseCampList_dirListing(reqp);
  }, [pathname]);

  return (
    <div className="page-header-section mt-3 ">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 searchtitle">
          <div style={{ display: 'flex', alignItems: 'center', columnGap: '15px' }}>
            <div className="search-top-title">
              {props.t('searchText')}
              {' '}
              "
              {pathname}
              "
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right sort-main">
          {/* <div className="full-width class-sort">

            <Select
              size="middle"
              defaultValue={props.commonSort ? props.commonSort : ''}
              style={{ width: 170, textAlign: 'left' }}

              onChange={(val) => {
                let rp = {
                  ...props.requestParams,
                  serviceName: pathname,

                  page: 1,
                  sort: val,
                };

                if (!props.headerSearch) {
                  rp = {
                    serviceName: pathname,

                    ...props.requestParams,
                    ...props.requestParamsMore,
                    page: 1,
                    sort: val,
                  };
                }

                // if (!_.isEmpty(params.requestParams.serviceID)) {
                props.onGetClasseCampList(rp);
                props.onGetClasseCampList_sponsored(rp);
                props.onGetClasseCampList_dirListing(rp);
                //                }

                props.onCommonSort(val);
              }}
            >

              <Option value="">{props.t('selectValue')}</Option>
              <Option value="1">{props.t('lowestFee')}</Option>
              <Option value="-1">{props.t('highestFee')}</Option>
            </Select>

          </div> */}
        </div>

      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchType: state.search.searchType,
  commonSort: state.search.commonSort,
  headerSearch: state.search.headerSearch,
  searchServiceID: state.search.serviceID,
  requestParamsMore: common.requestParamsMore(state.search),
  requestParams: common.reqFromHeader(state.search),

});

const mapDispatchToProps = (dispatch) => ({
  onSearchType: (requestParams) => dispatch(searchAction.common(searchActionType.searchType, requestParams)),
  onCommonSort: (commonSortParams) => dispatch(searchAction.common(searchActionType.commonSort, commonSortParams)),
  onGetClasseCampList: (rpGeneral) => dispatch(searchAction.onGetClasseCampList(rpGeneral)),
  onGetClasseCampList_sponsored: (rpSponsored) => dispatch(searchAction.onGetClasseCampList(rpSponsored, searchActionType.sponoredList)),
  onGetClasseCampList_dirListing: (rpDirlist) => dispatch(searchAction.onGetClasseCampList(rpDirlist, searchActionType.dirList)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Header));
