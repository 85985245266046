import _ from 'lodash'
import { notification } from 'antd'
import * as actionTypes from './actionTypes'
import axios from '../../../utils/axios'

export const evaluate = (data, callback = () => { }) => {
  axios({
    method: 'post',
    url: 'tutor/evaluate',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data)
      }
    })
    .catch((error) => {
      const message_args = {
        duration: 2,
        message: _.get(
          error,
          'response.data.message',
          'Something went wrong please try again'
        ),
      }
      notification.error(message_args)
    })
}
export const updateRequestAssign = (data, callback = () => { }) => {
  axios({
    method: 'post',
    url: '/providers/update-assignments',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data);
      }
    })
    .catch((error) => {
      const message_args = {
        duration: 2,
        message: _.get(error, 'response.data.message', 'Something went wrong please try again'),
      };
      notification.error(message_args);
    });
};
export const requestAssign = (data, callback = () => { }) => {
  axios({
    method: 'post',
    url: '/providers/request-assignments',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data);
      }
    })
    .catch((error) => {
      const message_args = {
        duration: 2,
        message: _.get(error, 'response.data.message', 'Something went wrong please try again'),
      };
      notification.error(message_args);
    });
};

export const update_addon = (type, data, callback = () => { }) => {
  let url

  if (type === 'classes') {
    url = '/classes/update-addon'
  } else if (type === 'camps') {
    url = '/camps/update-addon'
  } else if (type === 'competitions') {
    url = '/competitions/update-addon'
  } else if (type === 'events') {
    url = '/events/update-addon'
  }

  axios({
    method: 'post',
    url: url,
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data)
      }
    })
    .catch((error) => {
      console.log('error', error)
    })
}

export const providers_update_service =
  (data, callback = () => { }) =>
    (dispatch) => {
      axios({
        method: 'post',
        url: '/providers/update-services',
        withCredentials: true,
        data,
        config: { headers: { 'Content-Type': 'application/json' } },
      })
        .then((res) => {
          if (res.status === 200) {
            callback(res.data)
          }
        })
        .catch((error) => {
          console.log('error', error)
        })
    }
export const loading = (status = false) => ({
  type: actionTypes.enableLoading,
  loading: status,
})

export const show_element = (status) => ({
  type: actionTypes.show_element,
  payload: status,
})

export const show_group_info = (status) => ({
  type: actionTypes.show_group_info,
  payload: status,
})

export const selectedProvider = (id) => ({
  type: actionTypes.selected_provider,
  payload: id,
})

export const onSessionList = (id) => ({
  type: actionTypes.sessionList,
  payload: id,
})
export const onRequestCount = (id) => ({
  type: actionTypes.requestCount,
  payload: id,
})
export const onSessionListComp = (id) => ({
  type: actionTypes.sessionListComp,
  payload: id,
})

export const onSessionListEvent = (id) => ({
  type: actionTypes.sessionListEvent,
  payload: id,
})

export const onSessionListClass = (id) => ({
  type: actionTypes.sessionListClass,
  payload: id,
})

export const onTotalStudentLists = (id) => ({
  type: actionTypes.TotalStudentList,
  payload: id,
})
export const onTotalStudentListsCamp = (id) => ({
  type: actionTypes.TotalStudentListCamp,
  payload: id,
})
export const onTotalStudentListsComp = (id) => ({
  type: actionTypes.TotalStudentListComp,
  payload: id,
})
export const onTotalsubTransLists = (data) => ({
  type: actionTypes.TotalSubsTransList,
  success: data,
})
export const onRequestAssignment = (data) => ({
  type: actionTypes.RequestAssignment,
  success: data,
})
export const enableClassSessionsLoading = (id) => ({
  type: actionTypes.enableClassSessionsLoading,
  sessionsLoading: { sessionsLoading: true },
})

export const disableClassSessionsLoading = (id) => ({
  type: actionTypes.disableClassSessionsLoading,
  sessionsLoading: { sessionsLoading: false },
})

export const enableTotalStudentListLoading = (id) => ({
  type: actionTypes.enableTotalStudentListLoading,
  TotalStudentListLoading: { TotalStudentListLoading: true },
})

export const disableTotalStudentListLoading = (id) => ({
  type: actionTypes.disableTotalStudentListLoading,
  TotalStudentListLoading: { TotalStudentListLoading: false },
})
export const enableTotalStudentListLoadingCamp = (id) => ({
  type: actionTypes.enableTotalStudentListLoadingCamp,
  TotalStudentListLoading: { TotalStudentListLoading: true },
})

export const disableTotalStudentListLoadingCamp = (id) => ({
  type: actionTypes.disableTotalStudentListLoadingCamp,
  TotalStudentListLoading: { TotalStudentListLoading: false },
})
export const enableTotalStudentListLoadingComp = (id) => ({
  type: actionTypes.enableTotalStudentListLoadingComp,
  TotalStudentListLoading: { TotalStudentListLoading: true },
})

export const disableTotalStudentListLoadingComp = (id) => ({
  type: actionTypes.disableTotalStudentListLoadingComp,
  TotalStudentListLoading: { TotalStudentListLoading: false },
})

export const enroll =
  (data, callback = () => { }) =>
    (dispatch) => {
      // dispatch(addCampEnableLoading());
      axios({
        method: 'post',
        url: 'student/enroll',
        withCredentials: true,
        data,
        config: { headers: { 'Content-Type': 'application/json' } },
      })
        .then((res) => {
          if (res.status === 200) {
            callback(res.data)
          }
        })
        .catch((error) => {
          const message_args = {
            duration: 2,
            message: _.get(
              error,
              'response.data.message',
              'Something went wrong please try again'
            ),
          }
          notification.error(message_args)
        })
    }

export const onGetsessionList = (data, type) => (dispatch) => {
  axios({
    method: 'get',
    url: '/session/list',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onSessionList(requestParams))
      }
    })
    .catch((error) => console.log('error', error))
}
export const onGetRequestAssignment = (data, type) => (dispatch) => {
  axios({
    method: 'get',
    url: '/providers/get-req-assignment',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onRequestAssignment(requestParams))
      }
    })
    .catch((error) => console.log('error', error))
}

export const onTotalStudentList = (data, type) => (dispatch) => {
  dispatch(enableTotalStudentListLoading())
  axios({
    method: 'get',
    url: '/providers/total-student-list',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      dispatch(disableTotalStudentListLoading())
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onTotalStudentLists(requestParams))
      }
    })
    .catch((error) => {
      console.log('error in total stu list', error)
      dispatch(disableTotalStudentListLoading())
    })
}
export const onTotalStudentListCamp = (data, type) => (dispatch) => {
  dispatch(enableTotalStudentListLoadingCamp())
  axios({
    method: 'get',
    url: '/providers/total-student-list',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      dispatch(disableTotalStudentListLoadingCamp())
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onTotalStudentListsCamp(requestParams))
      }
    })
    .catch((error) => {
      console.log('error in total stu list', error)
      dispatch(disableTotalStudentListLoadingCamp())
    })
}
export const onTotalStudentListComp = (data, type) => (dispatch) => {
  dispatch(enableTotalStudentListLoadingComp())
  axios({
    method: 'get',
    url: '/providers/total-student-list',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      dispatch(disableTotalStudentListLoadingComp())
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onTotalStudentListsComp(requestParams))
      }
    })
    .catch((error) => {
      console.log('error in total stu list', error)
      dispatch(disableTotalStudentListLoadingComp())
    })
}
export const onSubscriptionTransactionList = (data, type) => (dispatch) => {
  //console.log('came in wow>>>>>>>', data)
  axios({
    method: 'get',
    url: '/providers/subscription-transaction-list?' + Math.random(),
    params: data,
    withCredentials: true,
    config: {
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    },
  })
    .then((response) => {
      if (response.status === 200) {
        //console.log('response>>>>>>>>list', response.data)
        const requestParams = { ...response.data }
        dispatch(onTotalsubTransLists(requestParams))
      }
    })
    .catch((error) => {
      console.log('error in total Subscrib Transaction list', error)
    })
}

export const onGetRequestCount = (data, type) => (dispatch) => {
  axios({
    method: 'get',
    url: '/providers/request-count',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        // console.log('notificationCount12=======', response.data.data)
        const requestParams = _.get(response, 'data.data', 0)
        dispatch(onRequestCount(requestParams))
      }
    })
    .catch((error) => console.log('error', error))
}

export const onGetsessionListComp = (data, type) => (dispatch) => {
  axios({
    method: 'get',
    url: '/session/listForCompetition',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onSessionListComp(requestParams))
      }
    })
    .catch((error) => console.log('error', error))
}

export const onGetsessionListEvents = (data, type) => (dispatch) => {
  axios({
    method: 'get',
    url: '/session/listForEvents',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onSessionListEvent(requestParams))
      }
    })
    .catch((error) => console.log('error', error))
}

/* Class session list for provider */
export const onGetsessionListClass = (data, type) => (dispatch) => {
  dispatch(enableClassSessionsLoading())
  axios({
    method: 'get',
    url: '/session/listclass',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data }
        dispatch(onSessionListClass(requestParams))
      }
      dispatch(disableClassSessionsLoading())
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(disableClassSessionsLoading())
    })
}

const setPaymentReport = (data) => ({
  type: actionTypes.paymentReport,
  success: data,
})
const paymentReportEnableLoading = () => ({
  type: actionTypes.EnablepaymentReportLoading,
  loading: { loading: true },
})
const paymentReportDisableLoading = () => ({
  type: actionTypes.DisablepaymentReportLoading,
  loading: { loading: false },
})

export const paymentReport = (requestParams) => {
  console.log('userreport1========', requestParams)
  return (dispatch) => {
    dispatch(paymentReportEnableLoading())
    axios({
      method: 'get',
      url: 'common/payment-report',
      withCredentials: true,
      params: requestParams,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((res) => {
        console.log('userreport2=========', res)
        if (res.status === 200) {
          dispatch(setPaymentReport(res.data))
          dispatch(paymentReportDisableLoading())
        }
      })
      .catch((error) => {
        dispatch(paymentReportDisableLoading())
      })
  }
}
