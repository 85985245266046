import * as actionTypes from '../../actions/public/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  recentchatlistuser: {},
  addinguser: {},
  updateMessage: {},
  createMessage: {},
  individualMessages: {},
  providerListForUser: {},
  adduserLoading: false,
};

const onGetRecentMessages = (state, action) => ({ ...state, recentchatlistuser: action.success });
const onAddingUser = (state, action) => ({ ...state, addinguser: action.success });
const updateMessage = (state, action) => ({ ...state, updateMessage: action.success });
const createMessage = (state, action) => ({ ...state, createMessage: action.success });
const onGetIndividualMessages = (state, action) => ({ ...state, individualMessages: action.success });
const getProviderList = (state, action) => ({ ...state, providerListForUser: action.success });
const disableAddUserLoading = (state, action) => updateObject(state, action.adduserLoading);
const enableAddUserLoading = (state, action) => updateObject(state, action.adduserLoading);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.recentchatlistuser:
      return onGetRecentMessages(state, action);
    case actionTypes.addinguser:
      return onAddingUser(state, action);
    case actionTypes.disableAddUserLoading:
      return disableAddUserLoading(state, action);
    case actionTypes.enableAddUserLoading:
      return enableAddUserLoading(state, action);
    case actionTypes.updateMessage:
      return updateMessage(state, action);
    case actionTypes.createMessage:
      return createMessage(state, action);
    case actionTypes.individualMessages:
      return onGetIndividualMessages(state, action);
    case actionTypes.providerListForUser:
      return getProviderList(state, action);
    default:
      return state;
  }
};
export default reducer;
