import React, { FC, ChangeEventHandler } from 'react'
import { Input } from 'antd'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

interface CommonAntdPasswordInputProps {
  type?: string
  placeholder?: string
  id?: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  autoComplete?: string
  maxLength?: number
  size?: 'large' | 'middle' | 'small'
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  addonBefore?: string
}

export const CommonAntdPasswordInput: FC<CommonAntdPasswordInputProps> = ({
  type,
  placeholder,
  id,
  onChange,
  autoComplete,
  maxLength,
  prefix,
  suffix,
  addonBefore,
}) => {
  return (
    <Input.Password
      type={type}
      placeholder={placeholder}
      id={id}
      iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
      onChange={onChange}
      autoComplete={autoComplete}
      maxLength={maxLength}
      suffix={suffix}
      prefix={prefix}
      addonBefore={addonBefore}
    />
  )
}
