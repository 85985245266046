const AgeGroupValues = [
  { value: '0,1', label: 'Infant < 1 year' },
  { value: '1,2', label: 'Toddler ( 1 - 2 years)' },
  { value: '2,5', label: 'Preschooler (ages 2–5 years)' },
  { value: '1,5', label: 'Elementary (Grade K-5)' },
  { value: '6,8', label: 'Middle School (Grade 6 -8)' },
  { value: '9,12', label: 'High School (Grade 9-12)' },
  { value: '13,17', label: 'College (Age 13 or older)' },
  { value: '18,100', label: 'Adult' },
];
export default AgeGroupValues;
