import React from 'react'
import './message.css'
import { Select, Input } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
// import timeConverterMessage from "../../../components/timezone/timeConverterMessage";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import * as msgAction from '../../../store/actions/public/message'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import { DEFAULTUSER } from '../../../utils/config/assets'
import css from '../../../utils/CSSUtility'

dayjs.extend(utc)
dayjs.extend(timezone)
class StudentMessage extends React.Component {
  constructor(props) {
    super(props)
    console.log("PropsMessage>>>>>>>>>>", props)
    this.state = {
      chat: {},
      newchat: {},
      message: '',
      file: null,
      newprovider: [],
      providername: {},
      searchValue: '',
      selectedChat: null,
      selectedChatGroup: ""
    }
    this.chatBoxRef = React.createRef()
  }

  componentDidMount() {
    try {
      // ipapi = _.get(this, 'props.searchLocation')
      window.scrollTo({ top: 0, behavior: 'smooth' })
      let requestParams
      requestParams = {
        user: this.props.userBaseProfile.details.id,
        //sort: 'updated_at.desc',
      }
      this.props.onGetRecentMessages(requestParams)

      let requestParamsProvider
      this.props.userBaseProfile.details.user.account_type === 'self'
        ? (requestParamsProvider = {
          user: this.props.userBaseProfile.details.id,
          //sort: 'updated_at.desc',
        })
        : (requestParamsProvider = {
          dependent_user: this.props.userBaseProfile.details.id,
          sort: 'updated_at.desc',
        })
      this.props.onProviderList(requestParamsProvider)
    } catch (error) {
      console.log('error at user messages,componentDidMount', error)
    }
  }

  scrollToBottom() {
    try {
      if (this.chatBoxRef && this.chatBoxRef.current) {
        const scroll_height = _.get(
          this.chatBoxRef,
          'current.scrollHeight',
          100
        )
        this.chatBoxRef.current.scrollTop = scroll_height
      }
    } catch (error) {
      console.log('error at user messages,scrollToBottom', error)
    }
  }

  componentDidUpdate(prevProps) {
    try {
      // const scroll_height =
      //   this.chatBoxRef && _.get(this.chatBoxRef, 'current.scrollHeight', 100)

      // // Scroll to the bottom of the chat box when new messages are added
      // if (prevProps.messages !== this.props.messages) {
      //   this.scrollToBottom()
      //   const chatBox = this.chatBoxRef.current

      //   chatBox.scrollTo(0, scroll_height)
      // }
      // if (prevProps.updateMessage !== this.props.updateMessage) {
      //   if (this.props.updateMessage.status === 200) {
      //     this.setState({ message: '' })
      //     setTimeout(() => {
      //       if (
      //         this.chatBoxRef &&
      //         this.chatBoxRef.current &&
      //         this.chatBoxRef.current.scrollTop
      //       ) {
      //         this.chatBoxRef.current.scrollTop = scroll_height
      //       }
      //     }, 2000)
      //   }
      // }
      // if (prevProps.createMessage !== this.props.createMessage) {
      //   if (this.props.createMessage.status === 200) {
      //     this.setState({ message: '' })
      //     setTimeout(() => {
      //       if (
      //         this.chatBoxRef &&
      //         this.chatBoxRef.current &&
      //         this.chatBoxRef.current.scrollTop
      //       ) {
      //         this.chatBoxRef.current.scrollTop = scroll_height
      //       }
      //     }, 2000)
      //   }
      // }
      // if (
      //   prevProps.individualMessages.items &&
      //   prevProps.individualMessages.items.length !==
      //   this.props.individualMessages.items.length
      // ) {
      //   setTimeout(() => {
      //     if (
      //       this.chatBoxRef &&
      //       this.chatBoxRef.current &&
      //       this.chatBoxRef.current.scrollTop
      //     ) {
      //       this.chatBoxRef.current.scrollTop = scroll_height
      //     }
      //   }, 2000)
      // }
    } catch (error) {
      console.log('error at tutor messages,componentDidUpdate', error)
    }
  }

  ontutorSelect = (value, option) => {
    try {
      const selectedTutor = JSON.parse(value)
      const requestParams = {
        user: this.props.userBaseProfile.details.id,
        provider: selectedTutor.id,
        // sort: 'updated_at.desc',
        // limit:10
      }
      if (selectedTutor.id && selectedTutor.name) {
        this.props.onIndividualMessages(requestParams)
      }
      this.setState({ providername: selectedTutor })
      this.setState({ chat: null })
      setTimeout(() => {
        const scroll_height = _.get(
          this.chatBoxRef,
          'current.scrollHeight',
          100
        )

        if (
          this.chatBoxRef &&
          this.chatBoxRef.current &&
          this.chatBoxRef.current.scrollTop
        ) {
          this.chatBoxRef.current.scrollTop = scroll_height
          window.scrollTo({ top: 200, behavior: 'smooth' })
        }
      }, 1000)
    } catch (error) {
      console.log('error at tutor messages,ontutorSelect', error)
    }
  }

  searchrecentmsg = (key, rList) => {
    const selectedList = rList[key];
    this.thanimsg(key, selectedList)
  };



  thanimsg = (key, value) => {
    try {

      this.setState({ chatList: value });
      this.setState({ selectedChatGroup: key });
    } catch (error) {
      console.log('error at tutor messages,thanimsg', error)
    }
  }

  // ipapi = ipapi.timezone ? ipapi.timezone : dayjs.tz.guess()
  handleSendMessage = (e, rList) => {
    e.preventDefault();
    try {
      if (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey) {
        // if any modifier key is pressed, ignore the keypress
        return
      }

      const { message, selectedChatGroup, selectedChat } = this.state;
      const selectedList = selectedChatGroup ? rList[selectedChatGroup] : rList[rList.length - 1];
      const selectedChatData = selectedChat ? selectedChat : selectedList[selectedList.length - 1];

      if (message.trim() !== '') {
        const requestParams = {
          id: selectedChatData.id,
          my_id: this.props.userBaseProfile.details.id,
          name: this.props.userBaseProfile.details.name,
          type: 'user',
          text: message,
        };

        this.props.onUpdateMessages(requestParams);
        this.setState({ message: '' })

        setTimeout(() => {
          const recent = this.props.recentchatlistuser
          const resentItemsCount = _.get(recent, 'items', []).length;
          const resentList = resentItemsCount > 0 ? this.filteredAndGroupedData(recent.items) : {}
          this.searchrecentmsg(selectedChatGroup, resentList)
        }, 500)

      }

    } catch (error) {
      console.log('error at tutor messages,handleSendMessage', error)
    }
  }

  handleMessageChange = (event) => {
    try {
      this.setState({ message: event.target.value })
    } catch (error) {
      console.log('error at tutor messages,handleMessageChange', error)
    }
  }



  filteredAndGroupedIndivData = (data) => data.reduce((acc, item) => {
    const { name, msg_type } = _.get(item, 'provider', {});
    if (msg_type == "i") {
      if (!acc[name]) {
        acc[name] = [];
      }

      acc[name].push(item);

    }
    return acc;

  }, {});

  filteredAndGroupedData = (data) => data.reduce((acc, item) => {
    const { name } = _.get(item, 'provider', {});

    if (!acc[name]) {
      acc[name] = [];
    }

    acc[name].push(item);

    return acc;
  }, {});


  makeMessage = (msg_type, userid, msgs) => {

    console.log("msgs>>>", msgs)
    console.log("msgs>>>userid", userid)

    return msgs.map((msg, key) => {

      const timeConvert = timeDateConverterSession(
        msg.created_at,
        'date',
        'MMM DD,YYYY h:mm A'
      )
      const msgTime = dayjs(timeConvert)
      const currentTime = msgTime.format('hh:mm A')
      const currentDate = msgTime.format('MMM DD, YYYY')
      const displayDate = currentDate


      return msg.userid === userid ? (
        <div key={key} className="chat-message-right pt-4">
          <div>
            <img
              src={msg.photo ? msg.photo : DEFAULTUSER}
              className="rounded-circle mr-1"
              alt="User Img"
              width="40"
              height="40"
            />
          </div>
          <div className="media-body chat-message-right-inside mr-3">
            <div className="flex-shrink-1 mf-content-right bg-light py-2 px-3 mr-3">
              {' '}
              {msg.text}
            </div>
            <small className="mf-date-right mr-3">
              <i className="ntm-clock mr-1" />
              {currentTime}
              {displayDate && ` | ${displayDate}`}
            </small>
          </div>
        </div>
      ) : (
        <div key={key} className="chat-message-left pt-4">
          <div>
            <img
              src={msg.photo ? msg.photo : DEFAULTUSER}
              className="rounded-circle mr-1"
              alt="Tutor Img"
              width="40"
              height="40"
            />
          </div>
          <div className="media-body ml-3">
            {msg_type === 'g' ? (
              <div className="sender-name">
                <div className="ml-2">{msg.name}</div>
              </div>
            ) : (
              ''
            )}
            <div className="mf-content">{msg.text}</div>
            <small className="mf-date mr-3">
              <i className="ntm-clock mr-1" />
              {currentTime}
              {displayDate && ` | ${displayDate}`}
            </small>
          </div>
        </div>
      )
    })
  }
  render() {

    const user = this.props.userBaseProfile.details
    const recent = this.props.recentchatlistuser
    const { selectedChatGroup, chatList } = this.state
    const resentItemsCount = _.get(recent, 'items', []).length;
    const resentList = resentItemsCount > 0 ? this.filteredAndGroupedData(recent.items) : {}
    const resentListIndividual = resentItemsCount > 0 ? this.filteredAndGroupedIndivData(recent.items) : {}

    const resentListKeys = Object.keys(resentList);
    const resentListKeysIndiv = Object.keys(resentListIndividual);

    return (
      <div className="nurtem-card-design p-0 mb-4 messgbox">
        <div className="row pl-2">
          <div className="col-12 col-lg-5 col-xl-3 pr-0 nurtem-card-design-message border-right">

            <div style={css.heading1}>{"Tutor"}</div>
            <div className="px-4 mt-3 mb-3 d-none d-md-block">
              <div className="search-recent">
                <Select
                  showSearch
                  placeholder="Select / Search a Tutor Name"
                  onSelect={(key) => this.searchrecentmsg(key, resentList)}
                >
                  {
                    _.size(resentListIndividual) > 0 &&
                    resentListKeysIndiv.map((key) => {
                      return (
                        <Select.Option value={key} key={key}>
                          {key}
                        </Select.Option>
                      )
                    })
                  }
                </Select>

              </div>
            </div>

            <div style={css.heading1}>{this.props.t('recent')}</div>
            <div className="px-4 mt-3 mb-3 d-none d-md-block">
              <div className="search-recent">
                <Select
                  showSearch
                  placeholder={"Search Tutors"}
                  onSelect={(key) => this.searchrecentmsg(key, resentList)}
                >
                  {
                    _.size(resentList) > 0 &&
                    resentListKeys.map((key) => {
                      return (
                        <Select.Option value={key} key={key}>
                          {key}
                        </Select.Option>
                      )
                    })}
                </Select>

              </div>
            </div>
            <div className="chat-messages-left">
              {_.size(resentList) > 0 && resentListKeys.map((key) => {
                const selectedList = resentList[key];
                const value = selectedList[0];
                const isActive = selectedChatGroup === key;
                const readCount = _.sumBy(selectedList, (o) => o.provider.read_count);
                const msgCount = resentItemsCount > 0 ? resentItemsCount - readCount : 0;
                const msgType = _.get(value, 'provider.msg_type', '');
                const typeMsg = msgType === 'g' ? "Group" : '';

                return (
                  <div
                    key={key}
                    className={`list-group-item list-group-item-action border-0 ${isActive ? 'active' : ''}`}
                    onClick={() => this.thanimsg(key, selectedList)}
                  >
                    {msgCount > 0 && (
                      <sup
                        data-show="true"
                        className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                      >
                        {msgCount}
                      </sup>
                    )}
                    <div className="d-flex align-items-start">
                      <img
                        src={value.provider?.photo || DEFAULTUSER}
                        className="rounded-circle mr-1"
                        alt="Provider"
                        width="40"
                        height="40"
                      />
                      {key && (
                        <div className="recent-text text-truncate ml-3">
                          <span className='msgerName m-0'>{key}</span>
                          {typeMsg && <span style={{ color: '#5b5b5b' }}>{typeMsg}</span>}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-9">
            <div className="col-12 py-2 d-flex justify-content-between message-header">
              <div style={css.heading1}>{_.get(chatList, ['0', 'provider', 'name'])}</div>
              <div></div>
            </div>

            {<div className="col-12 pl-0 pr-0 chat-messages">
              {
                chatList && chatList.map((chatval) => {
                  const userrid = _.get(user, ['id'], '')
                  const msgs = _.get(chatval, ['provider', 'messages_list'], [])
                  const msgType = _.get(chatval, ['provider', 'msg_type'], [])
                  const mkMsg = this.makeMessage(msgType, userrid, msgs);
                  return (<div className="chat-messages-inner">
                    {mkMsg}
                  </div>)
                })}
            </div>}

            {this.state.selectedChatGroup && <div className="flex-grow-0 py-3 px-4 border-top">
              <div className="chat-footer">
                <Input.TextArea
                  className="send-message-text"
                  value={this.state.message}
                  onChange={(e) => this.handleMessageChange(e, resentList)}
                  onPressEnter={(e) => this.handleSendMessage(e, resentList)}
                />
                <button
                  type="button"
                  className="send-message-button btn-info"
                  onClick={(e) => this.handleSendMessage(e, resentList)}
                >
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-send"
                    viewBox="0 0 16 16"
                  >
                    {' '}
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />{' '}
                  </svg>
                </button>
              </div>
            </div>}

          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  searchLocation: state.search.searchLocation,
  userBaseProfile: state.userProfile.userBaseProfile,
  dependentUsers: state.userProfile.dependentUsers,
  recentchatlistuser: state.message.recentchatlistuser,
  updateMessage: state.message.updateMessage,
  createMessage: state.message.createMessage,
  individualMessages: state.message.individualMessages,
  providerListForUser: state.message.providerListForUser,
})
const mapDispatchToProps = (dispatch) => ({
  onGetRecentMessages: (requestParams) =>
    dispatch(msgAction.recentMessages(requestParams)),
  onUpdateMessages: (requestParams) =>
    dispatch(msgAction.onUpdateUserMessages(requestParams)),
  onCreateMessages: (requestParams) =>
    dispatch(msgAction.onCreateUserMessages(requestParams)),
  onIndividualMessages: (requestParams) =>
    dispatch(msgAction.indvidualMessages(requestParams)),
  onProviderList: (requestParams) =>
    dispatch(msgAction.getProviderList(requestParams)),
  onUpdateData: (requestParams) =>
    dispatch(msgAction.onUpdateUserData(requestParams)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(StudentMessage))
