import React, { useState, useEffect } from 'react'
import { AiOutlineLaptop, AiOutlineCalendar } from 'react-icons/ai'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import timeDateConverterSession from '../../../../components/timezone/sessionTimeDate'
import CancelledDays from '../../../../components/tutor/cancelledDays'
import ReschduleDays from '../../../../components/tutor/reschduleDays'
import AddedDays from '../../../../components/tutor/addedDays'
import * as action from '../../../../store/actions/tutor/camps'
import SessionDetailsRight from '../../../../components/tutor/full-details/session-details'
import './student-list.css'
import LabelConvertion from '../../../../components/re-usable/label-convertion'
import SessionsLeft from '../../../../components/re-usable/SessionsLeft'
import timeZone from '../../../../components/common/timezone'
import { seatsAvailableCount } from '../../../../utils/common'
import variant from '../../../../utils/data/variant'
import { ListAntd } from '../../../../components/antd-components/index.tsx'
import { List } from 'antd'
export function SessionInfo(props) {
  console.log('propssession========', props)
  const { Item } = List // Destructure Item from List
  const [sessionDetail, setSessionDetail] = useState([])

  function getLabelByValue(arrObj, value) {
    const matchingObject = arrObj.find((item) => item.value === value)
    return matchingObject ? matchingObject.label : '--'
  }

  useEffect(() => {
    if (props.id) {
      props.onsessionDetail({
        id: props.id,
        expand: 'services,provider',
        type: props.type,
      })
    }
  }, [props.id])

  useEffect(() => {
    setSessionDetail(props.sessionDetail)
  }, [props.sessionDetail])

  const cd = _.chain(sessionDetail)
  let session_dates = cd.get('session_dates', []).size().value()
  if (session_dates === 0) {
    session_dates = '---'
  }
  const start_date = cd.get('start_date').value()
  const end_date = cd.get('end_date').value()
  const time_slot = cd.get('time_slot').value() || {}
  const BillingType = cd.get('billing_type').value()
  const MonthlyType = cd.get('payment_cycle_type').value()
  const variantType = cd.get('variant').value()
  const selectedDays = cd.get('selected_days').value()
  const session_type =
    BillingType !== 2
      ? LabelConvertion(BillingType, 'billing-type')
      : `${LabelConvertion(BillingType, 'billing-type')} - ${LabelConvertion(MonthlyType, 'payment_cycle_type')}`

  const maxCount =
    props.type !== 'competitions'
      ? cd.get('no_of_max_students').value()
      : cd.get('max_no_of_participants').value()
  const stuAccpt = cd.get('additional_details').value()
  const enroll_stud = _.get(stuAccpt, 'no_of_students_accepted')
  const total_seats_left = seatsAvailableCount(maxCount, stuAccpt)
  const sessionDates = `${timeDateConverterSession(
    start_date,
    'date',
    'MMM DD, YYYY'
  )} - ${timeDateConverterSession(end_date, 'date', 'MMM DD, YYYY')}`

  const timeSlotArray =
    Object.keys(time_slot).length > 0
      ? Object.keys(time_slot).map((day) => ({
        day,
        start_time: new Date(time_slot[day].start_time).toLocaleTimeString(
          'en-US',
          { hour: '2-digit', minute: '2-digit' }
        ),
        end_time: new Date(time_slot[day].end_time).toLocaleTimeString(
          'en-US',
          { hour: '2-digit', minute: '2-digit' }
        ),
      }))
      : []
  // const enrollCount = props.type === 'camps' ? props.campsStudents : props.type === 'classes' ? props.studentsCount : props.competitionStudents;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 pl-2">
        <div className="row py-3" style={{ width: '100%' }}>
          <div
            className="col-12 col-md-6 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#FFEDED' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('totalSes')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {session_dates}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#EEEBFF' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sLeft')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {SessionsLeft(cd.get('session_dates', []).value())}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#F7FECC' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('enrolled')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {enroll_stud ? enroll_stud : '0'}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#DAF5F6' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('seatLeft')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {total_seats_left}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-12 pr-md-0"
            style={{ maxWidth: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#dfdfdf' }}
              >
                <i className="ntm-calendar" style={{ fontWeight: '600' }} />
              </div>
              <div className="sess-left">
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('frequency')}
                </div>
                <div
                  className="session-text-bold"
                  style={{ marginLeft: 10, marginTop: 2, width: 'unset' }}
                >
                  {/* {LabelConvertion(cd.get('variant'), 'variant')} */}
                  {/* { console.log("variient",cd)} */}
                  {variantType === 'o'
                    ? _.map(selectedDays, (day, index) => {
                      const dayMap = {
                        'mo': 'Mon',
                        'tu': 'Tue',
                        'we': 'Wed',
                        'th': 'Thu',
                        'fr': 'Fri',
                        'sa': 'Sat',
                        'su': 'Sun'
                      };
                      const formattedDay = dayMap[day] || day;
                      const comma =
                        index !== selectedDays.length - 1 ? ', ' : ''
                      return formattedDay + comma
                    })
                    : getLabelByValue(variant, variantType)}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-6 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#ffeded' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('date')}
                </div>
                <div className="session-text-bold" style={{ marginLeft: 10 }}>
                  {sessionDates}
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-12 col-md-12 pr-md-0"
            style={{ width: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#ffeded' }}
              >
                <AiOutlineCalendar style={{ fontSize: 20 }} />
              </div>
              <div className="full-width ">
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('batchTimings')} ({timeZone()})
                </div>
                <ListAntd
                  className={'ml-2 mt-1'}
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={timeSlotArray}
                  renderItem={(item) => (
                    <Item>
                      <Item.Meta
                        title={item.day}
                        description={`${item.start_time} - ${item.end_time}`}
                      />
                    </Item>
                  )}
                />
              </div>
            </div>
          </div>

          {props.type === 'classes' && (
            <div
              className="col-12 col-md-6 pr-md-0"
              style={{ width: '50%', marginBottom: 35 }}
            >
              <div className="session-block" style={{ display: 'flex' }}>
                <div
                  className="session-icon mr-2 sessionInfoStyle"
                  style={{ background: '#F7FECC' }}
                >
                  <AiOutlineLaptop style={{ fontSize: 20 }} />
                </div>
                <div>
                  <div
                    className="session-text-regular"
                    style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                  >
                    {props.t('batType')}
                  </div>
                  <div className="session-text-bold" style={{ marginLeft: 10 }}>
                    {session_type}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            className="col-12 col-md-12 pr-md-0"
            style={{ maxWidth: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#f5bde9' }}
              >
                <i className="ntm-calendar" style={{ fontWeight: '600' }} />
              </div>
              <div>
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sessionsCancelled')}
                </div>
                <CancelledDays
                  sessionDetail={sessionDetail}
                  types={props.type}
                />
              </div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className='row py-3' style={{width:'100%',marginTop:10}}> */}
          <div
            className="col-12 col-md-12 pr-md-0"
            style={{ maxWidth: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#ff8181' }}
              >
                <i className="ntm-calendar" style={{ fontWeight: '600' }} />
              </div>
              <div className="sess-left">
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sessAdd')}
                </div>
                <AddedDays sessionDetail={sessionDetail} type={props.type} />
              </div>
            </div>
          </div>

          <div
            className="col-12 col-md-12 pr-md-0"
            style={{ maxWidth: '50%', marginBottom: 35 }}
          >
            <div className="session-block" style={{ display: 'flex' }}>
              <div
                className="session-icon mr-2 sessionInfoStyle"
                style={{ background: '#dfdfdf' }}
              >
                <i className="ntm-calendar" style={{ fontWeight: '600' }} />
              </div>
              <div className="sess-left">
                <div
                  className="session-text-regular"
                  style={{ width: 150, paddingTop: 0, paddingRight: 0 }}
                >
                  {props.t('sessionsRes')}{' '}
                </div>
                <ReschduleDays
                  sessionDetail={sessionDetail}
                  type={props.type}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pl-0">
        <SessionDetailsRight />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  sessionDetail: state.campsDetails.sessionDetail.data,
  loadingSession: state.campsDetails.loadingSession,
  studentsCount: _.get(
    state.classesDetails.classesStudents,
    ['meta', 'total_count'],
    0
  ),
  campsStudents: _.get(
    state.campsDetails.campsStudents,
    ['meta', 'total_count'],
    0
  ),
  competitionStudents: _.get(
    state.competitionsDetails.competitionStudents,
    ['meta', 'total_count'],
    0
  ),
})
const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) =>
    dispatch(action.onGetsessionDetail(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SessionInfo))
