import React from 'react'
// import "../attendance/attendance.css";
import AttendancesFilter from './filter'

const AttendanceReport = (props) => {
  const render = () => (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="nurtem-card-design tutor-attendance-page tutor-tab nurtem-table py-2 mb-3">
          <div
            className="schedule-content"
            style={{ position: 'relative', zIndex: '1' }}
          >
            <div className="full-width">
              <AttendancesFilter ForComponent="attendance" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return render()
}

export default AttendanceReport
