import React from 'react'
import { Tabs, Table, Space, Button, Tooltip, notification } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as userAction from '../../../store/actions/users'
import * as routeName from '../../../components/route-names'
import { AiFillDollarCircle} from 'react-icons/ai'
import {
  getGrandTotal,
  campsAddToCart,
} from '../../public/view-class-details/add-to-cart-container/component-functionalities'
import './user-my-camp.css'
import history from '../../../utils/history'
import axiostoServer from '../../../utils/axios'
import * as action from '../../../store/actions/public/Classes'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import nextSession from '../../../components/tutor/nextSession'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import _ from 'lodash'
import dayjs from 'dayjs'
import * as searchAction from '../../../store/actions/public/search'
import { cartItems } from '../../../store/actions/public/search/actionTypes'
import css from '../../../utils/CSSUtility'

class Camps extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      classTabs: '1',
      today_schedules: [],
      page: 1,
      limit: 100,
      classesTableData: [],
      expiredClassesTableData: [],
      timeZones: 'Asia/Kolkata',
      provider_id:
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.details &&
        this.props.tutorBaseProfile.details.id,
      classRow_id: '',
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    this._isMounted = true
    if (
      _.get(this, 'props.userBaseProfile.details.user.account_type') &&
      _.get(this, 'props.userBaseProfile.details.user.account_type') !==
      'dependent'
    ) {
      this.props.getDependentUsers({ limit: 100 })
    }
    //let url = this.props.location.search
    //let urlparams = queryString.parse(url)

    let requestParams = {}
    requestParams.limit = 100
    requestParams.expand = 'provider,dependent_user,class_details,type_details'
    requestParams.sort = 'updated_at.desc'
    this.props.getClassList(requestParams)
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidUpdate(prevProps) {
    if (
      prevProps.userClasses !== this.props.userClasses &&
      this.props.userClasses.items &&
      this.props.userClasses.items.length > 0
    ) {

      this.setState((prevState) => ({
        classesTableData: [
          ...prevState.classesTableData,
          ...this.props.userClasses.items.filter(item => !this.isExpired(item)),
        ],
        expiredClassesTableData: [
          ...prevState.expiredClassesTableData,
          ...this.props.userClasses.items.filter(item => this.isExpired(item)),
        ],
      }))

    }
  }
  formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return ` ${month} ${day}, ${year}`;
  };

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  isExpired = (classes) => {
    const endDate = new Date(_.get(classes, 'class_details.end_date'))
    return endDate < new Date()
  }

  onAddToCart = async (data, callback) => {
    // console.log("selectedSession>>", data);
    const price = _.get(data, 'campDetails.price', 0)
    const selectedSession = _.get(data, 'campDetails', {})
    const priceData = getGrandTotal(price, [])
    var userObj = {
      isLoggedIn: _.get(this.props, 'loggedInUserDetails.isLoggedIn', false),
      userID: _.get(this.props, 'userBaseProfile.details.id', ''),
      to_whom_id: _.get(data, 'to_whom_id', ''),
      user_type: _.get(data, 'user_type', ''),
      reorder: true,
    }
    const paid_for = _.get(userObj, 'to_whom_id', _.get(userObj, 'userID', ''))
    const clsID = selectedSession.id
    const billingType = _.get(data, 'campDetails.billing_type', 1)
    const SessionPaid = () => {
      if (billingType === 3) {
        return 0
      }
      return new Promise((resolve, reject) => {
        if (paid_for && clsID) {
          axiostoServer({
            method: 'post',
            url: '/number-sessons-paid',
            withCredentials: true,
            data: {
              billingType,
              class_id: clsID,
              paidfor: paid_for,
            },
            headers: { 'Content-Type': 'application/json' },
          })
            .then((resp) => {
              if (resp.status === 200 || resp.status === 304) {
                resolve(resp.data.data)
              } else {
                reject(new Error('Failed to fetch session purchase'))
              }
            })
            .catch((error) => {
              reject(error)
            })
        } else {
          reject(new Error('Invalid paid_for or clsID'))
        }
      })
    }
    let session_dates_count = 0
    session_dates_count = await SessionPaid()
    const session_dates = _.get(data, 'campDetails.session_dates', [])
    const sessionName = _.get(data, 'campDetails.session_name', [])
    const SessionsPaid = _.get(session_dates_count, 'sessionPaid', 0)
    const SessionsPaidOn1 = _.get(session_dates_count, 'paidOn', dayjs())
    const SessionsPaidOn = SessionsPaidOn1
      ? dayjs(SessionsPaidOn1).startOf('day')
      : dayjs().startOf('day')
    const filteredDates = session_dates.filter(
      (date) => new Date(date) >= SessionsPaidOn
    )
    if (billingType === 1) {
      session_dates_count = filteredDates.length - SessionsPaid
    }
    if (billingType === 2) {
      const uniqueMonths = new Set()
      filteredDates.forEach((date) => {
        const yearMonth = dayjs(date).format('MM-YYYY')
        uniqueMonths.add(yearMonth)
      })
      const numberOfMonths = uniqueMonths.size
      const sessionPaidLs = SessionsPaid
      if (sessionPaidLs === numberOfMonths) {
        session_dates_count = 0
      } else {
        session_dates_count = 1
      }
    }
    if (billingType === 3) {
      session_dates_count = 0
    }
    console.log('datapaid=======', session_dates_count)
    if (session_dates_count === 0) {
      notification.warning({
        message: 'Fully Booked',
        description: `You have already fully paid for the session "${sessionName}"`,
      })
    } else {
      campsAddToCart(
        this.props.t,
        selectedSession,
        [],
        priceData,
        userObj,
        1,
        'classes',
        this.props.setCartItemsRedux
      )
      this.props.onItemAdd()
      setTimeout(() => {
        this.props.onUserProfile()
      }, 2000)
    }
  }

  onBooknow = (data) => {
    this.onAddToCart(data, () => {
      setTimeout(() => {
        history.push('/mycart')
      }, 1000)
    })
  }
  linkwrap = (data) => {
    return (
      <div>
        <a
          style={{ color: 'rgb(24, 144, 255)' }}
          target="_blank"
          href={data}
        >{`${_.truncate(data, { length: 25, omission: ' ...' })}`}</a>
      </div>
    )
  }
 
  

  render() {
    const isUser =
      _.get(this, 'props.userBaseProfile.details.user.account_type') !==
      'dependent'
    const { classRow_id } = this.state
    const ListingClasscolumns = [
      {
        title: this.props.t('batchLabel2'),
        key: 'class',
        dataIndex: 'class',
        // render: (text, record) => {
        //   const fname = _.get(record, 'providerDetails.firstname')
        //   const lname = _.get(record, 'providerDetails.lastname')
        //   const bname = _.get(record, 'providerDetails.businessname', '')
        //   const aCampName = _.get(record, 'batch_details.name')
        //   const classId = _.get(record, 'batch_details.id')
        //   let tutorName =
        //     _.get(record, 'providerDetails.type') === 'Individual'
        //       ? `${fname} ${lname}`
        //       : `${bname}`
        //   if (tutorName) {
        //     tutorName = tutorName.toLowerCase().trim()
        //   }
        //   var viewCampUrl = ''
        //   if (tutorName && aCampName) {
        //     viewCampUrl = `${tutorName.replace(/\s+/g, '-')}/${aCampName.replace(/\s+/g, '-')}/?gid=${classId}`
        //   }
        //   return (
        //     <Space size="middle">
        //       <a
        //         style={{ color: 'rgb(24, 144, 255)' }}
        //         href={`/view-class/${viewCampUrl}`}
        //         rel="noreferrer"
        //         target="_blank"
        //       >
        //         {_.get(record, 'class')}
        //       </a>
        //     </Space>
        //   )
        // },
      },
      {
        title: this.props.t('toWhom'),
        dataIndex: 'to_whom',
        key: 'to_whom',
        width: '10%',
      },
      {
        title: this.props.t('nextSession'),
        dataIndex: 'next_session',
        key: 'next_session',
        width: '20%',
      },
      {
        title: this.props.t('sDate'),
        dataIndex: 'session',
        key: 'session',
        width: '15%',
      },
      {
        title: this.props.t('eDate'),
        dataIndex: 'end_date',
        key: 'end_date',
        width: '15%',
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
        width: '10%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '8%',
        render: (text, record) => {
          console.log('record=======', record)
          var activeuser = {
            to_whom: _.get(record, 'to_whom'),
            to_whom_id: _.get(record, 'to_whom_id'),
            user_type: _.get(record, 'user_type'),
          }
          var link = `${routeName.userClassView}/${encodeURIComponent(_.get(record, 'class'))}/?campId=${_.get(record, 'id', '')}&userid=${_.get(activeuser, 'to_whom_id', '')}&usertype=${_.get(activeuser, 'user_type')}&username=${_.get(activeuser, 'to_whom')}`
          return (
            <Space size="middle">
              <div className="d-flex">
                <button
                  onClick={() => history.push(link)}
                  className="btn btn-accept  mr-1"
                >
                  {this.props.t('details')}
                </button>
              </div>
            </Space>
          )
        },
      },
      isUser && {
        title: this.props.t('buyAgain'),
        key: 'action2',
        width: '8%',
        render: (text, record) => {
          var data = {
            to_whom: _.get(record, 'to_whom'),
            to_whom_id: _.get(record, 'to_whom_id'),
            user_type: _.get(record, 'user_type'),
            campDetails: _.get(record, 'campDetails'),
          }
          var SessionAvailable = _.get(record, 'campDetails.session_dates')
          let EnableButton = SessionAvailable.filter(
            (date) => dayjs(date) >= dayjs()
          )
          return (
            <Space size="middle">
              {EnableButton.length === 0 ||
                _.get(record, 'campDetails.billing_type') === 3 ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    rowGap: '10px',
                  }}
                >
                  {_.get(record, 'campDetails.price') !== 0 ? (
                  <Tooltip
                    placement="left"
                    title="No Sessions available to reorder"
                  >
                    <Button
                        disabled={true}
                        style={{ cursor: 'block' }}
                        className="btn btn-add-to-cart userClassTablebtn"
                      >
                        <AiFillDollarCircle className="cart-icon mr-1" />{' '}
                        {this.props.t('buyAgain')}
                      </Button>
                    </Tooltip>) : (this.props.t('freeLabel'))}
                </div>
              ) : (
                <div>
                  {_.get(record, 'campDetails.price') !== 0 ? (
                  <Button
                    onClick={() => this.onBooknow(data)}
                    style={{
                      cursor: 'pointer',
                      background: '#24b3af',
                      color: 'white',
                    }}
                    className="btn btn-add-to-cart userClassTablebtn"
                  >
                    <AiFillDollarCircle className="cart-icon mr-1" />{' '}
                    {this.props.t('buyAgain')}
                  </Button>
                  ) :(this.props.t('freeLabel'))}
                </div>
              )}
            </Space>
          )
        },
      },
    ].filter(Boolean)
    const expiredListingClasscolumns = [
      {
        title: this.props.t('batchLabel2'),
        key: 'class',
        dataIndex: 'class',
      },
      {
        title: this.props.t('toWhom'),
        dataIndex: 'to_whom',
        key: 'to_whom',
        width: '10%',
      },
      {
        title: this.props.t('sDate'),
        dataIndex: 'session',
        key: 'session',
        width: '15%',
      },
      {
        title: this.props.t('eDate'),
        dataIndex: 'end_date',
        key: 'end_date',
        width: '15%',
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
        width: '10%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '8%',
        render: (text, record) => {
          const activeuser = {
            to_whom: _.get(record, 'to_whom'),
            to_whom_id: _.get(record, 'to_whom_id'),
            user_type: _.get(record, 'user_type'),
          };
          const link = `${routeName.userClassView}/${encodeURIComponent(_.get(record, 'class'))}/?campId=${_.get(record, 'id', '')}&userid=${_.get(activeuser, 'to_whom_id', '')}&usertype=${_.get(activeuser, 'user_type')}&username=${_.get(activeuser, 'to_whom')}`;
          return (
            <Space size="middle">
              <div className="d-flex">
                <button
                  onClick={() => history.push(link)}
                  className="btn btn-accept  mr-1"
                >
                  {this.props.t('details')}
                </button>
              </div>
            </Space>
          );
        },
      },
    ].filter(Boolean);
    const generateClassData = (classList, userBaseProfile, capitalizeWord, nextSession, timeDateConverterSession, linkwrap) => {
      return classList.map((value) => {
        const {
          class_details,
          packages,
          dependent_user,
          provider,
          type_details,
          user,
          status,
          id,
        } = value;
        const userName = _.get(userBaseProfile, 'details.firstname');
        const capitalizedSessionName = capitalizeWord(_.get(class_details, 'session_name', ''));
        const isUserAccountType = _.get(userBaseProfile, 'details.user.account_type') !== 'dependent';
        const isDependentUser = _.isEmpty(dependent_user);
        const isUser = !_.isEmpty(user);
        
        if (!_.isEmpty(class_details)) {
          const item = {
            id,
            class: status === 0 ? `${capitalizedSessionName} (Awaiting for Tutor response.)` : capitalizedSessionName,
            to_whom: isDependentUser && isUser ? userName : _.startCase(_.get(dependent_user, 'firstname')),
            to_whom_id: isDependentUser && isUser ? user : _.get(dependent_user, 'id'),
            user_type: isDependentUser && isUser ? 'user' : 'dependent',
            package: _.get(packages, '[0].name'),
            next_session: nextSession(class_details, 'classes'),
            session: timeDateConverterSession(_.get(class_details, 'start_date'), 'date'),
            end_date: timeDateConverterSession(_.get(class_details, 'end_date'), 'date'),
            location: _.get(class_details, 'teaching_mode') === 'o' ? 'Online' : _.get(class_details, 'location_taught', '---') || '---',
            key: _.get(class_details, 'id'),
            class_id: _.get(class_details, 'id'),
            batch_details: type_details,
            campDetails: class_details,
            providerDetails: provider,
            getUserDetails: user || dependent_user,
            link: linkwrap(_.get(class_details, 'link', '---')),
            code: _.get(class_details, 'code', '---'),
          };
    
          if (isUserAccountType) {
            return isDependentUser && isUser ? { type: 'classData', item } : { type: 'dependentClassData', item };
          } else {
            return !isDependentUser && isUser ? { type: 'classData', item } : null;
          }
        }
        return null;
      }).filter(Boolean);
    };
    
    const classData = [];
    const dependentClassData = [];
    const expiredClassData = [];
    const expiredDependentClassData = [];
    
    if ((this.props.userClasses && this.props.userClasses.items.length > 0) || _.size(this.state.classesTableData) > 0) {
      generateClassData(this.state.classesTableData, this.props.userBaseProfile, this.capitalizeWord, nextSession, timeDateConverterSession, this.linkwrap).forEach((result) => {
        if (result.type === 'classData') classData.push(result.item);
        else if (result.type === 'dependentClassData') dependentClassData.push(result.item);
      });
    }
    
    if ((this.props.userClasses && this.props.userClasses.items.length > 0) || _.size(this.state.expiredClassesTableData) > 0) {
      generateClassData(this.state.expiredClassesTableData, this.props.userBaseProfile, this.capitalizeWord, nextSession, timeDateConverterSession, this.linkwrap).forEach((result) => {
        if (result.type === 'classData') expiredClassData.push(result.item);
        else if (result.type === 'dependentClassData') expiredDependentClassData.push(result.item);
      });
    }
    

    var classDataUniq = _.uniqBy(classData, (e) => e.class_id)
    var dependentClassDataUniq = _.uniqBy(dependentClassData, (e) => e.class_id)
  
    var expiredClassDataUniq = _.uniqBy(expiredClassData, (e) => e.class_id)
    var expiredDependentClassDataUniq = _.uniqBy(expiredDependentClassData, (e) => e.class_id)

    let dependentUsers = _.get(this, 'props.dependentUsers.items', [])
    return (
      <div className="nurtem-myclass-design nurtem-table mb-3">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
          <Tabs defaultActiveKey="1">
            <items tab={this.props.t('tutorSidebarMyClasses')} key="1">
              <div className="schedule-content mt-2">
                <div className="" style={{ overflowX: 'auto' }}>
                  {this.props.loading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      loading={
                        _.isEmpty(classDataUniq)
                          ? false
                          : classDataUniq
                            ? false
                            : true
                      }
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={ListingClasscolumns}
                      dataSource={classDataUniq}
                      scroll={{ x: true }}
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 5,
                        position: ['bottomLeft'],
                      }}
                    />
                  )}
                </div>
              </div>
            </items>
            {(() => {
              return _.map(
                _.size(dependentUsers) > 0 && dependentUsers,
                (user, index) => {
                  return (
                    <items
                      tab={`${user && _.get(user, 'firstname') ? _.get(user, 'firstname', '') + ' ' + _.get(user, 'lastname', '') : ''}'s`}
                      key={_.get(user, 'id')}
                    >
                      <div className="schedule-content mt-2 dependent-shedule-content">
                        <div style={{ overflowX: 'auto' }}>
                          {this.props.loading ? (
                            <ClassDetailLoader />
                          ) : (
                            <Table
                              loading={
                                _.isEmpty(dependentClassDataUniq)
                                  ? false
                                  : dependentClassDataUniq
                                    ? false
                                    : true
                              }
                              rowClassName={(record, index) =>
                                record.class_id === classRow_id ? 'active' : ''
                              }
                              columns={ListingClasscolumns}
                              scroll={{ x: true }}
                              dataSource={_.uniqBy(
                                _.filter(dependentClassData, (e) => {
                                  return (
                                    user && user.id && e.to_whom_id === user.id
                                  )
                                }),
                                'class_id'
                              )}
                              pagination={{
                                pageSize: 5,
                                position: ['bottomLeft'],
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </items>
                  )
                }
              )
            })()}
          </Tabs>
          <div style={css.heading1}>
          {this.props.t('expiredClass')}
          </div>
          <Tabs>
            <items tab={this.props.t('tutorSidebarMyClasses')} key="1">
              <div className="schedule-content mt-2">
                <div className="" style={{ overflowX: 'auto' }}>
                  {this.props.loading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      loading={
                        _.isEmpty(expiredClassDataUniq)
                          ? false
                          : expiredClassDataUniq
                            ? false
                            : true
                      }
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={expiredListingClasscolumns}
                      dataSource={expiredClassData}
                      scroll={{ x: true }}
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 5,
                        position: ['bottomLeft'],
                      }}
                    />
                  )}
                </div>
              </div>
            </items>
            {(() => {
              return _.map(
                _.size(dependentUsers) > 0 && dependentUsers,
                (user, index) => {
                  return (
                    <items
                      tab={`${user && _.get(user, 'firstname') ? _.get(user, 'firstname', '') + ' ' + _.get(user, 'lastname', '') : ''}'s`}
                      key={_.get(user, 'id')}
                    >
                      <div className="schedule-content mt-2 dependent-shedule-content">
                        <div style={{ overflowX: 'auto' }}>
                          {this.props.loading ? (
                            <ClassDetailLoader />
                          ) : (
                            <Table
                              loading={
                                _.isEmpty(expiredDependentClassDataUniq)
                                  ? false
                                  : expiredDependentClassDataUniq
                                    ? false
                                    : true
                              }
                              rowClassName={(record, index) =>
                                record.class_id === classRow_id ? 'active' : ''
                              }
                              columns={expiredListingClasscolumns}
                              scroll={{ x: true }}
                              dataSource={_.uniqBy(
                                _.filter(expiredDependentClassData, (e) => {
                                  return (
                                    user && user.id && e.to_whom_id === user.id
                                  )
                                }),
                                'class_id'
                              )}
                              pagination={{
                                pageSize: 5,
                                position: ['bottomLeft'],
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </items>
                  )
                }
              )
            })()}
          </Tabs>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    loggedInUserDetails: state.authentication,
    userClasses: state.users.userClasses,
    dependentUsers: state.userProfile.dependentUsers,
    userBaseProfile: state.userProfile.userBaseProfile,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCartItemsRedux: (requestParams) =>
      dispatch(searchAction.common(cartItems, requestParams)),

    getClassList: (requestParams) =>
      dispatch(userAction.getUserClasses(requestParams)),
    getDependentUsers: (requestParams) =>
      dispatch(userAction.getDependentUsers(requestParams)),
    onItemAdd: () => dispatch(action.cartItemAdd()),
    onUserProfile: () => dispatch(userAction.userProfile()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Camps))
