import React, { useState, useEffect } from 'react'
import { Carousel, Rate } from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import * as action from '../../../store/actions/tutor/camps'
import _ from 'lodash'
import SessionDetailsLoader from '../../loader/common/session-details-loader'
import LabelConvertion from '../../re-usable/label-convertion'
import getAgeGroups from '../../re-usable/age-label'
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'

const SessionDetailsRight = (props) => {
  const [sessionDetail, setSessionDetail] = useState([])

  useEffect(() => {
    if (props.id) {
      props.onsessionDetail({
        id: props.id,
        expand: 'services,provider',
        type: props.type,
      })
    }
  }, [props.id])

  useEffect(() => {
    setSessionDetail(props.sessionDetail)
  }, [props.sessionDetail])

  // Check if sessionDetail is available before accessing its properties
  if (props.loadingSession) {
    return <SessionDetailsLoader /> // or any other placeholder/Loading component
  }

  const cd = _.chain(sessionDetail)
  const session_name = cd.get('session_name', '').value()

  const session_price = cd.get('price', '').value()
  const description = cd.get('description', '').value()
  const avg_count = cd.get('average_rating', 0).value()
  const rating_count = cd.get('rating_count', 0).value()
  let session_dates = cd.get('session_dates', []).size().value()
  if (session_dates === 0) {
    session_dates = '---'
  }
  const BillingType = cd.get('billing_type').value()
  const MonthlyType = cd.get('payment_cycle_type').value()
  const AgeGroup = cd.get('age_group').value()
  const session_type =
    BillingType !== 2
      ? LabelConvertion(BillingType, 'billing-type')
      : LabelConvertion(BillingType, 'billing-type') +
        ' - ' +
        LabelConvertion(MonthlyType, 'payment_cycle_type')

  const labels = getAgeGroups(AgeGroup)

  return (
    <div className="user-details-right col-12">
      <div className="row align-items-center mt-3">
        <div className="col-12  pr-md-0">
          <div className="tab-content-title">
            {session_name} - ${session_price}{' '}
          </div>
        </div>
      </div>

      <div className="row align-items-center justify-content-between py-2">
        <div className="col-8">
          <div className="row align-items-center">
            <Carousel autoplay>
              {labels &&
                labels.map((label, index) => (
                  <div key={index}>
                    <div className="review-counting-session">{label} </div>
                  </div>
                ))}
            </Carousel>
            <span className="ml-3 mt-4 mb-4">{session_type}</span>
            <div className="col-12 pl-0">
              <div
                className="star-rating-session mt-4"
                style={{ display: 'flex' }}
              >
                <Rate disabled="true" value={parseInt(avg_count)} />
                {parseInt(avg_count) !== 0 && (
                  <b className="ml-2">({avg_count.toFixed(1)}/5)</b>
                )}
              </div>
              <div className="review-counting-session">
                ({parseInt(rating_count)} {props.t('rating')})
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row align-items-center py-4">
        <div
          className="col-12 session-text-bold mb-2"
          style={{ fontWeight: 'bolder', fontSize: 15 }}
        >
          {props.t('descp')}
        </div>
        <div className="col-12">
          <Scrollbars
            className="camp-scroll-height"
            renderTrackHorizontal={(props) => (
              <div
                {...props}
                className="track-horizontal"
                style={{ display: 'none' }}
              />
            )}
            renderThumbHorizontal={(props) => (
              <div
                {...props}
                className="thumb-horizontal"
                style={{ display: 'none' }}
              />
            )}
          >
            <div>
              {typeof description === 'string' ? parser(description.replace(/\n/g, '<br>'))  : ''}
            </div>
          </Scrollbars>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    sessionDetail: state.campsDetails.sessionDetail.data,
    loadingSession: state.campsDetails.loadingSession,
    studentsCount: _.get(
      state.classesDetails.classesStudents,
      ['meta', 'total_count'],
      0
    ),
    campsStudents: _.get(
      state.campsDetails.campsStudents,
      ['meta', 'total_count'],
      0
    ),
    competitionStudents: _.get(
      state.competitionsDetails.competitionStudents,
      ['meta', 'total_count'],
      0
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onsessionDetail: (requestParams) =>
      dispatch(action.onGetsessionDetail(requestParams)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SessionDetailsRight))
