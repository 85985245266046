export const user_class_sessions_attended = 'user_class_sessions_attended';
export const userInfoFound = 'userInfoFound';
export const enableLoading = 'enableLoading';
export const disableLoading = 'disableLoading';
export const updateUserProfile = 'updateUserProfile';
export const notesFound = 'notesFound';
export const sendNotes = 'sendNotes';
export const userClassInfo = 'userClassInfo';
export const enableClassLoading = 'enableClassLoading';
export const disableClassLoading = 'disableClassLoading';
export const couponsApplyCodeLoading = 'couponsApplyCodeLoading';
export const userBaseProfile = 'userBaseProfile';
export const userClasses = 'userClasses';
export const userClassIndividualSuccess = 'userClassIndividualSuccess';
export const userCampsIndividualSuccess = 'userCampsIndividualSuccess';
export const userCompsIndividualSuccess = 'userCompsIndividualSuccess';
export const userEventsIndividualSuccess = 'userEventsIndividualSuccess';
export const todayClasses = 'todayClasses';
export const dependentUser = 'dependentUser';
export const userPaymentList = 'userPaymentList';
export const updateUserProfileInfo = 'updateUserProfileInfo';
export const guestUserFlowStep = 'guestUserFlowStep';
export const guestUserDepententSelect = 'guestUserDepententSelect';
export const guestUserRouteActive = 'guestUserRouteActive';
export const deleteDependentUser = 'deleteDependentUser';
export const updateDependentUser = 'updateDependentUser';
export const createDependentUser = 'createDependentUser';
export const serviceList = 'serviceList';
export const enableDependentUserLoading = 'enableDependentUserLoading';
export const disableDependentUserLoading = 'disableDependentUserLoading';
export const enableServiceListLoading = 'enableServiceListLoading';
export const disableServiceListLoading = 'disableServiceListLoading';
export const enableProfileLoading = 'enableProfileLoading';
export const disableProfileLoading = 'disableProfileLoading';
export const bookingsResponse = 'bookingsResponse';
export const onBookingsCreatedLoading = 'onBookingsCreatedLoading';
export const campBookingsResponse = 'campBookingsResponse';
export const enableNotesLoading = 'enableNotesLoading';
export const disableNotesLoading = 'disableNotesLoading';
export const openUserProfileModal = 'openUserProfileModal';
export const closeUserProfileModal = 'closeUserProfileModal';
export const openProfileModal = 'openProfileModal';
export const closeProfileModal = 'closeProfileModal';
export const trailBookingResponse = 'trailBookingResponse';
export const disableprofileModal = 'disableprofileModal';
export const enableprofileModal = 'enableprofileModal';
export const otherBookingClass = 'otherBookingClass ';
export const couponsApplyResponse = 'couponsApplyResponse ';
export const usersSwitchPropertyLoading = 'usersSwitchPropertyLoading';
export const providerNotesForUser = 'providerNotesForUser';
export const updateUserState = 'updateUserState';
export const on_receiving_student_payment_information = 'on_receiving_student_payment_information';
export const offline_payment_object = 'offline_payment_object';
export const balance_amount = 'balance_amount';

/* Camps actions */
export const userCamps = 'userCamps';
export const userCampsInfo = 'userCampsInfo';
export const enableCampsLoading = 'enableCampsLoading';
export const disableCampsLoading = 'disableCampsLoading';

/* Competitions actions */
export const userCompetitions = 'userCompetitions';
export const userCompetitionsInfo = 'userCompetitionsInfo';
export const enableCompetitionsLoading = 'enableCompetitionsLoading';
export const disableCompetitionsLoading = 'disableCompetitionsLoading';

/* Events actions */
export const userEvents = 'userEvents';
export const userEventsInfo = 'userEventsInfo';

/** Monthly compute actions */
export const monthlyCompute = 'monthlyCompute';
export const bookingCredits = 'bookingCredits';

/** Cart actions */
export const sessionLeft = 'sessionLeft';
