const variant = [
  // { value: 'o', label: 'Custom (Select Day)' },
  
  { value: 'o1', label: 'Once in a week' },
  { value: 'o2', label: 'Twice  in a week' },
  { value: 'o3', label: 'Thrice  in a week' },
  { value: 'o4', label: 'Four times a week' },


  { value: 'wd', label: 'Weekdays' },
  { value: 'wend', label: 'Weekend' },
  { value: 'fw', label: 'Full Week (All days)' },

  // {value:'wdm', label:'Weekdays - Morning'},
  // {value:'wde', label:'Weekdays - Evening'},

  // {value:'wendm', label:'Weekend - Morning'},
  // {value:'wende', label:'Weekend - Evening'},

  // {value:'fwm', label:'Fullweek - Morning'},
  // {value:'fwe', label:'Fullweek - Evening'},
];
export default variant;
