import React, { useState } from 'react'
// import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Flex, message, Row, Steps, theme } from 'antd'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import _ from 'lodash'
import FileUpload from '../../../components/re-usable/file-upload'
import downloadXls from '../../../utils/common/download-xls'
import { OBXLSX, OBCLASSXLSX } from '../../../utils/config/assets'
import Filter from '../attendance-report/filter'
import css from '../../../utils/CSSUtility'

function OnboardingWizard(props) {
  console.log('onprops', props)
  const callback = (resp) => {
    console.log('callback==========', resp)
  }

  const onboarding_tutor = {
    key: 'Onboard_Tutors',
    title: <span className='onboardHead'>{props.t('onboardTutors')}</span>,
    content: (
      <div className="container-upload">
        <FileUpload url="/files/onboard-tutor" step="1" />

        <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          <div
            onClick={() => downloadXls(OBXLSX)}
            style={css.heading3}
          >
            {props.t('enrollLabel4')}
          </div>
        </div>
      </div>
    ),
  }

  const onboarding_classes = {
    key: 'Onboard_Classes',
    title: <span className='onboardHead'>{props.t('onboardClasses')}</span>,
    content: (
      <div
        className="container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          columnGap: '25px',
        }}
      >
        <FileUpload url="/files/onboard-classes" step="2" callback={callback} />

        <div style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          <div
            onClick={() => downloadXls(OBCLASSXLSX)}
            style={css.heading3}
          >
            {props.t('enrollLabel4')}
          </div>
        </div>
      </div>
    ),
  }

  const onboarding_students = {
    key: 'Onboard_Students',
    title: <span className='onboardHead'>{props.t('onboardStu')}</span>,
    content: <Filter ForComponent="onboarding" />,
  }

  const flow = [onboarding_classes, onboarding_students]

  const flow_business = [
    onboarding_tutor,
    onboarding_classes,
    onboarding_students,
  ]

  // const ch = _.chain(props.loggedInUserDetails);
  // let isLoggedIn = ch.get('isLoggedIn', false).value()
  // let isProvider = ch.get('login_type', '').eq('provider').value()

  const ch2 = _.chain(props.tutorBaseProfile)
  const isBusiness = ch2.get('type', '').toLower().eq('business').value()
  // let isIndividual = ch2.get('type', '').toLower().eq('individual').value()
  console.log('isBusiness>>>', isBusiness)

  const itemsList = isBusiness ? flow_business : flow

  const { token } = theme.useToken()
  const [current, setCurrent] = useState(0)
  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const contentStyle = {
    // height: '560px',
    display: Flex,
    flexDirection: Row,
    alignItem: 'center',
    justifyContent: 'center',
    color: token.colorTextTertiary,
    // backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    // border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
    paddingTop: 10,
  }

  const stepsLength = itemsList.length

  return (
    <div className="nurtem-card-design  py-3 px-1 mb-3">
      <div className="container">
        <Steps current={current} items={itemsList} />
        <div style={contentStyle}>{itemsList[current].content}</div>
        <div
          style={{
            marginTop: 24, display: 'flex', justifyContent: 'flex-end', flexDirection: 'row-reverse',
          }}
        >
          {current < stepsLength - 1 && (
            <Button type="primary" onClick={() => next()}>
              {props.t('next')}
            </Button>
          )}
          {current === stepsLength - 1 && (
            <Button
              type="primary"
              onClick={() => message.success('Processing complete!')}
            >
              {props.t('done')}
            </Button>
          )}
          {current > 0 && (
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => prev()}
            >
              {props.t('prev')}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  loggedInUserDetails: state.authentication,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile.details,
})

export default connect(mapStateToProps)(withTranslation()(OnboardingWizard))
