import React from 'react';
import { notification } from 'antd';
// import TutorProfileInfoCard from './info-card/index';
import { connect } from 'react-redux';
import TutorMyProfileTab from './my-profile-tab/profile-others';
import * as servicesActions from '../../../store/actions/public/Services';
import './style.css';
import generalAction from '../../../store/general/action';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';
import _ from 'lodash';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';

class TutorMyPofile extends React.Component {
  state = {
    tutors: {},
  };

  componentDidMount() {
    if (!_.get(this.props, 'tutorBaseProfile.details.user_handle') || (!_.get(this.props, 'tutorBaseProfile.details.firstname') && !_.get(this.props, 'tutorBaseProfile.details.businessname'))) {
      history.push(routeName.TutorBaseProfileStep1);
      notification.warning({
        message: 'Warning',
        description: 'Please update your profile.',
      });
    }
    this.props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {

      },
    });
    this.props.onTutorSiderbar(false);
  }

  componentDidUpdate(preProps) {
    if (preProps.generalTutorProfile !== this.props.generalTutorProfile && this.props.generalTutorProfile.details) {
      this.setState({
        tutors: this.props.generalTutorProfile.details,
      });
    }

    if (preProps.location !== this.props.location) {
      if (window.location.href.indexOf('/profile/') > -1 || window.location.href.indexOf('/view-class/') > -1 || window.location.href.indexOf('/view-camp/') > -1) {
        this.props.onTutorSiderbarLogo(true);
        this.props.onTutorSiderbar(true);
      } else {
        this.props.onTutorSiderbar(false);
      }
    }
  }

  render() {
    return (
      <div className="full-width tutor-my-class-page tutor-my-profile-page mb-3">
        <div className="row">
          <ScrollToTop />
          {/* <TutorProfileInfoCard {...this.props} /> */}
          <TutorMyProfileTab {...this.props} tutorsChangedDetails={this.state.tutors} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  onTutorPublicsiderbar: state.services.onTutorPublicsiderbar,
  loggedInUserDetails: state.authentication,
  generalTutorProfile: state.general.tutor.profile,
});

const mapDispatchToProps = (dispatch) => ({
  getTutorProfile: (details) => dispatch(generalAction.tutor.getProfile(details)),
  onTutorSiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),
  onTutorSiderbar: (requestParams) => dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutorMyPofile);
