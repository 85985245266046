import React from 'react';
import {
  Input, Modal, notification, Button, Table, Space, Form,
} from 'antd';
import { AiOutlinePlus, AiOutlineUpSquare, AiFillLock } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import parser from 'html-react-parser';
import { ClassDetailLoader } from '../../../components/loader/common/class-detail';
import * as routeName from '../../../components/route-names';
import history from '../../../utils/history';
import generalAction from '../../../store/general/action';
import './tutor-list.css';
import { NurtemUtils } from '../../../utils';
import { DEFAULTUSER } from '../../../utils/config/assets';
import _ from 'lodash';
import css from '../../../utils/CSSUtility';

const FormItem = Form.Item;
const rootElement = document.documentElement;

class TutorClasses extends React.Component {
  faqRef = React.createRef();

  form = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      description: '',
      redirect: false,
      page_provider_id: '',
      page: 1,
      limit: 4,
      tutorTableData: [],
      provider_id: this.props.tutorBaseProfile && this.props.tutorBaseProfile.details && this.props.tutorBaseProfile.details.id,
      tutorRow_id: '',
      createTutorModal: false,
      isModalVisible: false,
      modal1Visible: false,
    };
  }

  componentDidMount() {
    if (this.props.tutorBaseProfile && this.props.tutorBaseProfile.details && this.props.tutorBaseProfile.details.id) {
      const requestParams = {};
      requestParams.limit = 4;
      requestParams.works_for = this.props.tutorBaseProfile.details.id;
      requestParams.sort = 'created_at.desc';
      console.log('requestParams', requestParams);
      requestParams.expand = 'classes';
      this.props.tutorList({ params: requestParams });
    }
  }

  commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
    });
  };

  async componentDidUpdate(prevPros) {
    if (prevPros.tutorsList !== this.props.tutorsList && this.props.tutorsList.items && this.props.tutorsList.items.length > 0) {
      this.setState({
        tutorTableData: this.props.tutorsList.items,
      });
    }
  }

  pageChange = async (page, pageSize) => {
    await this.setState({
      page,
    });
    const data = {};
    data.provider = this.state.provider_id;
    data.page = page;
    data.limit = this.state.limit;
    data.works_for = _.get(this, 'props.tutorBaseProfile.details.id');
    data.expand = 'provider';
    data.sort = 'created_at.desc';
    this.props.tutorList({ params: data });
  };

  setModal1Visible(modal1Visible, description) {
    this.setState({ modal1Visible, description });
  }

  setCreateTutorModal(modal1Visible, description) {
    if (modal1Visible === false) {
      this.form.current.resetFields();
    }
    this.setState({ createTutorModal: modal1Visible });
  }

  titleRender = (details) => {
    console.log('pic-->', details);
    return (
      <div className="row">
        <div>
          <img style={{ width: '50px', height: '50px', borderRadius: '30px' }} src={_.get(details, 'photo') ? _.get(details, 'photo') : DEFAULTUSER} alt="img" />
        </div>
        <div className="tutorname">{_.get(details, 'user_handle') ? _.get(details, 'type') === 'Individual' ? `${_.startCase(_.get(details, 'firstname'))} ${_.get(details, 'lastname', '')}` : `${_.startCase(_.get(details, 'businessname', '---'))}` : _.get(details, 'type') === 'Individual' ? `${_.startCase(_.get(details, 'firstname'))} ${_.get(details, 'lastname', '')}` : `${_.startCase(_.get(details, 'businessname', '---'))}`}</div>
      </div>
    );
  };

  onFinish = (credentials, signInType) => {
    const body = { profile_type: 1, works_for: _.get(this, 'props.tutorBaseProfile.details.id'), ...credentials };
    this.props.createProvider({
      body,
      callback: (res, data) => {
        if (res === true && data) {
          notification.success({
            message: 'Success',
            description: 'Tutor has been created successfully.',
          });

          this.onConfirmTutorAdd(_.get(data, 'details'));
          const requestParams = {};
          requestParams.limit = 4;
          requestParams.works_for = _.get(this, 'props.tutorBaseProfile.details.id');
          requestParams.sort = 'created_at.desc';
          this.props.tutorList({ params: requestParams });
          this.setCreateTutorModal(false, '');
        } else {
          notification.error({
            message: 'Error',
            description: _.get(data, 'message'),
          });
        }
      },
    });
  };

  onConfirmTutorAdd = (item) => {
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Do you want to complete this tutor\'s profile?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        history.push(`${routeName.tutorEditProfileOthers}/?id=${_.get(item, 'id')}`);
        Modal.destroyAll();
      },
      onCancel() {
        Modal.destroyAll();
      },
    });
  };

  passwordValidation = (rule, value, callback, message) => {
    const passw = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?]).{8,}/;
    if (value && !value.match(passw)) {
      callback(message);
    } else {
      callback();
    }
  };

  renderModal = () => (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="row ml-8">
        <div className="col-12 mr-2">
          <Form autoComplete="off" ref={this.form} scrollToFirstError onFinish={(e) => this.onFinish(e, 'user')} className="login-form">
            <FormItem
              autoComplete="off"
              name="username"
              rules={[{
                required: true,
                message: this.props.t(
                  'authenticationEmailValidationMessage',
                ),
              }, {
                type: 'email',
                message: this.props.t(
                  'authenticationEmailValidationMessage',
                ),
              }]}
            >
              <Input
                autoComplete="off"

                prefix={<i className="ntm-envelope" />}
                size="large"
                placeholder="Your Email ID"
              />

            </FormItem>
            <FormItem
              autoComplete="new-password"
              name="password"
              rules={[{
                required: true,
                message: this.props.t(
                  'authenticationRequiredPasswordValidationMessage',
                ),
              }, {
                validator: (rule, value, callback) => this.passwordValidation(
                  rule,
                  value,
                  callback,
                  this.props.t(
                    'enterPass3',
                  ),
                ),
              }]}
            >
              <Input.Password
                autoComplete="new-password"
                size="large"
                prefix={<AiFillLock />}
                type="password"
                placeholder="Password"
              />

            </FormItem>
            <div className="full-width mb-3 mt-2">
              <div className="row login-input">
                <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <Button
                    loading={_.get(this.props, 'createLoadingInProgress')}
                    type="primary"
                    htmlType="submit"
                    className="btn btn-sm btn-login"
                  >
                    {this.props.t('submit')}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );

  scrollToTop() {
    // Scroll to top logic
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    const {
      tutorRow_id,
      description, page,
    } = this.state;

    const ListingTutorscolumns = [{
      title: this.props.t('tutName'),
      dataIndex: 'tutor',
      key: 'tutor',
      width: '30%',
    },
    {
      title: this.props.t('tutName'),
      dataIndex: 'email',
      key: 'email',
      width: '30%',
    },
    {
      title: this.props.t('phNo'),
      dataIndex: 'phone',
      key: 'phone',
      width: '15%',
    },
    {
      title: this.props.t('Action'),
      key: 'action',
      width: '25%',
      render: (text, record) => {
        console.log('record', record);
        return (
          <Space size="middle">
            <div>
              {_.get(record, 'tutorDetails.user_handle') === null ? (
                <Link to={`${routeName.tutorEditProfileOthers}/?id=${_.get(record, 'tutorDetails.id')}`}>
                  <button className="btn btn-accept  mr-1">{this.props.t('edit')}</button>
                </Link>
              )
                : (
                  <Link to={`${routeName.tutorEditProfileOthers}/?id=${_.get(record, 'tutorDetails.id')}&userhandle=${_.get(record, 'tutorDetails.user_handle')}`}>
                    <button className="btn btn-accept  mr-1">{this.props.t('edit')}</button>
                  </Link>
                )}
            </div>
            <div>
              {_.get(record, 'tutorDetails.user_handle') !== null ? (
                <Link
                  to={`/profile/${_.get(record, 'tutorDetails.user_handle')}`}
                  target="_blank"
                >
                  <button
                    className="btn btn-accept mr-1"
                    style={{ cursor: _.get(record, 'tutorDetails.user_handle') === null ? 'not-allowed' : 'default' }}
                  >
                    {this.props.t('profile')}
                  </button>
                </Link>
              )
                : ''}
            </div>
          </Space>
        );
      }
      ,
    },
    ];
    let tutorData = [];
    if (
      this.props.tutorsList
            && this.props.tutorsList.items
            && this.props.tutorsList.items.length > 0
    ) {
      tutorData = this.state.tutorTableData.map((value, index) => ({
        tutor: this.titleRender(value),
        email: _.get(value, 'email'),
        phone: _.get(value, 'mobile_number') ? _.get(value, 'mobile_number') : '---',
        key: value.id,
        tutorDetails: value,
      }));
    }
    return (
      <div className="full-width tutor-my-class-page pb-4 mb-3">
        <div className="row">
          {urlparams && !urlparams.classId && (
          <div className="col-12">
            <div className="nurtem-card-design nurtem-table py-4 mb-3">
              <div className="d-flex justify-content-between  mb-3">
                <div className="message-title ">
                  <span style={css.heading1}>{this.props.t('listingTutors')}</span>
                </div>
                <Button onClick={() => this.setCreateTutorModal(true, '')} icon={<AiOutlinePlus />} type='primary' className="class-btn">
                  {this.props.t('addTut')}
                </Button>
              </div>
              <div className="schedule-content mt-2 class-details-table">
                {this.props.tutorListLoading ? (
                  <ClassDetailLoader />
                ) : (
                  <Table
                    rowClassName={(record, index) => (record.key === tutorRow_id ? 'active' : '')}
                    columns={ListingTutorscolumns}
                    dataSource={tutorData}
                    pagination={{

                      pageSize: this.state.limit,
                      onChange: (page, pageSize) => this.pageChange(page, pageSize),
                      total:
                                                this.props.tutorsList
                                                && this.props.tutorsList.meta
                                                && parseInt(this.props.tutorsList.meta.total_count),
                      hideOnSinglePage: !this.props.loading || this.props.loading,
                      showSizeChanger: false,
                      current: page,

                    }}
                  />
                )}
              </div>
            </div>
          </div>
          )}
        </div>

        <div className="scroll-top">
          <AiOutlineUpSquare onClick={this.scrollToTop} />
        </div>

        <Modal
          destroyOnClose
          className="details-group-view-modal"
          title={this.props.t('createTutor')}
          open={this.state.createTutorModal}
          onOk={() => this.setCreateTutorModal(false, '')}
          onCancel={() => this.setCreateTutorModal(false, '')}
          footer={false}
        >
          {this.renderModal()}
        </Modal>

        <Modal
          className="details-group-view-modal"
          title="Description"
          open={this.state.modal1Visible}
          onOk={() => this.setModal1Visible(false, '')}
          onCancel={() => this.setModal1Visible(false, '')}
          footer={false}
        >
          {parser(NurtemUtils.urlify(description))}
        </Modal>

      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  loading: state.classesDetails.loading,
  tutorsList: _.get(state, 'general.tutor.list'),
  tutorListLoading: _.get(state, 'general.tutor.listLoadingInProgress'),
  createLoadingInProgress: _.get(state, 'general.tutor.createLoadingInProgress'),

});

const mapDispatchToProps = (dispatch) => ({
  tutorList: (data) => dispatch(generalAction.tutor.tutorList(data)),
  createProvider: (data) => dispatch(generalAction.tutor.createProvider(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TutorClasses));
