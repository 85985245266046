import React, { useEffect, useState } from 'react';
import {
  Progress, Rate, Spin, Pagination,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import * as ratings from '../../store/actions/public/rating';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import { DEFAULTUSER } from '../../utils/config/assets';
import css from '../../utils/CSSUtility';

const Ratings = (props) => {
  // console.log('propsnow======', props)
  const [clsRating, setClsRating] = useState(0);
  const [ratingList, setratingList] = useState(null);
  const [ratingList_meta, setratingList_meta] = useState(null);
  const [review_loading, setreview_loading] = useState(false);
  const [singleClassView, setSingleClassView] = useState(props.session);
  const [page, setPage] = useState(1);
  const [limit] = useState(3);
  const [oneStarPercentage, setOneStarPercentage] = useState(0);
  const [twoStarPercentage, setTwoStarPercentage] = useState(0);
  const [threeStarPercentage, setThreeStarPercentage] = useState(0);
  const [fourStarPercentage, setFourStarPercentage] = useState(0);
  const [fiveStarPercentage, setFiveStarPercentage] = useState(0);

  useEffect(() => {
    // Calculate counts and percentages for each star rating category
    if (_.get(props, 'onRatingListFull.items', [])) {
      const items = _.get(props, 'onRatingListFull.items', []);
      const oneStarCount = items.filter((item) => item.no_of_stars === 1).length;
      const twoStarCount = items.filter((item) => item.no_of_stars === 2).length;
      const threeStarCount = items.filter((item) => item.no_of_stars === 3).length;
      const fourStarCount = items.filter((item) => item.no_of_stars === 4).length;
      const fiveStarCount = items.filter((item) => item.no_of_stars === 5).length;

      const totalCount = items.length;

      const oneStarPct = ((oneStarCount / totalCount) * 100).toFixed(0);
      const twoStarPct = ((twoStarCount / totalCount) * 100).toFixed(0);
      const threeStarPct = ((threeStarCount / totalCount) * 100).toFixed(0);
      const fourStarPct = ((fourStarCount / totalCount) * 100).toFixed(0);
      const fiveStarPct = ((fiveStarCount / totalCount) * 100).toFixed(0);

      // Update state variables with percentages
      setOneStarPercentage(oneStarPct);
      setTwoStarPercentage(twoStarPct);
      setThreeStarPercentage(threeStarPct);
      setFourStarPercentage(fourStarPct);
      setFiveStarPercentage(fiveStarPct);
      return () => {
        setOneStarPercentage(0);
        setTwoStarPercentage(0);
        setThreeStarPercentage(0);
        setFourStarPercentage(0);
        setFiveStarPercentage(0);
        setClsRating(0);
        setratingList(0);
        setratingList_meta([]);
        setreview_loading(false);
      }
    }
  }, [_.get(props, 'onRatingListFull.items', [])]);

  useEffect(() => {
    setSingleClassView(props.session);
  }, [props.session]);

  useEffect(() => {
    setreview_loading(true);
    // console.log('propsinside========',props)
    if (props.type === 'provider') {
      props.listOfRatings({
        type: props.type,
        provider: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'provider,user',
        user: _.get(props, 'getUsersId'),
      });
      props.listRatings({
        type: props.type,
        provider: props.session.id,
        sort: 'created_at.desc',
        limit: 1000,
        user: _.get(props, 'getUsersId'),
      });
    } else if (props.type === 'camps') {
      props.listOfRatings({
        type: props.type,
        camp_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'camp_details,user',
        user: _.get(props, 'getUsersId'),
      });
      props.listRatings({
        type: props.type,
        camp_details: props.session.id,
        sort: 'created_at.desc',
        limit: 1000,
        user: _.get(props, 'getUsersId'),
      });
    } else if (props.type === 'classes') {
      props.listOfRatings({
        type: props.type,
        class_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'class_details,user',
        user: _.get(props, 'getUsersId'),
      });
      props.listRatings({
        type: props.type,
        class_details: props.session.id,
        sort: 'created_at.desc',
        limit: 1000,
        user: _.get(props, 'getUsersId'),
      });
    } else if (props.type === 'competition') {
      props.listOfRatings({
        type: props.type,
        competition_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'competition_details,user',
        user: _.get(props, 'getUsersId'),
      });
      props.listRatings({
        type: props.type,
        competition_details: props.session.id,
        sort: 'created_at.desc',
        limit: 1000,
        user: _.get(props, 'getUsersId'),
      });
    } else if (props.type === 'event') {
      props.listOfRatings({
        type: props.type,
        event_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'event_details,user',
        user: _.get(props, 'getUsersId'),
      });
      props.listRatings({
        type: props.type,
        event_details: props.session.id,
        sort: 'created_at.desc',
        limit: 1000,
        user: _.get(props, 'getUsersId'),
      });
    }

    setClsRating(_.get(props, 'getRatingList.items[0].no_of_stars', 0));
    setratingList(_.get(props, 'getRatingList.items', []));
    setratingList_meta(_.get(props, 'getRatingList.meta', []));
    setreview_loading(false);
  }, [props.session]);

  useEffect(() => {
    setClsRating(_.get(props, 'getRatingList.items[0].no_of_stars', 0));
    setratingList(_.get(props, 'getRatingList.items', []));
    setratingList_meta(_.get(props, 'getRatingList.meta', []));
  }, [props.getRatingList]);

  const ratingpageChange = (page) => {
    setPage(page);
    if (props.type === 'provider') {
      props.listOfRatings({
        type: props.type,
        provider: props.session.id,
        expand: 'session_details,user',
        sort: 'created_at.desc',
        page,
        limit,
      });
    } else if (props.type === 'camps') {
      props.listOfRatings({
        type: props.type,
        session_details: props.session.id,
        sort: 'created_at.desc',
        expand: 'session_details,user',
        user: _.get(props, 'getUsersId'),
        page,
        limit,
      });
    } else if (props.type === 'classes') {
      props.listOfRatings({
        type: props.type,
        class_details: props.class_details,
        sort: 'created_at.desc',
        expand: 'session_details,user',
        user: _.get(props, 'getUsersId'),
        page,
        limit,
      });
    } else if (props.type === 'competition') {
      props.listOfRatings({
        type: props.type,
        competition_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'competition_details,user',
        user: _.get(props, 'getUsersId'),
      });
    } else if (props.type === 'event') {
      props.listOfRatings({
        type: props.type,
        event_details: props.session.id,
        sort: 'created_at.desc',
        page,
        limit,
        expand: 'event_details,user',
        user: _.get(props, 'getUsersId'),
      });
    }
  };

  const render = () => {
    // console.log('review1=======', clsRating)
    // console.log('review2=======', ratingList)
    // console.log('review3=======', ratingList_meta)
    // console.log('review4=======', singleClassView)
    console.log('review5=======', props.getRatingList);
    console.log('review6=======', props.onRatingListFull);
    return (
      (clsRating) > 0 || (ratingList && ratingList.length > 0) ? (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
          <div className="sub-title">Reviews</div>
          <div className="col-12">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-lg-6 col-xl-6 text-center my-auto  review-border-end">
                <div className="star-rating mb-3">
                  <h1 className="text-view mb-0 font-weight-semibold fs-60">
                    {Number.isInteger(_.get(singleClassView, 'average_rating', 0)) ? _.get(singleClassView, 'average_rating', 0) : _.get(singleClassView, 'average_rating', 0).toFixed(1)}
                  </h1>
                  <p className="mb-0 font-weight-bold">{props.t('outof5')}</p>
                  <Rate
                    disabled="true"
                    value={_.parseInt(_.get(singleClassView, 'average_rating', 0))}
                  />
                  <p className="review-rating-count-class mb-3">
                    (
                    {parseInt(_.get(singleClassView, 'rating_count'))}
                    {' '}
                    {props.t('rating')}
                    )
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-5 col-xl-4    col-xl-4  my-auto">
                <div className="ps-0 ps-md-5">
                  <div className="rating-progress-set">
                    <div className="rating-progress-text">
                      5
                      {props.t('star')}
                    </div>
                    <Progress percent={fiveStarPercentage} />
                  </div>
                  <div className="rating-progress-set">
                    <div className="rating-progress-text">
                      4
                      {props.t('star')}
                    </div>
                    <Progress percent={fourStarPercentage} />
                  </div>
                  <div className="rating-progress-set">
                    <div className="rating-progress-text">
                      3
                      {props.t('star')}
                    </div>
                    <Progress percent={threeStarPercentage} />
                  </div>
                  <div className="rating-progress-set">
                    <div className="rating-progress-text">
                      2
                      {props.t('star')}
                    </div>
                    <Progress percent={twoStarPercentage} />
                  </div>
                  <div className="rating-progress-set">
                    <div className="rating-progress-text">
                      1
                      {props.t('star')}
                    </div>
                    <Progress percent={oneStarPercentage} />
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
            <div className="card-body">

              <div className="user-review-container">
                {Array.isArray(ratingList) && ratingList.map((review, index) => (
                  <div key={index} className="user-review-set">
                    <div className="user-review-set-header">
                      <div className="class-profile-image">
                        <img
                          src={_.get(review, 'user.photo') ? _.get(review, 'user.photo') : DEFAULTUSER}
                          className="avatar"
                          alt="img"
                        />
                      </div>
                      <div className="profile-name">
                        {_.get(review, 'user.firstname')}
                        {' '}
                        {_.get(review, 'user.lastname', '') !== '' ? `${_.startCase(_.get(review, 'user.lastname', ''))}` : ''}
                      </div>
                    </div>
                    <div style={{ marginLeft: '45px' }}>
                      <div className="star-rating">
                        <Rate
                          disabled="true"
                          defaultValue={_.get(review, 'no_of_stars', 0)}
                        />
                      </div>
                      <div className="user-review-set-text">
                        {_.get(review, 'text')}
                      </div>
                      <div className="user-review-set-info">
                        Reviewed in India on
                        {' '}
                        {dayjs(_.get(review, 'created_at')).format('MMM DD YYYY')}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* </Scrollbars> */}
            </div>
          </div>
          {_.get(ratingList_meta, 'total_count') > 3 && (
            <Pagination
              current={page}
              pageSize={limit}
              total={_.get(ratingList_meta, 'total_count')}
              onChange={ratingpageChange}
            />
          )}
        </div>
      ) : review_loading === true ? (
        <div className="nurtem-card-design mt-2 mb-3">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
              <div
                className="class-preview-content"
                style={{ height: '240px' }}
              >
                {' '}
                <Spin
                  size="large"
                  tip="Loading...."
                  style={{
                    left: 0,
                    right: 0,
                    bottom: 0,
                    top: 0,
                    position: 'absolute',
                    marginTop: '100px',
                  }}
                />
                {' '}
              </div>
            </div>
          </div>
          {' '}
        </div>
      ) : (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
          <div style={css.heading1}>{props.t('r&r')}</div>
          <div>
            <EmptyAnime AnimeType="Empty" loop animeheight={150} />

            <p className="empty-animations" style={{ color: '#cbcbcb' }}>{props.t('nor&r')}</p>
          </div>
        </div>
      )
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  getRatingList: _.get(state, 'rating.onRatingList'),
  onRatingListFull: _.get(state, 'rating.onRatingList'),
});

const mapDispatchToProps = (dispatch) => ({
  listRatings: (data, callback) => dispatch(ratings.listRatingsViewDetails(data, callback)),
  listOfRatings: (requestParams) => dispatch(ratings.listRatings(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Ratings));
