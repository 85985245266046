import React from 'react'
import {
  AiOutlineExclamationCircle,
  AiOutlineLaptop,
  AiOutlineCalendar,
} from 'react-icons/ai'
import { Tooltip, Carousel } from 'antd'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import SessionsLeft from '../../re-usable/SessionsLeft'
import timeZone from '../../common/timezone'
import timeDateConverterSession from '../../timezone/sessionTimeDate'

function FullDetails(props) {
  const totalsessions = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sessLeftLabel')}</span>
    </span>
  )
  const sessionsleft = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sessLeftLabel1')}</span>
    </span>
  )
  const sessionsattended = (
    <span>
      {' '}
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>{' '}
      <span className="tooltip-text">{props.t('sessLeftLabel2')}</span>
    </span>
  )
  const { session_details } = props
  const sessionAttended = _.get(session_details, 'session_attended', 0)
  const DateType =
    props.type === 'camp'
      ? props.t('campDate')
      : props.type === 'classes'
        ? props.t('clsDate')
        : props.type === props.t('tutorSidebarMyCompetitions')
          ? props.t('compDate')
          : ''

  const timings = _.get(session_details, 'time_slot', {})
  const items =
    timings &&
    Object.entries(timings).map(([day, timings]) => (
      <div className="dashboard-profile-name" key={day}>
        {day} -{' '}
        {timeDateConverterSession(timings?.start_time, 'time', 'hh:mm A')} to{' '}
        {timeDateConverterSession(timings?.end_time, 'time', 'hh:mm A')}
      </div>
    ))
  return (
    <div className="row  session-info">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 user-session">
        {session_details && (
          <div className="user-details-right col-12">
            <div className="row align-items-center py-3">
              {props.type !== 'competitions' && props.type !== 'events' && (
                <>
                  <div className="col-12 col-md-6 pr-md-0">
                    <div className="session-block">
                      <div
                        className="session-icon mr-2"
                        style={{ background: '#FFEDED' }}
                      >
                        <AiOutlineCalendar style={{ fontSize: 20 }} />
                      </div>
                      <div>
                        <div className="session-text-regular">
                          <Tooltip placement="topLeft" title={totalsessions}>
                            {props.t('totDay')}
                          </Tooltip>
                        </div>
                        {session_details.session_dates &&
                          session_details.session_dates.length > 0
                          ? session_details.session_dates.length
                          : '---'}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="session-block">
                      <div
                        className="session-icon mr-2"
                        style={{ background: '#EEEBFF' }}
                      >
                        <AiOutlineCalendar style={{ fontSize: 20 }} />
                      </div>
                      <div>
                        <div
                          className="session-text-regular"
                          style={{ width: '100%', float: 'left' }}
                        >
                          <Tooltip placement="topLeft" title={sessionsleft}>
                            {props.t('sLeft')}
                          </Tooltip>
                        </div>
                        {SessionsLeft(session_details.session_dates)}
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* </div>
                        <div className='row align-items-center py-3'> */}
              {props.type !== 'competitions' && props.type !== 'events' && (
                <div div className="col-12 col-md-6 pr-md-0">
                  <div className="session-block">
                    <div
                      className="session-icon mr-2"
                      style={{ background: '#F7FECC' }}
                    >
                      <AiOutlineLaptop style={{ fontSize: 20 }} />
                    </div>
                    <div>
                      <div className="session-text-regular">
                        <Tooltip placement="topLeft" title={sessionsattended}>
                          {props.t('sessAtt')}
                        </Tooltip>
                      </div>
                      <div
                        className="session-text-bold"
                        style={{ paddingTop: 1 }}
                      >
                        {sessionAttended}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-12 col-md-6 pr-md-0">
                <div className="session-block">
                  <div
                    className="session-icon mr-2"
                    style={{ background: '#ffeded' }}
                  >
                    <AiOutlineCalendar style={{ fontSize: 20 }} />
                  </div>
                  <div>
                    <div className="session-text-regular">{DateType} </div>
                    <div className="session-text-bold">
                      {`${timeDateConverterSession(session_details.start_date, 'date', 'MMM DD,YYYY')} - ${timeDateConverterSession(session_details.end_date, 'date', 'MMM DD,YYYY')}`}
                    </div>
                    <div className="session-text-regular">
                      <span className="flex">
                        <Carousel autoplay={true} autoplaySpeed={1000}>
                          {items}
                        </Carousel>{' '}
                        {timeZone()}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default withTranslation()(FullDetails)
