import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  Form, AutoComplete, Button, Popconfirm, InputNumber,
} from 'antd';
import _ from 'lodash';
import './style.css';
import { withTranslation } from 'react-i18next';
import GenList from '../../../components/re-usable/list-with-row-selection';
import * as actions from '../../../store/actions/tutor/payments';
import { OfflinePaymentLoader } from '../../../components/loader/common/offline-payment-loader';
import css from '../../../utils/CSSUtility';

const Payments = (props) => {
  const [updatedAmounts, setUpdatedAmounts] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedRows, setSelectedRows] = useState(0);
  const autocompleteStudentList = useRef(false);
  const [form] = Form.useForm();
  const importedStudentTablecolumns = [
    {
      title: props.t('billingType'),
      dataIndex: 'billing_type_str',
      width: '20%',
    },
    {
      title: props.t('cls&cmp'),
      dataIndex: 'class_type_label',
      width: '5%',
    },
    {
      title: props.t('nameLabel'),
      dataIndex: 'className',
      width: '15%',
    },
    {
      title: props.t('tutorLabel'),
      dataIndex: 'providerName',
      width: '15%',
    },
    {
      title: props.t('userLabel'),
      dataIndex: 'user_name',
      width: '15%',
    },
    {
      title: props.t('depLabel'),
      dataIndex: 'dependent_user_name',
      width: '15%',
    },
    {
      title: props.t('sesAtt'),
      dataIndex: 'session_attended',
    },
    {
      title: props.t('sessions'),
      dataIndex: 'number_session_paid',
      width: '10%',
    }, {
      title: props.t('price'),
      dataIndex: 'price',
      width: '10%',
    },
    {
      title: props.t('batchDetails35'),
      dataIndex: 'tax_percentage',
      width: '10%',
    },
    {
      title: props.t('incCountMnth'),
      dataIndex: 'increaseCount',
      width: '10%',
      render: (_, record) => {
        if (record.billing_type === 2) {
          return record.targetMonthName;
        }
        return (
          <InputNumber
            type="number"
            min={1}
            defaultValue={record.num_session_toPay}
            max={record.num_session_toPay}
            disabled={record.billing_type !== 1}
            onChange={async (value) => {
              if (value === null) {
                return;
              }
              const increasedCount = value;
              record.sessionPurchased = value;
              const updatedAmount = (increasedCount * record.price * (1 + record.tax_percentage / 100));
              record.amountToBePaidForThisClass = updatedAmount;
              props.setBalanceAmount(updatedAmount);
              setUpdatedAmounts((prev) => ({ ...prev, [record.key]: updatedAmount }));
            }}
          />
        );
      },
    },
    {
      title: props.t('balAmount'),
      dataIndex: 'amountToBePaidForThisClass',
      width: '10%',
      render: (_, record) => <span>{updatedAmounts[record.key] ? updatedAmounts[record.key] : record.amountToBePaidForThisClass}</span>,
    },
  ];

  const onSelect = (val, option) => {
    // clear previously student_payment_information data
    setSelectedUser(option);
    props.clear_student_payment_info();
    props.get_student_payment_info(option);
  };

  useEffect(() => {
    // this code will be called on component mounted
    autocompleteStudentList.current = true;

    if (autocompleteStudentList.current) {
      props.getStudentList();
    }

    // return will be called on component unmount
    return () => {
      autocompleteStudentList.current = false;

      // clean up process on exit screen
      props.on_change_payment_option([]);
      props.clear_student_payment_info();
      props.clear_student_list_by_provider();
      props.setBalanceAmount(0);
    };
  }, []);

  useEffect(() => {
    if (props.reload && selectedUser) {
      props.get_student_payment_info(selectedUser);
    }
  }, [props.reload]);

  // used in check box row selection
  const rowSelection = {
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      setSelectedRows(selectedRows.length);
      props.on_change_payment_option(selectedRows);
      const result = _.round(_.sumBy(selectedRows, 'amountToBePaidForThisClass'), 2);
      props.setBalanceAmount(result);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows.length);
      if (selected) {
        props.on_change_payment_option(selectedRows);
        const result = _.round(_.sumBy(selectedRows, 'amountToBePaidForThisClass'), 2);
        props.setBalanceAmount(result);
      } else {
        props.setBalanceAmount(0);
        props.on_change_payment_option([]);
      }
    },
  };

  function payButton() {
    if (_.get(props.student_payment_information, ['moredata', 'billAmount']) > 0) {
      return (props.offline_payment_object.length > 0 && _.get(props, 'balance_amount') !== 0 && selectedRows !== 0 ? (
        <Popconfirm onConfirm={() => props.payOffline(props.offline_payment_object)} title="Are you sure？" okText="Yes" cancelText="No">
          <Button type="primary">{props.t('paidLabel1')}</Button>
        </Popconfirm>
      ) : props.t('paidLabel2'));
    }
    return props.offline_payment_object.length > 0 ? props.t('paidLabel3') : '';
  }

  const render = () => {
    // console.log('offlinestudent list======>', props.reload)

    if (props.is_studentListLoaded) {
      return (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="nurtem-card-design nurtem-table payments-page py-4 mb-3">
              <div className="message-title mb-3 d-flex justify-content-between">
                <span style={css.heading1}>{props.t('tutorSidebarOfflinePayments')}</span>
              </div>
              <div
                className="col-12"
                style={{
                  display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '10px',
                }}
              >

                <Form
                  form={form}
                  initialValues={{ remember: true }}
                  autoComplete="off"
                >

                  <Form.Item
                    label={<span style={css.label}>{props.t('searchStu')}</span>}
                    name="enrolled_student"
                    rules={[{ required: false, message: 'Please input a name!' }]}
                    className="mb-2"
                  >
                    <AutoComplete
                      ref="autocompleteStudentList"
                      allowClear
                      autoFocus
                      onClear={() => setSelectedUser({})}
                      style={{
                        width: 300,
                      }}
                      options={props.student_list_by_provider}
                      placeholder="Student name here..."
                      filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                      onSelect={(val, option) => onSelect(val, option)}
                    />
                  </Form.Item>
                </Form>
              </div>
              <GenList rowSelection={rowSelection} key="paymentstatus" selectionType="checkbox" title={<span style={css.heading2}>{props.t('payStatus')}</span>} col={importedStudentTablecolumns} data={_.get(props.student_payment_information, ['data'])} />

              <div className="col-12" style={{ display: 'flex', justifyContent: 'end' }}>
                <table className="table" style={{ width: '350px' }}>
                  <tbody>
                    <tr className="table-info-offline-payment">
                      <td>{props.t('totBillAmnt')}</td>
                      <td>{_.round(_.get(props, 'balance_amount'), 2)}</td>
                    </tr>
                    <tr>
                      <td colSpan={2} style={{ textAlign: 'right' }}>
                        {payButton()}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <OfflinePaymentLoader />;
  };

  return render();
};

const mapStateToProps = (state) => ({
  offline_payment_object: state.payments.offline_payment_object,
  balance_amount: state.payments.balance_amount,
  reload: state.payments.reload,
  is_studentListLoaded: state.payments.student_list_loaded,
  student_list_by_provider: state.payments.student_list_by_provider,
  student_payment_requestdata: state.payments.student_payment_information.requestdata,
  student_payment_information: state.payments.student_payment_information.responsedata,
});

const mapDispatchToProps = (dispatch) => ({

  on_change_payment_option: (data) => dispatch(actions.on_receiving_payment_object(data)),
  getStudentList: () => dispatch(actions.getStudentList()),
  get_student_payment_info: (data) => dispatch(actions.get_student_payment_info(data)),
  clear_student_payment_info: () => dispatch(actions.on_receiving_student_payment_information({})),
  payOffline: (data) => dispatch(actions.payOffline(data)),
  student_list_loaded: (data) => dispatch(actions.student_list_loaded(data)),
  setBalanceAmount: (data) => dispatch(actions.balanceAmount(data)),
  clear_student_list_by_provider: () => dispatch(actions.providerstudents([])),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Payments));
