import axios from '../../../utils/axios';
// import { notification } from 'antd'
export const onDeleteUploads = (requestParams, callback = () => { }) => (dispatch) => {
  axios({
    method: 'post',
    url: '/s3/delete-one',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        callback(response);
        // dispatch(classDeleteNoteSuccess(deleteResponse))
        // const message_args = {
        //   duration: 2,
        //   message: response.data.message
        // }
        // notification.success(message_args)
      }
    })
    .catch((error) => {
      callback(error);
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      // dispatch(classDeleteNoteSuccess(deleteResponse))
    });
};

export const commonUplods = (data, type) => ({
  type,
  payload: data,
});

export const getUploadedList = (requestParams) => (dispatch) => {
  // dispatch(enableLoading())
  axios
    .get('/common/get-uploads', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(commonUplods(response.data, requestParams.subcat));
        // dispatch(disableLoading())
      }
    })
    .catch((error) => {
      // dispatch(disableLoading())
    });
};
