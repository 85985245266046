import * as actionTypes from '../../actions/tutor/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: {
    loading: false,
  },

  CommissionConfigurations: {},
};

const CommissionConfigurations = (state, action) => ({ ...state, CommissionConfigurations: action.success });

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.enableLoading:
      return enableLoading(state, action);

    case actionTypes.disableLoading:
      return disableLoading(state, action);

    case actionTypes.CommissionPercentages:
      return CommissionConfigurations(state, action);

    default:
      return state;
  }
};

export default reducer;
