import React from 'react';
// import PublicAccount from '../containers/public/index'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import history from '../utils/history';
import * as actions from '../store/actions/authentication/authentication';
import { DefaultLoader } from '../components/loader/default-loader';
import Account from '../containers/account';
import TutorAccount from '../containers/tutor';
import UserAccount from '../containers/user';
import * as routeName from '../components/route-names';
import * as servicesActions from '../store/actions/public/Services';

class WebLayout extends React.Component {
  componentDidMount() {
    this.props.onLogin();
    history.listen((location, action) => {
      if (action === 'POP') {
        // window.location.reload()
      }
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location
      && prevProps.location.pathname !== this.props.location
      && this.props.location.pathname) {
      if (this.props.location.pathname.indexOf(routeName.TutorPublicProfile.split(':')[0]) > -1) {
        this.props.onTutorClassCampsSiderbar(true);
        this.props.onTutorDashboardsiderbar(false);
        this.props.onTutorSiderbarLogo(true);
        this.props.onGuestsiderbar(false);
      } else if (this.props.location.pathname.indexOf(routeName.search.split(':')[0]) > -1) {
        this.props.onTutorClassCampsSiderbar(true);
        this.props.onTutorDashboardsiderbar(false);
        this.props.onTutorSiderbarLogo(false);
        this.props.onGuestsiderbar(false);
      } else if (this.props.location.pathname.indexOf(routeName.viewClassDetails.split(':')[0]) > -1
        || this.props.location.pathname.indexOf(routeName.viewCampDetails.split(':')[0]) > -1
        || this.props.location.pathname.indexOf(routeName.viewCompetitionDetails.split(':')[0]) > -1) {
        this.props.onTutorClassCampsSiderbar(true);
        this.props.onTutorDashboardsiderbar(false);
        this.props.onTutorSiderbarLogo(true);
        this.props.onGuestsiderbar(false);
      } else if (this.props.loggedInUserDetails
        && this.props.loggedInUserDetails.isLoggedIn
        && this.props.loggedInUserDetails.login_type === 'user') {
        if (this.props.location.pathname.indexOf(routeName.termsAndConditions) > -1
          || this.props.location.pathname.indexOf(routeName.CookiePolicy) > -1
          || this.props.location.pathname.indexOf(routeName.PrivacyPolicy) > -1) {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(false);
          this.props.onTutorSiderbarLogo(false);
          this.props.onGuestsiderbar(false);
          window.scrollTo(0, 0);
        } else {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(true);
          this.props.onTutorSiderbarLogo(true);
          this.props.onGuestsiderbar(false);
        }
      } else if (this.props.loggedInUserDetails
        && this.props.loggedInUserDetails.isLoggedIn
        && this.props.loggedInUserDetails.login_type === 'provider') {
        if (this.props.location.pathname.indexOf(routeName.termsAndConditions) > -1 || this.props.location.pathname.indexOf(routeName.CookiePolicy) > -1 || this.props.location.pathname.indexOf(routeName.PrivacyPolicy) > -1) {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(false);
          this.props.onTutorSiderbarLogo(false);
          this.props.onGuestsiderbar(false);
          window.scrollTo(0, 0);
        } else if (this.props.location.pathname.indexOf(routeName.TutorBaseProfileStep1) > -1) {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(true);
          this.props.onTutorSiderbarLogo(false);
          this.props.onGuestsiderbar(false);
        } else {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(true);
          this.props.onTutorSiderbarLogo(true);
          this.props.onGuestsiderbar(false);
        }
      } else if (this.props.loggedInUserDetails && this.props.loggedInUserDetails.isLoggedIn === false) {
        if (this.props.location.pathname.indexOf(routeName.myCart) > -1 || this.props.location.pathname.indexOf(routeName.guestUpdate) > -1) {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(false);
          this.props.onGuestsiderbar(true);
          this.props.onTutorSiderbarLogo(true);
        } else {
          this.props.onTutorClassCampsSiderbar(false);
          this.props.onTutorDashboardsiderbar(false);
          this.props.onTutorSiderbarLogo(false);
          this.props.onGuestsiderbar(false);
        }
      }
    }
  }

  render() {
    let pageRedirect;
    // pageRedirect = <Account {...this.props} />
    if (
      this.props.loggedInUserDetails.isLoggedIn
      && this.props.loggedInUserDetails.login_type === 'provider'
    ) {
      pageRedirect = <TutorAccount {...this.props} />;
    } else if (
      this.props.loggedInUserDetails.isLoggedIn
      && this.props.loggedInUserDetails.login_type === 'user'
    ) {
      pageRedirect = <UserAccount {...this.props} />;
    } else if (this.props.loggedInUserDetails.isLoggedIn === false) {
      pageRedirect = <Account {...this.props} />;
    } else {
      pageRedirect = <DefaultLoader />;
    }

    return <div>{pageRedirect}</div>;
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  loggedInUserDetails: state.authentication,
  onGoBack: state.authentication.goBack,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: () => dispatch(actions.onCheckLogin()),
  disableLoading: () => dispatch(actions.disableLoading()),
  onTutorSiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),
  onTutorClassCampsSiderbar: (requestParams) => dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
  onTutorDashboardsiderbar: (requestParams) => dispatch(servicesActions.onTutorDashboardsiderbar(requestParams)),
  onGuestsiderbar: (requestParams) => dispatch(servicesActions.onGuestsiderbar(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(WebLayout));
