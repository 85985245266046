/**
 * @author ramsteyn17@gmail.com
 * @dated 31/01/2023
 * This is to calculate the next session on tutor & user My classes. Using class_dates from the db.
 */
import React from 'react'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import timeDateConverterSession from '../timezone/sessionTimeDate'
import timeZone from '../common/timezone'

dayjs.extend(isSameOrBefore)

const nextSession = (value, type) => {
  const getDatesBetween = (start, end) => {
    const dateFormat = 'MMM DD,YYYY'
    const dates = []
    let currentDate = dayjs(start, dateFormat)

    while (currentDate.isSameOrBefore(dayjs(end, dateFormat))) {
      dates.push(currentDate.format(dateFormat))
      currentDate = currentDate.add(1, 'day')
    }

    return dates
  }

  if (!value) {
    return <div style={{ display: 'flex', alignItems: 'center' }}>---</div>
  }

  const currentDate = dayjs()
  const liveDate = currentDate.format('MMM DD,YYYY')

  let date
  let timeString

  const { start_date } = value
  const { end_date } = value
  const actual_start_date = timeDateConverterSession(
    start_date,
    'date',
    'MMM DD,YYYY'
  )
  const actual_end_date = timeDateConverterSession(
    end_date,
    'date',
    'MMM DD,YYYY'
  )
  if (dayjs(actual_end_date).isBefore(currentDate, 'day')) {
    return <div style={{ display: 'flex', alignItems: 'center' }}>---</div>
  }
  const classDate =
    type === 'camp' || type === 'classes'
      ? value.session_dates
      : type === 'competitions'
        ? getDatesBetween(actual_start_date, actual_end_date)
        : []

  const nextSession = classDate.find((date) =>
    dayjs(date).isAfter(currentDate, 'day')
  )

  if (!nextSession) {
    return (
      <div>
        <span>---</span>
      </div>
    )
  }
  if (classDate.includes(liveDate)) {
    date = 'Today'
  } else if (dayjs(nextSession).isSame(currentDate, 'day')) {
    date = 'Tomorrow'
  } else {
    date = (dayjs(nextSession).format('MMM DD, YYYY'))
  }

  // timeString = `${timeDateConverterSession(value.start_time, 'time', 'hh:mm A')} - ${timeDateConverterSession(value.end_time, 'time', 'hh:mm A')}`;

  return (
    <div>
      <span>
        {`${date}`}
        {/* {`${date} | ${timeString}`} */}
        {/* {timeZone()} */}
      </span>
    </div>
  )
}

export default nextSession
