import React from 'react'
import { Layout, Image, Tabs, Rate } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import _ from 'lodash'
import parser from 'html-react-parser'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { BiLogoZoom } from 'react-icons/bi'
import { MdLocationOn } from 'react-icons/md'
import * as userAction from '../../../store/actions/users'
import * as servicesActions from '../../../store/actions/public/Services'
import './user-my-camp.css'
import * as action from '../../../store/actions/public/Classes'
import Rating from '../../../components/common-user/ratings'
import Flyers from '../../../components/re-usable/gallery-view/flyers-preview'
import Notes from '../../../components/common-user/notes'
import Milestones from '../../../components/common-user/milestones'
import Faq from '../../../components/common-user/faq'
import Ratings from '../../../components/rating/rating-list'
import * as tutorActions from '../../../store/actions/public/tutor-public-profile'
import OtherClassesCamps from '../../../components/common-user/full-details/other-class-camp'
import { FullDetailsLoader } from '../../../components/loader/common/full-details-loader'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import UploadToS3 from '../../../components/re-usable/file-to-s3'
import ParticipantUploads from '../../../components/common/upload-list'
import * as actionTypes from '../../../store/actions/common/actionTypes'
import UserSessionInfo from '../user-classes/user-sessionInfo'
import { CLASSPNG, DEFAULTUSER } from '../../../utils/config/assets'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
import UserMeetingLink from '../user-classes/user-meeting-link'
import { NurtemUtils } from '../../../utils'
import { toggleLeftMenu } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import { BsTwitterX } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";

const { Footer, Content } = Layout

class DetailView extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 20,
      singleClassView: {},
      provider_id:
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.details &&
        this.props.tutorBaseProfile.details.id,
      classRow_id: '',
      upWork: this.props.t('upWork'),
      notes: this.props.t('notes&asg'),
      milestones: this.props.t('milestones'),
      fqs: this.props.t('faqs'),
      rr: this.props.t('r&r'),
      batchInfo: this.props.t('batchInfo'),
      tutorSidebarAttendanceReport: this.props.t(
        'tutorSidebarAttendanceReport'
      ),
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    this._isMounted = true
    this.props.setToggleMenu(true)
    // this.props.getDependentUsers();
    const url = this.props.location.search
    const urlparams = queryString.parse(url)

    if (urlparams && urlparams.campId) {
      this.props.onCampDetail({
        id: urlparams.campId,
        expand: 'camp_details,user,dependent_user,provider',
      })
    }
  }

  async componentDidUpdate(prevPros) {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    if (prevPros.location.search !== this.props.location.search) {
      if (urlparams && urlparams.campId) {
        this.props.onCampDetail({
          id: urlparams.campId,
          expand: 'camp_details,user,dependent_user,provider',
        })
      }
    }

    if (
      this.props.classDetailsView &&
      prevPros.classDetailsView !== this.props.classDetailsView
    ) {
      const requestParams = {
        provider: _.get(
          this.props.classDetailsView.details.provider,
          'id',
          null
        ),
      }
      requestParams.limit = 8
      requestParams.count = 8
      console.log('requestParams', requestParams)
      this.props.onGetCampsList(requestParams)
    }
  }

  viewAttendance = (key, session) => {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams) {
      const userId = _.get(urlparams, 'userid', '')
      const userType = _.get(urlparams, 'usertype', '')
      const username = _.get(urlparams, 'username', '')
      if (key === '8' && userId && userType !== 'dependent') {
        history.push(
          `${routeName.attendanceReportUser.split(':')[0]}?classId=${session.id}&batchType=${'camps'}&userId=${userId}`
        )
      } else if (key === '8' && userId && userType === 'dependent') {
        history.push(
          `${routeName.attendanceReportUser.split(':')[0]}?classId=${session.id}&batchType=${'camps'}&userId=${userId}&dependent_user=${userId}&username=${username}`
        )
      }
    }
  }

  render() {
    const st = _.chain(this.props.classDetailsView.details)

    const session_details = st.get('camp_details').value()

    this.session_date = session_details
    const providerInfo = st.get('provider').value()
    const userId =
      st.get('dependent_user').value() !== null
        ? st.get('dependent_user.id').value()
        : st.get('user').value() !== null
          ? st.get('user.id').value()
          : ''
    const tit = st.get('camp_details.session_name').value()
    const ht = '270px'

    const desc = st.get('camp_details.description', '').value()
    const provider = st.get('camp_details.provider').value()
    const group_id = st.get('camp_details.group_id').value()
    const session_id = st.get('camp_details.id').value()
    const flyers = st.get('flyers').value()
    const fn = st.get('provider.firstname').startCase().value()
    const ln = st.get('provider.lastname').startCase().value()
    const bn = st.get('provider.businessname').isEmpty().value()
    const ty = st.get('provider.type').value()
    const providerName =
      ty === 'Individual' ? `${fn} ${ln}` : !_.isEmpty(bn) ? bn : `${fn} ${ln}`
    const fname = st.get('provider.firstname').startCase().value()
    const lname = st.get('provider.lastname').startCase().value()
    const bname = st.get('provider.businessname').isEmpty().value()
      ? fname
      : st.get('provider.businessname').startCase().value()
    const providerDetail = st.get('provider').value()
    let tutorName =
      st.get('provider.type').value() === 'Individual'
        ? `${fname} ${lname}`
        : `${bname}`
    if (tutorName) {
      tutorName = tutorName.toLowerCase().trim()
    }
    let aCampName = st.get('camp.name').value()
    if (aCampName) {
      aCampName = aCampName.toLowerCase().trim()
    }
    let viewCampUrl = ''
    if (tutorName && aCampName) {
      const gid = st.get('camp.id')
      viewCampUrl = `${tutorName.replace(/\s+/g, '-')}/${encodeURIComponent(aCampName)}/?gid=${gid}`
    }
    const url = this.props.location.search
    const urlparams = queryString.parse(url)

    return (
      <div className="tabs-container nurtem-my-class-card tutor-my-camp-details-page mb-4">
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
          onChange={(key) => this.viewAttendance(key, session_details)}
        >
          <items tab={this.props.t('batchDetail')} key="1">
            {!session_details ? (
              <FullDetailsLoader />
            ) : (
              <Layout>
                <div className="col-12">
                  <Content>
                    <div className="row">
                      <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                        <div className="head-classdetails-page-title ">
                          {tit}
                        </div>
                      </div>
                      <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                        <div className="ratings user-details">
                          <Rating
                            title={tit}
                            type="camps"
                            campUrl={viewCampUrl}
                            provider={provider}
                            camp_details={group_id}
                            userId={userId}
                            session_details={session_id}
                          />
                        </div>
                      </div>
                      <div className=" col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                        <Image
                          src={
                            st.get('camp.photo').value()
                              ? st.get('camp.photo').value()
                              : CLASSPNG
                          }
                          className="camp-img-bg"
                          alt="img"
                          width="100%"
                          height={ht}
                        />
                      </div>
                    </div>
                  </Content>
                  <div className="flyer mt-3 mb-3">
                    <div className="sub-title">{this.props.t('gallery')}</div>
                  </div>
                  <Content>
                    <Layout
                      style={{
                        fontSize: window.innerWidth < 980 ? '12px' : '14px',
                      }}
                    >
                      <Layout className="flyer">
                        {flyers && flyers.length > 0 ? (
                          <Flyers ht="300px" wt="30%" images={flyers} />
                        ) : (
                          <>
                            {' '}
                            <EmptyAnime
                              AnimeType="Empty"
                              loop
                              animeheight={150}
                            />
                            <p className="empty-animations">
                              {this.props.t('noImageFound')}
                            </p>
                          </>
                        )}
                      </Layout>
                    </Layout>
                  </Content>

                  <div className="sub-title ">
                    {this.props.t('descp')}
                  </div>
                  <div style={{ background: '#fff' }} className='parafont'>
                  <p>
                  {typeof desc === 'string'
                    ? parser(desc.replace(/\n/g, '<br>').replace(/<\/p>\s*<br>/g, '</p>'))
                    : ''}
                  </p>
                  </div>

                  {providerInfo && (
                    <>
                      <div className="sub-title mt-3 mb-3">
                        {this.props.t('aboutTutor')}
                      </div>
                      <div className="row ml-3 mr-3">
                        <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="pro-header">
                            <img
                              src={
                                providerInfo.photo
                                  ? providerInfo.photo
                                  : DEFAULTUSER
                              }
                              className="tutor-page-img-bg"
                              alt="img"
                            />
                          </div>
                          <div className="row">
                            {_.get(providerInfo, 'email', '') && (
                              <div
                                className="col-12  contact-list-profile mb-1"
                                style={{ display: 'flex' }}
                              >
                                <span
                                  className="icon link"
                                  style={{ marginRight: 3 }}
                                >
                                  <i className="ntm-envelope" />
                                </span>{' '}
                                <span>{_.get(providerInfo, 'email', '')}</span>
                              </div>
                            )}
                            {_.get(providerInfo, 'address', '') && (
                              <div
                                className="col-12  contact-list-profile mb-1"
                                style={{ display: 'flex' }}
                              >
                                <span
                                  className="icon link"
                                  style={{ marginRight: 6 }}
                                >
                                  <MdLocationOn style={{ fontSize: 18 }} />
                                </span>{' '}
                                <span>
                                  {_.get(providerInfo, 'address', '')}
                                </span>
                              </div>
                            )}
                            <div
                              style={{
                                display: 'flex',
                                fontSize: 20,
                                marginTop: 5,
                              }}
                            >
                              {_.get(providerInfo, 'skype_id', '') && (
                                <div className="col-3  contact-list-profile mb-1">
                                  <span className="icon link">
                                    <a
                                      href={_.get(providerInfo, 'skype_id', '')}
                                    >
                                      <BsTwitterX />
                                    </a>
                                  </span>
                                </div>
                              )}
                              {_.get(providerInfo, 'zoom_id', '') && (
                                <div className="col-3 contact-list-profile mb-1">
                                  <span className="icon link">
                                    <a
                                      style={{ wordBreak: 'break-all' }}
                                      className="a"
                                      href={_.get(providerInfo, 'zoom_id', '')}
                                      rel="noreferrer"
                                      target="_blank"
                                    >
                                      <FaLinkedinIn
                                        style={{
                                          color: '#0058ffc7',
                                          fontSize: 24,
                                        }}
                                      />
                                    </a>
                                  </span>
                                </div>
                              )}
                              {_.get(providerInfo, 'facebook_id', '') && (
                                <div className="col-3  contact-list-profile mb-1">
                                  {' '}
                                  <span>
                                    <a
                                      className="a"
                                      href={_.get(
                                        providerInfo,
                                        'facebook_id',
                                        ''
                                      )}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className="icon link">
                                        <i
                                          className="ntm-facebook-logo"
                                          style={{ color: '#0058ffc7', fontSize: '24px' }}
                                        />
                                      </span>{' '}
                                    </a>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                          <div className="camp-profile-details mt-1 mb-2">
                            <div className="profile-name-header">
                              {/* <div className='hideTutor' style={{ fontSize: 15, color: '#5a5a5a' }}><b>{this.props.t("tutorLabel")}</b></div> */}
                              <Link
                                className="view-profile"
                                to={{
                                  pathname: `/profile/${_.get(providerInfo, 'user_handle', null)}`,
                                  state: { data: 'Hello from FirstPage' },
                                }}
                                target="_blank"
                              >
                                {_.get(providerInfo, 'type') === 'Individual'
                                  ? !_.isEmpty(
                                    _.get(providerInfo, 'firstname', null)
                                  )
                                    ? `${_.startCase(_.get(providerInfo, 'firstname'))} ${_.get(providerInfo, 'lastname', '') !== '' ? `${_.startCase(_.get(providerInfo, 'lastname', ''))}` : ''}`
                                    : ''
                                  : `${_.startCase(_.get(providerInfo, 'businessname'))}`}
                              </Link>
                            </div>

                            <h5
                              className="claim-business"
                              style={{ display: 'flex', width: '100%' }}
                            >
                              <span
                                className="star-rating"
                                style={{ display: 'flex' }}
                              >
                                <Rate
                                  disabled="true"
                                  value={_.parseInt(
                                    _.get(providerInfo, 'average_rating', 0)
                                  )}
                                />
                                {parseInt(
                                  providerInfo && providerInfo.average_rating
                                ) !== 0 && (
                                    <b className="ml-3" style={{ fontSize: 14 }}>
                                      {`${_.get(
                                        providerInfo,
                                        'average_rating',
                                        0
                                      ).toFixed(1)}`}
                                      /5
                                    </b>
                                  )}
                              </span>
                            </h5>
                            <b className="rating-count">
                              ({parseInt(providerInfo.rating_count)}{' '}
                              {this.props.t('review')}
                              's)
                            </b>
                          </div>

                          <div className="camp-profile-details mb-2">
                            <div className="camp-profile-info  tutor-desc">
                              {_.get(providerInfo, 'bio', '') !== ''
                                ? parser(
                                  NurtemUtils.urlify(
                                    _.get(providerInfo, 'bio', '')
                                  )
                                )
                                : ''}
                              {_.get(providerInfo, 'services', []).length >
                                0 && (
                                  <>
                                    <div className="about-title mt-3">
                                      {' '}
                                      <h6>
                                        {this.props.t('onboardingTutor14')}{' '}
                                      </h6>
                                    </div>
                                    <div className="camps-class-tags-container">
                                      {providerInfo &&
                                        providerInfo.services &&
                                        providerInfo.services.length > 0 &&
                                        providerInfo.services.map(
                                          (service, index) => (
                                            <Link
                                              key={index}
                                              to={`/search/${service.name}`}
                                            >
                                              <span className="camps-class-tags-course-banner">
                                                <ul style={{ listStyle: 'none' }}>
                                                  <li>{service.name}</li>
                                                </ul>{' '}
                                              </span>
                                            </Link>
                                          )
                                        )}
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  <div
                    ref={this.myCampspickerRef}
                    className="tutor-public-profile-container about-title mt-5 mb-2"
                  >
                    <div className="sub-title  ml-2">
                      {this.props.t('availableCamps')}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row  other-classes">
                      {this.props.campsList && providerDetail && (
                        <OtherClassesCamps
                          type="camp"
                          otherList={this.props.campsList}
                          provider={providerDetail}
                          providerName={providerName}
                          providerType={ty}
                          thisDetailId={group_id}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Layout>
            )}
          </items>

          <items tab={this.state.upWork} key="2">
            <div className="col-12 mb-3">
              <div className="row mb-3">
                <div className="col-6 tab-content-title">
                  {this.state.upWork}
                </div>
                <div className="col-6 d-flex justify-content-end container-upload">
                  <UploadToS3
                    cat="camps"
                    subcat={actionTypes.campUploads}
                    cat_id={session_id}
                    user_id={urlparams.userid}
                    usertype={urlparams.usertype}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-12">
                  <ParticipantUploads
                    session={session_id}
                    cat="camps"
                    subcat={actionTypes.campUploads}
                    user_id={urlparams.userid}
                    user_type="user"
                    user="participant"
                  />
                </div>
              </div>
            </div>
          </items>

          <items tab={this.state.notes} key="3">
            <Notes session_details={session_details} type="camps" />
          </items>

          <items tab={this.state.milestones} key="4">
            <Milestones session_details={session_details} type="camps" />
          </items>

          <items tab={this.state.fqs} key="5">
            <Faq session_details={session_details} type="camps" />
          </items>

          <items tab={this.state.rr} key="6">
            <Ratings session={session_details} type="camps" />
          </items>

          <items tab={this.state.batchInfo} key="7">
            <UserSessionInfo session_details={session_details} type="camps" />
          </items>
          <items tab={this.state.tutorSidebarAttendanceReport} key="8" />
          {session_details && session_details.link && (
            <items tab="Meeting Link" key="9">
              <UserMeetingLink session_details={session_details} />
            </items>
          )}
        </Tabs>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  loading: state.campsDetails.loading,
  loggedInUserDetails: state.authentication,
  DetailView: state.users.DetailView,
  classDetailsView: state.users.userCampsInfo,
  singlePackViewLoading: state.campsDetails.singleCampsViewLoading,
  campsList: state.campsDetails.campsList,
  dependentUsers: state.userProfile.dependentUsers,
  userBaseProfile: state.userProfile.userBaseProfile,
})

const mapDispatchToProps = (dispatch) => ({
  setToggleMenu: (requestParams) =>
    dispatch(searchAction.common(toggleLeftMenu, requestParams)),
  getCampsList: (requestParams) =>
    dispatch(userAction.getUserCamps(requestParams)),
  onCampDetail: (requestParams) =>
    dispatch(userAction.getUserCampsInfo(requestParams)),
  onGetCurrentLocation: (callback) =>
    dispatch(servicesActions.onGetCurrentLocation(callback)),
  onGetCampsList: (providerId) =>
    dispatch(tutorActions.getCampsList(providerId)),
  getDependentUsers: () => dispatch(userAction.getDependentUsers()),
  onItemAdd: () => dispatch(action.cartItemAdd()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DetailView))
