/**
 *   <UploadToS3
          cat={'competition'}
          subcat={"entry"}
          cat_id={session_id}
      />
 */

import { AiOutlineUpload } from 'react-icons/ai';
import {
  Button, Upload, notification, Form,
} from 'antd';
import React, { useState } from 'react';
import axios1 from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import axios from '../../../utils/axios';
import * as commonActions from '../../../store/actions/common/uploads';

function FileUploadToS3(props) {
  const [ButtonDisable, setUploadButtonDisable] = useState(false);
  const totalCount = _.get(props, ['uploadedList', props.subcat, 'meta', 'total_count'], 0);
  const UploadButtonDisable = props.cat !== 'competitions' ? false : !!(totalCount > 0 || ButtonDisable);

  const pickdata = ['cat',
    'subcat',
    'cat_id',
    'user_id',
    'usertype',
    'message',
    'session',
  ];
  const beforeUploadHandler = (file) => {
    // Trim the filename to 10 characters
    const trimmedFileName = file.name.length > 10 ? file.name.substring(0, 20) : file.name;

    // Create a new File object with the trimmed filename
    const modifiedFile = new File([file], trimmedFileName, { type: file.type });

    // Perform any other actions, such as disabling the upload button
    setUploadButtonDisable(true);

    // Return the modified file or perform any other necessary actions
    return Promise.resolve(modifiedFile);
  };

  const onChangeHandler = (info) => {
    if (info.file.status === 'error' || info.file.status === 'done') {
      setUploadButtonDisable(false);
    }
  };

  const params = _.pick(props, pickdata);

  const GetPhotoUpload = () => {
    try {
      /**
       * if u use
       * <ParticipantUploads
       *   session={session_id}
       *   cat={'classes'}
       *   subcat={"classUploads"}
       *   user_id={urlparams.userid}
       *   user_type={urlparams.usertype}
       *   user="participant" />
       *
       *   then u have to use "session" to get cat_id
       *
       * let cat_id = _.get(props, 'session', "")
       *
       * here in this place cat_id comming in cat_id itself
      */

      const cat_id = _.get(params, 'cat_id', '');

      if (cat_id && cat_id !== '') {
        const notesParams = {
          cat: params.cat,
          subcat: params.subcat,
          user: params.user_id ? params.user_id : null,
          user_type: params.usertype ? params.usertype : null,
          cat_id,
          limit: 5,
          status: 1,
          page: 1,
          sort: 'created_at.desc',
          expand: 'user_profile,provider_profile',
        };
        props.getUploadedList(notesParams);
      }
    } catch (error) {
      console.log('error at upload list ', error);
    }
  };

  const datas = {
    data: params,
    onChange: (stat) => {
    },
    status: 'success',
    name: 'uploadedfile',
    customRequest(options) {
      const { onSuccess, file, onProgress } = options;

      let file_names = _.get(file, 'name', 'uploaded-file');

      file_names = file_names.replace(/[^a-zA-Z0-9\-\.]/g, '-');
      // Trim the filename to 10 characters
      const file_name = file_names.length > 10 ? file_names.substring(0, 40) : file_names;

      const file_size = _.get(file, 'size', 0);
      const maxSizeInBytes = 1024 * 1024 * 11; // 10MB

      if (file_size > maxSizeInBytes) {
        const msg = props.t('fileSizeMsg');
        notification.warning({ message: msg });
        return false;
      }

      // get secure urll from aws s3
      axios({
        method: 'post',
        url: '/s3/secure-url',
        withCredentials: true,
        data: { ...params, file_name },
        headers: { 'Content-Type': 'application/json' },

      })
        .then((resp) => {
          if (resp.status === 200) {
            let url = resp.data;
            url = url.trim();

            onProgress({ percent: 25 });
            // file upload directly to s3
            axios1({
              method: 'put',
              url,
              data: file,
              headers: {
                'Content-Type': file.type,
              },
            })
              .then((resp) => {
                if (resp.status === 200) {
                  // next add to table
                  const url = _.chain(resp).get('config.url').split('?').first();
                  onProgress({ percent: 60 });
                  const randomTwoDigitNumber = Math.floor(Math.random() * (99 - 10 + 1)) + 10;
                  const fileNameWithTimestamp = `${randomTwoDigitNumber}_${file.name}`;
                  axios({
                    method: 'post',
                    url: '/common/add-to-media',
                    withCredentials: true,
                    data: {
                      cat: props.cat,
                      subcat: props.subcat,
                      catid: props.cat_id,
                      typeid: props.type_id,
                      url,
                      userid: props.user_id ? props.user_id : null,
                      usertype: props.usertype,
                      filename: fileNameWithTimestamp,
                    },
                    headers: {
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((resp) => {
                      if (resp.status === 200) {
                        GetPhotoUpload();
                        onSuccess();

                        notification.success({
                          message: props.t('uploadedSucessfully'),
                          style: {
                            zIndex: 600,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      console.error('Error >> uploading to S3:', error);
                      notification.error({
                        message: props.t('uploadefailed'),
                        style: {
                          zIndex: 600,
                        },
                      });
                    });
                }
              })
              .catch((error) => {
                console.error('Error >> uploading to S3:', error);
              });
          } else {
            console.error('Error >> uploading to S3:resp.status != 200, in secure url');

            notification.error({
              message: props.t('uploadefailed'),
              style: {
                zIndex: 600,
              },
            });
          }
        })
        .catch((error) => {
          console.error('Error >> uploading to S3: catch block 1 file-to-s3', error);
          notification.error({
            message: props.t('uploadefailed'),
            style: {
              zIndex: 600,
            },
          });
        });
    },

  };

  return (
    <Form.Item>
      <Upload
        {...datas}
        listType="picture"
        beforeUpload={beforeUploadHandler}
        onChange={onChangeHandler}
      >
        <Button
          type="primary"
          disabled={UploadButtonDisable}
          icon={<AiOutlineUpload style={{ fontSize: 20 }} />}
        >
          {props.t('clicktoUp')}
        </Button>
      </Upload>
    </Form.Item>
  );
}
const mapStateToProps = (state) => ({
  uploadedList: state.uploads,
});

const mapDispatchToProps = (dispatch) => ({
  getUploadedList: (requestParams) => dispatch(commonActions.getUploadedList(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FileUploadToS3));
