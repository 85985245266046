/**
 * @Important !!
 * react-stripe-checkout commented is using the version  "react-stripe-checkout": "^2.6.3",
 * Plaid commented is using version "react-plaid-link": "^1.5.1", These have been removed from package.json if u uncomment and use the code use these versions
 *
 */

import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  Radio,
  Input,
  InputNumber,
  notification,
  Collapse,
  Button,
  Select,
  Table,
  Space,
  Form,
  Carousel,
  Modal,
  Spin,
  Switch,
  Card,
  List,
  Typography
} from 'antd'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import * as SecureLS from 'secure-ls'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import CheckoutForm from './CheckoutForm'
import history from '../../../utils/history'
import * as routeName from '../../../components/route-names'
// import StripeCheckout from "react-stripe-checkout";
// import PlaidLink from "react-plaid-link";
import * as tutorActions from '../../../store/actions/tutor/index'
import * as userAction from '../../../store/actions/users'
import * as campsActions from '../../../store/actions/public/Camps'
import * as action from '../../../store/actions/public/Classes'
import { globalVariable } from '../../../utils/config/config'
import * as servicesActions from '../../../store/actions/public/Services'
import CartLoader from './cart-loader'
import { withErrorHandler } from '../../../utils/handler'
import LabelConvertion from '../../../components/re-usable/label-convertion'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
import './user-my-cart.css'
import generalAction from '../../../store/general/action'
import classTimingsOnly from '../../../components/timezone/onlytiming'
import axiostoServer from '../../../utils/axios'
import _ from 'lodash'
import dayjs from 'dayjs'
import { cartItems } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import { FaPlus } from "react-icons/fa6";
import GuestFlow from '../guest-flow/guest-flow'
import css from '../../../utils/CSSUtility'

const { Panel } = Collapse
const { Option } = Select
const { Title, Text } = Typography;

/**
 * billing_type > refers class type
 *  1 > session
 *  2 > monthly > payment_cycle_type 1 > fixed, 2 > variable
 *  3 > season
 *
 * collection_method > (charge_automatically / send_invoice - stripe supported) / subscription (seperate entity) via card or ach bank payment
 */

class UserMyCart extends React.Component {
  mounted = false

  ls = new SecureLS()

  state = {
    refreshCount: 0,
    cartItems: {},
    selected_users: {},
    session_count: {},
    dependentUsersOpt: '',
    selectedPayment: 'Stripe',
    discountDetails: {},
    valid_discount: {},
    recalculate_cart: true,
    dataSource: [],
    grandTotal: 0,
    taxTotal: 0,
    session_paid: 0,
    session_left: 0,
    discountId: '',
    dependent_user: '',
    dependents_users: ['Myself'],
    dependent_select_count: 1,
    dependent_user_count: 0,
    couponCode: '',
    discountPrice: 0,
    maxDiscount: 0,
    discountAmount: 0,
    classItemsDetails: {},
    couponsLoading: false,
    isFreeCoupons: false,
    successPay: false,
    couponsType: '',
    sessionCount: 1,
    tax_amount: 0,
    tax_percentage: 10,
    collection_method: '',
    isACHModalOpen: false,
    accountHolderNameField: '',
    emailField: '',
    intent_clientSecret: '',
    monthName: dayjs().format('MMM'),
    guestCheckout: false,
    leftSessionsCount: 0,
    response: {},
    loadingcart: false,
    guestVisible: false,
  }

  async componentDidMount() {
    try {
      this.setState({ loadingcart: true })
      setTimeout(async () => {
        let CartApi = {}
        if (_.get(this, 'props.loggedInUserDetails.isLoggedIn') === true) {

          // CartApi = await this.getCartItems(
          //   _.get(this, 'props.loggedInUserDetails.isLoggedIn', false),
          //   _.get(this.props, 'userBaseProfile.id', {}), 
          //   1
          // )

          const cItemFull = await this.getCartItems(
            _.get(this, 'props.loggedInUserDetails.isLoggedIn', false),
            _.get(this.props, 'userBaseProfile.id', {})
          )

          this.props.setCartItemsRedux(cItemFull)


          // Loop through the keys of the cItemFull object
          for (const key in cItemFull) {
            if (cItemFull.hasOwnProperty(key) && cItemFull[key].status === 1) {
              CartApi[key] = cItemFull[key];
            }
          }


        } else {
          CartApi = await this.ls.get('cartItems')
          this.setState({ guestCheckout: await this.ls.get('guestCheckout') })
          //this.props.setCartItemsRedux(CartApi)
        }
        this.setState({ loadingcart: false, cartItems: CartApi })


        this.mounted = true
        if (_.get(this, 'props.loggedInUserDetails.isLoggedIn') === true) {
          this.props.getDependentUsers({ limit: 100 })
        }

        this.recalculate_cartdata()
      }, 1000)
    } catch (error) {
      console.log('error at cart.js in componentDidMount', error)
    }
  }

  getCartItems = async (isLoggedIn, id, status = -1) => {
    try {
      if (isLoggedIn) {
        let params = {
          id
        }
        if (status > -1) {
          params = {
            id,
            status: status,
          }
        }

        this.setState({ loadingcart: true })
        const resp = await axiostoServer({
          method: 'get',
          url: '/users/get-cartitems',
          withCredentials: true,
          params: params,
          headers: { 'Content-Type': 'application/json' },
        })

        if (resp.status === 200) {
          this.setState({ loadingcart: false })
          return resp.data.details
        }
        this.setState({ loadingcart: false })
        notification.error({
          description: this.props.t('errGetCart'),
          message: this.props.t('error'),
        })
      }
    } catch (error) {
      this.setState({ loadingcart: false })
      notification.error({
        description: this.props.t('errGetCart'),
        message: this.props.t('error'),
      })
      console.error('Error while getting the cart!', error)
      throw error
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.recalculate_cart) {
      this.recalculate_cartdata()
    }



    const dep_user_items = _.get(this.props, 'dependentUsers.items', [])

    if (
      this.props.dependentUsers !== prevProps.dependentUsers &&
      dep_user_items.length > 0
    ) {
      const dependentUsersOpt =
        Array.isArray(dep_user_items) &&
        dep_user_items.map((value, index) => {
          if (value) {
            return (
              <Option key={index} value={value.id}>
                {_.capitalize(_.get(value, 'firstname', 'myself'))}
              </Option>
            )
          }
          return null
        })
      this.setState({
        recalculate_cart: true,
        dependentUsersOpt,
      })
    }
  }

  sessionMaxCount = (clsData) => {
    const session_dates = _.get(clsData, 'session.session_dates', [])
    const SessionsPaid = _.get(clsData, 'SessionsPaid', 0)
    const SessionsPaidOn1 = _.get(clsData, 'SessionsPaidOn')
    const SessionsPaidOn = SessionsPaidOn1
      ? dayjs(SessionsPaidOn1).startOf('day')
      : dayjs().startOf('day')
    const isReorder = _.get(clsData, 'reorder', false)
    const currentDate = dayjs().startOf('day')
    const filteredDates = isReorder
      ? session_dates.filter((date) => new Date(date) >= SessionsPaidOn)
      : session_dates.filter((date) => new Date(date) >= currentDate)
    const session_dates_count = isReorder
      ? filteredDates.length - SessionsPaid
      : filteredDates.length
    this.setState({ leftSessionsCount: session_dates_count })
    return session_dates_count
  }

  recalculate_cartdata = () => {
    const cartItemUpdated = {}
    const dataSourceUpdated = []
    const { cartItems, discountPrice, isFreeCoupons } = this.state

    let grandTotal = 0
    let taxTotal = 0

    for (const key in cartItems) {
      const clsData = cartItems[key]
      const type = _.get(clsData, 'type')
      const maxCountData =
        type !== 'competitions' &&
        type !== 'events' &&
        this.sessionMaxCount(clsData)
      const id = _.get(clsData, 'id', '')
      const isReorder = _.get(clsData, 'reorder', false)
      const session = _.get(clsData, 'session')
      const sessionTax = _.get(clsData, 'session.tax_percentage', 0)
      const session_dates = _.get(clsData, 'session.session_dates', [])
      const SessionsPaid = _.get(clsData, 'SessionsPaid', 0)
      const SessionsPaidOn1 = _.get(clsData, 'SessionsPaidOn', new Date())
      const maxCount =
        type !== 'competitions' && type !== 'events'
          ? _.get(clsData, 'session.no_of_max_students', 0)
          : _.get(clsData, 'session.max_no_of_participants', 0)
      const stuAccpt = _.get(
        clsData,
        'session.additional_details.no_of_students_accepted',
        0
      )
      const stuBooked = _.get(
        clsData,
        'session.additional_details.no_of_students_booked',
        0
      )
      const SessionsPaidOn = SessionsPaidOn1
        ? dayjs(SessionsPaidOn1).startOf('day')
        : dayjs()
      const SeatsLeftCount = _.subtract(maxCount, _.add(stuAccpt, stuBooked))
      const currentDate = isReorder ? SessionsPaidOn : dayjs().startOf('day')
      const filteredDates =
        session_dates && session_dates.length > 0
          ? session_dates.filter(
            (date) => dayjs(date).startOf('day') >= currentDate
          )
          : []

      let session_dates_count
      const price = _.get(clsData, 'price.sessionPrice')
      const addonPrice = _.get(clsData, 'price.addonTotal')
      if (_.get(clsData, 'session.billing_type') === 2) {
        const session_dates = _.get(clsData, 'session.session_dates', [])
        const leastDate = session_dates.reduce((a, b) =>
          dayjs(a).isBefore(dayjs(b)) ? a : b
        )
        const whenStarting = dayjs(leastDate).isAfter(dayjs().startOf('day'))
        const currentDate = whenStarting
          ? dayjs(leastDate)
          : dayjs().startOf(isReorder ? 'month' : 'day')

        const uniqueMonths = new Set()
        filteredDates.forEach((date) => {
          const yearMonth = dayjs(date).format('MM-YYYY')
          uniqueMonths.add(yearMonth)
        })
        const numberOfMonths = uniqueMonths.size
        const sessionPaidLs = _.get(clsData, 'SessionsPaid', 0)
        if (sessionPaidLs === numberOfMonths) {
          console.log('numberOfSessions======1')
          session_dates_count = 0
          this.setState({ monthName: 'Fully-Paid' })
        } else if (sessionPaidLs === 0 || !isReorder) {
          console.log('numberOfSessions======12')
          const filteredDates = session_dates.filter((date) => {
            const sessionDate = dayjs(date).startOf('day')
            return (
              sessionDate.isSameOrAfter(currentDate, 'day') &&
              sessionDate.isSame(currentDate, 'month')
            )
          })
          session_dates_count = filteredDates.length
        } else {
          const monthsArray = Array.from(uniqueMonths)
          const targetMonthIndex = monthsArray.findIndex(
            (month) => month === dayjs(currentDate).format('MM-YYYY')
          )
          const finalMnth =
            sessionPaidLs >= targetMonthIndex ? sessionPaidLs : targetMonthIndex
          const targetMonth = monthsArray[finalMnth]

          this.setState({
            monthName: dayjs(`${targetMonth}`, 'MM-YYYY').format('MMM'),
          })
          const targetMonthDatesCount = dayjs(`${targetMonth}`, 'MM-YYYY')
          const filteredDates1 = filteredDates.filter((date) => {
            const sessionDate = dayjs(date).startOf('day')
            return (
              sessionDate.isSameOrAfter(targetMonthDatesCount, 'day') &&
              sessionDate.isSame(targetMonthDatesCount, 'month')
            )
          })
          session_dates_count =
            _.get(clsData, 'session.payment_cycle_type') === 2
              ? filteredDates1.length
              : 1
        }
      } else if (
        filteredDates.length > 0 &&
        _.get(clsData, 'session.billing_type') !== 1
      ) {
        session_dates_count = filteredDates.length
      } else if (_.get(clsData, 'session.billing_type') === 1) {
        const session_dates_counts = isReorder
          ? filteredDates.length - SessionsPaid
          : filteredDates.length
        session_dates_count = session_dates_counts
      }
      const numberOfSessions = this.state.session_count.hasOwnProperty(key)
        ? this.state.session_count[key]
        : _.get(clsData, 'session.billing_type') === 3 ||
          _.get(clsData, 'session.billing_type') === 0 ||
          (_.get(clsData, 'session.billing_type') === 2 &&
            _.get(clsData, 'session.payment_cycle_type') === 2)
          ? session_dates_count
          : session_dates_count === 0
            ? 0
            : 1

      const number_of_users = this.state.selected_users.hasOwnProperty(key)
        ? this.state.selected_users[key].length
        : 1

      if (_.get(clsData, 'reorder', false)) {
        const keyWithDash = key
        const splitKey = keyWithDash.split('--')
        const separatedKey = splitKey[1]
        if (separatedKey !== _.get(this.props, 'userBaseProfile.id')) {
          this.setState({
            selected_users: {
              ...this.state.selected_users,
              [key]: [separatedKey],
            },
          })
        }
      }
      const totalPrice = price * number_of_users * numberOfSessions + addonPrice
      /// check u have discount
      const discount = this.applyDiscount(clsData, key, totalPrice)
      let discount_per_user = 0
      let price_peruser_after_discount = price * numberOfSessions

      let discount_ids = []
      if (discount.amount > 0) {
        discount_ids = discount.ids
        discount_per_user = discount.amount / number_of_users
        price_peruser_after_discount = _.subtract(
          price_peruser_after_discount,
          discount_per_user
        )
      }

      // add tax per user

      const tax_amount = price_peruser_after_discount * sessionTax * 0.01
      const price_peruser_after_discount_plus_tax = _.add(
        price_peruser_after_discount,
        tax_amount
      )

      const price_data = {
        ..._.get(clsData, 'price'),
        discount_ids,
        discount_per_user,
        price_peruser_after_discount_plus_tax:
          price_peruser_after_discount_plus_tax + addonPrice,
        number_of_sessions: numberOfSessions,
      }
      const tp_discounted = totalPrice - discount.amount
      const tax = tp_discounted * sessionTax * 0.01
      const tp_discounted_withTax = tp_discounted + tax
      grandTotal = _.add(grandTotal, tp_discounted_withTax)
      taxTotal = _.add(taxTotal, tax)
      const TeachingType =
        type !== 'competitions' && type !== 'events'
          ? _.get(clsData, 'session.teaching_type')
          : 'g'
      const data = {
        id,
        key,
        item: (
          <div className="full-width">
            <div className="item-text-regular">
              {_.get(session, 'session_name', '')}
            </div>
            <Carousel
              className="item-text-regular"
              style={{ width: '100px' }}
              speed={100}
              autoplay
            >
              {classTimingsOnly(session)}
            </Carousel>
          </div>
        ),
        type: _.capitalize(type),
        towhom: (
          <div className="cart-selector">
            <Select
              mode={
                TeachingType === 'i' || SeatsLeftCount === 1
                  ? 'single'
                  : 'multiple'
              }
              defaultValue={_.get(
                this.props,
                'userBaseProfile.fristname',
                'Myself'
              )}
              disabled={_.get(clsData, 'reorder', false)}
              value={
                this.state.selected_users.hasOwnProperty(key)
                  ? this.state.selected_users[key]
                  : ['myself']
              }
              onChange={async (values) => {
                const value = Array.isArray(values) ? values : [values]
                const selectedCount = value.length
                // console.log("selectedCount=====", value);
                if (
                  TeachingType !== 'i' &&
                  SeatsLeftCount > 1 &&
                  selectedCount > SeatsLeftCount
                ) {
                  notification.warning({
                    message: 'Seats Full',
                    description: 'No more seats left for this session.',
                  })
                  return
                }

                // Check if "myself" is selected
                const isMyselfSelected = value.includes('myself')

                // Check if any other option is selected
                const isOtherSelected = value.some(
                  (option) => option !== 'myself'
                )

                // If "myself" is not selected and no other option is selected, add "myself" back to the value
                if (!isMyselfSelected && !isOtherSelected) {
                  value.push('myself')
                }

                if (!value.includes('myself')) {
                  const dependentUsersOpt = [...this.state.dependentUsersOpt]

                  const updatedList = dependentUsersOpt.find(
                    (opt) => opt && opt.props.value === 'myself'
                  )
                  if (!updatedList) {
                    const myselfElement = (
                      <Option value="myself" children="Myself" />
                    )
                    dependentUsersOpt.push(myselfElement)
                  }

                  this.setState({
                    dependentUsersOpt,
                  })
                }

                await this.setState({
                  recalculate_cart: true,
                  selected_users: {
                    ...this.state.selected_users,
                    [key]: value,
                  },
                })
              }}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  {Object.keys(this.state.cartItems).length > 0 &&
                    this.props.loggedInUserDetails &&
                    this.props.loggedInUserDetails.isLoggedIn && (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8,
                          justifyContent: 'right'
                        }}
                      >
                        <Link
                          to={{
                            pathname: routeName.userProfile,
                            search: '?dependent=true&cart=true'
                          }}
                        >
                          <Button icon={<FaPlus />} type='primary'>
                            {this.props.t('addDependents')}
                          </Button>
                        </Link>
                      </div>
                    )}
                </div>
              )}
            >
              {/* {console.log("selectedCount1=====", this.state.dependentUsersOpt)} */}

              {this.state.dependentUsersOpt}
            </Select>
          </div>
        ),

        billingtype: LabelConvertion(
          _.get(clsData, 'session.billing_type', 1),
          'billing-type'
        ),

        sessions:
          _.get(clsData, 'session.billing_type') === 2 ? (
            this.state.monthName
          ) : (
            <div className="full-width">
              {_.get(clsData, 'type', null) === 'competitions' ||
                _.get(clsData, 'type', null) === 'events' ? (
                'All'
              ) : (
                <InputNumber
                  type="number"
                  min={this.state.leftSessionsCount === 0 ? 0 : 1}
                  disabled={_.get(clsData, 'session.billing_type', 1) !== 1}
                  max={maxCountData}
                  defaultValue={maxCountData}
                  value={numberOfSessions}
                  onChange={async (value) => {
                    console.log('valuechangeed======', value)
                    if (value === null) {
                      // notification.error({
                      //   message: this.props.t("error"),
                      //   description: this.props.t("sessionCountEmpty"),
                      // });
                      return
                    }
                    await this.setState({
                      recalculate_cart: true,
                      session_count: {
                        ...this.state.session_count,
                        [key]: value,
                      },
                    })
                  }}
                  style={{ width: 60 }}
                />
              )}
            </div>
          ),

        price: (
          <div className="full-width">
            <div
              className={
                discount.amount > 0
                  ? ' item-text-regular line-through'
                  : ' item-text-regular '
              }
            >
              ${_.round(totalPrice, 2)}
            </div>

            {discount.amount > 0 && (
              <div className="item-text-regular">
                ${_.round(tp_discounted, 2)}
              </div>
            )}
          </div>
        ),
        discount: (
          <div className="full-width">
            {discount.amount > 0 && (
              <div className="item-text-regular">
                ${_.round(discount.amount, 2)}
              </div>
            )}
          </div>
        ),
        tax: (
          <div className="full-width">
            <div
              className={
                discountPrice || isFreeCoupons
                  ? ' item-text-regular line-through'
                  : ' item-text-regular '
              }
            >
              {_.round(sessionTax, 2)}%
            </div>
          </div>
        ),
      }

      dataSourceUpdated.push(data)

      cartItemUpdated[key] = {
        ...clsData,
        price: price_data,
      }
    }

    this.setState({
      cartItems: cartItemUpdated,
      dataSource: dataSourceUpdated,
      recalculate_cart: false,
      grandTotal,
      taxTotal,
    })
  }

  applyCPN = (discountDetails) => {
    const disc_details = _.get(discountDetails, 'details', {})

    // const type = _.get(discountDetails, 'details.type', '')
    const couponid = _.get(discountDetails, 'details.id', '')
    const coupon_providerid = _.get(discountDetails, 'details.provider', '')

    for (const key in this.state.cartItems) {
      const cart = this.state.cartItems[key]

      if (cart.session.provider === coupon_providerid) {
        const discountObj = this.state.valid_discount.hasOwnProperty(key)
          ? this.state.valid_discount[key]
          : {}
        if (_.isEmpty(discountObj)) {
          this.setState({
            valid_discount: {
              ...this.state.valid_discount,
              [key]: { [couponid]: disc_details },
            },
          })
        } else {
          // apply coupon
          this.setState({
            valid_discount: {
              ...this.state.valid_discount,
              [key]: { ...discountObj, [couponid]: disc_details },
            },
          })
        }
        break
      }
    }

    if (Object.keys(this.state.valid_discount).length <= 0) {
      notification.warning({
        message: 'No Discount',
        description: 'Invalid Coupon!',
        style: { zIndex: 600 },
      })
    } else {
      notification.success({
        message: 'Discount',
        description: 'Coupon applied!',
        style: { zIndex: 600 },
      })
    }
  }

  onSelectPayment = (value) => {
    if (value) {
      this.setState({
        selectedPayment: value,
      })
    }
  }

  handleOnExit(er) {
    // handle the case when your user exits Link
  }

  // Plaid Link For Classes
  // handleOnSuccessClass(token, metadata, bookingDetails, amount, tax_amount = 0) {
  //   console.log("token", token)
  //   console.log("classesDetails", bookingDetails)
  //   console.log("metadata", metadata)
  //   this.props.onCreateBookings({ token: token, account_id: metadata.account_id, bookingDetails: bookingDetails, amoutTo: amount }, '/usersbooking', this.createBookingCallback);
  // };
  successPayment = () => {
    history.push(routeName.userDashboard)
    this.setState({
      successPay: false,
      cartItems: {},
    })
  }

  createBookingCallback = async (resp) => {
    this.ls.remove('cartItems')
    this.ls.remove('guestCheckout')
    if (
      _.get(this, 'props.loggedInUserDetails.isLoggedIn') &&
      _.get(this.props, 'userBaseProfile.id') &&
      !this.state.guestCheckout
    ) {
      await this.removeCartItems({
        user: _.get(this.props, 'userBaseProfile.id', {}),
      })
    }
    this.setState({
      successPay: true,
      response: resp,
    })
    setTimeout(() => {
      this.props.onUserProfile()
    }, 1000)
  }

  removeCartItems = async (id) => {
    if (id) {
      try {
        const resp = await axiostoServer({
          method: 'post',
          url: '/users/delete-cart',
          withCredentials: true,
          data: id,
          headers: { 'Content-Type': 'application/json' },
        })
        if (resp.status === 200) {
          if (_.get(this, 'props.loggedInUserDetails.isLoggedIn') === true) {
            const cItem = await this.getCartItems(
              _.get(this, 'props.loggedInUserDetails.isLoggedIn', false),
              _.get(this.props, 'userBaseProfile.id', {}),
              1
            )
            this.setState({ cartItems: cItem })

            const cItemFull = await this.getCartItems(
              _.get(this, 'props.loggedInUserDetails.isLoggedIn', false),
              _.get(this.props, 'userBaseProfile.id', {})
            )

            this.props.setCartItemsRedux(cItemFull)
          }
          return resp
        }
      } catch (error) {
        // notification.error({
        //   description: 'Error while deleting the cart!!',
        //   message: "Error",
        // });
        console.error('Error fetching Deleting cart', error)
        throw error
      }
    }
  }

  // onToken = (token, bookingDetails, amoutTo) => {

  //   if (this.state.collection_method === 'charge_automatically' || this.state.collection_method === 'subscription') {
  //     let stripe_token = token.id;
  //     let recurringData = { token: stripe_token, datas: this.props.userDetails }
  //     var customer_id = '';
  //     console.log("recurringData>>", recurringData)

  //     if (!this.props.userDetails.bank_details ||
  //       this.props.userDetails.bank_details === null ||
  //       this.props.userDetails.bank_details === 'null' ||
  //       !_.get(this, 'props.userDetails.bank_details.stripe')) {

  //       this.props.newCustomers(recurringData, (response) => {
  //         // console.log('this.props.customerDetails', this.props.customerDetails, response);
  //         if (this.props.customerDetails && this.props.customerDetails.id) {
  //           // console.log('a 1');
  //           let request = { ...this.props.userDetails }
  //           // console.log('a 2');
  //           request.bank_details = {
  //             stripe: { customer_id: _.get(this, 'props.customerDetails.id') }
  //           }
  //           // console.log('a 3');
  //           this.props.onUpdateUserProfileInfo(request)
  //           // console.log('a 4');
  //           customer_id = _.get(this, 'props.customerDetails.id')

  //         }
  //       })
  //     } else {
  //       customer_id = _.get(this, 'props.userDetails.bank_details.stripe.customer_id')
  //     }

  //     this.props.onCreateBookings({ customer_id: customer_id, token: token, bookingDetails: bookingDetails, amoutTo: amoutTo }, '/create-recurring-bookings', this.createBookingCallback);

  //   } else {
  //     this.props.onCreateBookings({ token: token, bookingDetails: bookingDetails, amoutTo: amoutTo }, '/usersbooking', this.createBookingCallback);
  //   }

  // };

  changeInput = (value, key = 'couponCode') => {
    this.setState({
      [key]: value,
    })
  }

  onApplyCoupons = () => {
    if (this.state.couponCode) {
      this.setState({
        couponsLoading: true,
        recalculate_cart: true,
        valid_discount: {},
      })
      setTimeout(() => {
        const data = {}
        data.code = this.state.couponCode
        data.dependent_user_count =
          this.props.dependentUsers && this.props.dependentUsers.items
            ? this.props.dependentUsers.items.length
            : 0
        data.total_user_count = data.dependent_user_count + 1
        this.props.couponsApply(data, (response) => {
          const resDet = _.get(response, 'details', {})

          const updt = {
            couponsLoading: false,
          }
          if (!_.isEmpty(resDet)) {
            updt.discountDetails = resDet
          }
          this.setState(updt)

          this.applyCPN(response)
          this.setState({
            recalculate_cart: true,
          })
        })
      }, 1000)
    }
  }

  onRejectCoupons = async () => {
    if (this.state.couponCode) {
      const discountObjMaster = {}

      for (const key in this.state.valid_discount) {
        const discountObj = this.state.valid_discount.hasOwnProperty(key)
          ? this.state.valid_discount[key]
          : {}
        const discountObjCopy = _.cloneDeep(discountObj)

        if (!_.isEmpty(discountObj) && discountObj.length > 0) {
          for (const k in discountObjCopy) {
            const disObj =
              discountObjCopy.hasOwnProperty(k) && discountObjCopy[k]

            if (disObj.code === this.state.couponCode) {
              delete discountObj[k]
            }
          }
        }
        if (discountObj.length > 0) {
          discountObjMaster[key] = discountObj
        }
      }
      console.log('discount=====', discountObjMaster)
      this.setState({
        recalculate_cart: true,
        valid_discount: discountObjMaster,
      })
    }
  }

  deleteCartItems = (record) => {
    const ci = this.state.cartItems
    ci.hasOwnProperty(record.key) && delete ci[record.key]
    this.setState({
      recalculate_cart: true,
      cartItems: ci,
    })
    this.ls.remove('cartItems')
    this.ls.set('cartItems', ci)
    //window.location.reload()
    if (_.get(this, 'props.loggedInUserDetails.isLoggedIn') === true) {
      this.removeCartItems({ _id: record.id })
      setTimeout(() => {
        this.props.onUserProfile()
      }, 2000)
    }
  }

  columns = [
    {
      title: this.props.t('item'),
      dataIndex: 'item',
      key: 'item',
      width: '20%',
    },
    {
      title: this.props.t('type'),
      dataIndex: 'type',
      key: 'type',
      width: '5%',
      render: (text) => {
        if (text === 'Classes') {
          return 'Class';
        }
        return text;
      },
    },
    {
      title: this.props.t('toWhom'),
      dataIndex: 'towhom',
      key: 'towhom',
      width: '35%',
    },
    {
      title: this.props.t('billingType'),
      dataIndex: 'billingtype',
      key: 'billingtype',
      width: '10%',
    },
    {
      title: this.props.t('sessions'),
      dataIndex: 'sessions',
      key: 'sessions',
      width: '5%',
    },
    {
      title: this.props.t('discount'),
      dataIndex: 'discount',
      key: 'discount',
      width: '10%',
    },
    {
      title: this.props.t('tax'),
      dataIndex: 'tax',
      key: 'tax',
      width: '5%',
    },
    {
      title: this.props.t('price'),
      dataIndex: 'price',
      key: 'price',
      width: '5%',
    },
    {
      title: this.props.t('Action'),
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <Space size="middle">
          <div>
            <button
              onClick={() => this.deleteCartItems(record)}
              className="btn btn-delete mr-1"
            >
              <i className="ntm-bin" />
            </button>
          </div>
        </Space>
      ),
    },
  ]

  selectedUserCount(key) {
    return this.state.selected_users.hasOwnProperty(key)
      ? this.state.selected_users[key].count()
      : 1
  }

  applyDiscount(cartItem, index, totalPrice) {
    let priceDiscountT = 0
    const { valid_discount } = this.state
    const disc_ids = []

    if (valid_discount.hasOwnProperty(index)) {
      const session_price = _.round(totalPrice, 2)
      // check for no of user selected
      const selected_users = this.state.selected_users.hasOwnProperty(index)
        ? this.state.selected_users[index]
        : ['myself']
      const sessionTotal = session_price
      const discount_objs = valid_discount[index]

      for (const discount_id in discount_objs) {
        const discount_obj = discount_objs[discount_id]

        let discount_forthis_obj = 0
        const min_amount = _.get(discount_obj, 'min_amount', 0)
        // check for minimum cart amount, the discount applicable
        if (sessionTotal >= min_amount) {
          if (discount_obj.type === 0) {
            // general %
            const discountPercentage = discount_obj.amount
            const discountedAmount = sessionTotal * (discountPercentage / 100)
            discount_forthis_obj = discountedAmount
          } else if (discount_obj.type === 1) {
            // flate_rate
            discount_forthis_obj += discount_obj.amount
          } else if (discount_obj.type === 2) {
            // item_based or ex: sibilings offer
            const selected_dependent_users = selected_users.filter(
              (val, i, arr) => val !== 'myself'
            )
            const no_of_sibilings = selected_dependent_users.length
            const user_count = Math.min(
              discount_obj.no_of_dependents,
              no_of_sibilings
            )
            discount_forthis_obj +=
              (session_price * user_count * discount_obj.amount) / 100
          } else if (discount_obj.type === 3) {
            // free coupon
            discount_forthis_obj += session_price
          } else {
            // no nothing
          }
        }

        // check for max limit
        const max_amount = _.get(discount_obj, 'max_amount', 0)

        if (_.isNumber(max_amount) && max_amount > 0) {
          priceDiscountT += Math.min(discount_forthis_obj, max_amount)
        } else {
          priceDiscountT += discount_forthis_obj
        }
        disc_ids.push(discount_id)
      }
    }
    return { amount: priceDiscountT, ids: disc_ids }
  }


  showACHModal = (params = true) => {
    this.setState({ isACHModalOpen: params })
  }

  handleFreeEnrollment = () => {
    const payment_data = {
      collection_method: 'free',
      payment_method: 'free',
      cart_items: this.state.cartItems,
      selected_users: this.state.selected_users,
    }
    const freeBookingData = {
      token: '',
      account_id: '',
      bookingDetails: payment_data,
      amoutTo: 0,
    }

    console.log('freeBookingData>>>', freeBookingData)

    this.props.onCreateBookings(freeBookingData, '/usersbooking', (resp) => {
      const message = _.get(resp, 'data.message', '')

      setTimeout(() => {
        // setIsLoading(false);
        this.createBookingCallback({
          amount: 0,
          resp,
          message,
        })
      }, 1500)
    })
  }

  handleAch = () => {
    this.showACHModal(true)

    const paymentIntent_data = {
      payment_data: {
        login_type: _.get(this.props, 'loggedInUserDetails.login_type', ''),
        collection_method: this.state.collection_method,
        payment_method: this.state.selectedPayment,
        cart_items: this.state.cartItems,
        selected_users: this.state.selected_users,
      },
      amount: _.round(this.state.grandTotal * 100, 0),
    }

    setTimeout(() => {
      if (_.isEmpty(this.state.intent_clientSecret)) {
        this.props.stripePaymentIntent(paymentIntent_data, (data) => {
          try {
            if (data.clientSecret) {
              this.setState({
                intent_clientSecret: data.clientSecret,
              })
            } else {
              console.log(
                'error> at /stripe/create-payment-intent cart.js',
                data.error
              )
            }
          } catch (error) {
            console.log('error> at /stripe/create-payment-intent cart.js')
          }
        })
      }
    }, 1000)
  }
  showGuestCheckout = () => {
    this.setState({ guestVisible: true })
    setTimeout(() => {
      window.scrollBy({
        top: 500, // Adjust the value as needed for the desired scroll amount
        behavior: "smooth"
      });
    }, 500);
  }

  handleCancel = () => {
    this.showACHModal(false)
    this.setState({
      intent_clientSecret: '',
    })
  }

  render() {
    const classes = {}
    const amount = _.get(this, 'state.response.amount', '')
    const mData = _.get(this, 'state.response.resp.data', '')
    const mailData = _.get(mData, 'data.mailobj', '')
    const sessionData = _.get(mailData, 'data', '')
    console.log('afterPayment>>>>>>>', sessionData)
    const resultArray = Object.entries(sessionData).map(([key, value]) => {
      return {
        key,
        amount: value.amount ?? null,
        event_details: value.event_details ?? null,
        competition_details: value.competition_details ?? null,
        class_details: value.class_details ?? null,
        camp_details: value.camp_details ?? null,
        classes: value.classes ?? null,
        camp: value.camp ?? null,
        competition: value.competition ?? null,
        event: value.event ?? null,
        user: value.user ?? null,
      };
    });
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { month: 'short', day: '2-digit', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    };

    return (
      <div className="nurtem-table">
        <div className="full-width py-2 mt-2 mb-3">
          {this.state.successPay ? (
            <div className="user-success-page">
              <div className="displaysuccess">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                  <div style={{ display: 'flex', alignItems: 'center' }} className='mt-3'>
                    <EmptyAnime animeheight='60px' AnimeType="PaySuccess" loop />
                    <span className="h4success">Thanks for your order!</span>
                  </div>
                </div>
                {/* <div className="amount-container ">
                  <span className="total-amount">{this.props.t('totalAmount')}{"  "}</span>
                  {amount === 0 ? (
                    <span className="free-class">{this.props.t('freeClass')}</span>
                  ) : (
                    <span className="amount">${amount / 100}</span>
                  )}
                </div> */}
                <div className='p-4'>
                  {resultArray.length !== 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
                      {resultArray.map((session) => (
                        <div >
                          <div style={{ fontSize: '14px', fontWeight: 600, color: 'grey' }}>
                            {session.classes != null && session.classes.name}
                            {session.camp != null && session.camp.name}
                            {session.competition != null && session.competition.name}
                            {session.event != null && session.event.name}
                          </div>
                          <div style={{ fontSize: '25px', fontWeight: 600, color: '#2e005c' }}>
                            <div></div>
                            {session.class_details != null && session.class_details.session_name}
                            {session.camp_details != null && session.camp_details.session_name}
                            {session.competition_details != null && session.competition_details.session_name}
                            {session.event_details != null && session.event_details.session_name}
                          </div>
                          <div style={{ display: 'flex', gap: '40px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ fontSize: '14px', fontWeight: 600, color: '#000' }}>Booking info sent to</div>
                              {session.user != null && session.user.email}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ fontSize: '14px', fontWeight: 600, color: '#000' }}>Location</div>
                              {session.class_details != null && session.class_details.location_taught}
                              {session.camp_details != null && session.camp_details.location_taught}
                              {session.competition_details != null && session.competition_details.location_taught}
                              {session.event_details != null && session.event_details.location_taught}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ fontSize: '14px', fontWeight: 600, color: '#000' }}>Session Dates</div>
                              {session.class_details != null && `${formatDate(session.class_details.start_date)} - ${formatDate(session.class_details.end_date)}`}
                              {session.camp_details != null &&
                                `${formatDate(session.camp_details.start_date)} - ${formatDate(session.camp_details.end_date)}`
                              }
                              {session.competition_details != null && `${formatDate(session.competition_details.start_date)} - ${formatDate(session.competition_details.end_date)}`}
                              {session.event_details != null && `${formatDate(session.event_details.start_date)} - ${formatDate(session.event_details.end_date)}`}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className='free-class'>Bank is processing the Payment</span>
                  )}

                </div>
                <button
                  className="btnStyle"
                  onClick={() => this.successPayment()}
                >
                  <AiOutlineCloseCircle style={{ fontSize: 25 }} />
                </button>
              </div>
            </div>
          ) : !this.state.successPay &&
            !this.state.loadingcart &&
            !this.props.onBookingsCreatedLoading ? (
            <Collapse activeKey="1" accordion>
              <Panel header="My Cart" key="1">
                {Object.keys(this.state.cartItems).length > 0 &&
                  !this.state.successPay ? (
                  <div className="user-cart-table-content">
                    <Table
                      className="cartTable"
                      dataSource={this.state.dataSource}
                      columns={this.columns}
                      pagination={false}
                    />

                    <div className="full-width p-3">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-12 col-md-4">
                          {this.props.loggedInUserDetails &&
                            this.props.loggedInUserDetails.isLoggedIn && (
                              <div className="row justify-content-between align-items-center">
                                <div className="col-12 mb-4">
                                  <Form.Item>
                                    <label style={{ marginTop: '5px', fontSize: '12px', color: 'rgb(136 136 136)' }}
                                      className="full-width item-text-bold">Pay my bill automatically each time it is due.
                                    </label>
                                    <span className="item-text-bold">Enable Auto Pay </span>
                                    <Switch
                                      checkedChildren="On"
                                      unCheckedChildren="Off"
                                      checked={this.state.collection_method === 'charge_automatically'}
                                      onChange={(checked) => {
                                        this.changeInput(
                                          checked ? 'charge_automatically' : null, // Replace `null` with your default value if needed
                                          'collection_method'
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </div>
                                <div className="col-12">
                                  <Form.Item>
                                    <label className="full-width item-text-bold">
                                      {this.props.t('applyCoupons')}
                                    </label>
                                    <Input
                                      value={this.state.couponCode}
                                      onChange={(e) =>
                                        this.changeInput(e.target.value)
                                      }
                                      placeholder="MJH767GH"
                                      suffix={<i className="" />}
                                    />
                                    <div className="d-flex mt-3">
                                      <Button
                                        className="ant-btn ant-btn-primary ant-btn-md mr-3"
                                        type="primary"
                                        danger
                                        onClick={() => this.onRejectCoupons()}
                                      >
                                        {this.props.t('remove')}
                                      </Button>
                                      <Button
                                        loading={this.state.couponsLoading}
                                        className="ant-btn ant-btn-primary ant-btn-md mr-3"
                                        type="primary"
                                        onClick={() => this.onApplyCoupons()}
                                      >
                                        {this.props.t('applyCoupons')}
                                      </Button>
                                    </div>
                                  </Form.Item>
                                </div>
                              </div>
                            )}
                        </div>

                        <div className="col-12 col-md-4 mt-2 mt-md-0">
                          {_.get(classes, 'tax_percentage') !== 0 && (
                            <div
                              className="row"
                              style={{ marginRight: '80px' }}
                            >
                              <div
                                className="col-8 col-md-6"
                                style={{ paddingRight: 58 }}
                              >
                                <div className="item-text-regular-16 text-right">
                                  {this.props.t('tax')}
                                </div>
                              </div>
                              <div className="col-4 col-md-6 text-right">
                                <div className="item-text-bold-16">
                                  ${_.round(this.state.taxTotal, 2)}
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="row" style={{ marginRight: '80px' }}>
                            <div
                              className="col-8 col-md-6"
                              style={{ padding: 0 }}
                            >
                              <div className="item-text-regular-16 text-right">
                                {this.props.t('gt')}
                              </div>
                            </div>
                            <div className="col-4 col-md-6 text-right">
                              <div className="item-text-bold-16">
                                ${_.round(this.state.grandTotal, 2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="my-5">
                    <EmptyAnime AnimeType="EmptyUserSide" />
                    <p className="empty-animations">
                      {this.props.t('cartEmpty')}
                    </p>
                  </div>
                )}

                {!_.get(this.props, 'loggedInUserDetails.isLoggedIn', false) &&
                  Object.keys(this.state.cartItems).length > 0 ? (
                  <div className="row public-pay">
                    <div className="col-12 col-sm-5 col-md-6 co-lg-6 col-xl-6 mr-3">
                      <div className="account-card-details">
                        <span className="sm-w-100">
                          {this.props.t('alreadySignedUp')}{' '}
                          <span
                            className="SkipLink ml-2"
                            onClick={() => {
                              history.push(
                                `${routeName.signIn}?redirectTo=${routeName.myCart}`
                              )
                            }}
                          >
                            Login & Checkout
                          </span>
                        </span>
                        <span className="px-4 sm-w-100">or</span>
                        <Button
                          type="primary"
                          onClick={this.showGuestCheckout}
                        >
                          {this.props.t('guestCheck')}
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  Object.keys(this.state.cartItems).length > 0 && (
                    <div className="row user-pay">
                      <div className="col-12 col-sm-5 col-md-6 co-lg-3 col-xl-3 mr-3 ">
                        <div className="account-card-details ">
                          {navigator.onLine ? (
                            this.props.loggedInUserDetails &&
                              this.props.loggedInUserDetails.isLoggedIn &&
                              this.state.selectedPayment === 'Stripe' ? (
                              this.state.grandTotal > 0 ? (
                                <Button
                                  style={{ width: '100%' }}
                                  type="primary"
                                  disabled={this.state.grandTotal < 1}
                                  onClick={() => this.handleAch()}
                                >
                                  {this.props.t('payNow')}
                                </Button>
                              ) : (
                                <Button
                                  style={{ width: '100%' }}
                                  type="primary"
                                  onClick={() => this.handleFreeEnrollment()}
                                >
                                  You're welcome to enroll
                                </Button>
                              )
                            ) : ('')
                          ) : (
                            <Button
                              type="primary"
                              onClick={() => this.setState({ refreshCount: 1 })}
                            >
                              {this.props.t('alreadySignedUp')}
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </Panel>
            </Collapse>
          ) : (
            !this.state.successPay && <CartLoader />
          )}
        </div>
        {this.state.guestVisible &&
          !_.get(this.props, 'loggedInUserDetails.isLoggedIn', false) &&
          Object.keys(this.state.cartItems).length > 0 &&
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-8 co-lg-8 col-xl-8'>
              <GuestFlow />
            </div>
            <div className='col-12 col-sm-12 col-md-4 co-lg-4 col-xl-4 py-3'>
              <div className='summaryCard p-3'>
                <div style={css.heading1} className='ml-3'>Cart Summary</div>
                <div className='summaryItems' style={{ marginBottom: '10px' }}>
                  <span style={{ color: '#1890ff' }}>Item (s)</span>
                  <span style={{ color: 'black', fontWeight: 600 }}>{Object.keys(this.state.cartItems).length}</span>
                </div>
                <div className='summaryItems' style={{ margin: '10px 0px' }}>
                  <span style={{ color: '#1890ff' }}>Total Tax</span>
                  <span style={{ color: 'black', fontWeight: 600 }}>${_.round(this.state.taxTotal, 2)}</span>
                </div>
                <div className='summaryItems' style={{ margin: '10px 0px' }}>
                  <span style={{ color: '#1890ff' }}>Grand Total</span>
                  <span style={{ color: 'black', fontWeight: 600 }}>${_.round(this.state.grandTotal, 2)}</span>
                </div>
              </div>
            </div>
          </div>
        }
        <Modal
          title="Payment Powered by Stripe"
          open={this.state.isACHModalOpen}
          onCancel={this.handleCancel}
          footer={null}
        >
          {this.state.intent_clientSecret ? (
            <Elements
              options={{
                clientSecret: this.state.intent_clientSecret,
                theme: 'stripe',
              }}
              stripe={loadStripe(globalVariable.STRIPE_PUBLISHABLE_KEY)}
            >
              <CheckoutForm
                paymentIntent_data={{
                  payment_data: {
                    login_type: _.get(
                      this.props,
                      'loggedInUserDetails.login_type',
                      ''
                    ),
                    collection_method: this.state.collection_method,
                    payment_method: this.state.selectedPayment,
                    cart_items: this.state.cartItems,
                    selected_users: this.state.selected_users,
                  },
                  amount: _.round(this.state.grandTotal * 100, 0),
                }}
                showACHModal={this.showACHModal}
                onCreateBookings={this.props.onCreateBookings}
                createBookingCallback={(resp) =>
                  this.createBookingCallback(resp)
                }
                clientSecret={this.state.intent_clientSecret}
              />
            </Elements>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </div>
          )}
        </Modal>
      </div >
    )
  }
}

const mapStateToProps = (state) => ({
  redux_cart_Items: state.search.cartItems,

  userBaseProfile: state.users.userBaseProfile.details,
  tutorDetails: state.tutorProfile.tutorBaseProfile.details,
  itemsExist: state.classes.addedCartItems,
  itemsDetails: state.classes.cartItemDetails,
  contentLoading: state.classes.contentLoading,
  cartItemLoading: state.classes.cartItemLoading,
  dependentUsers: state.userProfile.dependentUsers,
  couponsApplyResponse: state.userProfile.couponsApplyResponse,
  bookingsResponse: state.users.bookingsResponse,
  onBookingsCreatedLoading: state.users.onBookingsCreatedLoading,
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  loggedInUserDetails: state.authentication,
  customerDetails: state.classes.customerDetails,
  userProfileUpdate: state.users.updateUserProfileInfo,
  updatedTutorProfile: state.tutorProfile.ProfilesUpdateSuccess,
})

const mapDispatchToProps = (dispatch) => ({

  setCartItemsRedux: (requestParams) =>
    dispatch(searchAction.common(cartItems, requestParams)),

  onItemAdd: () => dispatch(action.cartItemAdd()),
  getDependentUsers: (data) => dispatch(userAction.getDependentUsers(data)),
  couponsApply: (data, callback) =>
    dispatch(userAction.couponsApplyCode(data, callback)),
  onCreateBookings: (data, url, bookingCallback) =>
    dispatch(userAction.onCreateBookings(data, url, bookingCallback)),
  newCustomers: (data, callback) =>
    dispatch(action.newCustomers(data, callback)),
  stripePaymentIntent: (data, callback) =>
    dispatch(action.stripe_payment_intent(data, callback)),
  onUpdateUserProfileInfo: (request) =>
    dispatch(userAction.updateUserProfileInfo(request)),
  onProfileUpdate: (requestPar) =>
    dispatch(tutorActions.onProfileUpdate(requestPar)),
  onTutorDashboardsiderbar: (requestParams) =>
    dispatch(servicesActions.onTutorDashboardsiderbar(requestParams)),
  onGetCurrentLocation: (callback) =>
    dispatch(servicesActions.onGetCurrentLocation(callback)),
  getAttendanceList: (requestParams) =>
    dispatch(generalAction.user.getAttendanceList(requestParams)),
  onmonthlypayment: (request, callback) =>
    dispatch(userAction.monthlybooking(request, callback)),
  onsessionsLeft: (request, callback) =>
    dispatch(userAction.sessionsLeft(request, callback)),
  onUserProfile: () => dispatch(userAction.userProfile()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserMyCart))
