/**
 * @author ramsteyn17@gmail.com
 * @dated 09/05/2023
 */

import React from 'react';
import './invoice.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { withTranslation } from 'react-i18next';
import { LOGO } from '../../../utils/config/assets';

function Invoice(props) {
  console.log('invoice1=========', props);
  window.scrollTo(0, 0); // scroll to top
  const data = props.location.state;
  const to_who = data.dependent_user_name !== '--' ? data.dependent_user_name : data.user_name;
  const address = data.dependent_address ? data.dependent_address : data.user_address ? data.user_address : '';
  const phone = data.dependent_phone ? data.dependent_phone : data.user_phone ? data.user_phone : '';
  const email = data.dependent_email ? data.dependent_email : data.user_email ? data.user_email : '';
  const {
    transaction_id,
    paid_date,
    providerName,
    price,
    tax_percentage,
    number_session_paid,
    className,
    paid_amount,
    class_type_label,
  } = props.location.state;
  const saveName = `Nurtem_invoice_${className}.pdf`;
  function GenerateInvoice() {
    html2canvas(document.querySelector('#invoiceCapture')).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'pt',
        format: [612, 792],
      });
      pdf.internal.scaleFactor = 1;
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(saveName);
    });
  }
  return (

    <div className="nurtem-card-design mb-4 col-md-12">
      <div className="invoice-company text-inverse f-w-600">
        <span className="pull-right hidden-print">
          <button onClick={GenerateInvoice} className="btn btn-sm btn-white m-b-10 p-l-5">
            <span style={{ marginRight: '8px', fontSize: '20px', fontWeight: 600 }}>&#128438;</span>
            {' '}
            {props.t('exportPdf')}
          </button>
        </span>
      </div>

      <div id="invoiceCapture">
        <div className="invoice">
          <img src={LOGO} alt="Nurtem Logo" />

          <div className="invoice-header mt-2">
            <div className="invoice-from">
              <small>From</small>
              <address className="m-t-5 m-b-5">
                <strong className="text-inverse">{props.t('nurtemInc')}</strong>
                <br />
                {props.t('fremont')}
                {' '}
                <br />
                {props.t('california')}
                <br />
                {props.t('phoneNurtem')}
                <br />
                {props.t('emailNurtem')}
              </address>
            </div>
            <div className="invoice-to">
              <small>To</small>
              <address className="m-t-5 m-b-5">
                <strong className="text-inverse">{to_who}</strong>
                <br />
                {address}
                <br />
                {props.t('phNo')}
                :
                {phone}
                <br />
                {props.t('email')}
                :
                {email}
                <br />
              </address>
            </div>
            <div className="invoice-date">
              <small>
                {props.t('invoice')}
                {transaction_id}
                <br />
              </small>
              <div className="date text-inverse m-t-5">{paid_date}</div>
              <div className="invoice-detail" />
            </div>
          </div>

          <div className="invoice-content">
            <div className="table-responsive">
              <table className="table table-invoice">
                <thead>
                  <tr>
                    <th>
                      {class_type_label}
                      {' '}
                      {props.t('nameLabel')}
                    </th>
                    <th className="text-center" width="20%">{props.t('proName')}</th>
                    <th className="text-center" width="10%">{props.t('sessPurchased')}</th>
                    <th className="text-center" width="10%">{props.t('price')}</th>
                    <th className="text-center" width="10%">{props.t('tax')}</th>
                    <th className="text-right" width="20%">{props.t('total')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span className="text-inverse">{className}</span>
                      <br />
                      {/* <small>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id sagittis arcu.</small> */}
                    </td>
                    <td className="text-center">{providerName}</td>
                    <td className="text-center">{number_session_paid}</td>
                    <td className="text-center">
                      $
                      {price}
                    </td>
                    <td className="text-center">
                      {tax_percentage}
                      %
                    </td>
                    <td className="text-right">
                      $
                      {paid_amount}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="invoice-price">
              {/* <div className="invoice-price-left">
                            <div className="invoice-price-row">
                                <div className="sub-price">
                                    <small>SUBTOTAL</small>
                                    <span className="text-inverse">$4,500.00</span>
                                </div>
                                <div className="sub-price">
                                    <i className="fa fa-plus text-muted"></i>
                                </div>
                                <div className="sub-price">
                                    <small>PAYPAL FEE (5.4%)</small>
                                    <span className="text-inverse">$108.00</span>
                                </div>
                            </div>
                        </div> */}
              <div className="invoice-price-right">
                <small>{props.t('total')}</small>
                {' '}
                <span className="f-w-600">
                  $
                  {paid_amount}
                </span>
              </div>
            </div>
          </div>

          <div className="invoice-note">
            {props.t('invoiceNote1')}
            {' '}
            <br />
            {props.t('invoiceNote2')}
            {' '}
            <br />
            {props.t('invoiceNote3')}

          </div>

          <div className="invoice-footer">
            <p className="text-center m-b-5 f-w-600">
              {props.t('invoiceNote4')}
            </p>
            <p className="text-center">
              <span className="m-r-10">
                <span style={{ padding: '0px 8px' }}>&#127760;</span>
                {props.t('invoiceNote5')}
              </span>
              <span className="m-r-10">
                <span style={{ padding: '0px 8px' }}>&#9742;</span>
                {props.t('invoiceNote6')}
              </span>
              <span className="m-r-10">
                <span style={{ padding: '0px 8px' }}>&#9993;</span>
                {' '}
                {props.t('invoiceNote7')}
              </span>

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Invoice);
