import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import { Provider } from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore from './store'
import './i18n/i18n'
import css from './utils/CSSUtility'
import { ConfigProvider } from 'antd'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { globalVariable } from './utils/config/config.js'
ReactDOM.render(
  <Provider store={configureStore()}>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: css.color.primary,
          complementaryColor: css.color.complementary
        },
      }}
    >
      <GoogleOAuthProvider clientId={globalVariable.GOOGLE_ID}>
        <App />
      </GoogleOAuthProvider>
    </ConfigProvider>
  </Provider>,

  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
