import * as actionTypes from '../../../actions/tutor/add-class/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
  validateAddClassStep1: false,
  validateAddClassStep2: false,
  currentPage: 0,
  saveToLocalStorage: 0,
  classCreatedSuccessfully: false,
  loading: false,
  profile_step_1: {
    service_ids: { data: [], fetching: false },
    location_taught: { data: [], fetching: false },
    services: { data: [], fetching: false },
  },
  classData: {},
  faqLoading: false,
  setClassPhotosloading: false,
};

const enableValidateAddClassStep1 = (state, action) => updateObject(state, action.data);
const disableValidateAddClassStep1 = (state, action) => updateObject(state, action.data);
const enableValidateAddClassStep2 = (state, action) => updateObject(state, action.data);
const disableValidateAddClassStep2 = (state, action) => updateObject(state, action.data);
const saveToLocalStorage = (state, action) => updateObject(state, action.data);
const nextPage = (state, action) => updateObject(state, { currentPage: state.currentPage + 1 });
const previousPage = (state, action) => updateObject(state, { currentPage: state.currentPage - 1 });
const resetPage = (state, action) => updateObject(state, action.data);
const addClassEnableLoading = (state, action) => updateObject(state, action.loading);
const faqLoading = (state, action) => ({ ...state, faqLoading: action.loading });
const addClassDisableLoading = (state, action) => updateObject(state, action.loading);
const beforeFetchingServices = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const afterFetchingServices = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const afterFetchingServicesValues = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const beforeFetchingLocations = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const beforeFetchingServicesValues = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const afterFetchingLocations = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const classCreatedSuccessfully = (state, action) => updateObject(state, action.data);
const setClassData = (state, action) => updateObject(state, action.data);
const setClassPhotosData = (state, action) => ({ ...state, setClassPhotosloading: action.loading });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.enableValidateAddClassStep1:
      return enableValidateAddClassStep1(state, action);
    case actionTypes.disableValidateAddClassStep1:
      return disableValidateAddClassStep1(state, action);
    case actionTypes.enableValidateAddClassStep2:
      return enableValidateAddClassStep2(state, action);
    case actionTypes.disableValidateAddClassStep2:
      return disableValidateAddClassStep2(state, action);
    case actionTypes.saveToLocalStorage:
      return saveToLocalStorage(state, action);
    case actionTypes.nextPage:
      return nextPage(state, action);
    case actionTypes.previousPage:
      return previousPage(state, action);
    case actionTypes.resetPage:
      return resetPage(state, action);
    case actionTypes.addClassEnableLoading:
      return addClassEnableLoading(state, action);
    case actionTypes.faqLoading:
      return faqLoading(state, action);
    case actionTypes.addClassDisableLoading:
      return addClassDisableLoading(state, action);
    case actionTypes.beforeFetchingServices:
      return beforeFetchingServices(state, action);
    case actionTypes.afterFetchingServices:
      return afterFetchingServices(state, action);
    case actionTypes.afterFetchingServicesValues:
      return afterFetchingServicesValues(state, action);
    case actionTypes.beforeFetchingLocations:
      return beforeFetchingLocations(state, action);
    case actionTypes.beforeFetchingServicesValues:
      return beforeFetchingServicesValues(state, action);
    case actionTypes.afterFetchingLocations:
      return afterFetchingLocations(state, action);
    case actionTypes.classCreatedSuccessfully:
      return classCreatedSuccessfully(state, action);
    case actionTypes.setClassData:
      return setClassData(state, action);
    case actionTypes.setClassPhotosData:
      return setClassPhotosData(state, action);

    default:
      return state;
  }
};

export default reducer;
