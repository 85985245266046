import * as actionTypes from '../../actions/common/actionTypes';

const initialState = {
  updateTourGuide: {},
};

const updateTourGuide = (state, action) => ({ ...state, updateTourGuide: action.success });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.updateTourGuide:
      return updateTourGuide(state, action);

    default:
      return state;
  }
};
export default reducer;
