import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  Input,
  Button,
  Rate,
  Form,
  Carousel,
  DatePicker,
  Select,
  Spin,
} from 'antd'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'
import dayjs from 'dayjs'
import * as tutorActions from '../../../store/actions/tutor/index'
import * as userAction from '../../../store/actions/users'
import * as campsActions from '../../../store/actions/public/Camps'
import * as action from '../../../store/actions/public/Classes'
import * as actions from '../../../store/actions/authentication/authentication'
import { NurtemUtils } from '../../../utils'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withErrorHandler } from '../../../utils/handler'
import axios from '../../../utils/axios'
import './guest-flow.css'
import classTimingsOnly from '../../../components/timezone/onlytiming'
import { IoIosSearch } from 'react-icons/io'
import * as routeName from '../../../components/route-names';
import history from '../../../utils/history';



class ContactInfoTab extends React.Component {
  userFormRef = React.createRef()

  state = {
    address_results: [],
    fetching: false,
    timeZones: 'Asia/Kolkata',
    cartItems: [],
    ItemsDetails: {},
    campsTotalAmount: 0,
    cartType: '',
    userFormData: {},
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.itemsExist && this.props.itemsExist.length > 0) {
      const requestParams = {}
      requestParams.classes = this.props.itemsExist.join(',')
      this.props.getCartItems(requestParams)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    /* End of the Camps cart details */
    if (prevProps.itemsExist !== this.props.itemsExist) {
      if (this.props.itemsExist && this.props.itemsExist.length > 0) {
        const requestParams = {}
        requestParams.classes = this.props.itemsExist.join(',')
        this.props.getCartItems(requestParams)
      }
    }
    if (prevProps.itemsDetails !== this.props.itemsDetails) {
      if (
        this.props.itemsDetails &&
        Object.keys(this.props.itemsDetails).length > 0
      ) {
        this.setState({
          ItemsDetails: _.get(
            this.props,
            'itemsDetails.items[0].classes[0]',
            {}
          ),
          cartDetailsItems: this.props.itemsDetails.items,
          cartType: 'class',
        })
      }
    }
  }

  passwordValidation = (rule, value, callback, message) => {
    const passw =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}/
    if (value && !value.match(passw)) {
      callback(message)
    } else {
      callback()
    }
  }

  confirmPassword = (rule, value, callback, message) => {
    const password = this.userFormRef.current.getFieldValue('password')
    if (value && value !== password) {
      callback(this.props.t('authenticationRequiredPasswordValidationMessage2'))
    } else {
      callback()
    }
  }

  emailvalidation = (rule, value, callback) => {
    if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      callback(this.props.t('settings2'))
    } else {
      callback()
    }
  }

  fetchAddress = (value) => {
    const state_values = this.state
    this.setState({
      ...state_values,
      address_results: [],
      address_fetching: true,
    })
    geocodeByAddress(value)
      .then((body) => {
        const address_results = _.map(body, (location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            text: location.formatted_address,
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
            postal_code: NurtemUtils.fetchPostalCode(
              _.get(location, 'address_components', [])
            ),
          }),
        }))
        this.setState({
          ...state_values,
          address_results,
          address_fetching: false,
        })
      })
      .catch((error) => {
        this.setState({
          ...state_values,
          address_results: [],
          address_fetching: false,
        })
      })
  }

  onChangeForm = async (value, key) => {
    const { userFormData } = this.state
    userFormData[key] = value
    this.setState({ userFormData })
  }

  // Initializing debounce for address
  addressDelayedQuery = _.debounce((q) => this.fetchAddress(q), 1200)

  onFinish = () => {
    const userFormData = this.userFormRef.current.getFieldValue()
    history.push(`?redirectTo=${routeName.myCart}`)
    console.log('formdaata', userFormData)
    this.props.onUserSignUp(userFormData)
  }

  viewPreview = (ItemsDetails, cartType, campsTotalAmount) => {
    if (_.get(ItemsDetails, 'teaching_mode')) {
      return (
        <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
          <div className="nurtem-card-design px-0">
            <div className="px-3">
              <div className="row py-3">
                <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                  <div className="profile-image">
                    <img
                      src={_.get(ItemsDetails, 'provider_profile[0].photo', '')}
                      className="avatar img-fluid"
                      alt="img"
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                  <div className="profile-name text-truncate">
                    {`${_.startCase(_.get(ItemsDetails, 'provider_profile[0].firstname', ''))} ` +
                      `${_.get(ItemsDetails, 'provider_profile[0].lastname', '') !== '' ? `${_.startCase(_.get(ItemsDetails, 'provider_profile[0].lastname', ''))[0]}.` : ''} `}
                  </div>
                  <div className="star-rating">
                    <Rate
                      disabled="true"
                      defaultValue={_.get(
                        ItemsDetails,
                        'provider_profile[0].average_stars',
                        0
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="orderSummeryTitle">{this.props.t('orderSum')}</div>
            <div className="orderSummeryDetails">
              <div className="full-width py-2">
                <div className="full-width">
                  <b>{ItemsDetails.name}</b>
                </div>
                {cartType === 'camps' ? (
                  <div className="full-width">Every</div>
                ) : (
                  <div className="full-width">
                    <Carousel className="carousel-text" speed={100} autoplay>
                      {classTimingsOnly(ItemsDetails)}
                    </Carousel>
                  </div>
                )}
              </div>
              <div className="orderSummeryDetailsTotal full-width py-2 d-flex justify-content-between">
                <span>{this.props.t('gt')} </span>
                <b>
                  $
                  {cartType === 'camps' ? campsTotalAmount : ItemsDetails.price}
                </b>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  // cancelForm = async () => {
  //   this.props.history.push('/mycart')
  // }

  render() {
    const { ItemsDetails, cartType, campsTotalAmount } = this.state
    return (
      <div className="steps-inner-container info-container">
        <Form
          scrollToFirstError
          onFinish={(e) => this.onFinish(e)}
          ref={this.userFormRef}
          className="row"
        >
          <div className="row col-12">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ml-3">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Firstname"
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'firstname')
                    }
                    name="firstname"
                    rules={[
                      {
                        required: true,
                        pattern: '^[a-zA-Z ]*$',
                        message: this.props.t('firstname1'),
                      },
                    ]}
                  >
                    <Input placeholder="Enter your First Name" maxLength={20} />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Lastname"
                    rules={[
                      {
                        required: true,
                        pattern: '^[a-zA-Z ]*$',
                        message: this.props.t('lastname1'),
                      },
                    ]}
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'lastname')
                    }
                    name="lastname"
                  >
                    <Input placeholder="Enter your Last Name" maxLength={20} />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Mobile Number"
                    rules={
                      [
                        // () => ({
                        //   validator(rule, value) {
                        //     value = _.trim(value)
                        //     // if (_.isEmpty(value)) {
                        //     //     return Promise.reject(this.props.t("phNo5"))
                        //     // } else
                        //     if (value) {
                        //       if (!value.match('^[0-9]*$')) {
                        //         return Promise.reject(this.props.t('phNo4'))
                        //       } else if (value.length < 10) {
                        //         return Promise.reject(this.props.t('phNo2'))
                        //       } else {
                        //         return Promise.resolve()
                        //       }
                        //     }
                        //   },
                        // }),
                      ]
                    }
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'mobile_number')
                    }
                    name="mobile_number"
                  >
                    <Input
                      placeholder="Enter your Phone Number"
                      type="number"
                      minLength={8}
                      maxLength={15}
                    />
                  </Form.Item>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <Form.Item
                                        label="Age"
                                        name="age"
                                        rules={[{ required: true, message: 'Please input your age!' }]}>
                                        <InputNumber onChange={(e) => this.onChangeForm(e, 'age')} max={100} min={18} maxLength={3} parser={(value) => value.replace(/[^0-9]/g, "")} />
                                    </Form.Item>
                                </div> */}
                {/* <div className="gender-profile col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <Form.Item name="gender" label="Gender">
                    <Select
                      className="selectBox"
                      getPopupContainer={(trigger) => trigger.parentNode}
                      onChange={(e) => this.onChangeForm(e, 'gender')}
                      placeholder={
                        this.state.userFormData.gender
                          ? this.state.userFormData.gender
                          : 'Select Gender'
                      }
                    >
                      <Select.Option key="Male">Male</Select.Option>
                      <Select.Option key="Female">Female</Select.Option>
                      <Select.Option key="Others">Others</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div
                  className="col-12 col-sm-12 col-md-4 col-lg-2 col-xl-2"
                  style={{ paddingRight: 0, paddingLeft: 5 }}
                >
                  <Form.Item label="Birthdate" name="dob">
                    <DatePicker
                      className="birthDateBox"
                      defaultPickerValue={dayjs().subtract(13, 'years')}
                      format="MM/DD/YYYY"
                      disabledTime
                      style={{ borderRadius: 20, padding: '7px 15px' }}
                      onChange={(e) => this.onChangeForm(e, 'dob')}
                      disabledDate={(currentDate) =>
                        currentDate.isAfter(
                          dayjs().subtract(13, 'years'),
                          'day'
                        )
                      }
                    />
                  </Form.Item>
                </div> */}

                <div className="adress col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Address"
                    name="address"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your address!',
                      },
                    ]}
                  >
                    <Select
                      getPopupContainer={(trigger) => trigger.parentNode}
                      className="selectoradd"
                      showSearch
                      allowClear
                      suffixIcon={<IoIosSearch />}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      onSearch={(search) =>
                        search ? this.addressDelayedQuery(search) : ''
                      }
                      onChange={(e) => this.onChangeForm(e, 'address')}
                      placeholder={'Search address...'}
                      notFoundContent={
                        this.state.address_fetching ? (
                          <Spin size="small" />
                        ) : null
                      }
                    >
                      {this.state.address_results &&
                        this.state.address_results.length > 0 &&
                        this.state.address_results.map((d) => (
                          <Select.Option key={d.text}>{d.text}</Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Zipcode"
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'zip_code')
                    }
                    name="zip_code"
                    rules={[
                      () => (
                        {
                          validator(rule, value) {
                            value = _.trim(value)
                            // if (_.isEmpty(value)) {
                            //     return Promise.reject(this.props.t("zipcode1"))
                            // } else
                            if (value) {
                              if (!value.match('^[0-9]*$')) {
                                return Promise.reject(this.props.t('zipcode2'))
                              } else if (value.length < 4) {
                                return Promise.reject(this.props.t('zipcode3'))
                              } else {
                                return Promise.resolve()
                              }
                            }
                          },
                        },
                        {
                          required: true,
                          message: 'Please input your zipcode!',
                        }
                      ),
                    ]}
                  >
                    <Input placeholder="Enter your Zipcode" maxLength={6} />
                  </Form.Item>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Email"
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'username')
                    }
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: this.props.t('inputValidEmail'),
                      },
                      {
                        type: 'email',
                        message: this.props.t('inputValidEmail'),
                      },
                    ]}
                  >
                    <Input placeholder="Enter your Email ID" maxLength={50} />
                  </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Password"
                    dependencies={['new_password']}
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'password')
                    }
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: this.props.t('enterPass'),
                      },
                      {
                        validator: (rule, value, callback) =>
                          this.passwordValidation(
                            rule,
                            value,
                            callback,
                            'At least one number, one lowercase, one uppercase letter and at least 8 characters'
                          ),
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="new-password"
                      disabled={this.props.loading}
                      placeholder="Password"
                    />
                  </Form.Item>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <Form.Item
                    label="Confirm password"
                    dependencies={['password']}
                    onChange={(e) =>
                      this.onChangeForm(e.target.value, 'new_password')
                    }
                    name="new_password"
                    rules={[
                      {
                        required: true,
                        message: this.props.t('enterPass'),
                      },
                      {
                        validator: (rule, value, callback) =>
                          this.confirmPassword(
                            rule,
                            value,
                            callback,
                            'At least one number, one lowercase, one uppercase letter and at least 8 characters'
                          ),
                      },
                    ]}
                  >
                    <Input.Password
                      autoComplete="new-password"
                      disabled={this.props.loading}
                      placeholder="Password"
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            {this.viewPreview(ItemsDetails, cartType, campsTotalAmount)}
            <div className="col-12 text-right">
              <div className="steps-action">
                <Button
                  loading={this.props.loading}
                  type="primary"
                  htmlType="submit"
                >
                  {"Guest Check Out"}
                </Button>
                {/* <Button
                  onClick={() => this.cancelForm()}
                  style={{ marginRight: '12px' }}
                  //  className="FormAddButtom"
                  type="primary"
                  danger
                >
                  {this.props.t('cancel')}
                </Button> */}
              </div>
            </div>
          </div>
        </Form>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  itemsExist: state.classes.addedCartItems,
  itemsDetails: state.classes.cartItemDetails,
  contentLoading: state.classes.contentLoading,
  cartItemLoading: state.classes.cartItemLoading,
  loggedInUserDetails: state.authentication,
  userProfileUpdate: state.users.updateUserProfileInfo,
  updatedTutorProfile: state.tutorProfile.ProfilesUpdateSuccess,
  loading: state.authentication.loading,
})

const mapDispatchToProps = (dispatch) => ({
  getCartItems: (data) => dispatch(action.getCartItems(data)),
  onUpdateUserProfileInfo: (request) =>
    dispatch(userAction.updateUserProfileInfo(request)),
  onProfileUpdate: (requestPar) =>
    dispatch(tutorActions.onProfileUpdate(requestPar)),
  onUserSignUp: (credentials) => dispatch(actions.signUpUser(credentials)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(withTranslation()(withRouter(ContactInfoTab)), axios))
