import _ from 'lodash';
import { notification } from 'antd';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import { resetPage } from './add-camp';
import { onBusinessClassesList } from './Classes';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';

export const activeKey = (data) => ({
  type: actionTypes.active_key,
  success: data,
});

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: true,
});
export const enableCampListLoading = () => ({
  type: actionTypes.enableCampListLoading,
  loadingCampList: { loadingCampList: true },
});
export const enableCampDetailsLoading = () => ({
  type: actionTypes.enableCampDetailsLoading,
  loadingCampDetails: { loadingCampDetails: true },
});
export const enableSessionLoading = () => ({
  type: actionTypes.enableSessionLoading,
  loadingSession: { loadingSession: true },
});
export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: false,
});
export const disableCampListLoading = () => ({
  type: actionTypes.disableCampListLoading,
  loadingCampList: { loadingCampList: false },
});
export const disableCampDetailsLoading = () => ({
  type: actionTypes.disableCampDetailsLoading,
  loadingCampDetails: { loadingCampDetails: false },
});
export const disableSessionLoading = () => ({
  type: actionTypes.disableSessionLoading,
  loadingSession: { loadingSession: false },
});
export const enableCampsRequestLoading = () => ({
  type: actionTypes.enableCampsRequestLoading,
  loading: true,
});

export const enableCampListloading = () => ({
  type: actionTypes.enableCampListloading,
  loading: true,
});

export const disableCampListloading = () => ({
  type: actionTypes.disableCampListloading,
  loading: false,
});
export const enableMilestoneLoading = () => ({
  type: actionTypes.enableMilestoneLoading,
  milestoneLoading: { milestoneLoading: true },
});

export const disableMilestoneLoading = () => ({
  type: actionTypes.disableMilestoneLoading,
  milestoneLoading: { milestoneLoading: false },
});

export const campsFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorCampsDetailsFoundSuccess,
    success: {
      ...data,
      randomKey: Math.random()
        .toString(36)
        .substring(5)
        .toUpperCase(),
    },
  });
};
export const onSetcampsFoundSuccess = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.tutorCampsDetailsFoundSuccess,
    success: data,
  });
};
export const campsDeleteFoundSuccess = (data) => ({
  type: actionTypes.tutorCampsDeleteFoundSuccess,
  success: data,
});
export const campsCreateMilestoneSuccess = (data) => ({
  type: actionTypes.milestoneCreate,
  success: data,
});
export const campsListMilestoneSuccess = (data) => ({
  type: actionTypes.milestoneList,
  success: data,
});
export const campsFoundSuccessData = (data) => ({
  type: actionTypes.tutorCampsDetailsFoundSuccess,
  success: data,
});
export const resetTutorCampsDetails = (data) => ({
  type: actionTypes.resetTutorCampsDetails,
  data,
});
export const onGetCampsDetails = (requestParams) => {
  const modifiedParams = { ...requestParams };
  delete modifiedParams.filterStatus;
  return (dispatch) => {
    dispatch(enableCampDetailsLoading());
    axios
      .get('/camps/view', {
        params: { ...modifiedParams, expand: 'services,provider' },
        withCredentials: true,
      })
      .then((response) => {
        if (response.status === 200) {
          dispatch(campsFoundSuccess(response.data));
          dispatch(disableCampDetailsLoading());
        }
      })
      .catch((error) => {
        dispatch(disableCampDetailsLoading());
      });
  };
};
export const ProviderCampsSuccess = (data) => ({
  type: actionTypes.providerCamps,
  success: data,
});

export const OtherProviderCampsSuccess = (data) => ({
  type: actionTypes.otherProviderCamps,
  success: data,
});

export const getProviderCamps = (requestParams = {}) => {
  if (requestParams.start_hour) {
    requestParams.start_minute = '00';
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00';
  }
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query);
  }
  return (dispatch) => {
    dispatch(enableCampListLoading());
    axios({
      method: 'get',
      url: '/providers/camps',
      withCredentials: true,
      params: { ...requestParams },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(ProviderCampsSuccess(response.data));
          dispatch(disableCampListLoading());
        }
      })
      .catch((error) => {
        dispatch(disableCampListLoading());
      });
  };
};

export const getOtherProviderCamps = (requestParams = {}) => {
  if (requestParams.start_hour) {
    requestParams.start_minute = '00';
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00';
  }
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query);
  }
  return (dispatch) => {
    dispatch(enableCampListLoading());
    axios({
      method: 'get',
      url: '/providers/camps',
      withCredentials: true,
      params: { ...requestParams },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(OtherProviderCampsSuccess(response.data));
          dispatch(disableCampListLoading());
        }
      })
      .catch((error) => {
        dispatch(disableCampListLoading());
      });
  };
};

export const onDeleteCamps = (requestParams, callback = () => { }) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/camps/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(campsDeleteFoundSuccess(deleteResponse));
        dispatch(disableLoading());
        history.push(`${routeName.tutorCamps}`);
        callback();
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;
      deleteResponse.success = false;
      dispatch(campsDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onClearCampsDetail = () => (dispatch) => {
  dispatch(campsFoundSuccess({}));
};

export const onClearCampsDeleteDetail = () => (dispatch) => {
  dispatch(campsDeleteFoundSuccess({}));
};

export const onCampsRequestsSuccess = (data) => ({
  type: actionTypes.tutorCampsRequests,
  success: data,
});

export const getCampsRequests = (data) => (dispatch) => {
  dispatch(enableLoading());
  dispatch(enableCampsRequestLoading(true));
  axios({
    method: 'get',
    url: '/camps/requests',
    params: {
      ...data, expand: 'user,provider,camp_details,dependent_user', sort: 'created_at.desc', type: 'business_provider',
    },
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCampsRequestsSuccess(response.data));
        dispatch(enableCampsRequestLoading(false));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      const responseData = {};
      responseData.status = 'error';
      dispatch(onCampsRequestsSuccess(responseData)); dispatch(enableCampsRequestLoading(false));
      dispatch(disableLoading());
    });
};

export const onCampsRequestActionSuccess = (data) => ({
  type: actionTypes.campsRequestActionSuccess,
  success: data,
});

export const CampsRequestAction = (data, callback) => (dispatch) => {
  dispatch(enableMilestoneLoading());
  axios({
    method: 'post',
    url: '/camps/requestaction',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const responseData = {};
        responseData.status = response.status;
        responseData.data = response.data;
        const message_args = {
          duration: 2,
          message: 'Success',
          description: response.data.message,
        };
        notification.success(message_args);
        dispatch(onCampsRequestActionSuccess(responseData));
        dispatch(disableMilestoneLoading());
        callback(responseData);
      }
    })
    .catch((error) => {
      const responseData = {};
      responseData.status = 'error';
      dispatch(onCampsRequestActionSuccess(responseData));
      dispatch(disableMilestoneLoading());
      callback(responseData);
    });
};

export const onSessionDetail = (data) => ({
  type: actionTypes.sessionDetail,
  success: data,
});

export const onGetsessionDetail = (data) => (dispatch) => {
  dispatch(enableSessionLoading());
  axios({
    method: 'get',
    url: '/session/detail',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const requestParams = { ...response.data };
        // const message_args = {
        //   duration: 2,
        //   message: 'Request Successfull'
        // }
        // notification.success(message_args)
        dispatch(onSessionDetail(requestParams));
        dispatch(disableSessionLoading());
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch(disableSessionLoading());
    });
};

export const onCampsStudents = (data) => ({
  type: actionTypes.campsStudents,
  success: data,
});

export const getCampsStudents = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/camps/students',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      dispatch(disableLoading());
      if (response.status === 200) {
        const requestParams = { ...response.data };
        requestParams.updateData = Math.random()
          .toString(36)
          .substring(5)
          .toUpperCase();
        dispatch(onCampsStudents(requestParams));
      }
    })
    .catch((error) => {
      console.log('error', error);
      dispatch(disableLoading());
    });
};

export const onUpdateMilestone = (data) => ({
  type: actionTypes.updateMilestone,
  success: data,
});

export const updateCampsMilestone = (data) => (dispatch) => {
  dispatch(enableMilestoneLoading());
  axios({
    method: 'post',
    url: '/updateCampsMilestone',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onUpdateMilestone(response.data));
        dispatch(disableMilestoneLoading());
      }
    })
    .catch((error) => dispatch(disableMilestoneLoading()));
};

export const onTutorSpecificCamps = (data) => ({
  type: actionTypes.tutorSpecificCampsFound,
  success: data,
});

export const getTutorSpecificCamps = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/getcamps',
    params: data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onTutorSpecificCamps(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const onCampsList = (data) => ({
  type: actionTypes.campsListFound,
  success: data,
});
export const onCampsSponsoredList = (data) => ({
  type: actionTypes.onCampsSponsoredList,
  success: data,
});
export const onProviderDirectoryList = (data) => ({
  type: actionTypes.onProviderDirectoryList,
  success: data,
});
export const onEnptyCampsList = () => ({
  type: actionTypes.campsListFound,
  success: {},
});
const capitalizeWord = (value) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getCampsList = (requestParams = {}) => {
  if (requestParams.start_hour) {
    requestParams.start_minute = '00';
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00';
  }
  requestParams.limit = 3;
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query);
  }
  if (!requestParams.sort) {
    requestParams.sort = 'created_at.desc';
  }
  return (dispatch) => {
    dispatch(enableCampListloading());
    axios({
      method: 'get',
      url: '/camps/list',
      withCredentials: true,
      params: { ...requestParams, expand: 'provider,services' },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        console.log('response', response);
        if (response.status === 200) {
          if (_.get(requestParams, 'requestType') === 'business') {
            dispatch(onBusinessClassesList(response.data));
          } else {
            dispatch(onCampsList(response.data));
          }

          dispatch(disableCampListloading());
        }
      })
      .catch((error) => {
        dispatch(disableCampListloading());
      });
  };
};
export const getCampsSponsoredList = (requestParams = {}) => {
  if (requestParams.start_hour) {
    requestParams.start_minute = '00';
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00';
  }
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query);
  }
  if (requestParams.sort != '') {
    requestParams.sort = `${requestParams.sort},is_premium.desc`;
  } else {
    requestParams.sort = 'is_premium.desc';
  }
  requestParams.is_sponsored = 1;
  requestParams.limit = 3;
  return (dispatch) => {
    dispatch(enableCampListloading());
    axios({
      method: 'get',
      url: '/camps/sponsoredList',
      withCredentials: true,
      params: { ...requestParams, expand: 'provider,services' },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onCampsSponsoredList(response.data));
          dispatch(disableCampListloading());
        }
      })
      .catch((error) => {
        dispatch(disableCampListloading());
      });
  };
};
export const getDirectoryList = (requestParams = {}) => {
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query);
  }
  requestParams.limit = 3;
  return (dispatch) => {
    // dispatch(enableCampListloading());
    axios({
      method: 'get',
      url: '/providers/list-directory',
      withCredentials: true,
      params: { ...requestParams },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onProviderDirectoryList(response.data));
          // dispatch(disableCampListloading());
        }
      })
      .catch((error) => {
        dispatch(disableCampListloading());
      });
  };
};
/* Camps list count */
export const onCampsCount = (data) => ({
  type: actionTypes.campsCount,
  success: data,
});

export const getCampsCount = () => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/getCampsCount',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCampsCount(response.data));
        dispatch(onCampsList(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const userVerify = (req) => {
  const requestParams = { token: req };

  return (dispatch) => {
    dispatch(enableLoading());
    axios({
      method: 'post',
      url: '/users/verify',
      withCredentials: true,
      data: requestParams,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          const deleteResponse = {};
          deleteResponse.status = response.status;
          deleteResponse.data = response.data;
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        const deleteResponse = {};
        deleteResponse.status = 'error';
        dispatch(disableLoading());
      });
  };
};

export const providerPaymentListSuccess = (data) => ({
  type: actionTypes.providerPaymentList,
  success: data,
});

export const getProviderPaymentList = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/getProviderPaymentList',
    withCredentials: true,
    params: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(providerPaymentListSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => dispatch(disableLoading()));
};

export const onCreateMilestoneForAll = (requestParams) => (dispatch) => {
  dispatch(enableMilestoneLoading());
  axios({
    method: 'post',
    url: '/milestone/create',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        dispatch(campsCreateMilestoneSuccess(deleteResponse));
        dispatch(disableMilestoneLoading());
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      dispatch(campsCreateMilestoneSuccess(deleteResponse));
      dispatch(disableMilestoneLoading());
    });
};

export const onListMilestone = (params) => (dispatch) => {
  axios({
    method: 'get',
    url: '/milestone/list',
    withCredentials: true,
    params,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {

      }
      dispatch(campsListMilestoneSuccess(res));
      dispatch(disableMilestoneLoading());
    })
    .catch((error) => {
      dispatch(campsListMilestoneSuccess(error.res));
      dispatch(disableMilestoneLoading());
    });
};
