import React, { useState, useEffect } from 'react';
import { Image } from 'antd';
import NoInternet from './assets/images/NoInternet.jpg';
import { DEFAULTUSER } from './utils/config/assets';

function NoInternetConnection(props) {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return (
      props.children
    );
  }
  const imageSource = NoInternet || DEFAULTUSER;

  return (
    <div className="empty-animations">
      <Image
        src={imageSource}
        preview={false}
        style={{ width: 400, height: 500 }}
        alt="No Internet"
      />
    </div>
  );
}

export default NoInternetConnection;
