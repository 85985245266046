import React, { useEffect, useRef, useState } from 'react';
import {
  DatePicker, Form, Select, Button,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import './style.css';
import dayjs from 'dayjs';
import { withTranslation } from 'react-i18next';
import * as commonActions from '../../../store/actions/tutor/common';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as action from '../../../store/actions/tutor/camps';
import * as competitionActions from '../../../store/actions/tutor/competitions';
import generalAction from '../../../store/general/action';
import FiltersReport from './report';
import css from '../../../utils/CSSUtility';
import ServicesTypes from '../../../utils/data/services_types.js';
const { RangePicker } = DatePicker;

const PaymentReportFilter = (props) => {
  const [form] = Form.useForm();
  const mounted = useRef();
  const [provider, setProvider] = useState('');
  const [classname, setClassName] = useState('');
  const [campname, setCampName] = useState('');
  const [compname, setCompName] = useState('');
  const [eventname, setEventName] = useState('');
  const [student, setStudent] = useState('');
  const [filterDetails, setFilterDetails] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const [classes, setClasses] = useState([]);
  const [services, setServices] = useState([]);
  const [tutorIdForFilter, setTutorIdForFilter] = useState(null);
  const [stuentListopt, setstuentListopt] = useState('');

  useEffect(() => {
    const students = _.get(props, 'students.items', []);

    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = '';
        let name = '';
        let type = '';
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id');
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'dependent_user.lastname', '').trim())}`; // Use dependent_user.lastname instead of user.lastname
          type = 'dependent_user';
        } else {
          id = _.get(each, 'user.id');
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'user';
        }

        return <Select.Option value={id} key={id} type={type}>{name}</Select.Option>;
      });

      studentList.unshift(<Select.Option value="" key="allstudents">{props.t('all')}</Select.Option>);
      setstuentListopt(studentList);
    }
  }, [_.get(props, 'students.items')]);

  useEffect(() => {
    const students = _.get(props, 'campsStudents.items', []);

    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = '';
        let name = '';
        let type = '';
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id');
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'dependent_user.lastname', '').trim())}`; // Use dependent_user.lastname instead of user.lastname
          type = 'dependent_user';
        } else {
          id = _.get(each, 'user.id');
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'user';
        }

        return <Select.Option value={id} key={id} type={type}>{name}</Select.Option>;
      });

      studentList.unshift(<Select.Option value="" key="allstudents">{props.t('all')}</Select.Option>);
      setstuentListopt(studentList);
    }
  }, [_.get(props, 'campsStudents.items')]);

  useEffect(() => {
    const students = _.get(props, 'competitionStudents.items', []);

    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = '';
        let name = '';
        let type = '';
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id');
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'dependent_user.lastname', '').trim())}`; // Use dependent_user.lastname instead of user.lastname
          type = 'dependent_user';
        } else {
          id = _.get(each, 'user.id');
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`;
          type = 'user';
        }

        return <Select.Option value={id} key={id} type={type}>{name}</Select.Option>;
      });

      studentList.unshift(<Select.Option value="" key="allstudents">{props.t('all')}</Select.Option>);
      setstuentListopt(studentList);
    }
  }, [_.get(props, 'competitionStudents.items')]);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (!mounted.current) {
      if (_.get(props, 'businessTutorBaseProfile.details.id')) {
        const requestParams = {};
        requestParams.limit = 1000;
        requestParams.works_for = _.get(props, 'businessTutorBaseProfile.details.id');
        requestParams.sort = 'created_at.desc';
        requestParams.expand = 'update_progress';

        props.gettutorlist({ params: requestParams });
      }
      mounted.current = true;
    }
  }, [refreshKey]);

  useEffect(() => {
    const updateClasses = () => {
      if (services === 'classes') {
        setClasses(_.get(props, 'sessionListClass.items', []));
      } else if (services === 'camps') {
        setClasses(_.get(props, 'sessionList.items', []));
      } else if (services === 'competitions') {
        setClasses(_.get(props, 'sessionListComp.items', []));
      } else if (services === 'events') {
        setClasses(_.get(props, 'sessionListEvent.items', []));
      }
    };

    updateClasses();
  }, [props, services, (_.get(props, 'sessionListComp.items', [])), (_.get(props, 'sessionListEvent.items', [])), (_.get(props, 'sessionListClass.items', [])), (_.get(props, 'sessionList.items', []))]);

  const onFinish = async (values) => {
    console.log('valuesfilter12=====', values);
    let requestParams = {};
    const start_date = !_.isEmpty(_.get(values, 'date')) && dayjs(_.get(values, 'date[0]')).format('MM-DD-YYYY');
    const end_date = !_.isEmpty(_.get(values, 'date')) && dayjs(_.get(values, 'date[1]')).format('MM-DD-YYYY');

    if (!_.isEmpty(_.get(values, 'date'))) {
      requestParams = {
        ...values,
        created_at_gte: `${start_date}`,
        created_at_lte: `${end_date}`,
        expand: 'user,class_information_details,provider,dependent_user,class_details',
      };
      if (!_.isEmpty(student)) {
        _.isEqual(student, 'user')
          ? (
            requestParams = {
              ...values,
              user: values.user,
              created_at_gte: `${start_date}`,
              created_at_lte: `${end_date}`,
              expand: 'user,class_information_details,provider,dependent_user,class_details',
            }
          ) : (
            requestParams = {
              ...values,
              created_at_gte: `${start_date}T00:00:00.000Z`,
              created_at_lte: `${end_date}T23:59:00.000Z`,
              dependent_user: values.user,
              expand: 'user,class_information_details,provider,dependent_user,class_details',
            }
          );
      }
    }

    if (!_.isEmpty(student)) {
      _.isEqual(student, 'user')
        ? (
          requestParams = {
            ...values,
            user: values.user,
            expand: 'user,class_information_details,provider,dependent_user,class_details',
          }
        ) : (
          requestParams = {
            ...values,
            dependent_user: values.user,
            expand: 'user,class_information_details,provider,dependent_user,class_details',
          }
        );
      if (!_.isEmpty(_.get(values, 'date'))) {
        if (!_.isEmpty(student)) {
          _.isEqual(student, 'user')
            ? (
              requestParams = {
                ...values,
                user: values.user,
                created_at_gte: `${start_date}T00:00:00.000Z`,
                created_at_lte: `${end_date}T23:59:00.000Z`,
                expand: 'user,class_information_details,provider,dependent_user,class_details',
              }
            ) : (
              requestParams = {
                ...values,
                created_at_gte: `${start_date}T00:00:00.000Z`,
                created_at_lte: `${end_date}T23:59:00.000Z`,
                dependent_user: values.user,
                expand: 'user,class_information_details,provider,dependent_user,class_details',
              }
            );
        }
      }
    }

    if (_.isEmpty(student) && _.isEmpty(_.get(values, 'date'))) {
      requestParams = {
        ...values,
        expand: 'user,class_information_details,provider,dependent_user,class_details,camp_details',
      };
    }

    delete requestParams.date;
    await setFilterDetails(requestParams);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onservicesSelect = (value, key) => {
    console.log('valueselect12=====', value);
    setServices(value);
    form.resetFields(['type_details', 'user']);
    setstuentListopt('');
    setClasses([]);
    if (value === 'classes') {
      const requestParams = {
        provider: tutorIdForFilter,
        sort: 'created_at.desc',
        expand: 'provider',
        limit: 1000,
        type: 'classes',
      };
      props.getclasseslist(requestParams);
    } else if (value === 'camps') {
      const requestParams = {
        provider: tutorIdForFilter,
        sort: 'created_at.desc',
        // expand: 'sessions',
        limit: 1000,
        type: 'camps',
      };
      props.onGetsessionList(requestParams);
    } else if (value === 'competitions') {
      const requestParams = {
        provider: tutorIdForFilter,
        sort: 'created_at.desc',
        // expand: 'sessions',
        limit: 1000,
        type: 'competitions',
      };
      props.getsessionlistComp(requestParams);
    } else if (value === 'events') {
      const requestParams = {
        provider: tutorIdForFilter,
        sort: 'created_at.desc',
        // expand: 'sessions',
        limit: 1000,
        type: 'events',
      };
      props.getsessionlistEvent(requestParams);
    }
  };

  const ontutorSelect = (tutorId, key) => {
    form.resetFields(['type_details', 'user', 'serviceType']);
    setServices(null);
    setstuentListopt('');
    setClasses([]);
    setTutorIdForFilter((prev) => tutorId);
    setFilterDetails({
      provider: tutorId,
    });
    setProvider(_.get(key, 'children'));
  };

  const onclassSelect = (classId, key) => {
    form.resetFields(['user']);
    if (services === 'classes') {
      setClassName(_.get(key, 'children'));
      form.submit();
      const params = {
        class_details: classId,
        provider: tutorIdForFilter,
        list_type: 'works_for',
        limit: 1000,
        expand: 'user,class_details,type_details,provider,payment_details,dependent_user',
      };
      props.getstudentslist(params);
    } else if (services === 'camps') {
      setCampName(_.get(key, 'children'));
      const notesParams = {
        camp_details: classId,
        provider: tutorIdForFilter,
        limit: 1000,
        status: 1,
        // page: page,
        sort: 'created_at.desc',
        list_type: 'works_for',
        expand: 'camp_details,user,provider,dependent_user',
      };
      props.getCampsStudents(notesParams);
    } else if (services === 'competitions') {
      setCompName(_.get(key, 'children'));
      form.submit();
      const notesParams = {
        competition_details: classId,
        provider: tutorIdForFilter,
        limit: 1000,
        status: 1,
        // page: page,
        sort: 'created_at.desc',
        list_type: 'works_for',
        expand: 'competition_details,user,provider,dependent_user',
      };
      props.getCompetitionStudents(notesParams);
    } else if (services === 'events') {
      setEventName(_.get(key, 'children'));
      form.submit();
      const notesParams = {
        event_details: classId,
        provider: tutorIdForFilter,
        limit: 1000,
        status: 1,
        // page: page,
        sort: 'created_at.desc',
        list_type: 'works_for',
        expand: 'event_details,user,provider,dependent_user',
      };
      props.getEventStudents(notesParams);
    }
    form.submit();

  };

  const onDateSelect = () => {
    form.submit();
  };

  const onstudentSelect = (value, key) => {
    setStudent(_.get(key, 'type'));
    form.submit();
  };

  const clearInput = () => {
    form.resetFields();
    setFilterDetails({});
    setRefreshKey((oldKey) => oldKey + 1);
  };

  const render = () => {
    const tutors = [_.get(props, 'businessTutorBaseProfile.details'), ..._.get(props, 'tutorslist.items', [])];

    return (

      <div className="row">

        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="full-width"
        >

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="attendance-report mt-3">
              <div className="row">
                <div className="col-12 d-flex justify-content-between" style={{ height: '20px' }}>
                  <div className="payment-title">
                    <span style={css.heading1}>{props.t('tutorSidebarPaymentReport')}</span>
                    {' '}
                  </div>
                  <Button type='primary' className="class-btn" onClick={() => clearInput()}>
                    {props.t('clearFilter')}
                    {' '}
                  </Button>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('tutorLabel')}</span>}
                      name="provider"
                      rules={[{ required: false, message: props.t('tutorValid') }]}
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('attRepoPlaceHolder')}
                        optionFilterProp="children"
                        onSelect={ontutorSelect}
                        allowClear
                      >
                        {_.size(tutors) > 0 && _.map(tutors, (each, i) => <Select.Option value={_.get(each, 'id')} key={_.get(each, 'id')}>{!_.isEmpty(_.get(each, 'businessname', '')) ? _.startCase(_.get(each, 'businessname', '')) : `${_.startCase(_.get(each, 'firstname', ''))} ${_.startCase(_.get(each, 'lastname', ''))}`}</Select.Option>)}

                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('type')}</span>}
                      name="serviceType"
                      rules={[{ required: false, message: props.t('tutorValid') }]}
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('attRepoPlaceHolder')}
                        optionFilterProp="children"
                        onSelect={onservicesSelect}
                        disabled={tutorIdForFilter ? false : true}
                        options={ServicesTypes}
                        key={'value'}
                        allowClear
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('selectYour')} {services && services}</span>}
                      name="type_details"
                      rules={[{ required: false, message: props.t('classValid') }]}
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('selectClassname')}
                        optionFilterProp="children"
                        allowClear
                        onSelect={onclassSelect}
                      >

                        {_.size(classes) > 0 && _.map(classes, (value, key) => <Select.Option value={_.get(value, 'id')} key={_.get(value, 'name')}>{_.get(value, 'session_name')}</Select.Option>)}

                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('filterByDate')}</span>}
                      name="date"
                      rules={[{ required: false, message: props.t('dateValid') }]}
                      className="mb-4"
                    >
                      <RangePicker onChange={onDateSelect} />
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('filterByStu')}</span>}
                      name="user"
                      rules={[{ required: false, message: props.t('stuValid') }]}
                      className="mb-4"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('selectUserName')}
                        optionFilterProp="children"
                        allowClear
                        onSelect={onstudentSelect}
                      >
                        {stuentListopt}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Form>

        {/* Class Details Preview */}
        <div className="col-12">
          <div className="row">
            {!_.isEmpty(form.getFieldValue('provider')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {props.t('tutName')}
                  :
                  {' '}
                  <span style={css.heading3}>{provider}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('class_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('className')}
                  :
                  {' '}
                  <span style={css.heading3}>{classname}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('camp_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('campName')}
                  :
                  {' '}
                  <span style={css.heading3}>{campname}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('competition_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('compName')}
                  :
                  {' '}
                  <span style={css.heading3}>{compname}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('event_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('eventName')}
                  :
                  {' '}
                  <span style={css.heading3}>{eventname}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-12 ">
          <FiltersReport filterDetails={filterDetails} tutorId={tutorIdForFilter} />
        </div>
      </div>

    );
  };

  return render();
};

const mapStateToProps = (state) => ({
  businessTutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  tutorclasses: state.classesDetails.classesList,
  tutorslist: _.get(state, 'general.tutor.list'),
  students: state.classesDetails.classesStudents,
  sessionList: state.common.sessionList,
  sessionListComp: state.common.sessionListComp,
  sessionListEvent: state.common.sessionListEvent,
  sessionListClass: state.common.sessionListClass,
  campsStudents: state.campsDetails.campsStudents,
  competitionStudents: state.competitionsDetails.competitionStudents,

});

const mapDispatchToProps = (dispatch) => ({

  gettutorlist: (data) => dispatch(generalAction.tutor.tutorList(data)),

  getclasseslist: (requestParams) => dispatch(commonActions.onGetsessionListClass(requestParams)),
  onGetsessionList: (requestParams) => dispatch(commonActions.onGetsessionList(requestParams)),
  getsessionlistComp: (requestParams) => dispatch(commonActions.onGetsessionListComp(requestParams)),
  getsessionlistEvent: (requestParams) => dispatch(commonActions.onGetsessionListEvents(requestParams)),
  getCampsStudents: (requestParams) => dispatch(action.getCampsStudents(requestParams)),
  getstudentslist: (requestParams) => dispatch(classActions.getClassesStudents(requestParams)),
  getCompetitionStudents: (requestParams) => dispatch(competitionActions.getCompetitionStudents(requestParams)),
  getEventStudents: (requestParams) => dispatch(competitionActions.getEventStudents(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PaymentReportFilter));
