// this action contains functionality for add class as well as edit class
import Compressor from 'compressorjs';
import * as actionTypes from './actionTypes';
import axios from '../../../../utils/axios';
// import _ from 'lodash';

export const enableValidateAddCamp = () => ({
  type: actionTypes.enableValidateAddCamp,
  data: { validateAddCamp: true },
});
export const campFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorCampDetailsFoundSuccess,
    success: data,
  });
};
export const disableValidateAddCamp = () => ({
  type: actionTypes.disableValidateAddCamp,
  data: { validateAddCamp: false },
});
export const saveToLocalStorage = (value) => ({
  type: actionTypes.saveToLocalStorage,
  data: { saveToLocalStorage: value },
});
export const nextPage = () => ({
  type: actionTypes.nextPage,
  data: {},
});
export const previousPage = () => ({
  type: actionTypes.previousPage,
  data: {},
});
export const resetPage = () => ({
  type: actionTypes.resetPage,
  data: { currentPage: 0 },
});
export const addCampEnableLoading = () => ({
  type: actionTypes.addCampEnableLoading,
  loading: { loading: true },
});
export const addCampDisableLoading = () => ({
  type: actionTypes.addCampDisableLoading,
  loading: { loading: false },
});
export const campCreatedSuccessfully = (value) => ({
  type: actionTypes.addCampDisableLoading,
  loading: { campCreatedSuccessfully: value },
});
export const setCampData = (data) => ({
  type: actionTypes.setCampData,
  data: { campData: data },
});
export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});
export const createCamp = (data, callback = () => { }) => (dispatch) => {
  // dispatch(addCampEnableLoading());
  axios({
    method: 'post',
    url: 'camps/create',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        // dispatch(campCreatedSuccessfully(true));
        callback('success', res.data.details);
      }
    })
    .catch((error) => {
      console.log('err', error.message);
      callback('failed', {});
    });
};

const beforeFetchingServices = () => ({
  type: actionTypes.beforeFetchingServices,
  data: { service_ids: { data: [], fetching: true } },
});
const afterFetchingServices = (data) => ({
  type: actionTypes.afterFetchingServices,
  data: { service_ids: { data: data.items, fetching: false } },
});
const afterFetchingServicesValues = (data) => ({
  type: actionTypes.afterFetchingServicesValues,
  data: { services: { data, fetching: false } },
});
const beforeFetchingLocations = () => ({
  type: actionTypes.beforeFetchingLocations,
  data: { location_taught: { data: [], fetching: true } },
});
const beforeFetchingServicesValues = () => ({
  type: actionTypes.beforeFetchingServicesValues,
  data: { services: { data: [], fetching: true } },
});
const afterFetchingLocations = (data) => ({
  type: actionTypes.afterFetchingLocations,
  data: { location_taught: { data, fetching: false } },
});
export const fetchServices = (lastFetchId, data) => {
  const fetchId = lastFetchId;
  return (dispatch) => {
    dispatch(beforeFetchingServices());
    axios({
      method: 'get',
      url: 'services/list',
      withCredentials: true,
      params: { search: data },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((res) => {
        if (res.status === 200) {
          if (fetchId !== lastFetchId) {
            // for fetch callback order
            return;
          }
          dispatch(afterFetchingServices(res.data));
        }
      })
      .catch((error) => { });
  };
};
export const fetchLocations = () => (dispatch) => {
  dispatch(beforeFetchingLocations());
  dispatch(beforeFetchingServicesValues());
  axios({
    method: 'get',
    url: 'providers/profile',
    withCredentials: true,
    params: { expand: 'services' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(afterFetchingLocations(res.data.details.location_taught));
        dispatch(afterFetchingServicesValues(res.data.details.services));
      }
    })
    .catch((error) => { });
};

// edit camps
export const updateCamps = (params, callback = () => { }) => (dispatch) => {
  // dispatch(addCampEnableLoading());
  axios({
    method: 'post',
    url: 'camps/update',
    withCredentials: true,
    params: { id: params.params.id }, // it contains id of the specific class
    data: params.data, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback('success', res.data.details);
      } else {
        callback('failed', {});
      }
    })
    .catch((error) => {
      callback('failed', {});
      // dispatch(setCampData(error));
      // dispatch(campCreatedSuccessfully(true));
      // dispatch(addCampDisableLoading());
    });
};
/* Camps image upload */
export const addCampImageDisableLoading = () => ({
  type: actionTypes.addCampImageDisableLoading,
  loading: { loading: false },
});
export const campImageCreatedSuccessfully = (value) => ({
  type: actionTypes.addCampImageDisableLoading,
  loading: { campImageCreatedSuccessfully: value },
});

export const updateImageForCamps = (params, callback = () => { }) => (dispatch) => {
  const image = params.photo;
  new Compressor(image, {
    quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
    success: (compressedResult) => {
      const formData = new FormData();
      formData.set('id', params.id);
      formData.append('photo', compressedResult);
      axios
        .post('camps/update-photo', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            callback('success');
          }
        })
        .catch((error) => {
          callback('failed');
          console.log('err>>', error);
        });
    },
  });
};
