import React from 'react';
import _ from 'lodash';

export class PaymentReportLoader extends React.Component {
  render() {
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className={css}>
        <div className="card-tab-section">
          <div className="ph-item">
            <div>
              <div className="ph-row">
                <div className="ph-col-2 big mr-3" />
                <div className="ph-col-2 big mr-3" />
                <div className="ph-col-2 big mr-3" />
                <div className="ph-col-2 big mr-3" />
                <div className="ph-col-2 big mr-3" />
              </div>
            </div>
            <div className="ph-col-12">
              <div className="ph-row">
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12 mb-4" />
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12 mb-4" />
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12" />
                <div className="ph-col-12 mb-4" />
                <div className="ph-col-12" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
