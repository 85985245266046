import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import queryString from 'query-string'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import '../style.css'
import { Link } from 'react-router-dom'
import { Tooltip } from 'antd'
import { AiOutlineInfoCircle, AiOutlineExclamationCircle } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'
import generalAction from '../../../../store/general/action'
import BaseProfileTab from './base-profile-tab'
// import OnboardingWizard from '../../onboarding-wizard/onboarding-wizard'
import GeneralInfoTab from './general-info-tab'
import PoliciesTab from './policies-tab'
import SocialLink from './social-link'
import MediaAndAnnouncementsTab from './media-and-announcements-tab'
import ChangePassword from '../../setting/change-password'
import ResetPassword from '../../setting/setting'
import { CanSwitchTabModal } from './can-switch-tab-modal'
import { IoLogoSkype } from "react-icons/io";
// import { PiPasswordBold } from 'react-icons/pi'
import { LuView } from 'react-icons/lu'
import { FaUserPen } from 'react-icons/fa6'
import { FcInfo, FcInspection, FcStart } from 'react-icons/fc'
import { TbPasswordUser } from "react-icons/tb";
import { RxCrossCircled } from "react-icons/rx";

class TutorMyProfileTab extends React.Component {
  state = {
    custom_tabs: {
      tab_panes: {
        // onboarding_wizard: {
        //   name: this.props.t('onboardWizard'),
        //   key: '0',
        //   meta: { className: 'tab-pane fade' },
        // },
        base_profile: {
          name: this.props.t('baseProfiletab'),
          key: '1',
          meta: { className: 'tab-pane fade' },
        },
        general_info: {
          name: this.props.t('generalInfo'),
          key: '2',
          meta: { className: 'tab-pane fade' },
        },
        media_and_announcements: {
          name: this.props.t('mediaAndAnnouncement'),
          key: '3',
          meta: { className: 'tab-pane fade' },
        },
        policies: {
          name: this.props.t('policies'),
          key: '4',
          meta: { className: 'tab-pane fade' },
        },
        change_password: {
          name: this.props.t('settings20'),
          key: '5',
          meta: { className: 'tab-pane fade' },
        },
        social_link: {
          name: this.props.t('socialLink'),
          key: '7',
          meta: { className: 'tab-pane fade' },
        },
        close_account: {
          name: this.props.t('closeAccount'),
          key: '6',
          meta: { className: 'tab-pane fade' },
        },
      },
      activeTab: '-',
      isCurrentTabIsModified: false,
    },
    can_switch_modal_properties: (criteria) => ({
      title: 'Please Confirm',
      content: (
        <Tooltip
          placement="right"
          title={
            <span>
              {' '}
              <span className="tooltip-icon">
                <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
              </span>{' '}
              <span className="tooltip-text">
                ie: This is just a remainder. You can always save your modified
                tab details later until you hold on in this page.
              </span>
            </span>
          }
        >
          <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
            You have unsaved changes. Do you really want to leave the
            {_.get(criteria, 'tab_details.name', '')} tab?
            <AiOutlineInfoCircle style={{ fontSize: '20px' }} />
          </label>
        </Tooltip>
      ),
    }),
    pastQueryParams: {},
    BusinessTutor: false,
  }

  tabReference = {}

  setCurrentTabModified = (value) => {
    if (
      !_.isEqual(_.get(this, 'state.custom_tabs.isCurrentTabIsModified'), value)
    ) {
      this.setState((previousState) => ({
        custom_tabs: _.merge(previousState.custom_tabs, {
          isCurrentTabIsModified: value,
        }),
      }))
    }
  }

  toggleTabByValue = (tab) => {
    const availableTabs = _.map(
      _.get(this, 'state.custom_tabs.tab_panes', {}),
      (tabPane) => _.get(tabPane, 'key', '')
    )
    tab = _.includes(availableTabs, tab)
      ? tab
      : _.get(this, 'state.custom_tabs.tab_panes.base_profile.key', '')
    // if user clicks current tab we need to return
    if (_.isEqual(_.get(this, 'state.custom_tabs.activeTab', ''), tab)) {
      return
    }
    if (_.isFunction(_.get(this, 'props.canSwitchTabModal.showModal'))) {
      // if (_.get(this, 'state.custom_tabs.isCurrentTabIsModified')) {

      //   this.props.canSwitchTabModal.showModal(this.state.can_switch_modal_properties({
      //     tab_details: _.find(
      //       _.get(this, 'state.custom_tabs.tab_panes', {}),
      //       item => _.isEqual(_.get(item, 'key', ""), _.get(this, 'state.custom_tabs.activeTab', ""))
      //     )
      //   }), (success) => {
      //     if (success) {
      //       this.setState(previousState => ({ custom_tabs: _.merge(previousState.custom_tabs, { isCurrentTabIsModified: false }) }));
      //       this.setTabPane(tab);
      //       // Remove the 'active' class from the current active tab
      //       const currentActiveTab = document.querySelector('.nav-link.active');
      //       currentActiveTab.classList.remove('active');

      //       // Add the 'active' class to the clicked tab
      //       const clickedTab = this.tabReference[tab];
      //       clickedTab.classList.add('active');
      //     } else {
      //       this.tabReference[_.get(this, 'state.custom_tabs.activeTab', "")].click();
      //     }
      //   });
      // } else {
      this.setTabPane(tab)
      // Remove the 'active' class from the current active tab
      const currentActiveTab = document.querySelector('.nav-link.active')
      currentActiveTab.classList.remove('active')

      // Add the 'active' class to the clicked tab
      const clickedTab = this.tabReference[tab]
      clickedTab.classList.add('active')
      if (_.isEqual('-', _.get(this, 'state.custom_tabs.activeTab', ''))) {
        // need to highlight the tab pans during component didmount
        this.tabReference[tab].click()
      }
      // }
    }
  }

  setTabPane = (key) => {
    const edit_tab_panes = _.get(this, 'state.custom_tabs.tab_panes', {})
    _.forEach(edit_tab_panes, (tab_pane) => {
      if (_.isEqual(key, _.get(tab_pane, 'key', ''))) {
        tab_pane.meta.className = 'tab-pane active'
      } else {
        tab_pane.meta.className = 'tab-pane fade'
      }
    })
    this.setState((previousState) => ({
      custom_tabs: _.merge(_.get(previousState, 'custom_tabs', {}), {
        tab_panes: edit_tab_panes,
        activeTab: key,
      }),
    }))
  }

  componentDidMount() {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams && urlparams.id) {
      this.setState({ BusinessTutor: true })
    }
    this.toggleTabByValue(
      _.get(queryString.parse(_.get(this, 'props.location.search')), 'tab')
    )
  }

  contentTab = () => {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    const customTabPanes = _.get(this, 'state.custom_tabs.tab_panes')
    const tutorDetails = _.get(this, 'props.tutorBaseProfile.details', '')
    const inputProps = {
      setCurrentTabModified: this.setCurrentTabModified,
      isCurrentTabIsModified: _.get(
        this,
        'state.custom_tabs.isCurrentTabIsModified',
        false
      ),
    }
    const userHandle =
      urlparams && urlparams.userhandle
        ? `/profile/${_.get(urlparams, 'userhandle', '')}`
        : _.get(tutorDetails, 'user_handle')
          ? `/profile/${_.get(tutorDetails, 'user_handle', '')}`
          : null
    return (
      <div
        className="tutor-base-profile-tab"
        style={{ display: 'flex', height: '100%' }}
      >
        <ul
          className="nav nav-tabs tutorProfileList"
          role="tablist"
          style={{
            flex: '0 0 18%',
            flexDirection: 'column',
            backgroundColor: '#F9F9F9',
          }}
        >
          {userHandle && (
            <Link
              to={{
                pathname: userHandle,
                state: { data: 'Hello from FirstPage' },
              }}
              target="_blank"
            >
              <li className="nav-item">
                <div
                  style={{
                    padding: '10px 0px 10px 10px ',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LuView
                    style={{
                      color: 'darkcyan',
                      fontSize: '20px',
                      marginRight: '5px',
                    }}
                  />
                  {this.props.t('viewProfile')}
                </div>
              </li>
            </Link>
          )}

          {/* <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'onboarding_wizard.key', '')
                )
              }
              ref={(input) =>
                (this.tabReference[
                  _.get(customTabPanes, 'onboarding_wizard.key', '')
                ] = input)
              }
              role="tab"
              aria-selected="true"
              className="ant-tabs-tab-btn nav-link active"
              data-toggle="tab"
            >
              <MdGroupAdd
                style={{
                  color: 'darkcyan',
                  fontSize: '20px',
                  marginRight: '5px',
                }}
              />{' '}
              {_.get(customTabPanes, 'onboarding_wizard.name', '')}
            </div>
          </li> */}

          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'base_profile.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'base_profile.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="true"
              className="ant-tabs-tab-btn nav-link active"
              data-toggle="tab"
            >
              <FaUserPen style={{
                color: '#0071b3',
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'base_profile.name', '')}
            </div>
          </li>
          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'general_info.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'general_info.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <FcInfo style={{
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'general_info.name', '')}
            </div>
          </li>
          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'media_and_announcements.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'media_and_announcements.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <FcStart style={{
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'media_and_announcements.name', '')}
            </div>
          </li>
          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(_.get(customTabPanes, 'policies.key', ''))
              }
              ref={(input) =>
              (this.tabReference[_.get(customTabPanes, 'policies.key', '')] =
                input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <FcInspection style={{
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'policies.name', '')}
            </div>
          </li>

          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'social_link.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'social_link.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <IoLogoSkype style={{
                color:'skyblue',
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'social_link.name', '')}
            </div>
          </li>
          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'change_password.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'change_password.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <TbPasswordUser style={{
                color: 'darkolivegreen',
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'change_password.name', '')}
            </div>
          </li>
          <li className="nav-item">
            <div
              style={{
                padding: '10px 0px 10px 10px ',
                display: 'flex',
                alignItems: 'center',
              }}
              onClick={() =>
                this.toggleTabByValue(
                  _.get(customTabPanes, 'close_account.key', '')
                )
              }
              ref={(input) =>
              (this.tabReference[
                _.get(customTabPanes, 'close_account.key', '')
              ] = input)
              }
              role="tab"
              aria-selected="false"
              className="ant-tabs-tab-btn nav-link"
              data-toggle="tab"
            >
              <RxCrossCircled style={{
                color: 'red',
                fontSize: '20px',
                marginRight: '5px',
              }} />
              {_.get(customTabPanes, 'close_account.name', '')}
            </div>
          </li>
        </ul>

        <div
          className="tab-content"
          style={{ flex: '0 0 80.6%', margin: 10, marginLeft: 15 }}
        >
          <div
            id="Base_Profile"
            {..._.get(customTabPanes, 'base_profile.meta', {})}
          >
            {/* Declaring base profile tab */}
            <BaseProfileTab {...this.props} {...inputProps} />
          </div>
          {/* <div
            id="onboarding_wizard"
            {..._.get(customTabPanes, 'onboarding_wizard.meta', {})}
          >
            <OnboardingWizard {...this.props} {...inputProps} />
          </div> */}

          <div
            id="General_Info"
            {..._.get(customTabPanes, 'general_info.meta', {})}
          >
            {/* Declaring general info tab */}
            <GeneralInfoTab {...this.props} {...inputProps} />
          </div>
          <div
            id="Media_Annoncements"
            {..._.get(customTabPanes, 'media_and_announcements.meta', {})}
          >
            {/* Declaring policies tab */}
            <MediaAndAnnouncementsTab {...this.props} {...inputProps} />
          </div>
          <div id="Policies" {..._.get(customTabPanes, 'policies.meta', {})}>
            {/* Declaring policies tab */}
            <PoliciesTab {...this.props} {...inputProps} />
          </div>

          <div
            id="social_link"
            {..._.get(customTabPanes, 'social_link.meta', {})}
          >
            <SocialLink
              tutorBaseProfile={this.props.tutorBaseProfile.details}
            />
          </div>

          <div
            id="change_password"
            {..._.get(customTabPanes, 'change_password.meta', {})}
          >
            {/* Declaring Change password tab */}
            <ChangePassword />
          </div>

          <div
            id="close_account"
            {..._.get(customTabPanes, 'close_account.meta', {})}
          >
            <div className="col-12 ">
              <ResetPassword />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className={`col-12 ${this.state.BusinessTutor ? '' : 'col-lg-12'}`}>
        <div
          className="nurtem-card-design my-profile-tab tutor-onboarding-right "
          style={{ padding: 0 }}
        >
          {/* Declaring Content tab */}
          {this.contentTab()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tutor: _.get(state, 'general.tutor', {}),
})

const mapDispatchToProps = (dispatch) => ({
  getTutorProfile: (details) =>
    dispatch(generalAction.tutor.getProfile(details)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CanSwitchTabModal(TutorMyProfileTab)))
