import React, { useState, useEffect } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../user-cart/ach.css';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [delayTime, setDelayTime] = useState(5000);
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const { clientSecret } = props;

    if (!clientSecret) {
      return;
    }

    // this is to check th estatus of intent only
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (paymentIntent) {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(props.t('paySuccess'));
            break;
          case 'processing':
            setMessage(props.t('payLoading'));
            break;
          case 'requires_payment_method':
            setMessage(props.t('payFail'));
            break;
          default:
            setMessage('');
            break;
        }
      }
    });
  }, [stripe, props.clientSecret]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const { cart_items, amount } = props.selectedData();

      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      stripe.confirmPayment({
        elements,

        redirect: 'if_required',
      }).then(({ paymentIntent, error }) => {
        if (error) {
          // Inform the customer that there was an error.
          if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
            setTimeout(() => {
              setDelayTime(delayTime * 2);
              setIsLoading(false);
              setMessage('');
            }, delayTime);
          } else {
            setMessage(props.t('error1'));
            setTimeout(() => {
              setDelayTime(delayTime * 2);
              setIsLoading(false);
              setMessage('');
            }, delayTime);
          }
        } else {
          const status = _.get(paymentIntent, 'status');
          switch (status) {
            case 'succeeded':
              setMessage(props.t('paySuccess'));
              props.onCreateBookings({
                token: paymentIntent, account_id: '', bookingDetails: cart_items, amoutTo: amount,
              }, '/users/reorder', (resp) => {
                // console.log("resp>>>>", resp)

                setMessage(props.t('bookSuccess'));
                setTimeout(() => {
                  setIsLoading(false);
                  props.showACHModal(false);
                  props.createBookingCallback();
                }, 1500);
              });

              // if it is not redirected then
              setTimeout(() => {
                setMessage(status + props.t('bookFail'));
                setIsLoading(false);
                props.showACHModal(false);
              }, 1500);
              break;
            case 'processing':
              setMessage(props.t('payLoading'));
              props.onCreateBookings({
                token: paymentIntent, account_id: '', bookingDetails: cart_items, amoutTo: amount,
              }, '/users/reorder', (resp) => {
                // console.log("resp>>>>", resp)
                setMessage(props.t('bookSuccess'));
                setTimeout(() => {
                  setIsLoading(false);
                  props.showACHModal(false);
                  props.createBookingCallback();
                }, 1500);
              });

              // if it is not redirected then
              setTimeout(() => {
                setMessage(status + props.t('bookFail'));
                setIsLoading(false);
                props.showACHModal(false);
              }, 1500);

              break;
            case 'requires_payment_method':
              setMessage(props.t('payFail'));
              setTimeout(() => {
                setDelayTime(delayTime * 2);
                setIsLoading(false);
                setMessage('');
              }, delayTime);
              break;
            default:
              setMessage(props.t('payFail'));
              setTimeout(() => {
                setDelayTime(delayTime * 2);
                setIsLoading(false);
                setMessage('');
              }, delayTime);
              break;
          }
        }
      });
    } catch (error) {
      console.log('error at CheckoutForm.js', error);
      setTimeout(() => {
        setIsLoading(false);
        setMessage(error.message);
      }, delayTime);
    }
  };

  const paymentElementOptions = {
    layout: 'tabs',
    clientSecret: props.clientSecret,
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>

      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner" /> : props.t('payNow')}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>

  );
}

export default withTranslation()(CheckoutForm);
