import React from 'react'
import { Modal, Form, Button, Input, Spin } from 'antd'
import _ from 'lodash'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as tutorActionTypes from '../../../store/actions/tutor/actionTypes'
import * as tutorAction from '../../../store/actions/tutor'
import { isPropertyLoadingInProgress } from '../../../store/utility'

const { TextArea } = Input

export function tutorSendNotesToStudentModal(WrappedComponent) {
  class mainComponent extends React.Component {
    constructor(props) {
      super(props)
      this.titleRef = React.createRef()
      this.textRef = React.createRef()
      this.notesRef = React.createRef()
    }

    state = {
      visible: false,
      send_note_api_params: {},
      loadSpin: false,
    }

    componentDidUpdate() {
      this.manageLoadSpin()
    }

    showModal = () => {
      this.setState({
        visible: true,
      })
    }

    handleOk = (e) => {
      const params = queryString.parse(
        _.get(this, 'props.location.search', null)
      )
      const bodyParams = { ...e }
      bodyParams.user = _.get(this, 'props.match.params.userId', null)
      bodyParams.class_details = _.get(params, 'classId', null)

      this.props.sendNotesToStudent(bodyParams, () => {
        this.notesRef.current.resetFields()
        this.props.triggerProviderNotesToStudentComponent(true)
        this.handleCancel()
      })
    }

    handleCancel = (e) => {
      this.setState({
        visible: false,
      })
    }

    modalUI = () => (
      <Modal
        className="student-view-notes-modal"
        title="Send Notes"
        maskClosable={false}
        centered
        open={this.state.visible}
        onCancel={() => this.handleCancel()}
        footer={null}
      >
        <Spin spinning={_.get(this, 'state.loadSpin')}>
          <Form
            scrollToFirstError
            onFinish={(e) => this.handleOk(e, {}, 'createFaq')}
            ref={this.notesRef}
            className="nurtem-modal-container notes-input-box p-4"
          >
            <Form.Item
              name="title"
              rules={[
                {
                  required: true,
                  message: this.props.t('notesValid'),
                },
              ]}
              className="col-12"
            >
              <div className="full-width text-right">
                <Input placeholder="Title" className="form-control" />
              </div>
            </Form.Item>
            <Form.Item
              name="text"
              rules={[
                {
                  required: true,
                  message: this.props.t('notesValid1'),
                },
              ]}
              className="col-12"
            >
              <div className="full-width text-right">
                <TextArea placeholder="Enter your notes here" rows={4} />
              </div>
            </Form.Item>
            <Form.Item className="col-12">
              <div className="full-width text-right">
                <Button
                  className="btn-cancel"
                  key="back"
                  onClick={this.handleCancel}
                >
                  {this.props.t('cancel')}
                </Button>
                ,
                <Button
                  disabled={_.get(this, 'state.loadSpin')}
                  className="btn-submit"
                  htmlType="submit"
                >
                  {this.props.t('submit')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    )

    manageLoadSpin = () => {
      const loadSpin = isPropertyLoadingInProgress(
        _.get(this, 'props.tutor'),
        tutorActionTypes.sendNotesToStudent
      )
      if (!_.isEqual(_.get(this, 'state.loadSpin'), loadSpin)) {
        this.setState({ loadSpin })
        Modal.destroyAll()
      }
    }

    render() {
      return (
        <>
          {this.modalUI()}
          <WrappedComponent
            {...this.props}
            {...{ sendNotesModal: { showModal: this.showModal } }}
          />
        </>
      )
    }
  }

  const mapStateToProps = (state) => ({
    tutor: state.tutor,
  })
  const mapDispatchToProps = (dispatch) => ({
    sendNotesToStudent: (requestBody, callback = () => {}) =>
      dispatch(tutorAction.sendNotesToStudent(requestBody, callback, true)),
    triggerProviderNotesToStudentComponent: (value) =>
      dispatch({
        type: tutorActionTypes.updateTutorState,
        data: { triggerProviderNotesToStudentComponent: value },
      }),
  })

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(mainComponent))
}
