import React from 'react';
import { Pagination } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import * as searchActionType from '../../../../store/actions/public/search/actionTypes';
import * as searchAction from '../../../../store/actions/public/search';
import * as common from '../../../../utils/common';
import _ from 'lodash';

function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <>Prev</>;
  }
  if (type === 'next') {
    return <>Next</>;
  }
  return originalElement;
}

const Paging = function (params) {
  const pathArr = String(_.get(window.location, 'pathname', '')).split('/');

  const pathname = Array.isArray(pathArr) && _.last(pathArr).replace(/%20/g, ' ');

  return (
    <div className="pagination-container">
      <Pagination
        key={`pagination_${params.pageCompID}`}
        itemRender={itemRender}
        current={params.meta && params.meta.page && parseInt(params.meta.page)}
        onChange={(page, pageSize) => {
          let rp = {
            ...params.requestParams,
            serviceName: pathname,
            page,
            limit: pageSize,
          };

          if (!params.headerSearch) {
            rp = {
              serviceName: pathname,
              ...params.requestParams,
              ...params.requestParamsMore,
              page,
              limit: pageSize,
            };
          }

          if (!_.isEmpty(rp.serviceID)) {
            rp.serviceID = params.serviceID;
          }

          if (params.pageCompID === searchActionType.generalList) {
            params.onGetClasseCampList(rp);
          } else if (params.pageCompID === searchActionType.sponoredList) {
            params.onGetClasseCampList_sponsored(rp);
          } else if (params.pageCompID === searchActionType.dirList) {
            params.onGetClasseCampList_dirListing(rp);
          }
        }}
        pageSize={params.meta && params.meta.limit ? parseInt(params.meta.limit) : 5}
        total={params.meta && params.meta.total_count}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  serviceID: state.search.serviceID,
  headerSearch: state.search.headerSearch,
  requestParamsMore: common.requestParamsMore(state.search),
  requestParams: common.reqFromHeader(state.search),
});

const mapDispatchToProps = (dispatch) => ({
  onGetClasseCampList: (rpGeneral) => dispatch(searchAction.onGetClasseCampList(rpGeneral)),
  onGetClasseCampList_sponsored: (rpSponsored) => dispatch(searchAction.onGetClasseCampList(rpSponsored, searchActionType.sponoredList)),
  onGetClasseCampList_dirListing: (rpDirlist) => dispatch(searchAction.onGetClasseCampList(rpDirlist, searchActionType.dirList)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Paging));
