// import _ from 'lodash';
import Compressor from 'compressorjs';
import { notification } from 'antd';
import axios from '../../utils/axios';
import { notifyAPIResponse } from '../utility';

export const updateProvidersPartialProfile = (data, callback = () => { }) => {
  axios({
    method: 'post',
    url: '/providers/partial-profile-update',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      const response_data = { ...response };
      callback(response_data);
    })
    .catch((error) => {
      const response_data = {};
      callback(response_data);
      console.log('error in response', error);
    });
};

export const onCreateTransaction = (data, callback = () => { }) => (dispatch) => {
  axios({
    method: 'post',
    url: '/providers/subscription-transaction',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      const response_data = { ...response };
      console.log('response_data', response_data);
      
      const msg = _.get(response_data, ['data', 'message'], 'Success!')
      notifyAPIResponse('success',msg,"Subscription Status")
      callback(response_data);
    })
    .catch((error) => {
      const response_data = {};
      callback(response_data);
      console.log('error in response', error);
      const msg = _.get(response_data, ['data', 'message'], 'Success!')
      notifyAPIResponse('error',"Failed!","Subscription Status")
    });
};

export const makRequest = (url, callback = () => { }, method = 'get', data = {}) => {
  axios({
    method,
    url,
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data);
      }
    })
    .catch((error) => {
      console.log('error', error);
    });
};

export const onAiGenerateImage = (data, callback = () => { }) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ai/generate-image',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const response_data = { ...response.data };
        callback(response_data);
      }
    })
    .catch((error) => {
      const response_data = { error };
      callback(response_data);
      console.log('error in response', error);
    });
};

export const onAiGenerateText = (data, callback = () => { }) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ai/generate-description',
    data,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      const response_data = { ...response };
      callback(response_data);
    })
    .catch((error) => {
      const response_data = {};
      callback(response_data);
    });
};

export const onDeleteImage = (requestParams, callback = () => { }) => (dispatch) => {
  // dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/photos/delete-one',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        callback(null, response); // Pass response data to the callback
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      callback(error, null); // pass the error as the first argument to the callback
      // dispatch(disableLoading());
    });
};

export const uploadImages = (data, callback = (type, details) => { }) =>
  /** @example :: data = {
   * cat:"camp",
   * subcat :"flyer",
   * catid: "campid here",
   * images: e.flyer.fileList (list of uploaded images)
   * }
   */
  (dispatch) => {
    try {
      data.images && Array.isArray(data.images) && data.images.map((file) => {
        if (file.originFileObj instanceof File) {
          // new Compressor(file.originFileObj, {
          //   quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
          //   success: (compressedResult) => {
          const formData = new FormData();
          formData.set('catid', data.catid);
          formData.append('cat', data.cat);
          formData.append('subcat', data.subcat);
          formData.append('image', file.originFileObj);

          axios
            .post('photo/create-one', formData, {
              withCredentials: true,
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then((response) => {
              if (response.status === 200) {
                callback('success', response);
              }
            })
            .catch((error) => {
              callback('failed', {});
            });
          //   },
          // });
        }
        return null;
      });
    } catch (error) {
      console.log('error at common.js in uploadImages', error);
    }
  };
