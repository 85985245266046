import React from 'react';
import { DatePicker } from 'antd';
const { RangePicker } = DatePicker;

interface CustomRangePickerProps {
  selectedValue?: any[]; // Consider using a more specific type based on your usage
  onChange?: (dates: any, dateStrings: [string, string]) => void;
  format?: string;
  placeholder?: [string, string];
  disabled?: boolean;
  className?: string;
  readOnly?: boolean; // Correct casing to `readOnly` for consistency with HTML attributes
  defaultValue?: any; // Consider specifying a more precise type
}

export const CustomRangePicker: React.FC<CustomRangePickerProps> = ({
  selectedValue,
  onChange,
  format = 'DD-MM-YYYY',
  placeholder = ['Select Range', 'Select Range'],
  disabled,
  className,
  readOnly = true,
  defaultValue,
}) => {
  return (
    <RangePicker
      className={className}
      value={selectedValue}
      onChange={onChange}
      placeholder={placeholder}
      format={format}
      allowClear
      readOnly={readOnly}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
};
