import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';

export const onCommissionPrice = (data) => ({
  type: actionTypes.CommissionPercentages,
  success: data,
});

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});
export const getCommissionPrice = () => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'get',
    url: '/configurations/view/NUR-01',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCommissionPrice(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};
