import React, { useState } from 'react';
import { Image, Spin } from 'antd';
import EmptyAnime from '../lottie/EmptyAnime';
import { BsFilePdf } from 'react-icons/bs';
import getMimeTypeFromExtension from '../../../utils/common/getMimeTypeFromExtension';

export default function Flyers(props) {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <Image.PreviewGroup
      preview={{
        onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
      }}
    >
      <>
        {props.images && props.images.length > 0 ? (
          <div className="row" style={{ margin: 0, flexWrap: 'nowrap', overflowX: 'auto' }}>
            {props.images.map((flyer, index) => (
              <div className="" style={{ marginBottom: 15, padding: '0px 10px' }} key={index}>
                {getMimeTypeFromExtension(flyer.url).isImage ? (
                  <Image
                    width={flyer.wt}
                    height={flyer.ht}
                    src={flyer.url}
                    onLoad={handleImageLoad}
                  />

                ) : (
                  <a href={flyer.url} target="_blank" rel="noopener noreferrer" style={{ width: 100, height: 100 }}>
                    <BsFilePdf style={{ width: 100, height: 150, color: '#cc0000' }} />
                  </a>
                )}
                {loading && <Spin className="image-loading" />}
              </div>
            ))}
          </div>
        )
          : (
            <div>
              <EmptyAnime AnimeType="EmptyUserSide" />
            </div>
          )}
      </>
    </Image.PreviewGroup>
  );
}
