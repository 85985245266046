export const generalActionTypes = {
  ipapi: 'ipapi',
  // please have all tutor actions types const in the below tutor property
  tutor: {
    profile: 'profile',
    handle: 'handle',
    profileUpdate: 'profileUpdate',
    coverImage: 'coverImage',
    businessProfileUpdate: 'businessProfileUpdate',
    profileImageUpdate: 'profileImageUpdate',
    profileImageRemove: 'profileImageRemove',
    name: 'tutor',
  },
  // please have all services actions types const in the below services property
  business: {
    list: 'list',
    create: 'create',
    view: 'view',
    searchTutorList: 'searchTutorList',
  },

  services: {
    list: 'list',
    name: 'services',
    servicesCount: 'servicesCount',
  },
  // please have all camps actions types const in the below camps property
  camps: {
    view: 'view',
    name: 'camps',
  },
  competitions: {
    view: 'view',
    name: 'competitions',
  },
  events: {
    view: 'view',
    name: 'events',
  },

  classes: {
    name: 'classes',
    list: 'list',
    view: 'view',
  },

  // classesView:{
  //   name:'classesView',
  //   view: 'view',
  // },
  // please have all discounts actions types const in the below discounts property
  discounts: {
    list: 'list',
    delete: 'delete',
    create: 'create',
    update: 'update',
    name: 'discounts',
  },
  user: {
    attendances: 'attendances',
  },

  // update specific action state
  generalUpdateTutorState: 'generalUpdateTutorState',
  generalUpdateServicesState: 'generalUpdateServicesState',
  generalUpdateCampsState: 'generalUpdateCampsState',
  generalUpdateDiscountsState: 'generalUpdateDiscountsState',
  generalUpdateClassesState: 'generalUpdateClassesState',
  generalUpdateCompetitionState: 'generalUpdateCompetitionState',
  generalUpdateEventState: 'generalUpdateEventState',
  generalUpdateUserState: 'generalUpdateUserState',
};

export default generalActionTypes;
