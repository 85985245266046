import { Tooltip } from 'antd'
import React, { ReactNode } from 'react'

interface CommonTooltipProps {
  title: string
  arrow?: boolean
  children?: ReactNode
  overlayClassName?: string
}

export const CommonTooltip: React.FC<CommonTooltipProps> = ({
  title = '',
  arrow,
  children = null,
  overlayClassName,
}) => {
  return (
    <Tooltip title={title} arrow={arrow} overlayClassName={overlayClassName}>
      <span>{children}</span>
    </Tooltip>
  )
}
