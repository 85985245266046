import React, { useState } from 'react';
import { Pagination } from 'antd';
import { Link } from 'react-router-dom';
import ClassCampCard from '../../re-usable/class-camp-card';
import viewClassCamps from '../../../utils/common/get-view-url';
import _ from 'lodash'
import EmptyAnime from '../../re-usable/lottie/EmptyAnime';

function OtherClassesCamps(props) {
  const [campsListPage, setCampsListPage] = useState(1);
  const onChangePage = (page, pageSize, stateVariable) => {
    // Perform necessary actions when page changes
    // Update the state variable
    if (stateVariable === 'classesListPage') {
      setCampsListPage(page);
    }
  };
  const listItems = _.get(props, 'otherList');
  return (
    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <div className="row  other-classes">

        {listItems && listItems.meta && listItems.meta.count > 0
          ? Array.isArray(listItems.items) && listItems.items.map((value, key) => {
            if (value.id !== props.thisDetailId) {
              return (
                <div key={`class_list${key}`} className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-4">
                  <div className="nurtem-profile-class-card">
                    <Link to={viewClassCamps(props.provider, value, props.type)}>
                      <>
                        {' '}
                        <ClassCampCard data={value} providerdata={props} />
                      </>
                    </Link>
                  </div>
                </div>
              );
            }
            return (" ");
          })
          : (
            <div>
              <EmptyAnime AnimeType="Empty" loop animeheight={150} />
              <p className="empty-animations">
                {"No Other Class/Camps"}
                {' '}
              </p>
            </div>
          )}

        <div className="full-width mb-4">
          {listItems && listItems.items && listItems.items.length > 0
            && listItems && listItems.meta && listItems.meta.total_count > 10 ? (
            <Pagination
              style={{ width: '100%', height: '100%', cursor: 'pointer' }}
              defaultCurrent={1}
              current={campsListPage}
              showSizeChanger={false}
              onChange={(page, pageSize) => { onChangePage(page, pageSize, 'classesListPage'); this.scrollViewPicker(); }}
              defaultPageSize={_.get(listItems, 'meta.limit', 0)}
              total={_.get(listItems, 'meta.total_count', 0)}
              className="ant-table-pagination"
            />
          )
            : null}
        </div>
      </div>
    </div>
  );
}
export default OtherClassesCamps;
