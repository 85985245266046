import axios from '../../../utils/axios';
// import _ from 'lodash';
// import { notification } from 'antd'
import * as actionTypes from './actionTypes';

export const updateTourGuide = (data) => ({
  type: actionTypes.updateTourGuide,
  loading: data,
});

export const onTourGuide = (requestParams, callback = () => { }) => (dispatch) => {
  axios({
    method: 'post',
    url: '/common/tour-guide',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        // const message_args = {
        //     duration: 3,
        //     message: response.data.message
        // }
        // notification.success(message_args)
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(updateTourGuide(deleteResponse));
        callback();
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      dispatch(updateTourGuide(deleteResponse));
    });
};
