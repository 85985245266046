import React from 'react'
import {
  Form,
  Input,
  Radio,
  Row,
  Col,
  DatePicker,
  InputNumber,
  Button,
} from 'antd'
import _ from 'lodash'

import buildFormMeta from './form-meta'

const rootElement = document.documentElement

const CouponBlockForm = (props) => {
  console.log('props >>>', props)

  const formFields = buildFormMeta(props).fields
  const formItemRenderEngine = _.get(
    props,
    'LogicalBlock.generalLogic.renderEngine',
    () => {}
  )
  const updateComponentDetails = _.get(
    props,
    'LogicalBlock.generalLogic.updateComponentDetails',
    () => {}
  )
  const generateRandomCoupon = _.get(
    props,
    'LogicalBlock.generalLogic.generateRandomCoupon',
    () => {}
  )
  const resetAll = _.get(props, 'LogicalBlock.generalLogic.resetAll', () => {})
  const ExitCouponBlock = _.get(
    props,
    'LogicalBlock.generalLogic.ExitCouponBlock',
    () => {}
  )
  const onFormFinish = _.get(
    props,
    'LogicalBlock.generalLogic.onFormFinish',
    () => {}
  )

  const componentDetails_type = _.get(props, 'componentDetails.type', '')
  const componentDetails_mode = _.get(props, 'componentDetails.mode', '')

  console.log('componentDetails_type', _.get(props, 'componentDetails', ''))

  const block1 = () => (
    <div className="full-width couponbox">
      <div className="row">
        {/* constructing form-item coupon code. */}
        {formItemRenderEngine({
          config: formFields.coupon_code,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              {formField}
              <span style={{ paddingLeft: '10px' }}>Type your own or</span>{' '}
              <span className="generate-coupon">
                <u
                  onClick={() =>
                    generateRandomCoupon({
                      form_field: formFields.coupon_code.form_item.name,
                    })
                  }
                  style={{ color: '#007ae5' }}
                >
                  Generate Coupon
                </u>
              </span>
            </div>
          ),
          element: (properties) => <Input {...properties} />,
        })}

        {/* constructing form-item coupon type. */}
        {formItemRenderEngine({
          config: formFields.coupon_type,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => {
            const type = [
              {
                label: 'Percentage',
                eg: 'eg:10% off',
                type_obj: _.get(props, 'config.type.general_discount', {}),
              },
              {
                label: 'Flat off rate',
                eg: 'eg:Flat $5 off',
                type_obj: _.get(props, 'config.type.flate_rate', {}),
              },
              {
                label: 'Item Based',
                eg: 'eg:Siblings offer',
                type_obj: _.get(props, 'config.type.item_based', {}),
              },
            ]
            return (
              <Row className="class-type-radio-group">
                {_.map(type, (item, key) => (
                  <Col span={8} key={key}>
                    <Radio
                      key={`radio_${key}`}
                      name="radiotype"
                      disabled={componentDetails_mode === 'updateCoupon'}
                      onClick={(event) => {
                        updateComponentDetails({
                          type: _.get(event, 'target.value', null),
                        })
                      }}
                      checked={componentDetails_type === item.type_obj.alias}
                      value={item.type_obj.alias}
                    >
                      {item.label}
                    </Radio>
                    <span className="radio-text">{item.eg}</span>
                  </Col>
                ))}
              </Row>
            )
          },
        })}
      </div>
    </div>
  )
  const block2 = () => (
    <div className="full-width mt-1 couponbox">
      <div className="row">
        {/* constructing form-item coupon start date. */}
        {formItemRenderEngine({
          config: formFields.coupon_valid_from,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <DatePicker {...properties} />,
        })}
        {/* constructing form-item coupon no of dependent. */}
        {formItemRenderEngine({
          config: formFields.coupon_no_of_dependent,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <InputNumber {...properties} />,
        })}
        {/* constructing form-item coupon amount. */}
        {formItemRenderEngine({
          config: formFields.coupon_value,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <InputNumber {...properties} />,
        })}
      </div>
    </div>
  )
  const block3 = () => (
    <div className="full-width mt-1 couponbox">
      <div className="row">
        {/* constructing form-item coupon end date. */}
        {formItemRenderEngine({
          config: formFields.coupon_expiry_date,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <DatePicker {...properties} />,
        })}
        {/* constructing form-item coupon min cart value. */}
        {formItemRenderEngine({
          config: formFields.coupon_min_cart_value,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <InputNumber {...properties} />,
        })}
        {/* constructing form-item coupon max cart value. */}
        {formItemRenderEngine({
          config: formFields.coupon_max_cart_value,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <InputNumber {...properties} />,
        })}
        {/* constructing form-item coupon max no of avails. */}
        {formItemRenderEngine({
          config: formFields.coupon_max_no_of_avails,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 mb-3">
              {formField}
            </div>
          ),
          element: (properties) => <InputNumber {...properties} />,
        })}
      </div>
    </div>
  )
  const block4 = () => (
    <div className="full-width mt-1 couponbox">
      <div className="row">
        {/* constructing form-item coupon description. */}
        {formItemRenderEngine({
          config: formFields.coupon_description,
          wrapper: (formField) => (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              {formField}
            </div>
          ),
          element: (properties) => <Input.TextArea {...properties} />,
        })}
      </div>
    </div>
  )
  const actionBlock = () => {
    const isAddCouponModeInProgress = () =>
      _.isEqual(
        _.get(props, 'componentDetails.mode', null),
        _.get(props, 'config.modes.addCoupon.alias', null)
      )
    rootElement.scrollIntoView({ behavior: 'smooth' })
    return (
      <div className="full-width button-group-section mt-3 couponbox">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <Button htmlType="submit" type="primary" className="class-btn">
              {isAddCouponModeInProgress() ? 'Add Coupon' : 'Update Coupon'}
            </Button>
            {isAddCouponModeInProgress() ? (
              <Button className="btn-reset mr-2" onClick={resetAll}>
                Reset All
              </Button>
            ) : null}
            <Button className="btn-exit" onClick={ExitCouponBlock}>
              Exit
            </Button>
          </div>
        </div>
      </div>
    )
  }
  const renderOutput = () => (
    <div className="tutor-dashboard-my-coupons-form">
      <Form form={props.couponBlockFormInstance} onFinish={onFormFinish}>
        {block1()}
        {block2()}
        {block3()}
        {block4()}
        {actionBlock()}
      </Form>
    </div>
  )

  return renderOutput()
}

export default CouponBlockForm
