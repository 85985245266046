import React from 'react'
import {
  Button,
  Table,
  Checkbox,
  Popconfirm,
  Form,
  Radio,
  notification,
  DatePicker,
  Space,
} from 'antd'
import {
  AiOutlineCalendar,
  AiOutlineLeft,
  AiOutlineRight,
} from 'react-icons/ai'
import './tutor-dashboard-attendance.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import _ from 'lodash'
import async from 'async'
import queryString from 'query-string'
import * as userAction from '../../../store/actions/users'
import * as classActions from '../../../store/actions/tutor/add-class'
import * as action from '../../../store/actions/tutor/camps'
import * as attendances from '../../../store/actions/tutor/attendances'
import ScrollToTop from '../../../components/re-usable/ScrollToTop'

let class_id
class TutorViewSessionAttendance extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      monthDate: dayjs().format('MMM YYYY'),
      page: 1,
      dataSource: [],
      columns: [],
      showTextBox: false,
      showTextBoxes: false,
      date: {},
      selectedDate: null,
      toDate: null,
      isLoading: false,
      selectedButton: null,
      dataArray: [],
      transformedData: {
        session_details: '',
        date_marked: [],
        date_unmarked: [],
      },
    }
  }

  async componentDidMount() {
    if (window.location.hash) window.scroll({ top: 0, behavior: 'smooth' })
    setTimeout(() => {
      window.scroll({ top: 0, behavior: 'smooth' })
    }, 1)

    const urlparams = queryString.parse(this.props.location.search)
    class_id = urlparams && _.get(urlparams, 'classId').split('?')[0]
    const provId = _.get(urlparams, 'provId', '')
    if (class_id) {
      try {
        await Promise.all([
          this.props.onsessionDetail({
            s: Math.random(),
            id: class_id,
            expand: 'services,provider',
            type: 'camps',
          }),
          this.props.onGetAttendenceList({
            s: Math.random(),
            session_details: class_id,
            user_type: 'provider',
            limit: 100,
          }),
          this.props.getCampsStudents({
            s: Math.random(),
            camp_details: class_id,
            limit: 100,
            status: 1,
            sort: 'created_at.desc',
            provider: _.get(this, 'props.sessionDetail.provider.id', provId),
            expand: 'camp_details,user,dependent_user,attendance',
          }),
        ])
        this.setState({ class_id })
      } catch (error) {
        console.log('err in camp att', error)
      }
    }
  }

  handleBack() {
    window.history.back()
  }

  apiCall = async () => {
    const urlparams = queryString.parse(this.props.location.search)
    const class_id = urlparams && _.get(urlparams, 'classId').split('?')[0]
    const provId = _.get(urlparams, 'provId', '')
    if (class_id) {
      try {
        await Promise.all([
          this.props.onsessionDetail({
            s: Math.random(),
            id: class_id,
            expand: 'services,provider',
          }),
          this.props.onGetAttendenceList({
            s: Math.random(),
            session_details: class_id,
            user_type: 'provider',
            limit: 100,
          }),
          this.props.getCampsStudents({
            s: Math.random(),
            camp_details: class_id,
            limit: 100,
            status: 1,
            // page: page,
            sort: 'created_at.desc',
            provider: _.get(this, 'props.sessionDetail.provider.id', provId),
            expand: 'camp_details,user,dependent_user',
          }),
        ])
        this.setState({ class_id })
        this.tablePreviewcall()
      } catch (error) {
        console.log('err in camp att1', error)
      }
    }
  }

  async componentDidUpdate(prevprops, prevState) {
    if (
      this.props.sessionDetail &&
      typeof this.props.attendances === 'object' &&
      !Array.isArray(this.props.attendances) &&
      class_id === this.props.sessionDetail.id
    ) {
      if (
        prevprops.classSessionModifySuccess !==
          this.props.classSessionModifySuccess &&
        this.props.classSessionModifySuccess
      ) {
        if (this.props.classSessionModifySuccess.status) {
          this.setState({ isLoading: false })
        }
        if (this.props.classSessionModifySuccess.status === 200) {
          this.setState({ selectedButton: null })
          this.setState({ showTextBox: false })
          this.setState({ showTextBoxes: false })
        }
      }
      if (
        prevprops.createdAttendance !== this.props.createdAttendance ||
        prevprops.classSessionModifySuccess !==
          this.props.classSessionModifySuccess ||
        prevprops.cancelledAttendance !== this.props.cancelledAttendance
      ) {
        if (this.props.createdAttendance.status) {
          this.setState({ isLoading: false })
        }
        this.apiCall()
      }

      if (
        (prevState.dataSource !== this.state.dataSource &&
          _.size(_.get(prevState, 'dataSource')) !==
            _.size(_.get(this, 'state.dataSource'))) ||
        (prevState.columns !== this.state.columns &&
          _.size(_.get(prevState, 'columns')) !==
            _.size(_.get(this, 'state.columns'))) ||
        prevState.monthDate !== this.state.monthDate
      ) {
        this.tablePreviewcall()
      }

      if (
        prevprops.attendances !== this.props.attendances ||
        (prevprops.campsStudents !== this.props.campsStudents &&
          _.size(_.get(prevprops, 'campsStudents')) !==
            _.size(_.get(this, 'props.campsStudents')))
      ) {
        this.tablePreviewcall()
      }
    }
  }

  onCancelClass = async (cancel_date, column_key, reason) => {
    const session_details = this.props.sessionDetail
    const cancelled_days = session_details.cancelled_days
      ? session_details.cancelled_days
      : []
    cancel_date = dayjs(cancel_date)
    const credit_booking_dates = dayjs(cancel_date).format('YYYY-MM-DD')
    const addedon = dayjs()
    const type = 'cancelled_class'
    if (
      _.findIndex(cancelled_days, (o) => _.isMatch(o.date, cancel_date)) === -1
    ) {
      cancelled_days.push({
        date: dayjs(cancel_date).format('YYYY-MM-DD'),
        type,
        addedon,
        reason: reason || 'N/A',
        for: 'camps',
      })
      const data = {
        params: { id: session_details.id },
        data: { cancelled_days },
      }
      const request = {
        previous_credits: _.size(cancelled_days),
        class_id: _.get(session_details, 'id'),
        booking_date: credit_booking_dates,
      }
      await this.props.cancelClass(data)
      await this.props.credits(request)
    }
  }

  onReschduleClasses = async (cancel_date, type, reason) => {
    const session_details = this.props.sessionDetail
    const data_obj = session_details.session_dates
    const modifiedon = dayjs()

    if (type === 'extra class') {
      const extra_days = session_details.extra_days
        ? session_details.extra_days
        : []
      if (
        _.findIndex(extra_days, (o) => _.isMatch(o.date, cancel_date)) === -1
      ) {
        extra_days.push({
          date: dayjs(cancel_date).format('YYYY-MM-DD'),
          type,
          modifiedon,
          reason: reason || 'N/A',
          for: 'camps',
        })
        const data = {
          params: { id: session_details.id },
          data: { extra_days },
        }
        const request = {
          previous_credits: _.size(extra_days),
          class_id: _.get(session_details, 'id'),
          // booking_date: credit_booking_dates
        }
        await this.props.reschudeledClass(data)
        await this.props.credits(request)
      }
    } else if (type === 'reschdule class') {
      const reschdule_date = dayjs(cancel_date[0]).format('YYYY-MM-DD')
      const new_date = dayjs(cancel_date[1]).format('YYYY-MM-DD')
      if (data_obj.includes(reschdule_date)) {
        if (data_obj.includes(new_date)) {
          this.setState({ isLoading: false })
          notification.error({
            message: this.props.t('dateResh'),
          })
        } else {
          const reschduled_days = session_details.reschduled_days
            ? session_details.reschduled_days
            : []
          if (
            _.findIndex(reschduled_days, (o) =>
              _.isMatch(o.date, cancel_date)
            ) === -1
          ) {
            reschduled_days.push({
              oldDate: dayjs(cancel_date[0]).format('YYYY-MM-DD'),
              newDate: dayjs(cancel_date[1]).format('YYYY-MM-DD'),
              type,
              modifiedon,
              reason: reason || 'N/A',
              for: 'camps',
            })
            const data = {
              params: { id: session_details.id },
              data: { reschduled_days },
            }
            const request = {
              previous_credits: _.size(reschduled_days),
              class_id: _.get(session_details, 'id'),
              // booking_date: credit_booking_dates
            }
            await this.props.reschudeledClass(data)
            await this.props.credits(request)
          }
        }
      } else {
        this.setState({ isLoading: false })
        notification.error({
          message: this.props.t('dateResh1'),
        })
      }
    }
  }

  onSubmitAtt = () => {
    const urlparams = queryString.parse(this.props.location.search)
    const provId = _.get(urlparams, 'provId', '')
    if (
      !this.state.transformedData.date_marked.length &&
      !this.state.transformedData.date_unmarked.length
    ) {
      return
    }
    const data = this.state.transformedData
    this.setState({ isLoading: true })
    this.props.createAttendance({
      ...data,
      provider: _.get(this, 'props.sessionDetail.provider.id', provId),
    })

    this.setState({
      transformedData: {
        session_details: '',
        date_marked: [],
        date_unmarked: [],
      },
    })
  }

  onCollectData = async (
    checked,
    session_details,
    user,
    dependent_user,
    mark_date,
    id
  ) => {
    const dataArray = [...this.state.dataArray]
    const transformedData = { ...this.state.transformedData }
    transformedData.session_details = session_details.camp_details.id
    transformedData.class_details = session_details.camp_details.group_id
    transformedData.type = 'camps'
    const data = {
      user,
      dependent_user,
      mark_date: dayjs(mark_date).format('MM-DD-YY'),
      session_details: session_details.camp_details.id,
      class_information_id: session_details.id,
    }

    if (!checked) {
      const index = dataArray.findIndex(
        (item) =>
          item.class_information_id === session_details.id &&
          item.mark_date === mark_date
      )
      if (index > -1) {
        dataArray.splice(index, 1)
      }
      transformedData.date_unmarked.push({
        session_details: session_details.camp_details.id,
        class_information_id: session_details.id,
        marked_date: dayjs(mark_date).format('MM-DD-YY'),
      })
    } else {
      dataArray.push(data)

      const dateMarkedIndex = transformedData.date_marked.findIndex(
        (item) => item.marked_date === data.mark_date
      )
      if (dateMarkedIndex === -1) {
        transformedData.date_marked.push({
          marked_date: data.mark_date,
          attendance: [
            {
              user: data.user,
              dependent_user: data.dependent_user,
              class_information_id: data.class_information_id,
            },
          ],
        })
      } else {
        transformedData.date_marked[dateMarkedIndex].attendance.push({
          user: data.user,
          dependent_user: data.dependent_user,
          class_information_id: data.class_information_id,
        })
      }

      transformedData.date_unmarked = transformedData.date_unmarked.filter(
        (item) =>
          !(
            item.class_information_id === session_details.id &&
            item.marked_date === dayjs(mark_date).format('MM-DD-YY')
          )
      )
    }

    transformedData.date_marked = dataArray.reduce((result, item) => {
      const marked_date = item.mark_date

      if (!result[marked_date]) {
        result[marked_date] = {}
      }

      if (!result[marked_date].attendance) {
        result[marked_date].attendance = []
      }

      result[marked_date].attendance.push({
        user: item.user,
        dependent_user: item.dependent_user,
        class_information_id: item.class_information_id,
      })

      return result
    }, {})

    transformedData.date_marked = Object.entries(
      transformedData.date_marked
    ).map(([marked_date, attendance]) => ({
      marked_date,
      attendance: attendance.attendance,
    }))
    this.setState({ dataArray, transformedData })
  }

  tablePreviewcall = () => {
    const { columns } = this.state
    if (this.props.sessionDetail && this.props.attendances) {
      this.buildColumns(
        this.props.sessionDetail,
        this.props.attendances,
        (column_data) => {
          if (_.size(column_data) !== _.size(_.get(this, 'state.columns'))) {
            this.setState({
              columns: column_data,
            })
          }
        }
      )
    }
    if (
      this.props.attendances &&
      this.props.campsStudents &&
      this.props.campsStudents.items
    ) {
      try {
        this.buildDataSource(
          columns,
          this.props.campsStudents.items,
          this.props.attendances,
          (source_data) => {
            this.setState({
              dataSource: source_data,
            })
          }
        )
      } catch (error) {
        console.log(
          'error at session-attendance in  buildDataSource function',
          error
        )
      }
    }
  }

  buildColumns = (details, attendeesDetails, callback) => {
    let column_data = [
      {
        title: this.props.t('stuName'),
        dataIndex: 'name',
        key: 'name',
        children: [
          {
            title: <div />,
            dataIndex: 'name',
            key: 'name',
            width: '20%',
          },
        ],
      },
    ]
    const weekDaysCount = details.session_dates
    const currentFilterDate = dayjs(this.state.monthDate, 'MMM YYYY').format(
      'MM-DD-YY'
    )
    const startOfMonth = dayjs(currentFilterDate)
      .startOf('month')
      .format('MM-DD-YY')
    const endOfMonth = dayjs(currentFilterDate)
      .endOf('month')
      .format('MM-DD-YY')

    const finalData = _.map(weekDaysCount, (value, index) =>
      (dayjs(startOfMonth).isSame(value, 'day') ||
        dayjs(startOfMonth).isBefore(value, 'day')) &&
      (dayjs(endOfMonth).isSame(value, 'day') ||
        dayjs(endOfMonth).isAfter(value, 'day'))
        ? value
        : null
    )

    _.compact(finalData).map((start, index) => {
      const selected_date = dayjs(start).format('MM-DD-YY')

      if (
        (dayjs(startOfMonth).isSame(selected_date, 'day') ||
          dayjs(startOfMonth).isBefore(selected_date, 'day')) &&
        (dayjs(endOfMonth).isSame(selected_date, 'day') ||
          dayjs(endOfMonth).isAfter(selected_date, 'day'))
      ) {
        const column_index = index
        const hideCancelClass =
          _.get(this, 'props.classDetails.provider.type') === 'Individual' ||
          'Business'
            ? 'ant-btn-sm btn-cancel'
            : 'ant-btn-sm btn-cancel'

        const temp = {
          title: dayjs(selected_date).format('MMM DD'),
          attendance_date: selected_date,
          is_cancelled: false,
          width: 100,
          align: 'center',
          children: [
            {
              title: (
                <div>
                  <Popconfirm
                    title={this.props.t('dateResh2')}
                    onConfirm={() =>
                      this.onCancelClass(selected_date, column_index)
                    }
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      id={`cancel_btn_column_${column_index}`}
                      className={hideCancelClass}
                    >
                      Cancel
                    </Button>
                  </Popconfirm>
                </div>
              ),
              dataIndex: `column_${column_index}`,
              align: 'center',
            },
          ],
        }

        Object.keys(attendeesDetails).length > 0 &&
          _.map(attendeesDetails.date_marked, (att, i) => {
            const date_marked = att.marked_date

            if (_.isEqual(date_marked, selected_date)) {
              temp.children = [
                {
                  title: (
                    <div>
                      <Button
                        id={`cancel_btn_column_${column_index}`}
                        className="ant-btn-sm btn-cancel-disable"
                        disabled
                      >
                        Cancel
                      </Button>
                    </div>
                  ),
                  dataIndex: `column_${column_index}`,
                },
              ]
            }
          })
        temp[`column_${column_index}`] = selected_date
        column_data.push(temp)
        return null
      }
      return null
    })

    if (_.size(column_data) > 1 && _.size(column_data) < 3) {
      column_data = [...column_data]
    }
    callback(column_data)
  }

  buildDataSource = (columns, campsStudents, attendances, callback) => {
    const params = new URLSearchParams(window.location.search)
    const source_data = []
    const checkboxAction = this.onCollectData
    const studentslistfilters = _.uniqBy(
      campsStudents,
      (n) =>
        (!_.isEmpty(n.dependent_user) && n.dependent_user.id) ||
        (n.user && n.user.id)
    )

    async.forEachOf(studentslistfilters, (value, key, student_callback) => {
      if (
        (_.get(value, 'booking_dates') &&
          _.size(_.get(value, 'booking_dates')) > 0 &&
          !_.isDate(new Date(_.get(value, 'booking_dates')))) ||
        _.size(_.get(value, 'additional_details', [])) > 0
      ) {
        const bookdates =
          !_.isEmpty(_.get(value, 'booking_dates')) &&
          !_.isDate(new Date(_.get(value, 'booking_dates')))
            ? _.get(value, 'booking_dates', [])
            : _.get(value, 'additional_details', [])

        _.map(bookdates, (booking, index) => {
          const temp = {}

          if (!params.has('day')) {
            temp.name = _.get(value, 'dependent_user.id')
              ? `${_.startCase(_.get(value, 'dependent_user.firstname'))} ${
                  _.get(value, 'dependent_user.lastname', '') !== ''
                    ? `${_.startCase(_.get(value, 'dependent_user.lastname', ''))[0]}.`
                    : ''
                }`
              : `${value.user.firstname} ${
                  _.get(value, 'user.lastname', '') !== ''
                    ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.`
                    : ''
                }`
            temp.key = `row_${key}`
            const user = value.user.id
            const dependent_user = value.dependent_user
              ? value.dependent_user.id
              : null
            let arr = columns
            arr = _.tail(arr)
            async.forEachOf(
              arr,
              (column_value, column_key, column_callback) => {
                let checked = !!0
                const current_date = dayjs().format('MM-DD-YY')

                const column_date = column_value.attendance_date
                temp.date = column_date
                if (attendances && _.size(attendances.date_marked) === 0) {
                  temp[`column_${column_key}`] =
                    dayjs(column_date).isAfter(current_date) ||
                    column_value.is_cancelled === true ? (
                      <Checkbox
                        disabled
                        className={`checkbox_column_${column_key}`}
                        defaultChecked={checked}
                      />
                    ) : _.isEmpty(column_date) ? (
                      <div />
                    ) : (
                      <Checkbox
                        disabled={_.get(column_value, 'is_cancelled', false)}
                        defaultChecked={checked}
                        onChange={(e) =>
                          checkboxAction(
                            e.target.checked,
                            value,
                            user,
                            dependent_user,
                            column_date,
                            id
                          )
                        }
                      />
                    )
                }
                let id = null
                async.forEachOf(
                  attendances.date_marked,
                  (sub_value, sub_key) => {
                    const mark_date = sub_value.marked_date
                    const att_name = sub_value.attendance
                    async.forEachOf(
                      att_name,
                      (sub_value, sub_key, attendance_callback) => {
                        const attendance_user = !_.isEmpty(
                          _.get(sub_value, 'dependent_user')
                        )
                          ? _.get(sub_value, 'dependent_user')
                          : _.get(sub_value, 'user')

                        if (
                          mark_date === column_date &&
                          _.isEmpty(_.get(value, 'dependent_user')) &&
                          user === attendance_user
                        ) {
                          checked = !!1
                          id = sub_value.id
                          temp[`column_${column_key}`] = (
                            <Checkbox
                              disabled={_.get(
                                column_value,
                                'is_cancelled',
                                false
                              )}
                              defaultChecked={checked}
                              onChange={(e) =>
                                checkboxAction(
                                  e.target.checked,
                                  value,
                                  user,
                                  dependent_user,
                                  column_date,
                                  id
                                )
                              }
                            />
                          )
                        } else {
                          temp[`column_${column_key}`] =
                            dayjs(column_date).isAfter(current_date) ||
                            column_value.is_cancelled === true ? (
                              <Checkbox
                                disabled
                                className={`checkbox_column_${column_key}`}
                                defaultChecked={checked}
                              />
                            ) : (
                              <Checkbox
                                disabled={_.get(
                                  column_value,
                                  'is_cancelled',
                                  false
                                )}
                                defaultChecked={checked}
                                onChange={(e) =>
                                  checkboxAction(
                                    e.target.checked,
                                    value,
                                    user,
                                    dependent_user,
                                    column_date,
                                    id
                                  )
                                }
                              />
                            )
                        }
                        if (
                          mark_date === column_date &&
                          dependent_user === attendance_user
                        ) {
                          checked = !!1
                          id = sub_value.id
                          temp[`column_${column_key}`] = (
                            <Checkbox
                              disabled={_.get(
                                column_value,
                                'is_cancelled',
                                false
                              )}
                              defaultChecked={checked}
                              onChange={(e) =>
                                checkboxAction(
                                  e.target.checked,
                                  value,
                                  user,
                                  dependent_user,
                                  column_date,
                                  id
                                )
                              }
                            />
                          )
                        } else {
                          temp[`column_${column_key}`] =
                            dayjs(column_date).isAfter(current_date) ||
                            column_value.is_cancelled === true ? (
                              <Checkbox
                                disabled
                                className={`checkbox_column_${column_key}`}
                                defaultChecked={checked}
                              />
                            ) : (
                              <Checkbox
                                disabled={_.get(
                                  column_value,
                                  'is_cancelled',
                                  false
                                )}
                                defaultChecked={checked}
                                onChange={(e) =>
                                  checkboxAction(
                                    e.target.checked,
                                    value,
                                    user,
                                    dependent_user,
                                    column_date,
                                    id
                                  )
                                }
                              />
                            )
                        }
                        attendance_callback()
                      }
                    )
                  }
                )

                column_callback()
              }
            )
            source_data.push(temp)
            student_callback()
          }
        })
      } else {
        const temp = {}
        temp.name = _.get(value, 'dependent_user.id')
          ? `${_.startCase(_.get(value, 'dependent_user.firstname'))} ${
              _.get(value, 'dependent_user.lastname', '') !== ''
                ? `${_.startCase(_.get(value, 'dependent_user.lastname', ''))[0]}.`
                : ''
            }`
          : `${value.user.firstname} ${
              _.get(value, 'user.lastname', '') !== ''
                ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.`
                : ''
            }`
        temp.key = `row_${key}`
        const user = value.user.id
        const dependent_user = value.dependent_user
          ? value.dependent_user.id
          : null
        let arr = columns
        arr = _.tail(arr)
        async.forEachOf(arr, (column_value, column_key, column_callback) => {
          let checked = !!0
          const current_date = dayjs().format('MM-DD-YY')

          const column_date = column_value.attendance_date
          temp.date = column_date
          if (attendances && _.size(attendances.date_marked) === 0) {
            temp[`column_${column_key}`] =
              dayjs(column_date).isAfter(current_date) ||
              column_value.is_cancelled === true ? (
                <Checkbox
                  disabled
                  className={`checkbox_column_${column_key}`}
                  defaultChecked={checked}
                />
              ) : _.isEmpty(column_date) ? (
                <div />
              ) : (
                <Checkbox
                  disabled={_.get(column_value, 'is_cancelled', false)}
                  defaultChecked={checked}
                  onChange={(e) =>
                    checkboxAction(
                      e.target.checked,
                      value,
                      user,
                      dependent_user,
                      column_date,
                      id
                    )
                  }
                />
              )
          }
          let id = null
          async.forEachOf(attendances.date_marked, (sub_value, sub_key) => {
            const mark_date = sub_value.marked_date
            const att_name = sub_value.attendance
            async.forEachOf(
              att_name,
              (sub_value, sub_key, attendance_callback) => {
                const attendance_user = !_.isEmpty(
                  _.get(sub_value, 'dependent_user')
                )
                  ? _.get(sub_value, 'dependent_user')
                  : _.get(sub_value, 'user')

                if (
                  mark_date === column_date &&
                  _.isEmpty(_.get(value, 'dependent_user')) &&
                  user === attendance_user
                ) {
                  checked = !!1
                  id = sub_value.id
                  temp[`column_${column_key}`] = (
                    <Checkbox
                      disabled={_.get(column_value, 'is_cancelled', false)}
                      defaultChecked={checked}
                      onChange={(e) =>
                        checkboxAction(
                          e.target.checked,
                          value,
                          user,
                          dependent_user,
                          column_date,
                          id
                        )
                      }
                    />
                  )
                } else {
                  temp[`column_${column_key}`] =
                    dayjs(column_date).isAfter(current_date) ||
                    column_value.is_cancelled === true ? (
                      <Checkbox
                        disabled
                        className={`checkbox_column_${column_key}`}
                        defaultChecked={checked}
                      />
                    ) : (
                      <Checkbox
                        disabled={_.get(column_value, 'is_cancelled', false)}
                        defaultChecked={checked}
                        onChange={(e) =>
                          checkboxAction(
                            e.target.checked,
                            value,
                            user,
                            dependent_user,
                            column_date,
                            id
                          )
                        }
                      />
                    )
                }
                if (
                  mark_date === column_date &&
                  dependent_user === attendance_user
                ) {
                  checked = !!1
                  id = sub_value.id
                  temp[`column_${column_key}`] = (
                    <Checkbox
                      disabled={_.get(column_value, 'is_cancelled', false)}
                      defaultChecked={checked}
                      onChange={(e) =>
                        checkboxAction(
                          e.target.checked,
                          value,
                          user,
                          dependent_user,
                          column_date,
                          id
                        )
                      }
                    />
                  )
                } else {
                  temp[`column_${column_key}`] =
                    dayjs(column_date).isAfter(current_date) ||
                    column_value.is_cancelled === true ? (
                      <Checkbox
                        disabled
                        className={`checkbox_column_${column_key}`}
                        defaultChecked={checked}
                      />
                    ) : (
                      <Checkbox
                        disabled={_.get(column_value, 'is_cancelled', false)}
                        defaultChecked={checked}
                        onChange={(e) =>
                          checkboxAction(
                            e.target.checked,
                            value,
                            user,
                            dependent_user,
                            column_date,
                            id
                          )
                        }
                      />
                    )
                }
                attendance_callback()
              }
            )
          })

          column_callback()
        })
        source_data.push(temp)
        student_callback()
      }
    })
    callback(source_data)
  }

  onChangeMonth = (type, value) => {
    this.setState({
      dataSource: [],
      columns: [],
    })
    if (type === 'next') {
      this.setState({
        monthDate: dayjs(value).add(1, 'M').format('MMM YYYY'),
      })
    } else {
      this.setState({
        monthDate: dayjs(value).subtract(1, 'months').format('MMM YYYY'),
      })
    }
    this.apiCall()
  }

  pageChange = async (page, pageSize) => {
    const class_id = this.props.match.params.classId

    await this.setState({
      page,
    })
    this.props.getCampsStudents({
      s: Math.random(),
      camp_details: class_id,
      limit: 100,
      status: 1,
      // page: page,
      sort: 'created_at.desc',
      expand: 'camp_details,user,dependent_user',
    })
  }

  disabledDate = (current) => current && current < dayjs().startOf('day')

  onSubmitExtra = () => {
    // this.setState({ isLoading: true });
    const selecteddate = this.state.selectedDate
    const type = 'extra class'
    this.onReschduleClasses(selecteddate, type)
  }

  onSubmitReschdule = () => {
    this.setState({ isLoading: true })
    const selectdate = this.state.selectedDate
    const todate = this.state.toDate
    const dates = [].concat(selectdate, todate)
    const type = 'reschdule class'
    this.onReschduleClasses(dates, type)
  }

  handleOnChange = (e) => {
    this.setState({ selectedButton: e.target.value })
    if (e.target.value === 0) {
      this.setState({ showTextBoxes: false })
      this.setState({ showTextBox: true })
    } else if (e.target.value === 1) {
      this.setState({ showTextBoxes: true })
      this.setState({ showTextBox: false })
    }
  }

  render() {
    const { monthDate, dataSource, columns, showTextBox, showTextBoxes } =
      this.state
    return (
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 nurtem-profile-card sessionbox ">
        <div className="container-xl row">
          <ScrollToTop />
          <div className="col-12 mb-3">
            <div className="nurtem-profile-card-title">
              <span className="title-text">{this.props.t('stuName')}</span>
              <span className="close-symbol" onClick={this.handleBack}>
                &#x2716;
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="nurtem-profile-card-title-name">
              {this.props.t('batName')} :{' '}
              <span style={{ fontWeight: '400' }}>
                {_.get(this, 'props.sessionDetail.session_name')}
              </span>
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3 text-right">
            <div className="full-width">
              <Form.Item>
                <Radio.Group
                  onChange={this.handleOnChange}
                  value={this.state.selectedButton}
                >
                  <Space direction="horizontal">
                    <Radio value={0}>{this.props.t('addSession')}</Radio>
                    <Radio value={1}>{this.props.t('resSession')}</Radio>
                  </Space>
                </Radio.Group>

                {showTextBox && (
                  <div className="col-12 mt-4">
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'end',
                      }}
                    >
                      <div className="nurtem-card-card-title">
                        <span style={{ marginRight: 5 }}>
                          {this.props.t('addSession')} :{' '}
                        </span>
                        <DatePicker
                          onChange={(date) =>
                            this.setState({ selectedDate: date })
                          }
                          disabledDate={this.disabledDate}
                          format="MM/DD/YYYY"
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              e.preventDefault(); // Prevent form submission
                            }
                          }}
                        />
                      </div>

                      <Button
                        className="submit-att-button"
                        loading={this.state.isLoading}
                        onClick={this.onSubmitExtra}
                      >
                        {this.props.t('submit')}
                      </Button>
                    </div>
                  </div>
                )}
                {showTextBoxes && (
                  <div className="col-12 mt-4">
                    <div className="row">
                      <div className="col-12">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                          }}
                        >
                          <div className="nurtem-date">
                            <DatePicker
                              placeholder={['From']}
                              onChange={(date) =>
                                this.setState({ selectedDate: date })
                              }
                              disabledDate={this.disabledDate}
                              format="MM/DD/YYYY"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevent form submission
                                }
                              }}
                            />
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span style={{ fontSize: '20px' }}>&#8594;</span>
                          </div>
                          <div
                            className="nurtem-date"
                            style={{ marginRight: 5 }}
                          >
                            <DatePicker
                              placeholder={['To']}
                              onChange={(date) =>
                                this.setState({ toDate: date })
                              }
                              disabledDate={this.disabledDate}
                              format="MM/DD/YYYY"
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault(); // Prevent form submission
                                }
                              }}
                            />
                          </div>

                          <Button
                            className="submit-att-button"
                            loading={this.state.isLoading}
                            disabled={
                              !this.state.selectedDate || !this.state.toDate
                            }
                            onClick={this.onSubmitReschdule}
                          >
                            {this.props.t('submit')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Form.Item>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 mb-3">
            <Button
              onClick={() => this.onSubmitAtt()}
              loading={this.state.isLoading}
              className="submit-att-button"
            >
              Submit Attendance
            </Button>
          </div>

          <div className="col-12">
            <div
              className="nurtem-profile-card  border"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '5px',
                }}
              >
                <div className="col-auto">
                  <Button
                    onClick={() => this.onChangeMonth('prev', monthDate)}
                    className="btn-control"
                  >
                    <AiOutlineLeft style={{ fontSize: 20 }} />{' '}
                    {this.props.t('prev')}
                  </Button>
                </div>
                <div
                  className="col d-flex align-items-center justify-content-center"
                  style={{ fontWeight: '600' }}
                >
                  <AiOutlineCalendar
                    style={{ fontSize: 20 }}
                    className="mr-3"
                  />{' '}
                  {monthDate}
                </div>
                <div className="col-auto">
                  <Button
                    onClick={() => this.onChangeMonth('next', monthDate)}
                    className="btn-control"
                  >
                    {this.props.t('next')}{' '}
                    <AiOutlineRight style={{ fontSize: 20 }} />
                  </Button>
                </div>
              </div>

              <div className="nurtem-table mt-4" style={{ margin: '5px' }}>
                <div
                  className="col-12 p-0 table-responsive"
                  style={{ overflowX: 'auto' }}
                >
                  <Table
                    sticky={false}
                    className="table attendanceTable"
                    columns={columns}
                    dataSource={dataSource}
                    pagination={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  attendances: state.attendances.attendancesList.data.items,
  campsStudents: state.campsDetails.campsStudents,
  sessionDetail: state.campsDetails.sessionDetail.data,
  createdAttendance: state.attendances.AttendenceCreation,
  loading: state.attendances.disableLoading,
  deletedAttendance: state.attendances.deleteAttendenceInfo.data,
  cancelledAttendance: state.classesDetails.tutorClassDetails.details,
  classSessionModifySuccess: state.classesDetails.classSessionModifySuccess,
})

const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) =>
    dispatch(action.onGetsessionDetail(requestParams)),
  getCampsStudents: (requestParams) =>
    dispatch(action.getCampsStudents(requestParams)),
  createAttendance: (data) => dispatch(attendances.CreateAttendence(data)),
  onGetAttendenceList: (requestParams) =>
    dispatch(attendances.getAttendenceList(requestParams)),
  deleteAttendance: (data) => dispatch(attendances.onDeleteAttendence(data)),
  cancelClass: (data) => dispatch(classActions.cancelledClass(data)),
  credits: (data) => dispatch(userAction.bookingCredits(data)),
  reschudeledClass: (data) => dispatch(classActions.reschudeledClass(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorViewSessionAttendance))
