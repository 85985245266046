import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';

export const enableContentLoading = () => ({
  type: actionTypes.enableContentLoading,
  loading: { contentLoading: true },
});

export const disableContentLoading = () => ({
  type: actionTypes.disableContentLoading,
  loading: { contentLoading: false },
});

export const enableSearchLoading = () => ({
  type: actionTypes.enableSearchLoading,
  loading: { searchLoading: true },
});

export const disableSearchLoading = () => ({
  type: actionTypes.disableLoadMoreLoading,
  loading: { searchLoading: false },
});

export const enableLoadMoreLoading = () => ({
  type: actionTypes.enableLoadMoreLoading,
  loading: { loadMoreLoading: true },
});

export const disableLoadMoreLoading = () => ({
  type: actionTypes.disableLoadMoreLoading,
  loading: { loadMoreLoading: false },
});

export const serviceFoundSuccess = (data) => ({
  type: actionTypes.serviceFoundSuccess,
  success: data,
});

export const repetServiceFoundSuccess = (data) => ({
  type: actionTypes.serviceSeachFoundSuccess,
  success: [],
});

export const serviceSearchFoundSuccess = (data) => ({
  type: actionTypes.serviceSeachFoundSuccess,
  success: data,
});

export const serviceQuotesFoundSuccess = (data) => ({
  type: actionTypes.serviceQuotesFoundSuccess,
  success: data,
});
export const tutorRateListFoundSuccess = (data) => ({
  type: actionTypes.tutorRateListFoundSuccess,
  success: data,
});
export const sidebarLogo = (data) => ({
  type: actionTypes.sidebarLogo,
  success: data,
});
export const viewAllClassTabs = (data) => ({
  type: actionTypes.viewAllClassTabs,
  success: data,
});
export const onTutorPublicsiderbar = (data) => ({
  type: actionTypes.onTutorPublicsiderbar,
  success: data,
});

export const onTutorDashboardsiderbar = (data) => ({
  type: actionTypes.onTutorDashboardsiderbar,
  success: data,
});

export const onGuestsiderbar = (data) => ({
  type: actionTypes.onGuestsiderbar,
  success: data,
});

export const onHeaderSearch = (data) => ({
  type: actionTypes.onHeaderSearch,
  success: data,
});
export const searchClassCampsRouteRedirect = (data) => ({
  type: actionTypes.searchClassCampsRouteRedirect,
  success: data,
});
export const ontutorSearchBox = (data) => ({
  type: actionTypes.ontutorSearchBox,
  success: data,
});
const capitalizeWord = (value) => {
  if (typeof value !== 'string') return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const onServices = (requestParams) => {
  const modifiedParams = { ...requestParams };
  if (modifiedParams && modifiedParams.search) {
    modifiedParams.search = capitalizeWord(modifiedParams.search);
  }

  console.log('modifiedParams >> ', modifiedParams);

  delete modifiedParams.loadMoreBtnStatus;
  return (dispatch) => {
    if (requestParams.search) {
      dispatch(serviceSearchFoundSuccess(null));
      dispatch(enableSearchLoading());
    } else if (requestParams.loadMoreBtnStatus === true) {
      dispatch(enableLoadMoreLoading());
    } else {
      dispatch(enableContentLoading());
    }

    axios
      .get('/services/list', { params: modifiedParams })
      .then((response) => {
        if (response.status === 200) {
          if (requestParams.search) {
            dispatch(serviceSearchFoundSuccess(response.data));
            dispatch(disableSearchLoading());
          } else if (requestParams.loadMoreBtnStatus === true) {
            dispatch(serviceFoundSuccess(response.data));
            dispatch(disableSearchLoading());
            dispatch(disableLoadMoreLoading());
          } else {
            dispatch(serviceFoundSuccess(response.data));
            dispatch(disableContentLoading());
            dispatch(disableLoadMoreLoading());
          }
        }
      })
      .catch((error) => {
        if (requestParams.search) {
          dispatch(disableSearchLoading());
        } else if (requestParams.loadMoreBtnStatus === true) {
          dispatch(disableLoadMoreLoading());
        } else {
          dispatch(disableContentLoading());
        }
      });
  };
};

export const onGetServiceQuotes = () => (dispatch) => {
  axios
    .get('/servicesquotes/list')
    .then((response) => {
      if (response.status === 200) {
        dispatch(serviceQuotesFoundSuccess(response.data));
      }
    })
    .catch((error) => { });
};
export const onGetTutorRateList = () => (dispatch) => {
  axios
    .get('/search/provider-ratings')
    .then((response) => {
      if (response.status === 200) {
        dispatch(tutorRateListFoundSuccess(response.data));
      }
    })
    .catch((error) => { });
};

export const onGetCurrentLocation = (callback) => (dispatch) => {
  axios
    .get('/getCurrentLocation')
    .then((response) => {
      if (response.status === 200) {
        callback(response && response.data);
      }
    })
    .catch((error) => { });
};
