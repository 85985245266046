import React from 'react';
import { Steps } from 'antd';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ContactInfoTab from './guest-info-flow';
import DependantsTab from './guest-depentent-flow';
import * as tutorActions from '../../../store/actions/tutor/index';
import * as userAction from '../../../store/actions/users';
import * as campsActions from '../../../store/actions/public/Camps';
import * as action from '../../../store/actions/public/Classes';
import * as actions from '../../../store/actions/authentication/authentication';
import './guest-flow.css';
import _ from 'lodash';

const { Step } = Steps;
// Steps Component

class GuestFlowScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
    };
  }

  componentDidMount() {
    // const url = this.props.location.search;
    // const urlparams = queryString.parse(url);
    // if (_.isEmpty(urlparams)) {
    //   // history.push('/')
    // }
    if (_.get(this.props, 'loggedInUserDetails.isLoggedIn', false)) {
      this.props.guestUserFlowStep(1);
      this.setState({
        current: 1,
      });
    }
  }

  componentDidUpdate(preProps) {
    if (preProps.step !== this.props.step) {
      this.setState({
        current: this.props.step,
      });
    }
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  render() {
    // const { current } = this.state;
    // const steps = [
    //   {
    //     title: this.props.t('onboardingTutor4'),
    //     content: <ContactInfoTab />,
    //   },
    // {
    //   title: this.props.t('depLabel'),
    //   content: <DependantsTab />,
    // },
    // {
    //   title: this.props.t('paymentsLabel'),
    //   content: '',
    // },
    // ];
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
          <div className="nurtem-card-design tutor-tab nurtem-table guest-flow-card-container mt-3">
            <div
              className="schedule-content"
              style={{ position: 'relative', zIndex: '1' }}
            >
              <div className="full-width">
                <div className="guest-flow-steps-title">
                  Guest checkout
                </div>
                <div className="full-width">
                  {/* <Steps current={current}>
                    {steps.map((item) => (
                      <Step
                        key={item.title}
                        title={item.title}
                      />
                    ))}
                  </Steps>
                  <div className="steps-content">
                    {steps[current].content}
                  </div> */}
                  <ContactInfoTab />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  itemsExist: state.classes.addedCartItems,
  itemsDetails: state.classes.cartItemDetails,
  contentLoading: state.classes.contentLoading,
  cartItemLoading: state.classes.cartItemLoading,
  loggedInUserDetails: state.authentication,
  userProfileUpdate: state.users.updateUserProfileInfo,
  updatedTutorProfile: state.tutorProfile.ProfilesUpdateSuccess,
  loading: state.authentication.loading,
  step: state.users.guestUserFlowStep,
});

const mapDispatchToProps = (dispatch) => ({
  getCartItems: (data) => dispatch(action.getCartItems(data)),
  onUpdateUserProfileInfo: (request) => dispatch(userAction.updateUserProfileInfo(request)),
  onProfileUpdate: (requestPar) => dispatch(tutorActions.onProfileUpdate(requestPar)),
  onUserSignUp: (credentials) => dispatch(actions.signUpUser(credentials)),
  guestUserFlowStep: (request) => dispatch(userAction.guestUserFlowStep(request)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(GuestFlowScreen));
