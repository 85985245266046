import React from 'react'
import { Button, Select, Input } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import * as msgAction from '../../../store/actions/public/message'
import MessageForm from './message-form'
import GroupInfoForm from './group-info'
import * as commonAction from '../../../store/actions/tutor/common'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import { DEFAULTUSER } from '../../../utils/config/assets'
import css from '../../../utils/CSSUtility'
import _ from 'lodash'

dayjs.extend(timezone)
let ipapi = {}

class TutorMessage extends React.Component {
  constructor(props) {
    super(props)
    this.myMessageRef = React.createRef()
    this.state = {
      chatList: [],
      messageText: '',
      visible: false,
      message: '',
      searchValue: '',
      selectedChatGroup: null,
      selectedChat: null,

    }
  }
  showModal = () => {
    this.props.showMessageForm(true);
  };

  showModalInfo = (data) => {
    const datas = {
      id: data,
      loading: true,
    };
    this.props.showGroupInfoForm(datas);
  };

  componentDidMount() {
    ipapi = _.get(this, 'props.searchLocation');
    const requestParams = {
      provider: this.props.tutorBaseProfile.details.id,
      sort: 'updated_at.desc',
      sec: Math.random(),
    };
    this.props.onGetRecentMessages(requestParams);
  }

  componentDidUpdate(prevprops) {
    try {
      // const { location } = window;
      // const searchParams = new URLSearchParams(location.search);
      // const runFunction = searchParams.get('message');
      // if (runFunction === 'true') {
      //   const scrollHeight = this.myMessageRef?.current?.scrollHeight || 100;

      //   if (prevprops.updateMessage !== this.props.updateMessage) {
      //     if (this.props.updateMessage.status === 200) {
      //       this.setState({ message: '' });
      //       this.scrollToHeight(scrollHeight, 2000);
      //     }
      //   }

      //   if (prevprops.individualMessages.items?.length !== this.props.individualMessages.items?.length) {
      //     this.scrollToHeight(scrollHeight, 1000);
      //   }
      // }
    } catch (error) {
      console.log('error at tutor messages,componentDidUpdate', error);
    }
  }

  scrollToHeight = (height, delay) => {
    setTimeout(() => {
      if (height) {
        window.scrollTo({ top: 150, behavior: 'smooth' });
        this.myMessageRef.current.scrollTop = height;
      }
    }, delay);
  };

  thanimsg = (key, value) => {
    try {

      //const msgs = this.props.recentchatlistuser.items;
      //if (value.id && value.user) {
      // const msg = msgs.find((v) => v.id === value.id);
      // const msgobj = msg.user;
      // const msgItems = msgobj.messages_list;
      // const msgUnreadCount = Array.isArray(msgItems) && msgItems.filter((item) => item.type === 'user').length;

      // if (msgUnreadCount) {
      //   let msgReadCount = 0;
      //   if (msgobj.read_count) msgReadCount = msgobj.read_count;
      //   let unread_count = msgUnreadCount - msgReadCount;
      //   if (unread_count > 4) unread_count -= 4;
      //   unread_count += msgReadCount;
      //   const requestParams = {
      //     id: value.id,
      //     read_count: unread_count,
      //   };
      //   this.props.onUpdateData(requestParams);
      // } else {
      //   const requestParams = {
      //     id: value.id,
      //     read_count: msgobj.messages,
      //   };
      //   this.props.onUpdateData(requestParams);
      // }
      //}

      this.setState({ chatList: value });
      this.setState({ selectedChatGroup: key });
      // const scrollHeight = this.myMessageRef?.current?.scrollHeight || 200;
      // this.scrollToHeight(scrollHeight, 1000);
    } catch (error) {
      console.log('error at tutor messages,thanimsg', error);
    }
  };

  searchrecentmsg = (key, rList) => {
    const selectedList = rList[key];
    this.thanimsg(key, selectedList)
  };

  handleSendMessage = (e, rList) => {

    console.log(">>>>>>>>>>>handleMessageChange", rList)

    e.preventDefault();
    if (e.shiftKey || e.metaKey || e.ctrlKey || e.altKey) {
      // if any modifier key is pressed, ignore the keypress
      return;
    }

    const { message, selectedChatGroup, selectedChat } = this.state;
    const selectedList = selectedChatGroup ? rList[selectedChatGroup] : rList[rList.length - 1];
    const selectedChatData = selectedChat ? selectedChat : selectedList[selectedList.length - 1];

    if (message.trim() !== '') {
      const requestParams = {
        id: selectedChatData.id,
        my_id: this.props.tutorBaseProfile.details.id,
        name:
          this.props.tutorBaseProfile.details.type === 'Business'
            ? this.props.tutorBaseProfile.details.businessname
            : this.props.tutorBaseProfile.details.firstname,
        type: 'tutor',
        text: message,
      };

      this.props.onUpdateMessages(requestParams);
      setTimeout(() => {
        const recent = this.props.recentchatlistuser
        const resentItemsCount = _.get(recent, 'items', []).length;
        const resentList = resentItemsCount > 0 ? this.filteredAndGroupedData(recent.items) : {}
        this.searchrecentmsg(selectedChatGroup, resentList)
        this.setState({ message: '' })
      }, 500)


    }
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  filteredAndGroupedData = (data) => data.reduce((acc, item) => {
    const { firstname } = _.get(item, 'user', {});

    if (!acc[firstname]) {
      acc[firstname] = [];
    }

    acc[firstname].push(item);

    return acc;
  }, {});


  makeMessage = (msg_type, userid, msgs) => {

    return msgs.map((msg, key) => {

      const timeConvert = timeDateConverterSession(
        msg.created_at,
        'date',
        'MMM DD,YYYY h:mm A'
      )
      const msgTime = dayjs(timeConvert)
      const currentTime = msgTime.format('hh:mm A')
      const currentDate = msgTime.format('MMM DD, YYYY')
      const displayDate = currentDate


      return msg.userid === userid ? (
        <div key={key} className="chat-message-right pt-4">
          <div>
            <img
              src={msg.photo ? msg.photo : DEFAULTUSER}
              className="rounded-circle mr-1"
              alt="User Img"
              width="40"
              height="40"
            />
          </div>
          <div className="media-body chat-message-right-inside mr-3">
            <div className="flex-shrink-1 mf-content-right bg-light py-2 px-3 mr-3">
              {' '}
              {msg.text}
            </div>
            <small className="mf-date-right mr-3">
              <i className="ntm-clock mr-1" />
              {currentTime}
              {displayDate && ` | ${displayDate}`}
            </small>
          </div>
        </div>
      ) : (
        <div key={key} className="chat-message-left pt-4">
          <div>
            <img
              src={msg.photo ? msg.photo : DEFAULTUSER}
              className="rounded-circle mr-1"
              alt="Tutor Img"
              width="40"
              height="40"
            />
          </div>
          <div className="media-body ml-3">
            {msg_type === 'g' ? (
              <div className="sender-name">
                <div className="ml-2">{msg.name}</div>
              </div>
            ) : (
              ''
            )}
            <div className="mf-content">{msg.text}</div>
            <small className="mf-date mr-3">
              <i className="ntm-clock mr-1" />
              {currentTime}
              {displayDate && ` | ${displayDate}`}
            </small>
          </div>
        </div>
      )
    })
  }
  render() {
    console.log(">>>>>>gbs>>>>>>>>>>")
    const tutor = this.props.tutorBaseProfile.details
    const user = this.state.chat
    const recent = this.props.recentchatlistuser
    const { selectedChatGroup } = this.state
    const resentItemsCount = _.get(recent, 'items', []).length;
    const resentList = resentItemsCount > 0 ? this.filteredAndGroupedData(recent.items) : {}
    const chatList = this.state.chatList
    const resentListKeys = Object.keys(resentList);
    return (
      <div className="nurtem-card-design p-0 mb-4 messgbox">
        <div className="row mesgbox">
          <div className="col-12 col-lg-5 col-xl-3 nurtem-card-design-message pr-0 border-right">
            <div style={css.heading1}>{this.props.t('recent')}</div>
            <div className="px-4 mt-3 mb-3 d-none d-md-block">
              <div className="search-recent">
                <Select
                  showSearch
                  placeholder={this.props.t('selectStudent')}
                  onSelect={(key) => this.searchrecentmsg(key, resentList)}
                >
                  {
                    _.size(resentList) > 0 &&
                    resentListKeys.map((key) => {
                      return (
                        <Select.Option value={key} key={key}>
                          {key}
                        </Select.Option>
                      )
                    })}
                </Select>
              </div>
            </div>
            <div className="chat-messages-left">
              {_.size(resentList) > 0 && resentListKeys.map((key) => {
                const selectedList = resentList[key];
                const value = selectedList[0];
                const isActive = selectedChatGroup === key;
                const readCount = _.sumBy(selectedList, (o) => o.user.read_count);
                const msgCount = resentItemsCount > 0 ? resentItemsCount - readCount : 0;
                const msgType = _.get(value, 'user.msg_type', '');
                const typeMsg = msgType === 'g' ? "Group" : '';

                return (
                  <div
                    key={key}
                    className={`list-group-item list-group-item-action border-0 ${isActive ? 'active' : ''}`}
                    onClick={() => this.thanimsg(key, selectedList)}
                  >
                    {msgCount > 0 && (
                      <sup
                        data-show="true"
                        className="ant-scroll-number ant-badge-count ant-badge-multiple-words"
                      >
                        {msgCount}
                      </sup>
                    )}
                    <div className="d-flex align-items-start">
                      <img
                        src={value.user?.photo || DEFAULTUSER}
                        className="rounded-circle mr-1"
                        alt="User"
                        width="40"
                        height="40"
                      />
                      {key && (
                        <div className="recent-text text-truncate ml-3">
                          <span className='msgerName m-0'>{key}</span>
                          {typeMsg && <span style={{ color: '#5b5b5b' }}>{typeMsg}</span>}
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}

            </div>
          </div>
          <div className="col-12 col-lg-7 col-xl-9">
            <div className="col-12 py-2 d-flex justify-content-between message-header">
              <div style={css.heading1}>{_.get(chatList, ['0', 'user', 'firstname'])}</div>

              <Button
                type="primary"
                onClick={() => this.showModal()}
                className="ant-btn"
              >
                <i className="ntm-envelope mr-2" />
                {this.props.t('sendMsg')}
              </Button>
            </div>

            {<div className="col-12 pl-0 pr-0 chat-messages">
              {
                chatList && chatList.map((chatval) => {
                  const userrid = _.get(tutor, ['id'], '')

                  const msgs = _.get(chatval, ['user', 'messages_list'], [])
                  const msgType = _.get(chatval, ['user', 'msg_type'], [])
                  const mkMsg = this.makeMessage(msgType, userrid, msgs);
                  return (
                    <div className="chat-messages-inner">
                      {mkMsg}
                    </div>)
                })}
            </div>}

            {this.state.selectedChatGroup && <div className="flex-grow-0 py-3 px-4 border-top">
              <div className="chat-footer">
                <Input.TextArea
                  className="send-message-text"
                  value={this.state.message}
                  onChange={(e) => this.handleMessageChange(e, resentList)}
                  onPressEnter={(e) => this.handleSendMessage(e, resentList)}
                />
                <button
                  type="button"
                  className="send-message-button btn-info"
                  onClick={(e) => this.handleSendMessage(e, resentList)}
                >
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-send"
                    viewBox="0 0 16 16"
                  >
                    {' '}
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />{' '}
                  </svg>
                </button>
              </div>
            </div>}

          </div>
        </div>
        <GroupInfoForm />
        <MessageForm />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  searchLocation: state.search.searchLocation,
  recentchatlistuser: state.message.recentchatlistuser,
  updateMessage: state.message.updateMessage,
  individualMessages: state.message.individualMessages,
})

const mapDispatchToProps = (dispatch) => ({
  showMessageForm: (data) => dispatch(commonAction.show_element(data)),
  showGroupInfoForm: (data) => dispatch(commonAction.show_group_info(data)),
  onGetRecentMessages: (requestParams) =>
    dispatch(msgAction.recentMessages(requestParams)),
  onUpdateMessages: (requestParams) =>
    dispatch(msgAction.onUpdateUserMessages(requestParams)),
  onIndividualMessages: (requestParams) =>
    dispatch(msgAction.indvidualMessages(requestParams)),
  onProviderList: (requestParams) =>
    dispatch(msgAction.getProviderList(requestParams)),
  onUpdateData: (requestParams) =>
    dispatch(msgAction.onUpdateUserData(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorMessage))
