import React from 'react';
import { withTranslation } from 'react-i18next';
import EmptyAnime from '../../components/re-usable/lottie/EmptyAnime';

function MaintenanceLottie(props) {
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '8%',
    }}
    >
      <EmptyAnime AnimeType="maintenance" loop animeheight={300} />
      <h3 style={{ fontWeight: 600 }}>{props.t('maint')}</h3>
    </div>
  );
}

export default withTranslation()(MaintenanceLottie);
