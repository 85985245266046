import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Rate, Anchor, FloatButton, Spin } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

import SearchImg from '../../../assets/images/Search-card-img.jpg'
import DefaultImg from '../../../assets/images/default-user.png'
import { algebra, chess, maths, python, speaking, swim } from '../../../utils/config/assets.js'

import './login.css'
import history from '../../../utils/history.js'
import { SearchInput } from '../../../components/antd-components/index.tsx'
import css from '../../../utils/CSSUtility'
import LandingCard from './landing-card.js'
import * as landpageActions from '../../../store/actions/public/Services'
import * as searchAction from '../../../store/actions/public/search/landing-list.js'

const LandingNew = React.memo((props) => {
  const {
    tutorRateData,
    classlist,
    camplist,
    complist,
    eventlist,
    getTutorRateList,
    onGetClassesList,
    requestParams,
  } = props

  const [loading, setLoading] = useState(true)

  const tutorlist = _.get(tutorRateData, 'items', [])
  const classList = _.get(classlist, 'items', [])
  const campList = _.get(camplist, 'items', [])
  const compList = _.get(complist, 'items', [])
  const eventList = _.get(eventlist, 'items', [])

  useEffect(() => {
    const fetchData = async () => {
      await getTutorRateList()

      const fetchLists = (serviceName, searchType) => {
        const reqParams = {
          ...requestParams,
          serviceName,
          searchType,
          limit: 10,
          page: 1,
        }
        return onGetClassesList(reqParams)
      }

      await Promise.all([
        fetchLists('class', 'class'),
        fetchLists('camp', 'camp'),
        fetchLists('competition', 'competition'),
        fetchLists('event', 'event')
      ])

      setLoading(false)
    }

    fetchData()
  }, [getTutorRateList, onGetClassesList, requestParams])

  const triggerSearch = useCallback((value) => {
    if (value) {
      history.push(`/search/${value}`)
    }
  }, [])

  const renderCategoryCard = useCallback(
    ({ to, src, alt, text }) => (
      <div className="category-card">
        <Link to={to}>
          <div className="overlay-land"></div>
          <img
            src={src}
            alt={alt}
            height={170}
            width={200}
            style={{ borderRadius: 15 }}
          />
        </Link>
        <div className="banner-white">
          <span className="span-text">{text}</span>
        </div>
      </div>
    ),
    []
  )

  const renderTutorCard = useCallback(
    ({ tutor, index }) => (
      <div key={index} style={{ maxWidth: '300px', padding: '20px' }}>
        <div className="row-tutor mt-3">
          <img
            src={tutor.photo || DefaultImg}
            width={80}
            height={80}
            alt="nurtem-logo"
            style={{ borderRadius: '50px' }}
          />
        </div>
        <div className="row-tutor mt-3">
          <Rate value={tutor.average_rating} />
          <span>{tutor.rating_count}</span>
        </div>
        <div className="row-tutor mt-3">
          <Link to={`/profile/${tutor.user_handle}`} target="_blank">
            <span className="tutorName">
              {tutor.businessname || tutor.class_teacher}
            </span>
          </Link>
        </div>
        <div className="row-tutor mt-3">
          <span className="tutorDesp">
            {tutor.bio.length > 120
              ? `${tutor.bio.substring(0, 120)}...`
              : tutor.bio}
          </span>
        </div>
      </div>
    ),
    []
  )

  const anchorItems = useMemo(
    () => [
      {
        key: 'part-1',
        href: '#part-1',
        title: <span className='anc-title'>Classes</span>,
      },
      {
        key: 'part-2',
        href: '#part-2',
        title: <span className='anc-title'>Camp</span>,
      },
      {
        key: 'part-3',
        href: '#part-3',
        title: <span className='anc-title'>Competition</span>,
      },
      {
        key: 'part-4',
        href: '#part-4',
        title: <span className='anc-title'>Event</span>,
      },
    ],
    []
  )

  return (
    loading ? <Spin size="large" fullscreen /> :
      <>
        <FloatButton.BackTop visibilityHeight={400} />

        {window.innerWidth >= 650 && (
          <>
            <div>
              <img
                src={SearchImg}
                alt="nurtem-logo"
                style={{ maxWidth: '100%', height: 'auto' }}
                loading="lazy"
              />
            </div>
            <div className="landSearch">
              <span className="land-title">
                Discover Top-Rated Classes, Camps, and Events
              </span>
              <SearchInput
                className="userSearch"
                onSearch={triggerSearch}
                placeholder="Search class, camps, events, etc"
                style={{ width: '100%' }}
              />
            </div>
          </>
        )}

        <div className="paddingStyle" style={{ minHeight: 100 }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: '20px', marginTop: '40px' }}>
              <span style={css.heading1}>Popular Category</span>
            </div>
            <div className="link-container">
              {renderCategoryCard({
                to: '/search/maths',
                src: maths,
                alt: 'class-background',
                text: 'Maths',
              })}
              {renderCategoryCard({
                to: '/search/speak',
                src: speaking,
                alt: 'class-background',
                text: 'Speaking',
              })}
              {renderCategoryCard({
                to: '/search/chess',
                src: chess,
                alt: 'class-background',
                text: 'Chess',
              })}
              {renderCategoryCard({
                to: '/search/swim',
                src: swim,
                alt: 'class-background',
                text: 'Swimming',
              })}
              {renderCategoryCard({
                to: '/search/algebra',
                src: algebra,
                alt: 'class-background',
                text: 'Algebra',
              })}
              {renderCategoryCard({
                to: '/search/Python',
                src: python,
                alt: 'class-background',
                text: 'Python',
              })}
            </div>
          </div>
        </div>

        <div className="paddingStyle">
          <span className='mostpop'>Most Popular</span>
          <Anchor
            direction="horizontal"
            items={anchorItems}
            style={{ marginTop: '55px', backgroundColor: 'white' }}
          />
        </div>

        <div className="paddingStyle2">
          {[
            { title: 'Popular Classes', id: 'part-1', list: classList },
            { title: 'Popular Camps', id: 'part-2', list: campList },
            { title: 'Popular Competition', id: 'part-3', list: compList },
            { title: 'Popular Event', id: 'part-4', list: eventList },
          ].map(({ title, id, list }, idx) => (
            <React.Fragment key={id}>
              <div
                style={{
                  marginBottom: '20px',
                  marginTop: idx === 0 ? '0px' : '30px',
                }}
              >
                <span style={css.header}>{title}</span>
              </div>
              <div id={id} className="land-card-container">
                {list.map((value, index) => (
                  <LandingCard key={`${id}_${index}`} index={index} value={value} />
                ))}
              </div>
            </React.Fragment>
          ))}
        </div>

        <div className="paddingStyle">
          <span style={css.heading1}>Most Popular Tutors</span>
          <div className="row">
            <div className="toprated-tutors">
              {tutorlist.map((tutor, index) => renderTutorCard({ tutor, index }))}
            </div>
          </div>
        </div>
      </>
  )
})

const mapStateToProps = (state) => ({
  tutorRateData: state.services.tutorRateData,
  classlist: state.search.classList,
  camplist: state.search.campList,
  complist: state.search.compeList,
  eventlist: state.search.eventList,
})

const mapDispatchToProps = {
  getTutorRateList: landpageActions.onGetTutorRateList,
  onGetClassesList: searchAction.onGetClassList,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(LandingNew))
