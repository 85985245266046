import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  AiOutlineSearch,
  AiFillCheckCircle,
  AiTwotoneDelete,
  AiTwotoneEdit,
  AiFillInfoCircle,
  AiOutlinePlus,
} from 'react-icons/ai'
import {
  Modal,
  Radio,
  Tooltip,
  Form,
  Checkbox,
  InputNumber,
  Select,
  Spin,
  Tag,
  Input,
  Button,
  Upload,
  message,
  TimePicker,
  notification,
} from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'
import dayjs from 'dayjs'
import queryString from 'query-string'
import axios1 from 'axios'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { withTranslation } from 'react-i18next'
import baseProfileFormMeta from './form-meta'
import * as commonActions from '../../../../../store/actions/common'
import generalAction from '../../../../../store/general/action'
import * as action from '../../../../../store/actions/public/Classes'
import { isGeneralPropertyLoadingInProgress } from '../../../../../store/general/settings'
import generalActionTypes from '../../../../../store/general/actionTypes'
import { NurtemUtils } from '../../../../../utils'
import * as actions from '../../../../../store/actions/tutor'
import axios from '../../../../../utils/axios'
import { globalVariable } from '../../../../../utils/config/config'
import AiDescriptionGenerator from '../../../../../components/common/AiDescriptionGenerator'
import PlanSubscriptionForm from '../../../../user/user-cart/PlanSubscriptionForm'
import NoCostPlanSubscriptionForm from '../../../../user/user-cart/NoCostPlanSubscriptionForm'

import TextArea from 'antd/es/input/TextArea'
import css from '../../../../../utils/CSSUtility'

const { Option } = Select
const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

function BaseProfileTab(props) {
  
  const [skuAddons, setSkuAddons] = useState([])

  
  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(baseProfileFormMeta)
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({})
  // Declare a new state variable, which we'll call "serviceList"
  const [serviceList, setServiceList] = useState([])
  // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false)
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false)
  
  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm()
 

  // const multiCampsRef = React.createRef();
  const [businessTutor, setBusinessTutor] = useState(false)


  const [openPaymentModel, setToOpenPaymentModel] = useState(false)

  const [openCashlessModel, setToOpenCashlessModel] = useState(false)

  const [paymentData, setPaymentData] = useState(0)

  const [intent_clientSecret, setIntentClientSecret] = useState(false)

  const handleCancel = () => {
    // handle cancel payment
    setToOpenPaymentModel(false)
  }

  const handleCancel2 = () => {
    // handle cancel payment
    setToOpenCashlessModel(false)
  }

  useEffect(() => {
    const abortController = new AbortController() // Create an instance of AbortController

    commonActions.makRequest('/sku/addons', setSkuAddons)

    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          setBaseProfileFormFields(_.get(data, 'details', {}))
        }
      },
    })

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    setCurrentTabModified()

    // No need for cleanup here, but adding for consistency
    return () => {}
  }, [canUpdateForm])

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => {}))) {
      props.setCurrentTabModified(canUpdateForm)
    }
  }

  // const getServicesList = (params = {}) => {
  //   // if the end point in progress then we should avoid the api call
  //   if (
  //     !isGeneralPropertyLoadingInProgress(
  //       _.get(props, 'services'),
  //       generalActionTypes.services.list
  //     )
  //   ) {
  //     // fetching nurtem service list from api
  //     props.getServicesList({
  //       params,
  //       callback: (success, data) => {
  //         if (success) {
  //           setServiceList(_.get(data, 'items', []))
  //         }
  //       },
  //     })
  //   }
  // }

  const setBaseProfileFormFields = (tutor_profile_details) => {
    const formProperties = _.pick(tutor_profile_details, [
    ])

    const plans = _.get(
      tutor_profile_details,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    if (!_.isEmpty(plans)) {
      formProperties.plans = plans
    }


    const service_labels = []
 
 
    form.setFieldsValue(formProperties)
    setTutorProfile(tutor_profile_details)
 
  }



  const processFormData = (profileDetails) => {
    profileDetails = _.clone(profileDetails)
    // profileDetails.bio = description;
    return profileDetails
  }

  const [textAreaCount, ChangeTextAreaCount] = React.useState(0)
  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length)
  }



  const radioStyle = {
  }


  const mainComponent = () => {
    //console.log('profile>>>', tutorProfile)

    const selectedPlan = _.get(
      tutorProfile,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    const alreadyNotInAPlan = _.isEmpty(selectedPlan)

    const profile_type = _.get(tutorProfile, 'profile_type', '')
    const plStr = alreadyNotInAPlan
      ? 'Please select a plan'
      : `You are curently on "${selectedPlan}", you can change the plan.`

    const plan_rule = alreadyNotInAPlan
      ? {
          name: 'plans',
          rules: [
            {
              required: true,
              message: 'Please select which type of plan you want.',
            },
          ],
        }
      : {
          name: 'plans',
        }

    return (
      <div className="my-profile-tabPane-container">
        <div className="row" style={{ width: '100%', margin: 0 }}>
          <div className="col-12 mb-3">
            <div className="business-information">
              <Form
                form={form}
                onFinish={() => {}}
                scrollToFirstError
                onValuesChange={() => {}}
              >
                <div className="row business-information mt-4">
                  {profile_type === 0 && (
                    <div className="col-8 mb-4">
                      <Tooltip title={"Addons"}>
                        <label
                          className="filter-group-title mb-3"
                          style={{ width: 'auto' }}
                        >
                          {"Addons"}
                          {alreadyNotInAPlan && (
                            <span className="label-star">*</span>
                          )}
                        </label>
                      </Tooltip>
                      <div className="full-width">
                        <Form.Item {...plan_rule}>
                            {_.map(skuAddons, (item, key) => (
                              <Checkbox
                                onChange={(event) => {
                                  //detect the plan
                                  //console.log("event>>>", event.target.item)
                                  // let plan = event.target.value
                                  // let item = event.target.item
                                  // let price = _.get(
                                  //   event,
                                  //   ['target', 'item', 'price'],
                                  //   0
                                  // )

                                  // if (price > 0) {
                                  //   setIntentClientSecret(false)
                                  //   //popup for payment
                                  //   setToOpenPaymentModel(true)
                                  //   setToOpenCashlessModel(false)
                                  //   let paymentIntent_data = {
                                  //     provider_type: profile_type,
                                  //     payment_data: {
                                  //       login_type: 'provider',
                                  //       subscription_date: new Date(),
                                  //       collection_method: 'subscription', //make it default we can change on demand
                                  //       payment_method: 'ach',
                                  //       cart_items: item, //item refers plan details
                                  //     },
                                  //     amount: _.round(price * 100, 0),
                                  //   }

                                  //   setPaymentData(paymentIntent_data)

                                  //   props.stripePaymentIntent(
                                  //     paymentIntent_data,
                                  //     (data) => {
                                  //       try {
                                  //         if (data.clientSecret) {
                                  //           setIntentClientSecret(
                                  //             data.clientSecret
                                  //           )
                                  //         } else {
                                  //           console.log(
                                  //             'error> at /stripe/create-payment-intent cart.js',
                                  //             data.error
                                  //           )
                                  //         }
                                  //       } catch (error) {
                                  //         console.log(
                                  //           'error> at /stripe/create-payment-intent cart.js'
                                  //         )
                                  //       }
                                  //     }
                                  //   )
                                  // } else {
                                  //   //free plan
                                  //   setToOpenCashlessModel(true)
                                  //   setToOpenPaymentModel(false)
                                  //   let paymentIntent_data = {
                                  //     provider_type: profile_type,
                                  //     payment_data: {
                                  //       login_type: 'provider',
                                  //       subscription_date: new Date(),
                                  //       collection_method: 'subscription', //make it default we can change on demand
                                  //       payment_method: 'ach',
                                  //       cart_items: item, //item refers plan details
                                  //     },
                                  //     amount: 0,
                                  //   }

                                  //   setPaymentData(paymentIntent_data)
                                  // }
                                  // force re-rendering
                                  //setLocalState({ ...localState })
                                }}
                                style={radioStyle}
                                item={item}
                                value={_.get(item, 'value', '')}
                                key={key}
                              >
                                {_.get(item, 'label', '')}
                              </Checkbox>
                            ))}
                          </Form.Item>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Scrollbars
        className="tutor-onboarding-scroll-height hidden-scroll-x"
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            className="track-horizontal"
            style={{ display: 'none' }}
          />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            className="thumb-horizontal"
            style={{ display: 'none' }}
          />
        )}
      >
        <Spin spinning={loadSpin}>{mainComponent()}</Spin>
      </Scrollbars>
      <Modal
        title="Payment Powered by Stripe"
        open={openPaymentModel}
        onCancel={handleCancel}
        footer={null}
      >
        {intent_clientSecret ? (
          <Elements
            options={{
              clientSecret: intent_clientSecret,
              theme: 'stripe',
            }}
            stripe={loadStripe(globalVariable.STRIPE_PUBLISHABLE_KEY)}
          >
            {paymentData && (
              <PlanSubscriptionForm
                paymentIntent_data={paymentData}
                showACHModal={setToOpenPaymentModel}
                clientSecret={intent_clientSecret}
                onCreateTransaction={props.onCreateTransaction}
              />
            )}
          </Elements>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </Modal>

      <Modal
        title="Cashless Plan"
        open={openCashlessModel}
        onCancel={handleCancel2}
        footer={null}
      >
        <NoCostPlanSubscriptionForm
          paymentIntent_data={paymentData}
          showACHModal={setToOpenCashlessModel}
          clientSecret={''}
          onCreateTransaction={props.onCreateTransaction}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}),
  services: _.get(state, `general.${generalActionTypes.services.name}`, {}),
  loggedInUserDetails: state.authentication,
  tutorProfileDetails: _.get(
    state,
    'tutorProfile.tutorBaseProfile.details',
    {}
  ),
})

const mapDispatchToProps = (dispatch) => ({
  onCreateTransaction: (details) =>
    dispatch(commonActions.onCreateTransaction(details)),

  // onAiGenerateText: (requestParams, callback) =>
  //   dispatch(commonActions.onAiGenerateText(requestParams, callback)),
  stripePaymentIntent: (data, callback) =>
    dispatch(action.stripe_payment_intent(data, callback)),
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) =>
    dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) =>
    dispatch(generalAction.tutor.updateProfile(details)),

  updateProfileImage: (details) =>
    dispatch(generalAction.tutor.updateProfileImage(details)),
  removeProfileImage: (details) =>
    dispatch(generalAction.tutor.removeProfileImage(details)),
  getTutorProfile: (details) =>
    dispatch(generalAction.tutor.getProfile(details)),
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BaseProfileTab))
