import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateTutorState;
// tutor actions

export default {
  // get profile data of the current logged in tutor
  getProfile: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/providers/profile',
      },
      actionType: actionType.tutor.profile,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // update provider handle
  updateHandle: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/handle',
      },
      actionType: actionType.tutor.handle,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // Provider transfer
  providerTransfer: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/transfer-account',
      },
      actionType: actionType.tutor.handle,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  tutorList: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/providers/list',
      },
      actionType: actionType.business.list,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  searchTutorList: (componentRequest) => {
    console.log('List', componentRequest);
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: 'get',
          url: '/providers/list',
        },
        actionType: actionType.business.searchTutorList,
        reducerName,
        dispatch,
        componentRequest,

      };
      requestMiddleware(requestOptions);
    };
  },
  createProvider: (componentRequest) => {
    console.log('List', componentRequest);
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: 'post',
          url: '/business/providers/create',
        },
        actionType: actionType.business.create,
        reducerName,
        dispatch,
        componentRequest,

      };
      requestMiddleware(requestOptions);
    };
  },
  viewProvider: (componentRequest) => {
    console.log('List', componentRequest);
    return (dispatch) => {
      const requestOptions = {
        axiosParams: {
          method: 'get',
          url: '/business/providers/view',
        },
        actionType: actionType.business.view,
        reducerName,
        dispatch,
        componentRequest,

      };
      requestMiddleware(requestOptions);
    };
  },
  // update provider details
  updateProfile: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/update',
      },
      actionType: actionType.tutor.profileUpdate,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // update provider details
  updateBusinessProfile: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/businessprofile',
      },
      actionType: actionType.tutor.businessProfileUpdate,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // update provider gallery image
  updateGalleryImage: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/createGallery',
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      },
      actionType: actionType.tutor.profileGallery,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // update provider profile image
  updateProfileImage: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/update-photo',
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      },
      actionType: actionType.tutor.businessProfileUpdate,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // remove provider profile image
  removeProfileImage: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/remove-profile-image',
      },
      actionType: actionType.tutor.businessProfileUpdate,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // update provider cover image
  updateCoverImage: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/update-coverpic',
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      },
      actionType: actionType.tutor.coverImage,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // remove provider cover image
  removeCoverImage: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/providers/photo-remove',
      },
      actionType: actionType.tutor.coverImage,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
};
