import React from 'react';
import { Table } from 'antd';

import './style.css';

const genList = (props) => {
  const {
    title, data, col, selectionType, rowSelection,
  } = props;
  return (
    <div className="full-width">
      <div className="mb-3">
        <div className="table-title">{title}</div>
      </div>
      <Table
        dataSource={data}
        columns={col}
        pagination
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default genList;
