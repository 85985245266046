import React, { useState, useEffect } from 'react'
import { Button, Modal, Tabs, Tooltip } from 'antd'
import {
  AiOutlineForm,
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineShareAlt,
} from 'react-icons/ai'
import { connect } from 'react-redux'
import '../tutor-camps/user-new-my-class.css'
import _ from 'lodash'
import { RWebShare } from 'react-web-share'
import queryString from 'query-string'
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'
import SessionList from './session-list'
import { CLASSPNG } from '../../../utils/config/assets'
import * as action from '../../../store/actions/tutor/Classes'
import * as routeName from '../../../components/route-names'
import history from '../../../utils/history'
import FlyerPreview from '../../../components/common/gallery'
import { FullDetailsLoader } from '../../../components/loader/common/full-details-loader'
import ImageWithLoading from '../../../components/tutor/imageOf'
// import GoogleMaps from '../../../components/re-usable/maps/maps';
import viewUrl from '../../../utils/common/get-view-url'
import { toggleLeftMenu } from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'

const CampDetailedView = (props) => {
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [modalText, setModalText] = useState('Content of the modal')
  const urlparams = queryString.parse(window.location.search)
  const gid = _.get(urlparams, 'gid')
  const class_name = props.location.pathname.split('/')[3]
  const provId = _.get(urlparams, 'provId', '')

  function toTutorSessionDetails(record) {
    if (provId) {
      history.push(
        `${routeName.SessionViewClass}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}&provId=${provId}`
      )
    } else {
      history.push(
        `${routeName.SessionViewClass}/${encodeURIComponent(record.session_name)}/?gid=${gid}&sid=${record.id}`
      )
    }
  }
  const { onDeleteClass, onEditClass, classDetails } = props

  useEffect(() => {
    window.scrollTo(0, 0)
    props.onGetClassDetails({
      id: gid,
      expand: 'provider',
    })
    props.setToggleMenu(true)
  }, [])

  const render = () => {
    
    const cd = _.chain(classDetails)
    const flyers = cd.get('flyers').value()
    const image_back = cd.get('photo').value()
    const provider = cd.get('provider').value()

    const viewCampUrl = viewUrl(provider, cd.value(), 'class')

    const UseMatchMedia = (mediaQuery, initialValue) => {
      const [isMatching, setIsMatching] = useState(initialValue)
      useEffect(() => {
        const watcher = window.matchMedia(mediaQuery)
        setIsMatching(watcher.matches)
        const listener = (matches) => {
          setIsMatching(matches.matches)
        }
        if (watcher.addEventListener) {
          watcher.addEventListener('change', listener)
        } else {
          watcher.addEventListener(listener)
        }
        return () => {
          if (watcher.removeEventListener) {
            return watcher.removeEventListener('change', listener)
          }
          return watcher.removeEventListener(listener)
        }
      }, [mediaQuery])
      return isMatching
    }

    const isWebResolution = UseMatchMedia('(min-width:992px)', true)
    const handleOnClick = () => {
      if (navigator.share) {
        navigator
          .share({
            title: '`${postTitle} | ${siteTitle}`,',
            text: 'Check out',
            url: document.location.href,
          })
          .then(() => {
            console.log('Successfully shared')
          })
          .catch((error) => {
            console.error('Something went wrong sharing the blog', error)
          })
      }
    }
    const showModal = () => {
      setOpen(true)
    }
    const handleOk = () => {
      setConfirmLoading(true)
      onDeleteClass(_.get(classDetails, 'id'))
        .then(() => {
          setOpen(false)
          setConfirmLoading(false)
        })
        .catch((error) => {
          console.error('Error deleting class:', error)
          setConfirmLoading(false)
        })
    }

    const handleCancel = () => {
      setOpen(false)
    }

    return (
      <div className="nurtem-my-class-card m-2">
        <Tabs
          defaultActiveKey="1"
          tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
        >
          <items tab={props.t('batchDetail')} key="1">
            <SessionList
              groupID={gid}
              className={class_name}
              callback={toTutorSessionDetails}
            />
          </items>
          <items tab={props.t('classDetails')} key="2">
            {props.loadingClassDetails ? (
              <FullDetailsLoader />
            ) : (
              <>
                <div className="full-width detailsbox">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 pr-md-0">
                      <div className="class-name" style={{ paddingLeft: 25 }}>
                        {cd.get('name','').value()}
                      </div>
                      <ImageWithLoading
                        imageUrl={image_back}
                        defaultImageUrl={CLASSPNG}
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 pl-md-0 pb-3">
                      <div className="class-header">
                        <div className="row align-items-center pl-3">
                          <div className="col-12 col-sm-12 col-lg-8  col-xl-8">
                            
                          </div>
                          <div className="col-12 col-sm-12 col-lg-4  col-xl-4">
                            <div className="class-action">
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="View"
                              >
                                <Button
                                  href={viewCampUrl}
                                  target="_blank"
                                  className="delete"
                                  icon={<AiOutlineEye />}
                                />
                              </Tooltip>
                              {isWebResolution ? (
                                <div>
                                  <RWebShare
                                    data={{
                                      text: 'Class details page',
                                      url: window.location.origin + viewCampUrl,
                                      title: 'Share Class',
                                    }}
                                    onClick={() =>
                                      console.log(
                                        'shared successfully!',
                                        viewCampUrl
                                      )
                                    }
                                  >
                                    <Tooltip
                                      placement="bottom"
                                      arrow={false}
                                      overlayClassName="tooltipForIcon"
                                      title="Share"
                                    >
                                      <Button
                                        className="delete"
                                        icon={<AiOutlineShareAlt />}
                                      />
                                    </Tooltip>
                                  </RWebShare>
                                </div>
                              ) : (
                                <Button
                                  className="delete mt-1"
                                  onClick={handleOnClick}
                                  icon={<AiOutlineShareAlt />}
                                />
                              )}
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Delete"
                              >
                                <Button
                                  // onClick={() => onDeleteClass(_.get(classDetails, 'id'))}
                                  onClick={showModal}
                                  className="delete"
                                  icon={<AiOutlineDelete />}
                                />
                              </Tooltip>
                              <Modal
                                title="Are you sure to delete this Class?"
                                open={open}
                                onOk={handleOk}
                                okType="danger"
                                okText="Yes"
                                confirmLoading={confirmLoading}
                                onCancel={handleCancel}
                                cancelText="No"
                              />
                              <Tooltip
                                placement="bottom"
                                arrow={false}
                                overlayClassName="tooltipForIcon"
                                title="Edit"
                              >
                                <Button
                                  onClick={() =>
                                    onEditClass(_.get(classDetails, 'id'))
                                  }
                                  className="edit"
                                  icon={<AiOutlineForm />}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="nurtem-my-class-card mb-4">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          style={{ paddingLeft: 35, marginTop: 20 }}
                        >
                          {/* <div className='class-name'>
                        Description
                      </div> */}
                          <div>
                            {typeof _.get(classDetails, 'description') ===
                            'string'
                              ? parser(_.get(classDetails, 'description'))
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='nurtem-my-class-card mb-4'>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 m-3'>
                      <div className='class-name'>
                        Description
                      </div>
                      <>
                        <div dangerouslySetInnerHTML={{ __html: `${_.get(classDetails, 'description')}` }} />
                      </>
                    </div>
                  </div> */}
              </>
            )}
          </items>
          <items tab={props.t('gallery')} key="3">
            <FlyerPreview flyers={flyers} gid={gid} cat="classes" />
          </items>

          {/* <items tab='Google maps' key='4'>
            <GoogleMaps />
          </items> */}
        </Tabs>
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  sessions: state.sessions.sessions,
  classDetails: state.classesDetails.tutorClassDetails.details,
  loadingClassDetails: state.classesDetails.loadingClassDetails,
})

const mapDispatchToProps = (dispatch) => ({
  onGetClassDetails: (requestParams) =>
    dispatch(action.onGetClassDetails(requestParams)),
  setToggleMenu: (requestParams) =>
    dispatch(searchAction.common(toggleLeftMenu, requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CampDetailedView))
