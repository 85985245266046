import React from 'react';
import { connect } from 'react-redux';
import * as action from '../../../../store/actions/tutor/camps';
import _ from 'lodash';
import './student-list.css';
import { withTranslation } from 'react-i18next';
import Linkwrap from '../../component/linkwrap';

export function MeetingLink(props) {
  const cd = props.session;

  console.log('cd1===============', cd);
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      <div className="row py-3" style={{ width: '100%', gap: '20%' }}>
        <div style={{ fontSize: 14, marginLeft: 25 }}>
          Meeting Link :
          {' '}
          <Linkwrap link={cd.link} />
        </div>
        <div style={{ fontSize: 14, marginLeft: 25 }}>
          Meeting Code:
          {' '}
          {cd.code}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  sessionDetail: state.campsDetails.sessionDetail.data,
  loadingSession: state.campsDetails.loadingSession,
});
const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) => dispatch(action.onGetsessionDetail(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MeetingLink));
