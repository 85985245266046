import React from 'react';

export class ServicesLoader extends React.Component {
  render() {
    return (
      <div>
        <div className="row explore-inner-alignment-set explore-inner-placeholder">
          <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 explore-inner-image-col">
            <div className="explore-inner-image-set">
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
            </div>
            <div className="ViewMore" />
          </div>
          <div className="col-lg-7 col-md-6 col-sm-6 col-xs-12 explore-inner-set-content">
            <div className="text-center">
              <div className="line_1" />
              <div className="line_1" />
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
        <div className="row explore-inner-alignment-set explore-inner-placeholder">
          <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 explore-inner-image-col">
            <div className="explore-inner-image-set">
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
              <div className="explore-inner-images" />
            </div>
            <div className="ViewMore" />
          </div>
          <div className="col-lg-7 col-md-6 col-sm-6 col-xs-12 explore-inner-set-content">
            <div className="text-center">
              <div className="line_1" />
              <div className="line_1" />
              <div className="line_1" />
              <div className="line_2" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
