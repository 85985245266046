import * as actionTypes from '../../../actions/tutor/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
    loading: {
        loading: false,
    },
    loadingcompList: {
        loadingcompList: false,
    },
    CompetitionsStudentListLoading: false,
    EventDetails: {},
    tutorCompetitionRequestAction: {},
    tutorEventRequestAction: {},
    competitionStudents: {},
    eventStudents: {},
    providerEvents: [],
    otherProviderCompetitions: [],
    tutorCompetitionRequests: {},
    tutorEventRequests: {},
    deleteEventInfo: {},

};

const setTutorCompetitionRequests = (state, action) => ({ ...state, tutorCompetitionRequests: action.success });

const setTutorEventRequests = (state, action) => ({ ...state, tutorEventRequests: action.success });

const tutorCompetitionDetele = (state, action) => ({ ...state, deleteEventInfo: action.success });

const getEvents = (state, action) => ({ ...state, providerEvents: action.payload });

const otherProviderCompetitions = (state, action) => ({ ...state, otherProviderCompetitions: action.payload });

const enableLoading = (state, action) => updateObject(state, action.loading);

const disablecompListLoading = (state, action) => updateObject(state, action.loadingcompList);

const enablecompListLoading = (state, action) => updateObject(state, action.loadingcompList);

const disableLoading = (state, action) => updateObject(state, action.loading);

const CompetitionsStudentListLoading = (state, action) => updateObject(state, action.CompetitionsStudentListLoading);

const EventDetails = (state, action) => ({ ...state, EventDetails: action.success });

const tutorCompetitionRequestAction = (state, action) => ({ ...state, tutorCompetitionRequestAction: action.success });

const tutorEventRequestAction = (state, action) => ({ ...state, tutorEventRequestAction: action.success });

const competitionStudents = (state, action) => ({ ...state, competitionStudents: action.success });

const eventStudents = (state, action) => ({ ...state, eventStudents: action.success });

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.tutorCompetitionRequests:
            return setTutorCompetitionRequests(state, action);
        case actionTypes.tutorEventRequests:
            return setTutorEventRequests(state, action);
        case actionTypes.tutorCompetitionsDeleteFoundSuccess:
            return tutorCompetitionDetele(state, action);
        case actionTypes.tutorEventsDetailsFoundSuccess:
            return EventDetails(state, action);
        case actionTypes.tutorCompetitionRequestAction:
            return tutorCompetitionRequestAction(state, action);
        case actionTypes.tutorEventRequestAction:
            return tutorEventRequestAction(state, action);
        case actionTypes.providerEvents:
            return getEvents(state, action);
        case actionTypes.otherProviderCompetitions:
            return otherProviderCompetitions(state, action);
        case actionTypes.enableLoading:
            return enableLoading(state, action);
        case actionTypes.disableLoading:
            return disableLoading(state, action);
        case actionTypes.enableEventsLoading:
            return enablecompListLoading(state, action);
        case actionTypes.disableEventsLoading:
            return disablecompListLoading(state, action);
        case actionTypes.CompetitionsStudentListLoading:
            return CompetitionsStudentListLoading(state, action);
        case actionTypes.competitionStudents:
            return competitionStudents(state, action);
        case actionTypes.eventStudents:
            return eventStudents(state, action);
        default:
            return state;
    }
};

export default reducer;
