import React from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import { Tooltip, Form } from 'antd';
import { AiOutlineExclamationCircle, AiOutlineInfoCircle } from 'react-icons/ai';
import history from '../../../../../utils/history';
import * as routeName from '../../../../../components/route-names';
import middlewareLogic from './middleware-logic';

const CouponBlockLogic = (componentAssests) => {
  const canSwitchTabModal = _.get(componentAssests, 'externalProps.canSwitchTabModal.showModal', () => { });
  const middlewareLogicInstance = middlewareLogic(componentAssests);

  // console.log("t>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", componentAssests)
  const t = _.get(componentAssests, 't', (params) => { console.log('translation object is missiing in general-logic'); });

  const updateComponentDetails = (properties) => {
    const setComponentDetails = _.get(componentAssests, 'self.setComponentDetails', () => { });
    const componentDetails = _.get(componentAssests, 'self.componentDetails', {});
    setComponentDetails({
      ...componentDetails,
      ...properties,
    });
  };

  const getCurrentModeDetails = () => _.chain(componentAssests).get('self.config.modes', {}).find({ alias: _.get(componentAssests, 'self.componentDetails.mode', null) }).value();

  const getCurrentTypeDetails = () => _.chain(componentAssests).get('self.config.type', {}).find({ alias: _.get(componentAssests, 'self.componentDetails.type', null) }).value();

  const canSetCouponBlock = (callback) => {
    const currentModeDetails = getCurrentModeDetails();
    if (!_.isEmpty(currentModeDetails)) {
      canSwitchTabModal({
        title: 'Please confirm',
        content: (
          <Tooltip
            placement="right"
            title={(
              <span>
                {' '}
                <span className="tooltip-icon"><AiOutlineExclamationCircle style={{ fontSize: 20 }} /></span>
                {' '}
                <span className="tooltip-text">
                  {t('couponText4')}
                  {_.get(currentModeDetails, 'label', '')}
                  {' '}
                  {t('couponText5')}
                </span>
              </span>
                      )}
          >
            <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
              {_.get(currentModeDetails, 'label', '')}
              {' '}
              {t('couponText6')}
              {' '}
              <AiOutlineInfoCircle style={{ fontSize: '20px' }} />
            </label>
          </Tooltip>
        ),
      }, (success) => {
        if (success) {
          callback();
        }
      });
    } else {
      callback();
    }
  };

  const ExitCouponBlock = (props) => {
    const exitCouponBlockProps = {
      showConfirmModal: true,
      ...props,
    };

    const execCode = () => {
      const setComponentDetails = _.get(componentAssests, 'self.setComponentDetails', () => { });
      const defaultConfigDetails = _.get(componentAssests, 'self.config.details', {});
      setComponentDetails(defaultConfigDetails);
    };

    const currentModeDetails = getCurrentModeDetails();
    if (_.isEqual(_.get(exitCouponBlockProps, 'showConfirmModal'), true)) {
      canSwitchTabModal({
        title: 'Please confirm',
        content: (
          <Tooltip
            placement="right"
            title={(
              <span>
                {' '}
                <span className="tooltip-icon"><AiOutlineExclamationCircle style={{ fontSize: 20 }} /></span>
                {' '}
                <span className="tooltip-text">
                  {t('couponText7')}
                  {' '}
                  {_.get(currentModeDetails, 'label', '')}
                  {t('couponText8')}
                </span>
              </span>
                      )}
          >
            <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
              {_.get(currentModeDetails, 'label', '')}
              {' '}
              {t('couponText9')}
              {' '}
              <AiOutlineInfoCircle style={{ fontSize: '20px' }} />
            </label>
          </Tooltip>
        ),
      }, (success) => {
        if (success) {
          history.push(routeName.myCoupons);
          execCode();
        }
      });
    } else {
      history.push(routeName.myCoupons);
      execCode();
    }
  };

  const validateEngine = (criteria) => {
    const initialCondition = _.overEvery([_.isObjectLike, (value) => !_.isArray(value)]);
    if (initialCondition(criteria)) {
      const availableConditions = {
        coupon_type: (values) => _.includes(values, _.get(componentAssests, 'self.componentDetails.type', null)),
        coupon_mode: (values) => _.includes(values, _.get(componentAssests, 'self.componentDetails.mode', null)),
      };
      const filteredCriteria = _.pick(criteria, _.keys(availableConditions));
      if (_.isEmpty(criteria)) {
        return true;
      }
      return !_.chain(filteredCriteria).filter((item, key) => availableConditions[key](item)).isEmpty().value();
    }
    return false;
  };

  const renderEngine = (criteria) => {
    if (validateEngine(_.get(criteria, 'config.render_on', null))) {
      const formItem = _.get(criteria, 'config.form_item', {});
      formItem.rules = _.chain(formItem).get('rules', []).concat({
        required: validateEngine(_.get(criteria, 'config.required_on', null)),
        message: 'This field is required.',
      }).value();
      const element_properties = _.get(criteria, 'config.element_properties', {});
      element_properties.disabled = validateEngine(_.get(criteria, 'config.disable_on', null));
      const buildFormField = (
        <>
          <label className="filter-group-title mb-3">
            {_.get(criteria, 'config.label', '')}
            {' '}
            {
                            validateEngine(_.get(criteria, 'config.required_on', null)) ? <span className="label-star">*</span> : ''
                        }
          </label>
          <div className="full-width">
            <Form.Item {...formItem}>
              {_.get(criteria, 'element', () => { })(element_properties)}
            </Form.Item>
          </div>
        </>
      );
      return _.get(criteria, 'wrapper', () => { })(buildFormField);
    }
  };

  const generateRandomCoupon = (props) => {
    const randomCouponProps = {
      length: 8,
      form_field: null,
      ...props,
    };
    let coupon = '';
    do {
      coupon = _.times(_.chain(randomCouponProps).get('length').toNumber().value(), () => _.random(35).toString(36)).join('').toUpperCase();
    } while (_.isEmpty(coupon.match(/[0-9]/)));
    const setFieldsValue = _.get(componentAssests, 'self.couponBlockFormInstance.setFieldsValue', () => { });
    setFieldsValue({ [randomCouponProps.form_field]: coupon });
  };

  const resetAll = () => {
    const resetFields = _.get(componentAssests, 'self.couponBlockFormInstance.resetFields', () => { });
    const type = _.get(componentAssests, 'self.config.type.general_discount', {});
    const setFieldsValue = _.get(componentAssests, 'self.couponBlockFormInstance.setFieldsValue', () => { });
    const currentModeDetails = getCurrentModeDetails();

    canSwitchTabModal({
      title: 'Please confirm',
      content: (
        <Tooltip
          placement="right"
          title={(
            <span>
              {' '}
              <span className="tooltip-icon"><AiOutlineExclamationCircle style={{ fontSize: 20 }} /></span>
              {' '}
              <span className="tooltip-text">
                {t('couponText10')}
                {' '}
                {_.get(currentModeDetails, 'label', '')}
                {' '}
                {t('couponText8')}
              </span>
            </span>
                  )}
        >
          <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
            {t('couponText11')}
            {' '}
            <AiOutlineInfoCircle style={{ fontSize: '20px' }} />
          </label>
        </Tooltip>
      ),
    }, (success) => {
      if (success) {
        resetFields();
        setFieldsValue({
          [_.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name', '')]: type,
        });
      }
    });
  };

  const onFormFinish = (values) => {
    const form_item_type_selected = _.get(componentAssests, 'self.componentDetails.type', '');
    const form_item_type = _.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name', '');
    const typeObj = _.get(componentAssests, 'self.config.type', {});

    if (_.has(values, form_item_type)) {
      const form_item_amount_type = _.get(componentAssests, 'self.formDetails.fields.coupon_value_type.form_item.name', '');
      values[form_item_amount_type] = _.chain(typeObj).get(`${form_item_type_selected}.amount_type.value`).toNumber().value();
      values[form_item_type] = _.chain(typeObj).get(`${form_item_type_selected}.value`).toNumber().value();
    }
    const form_item_start_date = _.get(componentAssests, 'self.formDetails.fields.coupon_valid_from.form_item.name', '');
    if (_.has(values, form_item_start_date)) {
      values[form_item_start_date] = dayjs(values[form_item_start_date]).format('MM-DD-YYYY');
    }
    const form_item_end_date = _.get(componentAssests, 'self.formDetails.fields.coupon_expiry_date.form_item.name', '');
    if (_.has(values, form_item_end_date)) {
      values[form_item_end_date] = dayjs(values[form_item_end_date]).format('MM-DD-YYYY');
    }

    if (_.isEqual(_.get(componentAssests, 'self.componentDetails.mode', null), _.get(componentAssests, 'self.config.modes.addCoupon.alias', null))) {
      middlewareLogicInstance.createCoupon(values);
    } else if (_.isEqual(_.get(componentAssests, 'self.componentDetails.mode', null), _.get(componentAssests, 'self.config.modes.updateCoupon.alias', null))) {
      middlewareLogicInstance.updateCoupon(values);
    }
  };

  return {
    updateComponentDetails,
    canSetCouponBlock,
    getCurrentModeDetails,
    getCurrentTypeDetails,
    ExitCouponBlock,
    renderEngine,
    generateRandomCoupon,
    resetAll,
    onFormFinish,
  };
};

export default CouponBlockLogic;
