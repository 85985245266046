export const loading = 'loading';
export const sessions = 'sessions';
export const enableLoading = 'enableLoading';
export const disableLoading = 'disableLoading';

export const serviceFoundSuccess = 'serviceFoundSuccess';
export const serviceSeachFoundSuccess = 'serviceSeachFoundSuccess';
export const enableSearchLoading = 'enableSearchLoading';
export const disableSearchLoading = 'disableSearchLoading';
export const enableContentLoading = 'enableContentLoading';
export const disableContentLoading = 'disableContentLoading';
export const enableCartContentLoading = 'enableCartContentLoading';
export const disableCartContentLoading = 'disableCartContentLoading';
export const enableLoadMoreLoading = 'enableLoadMoreLoading';
export const disableLoadMoreLoading = 'disableLoadMoreLoading';
export const classStudioFoundSuccess = 'classStudioFoundSuccess';
export const classDriveFoundSuccess = 'classDriveFoundSuccess';
export const classOnlineFoundSuccess = 'classOnlineFoundSuccess';
export const enableFilterLoading = 'enableFilterLoading';
export const disableFilterLoading = 'disableFilterLoading';
export const cartItemAdded = 'cartItemAdded';
export const cartItemDetails = 'cartItemDetails';
export const serviceQuotesFoundSuccess = 'serviceQuotesFoundSuccess';
export const tutorRateListFoundSuccess = 'tutorRateListFoundSuccess';
export const sidebarLogo = 'sidebarLogo';
export const viewAllClassTabs = 'viewAllClassTabs';
export const onTutorPublicsiderbar = 'onTutorPublicsiderbar';
export const onTutorDashboardsiderbar = 'onTutorDashboardsiderbar';
export const onGuestsiderbar = 'onGuestsiderbar';
export const onHeaderSearch = 'onHeaderSearch';
export const searchClassCampsRouteRedirect = 'searchClassCampsRouteRedirect';
export const ontutorSearchBox = 'ontutorSearchBox';
export const onClassesListLoading = 'onClassesListLoading';
export const onGetClassesTutorView = 'onGetClassesTutorView';
export const getClassesListSuccess = 'getClassesListSuccess';
export const getClassesSponoredListSuccess = 'getClassesSponoredListSuccess';
export const cartItemsDeatils = 'cartItemsDeatils';
export const customerDetailsCompleted = 'customerDetailsCompleted';
export const customerDetails = 'customerDetails';
export const paymentDetailsCompleted = 'paymentDetailsCompleted';
export const paymentDetails = 'paymentDetails';
export const onOtherViewCamps = 'onOtherViewCamps';

/* Camps */
export const onGetCampsTutorView = 'onGetCampsTutorView';
export const campsFoundSuccess = 'campsFoundSuccess';
export const campsServices = 'campsServices';

/* Rating list */
export const onRatingListData = 'onRatingListData';
export const onCreateRating = 'onCreateRating';
export const onCreateReview = 'onCreateReview';
export const onRatingListFull = 'onRatingListFull';
export const onRatingListDataForTutor = 'onRatingListDataForTutor';
export const onRatingListDataLoading = 'onRatingListDataLoading';

/* Message */
export const recentchatlistuser = 'recentchatlistuser';
export const addinguser = 'addinguser';
export const disableMessageLoading = 'disableMessageLoading';
export const disableAddUserLoading = 'disableAddUserLoading';
export const enableAddUserLoading = 'enableAddUserLoading';
export const updateMessage = 'updateMessage';
export const createMessage = 'createMessage';
export const individualMessages = 'individualMessages';
export const providerListForUser = 'providerListForUser';
