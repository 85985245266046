import React from 'react'
import {
  Input,
  Button,
  Select,
  Rate,
  Table,
  Space,
  Steps,
  Form,
  notification,
  Carousel,
  DatePicker,
} from 'antd'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import * as tutorActions from '../../../store/actions/tutor/index'
import * as userAction from '../../../store/actions/users'
import * as campsActions from '../../../store/actions/public/Camps'
import * as action from '../../../store/actions/public/Classes'
import classTimingsOnly from '../../../components/timezone/onlytiming'
import history from '../../../utils/history'
import axios from '../../../utils/axios'
import { withErrorHandler } from '../../../utils/handler'
import './guest-flow.css'

const { Option } = Select

// Listing Table Data Start

class DependantsTab extends React.Component {
  userFormRef = React.createRef()

  dependentFormRef = React.createRef()

  state = {
    address_results: [],
    fetching: false,
    cartItems: [],
    ItemsDetails: {},
    campsTotalAmount: 0,
    cartType: '',
    dependentFormData: {},
    dependentUsers: [],
    selectedRowKeys: [],
    depententId: 'Myself',
  }

  componentDidMount() {
    if (this.props.dependentUserSelect) {
      this.onRowSelectChange([this.props.dependentUserSelect], [])
    }
    this.props.getDependentUsers()

    window.scrollTo(0, 0)
    if (this.props.itemsExist && this.props.itemsExist.length > 0) {
      const requestParams = {}
      requestParams.classes = this.props.itemsExist.join(',')
      this.props.getCartItems(requestParams)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    /* End of the Camps cart details */
    if (prevProps.updatedData !== this.props.updatedData) {
      this.props.getDependentUsers()
    }
    if (prevProps.userProfileUpdate !== this.props.userProfileUpdate) {
      this.props.getDependentUsers()
    }

    if (prevProps.itemsDetails !== this.props.itemsDetails) {
      if (
        this.props.itemsDetails &&
        Object.keys(this.props.itemsDetails).length > 0
      ) {
        this.setState({
          ItemsDetails: _.get(
            this.props,
            'itemsDetails.items[0].classes[0]',
            {}
          ),
          cartDetailsItems: this.props.itemsDetails.items,
          cartType: 'class',
        })
      }
    }

    if (prevProps.campsItemsDetails !== this.props.campsItemsDetails) {
      if (
        this.props.campsItemsDetails &&
        Object.keys(this.props.campsItemsDetails).length > 0
      ) {
        if (
          _.get(this.props, 'campsItemsDetails.items') &&
          this.props.campsItemsDetails.items.length == 1
        ) {
          this.setState({
            ItemsDetails: _.get(
              this.props,
              'campsItemsDetails.items[0].camps[0]',
              {}
            ),
            cartDetailsItems: this.props.campsItemsDetails.items,
            cartType: 'camps',
          })
        }
      }
    }

    if (prevProps.dependentUsers !== this.props.dependentUsers) {
      if (
        this.props.dependentUsers.meta &&
        this.props.dependentUsers.meta.count > 0
      ) {
        await this.setState({
          dependentUsers: this.props.dependentUsers.items,
        })
      }
    }

    if (
      prevProps.createdDependentData !== this.props.createdDependentData &&
      this.props.createdDependentData
    ) {
      console.log('createddepen=======', this.props.createdDependentData)
      if (
        this.props.createdDependentData instanceof Error ||
        this.props.createdDependentData.status !== 200
      ) {
        let message = 'Something went wrong.'
        if (
          this.props.createdDependentData.response &&
          this.props.createdDependentData.response.data &&
          this.props.createdDependentData.response.data.message
        ) {
          message = this.props.createdDependentData.response.data.message
        } else if (
          this.props.createdDependentData.response &&
          this.props.createdDependentData.response.data &&
          this.props.createdDependentData.response.data.errors &&
          this.props.createdDependentData.response.data.errors.length > 0
        ) {
          message = `${this.props.createdDependentData.response.data.errors[0].field}: ${this.props.createdDependentData.response.data.errors[0].rules[0].message}`
        }
      } else {
        const { dependentUsers } = this.state
        dependentUsers.push(this.props.createdDependentData.details)
        this.setState({
          dependentUsers,
        })
        const dependentFormData = {
          firstname: '',
          gender: '',
          age: '',
          relationship: '',
        }

        this.setState({
          dependentFormData,
          selectValue: '',
        })
        await this.dependentFormRef.current.resetFields()
        this.setState({
          selectValue: '',
        })
        // this.dependentFormRef.current.setFieldsValue(dependentFormData);
        notification.success({
          message: this.props.t('guestFlow'),
        })
      }
    }

    if (
      prevProps.deletedDependentData !== this.props.deletedDependentData &&
      this.props.deletedDependentData
    ) {
      if (this.props.deletedDependentData instanceof Error) {
        let message = 'Something went wrong.'
        if (
          this.props.deletedDependentData.response &&
          this.props.deletedDependentData.response.data &&
          this.props.deletedDependentData.response.data.message
        ) {
          message = this.props.deletedDependentData.response.data.message
        } else if (
          this.props.deletedDependentData.response &&
          this.props.deletedDependentData.response.data &&
          this.props.deletedDependentData.response.data.errors &&
          this.props.deletedDependentData.response.data.errors.length > 0
        ) {
          message = `${this.props.deletedDependentData.response.data.errors[0].field}: ${this.props.createdDependentData.response.data.errors[0].rules[0].message}`
        }
      } else {
        notification.success({
          message: this.props.t('guestFlow1'),
        })
      }
    }

    if (
      prevProps.updatedDependentData !== this.props.updatedDependentData &&
      this.props.updatedDependentData
    ) {
      if (this.props.updatedDependentData instanceof Error) {
        let message = 'Something went wrong.'
        if (
          this.props.updatedDependentData.response &&
          this.props.updatedDependentData.response.data &&
          this.props.updatedDependentData.response.data.message
        ) {
          message = this.props.updatedDependentData.response.data.message
        } else if (
          this.props.updatedDependentData.response &&
          this.props.updatedDependentData.response.data &&
          this.props.updatedDependentData.response.data.errors &&
          this.props.updatedDependentData.response.data.errors.length > 0
        ) {
          message = `${this.props.updatedDependentData.response.data.errors[0].field}: ${this.props.createdDependentData.response.data.errors[0].rules[0].message}`
        }
      } else {
        notification.success({
          message: this.props.t('guestFlow2'),
        })
      }
    }
  }

  onChangeDependentForm = async (value, key) => {
    const { dependentFormData } = this.state
    if (key === 'gender') {
      await this.setState({
        selectValue: value,
      })
      await this.dependentFormRef.current.setFieldsValue({ gender: value })
    }
    dependentFormData[key] = value
    this.setState({ dependentFormData })
  }

  onFinishDependent = async (e) => {
    let { dependentFormData } = this.state
    let { dependentUsers } = this.state
    if (dependentFormData.id) {
      dependentFormData.dependentUserId = dependentFormData.id
      await this.props.updateDependent(dependentFormData)
      dependentUsers = _.remove(
        dependentUsers,
        (item) => item.id !== dependentFormData.id
      )
      dependentUsers.push(dependentFormData)
      this.setState({
        dependentUsers,
      })
      dependentFormData = {
        firstname: '',
        gender: '',
        age: '',
        relationship: '',
      }

      this.setState({
        dependentFormData,
        selectValue: '',
      })
      await this.dependentFormRef.current.resetFields()
      await this.dependentFormRef.current.setFieldsValue(dependentFormData)
    } else {
      await this.props.addDependent(dependentFormData)
    }
  }

  onDeleteDependent = async (id) => {
    let { dependentUsers } = this.state
    await this.props.deleteDependent({ id })
    dependentUsers = _.remove(dependentUsers, (item) =>
      item ? item.id !== id : ''
    )
    this.setState({ dependentUsers })
  }

  onUpdateDependent = async (id) => {
    const { dependentUsers } = this.state
    const dependentFormData = _.find(dependentUsers, (obj) => obj.id === id)
    dependentFormData.dob = dependentFormData.dob
      ? dayjs(dependentFormData.dob)
      : ''
    this.setState({ dependentFormData })
    await this.dependentFormRef.current.setFieldsValue(dependentFormData)
  }

  nextPage = () => {
    history.push('/mycart')
  }

  onRowSelectChange = (selectedRowKeys, selectedRows) => {
    if (selectedRowKeys) {
      this.setState({
        depententId: _.get(selectedRowKeys, '[0]', 'Myself'),
        selectedRowKeys,
      })
    }
  }

  passwordValidation = (rule, value, callback, message) => {
    const passw =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]).{8,}/
    if (value && !value.match(passw)) {
      callback(message)
    } else {
      callback()
    }
  }

  confirmPassword = (rule, value, callback, message) => {
    const password = this.dependentFormRef.current.getFieldValue('password')
    if (value && value !== password) {
      callback(this.props.t('authenticationRequiredPasswordValidationMessage2'))
    } else {
      callback()
    }
  }

  cancelForm = async () => {
    const dependentFormData = {
      firstname: '',
      gender: '',
      age: '',
      relationship: '',
    }

    this.setState({
      dependentFormData,
      selectValue: '',
    })
    await this.dependentFormRef.current.resetFields()
    await this.dependentFormRef.current.setFieldsValue(dependentFormData)
  }

  render() {
    const { dependentFormData } = this.state

    let ListingClassdataSource
    if (this.state.dependentUsers.length > 0) {
      ListingClassdataSource = this.state.dependentUsers.map((value, key) => {
        if (_.get(value, 'id')) {
          return {
            key: value.id,
            Name: value.firstname,
            // Age: value.age,
            Dob: value.dob ? dayjs(value.dob).format('MM-DD-YYYY') : '---',
            Gender: value.gender,
            Relationship: value.relationship,
            id: value.id,
          }
        }
      })
    }
    const ListingClasscolumns = [
      {
        title: this.props.t('nameLabel'),
        dataIndex: 'Name',
        key: 'Name',
      },
      {
        title: this.props.t('guestFlow3'),
        dataIndex: 'Dob',
        key: 'Dob',
      },
      {
        title: this.props.t('gender'),
        dataIndex: 'Gender',
        key: 'Gender',
      },
      {
        title: this.props.t('guestFlow4'),
        dataIndex: 'Relationship',
        key: 'Relationship',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <div>
              <button
                onClick={() => this.onUpdateDependent(_.get(record, 'id'))}
                className="btn btn-edit"
              >
                <i className="ntm-edit" />
              </button>
              <button
                onClick={() => this.onDeleteDependent(_.get(record, 'id'))}
                className="btn btn-delete"
              >
                <i className="ntm-bin" />
              </button>
            </div>
          </Space>
        ),
      },
    ]
    const { ItemsDetails, cartType, campsTotalAmount } = this.state
    return (
      <div className="steps-inner-container dependent-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="dependents-tab-title py-2 pb-4">
              <i className="ntm-information dependents-tab-title-icon" />
              <span>
                {this.props.t('guestFlow5')}

                <span onClick={this.nextPage} className="SkipLink">
                  {this.props.t('guestFlow6')}
                </span>
                {this.props.t('guestFlow7')}
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="pb-4">
              <Table
                loading={this.props.dependentUserLoading}
                dataSource={ListingClassdataSource}
                columns={ListingClasscolumns}
                pagination={false}
                total={50}
              />
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
            <Form
              scrollToFirstError
              onFinish={(e) => this.onFinishDependent(e)}
              ref={this.dependentFormRef}
              className="row"
            >
              <Form.Item
                label="Dependent Name"
                onChange={(e) =>
                  this.onChangeDependentForm(e.target.value, 'firstname')
                }
                name="firstname"
                rules={[
                  {
                    required: true,
                    pattern: '^[a-zA-Z ]*$',
                    message: this.props.t('firstname1'),
                  },
                ]}
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <Input
                  className="input-bg"
                  placeholder="Enter your First Name"
                />
              </Form.Item>
              {/* <Form.Item label='Age' name="age"
                                rules={[{
                                    required: true, message: "Age is required."
                                }]}
                                className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">

                                <InputNumber className="input-bg" onChange={(e) => this.onChangeDependentForm(e, 'age')} max={100} min={1} maxLength={3} parser={(value) => value.replace(/[^0-9]/g, "")} />

                            </Form.Item> */}

              <Form.Item
                label="Gender"
                name="gender"
                className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8"
              >
                <Select
                  className="selectBox"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(e) => this.onChangeDependentForm(e, 'gender')}
                  value={this.state.selectValue}
                >
                  <Option key="Male">Male</Option>
                  <Option key="Female">Female</Option>
                  <Option key="Others">Others</Option>
                </Select>
              </Form.Item>

              {/* <Form.Item
                                label="Birthdate"
                                name="dob"
                                style={{ marginLeft: 15 }}

                            >
                                <DatePicker
                                    className="birthDateBox"
                                    defaultPickerValue={dayjs().subtract(13, 'years')}
                                    format={'MM/DD/YYYY'}
                                    disabledTime={true}
                                    style={{ borderRadius: 20, padding: '7px 15px' }}
                                    onChange={(e) => this.onChangeDependentForm(e, 'dob')}
                                    disabledDate={(currentDate) =>
                                        currentDate.isAfter(dayjs().subtract(13, 'years'), 'day')
                                    }
                                />
                            </Form.Item> */}

              <Form.Item
                label="Relationship to you"
                onChange={(e) =>
                  this.onChangeDependentForm(e.target.value, 'relationship')
                }
                name="relationship"
                rules={[
                  {
                    required: true,
                    // type: 'string',
                    pattern: /^[A-Za-z]+$/,
                    message: this.props.t('guestFlow8'),
                  },
                ]}
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <Input
                  maxLength={30}
                  placeholder="Enter the kid's relationship to you"
                />
              </Form.Item>
              <Form.Item
                label="Dependent email"
                onChange={(e) =>
                  this.onChangeDependentForm(e.target.value, 'email')
                }
                name="email"
                rules={[
                  // {
                  //     required: false,
                  //     message:
                  //         this.props.t("inputValidEmail")

                  // },
                  {
                    type: 'email',
                    message: this.props.t('inputValidEmail'),
                  },
                ]}
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <Input placeholder="Enter Email ID" />
              </Form.Item>
              <Form.Item
                label="Create Password"
                onChange={(e) =>
                  this.onChangeDependentForm(e.target.value, 'password')
                }
                name="password"
                dependencies={['new_password']}
                rules={[
                  //     {
                  //     required: true,
                  //     message: this.props.t("pwValidationMessage")
                  // },
                  {
                    validator: (rule, value, callback) =>
                      this.passwordValidation(
                        rule,
                        value,
                        callback,
                        this.props.t('enterPass3')
                      ),
                  },
                ]}
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                onChange={(e) =>
                  this.onChangeDependentForm(e.target.value, 'new_password')
                }
                name="new_password"
                dependencies={['password']}
                rules={[
                  // {
                  //     required: true,
                  //     message: this.props.t("pwValidationMessage"),
                  // },
                  {
                    validator: (rule, value, callback) =>
                      this.confirmPassword(
                        rule,
                        value,
                        callback,
                        this.props.t('enterPass3')
                      ),
                  },
                ]}
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <div className="col-12 pb-4">
                <div className="full-width">
                  <Button
                    className="FormAddButtom"
                    type="primary"
                    htmlType="submit"
                  >
                    {dependentFormData && dependentFormData.id
                      ? this.props.t('update')
                      : this.props.t('add')}
                  </Button>
                  <Button
                    onClick={() => this.cancelForm()}
                    style={{ marginLeft: '12px' }}
                    className="FormAddButtom"
                    type="primary"
                  >
                    {this.props.t('cancel')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {!_.isEmpty(ItemsDetails) && (
            <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
              <div className="nurtem-card-design px-0">
                <div className="px-3">
                  <div className="row py-3">
                    <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                      <div className="profile-image">
                        <img
                          src={_.get(
                            ItemsDetails,
                            'provider_profile[0].photo',
                            ''
                          )}
                          className="avatar img-fluid"
                          alt="img"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
                      <div className="profile-name text-truncate">
                        {`${_.startCase(_.get(ItemsDetails, 'provider_profile[0].firstname', ''))} ` +
                          `${_.get(ItemsDetails, 'provider_profile[0].lastname', '') !== '' ? `${_.startCase(_.get(ItemsDetails, 'provider_profile[0].lastname', ''))[0]}.` : ''} `}
                      </div>
                      <div className="star-rating">
                        <Rate
                          disabled="true"
                          defaultValue={_.get(
                            ItemsDetails,
                            'provider_profile[0].average_stars',
                            0
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="orderSummeryTitle">
                  {this.props.t('orderSum')}
                </div>
                <div className="orderSummeryDetails">
                  <div className="full-width py-2">
                    <div className="full-width">
                      <b>{ItemsDetails.name}</b>
                    </div>
                    {cartType === 'camps' ? (
                      <div className="full-width">Every</div>
                    ) : (
                      <div className="full-width">
                        <Carousel
                          className="carousel-text"
                          speed={100}
                          autoplay
                        >
                          {classTimingsOnly(ItemsDetails)}
                        </Carousel>
                      </div>
                    )}
                  </div>
                  <div className="orderSummeryDetailsTotal full-width py-2 d-flex justify-content-between">
                    <span> {this.props.t('gt')}</span>
                    <b>
                      $
                      {cartType === 'camps'
                        ? campsTotalAmount
                        : ItemsDetails.price}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="steps-action">
            <Button type="primary" onClick={this.nextPage}>
              {this.props.t('next')}
            </Button>
            <Button
              className="steps-skip-btn"
              style={{ margin: '0 8px' }}
              onClick={this.nextPage}
            >
              {this.props.t('guestFlow9')}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  userBaseProfile: state.userProfile.userBaseProfile,
  dependentUsers: state.userProfile.dependentUsers,
  loading: state.userProfile.updateUserProfile,
  updatedData: state.userProfile.updateUserProfileInfo,
  createdDependentData: state.userProfile.createDependentUser,
  dependentUserLoading: state.userProfile.dependentUserLoading,
  deletedDependentData: state.userProfile.deleteDependentUser,
  updatedDependentData: state.userProfile.updateDependentUser,
  searchClassCampsRouteRedirect: state.services.searchClassCampsRouteRedirect,
  dependentUserSelect: state.users.guestUserDepententSelect,
  itemsExist: state.classes.addedCartItems,
  itemsDetails: state.classes.cartItemDetails,
  contentLoading: state.classes.contentLoading,
  cartItemLoading: state.classes.cartItemLoading,
  loggedInUserDetails: state.authentication,
  userProfileUpdate: state.users.updateUserProfileInfo,
})

const mapDispatchToProps = (dispatch) => ({
  getCartItems: (data) => dispatch(action.getCartItems(data)),
  onUpdateUserProfileInfo: (request) =>
    dispatch(userAction.updateUserProfileInfo(request)),
  onProfileUpdate: (requestPar) =>
    dispatch(tutorActions.onProfileUpdate(requestPar)),
  addDependent: (data) => dispatch(userAction.createDependentUser(data)),
  updateDependent: (data) => dispatch(userAction.updateDependentUser(data)),
  deleteDependent: (data) => dispatch(userAction.deleteDependentUser(data)),
  getDependentUsers: () => dispatch(userAction.getDependentUsers()),
  guestUserFlowStep: (request) =>
    dispatch(userAction.guestUserFlowStep(request)),
  guestUserDepententSelect: (request) =>
    dispatch(userAction.guestUserDepententSelect(request)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(withTranslation()(DependantsTab), axios))
