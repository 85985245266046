import * as actionTypes from '../../../actions/tutor/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
  show_element: false,
  show_group_info: false,
  TotalStudentList: {},
  TotalStudentListCamp: {},
  TotalStudentListComp: {},
  transactionList: [],
  requestAssignment: [],
  sessionList: {},
  requestCount: 0,
  sessionListComp: {},
  sessionListEvent: {},
  sessionListClass: {},
  paymentReport: {},
  loading: { loading: false },
  sessionsLoading: { sessionsLoading: false },
  TotalStudentListLoading: { TotalStudentListLoading: false },
  TotalStudentListLoadingCamp: { TotalStudentListLoadingCamp: false },
  TotalStudentListLoadingComp: { TotalStudentListLoadingComp: false },
  [actionTypes.selected_provider]: '',
};

const showElement = (state, action) => ({ ...state, show_element: action.payload });
const showGroupInfo = (state, action) => ({ ...state, show_group_info: action.payload });

const selectedProvider = (state, action) => ({ ...state, selected_provider: action.payload });

const sessionList = (state, action) => ({ ...state, sessionList: action.payload });
const requestCount = (state, action) => ({ ...state, requestCount: action.payload });
const sessionListComp = (state, action) => ({ ...state, sessionListComp: action.payload });
const sessionListEvent = (state, action) => ({ ...state, sessionListEvent: action.payload });
const sessionListClass = (state, action) => ({ ...state, sessionListClass: action.payload });
const TotalStudentList = (state, action) => ({ ...state, TotalStudentList: action.payload });
const TotalStudentListCamp = (state, action) => ({ ...state, TotalStudentListCamp: action.payload });
const TotalStudentListComp = (state, action) => ({ ...state, TotalStudentListComp: action.payload });
const TotalTransList = (state, action) => ({ ...state, transactionList: action.success });
const RequestAssignment = (state, action) => ({ ...state, requestAssignment: action.success });
const enableClassSessionsLoading = (state, action) => updateObject(state, action.sessionsLoading);
const disableClassSessionsLoading = (state, action) => updateObject(state, action.sessionsLoading);
const enableTotalStudentListLoading = (state, action) => updateObject(state, action.TotalStudentListLoading);
const disableTotalStudentListLoading = (state, action) => updateObject(state, action.TotalStudentListLoading);
const enableTotalStudentListLoadingCamp = (state, action) => updateObject(state, action.TotalStudentListLoadingCamp);
const disableTotalStudentListLoadingCamp = (state, action) => updateObject(state, action.TotalStudentListLoadingCamp);
const enableTotalStudentListLoadingComp = (state, action) => updateObject(state, action.TotalStudentListLoadingComp);
const disableTotalStudentListLoadingComp = (state, action) => updateObject(state, action.TotalStudentListLoadingComp);
const paymentReportEnableLoading = (state, action) => updateObject(state, action.loading);
const paymentReportDisableLoading = (state, action) => updateObject(state, action.loading);
const paymentReport = (state, action) => ({ ...state, paymentReport: action.success });
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.show_element:
      return showElement(state, action);
    case actionTypes.show_group_info:
      return showGroupInfo(state, action);
    case actionTypes.selected_provider:
      return selectedProvider(state, action);
    case actionTypes.TotalStudentList:
      return TotalStudentList(state, action);
    case actionTypes.TotalStudentListCamp:
      return TotalStudentListCamp(state, action);
    case actionTypes.TotalSubsTransList:
      return TotalTransList(state, action);
    case actionTypes.RequestAssignment:
      return RequestAssignment(state, action);
    case actionTypes.TotalStudentListComp:
      return TotalStudentListComp(state, action);
    case actionTypes.sessionList:
      return sessionList(state, action);
    case actionTypes.requestCount:
      return requestCount(state, action);
    case actionTypes.sessionListComp:
      return sessionListComp(state, action);
    case actionTypes.sessionListEvent:
      return sessionListEvent(state, action);
    case actionTypes.sessionListClass:
      return sessionListClass(state, action);
    case actionTypes.enableClassSessionsLoading:
      return enableClassSessionsLoading(state, action);
    case actionTypes.disableClassSessionsLoading:
      return disableClassSessionsLoading(state, action);
    case actionTypes.enableTotalStudentListLoading:
      return enableTotalStudentListLoading(state, action);
    case actionTypes.disableTotalStudentListLoading:
      return disableTotalStudentListLoading(state, action);
    case actionTypes.enableTotalStudentListLoadingCamp:
      return enableTotalStudentListLoadingCamp(state, action);
    case actionTypes.disableTotalStudentListLoadingCamp:
      return disableTotalStudentListLoadingCamp(state, action);
    case actionTypes.enableTotalStudentListLoadingComp:
      return enableTotalStudentListLoadingComp(state, action);
    case actionTypes.disableTotalStudentListLoadingComp:
      return disableTotalStudentListLoadingComp(state, action);
    case actionTypes.paymentReport:
      return paymentReport(state, action);
    case actionTypes.EnablepaymentReportLoading:
      return paymentReportEnableLoading(state, action);
    case actionTypes.DisablepaymentReportLoading:
      return paymentReportDisableLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
