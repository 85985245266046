import React, { useState, useEffect } from 'react';
import { Tabs, Rate } from 'antd';
import { connect } from 'react-redux';
import '../tutor-camps/user-new-my-class.css';
import _ from 'lodash';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import StudentList from '../../../components/common/student-list-competition';
import Ratings from '../../../components/rating/rating-list';
import EnrollStudent from './components/enroll-a-student';
import Faq from '../../../components/common/faq';
import history from '../../../utils/history';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';
import ParticipantUploads from '../../../components/common/upload-list';
import * as actionTypes from '../../../store/actions/common/actionTypes';
import * as action from '../../../store/actions/tutor/camps';
import * as routeName from '../../../components/route-names';
import BatchInfoCompetition from './batch-info-compe';
import MeetingLink from '../tutor-classes/components/meeting-link';
import { FcConferenceCall, FcFaq, FcInfo, FcLink, FcRating, FcSurvey, FcUpload } from "react-icons/fc";
import { TiUserAdd } from "react-icons/ti";
import WaitingList from '../tutor-classes/components/waiting-list';
import { PiUserListBold } from 'react-icons/pi';

const SessionDetailedView = (props) => {
  const tutorProfile = _.get(props, 'tutorProfile', {});
  const profile_type = _.get(tutorProfile, 'profile_type', '');
  const works_for = _.get(tutorProfile, 'works_for', '');
  const isTutorUnderBusiness = profile_type === 1 && works_for !== null;

  const urlparams = queryString.parse(window.location.search);
  const [classDetails, setclassDetails] = useState({});

  useEffect(() => {
    if (urlparams && urlparams.gid && urlparams.sid) {
      props.onsessionDetail({ id: urlparams.sid, expand: 'services,provider', type: 'events' });
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    if (props.sessionDetail) {
      setclassDetails(props.sessionDetail);
    }
  }, [props.sessionDetail]);

  const render = () => {
    // var cd = _.chain(classDetails)
    const viewAttendance = (key, session) => {
      const provider_id = _.get(session, 'provider.id', _.get(session, 'provider', ''));

      if (key === '12') {
        history.push(`${routeName.paymentReportFull.split(':')[0]}?classId=${session.id}&type=${'competitions'}&provId=${provider_id}`);
      }
    };
    const tabTitle = props.t('participants');
    const tabTitle1 = props.t('batchInfo');
    const tabTitle3 = props.t('partiUp');
    const tabTitle4 = props.t('enrollParti');
    const tabTitle5 = props.t('faqs');
    const tabTitle7 = props.t('r&r');
    const tabTitle11 = props.t('tutorSidebarPaymentReport');
    const tabTitle12 = props.t('waitlist2');
    return (
      <div className="nurtem-my-class-card tutor-my-camp-details-page pb-4 mb-3 sessionbox">
        <ScrollToTop />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 border-right">
            <Tabs
              defaultActiveKey="1"
              tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
              onChange={(key) => viewAttendance(key, classDetails)}
            >
              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcConferenceCall style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle}</span>} key="1">
                <StudentList session={urlparams.sid} type="event" />
              </items>
              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><PiUserListBold style={{ marginRight: '5px', fontSize: '20px', color: '#daa69a' }} /> {tabTitle12}</span>} key="14">
                <WaitingList session={classDetails} type="camps" />
              </items>
              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcUpload style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle3}</span>} key="2">

                <ParticipantUploads
                  session={urlparams.sid}
                  cat="events"
                  subcat={actionTypes.eventUploads}
                  user="host"
                  user_type="student"
                />

              </items>

              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><TiUserAdd style={{ color: '#7d3d8b', marginRight: '5px', fontSize: '20px' }} /> {tabTitle4}</span>} key="3">
                <EnrollStudent session={classDetails} groupId={urlparams.gid} type="event" />
              </items>

              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcFaq style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle5}</span>} key="4">
                <Faq session={classDetails} type="event" />
              </items>

              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcRating style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle7}</span>} key="6">
                <Ratings session={classDetails} type="event" />
              </items>

              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcInfo style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle1}</span>} key="11">
                <BatchInfoCompetition classDetails={classDetails} type="event" />
              </items>

              {!isTutorUnderBusiness && <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcSurvey style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle11}</span>} key="12" />}

              {classDetails.link && (
                <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcLink style={{ marginRight: '5px', fontSize: '20px' }} /> Meeting Link</span>} key="13">
                  <MeetingLink session={classDetails} type="event" />
                </items>
              )}
            </Tabs>
          </div>
          {/* <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pl-0'>
            <div className='user-details-right col-12'>
              <div className='row align-items-center mt-3'>
                <div className='col-12  pr-md-0'>
                  <div className="tab-content-title">{session_name} - ${session_price}</div>
                </div>
              </div>

              <div className='row align-items-center justify-content-between py-2'>
                <div className='col-8'>
                  <div className='row align-items-center'>
                    <div className='col pl-0'>
                      <div className='star-rating-session mt-1'>
                        <Rate
                          disabled='true'
                          value={parseInt(avg_count)}
                        />
                        {parseInt(avg_count) !== 0 && (
                          <b className="ml-2">
                            ({(avg_count).toFixed(1)}/5)
                          </b>
                        )}
                      </div>
                      <div className="review-counting-session">
                        ({parseInt(rating_count)} {props.t('rating')})
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row align-items-center py-4'>
                <div className='col-12 session-text-bold mb-2'>
                  Rules :
                </div>
                <div className='col-12'>
                  <Scrollbars
                    className='camp-scroll-height'
                    renderTrackHorizontal={props => (
                      <div
                        {...props}
                        className='track-horizontal'
                        style={{ display: 'none' }}
                      />
                    )}
                    renderThumbHorizontal={props => (
                      <div
                        {...props}
                        className='thumb-horizontal'
                        style={{ display: 'none' }}
                      />
                    )}
                  >
                    <div>{parser(rules)}</div>
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  tutorProfile: _.get(state, 'tutor.tutorBaseProfile.details'),
  studentsCount: _.get(state.competitionsDetails.competitionStudents, ['meta', 'total_count'], 0),
  sessionDetail: state.campsDetails.sessionDetail.data,
});

const mapDispatchToProps = (dispatch) => ({
  onsessionDetail: (requestParams) => dispatch(action.onGetsessionDetail(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SessionDetailedView));
