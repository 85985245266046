import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import UserMyCart from './cart';
import profileFilledCheck from '../../../utils/profile-filled-check';

class UserCart extends React.Component {
  state = {
    campsDetails: [],
    classDetails: [],
    isUserProfileModal: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    // logged in user
    if (this.props.loggedInUserDetails && this.props.loggedInUserDetails.isLoggedIn) {
      profileFilledCheck(this.props.userBaseProfile);
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  gestUserProfileModal = (value) => {
    this.setState({
      isUserProfileModal: value === 'open',
    });
  };

  fetCartComponent = () => <UserMyCart {...this.props} handlePress={() => this.gestUserProfileModal('open')} />;

  render() {
    return this.fetCartComponent();
  }
}

const mapStateToProps = (state) => ({
  loggedInUserDetails: state.authentication,
  itemsExist: state.classes.addedCartItems,
  userBaseProfile: state.userProfile.userBaseProfile,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(UserCart));
