import React from 'react'
import {
  Button,
  notification,
  Carousel,
  Rate,
  Tooltip,
  FloatButton,
} from 'antd'
import './style.css'
import { IoCalendarNumberOutline } from 'react-icons/io5'
import { BsClockFill, BsFillArrowRightCircleFill } from 'react-icons/bs'
import { PiUserFill } from 'react-icons/pi'
import { MdLocationPin } from 'react-icons/md'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as userAction from '../../../../store/actions/users'
import * as action from '../../../../store/actions/public/Classes'
import * as routeName from '../../../../components/route-names'
import history from '../../../../utils/history'
import nextSession from '../../../../components/tutor/nextSession'
import timeDateConverterSession from '../../../../components/timezone/sessionTimeDate'
import { TodaysClassLoader } from '../../../../components/loader/common/class-list-loader'
import dayjs from 'dayjs'
import EmptyAnime from '../../../../components/re-usable/lottie/EmptyAnime'
import DashboardHeader from './DashboardHeader'
import { CLASSPNG, DEFAULTUSER } from '../../../../utils/config/assets'
import _ from 'lodash'
import css from '../../../../utils/CSSUtility'

class UserNewDashboard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      todayClassListLoading: false,
      myClassListLoading: false,
      myCampListLoading: false,
      mycompetionListLoading: false,
      myEventListLoading: false,

      items: Array.from({ length: 20 }),
      uniqueCampListArray: [],
      uniqueCompetitionListArray: [],
      uniqueClassListArray: [],
      uniqueEventListArray: [],
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    if (this.props.userBaseProfile && this.props.userBaseProfile.details) {
      if (
        _.get(this, 'props.userBaseProfile.details.user.account_type') !==
        'dependent'
      ) {
        if (
          !this.props.userBaseProfile.details.firstname ||
          !this.props.userBaseProfile.details.lastname ||
          !this.props.userBaseProfile.details.zip_code
        ) {
          history.push(routeName.userProfile)
          notification.warning({
            message: 'Warning',
            description: 'Please update your profile.',
          })
        }
        this.props.getDependentUsers({ limit: 100 })
      }
    }

    if (!this.state.myClassListLoading) {
      this.setState({
        myClassListLoading: true,
        mycompetionListLoading: true,
        myCampListLoading: true,
        myEventListLoading: true,
      })
    }

    let list_params = {
      limit: 100,
      sort: 'created_at.desc',
      expand:
        'class_details,type_details,camp_details,provider,dependent_user,user',
    }

    this.props.getUserClasses(list_params)

    this.props.getUserCamps({
      ...list_params,
      expand: 'type_details,camp_details,provider,dependent_user,user',
    })

    this.props.getUserCompetitions({
      ...list_params,
      expand: 'type_details,competition_details,provider,dependent_user,user',
    })
    this.props.getUserEvents({
      ...list_params,
      expand: 'type_details,event_details,provider,dependent_user,user',
    })
  }

  async componentDidUpdate(prevProps) {
    const { userClasses, userCamps, userCompetitions, userEvents } = this.props

    const updateState = (prevItems, currentItems, stateKey, loadingKey) => {
      if (prevItems !== currentItems && currentItems?.items?.length > 0) {
        this.setState((prevState) => ({
          [stateKey]: [...currentItems.items],
        }))
        if (this.state[loadingKey]) {
          this.setState({ [loadingKey]: false })
        }
      }
    }

    updateState(
      prevProps.userClasses,
      userClasses,
      'uniqueClassListArray',
      'myClassListLoading'
    )
    updateState(
      prevProps.userCamps,
      userCamps,
      'uniqueCampListArray',
      'myCampListLoading'
    )
    updateState(
      prevProps.userCompetitions,
      userCompetitions,
      'uniqueCompetitionListArray',
      'mycompetionListLoading'
    )
    updateState(
      prevProps.userEvents,
      userEvents,
      'uniqueEventListArray',
      'myEventListLoading'
    )
  }

  tutorName = (details) => {
    if (!_.get(details, 'id')) {
      return <span>---</span>
    }

    const type = _.get(details, 'type')
    if (type === 'Individual') {
      const firstName = _.startCase(_.get(details, 'firstname', ''))
      const lastName = _.startCase(_.get(details, 'lastname', ''))

      return lastName ? `${firstName} ${lastName}` : firstName
    }

    return _.startCase(_.get(details, 'businessname', ''))
  }

  getMeetingId = (details, type) => {
    let meeting_link = _.chain(details).get('meeting_details[0].link').value()
    //let decrypt_url = decryptedString(meeting_link)
    let decrypt_url = meeting_link
    return decrypt_url
  }

  meetingLinkAction = (details, type) => {
    let meeting_link = _.chain(details).get('meeting_details[0].link').value()
    if (meeting_link) {
      return (
        <span>
          <a href={meeting_link} target="_blank" rel="noopener noreferrer">
            Join Meeting
          </a>
        </span>
      )
    } else {
      return null
    }
  }

  payNowButtonValidation = (details, order) => {
    if (_.get(details, 'class_details.billing_type') === 2) {
      let order_create_date = dayjs(_.get(order, 'updated_at')).format(
        'MM-YYYY'
      )
      let new_date = dayjs(new Date()).format('MM-YYYY')
      if (dayjs(new_date).isAfter(order_create_date)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  todaysClassRenderNew = () => {
    const { userClasses, userCamps, userCompetitions, userEvents } = this.props

    try {
      const allDataAvailable = [
        userClasses?.meta,
        userCamps?.meta,
        userCompetitions?.meta,
        userEvents?.meta,
      ].every(Boolean)

      if (!allDataAvailable) {
        return (
          <div className="empty-schedule-text ml-4">
            We're having trouble finding your class for today, please contact
            your tutor.
          </div>
        )
      }

      const today = dayjs().startOf('day')
      const allItems = [
        ...userClasses.items,
        ...userCamps.items,
        ...userCompetitions.items,
        ...userEvents.items,
      ]

      const filteredClasses = allItems.filter((item, index, self) => {
        const ids = [
          _.get(item, 'class_details.id'),
          _.get(item, 'camp_details.id'),
          _.get(item, 'competition_details.id'),
          _.get(item, 'event_details.id'),
        ]

        const isDuplicate =
          self.findIndex((obj) =>
            ids.every(
              (id, idx) =>
                id ===
                _.get(
                  obj,
                  [
                    'class_details.id',
                    'camp_details.id',
                    'competition_details.id',
                    'event_details.id',
                  ][idx]
                )
            )
          ) !== index

        const sessionDates = _.get(
          item,
          [
            'class_details.session_dates',
            'camp_details.session_dates',
            'competition_details.session_dates',
            'event_details.session_dates',
          ].find((path) => _.has(item, path)),
          []
        )

        const hasTodaySession = sessionDates.some((date) =>
          dayjs(date).startOf('day').isSame(today)
        )

        return !isDuplicate && hasTodaySession
      })

      if (filteredClasses.length === 0) {
        return (
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="nurtem-dashboard-card nurtem-dashboard-empty text-center mb-3">
              <div className="empty-schedule-icon">
                <EmptyAnime
                  AnimeType={'Today-Dashboard-Empty'}
                  loop={true}
                  animeheight={200}
                />
              </div>
              <div className="empty-schedule-text ml-4">
                You don't have any class today
              </div>
            </div>
          </div>
        )
      }

      return (filteredClasses ?? []).map((value, key) => {
        const currentDay = new Date().toLocaleDateString('en-US', {
          weekday: 'long',
        })

        const timings = _.get(
          value,
          [
            'class_details.time_slot',
            'camp_details.time_slot',
            'competition_details.time_slot',
            'event_details.time_slot',
          ].find((path) => _.has(value, path))
        )

        const currentTimeSlot = timings?.[currentDay]
        if (!currentTimeSlot) return null

        const sessionDetails = [
          {
            key: 'class_details',
            type: 'Class',
            route: routeName.userClassView,
          },
          { key: 'camp_details', type: 'Camp', route: routeName.userCampView },
          {
            key: 'competition_details',
            type: 'Competition',
            route: routeName.userCompetitionView,
          },
          {
            key: 'event_details',
            type: 'Event',
            route: routeName.userEventView,
          },
        ].find(({ key }) => _.has(value, key))

        const sessionName = _.get(
          value,
          `${sessionDetails.key}.session_name`,
          ''
        )
        const location_text = _.get(
          value,
          `${sessionDetails.key}.location_text`,
          ''
        )
        const sessionId = _.get(value, 'id')
        const typeDetails = this.capitalizeWord(
          _.get(value, 'type_details.name')
        )
        const userId =
          _.get(value, 'dependent_user.id') || _.get(value, 'user.id')
        const userType = _.get(value, 'dependent_user') ? 'dependent' : 'user'
        const userName =
          _.get(value, 'dependent_user.firstname') ||
          _.get(value, 'user.firstname')

        const url = `${sessionDetails.route}/${encodeURIComponent(sessionName)}/?campId=${sessionId}&userid=${userId}&usertype=${userType}&username=${userName}`

        const renderTimeSlot = () => (
          <div className="dashboard-profile-name">
            {currentDay} -{' '}
            {timeDateConverterSession(
              currentTimeSlot.start_time,
              'time',
              'hh:mm A'
            )}{' '}
            to{' '}
            {timeDateConverterSession(
              currentTimeSlot.end_time,
              'time',
              'hh:mm A'
            )}
          </div>
        )

        return (
          <div
            key={'today_class_info_' + key}
            className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4"
          >
            <div
              className="nurtem-dashboard-card"
              onClick={() => history.push(url)}
            >
              <div className="row">
                <div className="col-12">
                  <div className="nurtem-dashboard-card-header mb-3 user-dashboard">
                    <div
                      style={{ display: 'inline-block' }}
                      className="nurtem-dashboard-card-class-detail"
                    >
                      <div style={{ width: '100%', float: 'left' }}>
                        <img
                          src={
                            _.get(value, 'type_details.photo')
                              ? _.get(value, 'type_details.photo')
                              : CLASSPNG
                          }
                          className="dashboard-class-image"
                          alt="img"
                        />
                        <div className="dashboard-card-right-container">
                          <div
                            style={{ width: '85%', float: 'left' }}
                            className="dashboard-class-name text-truncate"
                            title={typeDetails}
                          >
                            {' '}
                            {_.get(value, 'status') === 0
                              ? `${typeDetails} (Awaiting Tutor response.)`
                              : typeDetails}
                          </div>
                          <Tooltip title={sessionName}>
                            <span className="dashsessionname">
                              {sessionName}
                            </span>
                          </Tooltip>
                          <div
                            className="age-group mt-1 mb-1"
                            style={{ width: '85%' }}
                          >
                            <div className="dashboard-profile-name mt-1">
                              <img
                                src={
                                  _.get(value, 'provider.photo')
                                    ? _.get(value, 'provider.photo')
                                    : DEFAULTUSER
                                }
                                className="dashboard-profile-image mr-1"
                                alt="img"
                              />
                              <span className="card-tutor-name text-truncate">
                                {this.tutorName(_.get(value, 'provider', {}))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="nurtem-dashboard-class-overview mb-2">
                    <div className="duration">
                      <BsFillArrowRightCircleFill
                        size={15}
                        className="mr-1"
                        style={{ color: '#53E898' }}
                      />
                      {sessionDetails.type}
                    </div>
                  </div>

                  <div className="nurtem-dashboard-class-overview mb-2">
                    <div className="duration">
                      <BsClockFill
                        size={15}
                        className="mr-1"
                        style={{ color: '#00ddfe' }}
                      />
                      <div className="dashboard-profile-name">
                        {renderTimeSlot()}
                      </div>
                    </div>
                  </div>

                  {location_text && (
                    <div
                      className="nurtem-dashboard-class-overview mb-2"
                      style={{ height: '27px' }}
                    >
                      <div className="duration">
                        <MdLocationPin
                          className="mr-1"
                          style={{ color: '#FF5D5D' }}
                        />{' '}
                        {location_text}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })
    } catch (error) {
      return (
        <div className="empty-schedule-text ml-4">
          We're having trouble finding your class for today, please contact your
          tutor..
        </div>
      )
    }
  }

  newDashboardCard = (DetailsCard, originalData, type = 'classes') => {
    return (DetailsCard ?? []).map((value, key) => {
      let forWhom = []
      const forWhomTo =
        _.get(value, 'user') && !_.get(value, 'dependent_user')
          ? `&userid=${_.get(value, 'user.id', '')}&usertype=user`
          : `&userid=${_.get(value, 'dependent_user.id', '')}&usertype=dependent`

      const typeKeyMap = {
        classes: 'class_details',
        camps: 'camp_details',
        competitions: 'competition_details',
        events: 'event_details',
      }

      const detailKey = typeKeyMap[type]
      const matchingItems = originalData.filter(
        (item) =>
          _.get(item, `${detailKey}.id`) === _.get(value, `${detailKey}.id`)
      )

      forWhom = (matchingItems ?? []).map(
        (item) =>
          _.get(item, 'dependent_user.firstname') ||
          _.get(item, 'user.firstname') ||
          ''
      )

      const va = _.chain(value)
      const id = va.get('id').value()
      const ar = va.get(`${detailKey}.average_rating`).value()
      const sessionName = va.get(`${detailKey}.session_name`).value()

      const routeMap = {
        classes: `${routeName.userClassView}/${sessionName}/?campId=${id}${forWhomTo}`,
        camps: `${routeName.userCampView}/${sessionName}/?campId=${id}${forWhomTo}`,
        competitions: `${routeName.userCompetitionView}/${sessionName}/?competitionId=${id}${forWhomTo}`,
        events: `${routeName.userEventView}/${sessionName}/?competitionId=${id}${forWhomTo}`,
      }

      const nextSessionMap = {
        classes: nextSession(va.get('class_details').value(), 'classes'),
        camps: nextSession(va.get('camp_details').value(), 'camp'),
        competitions: nextSession(
          va.get('competition_details').value(),
          'competitions'
        ),
        events: nextSession(va.get('event_details').value(), 'events'),
      }

      const actionBtn = (
        <Button
          className="btn-join-meeting"
          onClick={() => history.push(routeMap[type])}
        >
          {`Go to ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        </Button>
      )

      const rating = parseFloat(ar).toFixed(1)
      const type_details_name = `${this.capitalizeWord(_.get(value, 'type_details.name'))}`

      return (
        <div
          key={'class_info_' + key}
          className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4"
        >
          <div className="nurtem-dashboard-card user-dashboard">
            <div className="row">
              <div className="col-12">
                <div className="nurtem-dashboard-card-header class mb-3">
                  <div
                    style={{ display: 'inline-block' }}
                    className="nurtem-dashboard-card-class-detail"
                  >
                    <div style={{ width: '100%', float: 'left' }}>
                      <img
                        src={
                          _.get(value, 'type_details.photo')
                            ? _.get(value, 'type_details.photo')
                            : CLASSPNG
                        }
                        className="dashboard-class-image"
                        alt="img"
                      />
                      <div className="dashboard-card-right-container">
                        <div
                          style={{ width: '85%', float: 'left' }}
                          className="dashboard-class-name text-truncate"
                          title={type_details_name}
                        >
                          {_.get(value, 'status') === 0
                            ? `${type_details_name} (Awaiting for Tutor response.)`
                            : type_details_name}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Rate disabled="true" value={rating} />
                          <b className="ml-2" style={{ fontSize: '10px' }}>
                            ({`${rating}`}/5)
                          </b>
                        </div>
                        <div className="session-name">{sessionName}</div>
                        <div className="age-group mt-1 mb-1 full-width">
                          <div className="dashboard-profile-name mt-1">
                            <img
                              src={
                                _.get(value, 'provider.photo')
                                  ? _.get(value, 'provider.photo')
                                  : DEFAULTUSER
                              }
                              className="dashboard-profile-image mr-1"
                              alt="img"
                            />
                            <span className="card-tutor-name text-truncate">
                              {this.tutorName(_.get(value, 'provider', {}))}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="nurtem-dashboard-class-overview mb-2">
                  <div className="duration">
                    <PiUserFill
                      className="mr-1"
                      style={{ color: '#46ff00cc' }}
                    />
                    <span style={{ fontWeight: '500', marginRight: '7px' }}>
                      {this.props.t('toWhomlabel')}{' '}
                    </span>
                    <Carousel
                      className="dashboard-carousel"
                      autoplay
                      autoplaySpeed={2000}
                    >
                      {(forWhom ?? []).map((name, index) => (
                        <div key={index}>{name}</div>
                      ))}
                    </Carousel>
                  </div>
                </div>

                <div className="nurtem-dashboard-class-overview mb-2">
                  <div className="duration">
                    <IoCalendarNumberOutline
                      className="mr-1"
                      style={{ color: '#46ff00cc' }}
                    />
                    <span style={{ fontWeight: '500', marginRight: '7px' }}>
                      {this.props.t('next')} :
                    </span>
                    <span style={{ paddingRight: 8 }}>
                      {nextSessionMap[type]}
                    </span>
                  </div>
                </div>

                <div className="nurtem-dashboard-class-overview mb-2"></div>

                <div className="text-center mt-3 mb-1">{actionBtn}</div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  }

  render() {
    let userClassFilteredArray = _.uniqBy(
      _.get(this, 'state.uniqueClassListArray'),
      (n) => {
        return _.get(n, 'class_details.id')
      }
    )
    let userCampFilteredArray = _.uniqBy(
      _.get(this, 'state.uniqueCampListArray'),
      (n) => {
        return _.get(n, 'camp_details.id')
      }
    )
    let userCompetitionFilteredArray = _.uniqBy(
      _.get(this, 'state.uniqueCompetitionListArray'),
      (n) => {
        return _.get(n, 'competition_details.id')
      }
    )
    let userEventFilteredArray = _.uniqBy(
      _.get(this, 'state.uniqueEventListArray'),
      (n) => {
        return _.get(n, 'event_details.id')
      }
    )

    const {
      myClassListLoading,
      myCampListLoading,
      mycompetionListLoading,
      myEventListLoading,
    } = this.state
    const {
      userClasses,
      userCamps,
      userCompetitions,
      userEvents,
      dependentUsers,
    } = this.props

    return (
      <div className="row">
        <FloatButton.BackTop className="back-to-top" />
        <DashboardHeader
          class={userClasses.meta}
          camp={userCamps.meta}
          competition={userCompetitions.meta}
          event={userEvents.meta}
          dependent={dependentUsers.meta}
          userType={_.get(
            this,
            'props.userBaseProfile.details.user.account_type'
          )}
        />
        {/*Today’s Schedule - Friday 17th September*/}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 dashbox">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
              <div style={css.heading1}>
                {this.props.t('todSch')} - {dayjs().format('dddd Do MMMM')}
              </div>
            </div>
            {myClassListLoading === true && myCampListLoading === true ? (
              <TodaysClassLoader cards={12} />
            ) : (
              this.todaysClassRenderNew()
            )}
          </div>
        </div>

        {/*My Classes*/}
        {this.props.userClasses &&
          this.props.userClasses.meta &&
          this.props.userClasses.meta.count > 0 && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 dashbox">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div style={css.heading1}>{this.props.t('classes')}</div>
                </div>
                {/*Card*/}
                {myClassListLoading === true ? (
                  <TodaysClassLoader cards={12} />
                ) : (
                  this.newDashboardCard(
                    userClassFilteredArray,
                    _.get(this, 'state.uniqueClassListArray'),
                    'classes'
                  )
                )}
                {/*Card*/}
              </div>
            </div>
          )}
        {/*My Classes*/}

        {/*My Camps*/}
        {this.props.userCamps &&
          this.props.userCamps.meta &&
          this.props.userCamps.meta.count > 0 && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 dashbox">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div style={css.heading1}>{this.props.t('camps')}</div>
                </div>
                {/*Card*/}
                {myCampListLoading === true ? (
                  <TodaysClassLoader cards={12} />
                ) : (
                  this.newDashboardCard(
                    userCampFilteredArray,
                    _.get(this, 'state.uniqueCampListArray'),
                    'camps'
                  )
                )}
                {/*Card*/}
              </div>
            </div>
          )}
        {/*My Camps*/}

        {/*My Competition*/}
        {this.props.userCompetitions &&
          this.props.userCompetitions.meta &&
          this.props.userCompetitions.meta.count > 0 && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 dashbox">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div style={css.heading1}>
                    {this.props.t('tutorSidebarMyCompetitions')}
                  </div>
                </div>
                {mycompetionListLoading === true ? (
                  <TodaysClassLoader cards={12} />
                ) : (
                  this.newDashboardCard(
                    userCompetitionFilteredArray,
                    _.get(this, 'state.uniqueCompetitionListArray'),
                    'competitions'
                  )
                )}
              </div>
            </div>
          )}
        {/*My Competition*/}

        {/*My Event*/}
        {this.props.userEvents &&
          this.props.userEvents.meta &&
          this.props.userEvents.meta.count > 0 && (
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 dashbox">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                  <div style={css.heading1}>
                    {this.props.t('tutorSidebarMyEvents')}
                  </div>
                </div>
                {myEventListLoading === true ? (
                  <TodaysClassLoader cards={12} />
                ) : (
                  this.newDashboardCard(
                    userEventFilteredArray,
                    _.get(this, 'state.uniqueEventListArray'),
                    'events'
                  )
                )}
              </div>
            </div>
          )}
        {/*My Event*/}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userClasses: state.users.userClasses,
    userCamps: state.users.userCamps,
    userCompetitions: state.users.userCompetitions,
    userEvents: state.users.userEvents,
    sponsoredClasses: state.classes.onClassesSponsoredList,
    generalClasses: state.classes.onClassesList,
    userBaseProfile: state.userProfile.userBaseProfile,
    loggedInUserDetails: state.authentication,
    dependentUsers: state.userProfile.dependentUsers,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserClasses: (requestParams) =>
      dispatch(userAction.getUserClasses(requestParams)),
    getUserCamps: (requestParams) =>
      dispatch(userAction.getUserCamps(requestParams)),
    getUserCompetitions: (requestParams) =>
      dispatch(userAction.getUserCompetitions(requestParams)),
    getUserEvents: (requestParams) =>
      dispatch(userAction.getUserEvent(requestParams)),
    getDependentUsers: (requestParams) =>
      dispatch(userAction.getDependentUsers(requestParams)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserNewDashboard))
