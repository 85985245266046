import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';

function EmptyAnime(props) {
  const { AnimeType, animeheight, loop } = props;
  const animationContainer = useRef(null);

  useEffect(() => {
    // Load animation from URL based on AnimeType value
    const animation = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: loop || false,
      autoplay: true,
      path: `https://nurtem-s3.s3.us-west-2.amazonaws.com/lottie/${AnimeType}.json`, // Replace with your S3 bucket URL
    });

    return () => {
      // Clean up the animation when the component unmounts
      animation.destroy();
    };
  }, [AnimeType, loop]);

  return (
    <div ref={animationContainer} style={{ height: animeheight || 150 }} />
  );
}

export default EmptyAnime;
