import { get, isEmpty } from 'lodash';
import sanitizeUrl from './sanitizeUrl';
const formatUrlPart = (value) => sanitizeUrl(value);

const viewUrl = (provider, details, type, query = '') => {

  try {
    const firstname = formatUrlPart(get(provider, 'firstname', ''));
    const businessname = formatUrlPart(get(provider, 'businessname', firstname));
    const className = formatUrlPart(get(details, 'name', ''));
    const classId = formatUrlPart(get(details, 'id', ''));

    const urlPart = provider ? (get(provider, 'type') === 'Individual' ? firstname : businessname) : "-";

    let typeToSingular = type
    if (type === 'classes') {
      typeToSingular = 'class';
    } else if (type === 'camps') {
      typeToSingular = 'camp';
    } else if (type === 'competitions') {
      typeToSingular = 'competitions';
    } else if (type === 'events') {
      typeToSingular = 'event';
    }

    const baseRoute = type ? `/view-${typeToSingular}` : '';

    const urlClassName = !isEmpty(className) ? className : "-";

    const viewUrl = `${urlPart}/${urlClassName}/?gid=${classId}${query ? `?${query}` : ''}`;

    return baseRoute && classId ? `${baseRoute}/${viewUrl}` : '';
  } catch (error) {
    console.log('error > at get-view url.js function', error);
    return '';
  }
};

export default viewUrl;
