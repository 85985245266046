import * as actionTypes from '../../actions/tutor/actionTypes';

const initialState = {
  offline_payment_object: [],
  student_list_by_provider: [],
  student_payment_information: {},
  student_list_loaded: false,
  balance_amount: 0,
  reload: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.offline_payment_object:
      return { ...state, offline_payment_object: action.payload };

    case actionTypes.student_list_loaded:
      return { ...state, student_list_loaded: action.payload };

    case actionTypes.providerstudents:
      return { ...state, student_list_by_provider: action.payload };

    case actionTypes.on_receiving_student_payment_information:
      return { ...state, student_payment_information: action.payload };

    case actionTypes.balance_amount:
      return { ...state, balance_amount: action.payload };
    case actionTypes.reload:
      return { ...state, reload: action.payload };
    default:
      return state;
  }
};

export default reducer;
