import _ from 'lodash'
import { notification as antdNotification, message as antdMessage } from 'antd'
import axios from '../../utils/axios'

export const requestMiddleware = (requestOptions) => {
  // component request can have the following properties notification , callback, params, body

  // Create an instance of AbortController
  const controller = new AbortController()
  const signal = controller.signal

  const callback = _.get(requestOptions, 'componentRequest.callback', () => {})
  const actionType = _.get(requestOptions, 'actionType', '')
  const reducerName = _.get(requestOptions, 'reducerName', '')
  const dispatch = _.get(requestOptions, 'dispatch', () => {})

  // start loader for the property
  dispatch({
    type: reducerName,
    data: { [actionType]: {}, [`${actionType}${LoadingInProgress}`]: true },
  })

  axios({
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
    params: _.get(requestOptions, 'componentRequest.params', {}),
    data: _.get(requestOptions, 'componentRequest.body', {}),
    ..._.get(requestOptions, 'axiosParams', {}),
    signal: signal, // Include signal for cancellation
  })
    .then((response) => {
      dispatch({
        type: reducerName,
        data: { [actionType]: _.get(response, 'data', {}) },
      })
      callback(true, _.get(response, 'data', {}))
      buildNotification(
        true,
        _.get(response, 'data', {}),
        _.get(requestOptions, 'componentRequest.notification', {})
      )
      buildShortNotification(
        true,
        _.get(response, 'data', {}),
        _.get(requestOptions, 'componentRequest.shortNotification', {})
      )
    })
    .catch((error) => {
     // console.log(_.get(error, 'response.data', {}))
      callback(false, _.get(error, 'response.data', {}))
      buildNotification(
        false,
        _.get(error, 'response.data', {}),
        _.get(requestOptions, 'componentRequest.notification', {})
      )
      buildShortNotification(
        false,
        _.get(error, 'response.data', {}),
        _.get(requestOptions, 'componentRequest.shortNotification', {})
      )


    })
    .finally(() => {
      // stop loader for the property
      dispatch({
        type: reducerName,
        data: { [`${actionType}${LoadingInProgress}`]: false },
      })
    })
}

const buildNotification = (success, data, notification) => {
  // enable notification
  if (_.get(notification, 'enable', false)) {
    if (success) {
      const default_success_mssage = 'Operation done successfull.'
      const description = !_.isEmpty(
        _.get(notification, 'message.success', null)
      )
        ? _.get(notification, 'message.success', null)
        : _.get(data, 'message', default_success_mssage)
      const message = _.get(notification, 'message.title', 'success')
      antdNotification.success({
        message,
        description,
        ..._.get(notification, 'settings', {}),
      })
    } else {
      const default_error_mssage =
        'Something went wrong while processing the request.'
      let description = ''
      if (!_.isEmpty(_.get(notification, 'message.failure', null))) {
        description = _.get(notification, 'message.failure', null)
      } else if (
        !_.chain(data).get('errors[0].rules[0].message', null).isEmpty().value()
      ) {
        description = _.get(data, 'errors[0].rules[0].message')
      } else if (
        !_.chain(data).get('errors.rules[0].message', null).isEmpty().value()
      ) {
        description = _.get(data, 'errors.rules[0].message')
      } else {
        description = default_error_mssage
      }

      const message = _.get(notification, 'message.title', 'error')
      antdNotification.error({
        message,
        description,
        ..._.get(notification, 'settings', {}),
      })
    }
  }
}

const buildShortNotification = (success, data, shortNotification) => {
  // enable shortNotification
  if (_.get(shortNotification, 'enable', false)) {
    if (success) {
      const default_success_mssage = 'Operation done successfull.'
      const message = !_.isEmpty(
        _.get(shortNotification, 'message.success', null)
      )
        ? _.get(shortNotification, 'message.success', null)
        : _.get(data, 'message', default_success_mssage)
      antdMessage.success(message)
    } else {
      const default_error_mssage =
        'Something went wrong while processing the request.'
      const message = !_.isEmpty(
        _.get(shortNotification, 'message.failure', null)
      )
        ? _.get(shortNotification, 'message.failure', null)
        : _.get(data, 'errors[0].rules[0].message', default_error_mssage)
      antdMessage.error(message)
    }
  }
}

export const LoadingInProgress = 'LoadingInProgress'

export const isGeneralPropertyLoadingInProgress = (reducer_state, actionType) =>
  _.get(reducer_state, `${actionType}${LoadingInProgress}`, false)
