import React, { useState, useEffect } from 'react';
import { notification, Table } from 'antd';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import axiostoServer from '../../../../utils/axios';
import EmptyAnime from '../../../../components/re-usable/lottie/EmptyAnime';


const WaitingList = (props) => {
    // console.log("props>>>>>>>>>>>>>", props)
    const session = _.get(props, 'session', {})
    const gid = _.get(session, 'group_id', '')
    const sid = _.get(session, 'id', '')
    const type = _.get(session, 'type', '')

    const [wishListItems, setWishListItems] = useState({});
    const [wishListItemsLoading, setWishListItemsLoading] = useState(false);

    const getWishListItems = async () => {
        try {
            setWishListItemsLoading(true);
            const resp = await axiostoServer({
                method: 'get',
                url: '/providers/waitlist',
                withCredentials: true,
                params: {
                    gid: gid,
                    status: 2,
                    sid: sid,
                    type: type
                },
                headers: { 'Content-Type': 'application/json' },
            });

            if (resp.status === 200) {
                setWishListItems(resp.data.details);
                console.log("response>>>>>>>>", resp.data.details)
                setWishListItemsLoading(false);
                return (resp.data.details);
            }
            setWishListItemsLoading(false);
            notification.error({
                description: props.t('errInWishlist'),
                message: props.t('error'),
            });
        } catch (error) {
            notification.error({
                description: props.t('errInWishlist'),
                message: props.t('error'),
            });
            console.error('Error fetching session purchase:', error);
        }
    };

    useEffect(() => {
        getWishListItems();
    }, []);

    const dataSourceUpdated = []

    for (const key in wishListItems) {
        const clsData = wishListItems[key]
        // console.log("datanew>>>>>>>>>>>>>>", clsData)

        const for_who = _.get(clsData, 'user', '')
        const userfName = _.get(for_who, 'firstname', '')
        const sessionId = _.get(clsData, 'session.id', '')
        const data = {
            userfName,
            sessionId,
        }
        dataSourceUpdated.push(data)
    }
    const columns = [
        {
            title: 'Students',
            dataIndex: 'userfName',
            key: 'userfName',
        },
    ];
    // console.log("dataSource>>>>>>>>>>", dataSourceUpdated)

    const render = () => (
        <>
            {!_.isEmpty(wishListItems) ? <div className='mr-3'>
                <div style={{ fontSize: 14, fontWeight: 600, marginBottom: 10 }}>
                    No.of Student Requested: {dataSourceUpdated.length}
                </div>
                <Table
                    columns={columns}
                    dataSource={dataSourceUpdated}
                    loading={wishListItemsLoading}
                    pagination={false}
                /></div> :
                <div>
                    <EmptyAnime AnimeType="Empty" loop animeheight={150} />
                    <p className="empty-animations">No Wait List</p>
                </div>
            }
        </>
    );
    return render();
};

export default (withTranslation()(WaitingList));
