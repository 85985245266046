import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const disabledStartDate = (current, start_date, end_date) => {
  try {
    const today = dayjs()

    if (end_date && current) {
      const startDate = dayjs(start_date)
      const endDate = dayjs(end_date)

      return (
        current.isBefore(today, 'day') ||
        current.isSameOrAfter(endDate, 'day') ||
        current.isBefore(startDate, 'day')
      )
    }
    return current.isBefore(today, 'day')
  } catch (error) {
    console.log('error at disabledStartDate', error)
  }
}

const disabledEndDate = (current, start_date,type) => {
  try {
    const today = dayjs()
    if (current && start_date) {
      const minimumEndDate = (type==='events' ? dayjs(start_date) : dayjs(start_date).add(1, 'day'))
      return (
          (type==='events' ? 
          current < minimumEndDate
          : current && (current < minimumEndDate || current < dayjs().endOf('day')))
      )
    }
    if (current && !start_date) {
      return current < today
    }
  } catch (error) {
    console.log('error at disabledEndDate', error)
  }
}

const disabledCompetitionStartDate = (
  current,
  start_date,
  end_date,
  teaching_type
) => {
  if (!end_date || !start_date) {
    return true
  }
  // const startDate = dayjs(start_date);
  const endDate = dayjs(end_date)

  if (teaching_type === '2') {
    // Disable dates before the current date or after the end date
    return current && current.isSameOrBefore(endDate, 'day')
  }
  // Disable dates before the current date or before the start date
  return teaching_type === 'events' 
  ? current && current.isBefore(endDate, 'day') 
  : current && current.isSameOrBefore(endDate, 'day');
}

const disabledCompetitionEndDate = (current, start_date) => {
  if (!start_date) {
    return true
  }
  const today = dayjs()
  if (current && start_date) {
    const minimumEndDate = dayjs(start_date).add(1, 'day')
    return (
      current && (current < minimumEndDate || current < dayjs().endOf('day'))
    )
  }
  if (current && !start_date) {
    return current < today
  }
}

const disabledCompetitionResultDate = (current, start_date) => {
  if (!start_date) {
    return true
  }
  const today = dayjs()
  if (current && start_date) {
    const minimumEndDate = dayjs(start_date).add(1, 'day').startOf('day')
    return (
      current && (current < minimumEndDate || current < dayjs().startOf('day'))
    )
  }
  if (current && !start_date) {
    return current < today
  }
}

export {
  disabledStartDate,
  disabledEndDate,
  disabledCompetitionStartDate,
  disabledCompetitionEndDate,
  disabledCompetitionResultDate,
}
