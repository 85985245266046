import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateCampsState;
// tutor actions

export default {
  // get nurtem services list
  view: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/camps/view',
      },
      actionType: actionType.camps.view,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },

};
