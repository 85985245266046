import { notification } from 'antd';
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import * as routeName from '../../../components/route-names';
import { resetPage } from './add-class';
import history from '../../../utils/history';

export const EventsSuccess = (data) => ({
  type: actionTypes.providerEvents,
  payload: data,
});

export const disableEventsLoading = () => ({
  type: actionTypes.disableEventsLoading,
  loadingcompList: { loadingcompList: false },
});

export const enableEventsLoading = () => ({
  type: actionTypes.enableEventsLoading,
  loadingcompList: { loadingcompList: true },
});

export const getProviderCompetitions = (requestParams) => (dispatch) => {
  dispatch(enableEventsLoading());
  axios({
    method: 'get',
    url: '/providers/events',
    params: requestParams,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(EventsSuccess(response.data));
        dispatch(disableEventsLoading());
      }
    })
    .catch((error) => {
      dispatch(disableEventsLoading());
    });
};

export const classFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorEventsDetailsFoundSuccess,
    success: {
      ...data,
      randomKey: Math.random()
        .toString(36)
        .substring(5)
        .toUpperCase(),
    },
  });
};

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const onGetCompetitionDetails = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios
    .get('/events/view', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(classFoundSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const competitionDeleteFoundSuccess = (data) => ({
  type: actionTypes.tutorCompetitionsDeleteFoundSuccess,
  success: data,
});

export const onClearCompetitionDeleteDetail = () => (dispatch) => {
  dispatch(competitionDeleteFoundSuccess({}));
};

export const onDeleteCompetition = (requestParams, callback = () => { }) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/events/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(competitionDeleteFoundSuccess(deleteResponse));
        dispatch(disableLoading());
        history.push(`${routeName.events}`);
        callback();
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;
      deleteResponse.success = false;
      dispatch(competitionDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};