/**
 * @author ramsteyn17@gmail.com
 * @dated 24/03/2023
 * This module is for User Payment Report Which has the code for the filter options.
 */

import React, { useEffect, useRef, useState } from 'react'
import { DatePicker, Form, Select, Button } from 'antd'
import { connect } from 'react-redux'
import * as userAction from '../../../store/actions/users'
import _ from 'lodash'
import './style.css'
import dayjs from 'dayjs'
import { withTranslation } from 'react-i18next'
import FiltersReport from './report'
import FiltersReportAtt from '../attendance-report/report'
import css from '../../../utils/CSSUtility'

const { RangePicker } = DatePicker

const PaymentReportFilter = (props) => {
  const [form] = Form.useForm()
  const mounted = useRef()
  const [filterDetails, setFilterDetails] = useState({})
  const [refreshKey, setRefreshKey] = useState(0)
  const [classeslist, setClassesList] = useState([])
  const [campslist, setCampsList] = useState([])
  const [compslist, setCompsList] = useState([])
  const [Eventslist, setEventsList] = useState([])
  const [uniqueClass, setUniqueClass] = useState([])
  const [uniqueCamps, setUniqueCamps] = useState([])
  const [uniqueComps, setUniqueComps] = useState([])
  const [uniqueEvents, setUniqueEvents] = useState([])
  const [userlist, setUserList] = useState('')
  const [depnlist, setdepnList] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [selectedUserName, setSelectedUserName] = useState(null)
  const [selectedClass, setSelectedClass] = useState(null)
  const [selectedCamp, setSelectedCamp] = useState(null)
  const [selectedComp, setSelectedComp] = useState(null)
  const [selectedEvent, setSelectedEvent] = useState(null)
  const dependentUsers = _.get(props, 'dependentUsers')
  const userBaseProfile = _.get(props, 'userBaseProfile')
  useEffect(() => {
    window.scrollTo(0, 0)
    if (!mounted.current) {
      props.getDependentUsers()
      mounted.current = true
    }
    setdepnList(_.get(props, 'dependentUsers.items', []))
    setUserList(_.get(props, 'userBaseProfile'))
  }, [refreshKey, dependentUsers, userBaseProfile])

  /* Get Class,camp,competition session List */
  const { getUserClasses, getUserCamps, getCompetitionList, getEventList } = props
  useEffect(() => {
    // When the component mounts, get the user classes,camps,competitions
    getUserClasses({
      limit: 1000,
      sort: 'updated_at.desc',
      expand: 'class_details,camp_details,dependent_user,provider',
    })
    getUserCamps({
      limit: 1000,
      sort: 'updated_at.desc',
      expand: 'camp_details,dependent_user,provider',
    })
    getCompetitionList({
      limit: 1000,
      sort: 'updated_at.desc',
      expand: 'competition_details,dependent_user,provider',
    })
    getEventList({
      limit: 1000,
      sort: 'updated_at.desc',
      expand: 'event_details,dependent_user,provider',
    })
  }, [getUserClasses, getUserCamps, getCompetitionList, getEventList])

  /* Set Class session List */
  useEffect(() => {
    // When the userClasses prop updates, update the classeslist state
    setClassesList(props.userClasses)
  }, [props.userClasses])

  /* Set Camp session List */
  useEffect(() => {
    setCampsList(props.userCamps)
  }, [props.userCamps])

  /* Set Competition session List */
  useEffect(() => {
    setCompsList(props.userCompetitions)
  }, [props.userCompetitions])

  /* Set Event session List */
  useEffect(() => {
    setEventsList(props.userEvents)
  }, [props.userEvents])

  useEffect(() => {
    if (_.get(classeslist, 'items.length', 0) > 0) {
      setUniqueClass('')

      const uniqueClasses = classeslist.items
        .filter(
          (item) =>
            _.get(item, 'class_details.id') !== null &&
            _.get(item, 'class_details.id') !== undefined
        )
        .filter(
          (item, index, self) =>
            self.findIndex(
              (t) => t.class_details.id === item.class_details.id
            ) === index
        )
        .map((item) => item.class_details)

      setUniqueClass(uniqueClasses)
    }
  }, [classeslist, classeslist.items])

  useEffect(() => {
    // Filter out objects with the same id
    if (_.get(campslist, 'items.length', 0) > 0) {
      setUniqueCamps('')
      const uniqueClasses = campslist.items
        .filter(
          (item) =>
            _.get(item, 'camp_details.id', null) !== null &&
            _.get(item, 'camp_details.id') !== undefined
        )
        .filter(
          (item, index, self) =>
            self.findIndex(
              (t) => t.camp_details.id === item.camp_details.id
            ) === index
        )
        .map((item) => item.camp_details)
      console.log('camp=========', uniqueClasses)
      setUniqueCamps(uniqueClasses)
    }
  }, [campslist, campslist.items])

  useEffect(() => {
    // Filter out objects with the same id
    if (_.get(Eventslist, 'items.length', 0) > 0) {
      setUniqueEvents('')
      const uniqueClasses = Eventslist.items
        .filter(
          (item) =>
            _.get(item, 'event_details.id', null) !== null &&
            _.get(item, 'event_details.id') !== undefined
        )
        .filter(
          (item, index, self) =>
            self.findIndex(
              (t) => t.event_details.id === item.event_details.id
            ) === index
        )
        .map((item) => item.event_details)
      setUniqueEvents(uniqueClasses)
      console.log('event=========', uniqueClasses)
    }
    console.log('event12=========', Eventslist)
  }, [Eventslist, Eventslist.items])

  useEffect(() => {
    // Filter out objects with the same id
    if (_.get(compslist, 'items.length', 0) > 0) {
      setUniqueComps('')
      const uniqueClasses = compslist.items
        .filter(
          (item) =>
            _.get(item, 'competition_details.id', null) !== null &&
            _.get(item, 'competition_details.id') !== undefined
        )
        .filter(
          (item, index, self) =>
            self.findIndex(
              (t) => t.competition_details.id === item.competition_details.id
            ) === index
        )
        .map((item) => item.competition_details)
      setUniqueComps(uniqueClasses)
    }
  }, [compslist, compslist.items])

  const {
    userClassIndividualSuccess,
    userClassIndividualSuccessItems,
    userCampsIndividualSuccess,
    userCampsIndividualSuccessItems,
    userCompsIndividualSuccess,
    userCompsIndividualSuccessItems,
    userEventsIndividualSuccess,
    userEventsIndividualSuccessItems,
  } = props
  useEffect(() => {
    // When the userClasses prop updates, update the classeslist state
    setClassesList(userClassIndividualSuccess)
  }, [userClassIndividualSuccessItems])

  useEffect(() => {
    // When the userClasses prop updates, update the campslist state
    setCampsList(userCampsIndividualSuccess)
  }, [userCampsIndividualSuccessItems])

  useEffect(() => {
    // When the userClasses prop updates, update the competitqlist state
    setCompsList(userCompsIndividualSuccess)
  }, [userCompsIndividualSuccessItems])

  useEffect(() => {
    // When the userClasses prop updates, update the eventlist state
    setEventsList(userEventsIndividualSuccess)
  }, [userEventsIndividualSuccessItems])

  const updatedUserList = {
    ...userlist,
    type: 'user',
    firstname: 'Myself',
    lastname: '',
  }

  const updatedDependentUser = [...depnlist, updatedUserList]

  const onUserSelect = (userId, key) => {
    const value = JSON.parse(key.value)
    let requestParams
    if (value.type === 'user') {
      setSelectedUser({ type: 'user', id: value.id })
      setSelectedUserName(key.children)
      requestParams = {
        user: value.id,
        dependent_user: null,
        sort: 'created_at.desc',
        limit: 1000,
      }
    } else {
      setSelectedUser({ type: 'dependent_user', id: value.id })
      setSelectedUserName(key.children)
      requestParams = {
        dependent_user: value.id,
        sort: 'created_at.desc',
        limit: 1000,
      }
    }
    // if (!selectedClass) {
    form.resetFields(['class_details', 'camp_details', 'competitions', 'event_details'])
    setSelectedClass(null)
    setSelectedCamp(null)
    setSelectedComp(null)
    setSelectedEvent(null)
    setUniqueClass(null)
    setUniqueCamps(null)
    setUniqueComps(null)
    setUniqueEvents(null)
    props.getUserClassesIndividual({
      ...requestParams,
      expand: 'class_details',
      type: 'classes',
    })
    props.getUserCampsIndividual({
      ...requestParams,
      expand: 'camp_details',
      type: 'camps',
    })
    if (props.forType !== 'attendance') {
      props.getUserCompetitionsIndividual({
        ...requestParams,
        expand: 'competition_details',
        type: 'competitions',
      })
      props.getUserEventsIndividual({
        ...requestParams,
        expand: 'event_details',
        type: 'events',
      })
    }
    // }
    form.submit()
  }

  const onDateSelect = () => {
    form.submit()
  }

  const onClassesSelect = (classId, key) => {
    form.resetFields(['camp_details', 'competition_details', 'event_details'])
    setSelectedCamp(null)
    setSelectedComp(null)
    setSelectedEvent(null)
    setSelectedClass(key.value)
    form.submit()
  }

  const onCampsSelect = (classId, key) => {
    form.resetFields(['class_details', 'competition_details', 'event_details'])
    setSelectedClass(null)
    setSelectedComp(null)
    setSelectedEvent(null)
    setSelectedCamp(key.value)
    form.submit()
  }

  const onCompsSelect = (classId, key) => {
    form.resetFields(['class_details', 'camp_details', 'event_details'])
    setSelectedClass(null)
    setSelectedCamp(null)
    setSelectedEvent(null)
    setSelectedComp(key.value)
    form.submit()
  }
  const onEventsSelect = (classId, key) => {
    form.resetFields(['class_details', 'camp_details', 'competition_details'])
    setSelectedClass(null)
    setSelectedCamp(null)
    setSelectedComp(null)
    setSelectedEvent(key.value)
    form.submit()
  }

  const onFinish = async (values) => {
    let requestParams = { for: 'user' }
    const start_date =
      !_.isEmpty(_.get(values, 'date')) &&
      dayjs(_.get(values, 'date[0]')).format('MM-DD-YYYY')
    const end_date =
      !_.isEmpty(_.get(values, 'date')) &&
      dayjs(_.get(values, 'date[1]')).format('MM-DD-YYYY')

    if (!_.isEmpty(selectedUser)) {
      if (selectedUser.type === 'user') {
        requestParams.user = selectedUser.id
        requestParams.user_details = selectedUserName
      } else {
        requestParams.dependent_user = selectedUser.id
        requestParams.user_details = selectedUserName
      }
    }

    if (!_.isEmpty(selectedClass)) {
      requestParams.class_details = selectedClass
    }

    if (!_.isEmpty(selectedCamp)) {
      requestParams.camp_details = selectedCamp
    }

    if (!_.isEmpty(selectedComp)) {
      requestParams.competition_details = selectedComp
    }

    if (!_.isEmpty(selectedEvent)) {
      requestParams.event_details = selectedEvent
    }

    if (!_.isEmpty(_.get(values, 'date'))) {
      const start = `${start_date}`
      const end = `${end_date}`
      requestParams.created_at_gte = start
      requestParams.created_at_lte = end
    }

    if (
      _.isEmpty(selectedUser) &&
      _.isEmpty(selectedClass) &&
      _.isEmpty(_.get(values, 'date')) &&
      _.isEmpty(selectedCamp) &&
      _.isEmpty(selectedComp) &&
      _.isEmpty(selectedEvent)
    ) {
      // All fields are empty
      requestParams = {
        class_details: selectedClass,
        camp_details: selectedCamp,
        competition_details: selectedComp,
        event_details: selectedEvent,
        created_at_gte: `${start_date}`,
        created_at_lte: `${end_date}`,
        expand:
          'user,class_information_details,provider,dependent_user,class_details,camp_details',
      }
    }

    requestParams.expand =
      'user,class_information_details,provider,dependent_user,class_details,camp_details'
    delete requestParams.date
    await setFilterDetails(requestParams)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const clearInput = () => {
    form.resetFields()
    setFilterDetails({})
    setSelectedClass(null)
    setSelectedCamp(null)
    setSelectedUser(null)
    setSelectedUserName(null)
    setRefreshKey((oldKey) => oldKey + 1)
  }

  const clearInputClass = () => {
    form.resetFields(['class_details'])
    setSelectedClass(null)
    setRefreshKey((oldKey) => oldKey + 1)
  }
  const clearInputCamp = () => {
    form.resetFields(['camp_details'])
    setSelectedCamp(null)
    setRefreshKey((oldKey) => oldKey + 1)
  }

  const render = () => (
    <div className="row">
      <Form
        form={form}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="full-width"
      >
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="attendance-report mt-3">
            <div className="row">
              <div
                className="col-12 d-flex justify-content-between"
                style={{ height: '20px' }}
              >
                <div
                  className={
                    props.forType === 'attendance'
                      ? 'attendance-title'
                      : 'payment-title'
                  }
                >
                  {' '}
                  {props.forType === 'attendance' ? (
                    <span style={css.heading1}>
                      {props.t('tutorSidebarAttendanceReport')}
                    </span>
                  ) : (
                    <span style={css.heading1}>
                      {props.t('tutorSidebarPaymentReport')}
                    </span>
                  )}{' '}
                </div>
                <Button
                  type='primary'
                  className="class-btn"
                  onClick={() => clearInput()}
                >
                  {' '}
                  {props.t('clearFilter')}{' '}
                </Button>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <div className="full-width">
                  <Form.Item
                    label={
                      <span style={css.label}>{props.t('filterByUser')}</span>
                    }
                    name="user"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your tutor!',
                      },
                    ]}
                    className="mb-2"
                  >
                    <Select
                      showSearch
                      placeholder="Select / Search a user name"
                      optionFilterProp="children"
                      allowClear
                      onSelect={onUserSelect}
                    >
                      {_.size(updatedDependentUser) > 0 &&
                        _.map(updatedDependentUser, (each, i) => {
                          const value = JSON.stringify({
                            id: _.get(each, 'id', ''),
                            type: _.get(each, 'type', ''),
                          })
                          return (
                            <Select.Option
                              value={value}
                              key={_.get(each, 'id')}
                            >
                              {`${_.startCase(
                                _.get(each, 'firstname', '')
                              )} ${_.startCase(_.get(each, 'lastname', ''))}`}
                            </Select.Option>
                          )
                        })}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                <div className="full-width">
                  <Form.Item
                    label={
                      <span style={css.label}>{props.t('filterByClass')}</span>
                    }
                    name="class_details"
                    rules={[
                      {
                        required: false,
                        message: 'Please input your class!',
                      },
                    ]}
                    className="mb-2"
                  >
                    <Select
                      showSearch
                      placeholder="Select / Search a class name"
                      optionFilterProp="children"
                      allowClear
                      onSelect={onClassesSelect}
                      onClear={clearInputClass}
                    >
                      {_.size(uniqueClass) > 0 &&
                        _.map(uniqueClass, (value, key) => (
                          <Select.Option
                            value={_.get(value, 'id')}
                            key={_.get(value, 'id')}
                          >
                            {_.get(value, 'session_name')}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="full-width">
                  <Form.Item
                    label={
                      <span style={css.label}>{props.t('filterByCamp')}</span>
                    }
                    name="camp_details"
                    rules={[
                      { required: false, message: 'Please input your camp!' },
                    ]}
                    className="mb-2"
                  >
                    <Select
                      showSearch
                      placeholder="Select / Search a camp name"
                      optionFilterProp="children"
                      allowClear
                      onSelect={onCampsSelect}
                      onClear={clearInputCamp}
                    >
                      {_.size(uniqueCamps) > 0 &&
                        _.map(uniqueCamps, (value, key) => (
                          <Select.Option
                            value={_.get(value, 'id')}
                            key={_.get(value, 'id')}
                          >
                            {_.get(value, 'session_name')}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              {props.forType !== 'attendance' && (
                <>
                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="full-width">
                      <Form.Item
                        label={
                          <span style={css.label}>Filter by Competition</span>
                        }
                        name="competition_details"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your camp!',
                          },
                        ]}
                        className="mb-2"
                      >
                        <Select
                          showSearch
                          placeholder="Select / Search a Competition name"
                          optionFilterProp="children"
                          allowClear
                          onSelect={onCompsSelect}
                        >
                          {_.size(uniqueComps) > 0 &&
                            _.map(uniqueComps, (value, key) => (
                              <Select.Option
                                value={_.get(value, 'id')}
                                key={_.get(value, 'id')}
                              >
                                {_.get(value, 'session_name')}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="full-width">
                      <Form.Item
                        label={
                          <span style={css.label}>Filter by Event</span>
                        }
                        name="event_details"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your event!',
                          },
                        ]}
                        className="mb-2"
                      >
                        <Select
                          showSearch
                          placeholder="Select / Search a Event name"
                          optionFilterProp="children"
                          allowClear
                          onSelect={onEventsSelect}
                        >
                          {_.size(uniqueEvents) > 0 &&
                            _.map(uniqueEvents, (value, key) => (
                              <Select.Option
                                value={_.get(value, 'id')}
                                key={_.get(value, 'id')}
                              >
                                {_.get(value, 'session_name')}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </div>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="full-width">
                      <Form.Item
                        label={<span style={css.label}>Filter by Date</span>}
                        name="date"
                        rules={[
                          {
                            required: false,
                            message: 'Please input your date!',
                          },
                        ]}
                        className="mb-4"
                      >
                        <RangePicker onChange={onDateSelect} />
                      </Form.Item>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Form>
      {props.forType === 'attendance' ? (
        <div className="gappadding col-12 ">
          <FiltersReportAtt
            filterDetails={filterDetails}
            session_details={selectedClass || selectedCamp}
            selected_user={selectedUser}
            type={selectedClass ? 'classes' : 'camps'}
          />
        </div>
      ) : (
        <div className="col-12 ">
          <FiltersReport filterDetails={filterDetails} />
        </div>
      )}
    </div>
  )

  return render()
}

const mapStateToProps = (state) => ({
  userClasses: state.users.userClasses,
  userCamps: state.users.userCamps,
  userClassIndividualSuccessItems: state.users.userClassIndividualSuccess.items,
  userClassIndividualSuccess: state.users.userClassIndividualSuccess,
  userCampsIndividualSuccessItems: state.users.userCampsIndividualSuccess.items,
  userCampsIndividualSuccess: state.users.userCampsIndividualSuccess,
  userCompsIndividualSuccessItems: state.users.userCompsIndividualSuccess.items,
  userCompsIndividualSuccess: state.users.userCompsIndividualSuccess,
  userEventsIndividualSuccessItems: state.users.userEventsIndividualSuccess.items,
  userEventsIndividualSuccess: state.users.userEventsIndividualSuccess,
  dependentUsers: state.userProfile.dependentUsers,
  userBaseProfile: state.userProfile.userBaseProfile.details,
  userCompetitions: state.users.userCompetitions,
  userEvents: state.users.userEvents,

})

const mapDispatchToProps = (dispatch) => ({
  getDependentUsers: () => dispatch(userAction.getDependentUsers()),
  getUserClassesIndividual: (requestParams) =>
    dispatch(userAction.getUserClassesIndividual(requestParams)),
  getUserCampsIndividual: (requestParams) =>
    dispatch(userAction.getUserCampsIndividual(requestParams)),
  getUserCompetitionsIndividual: (requestParams) =>
    dispatch(userAction.getUserCompetitionsIndividual(requestParams)),
  getUserEventsIndividual: (requestParams) =>
    dispatch(userAction.getUserEventsIndividual(requestParams)),
  getUserClasses: (requestParams) =>
    dispatch(userAction.getUserClasses(requestParams)),
  getUserCamps: (requestParams) =>
    dispatch(userAction.getUserCamps(requestParams)),
  getCompetitionList: (requestParams) =>
    dispatch(userAction.getUserCompetitions(requestParams)),
  getEventList: (requestParams) =>
    dispatch(userAction.getUserEvent(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PaymentReportFilter))
