import React, { Component } from 'react'
import { Form, TimePicker, Button } from 'antd'
import dayjs from 'dayjs'
import { connect } from 'react-redux'
import css from '../../../../utils/CSSUtility'
import { withTranslation } from 'react-i18next'

class TimeShedule extends Component {
  constructor(props) {
    super(props)
  }

  renderTimePicker(label, name, value, isStartTime) {
    const { t } = this.props
    const thisRowKey = name.split('_')[2]

    return (
      <Form.Item
        label={<span style={css.label}>{t(label)}</span>}
        tooltip={t(label)}
        validateTrigger={['onChange', 'onBlur']}
        name={name}
        initialValue={value}
        rules={[
          {
            required: true,
            message: t(`${label} required`),
          },
          ({ getFieldValue }) => ({
            validator(_, time) {
              const startTime = getFieldValue(`start_time_${thisRowKey}`)
              const endTime = getFieldValue(`end_time_${thisRowKey}`)

              if (isStartTime) {
                if (!time || !endTime) {
                  return Promise.resolve()
                }
                if (time.isBefore(endTime)) {
                  return Promise.resolve()
                }
                return Promise.reject(t('Start time must be before end time'))
              } else {
                if (!time || !startTime) {
                  return Promise.resolve()
                }
                if (time.isAfter(startTime)) {
                  return Promise.resolve()
                }
                return Promise.reject(t('End time must be after start time'))
              }
            },
          }),
        ]}
      >
        <TimePicker
          style={{ width: '100%' }}
          allowClear
          placeholder={'00:00 AM/PM'}
          getPopupContainer={(trigger) => trigger.parentNode}
          use12Hours
          format="hh:mm A"
          defaultValue={dayjs('00:00', 'HH:mm')}
          showNow={false}
          needConfirm={false}
        />
      </Form.Item>
    )
  }

  generateWeekdaysArray = (obj) => {
    const { variant, variantSelectedDays } = obj
    let weekdays = []
    const shortToFull = {
      mo: 'Monday',
      tu: 'Tuesday',
      we: 'Wednesday',
      th: 'Thursday',
      fr: 'Friday',
      sa: 'Saturday',
      su: 'Sunday',
    }

    const selectedDaysArray = Array.isArray(variantSelectedDays)
      ? variantSelectedDays
      : [variantSelectedDays]

    if (variant.includes('wd')) {
      weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']
    } else if (variant.includes('wend')) {
      weekdays = ['Saturday', 'Sunday']
    } else if (variant.includes('fw')) {
      weekdays = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
      ]
    } else if (variant.includes('o')) {
      weekdays = selectedDaysArray?.map((short) => shortToFull[short])
    }

    return weekdays
  }

  handleSubmit = (value) => {
    const { handleOk } = this.props

    const groupedByDay = {}
    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        const dayArr = key.split('_')

        const prefix = `${dayArr[0]}_${dayArr[1]}`

        const day = dayArr[2]

        if (!groupedByDay[day]) {
          groupedByDay[day] = {}
        }
        groupedByDay[day][prefix] = dayjs(value[key] ?? '').toISOString()
      }
    }

    this.props.setTimeSlot(groupedByDay)
    handleOk()
  }

  render() {
    const { getWeekdayShedule, getTimeSlot } = this.props
    const weekdayShedule = this.generateWeekdaysArray(getWeekdayShedule())
    const timeSlot = getTimeSlot()

    return (
      <div>
        <Form ref={this.timeSlotRef} onFinish={this.handleSubmit}>
          {weekdayShedule.map((value, index) => {
            let start_time = ''
            let end_time = ''

            if (Object.hasOwn(timeSlot, value)) {
              start_time = dayjs
                .utc(timeSlot[value]['start_time'])
                .tz(dayjs.tz.guess())
                .locale('en')

              end_time = dayjs
                .utc(timeSlot[value]['end_time'])
                .tz(dayjs.tz.guess())
                .locale('en')
            }

            return (
              <div className="row mb-3" key={value}>
                <div className="col-md-3">
                  <div>{value}</div>
                </div>
                <div className="col-md-3">
                  {this.renderTimePicker(
                    'Start Time',
                    `start_time_${value}`,
                    start_time,
                    true
                  )}
                </div>
                <div className="col-md-3">
                  {this.renderTimePicker(
                    'End Time',
                    `end_time_${value}`,
                    end_time,
                    false
                  )}
                </div>
              </div>
            )
          })}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    // Map Redux state to component props
    // Example:
    // someStateProp: state.someReducer.someStateProp
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // Map dispatch actions to component props
    // Example:
    // someAction: () => dispatch(someAction())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TimeShedule))
