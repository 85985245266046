import React from 'react'
import { Tabs, Table, Space } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as userAction from '../../../store/actions/users'
import * as routeName from '../../../components/route-names'
import * as servicesActions from '../../../store/actions/public/Services'
import './user-my-camp.css'
import history from '../../../utils/history'
import * as action from '../../../store/actions/public/Classes'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import nextSession from '../../../components/tutor/nextSession'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import _ from 'lodash'
import css from '../../../utils/CSSUtility'

class Camps extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      classTabs: '1',
      today_schedules: [],
      page: 1,
      limit: 100,
      classesTableData: [],
      expiredClassesTableData: [],
      timeZones: 'Asia/Kolkata',
      provider_id:
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.details &&
        this.props.tutorBaseProfile.details.id,
      classRow_id: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    this._isMounted = true
    if (
      _.get(this, 'props.userBaseProfile.details.user.account_type') !==
      'dependent'
    ) {
      this.props.getDependentUsers()
    }

    const requestParams = {}
    requestParams.limit = 100
    requestParams.expand = 'provider,dependent_user,camp_details'
    requestParams.sort = 'updated_at.desc'
    this.props.getCampsList(requestParams)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidUpdate(prevPros) {
    if (
      prevPros.userCamps !== this.props.userCamps &&
      this.props.userCamps.items &&
      this.props.userCamps.items.length > 0
    ) {
      this.setState((prevState) => ({
        classesTableData: [
          ...prevState.classesTableData,
          ...this.props.userCamps.items.filter(item => !this.isExpired(item)),
        ],
        expiredClassesTableData: [
          ...prevState.expiredClassesTableData,
          ...this.props.userCamps.items.filter(item => this.isExpired(item)),
        ],
      }))
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  linkwrap = (data) => (
    <div>
      <a
        style={{ color: 'rgb(24, 144, 255)' }}
        target="_blank"
        href={data}
        rel="noreferrer"
      >{`${_.truncate(data, { length: 25, omission: ' ...' })}`}</a>
    </div>
  )

  classStatus = (name, code) => {
    switch (code) {
      case 0:
        return `${name}(Awaiting for Tutor response.)`
      case 2:
        return `${name}(Tutor rejected your request.)`
      case 1:
      default:
        return name
    }
  }

  isExpired = (camp) => {
    const endDate = new Date(_.get(camp, 'camp_details.end_date'))
    return endDate < new Date()
  }

  render() {
    const { classRow_id } = this.state
    const ListingClasscolumns = [
      {
        title: this.props.t('batchLabel2'),
        key: 'class',
        dataIndex: 'class',
        width: '15%',
      },
      {
        title: this.props.t('toWhom'),
        dataIndex: 'to_whom',
        key: 'to_whom',
        width: '10%',
      },
      {
        title: this.props.t('nextSession'),
        dataIndex: 'next_session',
        key: 'next_session',
        width: '15%',
      },
      {
        title: this.props.t('sDate'),
        dataIndex: 'session',
        key: 'session',
        width: '15%',
      },
      {
        title: this.props.t('eDate'),
        dataIndex: 'end_date',
        key: 'end_date',
        width: '15%',
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
        width: '10%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '8%',
        render: (text, record) => {
          console.log('>>>>>record ', record)
          console.log('>>>>>record text', text)
          const status = _.get(record, 'status')

          if (status !== 2) {
            const activeuser = {
              to_whom: _.get(record, 'to_whom'),
              to_whom_id: _.get(record, 'to_whom_id'),
              user_type: _.get(record, 'user_type'),
            }
            const link = `${routeName.userCampView}/${encodeURIComponent(_.get(record, 'class'))}/?campId=${_.get(record, 'id', '')}&userid=${_.get(activeuser, 'to_whom_id', '')}&usertype=${_.get(activeuser, 'user_type')}&username=${_.get(activeuser, 'to_whom')}`
            return (
              <Space size="middle">
                <div className="d-flex">
                  <button
                    onClick={() => history.push(link)}
                    className="btn btn-accept  mr-1"
                  >
                    {this.props.t('details')}
                  </button>
                </div>
              </Space>
            )
          } else {
            return 'Rejected booking id (' + _.get(record, 'id', '') + ')'
          }
        },
      },
    ]
    const expiredListingClasscolumns = [
      {
        title: this.props.t('batchLabel2'),
        key: 'class',
        dataIndex: 'class',
        width: '15%',
      },
      {
        title: this.props.t('toWhom'),
        dataIndex: 'to_whom',
        key: 'to_whom',
        width: '10%',
      },
      {
        title: this.props.t('sDate'),
        dataIndex: 'session',
        key: 'session',
        width: '15%',
      },
      {
        title: this.props.t('eDate'),
        dataIndex: 'end_date',
        key: 'end_date',
        width: '15%',
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
        width: '10%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '8%',
        render: (text, record) => {
          console.log('>>>>>record ', record)
          console.log('>>>>>record text', text)
          const status = _.get(record, 'status')

          if (status !== 2) {
            const activeuser = {
              to_whom: _.get(record, 'to_whom'),
              to_whom_id: _.get(record, 'to_whom_id'),
              user_type: _.get(record, 'user_type'),
            }
            const link = `${routeName.userCampView}/${encodeURIComponent(_.get(record, 'class'))}/?campId=${_.get(record, 'id', '')}&userid=${_.get(activeuser, 'to_whom_id', '')}&usertype=${_.get(activeuser, 'user_type')}&username=${_.get(activeuser, 'to_whom')}`
            return (
              <Space size="middle">
                <div className="d-flex">
                  <button
                    onClick={() => history.push(link)}
                    className="btn btn-accept  mr-1"
                  >
                    {this.props.t('details')}
                  </button>
                </div>
              </Space>
            )
          } else {
            return 'Rejected booking id (' + _.get(record, 'id', '') + ')'
          }
        },
      },
    ]
    const classData = []
    const dependentClassData = []
    const expiredClassData = []
    const expiredDependentClassData = []

    this.state.classesTableData.forEach((value, index) => {
      const { camp_details, packages, dependent_user, provider } = value
      const userName = _.get(this, 'props.userBaseProfile.details.firstname')
      const md = _.chain(value)

      if (!md.get('camp_details').isEmpty().value()) {
        const capitalizedSessionName = this.capitalizeWord(
          _.get(camp_details, 'session_name', '')
        )
        const status = _.get(value, 'status')

        const item = {
          id: _.get(value, 'id'),
          status: status,
          class: this.classStatus(capitalizedSessionName, status),
          to_whom:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? userName
              : _.startCase(_.get(dependent_user, 'firstname')),
          to_whom_id:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? md.get('user').value()
              : _.get(dependent_user, 'id'),
          user_type:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? 'user'
              : 'dependent',
          package: _.get(packages, '[0].name'),
          next_session: nextSession(camp_details, 'camp'),
          session: timeDateConverterSession(
            _.get(camp_details, 'start_date'),
            'date'
          ),
          end_date: timeDateConverterSession(
            _.get(camp_details, 'end_date'),
            'date'
          ),
          location:
            _.get(camp_details, 'teaching_mode') === 'o'
              ? 'Online'
              : _.get(camp_details, 'location_taught', '')
                ? _.get(camp_details, 'location_taught', '')
                : '---',
          key: _.get(camp_details, 'id'),
          class_id: _.get(camp_details, 'id'),
          campDetails: camp_details,
          providerDetails: provider,
          getUserDetails: _.get(value, 'user', dependent_user),
          link: this.linkwrap(_.get(camp_details, 'link', '---')),
          code: _.get(camp_details, 'code', '---'),
        }

        if (
          _.get(this, 'props.userBaseProfile.details.user.account_type') !==
          'dependent'
        ) {
          if (
            md.get('dependent_user').isEmpty().value() &&
            !md.get('user').isEmpty().value()
          ) {
            classData.push(item)
          } else {
            dependentClassData.push(item)
          }
        } else if (
          !md.get('dependent_user').isEmpty().value() &&
          !md.get('user').isEmpty().value()
        ) {
          classData.push(item)
        }
      }
    })

    this.state.expiredClassesTableData.forEach((value, index) => {
      const { camp_details, packages, dependent_user, provider } = value
      const userName = _.get(this, 'props.userBaseProfile.details.firstname')
      const md = _.chain(value)

      if (!md.get('camp_details').isEmpty().value()) {
        const capitalizedSessionName = this.capitalizeWord(
          _.get(camp_details, 'session_name', '')
        )
        const status = _.get(value, 'status')

        const item = {
          id: _.get(value, 'id'),
          status: status,
          class: this.classStatus(capitalizedSessionName, status),
          to_whom:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? userName
              : _.startCase(_.get(dependent_user, 'firstname')),
          to_whom_id:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? md.get('user').value()
              : _.get(dependent_user, 'id'),
          user_type:
            md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
              ? 'user'
              : 'dependent',
          package: _.get(packages, '[0].name'),
          next_session: nextSession(camp_details, 'camp'),
          session: timeDateConverterSession(
            _.get(camp_details, 'start_date'),
            'date'
          ),
          end_date: timeDateConverterSession(
            _.get(camp_details, 'end_date'),
            'date'
          ),
          location:
            _.get(camp_details, 'teaching_mode') === 'o'
              ? 'Online'
              : _.get(camp_details, 'location_taught', '')
                ? _.get(camp_details, 'location_taught', '')
                : '---',
          key: _.get(camp_details, 'id'),
          class_id: _.get(camp_details, 'id'),
          campDetails: camp_details,
          providerDetails: provider,
          getUserDetails: _.get(value, 'user', dependent_user),
          link: this.linkwrap(_.get(camp_details, 'link', '---')),
          code: _.get(camp_details, 'code', '---'),
        }

        if (
          _.get(this, 'props.userBaseProfile.details.user.account_type') !==
          'dependent'
        ) {
          if (
            md.get('dependent_user').isEmpty().value() &&
            !md.get('user').isEmpty().value()
          ) {
            expiredClassData.push(item)
          } else {
            expiredDependentClassData.push(item)
          }
        } else if (
          !md.get('dependent_user').isEmpty().value() &&
          !md.get('user').isEmpty().value()
        ) {
          expiredClassData.push(item)
        }
      }
    })

    const classDataUniq = _.uniqBy(classData, (e) => e.class_id)
    const expiredClassDataUniq = _.uniqBy(expiredClassData, (e) => e.class_id)
    const dependentClassDataUniq = _.uniqBy(
      dependentClassData,
      (e) => e.class_id
    )
    const expiredDependentClassDataUniq = _.uniqBy(
      expiredDependentClassData,
      (e) => e.class_id
    )

    const dependentUsers = _.get(this, 'props.dependentUsers.items', [])

    return (
      <div className="nurtem-myclass-design nurtem-table mb-3">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
          <Tabs defaultActiveKey="1">
            <items tab={this.props.t('tutorSidebarMyCamps')} key="1">
              <div className="schedule-content mt-2">
                <div className="" style={{ overflowX: 'auto' }}>
                  {this.props.loading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      loading={
                        _.isEmpty(classDataUniq)
                          ? false
                          : classDataUniq
                            ? false
                            : true
                      }
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={ListingClasscolumns}
                      dataSource={classDataUniq}
                      scroll={{ x: true }}
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 5,
                        position: ['bottomLeft'],
                      }}
                    />
                  )}
                </div>
              </div>
            </items>
            {(() => {
              return _.map(
                _.size(dependentUsers) > 0 && dependentUsers,
                (user, index) => {
                  return (
                    <items
                      tab={`${user && _.get(user, 'firstname') ? _.get(user, 'firstname', '') + ' ' + _.get(user, 'lastname', '') : ''}'s`}
                      key={_.get(user, 'id')}
                    >
                      <div className="schedule-content mt-2 dependent-shedule-content">
                        <div style={{ overflowX: 'auto' }}>
                          {this.props.loading ? (
                            <ClassDetailLoader />
                          ) : (
                            <Table
                              loading={
                                _.isEmpty(dependentClassDataUniq)
                                  ? false
                                  : dependentClassDataUniq
                                    ? false
                                    : true
                              }
                              rowClassName={(record, index) =>
                                record.class_id === classRow_id ? 'active' : ''
                              }
                              columns={ListingClasscolumns}
                              scroll={{ x: true }}
                              dataSource={_.uniqBy(
                                _.filter(dependentClassData, (e) => {
                                  return (
                                    user && user.id && e.to_whom_id === user.id
                                  )
                                }),
                                'class_id'
                              )}
                              pagination={{
                                pageSize: 5,
                                position: ['bottomLeft'],
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </items>
                  )
                }
              )
            })()}
          </Tabs>
          <div style={css.heading1}>
          {this.props.t('expiredCamps')}
          </div>
          <Tabs>
            <items tab={this.props.t('tutorSidebarMyCamps')} key="1">
              <div className="schedule-content mt-2">
                <div className="" style={{ overflowX: 'auto' }}>
                  {this.props.loading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      loading={
                        _.isEmpty(expiredClassDataUniq)
                          ? false
                          : expiredClassDataUniq
                            ? false
                            : true
                      }
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={expiredListingClasscolumns}
                      dataSource={expiredClassData}
                      scroll={{ x: true }}
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 5,
                        position: ['bottomLeft'],
                      }}
                    />
                  )}
                </div>
              </div>
            </items>
            {(() => {
              return _.map(
                _.size(dependentUsers) > 0 && dependentUsers,
                (user, index) => {
                  return (
                    <items
                      tab={`${user && _.get(user, 'firstname') ? _.get(user, 'firstname', '') + ' ' + _.get(user, 'lastname', '') : ''}'s`}
                      key={_.get(user, 'id')}
                    >
                      <div className="schedule-content mt-2 dependent-shedule-content">
                        <div style={{ overflowX: 'auto' }}>
                          {this.props.loading ? (
                            <ClassDetailLoader />
                          ) : (
                            <Table
                              loading={
                                _.isEmpty(expiredDependentClassDataUniq)
                                  ? false
                                  : expiredDependentClassDataUniq
                                    ? false
                                    : true
                              }
                              rowClassName={(record, index) =>
                                record.class_id === classRow_id ? 'active' : ''
                              }
                              columns={expiredListingClasscolumns}
                              scroll={{ x: true }}
                              dataSource={_.uniqBy(
                                _.filter(expiredDependentClassData, (e) => {
                                  return (
                                    user && user.id && e.to_whom_id === user.id
                                  )
                                }),
                                'class_id'
                              )}
                              pagination={{
                                pageSize: 5,
                                position: ['bottomLeft'],
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </items>
                  )
                }
              )
            })()}
          </Tabs>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>
// console.log('loadingnow=======', state.users.loading)
({
  loading: state.users.loading,
  loggedInUserDetails: state.authentication,
  userCamps: state.users.userCamps,
  classDetailsView: state.users.userCampsInfo,
  singlePackViewLoading: state.campsDetails.singleCampsViewLoading,
  dependentUsers: state.userProfile.dependentUsers,
  userBaseProfile: state.userProfile.userBaseProfile,
})
const mapDispatchToProps = (dispatch) => ({
  getCampsList: (requestParams) =>
    dispatch(userAction.getUserCamps(requestParams)),
  onCampDetail: (requestParams) =>
    dispatch(userAction.getUserCampsInfo(requestParams)),
  onGetCurrentLocation: (callback) =>
    dispatch(servicesActions.onGetCurrentLocation(callback)),
  getDependentUsers: () => dispatch(userAction.getDependentUsers()),
  onItemAdd: () => dispatch(action.cartItemAdd()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Camps))
