import React from 'react'
import { AiOutlineCalendar, AiOutlineLaptop } from 'react-icons/ai'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'
import { Rate, List } from 'antd'
import ScrollToTop from '../../../components/re-usable/ScrollToTop'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import timeZone from '../../../components/common/timezone'
import { seatsAvailableCount } from '../../../utils/common'
import { ListAntd } from '../../../components/antd-components/index.tsx'
import {cleanHtmlContent} from '../../../utils/common/filter_empty_tag'

const BatchInfoCompetition = (props) => {
  const { Item } = List
  const { classDetails } = props
  const render = () => {
    const cd = _.chain(classDetails)
    const rules_data = cd.get('rules', '').value()
    const rules      = cleanHtmlContent(rules_data);
    const terms_conditions_data = cd.get('terms_conditions', '').value()
    const terms_conditions      = cleanHtmlContent(terms_conditions_data);
    const session_name = cd.get('session_name', '').value()
    const session_price = cd.get('price', '').value()
    const avg_count = _.get(classDetails, 'average_rating', 0)
    const rating_count = _.get(classDetails, 'rating_count', 0)
    const description_data = cd.get('description').value()
    const description      = cleanHtmlContent(description_data);
    const maxCount =
      props.type !== 'competition'
        ? cd.get('no_of_max_students').value()
        : cd.get('max_no_of_participants').value()
    const stuAccpt = cd.get('additional_details').value()
    const total_seats_left = seatsAvailableCount(maxCount, stuAccpt)
    const time_slot = cd.get('time_slot', {}).value()
    const timeSlotArray =
      Object.keys(time_slot).length > 0
        ? Object.keys(time_slot).map((day) => ({
          day,
          start_time: new Date(time_slot[day].start_time).toLocaleTimeString(
            'en-US',
            { hour: '2-digit', minute: '2-digit' }
          ),
          end_time: new Date(time_slot[day].end_time).toLocaleTimeString(
            'en-US',
            { hour: '2-digit', minute: '2-digit' }
          ),
        }))
        : []
    return (
      <div className="nurtem-my-class-card tutor-my-camp-details-page pb-4 mb-3">
        <ScrollToTop />
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 pl-3">
            <div className="user-details-right col-12">
              <div className="row align-items-center py-3">
                <div className="col-12 col-md-6 pr-md-0">
                  <div className="session-block">
                    <div
                      className="session-icon mr-2"
                      style={{ background: '#F7FECC' }}
                    >
                      <AiOutlineLaptop />
                    </div>
                    <div>
                      <div className="session-text-regular">
                        {props.t('enrolled')}
                      </div>
                      <div className="session-text-bold">
                        {props.studentsCount}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="session-block">
                    <div
                      className="session-icon mr-2"
                      style={{ background: '#DAF5F6' }}
                    >
                      <AiOutlineCalendar />
                    </div>
                    <div>
                      <div
                        className="session-text-regular"
                        style={{ width: '100%', float: 'left' }}
                      >
                        {props.t('seatLeft')}
                      </div>
                      <div className="session-text-bold">
                        {total_seats_left}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row align-items-center py-3">
                <div className="col-12 col-md-12 pr-md-0">
                  <div className="session-block">
                    <div
                      className="session-icon mr-2"
                      style={{ background: '#ffeded' }}
                    >
                      <AiOutlineCalendar />
                    </div>
                    <div className="full-width ">
                      <div className="session-text-regular">
                        {props.t('compDate')}{"  "} {timeZone()}
                      </div>
                      <div className="session-text-bold">
                        {_.get(classDetails, 'duration_competition') === '1'
                          ? timeDateConverterSession(
                            classDetails.start_date,
                            'date',
                            'MMM DD,YYYY'
                          )
                          : `${timeDateConverterSession(classDetails.start_date, 'date', 'MMM DD,YYYY')} - ${timeDateConverterSession(classDetails.end_date, 'date', 'MMM DD,YYYY')}`}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center py-3">
                <div className="col-12 col-md-12 pr-md-0">
                  <div className="session-block">
                    <div
                      className="session-icon mr-2"
                      style={{ background: 'rgb(242 237 254)' }}
                    >
                      <AiOutlineCalendar />
                    </div>
                    <div className="full-width ">
                      <div className="session-text-regular pl-2">
                        {props.t("batchTimings")}{"  "} ({timeZone()})
                      </div>
                      <ListAntd
                        className={'ml-2 mt-1'}
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={timeSlotArray}
                        renderItem={(item) => (
                          <Item>
                            <Item.Meta
                              title={item.day}
                              description={`${item.start_time} - ${item.end_time}`}
                            />
                          </Item>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pl-0">
            <div className="user-details-right col-12">
              <div className="row align-items-center mt-3">
                <div className="col-12  pr-md-0">
                  <div className="tab-content-title">
                    {session_name} - ${session_price}
                  </div>
                </div>
              </div>

              <div className="row align-items-center justify-content-between py-2">
                <div className="col-8">
                  <div className="row align-items-center">
                    <div className="col pl-0">
                      <div className="star-rating-session mt-1">
                        <Rate disabled="true" value={parseInt(avg_count)} />
                        {parseInt(avg_count) !== 0 && (
                          <b className="ml-2">
                            ({avg_count.toFixed(1)}
                            /5)
                          </b>
                        )}
                      </div>
                      <div className="review-counting-session">
                        ({parseInt(rating_count)} {props.t('rating')})
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {description && description.trim() && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="col-12 session-text-bold mb-2"
              style={{ fontWeight: 'bolder', fontSize: 15 }}
            >{props.t('descp')} </div>
            <div className="col-12">
                <div>
                  {typeof description === 'string'
                    ? parser(description.replace(/\n/g, '<br>').replace(/<\/p>\s*<br>/g, '</p>'))
                    : ''}
                </div>
            </div>
          </div>
        </div>
        )}
        {rules && rules.trim() && (
        <div className="row margin_top_line">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="col-12 session-text-bold mb-2"
              style={{ fontWeight: 'bolder', fontSize: 15 }}
            >{props.t('rules_label')} </div>
            <div className="col-12">
                <div>
                  {typeof rules === 'string'
                    ? parser(rules.replace(/\n/g, '<br>').replace(/<\/p>\s*<br>/g, '</p>'))
                    : ''}
                </div>
            </div>
          </div>
        </div>
        )}
        {terms_conditions && terms_conditions.trim() && (
        <div className="row margin_top_line">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="col-12 session-text-bold mb-2"
              style={{ fontWeight: 'bolder', fontSize: 15 }}
            >{props.t('terms&conditions')} </div>
            <div className="col-12">
                <div>
                  {typeof terms_conditions === 'string'
                    ? parser(terms_conditions.replace(/\n/g, '<br>').replace(/<\/p>\s*<br>/g, '</p>'))
                    : ''}
                </div>
            </div>
          </div>
        </div>
        )}
      </div>
    )
  }
  return render()
}

const mapStateToProps = (state) => ({
  studentsCount: _.get(
    state.competitionsDetails.competitionStudents,
    ['meta', 'total_count'],
    0
  ),
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BatchInfoCompetition))
