import React, { useEffect, useState } from 'react';
import { Pagination, Button, Tooltip } from 'antd';
import { MdLocationOn, MdCalendarMonth } from 'react-icons/md';
import { FaCarSide } from 'react-icons/fa';
import { PiUsersFourBold } from 'react-icons/pi';
import { AiOutlineGlobal } from 'react-icons/ai';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import generalAction from '../../store/general/action';
import * as sessionActions from '../../store/actions/public/sessions';
import '../../containers/tutor/style.css';
import timeDateConverterSession from '../timezone/sessionTimeDate';
import LabelConvertion from '../re-usable/label-convertion';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import { seatsAvailableCount } from '../../utils/common';
import _, { values } from 'lodash';
import { Link } from 'react-router-dom';

const SessionList = (props) => {
  console.log("psrops>>>>>>>>>", props);
  const groupId = _.get(props, 'groupID', false);
  const bid = _.get(props, 'bid', false);
  console.log('typeprops==', props.type)
  useEffect(() => {
    if (groupId) {
      const requestParams = {
        group_id: groupId,
        sort: 'created_at.desc',
        type: props.type,
        live: props.live,
        status: props.status,
      };
      props.getSessionList(requestParams, props.type);
    }
  }, [groupId, props.type, props.live, props.status]);

  useEffect(() => {
    const sessions = props.sessions?.[props.groupID] ?? [];
    if (bid && sessions.length > 0) {
      const matchedList = sessions.filter((map) => map.id === bid);
      props.callback(matchedList[0]);
    }
  }, [bid, props.sessions, props.groupID]);

  const [currentPage, setCurrentPage] = useState(1);
  const sessionsPerPage = 12;

  const render = () => {
    const sessions = props.sessions.hasOwnProperty(props.groupID) ? props.sessions[props.groupID] : [];
    console.log('sessions===========', sessions);

    return (
      <div className={sessions && sessions.length > 0 ? 'batchAvailable' : 'empty-animations'}>
        {sessions && sessions.length > 0
          ? sessions.slice((currentPage - 1) * sessionsPerPage, currentPage * sessionsPerPage)
            .map((value, key) => {
              const maxCount = (props.type !== 'competitions' && props.type !== 'events') ? value.no_of_max_students : _.get(value, 'max_no_of_participants', 0);
              const total_seats_left = seatsAvailableCount(maxCount, value.additional_details);
              const compdate = _.get(value, 'competition_start_date', null);
              if (value.id) {
                return (
                  <Link to={`/view-batch?type=${props.type}&gid=${groupId}&bid=${value.id}`} key={`class_list${key}`} target={'_blank'}>
                    <div key={`class_list${key}`}>
                      <div className="nurtem-profile-class-card" style={{ maxWidth: 270, minHeight: 350 }}>
                        <div className="class-details-card" style={{ minHeight: 350 }}>
                          <div className="row session-box" style={{ width: '100%', margin: 0, height: '350px' }} onClick={() => { props.callback(value); }}>
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ padding: 0 }}>
                              <div className="nurtem-profile-class-card-header">
                                <div style={{ display: 'flex', justifyContent: 'center' }} className="nurtem-profile-class-card-class-detail">
                                  <div className="profile-card-name-container" style={{ padding: 5, width: '100%', borderRadius: 10 }}>
                                    <div style={{ width: '100%' }} className="profile-class-name text-truncate">
                                      {(value.session_name)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ padding: 0 }}>
                              <div className="nurtem-profile-camp-packname-header" style={{ justifyContent: 'center', padding: 5 }}>
                                <div className="startdiv">
                                  <span>Starts from </span>
                                </div>
                                <div className="camp-detail-card-menu p-1" style={{ fontSize: '25px' }}>
                                  {value.teaching_type !== 'i' ? (
                                    <span>
                                      {timeDateConverterSession(value.start_date, 'date', 'MMM DD, YYYY')}
                                    </span>
                                  ) : (
                                    <span>
                                      {timeDateConverterSession(value.start_date, 'date', 'MMM DD, YYYY')}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'center' }}>
                              <div className="nurtem-profile-camp-packname-header" style={{ padding: 5, justifyContent: 'center' }}>
                                {props.type !== 'competitions' && (
                                  <h5 className="camp-detail-card-day p-1">
                                    <MdCalendarMonth style={{ marginRight: '5px' }} />
                                    {value.variant === 'o' || value.variant === 'o1' || value.variant === 'o2' || value.variant === 'o3' || value.variant === 'o4'
                                      ? _.map(value.selected_days, (day, index) => {
                                        const formattedDay = _.upperFirst(day.split(' ').join(', '));
                                        const comma = index !== value.selected_days.length - 1 ? ',' : '';
                                        return formattedDay + comma;
                                      })
                                      : LabelConvertion(value.variant, 'variant')}
                                  </h5>
                                )}
                                <span>
                                  {value.variant === 'o' || value.variant === 'o1' || value.variant === 'o2' || value.variant === 'o3' || value.variant === 'o4'
                                    ? `( ${value.selected_days.length} ${value.selected_days.length === 1 ? 'Day' : 'Days'} / week)`
                                    : value.variant === 'wd' ? '(5 Days / Week)' : value.variant === 'wend' ? '(2 Days / Week)' : ''}
                                  {value.variant === 'fw' && '(7 Days / Week)'}
                                </span>
                                {props.type === 'competitions' && (
                                  <span>
                                    <h5 className="camp-detail-card-day p-1">
                                      {timeDateConverterSession(compdate, 'date', 'MMM DD, YYYY')}
                                    </h5>
                                  </span>
                                )}
                              </div>
                            </div>

                            {value.teaching_type && props.type === 'competitions' && (
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="nurtem-profile-camp-packname-header" style={{ padding: '10px 0px' }}>
                                  <div className="camp-detail-card-menu">
                                    {LabelConvertion(value.teaching_type, 'teaching_type')}
                                    {' '}
                                    {' '}
                                    {(value.teaching_type === 'g' && `(${value.max_no_of_participants_in_team} members )`)}
                                  </div>
                                </div>
                              </div>
                            )}

                            {value.teaching_mode && (
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div className="nurtem-profile-camp-packname-header" style={{ paddingRight: 0 }}>
                                  {props.type !== 'camps' && (
                                    <div className="camp-detail-card-menu p-1">
                                      <PiUsersFourBold className="mr-1" />
                                      <span style={{ color: '#1771a8' }}>{LabelConvertion(value.teaching_type, 'teaching_type')}</span>
                                    </div>
                                  )}
                                </div>
                                <div className="nurtem-profile-camp-packname-header">
                                  <h6 style={{
                                    fontWeight: 600, marginRight: '3px', marginBottom: 0, color: '#1771a8',
                                  }}
                                  >
                                    {total_seats_left === 0 ? <span style={{ color: 'red' }}>No Seats Left</span> : total_seats_left}
                                    {' '}
                                  </h6>
                                  {' '}
                                  {total_seats_left > 1 && <span style={{
                                    fontSize: 13, fontWeight: 600, color: '#333', paddingRight: 5,
                                  }}
                                  >
                                    {' '}
                                    Seats Left
                                  </span>}
                                </div>
                              </div>
                            )}

                            {/* Banner Text */}
                            <div className="bannerDesign">
                              <div style={{ padding: 5, color: 'white', textAlign: 'center' }}>
                                <span style={{ fontSize: 13, fontWeight: 600 }}>Cost per Session </span>
                                {' '}
                                <span style={{ fontSize: 25 }}>
                                  $
                                  {(value.price.toFixed(2))}
                                </span>
                              </div>
                              {/* Side banner design */}
                              <div style={{ position: 'relative' }}>
                                <div className="triangle-up" />
                                <div className="square" />
                              </div>
                              <div style={{ position: 'relative' }}>
                                <div className="itriangle-up" />
                                <div className="isquare" />
                              </div>
                              {/* Side banner design */}
                            </div>
                            {/* Banner Text */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3 mb-2">
                              <div className="row" style={{ width: '80%' }}>
                                <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10">
                                  {value.teaching_mode && (
                                    <span style={{ color: '#1771a8' }}>
                                      {value.teaching_mode === 'i' ? <MdLocationOn className="mr-1" style={{ color: '#1771a8', fontSize: '20px', alignSelf: 'baseline' }} /> : value.teaching_mode && value.teaching_mode === 'o' ? <AiOutlineGlobal className="mr-1" style={{ color: '#1771a8', fontSize: '20px' }} /> : <FaCarSide className="mr-1" style={{ color: '#1771a8', fontSize: '20px' }} />}
                                      {value.teaching_mode && value.teaching_mode === 'i' ? LabelConvertion(value.teaching_mode, 'teaching-mode') : LabelConvertion(value.teaching_mode, 'teaching-mode')}

                                    </span>
                                  )}
                                </div>
                                <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
                                  <Button className="detailButton" style={{ backgroundColor: '#205298', height: '20px', lineHeight: '10px' }} onClick={() => { props.callback(value); }} type="primary">
                                    {props.t('details')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                );
              }
              return null;
            })
          : (
            <>
              <EmptyAnime AnimeType="Empty" loop animeheight={150} />
              <p className="empty-animations">{props.t('batchDetails44')}</p>
            </>
          )}

        {sessions && sessions.length > 8 && (
          <div className="full-width mb-4">
            <Pagination
              style={{ width: '100%', height: '100%', cursor: 'pointer' }}
              onChange={setCurrentPage}
              total={sessions.length}
              pageSize={sessionsPerPage}
              className="ant-table-pagination"
            />
          </div>
        )}

      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['sessions', 'loading'], false),
  sessions: _.get(state, ['sessions', 'sessions'], {}),
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  tutorTransfer: (data) => dispatch(generalAction.tutor.providerTransfer(data)),
  getSessionList: (requestParams, type) => dispatch(sessionActions.getSessionList(requestParams, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SessionList));
