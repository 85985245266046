import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  AiOutlineSearch,
  AiFillCheckCircle,
  AiTwotoneDelete,
  AiTwotoneEdit,
  AiFillInfoCircle,
  AiOutlinePlus,
} from 'react-icons/ai'
import {
  Modal,
  Radio,
  Tooltip,
  Form,
  Checkbox,
  InputNumber,
  Select,
  Spin,
  Tag,
  Input,
  Button,
  Upload,
  message,
  TimePicker,
  notification,
} from 'antd'
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import _ from 'lodash'
import { geocodeByAddress } from 'react-places-autocomplete'
import dayjs from 'dayjs'
import queryString from 'query-string'
import axios1 from 'axios'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { withTranslation } from 'react-i18next'
import baseProfileFormMeta from './form-meta'
import * as commonActions from '../../../../../store/actions/common'
import generalAction from '../../../../../store/general/action'
import * as action from '../../../../../store/actions/public/Classes'
import { isGeneralPropertyLoadingInProgress } from '../../../../../store/general/settings'
import generalActionTypes from '../../../../../store/general/actionTypes'
import { NurtemUtils } from '../../../../../utils'
import * as actions from '../../../../../store/actions/tutor'
import axios from '../../../../../utils/axios'
import { globalVariable } from '../../../../../utils/config/config'
import AiDescriptionGenerator from '../../../../../components/common/AiDescriptionGenerator'
import PlanSubscriptionForm from '../../../../user/user-cart/PlanSubscriptionForm'
import NoCostPlanSubscriptionForm from '../../../../user/user-cart/NoCostPlanSubscriptionForm'

import TextArea from 'antd/es/input/TextArea'
import css from '../../../../../utils/CSSUtility'

const { Option } = Select
const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
}

function BaseProfileTab(props) {
  const [profile_photo_s3_url, set_profile_photo_s3_url] = useState('')
  const [profile_photo_s3_url_processing, set_profile_photo_s3_url_processing] =
    useState(false)

  const [description_html, set_description_html] = useState(
    EditorState.createEmpty()
  )
  const [service_text, set_service_text] = useState('')

  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(baseProfileFormMeta)
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({})
  // Declare a new state variable, which we'll call "serviceList"
  const [serviceList, setServiceList] = useState([])
  // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false)
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false)
  // Declare a new state variable, which we'll call "providerProfileImage"
  const [providerProfileImage, setProviderProfileImage] = useState({
    photo: [],
    deleted: false,
    canUpload: false,
    loading: false,
  })
  const [businessHours, setBusinessHours] = useState([])
  const [selectedDay, setSelectedDay] = useState('')
  const [selectedStartTime, setSelectedStartTime] = useState(null)
  const [selectedEndTime, setSelectedEndTime] = useState(null)
  const [localState, setLocalState] = useState({
    location_taught: {
      location_search_data: [],
      select_location_city_data: [],
      fetching: false,
    },
    address: { search_result: [], fetching: false },
    user_handle: {
      profileNameValid: false,
      validateStatus: '',
      value: '',
    },
    gender: { Male: '0', Female: '1', 'Prefer not to answer': '2' },
    save_changes_loading: false,
    is_fetching_tutor_profile: false,
    service_provider_type: false,
  })
  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm()
  // initializing debounce for location taught
  const locationTaughtDelayedQuery = _.debounce(
    (q) => fetchLocationTaught(q),
    1200
  )

  // initializing debounce for address
  const addressDelayedQuery = _.debounce((q) => fetchAddress(q), 1200)

  // initializing debounce for user handle
  const userHandleDelayedQuery = _.debounce((q) => onChangeUserHandle(q), 1200)

  // initializing debounce for services list
  const serviceListDelayedQuery = _.debounce((q) => getServicesList(q), 1200)
  // const multiCampsRef = React.createRef();
  const [businessTutor, setBusinessTutor] = useState(false)

  const [isEditMode, setIsEditMode] = useState(false)

  const [tutorTypes, setTutorTypes] = useState([])
  const [tutorPlans, setTutorPlans] = useState([])

  const [openPaymentModel, setToOpenPaymentModel] = useState(false)

  const [openCashlessModel, setToOpenCashlessModel] = useState(false)

  const [paymentData, setPaymentData] = useState(0)

  const [intent_clientSecret, setIntentClientSecret] = useState(false)

  const handleCancel = () => {
    // handle cancel payment
    setToOpenPaymentModel(false)
  }

  const handleCancel2 = () => {
    // handle cancel payment
    setToOpenCashlessModel(false)
  }

  useEffect(() => {
    const abortController = new AbortController() // Create an instance of AbortController

    commonActions.makRequest('/providers/types', setTutorTypes)
    commonActions.makRequest('/sku/plans', setTutorPlans)

    // fetching tutor profile from api
    setLocalState({ ...localState, is_fetching_tutor_profile: true })
    const url = props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams && urlparams.id) {
      setBusinessTutor(true)
      props.viewProvider({
        params: { expand: 'classes,services', id: urlparams.id },
        callback: (success, data) => {
          if (success) {
            setBaseProfileFormFields(_.get(data, 'details', {}))
          }
        },
      })
    } else {
      props.getTutorProfile({
        params: { expand: 'classes,services' },
        callback: (success, data) => {
          if (success) {
            setBaseProfileFormFields(_.get(data, 'details', {}))
          }
        },
      })
    }
    getServicesList()

    return () => {
      abortController.abort()
    }
  }, [])

  useEffect(() => {
    setCurrentTabModified()

    // No need for cleanup here, but adding for consistency
    return () => {}
  }, [canUpdateForm])

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => {}))) {
      props.setCurrentTabModified(canUpdateForm)
    }
  }

  const getServicesList = (params = {}) => {
    // if the end point in progress then we should avoid the api call
    if (
      !isGeneralPropertyLoadingInProgress(
        _.get(props, 'services'),
        generalActionTypes.services.list
      )
    ) {
      // fetching nurtem service list from api
      props.getServicesList({
        params,
        callback: (success, data) => {
          if (success) {
            setServiceList(_.get(data, 'items', []))
          }
        },
      })
    }
  }

  const setBaseProfileFormFields = (tutor_profile_details) => {
    const formProperties = _.pick(tutor_profile_details, [
      'teaching_modes',

      'years_of_experience',
      'no_of_students_taught',
      'user_handle',
      'businesshours',
      'firstname',
      'lastname',
      'businessname',
      'zip_code',
      'mobile_number',
      'gender',
      'bio',
    ])

    setBusinessHours(_.get(formProperties, 'businesshours', []))
    formProperties.create_profile_as = _.get(tutor_profile_details, 'type', '')

    const bio_description = _.get(tutor_profile_details, 'bio', '')
    const plans = _.get(
      tutor_profile_details,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    if (!_.isEmpty(plans)) {
      formProperties.plans = plans
    }

    if (!_.isEmpty(bio_description)) {
      const blocksFromHtml = htmlToDraft(bio_description)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      )
      const description_html = EditorState.createWithContent(contentState)
      set_description_html(description_html)
    }

    const service_labels = []
    formProperties.service_ids = _.map(
      _.get(tutor_profile_details, 'services', []),
      (d) => {
        const label = _.get(d, 'name', 'null')
        const key = _.get(d, 'id', '')
        if (!_.isEmpty(key)) {
          service_labels.push(label)
          return { key, label }
        }
      }
    )

    formProperties.location = {
      key: JSON.stringify({
        lat: _.get(tutor_profile_details, 'location.coordinates[1]', null),
        lng: _.get(tutor_profile_details, 'location.coordinates[0]', null),
      }),
      label: _.get(tutor_profile_details, 'location_text', ''),
    }
    form.setFieldsValue(formProperties)
    setTutorProfile(tutor_profile_details)
    setLocalState({
      ...localState,
      user_handle: {
        ..._.get(localState, 'user_handle'),
        value: _.get(tutor_profile_details, 'user_handle', []),
      },
      location_taught: {
        ..._.get(localState, 'location_taught'),
        select_location_city_data: _.get(
          tutor_profile_details,
          'location_taught',
          []
        ),
      },
    })
    if (!_.isEmpty(_.get(tutor_profile_details, 'photo', ''))) {
      setProviderProfileImage({
        photo: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: _.get(tutor_profile_details, 'photo'),
          },
        ],
      })
    }

    // for ai generation
    const name = _.get(tutor_profile_details, 'name', '')
    const years_of_experience = _.get(
      tutor_profile_details,
      'years_of_experience',
      ''
    )
    const question = `tutor name '${name}' providing servies in the following area ${service_labels.toString()} with ${years_of_experience} years of experiance`
    set_service_text(question)
  }

  // Location search
  const fetchLocationTaught = (value) => {
    setLocalState({
      ...localState,
      location_taught: {
        ..._.get(localState, 'location_taught'),
        location_search_data: [],
        fetching: true,
      },
    })
    geocodeByAddress(value)
      .then((body) => {
        const location_search_data = _.map(body, (location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            geopoints: {
              lat: location.geometry.location.lat(),
              lng: location.geometry.location.lng(),
            },
            text: location.formatted_address,
          }),
        }))
        setLocalState({
          ...localState,
          location_taught: {
            ..._.get(localState, 'location_taught'),
            location_search_data,
            fetching: false,
          },
        })
      })
      .catch((error) => {
        setLocalState({
          ...localState,
          location_taught: {
            ..._.get(localState, 'location_taught'),
            fetching: false,
          },
        })
      })
  }

  // address search
  const fetchAddress = (value) => {
    setLocalState({
      ...localState,
      address: {
        ..._.get(localState, 'address'),
        search_result: [],
        fetching: true,
      },
    })
    geocodeByAddress(value)
      .then((body) => {
        const search_result = _.map(body, (location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            lat: location.geometry.location.lat(),
            lng: location.geometry.location.lng(),
            postal_code: NurtemUtils.fetchPostalCode(
              _.get(location, 'address_components', [])
            ),
          }),
        }))
        setLocalState({
          ...localState,
          address: {
            ..._.get(localState, 'address'),
            search_result,
            fetching: false,
          },
        })
      })
      .catch((error) => {
        setLocalState({
          ...localState,
          address: {
            ..._.get(localState, 'address'),
            search_result: [],
            fetching: false,
          },
        })
        console.error(
          'Error while fetiching address in tutor profile====',
          error
        )
      })
  }

  // change user handle
  const onChangeUserHandle = (user_handle) => {
    // if the end point in progress then we should avoid the api call
    if (
      _.isEqual(_.get(tutorProfile, 'user_handle', ''), user_handle) ||
      isGeneralPropertyLoadingInProgress(
        _.get(props, 'tutor'),
        generalActionTypes.tutor.handle
      )
    ) {
      return
    }
    const payload = {
      user_handle,
    }
    setLocalState({
      ...localState,
      user_handle: {
        ..._.get(localState, 'user_handle', {}),
        validateStatus: 'validating',
      },
    })
    const url = props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams && urlparams.id) {
      payload.id = urlparams.id
    }
    props.updateHandle({
      body: payload,
      callback: (success, data) => {
        if (success) {
          setLocalState({
            ...localState,
            user_handle: {
              ..._.get(localState, 'user_handle', {}),
              validateStatus: 'success',
              value: user_handle,
            },
          })
        } else {
          setLocalState({
            ...localState,
            user_handle: {
              ..._.get(localState, 'user_handle', {}),
              validateStatus: 'error',
            },
          })
        }
      },
    })
  }

  const onSaveChanges = (data) => {
    console.log('submit clicked!')
    let profileDetails = _.omit(data, ['user_handle', 'photo'])
    console.log('submiteddetails========', profileDetails)

    // update description from editor
    // profileDetails.bio = draftToHtml(
    //   convertToRaw(description_html.getCurrentContent())
    // )

    const url = props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams && urlparams.id) {
      profileDetails.id = urlparams.id
      profileDetails.update_progress = 1
    }
    if (profileDetails.years_of_experience === null) {
      profileDetails.years_of_experience = 0
    }
    if (profileDetails.no_of_students_taught === null) {
      profileDetails.no_of_students_taught = 0
    }
    profileDetails = processFormData(profileDetails)
    // console.log('updatedata======', profileDetails)

    if (!_.isEmpty(profile_photo_s3_url)) {
      set_profile_photo_s3_url_processing(false)
      profileDetails.photo = profile_photo_s3_url
    }

    // updating tutor profile to api
    setLocalState({ ...localState, save_changes_loading: true })
    props.updateProfile({
      body: profileDetails,
      callback: (success, data) => {
        if (success) {
          props.onTutorProfile(props.loggedInUserDetails)
          const del = _.get(providerProfileImage, 'deleted', false)
          if (del) {
            del_TutorProfileImage()
          }
          // window.location.reload()
        }
      },
      // notification: { enable: true }
    })
    notification.success({
      description:
        'Provider details has been updated. Please proceed with connecting your business account with stripe to list your business.',
      message: 'Success',
    })
    setLocalState({ ...localState, save_changes_loading: false })
    setCanUpdateForm(true)
  }

  const processFormData = (profileDetails) => {
    profileDetails = _.clone(profileDetails)
    profileDetails.location_text = _.get(profileDetails, 'location.label', '')
    profileDetails.address = _.get(profileDetails, 'location_text', '')
    profileDetails.location = JSON.parse(
      _.get(profileDetails, 'location.key', {})
    )
    profileDetails.location_taught = _.get(
      localState,
      'location_taught.select_location_city_data',
      []
    )
    profileDetails.service_ids = _.map(
      _.get(profileDetails, 'service_ids', []),
      'key'
    )

    profileDetails.businesshours = businessHours
    // profileDetails.bio = description;
    return profileDetails
  }

  const [textAreaCount, ChangeTextAreaCount] = React.useState(0)
  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length)
  }

  const tutorShortDescription = () => {
    return (
      <>
        
          <Form.Item {..._.get(formMeta, 'tutor_bio.form_item', {})}>
            <TextArea
              {..._.get(formMeta, 'tutor_bio.text_area', {})}
              onChange={recalculate}
            />
          </Form.Item>
          <div
            style={{
              fontSize: '12px',
              color: '#000',
              opacity: 0.6,
              marginTop: '3px',
            }}
          >
            {textAreaCount}/130
          </div>
        
      </>
    )
  }
  // const componentDidMount = (callback) => {
  //     if (!didMount) {
  //         callback();
  //         setDidMount(true);
  //     }
  // };

  // const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  // const recalculate = e => {
  //     ChangeTextAreaCount(e.target.value.length);
  // };

  function TutorImageUploadBlock() {
    const uploadButton = (
      <div>
        {_.get(providerProfileImage, 'loading', false) ? (
          <Spin />
        ) : (
          <AiOutlinePlus />
        )}
        <div className="ant-upload-text">{props.t('upload')}</div>
      </div>
    )
    return (
      <div className="full-width ">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <label style={css.label}>{props.t('upPhoto')}</label>
            <div className="full-width">
              <Form.Item name="photo" style={{ marginBottom: 5 }}>
                <Upload
                  disabled={_.get(providerProfileImage, 'loading', false)}
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  accept="image/*"
                  onPreview={(file) => {}}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess('ok')
                    }, 0)
                  }}
                  fileList={_.get(providerProfileImage, 'photo', [])}
                  // onPreview={handlePreview}
                  onRemove={(file) => {
                    setProviderProfileImage({ photo: [], deleted: true })
                  }}
                  onChange={(fileList) => {
                    const flist = _.get(fileList, 'fileList', [])

                    if (!_.isEmpty(flist) && flist.length > 0) {
                      set_profile_photo_s3_url_processing(true)
                      const files = _.get(flist, '[0].originFileObj', {})
                      const file_size = _.get(files, 'size', 0)
                      const maxSizeInBytes = 1024 * 1024 * 11 // 10MB

                      if (file_size > maxSizeInBytes) {
                        set_profile_photo_s3_url_processing(false)

                        const msg = props.t('fileSizeMsg')
                        notification.warning({ message: msg })
                        return false
                      }

                      const file = _.get(flist, '[0].originFileObj', {})
                      const file_name = _.get(
                        file,
                        ['name'],
                        'tutor_profile.png'
                      )
                      const params = {
                        cat: 'profile',
                        cat_id: '1',
                        subcat: 'tutor',
                        access: 'public',
                      }

                      setProviderProfileImage({
                        photo: [],
                        deleted: false,
                        loading: true,
                      })

                      // file_name = file_name.replace(/[^a-zA-Z0-9\-\.]/g, '-');

                      axios({
                        method: 'post',
                        url: '/s3/secure-url',
                        withCredentials: true,
                        data: { ...params, file_name },
                        headers: { 'Content-Type': 'application/json' },
                      })
                        .then((resp) => {
                          if (resp.status === 200) {
                            let url = resp.data
                            url = url.trim()

                            // file upload directly to s3
                            axios1({
                              method: 'put',
                              url,
                              data: file,
                              headers: {
                                'Content-Type': file.type,
                              },
                            })
                              .then((resp) => {
                                if (resp.status === 200) {
                                  const url = _.chain(resp)
                                    .get('config.url')
                                    .split('?')
                                    .first()
                                    .value()

                                  set_profile_photo_s3_url(url)
                                  set_profile_photo_s3_url_processing(false)
                                  setProviderProfileImage({
                                    photo: [
                                      {
                                        uid: '1',
                                        name: 'profile',
                                        status: 'done',
                                        url,
                                      },
                                    ],
                                    deleted: false,
                                    canUpload: false,
                                    loading: false,
                                  })
                                }
                              })
                              .catch((error) => {
                                console.error(
                                  'Error >> uploading to S3:',
                                  error
                                )
                              })
                          }
                        })
                        .catch((error) => {
                          console.error('Error >> uploading to S3:', error)
                        })
                    }
                  }}
                >
                  {_.size(_.get(providerProfileImage, 'photo', [])) > 0
                    ? null
                    : uploadButton}
                </Upload>
              </Form.Item>
              <div
                style={{
                  fontSize: '12px',
                  color: '#000',
                  opacity: 0.6,
                  marginTop: '5px',
                }}
              >
                {props.t('upPhoto1')}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const del_TutorProfileImage = () => {
    setProviderProfileImage({ ...providerProfileImage, loading: true })
    props.removeProfileImage({
      callback: (success, data) => {
        if (success) {
          setTutorProfile(_.get(data, 'details', {}))
          setProviderProfileImage({
            ...providerProfileImage,
            deleted: false,
            loading: false,
          })
          message.success('Profile image deleted successfully.')
        } else {
          setProviderProfileImage({ ...providerProfileImage, loading: false })
          message.error('Profile image Failed to get delete.')
        }
      },
    })
  }

  const validateCanUpdateForm = (form_data, additional_data = {}) => {
    let current_form_data = _.omit(_.clone(form_data), 'photo')

    current_form_data = processFormData(current_form_data)
    let current_tutor_profile_details = _.pick(
      _.clone(tutorProfile),
      _.keys(current_form_data)
    )
    current_form_data.zip_code = _.toNumber(current_form_data.zip_code)
    current_tutor_profile_details.zip_code = _.toNumber(
      current_tutor_profile_details.zip_code
    )
    current_tutor_profile_details[
      _.get(formMeta, 'service_provider_type.form_item.name', '')
    ] = _.get(tutorProfile, 'type', '')

    const lat_lng_match = _.intersection(
      _.values(_.get(_.clone(current_form_data), 'location', {})),
      _.get(_.clone(current_tutor_profile_details), 'location.coordinates', [])
    )
    current_form_data = {
      ..._.omit(current_form_data, ['location']),
      ...additional_data,
    }
    current_tutor_profile_details = _.omit(current_tutor_profile_details, [
      'location',
    ])

    const result =
      !_.isEqual(current_form_data, current_tutor_profile_details) ||
      !_.isEqual(_.size(lat_lng_match), 2) ||
      _.get(providerProfileImage, 'deleted', false) ||
      _.get(providerProfileImage, 'canUpload', false)
    if (!_.isEqual(result, canUpdateForm)) {
      setCanUpdateForm(result)
      setIsEditMode(false)
    } else if (
      _.has(props, 'isCurrentTabIsModified') &&
      !_.get(props, 'isCurrentTabIsModified')
    ) {
      setCurrentTabModified()
      setIsEditMode(false)
    }
  }

  // const manageLoadSpin = () => {
  //     const load_spin = (_.get(localState, 'is_fetching_tutor_profile', false) || _.get(localState, 'save_changes_loading', false) || _.get(providerProfileImage, 'loading', false));
  //     if (!_.isEqual(loadSpin, load_spin)) {
  //         setLoadSpin(load_spin);
  //     }
  // }

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const handleDayChange = (value) => {
    setSelectedDay(value)
  }

  const handleStartTimeChange = (time) => {
    setSelectedStartTime(time)
  }

  const handleEndTimeChange = (time) => {
    setSelectedEndTime(time)
  }

  const handleAddHours = () => {
    const startTime = dayjs(selectedStartTime).format('HH:mm')
    const endTime = dayjs(selectedEndTime).format('HH:mm')
    if (startTime > endTime) {
      alert(props.t('timeValidation4'))
      return
    }
    if (startTime === endTime) {
      alert(props.t('timeValidation3'))
      return
    }

    // Check if the selected day already exists in businessHours
    if (businessHours && businessHours.length > 0) {
      const existingHourIndex = businessHours.findIndex(
        (hour) => hour.day === selectedDay
      )

      if (existingHourIndex !== -1) {
        // If the day already exists, update the existing entry
        const updatedHours = [...businessHours]
        updatedHours[existingHourIndex] = {
          day: selectedDay,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
        }
        updatedHours.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
        setBusinessHours(updatedHours)
      } else {
        // If the day doesn't exist, add a new entry
        const newHour = {
          day: selectedDay,
          startTime: selectedStartTime,
          endTime: selectedEndTime,
        }
        const newHourArray = [...businessHours, newHour]
        newHourArray.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
        setBusinessHours([...newHourArray])
      }
    } else {
      const newHour = {
        day: selectedDay,
        startTime: selectedStartTime,
        endTime: selectedEndTime,
      }
      const newHourArray = [newHour]
      newHourArray.sort((a, b) => daysOfWeek[a.day] - daysOfWeek[b.day])
      setBusinessHours(newHourArray)
    }

    setSelectedDay('')
    setSelectedStartTime(null)
    setSelectedEndTime(null)
    setCanUpdateForm(true)
    setIsEditMode(false)
  }

  const handleEditHours = (index) => {
    const selectedHour = businessHours[index]
    setSelectedDay(selectedHour.day)
    setSelectedStartTime(dayjs(selectedHour.startTime))
    setSelectedEndTime(dayjs(selectedHour.endTime))
    setCanUpdateForm(true)
    setIsEditMode(true)
  }

  const handleDeleteHours = (index) => {
    const updatedHours = [...businessHours]
    updatedHours.splice(index, 1)
    setBusinessHours(updatedHours)
    setCanUpdateForm(true)
  }

  const mainComponent = () => {
    //console.log('profile>>>', tutorProfile)

    const selectedPlan = _.get(
      tutorProfile,
      ['subscription_plan', 'planDetails', 'value'],
      ''
    )

    const alreadyNotInAPlan = _.isEmpty(selectedPlan)

    const profile_type = _.get(tutorProfile, 'profile_type', '')
    const plStr = alreadyNotInAPlan
      ? 'Please select a plan'
      : `You are curently on "${selectedPlan}", you can change the plan.`

    const plan_rule = alreadyNotInAPlan
      ? {
          name: 'plans',
          rules: [
            {
              required: true,
              message: 'Please select which type of plan you want.',
            },
          ],
        }
      : {
          name: 'plans',
        }

    return (
      <div className="my-profile-tabPane-container">
        <div className="row" style={{ width: '100%', margin: 0 }}>
          <div className="col-12 mb-3">
            <div className="business-information">
              <Form
                form={form}
                onFinish={onSaveChanges}
                scrollToFirstError
                onValuesChange={(changedValues, allValues) => {
                  validateCanUpdateForm(allValues)
                }}
              >
                <div className="row business-information mt-4">
                  {/* defining form property create_profile_as */}
                  {profile_type === 1 && (
                  <div className="col-4">
                     <label style={css.label}>
                          {props.t('onboardingTutor15')} 
                        </label>
                      <div className="full-width">
                      {props.t('ind')}
                      </div>
                    </div>
                    )}
                    {profile_type === 0 && (
                    <>
                      <div className="col-4">
                        <Tooltip {..._.get(formMeta, 'service_provider_type.tooltip', {})}>
                          <label style={css.label}>
                            {props.t('onboardingTutor15')} <span className="label-star">*</span>
                          </label>
                        </Tooltip>
                        <div className="full-width">
                          <Form.Item {..._.get(formMeta, 'service_provider_type.form_item', {})}>
                            <Radio.Group className="class-type-radio-group">
                              {_.map(tutorTypes, (item, key) => (
                                <Radio
                                  onChange={() => setLocalState({ ...localState })}
                                  style={radioStyle}
                                  value={item.value}
                                  key={key}
                                >
                                  {item.name}
                                </Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-4 mb-4">
                        <Tooltip title={plStr}>
                          <label className="filter-group-title mb-1" style={{ width: 'auto' }}>
                            {props.t('Subscription')}
                            {alreadyNotInAPlan && <span className="label-star">*</span>}
                          </label>
                        </Tooltip>
                        <div className="full-width">
                          <Form.Item {...plan_rule}>
                            {_.map(tutorPlans, (item, key) => {
                              const isSelected =
                                item.value === _.get(tutorProfile, ['subscription_plan', 'planDetails', 'value'], '');

                              return isSelected ? <div key={key}>{item.label}</div> : null;
                            })}
                          </Form.Item>
                        </div>
                      </div>
                    </>
                  )}


                  {/* defining form property teaching_modes */}
                  <div className="col-4">
                    <Tooltip
                      {..._.get(
                        formMeta,
                        'service_provider_teaching_modes.tooltip',
                        {}
                      )}
                    >
                      <label
                        // className="filter-group-title "
                        style={css.label}
                      >
                        {props.t('onboardingTutor7')}
                        <span className="label-star">*</span>
                      </label>
                    </Tooltip>
                    <div className="full-width text-truncate">
                      <Form.Item
                        {..._.get(
                          formMeta,
                          'service_provider_teaching_modes.form_item',
                          {}
                        )}
                      >
                        <Checkbox.Group
                          className="teaching_modes_checkbox full-width"
                          options={_.get(
                            formMeta,
                            'service_provider_teaching_modes.data',
                            []
                          )}
                          onChange={() => {
                            // force re-rendering
                            setLocalState({ ...localState })
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className="contact-information">
                  {_.includes(
                    form.getFieldValue(
                      _.get(
                        formMeta,
                        'service_provider_type.form_item.name',
                        null
                      )
                    ),
                    'Individual'
                  ) ? (
                    <div className="full-width">
                      <div className="row">
                        {/* Defining form property firstname */}
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <label style={css.label}>
                            {props.t('firstname')}{' '}
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Form.Item
                              {..._.get(
                                formMeta,
                                'service_provider_firstname.form_item',
                                {}
                              )}
                            >
                              <Input
                                {..._.get(
                                  formMeta,
                                  'service_provider_firstname.input',
                                  {}
                                )}
                              />
                            </Form.Item>
                          </div>
                        </div>

                        {/* Defining form property lastname */}
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                          <label style={css.label}>{props.t('lastname')}</label>
                          <div className="full-width">
                            <Form.Item
                              {..._.get(
                                formMeta,
                                'service_provider_lastname.form_item',
                                {}
                              )}
                            >
                              <Input
                                {..._.get(
                                  formMeta,
                                  'service_provider_lastname.input',
                                  {}
                                )}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="full-width">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
                          <label style={css.label}>
                            {props.t('businame')}{' '}
                            <span className="label-star">*</span>
                          </label>
                          <div className="full-width">
                            <Form.Item
                              {..._.get(
                                formMeta,
                                'service_provider_businessname.form_item',
                                {}
                              )}
                            >
                              <Input
                                {..._.get(
                                  formMeta,
                                  'service_provider_businessname.input',
                                  {}
                                )}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Defining form property address */}
                  <div className="full-width">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label style={css.label}>
                          {props.t('address')}
                          <span className="label-star">*</span>
                        </label>
                        <div className="full-width">
                          <Form.Item
                            {..._.get(
                              formMeta,
                              'service_provider_address.form_item',
                              {}
                            )}
                          >
                            <Select
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                              {..._.get(
                                formMeta,
                                'service_provider_address.select',
                                {}
                              )}
                              onSearch={(search) => addressDelayedQuery(search)}
                              notFoundContent={
                                _.get(localState, 'address.fetching', false) ? (
                                  <Spin size="small" />
                                ) : _.get(
                                    localState,
                                    'address.search_result',
                                    []
                                  ).length === 0 ? (
                                  <div>{props.t('noResult')}</div>
                                ) : null
                              }
                              suffixIcon={<AiOutlineSearch />}
                            >
                              {_.map(
                                _.get(localState, 'address.search_result', []),
                                (d) => (
                                  <Select.Option key={_.get(d, 'value', null)}>
                                    {_.get(d, 'text', null)}
                                  </Select.Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </div>
                      </div>
                      {/* Defining form property zipcode */}
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <label style={css.label}>{props.t('zipcode')}</label>
                        <div className="full-width">
                          <Form.Item
                            {..._.get(
                              formMeta,
                              'service_provider_zipcode.form_item',
                              {}
                            )}
                          >
                            <Input
                              {..._.get(
                                formMeta,
                                'service_provider_zipcode.input',
                                {}
                              )}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Defining form property mobile number */}
                  <div className="full-width">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                        <label style={css.label}>
                          {props.t('phNo')}{' '}
                          <span className="label-star">*</span>
                        </label>
                        <div className="full-width">
                          <Form.Item
                            {..._.get(
                              formMeta,
                              'service_provider_mobile_number.form_item',
                              {}
                            )}
                          >
                            <Input
                              {..._.get(
                                formMeta,
                                'service_provider_mobile_number.input',
                                {}
                              )}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  {_.includes(
                    form.getFieldValue(
                      _.get(
                        formMeta,
                        'service_provider_type.form_item.name',
                        null
                      )
                    ),
                    'Individual'
                  ) ? (
                    <div className="full-width ">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <div className="full-width">
                            <label style={css.label}>{props.t('gender')}</label>
                            <div className="full-width">
                              <Form.Item
                                {..._.get(
                                  formMeta,
                                  'service_provider_gender.form_item',
                                  {}
                                )}
                              >
                                <Radio.Group className="class-type-radio-group">
                                  {_.map(
                                    _.keys(_.get(localState, 'gender', {})),
                                    (d, key) => (
                                      <Radio
                                        style={radioStyle}
                                        value={_.get(
                                          localState,
                                          `gender[${d}]`,
                                          {}
                                        )}
                                        key={key}
                                      >
                                        {d}
                                      </Radio>
                                    )
                                  )}
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="profile-information">
                  {/* defining form property service_ids */}
                  <div className="full-width">
                    <Tooltip
                      {..._.get(formMeta, 'nurtem_service_ids.tooltip', {})}
                    >
                      <div
                        className="filter-group-title"
                        style={{ width: 'auto' }}
                      >
                        {props.t('baseProfile')}{' '}
                        <span className="label-star">*</span>
                      </div>
                    </Tooltip>
                    <div className="title-detail-header m-1">
                      {props.t('baseProfile1')}
                    </div>
                    <div className="full-width position-relative">
                      {/* <span className="search-icon">
                                            <AiOutlineSearch />
                                        </span> */}
                      <Form.Item
                        {..._.get(formMeta, 'nurtem_service_ids.form_item', {})}
                      >
                        <Select
                          {..._.get(formMeta, 'nurtem_service_ids.select', {})}
                          onSearch={(search) =>
                            serviceListDelayedQuery({
                              search: _.startCase(search),
                            })
                          }
                          notFoundContent={
                            isGeneralPropertyLoadingInProgress(
                              _.get(props, 'services'),
                              generalActionTypes.services.list
                            ) ? (
                              <Spin size="small" />
                            ) : null
                          }
                          suffixIcon={<AiOutlineSearch />}
                        >
                          {_.map(serviceList, (d) => (
                            <Select.Option key={_.get(d, 'id', null)}>
                              {_.get(d, 'name', null)}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                      <div
                        style={{
                          marginTop: '-15px',
                          marginBottom: '12px',
                          color: '#000',
                          opacity: 0.6,
                          fontSize: '12px',
                        }}
                      >
                        {props.t('baseProfile2')}
                      </div>
                    </div>
                  </div>

                  {/* defining form property location_taught */}
                  <div className="full-width">
                    <Tooltip
                      {..._.get(
                        formMeta,
                        'service_provider_location_taught.tooltip',
                        {}
                      )}
                    >
                      <label style={css.label}>
                        {props.t('baseProfile3')}{' '}
                        <span className="label-star">
                          {_.includes(
                            form.getFieldValue(
                              _.get(
                                formMeta,
                                'service_provider_teaching_modes.form_item.name',
                                null
                              )
                            ),
                            'studio'
                          )
                            ? '*'
                            : ''}
                        </span>
                      </label>
                    </Tooltip>
                    <div className="title-detail-header m-1">
                      {props.t('baseProfile4')}
                    </div>
                    <div className="full-width position-relative">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                          <div className="full-width position-relative">
                            {/* <span className="search-icon">
                                                        <AiOutlineSearch />
                                                    </span> */}
                            <Form.Item
                              {...formMeta.service_provider_location_taught.form_item(
                                {
                                  field_required:
                                    !(
                                      _.size(
                                        _.get(
                                          localState,
                                          'location_taught.select_location_city_data'
                                        )
                                      ) > 0
                                    ) &&
                                    _.includes(
                                      form.getFieldValue(
                                        _.get(
                                          formMeta,
                                          'service_provider_teaching_modes.form_item.name',
                                          null
                                        )
                                      ),
                                      'studio'
                                    ),
                                }
                              )}
                            >
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                {..._.get(
                                  formMeta,
                                  'service_provider_location_taught.select',
                                  {}
                                )}
                                disabled={
                                  !_.includes(
                                    form.getFieldValue(
                                      _.get(
                                        formMeta,
                                        'service_provider_teaching_modes.form_item.name',
                                        null
                                      )
                                    ),
                                    'studio'
                                  )
                                }
                                notFoundContent={
                                  _.get(
                                    localState,
                                    'location_taught.fetching'
                                  ) ? (
                                    <Spin size="small" />
                                  ) : null
                                }
                                filterOption={false}
                                onSearch={(search) =>
                                  search
                                    ? locationTaughtDelayedQuery(search)
                                    : ''
                                }
                                onSelect={(value) => {
                                  let location_data = {}
                                  if (!_.isEmpty(value)) {
                                    location_data = JSON.parse(value)
                                    if (
                                      _.isEqual(
                                        _.findIndex(
                                          _.get(
                                            localState,
                                            'location_taught.select_location_city_data',
                                            []
                                          ),
                                          {
                                            geopoints: _.get(
                                              location_data,
                                              'geopoints',
                                              {}
                                            ),
                                          }
                                        ),
                                        -1
                                      )
                                    ) {
                                      setLocalState({
                                        ...localState,
                                        location_taught: {
                                          ..._.get(
                                            localState,
                                            'location_taught'
                                          ),
                                          select_location_city_data: _.concat(
                                            _.get(
                                              localState,
                                              'location_taught.select_location_city_data'
                                            ),
                                            location_data
                                          ),
                                        },
                                      })
                                    }
                                  }
                                  // Clearing selected location since we move this result as tag
                                  form.resetFields([
                                    formMeta.service_provider_location_taught.form_item()
                                      .name,
                                  ])
                                  // calling validateCanUpdateForm func
                                  validateCanUpdateForm(form.getFieldsValue(), {
                                    [formMeta.service_provider_location_taught.form_item()
                                      .name]: _.concat(
                                      _.get(
                                        localState,
                                        'location_taught.select_location_city_data'
                                      ),
                                      location_data
                                    ),
                                  })
                                }}
                                suffixIcon={<AiOutlineSearch />}
                              >
                                {_.map(
                                  _.get(
                                    localState,
                                    'location_taught.location_search_data',
                                    []
                                  ),
                                  (d) => (
                                    <Select.Option
                                      key={_.get(d, 'value', null)}
                                    >
                                      {_.get(d, 'text', null)}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 location-tag">
                          {_.map(
                            _.get(
                              localState,
                              'location_taught.select_location_city_data',
                              []
                            ),
                            (d, key) => (
                              <Tag
                                className="mb-2"
                                closable
                                key={JSON.stringify(_.get(d, 'geopoints', {}))}
                                onClose={() => {
                                  const filtered_location = _.filter(
                                    _.get(
                                      localState,
                                      'location_taught.select_location_city_data',
                                      []
                                    ),
                                    (item) =>
                                      !_.isEqual(
                                        _.get(item, 'geopoints', {}),
                                        _.get(d, 'geopoints', {})
                                      )
                                  )
                                  setLocalState({
                                    ...localState,
                                    location_taught: {
                                      ..._.get(localState, 'location_taught'),
                                      select_location_city_data:
                                        filtered_location,
                                    },
                                  })
                                  // calling validateCanUpdateForm func
                                  validateCanUpdateForm(form.getFieldsValue(), {
                                    [formMeta.service_provider_location_taught.form_item()
                                      .name]: filtered_location,
                                  })
                                }}
                              >
                                {_.get(d, 'text', '')}
                              </Tag>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                      <div className="filter-group-title ">
                        <Tooltip
                          {..._.get(
                            formMeta,
                            'service_provider_handle.tooltip',
                            {}
                          )}
                        >
                          <label style={css.label}>
                            {props.t('baseProfile5')}{' '}
                            <span className="label-star">*</span>
                          </label>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                      {_.isEqual(
                        _.get(localState, 'user_handle.validateStatus', ''),
                        'success'
                      ) ? (
                        <span className="profile-name ">
                          <AiFillCheckCircle className="profile-icon" />
                          {props.t('baseProfile6')}
                        </span>
                      ) : null}
                      {_.isEqual(
                        _.get(localState, 'user_handle.validateStatus', ''),
                        'error'
                      ) ? (
                        <span className="profile-name unavailable ">
                          <AiFillInfoCircle />
                          {props.t('baseProfile7')}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="full-width">
                    <div className="profile-link">
                      <Form.Item
                        {..._.get(
                          formMeta,
                          'service_provider_handle.form_item',
                          {}
                        )}
                        rules={[
                          () => ({
                            validator(rule, value) {
                              setLocalState({
                                ...localState,
                                user_handle: {
                                  ..._.get(localState, 'user_handle'),
                                  profileNameValid: false,
                                },
                              })
                              if (_.isEmpty(value)) {
                                setLocalState({
                                  ...localState,
                                  user_handle: {
                                    ..._.get(localState, 'user_handle'),
                                    validateStatus: 'error',
                                  },
                                })
                                return Promise.reject(props.t('baseProfile8'))
                              }
                              if (
                                !value.match(
                                  '^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$'
                                )
                              ) {
                                setLocalState({
                                  ...localState,
                                  user_handle: {
                                    ..._.get(localState, 'user_handle'),
                                    validateStatus: 'error',
                                  },
                                })
                                return Promise.reject(props.t('baseProfile9'))
                              }
                              setLocalState({
                                ...localState,
                                user_handle: {
                                  ..._.get(localState, 'user_handle'),
                                  validateStatus: '',
                                  profileNameValid: true,
                                },
                              })
                              return Promise.resolve()
                            },
                          }),
                        ]}
                        validateStatus={_.get(
                          localState,
                          'user_handle.validateStatus',
                          ''
                        )}
                      >
                        <Input
                          {..._.get(
                            formMeta,
                            'service_provider_handle.input',
                            {}
                          )}
                          onChange={({ target: { value } }) => {
                            if (
                              !_.isEmpty(value) &&
                              value.match(
                                '^[A-Za-z][A-Za-z0-9]*(?:_[A-Za-z0-9]+)*$'
                              )
                            ) {
                              userHandleDelayedQuery(value)
                            }
                          }}
                        />
                      </Form.Item>

                      {_.get(localState, ['user_handle', 'value'], false) && (
                        <span
                          className="profile-name-value mb-2"
                          style={{ alignItems: 'center' }}
                        >
                          <AiFillInfoCircle
                            style={{ fontSize: 15, marginRight: 5 }}
                          />{' '}
                          <span style={{ color: 'rgb(2, 16, 20)' }}>Your website link : </span>
                          <span style={{ color: '#371293' }}><a
                            href={`https://class.nurtem.com/profile/${_.get(localState, ['user_handle', 'value'], '')}`}
                            style={{ color: '#371293' }} // Optional: Ensures the link color matches the surrounding text
                            target="_blank" // Optional: Opens the link in a new tab
                            rel="noopener noreferrer" // Optional: Provides security benefits when using target="_blank"
                          >&nbsp;
                            https://class.nurtem.com/profile/{_.get(localState, ['user_handle', 'value'], '')}
                          </a>
                        </span></span>
                      )}
                    </div>
                  </div>
                  <div className="full-width">
                    <Tooltip
                      {..._.get(
                        formMeta,
                        'service_provider_businesshours.tooltip',
                        {}
                      )}
                    >
                      <label style={css.label}>
                        {props.t('baseProfile10')}
                      </label>
                    </Tooltip>
                    <div className="full-width">
                      <Form.Item
                        {..._.get(
                          formMeta,
                          'service_provider_businesshours.form_item',
                          {}
                        )}
                      >
                        <div className="row">
                          <div className="col-4">
                            <Select
                              value={selectedDay}
                              onChange={handleDayChange}
                              placeholder="Select Day"
                            >
                              <Option value="Monday">Mon</Option>
                              <Option value="Tuesday">Tue</Option>
                              <Option value="Wednesday">Wed</Option>
                              <Option value="Thursday">Thur</Option>
                              <Option value="Friday">Fri</Option>
                              <Option value="Saturday">Sat</Option>
                              <Option value="Sunday">Sun</Option>
                            </Select>
                          </div>
                          <div className="col-4">
                            <TimePicker
                              value={selectedStartTime}
                              onSelect={handleStartTimeChange}
                              onChange={(time, timeString) => {
                                if (time === null) {
                                  handleStartTimeChange(null)
                                } else {
                                  handleStartTimeChange(time)
                                }
                              }}
                              allowClear={true}
                              popupClassName="noFooterTimePick"
                              use12Hours
                              format="h:mm A"
                              placeholder="Start Time"
                              minuteStep={15}
                            />
                          </div>
                          <div className="col-4">
                            <TimePicker
                              value={selectedEndTime}
                              onSelect={handleEndTimeChange}
                              onChange={(time, timeString) => {
                                if (time === null) {
                                  handleEndTimeChange(null)
                                } else {
                                  handleEndTimeChange(time)
                                }
                              }}
                              allowClear={true}
                              popupClassName="noFooterTimePick"
                              format="h:mm A"
                              minuteStep={15}
                              use12Hours
                              placeholder="End Time"
                            />
                          </div>
                        </div>
                        <Button
                          className="mt-3"
                          type="primary"
                          onClick={handleAddHours}
                          disabled={
                            !selectedDay ||
                            !selectedStartTime ||
                            !selectedEndTime
                          } // Disable condition
                        >
                          {isEditMode ? 'Update Hours' : 'Add Hours'}
                        </Button>
                        <div className="mt-3">
                          {Array.isArray(businessHours) &&
                          businessHours.length > 0
                            ? businessHours.map((hour, index) => (
                                <div key={index} className="row">
                                  <div className="col-3">{hour.day}</div>
                                  <div className="col-3">
                                    {dayjs(hour.startTime).format('h:mm A')}
                                  </div>
                                  <div className="col-3">
                                    {dayjs(hour.endTime).format('h:mm A')}
                                  </div>
                                  <div className="col-3">
                                    <AiTwotoneDelete
                                      className="faq-buttons-delete"
                                      color="#ff5900"
                                      onClick={() => handleDeleteHours(index)}
                                    >
                                      Delete
                                    </AiTwotoneDelete>
                                    <AiTwotoneEdit
                                      className="faq-buttons-edit"
                                      color="#52c41a"
                                      onClick={() => handleEditHours(index)}
                                    >
                                      Edit
                                    </AiTwotoneEdit>
                                  </div>
                                </div>
                              ))
                            : props.t('baseProfile11')}
                        </div>
                      </Form.Item>
                    </div>
                  </div>

                  {/* defining form property years_of_experience */}
                  <div
                    className="row"
                    style={{ maxHeight: '75px', marginLeft: '5px' }}
                  >
                    {/* defining form property years_of_experience */}
                    <div className="row mb-3" style={{ maxHeight: '75px' }}>
                      <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <Tooltip
                          {..._.get(
                            formMeta,
                            'service_provider_teaching_experience.tooltip',
                            {}
                          )}
                        >
                          <label style={css.label}>
                            {props.t('baseProfile16')}
                          </label>
                        </Tooltip>
                        <div className="full-width">
                          <Form.Item
                            {..._.get(
                              formMeta,
                              'service_provider_teaching_experience.form_item',
                              {}
                            )}
                          >
                            <InputNumber
                              {..._.get(
                                formMeta,
                                'service_provider_teaching_experience.input_number',
                                {}
                              )}
                              className="text-center"
                              style={{ width: 80 + 'px' }}
                              // min={1}
                              // max={100}
                              // maxLength={3}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      {/* defining form property no of students taught */}
                      <div className="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
                        <Tooltip
                          {..._.get(
                            formMeta,
                            'service_provider_no_of_students_taught.tooltip',
                            {}
                          )}
                        >
                          <label style={css.label}>
                            {props.t('baseProfile17')}
                          </label>
                        </Tooltip>
                        <div className="full-width">
                          <Form.Item
                            {..._.get(
                              formMeta,
                              'service_provider_no_of_students_taught.form_item',
                              {}
                            )}
                          >
                            <InputNumber
                              {..._.get(
                                formMeta,
                                'service_provider_no_of_students_taught.input_number',
                                {}
                              )}
                              className="text-center"
                              style={{ width: `${80}px` }}
                              parser={(value) => value.replace(/[^0-9]/g, '')}
                            />
                            {/* <Input className="text-center" style={{ width: 80 + 'px' }} /> */}
                          </Form.Item>
                        </div>
                      </div>
                    </div>

                    {/* Defining form property photo */}
                    {TutorImageUploadBlock()}
                    {/* Defining form property description */}
                    {/* <div className=" textbio full-width">
                    <div className="row m-0">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <AiDescriptionGenerator
                          itemname="bio"
                          name="tutor_profile"
                          servicetext={service_text}
                          HeaderName="Tag Line"
                          type="tutor_profile_tagline"
                          descpValue={description_html}
                          onAiGenerateText={props.onAiGenerateText} // Pass other props as needed
                          updateDescription={(editorStateContent) =>
                            set_description_html(editorStateContent)
                          }
                        />
                      </div>
                    </div>
                  </div> */}
                    <div
                      className="full-width mt-4"
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <label style={css.label}>{props.t('descp')}</label>
                      {tutorShortDescription()}
                    </div>
                    {/* Defining form submit button */}
                    <div className="full-width">
                      <Form.Item>
                        <Button
                          disabled={
                            _.get(localState, 'save_changes_loading', false) ||
                            profile_photo_s3_url_processing
                          }
                          loading={
                            _.get(localState, 'save_changes_loading', false) ||
                            profile_photo_s3_url_processing
                          }
                          type="primary"
                          htmlType="submit"
                          className="btn-filter"
                        >
                          {props.t('submit')}
                        </Button>
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Scrollbars
        className="tutor-onboarding-scroll-height hidden-scroll-x"
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            className="track-horizontal"
            style={{ display: 'none' }}
          />
        )}
        renderThumbHorizontal={(props) => (
          <div
            {...props}
            className="thumb-horizontal"
            style={{ display: 'none' }}
          />
        )}
      >
        <Spin spinning={loadSpin}>{mainComponent()}</Spin>
      </Scrollbars>
      <Modal
        title="Payment Powered by Stripe"
        open={openPaymentModel}
        onCancel={handleCancel}
        footer={null}
      >
        {intent_clientSecret ? (
          <Elements
            options={{
              clientSecret: intent_clientSecret,
              theme: 'stripe',
            }}
            stripe={loadStripe(globalVariable.STRIPE_PUBLISHABLE_KEY)}
          >
            {paymentData && (
              <PlanSubscriptionForm
                paymentIntent_data={paymentData}
                showACHModal={setToOpenPaymentModel}
                clientSecret={intent_clientSecret}
                onCreateTransaction={props.onCreateTransaction}
              />
            )}
          </Elements>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </Modal>

      <Modal
        title="Cashless Plan"
        open={openCashlessModel}
        onCancel={handleCancel2}
        footer={null}
      >
        <NoCostPlanSubscriptionForm
          paymentIntent_data={paymentData}
          showACHModal={setToOpenCashlessModel}
          clientSecret={''}
          onCreateTransaction={props.onCreateTransaction}
        />
      </Modal>
    </>
  )
}

const mapStateToProps = (state) => ({
  tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}),
  services: _.get(state, `general.${generalActionTypes.services.name}`, {}),
  loggedInUserDetails: state.authentication,
  tutorProfileDetails: _.get(
    state,
    'tutorProfile.tutorBaseProfile.details',
    {}
  ),
})

const mapDispatchToProps = (dispatch) => ({
  onCreateTransaction: (details) =>
    dispatch(commonActions.onCreateTransaction(details)),

  // onAiGenerateText: (requestParams, callback) =>
  //   dispatch(commonActions.onAiGenerateText(requestParams, callback)),
  stripePaymentIntent: (data, callback) =>
    dispatch(action.stripe_payment_intent(data, callback)),
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) =>
    dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) =>
    dispatch(generalAction.tutor.updateProfile(details)),

  updateProfileImage: (details) =>
    dispatch(generalAction.tutor.updateProfileImage(details)),
  removeProfileImage: (details) =>
    dispatch(generalAction.tutor.removeProfileImage(details)),
  getTutorProfile: (details) =>
    dispatch(generalAction.tutor.getProfile(details)),
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(BaseProfileTab))
