import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

// pass parameter days
export default function OtherDay(params) {
  return (
    params.days && Array.isArray(params.days) && params.days.length > 0
      ? params.days.map((resh) => (
        <React.Fragment key={resh.index}>
          {dayjs(resh.date).format('YYYY-MM-DD')}
          <br />
        </React.Fragment>
      ))
      : '---'
  );
}
