import _ from 'lodash';
import axios from '../../../../utils/axios';
import * as searchActionType from './actionTypes';

export const common = (actionType, data) => ({
  type: actionType,
  payload: data,
});

/**
 *
 * @param {*} requestParams
 * @param {String} type  example: searchActionType.dirList, premiumList, sponoredList,
 * @returns
 */
export const onGetClasseCampList = (requestParams = {}, type = searchActionType.generalList) => {
  console.log('requestParams >>>> ', requestParams);

  const rp = { ...requestParams };

  rp.limit = 10;
  let searchAct = searchActionType.generalList;

  if (type === searchActionType.dirList) {
    rp.is_dirList = 1;// future work, need add to database and then use
    searchAct = searchActionType.dirList;
  } else if (type === searchActionType.sponoredList) {
    rp.is_sponsored = 1;
    searchAct = searchActionType.sponoredList;
  } else if (type === searchActionType.premiumList) {
    rp.is_premium = 1;
    searchAct = searchActionType.premiumList;
  } else {
    // geneal
    rp.is_sponsored = 0;
    searchAct = searchActionType.generalList;
  }

  return (dispatch) => {
    // dispatch(onClassesListLoading(true))
    setTimeout(() => {
      axios
        .get('/search', { params: rp })
        .then((response) => {
          /**
                     * HTTP 304 Not Modified client redirection response code indicates that
                     * there is no need to retransmit the requested resources
                     */

          if (response.status === 200 || response.status === 304) {
            // dispatch(onClassesListLoading(false))

            const data = _.get(response, ['data'], []);

            console.log('serviceID data 1', data);

            dispatch(common(searchAct, data));
            const serviceID = _.get(data, ['meta', 'serviceID'], '');

            console.log('serviceID data 2', serviceID);

            if (_.isEmpty(rp.serviceID) && !_.isEmpty(serviceID)) {
              dispatch(common(searchActionType.serviceID, serviceID));
            }

            // dispatch(getClassesListSuccess(response.data));
          } else {
            console.log('no data response');
            dispatch(common(searchAct, []));
          }
        })
        .catch((error) => {
          console.log('err at frontend src store actions public search index.js line 77', error.message);
          dispatch(common(searchAct, []));
        });
    }, 250);
  };
};
