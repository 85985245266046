import { isArray } from 'lodash';

const getAgeGroups = (AgeGroup) => {
  const list = [];

  if (AgeGroup) {
    isArray(AgeGroup) && AgeGroup.forEach((ageGroup) => {
      const startAge = String(ageGroup).split(',');

      if (startAge[0] === '0' && startAge[1] === '1') {
        list.push('Infant');
      } else if (startAge[0] === '1' && startAge[1] === '2') {
        list.push('Toddler');
      } else if (startAge[0] === '2' && startAge[1] === '5') {
        list.push('Pre-schooler');
      } else if (startAge[0] === '1' && startAge[1] === '5') {
        list.push('Elementary');
      } else if (startAge[0] === '6' && startAge[1] === '8') {
        list.push('Middle School');
      } else if (startAge[0] === '9' && startAge[1] === '12') {
        list.push('High School');
      } else if (startAge[0] === '13' && startAge[1] === '17') {
        list.push('College');
      } else {
        list.push('Adult');
      }
    });
  }
  return list;
};

export default getAgeGroups;
