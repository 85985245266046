const DownloadUrlXlsx = (url, fileName = 'Enroll_multiple_users_template.xlsx') => {
  try {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  } catch (err) {
    console.log('try cat block >>>', err);
  }
};
export default DownloadUrlXlsx;
