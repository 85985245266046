import React from 'react';
import _ from 'lodash';

export class ClassCatLoader extends React.Component {
  render() {
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className={css}>
        <div className="ph-item">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <div className="row">
              <div className="ph-col-12 mt-1 mb-3">
                <div className="ph-row">
                  <div
                    className="ph-picture"
                    style={{
                      height: '100px', width: '200px', borderRadius: '5%', marginRight: 10,
                    }}
                  />
                  <div
                    className="ph-picture"
                    style={{
                      height: '100px', width: '200px', borderRadius: '5%', marginRight: 10,
                    }}
                  />
                  <div
                    className="ph-picture"
                    style={{
                      height: '100px', width: '200px', borderRadius: '5%', marginRight: 10,
                    }}
                  />
                  <div
                    className="ph-picture"
                    style={{
                      height: '100px', width: '200px', borderRadius: '5%', marginRight: 10,
                    }}
                  />
                  <div
                    className="ph-picture"
                    style={{
                      height: '100px', width: '200px', borderRadius: '5%', marginRight: 10,
                    }}
                  />
                </div>
              </div>
              <div className="ph-col-12">
                <div className="ph-row">
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                </div>
              </div>
              <div className="ph-col-12">
                <div className="ph-row mt-3">
                  <div className="ph-picture" style={{ height: '50px', width: '50px', borderRadius: '100%' }} />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-picture" style={{ height: '50px', width: '50px', borderRadius: '100%' }} />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-picture" style={{ height: '50px', width: '50px', borderRadius: '100%' }} />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                  <div className="ph-col-2 big mr-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
