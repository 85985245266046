import React, { useEffect, useState } from 'react';
import {
  Form, Spin, Button, Slider, message, Tag, Select, Tooltip, Checkbox, notification,
} from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import _ from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { AiOutlineSearch } from 'react-icons/ai';
import queryString from 'query-string';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { withTranslation } from 'react-i18next';
import * as actions from '../../../../../store/actions/tutor';
import generalInfoFormMeta from './form-meta';
import generalAction from '../../../../../store/general/action';
import generalActionTypes from '../../../../../store/general/actionTypes';
import AiDescriptionGenerator from '../../../../../components/common/AiDescriptionGenerator';
import * as commonActions from '../../../../../store/actions/common';

function GeneralInfoTab(props) {
  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(generalInfoFormMeta);
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({});
  // Declare a new state variable, which we'll call "didMount"
  const [didMount, setDidMount] = useState(false);

  const [description_html, set_description_html] = useState(EditorState.createEmpty());
  const [service_text, set_service_text] = useState('');

  // Declare a new state variable, which we'll call "tutorCertifates"
  const [tutorCertifates, setTutorCertifates] = useState([]);
  // Declare a new state variable, which we'll call "forceRender"
  const [, setforceRender] = useState({});
  // Declare a new state variable, which we'll call "localState"
  const [localState, setLocalState] = useState({
    save_changes_loading: false,
    is_fetching_tutor_profile: false,
  });
    // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false);
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false);
  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm();

  const [Gallerypic, setGallerypic] = useState({
    gallery: [], deleted: false, canUpload: false, loading: false,
  });

  useEffect(() => {
    componentDidMount(() => {
      // fetching tutor profile from api
      setLocalState({ ...localState, is_fetching_tutor_profile: true });
      const url = props.location.search;
      const urlparams = queryString.parse(url);
      if (urlparams && urlparams.id) {
        props.viewProvider({
          params: { expand: 'classes,services', id: urlparams.id },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      } else {
        props.getTutorProfile({
          params: { expand: 'classes,services' },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      }
    });
    manageLoadSpin();
  });

  useEffect(() => {
    setCurrentTabModified();
  }, [canUpdateForm]);

  // Is tutorCertifates being updated
  useEffect(() => {
    // calling validateCanUpdateForm func
    if (!_.isEmpty(tutorProfile)) validateCanUpdateForm(form.getFieldsValue(), { [formMeta.service_provider_awards.form_item().name]: tutorCertifates });
  }, [tutorCertifates]);

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => { }))) {
      props.setCurrentTabModified(canUpdateForm);
    }
  };

  const validateCanUpdateForm = (form_data, additional_data = {}) => {
    let current_form_data = _.omit(_.clone(form_data), 'gallery');
    current_form_data = { ...form_data, ...processUpdateProfileData(), ...additional_data };
    const current_tutor_profile_details = _.pick(_.clone(tutorProfile), [
      _.get(formMeta, 'service_provider_bio.form_item.name', ''),
      _.get(formMeta, 'service_provider_max_drive.form_item.name'),
      _.get(formMeta, 'service_provider_amenities.form_item.name'),
      formMeta.service_provider_awards.form_item().name,
    ]);

    const result = !_.isEqual(current_form_data, current_tutor_profile_details) || (_.get(Gallerypic, 'deleted', false) || _.get(Gallerypic, 'canUpload', false));

    if (!_.isEqual(result, canUpdateForm)) {
      setCanUpdateForm(result);
    } else if ((_.has(props, 'isCurrentTabIsModified') && !_.get(props, 'isCurrentTabIsModified'))) {
      setCurrentTabModified();
    }
  };

  const setGeneralInfoFormFields = (tutor_profile_details) => {
    const formProperties = _.pick(tutor_profile_details, ['amenities',
      _.get(formMeta, 'service_provider_max_drive.form_item.name', null),
      _.get(formMeta, 'service_provider_amenities.form_item.name'),
    ]);

    const blocksFromHtml = htmlToDraft(_.get(tutor_profile_details, 'tagline', ''));
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const description_html = EditorState.createWithContent(contentState);
    set_description_html(description_html);

    setTutorCertifates(_.get(tutor_profile_details, formMeta.service_provider_awards.form_item().name, []));
    form.setFieldsValue(formProperties);
    setTutorProfile(tutor_profile_details);
    if (!_.isEmpty(_.get(tutor_profile_details, 'gallery', ''))) {
      setGallerypic({
        gallery: [
          {
            uid: '-1',
            name: 'image.png',
            status: 'done',
            url: _.get(tutor_profile_details, 'gallery'),
          },
        ],
      });
    }

    // for ai generation
    const service_labels = _.map(_.get(tutor_profile_details, 'services', []), (d) => _.get(d, 'name', 'null'));

    const name = _.get(tutor_profile_details, 'firstname', '');
    const businessname = _.get(tutor_profile_details, 'businessname', '');

    const years_of_experience = _.get(tutor_profile_details, 'years_of_experience', 1);
    const question = `tutor name '${name}' and business name '${businessname}' providing services in the following areas: ${service_labels.toString()} with ${years_of_experience} years of experience`;
    set_service_text(question);
  };

  const onSaveChanges = (form_data) => {
    setLocalState({ ...localState, save_changes_loading: true });

    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          const url = props.location.search;
          const urlparams = queryString.parse(url);
          if (urlparams && urlparams.id) {
            data.details.id = urlparams.id;
          }
          processTutorProfileImageUpload(form_data);
          form_data && form_data.gallery && delete form_data.gallery;
          updateProfile(form_data, _.get(data, 'details', {}), (success, updatedProfileData) => {
            // after completion of two api call we are doing the below process
            if (success) {
              setTutorProfile(updatedProfileData);
              setCanUpdateForm(false);
            }
            setLocalState({ ...localState, save_changes_loading: false });
          });
        } else {
          message.error('Something went wrong while updating general info please try again');
          setLocalState({ ...localState, save_changes_loading: false });
        }
      },
    });
  };

  const updateProfile = (form_data, profileDetails, callback = () => { }) => {
    profileDetails.location = { lat: _.get(profileDetails, 'location.coordinates[1]', null), lng: _.get(profileDetails, 'location.coordinates[0]', null) };
    let buildingProfileDetails = _.pick(form_data, [
      _.get(formMeta, 'service_provider_amenities.form_item.name', null),
    ]);
    buildingProfileDetails = { ...profileDetails, ...buildingProfileDetails, ...processUpdateProfileData() };
    buildingProfileDetails.tagline = draftToHtml(convertToRaw(description_html.getCurrentContent()));
    const url = props.location.search;
    const urlparams = queryString.parse(url);
    if (urlparams && urlparams.id) {
      form_data.id = urlparams.id;
    }
    // updating tutor profile to api
    props.updateProfile({
      body: buildingProfileDetails,
      callback: (success, data) => {
        if (success) {
          updateBusinessProfile(form_data, _.get(data, 'details', {}), callback);
        } else {
          message.error('Something went wrong while updating (profile info) in general info tab please try again');
          setLocalState({ ...localState, save_changes_loading: false });
        }
      },
      notification: { enable: false },
    });
  };

  const updateBusinessProfile = (form_data, profileDetails, callback = () => { }) => {
    let businessProfileDetails = _.pick(form_data, [
      _.get(formMeta, 'service_provider_max_drive.form_item.name', null),
    ]);
    businessProfileDetails = { ...profileDetails, ...businessProfileDetails };

    props.updateBusinessProfile({
      body: businessProfileDetails,
      callback: (success, data) => {
        callback(success, _.get(data, 'details', {}));
      },

    });
    notification.success({
      description: 'Updated Successfully',
      message: 'Success',
    });
  };

  const processUpdateProfileData = () => {
    const profileDetails = {};
    profileDetails[formMeta.service_provider_awards.form_item().name] = tutorCertifates;
    return profileDetails;
  };

  const isFieldRequired = (form_meta_name) => {
    const form_rules = _.isFunction(_.get(formMeta, `${form_meta_name}.form_item`, {})) ? formMeta[form_meta_name].form_item().rules : _.get(formMeta, `${form_meta_name}.form_item.rules`, []);
    return !_.isEmpty(_.find(form_rules, 'required')) ? (<span className="label-star">*</span>) : null;
  };

  const manageLoadSpin = () => {
    const load_spin = (_.get(localState, 'is_fetching_tutor_profile', false) || _.get(localState, 'save_changes_loading', false) || _.get(Gallerypic, 'loading', false));
    if (!_.isEqual(loadSpin, load_spin)) {
      setLoadSpin(load_spin);
    }
  };

  const componentDidMount = (callback) => {
    if (!didMount) {
      callback();
      setDidMount(true);
    }
  };

  const processTutorProfileImageUpload = (form_data) => {
    if (!_.isEmpty(_.get(Gallerypic, 'gallery[0].originFileObj', [])) && _.get(Gallerypic, 'canUpload', false)) {
      const formData = new FormData();
      formData.append('gallery', _.get(Gallerypic, 'gallery[0].originFileObj', []), _.get(Gallerypic, 'gallery[0].name'));
      setGallerypic({ ...Gallerypic, loading: true });
      props.updateGalleryImage({
        body: formData,
        callback: (success, data) => {
          props.onTutorProfile(props.loggedInUserDetails);
          if (success) {
            setTutorProfile(_.get(data, 'details', {}));
            setGallerypic({ ...Gallerypic, canUpload: false, loading: false });
            message.success(props.t('genProfile'));
            setGallerypic({
              gallery: [
                {
                  url: _.get(data, 'details.gallery'),
                },
              ],
            });
          } else {
            setGallerypic({ ...Gallerypic, loading: false });
            message.error(props.t('genProfile1'));
          }
        },
      });
    } else if (_.get(Gallerypic, 'deleted', false)) {
      setGallerypic({ ...Gallerypic, loading: true });
      props.removeCoverImage({
        callback: (success, data) => {
          if (success) {
            setTutorProfile(_.get(data, 'details', {}));
            setGallerypic({ ...Gallerypic, deleted: false, loading: false });
            message.success(props.t('genProfile2'));
          } else {
            setGallerypic({ ...Gallerypic, loading: false });
            message.error(props.t('genProfile3'));
          }
        },
      });
    }
  };

  const mainComponent = () => (
    <div className="row" style={{ margin: 0 }}>
      <div className="col-12 mb-3">
        <Form
          form={form}
          onFinish={onSaveChanges}
          scrollToFirstError
          onValuesChange={(changedValues, allValues) => {
            validateCanUpdateForm(allValues);
          }}
        >
          {/* defining form property bio */}
          <div className="row">
            <div className="col-12 col-lg-12 ">
              <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
                {_.get(formMeta, 'service_provider_bio.label_text', '')}
                {' '}
                {isFieldRequired('service_provider_bio')}
              </label>
              <div className="full-width drift-editor pr-2">

                <AiDescriptionGenerator
                  itemname="tagline"
                  name="tutor_profile"
                  HeaderName="About You"
                  servicetext={service_text}
                  type="tutor_profile_about"
                  descpValue={description_html}
                  onAiGenerateText={props.onAiGenerateText} // Pass other props as needed
                  updateDescription={(description_html) => set_description_html(description_html)}
                />

              </div>
            </div>
          </div>

          {/* defining form property max_drive */}
          <div className="full-width mb-4  pr-2">
            <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
              {_.get(formMeta, 'service_provider_max_drive.label_text', '')}
              {' '}
              {isFieldRequired('service_provider_max_drive')}
            </label>

            <div className="full-width max-slider-container">
              {!_.isEmpty(_.toString(form.getFieldValue('max_drive'))) ? (
                <span className="max-slider-label">
                  {form.getFieldValue('max_drive')}
                  {' '}
                  miles
                </span>
              ) : null}
              <Form.Item {..._.get(formMeta, 'service_provider_max_drive.form_item', {})}>
                <Slider onChange={() => {
                  // force re-rendering
                  setforceRender({});
                }}
                />
              </Form.Item>
            </div>
          </div>

          {/* defining form property amenities */}
          {/* <div className="full-width mb-3">
                        <Tooltip {..._.get(formMeta, 'service_provider_amenities.tooltip', {})}>
                            <label className="filter-group-title mb-3" style={{ width: 'auto' }}>{props.t("genProfile5")}<span className="label-star">*</span></label>
                        </Tooltip>
                        <div className="full-width text-truncate">
                            <Form.Item {..._.get(formMeta, 'service_provider_amenities.form_item', {})}>
                                <Checkbox.Group
                                    className="teaching_modes_checkbox full-width mb-3"
                                    options={_.get(formMeta, 'service_provider_amenities.data', [])}
                                    onChange={() => {
                                        // force re-rendering
                                        setLocalState({ ...localState })
                                    }}
                                />
                            </Form.Item>
                        </div>
                    </div> */}

          {/* defining form property credentials */}

          <div className="full-width mb-4">
            <Tooltip {..._.get(formMeta, 'service_provider_awards.tooltip', {})}>
              <label className="filter-group-title mb-3" style={{ width: 'auto' }}>
                {_.get(formMeta, 'service_provider_awards.label_text', {})}
                {' '}
                {isFieldRequired('service_provider_awards')}
              </label>
            </Tooltip>
            <div className="full-width position-relative">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5">
                  <div className="full-width position-relative">
                    <span className="search-icon">
                      <AiOutlineSearch />
                    </span>
                    <Form.Item {...formMeta.service_provider_awards.form_item(
                      {
                        field_required: !(_.size(tutorCertifates) > 0),
                      },
                    )
                                        }
                    >
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        {..._.get(formMeta, 'service_provider_awards.select', {})}
                        onSelect={
                                                    (value) => {
                                                      if (!_.isEmpty(value)) {
                                                        if (!_.includes(tutorCertifates, value)) {
                                                          setTutorCertifates(_.concat(tutorCertifates, value));
                                                        }
                                                      }

                                                      // Clearing selected certificates since we move this result as tag
                                                      form.resetFields([
                                                        formMeta.service_provider_awards.form_item().name,
                                                      ]);
                                                    }
                                                }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 location-tag">
                  {_.map(tutorCertifates, (d, key) => (
                    <Tag
                      className="mb-2"
                      closable
                      key={`${d}${key}`}
                      onClose={
                                                () => {
                                                  setTutorCertifates(_.filter(tutorCertifates, (certificate) => !_.isEqual(certificate, d)));
                                                }
                                            }
                    >
                      {d}
                    </Tag>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* defining form property Gallery */}
          {/* {TutorGalleryUploadBlock()} */}

          {/* Defining form submit button */}
          <div className="full-width button-group-section mt-3">
            <Form.Item>
              <Button disabled={!canUpdateForm || _.get(localState, 'save_changes_loading', false)} loading={_.get(localState, 'save_changes_loading', false)} type="primary" htmlType="submit" className="btn-filter">{props.t('submit')}</Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    </div>
  );
  return (
    <Scrollbars
      className="tutor-onboarding-scroll-height hidden-scroll-x"
      renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
      renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
    >
      <Spin spinning={loadSpin}>
        {mainComponent()}
      </Spin>
    </Scrollbars>
  );
}

const mapStateToProps = (state) => ({ tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}), services: _.get(state, `general.${generalActionTypes.services.name}`, {}), loggedInUserDetails: state.authentication });

const mapDispatchToProps = (dispatch) => ({
  onAiGenerateText: (requestParams, callback) => dispatch(commonActions.onAiGenerateText(requestParams, callback)),

  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) => dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) => dispatch(generalAction.tutor.updateProfile(details)),
  updateBusinessProfile: (details) => dispatch(generalAction.tutor.updateBusinessProfile(details)),
  updateGalleryImage: (details) => dispatch(generalAction.tutor.updateGalleryImage(details)),
  getTutorProfile: (details) => dispatch(generalAction.tutor.getProfile(details)),
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(GeneralInfoTab));
