import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateUserState;
// tutor actions

export default {
  // get profile data of the current logged in tutor

  getAttendanceList: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/attendances/list',
      },
      actionType: actionType.user.attendances,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },

};
