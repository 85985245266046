import { notification } from 'antd';

const notifications = (props) => {
  // console.log('datahere=======',props)
  if (props.status === 200) {
    if (props.newbooking === true) {
      notification.success({
        message: props.message,
        style: { zIndex: 600 },
      });
    } else {
      notification.info({
        message: props.message,
        style: { zIndex: 600 },
      });
    }
  } else if (props.status === 201) {
    notification.info({
      message: props.message,
      style: { zIndex: 600 },
    });
  } else if (props.status === 400) {
    notification.info({
      message: props.message,
      style: { zIndex: 600 },
    });
  }
};

export default notifications;
