// this action contains functionality for add class as well as edit class
import _ from 'lodash';
import { notification } from 'antd';

import Compressor from 'compressorjs';
import * as actionTypes from './actionTypes';
import axios from '../../../../utils/axios';

export const enableValidateAddClassStep1 = () => ({
  type: actionTypes.enableValidateAddClassStep1,
  data: { validateAddClassStep1: true },
});
export const classFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorClassDetailsFoundSuccess,
    success: data,
  });
};
export const classSessionModifySuccess = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.classSessionModifySuccess,
    success: data,
  });
};
export const disableValidateAddClassStep1 = () => ({
  type: actionTypes.disableValidateAddClassStep1,
  data: { validateAddClassStep1: false },
});
export const enableValidateAddClassStep2 = () => ({
  type: actionTypes.enableValidateAddClassStep2,
  data: { validateAddClassStep2: true },
});
export const disableValidateAddClassStep2 = () => ({
  type: actionTypes.disableValidateAddClassStep2,
  data: { validateAddClassStep2: false },
});
export const saveToLocalStorage = (value) => ({
  type: actionTypes.saveToLocalStorage,
  data: { saveToLocalStorage: value },
});
export const nextPage = () => ({
  type: actionTypes.nextPage,
  data: {},
});
export const previousPage = () => ({
  type: actionTypes.previousPage,
  data: {},
});
export const resetPage = () => ({
  type: actionTypes.resetPage,
  data: { currentPage: 0 },
});
export const addClassEnableLoading = () => ({
  type: actionTypes.addClassEnableLoading,
  loading: { loading: true },
});
export const faqLoading = (value) => ({
  type: actionTypes.faqLoading,
  loading: value,
});

export const addClassDisableLoading = () => ({
  type: actionTypes.addClassDisableLoading,
  loading: { loading: false },
});
export const addSessionDisableLoading = () => ({
  type: actionTypes.addSessionDisableLoading,
  loading: { loading: false },
});
export const classCreatedSuccessfully = (value) => ({
  type: actionTypes.addClassDisableLoading,
  loading: { classCreatedSuccessfully: value },
});
export const setClassData = (data) => ({
  type: actionTypes.setClassData,
  data: { classData: data },
});

export const setClassPhotosData = (data) => ({
  type: actionTypes.setClassPhotosData,
  loading: data,
});

export const updateImageUrlFromAiForClass = (params, callback = () => { }, url = 'classes/update-photo-fromai') => (dispatch) => {
  axios
    .post(url, {
      photo_url: params.photo_url,
      id: params.id,
    }, {
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      if (response.status === 200) {
        callback('success');
      }
    })
    .catch((error) => {
      callback('failed');
      console.log('err>>', error);
    });
};

export const updateImageForClass = (params, callback = () => { }, url = 'classes/update-photo') => (dispatch) => {
  const image = params.photo;
  new Compressor(image, {
    quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
    success: (compressedResult) => {
      const formData = new FormData();
      formData.set('id', params.id);
      formData.append('photo', compressedResult);
      axios
        .post(url, formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          if (response.status === 200) {
            callback('success');
          }
        })
        .catch((error) => {
          callback('failed');
          console.log('err>>', error);
        });
    },
  });
};

export const createClass = (data, callback = () => { }, url = 'classes/create') => (dispatch) => {
  // dispatch(addCampEnableLoading());
  axios({
    method: 'post',
    url,
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        // dispatch(campCreatedSuccessfully(true));
        callback('success', res.data.details);
      }
    })
    .catch((error) => {
      console.log('err', error.message);
      callback('failed', {});
    });
};

const beforeFetchingServices = () => ({
  type: actionTypes.beforeFetchingServices,
  data: { service_ids: { data: [], fetching: true } },
});
const afterFetchingServices = (data) => ({
  type: actionTypes.afterFetchingServices,
  data: { service_ids: { data: data.items, fetching: false } },
});
const afterFetchingServicesValues = (data) => ({
  type: actionTypes.afterFetchingServicesValues,
  data: { services: { data, fetching: false } },
});
const beforeFetchingLocations = () => ({
  type: actionTypes.beforeFetchingLocations,
  data: { location_taught: { data: [], fetching: true } },
});
const beforeFetchingServicesValues = () => ({
  type: actionTypes.beforeFetchingServicesValues,
  data: { services: { data: [], fetching: true } },
});
const afterFetchingLocations = (data) => ({
  type: actionTypes.afterFetchingLocations,
  data: { location_taught: { data, fetching: false } },
});
export const fetchServices = (lastFetchId, data) => {
  const fetchId = lastFetchId;
  return (dispatch) => {
    dispatch(beforeFetchingServices());
    axios({
      method: 'get',
      url: 'services/list',
      withCredentials: true,
      params: { search: data },
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((res) => {
        if (res.status === 200) {
          if (fetchId !== lastFetchId) {
            // for fetch callback order
            return;
          }
          dispatch(afterFetchingServices(res.data));
        }
      })
      .catch((error) => { });
  };
};
export const fetchLocations = () => (dispatch) => {
  dispatch(beforeFetchingLocations());
  dispatch(beforeFetchingServicesValues());
  axios({
    method: 'get',
    url: 'providers/profile',
    withCredentials: true,
    params: { expand: 'classes,camps,services' },
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(afterFetchingLocations(res.data.details.location_taught));
        dispatch(afterFetchingServicesValues(res.data.details.services));
      }
    })
    .catch((error) => { });
};

// edit class
export const updateClass = (params, callback = () => { }, url = '/classes/update') => (dispatch) => {
  axios({
    method: 'post',
    url,
    withCredentials: true,
    params: params.params, // it contains id of the specific class
    data: params.data, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback('success', res.data.details);
      } else {
        callback('failed', {});
      }
    })
    .catch((error) => {
      callback('failed', {});
    });
};

// Update FAQ
export const updateFAQ = (params, callback) => (dispatch) => {
  dispatch(faqLoading(true));
  axios({
    method: 'post',
    url: '/faqs/update',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
        dispatch(faqLoading(false));
      }
    })
    .catch((error) => {
      callback(error.response, 'error');
      dispatch(faqLoading(false));
    });
};
// Create FAQ
export const createFAQ = (params, callback) => (dispatch) => {
  dispatch(faqLoading(true));
  axios({
    method: 'post',
    url: '/faqs/create',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
        dispatch(faqLoading(false));
      }
    })
    .catch((error) => {
      callback(error, 'error');
      dispatch(faqLoading(false));
    });
};

// Create FAQ
export const listFAQ = (params, callback) => (dispatch) => {
  dispatch(faqLoading(true));
  axios({
    method: 'get',
    url: '/faqs/list',
    withCredentials: true,
    params,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
        dispatch(faqLoading(false));
      }
    })
    .catch((error) => {
      callback(error, 'error');
      dispatch(faqLoading(false));
    });
};

// delete FAQ
export const deleteFAQ = (params, callback) => (dispatch) => {
  dispatch(faqLoading(true));
  axios({
    method: 'post',
    url: '/faqs/delete',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
        dispatch(faqLoading(false));
      }
    })
    .catch((error) => {
      callback(error.response, 'error');
      dispatch(faqLoading(false));
    });
};
// cancel classes
export const cancelledClass = (params, cancelledClass) => (dispatch) => {
  dispatch(addClassEnableLoading());
  axios({
    method: 'post',
    url: '/classes/cancelled-days',
    withCredentials: true,
    params: { id: params.params.id, provider: params.params.provider }, // it contains id of the specific class
    data: params.data, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(addClassDisableLoading());
        dispatch(classFoundSuccess(res.data));
        dispatch(classCreatedSuccessfully(true));
        dispatch(classSessionModifySuccess(res));
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      console.log('error', _.get(error, 'res'));
      dispatch(classFoundSuccess(_.get(error, 'res.data')));
      dispatch(addClassDisableLoading());
    });
};

// add & reschdule classes
export const reschudeledClass = (params, reschudeledClass) => (dispatch) => {
  dispatch(addClassEnableLoading());
  axios({
    method: 'post',
    url: 'classes/rescheduled-days',
    withCredentials: true,
    params: { id: params.params.id, provider: params.params.provider }, // it contains id of the specific class
    data: params.data, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      dispatch(addSessionDisableLoading());
      dispatch(classSessionModifySuccess(res));
      if (res.status === 200) {
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.success(message_args);
        dispatch(classCreatedSuccessfully(true));
      } else if (res.status === 201) {
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.error(message_args);
      }
    })
    .catch((error) => {
      console.log('error', _.get(error, 'res'));
      dispatch(classSessionModifySuccess(_.get(error, 'res.data')));
      dispatch(addSessionDisableLoading());
    });
};
