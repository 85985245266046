import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { Breadcrumb, Item } from 'antd'
import './breadcrumb.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as routeName from '../../../components/route-names'
import _ from 'lodash'

function BreadCrumb(props) {
  const [previousUrl, setPreviousUrl] = useState(null)
  const location = useLocation()
  const [BCItems, setbreadCrumbItems] = useState([])

  useEffect(() => {
    const prevUrl = sessionStorage.getItem('previousUrl')
    setPreviousUrl(prevUrl)
    sessionStorage.setItem(
      'previousUrl',
      `${location.pathname}${location.search}`
    )

    const { pathname } = location
    let pathnames =
      pathname &&
      pathname.split('/').filter((item) => {
        if (item) {
          return item
        }
      })

    if (
      (pathnames.length > 2 && pathnames.includes('update-class')) ||
      (pathnames.length > 2 && pathnames.includes('update-camp')) ||
      (pathnames.length > 2 && pathnames.includes('update-competition')) ||
      (pathnames.length > 2 && pathnames.includes('update-event'))
    ) {
      pathnames = [pathnames[0], pathnames[2], pathnames[1]]
    }

    let pre_label = ''
    if (Array.isArray(pathnames)) {
      const breadcrumbItems = pathnames.map((path, index) => {
        const currentPathname = path
        const prePathname = index - 1 >= 0 ? pathnames[index - 1] : ''
        const nextPathname =
          pathnames.length - 1 >= index + 1 ? pathnames[index + 1] : ''
        const isfirst = index === 0

        const isLast = index === pathnames.length - 1

        const label = path.replace(/[\-\.]/g, ' ')

        const editedPathname = _.capitalize(label)
        let breadcrumbLabel = editedPathname
        let link = ''

        if (
          currentPathname.includes('class-details') ||
          currentPathname.includes('camp-details') ||
          currentPathname.includes('competitions-details') ||
          currentPathname.includes('events-details')
        ) {
          sessionStorage.setItem(
            'classUrl',
            `${location.pathname}${location.search}`
          )
        }
        const DashboardLabel = props.t('dashboard')

        const prePath_tutor = prePathname.includes('tutor')
        const currPath_tutor = currentPathname.includes('tutor')
        const currPath_user = currentPathname.includes('user')
        const prePath_user = prePathname.includes('user')

        if (!isLast) {
          switch (true) {
            case currPath_tutor:
              link = routeName.tutorDashboard
              breadcrumbLabel = DashboardLabel
              break

            case currPath_user:
              link = routeName.userDashboard
              breadcrumbLabel = DashboardLabel
              break

            case prePath_tutor && currentPathname.includes('class-details'):
            case currentPathname.includes('classes'):
              link = routeName.tutorClasses
              breadcrumbLabel = 'Class list'
              break
            case prePath_tutor && currentPathname.includes('camp-details'):
            case currentPathname.includes('camps'):
              link = routeName.tutorCamps
              breadcrumbLabel = 'Camp list'
              break

            case prePath_tutor && currentPathname.includes('competitions-details'):
            case currentPathname.includes('competitions'):
              link = routeName.competitions
              breadcrumbLabel = 'Competition list'
              break
            case prePath_tutor && currentPathname.includes('events-details'):
            case currentPathname.includes('events'):
                link = routeName.events
                breadcrumbLabel = 'Event list'
                break


            case prePath_tutor && currentPathname.includes('class-list'):
              link = routeName.tutorClasses
              break

            case prePath_tutor && currentPathname.includes('camp-list'):
              link = routeName.tutorCamps
              break

            case prePath_tutor && pathnames[2] === 'update-class':
              link = routeName.tutorClasses
              break

            case prePath_tutor && pathnames[2] === 'update-camp':
              link = routeName.tutorCamps
              break

            case prePath_tutor && pathnames[2] === 'update-competition':
              link = routeName.competitions
              break
            case prePath_tutor && pathnames[2] === 'update-event':
              link = routeName.competitions
              break  

            case prePath_user && currentPathname.includes('class-details'):
              link = routeName.userClasses
              break

            case prePath_user && currentPathname.includes('camp-details'):
              link = routeName.userCamps
              break

            case prePath_user &&
              currentPathname.includes('competition-details'):
              link = routeName.userCompetitions
              break
              case prePath_user &&
              currentPathname.includes('event-details'):
              link = routeName.userEvents
              break  

            case currentPathname.includes('Batch-details'):
            case currentPathname.includes('batch') &&
              nextPathname.includes('attendance'):
            case currentPathname.includes('batch') &&
              nextPathname.includes('session-attendance'):
            case currentPathname.includes('batch') &&
              nextPathname.includes('session-attendance'):
            case currentPathname.includes('session') &&
              nextPathname.includes('payment-report'):
              link = sessionStorage.getItem('classUrl')
              breadcrumbLabel = 'Batch details'
              break
            case prePath_tutor && currentPathname.includes('add-class'):
            case prePath_tutor && currentPathname.includes('add-camp'):
            case prePath_tutor && currentPathname.includes('add-competition'):
            case prePath_tutor && currentPathname.includes('add-event'):  
              link = sessionStorage.getItem('classUrl')
              breadcrumbLabel = 'Batch details'
              break
            default:
              if (isfirst) {
                breadcrumbLabel = DashboardLabel
              }
              break
          }
        }

        if (pre_label !== breadcrumbLabel) {
          pre_label = breadcrumbLabel

          let BCitem = {
            title: breadcrumbLabel,
          }

          if (!isLast) {
            BCitem['href'] = link
          }
          return BCitem
        }
      })
      const filteredBreadCrumb = breadcrumbItems.filter((item) => item)
      // console.log('breadcrumbItems:', breadcrumbItems)
      const updatedBreadcrumbItems = filteredBreadCrumb.map((item, index) => {
        if (index === breadcrumbItems.length - 1 && item.title) {
          let title = decodeURIComponent(item.title);
          if (title === 'Mycart') {
            title = 'My Cart';
          }
          return {
            ...item,
            title: title,
          }
        }
        return item
      })

      setbreadCrumbItems(updatedBreadcrumbItems)
    }
  }, [location])

  const renderBreadcrumbItem = () => (
    <div>
      {BCItems.length > 0 ? (
        <Breadcrumb
          items={BCItems}
          separator={BCItems.length > 1 && <span>&#8594;</span>}
        />
      ) : (
        ''
      )}
    </div>
  )

  return <div className="breadcrumb-header">{renderBreadcrumbItem()}</div>
}

const mapStateToProps = (state) => ({
  loggedInUserDetails: state.authentication,
})

export default connect(mapStateToProps)(withTranslation()(BreadCrumb))
