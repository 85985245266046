import React, { useEffect, useState } from 'react'
import {
  Input,
  Table,
  Modal,
  Button,
  Form,
  Popconfirm,
  notification,
  Tooltip,
} from 'antd'
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai'
import _ from 'lodash'
import { connect } from 'react-redux'
import * as action from '../../store/actions/tutor/Classes'
import * as userAction from '../../store/actions/users'
import EmptyAnime from '../re-usable/lottie/EmptyAnime'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { withTranslation } from 'react-i18next'
import timeDateConverterSession from '../timezone/sessionTimeDate'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import Linkify from 'linkify-react'

dayjs.extend(utc)
dayjs.extend(timezone)

const NotesAssignment = (props) => {
  const [createNotesModal, setCreateNotesModal] = useState(false)
  const [updatesNotesModal, SetUpdatesNotesModal] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [selectedNote, setSelectedNote] = useState(null)
  const [page, setPage] = useState(1)
  const [limit] = useState(3)
  const [createForm] = Form.useForm() // Create form for create modal
  const [updateForm] = Form.useForm()
  const [showMoreMap, setShowMoreMap] = useState({})
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  useEffect(() => {
    const notesParams = {
      type: props.type,
      page,
      limit,
      status: 1,
      sort: 'updated_at.desc',
    }

    if (props.type === 'camps') {
      notesParams.camp_details = props.session.id
      notesParams.camps = props.session.group_id
    } else if (props.type === 'classes') {
      notesParams.class_details = props.session.id
      notesParams.classes = props.session.group_id
    }

    const callback = async () =>
      await props.onGetProviderNotesForUser(notesParams)
    callback()
  }, [])

  const callback = (res) => {
    // console.log("res", res)
    if (res.status === 200 || 201) {
      notification.success({
        message: props.t('notesCreate'),
        style: {
          zIndex: 600,
        },
      })
      setEditorState(null)
    } else {
      notification.error({
        message: _.get(res, 'message', 'Failed to create Notes!'),
        style: {
          zIndex: 600,
        },
      })
    }
  }

  const sendNotes = async (event) => {
    try {
      const trimmedValues = {
        title: event.title.trim(),
      }
      const contentState = editorState.getCurrentContent()
      const rawText = contentState.getPlainText()

      if (!trimmedValues && !rawText) {
        return
      }
      const params = {
        type: props.type,
        title: event.title,
        text: rawText,
        // timezone: dayjs.tz.guess(),
        // time: new Date(),
      }

      const notesParams = {
        type: props.type,
        page,
        limit,
        staus: 1,
        sort: 'updated_at.desc',
      }

      if (props.type === 'camps') {
        params.camp_details = props.session.id
        params.camps = props.session.group_id

        notesParams.camp_details = props.session.id
        notesParams.camps = props.session.group_id
      } else if (props.type === 'classes') {
        params.class_details = props.session.id
        params.classes = props.session.group_id

        notesParams.class_details = props.session.id
        notesParams.classes = props.session.group_id
      }

      if (params) {
        setCreateNotesModal(false)
        props.sendNoteStudent(params, callback)
        setTimeout(() => props.onGetProviderNotesForUser(notesParams), 3000)
      }
      createForm.resetFields()
    } catch (error) {
    } finally {
      setCreateNotesModal(false)
      createForm.resetFields()
    }
  }

  const handleEdit = (note) => {
    setSelectedNote(note)
    const { title, text } = note
    updateForm.setFieldsValue({ title })
    const contentState = ContentState.createFromText(text)
    const initialEditorState = EditorState.createWithContent(contentState)
    setEditorState(initialEditorState)
    SetUpdatesNotesModal(true)
  }

  const onDeleteNotes = async (note) => {
    const { id } = note
    await props.deleteNotes({ id })
    const notesParams = {
      type: props.type,
      page: page - 1,
      limit,
      status: 1,
      sort: 'updated_at.desc',
    }

    if (props.type === 'camps') {
      notesParams.camp_details = props.session.id
      notesParams.camps = props.session.group_id
    } else if (props.type === 'classes') {
      notesParams.class_details = props.session.id
      notesParams.classes = props.session.group_id
    }
    setTimeout(() => props.onGetProviderNotesForUser(notesParams), 2000)
  }

  const updateNotes = async (values) => {
    try {
      const trimmedValues = {
        title: values.title.trim(),
      }
      const contentState = editorState.getCurrentContent()
      const rawText = contentState.getPlainText()

      if (!trimmedValues && !rawText) {
        return
      }
      setisLoading(true)
      const { title, text } = values
      const { id } = selectedNote
      let params = {
        id: id,
        type: props.type,
        title: title,
        text: rawText,
        // timezone: dayjs.tz.guess(),
        // time: new Date(),
      }

      const notesParams = {
        type: props.type,
        page,
        limit,
        status: 1,
        sort: 'updated_at.desc',
      }

      if (props.type === 'camps') {
        params.camp_details = props.session.id
        params.camps = props.session.group_id

        notesParams.camp_details = props.session.id
        notesParams.camps = props.session.group_id
      } else if (props.type === 'classes') {
        params.class_details = props.session.id
        params.classes = props.session.group_id

        notesParams.class_details = props.session.id
        notesParams.classes = props.session.group_id
      }
      await props.updateNotes(params)
      setTimeout(() => props.onGetProviderNotesForUser(notesParams), 2000)
      SetUpdatesNotesModal(false)
    } catch (error) {
      console.log(error)
    } finally {
      setisLoading(false)
      SetUpdatesNotesModal(false)
    }
  }

  const pageChange = async (page, pageSize) => {
    await setPage(page)
    const notesParams = {
      type: props.type,
      page,
      limit,
      status: 1,
      sort: 'updated_at.desc',
    }

    if (props.type === 'camps') {
      notesParams.camp_details = props.session.id
      notesParams.camps = props.session.group_id
    } else if (props.type === 'classes') {
      notesParams.class_details = props.session.id
      notesParams.classes = props.session.group_id
    }
    await props.onGetProviderNotesForUser(notesParams)
  }

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState)
  }

  const tiggerNotes = (st) => {
    setCreateNotesModal(st)
  }

  const renderText = (text, noteKey) => {
    const lines = text.split('\n') // Split text into lines
    const isShowMore = showMoreMap[noteKey] // Get show more/less state for this note
    if (lines.length > 2 && !isShowMore) {
      return (
        <>
          {lines.slice(0, 2).join('\n')} {/* Display only first two lines */}
          <Button onClick={() => toggleShowMore(noteKey)} className="Showmore">
            More
          </Button>
        </>
      )
    } else {
      return (
        <>
          {lines.join('\n')}
          {lines.length > 2 && (
            <Button
              onClick={() => toggleShowMore(noteKey)}
              className="Showmore"
            >
              Less
            </Button>
          )}
        </>
      )
    }
  }

  const columns = [
    {
      title: props.t('notes&asg'),
      dataIndex: 'notes',
      key: 'notes',
    },
  ]

  const customLocale = {
    emptyText: (
      <div>
        <EmptyAnime AnimeType={'Empty'} loop={true} animeheight={150} />

        <p className="empty-animations">{props.t('notesNoFound')}</p>
      </div>
    ),
  }

  const toggleShowMore = (noteKey) => {
    setShowMoreMap((prevMap) => ({
      ...prevMap,
      [noteKey]: !prevMap[noteKey],
    }))
  }

  const render = () => {
    var providerNotes = []

    let providerNotesForUser = _.get(props, 'providerNotesForUser.items', [])

    if (
      Array.isArray(providerNotesForUser) &&
      providerNotesForUser.length > 0
    ) {
      providerNotes = providerNotesForUser.map((value, i) => {
        let sessionDates = timeDateConverterSession(
          _.get(value, 'created_at'),
          'date',
          'MMM DD, YYYY | hh:mm A'
        )
        const noteKey = `note_${i}` // Get show more/less state for this note
        return {
          key: i,
          notes: (
            <div>
              <div className="mb-1">
                <i>{sessionDates}</i>
              </div>
              <div className="mb-1">
                <b> {_.get(value, 'title')}</b>
              </div>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                <Linkify as="p">
                  {renderText(_.get(value, 'text'), noteKey)}{' '}
                  {/* Pass noteKey to renderText */}
                </Linkify>
              </div>
              <Popconfirm
                title={props.t('notesDelete')}
                onConfirm={() => onDeleteNotes(value)}
                placement="bottom"
                okText="Yes"
                cancelText="No"
              >
                <Tooltip
                  placement="bottom"
                  arrow={false}
                  overlayClassName={'tooltipForIcon'}
                  title="Delete"
                >
                  <AiOutlineDelete
                    className="faq-buttons-delete"
                    color="#ff5900"
                  />
                </Tooltip>
              </Popconfirm>
              <Tooltip
                placement="bottom"
                arrow={false}
                overlayClassName={'tooltipForIcon'}
                title="Edit"
              >
                <AiOutlineEdit
                  className="faq-buttons-edit"
                  style={{ fontSize: 29 }}
                  color="#52c41a"
                  onClick={() => handleEdit(value, 'edit')}
                />
              </Tooltip>
            </div>
          ),
        }
      })
    }

    return (
      <div className="col-12">
        {/*items Header*/}
        <div className="row mb-3">
          <div className="col-6 tab-content-title">{props.t('notesList')}</div>
          <div className="col-6" style={{ textAlign: 'end' }}>
            <Button
              className="btn-green-filled"
              onClick={() => tiggerNotes(true)}
            >
              {props.t('notesAdd')}
            </Button>
          </div>
        </div>
        {/*items Header*/}

        <div className="row" style={{ margin: 0 }}>
          <Table
            dataSource={providerNotes}
            columns={columns}
            style={{ width: '100%', marginBottom: '20px' }}
            locale={customLocale}
            pagination={{
              pageSize: limit,
              onChange: (page, pageSize) => pageChange(page, pageSize),
              total:
                props.providerNotesForUser &&
                props.providerNotesForUser.meta &&
                parseInt(props.providerNotesForUser.meta.total_count),
              hideOnSinglePage: !props.loading || props.loading,
              showSizeChanger: false,
              current: page,
            }}
          />
        </div>

        <Modal
          className="faq-modal"
          title="Send Notes"
          centered
          open={createNotesModal}
          onOk={async () => {
            await createForm.resetFields()
            setCreateNotesModal(false)
          }}
          onCancel={async () => {
            await createForm.resetFields()
            setEditorState(null)
            setCreateNotesModal(false)
          }}
          footer={null}
        >
          <Form
            form={createForm}
            scrollToFirstError
            onFinish={(e) => sendNotes(e)}
            className="row"
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Form.Item
                name="title"
                label="Title"
                labelCol={{ span: 6 }}
                rules={[{ required: true, message: props.t('addTTitleMile') }]}
              >
                <Input maxLength={70} />
              </Form.Item>
              <Form.Item
                label="Text"
                name="text"
                rules={[
                  {
                    validator: async () => {
                      const getContent = editorState.getCurrentContent();
                      const descPvalue = draftToHtml(convertToRaw(getContent));
                      const strippedContent = descPvalue.replace(/<\/?[^>]+(>|$)/g, '').trim();
                      if (!strippedContent) {
                        throw new Error(props.t('batchDetails40'));
                      }
                    },
                  },
                ]}
              >
                <Editor
                  editorState={editorState}
                  editorClassName="drift-editor public-DraftEditor-content"
                  onEditorStateChange={onEditorStateChange}
                />
              </Form.Item>
            </div>
            <div className="col-12" style={{ textAlign: 'end' }}>
              <Button
                className="mr-2"
                danger
                type="primary"
                key="back"
                onClick={async () => {
                  await createForm.resetFields()
                  setEditorState(null)
                  setCreateNotesModal(false)
                }}
              >
                {props.t('cancel')}
              </Button>
              <Button type="primary" htmlType="submit" key="submit">
                {props.t('submit')}
              </Button>
            </div>
          </Form>
        </Modal>
        <Modal
          className="faq-modal"
          title="Update Notes"
          centered
          open={updatesNotesModal}
          onOk={async () => {
            await updateForm.resetFields()
            SetUpdatesNotesModal(false)
          }}
          onCancel={async () => {
            await updateForm.resetFields()
            setEditorState(null)
            SetUpdatesNotesModal(false)
          }}
          confirmLoading={isLoading}
          footer={null}
        >
          <Form
            form={updateForm}
            scrollToFirstError
            onFinish={(e) => updateNotes(e)}
            className="row"
          >
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: props.t('addTTitleMile') }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Text"
                name="text"
                rules={[
                  {
                    validator: async () => {
                      const getContent = editorState.getCurrentContent()
                      const descPvalue = draftToHtml(convertToRaw(getContent))
                      const strippedContent = descPvalue
                        .replace(/<\/?[^>]+(>|$)/g, '')
                        .trim()
                      if (!strippedContent) {
                        throw new Error(props.t('batchDetails40'))
                      }
                    },
                  },
                ]}
              >
                <div className="full-width drift-editor">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="drift-editor public-DraftEditor-content"
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
              </Form.Item>
            </div>
            <div className="col-12" style={{ textAlign: 'end' }}>
              <Button
                className="mr-2"
                danger
                type="primary"
                key="back"
                onClick={async () => {
                  await updateForm.resetFields()
                  SetUpdatesNotesModal(false)
                }}
              >
                {props.t('cancel')}
              </Button>
              <Button type="primary" htmlType="submit" key="submit Update">
                {props.t('submit')}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    )
  }

  return render()
}

const mapStateToProps = (state) => ({
  providerNotesForUser: state.userProfile.providerNotesForUser,
  onUpdateNotesForTutor: state.classesDetails.onUpdateNotesForTutor,
  deleteNotesForTutor: state.classesDetails.deleteNotesForTutor,
})

const mapDispatchToProps = (dispatch) => {
  return {
    sendNoteStudent: (requestParams, callback) =>
      dispatch(action.onCreateNotesForClass(requestParams, callback)),
    updateNotes: (requestParams) =>
      dispatch(action.onUpdateNotesForClass(requestParams)),
    deleteNotes: (requestParams) =>
      dispatch(action.onDeleteNotesForClass(requestParams)),
    onGetProviderNotesForUser: (requestParams, callback = () => { }) =>
      dispatch(userAction.getProviderNotesForUser(requestParams, callback)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NotesAssignment))
