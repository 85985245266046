import { matchPath } from "react-router-dom";
import * as SecureLS from "secure-ls";
import * as _ from "lodash";
import queryString from "query-string";
import history from "../../utils/history";
import routeClassName from "../route-names/declaringRouteNames.json";
import objectUtils from "../../utils/object";
import * as routeName from "../route-names";

const ls = new SecureLS();
export const routeRestriction = (loggedInUserDetails) => {
  if (loggedInUserDetails && loggedInUserDetails.isLoggedIn) {
    /**
     * if u find url with redirectTo parameter then redirect to that route
     */
    const url = window.location.search;
    const urlparams = queryString.parse(url);
    const redirectTo = _.get(urlparams, "redirectTo", false);

    if (redirectTo && loggedInUserDetails.login_type === "user") {
      /**
       * redirectTo key can be used for user
       * and for tutor we can use some other key
       * if it is cart then i reload also
       */
      history.push(redirectTo);

      // if (url.includes(routeName.myCart)) {
      //   window.location.reload();
      // }
      return;
    }

    if (
      ls.get("redirectPath") &&
      (loggedInUserDetails.switch_account ||
        ls.get("redirectPath").type === loggedInUserDetails.login_type)
    ) {
      history.push(ls.get("redirectPath").data);
      ls.remove("redirectPath");
      return;
    }

    // code route restrictions for unauthorized user
    if (isPathFound()) {
      if (loggedInUserDetails.login_type === "provider") {
        // code route restrictions for login_type provider

        if (!isPathFound(routeClassName.TUTOR)) {
          if (window.location.href.indexOf("/sign-in") > -1) {
            history.push("/tutor/dashboard");
          }
        }
      } else {
        // code route restrictions for login_type user

        if (!isPathFound(routeClassName.USER)) {
          history.push("/user/dashboard");
        }
      }
    } else {
      // we need to redirect to 404 component
      history.push("/404");
    }
  } else {
    console.log("log -1");
    // code route restrictions for unauthorized user
    if (isPathFound()) {
      console.log("log -1");
      if (
        !isPathFound(routeClassName.PUBLIC) &&
        (isPathFound(routeClassName.USER) ||
          isPathFound(routeClassName.TUTOR) ||
          isPathFound(routeClassName.COMPETITION))
      ) {
        console.log("log -2");
        const user_type = isPathFound(routeClassName.USER)
          ? "user"
          : "provider";
        ls.set("redirectPath", {
          data: _.pick(history.location, ["pathname", "search"]),
          type: user_type,
        });
      }

      if (
        !isPathFound(routeClassName.PUBLIC) ||
        !isPathFound(routeClassName.ACCOUNT)
      ) {
        // if path is available in some other routeClassNames then we need to redirect the user to login page

        history.push("/sign-up");
      }
    } else {
      // we need to redirect to 404 component
      history.push("/404");
    }
  }
};
export const isPathFound = (
  routeNames = {
    ...routeClassName.COMPETITION,
    ...routeClassName.ACCOUNT,
    ...routeClassName.TUTOR,
    ...routeClassName.USER,
    ...routeClassName.PUBLIC,
  }
) => {
  // let isPathFound;
  routeNames = { ...routeNames };
  //  routeNames = objectUtils.extract.extractValuesToArray(routeNames);
  // routeNames.forEach(pathName => {
  //   if (!!matchPath(history.location.pathname, pathName)) {
  //     isPathFound = true;
  //   }
  // });
  // return isPathFound;

  const pathNames = Object.values(routeNames);
  const isPathFound = pathNames.some((pathName) =>
    matchPath(history.location.pathname, pathName)
  );
  return isPathFound;
};
