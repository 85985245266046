import React, { useEffect, useRef, useState } from 'react'
import {
  Form,
  Input,
  InputNumber,
  Button,
  Tooltip,
  Table,
  notification,
} from 'antd'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import * as tutorCommon from '../../../../store/actions/tutor/common'
import queryString from 'query-string'

const AddonDetails = (props) => {
  const addonRef = useRef()

  const { type, setState, existingAddons, handleOk } = props

  const urlparams = queryString.parse(window.location.search)

  const [addonAction, setAddonAction] = useState('add')
  const [addons, setAddonList] = useState(existingAddons)

  const onDeleteAddon = async (deleteKey) => {
    try {
      const delAddon = addons.filter((item) => item.key !== deleteKey)
      setAddonList(delAddon)
      setState({ addons: delAddon })
      handleUpdateAddon(type, { id: urlparams.id, addons: delAddon })
    } catch (error) {
      console.log('error at update class,deleteAddon', error)
    }
  }

  const editAddon = (addKey) => {
    updateAddon(addKey)
  }

  const updateAddon = (key) => {
    try {
      setAddonAction('update')
      const item = addons.find((addon) => addon.key === key)
      setTimeout(() => {
        addonRef.current.setFieldsValue(item)
      }, 400)
    } catch (error) {
      console.log('error at update class,deleteAddon', error)
    }
  }

  const onFinishAddon = async (e) => {
    let updatedAddons = ''
    try {
      if (!e.name || !e.cost) {
        notification.error({ message: props.t('addForm') })
      } else {
        if (addons.length > 0 && addonAction === 'update') {
          updatedAddons = addons.map((item) => (item.key === e.key ? e : item))
          setAddonList(updatedAddons)
        } else {
          e.key = _.uniqueId(Date.now())
          updatedAddons = [...addons, e]
          setAddonList([...addons, e])
        }
        setAddonAction('add')
        setState({ addons: updatedAddons })
        addonRef.current.resetFields()
        handleUpdateAddon(type, { id: urlparams.id, addons: updatedAddons })
      }
    } catch (error) {
      console.log('error at update class,onFinishAddon', error)
    }
  }

  const handleCancel = () => {
    addonRef.current.resetFields()
    setAddonAction('add')
    handleOk()
  }

  const handleUpdateAddon = (type, data) => {
    tutorCommon.update_addon(type, data)
  }

  const columns = [
    {
      title: props.t('addonName'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: props.t('addonDesc'),
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: props.t('addonCost'),
      dataIndex: 'cost',
      key: 'cost',
      render: (text) => `$${text}`,
    },
    {
      title: props.t('action'),
      key: 'actions',
      render: (text, record) => (
        <span>
          <Tooltip title="Edit">
            <AiOutlineEdit
              style={{ fontSize: 25, paddingTop: 5, cursor: 'pointer' }}
              className="faq-buttons-edit"
              color="#52c41a"
              onClick={() => editAddon(record.key)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <AiOutlineDelete
              className="faq-buttons-delete"
              color="#ff5900"
              style={{ fontSize: 25, paddingTop: 5, cursor: 'pointer' }}
              onClick={() => onDeleteAddon(record.key)}
            />
          </Tooltip>
        </span>
      ),
    },
  ]

  return (
    <div>

      <div key="addonr2" className="row d-flex flex-wrap align-items-center">
        <div className="col">
          <Form
            style={{ display: 'flex', columnGap: '10px' }}
            key="campsAddonForm"
            className="full-width mt-2"
            ref={addonRef}
            onFinish={onFinishAddon}
          >
            <Form.Item name="key">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontWeight: 600 }}>{props.t('addonName')}</span>}
              tooltip={props.t('addontip1')}
              name="name"
              rules={[
                {
                  required: true,
                  message: props.t('addonNameReq'),
                },
                {
                  max: 100,
                  message: props.t('addonNameReq1'),
                },
              ]}
            >
              <Input placeholder={props.t('addonName')} maxLength={100} />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontWeight: 600 }}>{props.t('addonDesc')}</span>}
              tooltip="Additional Things/Material Description"
              name="description"
              rules={[
                {
                  required: false,
                  message: 'Addon Description optional.',
                },
              ]}
            >
              <Input placeholder={props.t('addonDesc')} />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontWeight: 600 }}>{props.t('addonCost')}</span>}
              tooltip="Price for Additional Things/Material"
              name="cost"
              rules={[
                {
                  required: true,
                  message: props.t('addonCostReq'),
                },
              ]}
            >
              <InputNumber min={0} placeholder="Eg:1" />
            </Form.Item>
            <Form.Item
              label={<span style={{ fontWeight: 600 }}>{props.t('action')}</span>}
            >
              <Button type="primary" className="mr-2" htmlType="submit">
                {addonAction !== 'add'
                  ? "Update"
                  : props.t('addNow')}
              </Button>
              <Button type="primary" danger onClick={handleCancel}>
                {props.t('cancel')}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Table dataSource={addons} columns={columns} rowKey="key" pagination={false} />
    </div>
  )
}

export default withTranslation()(AddonDetails)
