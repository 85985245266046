const config = () => {
  const amount_type = {
    percentage: {
      alias: 'percentage', symbol: '%', value: '0', render: (value) => `${value}%`,
    },
    dollar: {
      alias: 'dollar', symbol: '$', value: '1', render: (value) => `$${value}`,
    },
  };
  return {
    type: {
      general_discount: {
        alias: 'general_discount', name: 'General Discount', value: '0', amount_type: amount_type.percentage,
      },
      flate_rate: {
        alias: 'flate_rate', name: 'Flat Rate', value: '1', amount_type: amount_type.dollar,
      },
      item_based: {
        alias: 'item_based', name: 'Item Based', value: '2', amount_type: amount_type.percentage,
      },
    },
    amount_type,
  };
};

export default {
  config: config(),
};
