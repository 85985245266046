import { AiOutlineDownload } from 'react-icons/ai';
import { Button } from 'antd';
import React from 'react';
import axios from '../../../utils/axios';

function S3Downloader(params) {
  const handleDownload = (params) => {
    // Make a request to your server to generate the signed URL
    axios({
      method: 'post',
      url: '/s3/secure-url',
      withCredentials: true,
      data: { ...params },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((resp) => {
        if (resp.status === 200) {
          const url = resp.data;
          // Once you receive the signed URL, trigger the file download
          downloadFromS3(url);
        }
      })
      .catch((error) => {
        console.error('Error >> uploading to S3:', error);
      });
  };

  // const downloadFromS3 = (signedUrl) => {
  //   fetch(signedUrl)
  //     .then((response) => response.blob())
  //     .then((blob) => {
  //       // Create a temporary URL for the blob
  //       const url = URL.createObjectURL(blob);

  //       // Create an anchor element to trigger the download
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = 'download';

  //       // Append the anchor element to the DOM and trigger the download
  //       document.body.appendChild(link);
  //       link.click();

  //       // Clean up by removing the anchor element from the DOM
  //       document.body.removeChild(link);

  //       // Revoke the temporary URL
  //       URL.revokeObjectURL(url);
  //     })
  //     .catch((error) => {
  //       console.error('Error downloading file:', error);
  //     });
  // };

  const downloadFromS3 = (signedUrl) => {
    // Create an anchor element to trigger the download
    const link = document.createElement('a');
    link.href = signedUrl;
    link.target = '_blank';
    link.download = 'download';

    // Append the anchor element to the DOM and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the anchor element from the DOM
    document.body.removeChild(link);
  };

  return (
    <div>
      <Button icon={<AiOutlineDownload style={{ fontSize: 20 }} />} onClick={() => handleDownload({ key: params.url })}>Download</Button>
    </div>
  );
}

export default S3Downloader;
