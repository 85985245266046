import _ from 'lodash';
import dayjs from 'dayjs';
import generalLogic from './general-logic';

const couponBlockSetupLogic = (componentAssests) => {
  const generalLogicInstance = generalLogic(componentAssests);
  const setFieldsValue = _.get(componentAssests, 'self.couponBlockFormInstance.setFieldsValue', () => { });
  const resetFields = _.get(componentAssests, 'self.couponBlockFormInstance.resetFields', () => { });

  const setUpAddCoupon = () => {
    generalLogicInstance.canSetCouponBlock(() => {
      const mode = _.get(componentAssests, 'self.config.modes.addCoupon.alias', null);
      const type = _.get(componentAssests, 'self.config.type.general_discount', {});
      setTimeout(() => {
        resetFields();
        setFieldsValue({
          [_.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name', '')]: type,
        });
      }, 1450);

      generalLogicInstance.updateComponentDetails({ mode, updateCouponDetails: {}, type: _.get(type, 'alias', null) });
    });
  };

  const setUpUpdateCoupon = (updateCouponDetails) => {
    generalLogicInstance.canSetCouponBlock(() => {
      const mode = _.get(componentAssests, 'self.config.modes.updateCoupon.alias', null);
      let type = _.find(_.get(componentAssests, 'self.config.type', {}), { value: _.toString(updateCouponDetails[_.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name', '')]) });
      type = !_.isEmpty(type) ? type : {};
      const getFieldsValue = _.get(componentAssests, 'self.couponBlockFormInstance.getFieldsValue', () => { });

      setTimeout(() => {
        resetFields();
        const formFields = _.pick(updateCouponDetails, _.keys(getFieldsValue()));
        if (_.has(formFields, _.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name', ''))) {
          formFields[_.get(componentAssests, 'self.formDetails.fields.coupon_type.form_item.name')] = type;
        }
        if (_.has(formFields, _.get(componentAssests, 'self.formDetails.fields.coupon_valid_from.form_item.name', ''))) {
          formFields[_.get(componentAssests, 'self.formDetails.fields.coupon_valid_from.form_item.name')] = dayjs(formFields[_.get(componentAssests, 'self.formDetails.fields.coupon_valid_from.form_item.name')]);
        }
        if (_.has(formFields, _.get(componentAssests, 'self.formDetails.fields.coupon_expiry_date.form_item.name', ''))) {
          formFields[_.get(componentAssests, 'self.formDetails.fields.coupon_expiry_date.form_item.name')] = dayjs(formFields[_.get(componentAssests, 'self.formDetails.fields.coupon_expiry_date.form_item.name')]);
        }

        setFieldsValue(formFields);
      }, 1450);
      generalLogicInstance.updateComponentDetails({ mode, updateCouponDetails, type: _.get(type, 'alias', null) });
    });
  };

  return {
    setUpAddCoupon,
    setUpUpdateCoupon,
  };
};

export default couponBlockSetupLogic;
