import React from 'react'
import {
  notification,
  Input,
  Modal,
  Button,
  Select,
  Spin,
  Form,
  Tabs,
  Table,
  Tooltip,
} from 'antd'
import { IoIosSearch } from 'react-icons/io'
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'
import './style.css'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { geocodeByAddress } from 'react-places-autocomplete'
import dayjs from 'dayjs'
import queryString from 'query-string'
import history from '../../../../utils/history'
import * as userActions from '../../../../store/actions/users'
import * as routeName from '../../../../components/route-names'
import axios from '../../../../utils/axios'
import { globalVariable } from '../../../../utils/config/config'
import Photouploads from '../../../../components/re-usable/file-to-s3/photoupload'
import { FaChildren } from 'react-icons/fa6'
import { FaUser } from 'react-icons/fa'
import css from '../../../../utils/CSSUtility'

class UserNewMyProfile extends React.Component {
  userFormRef = React.createRef()

  dependentFormRef = React.createRef()

  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  state = {
    disabled: false,
    loading: false,
    selectValue: '',
    value: 1,
    userFormData: {},
    dependentFormData: {},
    dependentUsers: [],
    address_results: [],
    address_fetching: false,
    serviceList: [],
    photo_display: '',
    errors: {},
    address_error: '',
    photo: null,
    dependent_loading: false,
    photoUploading: false,
    activeTab: 'user',
  }

  async componentDidMount() {
    const url = window.location.search
    const urlparams = queryString.parse(url)
    if (urlparams && urlparams.dependent) {
      window.scrollTo(0, 600)
      this.setState({ activeTab: 'depn' })
    } else {
      window.scrollTo(0, 0)
    }
    this.props.userProfile()
    this.props.getDependentUsers({ limit: 100 })
  }

  async componentDidUpdate(prevprops) {
    try {
      if (
        prevprops.userBaseProfile !== this.props.userBaseProfile &&
        this.props.userBaseProfile &&
        _.get(this.props.userBaseProfile, 'success', false) === true &&
        this.props.userBaseProfile.details
      ) {
        const userFormData = _.cloneDeep(this.props.userBaseProfile.details)

        if (userFormData.photo) {
          await this.setState({
            photo_display: userFormData.photo,
            userFormData,
            serviceList: _.get(userFormData, 'service_list', []),
          })
        }
        userFormData.mobile_number = userFormData.mobile_number
          ? userFormData.mobile_number
          : null
        userFormData.zip_code = userFormData.zip_code
          ? userFormData.zip_code
          : ''
        userFormData.age = userFormData.age ? userFormData.age : ''
        userFormData.dob = userFormData.dob ? dayjs(userFormData.dob) : ''
        await this.userFormRef.current.setFieldsValue(userFormData)
      }
      if (prevprops.dependentUsers !== this.props.dependentUsers) {
        if (
          this.props.dependentUsers.meta &&
          this.props.dependentUsers.items &&
          this.props.dependentUsers.meta.count > 0
        ) {
          const dependentUsers = this.props.dependentUsers.items.filter(
            (user) => user !== null
          )

          await this.setState({
            dependentUsers,
          })
        }
      }
      if (
        prevprops.updateUserProfileInfo !== this.props.updateUserProfileInfo &&
        this.props.updateUserProfileInfo
      ) {
        notification.success({
          message: this.props.t('success'),
          description: this.props.t('profileUpdate'),
        })
        if (
          this.state.dependentUsers &&
          this.state.dependentUsers.length === 0
        ) {
          this.onConfirmDepentAdd()
          window.scrollTo(0, 0)
        } else {
          setTimeout(() => {
            if (
              this.props.searchClassCampsRouteRedirect &&
              this.props.searchClassCampsRouteRedirect.provider &&
              this.props.searchClassCampsRouteRedirect.classId
            ) {
              // history.push(`/view-class-details/${this.props.searchClassCampsRouteRedirect.provider}?classId=${this.props.searchClassCampsRouteRedirect.classId}`)
            } else if (
              this.props.searchClassCampsRouteRedirect &&
              this.props.searchClassCampsRouteRedirect.campId
            ) {
              // history.push(`/view-camp-details/${this.props.searchClassCampsRouteRedirect.campId}`)
            } else {
              // history.push(routeName.userDashboard)
            }
          }, 2000)
        }
      }
      if (
        prevprops.createDependentUser !== this.props.createDependentUser &&
        this.props.createDependentUser
      ) {
        this.setState({
          dependent_loading: false,
        })
        if (this.props.createDependentUser instanceof Error) {
          let message = 'Email already exist.'
          if (
            this.props.createDependentUser.response &&
            this.props.createDependentUser.response.data &&
            this.props.createDependentUser.response.data.message
          ) {
            message = this.props.createDependentUser.response.data.message
          } else if (
            this.props.createDependentUser.response &&
            this.props.createDependentUser.response.data &&
            this.props.createDependentUser.response.data.errors &&
            this.props.createDependentUser.response.data.errors.length > 0
          ) {
            message =
              this.props.createDependentUser.response.data.errors[0].field +
              ': ' +
              this.props.createDependentUser.response.data.errors[0].rules[0]
                .message
          }
          notification.error({
            message,
          })
        } else if (!_.get(this, 'props.createDependentUser.data.errors')) {
          let dependentUsers = this.state.dependentUsers
          dependentUsers.push(this.props.createDependentUser.details)

          dependentUsers = dependentUsers.filter((user) => user !== null)

          this.setState({
            dependentUsers,
            dependent_loading: false,
          })
          const dependentFormData = {
            firstname: '',
            gender: '',
            age: '',
            relationship: '',
          }

          this.setState({
            dependentFormData,
            selectValue: '',
          })
          await this.dependentFormRef.current.resetFields()
          this.setState({
            selectValue: '',
          })
          this.props.getDependentUsers({ limit: 100 })
          // this.dependentFormRef.current.setFieldsValue(dependentFormData);
          notification.success({
            message: this.props.t('depCreateSuccess'),
          })
          const url       = window.location.search;
          const urlparams = queryString.parse(url);
          // Check if 'cart' parameter exists
          if (urlparams && urlparams.cart) {
            history.push(routeName.myCart)// Redirect to the cart page
          }
         
        }
      }
      if (
        prevprops.deletedDependentData !== this.props.deletedDependentData &&
        this.props.deletedDependentData
      ) {
        this.props.getDependentUsers({ limit: 100 })
        notification.success({
          message: this.props.t('depDelSuccess'),
        })
      }
      if (
        prevprops.serviceList !== this.props.serviceList &&
        this.props.serviceList
      ) {
        await this.setState({ serviceList: this.props.serviceList })
      }

      if (
        prevprops.updateDependentUser !== this.props.updateDependentUser &&
        this.props.updateDependentUser
      ) {
        this.setState({
          dependent_loading: false,
        })
        const dependentFormData = {
          firstname: '',
          gender: '',
          age: '',
          relationship: '',
        }

        this.setState({
          dependentFormData,
          selectValue: '',
        })
        await this.dependentFormRef.current.resetFields()
        await this.dependentFormRef.current.setFieldsValue(dependentFormData)

        this.props.getDependentUsers({ limit: 100 })
        notification.success({
          message: this.props.t('depUpSuccess'),
        })
      }
    } catch (error) {}
  }

  // Address search
  fetchAddress = (value) => {
    const state_values = this.state
    this.setState({
      ...state_values,
      address_error: '',
      address_results: [],
      address_fetching: true,
    })

    geocodeByAddress(value)
      .then((body) => {
        const address_results = _.map(body, (location) => ({
          text: location.formatted_address,
        }))
        this.setState({
          ...state_values,
          address_results,
          address_error: '',
          address_fetching: false,
        })
      })
      .catch((error) => {
        this.setState({
          ...state_values,
          address_results: [],
          address_error: '',
          address_fetching: false,
        })
        console.error('Error while fetiching address====', error)
      })
  }

  // Initializing debounce for address
  addressDelayedQuery = _.debounce((q) => {
    this.fetchAddress(q)
  }, 1200)

  // Initializing debounce for service
  serviceDelayedQuery = _.debounce((q) => {
    this.getServicesList(q)
  }, 1200)

  getServicesList = (params = {}) => {
    const data = {
      search: _.startCase(params),
    }
    this.props.getServicesList(data)
  }

  onChangeForm = async (value, key) => {
    const { userFormData } = this.state
    userFormData[key] = value
    this.setState({ userFormData })
  }

  onChangeDependentForm = async (value, key) => {
    const { dependentFormData } = this.state
    if (key === 'gender') {
      await this.setState({
        selectValue: value,
      })
      await this.dependentFormRef.current.setFieldsValue({ gender: value })
    }
    dependentFormData[key] = value
    this.setState({ dependentFormData })
  }

  onFinish = async () => {
    const { data } = await axios.get(globalVariable.IPAPI_URL)
    const userFormData = this.userFormRef.current.getFieldValue()
    if (userFormData.mobile_number === '') {
      delete userFormData.mobile_number
    }
    // const { userFormData } = this.state
    userFormData.country_code = data.country
    userFormData.photo = this.state.photo_display
    // userFormData.dob = userFormData.dob.toISOString();
    this.props.updateProfile(userFormData)

    this.setState({ errors: {} })
  }

  onFinishDependent = async (e) => {
    const { dependentFormData } = this.state
    let { dependentUsers } = this.state
    dependentUsers = dependentUsers.filter((user) => user !== null)

    if (dependentFormData.email) {
      dependentFormData.email = dependentFormData.email.toLowerCase()
    }

    this.setState({
      dependent_loading: true,
    })
    if (dependentFormData.id) {
      dependentFormData.dependentUserId = dependentFormData.id
      // delete dependentFormData.email
      await this.props.updateDependent(dependentFormData)
      dependentUsers = _.remove(
        dependentUsers,
        (item) => item.id !== dependentFormData.id
      )
      dependentUsers.push(dependentFormData)
      this.setState({
        dependentUsers,
      })
    } else {
      const { data } = await axios.get(globalVariable.IPAPI_URL)
      dependentFormData.country_code = data.country
      await this.props.addDependent(dependentFormData)
    }
  }
  onDeleteDependent = (id) => {
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Are you sure you want to delete this dependent?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: async () => {
        try {
          let { dependentUsers } = this.state;
          dependentUsers = dependentUsers.filter((user) => user !== null);
  
          await this.props.deleteDependent({ id });
          dependentUsers = _.remove(dependentUsers, (item) => item.id !== id);
          this.setState({ dependentUsers });
        } catch (error) {
          console.log('Oops, errors!', error);
        }
      },
    });
  }

  onUpdateDependent = async (id) => {
    const { dependentUsers } = this.state
    const filteredUsers = dependentUsers.filter((user) => user !== null)

    const dependentFormData = _.find(filteredUsers, (obj) => obj.id === id)
    dependentFormData.email = this.validateEmail(dependentFormData.email)
      ? dependentFormData.email
      : ''
    dependentFormData.newOne = !this.validateEmail(dependentFormData.email)
    dependentFormData.dob = dependentFormData.dob
      ? dayjs(dependentFormData.dob)
      : ''

    this.setState({ dependentFormData })
    await this.dependentFormRef.current.setFieldsValue(dependentFormData)
  }

  onConfirmDepentAdd = () => {
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Do you want to add dependents to book class?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        Modal.destroyAll()
        setTimeout(() => {
          this.setState({ activeTab: 'depn' })
        }, 300)
      },
      onCancel: () => {
        Modal.destroyAll()
        history.push(routeName.userDashboard)
      },
    })
  }

  passwordValidation = (rule, value, callback, message) => {
    const passw =
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}|:"<>?]).{8,}/
    if (value && !value.match(passw)) {
      callback(message)
    } else {
      callback()
    }
  }

  confirmPassword = (rule, value, callback, message) => {
    try {
      const password = this.dependentFormRef.current.getFieldValue('password')
      if (value && value !== password) {
        callback(this.props.t('settings2'))
      } else {
        callback()
      }
    } catch (error) {}
  }

  cancelForm = async () => {
    const dependentFormData = {
      firstname: '',
      gender: '',
      age: '',
      relationship: '',
    }

    this.setState({
      dependentFormData,
      selectValue: '',
    })
    await this.dependentFormRef.current.resetFields()
    await this.dependentFormRef.current.setFieldsValue(dependentFormData)
    this.props.getDependentUsers({ limit: 100 })
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }


  splitDependentList = () => {
    const { dependentUsers } = this.state
    const filteredUsers = dependentUsers.filter((user) => user !== null)
    const  packageColumns = [
      {
        title: this.props.t('nameLabel'),
        dataIndex: 'firstname',
        key: 'firstname',
      },
      {
        title: this.props.t('guestFlow4'),
        dataIndex: 'relationship',
        key: 'relationship',
      },
      {
        title: this.props.t('action'),
        key: 'actions',
        render: (text, record) => (
          <span>
            <Tooltip title="Edit">
              <AiOutlineEdit
                style={{ fontSize: 25, paddingTop: 5, cursor: 'pointer' }}
                className="faq-buttons-edit"
                color="#52c41a"
                onClick={() => this.onUpdateDependent(record.id)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <AiOutlineDelete
                className="faq-buttons-delete"
                color="#ff5900"
                style={{ fontSize: 25, paddingTop: 5, cursor: 'pointer' }}
                onClick={() => this.onDeleteDependent(record.id)}
              />
            </Tooltip>
          </span>
        ),
      },
    ]
    return (< Table
      key="dependentList"
      columns={packageColumns}
      dataSource={filteredUsers}
      rowKey="id"
      pagination={{ pageSize: 5 }}
    />)
  }
 
 
  
/*   splitDependentList = () => {
    const { dependentUsers } = this.state
    const filteredUsers = dependentUsers.filter((user) => user !== null)

    return filteredUsers.map((v1, key) => {
      const vchain = _.chain(v1)
      const firstname = vchain.get('firstname').value()
      const age = vchain.get('age').value()
      const relationship = vchain.get('relationship').value()

      const ElementsID = vchain.get('id').value()

      return (
        <div
          key={_.uniqueId(`###_${key}`)}
          className="p-4 border-bottom position-relative"
          style={{ zIndex: 1 }}
        >
          <div className="row">
            <div
              key={_.uniqueId(`$$$_${key}`)}
              className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            >
              <div className="add-manage-card">
                <div className="name">{firstname}</div>
                <div className="age-group">
                  {age && `${age}yrs |`}
                  {relationship}
                </div>
                <div className="button-group mt-2">
                  <AiOutlineDelete
                    className="faq-buttons-delete"
                    color="#ff5900"
                    onClick={() => this.onDeleteDependent(ElementsID)}
                  />
                  <AiOutlineEdit
                    style={{ fontSize: 25, paddingTop: 5 }}
                    className="faq-buttons-edit"
                    color="#52c41a"
                    onClick={() => this.onUpdateDependent(ElementsID)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    })
  } */

  AgeCalculation = (props) => {
    try {
      const dateOfBirth = props.dob
      let age
      if (dateOfBirth) {
        const dateOf = dayjs(dateOfBirth)
        const today = dayjs()
        age = `${today.diff(dateOf, 'year')} Yrs`
      } else {
        age = '---'
      }

      return age
    } catch (error) {
      return ''
    }
  }

  askProfileshowModal = (value) => {
    if (!value) {
      this.setState({ openModal: false })
      history.push(routeName.userDashboard)
    } else if (value) {
      this.setState({ openModal: false })
      this.setState({ activeTab: 'depn' })
    }
  }

  tabchange = (key) => {
    if (key === 'depn') {
      if (this.props.userBaseProfile && this.props.userBaseProfile.details) {
        if (
          !this.props.userBaseProfile.details.firstname ||
          !this.props.userBaseProfile.details.zip_code
        ) {
          notification.warning({
            message: 'Warning',
            description: 'Please update your profile first',
          })
          return
        }
      }
    }
    this.setState({ activeTab: key })
  }

  render() {
    const { dependent_loading, dependentFormData } = this.state
    return (
      <div className="full-width">
        <div
          className="row"
          style={{
            width: '100%',
            margin: 0,
            marginBottom: 20,
            backgroundColor: 'white',
            borderRadius: 15,
            boxShadow: '0px 1px 5px #d2d2d2',
          }}
        >
          <div className="col-12" style={{ padding: 0 }}>
            <div ref={this.myRef} style={{ marginLeft: 0 }}>
              {/* Add/Manage your account */}
              <Tabs
                tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
                className="userprofile"
                activeKey={this.state.activeTab}
                onChange={(activeKey) => this.tabchange(activeKey)}
              >
                <items
                  onTabClick={() => this.setState({ activeTab: 'user' })}
                  tab={
                    <span>
                      <FaUser
                        style={{
                          color: 'cornflowerblue',
                          fontSize: '20px',
                          marginRight: '5px',
                        }}
                      />
                      {this.props.t('myProfile')}
                    </span>
                  }
                  key="user"
                >
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="overflow-hidden">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <Form
                          scrollToFirstError
                          onFinish={(e) => this.onFinish(e)}
                          ref={this.userFormRef}
                          colon={false}
                          className="row p-2 mr-3"
                        >
                          <div className="col-12 mt-2 text-center">
                            <Form.Item className="position-relative">
                              <Photouploads
                                type="profile"
                                subCat="users"
                                photoUrl={_.get(
                                  this.state,
                                  'photo_display',
                                  null
                                )}
                                updatePhoto={(photo) =>
                                  this.setState({
                                    photo_display_ai: null,
                                    photo_display: photo,
                                  })
                                }
                                photoLoading={(photoUploading) =>
                                  this.setState({
                                    photoUploading,
                                  })
                                }
                              />
                              <div
                                className="label-short-desc mt-1"
                                style={{
                                  fontSize: '10px',
                                  color: '#000',
                                  opacity: 0.6,
                                  textAlign: 'start',
                                }}
                              >
                                {this.props.t('upImageValid')}{' '}
                              </div>
                            </Form.Item>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Form.Item
                              label={
                                <span style={css.label}>
                                  {this.props.t('firstname')}
                                </span>
                              }
                              onChange={(e) =>
                                this.onChangeForm(e.target.value, 'firstname')
                              }
                              name="firstname"
                              rules={[
                                {
                                  required: true,
                                  pattern: '^[a-zA-Z ]*$',
                                  message: this.props.t('firstname1'),
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter your First Name"
                                maxLength={20}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Form.Item
                              label={
                                <span style={css.label}>
                                  {this.props.t('lastname')}
                                </span>
                              }
                              rules={[
                                {
                                  required: true,
                                  pattern: '^[a-zA-Z ]*$',
                                  message: this.props.t('lastname1'),
                                },
                              ]}
                              onChange={(e) =>
                                this.onChangeForm(e.target.value, 'lastname')
                              }
                              name="lastname"
                            >
                              <Input
                                placeholder="Enter your Last Name"
                                maxLength={20}
                              />
                            </Form.Item>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Form.Item
                              label={
                                <span style={css.label}>
                                  {this.props.t('phNo')}
                                </span>
                              }
                              rules={[
                                {
                                  validator: async (rule, value) => {
                                    if (value && value.length < 10) {
                                      throw new Error(this.props.t('phNo3'))
                                    }
                                  },
                                },
                              ]}
                              onChange={(e) =>
                                this.onChangeForm(
                                  e.target.value === '' ? null : e.target.value,
                                  'mobile_number'
                                )
                              }
                              name="mobile_number"
                            >
                              <Input
                                placeholder="Enter your Phone Number"
                                maxLength={15}
                                type="number"
                              />
                            </Form.Item>
                          </div>

                          {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <Form.Item label={this.props.t("dob")} name="dob">
                              <DatePicker
                                defaultPickerValue={dayjs().subtract(
                                  13,
                                  "years"
                                )}
                                format={"MM/DD/YYYY"}
                                disabledTime={true}
                                style={{
                                  borderRadius: 20,
                                  padding: "7px 15px",
                                }}
                                onChange={(e) => this.onChangeForm(e, "dob")}
                                disabledDate={(currentDate) =>
                                  currentDate.isAfter(
                                    dayjs().subtract(13, "years"),
                                    "day"
                                  )
                                }
                              />
                            </Form.Item>
                          </div> */}

                          {/* {this.props.userBaseProfile &&
                            this.props.userBaseProfile.details &&
                            this.props.userBaseProfile.details.dob && (
                              <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                <div className="row">
                                  <div
                                    className="col-12"
                                    style={{ marginTop: 5, fontSize: 12 }}
                                  >
                                    {this.props.t("age")}
                                  </div>
                                  <div className="col-12 mt-2">
                                    {this.AgeCalculation({
                                      dob: this.props.userBaseProfile.details
                                        .dob,
                                    })}
                                  </div>
                                </div>
                              </div>
                            )} */}

                          {/* <div className="gender-profile col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <Form.Item
                              name="gender"
                              label={this.props.t("gender")}
                            >
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                onChange={(e) => this.onChangeForm(e, "gender")}
                                placeholder={
                                  this.state.userFormData.gender
                                    ? this.state.userFormData.gender
                                    : "Select Gender"
                                }
                              >
                                <Select.Option key="Male">Male</Select.Option>
                                <Select.Option key="Female">
                                  Female
                                </Select.Option>
                                <Select.Option key="Others">
                                  Others
                                </Select.Option>
                              </Select>
                            </Form.Item>
                          </div> */}

                          <div className="gender-profile col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Form.Item
                              label={
                                <span style={css.label}>
                                  {this.props.t('address')}
                                </span>
                              }
                              name="address"
                            >
                              <Select
                                style={{ borderRadius: '5px' }}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                showSearch
                                allowClear={() =>
                                  this.onChangeForm(null, 'address')
                                }
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                onSearch={(search) =>
                                  search ? this.addressDelayedQuery(search) : ''
                                }
                                onChange={(value) =>
                                  this.onChangeForm(value || null, 'address')
                                }
                                placeholder="Search address..."
                                notFoundContent={
                                  this.state.address_fetching &&
                                  this.state.address_results.length === 0 ? (
                                    <Spin size="small" />
                                  ) : this.state.address_results.length ===
                                    0 ? (
                                    <div>{this.props.t('noResult')}</div>
                                  ) : null
                                }
                                suffixIcon={<IoIosSearch />}
                              >
                                {this.state.address_results &&
                                  this.state.address_results.length > 0 &&
                                  this.state.address_results.map((d) => (
                                    <Select.Option key={d.text}>
                                      {d.text}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className="gender-profile col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            <Form.Item
                              label={<span>{this.props.t('interests')}</span>}
                              name="services"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: this.props.t("onboardingTutor11"),
                              //   },
                              // ]}
                            >
                              <Select
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                                value={this.props.selectedServiceListArray}
                                showSearch
                                mode="multiple"
                                defaultActiveFirstOption={false}
                                filterOption={false}
                                onSearch={(search) =>
                                  search ? this.serviceDelayedQuery(search) : ''
                                }
                                onChange={(e) =>
                                  this.onChangeForm(e, 'services')
                                }
                                placeholder="Search field as java, cooking, math etc.,"
                                notFoundContent={
                                  this.props.serviceListLoading ? (
                                    <Spin size="small" />
                                  ) : null
                                }
                                suffixIcon={<IoIosSearch />}
                              >
                                {this.state.serviceList &&
                                  this.state.serviceList.length > 0 &&
                                  this.state.serviceList.map((d) => (
                                    <Select.Option key={d.id}>
                                      {d.name}
                                    </Select.Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                            <Form.Item
                              tooltip="To show relevant classes and camps near you"
                              label={
                                <span style={css.label}>
                                  {this.props.t('zipcode')}
                                </span>
                              }
                              onChange={(e) =>
                                this.onChangeForm(e.target.value, 'zip_code')
                              }
                              name="zip_code"
                              rules={[
                                {
                                  required: true,
                                  validator: async (rule, value) => {
                                    if (!value) {
                                      throw new Error(this.props.t('zipcode1'))
                                    }
                                    if (value < 3) {
                                      throw new Error(this.props.t('zipcode3'))
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                placeholder="Enter your Zipcode"
                                maxLength={6}
                                type="number"
                              />
                            </Form.Item>
                          </div>

                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <Form.Item style={{ marginBottom: 0 }}>
                              <Button
                                loading={this.props.loading}
                                disabled={this.state.photoUploading}
                                htmlType="submit"
                                className="btn-submit"
                              >
                                {this.props.t('submit')}
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </items>
                <items
                  tab={
                    <span>
                      <FaChildren
                        style={{
                          color: 'cornflowerblue',
                          fontSize: '20px',
                          marginRight: '5px',
                        }}
                      />
                      {this.props.t('depLabel')}
                    </span>
                  }
                  onTabClick={() => this.setState({ activeTab: 'depn' })}
                  key="depn"
                >
                  <div className="row" style={{ width: '100%', marginLeft: 0 }}>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2 mb-3">
                      <div className="nurtem-profile-card-title">
                        {this.props.t('addDepn')}{' '}
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="nurtem-profile-card overflow-hidden">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 border-right">
                            <Form
                              scrollToFirstError
                              onFinish={(e) => this.onFinishDependent(e)}
                              ref={this.dependentFormRef}
                              className="row p-2 mr-3"
                            >
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Form.Item
                                  label={
                                    <span style={css.label}>
                                      {this.props.t('firstname')}
                                    </span>
                                  }
                                  onChange={(e) =>
                                    this.onChangeDependentForm(
                                      e.target.value,
                                      'firstname'
                                    )
                                  }
                                  name="firstname"
                                  rules={[
                                    {
                                      required: true,
                                      pattern: '^[a-zA-Z ]*$',
                                      message: this.props.t('firstname1'),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter your First Name"
                                    maxLength={30}
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Form.Item
                                  label={
                                    <span style={css.label}>
                                      {this.props.t('lastname')}
                                    </span>
                                  }
                                  onChange={(e) =>
                                    this.onChangeDependentForm(
                                      e.target.value,
                                      'lastname'
                                    )
                                  }
                                  name="lastname"
                                  rules={[
                                    {
                                      required: true,
                                      pattern: '^[a-zA-Z ]*$',
                                      message: this.props.t('lastname1'),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Enter your Last Name"
                                    maxLength={30}
                                  />
                                </Form.Item>
                              </div>
                              {/* <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                          <Form.Item
                            label="Age"
                            name="age"
                            rules={[{ required: true, message: 'Please input your age!' }]}>
                            <InputNumber onChange={(e) => this.onChangeDependentForm(e, 'age')} max={100} min={1} maxLength={3} parser={(value) => value.replace(/[^0-9]/g, "")} />
                          </Form.Item>
                        </div> */}
                              {/* <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <Form.Item
                                  label={this.props.t("dob")}
                                  name="dob"
                                  rules={[{ required: false }]}
                                >
                                  <DatePicker
                                    defaultPickerValue={dayjs().subtract(
                                      13,
                                      "years"
                                    )}
                                    format={"MM/DD/YYYY"}
                                    disabledTime={true}
                                    style={{
                                      borderRadius: 20,
                                      padding: "7px 15px",
                                    }}
                                    onChange={(e) =>
                                      this.onChangeDependentForm(e, "dob")
                                    }
                                    disabledDate={(currentDate) =>
                                      currentDate.isAfter(
                                        dayjs().subtract(13, "years"),
                                        "day"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>

                              {_.get(
                                this,
                                "state.dependentFormData.dob",
                                ""
                              ) && (
                                <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                  <div className="row">
                                    <div
                                      className="col-12"
                                      style={{ marginTop: 5, fontSize: 12 }}
                                    >
                                      {this.props.t("age")}{" "}
                                    </div>
                                    <div className="col-12 mt-2">
                                      {this.AgeCalculation({
                                        dob: _.get(
                                          this.state.dependentFormData,
                                          "dob"
                                        ),
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="gender-profile col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Form.Item
                                  name="gender"
                                  label={this.props.t("gender")}
                                  rules={[{ required: false }]}
                                >
                                  <Select
                                    rules={[
                                      {
                                        required: false,
                                      },
                                    ]}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                    onChange={(e) =>
                                      this.onChangeDependentForm(e, "gender")
                                    }
                                    placeholder={_.get(
                                      this.state.dependentFormData,
                                      "gender",
                                      "Select Gender"
                                    )}
                                  >
                                    <Select.Option value="male">
                                      Male
                                    </Select.Option>
                                    <Select.Option value="female">
                                      Female
                                    </Select.Option>
                                    <Select.Option value="others">
                                      Others
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div> */}

                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <Form.Item
                                  label={
                                    <span style={css.label}>
                                      {this.props.t('guestFlow4')}
                                    </span>
                                  }
                                  onChange={(e) =>
                                    this.onChangeDependentForm(
                                      e.target.value,
                                      'relationship'
                                    )
                                  }
                                  name="relationship"
                                  rules={[
                                    {
                                      required: true,
                                      message: this.props.t('guestFlow8'),
                                    },
                                  ]}
                                >
                                  <Input
                                    maxLength={30}
                                    placeholder="Enter the Dependent's relationship to you"
                                  />
                                </Form.Item>
                              </div>
                            
                              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Form.Item
                                      tooltip="You can login and access the study material, notes, message tutor etc.,"
                                      label={
                                        <span style={css.label}>
                                          {this.props.t('depnEmail')}
                                        </span>
                                      }
                                      name="email"
                                      rules={[
                                        // {
                                        //   required: true,
                                        // },
                                        {
                                          type: 'email',
                                          message: this.props.t('emailValidationMessage'),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder="Enter your Email Address"
                                        readOnly={
                                          !(
                                            _.get(this, 'state.dependentFormData.id', '') === '' ||
                                            _.get(this, 'state.dependentFormData.newOne') === true
                                          )
                                        }
                                        onChange={(e) => this.onChangeDependentForm(e.target.value, 'email')}
                                      />
                                    </Form.Item>
                                  </div>

                                  {(_.get(
                                this,
                                'state.dependentFormData.id',
                                ''
                              ) === '' ||
                                _.get(
                                  this,
                                  'state.dependentFormData.newOne'
                                ) === true) && (
                                <>
                                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Form.Item
                                      label={
                                        <span style={css.label}>
                                          {this.props.t('createPassNew')}
                                        </span>
                                      }
                                      onChange={(e) =>
                                        this.onChangeDependentForm(
                                          e.target.value,
                                          'password'
                                        )
                                      }
                                      name="password"
                                      dependencies={['new_password']}
                                      rules={[
                                        // {
                                        //   required:
                                        //     this.dependentFormRef.current &&
                                        //     (this.dependentFormRef.current.getFieldValue(
                                        //       "email"
                                        //     ) ||
                                        //       this.dependentFormRef.current.getFieldValue(
                                        //         "new_password"
                                        //       ))
                                        //       ? true
                                        //       : true,
                                        //   message:
                                        //     "Please input your Password!",
                                        // },
                                        {
                                          validator: (rule, value, callback) =>
                                            this.passwordValidation(
                                              rule,
                                              value,
                                              callback,
                                              this.props.t('enterPass3')
                                            ),
                                        },
                                      ]}
                                    >
                                      <Input.Password
                                        autoComplete="new-password"
                                        placeholder="Enter your Password"
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <Form.Item
                                      label={
                                        <span style={css.label}>
                                          {this.props.t('confirmPassword')}
                                        </span>
                                      }
                                      onChange={(e) =>
                                        this.onChangeDependentForm(
                                          e.target.value,
                                          'new_password'
                                        )
                                      }
                                      name="new_password"
                                      dependencies={['password']}
                                      rules={[
                                        // {
                                        //   required:
                                        //     this.dependentFormRef.current &&
                                        //     (this.dependentFormRef.current.getFieldValue(
                                        //       "email"
                                        //     ) ||
                                        //       this.dependentFormRef.current.getFieldValue(
                                        //         "password"
                                        //       ))
                                        //       ? true
                                        //       : true,
                                        //   message: this.props.t("confirmPass"),
                                        // },
                                        {
                                          validator: (rule, value, callback) =>
                                            this.confirmPassword(
                                              rule,
                                              value,
                                              callback,
                                              this.props.t('enterPass3')
                                            ),
                                        },
                                      ]}
                                    >
                                      <Input.Password placeholder="Enter your Confirm Password" />
                                    </Form.Item>
                                  </div>
                                </>
                              )}

                              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Form.Item style={{ marginBottom: 0 }}>
                                  <Button
                                    htmlType="submit"
                                    loading={dependent_loading}
                                    className="btn-submit"
                                  >
                                    {this.props.t('submit')}
                                  </Button>
                                  {dependentFormData &&
                                    dependentFormData.id && (
                                      <Button
                                        onClick={() => this.cancelForm()}
                                        className="btn-submit"
                                      >
                                        {this.props.t('cancel')}
                                      </Button>
                                    )}
                                </Form.Item>
                              </div>
                            </Form>
                          </div>
                          </div>
                          <div className="row"> 
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 position-relative">
                            {this.state.dependentUsers.length > 0 &&
                              this.splitDependentList()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </items>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>
  _.chain(state)
    .get('userProfile')
    .pick([
      'userBaseProfile',
      'dependentUsers',
      'updateUserProfile',
      'updateUserProfileInfo',
      'createDependentUser',
      'deleteDependentUser',
      'updateDependentUser',
      'serviceListLoading',
      'serviceList',
    ])
    .thru((profile) => ({
      ...profile,
      serviceList: [
        ..._.get(profile, 'serviceList.items', []),
        ..._.get(profile, 'userBaseProfile.details.service_list', []),
      ],
      selectedServiceListArray: _.get(
        profile,
        'userBaseProfile.details.service_list',
        []
      ),
      searchClassCampsRouteRedirect: _.get(
        state,
        'services.searchClassCampsRouteRedirect',
        {}
      ),
    }))
    .value()

const mapDispatchToProps = (dispatch) => ({
  userProfile: () => dispatch(userActions.userProfile()),
  updateProfile: (data) => dispatch(userActions.updateUserProfileInfo(data)),
  getServicesList: (data) => dispatch(userActions.serviceList(data)),
  addDependent: (data) => dispatch(userActions.createDependentUser(data)),
  updateDependent: (data) => dispatch(userActions.updateDependentUser(data)),
  deleteDependent: (data) => dispatch(userActions.deleteDependentUser(data)),
  getDependentUsers: (data) => dispatch(userActions.getDependentUsers(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserNewMyProfile))
