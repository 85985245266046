import _ from 'lodash';
import { notification } from 'antd';

export const updateObject = (oldObject, updatedProperties) => ({
  ...oldObject,
  ...updatedProperties,
});

export const switchPropertyLoading = (dispatch, reducerName, actionType, loading, additionalParams = {}) => {
  dispatch({
    type: `${reducerName}SwitchPropertyLoading`,
    data: { [`${actionType}${LoadingInProgress}`]: loading, ...additionalParams },
  });
};

export const reducersName = {
  users: 'users',
  tutor: 'tutor',
  public: 'public',
  authentication: 'authentication',
};

export const isPropertyLoadingInProgress = (reducer_state, actionType) => _.get(reducer_state, `${actionType}${LoadingInProgress}`, false);

export const LoadingInProgress = 'LoadingInProgress';
//type NotificationType = 'success' | 'info' | 'warning' | 'error';
export const notifyAPIResponse = (type, descriptions, title, notificationON = true) => {
  if (notificationON) {
    notification[type]({
      message: title,
      description: descriptions,
    });
  }
};
