import React from 'react';
import _ from 'lodash';

export class TodaysClassLoader extends React.Component {
  render() {
    const NoOfTimes = this.props.cards ? _.range(this.props.cards) : _.range(6);
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className={css}>
        <div className="row">
          <div className="card-tab-section">
            <div className="ph-item">
              {Array.isArray(NoOfTimes) && NoOfTimes.map((_, i) => (
                <div key={i} className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 mb-4">
                  <div className="nurtem-dashboard-card">
                    <div className="col-12">
                      <div className="row">
                        <div className="ph-col-4">
                          <div className="ph-picture" style={{ height: '50px', width: '50px', borderRadius: '100%' }} />
                        </div>
                        <div className="ph-col-8">
                          <div className="ph-row">
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-8 big" />
                          </div>
                        </div>
                        <div className="ph-col-12">
                          <div className="ph-row mt-3">
                            <div className="ph-col-12" />
                            <div className="ph-col-12" />
                            <div className="ph-col-12" />
                          </div>
                          <div className="ph-row">
                            <div className="ph-col-4 big" />
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-2 empty" />
                            <div className="ph-col-4 big" />
                            <div className="ph-col-2 empty" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
