import { Layout, Button, notification } from 'antd'
import React from 'react'
import { Router, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as SecureLS from 'secure-ls'
import queryString from 'query-string'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import Header from '../layout/header'
import Footer from '../layout/footer'
import BreadCrumb from '../layout/BreadCrumb'
import LayoutContent from './content'
import axios from '../../utils/axios'
import history from '../../utils/history'
import { routeRestriction } from '../../components/Other/routeRestriction'
import UserDashboardPageSidebar from '../layout/Sidebar/user-dashboard-sidebar/user-dashboard-sidebar'
import * as routeName from '../../components/route-names'
import '../../assets/fonts/nurtem/style.css'
import _ from 'lodash'
import { DefaultLoader } from '../../components/loader/default-loader'
import { toggleLeftMenu } from '../../store/actions/public/search/actionTypes'
import * as searchAction from '../../store/actions/public/search'

const { Sider } = Layout

class UserAccount extends React.Component {
  state = {
    sidebar: true,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.sidebar) {
      this.setState({ sidebar: true })
    }
  }

  async componentDidMount() {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    if (!_.isEmpty(urlparams)) {
      if (urlparams && urlparams.type === 'guest') {
        history.push('/mycart')
      }
    }

    const ls = new SecureLS()
    let userInfo = ls.get('userInfoNew')
    if (_.isEmpty(userInfo)) {
      const resp = await axios({
        method: 'get',
        url: '/getUserProfile',
        withCredentials: true,
        params: { expand: 'enrolled_count,sessions_left,user,cart' },
        config: { headers: { 'Content-Type': 'application/json' } },
      })

      userInfo = { ...resp.data, success: true }
      ls.set('userInfoNew', userInfo)
    }

    if (!this.state.sidebar) {
      this.setState({ sidebar: true })
    }

    // console.log("userInfo", userInfo)

    const uinfo = _.get(userInfo, ['details'], '')
    const firstname = _.get(uinfo, ['firstname'], '')
    const email = _.get(uinfo, ['email'], '')
    const profileFilled = !_.isEmpty(firstname) && !_.isEmpty(email)

    if (profileFilled) {
      if (!this.state.sidebar) {
        this.setState({ sidebar: true })
      }
    } else {
      // commented for now gunabalans
      // history.push(routeName.userProfile)
      if (this.state.sidebar) {
        this.setState({ sidebar: false })
      }
    }
    routeRestriction(this.props.loggedInUserDetails)
  }

  onCollapse = (cond) => {
    this.props.setToggleMenu(cond)
  }

  render() {
    const { pathname } = this.props.location

    if (
      routeName.userProfile !== pathname &&
      pathname !== '/guest/profile' &&
      this.props.userBaseProfile &&
      this.props.userBaseProfile.details
    ) {
      if (
        _.get(this, 'props.userBaseProfile.details.user.account_type') !==
        'dependent'
      ) {
        if (
          !this.props.userBaseProfile.details.firstname ||
          !this.props.userBaseProfile.details.zip_code
        ) {
          history.push(routeName.userProfile)
          notification.warning({
            message: 'Warning',
            description: 'Please update your profile.',
          })
        }
      }
    }

    return (
      <Router history={history}>
        <div className="page-container">
          <div className="header-container">
            <div className="container-fluid">
              <Header location={history.location} />
            </div>
          </div>
          {Object.getOwnPropertyNames(this.props.userBaseProfile).length > 0 ? (
            <Layout className="site-layout">
              <div
                style={{
                  position: 'relative',
                  minHeight: 'calc(100vh - 190px)',
                  width: '100%',
                  margin: '0 0 px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {this.state.sidebar && (
                  <Sider
                    breakpoint="lg"
                    trigger={null}
                    collapsible
                    collapsed={this.props.toggleMenu}
                    className="usersidebar"
                    width="270"
                    transition=""
                    onBreakpoint={(broken) => {
                      if (!broken) {
                        // Screen size increased, set collapsed to false
                        this.props.setToggleMenu(false)
                      } else {
                        // Screen size decreased, set collapsed to true
                        this.props.setToggleMenu(true)
                      }
                    }}
                    style={{
                      position: 'fixed',
                      overflow: 'auto',
                      top: 60,
                      bottom: -20,
                    }}
                  >
                    <UserDashboardPageSidebar />
                  </Sider>
                )}

                {this.state.sidebar && (
                  <Button
                    size="small"
                    className={
                      this.props.toggleMenu
                        ? 'menuresize usermenuresizeleftbtn'
                        : 'menuresize usermenuresizerightbtn'
                    }
                    shape="circle"
                    icon={
                      this.props.toggleMenu ? (
                        <AiOutlineRight
                          style={{ fontSize: 20 }}
                          onClick={() => this.onCollapse(false)}
                        />
                      ) : (
                        <AiOutlineLeft
                          style={{ fontSize: 20 }}
                          onClick={() => this.onCollapse(true)}
                        />
                      )
                    }
                  />
                )}

                <div
                  className={
                    this.props.toggleMenu
                      ? 'usercontainerLeft'
                      : 'usercontainerRight'
                  }
                >
                  <div
                    className="container-fluid"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '10px',
                      marginTop: '15px',
                      marginLeft: '2px',
                      paddingLeft: '2px',
                    }}
                  >
                    <div
                      className="container-fluid"
                      style={{ marginLeft: '2px', paddingLeft: '2px' }}
                    >
                      {' '}
                      <BreadCrumb />
                    </div>
                    <div
                      className="container-fluid"
                      style={{ marginLeft: '2px', paddingLeft: '2px' }}
                    >
                      <Switch>
                        <LayoutContent />
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </Layout>
          ) : (
            <DefaultLoader />
          )}
          {Object.getOwnPropertyNames(this.props.userBaseProfile).length > 0 ? (
            <div
              className={
                this.props.toggleMenu
                  ? 'footer-container footerLeft'
                  : 'footer-container footerRight'
              }
            >
              <div className="container-fluid">
                <Footer />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Router>
    )
  }
}
const mapStateToProps = (state) => ({
  toggleMenu: state.search.toggleLeftMenu,
  loggedInUserDetails: state.authentication,
  userBaseProfile: state.users.userBaseProfile,
})

const mapDispatchToProps = (dispatch) => ({
  setToggleMenu: (requestParams) =>
    dispatch(searchAction.common(toggleLeftMenu, requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UserAccount))
