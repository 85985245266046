import React from 'react'
import { Table, Space, Pagination } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import * as competitionActions from '../../../store/actions/tutor/competitions'
import * as routeName from '../../../components/route-names'
import AssignTutorModalComponent from '../transfer-account'
import history from '../../../utils/history'
import generalAction from '../../../store/general/action'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import _ from 'lodash'
import sanitizeUrl from '../../../utils/common/sanitizeUrl'
class TutorCompetition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 5,
      classRow_id: '',
      tutorList: [],
      assignTutorModal: false,
      assignTutorId: '',
      group_id: '',
      formReset: '',
    }
  }

  state = {
    collapsed: false,
    sidebar: true,
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.tigerClassList()
    this.tigerTutorList()
  }

  async componentDidUpdate(prevPros, prevState) {
    if (this.props.selected_provider_id !== prevPros.selected_provider_id) {
      this.tigerClassList()
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  pageChange = async (page, pageSize) => {
    await this.setState({
      page,
    })
    this.tigerClassList()
  }

  tutorAssignModalFunction = (value, key) => {
    this.setState({
      assignTutorModal: value,
      group_id: key,
      formReset: _.uniqueId('_123_123_'),
    })
    this.tigerTutorList('expand')
  }

  headerTitle = (details) =>
    _.get(details, 'type') === 'Individual'
      ? `${_.startCase(_.get(details, 'firstname'))} ${_.get(details, 'lastname', '') !== '' ? `${_.startCase(_.get(details, 'lastname', ''))}` : ''}`
      : `${_.startCase(_.get(details, 'businessname'))}`

  tigerClassList = () => {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)

    const items = _.get(this.props, 'tutorCompetitions.items', [])
    const requestParams = {}
    requestParams.provider = this.props.selected_provider_id
    requestParams.limit = this.state.limit
    requestParams.page =
      !_.isEmpty(this.props.tutorCompetitions) && items.length === 1
        ? this.state.page > 1 && this.state.page - 1
        : this.state.page
    if (urlparams && urlparams.services) {
      requestParams.services = urlparams.services
    }
    requestParams.expand = 'provider'
    requestParams.sort = 'created_at.desc'
    this.props.getCompetitionsList(requestParams)
  }

  tigerTutorList = (e) => {
    const requestParams = {}
    requestParams.limit = 1000
    requestParams.works_for = _.get(this.props, 'tutorBaseProfile.details.id')
    requestParams.sort = 'created_at.desc'
    if (e && e === 'expand') {
      requestParams.expand = 'update_progress'
    }
    this.props.tutorList({
      params: requestParams,
      callback: (res, data) => {
        if (res) {
          if (_.size(_.get(data, 'items', [])) > 0) {
          } else {
          }
        }
      },
    })
  }

  viewDetails = (camp_name, groupid) => {
    const url = sanitizeUrl(camp_name)

    history.push(
      `${routeName.competitionsView}/${url}/?gid=${groupid}`
    )
  }

  render() {
    const { classRow_id, page, group_id, assignTutorModal, formReset } =
      this.state
    const ListingClasscolumns = [
      {
        title: this.props.t('nameLabel'),
        dataIndex: 'class',
        key: 'class',
      },
      {
        title: this.props.t('tutName'),
        dataIndex: 'tutor',
        key: 'tutor',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        render: (text, record) => (
          <Space size="middle">
            <div>
              <button
                onClick={() => {
                  this.viewDetails(
                    record.classDetails.name,
                    record.classDetails.id
                  )
                }}
                className="btn btn-accept  mr-1"
              >
                {this.props.t('details')}{' '}
              </button>
              {_.get(this, 'props.tutorBaseProfile.details.type') ===
                'Business' && (
                  <button
                    onClick={() =>
                      this.tutorAssignModalFunction(true, _.get(record, 'key'))
                    }
                    className={
                      group_id === _.get(record, 'key')
                        ? 'btn btn-accept-tutor-active  mr-1'
                        : 'btn btn-accept-tutor  mr-1'
                    }
                  >
                    {this.props.t('reasTutor')}
                  </button>
                )}
            </div>
          </Space>
        ),
      },
    ]
    let classData = []
    const items = _.get(this.props, 'tutorCompetitions.items', [])
    if (items.length > 0) {
      classData = items.map((value, index) => ({
        class: value.name ? this.capitalizeWord(value.name) : '',
        tutor: this.headerTitle(_.get(value, 'provider')),
        key: value.id,
        type: value.type,
        classDetails: value,
      }))
    }

    return (
      <>
        <div className="nurtem-table mb-3">
          <div className="schedule-content ">
            {this.props.loadingcompList ? (
              <ClassDetailLoader />
            ) : (
              <Table
                rowClassName={(record, index) =>
                  record.key === classRow_id ? 'active' : ''
                }
                columns={ListingClasscolumns}
                dataSource={classData}
                pagination={false}
              />
            )}
            <Pagination
              pageSize={this.state.limit}
              onChange={(page, pageSize) => this.pageChange(page, pageSize)}
              total={
                this.props.tutorCompetitions &&
                this.props.tutorCompetitions.meta &&
                this.props.tutorCompetitions.meta.total_count
              }
              hideOnSinglePage={!this.props.loading || this.props.loading}
              showSizeChanger={false}
              current={page}
              position={['bottomLeft']}
            />
          </div>
        </div>
        <AssignTutorModalComponent
          callback={(e) => this.tigerClassList()}
          tutorsList={_.get(this, 'props.tutorsList')}
          type="competitions"
          formReset={formReset}
          tutorAssignModalFunction={(e, key) =>
            this.tutorAssignModalFunction(e, key)
          }
          assignTutorModal={assignTutorModal}
          classTutorDetails={{
            class_id: this.state.group_id,
            provider: _.get(this, 'props.tutorBaseProfile.details.id'),
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  selected_provider_id: !_.isEmpty(state.common.selected_provider)
    ? state.common.selected_provider
    : state.tutorProfile.tutorBaseProfile &&
    state.tutorProfile.tutorBaseProfile.details &&
    state.tutorProfile.tutorBaseProfile.details.id,
  loadingcompList: state.competitionsDetails.loadingcompList,
  tutorCompetitions: state.competitionsDetails.providerCompetitions,
  tutorsList: _.get(state, 'general.tutor.list'),
})

const mapDispatchToProps = (dispatch) => ({
  getCompetitionsList: (requestParams) =>
    dispatch(competitionActions.getProviderCompetitions(requestParams)),
  tutorList: (data) => dispatch(generalAction.tutor.tutorList(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TutorCompetition))
