import _ from 'lodash';
import * as actionTypes from '../../../actions/public/tutor-public-profile/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
  requestCompleted: false,
  classesListLoading: false,
  errorOccurred: { status: false },
  tutorPublicProfileData: {},
  tutorClasses: {},
  tutorPublicDetails: {},
};

const onSuccess = (state, action) => updateObject(state, { tutorPublicProfileData: action.success });
const onTutorClasses = (state, action) => updateObject(state, { tutorClasses: action.success });
const tutorPublicDetails = (state, action) => updateObject(state, { tutorPublicDetails: action.success });
const requestCompleted = (state, action) => updateObject(state, action.requestCompleted);
const classesListLoading = (state, action) => ({ ...state, classesListLoading: action.success });
const errorOccurred = (state, action) => updateObject(state, action.errorOccurred);
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.onSuccess:
      return onSuccess(state, action);
    case actionTypes.onTutorClasses:
      return onTutorClasses(state, action);
    case actionTypes.requestCompleted:
      return requestCompleted(state, action);
    case actionTypes.classesListLoading:
      return classesListLoading(state, action);
    case actionTypes.errorOccurred:
      return errorOccurred(state, action);
    case actionTypes.tutorPublicDetails:
      return tutorPublicDetails(state, action);
    case actionTypes.tutorPublicDetailsLoadingInProgress:
      return updateObject(state, { tutorPublicDetailsLoadingInProgress: _.get(action, 'value', false) });
    default:
      return state;
  }
};

export default reducer;
