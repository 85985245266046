import React, { Component } from 'react';
import { Button, Checkbox, Input, Steps, message } from 'antd';
import './settings.css';
import { connect } from 'react-redux';
import { AiFillLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import axios from '../../../utils/axios';
import { withErrorHandler } from '../../../utils/handler';
import * as actions from '../../../store/actions/authentication/authentication';

const deletionDate = dayjs().add(30, 'days').format('MM/DD/YYYY');

class ResetPassword extends Component {
  state = {
    visible: false,
    passvisible: false,
    forgotvisible: false,
    selectedReason: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    errorMessage: '',
    current: 0,
    reasonFilled: false
  };

  next = () => {
    if (this.state.current === 0 && !this.state.reasonFilled) {
      // If current step is 0 (reason_form) and text area is empty, don't proceed
      return;
    }
    this.setState(prevState => ({
      current: prevState.current + 1
    }));
  };

  handleReasonChange = (event) => {
    const { value } = event.target;
    this.setState({
      selectedReason: value,
      reasonFilled: value.trim() !== '' // Check if text area is filled
    });
  };

  prev = () => {
    this.setState(prevState => ({
      current: prevState.current - 1
    }));
  };

  PasswordCheck = () => {
    if (this.state.passwordReceived) {
      this.props.onPasswordCheck({ password: this.state.passwordReceived });
      this.setState({ errorMessage: null });
    } else {
      this.setState({ errorMessage: this.props.t('settings1') });
    }
  };

  handleForgot = () => {
    this.sendMail(this.props.tutorBaseProfile.details.email);
    this.setState({ isLoading: true });
  };

  handleOk = () => {
    this.onDelete(this.props.tutorBaseProfile.details, this.state.selectedReason);
  };

  handlePassOk = () => {
    const { oldPassword, newPassword, confirmPassword } = this.state;
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    let errorMessage = '';
    if (confirmPassword.length === 0 || oldPassword.length === 0) {
      errorMessage = this.props.t('settings1');
    } else if (newPassword !== confirmPassword) {
      errorMessage = this.props.t('settings2');
    } else if (confirmPassword === oldPassword) {
      errorMessage = this.props.t('settings3');
    } else if (!regex.test(newPassword)) {
      errorMessage = this.props.t('settings4');
    } else if (!regex.test(confirmPassword)) {
      errorMessage = this.props.t('settings5');
    }

    if (errorMessage) {
      this.setState({ errorMessage });
      return;
    }
    this.onPassChange(this.props.tutorBaseProfile.details, oldPassword, confirmPassword);
  };

  handleCancel = () => {
    this.setState({ visible: false, passvisible: false, forgotvisible: false });
  };

  onDelete = (userInfo, reason) => {
    const request = {
      username: userInfo.email,
      id: userInfo.user,
      type: ['provider'],
      reason: reason || null
    };
    this.props.onDeleteAccount(request);
    this.setState({ isLoading: true });

    //after a dely logout
    setTimeout(() => {
      this.onLogout()
    }, 1000)
  };

  onPassChange = (userInfo, old, pass) => {
    const request = {
      username: userInfo.email,
      id: userInfo.user,
      oldPassword: old,
      password: pass,
    };
    this.props.onPasswordChange(request);
    this.setState({ isLoading: true });
  };

  onLogout = () => {
    this.props.onLogout();
  };

  onChange = (event) => {
    const { target } = event;
    if (target) {
      this.setState({
        selectedReason: target,
      });
    }
  };

  sendMail = (value) => {
    const request = {
      email: value,
    };
    this.props.onResetPassword(request);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.resetPasswordResponse !== this.props.resetPasswordResponse) {
      if (
        this.props.resetPasswordResponse
        && this.props.resetPasswordResponse.status === 200
      ) {
        this.setState({ isLoading: false, visible: false, passvisible: false, forgotvisible: false });
         this.onLogout()
      } else if (
        (this.props.resetPasswordResponse
          && this.props.resetPasswordResponse.status === 201) || this.props.resetPasswordResponse.status === 400
      ) {
        this.setState({ isLoading: false, visible: false, passvisible: false, forgotvisible: false });
      }
    }
  }

  render() {
    const { current } = this.state;

    const reason_form = {
      key: 'reason_form',
      title: 'Enter the Reason',
      content: (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <p className="setting-page-text">{this.props.t('settings9')}</p>
          <p className="setting-page-text">{this.props.t('settings10')}</p>
          <textarea
            style={{
              width: '100%',
              borderRadius: '10px',
              padding: '10px',
            }}
            value={this.state.selectedReason}
            onChange={this.handleReasonChange}
            className="ant-input-affix-wrapper mt-2 mb-2"
            rows={5}
            placeholder="Write your reason  here (max. 500 characters)"
          />
          {!this.state.reasonFilled && (
            <div style={{ color: 'red', marginTop: '5px' }}>Reason is required</div>
          )}
        </div>
      )
    };

    const password_form = {
      key: 'password_form',
      title: 'Confirm Deletion',
      content: (
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <div className='mb-3'>
            <span className="setting-page-text">
              <span style={{ color: 'red' }}>{this.props.t('warning')}:</span> {this.props.t('settings14')}
              {this.props.t('settings15')}
              {this.props.t('settings16')} ({deletionDate}). {this.props.t('settings17')}
            </span>
          </div>
          <Input.Password
            size="large"
            prefix={<AiFillLock style={{ fontSize: 20 }} />}
            type="password"
            placeholder="Confirm Password"
            onChange={(e) => this.setState({ passwordReceived: e.target.value })}
            iconRender={(visible) => (visible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />)}
          />
          {this.state.errorMessage && <div style={{ color: 'red' }}>{this.state.errorMessage}</div>}
          <div className="mt-2">
            {!this.props.passwordCheckResponse ? (
              <Button type="primary" onClick={this.PasswordCheck}>
                {this.props.t('settings18')}
              </Button>
            ) : (
              <Button type="primary" danger onClick={this.handleOk}>
                {this.props.t('settings19')}
              </Button>
            )}
          </div>
        </div>
      )
    };

    const itemsList = [reason_form, password_form];

    const contentStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#999',
      marginTop: 16,
    };

    return (
      <div className="container-fluid">
        <div className="container">
          <Steps current={current}>
            {itemsList.map(item => (
              <Steps.Step key={item.key} title={item.title} />
            ))}
          </Steps>
        </div>
        <div style={contentStyle}>{itemsList[current].content}</div>
        <div style={{ marginTop: 10, marginLeft: 5 }}>
          {current < itemsList.length - 1 && (
            <Button type="primary" style={{ margin: '0 8px' }} onClick={() => this.next()}>
              Next
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => this.prev()}>
              Prev
            </Button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  resetPasswordResponse: state.authentication.resetPasswordResponse,
  passwordCheckResponse: state.authentication.passwordCheckResponse,
});

const mapDispatchToProps = (dispatch) => ({
  onLogout: () => dispatch(actions.onLogout()),
  onResetPassword: (data) => dispatch(actions.resetPassword(data)),
  onPasswordCheck: (data) => dispatch(actions.passwordCheck(data)),
  onDeleteAccount: (data) => dispatch(actions.deleteAccount(data)),
  onPasswordChange: (data) => dispatch(actions.PasswordChange(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withErrorHandler(withTranslation()(ResetPassword), axios));
