// search parameters
export const headerSearch = 'headerSearch'
export const commonSort = 'commonSort'

export const searchType = 'searchType'
export const serviceID = 'serviceID'
export const searchZipCode = 'searchZipCode'
export const searchLocation = 'searchLocation'
export const showMoreSearchUI = 'showMoreSearchUI'

export const teachingMod = 'teachingMod'
export const typeOfClass = 'typeOfClass'
export const ageGroup = 'ageGroup'
export const milesFromHome = 'milesFromHome'
export const preferredDays = 'preferredDays'
export const preferredDate = 'preferredDate'
export const variant = 'variant'

export const preferredTime = 'preferredTime'
export const preferredPriceRange = 'preferredPriceRange'

// data
export const searchMounted = 'searchMounted'
export const tutorClassesCampsSearchData = 'tutorClassesCampsSearchData'
export const location_search_data = 'location_search_data'
export const location_search_data_fetching = 'location_search_data_fetching'

export const classList = 'classList'
export const campList = 'campList'
export const compeList = 'compeList'
export const eventList = 'eventList'

export const sponoredList = 'sponoredList'
export const generalList = 'generalList'
export const dirList = 'dirList'
export const premiumList = 'premiumList'

export const searchPriceRange = 'searchPriceRange'
export const searchPreferredDates = 'searchPreferredDates'
export const searchTeachingMode = 'searchTeachingMode'
export const searchDayOfWeek = 'searchDayOfWeek'

//common uses toggle menu automatically
export const toggleLeftMenu = 'toggleLeftMenu'
export const cartItems = 'cartItems'
