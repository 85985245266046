import React from 'react'
import { connect } from 'react-redux'
import {
  Modal,
  notification,
  Button,
  Spin,
  Pagination,
  Form,
  Input,
  FloatButton,
} from 'antd'
import _ from 'lodash'
import parser from 'html-react-parser'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import queryString from 'query-string'
import redirectTo from '../../../utils/redirectTo'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as action from '../../../store/actions/tutor/Classes'
import * as tutorAction from '../../../store/actions/public/tutor-public-profile'
import './style.css'
import generalAction from '../../../store/general/action'
import CampDetailsCard from './camp-details-card'
// import AddToCartContainer from './add-to-cart-container'
// import componentFunctionalities from './component-functionalities'
import SessionList from '../../../components/common/session-list-card'
// import SessionInfo from '../../../components/re-usable/session-info'
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime'
// import AboutTutorData from '../../../components/re-usable/about-tutor';
import ClassCampCard from '../../../components/re-usable/class-camp-card'
import Ratings from '../../../components/rating/rating-list'
import * as faqActions from '../../../store/actions/tutor/add-class'
import viewOtherClasses from '../../../utils/common/get-view-url'
import { withTranslation } from 'react-i18next'
import Linkify from 'linkify-react'

dayjs.extend(utc)
dayjs.extend(timezone)
const { TextArea } = Input

class ViewClassDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.myClassesRef = React.createRef()
    this.mySessionsRef = React.createRef()
    this.myClassespickerRef = React.createRef()
    this.scrollToSessions = this.scrollToSessions.bind(this);
  }
  scrollToSessions() {
    if (this.mySessionsRef.current) {
      this.mySessionsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }
  async componentDidMount() {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    if (gid) {
      this.getClassDetails(gid)
    }
    this.enableSidebarAndUserMenu()
    setTimeout(() => {
      const requestParams = {
        provider: _.get(this.state.campDetail.data.provider, 'id', null),
      }
      requestParams.limit = 8
      requestParams.count = 8
      requestParams.excludedId = gid
      this.props.onGetClassesList(requestParams)
    }, 2000)
  }

  state = {
    campDetail: { data: {}, fetching: false },
    selectPackagesData: { id: null, details: {} },
    selectedSession: {},
    askQuestion: false,
    detailsClicked: false,
  }

  toSessionOverview = (record) => {
    this.setState({ selectedSession: record })
    this.setState({ detailsClicked: true })
    this.scrollDetails()
  }

  modelcheck = () => {
    this.setState({ detailsClicked: false })
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  enableSidebarAndUserMenu = () => {
    this.props.turnOnTutorPublicDetailsLoader()
  }

  getClassDetails = async (campId) => {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    this.setState({ campDetail: { data: {}, fetching: true } })
    this.props.getClassDetails({
      params: { id: campId, expand: 'provider,user' },
      callback: (success, data) => {
        if (success) {
          const campDetails = _.get(data, 'details', {})
          this.setState({ campDetail: { data: campDetails, fetching: false } })
          this.props.onGetTutorPublicProfile(
            _.get(campDetails, 'provider.id', null)
          )
        }
      },
    })

    this.listFAQView(gid)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  scrollDetails = () => {
    this.myClassesRef.current.scrollIntoView({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  scrollDetailsSesssion = () => {
    this.mySessionsRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  }

  onChangePage = async (page, pageSize, type) => {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    this.setState({
      [type]: page,
    })
    const requestParams = {
      provider: _.get(this.state.campDetail.data.provider, 'id', null),
      page,
    }
    requestParams.limit = 8
    requestParams.count = 8
    requestParams.excludedId = gid
    this.props.onGetClassesList(requestParams)
  }

  commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
      top: 100,
    })
  }

  onfaqFinish = async (value, type) => {
    const urlparams = await queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    const data = { ...value }
    data.created_by = 'user'
    data.type = 'classes'
    data.timezone = dayjs.tz.guess()
    data.time = new Date()
    data.type_details = gid
    data.session_details = this.state.selectedSession.id
    this.faqActions(data)
  }

  faqActions = (values, type) => {
    this.setState({
      faq_loading: true,
    })
    this.props.createFaq(values, (response, type) => {
      this.setState({
        faq_loading: false,
        askQuestion: false,
      })

      if (type === 'success') {
        this.faqRef.current.resetFields()
        this.listFAQView(_.get(response, 'details.class_details'))

        this.commonSuccessErrorMessage('success', _.get(response, 'message'))
      } else if (_.get(response, 'response.data.errors')) {
        this.commonSuccessErrorMessage(
          'error',
          _.get(response, 'response.data.errors.rules[0].message')
        )
      } else if (_.get(response, 'response.data.message')) {
        this.commonSuccessErrorMessage(
          'error',
          _.get(response, 'response.data.message')
        )
      } else if (_.get(response, 'data.message')) {
        this.commonSuccessErrorMessage('error', _.get(response, 'data.message'))
      }
    })
  }

  listFAQView = (id) => {
    this.setState({
      faq_loading: true,
    })
    this.props.listFAQ(
      { type_details: id, sort: 'created_at.desc', limit: 1000 },
      (response, type) => {
        this.setState({
          faq_loading: false,
        })
        if (type === 'success') {
          this.setState({
            faqList: _.get(response, 'items', []),
          })
        } else if (_.get(response, 'response.data.errors')) {
          this.commonSuccessErrorMessage(
            'error',
            _.get(response, 'response.data.errors.rules[0].message')
          )
        } else if (_.get(response, 'response.data.message')) {
          this.commonSuccessErrorMessage(
            'error',
            _.get(response, 'response.data.message')
          )
        }
      }
    )
  }

  getFaq = (id) => {
    if (_.size(_.get(this, 'state.faqList')) > 0) {
      if (id) {
        const faqList = _.get(this, 'state.faqList')
        const filtered = faqList.filter((value) => value.session_details === id)
        return filtered.map((value, index) => (
          <div key={index} className="faq-set">
            <div className="faq-set-question">{_.get(value, 'question')}</div>
            <div className="faq-set-answer" style={{ whiteSpace: 'pre-wrap' }}>
              {_.get(value, 'answer')}
            </div>
          </div>
        ))
      }
      const faqList = _.get(this, 'state.faqList')

      return faqList.map((value, index) => (
        <div key={index} className="faq-set">
          <div className="faq-set-question">{_.get(value, 'question')}</div>
          <div className="faq-set-answer" style={{ whiteSpace: 'pre-wrap' }}>
            {_.get(value, 'answer')}
          </div>
        </div>
      ))
    }
    if (_.get(this, 'state.faq_loading') === true) {
      return (
        <Spin
          style={{
            marginTop: '122px',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            position: 'absolute',
          }}
          size="large"
          tip="Loding..."
        />
      )
    }
    return (
      <div>
        <EmptyAnime AnimeType="EmptyUserSide" />
        <p className="empty-animations">{this.props.t('noFAQF')}</p>
      </div>
    )
  }

  render() {
    const { askQuestion, detailsClicked } = this.state
    const providerDetail = _.get(this, 'state.campDetail.data.provider', {})
    const fn = providerDetail?.firstname
    const ln = providerDetail?.lastname
    const bn = providerDetail?.businessname
    const ty = providerDetail?.type
    const urlparams = queryString.parse(window.location.search)
    const gid = _.get(urlparams, 'gid', null)
    const bid = _.get(urlparams, 'bid', null)
    const providerName =
      ty === 'Individual' ? `${fn} ${ln}` : !_.isEmpty(bn) ? bn : `${fn} ${ln}`
    const provider = {
      providerName,
      providerType: ty,
    }
    const campDescriptionBlock = (data) => {
      console.log("datataa", data)
      const campDescription = data

      return (
        <div
          className="description mt-1 "
          style={{
            padding: '10px 10px 10px 10px',
            backgroundColor: 'transparent',
            border: 'none',
            fontSize: 17,
            lineHeight: '27px',
            backdropFilter: 'none',
          }}
        >
          {!_.isEmpty(campDescription) ? (
            <span className="parafont">
              <Linkify as="p">{parser(campDescription)}</Linkify>
            </span>
          ) : (
            <div>
              <EmptyAnime AnimeType="EmptyUserSide" />
              <p className="empty-animations">Description</p>
            </div>
          )}
        </div>
      )
    }
    const classDetail = _.get(this, 'state.campDetail', {})
    const description = _.get(classDetail, 'data.description', '')
    console.log("classs>>>>>>>>>>", description);
    const session_name = _.get(this.state.selectedSession, 'session_name', '')

    const isNotEmptySelectedSession = !_.isEmpty(this.state.selectedSession)
    return (
      <>
        <div className="nurtem-card-design viewtutorProfile mt-3 mb-4">
          <div className="nurtem-card-design-header">
            <div className="nurtem-card-design-bg" />
            <CampDetailsCard
              onScrollView={this.scrollDetails}
              {...this.props}
              componentInput={{
                ..._.get(this, 'state.campDetail', {}),
              }}
            />
          </div>

          <div
            id="sessionblock"
            ref={this.myClassesRef}
            className="full-width mt-4 mb-3"
          >
            <div className="sub-title   ml-2" ref={this.mySessionsRef}>
              {this.props.t('batchAvailable')}
            </div>
            <SessionList
              groupID={gid}
              type="classes"
              callback={this.toSessionOverview}
              status={0}
              live
              bid={bid}
            />
          </div>
          <div className="sub-title  ml-2">
            Description{' '}
          </div>
          <div
            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
            style={{ paddingLeft: 0, float: 'left' }}
          >
            {campDescriptionBlock(description)}
          </div>
          {/* <Modal
            className="sessionPopupCard"
            // style={{width:'70%'}}
            title={_.startCase(session_name)}
            open={isNotEmptySelectedSession && detailsClicked}
            onCancel={this.modelcheck}
            footer={null}
          >
            <div ref={this.mySessionsRef} className="full-width">
              <div className="row">
                <div className=" position-static">
                  <div className="" style={{ height: '94%' }}>
                    <div className="row">
                      {isNotEmptySelectedSession && (
                        <>
                          <div className="overview-description-header col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <SessionInfo
                              record={this.state.selectedSession}
                              type="classes"
                            />
                          </div>
                          <div
                            className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"
                            style={{ position: 'relative' }}
                          >
                            {!componentFunctionalities.isCampFull(
                              _.get(this, 'state.campDetail', {})
                            ) ? (
                              <AddToCartContainer
                                onScrollView={this.scrollDetails}
                                selectedSession={this.state.selectedSession}
                                popupMenuOpen={this.modelcheck}
                                campDetail={_.get(this, 'state.campDetail', {})}
                                callback={this.modelcheck}
                                type="classes"
                              />
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}

          {/* {isNotEmptySelectedSession &&
            !_.isEmpty(this.state.selectedSession.description) && (
              <div className="sub-title mt-4  ml-2">Description</div>
            )}
          {isNotEmptySelectedSession &&
            !_.isEmpty(this.state.selectedSession.description) && (
              <div className="row">
                <div className="col-12">
                  <div className="container mt-3 mb-2">
                    {parser(this.state.selectedSession.description)}
                  </div>
                </div>
              </div>
            )} */}
          {isNotEmptySelectedSession &&
            !_.isEmpty(this.state.selectedSession.rating_count) && (
              <div className="sub-title mt-4  ml-2">Rating and Reviews</div>
            )}
          {isNotEmptySelectedSession && (
            <div className="full-width mb-4 ">
              <div className=" mb-4 mt-4 ml-2">
                <div className="sub-title mt-4  ml-2">
                  {this.props.t('faqs')}{' '}
                </div>
                {_.get(this.props, 'loggedInUserDetails.isLoggedIn') ===
                  true ? (
                  <span
                    onClick={() => this.setState({ askQuestion: true })}
                    className="faq-add-link"
                  >
                    {this.props.t('askQuestion')}
                  </span>
                ) : (
                  <span onClick={() => redirectTo()} className="faq-add-link">
                    {this.props.t('login')} {this.props.t('askQuestion')}{' '}
                  </span>
                )}
              </div>
              <div className="faq-set-container ml-3">
                {this.getFaq(this.state.selectedSession.id)}
              </div>
            </div>
          )}
          {/* <div className="tutor-public-profile-container about-title mt-5 mb-2">
                        <div className="sub-title  ml-2">{this.props.t("aboutTutor")} </div>
                    </div> */}
          {/* <div className='AboutTutorDetails'><AboutTutorData type={'classes'} provider={_.get(this, 'state.campDetail.data.provider', {})} /></div> */}

          <div
            ref={this.myCampspickerRef}
            className="tutor-public-profile-container about-title mt-5 mb-2"
          >
            <div className="sub-title  ml-2">
              {this.props.t('availableClasses')}{' '}
            </div>
          </div>

          <div
            className="more-classes other-classes otherbox"
            style={{ backgroundColor: '#fff' }}
          >
            <div className="row">
              {this.props.classesList &&
                this.props.classesList.meta &&
                this.props.classesList.meta.count > 0 ? (
                this.props.classesList.items.map((value, key) => {
                  if (value.id !== gid) {
                    return (
                      <div
                        key={`class_list${key}`}
                        className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mt-3 mb-4"
                      >
                        <div className="nurtem-profile-class-card">
                          <Link
                            onClick={() => this.getClassDetails(value.id)}
                            to={viewOtherClasses(
                              _.get(this.state, 'campDetail.data.provider'),
                              value,
                              'class'
                            )}
                          >
                            <>
                              {' '}
                              <ClassCampCard
                                data={value}
                                providerdata={provider}
                              />
                            </>
                          </Link>
                        </div>
                      </div>
                    )
                  }
                  return null
                })
              ) : (
                <div>
                  <EmptyAnime AnimeType="EmptyUserSide" />
                  <p className="empty-animations">
                    {this.props.t('noClassessF')}{' '}
                  </p>
                </div>
              )}

              <div className="full-width mb-4">
                {this.props.classesList &&
                  this.props.classesList.items &&
                  this.props.classesList.items.length > 0 &&
                  this.props.classesList &&
                  this.props.classesList.meta &&
                  this.props.classesList.meta.total_count > 8 ? (
                  <Pagination
                    style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                    defaultCurrent={1}
                    current={this.state.classesListPage}
                    showSizeChanger={false}
                    onChange={(page, pageSize) => {
                      this.onChangePage(page, pageSize)
                    }}
                    defaultPageSize={_.get(
                      this.props.classesList,
                      'meta.limit',
                      0
                    )}
                    total={_.get(this.props.classesList, 'meta.total_count', 0)}
                    className="ant-table-pagination"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="faq-modal"
          open={askQuestion && isNotEmptySelectedSession}
          centered
          title={this.props.t('askQuestion')}
          footer={null}
          onCancel={() => this.setState({ askQuestion: false })}
        >
          <Form
            scrollToFirstError
            onFinish={(e) => this.onfaqFinish(e)}
            ref={this.faqRef}
          >
            <div className="row">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: this.props.t('Queisreq'),
                  },
                ]}
                name="question"
                className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
              >
                <div className="full-width">
                  <div className="full-width position-relative">
                    <TextArea rows={3} className="border-radius-10" />
                  </div>
                </div>
              </Form.Item>
              <Form.Item className="col-12">
                <div className="full-width text-right">
                  <Button
                    loading={this.state.faq_loading}
                    htmlType="submit"
                    className="btn-submit mr-3"
                  >
                    {this.props.t('submit')}
                  </Button>
                  <Button
                    disabled={this.state.faq_loading}
                    onClick={() => this.setState({ askQuestion: false })}
                    className="btn-cancel"
                  >
                    {this.props.t('cancel')}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
        <FloatButton.BackTop className="back-to-top" />
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  sessions: state.sessions.sessions,
  campDetailsView: _.get(state, 'general.camps.view.details', {}),
  userBaseProfile: state.userProfile.userBaseProfile,
  classesList: state.classesDetails.classesList,
  loggedInUserDetails: state.authentication,
})

const mapDispatchToProps = (dispatch) => ({
  turnOnTutorPublicDetailsLoader: () =>
    dispatch(tutorAction.turnOnTutorPublicDetailsLoader()),

  onGetTutorPublicProfile: (providerId) =>
    dispatch(tutorAction.getTutorPublicProfile(providerId)),
  getClassDetails: (details) =>
    dispatch(generalAction.classes.viewClass(details)),
  onGetClassesList: (providerId) => dispatch(action.getClassesList(providerId)),
  listFAQ: (data, callback) => dispatch(faqActions.listFAQ(data, callback)),
  createFaq: (data, callback) => dispatch(faqActions.createFAQ(data, callback)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewClassDetails))
