/**
 * @author ramsteyn17@gmail.com
 * @dated 11/04/2023
 * This is to display the timing from unix epoch time to users local timezones.
 * Display date on utc format
 */

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
const timeDateConverterSession = (timeDate, type, format, day) => {
  if (day === 'onlyDay') {
    const localizedTime = dayjs.utc(timeDate).tz(dayjs.tz.guess()).locale('en').startOf('day');
    return localizedTime;
  }
  if (type === 'date') {
    format = format || 'MMM DD,YYYY';
    const localizedTime = dayjs.utc(timeDate).tz(dayjs.tz.guess()).locale('en').format(format);
    return localizedTime;
  } if (type === 'time') {
    if (typeof timeDate === 'number' && timeDate >= 1000000000) {
      const localDate = new Date(timeDate * 1000);
      const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      };
      return localDate.toLocaleString([], options);
    }
    const utcTime = dayjs.utc(timeDate).tz(dayjs.tz.guess()).format(format);
    return utcTime;
  }
};

export default timeDateConverterSession;
