import React from 'react'
import _ from 'lodash'
import { mainComponentLoader } from './component-functionalities'
import { CLASSPNG } from '../../../../utils/config/assets'
import '../style.css'
import Flyers from '../../../../components/re-usable/gallery-view/flyers-preview'
import EmptyAnime from '../../../../components/re-usable/lottie/EmptyAnime'
import { withTranslation } from 'react-i18next'
import parser from 'html-react-parser'
import AboutTutorData from '../../../../components/re-usable/about-tutor'
import Linkify from 'linkify-react'
import { Tag } from 'antd'
import { MdOutlineBookmarkAdd } from "react-icons/md";

function CampDetailsCard(props) {
  const ch = _.get(props, 'loggedInUserDetails', {})
  const isLoggedIn = _.get(ch, 'isLoggedIn', {})
  const sessionsDetail = _.get(props, 'sessions', {})

  let length;
  let totalStudentsAcceptedGlobal = 0;
  Object.keys(sessionsDetail).forEach((key) => {
    const sessionDetailsArray = sessionsDetail[key];
    if (Array.isArray(sessionDetailsArray)) {
      const arrayLength = sessionDetailsArray.length;
      const totalStudentsAccepted = sessionDetailsArray
        .map((entry) => entry.additional_details.no_of_students_accepted || 0)
        .reduce((total, count) => total + count, 0);
      totalStudentsAcceptedGlobal += totalStudentsAccepted;
      length = arrayLength;
    }
  });
  console.log("count>>>>>>>", length)
  const campDescriptionBlock = (data) => {
    const campDescription = _.get(data, 'description', '')

    return (
      <div
        className="description mt-1 "
        style={{
          padding: '10px 10px 10px 10px',
          backgroundColor: 'transparent',
          border: 'none',
          fontSize: 17,
          lineHeight: '27px',
          backdropFilter: 'none',
        }}
      >
        {!_.isEmpty(campDescription) ? (
          <span className="parafont">
            <Linkify as="p">{parser(campDescription)}</Linkify>
          </span>
        ) : (
          <div>
            <EmptyAnime AnimeType="EmptyUserSide" />
            <p className="empty-animations">{props.t('noDescF')}</p>
          </div>
        )}
      </div>
    )
  }

  const capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  const mainComponent = (campDetails) => {
    const cd = _.chain(campDetails)
    const flyers = cd.get('flyers').value()
    const tutorDetails = cd.get('provider').value()
    return (
      <div className="row">
        {/* Declaring camp image block */}
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="head-classdetails-page-title  ml-3">
              {campDetails &&
                campDetails.name &&
                capitalizeWord(campDetails.name)}
            </div>
            <div className='ml-3'>
              {length >= 1 ? <span style={{ color: "#108ee9", fontSize: 22, padding: 5, fontWeight: '900' }}>Batches Available: {length}</span> : <span style={{ color: 'green', fontSize: 'large' }}>! NO Batches Available !</span>}
            </div>
          </div>
          <Tag className="ml-3" color='#41348c' style={{ fontSize: 'large', padding: '3px 13px', fontWeight: '900', marginTop: 5 }}> {props.t('class')}</Tag>
        </div>
        <div
          className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 PopupMenuHeader"
          style={{
            textAlign: 'end' /* display: 'flex', alignItems: 'center' */,
          }}
        >
          <div style={{ marginTop: 10 }} />
        </div>
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
          <div className="class-header-listing fulldetailbox">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="pro-header" style={{ width: '100%' }}>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 PopupMenuImage"
                      style={{ float: 'left', padding: '10px 10px' }}
                    >
                      <div
                        className="class-img-detail"
                        style={{
                          boxShadow: '0 4px 8px rgb(65 120 255 / 33%)',
                          height: 350,
                        }}
                      >
                        <img
                          style={{ height: '100%' }}
                          src={campDetails.photo ? campDetails.photo : CLASSPNG}
                          className="profile-camp-image mr-2"
                          alt="img"
                        />
                      </div>
                      <div
                        className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4 pr-md-0"
                        style={{ paddingLeft: 0 }}
                      >
                        <div className="results-cards__image-container flyer">
                          {flyers && flyers.length > 0 && (
                            <Flyers ht="200px" wt="25%" images={flyers} />
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-2 "
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingLeft: 10,
                        float: 'right',
                      }}
                    >
                      <AboutTutorData
                        type="classes"
                        provider={tutorDetails}
                        sessions={sessionsDetail}
                        isLoggedIn={isLoggedIn}
                      />
                    </div>
                    {/* <div
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 "
                      style={{ paddingLeft: 0, float: 'left' }}
                    >
                      {campDescriptionBlock(campDetails)}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {!_.isEmpty(_.get(props, 'componentInput.data', {}))
        ? mainComponent(_.get(props, 'componentInput.data', {}))
        : mainComponentLoader()}
    </>
  )
}

export default withTranslation()(CampDetailsCard)
