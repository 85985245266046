import React, { useEffect, useState } from 'react';
import { Modal, Input, List, Avatar, Skeleton, Button } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as msgAction from "../../../store/actions/public/message";
import * as commonAction from "../../../store/actions/tutor/common"
import { DEFAULTUSER } from '../../../utils/config/assets';
import { Scrollbars } from 'react-custom-scrollbars';

const GroupInfoForm = (props) => {
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [studentList, setstudentList] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const handleSearchChange = (e) => {
        setSearchValue(e.target.value);
    };

    const filteredStudentList = studentList.filter((item) => {
        return item.username.toLowerCase().includes(searchValue.toLowerCase());
    });
    const handleCancel = () => {
        props.showMessageForm({ loading: false })

    }
    const ChatId = _.get(props, 'visibleInfo.id', '')
    useEffect(() => {
        if (ChatId) {
            let requestParams = {};
            requestParams.limit = 1000;
            requestParams.expand = false
            props.getFullStudentList(requestParams);
        }
    }, [ChatId])
    const onLoadMore = () => {
        setLoading(true);

        setLoading(false);

    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>loading more</Button>
            </div>
        ) : null;

    useEffect(() => {
        let students = _.get(props, 'TotalStudentList.items', [])
        if (students.length > 0) {
            setstudentList(students)
        }

    }, [_.get(props, 'TotalStudentList.items', '')])

    const AddUser = (id) => {
        let value = {};
        value.chatid = ChatId;
        value.id = id
        value.type = 'add'
        props.onAddingUser(value);
    }
    const DeleteUser = (id) => {
        let value = {};
        value.chatid = ChatId;
        value.id = id
        value.type = 'delete'
        props.onAddingUser(value);
    }

    const render = () => {
        return (

            <Modal
                className='student-view-notes-modal'
                title="Participants"
                maskClosable={false}
                centered
                closable={true}
                open={props.visibleInfo.loading}
                onCancel={() => handleCancel()}
                footer={null}
            >
                <div className="search-recent">
                    {studentList.length > 0 &&
                        <>
                            <Input
                                placeholder="Search by username"
                                maxLength={20}
                                value={searchValue}
                                onChange={handleSearchChange}
                            />
                            <Scrollbars className="hidden-scroll-x" style={{ height: '350px' }}
                                renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
                                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
                            >
                                <List
                                    className="demo-loadmore-list"
                                    loading={props.TotalStudentListLoading}
                                    itemLayout="horizontal"
                                    loadMore={loadMore}
                                    dataSource={filteredStudentList}
                                    renderItem={(item) => (
                                        <>
                                            <List.Item
                                                actions={[<a key={item.key} onClick={() => AddUser(item.for)}>Add</a>, <a key={item.key} onClick={() => DeleteUser(item.for)}>Remove</a>]}
                                            >
                                                <Skeleton avatar title={false} loading={item.loading} active>
                                                    <List.Item.Meta
                                                        avatar={<Avatar src={item.userphoto ? item.userphoto : DEFAULTUSER} />}
                                                        title={item.username}
                                                        description={`Booked for ${item.type} named ${item.typename}`}
                                                    />
                                                </Skeleton>
                                            </List.Item>
                                        </>

                                    )}
                                />
                            </Scrollbars>
                        </>
                    }
                </div>
            </Modal>
        )
    };
    return render();
}

const mapStateToProps = state => {

    return {
        tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
        searchLocation: state.search.searchLocation,
        createMessage: state.message.createMessage,
        addinguser: state.message.addinguser,
        adduserLoading: state.message.adduserLoading,
        visibleInfo: state.common.show_group_info,
        TotalStudentList: state.common.TotalStudentList,
        TotalStudentListLoading: state.common.TotalStudentListLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {

        showMessageForm: (data) => dispatch(commonAction.show_group_info(data)),
        getFullStudentList: (data) => dispatch(commonAction.onTotalStudentList(data)),
        onAddingUser: (data) => dispatch(msgAction.AddingUser(data)),
        onCreateMessages: (requestParams) => dispatch(msgAction.onCreateUserMessages(requestParams)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfoForm);