import React from 'react'
import './style.css'
import PaymentReportFilter from './filter'

const paymentReport = () => {
  const render = () => (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div className="nurtem-card-design tutor-tab py-2 mb-3">
          <div
            className="schedule-content"
            style={{ position: 'relative', zIndex: '1' }}
          >
            <div className="full-width">
              <PaymentReportFilter />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return render()
}

export default paymentReport
