import * as actionTypes from '../../actions/public/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: {
    contentLoading: false,
    searchLoading: false,
  },
  serviceData: null,
  searchLoading: false,
  serviceSearchData: null,
  serviceQuoteData: null,
  tutorRateData: null,
  sidebarLogo: false,
  onTutorPublicsiderbar: false,
  onTutorDashboardsiderbar: false,
  onGuestsiderbar: false,
  ontutorSearchBox: false,
  onHeaderSearch: false,
  searchClassCampsRouteRedirect: {},
  viewAllClassTabs: '2',
};

const serviceQuotesFoundSuccess = (state, action) => ({ ...state, serviceQuoteData: action.success });
const tutorRateListFoundSuccess = (state, action) => ({ ...state, tutorRateData: action.success });
const sidebarLogo = (state, action) => ({ ...state, sidebarLogo: action.success });
const viewAllClassTabs = (state, action) => ({ ...state, viewAllClassTabs: action.success });
const onTutorPublicsiderbar = (state, action) => ({ ...state, onTutorPublicsiderbar: action.success });
const onTutorDashboardsiderbar = (state, action) => ({ ...state, onTutorDashboardsiderbar: action.success });
const onGuestsiderbar = (state, action) => ({ ...state, onGuestsiderbar: action.success });
const onHeaderSearch = (state, action) => ({ ...state, onHeaderSearch: action.success });
const searchClassCampsRouteRedirect = (state, action) => ({ ...state, searchClassCampsRouteRedirect: action.success });
const ontutorSearchBox = (state, action) => ({ ...state, ontutorSearchBox: action.success });
const serviceFoundSuccess = (state, action) => ({ ...state, serviceData: action.success });
const serviceSearchFoundSuccess = (state, action) => ({ ...state, serviceSearchData: action.success });
const enableContentLoading = (state, action) => updateObject(state, action.loading);
const disableContentLoading = (state, action) => updateObject(state, action.loading);
const enableSearchLoading = (state, action) => updateObject(state, action.loading);
const disableSearchLoading = (state, action) => updateObject(state, action.loading);
const enableLoadMoreLoading = (state, action) => updateObject(state, action.loading);
const disableLoadMoreLoading = (state, action) => updateObject(state, action.loading);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.sidebarLogo:
      return sidebarLogo(state, action);
    case actionTypes.viewAllClassTabs:
      return viewAllClassTabs(state, action);
    case actionTypes.onTutorPublicsiderbar:
      return onTutorPublicsiderbar(state, action);
    case actionTypes.onTutorDashboardsiderbar:
      return onTutorDashboardsiderbar(state, action);
    case actionTypes.onGuestsiderbar:
      return onGuestsiderbar(state, action);
    case actionTypes.onHeaderSearch:
      return onHeaderSearch(state, action);
    case actionTypes.searchClassCampsRouteRedirect:
      return searchClassCampsRouteRedirect(state, action);
    case actionTypes.ontutorSearchBox:
      return ontutorSearchBox(state, action);
    case actionTypes.serviceQuotesFoundSuccess:
      return serviceQuotesFoundSuccess(state, action);
    case actionTypes.tutorRateListFoundSuccess:
      return tutorRateListFoundSuccess(state, action);
    case actionTypes.serviceFoundSuccess:
      return serviceFoundSuccess(state, action);
    case actionTypes.serviceSeachFoundSuccess:
      return serviceSearchFoundSuccess(state, action);
    case actionTypes.enableContentLoading:
      return enableContentLoading(state, action);
    case actionTypes.disableContentLoading:
      return disableContentLoading(state, action);
    case actionTypes.disableSearchLoading:
      return disableSearchLoading(state, action);
    case actionTypes.enableSearchLoading:
      return enableSearchLoading(state, action);
    case actionTypes.enableLoadMoreLoading:
      return enableLoadMoreLoading(state, action);
    case actionTypes.disableLoadMoreLoading:
      return disableLoadMoreLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
