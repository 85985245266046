import React from 'react';
import {
  Button, Tabs, Modal, notification, Table, Space,
} from 'antd';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import './tutor-dashboard.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import * as actions from '../../../store/actions/tutor/camps';
import * as classActions from '../../../store/actions/tutor/Classes';
import * as routeName from '../../../components/route-names';
import EmptyAnime from '../../../components/re-usable/lottie/EmptyAnime';
import history from '../../../utils/history';
import * as servicesActions from '../../../store/actions/public/Services';
import _ from 'lodash';
import { loader } from './loader';

class TutorDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      classTabs: '1',
      today_schedules: [],
      commonPopupModal: false,
      loadMoreRequestType: '',
      count: 3,
      message: '',
      acceptUserType: '',
      requestId: '',
    };
  }

  componentDidMount() {
    if (!_.get(this.props, 'tutorBaseProfile.details.services') || !_.get(this.props, 'tutorBaseProfile.details.user_handle') || (!_.get(this.props, 'tutorBaseProfile.details.firstname') && !_.get(this.props, 'tutorBaseProfile.details.businessname'))) {
      history.push(routeName.TutorBaseProfileStep1);
      notification.warning({
        message: 'Warning',
        description: 'Please update your profile.',
      });
    }
    // this.props.onTutorSiderbar(false);
    this.props.getClassesCount();
    this.props.getCampsCount();
    this.props.getClassesRequests();
    this.props.getCampsRequests();
    const requestParams = {};
    requestParams.sender_type = 'provider';
    if (this.props.tutorBaseProfile && this.props.tutorBaseProfile.details && this.props.tutorBaseProfile.details.id) {
      const requestParams = {};
      requestParams.provider = this.props.tutorBaseProfile.details.id;
      requestParams.expand = 'provider';
      requestParams.day_of_week = dayjs().format('dddd');
      requestParams.sort = 'created_at.desc';
      this.props.getDashBoardClassesList(requestParams);
      this.props.getCampsList(requestParams);
    }
  }

  getDaysBetweenDates(start, end, dayName) {
    const result = [];
    const days = {
      sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6,
    };
    if (_.isArray(dayName) === true && _.size(dayName) > 0) {
      dayName.map((value, index) => {
        const day = days[value.toLowerCase().substr(0, 3)];
        // Copy start date
        const current = new Date(start);
        // Shift to next of required days
        current.setDate(current.getDate() + (day - current.getDay() + 7) % 7);
        // While less than end date, add dates to result array
        while (current < end) {
          result.push(new Date(+current));
          current.setDate(current.getDate() + 7);
        }
        return null;
      });
    }
    return result;
  }

  nearestDay = (value) => {
    const validatePaymentType = !!(_.get(value, 'billing_type') === 2
            || _.get(value, 'payment_cycle_type') === 1);
    const endOfDate = validatePaymentType
      ? dayjs(dayjs(value.occurrence_start).format('MM-YYYY')) < dayjs(dayjs().format('MM-YYYY')) ? dayjs().endOf('month').format('MM-DD-YYYY') : dayjs(value.occurrence_validity).endOf('month').format('MM-DD-YYYY')
      : value.occurrence_validity;
    const start_date = dayjs(value.occurrence_start) < dayjs(new Date()) ? dayjs(new Date()) : dayjs(value.occurrence_start);
    const end_date = dayjs(endOfDate);
    const weekDaysCount = this.getDaysBetweenDates(
      new Date(start_date),
      new Date(end_date),
      value.day_of_week,
    );
    return dayjs(weekDaysCount[0]).format('MM DD YYYY') === dayjs().format('MM DD YYYY') ? 'Yes' : 'No';
  };

  async componentDidUpdate(prevprops) {
    if (this.props.location !== prevprops.location) {
      this.props.emptyClassesCount();
      this.props.emptyCampsCount();
      this.props.emptyClassesRequests();
      this.props.emptyCampsRequests();
      this.componentDidMount();
    }

    if (prevprops.tutorClassesCount !== this.props.tutorClassesCount && this.props.tutorClassesCount && this.props.tutorClassesCount.items && this.props.tutorClassesCount.items.length > 0) {
      const today_classes = [];
      this.props.tutorClassesCount.items.map((value, index) => {
        const cuuDate = dayjs().format('MM-DD-YYYY');
        const classDate = value.class_dates;
        if (_.isArray(classDate) === true) {
          if (classDate.includes(cuuDate)) {
            value.urlType = 'classes';
            today_classes.push(value);
          }
        }
        return null;
      });

      this.setState({
        today_schedules: [...today_classes, ...this.state.today_schedules],
      });
    }

    if (prevprops.tutorCampsCount !== this.props.tutorCampsCount && this.props.tutorCampsCount && this.props.tutorCampsCount.items && this.props.tutorCampsCount.items.length > 0) {
      const today_camps = [];
      this.props.tutorCampsCount.items.map((value, index) => {
        if (dayjs(_.get(value, 'end_date[0]')) >= dayjs(new Date()) && dayjs(new Date()) >= dayjs(_.get(value, 'start_date[0]'))) {
          value.urlType = 'camps';
          today_camps.push(value);
        }
        return null;
      });

      this.setState({
        today_schedules: [...this.state.today_schedules, ...today_camps],
      });
    }
    if (
      prevprops.tutorCampsRequestAction !== this.props.tutorCampsRequestAction
    ) {
      if (this.props.tutorCampsRequestAction && this.props.tutorCampsRequestAction.status === 200 && this.props.tutorCampsRequestAction.data && this.props.tutorCampsRequestAction.data.message) {
        this.props.getCampsRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      } else if (this.props.tutorCampsRequestAction && this.props.tutorCampsRequestAction.data && this.props.tutorCampsRequestAction.data.message) {
        this.props.getCampsRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      } else {
        this.props.getCampsRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      }
    }

    if (prevprops.tutorClassRequestAction !== this.props.tutorClassRequestAction) {
      if (this.props.tutorClassRequestAction && this.props.tutorClassRequestAction.status === 200 && this.props.tutorClassRequestAction.data && this.props.tutorClassRequestAction.data.message) {
        this.props.getClassesRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      } else if (this.props.tutorClassRequestAction && this.props.tutorClassRequestAction.data && this.props.tutorClassRequestAction.data.message) {
        this.props.getClassesRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      } else {
        this.props.getClassesRequests();
        this.setState({
          acceptUserType: '',
          requestId: '',
        });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.inter);
  }

  onAcceptUser = (value, type) => {
    this.setState({
      acceptUserType: type,
      requestId: value.key,
    });
    const thisdata = this;

    if (value && value.type === 'classes') {
      const content = {
        id: value.key,
        user_id: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }
      Modal.confirm({
        title: this.props.t('areSure'),
        content: type === 'userAccept' ? this.props.t(
          'acceptNotice',
        ) : this.props.t(
          'acceptNotice2',
        ),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.ClassesRequestAction(content, (response) => {
            if (_.get(response, 'data.message')) {
              notification.success({
                message: 'Success',
                description: _.get(response, 'data.message'),
              });
            }
            if (_.get(response, 'status') === 200 && _.size(_.get(value, 'booking_dates', [])) > 0) {
              const seats_full_days = _.size(_.get(value, 'class_details.seats_full_days', [])) > 0 ? [...value.class_details.seats_full_days, value.booking_dates[0]] : [value.booking_dates[0]];
              thisdata.props.ClassesSlotFullAction({
                id: _.get(value, 'class_details.id'),
                seats_full_days: _.uniq(seats_full_days),
              });
            }
          });
        },
        cancelButtonProps: {
          disabled: false,
        },
        confirmLoading: {
          disabled: false,
        },
        cancelText: this.props.t('cancel'),
      });
    } else if (value && value.type === 'camps') {
      const content = {
        id: value.key,
        user_id: value.user_id,
        status: type === 'userAccept' ? 1 : 2,
      };
      if (value.dependent_user) {
        content.dependent_user = value.dependent_user;
      }
      Modal.confirm({
        title: this.props.t('areSure'),
        content: type === 'userAccept' ? this.props.t(
          'acceptNotice',
        ) : this.props.t(
          'acceptNotice2',
        ),
        okText: this.props.t('confirm'),
        onOk() {
          thisdata.props.CampsRequestAction(content, (response) => {
            if (_.get(response, 'status') === 200) {
              if (_.get(response, 'data.message')) {
                notification.success({
                  message: 'Success',
                  description: _.get(response, 'data.message'),
                });
              }
            }
          });
        },
        confirmLoading: {
          disabled: false,
        },
        cancelButtonProps: {
          disabled: false,
        },
        cancelText: this.props.t('cancel'),
      });
    }
  };

  // Scroll Function:
  handleScroll = (data, type) => {
    this.setState({
      count: 3,
      message: '',
    });
    // var thisdata = this
    console.log('items', data, data.top > 0.9, this.props.tutorClassRequests);
    if (type === 'classes' && data && data.top > 0.9 && this.props.tutorClassRequests && this.props.tutorClassRequests.meta && this.props.tutorClassRequests.meta.total_count > 10) {
      this.setState({
        commonPopupModal: true,
        loadMoreRequestType: 'class',
      });
      console.log('commonPopupModal');
      var timer = setInterval(() => {
        if (this.state.count <= 0) {
          clearInterval(timer);
          this.setState({
            message: 'Go Class request',
          });
          history.push(routeName.viewAllRequest);
        } else {
          this.setState((prevState) => ({ count: this.state.count - 1 }));
        }
      }, 1500);
    } else if (type === 'camps' && data && data.top > 0.9 && this.props.tutorCampsRequests && this.props.tutorCampsRequests.meta && this.props.tutorCampsRequests.meta.total_count > 10) {
      this.setState({
        commonPopupModal: true,
        loadMoreRequestType: 'camps',
      });
      this.inter = setInterval(() => {
        if (this.state.count <= 0) {
          history.push(routeName.viewAllRequest);
          clearInterval(timer);
          this.setState({
            message: 'Go Camps request',
          });
        } else {
          this.setState((prevState) => ({ count: prevState.count - 1 }));
        }
      }, 1000);
    }
  };

  tutorRoutes = () => {
    history.push({
      pathname: routeName.tutorClassView,

    });
  };

  closeCommonPopupModal = () => {
    this.setState({
      commonPopupModal: false,
      loadMoreRequestType: '',

    });
  };

  meetingLinkAction = (details, type) => {
    const meeting_link = _.chain(details).get('meeting_details[0].link').value();
    if (meeting_link) {
      return (
        <span>

          <a href={meeting_link} rel="noopener noreferrer" target="_blank">
            {type === 'list' ? 'Join to meeting'

              : meeting_link}
          </a>
        </span>
      );
    }
    return type === 'list' ? <div>---</div> : null;
  };

  render() {
    const { acceptUserType, requestId } = this.state;
    const { enableCampsRequestLoading } = this.props;
    const class_columns = [{
      title: this.props.t('userLabel'),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: this.props.t('userLabel1'),
      dataIndex: 'class',
      key: 'class',
    },
    {
      title: this.props.t('Action'),
      key: 'action',
      render: (text, record) => (
        <div>
          <Space size="middle">
            {_.get(record, 'class_details.additional_details.no_of_students_accepted') > _.get(record, 'class_details.no_of_max_students') ? (
              <div>
                <button onClick={() => this.onAcceptUser(record, 'userAccept')} className="btn btn-accept mr-1 d-none">Accept</button>
                {' '}
                <button onClick={() => this.onAcceptUser(record, 'userReject')} className="btn btn-reject">Reject</button>
              </div>
            ) : (
              <div>
                <button onClick={() => this.onAcceptUser(record, 'userAccept')} className="btn btn-accept mr-1">Accept</button>
                {' '}
                <button onClick={() => this.onAcceptUser(record, 'userReject')} className="btn btn-reject">Reject</button>
              </div>
            )}
          </Space>

        </div>
      ),
    },
    ];
    const camps_columns = [{
      title: this.props.t('userLabel'),
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: this.props.t('userLabel2'),
      dataIndex: 'Camps',
      key: 'Camps',
    },
    {
      title: this.props.t('Action'),
      key: 'action',
      render: (text, record) => (
        <div size="middle">
          <div>
            <Button loading={!!(record.type === 'camps' && acceptUserType === 'userAccept' && requestId === record.key && enableCampsRequestLoading)} disabled={record.type === 'camps' && acceptUserType === 'userAccept' ? false : !_.isEmpty(acceptUserType)} onClick={() => this.onAcceptUser(record, 'userAccept')} className="btn btn-accept mr-1">Accept</Button>
            {' '}
            <Button loading={!!(record.type === 'camps' && acceptUserType === 'userReject' && requestId === record.key && enableCampsRequestLoading)} disabled={record.type === 'camps' && acceptUserType === 'userReject' ? false : !_.isEmpty(acceptUserType)} onClick={() => this.onAcceptUser(record, 'userReject')} className="btn btn-reject">Reject</Button>
          </div>
        </div>
      ),
    },
    ];

    const user_class_request = [];
    if (this.props.tutorClassRequests && this.props.tutorClassRequests.items
            && this.props.tutorClassRequests.items.length > 0) {
      this.props.tutorClassRequests.items.map((class_user, index) => {
        user_class_request.push({
          type: 'classes',
          key: class_user.id,
          user: _.isEmpty(class_user.dependent_user) ? class_user.user && `${class_user.user.firstname} ${_.get(class_user, 'user.lastname', '') !== '' ? `${_.startCase(_.get(class_user, 'user.lastname', ''))[0]}.` : ''}` : _.startCase(class_user.dependent_user.firstname),
          class: class_user.class_details && class_user.class_details.name,
          user_id: class_user.user && class_user.user.id,
          dependent_user: class_user.dependent_user && class_user.dependent_user.id,
          class_details: _.get(class_user, 'class_details'),
          booking_dates: _.get(class_user, 'booking_dates', []),
        });
        return null;
      });
    }
    const user_camps_request = [];
    if (this.props.tutorCampsRequests && this.props.tutorCampsRequests.items
            && this.props.tutorCampsRequests.items.length > 0) {
      this.props.tutorCampsRequests.items.map((camp_user, index) => {
        user_camps_request.push({
          type: 'camps',
          key: camp_user.id,
          user: _.isEmpty(camp_user.dependent_user) ? camp_user.user && `${camp_user.user.firstname} ${_.get(camp_user, 'user.lastname', '') !== '' ? `${_.startCase(_.get(camp_user, 'user.lastname', ''))[0]}.` : ''}` : _.startCase(camp_user.dependent_user.firstname),
          Camps: _.get(camp_user, 'camp_details.name'),
          user_id: camp_user.user && camp_user.user.id,
          dependent_user: camp_user.dependent_user && camp_user.dependent_user.id,
        });
        return null;
      });
    }

    const { today_schedules } = this.state;
    if (
      this.props.tutorClassesCount
            && this.props.tutorClassesCount.items
            && this.props.tutorClassesCount.items.length > 0
    ) {
      const providerServices = [];
      this.props.tutorClassesCount.items.map((value, index, key) => {
        providerServices.push(...value.service);
        return null;
      });
      var servicesCount = {};
      providerServices.forEach((i) => {
        servicesCount[`${i.id}-${i.name}`] = (servicesCount[`${i.id}-${i.name}`] || 0) + 1;
      });
    }
    if (
      this.props.tutorCampsCount
            && this.props.tutorCampsCount.items
            && this.props.tutorCampsCount.items.length > 0
    ) {
      const providerCampsServices = [];
      this.props.tutorCampsCount.items.map((value, index) => {
        providerCampsServices.push(...value.service);
        return null;
      });
      var servicesCampsCount = {};
      providerCampsServices.forEach((i) => {
        servicesCampsCount[`${i.id}-${i.name}`] = (servicesCampsCount[`${i.id}-${i.name}`] || 0) + 1;
      });
    }

    const studentClessRequest = _.size(_.get(this, 'props.tutorClassRequests.items')) !== 0 ? _.size(_.get(this, 'props.tutorClassRequests.items')) : 0;
    const studentCampRequest = _.size(_.get(this, 'props.tutorCampsRequests.items')) !== 0 ? _.size(_.get(this, 'props.tutorCampsRequests.items')) : 0;
    const student_request_count = (studentClessRequest + studentCampRequest);
    const meeting_style = (details) => (_.chain(details).get('meeting_details[0].link').value() ? 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4' : 'col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4');
    return (
      <div className="row dashboard-tutor">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          {_.size(_.get(this, 'props.tutorClassesCount.items')) !== 0 || _.size(_.get(this, 'props.tutorClassesCount.items')) === 0 ? (
            <div className="nurtem-card-design">
              <section className="py-3">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="nurtem-dashboard-card-title">
                    {this.props.t('tutDashboard6')}
                    {dayjs().format('dddd Do MMMM')}
                  </div>
                </div>
                <div className="row pt-3">

                  <div className="col-md-6 col-xl-4">
                    <div className="image-box image-box--shadowed white-bg style-2 mb-4">
                      <div className="overlay-container">
                        <div className="box-blue" />
                        <img src={require('../../../assets/images/classess.png'.default)} alt="img" />
                        <Link to={routeName.tutorEditProfile} className="overlay-link" />
                      </div>
                      <div className="body classes">
                        <h5 className="font-weight-bold my-2">
                          {this.props.t('tutDashboard7')}
                          {' '}
                          {_.get(this.props, 'tutorBaseProfile.details.update_progress') >= 100 ? 100 : _.get(this.props, 'tutorBaseProfile.details.update_progress')}
                          {' '}
                        </h5>

                        <div className="row d-flex align-items-center">
                          <div className="col-5" />
                          <div className="col-7 text-right">
                            <Link to={routeName.tutorEditProfile} className="btn radius-50 btn-gray-transparent btn-animated">
                              {this.props.t('updateProfile')}
                              {' '}
                              <i className="ntm-right-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="image-box image-box--shadowed white-bg style-2 mb-4">
                      <div className="overlay-container">
                        <div className="box-blue" />
                        <img src={require('../../../assets/images/classess.png').default} alt="img" />
                        <Link to={routeName.tutorClassView} className="overlay-link" />
                      </div>
                      <div className="body classes">
                        <h5 className="font-weight-bold my-2">
                          {this.props.t('tutorSidebarMyClasses')}
                          {' '}
                          -
                          {' '}
                          {_.size(_.get(this.props, 'tutorClassesCount.items')) !== 0 ? _.size(_.get(this.props, 'tutorClassesCount.items')) : 0}
                          {' '}
                        </h5>

                        <div className="row d-flex align-items-center">
                          <div className="col-5">
                            <Link to={`${routeName.TutorUpdateClass}?type=add`}>
                              {' '}
                              <button className="btn btn-learn mt-2 mb-2">
                                {this.props.t('add')}
                                {' '}
                                <i className="ntm-plus" />
                              </button>
                            </Link>
                          </div>
                          <div className="col-7 text-right">
                            <Link to={routeName.tutorClassView} className="btn radius-50 btn-gray-transparent btn-animated">
                              {this.props.t('viewClasses')}
                              {' '}
                              <i className="ntm-right-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="image-box image-box--shadowed white-bg style-2 mb-4">
                      <div className="overlay-container">

                        <img src={require('../../../assets/images/camps.png').default} alt="img" />
                        <Link to={routeName.tutorCampView} className="overlay-link" />
                      </div>
                      <div className="body camps">
                        <h5 className="font-weight-bold my-2">
                          {this.props.t('tutorSidebarMyCamps')}
                          {' '}
                          -
                          {' '}
                          {_.size(_.get(this.props, 'tutorTotalCampsCount.items')) !== 0 ? _.size(_.get(this.props, 'tutorTotalCampsCount.items')) : 0}
                          {' '}
                        </h5>
                        <div className="row d-flex align-items-center">
                          <div className="col-5">
                            <Link to={`${routeName.TutorUpdateCamp}?type=add`}>
                              {' '}
                              <button className="btn btn-learn mt-2 mb-2">
                                {this.props.t('add')}
                                {' '}
                                <i className="ntm-plus" />
                              </button>
                            </Link>
                          </div>
                          <div className="col-7 text-right">
                            <Link to={routeName.tutorCampView} className="btn radius-50 btn-gray-transparent btn-animated">
                              {this.props.t('viewClasses')}
                              {' '}
                              <i className="ntm-right-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-xl-4">
                    <div className="image-box image-box--shadowed white-bg style-2 mb-4">
                      <div className="overlay-container">
                        <div className="box-blue" />
                        <img src={require('../../../assets/images/students.png').default} alt="img" />
                        <Link to={routeName.viewAllRequest} className="overlay-link"> </Link>
                      </div>
                      <div className="body students">
                        <h5 className="font-weight-bold my-2">
                          {this.props.t('students')}
                          {student_request_count}
                          {' '}

                        </h5>

                        <div className="row d-flex align-items-center">
                          <div className="col-6">
                            <Link to={routeName.viewAllRequest}>
                              {' '}
                              <button className="btn btn-learn mt-2 mb-2">
                                {' '}
                                {this.props.t('view')}
                                {' '}
                                <i className="ntm-eye" />
                              </button>
                            </Link>
                          </div>
                          <div className="col-6 text-right">
                            <Link to={routeName.viewAllRequest} className="btn radius-50 btn-gray-transparent btn-animated">
                              {this.props.t('requ')}
                              {' '}
                              <i className="ntm-right-arrow" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ) : (
            <div className="nurtem-card-design mt-2 mb-3 pt-3">
              {loader}
            </div>
          )}
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="nurtem-card-design individual-tutor tutor-tab nurtem-table learn-image-bg py-2 mt-4 mb-3">
            <div className="schedule-content" style={{ position: 'relative', zIndex: '1' }}>
              <div className="full-width">
                <div className="row mt-1 mb-2">
                  {(user_class_request && user_class_request.length > 0 && !this.props.enableRequestLoading) || (user_camps_request && user_camps_request.length > 0 && !this.props.enableCampsRequestLoading) ? (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="schedule-content dash-board-class-camp-request">
                        {' '}
                        {/* dash-board-class-camp-request this class name is updated by iyyanar@studioq.co.in */}
                        <Tabs defaultActiveKey="1">
                          <items tab="New Class Request " key={3}>
                            <Scrollbars
                              onScrollFrame={(e) => this.handleScroll(e, 'classes')}
                              style={{ height: 110, width: '80%' }}
                              className="hidden-scroll-x"
                              renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
                              renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
                            >
                              <div className="full-width">
                                <Table dataSource={user_class_request} columns={class_columns} pagination={false} />
                              </div>
                            </Scrollbars>
                          </items>
                          <items tab="New Camp Requests" key={4}>
                            <Scrollbars
                              onScrollFrame={(e) => this.handleScroll(e, 'camps')}
                              style={{ height: 110, width: '80%' }}
                              className="hidden-scroll-x"
                              renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
                              renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
                            >
                              <div className="full-width">
                                <Table dataSource={user_camps_request} columns={camps_columns} pagination={false} />
                              </div>
                            </Scrollbars>
                          </items>
                        </Tabs>
                      </div>
                    </div>
                  ) : _.get(this.props, 'enableRequestLoading') ? (
                    <div className="col-12 ">
                      <div className="card-tab-section">
                        {loader}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                      <p className="empty-animations">No schedule </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="nurtem-card-design py-4 mt-2 mb-3 today-sch">
            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
              <div className="schedule-title mb-3">
                {this.props.t('todSch')}
                {' '}
                {dayjs().format('ddd Do MMMM')}
              </div>
              {today_schedules
                                && today_schedules.length > 0 ? (
                                  <div className="schedule-content">
                                    <Scrollbars
                                      style={{ height: 210 }}
                                      className="hidden-scroll-x"
                                      renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
                                      renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
                                    >
                                      {(() => {
                                        if (
                                          today_schedules
                                            && today_schedules.length > 0
                                        ) {
                                          return today_schedules && today_schedules.map(
                                            (value, key) => (
                                              <div key={key} className="full-width">
                                                <div className="row mb-2">
                                                  <div className={meeting_style(value)}>
                                                    <div className="schedule-text">{value.name}</div>
                                                    {value.teaching_mode && <span className="schedule-badge">{value.teaching_mode}</span>}
                                                  </div>
                                                  <div className={meeting_style(value)}>
                                                    <div className="schedule-text">
                                                      {' '}
                                                      {value.start_time[_.findIndex(value.day_of_week, (o) => o === dayjs().format('dddd'))]}
                                                    </div>
                                                    <div className="schedule-text">{value.end_time[_.findIndex(value.day_of_week, (o) => o === dayjs().format('dddd'))]}</div>
                                                    <Link
                                                      to={value.urlType === 'camps' ? `${routeName.tutorCampView}?campId=${value._id}` : `${routeName.tutorClassView}?classId=${value._id}`}
                                                    >
                                                      <span className="schedule-link">{this.props.t('viewDetails')}</span>
                                                    </Link>
                                                  </div>
                                                  {_.chain(value).get('meeting_details[0].link').value() !== '' ? (
                                                    <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                                                      <div className="schedule-text">{this.meetingLinkAction(value, 'list')}</div>
                                                    </div>
                                                  )
                                                    : (
                                                      <div className="schedule-text-ram">
                                                        {this.props.t('noMeet')}
                                                        {' '}
                                                      </div>
                                                    )}
                                                </div>
                                              </div>
                                            ),
                                          );
                                        }
                                        return (
                                          <div>
                                            <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                                            <p className="empty-animations">{this.props.t('noSch')}</p>
                                          </div>
                                        );
                                      })()}

                                    </Scrollbars>
                                  </div>
                ) : this.props.classloading ? (
                  <div className=" today_schedule mt-2 mb-3 pt-3">
                    {loader}
                  </div>
                ) : (
                  <div className="no-data-content">
                    <div className="session-content-empty">
                      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                      <p className="empty-animations">{this.props.t('noSch')}</p>
                    </div>
                    {' '}

                  </div>
                )}
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
              <Tabs defaultActiveKey="1">
                <items tab="My Classes" key={1}>

                  {(servicesCampsCount
                                        && Object.keys(servicesCampsCount).length > 0) || (servicesCount
                                            && Object.keys(servicesCount).length > 0) ? (
                                              <Scrollbars
                                                style={{ height: 220 }}
                                                className="hidden-scroll-x"
                                                renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
                                                renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
                                              >
                                                {(() => {
                                                  if (
                                                    servicesCount
                                                && Object.keys(servicesCount).length > 0
                                                  ) {
                                                    return Object.keys(servicesCount).map(
                                                      (value, key) => (
                                                        <div key={key} className="schedule-content">
                                                          <div className="full-width">
                                                            <div className="row mb-2">
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="schedule-text">
                                                                  {' '}
                                                                  {value.split('-')[1]}
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="schedule-text">
                                                                  {servicesCount[value]}
                                                                  {' '}
                                                                  Classes
                                                                </div>
                                                                <Link
                                                                  to={`${routeName.tutorClassView}?services=${value.split('-')[0]
                                                                  }`}
                                                                >
                                                                  <span className="schedule-link">{this.props.t('viewDetails')}</span>
                                                                </Link>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ),
                                                    );
                                                  }
                                                  return (
                                                    <div>
                                                      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                                                      <p className="empty-animations">{this.props.t('noSer')}</p>
                                                    </div>
                                                  );
                                                })()}
                                                {this.props.tutorClassesCount
                                            && this.props.tutorClassesCount.items
                                            && Object.keys(this.props.tutorClassesCount.items)
                                              .length === 0 && (
                                                <div className="session-content-empty">
                                                  <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                                                  <p className="empty-animations">No Session</p>
                                                </div>
                                                )}
                                              </Scrollbars>
                    ) : this.props.classloading ? (
                      <div className=" today_schedule mt-2 mb-3 pt-3">
                        {loader}
                      </div>
                    ) : (
                      <div className="no-data-content-class">
                        <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                        <p className="empty-animations">{this.props.t('noCls')}</p>
                      </div>
                    )}
                </items>
                <items tab="Camps" key={2}>

                  {(servicesCampsCount
                                        && Object.keys(servicesCampsCount).length > 0) || (servicesCount
                                            && Object.keys(servicesCount).length > 0) ? (
                                              <Scrollbars
                                                style={{ height: 130 }}
                                                className="hidden-scroll-x"
                                                renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" style={{ display: 'none' }} />}
                                                renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" style={{ display: 'none' }} />}
                                              >
                                                {' '}
                                                {(() => {
                                                  if (
                                                    servicesCampsCount
                                                    && Object.keys(servicesCampsCount).length > 0
                                                  ) {
                                                    return Object.keys(servicesCampsCount).map(
                                                      (value, key) => (
                                                        <div key={key} className="schedule-content">
                                                          <div className="full-width">
                                                            <div className="row">
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="schedule-text">
                                                                  {' '}
                                                                  {value.split('-')[1]}
                                                                </div>
                                                              </div>
                                                              <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                                <div className="schedule-text">
                                                                  {' '}
                                                                  {servicesCampsCount[value]}
                                                                  {' '}
                                                                  {this.props.t('tutorSidebarMyCamps')}
                                                                </div>
                                                                <Link
                                                                  to={`${routeName.tutorCampView}?services=${value.split('-')[0]
                                                                  }`}
                                                                >
                                                                  <span className="schedule-link">{this.props.t('viewDetails')}</span>
                                                                </Link>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      ),
                                                    );
                                                  }
                                                })()}
                                                {this.props.tutorCampsCount
                                            && this.props.tutorCampsCount.items
                                            && this.props.tutorCampsCount.items.length > 0
                                            && Object.keys(this.props.tutorCampsCount.items)
                                              .length === 0 && (
                                                <div className="session-content-empty">
                                                  <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                                                  <p className="empty-animations">{this.props.t('noCamps')}</p>
                                                </div>
                                                )}
                                              </Scrollbars>
                    ) : this.props.camploading ? (
                      <div className=" today_schedule mt-2 mb-3 pt-3">
                        {loader}
                      </div>
                    ) : (
                      <div className="no-data-content-class">
                        <EmptyAnime AnimeType="Empty" loop animeheight={150} />

                        <p className="empty-animations">{this.props.t('noCamps')}</p>
                      </div>
                    )}
                </items>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          {this.state.commonPopupModal && (
          <div className="load-more-request-modal">
            <div className="load-more-request-content">
              <div className="load-more-request-title">
                {this.props.t('tutDashboard8')}
                {' '}
                {this.state.loadMoreRequestType === 'message' ? 'message' : 'requests'}
              </div>
              <div className="load-more-request-subtitle">
                {this.props.t('tutDashboard9')}
                {' '}
                {this.state.message ? this.state.message : this.state.count}
                {' '}
                secs...
              </div>
              <Button onClick={() => this.closeCommonPopupModal()} className="load-more-request-btn" icon={<AiOutlineCloseCircle />}>{this.props.t('tutDashboard10')}</Button>
              <div className="load-more-request-close-btn">
                <AiOutlineCloseCircle onClick={() => this.closeCommonPopupModal()} />
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  tutorClassesCount: state.classesDetails.classesCount,
  classloading: state.classesDetails.loading,
  camploading: state.campsDetails.loading,
  tutorCampsCount: state.campsDetails.campsCount,
  tutorClassRequests: state.classesDetails.tutorClassRequests,
  tutorClassRequestAction: state.classesDetails.tutorClassRequestAction,
  tutorCampsRequests: state.campsDetails.tutorCampsRequests,
  tutorCampsRequestAction: state.campsDetails.tutorCampsRequestAction,
  loading: state.users.loading,
  createdReply: state.tutorProfile.messages,
  messageLoading: state.users.messageLoading,
  enableRequestLoading: state.classesDetails.enableRequestLoading,
  enableCampsRequestLoading: state.campsDetails.enableCampsRequestLoading,
  tutorClasses: state.classesDetails.classesList,
  tutorCamps: state.campsDetails.providerCampsDetails,
  tutorTotalCampsCount: state.campsDetails.providerCampsDetails,

});

const mapDispatchToProps = (dispatch) => ({
  emptyClassesCount: () => dispatch(classActions.onClassesCount({})),
  emptyCampsCount: () => dispatch(actions.onCampsCount({})),
  emptyClassesRequests: (requestParams) => dispatch(classActions.onClassesRequestsSuccess({})),
  emptyCampsRequests: (params) => dispatch(actions.onCampsRequestsSuccess({})),
  getClassesList: (requestParams) => dispatch(classActions.getClassesList(requestParams)),
  getDashBoardClassesList: (requestParams) => dispatch(classActions.getClassesList(requestParams)),
  getCampsList: (requestParams) => dispatch(actions.getProviderCamps(requestParams)),
  getCampsCount: () => dispatch(actions.getCampsCount()),
  getClassesCount: () => dispatch(classActions.getClassesCount()),
  getClassesRequests: (requestParams) => dispatch(classActions.getClassesRequests(requestParams)),
  ClassesRequestAction: (data, callback) => dispatch(classActions.ClassesRequestAction(data, callback)),
  ClassesSlotFullAction: (data) => dispatch(classActions.ClassesSlotFullAction(data)),

  getCampsRequests: (params) => dispatch(actions.getCampsRequests(params)),
  CampsRequestAction: (data, callback) => dispatch(actions.CampsRequestAction(data, callback)),
  onTutorSiderbarLogo: (requestParams) => dispatch(servicesActions.sidebarLogo(requestParams)),

  onTutorSiderbar: (requestParams) => dispatch(servicesActions.onTutorPublicsiderbar(requestParams)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(TutorDashboard));
