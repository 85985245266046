import * as actionTypes from '../../../actions/tutor/add-camp/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
  validateAddCamp: false,
  currentPage: 0,
  saveToLocalStorage: 0,
  campCreatedSuccessfully: false,
  loading: false,
  camp_profile: {
    service_ids: { data: [], fetching: false },
    location_taught: { data: [], fetching: false },
    services: { data: [], fetching: false },
  },
  campData: {},
  campImageCreatedSuccessfully: false,

};

const enableValidateAddCamp = (state, action) => updateObject(state, action.data);
const disableValidateAddCamp = (state, action) => updateObject(state, action.data);
const saveToLocalStorage = (state, action) => updateObject(state, action.data);
const nextPage = (state, action) => updateObject(state, { currentPage: state.currentPage + 1 });
const previousPage = (state, action) => updateObject(state, { currentPage: state.currentPage - 1 });
const resetPage = (state, action) => updateObject(state, action.data);
const addCampEnableLoading = (state, action) => updateObject(state, action.loading);
const addCampDisableLoading = (state, action) => updateObject(state, action.loading);
const addCampImageDisableLoading = (state, action) => updateObject(state, action.loading);
const campImageCreatedSuccessfully = (state, action) => updateObject(state, action.data);
const beforeFetchingServices = (state, action) => updateObject(state, {
  camp_profile: { ...state.camp_profile, ...action.data },
});
const afterFetchingServices = (state, action) => updateObject(state, {
  camp_profile: { ...state.camp_profile, ...action.data },
});
const beforeFetchingServicesValues = (state, action) => updateObject(state, {
  camp_profile: { ...state.camp_profile, ...action.data },
});
const afterFetchingServicesValues = (state, action) => updateObject(state, {
  camp_profile: { ...state.camp_profile, ...action.data },
});
const beforeFetchingLocations = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});

const afterFetchingLocations = (state, action) => updateObject(state, {
  profile_step_1: { ...state.profile_step_1, ...action.data },
});
const campCreatedSuccessfully = (state, action) => updateObject(state, action.data);
const setCampData = (state, action) => updateObject(state, action.data);
const CampsList = (state, action) => ({ ...state, tutorCampsDetails: action.success });
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.enableValidateAddCamp:
      return enableValidateAddCamp(state, action);
    case actionTypes.disableValidateAddCamp:
      return disableValidateAddCamp(state, action);
    case actionTypes.saveToLocalStorage:
      return saveToLocalStorage(state, action);
    case actionTypes.nextPage:
      return nextPage(state, action);
    case actionTypes.previousPage:
      return previousPage(state, action);
    case actionTypes.resetPage:
      return resetPage(state, action);
    case actionTypes.addCampEnableLoading:
      return addCampEnableLoading(state, action);
    case actionTypes.addCampDisableLoading:
      return addCampDisableLoading(state, action);
    case actionTypes.beforeFetchingServices:
      return beforeFetchingServices(state, action);
    case actionTypes.afterFetchingServices:
      return afterFetchingServices(state, action);
    case actionTypes.afterFetchingServicesValues:
      return afterFetchingServicesValues(state, action);
    case actionTypes.beforeFetchingLocations:
      return beforeFetchingLocations(state, action);
    case actionTypes.beforeFetchingServicesValues:
      return beforeFetchingServicesValues(state, action);
    case actionTypes.afterFetchingLocations:
      return afterFetchingLocations(state, action);
    case actionTypes.campCreatedSuccessfully:
      return campCreatedSuccessfully(state, action);
    case actionTypes.setCampData:
      return setCampData(state, action);
    case actionTypes.tutorCampDetailsFoundSuccess:
      return CampsList(state, action);
    case actionTypes.addCampImageDisableLoading:
      return addCampImageDisableLoading(state, action);
    case actionTypes.campImageCreatedSuccessfully:
      return campImageCreatedSuccessfully(state, action);
    default:
      return state;
  }
};

export default reducer;
