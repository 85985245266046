import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Button, Tabs } from 'antd';
import { FaPlus } from "react-icons/fa6";
import '../tutor-camps/user-new-my-class.css';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import TutorList from './tutor-list';
import TutorCompetition from './tutor-comp-list';
import * as routeName from '../../../components/route-names';
import * as campAction from '../../../store/actions/tutor/camps';
import _ from 'lodash';


class Competitions extends React.Component {
  async componentDidMount() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  render() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    const addcampBtn = urlparams && !urlparams.campId && this.props.active_key === '1' && <Link to={`${routeName.updateCompetition}?type=add`}>
      <Button icon={<FaPlus style={{ fontSize: 20 }} />} type='primary' className="class-btn">{this.props.t('addCompetibtn')}</Button></Link>;
    const tabTitle = this.props.t('listAllComps');
    const tabTitle1 = this.props.t('listingTutors');

    return (
      _.get(this, 'props.tutorBaseProfile.details.type') === 'Business' && urlparams && !urlparams.campId ? (
        <div className="my-class-page-tab-container nurtem-card-design position-relative mb-4">
          {addcampBtn}
          <Tabs activeKey={this.props.active_key} onTabClick={(key) => this.props.switchTab(key)}>
            <items tab={tabTitle} key="1">
              <TutorCompetition {...this.props} />
            </items>
            <items tab={tabTitle1} key="2" onClick={() => this.props.switchTab('2')}>
              <TutorList {...this.props} />
            </items>
          </Tabs>
        </div>
      ) : (
        <div className="my-class-page-tab-container nurtem-card-design position-relative mb-4">
          {addcampBtn}
          <Tabs activeKey={this.props.active_key} onTabClick={(key) => this.props.switchTab(key)}>
            <items tab={tabTitle} key="1">
              <TutorCompetition {...this.props} />
            </items>
          </Tabs>
        </div>
      )
    );
  }
}
const mapStateToProps = (state) => ({
  tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  active_key: state.campsDetails.activeKey,
});

const mapDispatchToProps = (dispatch) => ({
  switchTab: (data) => dispatch(campAction.activeKey(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Competitions));
