import React from 'react';
import { Form, Input, Button } from 'antd';
import { evaluate } from '../../store/actions/tutor/common';
const EvaluationForm = (params) => {
  const onFinish = (values) => {
    const { handleOk } = params;
    console.log('Received values:params', params);
    console.log('Received values:', {...values, id:params.id});

    evaluate({...values, id:params.id});
    handleOk();
  };

  return (
    <Form
      name="providerEvaluationForm"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Assignment Identity"
        name="assignment_identity"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Provider Remarks"
        name="provider_remarks"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Provider Evaluation Score"
        name="provider_evaluation_score"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Provider Evaluation Status"
        name="provider_evaluation_status"
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EvaluationForm;
