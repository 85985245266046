import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import {
  Input, Button, Form, Tabs, notification, Modal, Tooltip, Pagination, Popconfirm,
} from 'antd';
import _ from 'lodash';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import { connect } from 'react-redux';
import '../../containers/tutor/tutor-camps/user-new-my-class.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { withTranslation } from 'react-i18next';
import { DEFAULTUSER } from '../../utils/config/assets';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import * as actions from '../../store/actions/tutor/add-class';

dayjs.extend(utc);
dayjs.extend(timezone);

const { TextArea } = Input;
function callback(key) {
  console.log(key);
}

const Faq = (props) => {
  const [form] = Form.useForm();
  const [message_id, setMessage_id] = useState('');
  const [isLoading, setisLoading] = useState(false);
  const [ownFAQ, setOwnFAQ] = useState();
  const [communityFAQ, setCommunityFAQ] = useState();
  const [askQuestion, setAskQuestion] = useState(false);
  const [askQuestionEdit, setAskQuestionEdit] = useState(false);
  const [faqLoading, setFaqLoading] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const faqRef = useRef();

  const handleErrors = (response) => {
    if (_.get(response, 'response.data.errors')) {
      commonSuccessErrorMessage('error', _.get(response, 'response.data.errors.rules[0].message'));
    } else if (_.get(response, 'response.data.message')) {
      commonSuccessErrorMessage('error', _.get(response, 'response.data.message'));
    }
  };

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    props.listFAQ({
      session_details: props.session.id,
      limit,
      page: pageNumber,
      status: 1,
      sort: 'updated_at.desc',
      type: props.type,
    }, (response, type) => {
      if (type === 'success') {
        setOwnFAQ(response);
      } else {
        handleErrors(response);
      }
      callback && callback(type === 'success' ? null : response);
    });
  };

  const onPageChangeCommuntiy = (pageNumber) => {
    setPage(pageNumber);
    props.listFAQ({
      session_details: props.session.id,
      limit,
      page: pageNumber,
      status: 2,
      expand: 'user',
      sort: 'updated_at.desc',
    }, (response, type) => {
      if (type === 'success') {
        setCommunityFAQ(response);
      } else {
        handleErrors(response);
      }
      callback && callback(type === 'success' ? null : response);
    });
  };

  const listFAQView = useCallback((id) => {
    props.listFAQ({
      session_details: id, limit, page, status: 1, sort: 'updated_at.desc', type: props.type,
    }, (response, type) => {
      if (type === 'success') {
        setOwnFAQ(response);
      } else {
        handleErrors(response);
      }
    });
  }, [props.listFAQ]);

  useEffect(() => {
    form.resetFields();
    setMessage_id('');
    listFAQView(props.session.id);
  }, [listFAQView, props.session.id]);

  const listCommunityFAQView = useCallback((id) => {
    props.listFAQ({
      session_details: id, limit, page, status: 2, expand: 'user', sort: 'updated_at.desc', type: props.type,
    }, (response, type) => {
      if (type === 'success') {
        setCommunityFAQ(response);
      } else {
        handleErrors(response);
      }
    });
  }, [props.listFAQ]);

  useEffect(() => {
    form.resetFields();
    setMessage_id('');
    listCommunityFAQView(props.session.id);
  }, [listCommunityFAQView, props.session.id]);

  const commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
    });
  };

  const onfaqFinish = (values, form, type) => {
    try {
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value.trim()]),
      );
      const hasNonEmptyFields = Object.values(trimmedValues).some(Boolean);

      if (!hasNonEmptyFields) {
        return;
      }
      const data = { ...values };
      data.created_by = 'provider';
      data.session_details = props.session.id;
      data.type_details = props.session.group_id;
      data.timezone = dayjs.tz.guess();
      data.time = new Date();
      data.type = props.type;
      faqActions(data, type);
    } catch (error) {
      console.log('error on faq.js at onfaqFinish', error);
    }
  };

  const faqActions = (values, type) => {
    setFaqLoading(true);
    props[type](values, async (response, type) => {
      setFaqLoading(true);
      if (type === 'success') {
        listFAQView(_.get(response, 'details.session_details'));
        listCommunityFAQView(_.get(response, 'details.session_details'));
        askQuestionshowModal(false);
        commonSuccessErrorMessage('success', _.get(response, 'message'));
        setisLoading(false);
        setFaqLoading(false);
      } else if (_.get(response, 'response.data.errors')) {
        commonSuccessErrorMessage('error', _.get(response, 'response.data.errors.rules[0].message'));
      } else if (_.get(response, 'response.data.message')) {
        commonSuccessErrorMessage('error', _.get(response, 'response.data.message'));
      } else if (_.get(response, 'data.message')) {
        commonSuccessErrorMessage('error', _.get(response, 'data.message'));
      }
      await form.resetFields();
    });
  };

  const deleteModal = (ids) => {
    // var currentThis = this
    Modal.confirm({
      keyboard: false,
      maskClosable: false,
      title: 'Are you sure to delete this faq?',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      // cancelButtonProps: {
      //   disabled: currentThis.state.faqloading
      // },
      onOk() {
        const id = { id: ids };
        faqActions(id, 'deleteFaq');
        // return new Promise((resolve, reject) => {
        //   currentThis.onMessageDelete(id)
        // }).catch(() => console.log('Oops errors!'))
      },
    });
  };

  const deleteFaq = async (value) => {
    const id = { id: value.id };
    faqActions(id, 'deleteFaq');
  };

  const handleEdit = (items, mode) => {
    setSelectedFaq(items);
    const { question, answer } = items;
    form.setFieldsValue({ question, answer });
    setAskQuestionEdit(true);
  };

  const updateFaq = async (values, type) => {
    try {
      const trimmedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, value.trim()]),
      );

      const hasNonEmptyFields = Object.values(trimmedValues).some(Boolean);

      if (!hasNonEmptyFields) {
        return;
      }
      setisLoading(true);
      const { question, answer } = values;
      const { id } = selectedFaq;
      const params = {
        type: props.type,
        id,
        question,
        answer,
        time: new Date(),
        session_details: props.session.id,
        type_details: props.session.group_id,
        timezone: dayjs.tz.guess(),
      };
      faqActions(params, type);
      setAskQuestionEdit(false);
    } catch (error) {
      console.log('error on faq.js at updateFaq');
    }
  };

  const askQuestionshowModal = (value) => {
    setAskQuestion(value);
  };

  const render = () => (
    <div className="container">

      {/* items Header */}
      <div className="row mb-3">
        <div className="col-6 tab-content-title">{props.t('faqs')}</div>
        <div className="col-6" style={{ textAlign: 'end' }}>
          <Button
            className="btn-green-filled"
            onClick={() => {
              askQuestionshowModal(true);
            }}
          >
            {props.t('askQuestion')}
          </Button>
        </div>
      </div>

      {/* items Header */}
      <div className="row faq-tabs" style={{margin:0}}>
        <Tabs defaultActiveKey="1" className="OwnStyle" onChange={callback}>
          <items tab="Own" key="own">

            {_.get(ownFAQ, 'meta.total_count', 0) > 0 ? (
              Array.isArray(ownFAQ.items) && ownFAQ.items.map((items, index) => {
                const questionNumber = index + 1;
                return (
                  <div key={index} className="faq-list border-bottom">
                    <div className="faq-question mb-1">
                      {questionNumber}
                      .
                      {_.get(items, 'question')}
                    </div>
                    <div className="faq-answer" style={{ whiteSpace: 'pre-wrap' }}>
                      {_.get(items, 'answer')}
                    </div>
                    <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Edit">
                      <AiOutlineEdit
                        className="faq-buttons-edit"
                        color="#52c41a"
                        style={{ fontSize: 29 }}
                        onClick={() => handleEdit(items, 'edit')}
                      />
                    </Tooltip>
                    <Popconfirm
                      title="Are you sure you want to Delete this Faq?"
                      onConfirm={() => deleteFaq(items)}
                      placement="rightTop"
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip placement="bottom" arrow={false} overlayClassName="tooltipForIcon" title="Delete">
                        <AiOutlineDelete
                          className="faq-buttons-delete"
                          color="#ff5900"
                        />
                      </Tooltip>
                    </Popconfirm>
                    <div className="user-review-set-info">
                      {dayjs(_.get(items, 'updated_at')).format('MMM DD YYYY')}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>
                <EmptyAnime AnimeType="Empty" loop animeheight={150} />
                <p className="empty-animations">
                  {props.t('noFAQF')}
                  {' '}
                </p>
              </div>
            )}
            {_.get(ownFAQ, 'meta.total_count', 0) > 5 ? (
              <Pagination
                current={page}
                pageSize={limit}
                total={_.get(ownFAQ, 'meta.total_count', 0)}
                onChange={onPageChange}
              />
            ) : ''}

          </items>

          <items tab="Community" key="Community">
            {_.get(communityFAQ, 'meta.total_count', 0) > 0 ? (
              Array.isArray(communityFAQ.items) && communityFAQ.items.map((items, index) => (
                <div key={index} className="tutor-message-container">
                  <div className="tutor-message-profile">
                    {_.get(items, 'user.photo') ? (
                      <img
                        className="tutor-profile-image mr-1"
                        src={_.get(items, 'user.photo')}
                        alt="img"
                      />
                    )
                      : (
                        <img
                          className="tutor-profile-image mr-2"
                          src={DEFAULTUSER}
                          alt="Default profile"
                        />
                      )}
                  </div>
                  <div className="tutor-message-info">
                    <div className="tutor-message-name-set">
                      <span>
                        {_.startCase(_.get(items, 'user.firstname'))}
                        {' '}
                        {_.startCase(_.get(items, 'user.lastname', ''))}
                      </span>
                    </div>
                    <div className="tutor-message-content">
                      {_.get(items, 'question')}
                    </div>
                    <Form
                      form={form}
                      scrollToFirstError
                      onFinish={(e) => onfaqFinish({ ...e, question: _.get(items, 'question'), id: _.get(items, 'id') }, { form }, 'updateFaq')}
                    >
                      {message_id === _.get(items, 'id') && (
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: props.t('ansReq'),
                            },
                          ]}
                          name="answer"
                          className="tutor-message-reply-textarea"
                        >
                          <TextArea rows="2" />
                        </Form.Item>
                      )}
                      <Form.Item className="tutor-message-reply-hide-btn">
                        {message_id !== _.get(items, 'id') && (
                          <Button
                            disabled={faqLoading}
                            onClick={() => deleteModal(_.get(items, 'id'))}
                            size="small"
                            type="primary"
                            danger
                          >
                            {props.t('delete')}
                          </Button>
                        )}
                        {message_id === _.get(items, 'id') && (
                          <Button
                            disabled={faqLoading}
                            onClick={() => setMessage_id('')}
                            size="small"
                            type="primary"
                            danger
                          >
                            {props.t('cancel')}
                          </Button>
                        )}
                        {message_id !== _.get(items, 'id') && (
                          <Button
                            disabled={faqLoading}
                            onClick={() => setMessage_id(_.get(items, 'id'))}
                            size="small"
                            type="primary"
                            className="ml-2"
                          >
                            {props.t('reply')}
                          </Button>
                        )}
                        {message_id === _.get(items, 'id') && (
                          <Button
                            loading={faqLoading}
                            htmlType="submit"
                            size="small"
                            type="primary"
                            className="ml-2"
                          >
                            {props.t('send')}
                          </Button>
                        )}
                      </Form.Item>
                    </Form>
                    <div className="user-review-set-info">
                      {dayjs(_.get(items, 'updated_at')).format('MMM DD YYYY')}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <EmptyAnime AnimeType="Empty" loop animeheight={150} />
                <p className="empty-animations">{props.t('noComFaq')}</p>
              </div>
            )}
          </items>
          {_.get(communityFAQ, 'meta.total_count', 0) > 5 ? (
            <Pagination
              current={page}
              pageSize={limit}
              total={_.get(communityFAQ, 'meta.total_count', 0)}
              onChange={onPageChangeCommuntiy}
            />
          ) : ''}
        </Tabs>
      </div>

      <Form onFinish={onfaqFinish}>
        <Modal
          className="faq-modal"
          open={askQuestion}
          centered
          title="Add a Question"
          onCancel={() => askQuestionshowModal(false)}
          confirmLoading={isLoading}
          footer={null}
        >
          <Form form={form} scrollToFirstError onFinish={(e) => onfaqFinish(e, { form }, 'createFaq')} ref={faqRef}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="full-width ">
                  <Tooltip>
                    <label className="filter-group-title">
                      {props.t('question')}
                      {' '}
                      <span className="label-star">*</span>
                    </label>
                  </Tooltip>
                  <div className="full-width position-relative row">
                    <Form.Item
                      name="question"
                      rules={[
                        {
                          required: true,
                          message: props.t('Queisreq'),
                        },
                      ]}
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="full-width ">
                  <Tooltip>
                    <label className="filter-group-title mb-3">
                      {props.t('answer')}
                      {' '}
                      <span className="label-star">*</span>
                    </label>
                  </Tooltip>
                  <div className="full-width position-relative row">
                    <Form.Item
                      name="answer"
                      rules={[
                        {
                          required: true,
                          message: props.t('ansReq'),
                        },
                      ]}
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    >
                      <Input.TextArea
                        rows={3}
                        className="border-radius-10"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Form.Item className="col-12">
                <div className="full-width text-right">
                  <Button
                    disabled={faqLoading}
                    onClick={() => askQuestionshowModal(false)}
                    type="primary"
                    danger
                  >
                    {props.t('cancel')}
                  </Button>
                  <Button
                    loading={faqLoading}
                    htmlType="submit"
                    className="ml-3"
                    type="primary"
                  >
                    {props.t('submit')}
                  </Button>
                </div>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </Form>

      <Form onFinish={updateFaq}>
        <Modal
          className="faq-modal"
          open={askQuestionEdit}
          centered
          title="Add a Question"
          onOk={async () => { await form.resetFields(); setAskQuestionEdit(false); }}
          onCancel={async () => { await form.resetFields(); setAskQuestionEdit(false); }}
          confirmLoading={isLoading}
          footer={null}
        >
          <Form
            form={form}
            scrollToFirstError
            onFinish={(e) => updateFaq(e, 'updateFaq')}
            ref={faqRef}
          >
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="full-width ">
                  <Tooltip>
                    <label className="filter-group-title">
                      {props.t('question')}
                      {' '}
                      <span className="label-star">*</span>
                    </label>
                  </Tooltip>
                  <div className="full-width position-relative row">
                    <Form.Item
                      name="question"
                      rules={[
                        {
                          required: true,
                          message: props.t('Queisreq'),
                        },
                      ]}
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    >
                      <Input className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="full-width ">
                  <Tooltip>
                    <label className="filter-group-title mb-3">
                      {props.t('answer')}
                      {' '}
                      <span className="label-star">*</span>
                    </label>
                  </Tooltip>
                  <div className="full-width position-relative row">
                    <Form.Item
                      name="answer"
                      rules={[
                        {
                          required: true,
                          message: props.t('ansReq'),
                        },
                      ]}
                      className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                    >
                      <Input.TextArea
                        rows={3}
                        className="border-radius-10"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="full-width text-right">
                <Button
                  disabled={faqLoading}
                  onClick={() => setAskQuestionEdit(false)}
                  type="primary"
                  danger
                >
                  {props.t('cancel')}
                </Button>
                <Button
                  loading={faqLoading}
                  htmlType="submit"
                  className="ml-3"
                  type="primary"
                >
                  {props.t('submit')}
                </Button>
              </div>
            </div>
          </Form>
        </Modal>
      </Form>
    </div>
  );
  return render();
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  listFAQ: (data, callback) => dispatch(actions.listFAQ(data, callback)),
  createFaq: (data, callback) => dispatch(actions.createFAQ(data, callback)),
  updateFaq: (data, callback) => dispatch(actions.updateFAQ(data, callback)),
  deleteFaq: (data, callback) => dispatch(actions.deleteFAQ(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Faq));
