import * as actionTypes from '../../actions/public/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  onClassesListLoading: false,
  loading: {
    contentLoading: false,
    filterLoading: false,
  },
  classStudioData: {},
  classDriveData: {},
  classOnlineData: {},
  addedCartItems: [],
  cartItemDetails: {},
  cartItemLoading: false,
  cartItemsDeatils: {},
  customerDetails: {},
  customerDetailsCompleted: false,
  paymentDetails: {},
  paymentDetailsCompleted: false,
  onClassesList: {},
  onClassesSponsoredList: {},
};

const classStudioFoundSuccess = (state, action) => ({ ...state, classStudioData: action.success });
const onClassesListLoading = (state, action) => ({ ...state, onClassesListLoading: action.success });

const getClassesListSuccess = (state, action) => ({ ...state, onClassesList: action.success });
const getClassesSponoredListSuccess = (state, action) => ({ ...state, onClassesSponsoredList: action.success });
const cartItemDetailsSuccess = (state, action) => ({ ...state, cartItemDetails: action.success });
const cartItemsDeatils = (state, action) => ({ ...state, cartItemsDeatils: action.success });

const cartItemAddedSuccess = (state, action) => ({ ...state, addedCartItems: action.success });

const classDriveFoundSuccess = (state, action) => ({ ...state, classDriveData: action.success });

const classOnlineFoundSuccess = (state, action) => ({ ...state, classOnlineData: action.success });
const customerDetails = (state, action) => {
  console.log('Redux action.success', action.success);
  return { ...state, customerDetails: action.success };
};
const paymentDetails = (state, action) => ({ ...state, paymentDetails: action.success });
const paymentDetailsCompleted = (state, action) => updateObject(state, action.paymentDetailsCompleted);
const customerDetailsCompleted = (state, action) => updateObject(state, action.customerDetailsCompleted);
const enableContentLoading = (state, action) => updateObject(state, action.loading);
const disableContentLoading = (state, action) => updateObject(state, action.loading);
const enableCartContentLoading = (state, action) => ({ ...state, cartItemLoading: action.loading });
const disableCartContentLoading = (state, action) => ({ ...state, cartItemLoading: action.loading });
const enableFilterLoading = (state, action) => updateObject(state, action.loading);
const disableFilterLoading = (state, action) => updateObject(state, action.loading);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.classStudioFoundSuccess:
      return classStudioFoundSuccess(state, action);
    case actionTypes.getClassesListSuccess:
      return getClassesListSuccess(state, action);
    case actionTypes.getClassesSponoredListSuccess:
      return getClassesSponoredListSuccess(state, action);
    case actionTypes.onClassesListLoading:
      return onClassesListLoading(state, action);
    case actionTypes.classDriveFoundSuccess:
      return classDriveFoundSuccess(state, action);
    case actionTypes.classOnlineFoundSuccess:
      return classOnlineFoundSuccess(state, action);
    case actionTypes.enableContentLoading:
      return enableContentLoading(state, action);
    case actionTypes.disableContentLoading:
      return disableContentLoading(state, action);
    case actionTypes.enableCartContentLoading:
      return enableCartContentLoading(state, action);
    case actionTypes.disableCartContentLoading:
      return disableCartContentLoading(state, action);
    case actionTypes.enableFilterLoading:
      return enableFilterLoading(state, action);
    case actionTypes.disableFilterLoading:
      return disableFilterLoading(state, action);
    case actionTypes.cartItemAdded:
      return cartItemAddedSuccess(state, action);
    case actionTypes.cartItemDetails:
      return cartItemDetailsSuccess(state, action);
    case actionTypes.cartItemsDeatils:
      return cartItemsDeatils(state, action);
    case actionTypes.customerDetails:
      console.log('Redux Working');
      return customerDetails(state, action);
    case actionTypes.customerDetailsCompleted:
      return customerDetailsCompleted(state, action);
    case actionTypes.paymentDetails:
      return paymentDetails(state, action);
    case actionTypes.paymentDetailsCompleted:
      return paymentDetailsCompleted(state, action);
    default:
      return state;
  }
};

export default reducer;
