import React from 'react';
import _ from 'lodash';
import { Rate, Badge, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { globalVariable } from '../../../../utils/config/config';
import { MdBusinessCenter } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import formatUrlPart from '../../../../utils/common/format-url-part';
/* Card UI */
const SearchResultCard = function (props) {
  const propsClasses = _.get(props, ['value', 'batches'], {});

  const ch = _.chain(propsClasses);
  const provider = ch.first().get('provider_info', {}).value();

  let Ctype = ch.first().get('type', '').value().charAt(0)
    .toUpperCase() + ch.first().get('type', '').value().slice(1);
  Ctype = Ctype === 'Competitions' ? ch.first().get('class_info').get('type').value() === 1 ? 'Competitions' : 'Events' : Ctype;
  const firstname = _.get(provider, 'fname', '');
  const lastname = _.get(provider, 'lname', '');
  const businessname = _.get(provider, 'businessname', '');
  const displayNameWeb = businessname || `${firstname} ${lastname}`;
  const average_rating = _.get(provider, 'average_rating', 0);
  const price = ch.first().get('price', '').value();
  const clsName = ch.first().get('class_info').get('name').value();
  const classPic = ch.first().get('class_info').get('photo').value();
  const session_name = ch.first().get('session_name', '').value();
  const gid = ch.first().get('group_id', '').value();

  let displayName = (businessname || `${firstname}`).toLowerCase().replace(/\s+/g, '-');
  displayName = !_.isEmpty(displayName) ? displayName : '-';

  let type = 'class';
  if (Ctype === 'Camps') {
    type = 'camp';
  }
  if (Ctype === 'Competitions') {
    type = 'competition';
  }
  if (Ctype === 'Events') {
    type = 'event';
  }

  const link_to = `/view-${type}/${displayName}/${formatUrlPart(session_name)}/?gid=${gid}`

  return (
    <div className="nurtem-search-card-design " key={props.index}>
      <Link to={link_to} target={"_blank"}>
        <div className="search-width">
          <img className="profile-image-avatar" src={classPic && classPic || globalVariable.DEFAULT_PROFILE_PIC} alt="img" />
        </div>
        <div className="search-width2">
          <div className="card-divider">
            <div className="profile-name text-truncate">
              {clsName ? (
                <Tooltip title={clsName.length > 20 && clsName}>{clsName}</Tooltip>
              ) : null}
            </div>
          </div>
          <div className="badge-group mt-2">
            <span className="badge badge-green mb-0 mr-2">{Ctype}</span>
          </div>
          <div className="card-divider">
            <div className="card-divider2 mt-2">
              <Rate style={{ marginRight: 10 }} disabled value={average_rating} />
              <strong style={{ fontSize: 13 }}>{average_rating.toFixed(1)}</strong>
            </div>
          </div>
          {price && price > 0 ? <div className="price-tag" style={{ padding: '2px 0px' }}>
            <span className="d-block">
              {props.t('startsFrom')}
              {' '}
              <b className="bold">
                $
                {price.toFixed(2)}
              </b>
            </span>
          </div> : <span style={{ color: 'green', fontWeight: 900 }}>FREE</span>}
        </div>
        <div className="class-links" />
        <div className="search-width3">
          <div className="card-divider4">
            {businessname ? <MdBusinessCenter size={25} style={{ marginRight: 5 }} /> : <FaUserTie size={15} style={{ marginRight: 5 }} />}
            <div className="profile-name2 text-truncate">
              {displayNameWeb}
            </div>
          </div>
          <div className="badge-group " />
        </div>
      </Link>

    </div>
  );
};

export default (withTranslation()(SearchResultCard));
