import * as actionTypes from '../../actions/tutor/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: {
    loading: false,
  },
  loadingClassDetails: { loadingClassDetails: false },
  milestoneLoading: false,
  createdCoupons: false,
  tutorClassDetails: {},
  deleteClassInfo: {},
  tutorClasses: {},
  tutorClassRequests: {},
  tutorClassRequestAction: {},
  classesStudents: {},
  businessClassesStudents: {},
  classSessionModifySuccess: {},
  createMilestone: {},
  createNotesForTutor: {},
  onUpdateNotesForTutor: {},
  deleteNotesForTutor: {},
  updatedMilestoneInfo: {},
  classesList: { items: [] },
  businessClassesList: { items: [] },
  classesCount: {},
  onSearchClassesList: {},
  onSearchClassesLoading: false,
  onBusinessSearchClassesLoading: false,
  ClassesStudentListLoading: false,
  businessClassesStudentListLoading: false,
  singleClassViewLoading: false,
  enableRequestLoading: false,
  enableCampsRequestLoading: false,
  singleClassView: {},
  otherProviderClasses: {},
};

const classesCount = (state, action) => ({ ...state, classesCount: action.success });

const classList = (state, action) => ({ ...state, classesList: action.success });

const businessClassesList = (state, action) => ({ ...state, businessClassesList: action.success });
const onSearchClassesList = (state, action) => ({ ...state, onSearchClassesList: action.success });
const onSearchClassesLoading = (state, action) => ({ ...state, onSearchClassesLoading: action.success });
const onBusinessSearchClassesLoading = (state, action) => ({ ...state, onBusinessSearchClassesLoading: action.success });
const couponList = (state, action) => ({ ...state, couponList: action.success });
const couponData = (state, action) => ({ ...state, couponData: action.success });
const tutorClassDetails = (state, action) => ({ ...state, tutorClassDetails: action.success });

const tutorClassDetele = (state, action) => ({ ...state, deleteClassInfo: action.success });
const singleClassView = (state, action) => ({ ...state, singleClassView: action.success });
const singleClassViewLoading = (state, action) => ({ ...state, singleClassViewLoading: action.success });
const enableRequestLoading = (state, action) => ({ ...state, enableRequestLoading: action.loading });
const enableCampsRequestLoading = (state, action) => ({ ...state, enableCampsRequestLoading: action.loading });

const tutorClasses = (state, action) => ({ ...state, tutorClasses: action.success });

const otherTutorClasses = (state, action) => ({ ...state, otherProviderClasses: action.success });

const tutorClassesRequests = (state, action) => ({ ...state, tutorClassRequests: action.success });
const tutorClassRequestAction = (state, action) => ({ ...state, tutorClassRequestAction: action.success });

const ClassesStudentListLoading = (state, action) => ({ ...state, ClassesStudentListLoading: action.success });
const businessClassesStudentListLoading = (state, action) => ({ ...state, businessClassesStudentListLoading: action.success });

const classesStudents = (state, action) => ({ ...state, classesStudents: action.success });

const businessClassesStudents = (state, action) => ({ ...state, businessClassesStudents: action.success });

const classSessionModifySuccess = (state, action) => ({ ...state, classSessionModifySuccess: action.success });

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const enableMilestoneLoading = (state, action) => updateObject(state, action.milestoneLoading);

const createdCoupons = (state, action) => updateObject(state, action.createdCoupons);
const disableMilestoneLoading = (state, action) => updateObject(state, action.milestoneLoading);
const setCouponsData = (state, action) => updateObject(state, action.setCouponsData);

const onUpdateMilestone = (state, action) => ({ ...state, updatedMilestoneInfo: action.success });
const onCreateMilestone = (state, action) => ({ ...state, createMilestone: action.success });
const onCreateNotesForTutor = (state, action) => ({ ...state, createNotesForTutor: action.success });
const onUpdateNotesForTutor = (state, action) => ({ ...state, onUpdateNotesForTutor: action.success });
const onDeleteNotesForTutor = (state, action) => ({ ...state, onDeleteNotesForTutor: action.success });

const resetTutorClassDetails = (state, action) => updateObject(state, { tutorClassDetails: {} });
const enableClassDetailsLoading = (state, action) => updateObject(state, action.loadingClassDetails);
const disableClassDetailsLoading = (state, action) => updateObject(state, action.loadingClassDetails);
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.tutorClassDetailsFoundSuccess:
      return tutorClassDetails(state, action);
    case actionTypes.tutorClassDeleteFoundSuccess:
      return tutorClassDetele(state, action);
    case actionTypes.singleClassView:
      return singleClassView(state, action);
    case actionTypes.enableRequestLoading:
      return enableRequestLoading(state, action);
    case actionTypes.enableCampsRequestLoading:
      return enableCampsRequestLoading(state, action);
    case actionTypes.singleClassViewLoading:
      return singleClassViewLoading(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.enableMilestoneLoading:
      return enableMilestoneLoading(state, action);
    case actionTypes.disableMilestoneLoading:
      return disableMilestoneLoading(state, action);
    case actionTypes.providerClasses:
      return tutorClasses(state, action);
    case actionTypes.otherProviderClasses:
      return otherTutorClasses(state, action);
    case actionTypes.tutorClassesRequests:
      return tutorClassesRequests(state, action);
    case actionTypes.classesRequestActionSuccess:
      return tutorClassRequestAction(state, action);
    case actionTypes.ClassesStudentListLoading:
      return ClassesStudentListLoading(state, action);
    case actionTypes.businessClassesStudentListLoading:
      return businessClassesStudentListLoading(state, action);
    case actionTypes.classesStudents:
      return classesStudents(state, action);
    case actionTypes.createMilestone:
      return onCreateMilestone(state, action);
    case actionTypes.updateMilestone:
      return onCreateNotesForTutor(state, action);
    case actionTypes.tutorClassCreateNoteSuccess:
      return onUpdateNotesForTutor(state, action);
    case actionTypes.onUpdateNotesForTutor:
      return onDeleteNotesForTutor(state, action);
    case actionTypes.tutorClassDeleteNoteSuccess:
      return onUpdateMilestone(state, action);
    case actionTypes.businessClassesListFound:
      return businessClassesList(state, action);
    case actionTypes.classesListFound:
      return classList(state, action);
    case actionTypes.onSearchClassesLoading:
      return onSearchClassesLoading(state, action);
    case actionTypes.onBusinessSearchClassesLoading:
      return onBusinessSearchClassesLoading(state, action);
    case actionTypes.onSearchClassesList:
      return onSearchClassesList(state, action);
    case actionTypes.couponList:
      return couponList(state, action);
    case actionTypes.couponData:
      return couponData(state, action);
    case actionTypes.classesCount:
      return classesCount(state, action);
    case actionTypes.resetTutorClassDetails:
      return resetTutorClassDetails(state, action);
    case actionTypes.setCouponsData:
      return setCouponsData(state, action);
    case actionTypes.createdCoupons:
      return createdCoupons(state, action);
    case actionTypes.businessClassesStudents:
      return businessClassesStudents(state, action);
    case actionTypes.classSessionModifySuccess:
      return classSessionModifySuccess(state, action);
    case actionTypes.enableClassDetailsLoading:
      return enableClassDetailsLoading(state, action);
    case actionTypes.disableClassDetailsLoading:
      return disableClassDetailsLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
