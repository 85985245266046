import { notification } from 'antd';
import _ from 'lodash';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import * as routeName from '../../../components/route-names';
import { resetPage } from './add-class';
import history from '../../../utils/history';

export const competitionDeleteFoundSuccess = (data) => ({
  type: actionTypes.tutorCompetitionsDeleteFoundSuccess,
  success: data,
});

export const onDeleteCompetition = (requestParams, callback = () => { }) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/competitions/delete',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        dispatch(competitionDeleteFoundSuccess(deleteResponse));
        dispatch(disableLoading());
        history.push(`${routeName.competitions}`);
        callback();
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;
      deleteResponse.success = false;
      dispatch(competitionDeleteFoundSuccess(deleteResponse));
      dispatch(disableLoading());
    });
};

export const onClearCampsDetail = () => (dispatch) => {
  dispatch(classFoundSuccess({}));
};

export const onClearCompetitionDeleteDetail = () => (dispatch) => {
  dispatch(competitionDeleteFoundSuccess({}));
};

export const onCompetitionRequestsSuccess = (data) => ({
  type: actionTypes.tutorCompetitionRequests,
  success: data,
});
export const onEventRequestsSuccess = (data) => ({
  type: actionTypes.tutorEventRequests,
  success: data,
});

export const getEventRequests = (data) => {
  console.log('data log', data);
  return (dispatch) => {
    // dispatch(enableRequestLoading(true))
    axios({
      method: 'get',
      url: '/events/requests',
      params: {
        ...data, expand: 'user,provider,event_details,dependent_user', sort: 'created_at.desc', type: 'business_provider',
      },
      withCredentials: true,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onEventRequestsSuccess(response.data));
        }
      })
      .catch((error) => {
        const responseData = {};
        responseData.status = 'error';
        dispatch(onEventRequestsSuccess(responseData));
      });
  };
};
export const getCompetitionRequests = (data) => {
  console.log('data log', data);
  return (dispatch) => {
    // dispatch(enableRequestLoading(true))
    axios({
      method: 'get',
      url: '/competitions/requests',
      params: {
        ...data, expand: 'user,provider,competition_details,dependent_user', sort: 'created_at.desc', type: 'business_provider',
      },
      withCredentials: true,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onCompetitionRequestsSuccess(response.data));
        }
      })
      .catch((error) => {
        const responseData = {};
        responseData.status = 'error';
        dispatch(onCompetitionRequestsSuccess(responseData));
      });
  };
};

export const onCompetitionRequestActionSuccess = (data) => ({
  type: actionTypes.tutorCompetitionRequestAction,
  success: data,
});

export const onEventRequestActionSuccess = (data) => ({
  type: actionTypes.tutorEventRequestAction,
  success: data,
});

export const CompetitionRequestAction = (data, callback) => (dispatch) => {
  // dispatch(enableMilestoneLoading())
  axios({
    method: 'post',
    url: '/competitions/requestaction',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const responseData = {};
        responseData.status = response.status;
        responseData.data = response.data;
        const message_args = {
          duration: 2,
          message: 'Success',
          description: response.data.message,
        };
        notification.success(message_args);
        dispatch(onCompetitionRequestActionSuccess(responseData));
        // dispatch(disableMilestoneLoading())
        callback(responseData);
      }
    })
    .catch((error) => {
      const responseData = {};

      if (error && error.response && error.response.data && error.response.data.errors) {
        const message_args = {
          duration: 2,
          message: error.response.data.errors && error.response.data.errors.rules && error.response.data.errors.rules[0] && error.response.data.errors.rules[0].message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onCompetitionRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === false) {
        const message_args = {
          duration: 2,
          message: error && error.response && error.response.data && error.response.data.message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onCompetitionRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === true) {
        const message_args = {
          duration: 2,
          message: _.get(error, 'response.data.message.message', 'Something went wrong please try again'),
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onCompetitionRequestActionSuccess(responseData));
      }
      // dispatch(disableMilestoneLoading())
      callback('error');
    });
};

export const EventRequestAction = (data, callback) => (dispatch) => {
  // dispatch(enableMilestoneLoading())
  axios({
    method: 'post',
    url: '/events/requestaction',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const responseData = {};
        responseData.status = response.status;
        responseData.data = response.data;
        const message_args = {
          duration: 2,
          message: 'Success',
          description: response.data.message,
        };
        notification.success(message_args);
        dispatch(onEventRequestActionSuccess(responseData));
        // dispatch(disableMilestoneLoading())
        callback(responseData);
      }
    })
    .catch((error) => {
      const responseData = {};

      if (error && error.response && error.response.data && error.response.data.errors) {
        const message_args = {
          duration: 2,
          message: error.response.data.errors && error.response.data.errors.rules && error.response.data.errors.rules[0] && error.response.data.errors.rules[0].message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onEventRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === false) {
        const message_args = {
          duration: 2,
          message: error && error.response && error.response.data && error.response.data.message,
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onEventRequestActionSuccess(responseData));
      } else if (error && error.response && error.response.data && _.isObject(error.response.data.message) === true) {
        const message_args = {
          duration: 2,
          message: _.get(error, 'response.data.message.message', 'Something went wrong please try again'),
        };
        notification.error(message_args);
        responseData.status = 'error';
        dispatch(onEventRequestActionSuccess(responseData));
      }
      // dispatch(disableMilestoneLoading())
      callback('error');
    });
};

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disablecompListLoading = () => ({
  type: actionTypes.disablecompListLoading,
  loadingcompList: { loadingcompList: false },
});
export const enablecompListLoading = () => ({
  type: actionTypes.enablecompListLoading,
  loadingcompList: { loadingcompList: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const CompetitionsStudentListLoading = (data) => ({
  type: actionTypes.CompetitionsStudentListLoading,
  success: data,
});

export const onCompetitionsStudents = (data) => ({
  type: actionTypes.competitionStudents,
  success: data,
});

export const onEventsStudents = (data) => ({
  type: actionTypes.eventStudents,
  success: data,
});

export const ProviderCompetitionsSuccess = (data) => ({
  type: actionTypes.providerCompetitions,
  payload: data,
});

export const OtherProviderCompetitionsSuccess = (data) => ({
  type: actionTypes.otherProviderCompetitions,
  payload: data,
});

export const classFoundSuccess = (data) => (dispatch) => {
  dispatch(resetPage());
  dispatch({
    type: actionTypes.tutorCompetitionDetailsFoundSuccess,
    success: {
      ...data,
      randomKey: Math.random()
        .toString(36)
        .substring(5)
        .toUpperCase(),
    },
  });
};

export const onGetCompetitionDetails = (requestParams) => (dispatch) => {
  dispatch(enableLoading());
  axios
    .get('/competitions/view', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(classFoundSuccess(response.data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
    });
};

export const getProviderCompetitions = (requestParams) => (dispatch) => {
  dispatch(enablecompListLoading());
  axios({
    method: 'get',
    url: '/providers/competitions',
    params: requestParams,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(ProviderCompetitionsSuccess(response.data));
        dispatch(disablecompListLoading());
      }
    })
    .catch((error) => {
      dispatch(disablecompListLoading());
    });
};

export const getOtherProviderCompetitions = (requestParams) => (dispatch) => {
  dispatch(enablecompListLoading());
  axios({
    method: 'get',
    url: '/providers/competitions',
    params: requestParams,
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(OtherProviderCompetitionsSuccess(response.data));
        dispatch(disablecompListLoading());
      }
    })
    .catch((error) => {
      dispatch(disablecompListLoading());
    });
};

export const getCompetitionStudents = (requestParams) => (dispatch) => {
  dispatch(CompetitionsStudentListLoading(true));
  axios
    .get('/competitions/students', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCompetitionsStudents(response.data));
        dispatch(CompetitionsStudentListLoading(false));
      }
    })
    .catch((error) => {
      dispatch(CompetitionsStudentListLoading(false));
    });
};

export const getEventStudents = (requestParams) => (dispatch) => {
  dispatch(CompetitionsStudentListLoading(true));
  axios
    .get('/events/students', {
      params: requestParams,
      withCredentials: true,
    })
    .then((response) => {
      if (response.status === 200) {
        dispatch(onCompetitionsStudents(response.data));
        dispatch(CompetitionsStudentListLoading(false));
      }
    })
    .catch((error) => {
      dispatch(CompetitionsStudentListLoading(false));
    });
};