// Update Ratings
import { notification } from 'antd';
import _ from 'lodash';
import axios from '../../../utils/axios';
import * as actionTypes from './actionTypes';

export const classRatingList = (data) =>
// console.log('datadatadata', data);
  ({
    type: actionTypes.onRatingListData,
    success: data,
  });

export const createRating = (data) =>
// console.log('datadatadata', data);
  ({
    type: actionTypes.onCreateRating,
    success: data,
  });

export const createReviews = (data) =>
// console.log('datadatadata', data);
  ({
    type: actionTypes.onCreateReview,
    success: data,
  });

export const classRatingListFull = (data) =>
// console.log('datadatadata', data);
  ({
    type: actionTypes.onRatingListFull,
    success: data,
  });

export const classRatingListForTutor = (data) =>
// console.log('datadatadata', data);
  ({
    type: actionTypes.onRatingListDataForTutor,
    success: data,
  });

export const updateRatings = (params, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ratings/view',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
      }
    })
    .catch((error) => {
      callback(error.response, 'error');
    });
};
// Create Ratings
export const createRatings = (params, callback = () => {}) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ratings/create',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(createRating(res.data));
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.success(message_args);
      }
      callback(res);
    })
    .catch((error) => {
      callback(error, 'error');
      const message_args = {
        duration: 3,
        message: 'Error occured please try again later!',
      };
      notification.error(message_args);
    });
};
// Create Reviews
export const createReview = (params, callback = () => {}) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ratings/create',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(createReviews(res.data));
        const message_args = {
          duration: 3,
          message: res.data.message,
        };
        notification.success(message_args);
      }
    })
    .catch((error) => {
      callback(error, 'error');
      const message_args = {
        duration: 3,
        message: 'Error occured please try again later!',
      };
      notification.error(message_args);
    });
};

// List Ratings
export const listRatings = (params, callback = () => {}) => (dispatch) => {
  axios({
    method: 'get',
    url: '/ratings/list',
    withCredentials: true,
    params,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(classRatingList(response.data));
      }
    })
    .catch((error) => {
      callback(error, 'error');
    });
};

export const listRatingsViewDetails = (params, callback) => (dispatch) => {
  axios({
    method: 'get',
    url: '/ratings/list',
    withCredentials: true,
    params,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(classRatingListFull(res.data));
      }
    })
    .catch((error) => {
      console.log(error, 'error');
    });
};

export const tutorListRatings = (params, callback = () => {}) => (dispatch) => {
  axios({
    method: 'get',
    url: '/ratings/list',
    withCredentials: true,
    params,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(classRatingListForTutor(response.data));
      }
    })
    .catch((error) => {
      callback(error, 'error');
    });
};

// delete Ratings
export const deleteRatings = (params, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/ratings/delete',
    withCredentials: true,
    data: params, // contains data to update the class
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        callback(res.data, 'success');
      }
    })
    .catch((error) => {
      callback(error.response, 'error');
    });
};

export const viewClass = (requestParams, callback) => {
  const modifiedParams = { ...requestParams };
  return (dispatch) => {
    axios
      .get('/classes/view', {
        params: { ...modifiedParams },
        withCredentials: true,
      })
      .then((res) => {
        if (res.status === 200) {
          callback(_.get(res, 'data.details'), 'success');
        }
      })
      .catch((error) => {
        callback(error.response, 'error');
      });
  };
};
