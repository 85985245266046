import React, { useEffect, useState } from 'react'
import {
  Form,
  Typography,
  Slider,
  Spin,
  Select,
  Checkbox,
  Button,
  Radio,
  DatePicker,
  Space,
} from 'antd'
import './sidebar.css'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import Geocode from 'react-geocode'
import { geocodeByAddress } from 'react-places-autocomplete'
import { globalVariable } from '../../../utils/config/config'
import ScrollToTop from '../../../components/re-usable/ScrollToTop'
import * as searchActionType from '../../../store/actions/public/search/actionTypes'
import * as searchAction from '../../../store/actions/public/search'
import age_groups_values from '../../../utils/data/age_groups_values'
import * as common from '../../../utils/common'
import variant from '../../../utils/data/variant'
import teaching_mode from '../../../utils/data/teaching_mode'
import teaching_type from '../../../utils/data/teaching_type'
import optionsPreferredDays from '../../../utils/data/weekdays'
import _ from 'lodash'

const { Text } = Typography
const size = 'large'

const { RangePicker } = DatePicker
const { Option } = Select

const findzipcodeLocationLocality = (addressComponentsArray, levelArray) => {
  for (let i = 0; i < addressComponentsArray.length; ++i) {
    for (let j = 0; j < levelArray.length; j++) {
      if (addressComponentsArray[i].types.indexOf('postal_code') > -1) {
        return {
          locality_level: levelArray[j],
          value: addressComponentsArray[i].long_name,
          postal_code: addressComponentsArray[i].long_name,
        }
      }
      if (addressComponentsArray[i].types.indexOf(levelArray[j]) > -1) {
        return {
          locality_level: levelArray[j],
          postal_code: '',
        }
      }
    }
  }
}

const PageSidebar = function (props) {
  const [form] = Form.useForm()

  const [getVariant, setVariant] = useState('')

  const pathArr = String(_.get(window.location, 'pathname', '')).split('/')

  const pathname =
    Array.isArray(pathArr) && _.last(pathArr).replace(/%20/g, ' ')

  console.log('searchQuery>>side bar', pathname)

  const cityNameOnSearch = (value) => {
    geocodeByAddress(value)
      .then((body) => {
        const zipcodes = findzipcodeLocationLocality(
          body[0].address_components,
          [
            'sublocality_level_2',
            'sublocality_level_1',
            'sublocality',
            'locality',
          ]
        ).postal_code

        body = [...body]

        // console.log("dara >> body", body)

        const location_search_data = body.map((location) => ({
          text: location.formatted_address,
          value: JSON.stringify({
            latitude: location.geometry.location.lat(),
            longitude: location.geometry.location.lng(),
            location_text: location.formatted_address,
            zipcodes,
          }),
        }))

        props.setLocation_search_data(location_search_data)
      })
      .catch((error) => props.setLocation_search_data([]))
  }

  useEffect(() => {
    Geocode.setApiKey(globalVariable.GOOGLE_API_KEY)
  }, [])

  function makeFilterRequest() {
    const rp = {
      serviceName: pathname,
      ...props.requestParams,
      ...props.requestParamsMore,
    }

    console.log('props.rp=======', rp)
    props.onGetClasseCampList(rp)
    props.onGetClasseCampList_sponsored(rp)
    // props.onGetClasseCampList_dirListing(rp);
    props.setHeaderSearch(false)
  }
  function resetandMakeRequest() {
    props.setHeaderSearch(true)
    form.resetFields()
    // make header request
    setTimeout(() => {
      const rp = {
        serviceName: pathname,
        ...props.requestParams,
      }

      props.onGetClasseCampList(rp)
      props.onGetClasseCampList_sponsored(rp)
      props.setLocation_search_data([])
      props.setPreferredPriceRange('')
      props.setPreferredTime('')
      props.setVariant('')

      props.setPreferredDate('')
      props.setPreferredDays('')
      props.setAgeGroup('')
    }, 500)
  }
  const isFormTouched = form.isFieldsTouched()
  return (
    <div className="full-width">
      {/* Search Side Bar Start */}
      <div className="sidebar-container">
        <ScrollToTop />
        <div className="row" style={{ padding: 10 }}>
          <Form form={form} name="sidebarform" className="overflow-hidden">
            <div className="side-bar-search">
              <div className="showing-results-section">
                {/* <div className="filter-group-title mb-1">Showing results for {props.searchType}</div> */}
                {/* <div className="full-width mt-3">
									<span className="filter-group-title mr-1">Around</span>
									<div className="full-width">
										<Form.Item name="cityNameFormItem">
											<Select
												showSearch
												getPopupContainer={trigger => trigger.parentNode}
												style={{ width: '100%' }}
												className={"global-search"}
												size={size}
												placeholder="zipcode or city"
												filterOption={false}
												allowClear={true}
												onClear={() => {
													props.setSearchLocation(props.searchLocation)
												}}
												onSearch={(e) => e ? cityNameOnSearch(e) : ''}
												onSelect={value => {
													try {
														console.log("data > value", JSON.parse(value))
														console.log("data > searchLocation", props.searchLocation)

														let newobj = {
															...props.searchLocation,
															...JSON.parse(value)
														}
														console.log("data > newobj", newobj)
														props.setSearchLocation(newobj)
													} catch (error) {
														console.log(error)
													}
												}}
												notFoundContent={props.fetching ? <Spin size={size} /> : null}
												defaultOpen={false}
											>
												{props.location_search_data && props.location_search_data.length > 0 && props.location_search_data.map(d => (
													<Option key={d.value}>{d.text}</Option>
												))}
											</Select>
										</Form.Item>
									</div>
								</div> */}
                <div className="full-width mt-3">
                  <div className="filter-group-title mb-3">Mode of class</div>
                  <div className="full-width">
                    <Form.Item name="teachingModFormItem">
                      <Radio.Group
                        size={size}
                        name="teachingMod"
                        onChange={(ev) => props.setTeachingMod(ev.target.value)}
                      >
                        <Space direction="vertical">
                          <Radio key="all" value="all">
                            All
                          </Radio>
                          {teaching_mode.map((v, i) => (
                            <Radio key={v.value} value={v.value}>
                              {v.label}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>

                <div className="full-width mt-3">
                  <div className="filter-group-title mb-3">Type of class</div>
                  <div className="full-width">
                    <Form.Item name="typeOfClassFormItem">
                      <Radio.Group
                        size={size}
                        name="typeOfClass"
                        onChange={(eve) =>
                          props.setTypeOfClass(eve.target.value)
                        }
                      >
                        <Space direction="vertical">
                          <Radio key="all" value="all">
                            All
                          </Radio>
                          {teaching_type.map((v, i) => (
                            <Radio key={v.value} value={v.value}>
                              {v.label}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>

                <div
                  className="full-width mt-2 text-right"
                  style={{ cursor: 'pointer' }}
                  onClick={() => props.setShowMore(!props.showMore)}
                >
                  <Text type="success">
                    {props.showMore ? 'Show Less' : 'Show More'}
                  </Text>
                </div>

                {props.showMore && (
                  <div className="full-width mt-2">
                    <div className="filter-group-title mb-3">Age Group</div>
                    <div className="full-width">
                      <Form.Item name="ageGroupFormItem">
                        <Checkbox.Group
                          size={size}
                          name="ageGroup"
                          onChange={(val) => {
                            props.setAgeGroup(val)
                          }}
                        >
                          <Space direction="vertical">
                            {age_groups_values.map((val) => (
                              <Checkbox
                                key={`ag${val.value}`}
                                value={val.value}
                              >
                                {val.label}
                              </Checkbox>
                            ))}
                          </Space>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                )}

                {props.showMore && (
                  <div className="full-width mt-3">
                    <div className="filter-group-title mb-3">
                      Miles from home
                      <span className="badge badge-blue float-right">
                        {props.requestParams.milesFromHome
                          ? props.requestParams.milesFromHome
                          : '40'}{' '}
                        mi
                      </span>
                    </div>
                    <div className="full-width">
                      <div className="row justify-content-between">
                        <div className="col-4 miles-text">0 mi</div>
                        <div className="col-4 miles-text">100 mi</div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Form.Item name="milesFromHomeFormItem">
                            <Slider
                              name="milesFromHome"
                              onChange={(value) =>
                                props.setMilesFromHome(value)
                              }
                              defaultValue={40}
                              min={1}
                              max={100}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {props.showMore && (
                  <div className="full-width mt-3">
                    <div className="filter-group-title mb-3">
                      Preferred Days
                    </div>
                    <div className="full-width">
                      <Form.Item name="variant">
                        <Radio.Group
                          size={size}
                          onChange={(e) => {
                            setVariant(e.target.value)
                            props.setVariant(e.target.value)

                            if (e.target.value !== 'o') {
                              props.setPreferredDays('')
                            }
                          }}
                        >
                          <Space direction="vertical">
                            {variant.map((val) => (
                              <Radio key={`day${val.value}`} value={val.value}>
                                {val.label}
                              </Radio>
                            ))}
                          </Space>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                )}

                {getVariant === 'o' && props.showMore && (
                  <div className="full-width mt-3">
                    <div className="filter-group-title mb-3">Preferred For</div>
                    <div className="full-width">
                      <Form.Item name="preferredDaysFormItem">
                        <Checkbox.Group
                          size={size}
                          name="preferredDays"
                          onChange={(e) => {
                            props.setPreferredDays(e)
                          }}
                        >
                          <Space direction="vertical">
                            {optionsPreferredDays.map((val) => (
                              <Checkbox
                                key={`day${val.value}`}
                                value={val.value}
                              >
                                {val.label}
                              </Checkbox>
                            ))}
                          </Space>
                        </Checkbox.Group>
                      </Form.Item>
                    </div>
                  </div>
                )}

                {props.showMore && (
                  <div className="full-width mt-3">
                    <div className="full-width">
                      <div className="filter-group-title mb-3">
                        Preferred dates
                      </div>
                      <div className="full-width">
                        <Form.Item name="RangePickerFormItem">
                          <RangePicker
                            onChange={(date, dateString) =>
                              props.setPreferredDate(dateString)
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}

                {/* {props.showMore && <div className="full-width mt-3">
									<div className="filter-group-title mb-2">Preferred Time <span className="badge badge-blue float-right"></span></div>
									<div className="full-width">
										<div className="row justify-content-between">
											<div className="col-4 miles-text">01 AM</div>
											<div className="col-4 miles-text">11 PM </div>
										</div>
										<div className="row">
											<div className="col-12">
												<Form.Item name="PreferredTimeFormItem"><Slider range
													onChange={(value) => {
														console.log("setPreferredTime", value)
														props.setPreferredTime(value)
													}}
													min={1}
													max={23}
												/></Form.Item>
											</div>
										</div>
									</div>
								</div>} */}

                {props.showMore && (
                  <div className="full-width mt-3">
                    <div className="filter-group-title mb-3">
                      Price for
                      {props.searchType ? props.searchType : 'class'}{' '}
                      <span className="badge badge-blue float-right" />
                    </div>
                    <div className="full-width">
                      <div className="row justify-content-between">
                        <div className="col-4 miles-text" title="Free">
                          $0
                        </div>
                        <div className="col-4 miles-text" title="Paid">
                          $999
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <Form.Item name="PriceFormItem">
                            <Slider
                              range
                              min={0}
                              max={999}
                              onChange={(value) =>
                                props.setPreferredPriceRange(value)
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="full-width">
                {/* More Filter results */}

                <div className=" mt-3 mb-4">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '5px',
                    }}
                  >
                    <Button
                      style={{
                        width: '45%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      disabled={!isFormTouched}
                      onClick={() => makeFilterRequest()}
                      type="primary"
                      shape="round"
                    >
                      Filter
                    </Button>
                    <Button
                      style={{
                        width: '45%',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      disabled={!isFormTouched}
                      onClick={() => resetandMakeRequest()}
                      danger
                      type="primary"
                      shape="round"
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
        {/* Search Side Bar End */}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log('state.search', state.search)

  return {
    searchLocation: state.search.searchLocation,
    serviceID: state.search.serviceID,
    headerSearch: state.search.headerSearch,
    requestParamsMore: common.requestParamsMore(state.search),
    requestParams: common.reqFromHeader(state.search),
    showMore: state.search.showMoreSearchUI,
    searchType: state.search.searchType,
    location_search_data: state.search.location_search_data,
    fetching: state.search.location_search_data_fetching,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setHeaderSearch: (statusParams) =>
    dispatch(searchAction.common(searchActionType.headerSearch, statusParams)),
  setShowMore: (moreParams) =>
    dispatch(
      searchAction.common(searchActionType.showMoreSearchUI, moreParams)
    ),
  setSearchZipCode: (zipParams) =>
    dispatch(searchAction.common(searchActionType.searchZipCode, zipParams)),
  setSearchLocation: (LocParams) =>
    dispatch(searchAction.common(searchActionType.searchLocation, LocParams)),
  setAgeGroup: (ageGroupParams) =>
    dispatch(searchAction.common(searchActionType.ageGroup, ageGroupParams)),
  setTeachingMod: (teachingModParams) =>
    dispatch(
      searchAction.common(searchActionType.teachingMod, teachingModParams)
    ),
  setTypeOfClass: (typeOfClassParams) =>
    dispatch(
      searchAction.common(searchActionType.typeOfClass, typeOfClassParams)
    ),
  setMilesFromHome: (milesFromHomeParams) =>
    dispatch(
      searchAction.common(searchActionType.milesFromHome, milesFromHomeParams)
    ),
  setPreferredDays: (preferredDaysParams) =>
    dispatch(
      searchAction.common(searchActionType.preferredDays, preferredDaysParams)
    ),
  setPreferredDate: (preferredDateParams) =>
    dispatch(
      searchAction.common(searchActionType.preferredDate, preferredDateParams)
    ),
  setVariant: (variantParams) =>
    dispatch(searchAction.common(searchActionType.variant, variantParams)),

  setPreferredTime: (ptParams) =>
    dispatch(searchAction.common(searchActionType.preferredTime, ptParams)),
  setPreferredPriceRange: (preferredPriceRangeParams) =>
    dispatch(
      searchAction.common(
        searchActionType.preferredPriceRange,
        preferredPriceRangeParams
      )
    ),
  setLocation_search_data: (loc_data) =>
    dispatch(
      searchAction.common(searchActionType.location_search_data, loc_data)
    ),
  onGetClasseCampList: (rpGeneral) =>
    dispatch(searchAction.onGetClasseCampList(rpGeneral)),
  onGetClasseCampList_sponsored: (rpSponsored) =>
    dispatch(
      searchAction.onGetClasseCampList(
        rpSponsored,
        searchActionType.sponoredList
      )
    ),
  onGetClasseCampList_dirListing: (rpDirlist) =>
    dispatch(
      searchAction.onGetClasseCampList(rpDirlist, searchActionType.dirList)
    ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(PageSidebar))
