import { combineReducers } from 'redux';
import authentication from './authentication/authentication';
import services from './public/Services';
import tutorBaseProfile from './tutor';
import userBaseProfile from './users';
import tutorPublicProfile from './public/tutor-public-profile';
import classes from './public/Classes';
import camps from './public/Camps';
import classesDetails from './tutor/Classes';
import campsDetails from './tutor/camps';
import addClass from './tutor/add-class';
import addCamp from './tutor/add-camp';
import attendances from './tutor/attendances';
import configurations from './tutor/configurations';
import general from '../general';
import rating from './public/ratings';
import message from './public/message';
import payments from './tutor/payments';
import search from './public/search';
import sessions from './public/sessions';
import common from './tutor/common';
import guide from './common/tour-guide';
import competitionsDetails from './tutor/competitions';
import eventsDetails from './tutor/events';
import uploads from './common/uploads';
import apiCall from './common/api-call';

export default combineReducers({
  apiCall,
  eventsDetails,
  competitionsDetails,
  authentication,
  services,
  tutorProfile: tutorBaseProfile,
  tutor: tutorBaseProfile,
  userProfile: userBaseProfile,
  classes,
  classesDetails,
  tutorPublicProfile,
  addClass,
  addCamp,
  users: userBaseProfile,
  attendances,
  configurations,
  campsDetails,
  camps,
  general,
  rating,
  message,
  payments,
  search,
  sessions,
  common,
  guide,
  uploads,
});
