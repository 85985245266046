import * as SecureLS from 'secure-ls'
import * as actionTypes from './actionTypes'
import axios from '../../../utils/axios'

export const enableContentLoading = () => ({
  type: actionTypes.enableContentLoading,
  loading: { contentLoading: true },
})
export const enableCartContentLoading = () => ({
  type: actionTypes.enableCartContentLoading,
  loading: true,
})
export const disableCartContentLoading = () => ({
  type: actionTypes.disableCartContentLoading,
  loading: false,
})
export const disableContentLoading = () => ({
  type: actionTypes.disableContentLoading,
  loading: { contentLoading: false },
})

export const enableFilterLoading = () => ({
  type: actionTypes.enableFilterLoading,
  loading: { filterLoading: true },
})

export const disableFilterLoading = () => ({
  type: actionTypes.disableFilterLoading,
  loading: { filterLoading: false },
})

export const classStudioFoundSuccess = (data) => ({
  type: actionTypes.classStudioFoundSuccess,
  success: data,
})

export const classDriveFoundSuccess = (data) => ({
  type: actionTypes.classDriveFoundSuccess,
  success: data,
})

export const classOnlineFoundSuccess = (data) => ({
  type: actionTypes.classOnlineFoundSuccess,
  success: data,
})

export const onGetClasses = (requestParams, classData, classInfo) => {
  const modifiedParams = { ...requestParams }
  delete modifiedParams.filterStatus
  return (dispatch) => {
    classData = classData || {}
    if (
      (Object.keys(classData).length === 0 &&
        typeof classData.items === 'undefined') ||
      (Object.keys(classData.items).length === 0 &&
        (!classInfo.paginationStatus || !classInfo.sortStatus))
    ) {
      dispatch(enableContentLoading())
      onGetClassRequest(dispatch, modifiedParams, requestParams, classInfo)
    }
    if (
      Object.keys(classData).length >= 0 &&
      classData.items &&
      Object.keys(classData.items).length >= 0 &&
      (classInfo.paginationStatus ||
        classInfo.sortStatus ||
        classInfo.filterStatus ||
        classInfo.emptyData)
    ) {
      if (requestParams.teaching_mode === 'studio') {
        dispatch(classStudioFoundSuccess(null))
      } else if (requestParams.teaching_mode === 'drive') {
        dispatch(classDriveFoundSuccess(null))
      } else if (requestParams.teaching_mode === 'online') {
        dispatch(classOnlineFoundSuccess(null))
      }
      if (classInfo.filterStatus) {
        dispatch(enableFilterLoading())
      }
      dispatch(enableContentLoading())
      onGetClassRequest(dispatch, modifiedParams, requestParams, classInfo)
    }
  }
}

const onGetClassRequest = (
  dispatch,
  modifiedParams,
  requestParams,
  classInfo
) =>
  axios
    .get('/classes/listgroup', { params: modifiedParams })
    .then((response) => {
      if (response.status === 200) {
        if (requestParams.teaching_mode === 'studio') {
          dispatch(classStudioFoundSuccess(response.data))
        } else if (requestParams.teaching_mode === 'drive') {
          dispatch(classDriveFoundSuccess(response.data))
        } else if (requestParams.teaching_mode === 'online') {
          dispatch(classOnlineFoundSuccess(response.data))
        }
        if (classInfo.filterStatus) {
          dispatch(disableFilterLoading())
          dispatch(disableContentLoading())
        } else {
          dispatch(disableContentLoading())
        }
      }
    })
    .catch((error) => {
      if (classInfo.filterStatus) {
        dispatch(disableFilterLoading())
        dispatch(disableContentLoading())
      } else {
        dispatch(disableContentLoading())
      }
    })

export const onGetClassesTutorView = (data) => (dispatch) => {
  axios
    .get('/classes/listgroup', { params: data })
    .then((response) => {
      if (response.status === 200) {
        dispatch(getClassesSuccess(response.data))
      }
    })
    .catch((error) => {})
}
const capitalizeWord = (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}
export const onGetClassesList = (requestParams = {}, type) => {
  console.log('type>> requestParams>>>>>', type, requestParams)
  if (requestParams.start_hour) {
    requestParams.start_minute = '00'
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00'
  }
  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query)
  }

  requestParams.limit = 5
  return (dispatch) => {
    dispatch(onClassesListLoading(true))
    axios
      .get('/classes/listgroup', { params: requestParams })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onClassesListLoading(false))
          dispatch(getClassesListSuccess(response.data))
        }
      })
      .catch((error) => {
        dispatch(onClassesListLoading(false))
      })
  }
}

export const onGetClassesSponsoredList = (requestParams = {}) => {
  if (requestParams.start_hour) {
    requestParams.start_minute = '00'
  } else if (requestParams.end_hour) {
    requestParams.end_minute = '00'
  }

  if (requestParams && requestParams.service_query) {
    requestParams.service_query = capitalizeWord(requestParams.service_query)
  }
  requestParams.limit = 3
  requestParams.is_sponsored = 1
  requestParams.sort = 'is_premium.desc'
  requestParams.expand = 'provider,services'

  return (dispatch) => {
    dispatch(onClassesListLoading(true))
    axios
      .get('/getSponsoredclasses', { params: requestParams })
      .then((response) => {
        if (response.status === 200) {
          dispatch(onClassesListLoading(false))
          dispatch(getClassesSponoredListSuccess(response.data))
        }
      })
      .catch((error) => {
        dispatch(onClassesListLoading(false))
      })
  }
}

export const getClassesSuccess = (data) => ({
  type: actionTypes.onGetClassesTutorView,
  success: data,
})
export const onClassesListLoading = (data) => ({
  type: actionTypes.onClassesListLoading,
  success: data,
})

export const getClassesListSuccess = (data) => ({
  type: actionTypes.getClassesListSuccess,
  success: data,
})
export const getClassesSponoredListSuccess = (data) => ({
  type: actionTypes.getClassesSponoredListSuccess,
  success: data,
})
export const itemAdded = (data) => ({
  type: actionTypes.cartItemAdded,
  success: data,
})

export const cartItemAdd = () => (dispatch) => {
  setTimeout(() => {
    const ls = new SecureLS()
    const itemsExist = ls.get('cartItems')
    if (itemsExist) {
      dispatch(itemAdded(Object.keys(itemsExist)))
    }
  }, 3000)
}

export const cartItemFound = (data) => ({
  type: actionTypes.cartItemDetails,
  success: data,
})

export const cartItemsDeatils = (data) => ({
  type: actionTypes.cartItemsDeatils,
  success: data,
})

export const getCartItems = (data) => {
  console.log('data cart', data)
  return (dispatch) => {
    dispatch(enableCartContentLoading())
    axios
      .get('/classes/cartlist', {
        params: data,
      })
      .then((response) => {
        console.log('getCartItems', data, response)
        if (response.status === 200) {
          dispatch(cartItemFound(response.data))
          dispatch(cartItemsDeatils(response.data))
          dispatch(disableCartContentLoading())
        }
      })
      .catch((error) => {})
  }
}
/* Subscriptions */
export const customerDetails = (data) => ({
  type: actionTypes.customerDetails,
  success: data,
})
export const customerDetailsCompleted = () => ({
  type: actionTypes.customerDetailsCompleted,
  customerDetailsCompleted: { customerDetailsCompleted: true },
})
export const newCustomers = (data, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/stripe/customers',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      console.log('res', res)
      dispatch(customerDetails(res.data))
      dispatch(customerDetailsCompleted())
      callback(res)
    })
    .catch((error) => {
      dispatch(customerDetailsCompleted())
    })
}

export const stripe_payment_intent = (data, callback) => (dispatch) => {
  axios({
    method: 'post',
    url: '/stripe/create-payment-intent',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      callback(res.data)
    })
    .catch((error) => {
      console.log(
        'error> at /stripe/create-payment-intent (frontendsrcstoreactionspublicClasses.js)',
        error
      )
    })
}
