/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  AiFillTwitterCircle, AiFillYoutube, AiOutlineInstagram, AiFillFacebook, AiOutlineWhatsApp,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import './footer.css';
import { connect } from 'react-redux';
import * as apicall from '../../../store/actions/common/api-call';
import * as common from '../../../store/actions/common/actionTypes';
import { globalVariable } from '../../../utils/config/config';

class PageFooter extends React.Component {
  async componentDidMount() {
    this.props.ipApiCall();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="contact-number">
            <a className="social-media-instagram" href="https://www.instagram.com/nurtem_tutoring/" target="_blank" rel="noopener noreferrer">
              <AiOutlineInstagram className="social-media" />
            </a>
            <a className="social-media-facebook" href="https://www.facebook.com/NurtemTutoring" target="_blank" rel="noopener noreferrer">
              <AiFillFacebook className="social-media" />
            </a>
            <a className="social-media-youtube" href="https://www.youtube.com/channel/UCCBh-BrzjeAAC8hDdDpuB6w" target="_blank" rel="noopener noreferrer">
              <AiFillYoutube className="social-media" />
            </a>
            <a className="social-media-twitter" href="https://twitter.com/NurtemInc" target="_blank" rel="noopener noreferrer">
              <AiFillTwitterCircle className="social-media" />
            </a>
            <AiOutlineWhatsApp className="social-media-whatsapp" />
            <span className="social-media-text"> +1 408 365 4708</span>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="row">
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://nurtem.com/classes-and-camps/">{this.props.t('class&camps')}</a></li>
                  <li><Link to="/sign-up">{this.props.t('studentSignup')}</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://nurtem.com/">{this.props.t('nurtem')}</a></li>
                  <li><a href="https://test.nurtem.com/">{this.props.t('testNurtem')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="row">
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://www.nurtem.com/cookies/">{this.props.t('cookiePolicy')}</a></li>
                  <li><Link to="/sign-up">{this.props.t('tutorSignup')}</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://nurtem.com/about-nurtem/">{this.props.t('aboutUs')}</a></li>
                  <li><a href="https://course.nurtem.com/forums">{this.props.t('courseForums')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="row">
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://course.nurtem.com/bundles">{this.props.t('courseBundles')}</a></li>
                  <li><a href="https://www.nurtem.com/privacy-policy/">{this.props.t('privacyPolicy')}</a></li>
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://nurtem.com/tutors/">{this.props.t('joinAsTutor')}</a></li>
                  <li><a href="https://nurtem.com/blogs/">{this.props.t('blogsNurtem')}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div className="row">
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li><a href="https://course.nurtem.com/">{this.props.t('courseNurtem')}</a></li>
                  <li><a href="https://nurtem.com/contact-nurtem/">{this.props.t('contactUs')}</a></li>
                  {/* <li><a href={'https://course.nurtem.com/bundles'}>{"Course bundles"}</a></li> */}
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="footer-links">
                <ul>
                  <li>
                    <a href="https://www.nurtem.com/terms-and-conditions/">
                      {this.props.t('terms&conditions')}
                      {' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = (dispatch) => ({
  ipApiCall: () => dispatch(apicall.getApiCall(common.ipapi, globalVariable.IPAPI_URL)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PageFooter));
