import React, { useState, useEffect } from 'react';
import { Button, Space, Table } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { AiOutlineEdit} from 'react-icons/ai';
import { FaPlus } from "react-icons/fa6";
import { withTranslation } from 'react-i18next';
import generalAction from '../../../store/general/action';
import '../style.css';
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate';
import queryString from 'query-string';
import * as sessionActions from '../../../store/actions/public/sessions';
import LabelConversion from '../../../components/re-usable/label-convertion';
import * as routeName from '../../../components/route-names';
import Linkwrap from '../component/linkwrap';
import _ from 'lodash';
import css from '../../../utils/CSSUtility'


const SessionList = (props) => {
  const urlparams = queryString.parse(window.location.search);
  const gid = _.get(urlparams, 'gid');
  const from = _.get(urlparams, 'tutor', null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const getSessionList = (id) => {
    if (id) {
      const requestParams = {};
      requestParams.group_id = id;
      requestParams.sort = 'created_at.desc';
      props.getSessionList(requestParams, 'classes');
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getSessionList(gid);
  }, []);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  let formattedSessions = [];
  try {
    const sessions = props.sessions.hasOwnProperty(props.groupID) ? props.sessions[props.groupID] : [];
    formattedSessions = sessions.map((session) => {
      const formattedCode = session.code;

      return {
        ...session,
        session_name: (<a style={{ color: '#1890ff' }} onClick={() => { props.callback(session); }}>{session.session_name}</a>),
        session_type: LabelConversion(session.billing_type, 'billing-type'),
        formatted_start_date: timeDateConverterSession(session.start_date, 'date'),
        formatted_end_date: timeDateConverterSession(session.end_date, 'date'),
        formatted_link: <Linkwrap link={session.link} />,
        formatted_code: formattedCode,
        key: session.id, // Assigning a unique key for each session
      };
    });
  } catch (error) {
    console.log('error', error);
  }

  // Sort the formattedSessions array by created_at in descending order
  const sortedSessions = formattedSessions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));


  const ListingClasscolumns = [{
    title: props.t('batName'),
    dataIndex: 'session_name',
    key: 'session_name',
    width: '15%',
  }, {
    title: props.t('batType'),
    dataIndex: 'session_type',
    key: 'session_type',
    width: '15%',
  },
  {
    title: props.t('sDate'),
    dataIndex: 'formatted_start_date',
    key: 'start_date',
    width: '15%',
  },
  {
    title: props.t('eDate'),
    dataIndex: 'formatted_end_date',
    key: 'end_date',
    width: '15%',
  },
  {
    title: props.t('meetLink'),
    dataIndex: 'formatted_link',
    key: 'formatted_link',
    width: '15%',
  },
  {
    title: props.t('passcode'),
    dataIndex: 'formatted_code',
    key: 'formatted_code',
  },
  {
    title: props.t('Action'),
    key: 'action',
    render: (text, record) => (
      <Space size="middle">
        <div>
          <button
            type="button"
            onClick={() => {
              record.session_name = record.session_name.props.children;
              props.callback(record);
            }}
            className="btn btn-accept  mr-1"
          >
            {props.t('details')}
          </button>
        </div>

        <div>
          {
            from
              ? (
                <Link to={`${routeName.TutorUpdateClass}/${props.className}?id=${record.group_id}&sid=${record.id}&type=update&scroll=true&tutor=${from}`}>
                  <AiOutlineEdit className="faq-buttons-edit" style={{ paddingTop: 5 }} />
                </Link>
              )
              : (
                <Link to={`${routeName.TutorUpdateClass}/${props.className}?id=${record.group_id}&sid=${record.id}&type=update&scroll=true`}>
                  <AiOutlineEdit className="faq-buttons-edit" style={{ paddingTop: 5 }} />
                </Link>
              )
          }
        </div>
      </Space>
    ),
  },
  ];

  const render = () => (
    <>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Link to={`${routeName.TutorUpdateClass}/${props.className}?id=${gid}&type=update&sid=new`}>
          <Button icon={<FaPlus style={{ fontSize: 18 }} />} style={css.button}>Add Batch</Button>
        </Link>
      </div>
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{ marginTop: '15px' }}>
        <div className="nurtem-table" style={{ overflowX: 'auto' }}>
          {<Table
            columns={ListingClasscolumns}
            dataSource={sortedSessions}
            pagination={{
              current: currentPage,
              pageSize,
              total: props.sessions.hasOwnProperty(props.groupID) ? props.sessions[props.groupID].length : 0,
              // showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            onChange={handleTableChange}
          />}
        </div>
      </div>
    </>
  );
  return render();
};

const mapStateToProps = (state) => ({
  loading: _.get(state, ['sessions', 'loading'], false),
  sessions: _.get(state, ['sessions', 'sessions'], {}),
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  tutorTransfer: (data) => dispatch(generalAction.tutor.providerTransfer(data)),
  getSessionList: (requestParams, type) => dispatch(sessionActions.getSessionList(requestParams, type)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(SessionList));
