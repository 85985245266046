import _ from 'lodash';
import axios from '../../../utils/axios';
import * as actionTypes from './actionTypes';

import { notifyAPIResponse } from '../../utility';

export const getStudentList = () => (dispatch) => {
  dispatch(student_list_loaded(false));
  axios({
    method: 'get',
    url: '/providers/get-student-list',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        console.log('res >>>> ', res);
        dispatch(student_list_loaded(true));
        dispatch(providerstudents(res.data.details));
      }
    })
    .catch((error) => {
    });
};

export const providerstudents = (data) => ({
  type: actionTypes.providerstudents,
  payload: data,
});

export const balanceAmount = (data) => ({
  type: actionTypes.balance_amount,
  payload: data,
});

export const reload = (data) => ({
  type: actionTypes.reload,
  payload: data,
});

export const student_list_loaded = (data) => ({
  type: actionTypes.student_list_loaded,
  payload: data,
});

export const on_receiving_student_payment_information = (data) => ({
  type: actionTypes.on_receiving_student_payment_information,
  payload: data,
});

export const on_receiving_payment_object = (data) => ({
  type: actionTypes.offline_payment_object,
  payload: data,
});

/**
 * data > {key: userid +|||+ dependentid, value: parent name + dependent name}
 */
export const get_student_payment_info = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/providers/student-payment-info',
    data,
    withCredentials: true,
  })
    .then((res) => {
      if (res.status === 200) {
        console.log('get_student_payment_info >> me  now', res.data.details);
        dispatch(on_receiving_student_payment_information({
          requestdata: data,
          responsedata: res.data.details,
        }));

        /**
                     * store payment object seperately and
                     * selected will be sent to server for transaction entry
                     */
        dispatch(on_receiving_payment_object(_.get(res.data.details, ['data'], [])));

        console.log("_.get(res.data.details, ['data'], [])", _.get(res.data.details, ['data'], []));

        // const balance = _.get(res.data.details, ['moredata', 'billAmount']);

        dispatch(balanceAmount(0));
        dispatch(reload(false));
      }
    })
    .catch((error) => {
    });
};

export const payOffline = (data) => (dispatch) => {
  axios({
    method: 'post',
    url: '/payments/pay-offline',
    data,
    withCredentials: true,
  })
    .then((res) => {
      if (res.status === 200) {
        notifyAPIResponse('success', res.data.message, 'Offline Payment');
        // clear payment data
        dispatch(on_receiving_student_payment_information({}));
        dispatch(on_receiving_payment_object([]));
        dispatch(balanceAmount(0));
        dispatch(reload(true));
      }
    })
    .catch((error) => {
    });
};
