import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import OnboardingSignup from './signup/signup';
import OnboardingLogin from './login/login';
import ResetPassword from './reset-password/reset-password';
import Reset_Password from './reset-password/password-verification';
import Account from './index.js';
import LandingNew from "./login/landing-new.js";
import '../../assets/fonts/nurtem/style.css';
import * as routeName from '../../components/route-names';

class LayoutContent extends React.Component {
  render() {
    return (
      <div style={{ width: '100%', float: 'left' }}>

        <Switch>
          <Redirect from="/" exact to={routeName.signIn} />
          <Route path={routeName.Landing} component={LandingNew} />
          <Route path={routeName.userSignUp} component={OnboardingSignup} />
          <Route path={routeName.signIn} component={OnboardingLogin} />
          <Route path={routeName.signIn} component={OnboardingLogin} />
          <Route path={routeName.resetPassword} component={ResetPassword} />
          <Route path={routeName.changePassword} component={Reset_Password} />
          <Route path="/askdkjsadhjadhjashdas" component={Account} />

        </Switch>

      </div>
    );
  }
}

export default LayoutContent;
