import React from 'react';
import SearchResultCard from './searchResultCard';
import SpeicalResultCard from './speicalResultCard';

import Paging from './paging';
import _ from 'lodash';
import * as searchActionType from '../../../../store/actions/public/search/actionTypes';

/**
 * @param title  get title of the component
 * @param list list to display
 */
const SearchComponent = function (props) {

  console.log("props>>>>SearchComponent",props)

  const itemList = _.get(props, ['list', 'items'], false);

  let itemListLength = 0;
  if (itemList) {
    itemListLength = itemList.length;
  }

  return itemListLength > 0 && (
    <div className="nurtem-list-section">
      <div className="full-width row">
        <div className="card-top-title">{props.title ? props.title : 'Search Result'}</div>
        {props.compID && itemList.map(
          (value, index) => (props.compID === searchActionType.sponoredList || props.compID === searchActionType.dirList) ? <SpeicalResultCard key={`SearchResultCard_${props.compID}${index}`} index={index} value={value} /> : <SearchResultCard key={`SearchResultCard_${props.compID}${index}`} index={index} value={value} />)}
        {props.compID && props.list && props.list.meta && <Paging pageCompID={props.compID} key={`paging_${props.compID}`} meta={props.list.meta} />}
      </div>
    </div>
  );
};

export default SearchComponent;
