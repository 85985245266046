import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import queryString from 'query-string';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../style.css';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import {
  AiOutlineInfoCircle,
  AiOutlineExclamationCircle,
} from 'react-icons/ai';
import { withTranslation } from 'react-i18next';
import generalAction from '../../../../store/general/action';
import generalActionTypes from '../../../../store/general/actionTypes';
import BaseProfileTab from './base-profile-tab';
import { CanSwitchTabModal } from './can-switch-tab-modal';

class TutorMyProfileTab extends React.Component {
  state = {
    custom_tabs: {
      tab_panes: {
        base_profile: {
          name: this.props.t('baseProfiletab'),
          key: '1',
          meta: { className: 'tab-pane fade' },
        },
      },
      activeTab: '-',
      isCurrentTabIsModified: false,
    },
    can_switch_modal_properties: (criteria) => ({
      title: 'Please Confirm',
      content: (
        <Tooltip
          placement="right"
          title={(
            <span>
              {' '}
              <span className="tooltip-icon">
                <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
              </span>
              {' '}
              <span className="tooltip-text">
                ie: This is just a remainder. You can always save your
                modified tab details later until you hold on in this page.
              </span>
            </span>
            )}
        >
          <label
            className="filter-group-title mb-3"
            style={{ width: 'auto' }}
          >
            You have unsaved changes. Do you really want to leave the
            {' '}
            {_.get(criteria, 'tab_details.name', '')}
            {' '}
            tab?
            {' '}
            <AiOutlineInfoCircle style={{ fontSize: '20px' }} />
          </label>
        </Tooltip>
      ),
    }),
    pastQueryParams: {},
    BusinessTutor: false,
  };

  tabReference = {};

  setCurrentTabModified = (value) => {
    if (
      !_.isEqual(_.get(this, 'state.custom_tabs.isCurrentTabIsModified'), value)
    ) {
      this.setState((previousState) => ({
        custom_tabs: _.merge(previousState.custom_tabs, {
          isCurrentTabIsModified: value,
        }),
      }));
    }
  };

  toggleTabByValue = (tab) => {
    const availableTabs = _.map(
      _.get(this, 'state.custom_tabs.tab_panes', {}),
      (tabPane) => _.get(tabPane, 'key', ''),
    );
    tab = _.includes(availableTabs, tab)
      ? tab
      : _.get(this, 'state.custom_tabs.tab_panes.base_profile.key', '');
    // if user clicks current tab we need to return
    if (_.isEqual(_.get(this, 'state.custom_tabs.activeTab', ''), tab)) {
      return;
    }
    if (_.isFunction(_.get(this, 'props.canSwitchTabModal.showModal'))) {
      // if (_.get(this, 'state.custom_tabs.isCurrentTabIsModified')) {

      //   this.props.canSwitchTabModal.showModal(this.state.can_switch_modal_properties({
      //     tab_details: _.find(
      //       _.get(this, 'state.custom_tabs.tab_panes', {}),
      //       item => _.isEqual(_.get(item, 'key', ""), _.get(this, 'state.custom_tabs.activeTab', ""))
      //     )
      //   }), (success) => {
      //     if (success) {
      //       this.setState(previousState => ({ custom_tabs: _.merge(previousState.custom_tabs, { isCurrentTabIsModified: false }) }));
      //       this.setTabPane(tab);
      //       // Remove the 'active' class from the current active tab
      //       const currentActiveTab = document.querySelector('.nav-link.active');
      //       currentActiveTab.classList.remove('active');

      //       // Add the 'active' class to the clicked tab
      //       const clickedTab = this.tabReference[tab];
      //       clickedTab.classList.add('active');
      //     } else {
      //       this.tabReference[_.get(this, 'state.custom_tabs.activeTab', "")].click();
      //     }
      //   });
      // } else {
      this.setTabPane(tab);
      // Remove the 'active' class from the current active tab
      const currentActiveTab = document.querySelector('.nav-link.active');
      currentActiveTab.classList.remove('active');

      // Add the 'active' class to the clicked tab
      const clickedTab = this.tabReference[tab];
      clickedTab.classList.add('active');
      if (_.isEqual('-', _.get(this, 'state.custom_tabs.activeTab', ''))) {
        // need to highlight the tab pans during component didmount
        this.tabReference[tab].click();
      }
      // }
    }
  };

  setTabPane = (key) => {
    const edit_tab_panes = _.get(this, 'state.custom_tabs.tab_panes', {});
    _.forEach(edit_tab_panes, (tab_pane) => {
      if (_.isEqual(key, _.get(tab_pane, 'key', ''))) {
        tab_pane.meta.className = 'tab-pane active';
      } else {
        tab_pane.meta.className = 'tab-pane fade';
      }
    });
    this.setState((previousState) => ({
      custom_tabs: _.merge(_.get(previousState, 'custom_tabs', {}), {
        tab_panes: edit_tab_panes,
        activeTab: key,
      }),
    }));
  };

  componentDidMount() {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    if (urlparams && urlparams.id) {
      this.setState({ BusinessTutor: true });
    }
    this.toggleTabByValue(
      _.get(queryString.parse(_.get(this, 'props.location.search')), 'tab'),
    );
  }

  contentTab = () => {
    const url = this.props.location.search;
    const urlparams = queryString.parse(url);
    const customTabPanes = _.get(this, 'state.custom_tabs.tab_panes');
    const tutorDetails = _.get(this, 'props.tutorBaseProfile.details', '');
    const inputProps = {
      setCurrentTabModified: this.setCurrentTabModified,
      isCurrentTabIsModified: _.get(
        this,
        'state.custom_tabs.isCurrentTabIsModified',
        false,
      ),
    };
    const userHandle = urlparams && urlparams.userhandle
      ? `/profile/${_.get(urlparams, 'userhandle', '')}`
      : _.get(this, 'props.tutorView.details.user_handle', null)
        ? `/profile/${_.get(this, 'props.tutorView.details.user_handle')}`
        : // : _.get(tutorDetails, "user_handle")
      // ? `/profile/${_.get(tutorDetails, "user_handle", "")}`
        null;

    return (
      <div
        className="tutor-base-profile-tab"
        style={{ display: 'flex', height: '100%' }}
      >
        <ul
          className="nav nav-tabs tutorProfileList"
          role="tablist"
          style={{
            flex: '0 0 16%',
            flexDirection: 'column',
            backgroundColor: '#F9F9F9',
          }}
        >
          {userHandle && (
          <Link
            to={{
              pathname: userHandle,
              state: { data: 'Hello from FirstPage' },
            }}
            target="_blank"
          >
            <li className="nav-item">
              <div style={{ padding: '10px 0px 10px 10px ' }}>
                {this.props.t('viewProfile')}
              </div>
            </li>
          </Link>
          )}

          <li className="nav-item">
            <div
              style={{ padding: '10px 0px 10px 10px ' }}
              onClick={() => this.toggleTabByValue(
                _.get(customTabPanes, 'base_profile.key', ''),
              )}
              ref={(input) => (this.tabReference[
                _.get(customTabPanes, 'base_profile.key', '')
              ] = input)}
              role="tab"
              aria-selected="true"
              className="ant-tabs-tab-btn nav-link active"
              data-toggle="tab"
            >
              {_.get(customTabPanes, 'base_profile.name', '')}
            </div>
          </li>
        </ul>

        <div
          className="tab-content"
          style={{ flex: '0 0 82.5%', margin: 10, marginLeft: 15 }}
        >
          <div
            id="Base_Profile"
            {..._.get(customTabPanes, 'base_profile.meta', {})}
          >
            {/* Declaring base profile tab */}
            <BaseProfileTab {...this.props} {...inputProps} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={`col-12 ${this.state.BusinessTutor ? '' : 'col-lg-12'}`}>
        <div
          className="nurtem-card-design my-profile-tab tutor-onboarding-right "
          style={{ padding: 0 }}
        >
          {/* Declaring Content tab */}
          {this.contentTab()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tutorView: _.get(state, 'general.tutor.view', {}),
  tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}),
});
const mapDispatchToProps = (dispatch) => ({
  getTutorProfile: (details) => dispatch(generalAction.tutor.getProfile(details)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(CanSwitchTabModal(TutorMyProfileTab)));
