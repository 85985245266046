import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Search from './search-class-camps/search';
import UserVerify from './user-verify/index';
import TutorPublicProfile from './tutor-profile/index';
import TermsAndConditions from './terms-and-conditions/index';
import PrivacyPolicy from './privacy-policy/index';
import CookiePolicy from './cookie-policy/index';
import UserCart from '../user/user-cart';
import GuestFlowScreen from '../user/guest-flow/guest-flow';
import * as routeName from '../../components/route-names';
import ViewClassDetails from './view-class-details';
import ViewCampsDetails from './view-camp-details';
import viewCompetitionDetails from './view-competition-details';
import viewEventDetails from './view-event-details';
import MaintenanceLottie from './maintenanceLottie';
import BatchView from './view-batch/batch-view';

class LayoutContent extends React.Component {
  render() {
    return (

      <Switch>
        <Route path={routeName.search} component={Search} />
        <Route path={routeName.UserVerify} component={UserVerify} />
        <Route path={routeName.myCart} component={UserCart} />
        <Route path={routeName.guestUpdate} component={GuestFlowScreen} />
        <Route path={routeName.viewClassDetails} component={ViewClassDetails} />
        <Route path={routeName.viewCampDetails} component={ViewCampsDetails} />
        <Route path={routeName.viewCompetitionDetails} component={viewCompetitionDetails} />
        <Route path={routeName.viewEventDetails} component={viewEventDetails} />
        <Route path={routeName.batchDetails} component={BatchView} />
        <Route path={routeName.TutorPublicProfile} component={TutorPublicProfile} />
        <Route path={routeName.termsAndConditions} component={TermsAndConditions} />
        <Route path={routeName.PrivacyPolicy} component={PrivacyPolicy} />
        <Route path={routeName.CookiePolicy} component={CookiePolicy} />
        <Route path={routeName.maintenancePage} component={MaintenanceLottie} />
        {/* <Route path='/not-found' component={NotFoundComponent} />
        <Redirect from='*' to='/not-found' /> */}
      </Switch>

    );
  }
}

export default LayoutContent;
