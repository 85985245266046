import React, { useEffect, useState } from 'react';
import { Input, Button, Table, Modal, Form, DatePicker, Tooltip } from 'antd';
import './student-list.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import { requestAssign, updateRequestAssign } from '../../store/actions/tutor/common';
import * as common from '../../store/actions/tutor/common';
import { WiTime4 } from "react-icons/wi";
import { LuUserCheck2 } from "react-icons/lu";
import { FaRegEye, FaRegEdit, FaCheckCircle } from 'react-icons/fa';
import parser from 'html-react-parser';
import Linkify from 'linkify-react';
import { NurtemUtils } from '../../utils';
import dayjs from 'dayjs';

const { TextArea } = Input;

const TutorDocRequest = (props) => {
  const [reqModel, setReqModel] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [viewModel, setViewModel] = useState(false);
  const [textAreaCount, ChangeTextAreaCount] = useState(0);
  const [form] = Form.useForm();

  const gid = _.get(props, 'gid', '');
  const pid = _.get(props, 'provider_id', '');
  const sid = _.get(props, 'session', '');

  const Assignments = _.get(props, 'requestAssignment.details', []);

  useEffect(() => {
    const reqParams = { gid, sid, pid };
    props.onGetAssignment(reqParams);
  }, []);

  const ShowEdit = (record) => {
    setCurrentRecord(record);
    setReqModel(true);
    form.setFieldsValue({
      assignment_title: record.assignment_title,
      assignment_start_date: record.assignment_start_date ? dayjs(record.assignment_start_date) : null,
      assignment_end_date: record.assignment_end_date ? dayjs(record.assignment_end_date) : null,
      assignment_description: record.assignment_description,
    });
  };

  const handleCreate = async (values) => {
    const recordToSave = {
      ...values,
      category: props.cat,
      subcategory: props.subcat,
      typeid: props.session,
      categoryid: props.gid,
      provider_profile: props.provider_id,
    };

    requestAssign(recordToSave);
    form.resetFields();
    setReqModel(false);
  };

  const handleUpdate = async (values) => {
    const recordToSave = {
      ...values,
      id: currentRecord.id
    };

    updateRequestAssign(recordToSave);
    form.resetFields();
    setReqModel(false);
  };

  const onFinish = (values) => {
    if (currentRecord) {
      handleUpdate(values);
    } else {
      handleCreate(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const validateDates = (_, value) => {
    const { assignment_start_date, assignment_end_date } = form.getFieldsValue();
    if (assignment_start_date && assignment_end_date && assignment_start_date >= assignment_end_date) {
      return Promise.reject(new Error('End date must be after start date'));
    }
    return Promise.resolve();
  };

  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length);
  };

  const customLocale = {
    emptyText: (
      <div>
        <EmptyAnime AnimeType="Empty" loop animeheight={150} />
        <p className="empty-animations">{props.t('noResult')}</p>
      </div>
    ),
  };

  const showReqModel = () => {
    setReqModel(true);
  };

  const hideReqModel = () => {
    setReqModel(false);
    form.resetFields();
    setCurrentRecord(null);
  };

  const [startDate, setStartDate] = useState(null);

  const disablePastDates = (current) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return current && current.valueOf() < today.valueOf();
  };

  const disableDatesBefore = (current) => {
    if (!startDate) {
      return false;
    }
    const startDateObj = new Date(startDate);
    startDateObj.setHours(0, 0, 0, 0);
    return current && current.valueOf() < startDateObj.valueOf();
  };

  const onStartDateChange = (date) => {
    setStartDate(date);
    form.setFieldsValue({ assignment_end_date: null }); // Reset end date when start date changes
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: '2-digit', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const ShowView = (record) => {
    setCurrentRecord(record);
    setViewModel(true);
  };

  const ShowViewCancel = () => {
    setCurrentRecord(null);
    setViewModel(false);
  };

  const columns = [
    {
      title: 'Request Title',
      dataIndex: 'assignment_title',
      key: 'assignment_title',
    },
    {
      title: 'Submission Start Date',
      dataIndex: 'assignment_start_date',
      key: 'assignment_start_date',
      render: (text) => formatDate(text),
    },
    {
      title: 'Submission End Date',
      dataIndex: 'assignment_end_date',
      key: 'assignment_end_date',
      render: (text) => formatDate(text),
    },
    {
      title: 'Students Completed',
      dataIndex: 'student_completed',
      key: 'student_completed',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 15, alignItems: 'center' }}>
          <Tooltip placement="left" arrow={false} overlayClassName="tooltipForIcon" title="View">
            <FaRegEye style={{ fontSize: 20, color: 'coral' }} onClick={() => ShowView(record)} />
          </Tooltip>
          <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="Edit">
            <FaRegEdit style={{ fontSize: 20, color: 'blue' }} onClick={() => ShowEdit(record)} />
          </Tooltip>
          <Tooltip placement="top" arrow={false} overlayClassName="tooltipForIcon" title="Evaluate">
            <FaCheckCircle style={{ fontSize: 20, color: 'green' }} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="container uploadbox">
      {props.user === 'host' && (
        <div className="row mb-3">
          <div className="col-6 tab-content-title">
            {props.cat === 'events' || props.cat === 'competitions'
              ? props.t('partiUp')
              : props.t('stuUp')}
          </div>
          <Button type="primary" onClick={showReqModel}>
            Create Assignment
          </Button>
        </div>
      )}
      <Table
        columns={columns}
        dataSource={Assignments}
        pagination={false}
        locale={customLocale}
      />
      <Modal
        open={reqModel}
        footer={false}
        onCancel={hideReqModel}
        title={currentRecord ? "Edit Assignment" : "Create Assignment"}
      >
        <Form
          form={form}
          name="providerRequest"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Assignment Title"
            name="assignment_title"
            rules={[{ required: true, message: 'Please input the assignment title!' }]}
          >
            <Input />
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Form.Item
              label="Start Date"
              name="assignment_start_date"
              rules={[{ required: true, message: 'Please select the start date!' }, { validator: validateDates }]}
            >
              <DatePicker
                disabledDate={disablePastDates}
                onChange={onStartDateChange}
              />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="assignment_end_date"
              rules={[{ required: true, message: 'Please select the end date!' }, { validator: validateDates }]}
            >
              <DatePicker
                disabledDate={disableDatesBefore}
              />
            </Form.Item>
          </div>
          <Form.Item
            label="Description"
            name="assignment_description"
            rules={[{ required: true, message: 'Please input the description!' }]}
          >
            <TextArea onChange={recalculate} />
          </Form.Item>
          <div
            style={{
              fontSize: '12px',
              color: '#000',
              opacity: 0.6,
            }}
          >
            {textAreaCount}/130
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={viewModel}
        onCancel={ShowViewCancel}
        footer={false}
      >
        {
          currentRecord && (
            <>
              <div className='assignName'>
                {currentRecord.assignment_title}
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: 20, marginTop: '10px' }}>
                <div>
                  <span className='dateViewassign' ><WiTime4 style={{ color: '#008dff', marginRight: '5px', fontSize: '20px' }} />Start Time</span>
                  <span className='ml-4'>{formatDate(currentRecord.assignment_start_date)}</span>
                </div>
                <div>
                  <span className='dateViewassign' ><WiTime4 style={{ color: '#008dff', marginRight: '5px', fontSize: '20px' }} />End Time</span>
                  <span className='ml-4'>{formatDate(currentRecord.assignment_end_date)}</span>
                </div>
                <div>
                  <span className='dateViewassign' ><LuUserCheck2 style={{ color: '#008dff', marginRight: '5px', fontSize: '20px' }} />Student Completed</span>
                  <span className='ml-4'>{"04"}</span>
                </div>
              </div>
              <div className=' mt-3'>
                <span className='dateViewassign mt-3'>Description</span>
                <span style={{ color: 'black', fontWeight: 'normal' }}>
                  <Linkify as="p">
                    {currentRecord.assignment_description &&
                      parser(NurtemUtils.urlify(currentRecord.assignment_description))}
                  </Linkify>
                </span>
              </div>
            </>
          )
        }
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  requestAssignment: state.common.requestAssignment
});

const mapDispatchToProps = (dispatch) => ({
  onGetAssignment: (requestParams) => dispatch(common.onGetRequestAssignment(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TutorDocRequest));
