import _ from 'lodash';

import LogicalBlock from './logical-block';
import parentComponentFunctionalities from '../component-functionalities';

const config = {
  // available componenets modes
  modes: {
    addCoupon: { alias: 'addCoupon', label: 'Add Coupon' },
    updateCoupon: { alias: 'updateCoupon', label: 'Update Coupon' },
  },
  // default properties of the component
  details: {
    mode: null,
    type: _.get(parentComponentFunctionalities, 'config.type.general_discount.alias', null),
    updateCouponDetails: {},
  },
  // initializing parent component config
  ..._.get(parentComponentFunctionalities, 'config', {}),
};
export default {
  config,
  LogicalBlock,
};
