import React, { useEffect, useState } from 'react';
import { Table, Pagination, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { chain } from 'lodash';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';

function Milestones(props) {
  const [ms, setMs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  const Milestonecolumns = [{
    title: props.t('milestones'),
    dataIndex: 'milestones',
    key: 'milestones',
  },
  ];

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    const newStartIndex = (page - 1) * pageSize;
    const newEndIndex = newStartIndex + pageSize;
    startIndex = newStartIndex;
    endIndex = newEndIndex;
  };

  useEffect(() => {
    try {
      let mstone;
      if (props.type === 'camps') {
        mstone = chain(props.classDetailsView).get('details.camp_details.milestones', []).value();
      } else if (props.type === 'classes') {
        mstone = props.session_details.milestones;
      }

      if (mstone && mstone.length > 0) {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        const mileStonesData = mstone.slice(startIndex, endIndex).map((mile, mile_id) => ({
          key: mile.id,
          milestones: (
            <div className="user-my-class-milestones-container">
              <div className="user-my-class-milestones-set">
                <span>{mile.name}</span>
                {mile.completed === 1 ? (
                  <Tooltip placement="right" arrow={false} overlayClassName="tooltipForIcon" title="Completed">
                    <AiOutlineCheckCircle
                      style={{ fontSize: 20, paddingTop: 0, marginLeft: 5 }}
                      className="faq-buttons-tick"
                      color="#d1a30e"
                    />
                  </Tooltip>
                ) : ''}
              </div>
            </div>
          ),
        }));
        setMs(mileStonesData);
      }
    } catch (error) {
      console.log('error at common-user\milestones.js', error);
    }
  }, [props.session_details, props.classDetailsView, currentPage, itemsPerPage, props.type]);

  const customLocale = {
    emptyText: (<div>
      <EmptyAnime AnimeType="EmptyUserSide" />
      <p className="empty-animations">{props.t('noMile')}</p>
    </div>),
  };
  return (
    <div className="col-12 mb-3">
      <Table
        dataSource={ms}
        columns={Milestonecolumns}
        pagination={false}
        locale={customLocale}
        noDataIcon={null}
      />

      {
                props.type && props.type === 'camps' && props.classDetailsView.details.camp_details.milestones && props.classDetailsView.details.camp_details.milestones.length > itemsPerPage && (
                <Pagination
                  className="mt-3"
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={props.classDetailsView.details.camp_details.milestones.length}
                  onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                />
                )
            }
      {
                props.type && props.type === 'classes' && props.session_details.milestones && props.session_details.milestones.length > itemsPerPage && (
                <Pagination
                  className="mt-3"
                  current={currentPage}
                  pageSize={itemsPerPage}
                  total={props.session_details.milestones.length}
                  onChange={(page, pageSize) => handlePageChange(page, pageSize)}
                />
                )
            }

    </div>
  );
}

const mapStateToProps = (state) => ({
  loading: state.campsDetails.loading,
  loggedInUserDetails: state.authentication,
  DetailView: state.users.DetailView,
  classDetailsView: state.users.userCampsInfo,
  singlePackViewLoading: state.campsDetails.singleCampsViewLoading,
  dependentUsers: state.userProfile.dependentUsers,
  userBaseProfile: state.userProfile.userBaseProfile,

});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation()(Milestones));
