import * as SecureLS from 'secure-ls';
import { notification } from 'antd';
import * as actionTypes from './actionTypes';
import axios from '../../../utils/axios';
import { tutorProfileSuccess } from '../tutor';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import _ from 'lodash';
import {
  updateUserProfileInfo,
  guestUserFlowStep,
  userProfileSuccess,
} from '../users/index';

const isObject = (obj) => Object(obj) === obj;

export const enableLoading = () => ({
  type: actionTypes.enableLoading,
  loading: { loading: true },
});

export const disableLoading = () => ({
  type: actionTypes.disableLoading,
  loading: { loading: false },
});

export const signUpProvider = (credentials) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/providers/create',
    withCredentials: true,
    data: credentials,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      console.log('erroremail12=======', res);
      if (res.status === 200) {
        dispatch(disableLoading());
        const data = { ...res.data };
        data.loggedInAs = 'provider';
        dispatch(signUpSuccess(data));
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
      if (error && error.data && error.data.errors) {
        if (error.data.status === 402) {
          error.data.identity = 'tutorAdminApproval';
          dispatch(loginSignupAdminApproval(error.data));
        }
        // const message_args = {
        //   duration: 2,
        //   message: error.response.data.errors && error.response.data.errors.rules && error.response.data.errors.rules[0] && error.response.data.errors.rules[0].message
        // }
        // notification.error(message_args)
      } else if (error && error.data && error.status === 500) {
        error.data.status = 402;
        error.data.identity = 'tutorAdminApproval';
        error.data.email = _.get(error, 'data.details.username', '');
        dispatch(loginSignupAdminApproval(error.data));
      } else if (error && error.data && error.status === 404) {
        dispatch(loginSignupAdminApproval());
      } else {
        const data = {
          status: 402,
        };

        dispatch(loginSignupAdminApproval(data));

        // history.push(routeName.UsersEmailVerify)
      }
    });
};

export const signUpUser = (credentials) => {
  let users = {};

  if (credentials && credentials.new_password) {
    users = {
      password: credentials.password,
      username: credentials.username,
      utm_source: 'shopping_cart',
    };
  } else {
    users = { ...credentials };
  }

  return (dispatch) => {
    dispatch(enableLoading());
    axios({
      method: 'post',
      url: '/users/create',
      withCredentials: true,
      data: users,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((res) => {
        if (res.status === 200) {
          if (credentials && credentials.new_password) {
            dispatch(updateUserProfileInfo(credentials));
            dispatch(guestUserFlowStep(2));
          }
          const data = { ...res.data };
          data.loggedInAs = 'user';
          dispatch(signUpSuccess(data));
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        dispatch(disableLoading());
        try {
          if (error && error.data && error.data && error.data.errors) {
            if (error.data.status === 402) {
              error.data.identity = 'userAdminApproval';
              dispatch(loginSignupAdminApproval(error.data));
            }
          } else if (error && error.data && error.status === 500) {
            if (error.data.status !== 401) {
              error.data.status = 402;
              error.data.identity = 'userAdminApproval';
              error.data.email = _.get(error, 'data.details.username', '');
              dispatch(loginSignupAdminApproval(error.data));
            }
          } else if (error && error.data && error.status === 404) {
            dispatch(loginSignupAdminApproval());
          } else {
            const data = {
              status: 402,
            };

            dispatch(loginSignupAdminApproval(data));

            // history.push(routeName.UsersEmailVerify)
          }
        } catch (error) {
          console.log(
            'error at catch block of actionsauthenticationauthentication.js',
          );
          const data = {
            status: 402,
          };

          dispatch(loginSignupAdminApproval(data));
        }
      });
  };
};

export const signInSuccess = (data) => ({
  type: actionTypes.signInSuccess,
  success: data,
});
export const signInHandleVerification = (data) => ({
  type: actionTypes.signInHandleVerification,
  success: data,
});

export const logoutSuccess = (data) => ({
  type: actionTypes.logoutSuccess,
  success: data,
});

export const signUpSuccess = (data) => ({
  type: actionTypes.signUpSuccess,
  success: data,
});

export const loginSignupTab = (data) => ({
  type: actionTypes.loginSignupTab,
  success: data,
});

export const loginSignupAdminApproval = (data) => ({
  type: actionTypes.loginSignupAdminApproval,
  success: data,
});

export const SignupCodeVerificationResend = (data) => ({
  type: actionTypes.SignupCodeVerificationResend,
  success: data,
});

export const onSignupCodeVerificationState = (data) => ({
  type: actionTypes.onSignupCodeVerificationState,
  success: data,
});

export const onSignupCodeVerification = (requestParams) => (dispatch) => {
  // dispatch(enableMilestoneLoading())
  axios({
    method: 'post',
    url: '/common/emailcode',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        dispatch(onSignupCodeVerificationState(deleteResponse));
        // dispatch(disableMilestoneLoading())
      }
    })
    .catch((error) => {
      // console.log('emailverification========2',error.status)
      console.log('emailverification========3', error);
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      if (_.get(error, 'data.message.status', '') === 404) {
        const message_args = {
          duration: 3,
          message:
              'Multiple Verification attempt! Pls try again with new code after sometime!',
        };
        notification.warning(message_args);
      }
      dispatch(onSignupCodeVerificationState(deleteResponse));
      // dispatch(disableMilestoneLoading())
    });
};

export const onSignupCodeVerificationResend = (requestParams) => (dispatch) => {
  // dispatch(enableMilestoneLoading())
  axios({
    method: 'post',
    url: '/common/emailcoderesend',
    withCredentials: true,
    data: requestParams,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((response) => {
      if (response.status === 200) {
        const deleteResponse = {};
        deleteResponse.status = response.status;
        deleteResponse.data = response.data;
        const message_args = {
          duration: 3,
          message: response.data.message,
        };
        notification.success(message_args);
        dispatch(SignupCodeVerificationResend(deleteResponse));
        // dispatch(disableMilestoneLoading())
      }
    })
    .catch((error) => {
      const deleteResponse = {};
      deleteResponse.status = 'error';
      deleteResponse.data = error && error.response && error.response.data;

      dispatch(onSignupCodeVerificationState(deleteResponse));
      // dispatch(disableMilestoneLoading())
    });
};

export const signIn = (credentials) => (dispatch) => {
  dispatch(enableLoading());
  // console.log('credentials', credentials);

  axios({
    method: 'post',
    url: '/login',
    withCredentials: true,
    data: credentials,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        if (
          res.data.message
            === 'Email registered with tutor. Signup as student to proceed.'
        ) {
          const message_args = {
            duration: 4,
            message: res.data.message,
          };
          notification.warning(message_args);
        } else if (
          res.data.message
            === 'Email registered with student. Signup as tutor to proceed.'
        ) {
          const message_args = {
            duration: 4,
            message: res.data.message,
          };
          notification.warning(message_args);
        }
        dispatch(disableLoading());
        dispatch(signInSuccess(res.data));

        // console.log('res', res);
        /* if(res.data.login_type==="provider")
          {
            history.push(routeName.tutorDashboard);
          }
          else
          {
            history.push(routeName.userDashboard);
          } */
      }
    })
    .catch((error) => {
      if (
        error
          && error.data
          && error.data.message
          && error.data.message === 'Awaiting admin approval.'
      ) {
        notification.error({
          message: 'Error',
          description: 'Please confirm your email before trying to login',
        });
        dispatch(signInHandleVerification(error.data));
      }

      dispatch(disableLoading());
    });
};

export const socialSignupProvider = (credentials) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/providers/social-login',
    withCredentials: true,
    data: credentials,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const data = { ...res.data };
        data.loggedInAs = 'provider';
        data.login_type = 'provider';
        data.isLoggedIn = true;

        dispatch(signUpSuccess(data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      // dispatch(signUpSuccess())
      dispatch(disableLoading());
    });
};

export const userSocialSignupProvider = (credentials) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/users/social-login',
    withCredentials: true,
    data: credentials,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const data = { ...res.data };
        data.login_type = 'user';
        data.loggedInAs = 'user';
        data.isLoggedIn = true;
        dispatch(signUpSuccess(data));
        dispatch(disableLoading());
      }
    })
    .catch((error) => {
      // dispatch(signUpSuccess())
      dispatch(disableLoading());
    });
};

export const onSwitchAccount = () => (dispatch) => {
  axios({
    method: 'post',
    url: '/switchAccount',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        if (res.data.login_type === 'user') {
          // user
          history.push(routeName.userDashboard);
        } else {
          // provider
          history.push(routeName.tutorDashboard);
        }

        dispatch(signInSuccess(res.data));
      }
    })
    .catch((error) => {
      console.log('error at /switchAccount', error);
    });
};

export const onCheckLogin = () => (dispatch) => {
  axios({
    method: 'post',
    url: '/isLoggedIn',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        dispatch(signInSuccess(res.data));
      }
    })
    .catch((error) => {
      console.log('error at /isLoggedIn', error);
    });
};

export const resetPasswordResponse = (data) => ({
  type: actionTypes.resetPasswordResponse,
  success: data,
});

export const passwordCheckResponse = (data) => ({
  type: actionTypes.passwordCheckResponse,
  success: data,
});

export const ClaimBusinessResponse = (data) => ({
  type: actionTypes.ClaimBusinessResponse,
  success: data,
});

export const resetPassword = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: 'reset-password',
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
        dispatch(disableLoading());
        dispatch(resetPasswordResponse({ status: res.status, ...res.data }));
      }
    })
    .catch((error) => {
      const res = {};
      res.status = 'error';
      res.status = _.get(error, 'status');
      res.message = _.get(error, 'data.message');
      dispatch(
        resetPasswordResponse({
          status: res.status,
          message: res.message,
        }),
      );
      dispatch(disableLoading());
    });
};

export const PasswordChange = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: 'password-change',
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
        dispatch(disableLoading());
        dispatch(resetPasswordResponse({ status: res.status, ...res.data }));
      } else if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.error(message_args);
        dispatch(disableLoading());
        dispatch(resetPasswordResponse({ status: res.status, ...res.data }));
      } else if (res.status === 403) {
        dispatch(disableLoading());
        dispatch(resetPasswordResponse({ status: res.status, ...res.data }));
      }
    })
    .catch((error) => {
      const res = {};
      res.status = 'error';
      res.status = _.get(error, 'status');
      res.message = _.get(error, 'data.message');
      dispatch(
        resetPasswordResponse({
          status: res.status,
          message: res.message,
        }),
      );
      dispatch(disableLoading());
    });
};

export const deleteAccount = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: 'delete-account',
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const textMessage = res.data.message;
        const capitalizedMessage = textMessage.charAt(0).toUpperCase() + textMessage.slice(1);
        const message_args = {
          duration: 2,
          message: capitalizedMessage,
        };
        notification.success(message_args);
        dispatch(disableLoading());
        dispatch(resetPasswordResponse({ status: res.status, ...res.data }));
      }
    })
    .catch((error) => {
      const res = {};
      res.status = 'error';
      res.status = _.get(error, 'status');
      res.message = _.get(error, 'data.message');
      dispatch(
        resetPasswordResponse({
          status: res.status,
          message: res.message,
        }),
      );
      dispatch(disableLoading());
    });
};

export const passwordCheck = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/account/check-password',
    withCredentials: true,
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      console.log('res');
      dispatch(disableLoading());
      if (res.status === 200) {
        dispatch(passwordCheckResponse(true));
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
      }
      if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.warning(message_args);
        dispatch(passwordCheckResponse(false));
      }
    })
    .catch((error) => {
      dispatch(disableLoading());
      dispatch(passwordCheckResponse(false));
    });
};

export const changePasswordResponse = (data) => ({
  type: actionTypes.changePasswordResponse,
  success: data,
});

export const changePassword = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: '/changepassword',
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
        dispatch(disableLoading());
        dispatch(changePasswordResponse({ status: res.status, ...res.data }));
      }
    })
    .catch((error) => {
      const res = {};
      res.status = 'error';
      res.status = error && error.response && error.response.status;
      res.message = error
          && error.response
          && error.response.data
          && error.response.data.message;
      dispatch(
        changePasswordResponse({
          status: res.status,
          message: res.message,
        }),
      );
      dispatch(disableLoading());
    });
};

export const onLogout = () => (dispatch) => {
  axios({
    method: 'post',
    url: '/logout',
    withCredentials: true,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then(async (res) => {
      if (res.status === 200) {
        await dispatch(signInSuccess(res.data));
        dispatch(tutorProfileSuccess({}));
        dispatch(userProfileSuccess({}));

        const ls = new SecureLS();
        ls.removeAll();
        // window.location.replace('https://nurtem.com/')
        history.push(routeName.signIn);
        // window.location.reload()
      }
    })
    .catch((error) => {
      console.log('error at /logout', error);
    });
};

export const onGoBack = () => ({
  type: actionTypes.onGoBack,
  goBack: true,
});

export const userVerify = (req) => {
  const requestParams = { token: req };

  return (dispatch) => {
    dispatch(enableLoading());
    axios({
      method: 'post',
      url: '/users/verify',
      withCredentials: true,
      data: requestParams,
      config: { headers: { 'Content-Type': 'application/json' } },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: actionTypes.isUserVerified,
            data: { isUserVerified: { result: true } },
          });
          dispatch(disableLoading());
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.isUserVerified,
          data: {
            isUserVerified: {
              result: false,
              message: error.response.data.message,
            },
          },
        });
        dispatch(disableLoading());
      });
  };
};

export const ClaimBusiness = (data) => (dispatch) => {
  dispatch(enableLoading());
  axios({
    method: 'post',
    url: 'password-change',
    data,
    config: { headers: { 'Content-Type': 'application/json' } },
  })
    .then((res) => {
      if (res.status === 200) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.success(message_args);
        dispatch(disableLoading());
        dispatch(ClaimBusinessResponse({ status: res.status, ...res.data }));
      } else if (res.status === 201) {
        const message_args = {
          duration: 2,
          message: res.data.message,
        };
        notification.error(message_args);
        dispatch(disableLoading());
        dispatch(ClaimBusinessResponse({ status: res.status, ...res.data }));
      } else if (res.status === 400) {
        dispatch(disableLoading());
        dispatch(ClaimBusinessResponse({ status: res.status, ...res.data }));
      }
    })
    .catch((error) => {
      const res = {};
      res.status = 'error';
      res.status = _.get(error, 'status');
      res.message = _.get(error, 'data.message');
      dispatch(
        ClaimBusinessResponse({
          status: res.status,
          message: res.message,
        }),
      );
      dispatch(disableLoading());
    });
};
