import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import * as sessionActions from '../../../store/actions/public/sessions';
import '../tutor-camps/user-new-my-class.css';
import StudentList from '../../../components/common/student-list-class';
import Milestones from '../../../components/common/milestone';
import Ratings from '../../../components/rating/rating-list';
import EnrollStudent from './components/enroll-a-student';
import Faq from '../../../components/common/faq';
import WaitingList from './components/waiting-list';
import NotesAssignment from '../../../components/common/notes-assignment';
import ScrollToTop from '../../../components/re-usable/ScrollToTop';
import SessionDetailsRight from '../../../components/tutor/full-details/session-details';
import ParticipantUploads from '../../../components/common/upload-list';
import TutorDocRequest from '../../../components/common/tutor-doc-request';
import UploadToS3 from '../../../components/re-usable/file-to-s3';
import * as actionTypes from '../../../store/actions/common/actionTypes';
import history from '../../../utils/history';
import * as routeName from '../../../components/route-names';
import SessionInfo from './components/sessionInfo';
import { MeetingLink } from './components/meeting-link';
import { FcCheckmark, FcConferenceCall, FcFaq, FcInfo, FcLink, FcMoneyTransfer, FcRating, FcSurvey, FcTodoList, FcUpload, FcViewDetails } from "react-icons/fc";
import { FaBook } from "react-icons/fa";
import { TiUserAdd } from "react-icons/ti";
import { LuMilestone } from "react-icons/lu";
import { PiUserListBold } from "react-icons/pi";

const SessionDetailedView = (props) => {
  const urlparams = queryString.parse(window.location.search);

  const tutorProfile = _.get(props, 'tutorBaseProfile', {});
  const providerid = _.get(tutorProfile, 'id', '')


  const profile_type = _.get(tutorProfile, 'profile_type', '');
  const works_for = _.get(tutorProfile, 'works_for', '');
  const isTutorUnderBusiness = profile_type === 1 && works_for !== null;

  const getSessionList = (id) => {
    if (id) {
      const requestParams = {};
      requestParams.group_id = id;
      requestParams.sort = 'created_at.desc';
      props.getSessionList(requestParams, 'classes');
    }
  };

  const [classDetails, setclassDetails] = useState({});
  // const [tabPosition,] = useState('top')

  useEffect(() => {
    if (urlparams && urlparams.gid && urlparams.sid) {
      const requestParams = {};
      // requestParams.page = this.state.page
      requestParams.limit = 1000;
      requestParams.campId = urlparams.sid;
      requestParams.expand = 'class_details,user,dependent_user';
      // from the session list we can pick up individual session details
      getSessionList(urlparams.gid);
    } else {
      history.goBack();
    }
  }, []);

  useEffect(() => {
    const sessions = props.sessions[urlparams.gid];
    if (_.isArray(sessions) && sessions.length > 0) {
      const details = sessions.filter((session) => session.id === urlparams.sid);
      _.isArray(details) && details.length > 0 && setclassDetails(_.first(details));
    }
  }, [props.sessions]);

  const viewAttendance = (key, session) => {
    const provider_id = _.get(session, 'provider.id', _.get(session, 'provider', ''));

    if (key === '2') {
      history.push(`${routeName.TutorAttendance.split(':')[0]}?classId=${session.id}&type=${session.type}&provId=${provider_id}`);
    } else if (key === '11') {
      history.push(`${routeName.attendanceReportFull.split(':')[0]}?classId=${session.id}&type=${'classes'}&provId=${provider_id}`);
    } else if (key === '12') {
      history.push(`${routeName.payments}?provId=${provider_id}`);
    } else if (key === '13') {
      history.push(`${routeName.paymentReportFull.split(':')[0]}?classId=${session.id}&type=${'classes'}&provId=${provider_id}`);
    }
  };
  const tabTitle = props.t('students');
  const tabTitle1 = props.t('att');
  const tabTitle2 = props.t('notes&asg');
  const tabTitle3 = props.t('stuUp');
  const tabTitle4 = props.t('enrollStu');
  const tabTitle5 = props.t('faqs');
  const tabTitle6 = props.t('milestones');
  const tabTitle7 = props.t('r&r');
  const tabTitle8 = props.t('batchInfo');
  const tabTitle9 = props.t('tutorSidebarAttendanceReport');
  const tabTitle10 = props.t('tutorSidebarOfflinePayments');
  const tabTitle11 = props.t('tutorSidebarPaymentReport');
  const tabTitle12 = props.t('upWork1');
  const tabTitle13 = props.t('waitlist2');
  const render = () => (
    <div className="nurtem-my-class-card tutor-my-camp-details-page pb-4 mb-3 sessionbox">
      <ScrollToTop />
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pr-0 border-right">
          <Tabs
            defaultActiveKey="10"
            tabPosition={window.innerWidth >= 768 ? 'left' : 'top'}
            onChange={(key) => viewAttendance(key, classDetails)}
          >
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcInfo style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle8}</span>} key="10">
              {/* <Ratings session={classDetails} type="classes" /> */}
              <SessionInfo
                session={classDetails}
                id={urlparams.sid}
                type="classes"
              />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcConferenceCall style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle}</span>} key="1">
              <StudentList session={classDetails} type="classes" />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><PiUserListBold style={{ marginRight: '5px', fontSize: '20px', color: '#daa69a' }} /> {tabTitle13}</span>} key="15">
              <WaitingList session={classDetails} type="classes" />
            </items>
            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcCheckmark style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle1}</span>} key="2" />

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcViewDetails style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle2}</span>} key="3">
              <NotesAssignment session={classDetails} type="classes" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcUpload style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle3}</span>} key="4">
              {/* <TutorDocRequest
              provider_id={providerid}
              gid={urlparams.gid}
              subcat={actionTypes.requestAssignment} */}
              <ParticipantUploads
                session={urlparams.sid}
                cat="classes"
                subcat={actionTypes.classUploads}
                user="host"
                user_type="student"
              />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FaBook style={{ color: 'darkslateblue', marginRight: '5px', fontSize: '20px' }} /> {tabTitle12}</span>} key="5">
              <div className="row mb-3" style={{ margin: 0 }}>
                <div className="col-6 tab-content-title">Upload your work</div>
                <div className="col-6 d-flex justify-content-end container-upload">
                  <UploadToS3
                    cat="classes"
                    subcat={actionTypes.tutorUploads}
                    cat_id={urlparams.sid}
                    type_id={urlparams.gid}
                    usertype="provider"
                  />
                </div>
              </div>
              <div className="row mb-3" style={{ margin: 0 }}>
                <div className="col-12">
                  <ParticipantUploads
                    session={urlparams.sid}
                    cat="classes"
                    subcat={actionTypes.tutorUploads}
                    user_id={_.get(props, 'tutorBaseProfile.id')}
                    user_type="provider"
                  />
                </div>
              </div>

            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><TiUserAdd style={{ color: '#7d3d8b', marginRight: '5px', fontSize: '20px' }} /> {tabTitle4}</span>} key="6">
              <EnrollStudent session={classDetails} type="classes" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcFaq style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle5}</span>} key="7">
              <Faq session={classDetails} type="classes" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><LuMilestone style={{ color: 'darkblue', marginRight: '5px', fontSize: '20px' }} /> {tabTitle6}</span>} key="8">
              <Milestones session={classDetails} type="classes" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcRating style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle7}</span>} key="9">
              <Ratings session={classDetails} type="classes" />
            </items>

            <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcTodoList style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle9}</span>} key="11">
              {/* <AttendanceReportFull filterDetails={{
                  provider: props.tutorBaseProfile.id,
                  type: "classes",
                  details: classDetails.id,
                  expand: "user,class_information_details,provider,dependent_user,class_details,session_details,camp_details",
                  session_details: classDetails.id

                }} /> */}
            </items>
            {!isTutorUnderBusiness && <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcMoneyTransfer style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle10}</span>} key="12" />}
            {!isTutorUnderBusiness && <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcSurvey style={{ marginRight: '5px', fontSize: '20px' }} /> {tabTitle11}</span>} key="13" />}
            {classDetails.link && (
              <items tab={<span style={{ display: 'flex', alignItems: 'center' }}><FcLink style={{ marginRight: '5px', fontSize: '20px' }} /> Meeting Link</span>} key="14">
                <MeetingLink session={classDetails} type="classes" />
              </items>
            )}

          </Tabs>
        </div>
        {/* <div className='col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 pl-0'>
            {urlparams && urlparams.gid && urlparams.sid &&
              <SessionDetailsRight
                id={urlparams.sid}
                type={'classes'}
              />
            }
          </div> */}
      </div>
    </div>
  );
  return render();
};

const mapStateToProps = (state) => ({

  sessions: _.get(state, ['sessions', 'sessions'], {}),
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
});

const mapDispatchToProps = (dispatch) => ({
  getSessionList: (requestParams, type) => dispatch(sessionActions.getSessionList(requestParams, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SessionDetailedView));
