import React, { useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import _ from 'lodash'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { withTranslation } from 'react-i18next'
import EmptyAnime from '../re-usable/lottie/EmptyAnime'
import * as userAction from '../../store/actions/users'
import Linkify from 'linkify-react'

dayjs.extend(utc)
dayjs.extend(timezone)

const Notes = (props) => {
  const id = _.get(props, 'session_details.id')
  const group_id = _.get(props, 'session_details.group_id')
  const columns = [
    {
      title: props.t('notes&asg'),
      dataIndex: 'notes',
      key: 'notes',
    },
  ]

  useEffect(() => {
    const notesParams = {
      type: props.type,
      page,
      limit,
      status: 1,
      sort: 'updated_at.desc',
    }

    if (props.type === 'camps') {
      notesParams.camp_details = id
      notesParams.camps = group_id
    } else if (props.type === 'classes') {
      notesParams.class_details = id
      notesParams.classes = group_id
    }

    const callback = async () => await props.onGetNotes(notesParams)
    callback()
  }, [])

  const [page, setPage] = useState(1)
  const [limit] = useState(3)

  const pageChange = async (page, pageSize) => {
    await setPage(page)
    const notesParams = {
      type: props.type,
      page,
      limit,
      status: 1,
      sort: 'updated_at.desc',
    }

    if (props.type === 'camps') {
      notesParams.camp_details = id
      notesParams.camps = group_id
    } else if (props.type === 'classes') {
      notesParams.class_details = id
      notesParams.classes = group_id
    }
    await props.onGetNotes(notesParams)
  }
  const [showMore, setShowMore] = useState(false)

  const toggleShowMore = () => {
    setShowMore(!showMore)
  }

  const renderText = (text) => {
    const lines = text.split('\n') // Split text into lines
    if (lines.length > 2 && !showMore) {
      return (
        <>
          {lines.slice(0, 2).join('\n')} {/* Display only first three lines */}
          <Button onClick={toggleShowMore} className="Showmore">
            More
          </Button>
        </>
      )
    }
    return (
      <>
        {lines.join('\n')}
        {lines.length > 2 && (
          <Button onClick={toggleShowMore} className="Showmore">
            Less
          </Button>
        )}
      </>
    )
  }

  const render = () => {
    let providerNotes = []
    if (_.get(props, 'notes.items', []).length > 0) {
      providerNotes = _.get(props, 'notes.items', []).map((value, i) => ({
        key: i,
        notes: (
          <div>
            <div className="mb-1">
              <i>
                {dayjs(_.get(value, 'updated_at'))
                  .tz(dayjs.tz.guess())
                  .format('MMM DD,YYYY | hh:mm A')}
              </i>
            </div>
            <div className="mb-1">
              <b>{value.title}</b>
            </div>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              <Linkify as="p">{renderText(_.get(value, 'text'))}</Linkify>
            </div>
            {/* <button onClick={downloadNote}>Download</button> */}
          </div>
        ),
      }))
    }

    const customLocale = {
      emptyText: (
        <div>
          <EmptyAnime AnimeType="EmptyUserSide" />
          <p className="empty-animations">{props.t('notesNoFound')}</p>
        </div>
      ),
    }

    return (
      <div className="col-12">
        <Table
          dataSource={providerNotes}
          columns={columns}
          style={{ width: '100%', marginBottom: '20px' }}
          className="tablesizer"
          locale={customLocale}
          pagination={{
            pageSize: limit,
            onChange: (page, pageSize) => pageChange(page, pageSize),
            total:
              props.notes &&
              props.notes.meta &&
              parseInt(props.notes.meta.total_count),
            hideOnSinglePage: !props.loading || props.loading,
            showSizeChanger: false,
            current: page,
          }}
        />
      </div>
    )
  }

  return render()
}

const mapStateToProps = (state) => ({
  notes: state.users.notes,
})

const mapDispatchToProps = (dispatch) => ({
  onGetNotes: (requestParams) =>
    dispatch(userAction.getUserNotes(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Notes))
