import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import TutorDashboard from './dashboard/index'
import TutorUpdateClass from './update-class/index'
import TutorUpdateCamp from './update-camp/index'
import TutorOnboarding from './tutor-onboarding/tutor-onboarding'
import TutorMessage from './tutor-message/tutor-message'
import TutorClasses from './tutor-classes/index'
import Competitions from './competitions/index'
import Events from './events/index'
import TutorUpdatecompetitions from './competitions/update'
import TutorUpdateevents from './events/update'
import TutorOnboardingWizard from './onboarding-wizard/onboarding-wizard'
import TutorsList from './tutor-list/tutor-list'
import TutorCamps from './tutor-camps/index'
import CampDetailView from './tutor-camps/camp'
import ClassDetailView from './tutor-classes/classes'
import CompetitionDetailView from './competitions/comp'
import EventDetailView from './events/event'
import SessionDetailView from './tutor-camps/session-detail-view'
import SessionDetailViewClass from './tutor-classes/session-detail-view'
import SessionDetailViewCompetition from './competitions/session-detail-view'
import SessionDetailViewEvent from './events/session-detail-view'
import TutorRequest from './tutor-request/tutor-request'
import calendar from './calendar/index'
import TutorAttendance from './attendance/tutor-view-attendance'
import TutorViewSessionAttendance from './attendance/tutor-view-session-attendance'
import TutorCompetitionAttendance from './attendance/competition-attendance'
import TutorDashboardPageSidebar from '../layout/Sidebar/tutor-dashboard-sidebar/tutor-dashboard-sidebar'
import '../../assets/fonts/nurtem/style.css'
import * as routeName from '../../components/route-names'
import * as actions from '../../store/actions/tutor'
import StudentView from './student-view-of-specific-class'
import TutorEditProfile from './edit-profile'
import TutorMyProfileTab from './edit-profile/profile-others'
import ManageCoupons from './my-coupons'
// import Setting from "./setting/setting";
import TutorAccount from './setting/index'
import BusinessTutorDashboard from './dashboard/business-dashboard'
import AttendanceReport from './attendance-report'
import AttendanceReportFull from './attendance-report/report'
import PaymentReport from './payment-report'
import Payments from './payments'
import _ from 'lodash'
import changePassword from './setting/change-password'
import TutorStudentList from './student-list'
import TutorBusinessMenu from './business-menu'

// SessionDetailView called direcly
// import BusinessTutorMyCartDashboard from "./business-account/class-camp-mycart";
// CampDetailView > called in 2 component > camp  in side that detailed view
// import BusinessEditProfile from "./business-account/update-profile";

class LayoutContent extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  componentDidMount() {
    this.props.onTutorProfile(this.props.loggedInUserDetails)
  }

  render() {
    return (
      <div style={{ width: '100%', float: 'left' }}>
        <Switch>
          <Redirect from="/" exact to={routeName.tutorDashboard} />
          <Route
            path={routeName.tutorDashboard}
            component={
              _.get(this.props, 'tutorBaseProfile.type') === 'Business'
                ? BusinessTutorDashboard
                : BusinessTutorDashboard
            }
          />
          <Route
            path={routeName.TutorBaseProfileStep1}
            component={TutorOnboarding}
          />
          <Route path={routeName.viewAllMessages} component={TutorMessage} />
          <Route
            path={routeName.tutorOnboardingWizard}
            component={TutorOnboardingWizard}
          />
          <Route path={routeName.tutorClasses} component={TutorClasses} />
          <Route path={routeName.tutorCamps} component={TutorCamps} />
          <Route path={routeName.tutorClassView} component={ClassDetailView} />
          <Route path={routeName.tutorCampView} component={CampDetailView} />
          <Route path={routeName.SessionView} component={SessionDetailView} />
          <Route
            path={routeName.SessionViewClass}
            component={SessionDetailViewClass}
          />
          <Route
            path={routeName.SessionViewCompetition}
            component={SessionDetailViewCompetition}
          />
          <Route
            path={routeName.SessionViewEvent}
            component={SessionDetailViewEvent}
          />
          <Route path={routeName.TutorList} component={TutorsList} />
          {/* <Route path={routeName.updateProfile} component={BusinessEditProfile} /> */}
          <Route path={routeName.Tutorcalendar} component={calendar} />
          <Route
            path={routeName.TutorUpdateClass}
            component={TutorUpdateClass}
          />
          <Route path={routeName.TutorUpdateCamp} component={TutorUpdateCamp} />
          <Route
            path={routeName.updateCompetition}
            component={TutorUpdatecompetitions}
          />
          <Route
            path={routeName.updateEvent}
            component={TutorUpdateevents}
          />
          <Route path={routeName.competitions} component={Competitions} />
          <Route path={routeName.events} component={Events} />
          <Route
            path={routeName.competitionsView}
            component={CompetitionDetailView}
          />
          <Route
            path={routeName.eventsView}
            component={EventDetailView}
          />
          <Route path={routeName.viewAllRequest} component={TutorRequest} />
          <Route path={routeName.StudentView} component={StudentView} />
          <Route path={routeName.TutorAttendance} component={TutorAttendance} />
          <Route
            path={routeName.TutorSessionAttendance}
            component={TutorViewSessionAttendance}
          />
          <Route
            path={routeName.TutorCompetitionAttendance}
            component={TutorCompetitionAttendance}
          />
          <Route
            path={routeName.tutorEditProfile}
            component={TutorEditProfile}
          />
          <Route
            path={routeName.tutorEditProfileOthers}
            component={TutorMyProfileTab}
          />

          <Route path={routeName.myCoupons} component={ManageCoupons} />
          <Route path={routeName.TutorAccount} component={TutorAccount} />
          {/* <Route path={routeName.Tutorsetting} component={Setting} /> */}
          <Route
            path={routeName.TutorChangePassword}
            component={changePassword}
          />

          {/* <Route path={routeName.tutorMycart} component={BusinessTutorMyCartDashboard} /> */}
          <Route path="/sideBar" component={TutorDashboardPageSidebar} />
          <Route path={routeName.payments} component={Payments} />
          <Route
            path={routeName.attendanceReport}
            component={AttendanceReport}
          />
          <Route
            path={routeName.attendanceReportFull}
            component={AttendanceReportFull}
          />
          <Route
            path={routeName.tutorStudentList}
            component={TutorStudentList}
          />
          <Route path={routeName.paymentReport} component={PaymentReport} />
          <Route path={routeName.paymentReportFull} component={PaymentReport} />

          <Route path={routeName.businessmenu} component={TutorBusinessMenu} />


        </Switch>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  tutorBaseProfile: _.get(state, 'tutorProfile.tutorBaseProfile.details', {}),
  loggedInUserDetails: state.authentication,
})

const mapDispatchToProps = (dispatch) => ({
  onTutorProfile: (data) => dispatch(actions.tutorProfile(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LayoutContent)
