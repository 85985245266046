import React from 'react';
import _ from 'lodash';

export class FullDetailsLoader extends React.Component {
  render() {
    const css = `col-12 ${_.get(this.props, 'css', '')}`;
    return (
      <div className="ph-item">
        <div className="ph-col-12">
          <div className="ph-row">
            <div className="ph-col-8 big" />
            <div className="ph-col-2 empty" />
            <div className="ph-col-2 big" />
          </div>
        </div>

        <div className="ph-col-4">
          <div className="ph-picture" style={{ height: `${250}px` }} />
        </div>
        <div className="ph-col-8">
          <div className="ph-row">
            <div className="ph-col-8 big" />
            <div className="ph-col-2 empty" />
            <div className="ph-col-2 big" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
            <div className="ph-col-4 big" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
            <div className="ph-col-4 big" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
            <div className="ph-col-4 big" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
            <div className="ph-col-4 big" />
          </div>
        </div>
        <div className="ph-col-12">
          <div className="ph-row">
            <div className="ph-col-4 big" />
            <div className="ph-col-2 empty" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
          </div>
          <div className="ph-row">
            <div className="ph-col-4 big" />
            <div className="ph-col-2 empty" />
          </div>
        </div>
        <div className="ph-col-4">
          <div className="ph-picture" style={{ height: `${250}px` }} />
        </div>
        <div className="ph-col-6">
          <div className="ph-row mt-3">
            <div className="ph-col-4 big" />
            <div className="ph-col-4 empty" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-4 mr-2 big" />
            <div className="ph-col-4 mr-2 big" />
            <div className="ph-col-4 mr-2 big" />
          </div>
        </div>
        <div className="ph-col-2">
          <div className="ph-row mt-3">
            <div className="ph-col-12 big" />
          </div>
          <div className="ph-row mt-3">
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
            <div className="ph-col-12" />
          </div>
        </div>
      </div>
    );
  }
}
