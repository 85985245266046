import * as actionTypes from '../../../actions/tutor/actionTypes';
import { updateObject } from '../../../utility';

const initialState = {
  loading: {
    loading: false,
  },
  loadingcompList: {
    loadingcompList: false,
  },
  CompetitionsStudentListLoading: false,
  CompetitionDetails: {},
  tutorCompetitionRequestAction: {},
  competitionStudents: {},
  eventStudents: {},
  providerCompetitions: [],
  otherProviderCompetitions: [],
  tutorCompetitionRequests: {},
  deleteCompetitionsInfo: {},

};

const setTutorCompetitionRequests = (state, action) => ({ ...state, tutorCompetitionRequests: action.success });

const tutorCompetitionDetele = (state, action) => ({ ...state, deleteCompetitionsInfo: action.success });

const getCompetitions = (state, action) => ({ ...state, providerCompetitions: action.payload });
const otherProviderCompetitions = (state, action) => ({ ...state, otherProviderCompetitions: action.payload });

const enableLoading = (state, action) => updateObject(state, action.loading);

const disablecompListLoading = (state, action) => updateObject(state, action.loadingcompList);

const enablecompListLoading = (state, action) => updateObject(state, action.loadingcompList);

const disableLoading = (state, action) => updateObject(state, action.loading);

const CompetitionsStudentListLoading = (state, action) => updateObject(state, action.CompetitionsStudentListLoading);

const CompetitionDetails = (state, action) => ({ ...state, CompetitionDetails: action.success });

const tutorCompetitionRequestAction = (state, action) => ({ ...state, tutorCompetitionRequestAction: action.success });

const competitionStudents = (state, action) => ({ ...state, competitionStudents: action.success });

const eventStudents = (state, action) => ({ ...state, eventStudents: action.success });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.tutorCompetitionRequests:
      return setTutorCompetitionRequests(state, action);
    case actionTypes.tutorCompetitionsDeleteFoundSuccess:
      return tutorCompetitionDetele(state, action);
    case actionTypes.tutorCompetitionDetailsFoundSuccess:
      return CompetitionDetails(state, action);
    case actionTypes.tutorCompetitionRequestAction:
      return tutorCompetitionRequestAction(state, action);
    case actionTypes.providerCompetitions:
      return getCompetitions(state, action);
    case actionTypes.otherProviderCompetitions:
      return otherProviderCompetitions(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.enablecompListLoading:
      return enablecompListLoading(state, action);
    case actionTypes.disablecompListLoading:
      return disablecompListLoading(state, action);
    case actionTypes.CompetitionsStudentListLoading:
      return CompetitionsStudentListLoading(state, action);
    case actionTypes.competitionStudents:
      return competitionStudents(state, action);
    case actionTypes.eventStudents:
      return eventStudents(state, action);
    default:
      return state;
  }
};

export default reducer;
