import React, {
  useEffect, useState, useRef, useCallback,
} from 'react';
import {
  Input, Button, Form, notification, Modal, Tooltip, Pagination,
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import '../../containers/user/user-camps/user-my-camp.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { withTranslation } from 'react-i18next';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';
import * as actions from '../../store/actions/tutor/add-class';

dayjs.extend(utc);
dayjs.extend(timezone);

function callback(key) {
  console.log(key);
}

const Faq = (props) => {
  const { id } = props.session_details;
  const { group_id } = props.session_details;

  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState(false);
  const [ownFAQ, setOwnFAQ] = useState();
  const [askQuestion, setAskQuestion] = useState(false);
  const [faqLoading, setFaqLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit] = useState(5);
  const faqRef = useRef();
  const [message, setMessage] = useState(null);

  const handleErrors = (response) => {
    if (_.get(response, 'response.data.errors')) {
      commonSuccessErrorMessage('error', _.get(response, 'response.data.errors.rules[0].message'));
    } else if (_.get(response, 'response.data.message')) {
      commonSuccessErrorMessage('error', _.get(response, 'response.data.message'));
    }
  };

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    props.listFAQ({
      type: props.type,
      session_details: id,
      limit,
      page: pageNumber,
      status: 1,
      sort: 'updated_at.desc',
    }, (response, type) => {
      if (type === 'success') {
        setOwnFAQ(response);
      } else {
        handleErrors(response);
      }
      callback && callback(type === 'success' ? null : response);
    });
  };

  const listFAQView = useCallback((id) => {
    props.listFAQ({
      session_details: id, type: props.type, limit, page, status: 1, sort: 'updated_at.desc',
    }, (response, type) => {
      if (type === 'success') {
        setOwnFAQ(response);
      } else {
        handleErrors(response);
      }
    });
  }, [props.listFAQ]);

  useEffect(() => {
    form.resetFields();
    listFAQView(id);
  }, [listFAQView, id]);

  const commonSuccessErrorMessage = (type, message) => {
    notification[type]({
      message: _.startCase(type),
      description: message,
    });
  };

  const onfaqFinish = (values, form, type) => {
    const data = { ...values };
    data.created_by = 'user';
    data.type = props.type;
    data.session_details = id;
    data.type_details = group_id;
    data.timezone = dayjs.tz.guess();
    data.time = new Date();
    faqActions(data, type);
  };

  const faqActions = (values, type) => {
    setFaqLoading(true);
    props[type](values, async (response, type) => {
      setFaqLoading(true);
      if (type === 'success') {
        setMessage('Your question has been sent to the tutor and will be answered soon.');
        listFAQView(_.get(response, 'details.session_details'));
        commonSuccessErrorMessage('success', _.get(response, 'message'));
        setisLoading(false);
        setFaqLoading(false);

        setTimeout(() => {
          askQuestionshowModal(false);
        }, 2000); // Delay closing the modal by 2 seconds
        setTimeout(() => {
          setMessage('');
        }, 2000);
      } else if (_.get(response, 'response.data.errors')) {
        commonSuccessErrorMessage('error', _.get(response, 'response.data.errors.rules[0].message'));
      } else if (_.get(response, 'response.data.message')) {
        commonSuccessErrorMessage('error', _.get(response, 'response.data.message'));
      } else if (_.get(response, 'data.message')) {
        commonSuccessErrorMessage('error', _.get(response, 'data.message'));
      }
      await form.resetFields();
    });
  };

  const askQuestionshowModal = (value) => {
    setAskQuestion(value);
  };

  const render = () => (
    <div className="col-12 mb-3">

      <div className="row mb-3">
        <div className="col-6 tab-content-title">{props.t('faqs')}</div>
        <div className="col-6 d-flex justify-content-end">
          <Button
            className="btn-green-filled"
            onClick={() => {
              askQuestionshowModal(true);
            }}
          >
            {props.t('askQuestion')}
          </Button>
        </div>
      </div>

      <div className="faq-tabs">
        {_.get(ownFAQ, 'meta.total_count', 0) > 0 ? (
          ownFAQ.items && Array.isArray(ownFAQ.items) && ownFAQ.items.map((items, index) => {
            // Increment the counter variable for each question
            const questionNumber = index + 1;

            return (
              <div key={index} className="faq-list border-bottom">
                <div className="faq-question mb-1">
                  {/* Display the question number */}
                  {questionNumber}
                  .
                  {_.get(items, 'question')}
                </div>
                <div className="faq-answer ml-2" style={{ whiteSpace: 'pre-wrap' }}>
                  {_.get(items, 'answer')}
                </div>
                <div className="user-review-set-info">
                  {dayjs(_.get(items, 'updated_at')).format('MMM DD YYYY')}
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <EmptyAnime AnimeType="EmptyUserSide" />
            <p className="empty-animations" style={{ color: '#cbcbcb' }}>{props.t('noComFaq')}</p>
          </div>
        )}
      </div>

      <Form onFinish={onfaqFinish}>
        <Modal
          className="faq-modal"
          open={askQuestion}
          centered
          title="Add a Question"
          onCancel={() => askQuestionshowModal(false)}
          confirmLoading={isLoading}
          footer={null}
        >
          <Form form={form} scrollToFirstError onFinish={(e) => onfaqFinish(e, {}, 'createFaq')} ref={faqRef}>
            <div className="row">
              {!message
                ? (
                  <>
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="full-width ">
                        <Tooltip>
                          <label className="filter-group-title">
                            {props.t('question')}
                            {' '}
                            <span className="label-star">*</span>
                          </label>
                        </Tooltip>
                        <div className="full-width position-relative row">
                          <Form.Item
                            name="question"
                            rules={[
                              {
                                required: true,
                                message: props.t('Queisreq'),
                              },
                            ]}
                            className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          >
                            <Input className="form-control" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <Form.Item className="col-12">
                      <div className="full-width text-right">
                        <Button
                          disabled={faqLoading}
                          onClick={() => askQuestionshowModal(false)}
                          type="primary"
                          danger
                        >
                          { props.t('cancel')}
                        </Button>
                        <Button
                          loading={faqLoading}
                          htmlType="submit"
                          className="ml-3"
                          type="primary"
                        >
                          { props.t('submit')}
                        </Button>
                      </div>
                    </Form.Item>
                  </>
                )
                : (
                  <Form.Item className="col-12">
                    <div className="full-width text-center">{message}</div>
                  </Form.Item>
                )}
            </div>
          </Form>
        </Modal>
      </Form>
      {_.get(ownFAQ, 'meta.total_count', 0) > 5 ? (
        <Pagination
          className="mt-3"
          current={page}
          pageSize={limit}
          total={_.size(ownFAQ) > 0 ? ownFAQ.meta.total_count : 0}
          onChange={onPageChange}
        />
      ) : ''}
    </div>
  );
  return render();
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  listFAQ: (data, callback) => dispatch(actions.listFAQ(data, callback)),
  createFaq: (data, callback) => dispatch(actions.createFAQ(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Faq));
