import React from 'react'
import { Link } from 'react-router-dom'
import {
  Calendar,
  Tabs,
  Tooltip,
  Button,
  Modal,
  DatePicker,
  Input,
  Spin,
} from 'antd'
import * as routeName from '../../../components/route-names'
import { connect } from 'react-redux'
import axios from '../../../utils/axios'
import { withTranslation } from 'react-i18next'
import * as actions from '../../../store/actions/tutor/Classes'
import dayjs from 'dayjs'
import _ from 'lodash'
import './calendar.css'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import * as commonAction from '../../../store/actions/tutor/common'
import * as tutoractions from '../../../store/actions/tutor/index'
import { withLoaderHandler } from '../../../utils/handler/withLoaderHandler'
import { CalendarLoader } from '../../../components/loader/common'
import * as action from '../../../store/actions/tutor/add-class'
import { Scrollbars } from 'react-custom-scrollbars'
import { AiOutlineCalendar } from 'react-icons/ai'

dayjs.extend(localizedFormat)
function callback(key) { }

class MyAntdCalendar extends React.Component {
  state = {
    competitionsevents: [],
    events: [],
    campsevents: [],
    eventsevents: [],
  }

  async componentDidMount() {
    this.props.onTutorProfile(this.props.loggedInUserDetails)
    let requestParams = {}
    requestParams.limit = 1000
    requestParams.sort = 'created_at.desc'
    requestParams.type = 'camps'
    requestParams.provider = this.props.tutorBaseProfile.details.id
    await this.props.getSessionListFor(requestParams)
    let requestParamsClass = {}
    requestParamsClass.limit = 1000
    requestParamsClass.sort = 'created_at.desc'
    requestParamsClass.type = 'classes'
    requestParamsClass.provider = this.props.tutorBaseProfile.details.id
    let requestParamsComp = {}
    requestParamsComp.limit = 1000
    requestParamsComp.sort = 'created_at.desc'
    requestParamsComp.type = 'competitions'
    requestParamsComp.provider = this.props.tutorBaseProfile.details.id
    let requestParamsEvent = {}
    requestParamsEvent.limit = 1000
    requestParamsEvent.sort = 'created_at.desc'
    requestParamsEvent.type = 'events'
    requestParamsEvent.provider = this.props.tutorBaseProfile.details.id
    await this.props.getSessionListForClass(requestParamsClass)
    await this.props.getSessionListForCompetitions(requestParamsComp)
    await this.props.getSessionListForEvents(requestParamsEvent)
    this.props.getProviderClasses()
  }

  async componentDidUpdate(prevProps, PrevState) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        tutorBaseProfile: {},
      })
      this.componentDidMount()
    }
    if (
      prevProps.sessionList !== this.props.sessionList &&
      this.props.sessionList &&
      this.props.sessionList.items
    ) {
      this.getcampsList(this.props.sessionList.items)
    }

    if (
      prevProps.sessionListClass !== this.props.sessionListClass &&
      this.props.sessionListClass &&
      this.props.sessionListClass.items
    ) {
      this.getClassList(this.props.sessionListClass.items)
    }
    if (
      prevProps.sessionListComp !== this.props.sessionListComp &&
      this.props.sessionListComp &&
      this.props.sessionListComp.items
    ) {
      this.getCompetitionsList(this.props.sessionListComp.items)
    }
    console.log('prevProps.sessionListEvent', this.props.sessionListEvent)
    if (
      prevProps.sessionListEvent !== this.props.sessionListEvent &&
      this.props.sessionListEvent &&
      this.props.sessionListEvent.items
    ) {
      this.getEventsList(this.props.sessionListEvent.items)
    }
  }

  // Open holidays modal
  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  //  Close holidays model
  handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  // public holiday dates
  holidayDates = (value) => {
    if (value) {
      this.setState({
        holidayDate: value.format('MM/DD/YYYY'),
        holidayDateValue: value,
      })
    }
  }

  // public holiday input
  holidayMsg = (value) => {
    if (value) {
      this.setState({ holidayText: value })
    }
  }

  // Add holidays
  handleOk = (e) => {
    this.setState({
      visible: true,
      holidayText: '',
      holidayDateValue: null,
    })

    this.onHolidayClass(
      this.props.tutorBaseProfile.details
        ? this.props.tutorBaseProfile.details
        : {}
    )
  }

  onHolidayClass = (getClassList) => {
    let data = {
      date: this.state.holidayDate,
      description: this.state.holidayText,
    }
    if (getClassList.public_holidays) {
      getClassList.public_holidays.splice(0, 0, data)
    } else {
      getClassList.public_holidays = [data]
    }
    this.props.onBusinessProfileUpdate(getClassList)
    this.props.getProviderClasses()
  }

  // Delete holidays
  handleDelete = (e) => {
    var deleteday = this.props.tutorBaseProfile.details.public_holidays
    let removedata = e
    // let removeSingleData;
    this.setState({
      visible: true,
    })
    _.remove(deleteday, function (e) {
      return e === removedata
    })
    deleteday.map((value) => {
      return { value }
    })
    this.onRemoveHolidayClass(deleteday, this.props.tutorBaseProfile.details)
  }

  onRemoveHolidayClass = (deleteday, getClassList) => {
    if (getClassList.public_holidays) {
      getClassList.public_holidays = deleteday
    }
    this.props.onBusinessProfileUpdate(getClassList)
  }

  onbetweenDates = (startDate, endDate) => {
    // Implement the logic to get dates between startDate and endDate
    // This function is not provided in your code, so you may need to adjust it accordingly
    // Example implementation:
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const days = []
    let current = start

    while (current.isBefore(end) || current.isSame(end)) {
      days.push(current.format('MMM DD,YYYY'))
      current = current.add(1, 'day')
    }

    return days
  }

  getCompetitionsList = async (datas = []) => {
    const eventLists = []

    try {
      for (const item of datas) {
        if (_.get(item, 'duration_competition', '') === '2') {
          const sessionDates = await this.onbetweenDates(
            _.get(item, 'start_date'),
            _.get(item, 'end_date')
          )

          sessionDates.forEach((date) => {
            const eventObject = {
              type: 'warning', // Add your type logic here
              content: (
                <Link
                  to={`${routeName.tutorCampView}/${_.get(item, 'session_name')}/?gid=${_.get(item, 'group_id')}`}
                >
                  {_.get(item, 'session_name')}
                </Link>
              ),
              date: dayjs(date),
            }

            eventLists.push(eventObject)
          })
        } else {
          const individualDates = dayjs(_.get(item, 'end_date')).format(
            'MMM DD,YYYY'
          )
          eventLists.push({
            type: 'success', // Add your type logic here
            content: (
              <Tooltip
                title={
                  _.get(item, 'session_name').length > 20
                    ? _.get(item, 'session_name')
                    : ''
                }
              >
                <Link
                  to={`${routeName.competitionsView}/${_.get(item, 'session_name')}/?gid=${_.get(item, 'group_id')}`}
                >
                  {_.get(item, 'session_name')}
                </Link>
              </Tooltip>
            ),
            date: dayjs(individualDates),
          })
        }
      }
    } catch (error) {
      console.log(
        'Error at calendar.jsx in getCompetitionsList function',
        error
      )
    }

    this.setState({
      competitionsevents: eventLists,
    })
  }

  getEventsList = async (datas = []) => {
    const eventLists = []

    try {
      for (const item of datas) {
        if (_.get(item, 'duration_competition', '') === '2') {
          const sessionDates = await this.onbetweenDates(
            _.get(item, 'start_date'),
            _.get(item, 'end_date')
          )
          console.log('sessionDates', sessionDates)
          sessionDates.forEach((date) => {
            const eventObject = {
              type: 'warning', // Add your type logic here
              content: (
                <Link
                  to={`${routeName.eventsView}/${_.get(item, 'session_name')}/?gid=${_.get(item, 'group_id')}`}
                >
                  {_.get(item, 'session_name')}
                </Link>
              ),
              date: dayjs(date),
            }

            eventLists.push(eventObject)
          })
        } else {
          const individualDates = dayjs(_.get(item, 'end_date')).format(
            'MMM DD,YYYY'
          )
          console.log('individualDates', individualDates)

          eventLists.push({
            type: 'success', // Add your type logic here
            content: (
              <Tooltip
                title={
                  _.get(item, 'session_name').length > 20
                    ? _.get(item, 'session_name')
                    : ''
                }
              >
                <Link
                  to={`${routeName.eventsView}/${_.get(item, 'session_name')}/?gid=${_.get(item, 'group_id')}`}
                >
                  {_.get(item, 'session_name')}
                </Link>
              </Tooltip>
            ),
            date: dayjs(individualDates),
          })
        }
      }
    } catch (error) {
      console.log(
        'Error at calendar.jsx in getEventsList function',
        error
      )
    }

    this.setState({
      eventsevents: eventLists,
    })
  }

  getClassList = (data = [], holiday = []) => {
    try {
      this.props.resetTutorClassDetails()
      var eventLists = []

      data.forEach((item) => {
        let sessionDates = _.get(item, 'session_dates', [])
        sessionDates.forEach((date) => {
          var object = {
            title: (
              <Tooltip
                title={
                  _.get(item, 'session_name').length > 20
                    ? _.get(item, 'session_name')
                    : ''
                }
              >
                <Link
                  to={`${routeName.tutorClassView}/${encodeURIComponent(_.get(item, 'session_name'))}/?gid=${_.get(item, 'group_id')}`}
                >
                  {_.get(item, 'session_name')}
                </Link>
              </Tooltip>
            ),
            start: dayjs(date),
            end: dayjs(date),
          }
          eventLists.push(object)
        })
      })

      if (holiday && _.isArray(holiday) && holiday.length > 0) {
        var classHolidays = holiday.reduce((unique, o) => {
          if (
            !unique.some(
              (obj) =>
                obj.date === o.date &&
                obj.end_date === o.end_date &&
                obj.description === o.description
            )
          ) {
            unique.push(o)
          }
          return unique
        }, [])
      }

      if (classHolidays && classHolidays.length > 0) {
        classHolidays.forEach((item) => {
          var holidays = [item]
          if (item && _.isArray(holidays)) {
            let start_date = new Date(item.date).setHours(0, 0, 0, 0)
            let end_date = new Date(item.date).setHours(23, 59, 59, 0)
            var object = {
              title: (
                <span className="btn-event btn-holiday">
                  {item.description}
                </span>
              ),
              start: new Date(start_date),
              end: new Date(end_date),
            }
            eventLists.push(object)
          }
        })
      }

      this.setState({
        events: eventLists,
      })
    } catch (error) {
      console.log('Error at calendar.jsx in getClassList function', error)
    }
  }

  getcampsList = async (datas = []) => {
    try {
      var eventLists = []

      datas.forEach((item) => {
        let sessionDates = _.get(item, 'session_dates', []) // Get the array of session dates
        if (sessionDates && sessionDates.length > 0) {
          // Iterate over the sessionDates array
          sessionDates.forEach((date) => {
            var object = {
              title: (
                <Tooltip
                  title={
                    _.get(item, 'session_name').length > 20
                      ? _.get(item, 'session_name')
                      : ''
                  }
                >
                  <Link
                    to={`${routeName.tutorCampView}/${_.get(item, 'session_name')}/?gid=${_.get(item, 'group_id')}`}
                  >
                    {_.get(item, 'session_name')}
                  </Link>
                </Tooltip>
              ),
              start: dayjs(date),
              end: dayjs(date),
            }
            eventLists.push(object)
          })
        }
      })

      await this.setState({
        campsevents: eventLists,
      })
    } catch (error) {
      console.log('Error at calendar.jsx in getcampsList function', error)
    }
  }

  dateCellRender = (value) => {
    const listData = this.state.competitionsevents.filter((event) =>
      dayjs(event.date).isSame(value, 'day')
    )

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.content}</li>
        ))}
      </ul>
    )
  }

  dateCellRender3 = (value) => {
    const listData = this.state.eventsevents.filter((event) =>
      dayjs(event.date).isSame(value, 'day')
    )

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.content}</li>
        ))}
      </ul>
    )
  }

  dateCellRender1 = (value) => {
    const listData = this.state.events.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    )

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    )
  }

  dateCellRender2 = (value) => {
    const listData = this.state.campsevents.filter((event) =>
      dayjs(event.start).isSame(value, 'day')
    )

    return (
      <ul className="events">
        {listData.map((item, index) => (
          <li key={index}>{item.title}</li>
        ))}
      </ul>
    )
  }

  render() {
    return (
      <div
        className="Tutor_Dashboard_Event_COntainer mb-4"
        style={{ position: 'relative' }}
      >
        <div className="btn-tutor-modal">
          {/* <Button className="btn-public-holidays" type='primary' onClick={this.showModal}>
            {this.props.t('publicHol')}
          </Button> */}
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
          <Modal
            title="Public Holidays"
            open={this.state.visible}
            onOk={this.handleOk}
            onDelete={this.handleDelete}
            onCancel={this.handleCancel}
            footer={null}
          >
            <div className="public-holidays-row border-bottom">
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <DatePicker
                    format={'MM/DD/YYYY'}
                    onChange={(value) => this.holidayDates(value)}
                    selected={this.state.holidayDate}
                    value={this.state.holidayDateValue}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent form submission
                      }
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
                  <Input
                    name="name"
                    id="name"
                    value={this.state.holidayText}
                    onChange={(e) => this.holidayMsg(e.target.value)}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                  {(() => {
                    if (
                      this.state.holidayText &&
                      this.state.holidayText.length > 0 &&
                      this.state.holidayText &&
                      this.state.holidayText.length > 0
                    ) {
                      return (
                        <Button
                          type="primary"
                          className="mr-2"
                          onClick={(e) => this.handleOk(e.target.value)}
                        >
                          {this.props.t('add')}
                        </Button>
                      )
                    } else {
                      return (
                        <Button type="primary" className="mr-2">
                          {this.props.t('add')}
                        </Button>
                      )
                    }
                  })()}
                </div>
              </div>
            </div>

            <Scrollbars
              style={{ height: 300 }}
              className="CustomScrollbars_holidays"
            >
              {(() => {
                if (
                  this.props.tutorBaseProfile &&
                  this.props.tutorBaseProfile.details &&
                  this.props.tutorBaseProfile.details.public_holidays
                ) {
                  let holidays =
                    this.props.tutorBaseProfile.details.public_holidays
                  return holidays.map((value, index) => (
                    <div
                      className="public-holidays-results border-bottom"
                      key={index}
                    >
                      <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-5 col-xl-5">
                          <div className="text-result">{value.date}</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <div className="text-result">{value.description}</div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 text-right">
                          <Button
                            type="danger"
                            ghost
                            onClick={() => this.handleDelete(value)}
                          >
                            {this.props.t('delete')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                }
              })()}
            </Scrollbars>
          </Modal>
        </div>{' '}
        {this.props.loading ? (
          <div className="BigCalendarplaceholder">
            <AiOutlineCalendar />
          </div>
        ) : this.props.sessionsLoadingClass ? (
          <div className="BigCalendarplaceholder">
            <Spin size="large" />
          </div>
        ) : (
          <Tabs className="w-100" defaultActiveKey="1" onChange={callback}>
            <items tab={this.props.t('tutorSidebarMyClasses')} key="1">
              <Calendar cellRender={this.dateCellRender1} />
            </items>
            <items tab={this.props.t('tutorSidebarMyCamps')} key="2">
              <Calendar cellRender={this.dateCellRender2} />
            </items>
            <items tab={this.props.t('tutorSidebarMyCompetitions')} key="3">
              <Calendar cellRender={this.dateCellRender} />
            </items>
            <items tab={this.props.t('tutorSidebarMyEvents')} key="4">
              <Calendar cellRender={this.dateCellRender3} />
            </items>
          </Tabs>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    tutorBaseProfile: state.tutorProfile.tutorBaseProfile,
    loading: state.tutorProfile.loading,
    providerClass: state.classesDetails.tutorClasses,
    loggedInUserDetails: state.authentication,
    sessionList: state.common.sessionList,
    sessionListClass: state.common.sessionListClass,
    sessionsLoadingClass: state.common.sessionsLoading,
    sessionListComp: state.common.sessionListComp,
    sessionListEvent: state.common.sessionListEvent,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onTutorProfile: (data) => dispatch(tutoractions.tutorProfile(data)),
    // getProviderClasses: () => dispatch(actions.getProviderClasses())
    resetTutorClassDetails: () => dispatch(actions.resetTutorClassDetails()),
    onBusinessProfileUpdate: (data) =>
      dispatch(tutoractions.onBusinessProfileUpdate(data)),
    fetchLocations: () => dispatch(action.fetchLocations()),
    getProviderClasses: () => dispatch(actions.getProviderClasses()),
    getSessionListFor: (requestParams, type) =>
      dispatch(commonAction.onGetsessionList(requestParams, type)),
    getSessionListForClass: (requestParams, type) =>
      dispatch(commonAction.onGetsessionListClass(requestParams, type)),
    getSessionListForCompetitions: (requestParams) =>
      dispatch(commonAction.onGetsessionListComp(requestParams)),
    getSessionListForEvents: (requestParams) =>
      dispatch(commonAction.onGetsessionListEvents(requestParams)),
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLoaderHandler(withTranslation()(MyAntdCalendar), axios, CalendarLoader))
