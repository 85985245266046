import React, { Component } from 'react'
// import '../node_modules/font-awesome/css/font-awesome.css';
import './assets/fonts/nurtem/style.css'
import './resources/styles/Calendar-Style.css'
import 'placeholder-loading/dist/css/placeholder-loading.css'
import './resources/styles/bootstrap.css'
import './App.css'
import WebLayout from './pages/weblayout'
import * as Sentry from '@sentry/browser'
import config from './config.json'
import NoInternetConnection from './NoInternetConnection '

class App extends Component {
  componentDidMount() {
    document.title = 'Nurtem'
    document.title = 'Nurtem - Your Learning Partner'
    let sentry_config = {
      ...config.SENTRY.CONFIGURATION,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'Network Error',
        'Request failed with status code 500',
        'Request failed with status code 400',
        'ResizeObserver loop completed with undelivered notifications.',
        'Request failed with status code 429',
      ],
      debug: true,
    }
    // Sentry.init({
    //   dsn: config.SENTRY.dsn,
    //   integrations: [
    //     Sentry.browserTracingIntegration(),
    //     Sentry.metrics.metricsAggregatorIntegration(),
    //     Sentry.reactRouterV6BrowserTracingIntegration({
    //       useEffect: React.useEffect,
    //     }),
    //     Sentry.replayIntegration({
    //       maskAllText: false,
    //       blockAllMedia: false,
    //     }),
    //   ],
    //   ignoreErrors: [
    //     'ResizeObserver loop limit exceeded',
    //     'Network Error',
    //     'Request failed with status code 500',
    //     'Request failed with status code 400',
    //     'ResizeObserver loop completed with undelivered notifications.',
    //     'Request failed with status code 429',
    //   ],
    //   environment: 'sq-development',
    //   tracesSampleRate: 1.0,
    //   tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    //   replaysSessionSampleRate: 0.1,
    //   replaysOnErrorSampleRate: 1.0,
    // })
    Sentry.init(sentry_config)
  }

  render() {
    return (
      <>
        <NoInternetConnection>
          <WebLayout />
        </NoInternetConnection>
      </>
    )
  }
}

export default App
