import React from 'react';
import '../privacy-policy/privacy-policy.css';

class TermsAndConditions extends React.Component {
  render() {
    return (
      <div className="privacy-policy-page-container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <h2 className="title">{this.props.t('titleTerms')}</h2>
            <h4>{this.props.t('welcomeTerms')}</h4>
            <p>{this.props.t('contentTerms')}</p>
            <p>{this.props.t('additionalTerms')}</p>
            <p>{this.props.t('agreeTerms')}</p>
            <p>{this.props.t('agreeTerms1')}</p>
            <p>{this.props.t('agreeTerms2')}</p>
            <h4>
              {this.props.t('agreeTerms3')}
            </h4>
            <p>
              {this.props.t('agreeTerms4')}
            </p>
            <p>
              {this.props.t('agreeTerms5')}

            </p>
            <p>
              {this.props.t('agreeTerms7')}

            </p>
            <h4>{this.props.t('agreeTerms8')}</h4>
            <p>
              {this.props.t('agreeTerms9')}
            </p>
            <p>
              {this.props.t('agreeTerms10')}
            </p>
            <p>
              {this.props.t('agreeTerms11')}
            </p>
            <h4>{this.props.t('agreeTerms12')}</h4>
            <p>
              {this.props.t('agreeTerms13')}
            </p>
            <p>
              {this.props.t('agreeTerms14')}
            </p>
            <p>
              {this.props.t('agreeTerms15')}
            </p>
            <p>
              {this.props.t('agreeTerms16')}
            </p>
            <p>
              {this.props.t('agreeTerms17')}
            </p>
            <p>
              {this.props.t('agreeTerms18')}

            </p>
            <p>
              {this.props.t('agreeTerms19')}
            </p>
            <p>
              {this.props.t('agreeTerms20')}
              <br />
              <span>&lt;</span>
              {this.props.t('agreeTerms21')}
              <span>&gt;</span>
              {' '}
              <br />
              Email:&nbsp;
              <b>{this.props.t('agreeTerms22')}</b>
            </p>
            <h4>{this.props.t('agreeTerms23')}</h4>
            <h6>{this.props.t('agreeTerms24')}</h6>
            <p>
              {this.props.t('agreeTerms25')}
            </p>
            <h6>{this.props.t('agreeTerms26')}</h6>
            <p>
              {this.props.t('agreeTerms27')}
            </p>
            <p>
              {this.props.t('agreeTerms28')}
            </p>
            <h6>{this.props.t('agreeTerms29')}</h6>
            <p>
              {this.props.t('agreeTerms30')}

            </p>
            <h4>{this.props.t('agreeTerms31')}</h4>
            <h6>{this.props.t('agreeTerms32')}</h6>
            <p>
              {this.props.t('agreeTerms33')}
            </p>
            <h6>{this.props.t('agreeTerms34')}</h6>
            <p>
              {this.props.t('agreeTerms35')}
            </p>
            <h6>{this.props.t('agreeTerms36')}</h6>
            <p>
              {this.props.t('agreeTerms37')}
            </p>
            <h6>{this.props.t('agreeTerms38')}</h6>
            <p>
              {this.props.t('agreeTerms39')}
            </p>
            <h4>{this.props.t('agreeTerms40')}</h4>
            <p>
              {this.props.t('agreeTerms41')}
            </p>

            <h4>{this.props.t('agreeTerms42')}</h4>
            <p>
              {this.props.t('agreeTerms43')}
            </p>
            <h4>{this.props.t('agreeTerms44')}</h4>
            <p>
              {this.props.t('agreeTerms45')}
            </p>
            <h4>{this.props.t('agreeTerms46')}</h4>
            <p>
              {this.props.t('agreeTerms47')}
            </p>
            <p>
              {this.props.t('agreeTerms48')}
            </p>
            <p>
              {this.props.t('agreeTerms49')}
            </p>
            <h4>{this.props.t('agreeTerms48')}</h4>
            <p>
              {this.props.t('agreeTerms51')}
            </p>
            <h6>{this.props.t('agreeTerms52')}</h6>
            <p>
              {this.props.t('agreeTerms53')}
            </p>
            <h6>{this.props.t('agreeTerms54')}</h6>
            <p>
              {this.props.t('agreeTerms55')}
            </p>
            <h6>{this.props.t('agreeTerms56')}</h6>
            <p>
              {this.props.t('agreeTerms57')}
            </p>
            <h6>{this.props.t('agreeTerms58')}</h6>
            <p>
              {this.props.t('agreeTerms59')}
            </p>
            <p>
              {this.props.t('agreeTerms60')}
            </p>
            <p>
              {this.props.t('agreeTerms61')}
            </p>
            <p>
              {this.props.t('agreeTerms62')}
            </p>
            <h6>{this.props.t('agreeTerms63')}</h6>
            <p>
              {this.props.t('agreeTerms64')}
            </p>
            <h4>{this.props.t('agreeTerms65')}</h4>
            <p>
              {this.props.t('agreeTerms66')}
            </p>
            <h6>{this.props.t('agreeTerms67')}</h6>
            <p>
              <b>{this.props.t('agreeTerms68')}</b>
              .&nbsp;
              {this.props.t('agreeTerms69')}
&nbsp;
            </p>
            <p>
              <b>{this.props.t('agreeTerms70')}</b>
              .&nbsp;
              {this.props.t('agreeTerms71')}
              .&nbsp;
            </p>
            <p>
              <b>{this.props.t('agreeTerms72')}</b>
              .&nbsp;
              {this.props.t('agreeTerms73')}
&nbsp;
            </p>
            <p>
              <b>{this.props.t('agreeTerms74')}</b>
              .&nbsp;
              {this.props.t('agreeTerms75')}
            </p>
            <h6>
              {' '}
              {this.props.t('agreeTerms76')}
              &amp;
              {this.props.t('agreeTerms77')}
            </h6>
            <p>
              {this.props.t('agreeTerms78')}
            </p>
            <p>
              {this.props.t('agreeTerms79')}
            </p>
            <p>
              {this.props.t('agreeTerms80')}
              {' '}
            </p>
            <h6>{this.props.t('agreeTerms81')}</h6>
            <p>
              {this.props.t('agreeTerms82')}
            </p>
            <p>
              {this.props.t('agreeTerms83')}
            </p>
            <p>
              {this.props.t('agreeTerms84')}
            </p>
            <p>
              {this.props.t('agreeTerms85')}
              <br />
              {this.props.t('agreeTerms86')}
              <br />
              {this.props.t('agreeTerms87')}
              {' '}
              <span>&lt;</span>
              <span>&gt;</span>
            </p>
            <p>
              {this.props.t('agreeTerms88')}
              {' '}
              <br />
              {this.props.t('email')}
              :&nbsp;
              <b>{this.props.t('agreeTerms89')}</b>
              .
            </p>
            <p>
              {' '}
              {this.props.t('agreeTerms90')}
              <span>&lt;</span>
              {this.props.t('agreeTerms91')}
              <span>&gt;</span>
              ,
              {this.props.t('agreeTerms92')}
            </p>

          </div>
        </div>
      </div>
    );
  }
}

export default TermsAndConditions;
