// import React from 'react';
import _ from 'lodash';
import parser from 'html-react-parser';
import { NurtemUtils } from '../../../utils';

const seatsLeft = async (st, props) => {
  let total_seats_left = 0;
  let max_students = st.get('no_of_max_students').value();
  let total_seats_filled = _.get(props.classesStudents, 'meta.total_count', 0);

  if (props.type === 'camps') {
    total_seats_filled = _.get(props.campsStudents, 'meta.total_count', 0);
  } else if (props.type === 'competitions') {
    total_seats_filled = _.get(props.competitionStudents, 'meta.total_count', 0);

    if (st.get('teaching_type').value() !== 'i') {
      max_students = st.get('max_no_of_participants_in_team').value();
    }
  }

  return total_seats_left = max_students - total_seats_filled;
};

const fixHtml = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return (div.innerHTML);
};
const parseHtmlStringFromSubstring = (htmlString, startChar = 0, endChar = htmlString.length) => {
  if (typeof htmlString === 'string') {
    const substring = htmlString.substring(startChar, endChar);
    const fixedHtml = fixHtml(substring);
    return parser(NurtemUtils.urlify(fixedHtml));
  }
  const fixedHtml = '<div> </div';
  return parser(NurtemUtils.urlify(fixedHtml));
};

const isCampFull = (campDetails) => {
  if (!_.isEmpty(campDetails) && !_.isEqual(_.toNumber(_.get(campDetails, 'no_of_max_students', 0)), _.toNumber(_.get(campDetails, 'no_of_students_paid', 0)))) {
    return true;
  }
  return false;
};
export default {
  parseHtmlStringFromSubstring,
  isCampFull,
  seatsLeft,
};
