import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import './student-list.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DEFAULTUSER } from '../../utils/config/assets';
import * as competitionActions from '../../store/actions/tutor/competitions';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';

const StudentList = (props) => {
  const columns = [
    {
      title: props.t('participants'),
      dataIndex: 'participants',
      key: 'participants',
    },
  ];
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (props.session && props.session !== '') { // Only send the API request if competition_details is present or changed
      if (props.type === 'competition') {

        const notesParams = {
          competition_details: props.session,
          limit: 100,
          status: 1,
          page,
          sort: 'created_at.desc',
          expand: 'competition_details,user,dependent_user',
        };

        const callback = async () => await props.getCompetitionStudents(notesParams);
        callback();
      } else if (props.type === 'event') {
        const notesParams = {
          event_details: props.session,
          limit: 100,
          status: 1,
          page,
          sort: 'created_at.desc',
          expand: 'event_details,user,dependent_user',
        };

        const callback = async () => await props.onGetEventStudents(notesParams);
        callback();
      }
    }
  }, [props.session]);

  const pageChange = async (page, pageSize) => {
    await setPage(page);
    const notesParams = {
      competition_details: props.session,
      limit: 100,
      status: 1,
      page,
      sort: 'created_at.desc',
      expand: 'competition_details,user,dependent_user',
    };
    await props.getCompetitionStudents(notesParams);
  };

  const customLocale = {
    emptyText: (<div>
      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

      <p className="empty-animations">{props.t('noParti')}</p>
    </div>),
  };

  const render = () => {
    let tableDatatest = [];
    if (
      props.competitionStudents
      && props.competitionStudents.items
      && props.competitionStudents.items.length > 0
    ) {
      const studentslistfilters = _.uniqBy(_.get(props, 'competitionStudents.items'), (n) => (!_.isEmpty(n.dependent_user) ? n.dependent_user.id : n.user && n.user.id));

      tableDatatest = _.size(studentslistfilters) > 0 && studentslistfilters.map((value, index) => {
        const name_title_value = value && _.isEmpty(value.dependent_user) ? value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}` : _.startCase(value.dependent_user.firstname);
        const userphoto = value && _.get(value, 'user.photo');
        return {
          key: value.id,
          participants: <div className="table-profile">
            <div>
              <img src={userphoto || DEFAULTUSER} className="profile-image" alt="img" />
            </div>
            <div className="text-truncate" title={name_title_value}>
              {value && _.isEmpty(value.dependent_user)
                ? (value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}`)
                : _.startCase(value.dependent_user.firstname)}
              {_.get(value, 'status', 0) === 0 ? '( Awaiting for response )' : ''}
            </div>
          </div>,
          paid: _.get(value, 'payment_details.payment_source') === 'offline'
            ? _.startCase(_.get(value, 'payment_details.payment_object.payment_mehtod'))
            : _.startCase(_.get(value, 'payment_details.payment_object.payment_method_details.type')),
          // contact: <div>{_.get(value, 'user.mobile_number', '')}</div>,
          // message:
          //   <div className="row">
          //     {/* <Button className="btn-view-outline">View</Button> */}
          //     <Button className="btn-view-outline" style={{ padding: '0', width: '50px' }}><Link
          //       to={`/tutor/student-view/${_.get(value, 'user.id')}?sid=${_.get(value, 'id')}`}
          //     >View</Link></Button>

          // </div>,

        };
      });
    }

    return (
      <div className="container">
        {/* items Header */}
        <div className="row mb-3">
          <div className=" col-6 tab-content-title">{props.t('partiList')}</div>
          {/* <div className='col-6' style={{ textAlign: 'end' }}>
            <Button onClick={() => viewAttendance(props.session)} className="btn-green-filled">View Attendance</Button>
          </div> */}
        </div>
        <div className="row" style={{ margin: 0 }}>
          <Table
            loading={_.get(props, 'CompetitionsStudentListLoading', false)}
            dataSource={tableDatatest}
            columns={columns}
            style={{ width: '100%' }}
            locale={customLocale}
            pagination={{
              pageSize: 100,
              onChange: (page, pageSize) => pageChange(page, pageSize),
              total:
                props.competitionStudents
                && props.competitionStudents.meta
                && parseInt(props.competitionStudents.meta.total_count),
              hideOnSinglePage: !props.loading || props.loading,
              showSizeChanger: false,
              current: page,
            }}
          />
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  competitionStudents: state.competitionsDetails.competitionStudents,
  eventStudents: state.competitionsDetails.eventStudents,
  CompetitionsStudentListLoading: state.competitionsDetails.CompetitionsStudentListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getCompetitionStudents: (requestParams) => dispatch(competitionActions.getCompetitionStudents(requestParams)),
  onGetEventStudents: (requestParams) => dispatch(competitionActions.getEventStudents(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentList));
