import * as actionTypes from '../../actions/authentication/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: false,
  enableStudentLoginLoading: false,
  enableProviderLoginLoading: false,
  resetPasswordResponse: {},
  passwordCheckResponse: false,
  changePasswordResponse: {},
  goBack: false,
  loginSignupTab: '',
  loginSignupAdminApproval: '',
  onSignupCodeVerificationState: '',
  signUpSuccess: {},
  signInHandleVerification: false,
};

const onGoBack = (state, action) => ({ ...state, goBack: action.goBack });

const signInSuccess = (state, action) => updateObject(state, action.success);
const signUpSuccess = (state, action) => updateObject(state, action.success);
const loginSignupTab = (state, action) => ({ ...state, loginSignupTab: action.success });
const signInHandleVerification = (state, action) => ({ ...state, signInHandleVerification: action.success });
const loginSignupAdminApproval = (state, action) => ({ ...state, loginSignupAdminApproval: action.success });
const onSignupCodeVerificationState = (state, action) => ({ ...state, onSignupCodeVerificationState: action.success });
const SignupCodeVerificationResend = (state, action) => ({ ...state, SignupCodeVerificationResend: action.success });
const resetPasswordResponse = (state, action) => ({ ...state, resetPasswordResponse: action.success });
const passwordCheckResponse = (state, action) => ({ ...state, passwordCheckResponse: action.success });
const changePasswordResponse = (state, action) => ({ ...state, changePasswordResponse: action.success });
const enableLoading = (state, action) => updateObject(state, action.loading);
const enableStudentLoginLoading = (state, action) => enableStudentLoginLoading(state, action.loading);
const enableProviderLoginLoading = (state, action) => enableProviderLoginLoading(state, action.loading);
const disableLoading = (state, action) => updateObject(state, action.loading);
const isUserVerified = (state, action) => updateObject(state, action.data);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.onGoBack:
      return onGoBack(state, action);
    case actionTypes.signInSuccess:
      return signInSuccess(state, action);
    case actionTypes.signUpSuccess:
      return signUpSuccess(state, action);
    case actionTypes.loginSignupTab:
      return loginSignupTab(state, action);
    case actionTypes.signInHandleVerification:
      return signInHandleVerification(state, action);
    case actionTypes.loginSignupAdminApproval:
      return loginSignupAdminApproval(state, action);
    case actionTypes.onSignupCodeVerificationState:
      return onSignupCodeVerificationState(state, action);
    case actionTypes.SignupCodeVerificationResend:
      return SignupCodeVerificationResend(state, action);
    case actionTypes.enableLoading:
      return enableLoading(state, action);
    case actionTypes.disableLoading:
      return disableLoading(state, action);
    case actionTypes.resetPasswordResponse:
      return resetPasswordResponse(state, action);
    case actionTypes.passwordCheckResponse:
      return passwordCheckResponse(state, action);
    case actionTypes.changePasswordResponse:
      return changePasswordResponse(state, action);
    case actionTypes.isUserVerified:
      return isUserVerified(state, action);
    case actionTypes.enableStudentLoginLoading:
      return enableStudentLoginLoading(state, action);
    case actionTypes.enableProviderLoginLoading:
      return enableProviderLoginLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
