import React from 'react'
import { Modal, notification } from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import queryString from 'query-string'
import * as classActions from '../../../store/actions/tutor/camps'
import * as routeName from '../../../components/route-names'
import ClassDetailsView from './detail-view'
import history from '../../../utils/history'

class CampDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 3,
    }
  }

  async componentDidUpdate(prevPros) {
    if (
      prevPros.deleteClassInfo !== this.props.deleteClassInfo &&
      this.props.deleteClassInfo &&
      this.props.deleteClassInfo.data &&
      this.props.deleteClassInfo.status === 200
    ) {
      Modal.destroyAll()
    } else if (
      prevPros.deleteClassInfo !== this.props.deleteClassInfo &&
      this.props.deleteClassInfo.data
    ) {
      Modal.destroyAll()
      notification.error({
        message: 'Error',
        description: this.props.deleteClassInfo.data.message,
      })
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  onDeleteConfirm = async (classId) => {
    this.props.onClearCampDeleteDetail()
    await this.setState({
      classId,
    })
    try {
      const currentThis = this
      const requestParams = {}
      requestParams.id = classId
      currentThis.props.onDeleteCamp(requestParams)
    } catch (error) {
      console.log('Oops errors!', error)
    }
    // Modal.confirm({
    //     keyboard: false,
    //     maskClosable: false,
    //     title: 'Are you sure to delete this camp?',
    //     okText: 'Yes',
    //     okType: 'danger',
    //     cancelText: 'No',
    //     cancelButtonProps: {
    //         disabled: this.props.loading
    //     },
    //     onOk() {
    //         return new Promise((resolve, reject) => {
    //             let requestParams = {}
    //             requestParams.id = classId
    //             currentThis.props.onDeleteCamp(requestParams)
    //         }).catch(() => console.log('Oops errors!'))
    //     }
    // })
  }

  pageRedirect = (value) => {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)
    if (urlparams.tutor) {
      history.push(
        `${routeName.TutorUpdateCamp}?id=${value}&type=update&tutor=${urlparams.tutor}`
      )
    } else {
      history.push(`${routeName.TutorUpdateCamp}?id=${value}&type=update`)
    }
  }

  render() {
    const url = this.props.location.search
    const urlparams = queryString.parse(url)

    // if no camp id in url then redirect to camp list
    if (urlparams && !urlparams.gid) {
      history.push(`${routeName.tutorCamps}`)
    }

    return (
      <div className="full-width tutor-my-camp-details-page pb-4 mb-3">
        <ClassDetailsView
          {...this.props}
          onDeleteClass={(id) => this.onDeleteConfirm(id)}
          onEditClass={(id) => this.pageRedirect(id)}
          classDetails={this.props.singleClassView}
          state={this.state}
          deleteModal={(id) => this.deleteModal(id)}
        />
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  deleteClassInfo: state.campsDetails.deleteCampsInfo,
  loadingCampDetails: state.campsDetails.loadingCampDetails,
  singleClassView: state.campsDetails.tutorCampsDetails
    ? state.campsDetails.tutorCampsDetails.details
    : {},
})

const mapDispatchToProps = (dispatch) => ({
  onDeleteCamp: (requestParams) =>
    dispatch(classActions.onDeleteCamps(requestParams)),
  onClearCampDeleteDetail: () =>
    dispatch(classActions.onClearCampsDeleteDetail()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CampDetails))
