import React, { useEffect, useRef, useState } from 'react'
import { DatePicker, Form, Select, Button } from 'antd'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import * as commonActions from '../../../store/actions/tutor/common'
import * as classActions from '../../../store/actions/tutor/Classes'
import * as action from '../../../store/actions/tutor/camps'
import generalAction from '../../../store/general/action'
import './attendance.css'
import FiltersReport from './report'
import css from '../../../utils/CSSUtility'
import StudentOnboard from '../onboarding-wizard/student-onboard'

const { RangePicker } = DatePicker

const AttendancesFilter = (props) => {
  const [form] = Form.useForm()
  const mounted = useRef()
  const [provider, setProvider] = useState('')
  const [classname, setClassName] = useState('')
  const [campname, setCampName] = useState('')
  const [student, setStudent] = useState('')
  const [filterDetails, setFilterDetails] = useState({})
  const [filterClear, setFilterClear] = useState(false)
  const [refreshKey, setRefreshKey] = useState(0)
  const [classes, setClasses] = useState([])
  const [camps, setCamps] = useState([])
  const [tutorIdForFilter, setTutorIdForFilter] = useState('')
  const [classType, setclassType] = useState('classes')
  const [classDetailsLoading, setclassDetailsLoading] = useState(false)
  const [stuentListopt, setstuentListopt] = useState('')
  const students = _.get(props, 'students.items', [])
  const ForComponent = _.get(props, 'ForComponent', null)

  useEffect(() => {
    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = ''
        let name = ''
        let type
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id')
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`
          type = 'dependent_user'
        } else {
          id = _.get(each, 'user.id')
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`
          type = 'user'
        }

        return (
          <Select.Option value={id} key={`${type}_${id}`}>
            {name}
          </Select.Option>
        )
      })

      studentList.unshift(
        <Select.Option value="" key="allstudents">
          {props.t('all')}
        </Select.Option>
      )
      setstuentListopt(studentList)
    }
  }, [_.get(props, 'students.items')])

  useEffect(() => {
    const students = _.get(props, 'campsStudents.items', [])

    if (_.size(students) > 0) {
      const studentList = _.map(students, (each, i) => {
        let id = ''
        let name = ''
        let type
        if (_.get(each, 'dependent_user.id')) {
          id = _.get(each, 'dependent_user.id')
          name = `${_.startCase(_.get(each, 'dependent_user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`
          type = 'dependent_user'
        } else {
          id = _.get(each, 'user.id')
          name = `${_.startCase(_.get(each, 'user.firstname', '').trim())} ${_.startCase(_.get(each, 'user.lastname', '').trim())}`
          type = 'user'
        }

        return (
          <Select.Option value={id} key={`${type}_${id}`}>
            {name}
          </Select.Option>
        )
      })

      studentList.unshift(
        <Select.Option value="" key="allstudents">
          {props.t('all')}
        </Select.Option>
      )
      setstuentListopt(studentList)
    }
  }, [_.get(props, 'campsStudents.items')])

  useEffect(() => {
    if (!mounted.current) {
      if (_.get(props, 'businessTutorBaseProfile.details.id')) {
        const requestParams = {}
        requestParams.limit = 1000
        requestParams.works_for = _.get(
          props,
          'businessTutorBaseProfile.details.id'
        )
        requestParams.sort = 'created_at.desc'
        requestParams.expand = 'update_progress'

        props.gettutorlist({ params: requestParams })
      }
      mounted.current = true
    }
  }, [refreshKey, _.get(props, 'sessionListClass.items')])

  useEffect(() => {
    if (!mounted.current) {
      if (_.get(props, 'businessTutorBaseProfile.details.id')) {
        const requestParams = {}
        requestParams.limit = 1000
        requestParams.works_for = _.get(
          props,
          'businessTutorBaseProfile.details.id'
        )
        requestParams.sort = 'created_at.desc'
        requestParams.expand = 'update_progress'

        props.gettutorlist({ params: requestParams })
      }
      mounted.current = true
    }
  }, [refreshKey, _.get(props, 'sessionList.items')])

  useEffect(() => {
    if (classType === 'classes') {
      setClasses(_.uniqBy(_.get(props, 'sessionListClass.items'), 'id'))
      setclassDetailsLoading(false)
    }
  }, [classType, _.get(props, 'sessionListClass.items')])

  useEffect(() => {
    if (classType === 'camps') {
      setCamps(_.uniqBy(_.get(props, 'sessionList.items'), 'id'))
      setclassDetailsLoading(false)
    }
  }, [classType, _.get(props, 'sessionList.items')])

  const onFinish = async (values) => {
    if (!_.isEmpty(_.get(values, 'date'))) {
      const start_date =
        !_.isEmpty(_.get(values, 'date')) &&
        dayjs(_.get(values, 'date[0]')).format('MM-DD-YYYY')
      const end_date =
        !_.isEmpty(_.get(values, 'date')) &&
        dayjs(_.get(values, 'date[1]')).format('MM-DD-YYYY')

      values = {
        ...values,
        mark_date_gte: start_date,
        mark_date_lte: end_date,
      }
    }

    if (!_.isEmpty(student)) {
      if (_.isEqual(student, 'user')) {
        values = {
          ...values,
          user: values.user,
        }
      } else if (_.isEqual(student, 'dependent')) {
        values = {
          ...values,
          dependent_user: values.user,
        }
        delete values.user
      } else {
        delete values.user
      }
    }

    values = {
      ...values,
      expand:
        'user,class_information_details,provider,dependent_user,class_details,session_details,camp_details',
    }

    delete values.date
    await setFilterDetails(values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  const ontutorSelect = (tutorId, key) => {
    form.resetFields(['details', 'user'])
    setFilterDetails()
    setstuentListopt('')
    setTutorIdForFilter(tutorId)
    setProvider(_.get(key, 'children'))
  }

  const onclassSelect = (classId, key) => {
    form.resetFields(['user'])
    setFilterDetails()
    setClassName(_.get(key, 'children'))
    form.submit()
    const params = {
      class_details: classId,
      provider: tutorIdForFilter,
      list_type: 'works_for',
      limit: 1000,
      expand: 'user,class_details,provider,payment_details,dependent_user',
      s: Math.random(),
    }
    props.getstudentslist(params)
  }

  const oncampSelect = (classId, key) => {
    form.resetFields(['user'])
    setFilterDetails()
    setCampName(_.get(key, 'children'))
    form.submit()
    const notesParams = {
      camp_details: classId,
      provider: tutorIdForFilter,
      limit: 1000,
      status: 1,
      // page: page,
      sort: 'created_at.desc',
      expand: 'camp_details,user,provider,dependent_user',
      s: Math.random(),
    }
    props.getCampsStudents(notesParams)
  }

  const onDateSelect = () => {
    form.submit()
  }

  const onstudentSelect = (value, key) => {
    const st_type = _.first(_.split(_.get(key, 'key'), '_'))
    setStudent(st_type)
    form.submit()
  }

  const clearInput = () => {
    form.resetFields(['details', 'user', 'provider', 'date', 'type'])
    setFilterDetails()
    setFilterClear(true)
    setRefreshKey((oldKey) => oldKey + 1)
  }

  const render = () => {
    const tutors = [
      _.get(props, 'businessTutorBaseProfile.details'),
      ..._.get(props, 'tutorslist.items', []),
    ]
    const data = classType === 'classes' ? classes : camps

    return (
      <div className="row">
        <Form
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className="full-width"
        >
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="attendance-report mt-3">
              <div className="row">
                <div className="col-12 d-flex justify-content-between" style={{ height: '20px' }}>
                  <div className="attendance-title">
                    {ForComponent === 'attendance'
                      ? <span style={css.heading1}>{props.t('tutorSidebarAttendanceReport')}</span>
                      : null}
                  </div>
                  <Button
                    type='primary'
                    className="class-btn"
                    onClick={() => clearInput()}
                  >
                    {' '}
                    {props.t('clearFilter')}{' '}
                  </Button>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('tutorLabel')}</span>}
                      name="provider"
                      rules={[
                        { required: false, message: props.t('tutorValid') },
                      ]}
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('attRepoPlaceHolder')}
                        optionFilterProp="children"
                        allowClear
                        onSelect={ontutorSelect}
                      >
                        {_.size(tutors) > 0 &&
                          _.map(tutors, (each, i) => (
                            <Select.Option
                              value={_.get(each, 'id')}
                              key={_.get(each, 'id')}
                            >
                              {!_.isEmpty(_.get(each, 'businessname', ''))
                                ? _.startCase(_.get(each, 'businessname', ''))
                                : `${_.startCase(_.get(each, 'firstname', ''))} ${_.startCase(_.get(each, 'lastname', ''))}`}
                            </Select.Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-3">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{props.t('type')}</span>}
                      name="type"
                      rules={[
                        { required: false, message: props.t('clearFilter1') },
                      ]}
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        placeholder={props.t('clearFilter2')}
                        optionFilterProp="children"
                        allowClear
                        disabled={!(tutorIdForFilter.length > 0)}
                        onSelect={(e) => {
                          setclassType(e)
                          setclassDetailsLoading(true)
                          setFilterDetails()
                          form.resetFields(['details'])
                          if (e === 'classes') {
                            const requestParams = {
                              provider: tutorIdForFilter,
                              sort: 'created_at.desc',
                              type: 'classes',
                              limit: 1000,
                            }
                            props.getclasseslist(requestParams)
                          } else if (e === 'camps') {
                            const requestParamsSessions = {
                              provider: tutorIdForFilter,
                              sort: 'created_at.desc',
                              limit: 1000,
                              type: 'camps',
                            }
                            props.onGetSessionList(requestParamsSessions)
                          }
                        }}
                      >
                        <Select.Option value="classes" key="classes">
                          Classes
                        </Select.Option>
                        <Select.Option value="camps" key="camps">
                          Camps
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>

                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="full-width">
                    <Form.Item
                      label={<span style={css.label}>{_.upperFirst(classType)}</span>}
                      name="details"
                      className="mb-2"
                    >
                      <Select
                        showSearch
                        loading={classDetailsLoading}
                        placeholder={props.t('selectSessionName')}
                        optionFilterProp="children"
                        allowClear
                        onSelect={
                          classType === 'classes'
                            ? onclassSelect
                            : classType === 'camps'
                              ? oncampSelect
                              : () => { }
                        }
                      >
                        {_.map(data, (value, key) => (
                          <Select.Option value={_.get(value, 'id')} key={key}>
                            {_.get(value, 'session_name')}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                {ForComponent === 'attendance' && (
                  <>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="full-width">
                        <Form.Item
                          label={<span style={css.label}>{props.t('filterByDate')}</span>}
                          name="date"
                          rules={[
                            {
                              required: false,
                              message: props.t('filterBydateMsg'),
                            },
                          ]}
                          className="mb-4"
                        >
                          <RangePicker onChange={onDateSelect} />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                      <div className="full-width">
                        <Form.Item
                          label={<span style={css.label}>{props.t('filterByStu')}</span>}
                          name="user"
                          rules={[
                            {
                              required: false,
                              message: props.t('filterBystudentMsg'),
                            },
                          ]}
                          className="mb-4"
                        >
                          <Select
                            showSearch
                            placeholder={props.t('selectUserName')}
                            optionFilterProp="children"
                            allowClear
                            onSelect={onstudentSelect}
                          >
                            {stuentListopt}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Form>

        {/* Class Details Preview */}
        <div className="col-12">
          <div className="row">
            {!_.isEmpty(form.getFieldValue('provider')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {props.t('tutName')}: <span style={css.heading3}>{provider}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('class_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('className')}: <span style={css.heading3}>{classname}</span>
                </div>
              </div>
            )}
            {!_.isEmpty(form.getFieldValue('camp_details')) && (
              <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div style={css.heading2}>
                  {' '}
                  {props.t('campName')}: <span style={css.heading3}>{campname}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {ForComponent === 'attendance' ? (
          <div className="col-12 ">
            <FiltersReport
              filterDetails={filterDetails}
              refreshedValue={filterClear}
              {...form.getFieldsValue([
                'provider',
                'user',
                'class_details',
                'camp_details',
              ])}
            />
          </div>
        ) : (
          <div className="col-12 mt-2">
            <StudentOnboard
              filterDetails={filterDetails}
              refreshedValue={filterClear}
              {...form.getFieldsValue([
                'provider',
                'user',
                'class_details',
                'camp_details',
              ])}
            />
          </div>
        )}
      </div>
    )
  }

  return render()
}

const mapStateToProps = (state) => ({
  businessTutorBaseProfile: state.tutorProfile.tutorBaseProfile,
  sessionList: state.common.sessionList,
  sessionListClass: state.common.sessionListClass,
  tutorslist: _.get(state, 'general.tutor.list'),
  students: state.classesDetails.classesStudents,
  campsStudents: state.campsDetails.campsStudents,
})

const mapDispatchToProps = (dispatch) => ({
  gettutorlist: (data) => dispatch(generalAction.tutor.tutorList(data)),
  getclasseslist: (requestParams) =>
    dispatch(commonActions.onGetsessionListClass(requestParams)),
  onGetSessionList: (requestParams) =>
    dispatch(commonActions.onGetsessionList(requestParams)),
  getCampsStudents: (requestParams) =>
    dispatch(action.getCampsStudents(requestParams)),
  getstudentslist: (requestParams) =>
    dispatch(classActions.getClassesStudents(requestParams)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(AttendancesFilter))
