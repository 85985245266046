import React, { useEffect, useState } from 'react';
import {
  Form, Spin, Button, message, notification,
} from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import generalInfoFormMeta from './form-meta';
import generalAction from '../../../../../store/general/action';
import generalActionTypes from '../../../../../store/general/actionTypes';

function PoliciesTab(props) {
  // Declare a new state variable, which we'll call "formMeta"
  const [formMeta] = useState(generalInfoFormMeta);
  // Declare a new state variable, which we'll call "tutorProfile"
  const [tutorProfile, setTutorProfile] = useState({});
  // Declare a new state variable, which we'll call "didMount"
  const [didMount, setDidMount] = useState(false);
  // Declare a new state variable, which we'll call "tutorclassesPolicy"
  const [tutorclassesPolicy, setTutorclassesPolicy] = useState(EditorState.createEmpty());
  // Declare a new state variable, which we'll call "localState"
  const [localState, setLocalState] = useState({
    save_changes_loading: false,
    is_fetching_tutor_profile: false,
  });
    // Declare a new state variable, which we'll call "canUpdateForm"
  const [canUpdateForm, setCanUpdateForm] = useState(false);
  // Declare a new state variable, which we'll call "loadSpin"
  const [loadSpin, setLoadSpin] = useState(false);

  // Creating form instance, which we'll call "form"
  const [form] = Form.useForm();

  useEffect(() => {
    componentDidMount(() => {
      // fetching tutor profile from api
      setLocalState({ ...localState, is_fetching_tutor_profile: true });
      const url = props.location.search;
      const urlparams = queryString.parse(url);
      if (urlparams && urlparams.id) {
        props.viewProvider({
          params: { expand: 'classes,services', id: urlparams.id },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      } else {
        props.getTutorProfile({
          params: { expand: 'classes,services' },
          callback: (success, data) => {
            if (success) {
              setGeneralInfoFormFields(_.get(data, 'details', {}));
            }
            setLocalState({ ...localState, is_fetching_tutor_profile: false });
          },
        });
      }
    });
    manageLoadSpin();
  });

  useEffect(() => {
    setCurrentTabModified();
  }, [canUpdateForm]);

  const setCurrentTabModified = () => {
    if (_.isFunction(_.get(props, 'setCurrentTabModified', () => { }))) {
      props.setCurrentTabModified(canUpdateForm);
    }
  };

  const setGeneralInfoFormFields = (tutor_profile_details) => {
    getConvertedText(_.get(tutor_profile_details, 'classes_policy', ''), (classes_policy) => {
      setTutorclassesPolicy(classes_policy);
    });
    setTutorProfile(tutor_profile_details);
  };

  const onSaveChanges = (form_data) => {
    setLocalState({ ...localState, save_changes_loading: true });

    props.getTutorProfile({
      params: { expand: 'classes,services' },
      callback: (success, data) => {
        if (success) {
          const url = props.location.search;
          const urlparams = queryString.parse(url);
          if (urlparams && urlparams.id) {
            data.details.id = urlparams.id;
          }
          updateProfile(form_data, _.get(data, 'details', {}), (success, updatedProfileData) => {
            if (success) {
              setTutorProfile(updatedProfileData);
              setCanUpdateForm(false);
            }
            setLocalState({ ...localState, save_changes_loading: false });
          });
        } else {
          message.error(props.t('error1'));
          setLocalState({ ...localState, save_changes_loading: false });
        }
      },
    });
  };

  const updateProfile = (form_data, profileDetails, callback = () => { }) => {
    profileDetails.classes_policy = draftToHtml(convertToRaw(tutorclassesPolicy.getCurrentContent()));
    profileDetails.location = { lat: _.get(profileDetails, 'location.coordinates[1]', null), lng: _.get(profileDetails, 'location.coordinates[0]', null) };
    let buildingProfileDetails = _.pick(form_data, []);
    buildingProfileDetails = { ...profileDetails, ...buildingProfileDetails };
    const url = props.location.search;
    const urlparams = queryString.parse(url);
    if (urlparams && urlparams.id) {
      buildingProfileDetails.id = urlparams.id;
    }
    // updating tutor profile to api
    props.updateProfile({
      body: buildingProfileDetails,
      callback: (success, data) => {
        callback(success, _.get(data, 'details', {}));
      },
      // notification: { enable: true, message: { success: " Updated Successfully." } }
    });
    notification.success({
      description: props.t('genProfile11'),
      message: props.t('success'),
    });
  };

  const isFieldRequired = (form_meta_name) => {
    const form_rules = _.isFunction(_.get(formMeta, `${form_meta_name}.form_item`, {})) ? formMeta[form_meta_name].form_item().rules : _.get(formMeta, `${form_meta_name}.form_item.rules`, []);
    return !_.isEmpty(_.find(form_rules, 'required')) ? (<span className="label-star">*</span>) : null;
  };

  const getConvertedText = (classes_policy, callback) => {
    const blocksFromHtml = htmlToDraft(classes_policy);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    classes_policy = EditorState.createWithContent(contentState);
    callback(classes_policy);
  };
  const validateCanUpdateForm = (form_data, additional_data = {}) => {
    const current_form_data = { ...additional_data };
    const current_tutor_profile_details = _.pick(_.clone(tutorProfile), [_.get(formMeta, 'service_provider_bio.form_item.name', '')]);
    const result = !_.isEqual(current_form_data, current_tutor_profile_details);
    if (!_.isEqual(result, canUpdateForm)) {
      setCanUpdateForm(result);
    } else if ((_.has(props, 'isCurrentTabIsModified') && !_.get(props, 'isCurrentTabIsModified'))) {
      setCurrentTabModified();
    }
  };
  const manageLoadSpin = () => {
    const load_spin = (_.get(localState, 'is_fetching_tutor_profile', false) || _.get(localState, 'save_changes_loading', false));
    if (!_.isEqual(loadSpin, load_spin)) {
      setLoadSpin(load_spin);
    }
  };
  const componentDidMount = (callback) => {
    if (!didMount) {
      callback();
      setDidMount(true);
    }
  };

  const mainComponent = () => (
    <div className="row" style={{ margin: 0 }}>
      <label className="filter-group-title mt-3 ml-3">{props.t('policies')}</label>
      <div className="col-12 mb-3">
        <Form
          form={form}
          onFinish={onSaveChanges}
          scrollToFirstError
        >
          {/* defining form property classes_policy */}
          <div className="row">
            <div className="col-12 col-lg-12 mt-3">
              <div className="full-width drift-editor class-policy-editor">
                <Form.Item {..._.get(formMeta, 'service_provider_classes_policy.form_item', {})}>
                  <Editor
                    {..._.get(formMeta, 'service_provider_classes_policy.editor', {})}
                    editorState={tutorclassesPolicy}
                    onEditorStateChange={(editorState) => {
                      setTutorclassesPolicy(editorState);
                      validateCanUpdateForm(form.getFieldsValue(), {
                        [_.get(formMeta, 'service_provider_bio.form_item.name', '')]: draftToHtml(convertToRaw(editorState.getCurrentContent())),
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          {/* Defining form submit button */}
          <div className="full-width button-group-section mt-3">
            <Form.Item>
              <Button disabled={!canUpdateForm || _.get(localState, 'save_changes_loading', false)} loading={_.get(localState, 'save_changes_loading', false)} type="primary" htmlType="submit" className="btn-filter">Save Changes</Button>
            </Form.Item>
          </div>

        </Form>
      </div>
    </div>
  );
  return (
    <Spin spinning={loadSpin}>
      {mainComponent()}
    </Spin>
  );
}

const mapStateToProps = (state) => ({ tutor: _.get(state, `general.${generalActionTypes.tutor.name}`, {}), services: _.get(state, `general.${generalActionTypes.services.name}`, {}) });

const mapDispatchToProps = (dispatch) => ({
  getServicesList: (details) => dispatch(generalAction.services.list(details)),
  updateHandle: (details) => dispatch(generalAction.tutor.updateHandle(details)),
  updateProfile: (details) => dispatch(generalAction.tutor.updateProfile(details)),
  updateBusinessProfile: (details) => dispatch(generalAction.tutor.updateBusinessProfile(details)),
  getTutorProfile: (details) => dispatch(generalAction.tutor.getProfile(details)),
  viewProvider: (data) => dispatch(generalAction.tutor.viewProvider(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PoliciesTab));
