import React from 'react'
import timeDateConverterSession from '../timezone/sessionTimeDate'

const TimeDisplay = ({ day, startTime, endTime }) => {
  return (
    <div className="dashboard-profile-nametime">
      {day.slice(0, 3)} -{' '}
      {timeDateConverterSession(startTime, 'time', 'hh:mm A')} to{' '}
      {timeDateConverterSession(endTime, 'time', 'hh:mm A')}
    </div>
  )
}

const TimingsDisplay = ({ timings }) => {
  return timings
    ? Object.entries(timings).map(([day, timings]) => (
        <TimeDisplay
          key={day}
          day={day}
          startTime={timings.start_time}
          endTime={timings.end_time}
        />
      ))
    : ''
}

export default TimingsDisplay
