import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

const generalInfoFormMeta = {
  service_provider_bio: {
    form_item: {
      name: 'tagline',
      rules: [
        {
          required: false,
          message: 'Please write about you.',
        },
      ],
    },
    editor: {
      wrapperClassName: 'demo-wrapper',
      editorClassName: 'demo-editor',
    },
    // label_text: "About you"
  },
  service_provider_max_drive: {
    form_item: {
      name: 'max_drive',
      rules: [
        {
          required: false,
          message: 'Please slide to a valid miles.',
        },
      ],
    },
    label_text: 'Max miles you can drive to student from your location',
  },
  service_provider_amenities: {
    form_item: {
      name: 'amenities',
      rules: [
        {
          required: true,
          message: 'Please check the amenities.',
        },
      ],
    },
    tooltip: {
      placement: 'right',
      title: <span>
        {' '}
        <span className="tooltip-icon"><AiOutlineExclamationCircle /></span>
        {' '}
        <span className="tooltip-text">You can multi select the Amenities.</span>
      </span>,
    },
    data: [
      {
        label: 'Masks required',
        value: 'Masks required',
      },
      {
        label: 'Staff wears masks',
        value: 'Staff wears masks',
      },
      {
        label: 'Free Wi-Fi',
        value: 'Free Wi-Fi',
      },
      {
        label: 'Offers Military Discount',
        value: 'Offers Military Discount',
      },

    ],
  },
  service_provider_awards: {
    form_item: (properties = {}) => ({
      name: 'credentials',
      rules: [
        {
          required: false,
          message: 'Please Enter Honros and certificates you have received.',
        },
      ],
    }),
    tooltip: {
      placement: 'right',
      title: <span>
        {' '}
        <span className="tooltip-icon"><AiOutlineExclamationCircle /></span>
        {' '}
        <span className="tooltip-text">Honros and certificates you have received.</span>
      </span>,
    },
    label_text: 'Awards/Certificates',
    select: {
      className: 'w-100',
      showSearch: true,
      placeholder: 'eg: Certified by AMA',
      mode: 'tags',
    },
  },
};
export default generalInfoFormMeta;
