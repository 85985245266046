import _ from 'lodash';
import axios from '../../../../utils/axios';
import * as searchActionType from './actionTypes';

export const common = (actionType, data) => ({
  type: actionType,
  payload: data,
});

/**
 *
 * @param {*} requestParams
 * @param {String} type 
 * @returns
 */
export const onGetClassList = (requestParams = {}) => {
  // console.log('requestParams >>>> ', requestParams);

  const rp = { ...requestParams };
  rp.limit = 10;

  let searchAct = searchActionType.classList;
  
  if (requestParams.serviceName === 'camp') {
    searchAct = searchActionType.campList;
  } else if (requestParams.serviceName === 'competition') {
    searchAct = searchActionType.compeList;
  } else if (requestParams.serviceName === 'event') {
    searchAct = searchActionType.eventList;
  }


  return (dispatch) => {

    setTimeout(() => {
      axios
        .get('/search', { params: rp })
        .then((response) => {

          if (response.status === 200 || response.status === 304) {
            const data = _.get(response, ['data'], []);
            // console.log("responsedata", data)
            dispatch(common(searchAct, data));
          } else {
            console.log('no data response');
            dispatch(common(searchAct, []));
          }
        })
        .catch((error) => {
          console.log('err at frontend src store actions public search index.js line 77', error.message);
          dispatch(common(searchAct, []));
        });
    }, 250);
  };
};
