import React, { useState, useEffect } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import './ach.css'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'

function CheckoutForm(props) {
  const stripe = useStripe()
  const elements = useElements()
  const [delayTime, setDelayTime] = useState(5000)
  const [message, setMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [paymentInfo, setPaymentInfo] = useState({})

  useEffect(() => {
    if (!stripe) {
      return
    }

    const { clientSecret } = props

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      console.log('paymentIntent>>>', paymentIntent)
      console.log('paymentIntent.status>>>', paymentIntent.status)

      setPaymentInfo({
        amount: paymentIntent.amount,
        currency: paymentIntent.currency,
      })

      if (paymentIntent) {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage(props.t('paySuccess'))
            break
          case 'processing':
            setMessage(props.t('payLoading'))
            break
          case 'requires_payment_method':
          case 'requires_source':
            setMessage("")
            break
          case 'payment_failed':
            setMessage(props.t('payFail'))
            break
          default:
            setMessage('')
            break
        }
      }
    })
  }, [stripe, props.clientSecret])

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return
      }
      setIsLoading(true)
      stripe
        .confirmPayment({
          elements,
          redirect: 'if_required',
        })
        .then(({ paymentIntent, error }) => {
          if (error) {
            setMessage(error.message)
            setTimeout(() => {
              setDelayTime(delayTime * 2)
              setIsLoading(false)
            }, delayTime)
          } else {

            const { clientSecret } = props

            let status = _.get(paymentIntent, 'status')
            const paid_amount = _.get(paymentIntent, 'amount',0)

            const paymentIntent_data = { ...props.paymentIntent_data, amount: paid_amount}

            //console.log("paymentIntent>>>gbs", paymentIntent_data)
            if (
              status === 'requires_source_action' &&
              paymentIntent.payment_method &&
              paymentIntent.next_action.type === 'verify_with_microdeposits'
            ) {
              status = 'processing'
            }

            switch (status) {
              case 'succeeded':
                setMessage(props.t('paySuccess'))

                // update payment transaction
                props.onCreateTransaction({
                  clientSecret,
                  status: 'succeeded',
                  paymentIntent_data,
                })

                setTimeout(() => {
                  setIsLoading(false)
                  props.showACHModal(false)
                }, 1500)
                break
              case 'processing':
                setMessage(props.t('payLoading'))

                // keep in standard plan
                props.onCreateTransaction({
                  clientSecret,
                  status: 'processing',
                  paymentIntent_data,
                })

                setTimeout(() => {
                  setIsLoading(false)
                  props.showACHModal(false)
                }, 1500)

                break
              case 'requires_source':
              case 'requires_payment_method':
                setMessage(props.t('payFail'))
                setTimeout(() => {
                  setDelayTime(delayTime * 2)
                  setIsLoading(false)
                }, delayTime)
                break
              default:
                setMessage("")
                setTimeout(() => {
                  setDelayTime(delayTime * 2)
                  setIsLoading(false)
                }, delayTime)
                break
            }
          }
        })
    } catch (error) {
      console.log('error at PlanSubscriptionForm', error)
      setTimeout(() => {
        setIsLoading(false)
        setMessage(error.message)
      }, delayTime)
    }
  }

  const paymentElementOptions = {
    layout: 'tabs',
    clientSecret: props.clientSecret,
  }

  //const intentAmount = Math.round(_.get(paymentInfo, 'amount', 0) / 100, 2)
  const amount_int = _.get(paymentInfo, 'amount', 0);
  const intentAmount = (amount_int / 100).toFixed(2);

  // const actualAmount = Math.round(
  //   _.get(props, 'paymentIntent_data.amount', 0) / 100,
  //   2
  // )

  const amount_act = _.get(props, 'paymentIntent_data.amount', 0);
  const actualAmount = (amount_act / 100).toFixed(2);

  const currency = _.get(paymentInfo, 'currency', '')
  const currencySymbol = currency.toLowerCase() === 'usd' ? '$' : currency

  const payNowText = props.t('payNow')

  // Format the amounts conditionally
  // Function to render the button label
  const renderButtonLabel = () => {
    if (actualAmount > intentAmount) {
      return (
        <>
          <span
            style={{ textDecoration: 'line-through' }}
          >{`${currencySymbol}${actualAmount}`}</span>
          {` / ${currencySymbol}${intentAmount} - ${payNowText}`}
        </>
      )
    } else {
      return `${currencySymbol}${intentAmount} - ${payNowText}`
    }
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner" />
          ) : (
            renderButtonLabel()
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  )
}

export default withTranslation()(CheckoutForm)
