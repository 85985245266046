import React from 'react'
import { Tabs, Table, Space} from 'antd'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import * as userAction from '../../../store/actions/users'
import * as routeName from '../../../components/route-names'
import './user-my-competitions.css'
import history from '../../../utils/history'
import timeDateConverterSession from '../../../components/timezone/sessionTimeDate'
import nextSession from '../../../components/tutor/nextSession'
import { ClassDetailLoader } from '../../../components/loader/common/class-detail'
import _ from 'lodash'
import css from '../../../utils/CSSUtility'
class Competitions extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      redirect: false,
      classTabs: '1',
      today_schedules: [],
      page: 1,
      limit: 100,
      competitionTableData: [],
      expiredCompetitionTableData: [],
      timeZones: 'Asia/Kolkata',
      provider_id:
        this.props.tutorBaseProfile &&
        this.props.tutorBaseProfile.details &&
        this.props.tutorBaseProfile.details.id,
      classRow_id: '',
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0) // scroll to top
    this._isMounted = true
    if (
      _.get(this, 'props.userBaseProfile.details.user.account_type') !==
      'dependent'
    ) {
      this.props.getDependentUsers()
    }

    const requestParams = {}
    requestParams.limit = 100
    requestParams.expand = 'provider,dependent_user,competition_details'
    requestParams.sort = 'updated_at.desc'
    this.props.getCompetitionList(requestParams)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  async componentDidUpdate(prevPros) {
    if (
      prevPros.userCompetitions !== this.props.userCompetitions &&
      this.props.userCompetitions.items &&
      this.props.userCompetitions.items.length > 0
    ) {

      this.setState((prevState) => ({
        competitionTableData: [
          ...prevState.competitionTableData,
          ...this.props.userCompetitions.items.filter(item => !this.isExpired(item)),
        ],
        expiredCompetitionTableData: [
          ...prevState.expiredCompetitionTableData,
          ...this.props.userCompetitions.items.filter(item => this.isExpired(item)),
        ],
      }))
    
    }
  }

  capitalizeWord = (value) => {
    if (typeof value !== 'string') return ''
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  isExpired = (competition) => {
    const endDate = new Date(_.get(competition, 'competition_details.competition_start_date'))
    return endDate < new Date()
  }

  render() {
    const { classRow_id } = this.state
    const ListingCompetitioncolumns = [
      {
        title: this.props.t('batchLabel2'),
        key: 'class',
        dataIndex: 'class',
        width: '25%',
      },
      {
        title: this.props.t('toWhom'),
        dataIndex: 'to_whom',
        key: 'to_whom',
        width: '15%',
      },
      {
        title: this.props.t('registrationDate'),
        key: 'competition_book_date',
        width: '15%',
        render: (record) => {
          const { duration_competition, start_date, end_date } =
            record.campDetails

          if (duration_competition === '2') {
            return `${timeDateConverterSession(start_date, 'date')} to ${timeDateConverterSession(end_date, 'date')}`
          }
          if (duration_competition === '1') {
            return timeDateConverterSession(end_date, 'date')
          }
          return '---'
        },
      },
      {
        title: this.props.t('compDate'),
        key: 'competition_date',
        width: '15%',
        render: (record) => {
          const {
            duration_competition,
            competition_start_date,
            competition_end_date,
          } = record.campDetails

          if (duration_competition === '2') {
            return `${timeDateConverterSession(competition_start_date, 'date')} to ${timeDateConverterSession(competition_end_date, 'date')}`
          }
          if (duration_competition === '1') {
            return timeDateConverterSession(competition_start_date, 'date')
          }
          return '---'
        },
      },
      {
        title: this.props.t('location'),
        dataIndex: 'location',
        key: 'location',
        width: '22%',
      },
      {
        title: this.props.t('Action'),
        key: 'action',
        width: '8%',
        render: (text, record) => {
          const activeuser = {
            to_whom: _.get(record, 'to_whom'),
            to_whom_id: _.get(record, 'to_whom_id'),
            user_type: _.get(record, 'user_type'),
          }
          const link = `${routeName.userCompetitionView}/${encodeURIComponent(_.get(record, 'class'))}/?competitionId=${_.get(record, 'id', '')}&userid=${_.get(activeuser, 'to_whom_id', '')}&usertype=${_.get(activeuser, 'user_type')}`
          return (
            <Space size="middle">
              <div className="d-flex">
                <button
                  onClick={() => history.push(link)}
                  className="btn btn-accept  mr-1"
                >
                  {this.props.t('details')}
                </button>
              </div>
            </Space>
          )
        },
      },
    ]
    const competitionData = []
    const dependentCompetitionData = []
    if (
      (this.props.userCompetitions &&
        this.props.userCompetitions.items &&
        this.props.userCompetitions.items.length > 0) ||
      _.size(_.get(this, 'state.competitionTableData')) > 0
    ) {
      this.state.competitionTableData.forEach((value, index) => {
        const { competition_details, packages, dependent_user, provider } =
          value

        const userName = _.get(this, 'props.userBaseProfile.details.firstname')
        const md = _.chain(value)

        if (!md.get('competition_details').isEmpty().value()) {
          const capitalizedSessionName = this.capitalizeWord(
            _.get(competition_details, 'session_name', '')
          )
          const status = _.get(value, 'status')

          const item = {
            id: _.get(value, 'id'),
            class:
              status === 0
                ? `${capitalizedSessionName}(Awaiting for Tutor response.)`
                : capitalizedSessionName,
            to_whom:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? userName
                : _.startCase(_.get(dependent_user, 'firstname')),
            to_whom_id:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? md.get('user').value()
                : _.get(dependent_user, 'id'),
            user_type:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? 'user'
                : 'dependent',
            package: _.get(packages, '[0].name'),
            next_session: nextSession(competition_details, 'competitions'),
            session: timeDateConverterSession(
              _.get(competition_details, 'start_date'),
              'date'
            ),
            end_date: timeDateConverterSession(
              _.get(competition_details, 'end_date'),
              'date'
            ),
            location:
              _.get(competition_details, 'teaching_mode') === 'o'
                ? 'Online'
                : _.get(competition_details, 'location_taught', '')
                  ? _.get(competition_details, 'location_taught', '')
                  : '---',
            key: _.get(competition_details, 'id'),
            class_id: _.get(competition_details, 'id'),
            campDetails: competition_details,
            providerDetails: provider,
            getUserDetails: _.get(value, 'user', dependent_user),
            link: _.get(competition_details, 'link', '---'),
            code: _.get(competition_details, 'code', '---'),
          }
          if (
            _.get(this, 'props.userBaseProfile.details.user.account_type') !==
            'dependent'
          ) {
            if (
              md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
            ) {
              competitionData.push(item)
            } else {
              dependentCompetitionData.push(item)
            }
          } else if (
            !md.get('dependent_user').isEmpty().value() &&
            !md.get('user').isEmpty().value()
          ) {
            competitionData.push(item)
          }
        }
      })
    }
    const expiredCompetitionData = []
    const expiredDependentCompetitionData = []
    if (
      (this.props.userCompetitions &&
        this.props.userCompetitions.items &&
        this.props.userCompetitions.items.length > 0) ||
      _.size(_.get(this, 'state.expiredCompetitionTableData')) > 0
    ) {
      this.state.expiredCompetitionTableData.forEach((value, index) => {
        const { competition_details, packages, dependent_user, provider } =
          value

        const userName = _.get(this, 'props.userBaseProfile.details.firstname')
        const md = _.chain(value)

        if (!md.get('competition_details').isEmpty().value()) {
          const capitalizedSessionName = this.capitalizeWord(
            _.get(competition_details, 'session_name', '')
          )
          const status = _.get(value, 'status')

          const item = {
            id: _.get(value, 'id'),
            class:
              status === 0
                ? `${capitalizedSessionName}(Awaiting for Tutor response.)`
                : capitalizedSessionName,
            to_whom:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? userName
                : _.startCase(_.get(dependent_user, 'firstname')),
            to_whom_id:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? md.get('user').value()
                : _.get(dependent_user, 'id'),
            user_type:
              md.get('dependent_user').isEmpty().value() &&
                !md.get('user').isEmpty().value()
                ? 'user'
                : 'dependent',
            package: _.get(packages, '[0].name'),
            next_session: nextSession(competition_details, 'competitions'),
            session: timeDateConverterSession(
              _.get(competition_details, 'start_date'),
              'date'
            ),
            end_date: timeDateConverterSession(
              _.get(competition_details, 'end_date'),
              'date'
            ),
            location:
              _.get(competition_details, 'teaching_mode') === 'o'
                ? 'Online'
                : _.get(competition_details, 'location_taught', '')
                  ? _.get(competition_details, 'location_taught', '')
                  : '---',
            key: _.get(competition_details, 'id'),
            class_id: _.get(competition_details, 'id'),
            campDetails: competition_details,
            providerDetails: provider,
            getUserDetails: _.get(value, 'user', dependent_user),
            link: _.get(competition_details, 'link', '---'),
            code: _.get(competition_details, 'code', '---'),
          }
          if (
            _.get(this, 'props.userBaseProfile.details.user.account_type') !==
            'dependent'
          ) {
            if (
              md.get('dependent_user').isEmpty().value() &&
              !md.get('user').isEmpty().value()
            ) {
              expiredCompetitionData.push(item)
            } else {
              expiredDependentCompetitionData.push(item)
            }
          } else if (
            !md.get('dependent_user').isEmpty().value() &&
            !md.get('user').isEmpty().value()
          ) {
            expiredCompetitionData.push(item)
          }
        }
      })
    }
    
    const competitionDataUniq = _.uniqBy(competitionData, (e) => e.class_id)
    const expiredCompetitionDataUniq = _.uniqBy(expiredCompetitionData, (e) => e.class_id)
    const dependentCompetitionDataUniq = _.uniqBy(dependentCompetitionData,(e) => e.class_id)
    const expiredDependentCompetitionDataUniq = _.uniqBy(expiredDependentCompetitionData,(e) => e.class_id)

    const dependentUsers = _.get(this, 'props.dependentUsers.items', [])
    return (
      <div className="nurtem-myclass-design nurtem-table mb-3">
        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
          <Tabs defaultActiveKey="1">
            <items tab={this.props.t('tutorSidebarMyCompetitions')} key="1">
              <div className="schedule-content mt-2">
                <div style={{ overflowX: 'auto' }}>
                  {this.props.loading ? (
                    <ClassDetailLoader />
                  ) : (
                    <Table
                      loading={
                        _.isEmpty(competitionDataUniq) ? false : !competitionDataUniq
                      }
                      rowClassName={(record, index) =>
                        record.key === classRow_id ? 'active' : ''
                      }
                      columns={ListingCompetitioncolumns}
                      dataSource={competitionData}
                      scroll={{ x: true }}
                      pagination={{
                        showSizeChanger: false,
                        pageSize: 5,
                        position: ['bottomLeft'],
                      }}
                    />
                  )}
                </div>
              </div>
            </items>
            {(() =>
              _.map(
                _.size(dependentUsers) > 0 && dependentUsers,
                (user, index) => (
                  <items
                    tab={`${user && _.get(user, 'firstname') ? `${_.get(user, 'firstname', '')}${_.get(user, 'lastname', '')}` : ''}'s`}
                    key={_.get(user, 'id')}
                  >
                    <div className="schedule-content mt-2 dependent-shedule-content">
                      <div style={{ overflowX: 'auto' }}>
                        {this.props.loading ? (
                          <ClassDetailLoader />
                        ) : (
                          <Table
                            loading={
                              _.isEmpty(dependentCompetitionDataUniq)
                                ? false
                                : !dependentCompetitionDataUniq
                            }
                            rowClassName={(record, index) =>
                              record.class_id === classRow_id ? 'active' : ''
                            }
                            columns={ListingCompetitioncolumns}
                            scroll={{ x: true }}
                            dataSource={_.uniqBy(
                              dependentCompetitionData.filter(
                                (e) => e.to_whom_id === user.id
                              ),
                              (e) => e.class_id
                            )}
                            pagination={{
                              pageSize: 5,
                              position: ['bottomLeft'],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </items>
                )
              ))()}
          </Tabs>
          <div style={css.heading1}>
          {this.props.t('expiredCompetition')}
          </div>
          <Tabs>
        <items tab={this.props.t('tutorSidebarMyCompetitions')} key="1_1">
          <div className="schedule-content mt-2">
            <div style={{ overflowX: 'auto' }}>
              {this.props.loading ? (
                <ClassDetailLoader />
              ) : (
                <Table
                  loading={
                    _.isEmpty(expiredCompetitionDataUniq) ? false : !expiredCompetitionDataUniq
                  }
                  rowClassName={(record, index) =>
                    record.key === classRow_id ? 'active' : ''
                  }
                  columns={ListingCompetitioncolumns}
                  dataSource={expiredCompetitionData}
                  scroll={{ x: true }}
                  pagination={{
                    showSizeChanger: false,
                    pageSize: 5,
                    position: ['bottomLeft'],
                  }}
                />
              )}
            </div>
          </div>
        </items>
        {(() =>
          _.map(
            _.size(dependentUsers) > 0 && dependentUsers,
            (user, index) => (
              <items
                tab={`${user && _.get(user, 'firstname') ? `${_.get(user, 'firstname', '')}${_.get(user, 'lastname', '')}` : ''}'s`}
                key={_.get(user, 'id')}
              >
                <div className="schedule-content mt-2 dependent-shedule-content">
                  <div style={{ overflowX: 'auto' }}>
                    {this.props.loading ? (
                      <ClassDetailLoader />
                    ) : (
                      <Table
                        loading={
                          _.isEmpty(expiredDependentCompetitionDataUniq)
                            ? false
                            : !expiredDependentCompetitionDataUniq
                        }
                        rowClassName={(record, index) =>
                          record.class_id === classRow_id ? 'active' : ''
                        }
                        columns={ListingCompetitioncolumns}
                        scroll={{ x: true }}
                        dataSource={_.uniqBy(
                          expiredDependentCompetitionData.filter(
                            (e) => e.to_whom_id === user.id
                          ),
                          (e) => e.class_id
                        )}
                        pagination={{
                          pageSize: 5,
                          position: ['bottomLeft'],
                        }}
                      />
                    )}
                  </div>
                </div>
              </items>
            )
          ))()}
        </Tabs>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>
// console.log('loadingnow=======', state)
({
  loading: state.users.loading,
  loggedInUserDetails: state.authentication,
  userCompetitions: state.users.userCompetitions,
  dependentUsers: state.userProfile.dependentUsers,
  userBaseProfile: state.userProfile.userBaseProfile,
})
const mapDispatchToProps = (dispatch) => ({
  getCompetitionList: (requestParams) =>
    dispatch(userAction.getUserCompetitions(requestParams)),
  getDependentUsers: () => dispatch(userAction.getDependentUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Competitions))
