import * as actionTypes from '../../actions/tutor/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  loading: {
    loading: false,
  },
  tutorAttendenceDetails: {},
  deleteAttendenceInfo: {},
  tutorAttendence: {},
  attendancesList: { status: false, data: { items: [] } },
  AttendenceCreation: {},
  attendanceReport: {},
};

const attendancesList = (state, action) => ({ ...state, attendancesList: action.success });

const AttendenceCreation = (state, action) => ({ ...state, AttendenceCreation: action.success });

const tutorAttendenceDetails = (state, action) => ({ ...state, tutorAttendenceDetails: action.success });

const tutorAttendenceDetele = (state, action) => ({ ...state, deleteAttendenceInfo: action.success });

// const tutorAttendence = (state, action) => {
//   return { ...state, tutorAttendence: action.success };
// };

const enableLoading = (state, action) => updateObject(state, action.loading);

const disableLoading = (state, action) => updateObject(state, action.loading);

const attendanceReport = (state, action) => ({ ...state, attendanceReport: action.success });

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.tutorAttendenceDetailsFoundSuccess:
      return tutorAttendenceDetails(state, action);

    case actionTypes.attendenceDeleteFoundSuccess:
      return tutorAttendenceDetele(state, action);

    case actionTypes.enableLoading:
      return enableLoading(state, action);

    case actionTypes.AttendenceCreation:
      return AttendenceCreation(state, action);

    case actionTypes.disableLoading:
      return disableLoading(state, action);

    case actionTypes.attendenceListFound:
      return attendancesList(state, action);

    case actionTypes.attendanceReport:
      return attendanceReport(state, action);

    default:
      return state;
  }
};

export default reducer;
