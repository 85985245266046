import { requestMiddleware } from '../settings';
import actionType from '../actionTypes';

const reducerName = actionType.generalUpdateDiscountsState;
// tutor actions

export default {
  // get discounts list
  list: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'get',
        url: '/discounts/list',
      },
      actionType: actionType.discounts.list,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
  // delete a specific discount from discounts list
  delete: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/discounts/delete',
      },
      actionType: actionType.discounts.delete,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },

  // create a discount
  create: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/discounts/create',
      },
      actionType: actionType.discounts.create,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },

  // update a specific discount
  update: (componentRequest) => (dispatch) => {
    const requestOptions = {
      axiosParams: {
        method: 'post',
        url: '/discounts/update',
      },
      actionType: actionType.discounts.update,
      reducerName,
      dispatch,
      componentRequest,

    };
    requestMiddleware(requestOptions);
  },
};
