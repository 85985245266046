import React, { useState } from 'react';
import {
  Modal, Button, Input, Tooltip, notification,
} from 'antd';
import {
  AiOutlineExclamationCircle,
  AiOutlineInfoCircle,
  AiOutlineCheckCircle,
  AiFillCheckCircle,
} from 'react-icons/ai';
import axiostoServer from '../../../utils/axios';

import dayjs from 'dayjs';
import _ from 'lodash';
import './claimed.css';
import { withTranslation } from 'react-i18next';

function Claimed(props) {
  //console.log('provider4===========>', props);

  const unclaimedTooltip = (
    <span>
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>
      <span className="tooltip-text">{props.t('claimed')}</span>
    </span>
  );

  const claimedTooltip = (
    <span>
      <span className="tooltip-icon">
        <AiOutlineExclamationCircle style={{ fontSize: 20 }} />
      </span>
      <span className="tooltip-text">{props.t('claimed1')}</span>
    </span>
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState('');
  const [emailclaimed, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setIsModalVisible(false);
  };

  const handleSubmit = () => {
    const time = dayjs().valueOf();
    if (!name || !emailclaimed || !phone || !message) {
      alert(props.t('claimmessage4'));
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailclaimed)) {
      alert(props.t('inputValidEmail'));
      return;
    }
    // if (!phoneRegex.test(phone)) {
    //   alert(props.t("inputValidPhone"));
    //   return;
    // }

    const claimData = {
      name, emailclaimed, phone, message, time,
    };
    onClaim(claimData);
  };

  const onClaim = (data) => {
    // console.log("data1111=========",props.tutorDetails.id)
    data.provider = props.tutorDetails.id;
    setLoading(true);

    axiostoServer({
      method: 'post',
      url: '/claim-business',
      withCredentials: false,
      data,
      config: { headers: { 'Content-Type': 'application/json' } },
    }).then((resp) => {
      if (resp.status === 200) {
        const message_args = {
          duration: 10,
          message: resp.data.message,
        };
        notification.success(message_args);
        setIsModalVisible(false);
        setShowModal(true);
      } else {
        setLoading(false);
        setIsModalVisible(false);
        const message_args = {
          duration: 2,
          message: props.t('error1'),
        };
        notification.error(message_args);
      }
    });
  };

  const isClaimed = _.get(props, 'tutorDetails.claim', 0) === 1;
  //console.log('isClaimed===========>', isClaimed);
  //console.log('isClaimed======2', _.get(props, 'tutorDetails.claim'));
  return (
    <>
      {!isClaimed ? (
        <>
          <Tooltip placement="right" title={unclaimedTooltip}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: '600',
              }}
              onClick={handleOpenModal}
            >
              <span>{props.t('claimmessage3')}</span>
              <AiOutlineInfoCircle
                className="ml-1"
                style={{ color: '#fa0000', fontSize: '18px' }}
              />
            </div>
          </Tooltip>

          <Modal
            title="Claim Business"
            open={isModalVisible}
            onCancel={handleCloseModal}
            footer={[
              <Button key="cancel" onClick={handleCloseModal}>
                {props.t('cancel')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={handleSubmit}
                loading={loading}
              >
                {props.t('submit')}
              </Button>,
            ]}
          >
            <div>
              <label>
                {props.t('userLabel')}
                :
              </label>
              <Input
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div>
              <label>
                {props.t('email')}
                :
              </label>
              <Input
                name="emailclaimed"
                value={emailclaimed}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label>
                {props.t('phNo')}
                :
              </label>
              <Input
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label>
                {props.t('message')}
                :
              </label>
              <Input.TextArea
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </Modal>
          <Modal
            className="custom-modal"
            open={showModal}
            onCancel={closeModal}
            footer={null}
          >
            <div className="modal-content">
              <div className="modal-header">
                <AiFillCheckCircle
                  style={{ fontSize: 20 }}
                  className="modal-icon"
                />
                <h3>{props.t('claimmessage')}</h3>
              </div>
              <div className="modal-body">
                <p>{props.t('claimmessage1')}</p>
                <p>{props.t('claimmessage2')}</p>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <Tooltip placement="right" title={claimedTooltip}>
          <label className="claim-business-label">
            Claimed
            <AiOutlineCheckCircle
              style={{ fontSize: 20, color: 'blue' }}
              className="ml-1"
            />
          </label>
        </Tooltip>
      )}
    </>
  );
}

export default withTranslation()(Claimed);
