import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import _ from 'lodash';
import './student-list.css';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { DEFAULTUSER } from '../../utils/config/assets';
import * as action from '../../store/actions/tutor/camps';
import EmptyAnime from '../re-usable/lottie/EmptyAnime';

const StudentList = (props) => {
  const columns = [
    {
      title: props.t('students'),
      dataIndex: 'students',
      key: 'students',
    },
  ];
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (props.session.id && props.session.id !== '') { // Only send the API request if camp_details is present or changed
      const notesParams = {
        camp_details: props.session.id,
        provider: props.session.provider.id,
        limit: 100,
        status: 1,
        page,
        sort: 'created_at.desc',
        expand: 'camp_details,user,dependent_user',
      };

      const callback = async () => await props.getCampsStudents(notesParams);
      callback();
    }
  }, [props.session.id]);

  const pageChange = async (page, pageSize) => {
    await setPage(page);
    const notesParams = {
      camp_details: props.session.id,
      limit: 100,
      status: 1,
      page,
      sort: 'created_at.desc',
      expand: 'camp_details,user,dependent_user',
    };
    await props.getCampsStudents(notesParams);
  };

  const customLocale = {
    emptyText: (<div>
      <EmptyAnime AnimeType="Empty" loop animeheight={150} />

      <p className="empty-animations">{props.t('noStu')}</p>
    </div>),
  };
  console.log('sessionDetails======', props.session);
  const render = () => {
    let tableDatatest = [];
    if (
      props.campsStudents
      && props.campsStudents.items
      && props.campsStudents.items.length > 0
    ) {
      const studentslistfilters = _.uniqBy(_.get(props, 'campsStudents.items'), (n) => (!_.isEmpty(n.dependent_user) ? n.dependent_user.id : n.user && n.user.id));

      tableDatatest = _.size(studentslistfilters) > 0 && studentslistfilters.map((value, index) => {
        const name_title_value = value && _.isEmpty(value.dependent_user) ? value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))[0]}.` : ''}` : _.startCase(value.dependent_user.firstname);
        const userphoto = value && _.get(value, 'user.photo');
        return {
          key: value.id,
          students: <div className="table-profile">
            <div>
              <img src={userphoto || DEFAULTUSER} className="profile-image" alt="img" />
            </div>
            <div className="text-truncate" title={name_title_value}>
              {value && _.isEmpty(value.dependent_user)
                ? (value.user && `${_.startCase(value.user.firstname)} ${_.get(value, 'user.lastname', '') !== '' ? `${_.startCase(_.get(value, 'user.lastname', ''))}` : ''}`)
                : _.startCase(value.dependent_user.firstname)}
              {_.get(value, 'status', 0) === 0 ? '( Awaiting for response )' : ''}
            </div>
          </div>,
          paid: _.get(value, 'payment_details.payment_source') === 'offline'
            ? _.startCase(_.get(value, 'payment_details.payment_object.payment_mehtod'))
            : _.startCase(_.get(value, 'payment_details.payment_object.payment_method_details.type')),
          // contact: <div>{_.get(value, 'user.mobile_number', '')}</div>,
          // message:
          //   <div className="row">
          //     {/* <Button className="btn-view-outline">View</Button> */}
          //     <Button className="btn-view-outline" style={{ padding: '0', width: '50px' }}><Link
          //       to={`/tutor/student-view/${_.get(value, 'user.id')}?sid=${_.get(value, 'id')}`}
          //     >View</Link></Button>

          // </div>,

        };
      });
    }

    return (
      <div className="container">
        {/* items Header */}
        <div className="row mb-3">
          <div className=" col-6 tab-content-title">{props.t('stuList')}</div>
        </div>
        <div className="row" style={{ margin: 0 }}>
          <Table
            loading={_.get(props, 'ClassesStudentListLoading', false)}
            dataSource={tableDatatest}
            columns={columns}
            style={{ width: '100%' }}
            locale={customLocale}
            pagination={{
              pageSize: 100,
              onChange: (page, pageSize) => pageChange(page, pageSize),
              total:
                props.campsStudents
                && props.campsStudents.meta
                && parseInt(props.campsStudents.meta.total_count),
              hideOnSinglePage: !props.loading || props.loading,
              showSizeChanger: false,
              current: page,
            }}
          />
        </div>
      </div>
    );
  };
  return render();
};

const mapStateToProps = (state) => ({
  campsStudents: state.campsDetails.campsStudents,
  ClassesStudentListLoading: state.campsDetails.campsStudentListLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getCampsStudents: (requestParams) => dispatch(action.getCampsStudents(requestParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(StudentList));
